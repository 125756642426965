import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Grid } from "@mui/material";

import { styles } from "./AggregatedOrderInfo.styles";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";
import { currencyFormat } from "../../../../common/utils";

// id: 36
// numberOfOrders: 1
// numberOfProducts: 2
// price: 1980
// status: "AC"
// statusCompleted: true

function AggregatedOrderInfo({ classes, order }) {
  const { t } = useTranslation();

  return (
    <Grid className={classes.root} container>
      <Grid item xs={12}>
        <Typography variant="h4" align="left" component="h3">
          {t("orders.AggregatedDetails.aggregatedInfo.title")}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <LabelValue
          label={t("orders.AggregatedDetails.aggregatedInfo.id")}
          value={
            <Typography variant="body1" align="left" component="span">
              {order?.aggregateOrderInfo?.id}
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={2}>
        <LabelValue
          label={t("orders.AggregatedDetails.aggregatedInfo.numberOfOrders")}
          value={
            <Typography variant="body1" align="left" component="span">
              {order?.aggregateOrderInfo?.numberOfOrders}
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={2}>
        <LabelValue
          label={t("orders.AggregatedDetails.aggregatedInfo.numberOfProducts")}
          value={
            <Typography variant="body1" align="left" component="span">
              {order?.aggregateOrderInfo?.numberOfProducts}
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={2}>
        <LabelValue
          label={t("orders.AggregatedDetails.aggregatedInfo.status")}
          value={
            <Typography variant="body1" align="left" component="span">
              {t("common.Enums.orderStatus." + order?.aggregateOrderInfo?.status)}
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={2}>
        <LabelValue
          label={t("orders.AggregatedDetails.aggregatedInfo.price")}
          value={
            <Typography variant="body1" align="left" component="span">
              {currencyFormat(order?.aggregateOrderInfo?.price)}
            </Typography>
          }
        />
      </Grid>
    </Grid>
  );
}

export default withStyles(AggregatedOrderInfo, styles)

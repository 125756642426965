import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "@mui/material";
import CustomThemeProvider from "./theme/CustomThemeProvider";
import "./i18n";
import "../common/yup";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Login from "../features/authentication/Login/Login";
import RecoverPass from "../features/authentication/RecoverPass/RecoverPass";
import PasswordUpdate from "../features/authentication/PasswordUpdate/PasswordUpdate";

import MainLoader from "./components/MainLoader";

import MainRouting from "./MainRouting";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Sandbox from "../sandbox/Sandbox";
import VersionLabel from "./VersionLabel/VersionLabel";

function App() {
  return (
    <CustomThemeProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DndProvider backend={HTML5Backend}>
          <Container maxWidth={false} disableGutters={true}>
            <Suspense fallback={<MainLoader />}>
              <Router>
                <VersionLabel />
                <Routes>
                  <Route path="/sandbox" element={<Sandbox />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/recover" element={<RecoverPass />} />
                  <Route path="/password-update/:token" element={<PasswordUpdate />} />
                  <Route path="/:promotercode/*" element={<MainRouting />} />
                  <Route path="/" element={<Login />} />
                </Routes>
              </Router>
            </Suspense>
          </Container>
        </DndProvider>
      </LocalizationProvider>
    </CustomThemeProvider>
  );
}

export default App;

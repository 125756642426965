import React from "react";
import { withStyles } from "tss-react/mui";
import { Avatar, Tooltip } from "@mui/material";
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';

import { styles } from "./TaskIcon.styles";

function TaskIcon({ classes, task }) {

  return (
    <div className={classes.root}>
      {
        task.buName ?
          <Tooltip title={task.buName}>
            <Avatar className={classes.avatar}>
              {getDesignationInitials(task.buName)}
            </Avatar>
          </Tooltip>
          :
          <ExploreOutlinedIcon className={classes.icon} fontSize="medium" sx={{ display: "block" }} />
      }
    </div>
  );
}

export default withStyles(TaskIcon, styles);

const getDesignationInitials = (designation) => {
  const names = (designation || "").split(" ");
  let result = "";
  for (let i = 0; i < 2; i++) {
    if (names[i]) {
      result += names[i][0];
    }
  }
  return result;
}
import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Popover, List, ListItem } from "@mui/material";
import { DropDownIcon } from "../../../../../common/icons";

import { styles } from "./PriceSelector.styles";
import { currencyFormat } from "../../../../../common/utils";
import { getPriceWithCampaigns, getProductPrice } from "../../../orders.utils";
import { NumberInput } from "../../../../../common/inputs";
import { usePromoter } from "../../../../../common/hooks";

function PriceSelector({ classes, product, quantity, onChange }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [priceOptions, setPriceOptions] = useState();
  const promoter = usePromoter();
  const allowClientInput = promoter?.getConfig("price","allowClientInput");

  useEffect(() => {
    if (
      (product && !priceOptions) ||
      (product && quantity && (product.campaigns || []).length > 0) ||
      (!quantity && (priceOptions || []).filter((item) => item.priceType === "CMP").length > 0)
    ) {
      let options = getPriceOptions(product, allowClientInput);
      setPriceOptions(options);
      let selected = getDefaultSelected(options, product);


      if (selected?.priceType !== product?.priceType || selected?.price !== getProductPrice(product)) {
        onChange &&
          onChange({
            priceType: selected.priceType,
            price: selected.price,
          });
      }
    }

    // eslint-disable-next-line
  }, [product, quantity]);

  const handlePopoverOpen = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onPriceSelected = (item) => {
    // setSelectedPrice(item);
    onChange &&
      onChange({
        priceType: item.priceType,
        price: item.price,
      });
    handlePopoverClose();
  };

  const priceValueChangeHandler = (value) => {
    if (product.price !== parseFloat(value)) {
      let nSelectedPrice = { ...product, price: parseFloat(value) };

      // setSelectedPrice(nSelectedPrice);
      onChange &&
        onChange({
          priceType: nSelectedPrice.priceType,
          price: nSelectedPrice.price,
        });
    }
  };

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" spacing={1} justifyContent="center">
        <Grid item>
          <NumberInput
            className={classes.priceSelector}
            value={getProductPrice(product)}
            onChange={(v) => priceValueChangeHandler(v)}
            decimalScale={2}
            disabled={product?.priceType !== "C"}
            endAdornment={
              <Grid container spacing={0} direction="row" wrap="nowrap" alignItems="center" onClick={handlePopoverOpen}>
                <Grid item>
                  <span className={classes.currency}>€</span>
                </Grid>
                <Grid item>{product?.priceType ? t("orders.PriceSelector.typeShort." + product.priceType) : ""}</Grid>
                <Grid item>
                  {open ? (
                    <DropDownIcon className={classes.rotateIcon + " " + classes.dropicon} />
                  ) : (
                    <DropDownIcon className={classes.dropicon} />
                  )}
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </Grid>
      {priceOptions && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handlePopoverClose}
          className={classes.accountMenuPopover}
        >
          <List>
            {priceOptions.map((item, index) => (
              <ListItem
                selected={item?.priceType === (product?.priceType || "S")}
                button
                key={"opt_" + index}
                onClick={() => onPriceSelected(item)}
              >
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item>
                    <b>{t("orders.PriceSelector.type." + item.priceType)}</b>
                  </Grid>
                  <Grid item>{currencyFormat(item.price)}</Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </Popover>
      )}
    </div>
  );
}

export default withStyles(PriceSelector, styles)

const getPriceOptions = (product, allowClientInput) => {
  let options = [];
  options.push({
    priceType: "S",
    price: product.retailPrice < product.salePrice ? product.retailPrice : product.salePrice,
  });
  if (allowClientInput) {

    options.push({
      priceType: "C",
      price: getProductPrice(product),
    });
  }
  let campaignPrice = getPriceWithCampaigns(product);
  if (campaignPrice) {
    options.push({
      priceType: "CMP",
      price: campaignPrice,
    });
  }
  return options;
};

const getDefaultSelected = (options, product) => {
  let fOptions = options.filter((item) => item.priceType !== "C").sort((a, b) => (a.price < b.price ? -1 : 1));

  let option = options.find((item) => item.priceType === product?.priceType);
  if (!option && fOptions?.length > 0) {
    option = fOptions[0];
  }
  // return product.price ? { ...option, price: product.price } : option; 
  // not sure why was this, but was preventing the price update when promotion quantity was unmet
  return option;
};

import {call} from "../../../app/websocket/_sliceCalls/websocket";
import {getPromoterConfig, requestHelper} from "../../../app/coreSlice";
import {getImageUrlBase64} from "../../../api/s3";

const taskListCommentsCall = async (
  { clearTaskListComments, setTaskListCommentsFilter, appendToTaskListComments, setTaskListCommentsTotalRows },
  dispatch,
  { filter, clear },
  state
) => {
  let rh = requestHelper(dispatch, "LIST_COMMENT");
  if (clear) {
    dispatch(clearTaskListComments());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setTaskListCommentsFilter(filter));
  try {
    let result = await call({
      type: "TASK",
      subType: "LIST_COMMENT",
      request: filter,
    });
    if (result && result.items && result.items.length > 0) {
      let items = [];
      const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
      const promoterCode = state.core.promoterDetails?.details?.code;
      for (let i = 0; i < result.items.length; i++) {
        let item = result.items[i];
         items.push({ ...item, logo: (item ? await fromUserAssignee(fileStorageConfigs, promoterCode, item) : undefined) });
      }
      dispatch(appendToTaskListComments(items));
      dispatch(setTaskListCommentsTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("tasks", ex);
  }
  rh.close();
};

export default taskListCommentsCall;

export const fromUserAssignee = async (filestorageConfigs, promoterCode, contact) => {
  let logo = "";
  try {
    logo = await getImageUrlBase64(filestorageConfigs?.provider, filestorageConfigs?.privateBucket, contact.avatar?.path);
  } catch (ex) {
    logo = "";
  }
  return logo;
};
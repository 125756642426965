import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const rolesGetCall = async (
  { setRoleDetails },
  dispatch,
  { roleID }
) => {
  let rh = requestHelper(dispatch, "ROLES_GET");

  try {
    let result = await call({
      type: "ROLES",
      subType: "GET",
      request: {
        roleID
      },
    });
    if (result) {
      dispatch(setRoleDetails(result?.role));
    }
  } catch (ex) {
    rh.error("roles", ex);
  }
  rh.close();
};

export default rolesGetCall;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Grid, Stack } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { styles } from "./BusinessUnitList.styles";
import BusinessUnitCard from "./BusinessUnitCard/BusinessUnitCard";

import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import Permission from "../../../app/components/Permission";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { selectLoader } from "../../../app/coreSlice";
import {
  businessUnitDelete,
  businessUnitDisable,
  businessUnitEnable,
  businessUnitList,
  selectBusinessUnitList,
} from "../businessUnitsSlice";

function BusinessUnitList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(selectBusinessUnitList);
  const loading = useSelector(selectLoader);

  useFirstLoad(() => {
    dispatch(businessUnitList());
  })

  const onAddHandler = () => {
    navigate("/business/units/create")
  }

  const onRemoveHandler = (row) => {
    dispatch(businessUnitDelete({ id: row.id }));
  }

  const onDisableHandler = (row) => {
    dispatch(businessUnitDisable({ id: row.id }));
  }
  const onEnableHandler = (row) => {
    dispatch(businessUnitEnable({ id: row.id }));
  }

  return (
    <Page
      permission={"promoter.actions.businessUnit.list"}
      header={<PageTitle
        title={t("businessUnits.BusinessUnitList.header.title")}
        actions={
          <Grid container direction="row" spacing={2}>
            <Permission code={"promoter.actions.businessUnit.create"}>
              <Grid item>
                <Button variant="outlined" size="large" onClick={onAddHandler} disabled={loading}><AddIcon fontSize="inherit" /></Button>
              </Grid>
            </Permission>
          </Grid>
        }
      />}>
      <Stack spacing={1} className={classes.root}>
        <Grid container spacing={2}>
          {(data || []).map((unit, index) => (
            <Grid item key={"bu_" + index} xs={12} sm={6} md={6} lg={6} xl={4}>
              <BusinessUnitCard unit={unit} onRemove={onRemoveHandler} onDisable={onDisableHandler} onEnable={onEnableHandler} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Page>
  );
}

export default withStyles(BusinessUnitList, styles);


import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const businessUnitGetStepTaskCall = async (
  { setTaskDetails },
  dispatch,
  { id }
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_GET_TASK");
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "GET_TASK",
      request: {
        id
      },
    });

    dispatch(setTaskDetails({
      ...result?.businessUnitTask,
      associationType: result?.businessUnitTask?.users?.length > 0 ? "USER" :
        result?.businessUnitTask?.groups?.length > 0 ? "GROUP" :
          result?.businessUnitTask?.sectors?.length > 0 ? "SECTOR" :
            undefined
    }))

  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitGetStepTaskCall;


export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(3),
  },
  noMargin: {
    margin: 0
  }
});
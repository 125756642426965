import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const productCreateFamilyCall = async ({ productListFamily, setSelectedFamilyId }, dispatch, state, { id, description, type, parent }) => {
  let filter = state.productsFamilies.familyListFilter;
  let rh = requestHelper(dispatch, "CREATE_FAMILY");

  try {
    let result = await call({
      type: "PRODUCT",
      subType: "CREATE_FAMILY",
      request: {
        id,
        description: JSON.stringify(description),
        type,
        parent,
      },
    });
    setTimeout(() => {
      dispatch(productListFamily(filter, true));
      dispatch(setSuccess("productsFamilies", "PRODUCT_CREATE_FAMILY"));
      dispatch(setSelectedFamilyId(result?.productFamilyId))
    }, 300);
  } catch (ex) {
    rh.error("productsFamilies", ex);
  }
  rh.close();
};

export default productCreateFamilyCall;
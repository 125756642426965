import DateTime from "../../../../common/displays/DateTime/DateTime";

export function listColumns(t, classes) {
  return [
    {
      field: "orderId",
      headerName: t("ordersIncidents.OrdersIncidentsList.columns.orderId"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "code",
      headerName: t("ordersIncidents.OrdersIncidentsList.columns.code"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "createdTime",
      headerName: t("ordersIncidents.OrdersIncidentsList.columns.createdTime"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return <DateTime date={v.createdTime} />;
      },
    },
    {
      field: "deliveryTime",
      headerName: t("ordersIncidents.OrdersIncidentsList.columns.deliveryTime"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return <DateTime date={v.deliveryTime} />;
      },
    },
    {
      field: "document",
      headerName: t("ordersIncidents.OrdersIncidentsList.columns.document"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
  ];
}

// {
//   code: "MISSING",
//   createdTime: "2022-07-08T11:07:11.121488",
//   deliveryTime: "2022-07-05T10:50:45",
//   document: "DocumentTest",
//   orderId: 134,
//   products: [
//     description: "FILETE DE PESCADA ÁFRICA DO SUL 400G\t",
//     gtin: "05601718201717",
//     image: "https://gelpeixe.pt/assets/site/uploads/products/pt/20190123175860.png",
//     incidentQuantity: 5,
//     orderQuantity: 50,
//     productBatches: "[{\"batch\": \"test\", \"expirationDate\": \"2022-08-08\"}]",
//     productId: "e22b9a3a-ffec-4204-9d4f-834064f4d304",
//     thumbnail: "https://gelpeixe.pt/assets/site/uploads/products/pt/20190123175860.png"
//   ]
// }

export const styles = (theme) => ({
  root: {
    minHeight: 42,
    "& > .MuiGrid-root": {
      minHeight: 42,
    },
  },
  added: {
    color: theme.palette.success.main,
    backgroundColor: "#cff3f1",
  },
  removed: {
    color: theme.palette.error.main,
    backgroundColor: "#fad4d4",
    textDecoration: "line-through",
  },
  hasDiff: {
    backgroundColor: "#e8f9f8",
  },
});

import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";

import { styles } from "./AcceptProductCreateButton.styles";
import { formSchema, formFields } from "./AcceptProductCreateButton.schema";

import { usePromoter, useUser } from "../../../../common/hooks";
import { FormFields, Form } from "../../../../common/forms";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";
import { setCheckInternalCodeResult } from "../../productsSlice";
import { useDispatch } from "react-redux";

function AcceptProductCreateButton({ classes, onAccept, loading }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const promoter = usePromoter();
  const user = useUser();
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];
  const userType = user?.entity?.type;
  const showInternalCode = enabledProductFields.includes("productTemplate") && userType === "P";
  const [modal, setModal] = useState(false);
  const height = 360;

  const onSubmitHandler = async (values) => {
    onAccept && onAccept(values.productTemplate ? values.productTemplate.value : "", values.comment);
    setModal(false);
  }

  const acceptHandler = () => {
    dispatch(setCheckInternalCodeResult());
    setModal(true)
  }

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        type="button"
        disabled={loading}
        onClick={acceptHandler}
      >
        {t("products.ProductDetails.header.btnAccept")}
      </Button>
      <CustomModal open={modal} setOpen={setModal} size="large" height={height} title={t("products.ProductDetails.modalAccept.title")}>

        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t, showInternalCode })}
          initialValues={{}}
          render={() => {
            return (
              <Grid container direction="column" spacing={2} justifyContent="space-between" style={{ height: height - 64 }}>
                <Grid item>
                  <FormFields fields={formFields({ t, loading, showInternalCode })} />
                </Grid>
                <Grid item alignSelf="flex-end">
                  <Button variant="contained" color="primary" type="submit">{t("products.ProductDetails.modalAccept.btnSubmit")}</Button>
                </Grid>
              </Grid>
            )
          }}
        />
      </CustomModal>
    </div>
  );
}

export default withStyles(AcceptProductCreateButton, styles);
import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid } from "@mui/material";
import { styles } from "./ProductSnip.styles";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import IconWithTooltip from "../../components/displays/IconWithTooltip";
import { useTranslation } from "react-i18next";
import ProductImage from "./ProductImage/ProductImage";
import { useUser } from "../../hooks";

function ProductSnip({ classes, image, description, gtin, deliveryIncidents, ownerName, promoterInternalCode }) {
  const { t, i18n } = useTranslation();
  const user = useUser();
  let secondary = "";

  let productCodes = [];
  if (ownerName) {
    productCodes.push(ownerName);
  }
  if (gtin) {
    productCodes.push(gtin);
  }
  if (promoterInternalCode && user?.isPromoter) {
    productCodes.push(promoterInternalCode);
  }
  secondary = productCodes?.length > 0 ? productCodes.join(" | ") : "-"

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center" wrap="nowrap" >
        <Grid
          item
          style={{
            width: 60,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ProductImage image={image} alt={description} />
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography className={classes.nomargin}>{description}</Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" className={classes.nomargin}>
                {secondary}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {deliveryIncidents?.length > 0 && (
          <Grid item>
            <IconWithTooltip
              placement="right"
              icon={
                <div className={classes.incidentIcon}>
                  <WarningAmberIcon />
                </div>
              }
            >
              <Grid container direction="column">
                {deliveryIncidents.map((incident, index) => (
                  <Grid item key={`incident_${index}`}>
                    <Typography component="h6" variant="caption" color="textPrimary">
                      {i18n.exists("common.Enums.deliveryIncident." + incident)
                        ? t("common.Enums.deliveryIncident." + incident)
                        : incident}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </IconWithTooltip>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default withStyles(ProductSnip, styles)

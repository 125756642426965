export const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: "3px 0 0 3px",
    backgroundColor: theme.palette.primary.back,
    borderLeft: "4px solid",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(1)
  },
  primary: {
    borderColor: theme.palette.primary.main + " !important",
    backgroundColor: theme.palette.primary.back + " !important",
  },
  secondary: {
    borderColor: theme.palette.secondary.main + " !important",
    backgroundColor: theme.palette.secondary.back + " !important",
  },
  warning: {
    borderColor: theme.palette.warning.main + " !important",
    backgroundColor: theme.palette.warning.back + " !important",
  },
  error: {
    borderColor: theme.palette.error.main + " !important",
    backgroundColor: theme.palette.error.back + " !important",
  },
  info: {
    borderColor: theme.palette.info.main + " !important",
    backgroundColor: theme.palette.info.back + " !important",
  },
  success: {
    borderColor: theme.palette.success.main + " !important",
    backgroundColor: theme.palette.success.back + " !important",
  },
});
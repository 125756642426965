const roles = {
  flowsMessages: {
    success: {
      ROLES_UPDATE: "Role updated successfully!",
      ROLES_CREATE: "Role created successfully!",
      ROLES_DELETE: "Role removed successfully!",
    },
    error: {
      GENERIC: "Something went wrong",
    },
  },
  enum: {
    associationType: {
      NONE: "None",
      USER: "Users",
      GROUP: "Groups",
      SECTOR: "Sectors"
    }
  },
  RolesList: {
    header: {
      title: "Roles",
      filterBtn: "Filter",
      defaultRolesBtn: "Default Roles"
    },
    filter: {
      entityType: "Entity Type",
      roleType: "Role Type",
      entity: "Entity",
    },
    warn: {
      noEntity: "Please select an entity to view custom roles",
      noCustomRoles: "This entity has no custom roles"
    },
    columns: {
      entityName: "Entity Name",
      entityType: "Entity Type",
      roleType: "Role Type",
      description: "Description",
      createdBy: "Created By",
      createdTime: "Created Time",
      updatedBy: "Updated By",
      updatedTime: "Updated Time",
      editBtn: "Edit",
      deleteBtn: "Delete entity role",
      confirmDeleteBtn: "Confirm delete",
      deleteTitle: "Delete entity role",
      deleteMessage: "You will delete the entity role, this entity will use the default Role instead, are you sure?",
      defaultRoleMessage: "Using the default role permissions for this role and entity type",
      editDefaultBtn: "Edit default role",
      createBtn: "Create entity role",
      designation: "Designation",
      association: "Association"
    },
    actions: {
      edit: "Edit",
      delete: "Delete"
    }
  },
  RolesDefaultList: {
    header: {
      title: "Default Roles",
    }
  },
  ConfirmRemoveModal: {
    modal: {
      title: "Confirm Removal",
      message: "Are you sure you want to remove this role?",
      confirmBtn: "Confirm",
      replace: "Replacement Role",
    }
  },
  RolesForm: {
    header: {
      submitBtn: "Submit",
    },
    form: {
      dataTitle: "Role",
      entityName: "Entity Name",
      entityType: "Entity Type",
      roleType: "Role Type",
      description: "Description",
      permissions: "Permissions",
      requiredDescription: "Description is required",
      entity: "Entity",
      designation: "Designation",
      designationRequired: "Designation is required",
      associationType: "Association",
      users: "Users",
      groups: "Groups",
      sector: "Sectors",
      userRequired: "At least one user is required",
      groupRequired: "At least one group is required",
      sectorRequired: "At least one sector is required",
      selectAll: "Select All",

      menu_home: "Home",
      menu_home_available: "Available",

      menu_order: "Order",
      menu_order_available: "Order available",
      menu_order_list: "List",
      menu_order_list_all: "List all",
      menu_order_list_own: "List own",
      menu_order_list_available: "List available",
      menu_order_list_placed: "List placed",
      menu_order_list_missing: "List missing",
      menu_order_list_operator: "List operator",
      menu_order_list_received: "List received",
      menu_order_list_incidents: "List incidents",
      menu_order_view: "View",
      menu_order_view_all: "View all",
      menu_order_view_own: "View own",
      menu_order_view_available: "View available",
      menu_order_update: "Update",
      menu_order_update_all: "Update all",
      menu_order_update_assign: "Update assign",
      menu_order_update_delivery: "Update delivery",
      menu_order_update_dispatch: "Update dispatch",
      menu_order_update_available: "Update available",
      menu_order_update_reception: "Update reception",
      menu_order_update_acceptance: "Update acceptance",
      menu_order_update_in_transit: "Update in transit",
      menu_order_update_confirmation: "Update confirmation",
      menu_order_update_confirmation_delivery: "Update confirm delivery",
      menu_order_creation: "Creation",
      menu_order_creation_available: "Creation available",

      menu_config: "Configuration",
      menu_config_available: "Configuration available",
      menu_config_i18n: "I18N",
      menu_config_i18n_available: "I18N available",
      menu_config_view: "View",
      menu_config_view_available: "View available",
      menu_config_roles: "Roles",
      menu_config_roles_available: "Roles available",
      menu_config_timer: "Timer",
      menu_config_timer_available: "Timer available",
      menu_config_update: "Update",
      menu_config_update_available: "Update available",
      menu_config_document: "Document",
      menu_config_document_available: "Document available",
      menu_config_notification: "Notification",
      menu_config_notification_available: "Notification available",

      menu_entity: "Entity",
      menu_entity_available: "Entity available",
      menu_entity_list: "Entity list",
      menu_entity_list_own: "Entity list own",
      menu_entity_list_type: "Entity list type",
      menu_entity_list_available: "Entity list available",
      menu_entity_list_onlyCatalog: "Entity list only catalog",
      menu_entity_view: "Entity view",
      menu_entity_view_own: "Entity view own",
      menu_entity_view_type: "Entity view type",
      menu_entity_view_available: "Entity view available",
      menu_entity_update: "Entity update",
      menu_entity_update_all: "Entity update all",
      menu_entity_update_own: "Entity update own",
      menu_entity_update_entity: "Entity update",
      menu_entity_update_sector: "Entity update sector",
      menu_entity_update_address: "Entity update address",
      menu_entity_update_contact: "Entity update contact",
      menu_entity_update_available: "Entity update available",
      menu_entity_creation: "Entity creation",
      menu_entity_creation_available: "Entity creation available",
      menu_entity_creation_type: "Entity creation type",
      menu_entity_creation_sector: "Entity creation sector",
      menu_entity_creation_user: "Entity creation user",
      menu_entity_creation_group: "Entity creation group",
      menu_entity_creation_entity: "Entity creation entity",
      menu_entity_creation_address: "Entity creation address",
      menu_entity_creation_contact: "Entity creation contact",

      menu_product: "Product",
      menu_product_available: "Product available",
      menu_product_list: "Product list",
      menu_product_list_all: "Product list all",
      menu_product_list_own: "Product list own",
      menu_product_list_price: "Product list price",
      menu_product_list_available: "Product list available",
      menu_product_view: "Product view",
      menu_product_view_own: "Product view own",
      menu_product_view_all: "Product view all",
      menu_product_view_available: "Product view available",
      menu_product_update: "Product update",
      menu_product_update_all: "Product update all",
      menu_product_update_own: "Product update own",
      menu_product_update_catalog: "Product update catalog",
      menu_product_update_available: "Product update available",
      menu_product_update_price: "Product update price",
      menu_product_update_campaign: "Product update campaign",
      menu_product_creation: "Product creation",
      menu_product_creation_price: "Product creation price",
      menu_product_creation_catalog: "Product creation catalog",
      menu_product_creation_campaign: "Product creation campaign",
      menu_product_creation_available: "Product creation available",

      menu_business: "Business",
      menu_business_available: "Business available",
      menu_business_view: "Business view",
      menu_business_view_audit: "Business view audit",
      menu_business_view_history: "Business view history",
      menu_business_view_queries: "Business view queries",
      menu_business_view_reports: "Business view reports",
      menu_business_view_accounting: "Business view accounting",
      menu_business_creation: "Business creation",
      menu_business_creation_accounting: "Business creation accounting"
    },
    option: {
      true: "yes",
      false: "no",
    },
  },
};

export default roles;

import React from "react";
import * as Yup from "yup";
import { MultiSelectField, NumberField, SelectField } from "../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    code: Yup.string().required(t("promoter.RefDataTaxsForm.form.requiredCode")),
    value: Yup.string().required(t("promoter.RefDataTaxsForm.form.requiredValue")),
  });
}

export function formFields({ t, vatOptions, locationsOptions, values, vatAssignedLocations, isEdit }) {
  let filteredLocationsOptions = locationsOptions.map(item => {
    return {
      ...item,
      disabled: !values?.code || (vatAssignedLocations?.[values.code] || []).some(a => a === item.value)
    }
  })

  let fields = [
    {
      size: 4,
      field: (
        <SelectField
          id="code"
          type="text"
          label={t("promoter.RefDataTaxsForm.form.code")}
          required
          options={vatOptions}
          disabled={isEdit}
        />
      ),
    },
    {
      size: 4,
      field: <NumberField id="value" decimalScale={2} label={t("promoter.RefDataTaxsForm.form.value")} required />,
    },
    {
      size: 4,
      field: (
        <MultiSelectField
          id="locations"
          type="text"
          label={t("promoter.RefDataTaxsForm.form.locations")}
          options={filteredLocationsOptions}
        />
      ),
    },
  ];
  return fields;
}

import {call} from "../../../app/websocket/_sliceCalls/websocket";
import {requestHelper, setRedirect, setSuccess} from "../../../app/coreSlice";
import {placeList} from "../placesSlice";

const placeDisableCall = async (
  dispatch,
  { id },
  state
) => {
  let filter = state.places.placeListFilter;
  let rh = requestHelper(dispatch, "PLACE_ENABLE");
  try {
    let result = await call({
      type: "PLACE",
      subType: "ENABLE",
      request: {
        id
      },
    });

    setTimeout(() => {
      if (result && result.enabled) {
        dispatch(setRedirect("/places"));
        dispatch(setSuccess("places", "PLACE_ENABLE"));
        dispatch(placeList(filter, true))
      }
    }, 300);
    
  } catch (ex) {
    rh.error("places", ex);
  }
  rh.close();
};

export default placeDisableCall;


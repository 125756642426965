const common = {
  Navigation: {
    menu: {
      home: "Home",
      tasks: "Tasks",
      tasksList: "Task List",
      entities: "Entities",
      entitiesList: "Entities",
      administration: "Administration",
      entitiesCreate: "Create Entity",
      entitiesGroups: "Groups",
      cutOffs: "Cut Offs",
      orders: "Orders",
      ordersSummary: "Summary",
      ordersPlaced: "Placed",
      ordersReceived: "Received",
      ordersCreate: "New order",
      ordersOperator: "Operator Orders",
      products: "Products",
      productsList: "Products",
      productsCatalogs: "Catalogs",
      productsCampaigns: "Campaigns",
      productsPrices: "Prices",
      productsPricesValidation: "Validate Prices",
      productsPending: "Validate Products",
      activity: "Activity",
      activityHistory: "History",
      activityQueries: "Queries",
      activityReports: "Reports",
      activityAudit: "Audit",
      activityAccounting: "Accounting",
      activityProcess: "Processes List",
      activityTimer: "Timer List",
      systemStatus: "Status",
      business: "Business",
      ordersMissingProducts: "Missing Products",
      families: "Families",
      places: "Places",
      resources: "Resources",
      ordersIncidents: "Incidents",
      productsPurchases: "Purchases",
      entitiesValidate: "Validate Entities",
      dproms: "dProms",
      dpromsValidation: "Validate dProms",
      promoter: "Promoter",
      roles: "Roles",
      i18n: "I18n",
      documents: "Documents",
      emails: "Emails",
      productsExtraPrices: "Extra Prices",
      taxes: "Taxes",
      taxesValidation: "Validate taxes",
      planning: "Planning",
      businessUnits: "Business Units"
    },
    account: {
      logout: "Logout",
      settings: "Settings",
      promoter: "Promoter"
    },
  },
  Enums: {
    language: {
      en: "English",
      es: "Spanish",
      pt: "Portuguese",
    },
    currency: {
      EUR: "EURO",
    },
    entityType: {
      P: "Promoter",
      S: "Supplier",
      W: "Wholesaler",
      LO: "Logistic operator",
      ST: "Store",
      ALL: "All",
    },
    roleType: {
      F: "Full access",
      N: "Notifications only",
      R: "Read only",
      A: "Admin",
      W: "Write",
      C: "Custom",
      ALL: "All",
    },
    trafficType: {
      S: "Supplier deliver Operator",
      O: "Operator retrives Supplier",
    },
    operationType: {
      TF: "Tense Flow",
      CD: "Cross Docking",
    },
    frequencyType: {
      A: "Annual",
      S: "Semiannual",
      Q: "Quarterly",
      M: "Monthly",
      W: "Weekly",
      D: "Daily",
    },
    feeType: {
      O: "Order",
      I: "Invoice",
    },
    weekday: {
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
      7: "Sunday",
    },
    month: {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December",
    },
    orderStatus: {
      CR: "Created",
      CO: "Confirmed",
      AC: "Accepted",
      DI: "Dispatched",
      RE: "Reception",
      IT: "In transit",
      DE: "Delivered",
      CD: "Delivered confirmed",
      IN: "Delivered with incident",
      CA: "Canceled",
    },
    orderActionStatus: {
      DI: "To dispatch",
      RE: "To receive",
      IT: "To set in transit",
      DE: "To deliver",
    },
    placeTypes: {
      B: "Building",
      R: "Room",
      A: "Area",
    },
    productAvailable: {
      N: "Unavailable",
      S: "Only Ship",
      O: "Order and ship",
    },
    productActive: {
      true: "Active",
      false: "Inactive",
    },
    resourceTypes: {
      S: "Sieve",
      T: "Tank",
      C: "Cylinder"
    },
    resourceStates: {
      I: "In Use",
      A: "Available",
      U: "Unavailable",
      M: "Maintenance",
    },
    tax: {
      VAT23: "IVA 23%",
      VAT13: "IVA 13%",
      VAT6: "IVA 6%",
      VAT0: "No Tax",
    },
    temperature: {
      CHILLED: "Chilled",
      FROZEN: "Frozen",
      ROOM: "Room",
    },
    transport: {
      BASE_UNIT_OR_EACH: "Base Unit or Each",
      CASE: "Case",
      DISPLAY_SHIPPER: "Display Shipper",
      MIXED_MODULE: "Mixed Module",
      PACK_OR_INNER_PACK: "Pack or Inner Pack",
      PALLET: "Pallet",
      TRANSPORT_LOAD: "Transport Load",
      BULK: "Bulk"
    },
    transportShort: {
      BASE_UNIT_OR_EACH: "UN",
      CASE: "CS",
      DISPLAY_SHIPPER: "DS",
      MIXED_MODULE: "MM",
      PACK_OR_INNER_PACK: "PK",
      PALLET: "PA",
      TRANSPORT_LOAD: "TL",
    },
    campaignType: {
      PR: "Promotion",
      PD: "Percentage Discount",
      MB: "Merchandise Bonuses",
      BU: "Bundle",
    },
    campaignStatus: {
      0: "Running",
      1: "Waiting",
      2: "Ended",
      3: "Disabled",
    },
    measureUnits: {
      MTR: "m",
      CMT: "cm",
      MMT: "mm",
      TNE: "Tn",
      KGM: "Kg",
      GRM: "g",
      LTR: "l",
      CLT: "cl",
      MLT: "ml",
      H87: "piece",
    },
    entityPermission: {
      EC: "Entity Choice",
      AL: "Allow",
      NA: "Not Allow",
    },
    priceType: {
      S: "Standard",
      G: "Group",
      C: "Client",
      CMP: "Campaign",
      O: "Offer",
      W: "Warehouse",
      D: "Direct"
    },
    taxGroup: {
      ACT: "Alcohol",
      ENV: "Environmental",
      EXC: "Special consumption",
      GST: "Goods and services",
      IMP: "Import",
      VAT: "Value added",
      AAD: "Tobacco",
      CAR: "Car",
      OIL: "Petroleum products",
      OTH: "Other",
      ALL: "All",
    },
    incidentCode: {
      MISSING: "MISSING",
      DAMAGED: "DAMAGED",
      EXCESS: "EXCESS",
      NON_CONFORMING_GOODS: "NON_CONFORMING_GOODS",
      GOODS_WITHOUT_DOCUMENTATION: "GOODS_WITHOUT_DOCUMENTATION",
      INVOICING: "INVOICING",
    },
    incidentSearchType: {
      ORDERID: "ORDERID",
      DELIVERYTIME: "DELIVERYTIME",
      CREATEDTIME: "CREATEDTIME",
      PRODUCT: "PRODUCT",
    },
    channelType: {
      P: "PORTAL",
      S: "SYSTEM",
      C: "CATALOG",
      A: "MOBILE_APP",
      WS: "WEB_SERVICE",
      API: "API",
      AU: "AUDIT",
    },
    deliveryIncident: {
      I002: "Avaria no carro",
      I036: "Caixas danificadas",
      I032: "Cliente sem espaço na câmara",
      I033: "Cliente sem nota de pedido",
      I039: "Código de barras incorrecto",
      I038: "Conteúdo não coincide com a descrição",
      I029: "Data de validade próxima",
      I015: "Diferença no peso",
      I012: "Equipamento de frio avariado",
      I056: "Erro de gravação",
      I025: "Erro no cais",
      I003: "Erro picking",
      I028: "Falta à carga na STEF",
      I001: "Falta de tempo / Fora de hora",
      I060: "Falta na entrega",
      I016: "Fora de hora (hora não acordada)",
      I006: "Fora de rota",
      I011: "Fora do horário comercial",
      I007: "Mal gravado",
      I25E: "Mercadoria já entregue anteriormente",
      I023: "Mercadoria mal entregue a 1ª vez",
      I034: "Morada incorreta",
      I010: "Não gosta do produto",
      I013: "Não paga",
      I008: "Não pedido pelo cliente",
      I017: "Operação cross-docking tardia",
      I031: "Produto não lido pelo scanner",
      I014: "Vários",
    },
    documentType: {
      INV: "Invoice",
      WYB: "Way Bill",
      DTS: "Datasheet",
      CTN: "Credit Note",
      DTN: "Debit Note",
      ASN: "ASN",
      HCP: "HACCP",
      TCB: "Traceability Documents",
      LGS: "Logistics Sheet",
      PTP: "Product Presentation",
      CTF: "Certificates",
      CFD: "Conformity Declaration",
      TSS: "Technical Security Sheet",
      OTR: "Other",
    },
    documentMetadata: {
      DSC: "Description",
      NME: "Name",
      EXD: "Expiration Date",
      RCD: "Reception Date",
      ADD: "Added by",
      DVS: "Document Version",
      APV: "Approved by",
    },
    sector: {
      PCH: "Purchases",
      SL: "Sales",
      QCL: "Quality Control",
      CBY: "Contability",
      IT: "Information Technology",
      PVS: "Provisioning",
      LG: "Legal",
      ADM: "Administrator",
      WAH: "Warehouse",
      RCP: "Reception",
      HRS: "Human Resources",
      MKT: "Marketing"
    },
    priceActionStatus: {
      PENDING: "Request",
      REFUSED: "Refused",
      ACCEPTED: "Accepted",
      PROPOSAL: "Proposal",
      EXECUTED: "Executed"
    },
    productWorkflow: {
      PENDING: "Pending",
      VALIDATING: "Waiting Validation",
      REFUSED: "Refused",
      ACCEPTED: "Accepted",
      NOT_OK: "Not ok",
      NOT_VALIDATED: "Not valid",
      RECOVERED: "Recovered"
    },
    entityWorkflow: {
      PENDING: "Pending",
      VALIDATING: "Waiting Validation",
      REFUSED: "Refused",
      ACCEPTED: "Accepted",
      NOT_OK: "Not ok",
      NOT_VALIDATED: "Not valid",
      RECOVERED: "Recovered"
    },
    measureCategory: {
      MASS: "Mass",
      COMP: "Length",
      VOLU: "Volume",
      OUTR: "Other",
    },
    packagingType: {
      PRIMARY: "PRIMARY",
      SECONDARY: "SECONDARY",
      TERTIARY: "TERTIARY",
      MULTIPACK: "MULTIPACK",
      MULTIPACK_PROMO: "MULTIPACK_PROMO",
    },
    perishable: {
      Y: "Yes",
      N: "No",
      O: "Other",
    },
    vat: {
      VAT_0: "No VAT",
      VAT_REDUX: "Reduced VAT",
      VAT_MED: "Intermediate VAT",
      VAT_NORMAL: "Standard VAT",
    },
    switch: {
      ON: "ON",
      OFF: "OFF"
    },
    packagingMaterialComponentType: {
      PET_PLASTIC: "PET PLASTIC",
      HDPE_PLASTIC: "HDPE PLASTIC",
      PVC_PLASTIC: "PVC PLASTIC",
      LDPE_PLASTIC: "LDPE PLASTIC",
      PP_PLASTIC: "PP PLASTIC",
      PS_PLASTIC: "PS PLASTIC",
      EPS_PLASTIC: "EPS PLASTIC",
      XPS_PLASTIC: "XPS PLASTIC",
      PLA_PLASTIC: "PLA PLASTIC",
      OTHER_PLASTIC_OF_BIOLOGICAL_ORIGIN: "OTHER PLASTIC OF BIOLOGICAL ORIGIN",
      OTHER_PLASTICS: "OTHER PLASTICS",
      UNCERTIFIED_PAPER_AND_CARD: "UNCERTIFIED PAPER AND CARD",
      CERTIFIED_PAPER_AND_CARDBOARD: "CERTIFIED PAPER AND CARDBOARD",
      UNCERTIFIED_WOOD: "UNCERTIFIED WOOD",
      CERTIFIED_WOOD: "CERTIFIED WOOD",
      STEEL: "STEEL",
      ALUMINUM: "ALUMINUM",
      ECAL: "ECAL",
      GLASS: "GLASS",
      OTHER_MATERIALS: "OTHER MATERIALS",
    },
    dpromType: {
      DIR: "Direct",
      WHS: "Warehouse"
    },
    dpromStatus: {
      ACC: "Accepted",
      REF: "Refused",
      PND: "Pending",
      CAN: "Canceled",
      PNDC: "Cancel Pending",
      NEW: "New",
      REM: "Remove",
      END: "Finished"
    },
    processType: {
      FlowCreateProduct: "FlowCreateProduct",
      FlowUpdateProduct: "FlowUpdateProduct",
      FlowPrice: "FlowPrice",
      FlowPurchase: "FlowPurchase",
      FlowRecoverProduct: "FlowRecoverProduct",
      FlowCreateEntity: "FlowCreateEntity",
      FlowUpdateEntity: "FlowUpdateEntity",
      FlowCreatePromoDiscount: "FlowCreatePromoDiscount",
      FlowRemovePromoDiscount: "FlowRemovePromoDiscount",
      FlowRemovePrice: "FlowRemovePrice",
      FlowTax: "FlowTax",
      FlowCreateTask: "FlowCreateTask",
      FlowUpdateTask: "FlowUpdateTask",
    },
    productProcessType: {
      FlowCreateProduct: "Create",
      FlowUpdateProduct: "Update",
      FlowRecoverProduct: "Recover",
    },
    entityProcessType: {
      FlowCreateEntity: "Create",
      FlowUpdateEntity: "Update",
    },
    productStatus: {
      ACC: "Accepted",
      DFT: "Draft",
      PND: "Pending",
      VAL: "Validating",
      REF: "Refused",
      NVL: "Not Validated",
    },
    priceStatus: {
      ACC: "Accepted",
      REF: "Refused",
      PND: "Pending",
      END: "Ended"
    },
    taxStatus: {
      ACC: "Accepted",
      REF: "Refused",
      PND: "Pending",
    },
    taskStatus: {
      PND: "Pending",
      CMP: "Completed",
    },
    batchOrderType: {
      B: "Blanket Order",
      E: "Client Order",
      T: "Transition Order",
      I: "Internal Order",
    },
    conditionVariable: {
      batch_start_date: "Batch start date",
      batch_quantity_distribution: "Batch quantity distribution"
    },
    taskInfoType: {
      text: "Text block",
      warning: "Warning block",
      batch_distribution: "Batch distribution",
    },
    taskInputType: {
      text: "Text",
      number: "Number",
      resource: "Group by resource",
      transfer: "Move resource"
    },
    scheduleType: {
      D: "Daily",
      W: "Weekly",
      O: "Once",
    }
  },
  ProductPicker: {
    columns: {
      description: "Name",
      brand: "Brand",
      ownerName: "Owner",
      price: "Price",
    },
    input: {
      editBtn: "Edit",
      emptytable: "No product selected",
      all: "All products selected",
    },
    modal: {
      title: "Select products",
      selectAll: "You have selected {{selected}} of {{total}} products, click here if you want to select all.",
      unselectAll: "All products are selected, click here to unlock selection",
      closeBtn: "Finish",
    },
  },
  EntityPicker: {
    columns: {
      name: "Name",
      taxNumber: "NIF",
    },
    input: {
      editBtn: "Edit",
      emptytable: "No entity selected",
    },
    modal: {
      title: "Select Entities",
      closeBtn: "Finish",
    },
  },
  EntityGroupSelector: {
    modal: {
      addMoreBtn: "Select Groups",
      title: "Select Groups",
      closeBtn: "Finish",
    },
  },
  QuantitySelect: {
    errors: {
      min: "Select at least {{min}} units",
      max: "Select no more than {{max}} units",
      multi: "Select a multiple of {{multi}} units",
    },
  },
  FilterForm: {
    button: {
      submit: "Filter",
      reset: "Reset",
    },
  },
  NotAvailablePage: {
    page: {
      message: "The page you're trying to access is no longer available or you don't have permissions",
      backBtn: "Go to home"
    }
  },
  DynamicForm: {
    schema: {
      requiredField: "Field is required"
    }
  },
  ReassignModal: {
    modal: {
      title: "Change assignee"
    },
    form: {
      btnSubmit: "Submit",
      usersRequired: "User required",
      groupsRequired: "Groups required",
      sectorsRequired: "Sectors required",
      associationType: "Association Type",
      users: "Users",
      groups: "Groups",
      sectors: "Sectors",
    }
  }
};

export default common;

import * as Yup from "yup";
import { DatePickerField, SelectField } from "../../../../../common/forms";
import { getAvailableOptions } from "../../../ProductForm/ProductForm.options";
import { ProductPickerField } from "../../../../../common/components/formFields";
import moment from "moment";

export function formSchema({ t }) {
  return Yup.object().shape({
    scheduleAvailable: Yup.string().required(t("products.ProductAvaibleState.form.requiredScheduleAvailable")),
    scheduleAvailableDate: Yup.date().nullable()
      .min(moment().add(1, "day").format("YYYY-MM-DDT00:00:00"), t('products.ProductAvaibleState.form.scheduleAvailableDateMin'))
  });
}

export function formFields({ t, values, productId, disabled }) {
  return [
    {
      size: 12,
      field: <SelectField
        id="scheduleAvailable"
        label={t("products.ProductAvaibleState.form.scheduleAvailable")}
        options={getAvailableOptions(t)}
        required
        disabled={disabled}
      />
    },
    {
      size: 12,
      field: (
        <DatePickerField
          id="scheduleAvailableDate"
          label={t("products.ProductAvaibleState.form.scheduleAvailableDate")}
          minDate={moment().add(1, "day")}
          disabled={disabled}
        />
      ),
    },
    {
      size: 12,
      field: (
        <ProductPickerField
          id="replacementProduct"
          label={t("products.ProductAvaibleState.form.replacementProduct")}
          single
          simple
          blackListedProducts={productId}
          disabled={disabled}
        />
      ),
      hidden: values.scheduleAvailable !== "N"
    },
  ];
}

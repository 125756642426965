import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { Typography, TextField, InputAdornment, Stack, Tooltip } from "@mui/material";

import { styles } from "./InternalCodeField.styles";
import MetaError from "../../../../common/forms/MetaError";
import { Field, useField } from "react-final-form";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { productCheckInternalCode, selectCheckInternalCodeResults, setCheckInternalCodeResult } from "../../productsSlice";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import CustomTooltip from "../../../../common/displays/CustomTooltip/CustomTooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from "react-i18next";

function InternalCodeField({ classes, id, label, required, disabled, note }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [state, setState] = useState('CHECK');
  const [internalCode, setInternalCode] = useState();
  const [product, setProduct] = useState();
  const result = useSelector(selectCheckInternalCodeResults);
  const field = useField(id);

  useEffect(() => {
    setProduct();
    if (result) {
      if (result.error) {
        setState("INVALID");
      }
      else if (result.description) {
        setState("VALID");
        setProduct(result);
        field.input.onChange({ value: internalCode, valid: true });
      }
      else {
        setState("CHECK");
      }
    }
    // eslint-disable-next-line
  }, [result])

  const onChangeHandler = (v) => {
    let trimedValue = (v || "").toString().trim();
    setInternalCode(trimedValue);
    field.input.onChange({ value: trimedValue, valid: !Boolean(v) });
    setState("CHECK");
  };

  const onCheckHandler = () => {
    if (internalCode) {
      if (internalCode === "0") {
        dispatch(setCheckInternalCodeResult({ description: t("products.ProductCreate.form.productTemplateNew"), internalCode }));
      }
      else {
        dispatch(productCheckInternalCode({ internalCode }));
      }
    }
  }

  const tooltip = product ? <ProductSnip image={product.thumbnail} description={product.description}  promoterInternalCode={product.promoterInternalCode} /> : null

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <div>
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="flex-end">
              {label && (
                <Typography
                  style={{ marginBottom: 8, marginTop: 8 }}
                  component="span"
                  variant="caption"
                  htmlFor={id}
                  color="textPrimary"
                  className={disabled ? classes.disabledLabel : ""}
                >
                  {label}
                  {required && <span> *</span>}
                </Typography>
              )}
              {note && <Tooltip title={note} placement="left"><InfoOutlinedIcon color="primary" className={classes.noteIcon} /></Tooltip>}
            </Stack>
            <CustomTooltip title={tooltip}>
              <TextField
                id={id}
                fullWidth
                type="text"
                className={classes.input + (disabled ? " " + classes.disabledInput : "")}
                error={Boolean((meta.error && meta.touched) || state === "INVALID")}
                onChange={(e) => onChangeHandler(e.target.value)}
                value={internalCode || (typeof input.value === 'object' ? input.value.value : input.value)}
                disabled={disabled}
                variant="outlined"
                onFocus={(e) => e.target && e.target.select && e.target.select()}
                size="small"
                InputProps={{
                  className: classes.adornment,
                  endAdornment:
                    <InputAdornment position="end">
                      {state === "INVALID" && <CloseIcon color="error" className={classes.statusIcon} />}
                      {state === "VALID" && <CheckIcon color="success" className={classes.statusIcon} />}
                      {state === "CHECK" && <CheckIcon onClick={onCheckHandler} className={classes.checkIcon} />}
                    </InputAdornment>
                  ,
                }}
              />
            </CustomTooltip>
            <div className={classes.error}>
              <MetaError meta={meta} />
            </div>
          </div>
        )}
      </Field>
    </div>
  );
}

export default withStyles(InternalCodeField, styles);
import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { styles } from "./ReportsList.styles";
import WeeklyUnitsDataGrid from "./WeeklyUnitsDataGrid/WeeklyUnitsDataGrid";
import WeeklyUnitsAcceptedDataGrid from "./WeeklyUnitsAcceptedDataGrid/WeeklyUnitsAcceptedDataGrid";
import WeeklyUnitsDeliveredDataGrid from "./WeeklyUnitsDeliveredDataGrid/WeeklyUnitsDeliveredDataGrid";
import SuppliersTabGroup from "./SuppliersTabGroup/SuppliersTabGroup";

import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { Grid } from "@mui/material";
import {
  businessReportSuppliers,
  businessReportWeeklyUnits,
  businessReportWeeklyUnitsAccepted,
  businessReportWeeklyUnitsDelivered,
  selectReportReportWeeklyUnitsAcceptedFilter,
  selectReportReportWeeklyUnitsAcceptedList,
  selectReportReportWeeklyUnitsAcceptedListTotalRows,
  selectReportReportWeeklyUnitsDeliveredFilter,
  selectReportReportWeeklyUnitsDeliveredList,
  selectReportReportWeeklyUnitsDeliveredListTotalRows,
  selectReportReportWeeklyUnitsFilter,
  selectReportReportWeeklyUnitsList,
  selectReportReportWeeklyUnitsListTotalRows,
  selectReportSuppliersCostByDeliveryList,
  selectReportSuppliersFilter,
  selectReportSuppliersList,
  selectReportSuppliersPlatformCostList,
  selectReportSuppliersTransportCostList
} from "../businessSlice";
import { SelectInput } from "../../../common/inputs";
import { getReportsOptions } from "../BusinessForm.options";

import Page from "../../../common/displays/Page/Page";
import { selectLoader } from "../../../app/coreSlice";
import {useUser} from "../../../common/hooks";

function ReportsList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();
  const [selectedReport, setSelectedReport] = useState("empty");

  const loading = useSelector(selectLoader);
  const filterWeeklyUnits = useSelector(selectReportReportWeeklyUnitsFilter);
  const dataWeeklyUnits = useSelector(selectReportReportWeeklyUnitsList);
  const totalRowsWeeklyUnits = useSelector(selectReportReportWeeklyUnitsListTotalRows);
  const filterWeeklyUnitsAccepted = useSelector(selectReportReportWeeklyUnitsAcceptedFilter);
  const dataWeeklyUnitsAccepted = useSelector(selectReportReportWeeklyUnitsAcceptedList);
  const totalRowsWeeklyUnitsAccepted = useSelector(selectReportReportWeeklyUnitsAcceptedListTotalRows);
  const filterWeeklyUnitsDelivered = useSelector(selectReportReportWeeklyUnitsDeliveredFilter);
  const dataWeeklyUnitsDelivered = useSelector(selectReportReportWeeklyUnitsDeliveredList);
  const totalRowsWeeklyUnitsDelivered = useSelector(selectReportReportWeeklyUnitsDeliveredListTotalRows);

  const dataSuppliers = useSelector(selectReportSuppliersList);
  const dataCostDelivery = useSelector(selectReportSuppliersCostByDeliveryList);
  const dataPlatformCost = useSelector(selectReportSuppliersPlatformCostList);
  const dataTransportCost = useSelector(selectReportSuppliersTransportCostList);
  const filterSuppliers = useSelector(selectReportSuppliersFilter);

  const onFilterChangeHandler = ({ filter, clear = true }) => {
    populateReport(selectedReport, filter, clear);
  };

  const populateReport = (reportName, filter, clear = true) => {
    switch (reportName) {
      case "report_suppliers":
        dispatch(businessReportSuppliers(filter, clear));
        break;
      case "report_weekly_units":
        dispatch(businessReportWeeklyUnits(filter, clear));
        break;
      case "report_weekly_units_accepted":
        dispatch(businessReportWeeklyUnitsAccepted(filter, clear));
        break;
      case "report_weekly_units_delivered":
        dispatch(businessReportWeeklyUnitsDelivered(filter, clear));
        break;
      default:
        return;
    }
  };

  const onLoadMoreWeeklyUnitsHandler = () => {
    if (dataWeeklyUnits.length < totalRowsWeeklyUnits && dataWeeklyUnits.length > 0 && !loading) {
      let nFilter = {
        filter: {
          ...filterWeeklyUnits,
          offset: dataWeeklyUnits.length,
        },
        reset: false,
      };
      populateReport(selectedReport, nFilter, false);
    }
  };

  const onLoadMoreWeeklyUnitsAcceptedHandler = () => {
    if (
      dataWeeklyUnitsAccepted.length < totalRowsWeeklyUnitsAccepted &&
      dataWeeklyUnitsAccepted.length > 0 &&
      !loading
    ) {
      let nFilter = {
        filter: {
          ...filterWeeklyUnitsAccepted,
          offset: dataWeeklyUnitsAccepted.length,
        },
        reset: false,
      };
      populateReport(selectedReport, nFilter, false);
    }
  };

  const onLoadMoreWeeklyUnitsDeliveredHandler = () => {
    if (
      dataWeeklyUnitsDelivered.length < totalRowsWeeklyUnitsDelivered &&
      dataWeeklyUnitsDelivered.length > 0 &&
      !loading
    ) {
      let nFilter = {
        filter: {
          ...filterWeeklyUnitsDelivered,
          offset: dataWeeklyUnitsDelivered.length,
        },
        reset: false,
      };
      populateReport(selectedReport, nFilter, false);
    }
  };

  const onReportOptionChange = (value) => {
    setSelectedReport(value);
    switch (value) {
      case "report_weekly_units":
        populateReport(value, filterWeeklyUnits);
        break;
      case "report_weekly_units_accepted":
        populateReport(value, filterWeeklyUnitsAccepted);
        break;
      case "report_weekly_units_delivered":
        populateReport(value, filterWeeklyUnitsDelivered);
        break;
      default:
        populateReport(value, filterSuppliers, true);
        break;
    }
  };


  let subtitle = " ";
  if (selectedReport === "report_suppliers") {
    subtitle = `${t("business.reports.filter.year")}: ${filterSuppliers?.year} | ${t("business.reports.filter.month")}: ${filterSuppliers?.month}`
  }
  if (selectedReport === "report_weekly_units") {
    subtitle = `${t("business.reports.filter.year")}: ${filterWeeklyUnits?.year} | ${t("business.reports.filter.week")}: ${filterWeeklyUnits?.week}`
  }
  if (selectedReport === "report_weekly_units_accepted") {
    subtitle = `${t("business.reports.filter.year")}: ${filterWeeklyUnitsAccepted?.year} | ${t("business.reports.filter.week")}: ${filterWeeklyUnitsAccepted?.week}`
  }
  if (selectedReport === "report_weekly_units_delivered") {
    subtitle = `${t("business.reports.filter.year")}: ${filterWeeklyUnitsDelivered?.year} | ${t("business.reports.filter.week")}: ${filterWeeklyUnitsDelivered?.week}`
  }

  return (
    <Page
      permission="business.actions.report.list"
      header={<PageTitle
        title={t("business.reports.header.title")}
        subtitle={subtitle}
      />}>

      <Grid container direction="row" spacing={2}>
        <Grid item xs={4}>
          <SelectInput
            className={classes.select}
            margin="none"
            variant="outlined"
            fullWidth
            size="small"
            value={selectedReport}
            label={t("business.reports.filter.report")}
            onChange={onReportOptionChange}
            required
            options={getReportsOptions(t, user)}
          />
        </Grid>

        <Grid item xs={12}>
          {/* report_suppliers */}
          {selectedReport === "report_suppliers" && (
            <SuppliersTabGroup
              dataSuppliers={dataSuppliers}
              dataCostDelivery={dataCostDelivery}
              dataPlatformCost={dataPlatformCost}
              dataTransportCost={dataTransportCost}
              filter={filterSuppliers}
              onFilterChange={onFilterChangeHandler}
            ></SuppliersTabGroup>
          )}
          {/* report_weekly_units */}
          {selectedReport === "report_weekly_units" && (
            <WeeklyUnitsDataGrid
              data={dataWeeklyUnits}
              totalRows={totalRowsWeeklyUnits}
              onLoadMore={onLoadMoreWeeklyUnitsHandler}
              filter={filterWeeklyUnits}
              onFilterChange={onFilterChangeHandler}
            />
          )}
          {/* report_weekly_units_accepted */}
          {selectedReport === "report_weekly_units_accepted" && (
            <WeeklyUnitsAcceptedDataGrid
              data={dataWeeklyUnitsAccepted}
              totalRows={totalRowsWeeklyUnitsAccepted}
              onLoadMore={onLoadMoreWeeklyUnitsAcceptedHandler}
              filter={filterWeeklyUnitsAccepted}
              onFilterChange={onFilterChangeHandler}
            />
          )}

          {/* report_weekly_units_delivered */}
          {selectedReport === "report_weekly_units_delivered" && (
            <WeeklyUnitsDeliveredDataGrid
              data={dataWeeklyUnitsDelivered}
              totalRows={totalRowsWeeklyUnitsDelivered}
              onLoadMore={onLoadMoreWeeklyUnitsDeliveredHandler}
              filter={filterWeeklyUnitsDelivered}
              onFilterChange={onFilterChangeHandler}
            />
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default withStyles(ReportsList, styles);

export const styles = (theme) => ({
  root: {
    "& .CdgHeader-actions": {
      width: 120
    },
    position: "relative",
  },
  statusAcc: {
    color: theme.palette.success.main,
  },
  statusPnd: {
    color: theme.palette.warning.main,
  },
  statusRef: {
    color: theme.palette.error.main,
  }
});
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { IconButton, Stack, Typography } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { styles } from "./ClamImageRecognition.styles";
import FileUploadInput from "../../../common/inputs/base/FileUploadInput";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { documentProcessImageRecognition, selectImageProcessingResult } from "../documentSlice";
import { selectLoader } from "../../../app/coreSlice";

function ClamImageRecognition({ classes }) {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoader);
  const imageProcessingResult = useSelector(selectImageProcessingResult);
  const [details, setDetails] = useState();

  const onFilesUploadedHandler = (files) => {
    setDetails();
    dispatch(documentProcessImageRecognition(files));
  }

  if (imageProcessingResult) {
    console.log('imageProcessingResult: %o', imageProcessingResult);
    console.log('---> %o', imageProcessingResult?.files?.[0]);
  }
  return (
    <Page header={<PageTitle title="Image Recognition" />}>
      <Stack spacing={2}>
        <FileUploadInput id="uploadClamImage" label="Upload images" multiple image onChange={onFilesUploadedHandler} disabled={loading} />
        {(imageProcessingResult?.items || [])?.length > 0 &&
          <>
            <Stack direction="row" spacing={8} alignItems="center">
              <h3>Summary</h3>
              {
                (imageProcessingResult?.files || []).length > 0 &&
                  imageProcessingResult.files.map((item, index) => (
                      item.indexOf('.csv') > 0 &&
                      <div key={`files_${index}`}>
                      <IconButton color="primary" size="small" href={item}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <FileDownloadIcon fontSize="inherit" />
                          <Typography>{(item.split('.csv',1)[0] || "").split("/OUT/",2)[1]} (csv)</Typography>
                        </Stack>
                      </IconButton>
                    </div>
                  ))
              }
            </Stack>
            <Stack>
              {imageProcessingResult.items.map(item => (
                <Stack key={item.imageName} direction="row" spacing={2} alignItems="center">
                  <Typography onClick={() => setDetails(item)} className={classes.name}>{item.imageName}</Typography>
                  <Typography>count: {item.count}</Typography>
                  <a href={item.outputImage} target="_blank" rel="noreferrer"><img alt={item.imageName + "_OUT"} src={item.outputImage} height="100" /></a>
                </Stack>
              ))}
            </Stack>
            {
              details &&
              <>
                <h3>Details {details?.imageName}</h3>
                <Stack direction="row" spacing={2} alignItems="flex-start">
                  <Stack style={{ minWidth: 240 }}>
                    {(details?.data || []).map(item => (
                      <Stack key={item.ID} direction="row" spacing={2} alignItems="center">
                        <Typography>{item.ID}</Typography>
                        <Typography>{`${parseFloat(item.Area).toFixed(2)} (${parseFloat(item.Comprimento).toFixed(2)}x${parseFloat(item.Largura).toFixed(2)})`}</Typography>
                        <Typography>{parseFloat(item.Proporcao).toFixed(2)}</Typography>

                      </Stack>
                    ))}
                  </Stack>
                  <div style={{ overflowX: "scroll" }}>
                    <img alt={details.imageName + "_details"} src={details.outputImage} />
                  </div>
                </Stack>
              </>
            }
          </>
        }
      </Stack>
    </Page>
  );
}

export default withStyles(ClamImageRecognition, styles);
import axios from 'axios';

const restAPI = axios.create({
  baseURL: process.env.REACT_APP_REST_API
});

export function userPasswordUpdate({ token, password }) {
  return restAPI.post("/user/password/update", { token, password });
}

export function userPasswordReset({ email }) {
  return restAPI.post("/user/password/reset", { email });
}
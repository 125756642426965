export const styles = theme => ({
  root: {
    "& .CdgRow": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: '#fdfdfd'
      }
    }
  },
  statusActive: {
    color: theme.palette.success.main,
  },
  statusPending: {
    color: theme.palette.warning.main,
  },
  statusDisabled: {
    color: theme.palette.error.main,
  }
});
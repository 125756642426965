
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const orderDispatchCall = async ({ orderAggregateDetails }, dispatch, {
  aggregateId,
  orderSellerId,
  document,
  documentDate,
  transportDocument,
  transportDocumentDate,
  products,
  volumes,
  fromMissingProducts,
}) => {

  let rh = requestHelper(dispatch, "DISPATCH_ORDER");
  try {
    await call({
      type: "ORDER",
      subType: "DISPATCH",
      request: {
        orders: [
          {
            orderSellerId,
            document,
            documentDate: documentDate.format("YYYY-MM-DDTHH:mm:ss"),
            transportDocument,
            transportDocumentDate: transportDocumentDate.format("YYYY-MM-DDTHH:mm:ss"),
            products: products
              .filter((product) => product.quantity > 0)
              .map((product) => {
                return {
                  productId: product.productId,
                  quantity: product.quantity,
                  orderId: product.orderId,
                  priceType: product.priceType,
                  productBatch: (product.productBatch || []).map(({ batch, quantity, weight, expirationDate }) => {
                    return {
                      quantity: quantity,
                      batch: batch ? batch : undefined,
                      weight: weight ? weight : undefined,
                      expirationDate: expirationDate ? expirationDate.format("YYYY-MM-DD") : undefined,
                    };
                  }),
                };
              }),
            volumes: volumes.map((volume) => {
              return {
                ...volume,
                products: (volume.products || [])
                  .filter((p) => p.quantity > 0)
                  .map(({ productId, orderId, quantity }) => {
                    return {
                      productId,
                      orderId,
                      quantity,
                    };
                  }),
              };
            }),
          },
        ],
      },
    });
    setTimeout(() => {
      dispatch(orderAggregateDetails(orderSellerId));
      if (fromMissingProducts) {
        dispatch(setRedirect(`/orders/missing-products/`));
      } else {
        dispatch(setRedirect(`/orders/received/${aggregateId}`));
      }
      dispatch(setSuccess("orders", "ORDER_DISPATCH"));
    }, 500);
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
};


export default orderDispatchCall;
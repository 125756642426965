import countries from "i18n-iso-countries";
import i18n from "i18next";

let inited;
const init = () => {
  countries.registerLocale(require("i18n-iso-countries/langs/" + i18n.language + ".json"));
  inited = i18n.language;
};

export const getCountryName = (code) => {
  if (inited !== i18n.language) {
    init();
  }
  return countries.getName(code, i18n.language);
}

export const getCountryCode = (name) => {
  if (inited !== i18n.language) {
    init();
  }
  return countries.getAlpha2Code(name, i18n.language);
}

export const getCountries = () => {
  if (inited !== i18n.language) {
    init();
  }
  let list = countries.getNames(i18n.language);
  return Object.keys(list).map((k) => {
    return {
      value: k,
      text: list[k],
    };
  });
}

export const getCountriesLowerCase = () => {
  if (inited !== i18n.language) {
    init();
  }
  let list = countries.getNames(i18n.language);
  return Object.keys(list).map((k) => {
    return {
      value: k.toLowerCase(),
      text: list[k],
    };
  });
}

export const getAllAlpha2CountryCode = () => {
  if (inited !== i18n.language) {
    init();
  }
  let list = countries.getNames(i18n.language === undefined ? "en" : i18n.language);
  return Object.keys(list).map((k) => {
    return k.toLowerCase();
  });
}
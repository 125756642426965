import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { styles } from "./VersionsDataGrid.styles";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import { listColumns } from "./VersionsDataGrid.columns";
import { Typography } from "@mui/material";

function VersionsDataGrid({ classes,
  currentVersion,
  data,
  totalRows,
  onRowSelected }) {
  const { t } = useTranslation();

  return (
    <div>
      <Typography component="span" variant="caption" color="textPrimary">
        {t("emails.EmailTemplate.versions.title")}
      </Typography>
      <div className={classes.root}>
        {data && (
          <CustomDataGrid
            localFilters
            columns={listColumns(t, classes, currentVersion)}
            rows={data}
            onRowSelected={onRowSelected}
            total={totalRows}
          />
        )}
      </div>
    </div>
  );
}

export default withStyles(VersionsDataGrid, styles)

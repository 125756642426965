import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";

import { styles } from "./OrdersMissingProductsDataGrid.styles";

function OrdersMissingProductsDataGridActions({ classes, row, onDispatch }) {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item>
        <Button
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            onDispatch && onDispatch(row);
          }}
        >
          {t("orders.OrdersMissingProducts.columns.dispatchBtn")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default withStyles(OrdersMissingProductsDataGridActions, styles)

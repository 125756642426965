import {createSlice} from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    emailsList: null,
    emailTemplate: null,
  },
  reducers: {
    setEmailsList: (state, action) => {
      state.emailsList = action.payload;
    },
    setEmailTemplate: (state, action) => {
      state.emailTemplate = action.payload;
    },
  },
});

export default notificationSlice.reducer;

import * as Yup from "yup";
import { EntityAutoCompleteField, SelectField } from "../../../../common/forms";

export const filterFields = (user) => (t) => {
  return [
    {
      size: 12,
      field: <SelectField id="status" label={t("entities.CutOffs.filter.activeLabel")} options={getActiveOptions(t)} />,
    },
    {
      size: 12,
      field: (
        <EntityAutoCompleteField
          id="entityId"
          label={t("entities.CutOffs.filter.entityName")}
        />
      ),
      hidden: user?.entity?.type !== "P",
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    status: Yup.string().nullable(),
    entityId: Yup.string().nullable(),
  });
};

const getActiveOptions = (t) => {
  return [
    {
      value: null,
      text: t("entities.CutOffs.filter.optionAll"),
    },
    {
      value: true,
      text: t("entities.CutOffs.filter.optionActive"),
    },
    {
      value: false,
      text: t("entities.CutOffs.filter.optionInactive"),
    },
  ];
};

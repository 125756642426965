import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const entityGetContactCall = async ({ setContactDetails }, dispatch, { entityId, contactId }) => {
  let rh = requestHelper(dispatch, "GET_CONTACT");
  try {
    let details = await call({
      type: "ENTITY",
      subType: "GET_CONTACT",
      request: {
        entityId,
        contactId
      },
    });

    dispatch(
      setContactDetails(details)
    );
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityGetContactCall;

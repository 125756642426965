import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { withStyles } from "tss-react/mui";
import { styles } from "./ImportProduct.styles";
import { useNavigate, usePromoter, useUser } from "../../../common/hooks";

import { formFields, formSchema } from "./ImportProduct.schema";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { entityGet, selectEntityDetails } from "../../entities/entitiesSlice";
import { importData, importProduct, processFileResult, setImport } from "../productsSlice.importCalls";
import ImportResultsGrid from "./components/ImportResultsGrid";
import Form from "../../../common/forms/Form";
import Permission from "../../../app/components/Permission";
import { downloadFile } from "../../../api/s3";
import Page from "../../../common/displays/Page/Page";

function ImportProduct({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useUser();
  const entity = useSelector(selectEntityDetails);
  const promoter = usePromoter();
  const importFile = useSelector(importData);

  useEffect(() => {
    if (importFile?.fileProcessing) {
      let intervalCode = setInterval(() => {
        dispatch(processFileResult(importFile.processFileId));
      }, 5000);
      return () => {
        clearInterval(intervalCode);
      };
    }
    // eslint-disable-next-line
  }, [importFile]);

  useEffect(() => {
    if (entity && entity.gln) {
      setImport({
        ...importFile,
        gln: entity.gln,
      });
    }
    // eslint-disable-next-line
  }, [entity]);

  const onSubmit = async (values) => {
    dispatch(importProduct(promoter, values));
  };

  const onOwnerChangeHandler = (v, values) => {
    dispatch(
      setImport({
        ...importFile,
        gln: v?.gln,
        // type: values?.type,
        type: "product",
        ownerId: v?.entityId,
        address: values?.type === "price" ? undefined : values?.address,
      })
    );

    if (v?.entityId !== undefined) {
      dispatch(entityGet(v?.entityId));
    }
  };

  const downloadTemplate = (filePath) => {
    downloadFile(promoter, filePath);
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        schema={formSchema(t)}
        initialValues={importFile}
        render={({ submitting, values }) => {
          return (
            <Page
              permission="products.actions.product.import"
              header={<PageTitle
                title={t("products.ImportProduct.header.title")}
                onBack={() => {
                  navigate("/products");
                  dispatch(setImport({}));
                }}
                actions={
                  <Grid container direction="row" spacing={2}>
                    <Permission code="products.actions.product.import">
                      {!importFile?.fileProcessing &&
                        <Grid item>
                          <Button variant="contained" color="primary" size="large" type="submit" disabled={submitting}>
                            {t("products.ImportProduct.form.importBtn")}
                          </Button>
                        </Grid>}
                    </Permission>
                  </Grid>
                }
              />}>

              {!importFile?.fileProcessing && <Paper className={classes.paper} elevation={0}>
                <Grid container alignItems="flex-start" spacing={2}>
                  {formFields(t, user, promoter, onOwnerChangeHandler, entity, values).map((item, idx) => (
                    <Grid item xs={item.size} key={idx}>
                      {item.field}
                    </Grid>
                  ))}
                </Grid>
              </Paper>}

              <div>&nbsp;</div>

              {importFile?.results !== undefined && (
                <Paper className={classes.paper} elevation={0}>
                  <ImportResultsGrid data={importFile?.results} title={t("products.ImportProduct.header.results")}>
                    {" "}
                  </ImportResultsGrid>
                </Paper>
              )}

              <div>&nbsp;</div>

              <Paper className={classes.paper} elevation={0}>
                <Typography component="span" variant="caption" color="textPrimary">
                  {t("products.ImportProduct.header.template")}
                </Typography>
                <Grid container alignItems="flex-start" spacing={2}>
                  {values.type === "product" && (
                    <Grid item xs={12}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => downloadTemplate(promoter?.template?.product)}
                      >
                        {t("products.ImportProduct.template.product")}
                      </Button>
                    </Grid>
                  )}
                  {values.type === "price" && (
                    <Grid item xs={12}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => downloadTemplate(promoter?.template?.price)}
                      >
                        {t("products.ImportProduct.template.price")}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(ImportProduct, styles);



import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const productPendingTaxActionCall = async (
  { clearTempProductTaxValidation },
  dispatch,
  state
) => {
  let rh = requestHelper(dispatch, "PRODUCT_PENDING_TAX_ACTION");
  let tempProductTaxValidation = state.products.tempProductTaxValidation;
  let actions = [];

  Object.keys(tempProductTaxValidation).forEach(productId => {
    Object.keys(tempProductTaxValidation[productId]).forEach(taskId => {
      let taxes = [];
      Object.keys(tempProductTaxValidation[productId][taskId]).forEach(tax => {
        const { valid, comment } = tempProductTaxValidation[productId][taskId][tax];
        if (valid !== undefined) {
          taxes.push({
            tax,
            status: valid ? "ACCEPTED" : "REFUSED",
            comment,
          })
        }
      })
      if (taxes.length > 0) {
        actions.push({
          productId,
          taskId,
          taxes
        })
      }
    })
  })

  try {
    let result = await call({
      type: "PRODUCT",
      subType: "PENDING_TAX_ACTION",
      request: {
        actions
      },
    });

    setTimeout(() => {
      if (result) {
        dispatch(clearTempProductTaxValidation());
        dispatch(setSuccess("products", "PRODUCT_PENDING_TAX_ACTION"));
      }
    }, 300);

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productPendingTaxActionCall;



export function listColumns(t, classes) {
  return [
    {
      field: "groupCode",
      headerName: t("entities.GroupList.columns.groupCode"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "groupName",
      headerName: t("entities.GroupList.columns.groupName"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "entityName",
      headerName: t("entities.GroupList.columns.entityName"),
      align: "left",
      headerAlign: "left",
    },
  ];
}


import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Stack, Tooltip, MenuItem, Menu } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { styles } from "./ProductSelect.styles";
import ProductSnip from "../../../displays/ProductSnip/ProductSnip";

function ProductSelect({ classes, products, id, label, value, note, onChange, disabled, required, emptyMsg, errorMsg }) {
  const selectedProduct = (products || []).find(item => item.productId === value);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const productSelectedHandler = (product) => {
    setAnchorEl(null);
    onChange && onChange(product?.productId);
  }

  return (
    <div className={classes.root}>
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="flex-end">
        {label && (
          <Typography
            style={{ marginBottom: 8, marginTop: 8 }}
            component="span"
            variant="caption"
            htmlFor={id}
            color="textPrimary"
            className={disabled ? classes.disabledLabel : ""}
          >
            {label}
            {required && <span> *</span>}
          </Typography>
        )}
        {note && <Tooltip title={note} placement="left"><InfoOutlinedIcon color="primary" className={classes.noteIcon} /></Tooltip>}
      </Stack>
      <div onClick={handleClick} style={{ cursor: "pointer" }} >
        <ProductSnip
          description={selectedProduct ? selectedProduct?.description : value ? errorMsg : emptyMsg}
          image={selectedProduct?.thumbnail}
          gtin={selectedProduct?.gtin}
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.menu}
      >
        {
          (products || []).map((product, index) => (
            <MenuItem key={"option_" + index} onClick={() => productSelectedHandler(product)}>
              <ProductSnip
                description={product?.description}
                image={product?.thumbnail}
                gtin={product?.gtin}
              />
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  );
}

export default withStyles(ProductSelect, styles);
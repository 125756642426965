

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess, setRedirect } from "../../../app/coreSlice";

const businessUnitCreateCall = async (
  dispatch,
  {
    name,
    entityId,
    places,
    users,
    groups,
    sectors,
  }
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_CREATE");
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "CREATE",
      request: {
        name,
        entityId,
        places,
        users,
        groups,
        sectors,
      },
    });

    setTimeout(() => {
      if (result) {
        dispatch(setRedirect("/business/units"));
        dispatch(setSuccess("businessUnits", "BUSINESS_UNIT_CREATE"));
      }
    }, 300);

  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitCreateCall;

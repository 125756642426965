import * as Yup from "yup";
import InternalCodeField from "../../_common/InternalCodeField/InternalCodeField";
import { TextField } from "../../../../common/forms";


export function formSchema({ t, showInternalCode }) {
  if (showInternalCode) {
    return Yup.object().shape({
      productTemplate: Yup.object().shape({
        valid: Yup.bool().isTrue(t("products.ProductCreate.form.requiredProductTemplate")),
        value: Yup.string().required(t("products.ProductCreate.form.requiredProductTemplate"))
      }),
      comment: Yup.string()
    });
  }
  else {
    return Yup.object().shape({
      comment: Yup.string()
    });
  }
}

export const formFields = ({ t, loading, showInternalCode }) => {
  return [
    {
      size: 12,
      field: (
        <InternalCodeField
          id="productTemplate"
          label={t("products.ProductCreate.form.productTemplate")}
          note={t("products.ProductCreate.form.productTemplateNote")}
          required
          disabled={loading}
        />
      ),
      hidden: !showInternalCode
    },
    {
      size: 12,
      field: (
        <TextField
          id="comment"
          rows={3}
          label={t("products.ProductDetails.header.rejectComment")}
          disabled={loading}
        />
      ),
    },
  ];
};

import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid } from "@mui/material";

import { Field } from "react-final-form";
import MetaError from "../../forms/MetaError";

import { styles } from "./MeasureField.styles";

import MeasureInput from "../inputs/MeasureInput";

function MeasureField({ classes, label, id, disabled, required, variable, toLabel, fixedCategory }) {
  const onChangeHandler = (v, input) => {
    if (!v.value) {
      v.value = 0;
    }
    input.onChange && input.onChange(v);
  };

  const onChangeMaxHandler = (v, input) => {
    input.onChange && input.onChange({ ...v, value: input.value?.value, valueMax: v.value || 0 });
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <div>
            <Typography
              component="span"
              variant="caption"
              htmlFor={id}
              color="textPrimary"
              className={disabled ? classes.disabledLabel : ""}
            >
              {label}
              {required && <span> *</span>}
            </Typography>
            <Grid container spacing={1} alignItems="center" wrap="nowrap">
              <Grid item xs={variable ? undefined : 12}>
                <MeasureInput
                  value={input.value}
                  onChange={(v) => onChangeHandler(v, input)}
                  disabled={disabled}
                  error={Boolean(meta.error && meta.touched)}
                  fixedCategory={fixedCategory}
                />
              </Grid>
              {variable && (
                <>
                  <Grid item>{toLabel}</Grid>
                  <Grid item>
                    <MeasureInput
                      value={{ ...input.value, value: input.value?.valueMax }}
                      onChange={(v) => onChangeMaxHandler(v, input)}
                      disabled={disabled}
                      error={Boolean(meta.error && meta.touched)}
                      fixedCategory={fixedCategory}
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <MetaError className={classes.error} meta={meta} />
          </div>
        )}
      </Field>
    </div>
  );
}

export default withStyles(MeasureField, styles)

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./SelectAllField.styles";
import { useForm } from "react-final-form";
import { CheckboxInput } from "../../../../common/inputs";

function SelectAllField({ classes, baseKey }) {
  const { t } = useTranslation();
  const form = useForm();

  const fields = form.getRegisteredFields().filter(item => item.indexOf(`permissions.${baseKey}.actions`) > -1 && item.indexOf(`.options`) < 0);
  const values = fields.map(field => ({
    field,
    value: form.getFieldState(field).value
  }))
  const checked = values.every(item => item.value === true);

  const onChangeHandler = () => {
    fields.forEach(field => {
      form.change(field, !checked)
    });
  }

  return (
    <div className={classes.root}>
      <CheckboxInput
        id={baseKey}
        label={t("roles.RolesForm.form.selectAll")}
        value={checked}
        onChange={onChangeHandler}
      />
    </div>
  );
}

export default withStyles(SelectAllField, styles);
import React from "react";
import { withStyles } from "tss-react/mui";
import PropTypes from "prop-types";

import { styles } from "./Banner.styles";
import { classJoin } from "../../utils";

function Banner({ classes, children, color }) {
  return (
    <div className={classJoin([classes.root, classes[color]])}>
      {children}
    </div>
  );
}

export default withStyles(Banner, styles);

Banner.propTypes = {
  children: PropTypes.element.isRequired,
  color: PropTypes.oneOf(["primary", "secondary", "warning", "error", "info", "success"]),
};
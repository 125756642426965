import { Divider, Stack } from "@mui/material"
import BlockCard from "../BlockCard/BlockCard"
import SlimCheckBoxField from "../SlimCheckBoxField/SlimCheckBoxField"

export const promoterFormFields = (t) => {
  return [
    {
      size: 3,
      field: <BlockCard title="auditRetention">
        <Stack>
          <SlimCheckBoxField id="permissions.promoter.actions.auditRetention.list" label="list" />
          <SlimCheckBoxField id="permissions.promoter.actions.auditRetention.update" label="update" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="configs">
        <Stack>
          <SlimCheckBoxField id="permissions.promoter.actions.configs.list" label="list" />
          <SlimCheckBoxField id="permissions.promoter.actions.configs.update" label="update" />
          <Divider />
          <SlimCheckBoxField id="permissions.promoter.actions.configs.functional" label="functional" />
          <SlimCheckBoxField id="permissions.promoter.actions.configs.technical" label="technical" />
          <SlimCheckBoxField id="permissions.promoter.actions.configs.admin" label="admin" />
          <Divider />
          <SlimCheckBoxField id="permissions.promoter.actions.configs.general" label="general" />
          <SlimCheckBoxField id="permissions.promoter.actions.configs.contact" label="contact" />
          <SlimCheckBoxField id="permissions.promoter.actions.configs.cutoff" label="cutoff" />
          <SlimCheckBoxField id="permissions.promoter.actions.configs.entity" label="entity" />
          <SlimCheckBoxField id="permissions.promoter.actions.configs.order" label="order" />
          <SlimCheckBoxField id="permissions.promoter.actions.configs.price" label="price" />
          <SlimCheckBoxField id="permissions.promoter.actions.configs.product" label="product" />
          <SlimCheckBoxField id="permissions.promoter.actions.configs.taxes" label="taxes" />
          <SlimCheckBoxField id="permissions.promoter.actions.configs.home" label="home" />
          <SlimCheckBoxField id="permissions.promoter.actions.configs.document" label="document" />
          <SlimCheckBoxField id="permissions.promoter.actions.configs.notification" label="notification" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="location">
        <Stack>
          <SlimCheckBoxField id="permissions.promoter.actions.location.list" label="list" />
          <SlimCheckBoxField id="permissions.promoter.actions.location.create" label="create" />
          <SlimCheckBoxField id="permissions.promoter.actions.location.update" label="update" />
          <SlimCheckBoxField id="permissions.promoter.actions.location.delete" label="delete" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="vat">
        <Stack>
          <SlimCheckBoxField id="permissions.promoter.actions.vat.list" label="list" />
          <SlimCheckBoxField id="permissions.promoter.actions.vat.create" label="create" />
          <SlimCheckBoxField id="permissions.promoter.actions.vat.update" label="update" />
          <SlimCheckBoxField id="permissions.promoter.actions.vat.disable" label="disable" />
          <SlimCheckBoxField id="permissions.promoter.actions.vat.enable" label="enable" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="i18n">
        <Stack>
          <SlimCheckBoxField id="permissions.promoter.actions.i18n.list" label="list" />
          <SlimCheckBoxField id="permissions.promoter.actions.i18n.update" label="update" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="cutoff">
        <Stack>
          <SlimCheckBoxField id="permissions.promoter.actions.cutoff.create" label="create" />
          <SlimCheckBoxField id="permissions.promoter.actions.cutoff.disable" label="disable" />
          <SlimCheckBoxField id="permissions.promoter.actions.cutoff.enable" label="enable" />
          <SlimCheckBoxField id="permissions.promoter.actions.cutoff.list" label="list" />
          <SlimCheckBoxField id="permissions.promoter.actions.cutoff.update" label="update" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="timer">
        <Stack>
          <SlimCheckBoxField id="permissions.promoter.actions.timer.list" label="list" />
          <SlimCheckBoxField id="permissions.promoter.actions.timer.get" label="get" />
          <SlimCheckBoxField id="permissions.promoter.actions.timer.create" label="create" />
          <SlimCheckBoxField id="permissions.promoter.actions.timer.update" label="update" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="systemStatus">
        <Stack>
          <SlimCheckBoxField id="permissions.promoter.actions.systemStatus.list" label="list" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="role">
        <Stack>
          <SlimCheckBoxField id="permissions.promoter.actions.role.list" label="list" />
          <SlimCheckBoxField id="permissions.promoter.actions.role.get" label="get" />
          <SlimCheckBoxField id="permissions.promoter.actions.role.create" label="create" />
          <SlimCheckBoxField id="permissions.promoter.actions.role.update" label="update" />
          <SlimCheckBoxField id="permissions.promoter.actions.role.delete" label="delete" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="document">
        <Stack>
          <SlimCheckBoxField id="permissions.promoter.actions.document.list" label="list" />
          <SlimCheckBoxField id="permissions.promoter.actions.document.get" label="get" />
          <SlimCheckBoxField id="permissions.promoter.actions.document.update" label="update" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="email">
        <Stack>
          <SlimCheckBoxField id="permissions.promoter.actions.email.list" label="list" />
          <SlimCheckBoxField id="permissions.promoter.actions.email.get" label="get" />
          <SlimCheckBoxField id="permissions.promoter.actions.email.update" label="update" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="family">
        <Stack>
          <SlimCheckBoxField id="permissions.promoter.actions.family.list" label="list" />
          <SlimCheckBoxField id="permissions.promoter.actions.family.create" label="create" />
          <SlimCheckBoxField id="permissions.promoter.actions.family.update" label="update" />
          <SlimCheckBoxField id="permissions.promoter.actions.family.disable" label="disable" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="places">
        <Stack>
          <SlimCheckBoxField id="permissions.promoter.actions.places.list" label="list" />
          <SlimCheckBoxField id="permissions.promoter.actions.places.create" label="create" />
          <SlimCheckBoxField id="permissions.promoter.actions.places.update" label="update" />
          <SlimCheckBoxField id="permissions.promoter.actions.places.delete" label="delete" />
          <SlimCheckBoxField id="permissions.promoter.actions.places.disable" label="disable" />
          <SlimCheckBoxField id="permissions.promoter.actions.places.enable" label="enable" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="resources">
        <Stack>
          <SlimCheckBoxField id="permissions.promoter.actions.resources.list" label="list" />
          <SlimCheckBoxField id="permissions.promoter.actions.resources.create" label="create" />
          <SlimCheckBoxField id="permissions.promoter.actions.resources.update" label="update" />
          <SlimCheckBoxField id="permissions.promoter.actions.resources.delete" label="delete" />
          <SlimCheckBoxField id="permissions.promoter.actions.resources.disable" label="disable" />
          <SlimCheckBoxField id="permissions.promoter.actions.resources.enable" label="enable" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="businessUnit">
        <Stack>
          <SlimCheckBoxField id="permissions.promoter.actions.businessUnit.list" label="list" />
          <SlimCheckBoxField id="permissions.promoter.actions.businessUnit.create" label="create" />
          <SlimCheckBoxField id="permissions.promoter.actions.businessUnit.update" label="update" />
          <SlimCheckBoxField id="permissions.promoter.actions.businessUnit.delete" label="delete" />
          <SlimCheckBoxField id="permissions.promoter.actions.businessUnit.disable" label="disable" />
          <SlimCheckBoxField id="permissions.promoter.actions.businessUnit.enable" label="enable" />
        </Stack>
      </BlockCard>,
    }
  ]
}
import { createSlice } from "@reduxjs/toolkit";
import alertListCall from "./_sliceCalls/alertList";
import alertResolveCall from "./_sliceCalls/alertResolve";
import alertResolveAllCall from "./_sliceCalls/alertResolveAllCall";

export const alertsSlice = createSlice({
  name: "alerts",
  initialState: {
    alertsList: [],
    alertsListTotalRows: 0,
    alertsListFilter: {
      limit: 50,
      offset: 0,
      sortField: "DATE",
      sortType: "DESC",
      resolved: false,
    },
    alertsResolvedList: [],
    alertsResolvedListTotalRows: 0,
    alertsResolvedListFilter: {
      limit: 50,
      offset: 0,
      sortField: "DATE",
      sortType: "DESC",
      resolved: true,
    },
  },
  reducers: {
    clearAlertsList: (state) => {
      state.alertsList = [];
    },

    appendToAlertsList: (state, action) => {
      state.alertsList = [...state.alertsList, ...action.payload];
    },

    addAlertToList: (state, action) => {
      state.alertsList = [action.payload, ...(state.alertsList || [])];
      state.alertsListTotalRows = (state.alertsListTotalRows || 0) + 1;
    },

    setAlertsListTotalRows: (state, action) => {
      state.alertsListTotalRows = action.payload;
    },

    setAlertsListFilter: (state, action) => {
      state.alertsListFilter = action.payload;
    },

    clearAlertsResolvedList: (state) => {
      state.alertsResolvedList = [];
    },

    appendToAlertsResolvedList: (state, action) => {
      state.alertsResolvedList = [...state.alertsResolvedList, ...action.payload];
    },

    setAlertsResolvedListTotalRows: (state, action) => {
      state.alertsResolvedListTotalRows = action.payload;
    },

    setAlertsResolvedListFilter: (state, action) => {
      state.alertsResolvedListFilter = action.payload;
    },
  },
});

export default alertsSlice.reducer;
export const { addAlertToList } = alertsSlice.actions;

// SELECTS
export const selectIsLoading = (state) => state.core.loader.length > 0;

export const selectAlertsList = (state) => state.alerts.alertsList;
export const selectAlertsListTotalRows = (state) => state.alerts.alertsListTotalRows;
export const selectAlertsListFilter = (state) => state.alerts.alertsListFilter;
export const selectAlertsResolvedList = (state) => state.alerts.alertsResolvedList;
export const selectAlertsResolvedListTotalRows = (state) => state.alerts.alertsResolvedListTotalRows;
export const selectAlertsResolvedListFilter = (state) => state.alerts.alertsResolvedListFilter;

// CALLS

export const alertList =
  (filter, clear = false) =>
    (dispatch) => {
      alertListCall(alertsSlice.actions, dispatch, filter, clear);
    };

export const alertResolve = (alertId, alertStatusId) => (dispatch, getState) => {
  alertResolveCall({ alertList }, dispatch, getState(), alertId, alertStatusId);
};

export const alertResolveAll = () => (dispatch, getState) => {
  alertResolveAllCall({ alertList }, dispatch, getState());
};
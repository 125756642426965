import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, FormControlLabel, Tooltip, Checkbox } from "@mui/material";

import { styles } from "./AggregatedDetailsHeader.styles";
import PageTitle from "../../../../common/displays/PageTitle/PageTitle";
import { useNavigate, usePromoter } from "../../../../common/hooks";
import { dateTimeFormat } from "../../../../common/utils";
import SearchBox from "../../../../common/components/inputs/SearchBox";
import Permission from "../../../../app/components/Permission";

function AggregatedDetailsHeader({ classes, orderDetails, onSearch, onAccept, fromMissingProducts, dispatchScreen, acceptScreen }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const promoter = usePromoter();
  const [finished, setFinished] = useState(true);
  const checkFinishedAccept = promoter?.getConfig("order", "checkFinishedAccept");

  if (!orderDetails) {
    return null;
  }

  let subtitle = t("orders.AggregatedDetails.header.title", {
    description: orderDetails?.description,
    address: orderDetails?.address,
  });
  if (promoter?.getConfig("cutOff") && !promoter?.getConfig("cutOff", "automatic")) {
    subtitle += `- ${t("orders.AggregatedDetails.header.cutoff")}: ${dateTimeFormat(orderDetails?.cutOffTime)}`;
  }

  const onBackHandler = () => {
    if (fromMissingProducts) {
      navigate("/orders/missing-products/")
    }
    else if (orderDetails?.aggregateOrderInfo?.numberOfOrders === 1) {
      navigate("/orders/received/")
    }
    else {
      navigate("/orders/received/" + orderDetails?.aggregateOrderInfo?.id)
    }
  }

  return (
    <div className={classes.root}>
      <PageTitle
        title={t("orders.AggregatedDetails.header.description") + " " + orderDetails.id}
        onBack={onBackHandler}
        subtitle={subtitle}
        actions={
          <Grid container spacing={2}>
            {dispatchScreen && (
              <Grid item>
                <SearchBox onEnter={(v) => onSearch(v)} fast />
              </Grid>
            )}
            {dispatchScreen && (
              <Permission code="orders.actions.received.dispatch">
                <Grid item>
                  <Button variant="contained" color="primary" size="large" type="submit">
                    {t("orders.AggregatedDetails.header.dispatchBtn")}
                  </Button>
                </Grid>
              </Permission>
            )}
            {acceptScreen && (
              <Permission code="orders.actions.received.accept">
                <Grid item>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="button"
                        onClick={() => onAccept(checkFinishedAccept ? finished : true)}
                      >
                        {t("orders.AggregatedDetails.header.acceptBtn")}
                      </Button>
                    </Grid>
                    {checkFinishedAccept && (<Grid item>
                      <Tooltip title={t("orders.AggregatedDetails.header.finishAcceptNote")}>
                        <FormControlLabel
                          control={<Checkbox defaultChecked color="primary" value={finished} onChange={(event) => { setFinished(event.target.checked) }} />}
                          label={t("orders.AggregatedDetails.header.finishAccept")}
                        />
                      </Tooltip>
                    </Grid>)}
                  </Grid>
                </Grid>
              </Permission>
            )}
          </Grid>
        }
      />
    </div>
  );
}

export default withStyles(AggregatedDetailsHeader, styles);
import { processImageUrl } from "../../../api/s3";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { asyncForEach } from "../../../common/utils";

const productListCall = async (
  { clearProductList, setProductListFilter, appendToProductList, setProductListTotalRows, updateProductListThumbnails },
  dispatch,
  state,
  { filter, clear }
) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "LIST_PRODUCTS");
  if (clear) {
    dispatch(clearProductList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setProductListFilter(filter));
  try {
    let list = await call({
      type: "PRODUCT",
      subType: "LIST",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    dispatch(appendToProductList(list.products));
    dispatch(setProductListTotalRows(list.rows));
    
    let products = [];
    await asyncForEach(list.products, async (product) => {
      products.push({
        ...product,
        image: await processImageUrl(fileStorageConfigs,  product.image),
        thumbnail: await processImageUrl(fileStorageConfigs,  product.thumbnail),
      });
    });
    dispatch(updateProductListThumbnails(products));
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productListCall;

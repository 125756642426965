export const styles = theme => ({
  root: {
    "& .CdgRow": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: '#fdfdfd'
      }
    }
  },
  statusAcc: {
    color: theme.palette.success.main,
  },
  statusPnd: {
    color: theme.palette.warning.main,
  },
  statusRef: {
    color: theme.palette.error.main,
  }
});
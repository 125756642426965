import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Typography } from "@mui/material";

import { styles } from "./PlanningCreateModal.styles";
import { formFields, formSchema } from "./PlanningCreateModal.schema";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";

import { Form, FormFields } from "../../../../common/forms";
import { useDispatch } from "react-redux";
import moment from "moment";
import { planningCreate } from "../../planningSlice";
import { useNavigate, useUser } from "../../../../common/hooks";
import { measureFormat } from "../../../../common/utils";

function PlanningCreateModal({ classes, open, setOpen, data }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useUser();

  const onSubmitHandler = async (values) => {
    console.log('values: %o', values);
    const productId = values.product?.[0]?.productId;
    const { year, target } = values;

    dispatch(planningCreate({
      productId,
      year,
      isDefault: true,
      target,
      startDate: moment(`${year}-01-01`).startOf('year').format("YYYY-MM-DD"),
      endDate: moment(`${year}-01-01`).endOf('year').format("YYYY-MM-DD"),
      name: t("planning.PlanningCreateModal.form.planningDefaultLabel")
    }))

    // navigate(`/planning/${productId}/${year}`);
    setOpen(false);
  };

  return (
    <CustomModal open={open} setOpen={setOpen} size="large" height={500} title={t("planning.PlanningCreateModal.form.modalTitle")}>
      <div className={classes.root}>
        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t })}
          initialValues={{ target: { category: "MASS", value: "0", unit: "KGM" } }}
          keepDirtyOnReinitialize
          render={({ values }) => {
            console.log('values: %o', values);
            const selectedPlan = (data || []).find(item => item.year === values.year && item.productId === values.product?.[0]?.productId);
            console.log('selectedPlan: %o', selectedPlan);
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormFields fields={formFields({ t, user, hideTarget: Boolean(selectedPlan) })} />
                </Grid>
                {!selectedPlan ?
                  <Grid item>
                    <Button variant="contained" color="primary" type="submit" fullWidth>
                      {t("planning.PlanningCreateModal.form.btnSubmit")}
                    </Button>
                  </Grid>
                  :
                  <>
                    <Grid item>
                      <Typography style={{ textAlign: "center" }}>{`${t("planning.PlanningCreateModal.form.target")}: ${measureFormat({ t, measure: selectedPlan.target, decimalPlaces: 0 })}`}</Typography>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" color="primary" onClick={() => navigate(`/planning/${values.product?.[0]?.productId}/${values.year}`)} fullWidth>
                        {t("planning.PlanningCreateModal.form.btnView")}
                      </Button>
                    </Grid>
                  </>
                }
              </Grid>
            );
          }}
        />
      </div>
    </CustomModal>
  );
}

export default withStyles(PlanningCreateModal, styles);

import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import DeleteButton from "../../../../common/components/dialogs/DeleteButton";
// import { useUser } from "../../../../common/hooks";

export default function RefDataTaxsDataGridActions({ row, onDelete, onEnable, onEdit }) {
  const { t } = useTranslation();
  // const user = useUser();

  return (
    <Grid container spacing={1} justifyContent="flex-end">
      {row.active && (
        <Grid item>
          <DeleteButton
            labels={{
              button: t("promoter.RefDataTaxsList.columns.deleteBtn"),
              title: t("promoter.RefDataTaxsList.deleteConfirm.title"),
              description: t("promoter.RefDataTaxsList.deleteConfirm.description"),
              ok: t("promoter.RefDataTaxsList.deleteConfirm.ok"),
              cancel: t("promoter.RefDataTaxsList.deleteConfirm.cancel"),
            }}
            onConfirm={() => {
              onDelete && onDelete(row);
            }}
          />
        </Grid>
      )}
      {/* {!row.active && (
        <Grid item>
          <Button variant="outlined" color="primary" onClick={() => onEnable && onEnable(row)}>
            {t("promoter.RefDataTaxsList.columns.activateBtn")}
          </Button>
        </Grid>
      )} */}
      {row.active && (
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => onEdit && onEdit(row)}>
            {t("promoter.RefDataTaxsList.columns.editBtn")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

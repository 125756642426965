
const styles = theme => ({
  root: {
    display: "flex",
  },
  textfieldlabel: {
    fontSize: 12,
    fontWeight: "bold",
  },
  textfield: {
    '& input': {
      fontSize: 12,
      padding: 10
    }
  },
});
export default styles;
import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper, Stack, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import i18next from "i18next";

import { styles } from "./GeneralInfo.styles";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";
import { usePromoter, useUser } from "../../../../common/hooks";
import { getCountryName, multiLanguagePropToString } from "../../../../common/utils";
import { useMemo } from "react";
import ProductAvaibleState from "./ProductAvaibleState/ProductAvaibleState";
import ContactPointChangeModal from "./ContactPointChangeModal/ContactPointChangeModal";
import Permission from "../../../../app/components/Permission";

function GeneralInfo({ classes, product, taskId }) {
  const { t, i18n } = useTranslation();
  const promoter = usePromoter();
  const user = useUser();
  const { baseInfo, status } = product;
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];
  const [contactPointModal, setContactPointModal] = useState(false);

  const gpcCategoryDescription = useMemo(() => {
    if (promoter?.refData?.gpcCategories && product?.baseInfo?.gpcCategoryCode) {
      const gpc = (promoter?.refData?.gpcCategories || []).find(item => item.code === product?.baseInfo?.gpcCategoryCode);
      let altLang = Object.keys(gpc.description).filter((k) => k !== i18next.language)[0];
      let description = gpc.description[i18next.language] || gpc.description[altLang];
      return gpc.code + " - " + description
    }
    return "-"
  }, [promoter, product]);

  const productClassDescription = useMemo(() => {
    if (promoter?.refData?.productClasses && product?.baseInfo?.productClass) {
      const pClass = (promoter?.refData?.productClasses || []).find(item => item.code === product?.baseInfo?.productClass);
      let altLang = Object.keys(pClass.name).filter((k) => k !== i18next.language)[0];
      let name = pClass.name[i18next.language] || pClass.name[altLang];
      return name
    }
    return "-"
  }, [promoter, product])

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container direction="row" spacing={2}>
        <Grid item style={{ width: 320 }}>
          {product?.media.length > 0 ? (
            <div
              className={classes.defaultPic}
              style={{ backgroundImage: "url('" + product?.media[0].reference + "')" }}
            ></div>
          ) : (
            <div
              className={classes.defaultPic}
              style={{ backgroundImage: "url(data:image/svg+xml,..." }}
            ></div>
          )}
        </Grid>
        <Grid item style={{ width: "calc(100% - 320px)" }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h3" align="left" component="h2" className={classes.noMargin}>
                    {user?.isPromoter && enabledProductFields.includes("promoterDescription") && baseInfo?.promoterDescription ? baseInfo?.promoterDescription : baseInfo?.description}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    component="span"
                    color="textSecondary"
                    className={classes.noMargin}
                  >
                    {baseInfo?.brand}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <ProductAvaibleState product={product} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <LabelValue
                    label={t("products.ProductDetails.details.owner")}
                    value={
                      <Typography variant="body1" align="left" component="span">
                        {baseInfo?.ownerName}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <LabelValue
                    label={t("products.ProductDetails.details.ownerTaxNumber")}
                    value={
                      <Typography variant="body1" align="left" component="span">
                        {baseInfo?.ownerTaxNumber}
                      </Typography>
                    }
                  />
                </Grid>
                {baseInfo?.ownerPromoterInternalCode && user?.isPromoter &&
                  <Grid item xs={4}>
                    <LabelValue
                      label={t("products.ProductDetails.details.ownerPromoterInternalCode")}
                      value={
                        <Typography variant="body1" align="left" component="span">
                          {baseInfo?.ownerPromoterInternalCode}
                        </Typography>
                      }
                    />
                  </Grid>
                }
                <Grid item xs={4}>
                  {baseInfo?.gln && !product.baseInfo.gln.includes(baseInfo?.ownerTaxNumber) &&
                    <LabelValue
                      label={t("products.ProductDetails.details.gln")}
                      value={
                        <Typography variant="body1" align="left" component="span">
                          {baseInfo?.gln}
                        </Typography>
                      }
                    />
                  }
                </Grid>
                {baseInfo?.gtin && !product?.baseInfo?.internalGtin &&
                  <Grid item xs={4}>
                    <LabelValue
                      label={t("products.ProductDetails.details.gtin")}
                      value={
                        <Typography variant="body1" align="left" component="span">
                          {baseInfo?.gtin}
                        </Typography>
                      }
                    />
                  </Grid>
                }
                {enabledProductFields.includes("supplierInternalCode") &&
                  <Grid item xs={4}>
                    <LabelValue
                      label={t("products.ProductDetails.details.supplierInternalCode")}
                      value={
                        <Typography variant="body1" align="left" component="span">
                          {baseInfo?.supplierInternalCode}
                        </Typography>
                      }
                    />
                  </Grid>
                }
                {enabledProductFields.includes("contactPoint") &&
                  <Grid item xs={4}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <LabelValue
                        label={t("products.ProductDetails.details.contactPoint")}
                        value={
                          <Typography variant="body1" align="left" component="span" className={classes.noMargin}>
                            {baseInfo?.contactName || "-"}
                          </Typography>
                        }
                      />
                      <Permission code="products.actions.pendingProduct.action">
                        {status === "PND" &&
                          <IconButton onClick={() => setContactPointModal(true)} color="primary">
                            <EditIcon style={{ display: "block" }} />
                          </IconButton>
                        }
                      </Permission>
                    </Stack>
                  </Grid>
                }
                {enabledProductFields.includes("promoterInternalCode") && user?.isPromoter && <Grid item xs={4}>
                  <LabelValue
                    label={t("products.ProductDetails.details.promoterInternalCode")}
                    value={
                      <Typography variant="body1" align="left" component="span">
                        {baseInfo?.promoterInternalCode || "-"}
                      </Typography>
                    }
                  />
                </Grid>
                }
                {enabledProductFields.includes("productTemplate") && user?.isPromoter && <Grid item xs={4}>
                  <LabelValue
                    label={t("products.ProductDetails.details.productTemplate")}
                    value={
                      <Typography variant="body1" align="left" component="span">
                        {baseInfo?.productTemplate || "-"}
                      </Typography>
                    }
                  />
                </Grid>
                }
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <LabelValue
                    label={t("products.ProductDetails.details.productClass")}
                    value={
                      <Typography variant="body1" align="left" component="span">
                        {productClassDescription}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <LabelValue
                    label={t("products.ProductDetails.details.gpcCategoryCode")}
                    value={
                      <Typography variant="body1" align="left" component="span">
                        {gpcCategoryDescription}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <LabelValue
                    label={t("products.ProductDetails.details.familyCode")}
                    value={
                      <Typography variant="body1" align="left" component="span">
                        {multiLanguagePropToString(baseInfo?.familyDescription, i18n.language)}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}>
                  <LabelValue
                    label={t("products.ProductDetails.details.available")}
                    value={
                      <Typography variant="body1" align="left" component="span">
                        {baseInfo?.available ? t("common.Enums.productAvailable." + baseInfo?.available) : "-"}
                      </Typography>
                    }
                  />
                </Grid>
                {enabledProductFields.includes("addressId") &&
                  <Grid item xs={4}>
                    <LabelValue
                      label={t("products.ProductDetails.details.address")}
                      value={
                        <Typography variant="body1" align="left" component="span">
                          {baseInfo?.addressDescription || "-"}
                        </Typography>
                      }
                    />
                  </Grid>
                }
                {baseInfo?.countryOfOrigin &&
                  <Grid item xs={4}>
                    <LabelValue
                      label={t("products.ProductDetails.details.countryOfOrigin")}
                      value={
                        <Typography variant="body1" align="left" component="span">
                          {getCountryName(baseInfo?.countryOfOrigin)}
                        </Typography>
                      }
                    />
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ContactPointChangeModal open={contactPointModal} setOpen={setContactPointModal} taskId={taskId} productId={product?.id} contactPoint={product?.baseInfo?.contactPoint} />
    </Paper>
  );
}

export default withStyles(GeneralInfo, styles);
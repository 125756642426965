import Encoding from 'encoding-japanese';

const parseCSVRow = (row, splitChar) => {
  const regex = new RegExp(`(?<=${splitChar}|^)(".*?"|[^"${splitChar}]*?)(?=${splitChar}|$)`, 'g');
  return row.match(regex);
}

const getSplitChar = (text) => {
  if ((text.match(/;/g) || []).length > (text.match(/,/g) || []).length) {
    return ";";
  }
  return ",";
}

// This should correctly read the file, detect its encoding using encoding-japanese and convert it to UTF-8
const readFileAsText = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const bytes = new Uint8Array(arrayBuffer);
      const detectedEncoding = Encoding.detect(bytes);
      const utf8Str = Encoding.convert(bytes, {
        to: 'UNICODE',
        from: detectedEncoding,
        type: 'string'
      });
      resolve(utf8Str);
    };
    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export const csvFileToJson = async (file) => {
  let text = await readFileAsText(file);
  let splitChar = getSplitChar(text);

  let rows = (text.indexOf("\r\n") > 0 ? text.split("\r\n") : text.split("\n")).filter(item => item);
  let keys = rows[0].split(splitChar);
  let jsonData = rows.slice(1).map((row) => {
    let values = parseCSVRow(row, splitChar);
    return keys.reduce((object, key, index) => {
      object[key] = (values?.[index] || "").replace(/"/g, '');
      return object;
    }, {});
  });
  return jsonData
}

export const getCsvDataUrl = (data, headers) => {
  let CSV = "";
  // headers
  let row = "";
  let keys = Object.keys(data[0]);

  (headers || keys).forEach((k) => {
    row += k + ",";
  });
  row = row.slice(0, -1);
  CSV += row + "\r\n";

  // data
  data.forEach((item) => {
    row = "";
    keys.forEach((k) => {
      row += ((item?.[k]).toString().includes(',') ? `"${item[k]}"` : item[k]) + ',';
    });
    row = row.slice(0, -1);
    CSV += row + "\r\n";
  });

  if (CSV === "") {
    return;
  }

  return "data:text/csv;charset=utf-8,\uFEFF" + encodeURIComponent(CSV);
}

export const convertCsvJsonToObjects = (jsonData, keys) => {
  let result = [];
  (jsonData || []).forEach((row) => {
    let obj = {};
    keys.forEach((item, index) => {
      if (item.type === "float") {
        obj[item.key] = parseFloat((Object.values(row)[index] || "").toString()?.replace(/,/g, '.'));
      }
      else {
        obj[item.key] = Object.values(row)[index];
      }
    })
    result.push(obj);
  });
  return result;
}
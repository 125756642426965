import * as Yup from "yup";
import { TextField } from "../../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    name: Yup.string().required(t("entities.EntityUpdateName.form.requiredName")),
  });
}

export function formFields(t) {
  return [
    {
      size: 12,
      field: <TextField id="name" type="text" label={t("entities.EntityUpdateName.form.name")} required />,
    },
  ];
}

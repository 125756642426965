import { Grid, Typography } from "@mui/material";
import { currencyFormat } from "../../../../common/utils";
import OrderStatus from "../../_common/OrderStatus/OrderStatus";
import { canAcceptOrder, canDispatchOrder } from "../../_common/ordersUtils";

export function listColumns(t, classes) {
  return [
    {
      field: "id",
      headerName: t("orders.AggregatedOrders.columns.id"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <Grid container direction="column">
            <Typography className={classes.id} variant="h4">
              {v.id}
            </Typography>
            <Typography className={classes.orderBy}>{v.description}</Typography>
            <div><Typography className={classes.addressTitle}>{t("orders.AggregatedOrders.columns.address")}&nbsp;</Typography><Typography className={classes.address}>{v.address}</Typography></div>
          </Grid>
        );
      },
    },
    {
      field: "numberOfProducts",
      headerName: t("orders.AggregatedOrders.columns.numberOfProducts"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "price",
      headerName: t("orders.AggregatedOrders.columns.price"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return currencyFormat(v.price);
      },
    },
    {
      field: "status",
      headerName: t("orders.AggregatedOrders.columns.status"),
      align: "center",
      headerAlign: "left",
      customValue: (v) => {
        return <OrderStatus order={v} allowAccept={canAcceptOrder(v)} allowDispatch={canDispatchOrder(v)} />;
      },
    },
  ];
}

// description: "TESTES CRIACAO -> UPDATE BY USER"
// id: 416
// numberOfProducts: 2
// price: 1326.28
// quantityOfProducts: 702
// status: "CO"
// toDispatch: 0

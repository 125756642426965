export const styles = () => ({
  root: {
    width: "100%",
    minWidth: 160
  },
  bar: {
    backgroundColor: "#BFD9FF",
    width: "100%",
    margin: "0 1px"
  },
  divider: {
    height: 4,
    minWidth: 4,
    borderRadius: 2,
    backgroundColor: "#BFD9FF",
    margin: "0 1px",
    alignSelf: "center",
  }
});
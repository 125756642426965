import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./ProductImage.styles";
import { PicturePlaceholder } from "../../../icons";

function ProductImage({ classes, image, alt }) {
  const [picture, setPicture] = useState(<PicturePlaceholder />);

  useEffect(() => {
    if (image && (!image.includes("logistics-private") || (image.includes("logistics-private") && image.includes("Signature=")))) {
      setPicture(<img style={{ maxWidth: 44, maxHeight: 40 }} alt={alt} src={image} onError={() => setPicture(<PicturePlaceholder />)} />)
    }
    // eslint-disable-next-line
  }, [image])

  return (
    <div className={classes.root}>
      {picture}
    </div>
  );
}

export default withStyles(ProductImage, styles);
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, IconButton, Stack, Tooltip } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { styles } from "./PriceImportHelper.styles";
import HelperLine from "./HelperLine/HelperLine";

import CustomModal from "../../../../../common/displays/CustomModal/CustomModal";
import { usePromoter, useUser } from "../../../../../common/hooks";
import { selectLocations } from "../../../productsPricesSlice";
import Banner from "../../../../../common/displays/Banner/Banner";
import ScrollBox from "../../../../../common/displays/ScrollBox/ScrollBox";

function PriceImportHelper({ classes }) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const promoter = usePromoter();
  const user = useUser();
  const locations = useSelector(selectLocations);

  return (
    <div className={classes.root}>
      <Tooltip title={t("productsPrices.PricesList.header.tooltipHelper")}>
        <IconButton onClick={() => setModal(true)} size="small">
          <HelpOutlineIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <CustomModal open={modal} setOpen={setModal} size="large" height={680} title={t("productsPrices.PricesList.modalImportHelper.title")}>
        <ScrollBox style={{ height: 600 }}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography style={{ margin: 0 }} variant="caption">{t("productsPrices.PricesList.modalImportHelper.createPrice_title")}</Typography>
              <Typography style={{ margin: 0 }}>{t("productsPrices.PricesList.modalImportHelper.createPrice_description")}</Typography>
              <Banner color="success">
                <Stack spacing={1}>
                  <HelperLine width={90} label={t("productsPrices.PricesList.exportHeader.product")}
                    value={t("productsPrices.PricesList.modalImportHelper.createPrice_product")} />
                  {user?.isPromoter && <HelperLine width={90} label={t("productsPrices.PricesList.exportHeader.promoterInternalCode")}
                    value={t("productsPrices.PricesList.modalImportHelper.createPrice_promoterInternalCode")} />}
                  <HelperLine width={90} label={t("productsPrices.PricesList.exportHeader.supplierInternalCode")}
                    value={t("productsPrices.PricesList.modalImportHelper.createPrice_supplierInternalCode")} />
                  {user?.isPromoter && <HelperLine width={90} label={t("productsPrices.PricesList.exportHeader.owner")}
                    value={t("productsPrices.PricesList.modalImportHelper.createPrice_owner")} />}
                  <HelperLine width={90} label={t("productsPrices.PricesList.exportHeader.locations")}
                    value={t("productsPrices.PricesList.modalImportHelper.createPrice_locations")} />
                  <HelperLine width={90} label={t("productsPrices.PricesList.exportHeader.priceType")}
                    value={t("productsPrices.PricesList.modalImportHelper.createPrice_priceType")} />
                  <HelperLine width={90} label={t("productsPrices.PricesList.exportHeader.salePrice")}
                    value={t("productsPrices.PricesList.modalImportHelper.createPrice_salePrice")} />
                  {promoter?.getConfig("price", "allowRetailPrice") &&
                    <HelperLine width={90} label={t("productsPrices.PricesList.exportHeader.retailPrice")}
                      value={t("productsPrices.PricesList.modalImportHelper.createPrice_retailPrice")} />
                  }
                </Stack>
              </Banner>
            </Stack>
            {promoter?.getConfig("price", "allowPricePromotions") && <Stack spacing={1}>
              <Typography style={{ margin: 0 }} variant="caption">{t("productsPrices.PricesList.modalImportHelper.promotions_title")}</Typography>
              <Typography style={{ margin: 0 }}>{t("productsPrices.PricesList.modalImportHelper.promotions_description")}</Typography>
              <Banner color="success">
                <Stack spacing={1}>
                  <HelperLine width={90} label={t("productsPrices.PricesList.exportHeader.promotion1_name")}
                    value={t("productsPrices.PricesList.modalImportHelper.promotions_promotion1_name")} />
                  <HelperLine width={90} label={t("productsPrices.PricesList.exportHeader.promotion1_percentage")}
                    value={t("productsPrices.PricesList.modalImportHelper.promotions_promotion1_percentage")} />
                </Stack>
              </Banner>
            </Stack>}
            <Stack spacing={1}>
              <Typography style={{ margin: 0 }} variant="caption">{t("productsPrices.PricesList.modalImportHelper.priceTypes_title")}</Typography>
              <Banner color="info">
                <Stack spacing={1}>
                  {
                    (["G", "D", "W"])
                      .filter(item => (promoter?.configs?.technical?.price?.listCreationTypes || []).includes(item))
                      .map((item) => <Stack direction="row" spacing={1} alignItems="center" key={item}>
                        <div>
                          <Typography style={{ margin: 0 }}>{t("common.Enums.priceType." + item)}</Typography>
                        </div>
                      </Stack>)
                  }
                </Stack>
              </Banner>
            </Stack>
            <Stack spacing={1}>
              <Typography style={{ margin: 0 }} variant="caption">{t("productsPrices.PricesList.modalImportHelper.locations_title")}</Typography>
              <Banner color="info">
                <Stack spacing={1}>
                  {
                    (locations || []).map((item) => <Stack direction="row" spacing={1} alignItems="center" key={item.id}>
                      <Typography style={{ margin: 0 }}>{`${item.name}`}</Typography>
                    </Stack>)
                  }
                </Stack>
              </Banner>
            </Stack>
          </Stack>
        </ScrollBox>
      </CustomModal>
    </div>
  );
}

export default withStyles(PriceImportHelper, styles);
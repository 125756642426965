import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {withStyles} from "tss-react/mui";
import {useParams} from "react-router-dom";
import {useFirstLoad} from "../../../common/hooks";
import {Grid, Paper, Typography} from "@mui/material";

import {styles} from "./AggregatedDetails.styles";
import {formFields, formSchema} from "./AggregatedDetails.schema";
import {currencyFormat} from "../../../common/utils";

import moment from "moment";
import DispatchProducts from "./fields/DispatchProducts";
import Form from "../../../common/forms/Form";
import AggregatedOrderInfo from "./AggregatedOrderInfo/AggregatedOrderInfo";
import AggregatedOrderProductsDataGrid from "./AggregatedOrderProductsDataGrid/AggregatedOrderProductsDataGrid";
// import OrderInfo from "./OrderInfo/OrderInfo";
import AggregatedDetailsHeader from "./AggregatedDetailsHeader/AggregatedDetailsHeader";
import {
  orderAccept,
  orderAggregateDetails,
  orderDispatch,
  orderModifyQuantityAccept,
  selectAggregatedDetails
} from "../ordersSlice";
import Page from "../../../common/displays/Page/Page";
import OrderStatusDetails from "../_common/OrderStatus/OrderStatusDetails/OrderStatusDetails";
import {canAcceptOrder, canDispatchOrder} from "../_common/ordersUtils";
import OrderAttachments from "../_common/OrderAttachments/OrderAttachments";
import AggregatedOrderCommentsDataGrid from "./AggregatedOrderCommentsDataGrid/AggregatedOrderCommentsDataGrid";

function AggregatedDetails({ classes, fromMissingProducts, dispatchScreen, acceptScreen }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const details = useSelector(selectAggregatedDetails);

  const [totals, setTotals] = useState({});
  const [filteredProducts, setFilteredProducts] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [quantities, setQuantities] = useState();
  const [otherBuyersQuantities, setOtherBuyersQuantities] = useState([]);

  useFirstLoad(() => {
    if (details?.id?.toString() !== id) {
      dispatch(orderAggregateDetails(id));
    }
  });

  useEffect(() => {
    if (details && details.products) {
      let subtotal = 0;
      let ivatotal = 0;
      details.products.forEach((product) => {
        subtotal += product.quantity * product.price;
        ivatotal += (product.quantity * product.price * product.vat) / 100;
      });
      setTotals({
        subtotal,
        ivatotal,
      });
      setQuantities(processQuantities(details.products, dispatchScreen, acceptScreen));

      if (!searchQuery) {
        setFilteredProducts(details.products);
      } else {
        setFilteredProducts(
          details.products.filter(
            (item) => item.description.indexOf(searchQuery) > -1 || item.gtin.indexOf(searchQuery) > -1
          )
        );
      }
    }
    // eslint-disable-next-line
  }, [details]);

  const onSearchHandler = (v) => {
    setSearchQuery(v);
    if (v) {
      setFilteredProducts(
        processProducts(details.products.filter(
          (item) =>
            item.description.toLowerCase().indexOf(v.toLowerCase()) > -1 ||
            item.gtin.toLowerCase().indexOf(v.toLowerCase()) > -1
        ), dispatchScreen, acceptScreen)
      );
    } else {
      setFilteredProducts(processProducts(details.products, dispatchScreen, acceptScreen));
    }
  };

  const onSubmit = async (values) => {
    if (dispatchScreen) {
      dispatch(
        orderDispatch({
          ...values,
          products:
            details?.operationType === "CD"
              ? values.products.filter((item) => item.productBatch && item.productBatch.length > 0)
              : values.products,
          orderSellerId: details.id,
          aggregateId: details.aggregateOrderInfo?.id,
          fromMissingProducts,
        })
      );
    }
  };

  const acceptAllHandler = (finished) => {

    let nProducts = (details.products || []).map(({ key, productId, orderId, quantity, price, priceType }) => {
      let customQuantity = (quantities || []).find((item) => item.key === key);
      return {
        productId,
        orderId,
        quantity: customQuantity ? customQuantity.quantity : quantity,
        price,
        priceType,
      };
    });

    dispatch(orderAccept({ aggregateId: details.aggregateOrderInfo?.id, orderSellerId: details.id, products: nProducts, finished }));
    if (otherBuyersQuantities && otherBuyersQuantities?.length > 0) {
      dispatch(orderModifyQuantityAccept(otherBuyersQuantities));
    }
  };

  return details && details.id.toString() === id ? (
    <div>
      <Form
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        schema={dispatchScreen ? formSchema(t, details?.operationType, dispatchScreen) : undefined}
        initialValues={
          dispatchScreen
            ? {
              documentDate: moment(new Date()),
              transportDocumentDate: moment(new Date()),
              products: processProducts(details.products, dispatchScreen, acceptScreen),
              volumes: [],
            }
            : { documentDate: moment(new Date()), products: details.products, volumes: [] }
        }
        render={({ values }) => {
          return (
            <Page
              permission="orders.actions.received.get"
              header={
                <AggregatedDetailsHeader
                  orderDetails={details}
                  onSearch={onSearchHandler}
                  onAccept={acceptAllHandler}
                  fromMissingProducts={fromMissingProducts}
                  dispatchScreen={dispatchScreen}
                  acceptScreen={acceptScreen}
                />
              }
            >
              <Grid container spacing={3}>
                <Grid item xs={10}>
                 <div>
                   <Grid container  spacing={3} >
                     <Grid item xs={12}>
                        <AggregatedOrderInfo order={details} />
                     </Grid>
                     <Grid item xs={10}>
                        <AggregatedOrderCommentsDataGrid data={details.comments} />
                      </Grid>
                     <Grid item xs={12}>
                     </Grid>
                   </Grid>
                 </div>
                  {dispatchScreen ? (
                    <>
                      <Paper className={classes.paper} elevation={2}>
                        <Grid container alignItems="flex-start" spacing={2}>
                          {formFields({ t, products: details?.products, operationType: details?.operationType, dispatchScreen }).map(
                            (item, idx) =>
                              !item.hidden && (
                                <Grid item xs={item.size} key={idx}>
                                  {item.field}
                                </Grid>
                              )
                          )}
                        </Grid>
                      </Paper>
                      <br />
                      <DispatchProducts
                        id="products"
                        totals={totals}
                        buyerId={details?.buyerId}
                        order={details}
                        operationType={details?.operationType}
                        dispatchScreen={dispatchScreen}
                        acceptScreen={acceptScreen}
                      />
                    </>
                  ) : (
                    <AggregatedOrderProductsDataGrid
                      data={filteredProducts}
                      order={details}
                      buyerId={details?.buyerId}
                      onQuantitiesChange={setQuantities}
                      onOtherBuyersQuantitiesChange={setOtherBuyersQuantities}
                      dispatchScreen={dispatchScreen}
                      acceptScreen={acceptScreen}
                      footer={
                        <Grid container direction="column" alignItems="flex-end">
                          <Grid item className={classes.totalbox}>
                            <Grid container justifyContent="space-between">
                              <Typography className={classes.totalLabel}>
                                {t("orders.OrderDetails.footer.subtotal")}
                              </Typography>
                              <Typography className={classes.noMargin} color="textPrimary">
                                {currencyFormat(totals.subtotal)}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item className={classes.totalbox}>
                            <Grid container justifyContent="space-between">
                              <Typography className={classes.totalLabel}>
                                {t("orders.OrderDetails.footer.ivatotal")}
                              </Typography>
                              <Typography className={classes.noMargin} color="textPrimary">
                                {currencyFormat(totals.ivatotal)}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item className={classes.totalbox}>
                            <Grid container justifyContent="space-between">
                              <Typography className={classes.totalLabel} color="textPrimary">
                                {t("orders.OrderDetails.footer.total")}
                              </Typography>
                              <Typography className={classes.noMargin} color="textPrimary">
                                <b>{currencyFormat(totals.subtotal + totals.ivatotal)}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <OrderAttachments orderSellerId={details?.id} attachments={details?.documents} />
                    </Grid>
                    <Grid item>
                      <Paper className={classes.paper} elevation={2}>
                        <OrderStatusDetails order={details} allowAccept={canAcceptOrder(details) && !acceptScreen} allowDispatch={canDispatchOrder(details) && !dispatchScreen} />
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Page>
          );
        }}
      />
    </div>
  ) : null;
}

export default withStyles(AggregatedDetails, styles);

const processProducts = (products, dispatchScreen, acceptScreen) => {
  let nproducts = [];
  (products || []).forEach((product) => {
    let quantity = product.quantity;
    if (dispatchScreen) {
      quantity = product.quantityHist?.accepted - product.quantityHist?.dispatched
    }
    if (acceptScreen) {
      quantity = product.quantityHist?.confirmed - product.quantityHist?.accepted
    }
    nproducts.push({
      ...product,
      quantity
    });
  });
  return nproducts
}


const processQuantities = (products, dispatchScreen, acceptScreen) => {
  let nCustomQuantities = [];
  (products || []).forEach((product) => {
    let quantity = product.quantity;
    if (dispatchScreen) {
      quantity = product.quantityHist?.accepted - product.quantityHist?.dispatched
    }
    if (acceptScreen) {
      quantity = product.quantityHist?.confirmed - product.quantityHist?.accepted
    }

    nCustomQuantities = [...nCustomQuantities, { key: product.key, quantity }];
  });
  return nCustomQuantities
}
export const styles = (theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3)
  },
  circleBtn: {
    backgroundColor: theme.palette.primary.back,
    color: theme.palette.text.secondary,
    height: 24,
    width: 24,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.paper,
    }
  }
});
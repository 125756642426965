import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {useNavigate, useUser} from "../../../common/hooks";
import { withStyles } from "tss-react/mui";
import { Grid, Button, Drawer } from "@mui/material";

import { styles } from "./EntitiesList.styles";
import EntityDrawerDetails from "./EntityDrawerDetails/EntityDrawerDetails";

import EntitiesDataGrid from "./EntitiesDataGrid/EntitiesDataGrid";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";

import { selectIsLoading, entityList, selectList, selectListTotalRows, selectListFilter } from "../entitiesSlice";

import { setError } from "../../../app/coreSlice";

import SearchBox from "../../../common/components/inputs/SearchBox";
import Permission from "../../../app/components/Permission";
import { useLocation } from "react-router-dom";
import Page from "../../../common/displays/Page/Page";

function EntitiesList({ classes, invalidPath }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useUser();
  const dispatch = useDispatch();
  const totalRows = useSelector(selectListTotalRows);
  const data = useSelector(selectList);
  const filter = useSelector(selectListFilter);
  const loading = useSelector(selectIsLoading);
  const [entityId, setEntityId] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (invalidPath) {
      dispatch(setError("common", { tag: "INVALID_PATH", description: location?.pathname }));
    }
    // eslint-disable-next-line
  }, []);

  const onFilterHandler = (v) => {
    dispatch(entityList(v.filter, v.reset));
  };

  const onEntitySelectedHandler = (row) => {
    if((user.permissionFilter("entities.filters.entity.get.includeSelf") && user?.entity?.id === row.entityId)
        || (user.permissionFilter("entities.filters.entity.get.type") || []).includes(row.type)) {
      setEntityId(row.entityId);
      setDrawerOpen(true);
    }
  };

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search,
      offset: 0,
    };
    dispatch(entityList(nFilter, true));
  };

  return (
    <Page
      permission="entities.actions.entity.list"
      header={<PageTitle
        title={t("entities.Entities.common.title")}
        info={`${totalRows} ` + t("entities.Entities.common.rowsInfo")}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <SearchBox onEnter={(v) => onSearchHandler(v)} value={filter?.search} />
            </Grid>

            <Grid item>
              <Permission code="entities.actions.entity.create">
                <Button variant="outlined" color="primary" size="large" onClick={() => navigate("/entities/add")}>
                  {t("entities.Entities.common.addBtn")}
                </Button>
              </Permission>
            </Grid>
          </Grid>
        }
      />}>

      <EntitiesDataGrid
        data={data}
        totalRows={totalRows}
        onEntitySelected={onEntitySelectedHandler}
        onFilterChange={onFilterHandler}
        filter={filter}
        loading={loading}
      />
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div className={classes.drawer}>
          <EntityDrawerDetails id={entityId} />
        </div>
      </Drawer>
    </Page>
  );
}

export default withStyles(EntitiesList, styles);

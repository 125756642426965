import moment from "moment";
import { Chip, Grid, Stack, Typography } from "@mui/material";

import CustomTooltip from "../../../../common/displays/CustomTooltip/CustomTooltip";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import { currencyFormat, dateFormat } from "../../../../common/utils";
import MessageIcon from '@mui/icons-material/Message';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import i18next from "i18next";

export function listColumns({ t, user, locations, families }) {
  let columns = [];
  columns.push({
    field: "name",
    headerName: t("productsDproms.DpromsList.columns.name"),
    align: "left",
    headerAlign: "left",
  });
  if (user?.entity?.type === "P") {
    columns.push({
      field: "ownerName",
      headerName: t("productsDproms.DpromsList.columns.ownerName"),
      align: "left",
      headerAlign: "left",
    });
  }
  columns.push({
    field: "locations",
    align: "left",
    headerAlign: "left",
    headerName: t("productsDproms.DpromsList.columns.locations"),
    disableSort: true,
    customValue: (v) => {
      if (v.locations?.length > 0) {
        let locationsObjs = (locations || []).filter(item => (v.locations || []).includes(item.id));
        return (
          <Grid container spacing={1}>
            {locationsObjs.map(loc =>
              <Grid key={loc.id} item>
                <Chip label={loc.name} size="small" />
              </Grid>
            )}
          </Grid>
        );
      }
      return "";
    }
  });
  columns.push({
    field: "startDate",
    headerName: t("productsDproms.DpromsList.columns.date"),
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      if (v.startDate && v.endDate) {
        return `${moment(v.startDate).format('DD-MM-YYYY')} to ${moment(v.endDate).format('DD-MM-YYYY')}`
      }
      else {
        return `${moment(v.startDate).format('DD-MM-YYYY')}`
      }
    }
  });
  columns.push({
    field: "type",
    headerName: t("productsDproms.DpromsList.columns.type"),
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return <Stack>
        <Typography style={{ margin: 0 }}>{t("common.Enums.dpromType." + v.type)}</Typography>
        {v.products?.length > 0 && v.products !== "ALL" &&
          <CustomTooltip title={
            <Grid spacing={1} container style={{ width: 600 }}>
              {v.products.map(product => (
                <Grid item xs={6} key={`product_${v.tempId || v.id}_${product.productId}`}>
                  <ProductSnip key={product.productId} image={product.thumbnail || product.image} description={product.description} gtin={product.gtin} promoterInternalCode={v.promoterInternalCode} />
                </Grid>
              ))}
            </Grid>
          }>
            <Typography style={{ margin: 0 }}>{`${v.products?.length} ${t("productsDproms.DpromsList.columns.products")}`}</Typography>
          </CustomTooltip>
        }
        {(v.family || v.tempFamily)?.length > 0 &&
          <CustomTooltip title={
            <Grid spacing={1} container style={{ width: 600 }}>
              {(v.family || v.tempFamily).map(fam => {
                // console.log('fam: %o', fam);
                return (
                  <Grid item xs={6} key={`fam_${v.tempId || v.id}_${fam}`}>
                    <Typography style={{ margin: 0 }}>{`${processFamily(families, fam)}`}</Typography>
                  </Grid>
                )
              }

              )}
            </Grid>
          }>
            <Typography style={{ margin: 0 }}>{`${v.family?.length} ${t("productsDproms.DpromsList.columns.family")}`}</Typography>
          </CustomTooltip>
        }
        {/* {v.family &&
          <Typography style={{ margin: 0 }}>{`${t("productsDproms.DpromsList.columns.family")}: ${dataTranslation(v.status === "NEW" ? v.tempFamily : v.family)}`}</Typography>
        } */}
      </Stack>
    }
  });
  columns.push({
    field: "value",
    headerName: t("productsDproms.DpromsList.columns.value"),
    align: "left",
    headerAlign: "left",
    disableSort: true,
    customValue: (v) => {
      return <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{v.value?.type === "P" ? `${v.value?.amount} %` : currencyFormat(v.value?.amount)}</Typography>
    }
  });
  columns.push({
    field: "status",
    headerName: t("productsDproms.DpromsList.columns.status"),
    align: "center",
    headerAlign: "center",
    customValue: (v) => {
      if (v.status === "ACC" && ((v.processes || []).some(({ processType, status }) => processType === "FlowRemovePromoDiscount" && status === "PENDING") || v.removePending)) {
        return t("common.Enums.dpromStatus.PNDC");
      }
      return <Stack direction="row" spacing={1}>
        <Typography>{t("common.Enums.dpromStatus." + v.status)}</Typography>
        {v.comment && <CustomTooltip title={v.comment} placement="left">
          <MessageIcon fontSize="small" style={{ display: "block" }} />
        </CustomTooltip>}
        {v.removeDate && v.status === "ACC" && <CustomTooltip title={`${t("productsDproms.DpromsList.columns.removeDate")} ${dateFormat(v.removeDate)}`} placement="left">
          <WarningAmberIcon color="warning" fontSize="small" style={{ display: "block" }} />
        </CustomTooltip>}
      </Stack>
    }
  });

  return columns;
}


const processFamily = (families, id) => {
  let selectedFamily = families.find(item => item.id.toString() === (id || "").toString());
  if (selectedFamily) {
    let selectedParent = families.find(item => item.id === selectedFamily.parent);
    let altLang = Object.keys(selectedFamily.description).filter((k) => k !== i18next.language)[0];
    let family = selectedFamily.description[i18next.language] || selectedFamily.description[altLang];
    let parent = selectedParent?.description ? (selectedParent.description[i18next.language] || selectedParent.description[altLang]) : "";
    return `${parent} - ${family}`
  }
  return "";
}
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from "@mui/material";

import { styles } from "./RevokeAccessConfirmDialog.styles";

function RevokeAccessConfirmDialog({ classes, open, setOpen, onConfirm }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-title">{t("entities.EntityExternalAccess.confirmRevoke.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("entities.EntityExternalAccess.confirmRevoke.description")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}
          color="primary"
        >
          {t("entities.EntityExternalAccess.confirmRevoke.btnCancel")}
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
            onConfirm && onConfirm();
          }}
          color="primary"
          autoFocus
        >
          {t("entities.EntityExternalAccess.confirmRevoke.btnOk")}
        </Button>
      </DialogActions>
      <br />
    </Dialog>
  );
}

export default withStyles(RevokeAccessConfirmDialog, styles)

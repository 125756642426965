import * as Yup from "yup";
import { DatePickerField, TextField } from "../../../common/forms";
import Volumes from "../_common/fields/Volumes/Volumes";

export function formSchema(t, operationType, dispatchScreen) {
  return Yup.object().shape({
    document: Yup.string().required(t("orders.AggregatedDetails.form.documentRequired")),
    documentDate: Yup.string().required(t("orders.AggregatedDetails.form.documentDateRequired")),
    transportDocument: dispatchScreen
      ? Yup.string().required(t("orders.AggregatedDetails.form.transportDocumentRequired"))
      : undefined,
    transportDocumentDate: dispatchScreen
      ? Yup.string().required(t("orders.AggregatedDetails.form.transportDocumentDateRequired"))
      : undefined,
    volumes:
      operationType === "CD"
        ? Yup.array().ensure().min(1, t("orders.AggregatedDetails.form.requiredVolumes"))
        : Yup.array(),
    products:
      operationType === "CD"
        ? Yup.array().of(
          Yup.object().shape({
            productBatch: Yup.array()
              .ensure()
              .when(["hasBatchNumber", "hasExpirationDate", "hasVariableWeight", "quantity"], {
                is: (hasBatchNumber, hasExpirationDate, hasVariableWeight, quantity) =>
                  (hasBatchNumber || hasExpirationDate || hasVariableWeight) && quantity > 0,
                then: (schema) => schema.min(1, t("orders.AggregatedDetails.form.requiredBatch")),
                otherwise: (schema) => schema,
              }),
          })
        )
        : Yup.array(),
  });
}

export function formFields({t, products, operationType, dispatchScreen, quantities}) {
  return [
    {
      size: 4,
      field: <TextField id="document" type="text" label={t("orders.AggregatedDetails.form.document")} required />,
    },
    {
      size: 4,
      field: <DatePickerField id="documentDate" label={t("orders.AggregatedDetails.form.documentDate")} required />,
    },
    {
      size: 4,
      field: <Volumes id="volumes" label="&nbsp;" products={products} />,
      hidden: operationType !== "CD",
    },
    {
      size: 4,
      hidden: operationType === "CD",
    },
    {
      size: 4,
      field: (
        <TextField
          id="transportDocument"
          type="text"
          label={t("orders.AggregatedDetails.form.transportDocument")}
          required
        />
      ),
      hidden: !dispatchScreen,
    },
    {
      size: 4,
      field: (
        <DatePickerField
          id="transportDocumentDate"
          label={t("orders.AggregatedDetails.form.transportDocumentDate")}
          required
        />
      ),
      hidden: !dispatchScreen,
    },
  ];
}

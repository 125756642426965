import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper } from "@mui/material";

import { styles } from "./Order.styles";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";

function Order({ classes, product }) {
  const { t } = useTranslation();

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h3" align="left" component="h2" className={classes.noMargin}>
            {t("products.ProductDetails.details.ordersTitle")}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <LabelValue
                label={t("products.ProductDetails.details.minQuantity")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {product?.orders?.minQuantity}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={2}>
              <LabelValue
                label={t("products.ProductDetails.details.maxQuantity")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {product?.orders?.maxQuantity}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={2}>
              <LabelValue
                label={t("products.ProductDetails.details.quantityMultiple")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {product?.orders?.quantityMultiple}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(Order, styles);
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";

import { styles } from "./PendingPurchaseForm.styles";
import { formFields, formSchema } from "./PendingPurchaseForm.schema";

import { Form, FormFields } from "../../../../common/forms";
import { useDispatch } from "react-redux";
import { productRequestPurchase, productPendingPurchaseAction } from "../../productsPurchasesSlice";

function PendingPurchaseForm({ classes, action, onSubmit, productId, purchase }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmitHandler = async (values) => {
    const { quantity, unitPrice, comment } = values;
    let nValues = {
      productId: productId || purchase?.productId,
      quantity,
      price: (parseInt(quantity) || 0) * (parseFloat(unitPrice) || 0),
      comment
    }
    if (action === "CREATE") {
      dispatch(productRequestPurchase(nValues));
    }
    else {
      dispatch(productPendingPurchaseAction({ ...nValues, productId: productId || purchase?.productId, status: action, taskId: purchase.taskId }));
    }
    onSubmit && onSubmit();
  };


  let initialValues = {};
  if (purchase) {
    initialValues = {
      quantity: purchase.quantity,
      unitPrice: purchase.newPrice / purchase.quantity,
      comment: purchase.comment
    }
  }

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmitHandler}
        schema={formSchema(t)}
        initialValues={initialValues}
        render={({ values }) => {
          return (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormFields fields={formFields({ t, values, action })} />
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" size="large" type="submit" fullWidth>
                  {action === "CREATE" && t("productsPurchases.PendingPurchaseForm.form.btnCreate")}
                  {action === "ACCEPTED" && t("productsPurchases.PendingPurchaseForm.form.btnAccept")}
                  {action === "REFUSED" && t("productsPurchases.PendingPurchaseForm.form.btnReject")}
                  {action === "PROPOSAL" && t("productsPurchases.PendingPurchaseForm.form.btnProposal")}
                  {action === "PENDING" && t("productsPurchases.PendingPurchaseForm.form.btnProposal")}
                  {action === "EXECUTED" && t("productsPurchases.PendingPurchaseForm.form.btnExecute")}
                </Button>
              </Grid>
            </Grid>
          );
        }}
      />
    </div>
  );
}

export default withStyles(PendingPurchaseForm, styles);

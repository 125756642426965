

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const planningForecastCall = async (
  { planningForecastGet },
  dispatch,
  { year, productId, forecasts }
) => {
  let rh = requestHelper(dispatch, "PLANNING_FORECAST");
  try {
    let result = await call({
      type: "PLANNING",
      subType: "FORECAST",
      request: {
        forecasts
      },
    });
    if (result) {
      dispatch(setSuccess("planning", "PLANNING_FORECAST"));
      setTimeout(() => {
        dispatch(planningForecastGet({ year, productId }))
      }, 300);
    }
  } catch (ex) {
    rh.error("planning", ex);
  }
  rh.close();
};

export default planningForecastCall;


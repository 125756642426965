import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Button, Grid} from "@mui/material";

import {styles} from "./SystemStatus.styles";

import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {useFirstLoad} from "../../../common/hooks";
import Page from "../../../common/displays/Page/Page";
import {promoterTestFlow, selectSystemStatusDetails, setSystemStatusDetails} from "../promoterSlice";
import StatusSnip from "./StatusDataGrid/StatusSnip";
import RefreshIcon from "@mui/icons-material/Refresh";


function SystemStatus({classes}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(selectSystemStatusDetails);
    const typeList = ["AUDIT", "BIGDATA", "BPM", "DOCUMENT", "ENTITY", "MANAGEMENT", "NOTIFICATION", "OPERATOR", "ORDER", "PRODUCT", "PROMOTER", "RECEIVER", "REPORT", "SENDER", "TASK", "USER"]; // "STORE",  "SUPPLIER", "WHOLESALER"

    const refreshStatus = async () => {
        return typeList.forEach((item) => {
            dispatch(promoterTestFlow(item));
        });
    };

    useFirstLoad(() => {
        typeList.forEach((item) => {
            dispatch(setSystemStatusDetails({key: item, result: {}}));
        });

       refreshStatus();
    });

    const refreshHandler = (item) => {
        dispatch(setSystemStatusDetails({key: item, result: {}}));
        dispatch(promoterTestFlow(item));
    };

    return (
        <Page
            permission="promoter.actions.systemStatus.list"
            header={<PageTitle
                title={t("promoter.systemStatus.header.title")}
                actions={
                    <Grid container direction="row" spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                onClick={refreshStatus}
                            >
                                <RefreshIcon titleAccess={t("promoter.systemStatus.header.refreshAll")} />
                            </Button>
                        </Grid>
                    </Grid>
                }
            />}>
            <Grid container spacing={3}>
                { status && typeList?.map((item) =>  <Grid item xs={3} key={"item_"+item}><StatusSnip key={item} type={item} data={status[item]} refreshHandler={refreshHandler} /></Grid>) }
            </Grid>
        </Page>
    );
}

export default withStyles(SystemStatus, styles);

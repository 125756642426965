import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useParams } from "react-router-dom";
import { useFirstLoad, useNavigate, useUser } from "../../../common/hooks";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

import { styles } from "./EntityUsers.styles";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";

import { deleteContact } from "../entitiesSlice";
import { entityGet, selectEntityDetails } from "../entitiesSlice";
import UsersList from "./UsersList/UsersList";
import { usePromoter } from "../../../common/hooks";
import Page from "../../../common/displays/Page/Page";
import Permission from "../../../app/components/Permission";

function EntityUsers({ classes }) {
  // eslint-disable-next-line
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const details = useSelector(selectEntityDetails);
  const promoter = usePromoter();
  const user = useUser();

  useFirstLoad(() => {
    dispatch(entityGet(id));
  });

  return details ? (
    <Page
      permission="entities.actions.entity.get"
      header={
        <PageTitle
          title={t("entities.Entities.contacts.title")}
          info={`${details.contacts?.length || 0}`}
          onBack={() => navigate(`/entities/${id}`)}
          actions={
            <Permission code="entities.actions.contact.add">
              {(user?.entity?.type === "P" || user?.entity?.id === id) && details?.status !== "PND" && (
                <Button variant="contained" color="primary" onClick={() => navigate(`/entities/${id}/contacts/add`)}>
                  {t("entities.Entities.contacts.btnAdd")}
                </Button>
              )}
            </Permission>
          }
        />}>
      <UsersList
        data={details.contacts}
        onEdit={(row) => navigate(`/entities/${id}/contacts/edit/${row.id}`)}
        onDelete={(row) => {
          dispatch(deleteContact(promoter, id, row.id));
        }}
      />
    </Page>
  ) : null;
}

export default withStyles(EntityUsers, styles);

import { Chip, Grid, Typography } from "@mui/material";
import { QuantityInput } from "../../../../common/inputs/custom";
import { currencyFormat } from "../../../../common/utils";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";

export function listColumns(t, classes, customQuantities, setProductQuantity, order, dispatchScreen, acceptScreen) {
  return [
    {
      field: "orderId",
      headerName: t("orders.OrdersCreate.columns.orderId"),
      align: "left",
      headerAlign: "left",
      // width: 100,
    },
    {
      field: "description",
      headerName: t("orders.OrdersCreate.columns.description"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <ProductSnip
            image={v.thumbnail || v.image}
            description={v.description}
            gtin={v.gtin}
            promoterInternalCode={v.promoterInternalCode}
          />
        );
      },
    },
    {
      field: "quantity",
      headerName: t("orders.OrdersCreate.columns.quantity"),
      align: "center",
      headerAlign: "center",
      width: 160,
      customValue: (v) => {
        let prod = customQuantities.find((item) => item.key === v.key);

        let allowQuantityInput = dispatchScreen || acceptScreen;

        let maxQuantity = dispatchScreen ? v.quantityHist?.accepted - v.quantityHist?.dispatched : v.quantityHist?.confirmed - v.quantityHist?.accepted;

        let qtyLabel;

        if (order?.status === "CO") {
          qtyLabel = (<div>{`${maxQuantity} ${t("common.Enums.transportShort.BASE_UNIT_OR_EACH")}`} </div>)
        } else if (order?.status === "AC") {
          qtyLabel = (<div>
            <b>{t("common.Enums.orderStatus.AC")}:</b>
            <br />
            {`${v.quantityHist?.accepted} ${t("common.Enums.transportShort.BASE_UNIT_OR_EACH")} / ${v.quantityHist?.confirmed
              } ${t("common.Enums.transportShort.BASE_UNIT_OR_EACH")}`}
          </div>)
        } else {
          qtyLabel = (<div>
            <b>{t("common.Enums.orderStatus.DI")}:</b>
            <br />
            {`${v.quantityHist?.dispatched} ${t("common.Enums.transportShort.BASE_UNIT_OR_EACH")} / ${v.quantityHist?.accepted
              } ${t("common.Enums.transportShort.BASE_UNIT_OR_EACH")}`}
          </div>)
        }


        // partialDispatched ? (
        //   <div>
        //     <b>{t("common.Enums.orderStatus.DI")}:</b>
        //     <br />
        //     {`${v.quantityHist?.dispatched} ${t("common.Enums.transportShort.BASE_UNIT_OR_EACH")} / ${v.quantityHist?.accepted
        //       } ${t("common.Enums.transportShort.BASE_UNIT_OR_EACH")}`}
        //   </div>
        // ) : (
        //   <div>{`${maxQuantity} ${t("common.Enums.transportShort.BASE_UNIT_OR_EACH")}`} </div>
        // );

        // let quantity = prod ? prod.quantity : v.quantity;
        // if (partialDispatched) {
        //   quantity -= (v.quantityHist?.dispatched || 0);
        // }

        return (
          <Grid container justifyContent="center" spacing={1}>
            <Grid item>
              {allowQuantityInput ? (
                v.quantityHist?.dispatched === v.quantityHist?.accepted && order?.status === "DI" ? (
                  qtyLabel
                ) : (
                  <>
                    <QuantityInput
                      slim
                      style={{ width: 120 }}
                      max={maxQuantity}
                      multi={1}
                      disabled={Boolean((!prod && v.quantity <= 0) || maxQuantity === 0)}
                      value={prod ? prod.quantity : maxQuantity}
                      onChange={(val) => setProductQuantity(v, val)}
                    />
                    {qtyLabel}
                  </>
                )
              ) : (
                qtyLabel
              )}
            </Grid>
            {/* <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={(v) => acceptProductHandler(v)}
              >
                Accept
              </Button>
            </Grid> */}
          </Grid>
        );
      },
    },
    {
      field: "price",
      headerName: t("orders.OrdersCreate.columns.price"),
      align: "right",
      headerAlign: "center",
      width: 130,
      customValue: (v) => {
        return v.priceType !== "O" ?
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.nomargin}>{currencyFormat(v.price)}</Typography>
                </Grid>
                {v.priceType === 'C' && <Grid item>
                  <Typography color="textSecondary" className={classes.nomargin}>
                    {t("orders.PriceSelector.type." + v.priceType)}
                  </Typography>
                </Grid>}
              </Grid>
          : <Chip label={t("orders.OrdersCreate.columns.offer")} color="warning" size="small" variant="outlined" />;
      },
    },
    {
      field: "vat",
      headerName: t("orders.OrdersCreate.columns.tax"),
      align: "right",
      headerAlign: "center",
      width: 130,
      customValue: (v) => v.vat !== undefined && v.priceType !== "O" ? <Typography style={{ whiteSpace: "nowrap", margin: 0 }}>{v.vat} %</Typography> : ""
    },
    {
      field: "total",
      headerName: t("orders.OrdersCreate.columns.total"),
      align: "right",
      width: 130,
      headerAlign: "center",
      customValue: (v) => {
        return currencyFormat(v.price * v.quantity);
      },
    },
  ];
}

import { dateTimeFormatWithMilliSeconds } from "../../../../common/utils/formats";

export function listColumns(t, classes) {
  return [
    {
      field: "type",
      headerName: t("business.AuditsList.columns.type"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (v.type + "/" + v.subType);
      },
    },{
      field: "method",
      headerName: t("business.AuditsList.columns.method"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "entity",
      headerName: t("business.AuditsList.columns.entity"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "userRequest",
      headerName: t("business.AuditsList.columns.userRequest"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "requestTime",
      headerName: t("business.AuditsList.columns.requestTime"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.requestTime));
      },
    },
    {
      field: "responseTime",
      headerName: t("business.AuditsList.columns.responseTime"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.responseTime));
      },
    },
    {
      field: "error",
      headerName: t("business.AuditsList.columns.error"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (v.error ? t("business.AuditsList.columns.errorTrue") : t("business.AuditsList.columns.errorFalse"));
      },
    },
  ];
}

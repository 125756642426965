import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

import { styles } from "./PurchaseFlowHistory.styles";
import { currencyFormat, dateTimeFormat } from "../../../../../common/utils";
import CustomList from "../../../../../common/displays/CustomList/CustomList";

function PurchaseFlowHistory({ classes, processId, history = [] }) {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <CustomList
        customKey={processId}
        items={history}
        render={(item, index) => (
          <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
            <Grid item xs={4}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Typography className={classes.datetime} component="span" variant="caption" color="textPrimary">
                    {dateTimeFormat(item?.dateOut, "DD MMM")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.datetime} component="span" variant="subtitle1" color="textSecondary">
                    {dateTimeFormat(item?.dateOut, "HH:mm")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Typography className={classes.nomargin} component="h6" variant="h4" color="textPrimary">
                    {t("common.Enums.priceActionStatus." + item?.status)}
                  </Typography>
                </Grid>
                {(item?.status === "PENDING" || item?.status === "PROPOSAL" || item?.status === "ACCEPTED") && (
                  <Grid item>
                    <Typography className={classes.nomargin} component="span" variant="caption" color="textSecondary" style={{ whiteSpace: "nowrap" }}>
                      {item?.request?.quantity + " - " + currencyFormat(item?.request?.price)}
                    </Typography>
                  </Grid>
                )}
                {item?.request?.comment && <Grid item>
                  <Typography className={classes.nomargin} component="span" variant="caption" color="textSecondary">
                    {item?.request?.comment}
                  </Typography>
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
        )}
      />
    </div>
  );
}

export default withStyles(PurchaseFlowHistory, styles);

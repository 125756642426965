import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";

import {styles} from "./TimerDetails.styles";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {useFirstLoad, useNavigate} from "../../../common/hooks";
import {useParams} from "react-router-dom";
import {Button, Grid, Paper, Typography} from "@mui/material";
import LabelValue from "../../../common/displays/LabelValue/LabelValue";
import Permission from "../../../app/components/Permission";
import {dateTimeFormat, dateTimeFormatWithMilliSeconds} from "../../../common/utils/formats";
import {promoterChangeStatusTimer, promoterGetTimer, selectTimerDetails} from "../promoterSlice";
import {jsonTryParse} from "../../../common/utils";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import {selectLoader} from "../../../app/coreSlice";

function TimerDetails({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(selectLoader);
  const navigate = useNavigate();
  const { id } = useParams();
  const detail = useSelector(selectTimerDetails);

  useFirstLoad(() => {
    dispatch(promoterGetTimer(id));
  })

    const request = jsonTryParse(detail?.request);

    const disableTimer = () => {
        dispatch(promoterChangeStatusTimer(id, false));
    }

    const enableTimer = () => {
        dispatch(promoterChangeStatusTimer(id, true));
    }


  return <Page
    permission="promoter.actions.timer.get"
    header={
      <PageTitle
        title={detail?.description}
        onBack={() => navigate('/promoter/timer')}
        actions={
            <Grid container direction="row" spacing={2}>
                <Permission code="promoter.actions.timer.update">
                    {detail?.active && <Grid item>
                        <Button variant="contained" color="primary" size="large"
                                onClick={disableTimer}
                                disabled={loading}>
                            {t("promoter.timer.details.btnDisable")}
                        </Button>
                    </Grid>}
                    {!detail?.active && <Grid item>
                        <Button variant="contained" color="primary" size="large"
                                onClick={enableTimer}
                                disabled={loading}>
                            {t("promoter.timer.details.btnEnable")}
                        </Button>
                    </Grid>}
                    <Grid item>
                        <Button variant="contained" color="primary" size="large" onClick={() => navigate("/promoter/timer/edit/"+id)}>
                            {t("promoter.timer.details.btnEdit")}
                        </Button>
                    </Grid>
                </Permission>
            </Grid>
        }
      />
    }>
    {detail && (
      <Grid container spacing={12}>
        <Grid item xs={12}>

            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <LabelValue
                            label={t("promoter.timer.details.description")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {detail?.description}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LabelValue
                            label={t("promoter.timer.details.status")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {t("promoter.timer.timerList.columns_active_"+detail.active)}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                     <Grid item xs={3}>
                        <LabelValue
                            label={t("promoter.timer.details.type")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {detail?.type}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LabelValue
                            label={t("promoter.timer.details.subtype")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {detail?.subtype}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LabelValue
                            label={t("promoter.timer.details.userRequest")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {detail?.userRequest}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    { detail?.dayOfMonth !== 0 && <Grid item xs={3}>
                        <LabelValue
                            label={t("promoter.timer.details.dayOfMonth")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    { detail.dayOfMonth === 0 ? "-" : detail.dayOfMonth }
                                </Typography>
                            }
                        />
                    </Grid>}

                    { detail?.dayOfWeek !== 0 && <Grid item xs={3}>
                        <LabelValue
                            label={t("promoter.timer.details.dayOfWeek")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    { detail.dayOfWeek === 0 ? "-" : t("common.Enums.weekday."+detail.dayOfWeek) }
                                </Typography>
                            }
                        />
                    </Grid>}

                    <Grid item xs={3}>
                        <LabelValue
                            label={t("promoter.timer.details.time")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {dateTimeFormat(detail?.time, "HH:mm")}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={3}>
                        <LabelValue
                            label={t("promoter.timer.details.createdBy")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {detail?.createdBy}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LabelValue
                            label={t("promoter.timer.details.createdTime")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {dateTimeFormatWithMilliSeconds(detail?.createdTime)}
                                </Typography>
                            }
                        />
                    </Grid>


                    <Grid item xs={3}>
                        <LabelValue
                            label={t("promoter.timer.details.updatedBy")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {detail?.updatedBy}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LabelValue
                            label={t("promoter.timer.details.updatedTime")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {dateTimeFormatWithMilliSeconds(detail?.updatedTime)}
                                </Typography>
                            }
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <LabelValue
                            label={t("promoter.timer.details.request")}
                            value={
                                typeof request === "object" ? <div className={classes.json}>
                                    <JSONInput
                                        id="json-input"
                                        placeholder={request}
                                        viewOnly
                                        locale={locale}
                                        confirmGood={false}
                                        theme={"light_mitsuketa_tribute"}
                                    />
                                </div> : request
                            }
                        />
                    </Grid>
                </Grid>
            </Paper>
      </Grid>
      </Grid>
)

}
      </Page>
}

export default withStyles(TimerDetails, styles);
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "../../../common/hooks";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, LinearProgress, Grid, Button } from "@mui/material";

import { styles } from "./PasswordUpdate.styles";
import { formFields, formSchema } from "./PasswordUpdate.schema";

import { selectLoader, selectUpdatePassError, selectStatus, passwordUpdate } from "../authenticationSlice";

import AuthenticationContainer from "../../../common/components/containers/AuthenticationContainer";
import { Form, FormFields } from "../../../common/forms";
import { Alert } from '@mui/material';

function PasswordUpdate({ classes }) {
  const { t } = useTranslation();
  const loader = useSelector(selectLoader);
  const error = useSelector(selectUpdatePassError);
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token } = useParams();

  useEffect(() => {
    if (status === "PASSWORD_UPDATED") {
      navigate("/login/");
    }
    // eslint-disable-next-line
  }, [status]);

  const onSubmit = async (values) => {
    dispatch(passwordUpdate({ password: values.password, token }));
  };

  return (
    <AuthenticationContainer imgUrl={process.env.REACT_APP_BACKGROUND_IMAGE_URL}>
      <div className={classes.root}>
        {status !== "PASSWORD_UPDATED" && (
          <div>
            <Typography variant="h2" align="left" component="h1">
              {t("authentication.PasswordUpdate.form.title")}
            </Typography>
            <Typography variant="subtitle1" align="left" component="h6" color="textSecondary">
              {t("authentication.PasswordUpdate.form.subtitle")}
            </Typography>
            <Form
              schema={formSchema(t)}
              initialValues={{}}
              onSubmit={onSubmit}
              render={({ submitting, pristine }) => {
                return (
                  <>
                    <FormFields fields={formFields(t)} />
                    <br />
                    <Grid container direction="column" spacing={1}>
                      {error && (
                        <Grid item xs={12}>
                          <Alert variant="filled" severity="error">
                            {t("authentication.PasswordUpdate.form." + error)}
                          </Alert>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          className={classes.btn}
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={submitting || pristine}
                        >
                          {t("authentication.PasswordUpdate.form.submit")}
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                );
              }}
            />
            <Link className={classes.link} to={`/login`}>
              {t("authentication.RecoverPass.form.back")}
            </Link>
          </div>
        )}
        {loader && <LinearProgress className={classes.loader} />}
      </div>
    </AuthenticationContainer>
  );
}

export default withStyles(PasswordUpdate, styles)

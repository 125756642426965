import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Divider, Grid, Button } from "@mui/material";

import { selectEntityDetails, entityGet } from "../../entitiesSlice";
import {usePrevious, useUser, useNavigate, usePromoter} from "../../../../common/hooks";
import { getCountryName } from "../../../../common/utils";
import { styles } from "./EntityDrawerDetails.styles";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";
import ContactsLiteList from "./ContactsLiteList/ContactsLiteList";
import Permission from "../../../../app/components/Permission";

function EntityDrawerDetails({ classes, id }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const details = useSelector(selectEntityDetails);
  const dispatch = useDispatch();
  const prevId = usePrevious(id);
  const user = useUser();
  const promoter = usePromoter();

  useEffect(() => {
    if (id && id !== prevId) {
      dispatch(entityGet(id));
    }
    // eslint-disable-next-line
  }, [id, prevId]);

  return details && details.entityId === id ? (
    <div id="EntityDrawerDetails" className={classes.root}>
      <Typography variant="h3" align="left" component="h2" className={classes.title}>
        {details.name}
      </Typography>
      <Typography variant="body1" align="left" component="span" color="textSecondary" className={classes.type}>
        {t("common.Enums.entityType." + details.type)}
      </Typography>

      <Divider className={classes.divider} />

      <LabelValue
        label={t("entities.Entities.details.taxNumber")}
        value={
          <Typography variant="body1" align="left" component="span">
            {details?.taxNumber}
          </Typography>
        }
      />

      <LabelValue
        label={t("entities.Entities.details.website")}
        value={
          <Typography variant="body1" align="left" component="span">
            {details?.website}
          </Typography>
        }
      />
      <LabelValue
        label={t("entities.Entities.details.fax")}
        value={
          <Typography variant="body1" align="left" component="span">
            {details?.fax}
          </Typography>
        }
      />
      <LabelValue
        label={t("entities.Entities.details.phone")}
        value={
          <Typography variant="body1" align="left" component="span">
            {details?.phone}
          </Typography>
        }
      />
      <LabelValue
        label={t("entities.Entities.details.email")}
        value={
          <Typography variant="body1" align="left" component="span">
            {details?.email}
          </Typography>
        }
      />
      <LabelValue
        label={t("entities.Entities.details.address")}
        type="address"
        value={
          <Grid container direction="column">
            <Typography variant="body1" align="left" component="span" className={classes.noMargin}>
              {details?.taxAddress?.address}
            </Typography>
            {(details?.taxAddress?.locale || details?.taxAddress?.postalCode) && <Typography variant="body1" align="left" component="span" className={classes.noMargin}>
              {[details?.taxAddress?.locale, details?.taxAddress?.postalCode].filter(item => item).join(" - ")}
            </Typography>}
            <Typography variant="body1" align="left" component="span" className={classes.noMargin}>
              {getCountryName(details?.taxAddress?.country)}
            </Typography>
          </Grid>
        }
      />
      { details?.promoterInternalCode && user?.isPromoter && promoter?.getConfig("entity", "requiresInternalCode") &&
          <LabelValue
              label={t("entities.Entities.details.promoterInternalCode")}
              value={
                  <Typography variant="body1" align="left" component="span">
                      {details?.promoterInternalCode}
                  </Typography>
              }
          />
      }




      {details?.contacts && (
        <div>
          <br />
          <LabelValue
            label={t("entities.Entities.contacts.title")}
            value={<ContactsLiteList contacts={details?.contacts} />}
          />
        </div>
      )}
      {details?.addresses && (
        <div>
          <br />
          <LabelValue
            label={t("entities.Entities.addresses.title")}
            value={
              <div>
                {details?.addresses.map((address) => (
                  <Grid container direction="column" key={"address_" + address.id}>
                    <Typography variant="body1" align="left" component="span" className={classes.addressDescription}>
                      {address.description || t("entities.Entities.addresses.defaultName")}
                    </Typography>
                    <Typography variant="body1" align="left" component="span" className={classes.noMargin}>
                      {address.address}
                    </Typography>
                    <Typography variant="body1" align="left" component="span" className={classes.noMargin}>
                      {address.locale + " - " + address.postalCode}
                    </Typography>
                    <Typography variant="body1" align="left" component="span" className={classes.noMargin}>
                      {getCountryName(address.country)}
                    </Typography>
                    <br />
                  </Grid>
                ))}
              </div>
            }
          />
        </div>
      )}

      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Permission code="entities.actions.entity.get">
          {
            ((user.permissionFilter("entities.filters.entity.get.includeSelf") && user?.entity?.id === id) || (user.permissionFilter("entities.filters.entity.get.type") || []).includes(details.type)) &&
            <Grid item xs={12}>
              <Button variant="outlined" fullWidth color="primary" onClick={() => navigate(`/entities/${id}`)}>
                {t("entities.Entities.drawer.detailsBtn")}
              </Button>
            </Grid>
          }
        </Permission>
      </Grid>
    </div>
  ) : null;
}

export default withStyles(EntityDrawerDetails, styles);

import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

import ActionsPopover from "../../../../../common/components/ActionsPopover/ActionsPopover";
import { usePromoter, useUser } from "../../../../../common/hooks";

export default function ExtraPricesVariantsDataGridActions({ row, onRemove, product }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const user = useUser();
  const allowedToChange = user?.entity?.type !== "P" || !promoter?.getConfig("product", "requiresInternalCode") || product.promoterInternalCode;

  let actionsButtons = [];

  const onRemoveHandler = (e) => {
    e.preventDefault();
    onRemove && onRemove({ variant: row, product });
  }

  // REMOVE
  if (user.hasPermission("products.actions.price.remove") && allowedToChange && row?.extraPrice && !row?.extraPrice?.removeDate) {
    actionsButtons.push(
      <Button onClick={onRemoveHandler} color="error" fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <DeleteIcon />
          </Grid>
          <Grid item>{t("productsPrices.PricesList.actions.remove")}</Grid>
        </Grid>
      </Button>
    );
  }

  return (
    <Grid container spacing={1} justifyContent="flex-end" alignItems="center" flexWrap="nowrap">
      {actionsButtons.length > 0 &&
        <Grid item>
          <ActionsPopover
            actions={actionsButtons}
          />
        </Grid>
      }
    </Grid>
  );
}

import { processImageUrl } from "../../../api/s3";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { asyncForEach } from "../../../common/utils";
import { getOrderDocumentsSignedUrls } from "./_auxiliars";

const orderAggregateDetailsCall = async ({ setAggregatedOrderDetails }, dispatch, state, { id }) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "ORDER_AGGREGATE_DETAILS");
  try {
    let result = await call({
      type: "ORDER",
      subType: "AGGREGATE_DETAILS",
      request: {
        id,
      },
    });
    let products = [];
    await asyncForEach(result.orderDetails.products, async (product) => {
      products.push({
        ...product,
        image: await processImageUrl(fileStorageConfigs,  product.image),
        thumbnail: await processImageUrl(fileStorageConfigs,  product.thumbnail),
        key: product.productId + product.orderId + product.priceType,
      });
    });

    dispatch(setAggregatedOrderDetails({
      products,
      cutOffTime: result?.orderDetails.cutOffTime,
      operationType: result?.orderDetails.operationType,
      aggregateOrderInfo: result?.orderDetails.aggregateOrderInfo,
      ...result?.orderDetails?.orderInfo,
      documents: await getOrderDocumentsSignedUrls(fileStorageConfigs, result?.orderDetails?.orderInfo?.documents),
      comments: processComments(result?.orderDetails.comments)
    }));
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
};

const processComments = (comments) => {
  let commentsArray = [];
   comments.forEach((comment) => {
     Object.keys(comment).forEach((key) => {
      commentsArray.push( {
        orderId: key,
        comment: comment[key]
      });
    });
  });
   return commentsArray;
}
export default orderAggregateDetailsCall;
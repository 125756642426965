import moment from "moment";

export function dateFormat(date) {
  if (!date) {
    return "";
  }
  return moment.utc(date).local().format("yyyy-MM-DD");
}

export function dateTimeFormat(date, format = "yyyy-MM-DD HH:mm") {
  if (!date) {
    return "";
  }
  return moment.utc(date).local().format(format);
}

export function dateTimeFormatWithSeconds(date, format = "yyyy-MM-DD HH:mm:ss") {
  if (!date) {
    return "";
  }
  return moment.utc(date).local().format(format);
}
export function dateTimeFormatWithMilliSeconds(date, format = "yyyy-MM-DD HH:mm:ss:SSS") {
  if (!date) {
    return "";
  }
  return moment.utc(date).local().format(format);
}

export function currencyFormat(value, currency = "€") {
  if (!value && value !== 0) {
    return "";
  }
  return `${parseFloat(value).toFixed(2)} ${currency}`;
}

export function weightFormat({ value, unit }) {
  if (!value && value !== 0) {
    return "";
  }
  switch (unit) {
    case "KGM": {
      return parseFloat(value).toFixed(2) + " Kg";
    }
    default:
      return "";
  }
}

export function measureFormat({t, measure, decimalPlaces }) {
  const {value, unit} = measure
  return `${parseFloat(value).toFixed(decimalPlaces)} ${t("common.Enums.measureUnits." + unit)}`;
}

// MASS: [
//   { value: "MASS", text: t("common.Enums.measureCategory.MASS"), disabled: true },
//   { value: "KGM", text: t("common.Enums.measureUnits.KGM") },
//   { value: "GRM", text: t("common.Enums.measureUnits.GRM") },
//   { value: "TNE", text: t("common.Enums.measureUnits.TNE") },
// ],
// COMP: [
//   { value: "COMP", text: t("common.Enums.measureCategory.COMP"), disabled: true },
//   { value: "MTR", text: t("common.Enums.measureUnits.MTR") },
//   { value: "CMT", text: t("common.Enums.measureUnits.CMT") },
//   { value: "MMT", text: t("common.Enums.measureUnits.MMT") }
// ],
// VOLU: [
//   { value: "VOLU", text: t("common.Enums.measureCategory.VOLU"), disabled: true },
//   { value: "LTR", text: t("common.Enums.measureUnits.LTR") },
//   { value: "CLT", text: t("common.Enums.measureUnits.CLT") },
//   { value: "MLT", text: t("common.Enums.measureUnits.MLT") }
// ],
// OUTR: [
//   { value: "OUTR", text: t("common.Enums.measureCategory.OUTR"), disabled: true },
//   { value: "H87", text: t("common.Enums.measureUnits.H87") }
// ],

import React from "react";
import { withStyles } from "tss-react/mui";
import { Tooltip } from "@mui/material";

import { styles } from "./CustomTooltip.styles";
const StyledTooltip = withStyles(Tooltip, styles);

function CustomTooltip({ title, placement, children, onClose }) {
  if (title) {
    return (
      <StyledTooltip title={title} placement={placement} onClose={onClose}>
        {children}
      </StyledTooltip>
    )
  }
  else {
    return children;
  }
}

export default CustomTooltip;

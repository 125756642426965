import {requestHelper} from "../../../app/coreSlice";
import {call} from "../../../app/websocket/_sliceCalls/websocket";

const promoterListTimerCall = async ({ clearPromoterTimerList, setPromoterTimerList, setPromoterTimerListTotalRows, setPromoterTimerFilter }, dispatch, state, { filter, clear }) => {
  let rh = requestHelper(dispatch, "PROMOTER_LIST_TIMER");
  const promoterCode = filter.promoterCode ? filter.promoterCode : state.core.promoterDetails?.details?.code;
  if (clear) {
    dispatch(clearPromoterTimerList());
  }

  dispatch(setPromoterTimerFilter({
    ...filter,
    promoterCode
  }));

  try {
    let result = await call({
      type: "PROMOTER",
      subType: "LIST_TIMER",
      request: {
        ...filter,
        promoterCode
      }
    });

    if (result && result.timers && result.timers.length > 0) {
      let timers = result.timers.map((item) => {
        return {
          ...item,
          time: "1970-01-01T" + ("00" + item.hour).slice(-2) + ":" + ("00" + item.minute).slice(-2) + ":00",
        };
      });
      dispatch(setPromoterTimerList(timers));
    }
    dispatch(setPromoterTimerListTotalRows(result.rows));
  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default promoterListTimerCall;

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";

const promoterUpdateLocationCall = async ({ promoterListLocations }, dispatch, { id, name, code, states }) => {
  let rh = requestHelper(dispatch, "PROMOTER_UPDATE_LOCATION");
  try {
    await call({
      type: "PROMOTER",
      subType: "UPDATE_LOCATION",
      request: { id, name, code, states },
    });
    setTimeout(() => {
      dispatch(setRedirect("/promoter/configs/locations"));
      dispatch(setSuccess("promoter", "PROMOTER_UPDATE_LOCATION"));
      dispatch(promoterListLocations());
    }, 300);
  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default promoterUpdateLocationCall;
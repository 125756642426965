import { requestHelper, setSuccess, setError, setRedirect } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import moment from "moment";

const orderCreateIncidentCall = async (dispatch, { orderId, code, document, deliveryTime, products }) => {
  let rh = requestHelper(dispatch, "ORDER_CREATE_INCIDENT");
  try {
    let result = await call({
      type: "ORDER",
      subType: "CREATE_INCIDENT",
      request: {
        orderId,
        code,
        document,
        deliveryTime: moment.isMoment(deliveryTime) ? deliveryTime.toISOString() : deliveryTime,
        products,
      },
    });
    if (result) {
      dispatch(setSuccess("ordersIncidents", "ORDER_CREATE_INCIDENT"));
      dispatch(setRedirect("/orders"));
    } else {
      dispatch(setError("ordersIncidents", "ORDER_CREATE_INCIDENT"));
    }
  } catch (ex) {
    rh.error("ordersIncidents", ex);
  }
  rh.close();
};

export default orderCreateIncidentCall;

import React from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./CountryAutoComplete.styles";
import AutoCompleteInput from "../../base/AutoCompleteInput";

import { getCountries } from "../../../utils";

function CountryAutoComplete({ classes, label, value, onChange, disabled, required, error, id }) {
  const countries = getCountries();

  const onChangeHandler = (countryCode) => {
    onChange && onChange(countryCode);
  };

  return countries?.length > 0 ? (
    <AutoCompleteInput
      id={id}
      required={required}
      label={label}
      value={value || ""}
      onChange={onChangeHandler}
      disabled={disabled}
      options={countries}
      variant="outlined"
      error={error}
    />
  ) : null;
}

export default withStyles(CountryAutoComplete, styles)

import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";

import {styles} from "./ProcessDetails.styles";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {useFirstLoad, useNavigate} from "../../../common/hooks";
import {useParams} from "react-router-dom";
import {businessGetProcessDetail, businessProcessTaskAddUser, selectProcessDetails} from "../businessSlice";
import {Button, Grid, Paper, Typography} from "@mui/material";
import LabelValue from "../../../common/displays/LabelValue/LabelValue";
import ProcessHistoryDataGrid from "./ProcessHistoryDataGrid/ProcessHistoryDataGrid";
import ProcessBusinessHistoryDataGrid from "./ProcessBusinessHistoryDataGrid/ProcessBusinessHistoryDataGrid";
import Permission from "../../../app/components/Permission";
import TaskAddUserModal from "./TaskAddUserModal/TaskAddUserModal";
import {dateTimeFormatWithMilliSeconds} from "../../../common/utils/formats";

function ProcessDetails({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { processId } = useParams();
  const detail = useSelector(selectProcessDetails);
  const [modal, setModal] = useState(false);
 // const history = useSelector(selectProcessHistory);

  useFirstLoad(() => {
    dispatch(businessGetProcessDetail(processId));
    //dispatch(businessGetProcessHistory(processId));
  })

  const historyData = detail?.processHistory?.map((value) => {
      return {
          ...value,
          comment:  JSON.stringify(value.comment)
      };
  });

  const businessHistoryData = detail?.businessHistory?.map((value) => {
      return {
          ...value,
          request:  JSON.stringify(value.request)
      };
  });

    const processDataUserAssignees = detail?.processData?.userAssignees?.map((value) => {
        return value+", "
    });

    const businessDataAssignedUsers = detail?.businessData?.assignedUsers?.map((value) => {
        return value+", "
    });


    const onAddUserHandler = (user) => {
        dispatch(businessProcessTaskAddUser(processId, detail?.processData?.taskId, user));
        setModal(false);
    }

  return <Page
    permission="business.actions.process.get"
    header={
      <PageTitle
        title={detail?.processData?.processType + " - " + dateTimeFormatWithMilliSeconds(detail?.processData?.date)}
        onBack={() => navigate('/activity/process')}
        actions={
            <Grid container direction="row" spacing={2}>
                <Permission code="business.actions.process.update">
                    <Grid item>
                       <Button variant="text" color="primary" size="large" onClick={() => setModal(true)}>
                            {t("business.process.details.btnAddUser")}
                        </Button>
                    </Grid>
                </Permission>
            </Grid>
        }
      />
    }>
    {detail && (
      <Grid container spacing={12}>
        <Grid item xs={12}>

            <Typography component="span" variant="caption" color="textPrimary">
                {t("business.process.details.processData")}
            </Typography>

            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <LabelValue
                            label={t("business.process.details.processData_type")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {detail?.processData?.type}
                                </Typography>
                            }
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <LabelValue
                            label={t("business.process.details.processData_processType")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {detail?.processData?.processType}
                                </Typography>
                            }
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <LabelValue
                            label={t("business.process.details.processData_processId")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {detail?.processData?.processId}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <LabelValue
                            label={t("business.process.details.processData_taskId")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {detail?.processData?.taskId}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <LabelValue
                            label={t("business.process.details.processData_state")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {detail?.processData?.state}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <LabelValue
                            label={t("business.process.details.processData_status")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {detail?.processData?.status}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <LabelValue
                            label={t("business.process.details.processData_date")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {dateTimeFormatWithMilliSeconds(detail?.processData?.date)}
                                </Typography>
                            }
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <LabelValue
                            label={t("business.process.details.processData_active")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                   {t("business.process.processList.status_" + detail?.processData?.active)}
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <LabelValue
                            label={t("business.process.details.processData_createdBy")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {detail?.processData?.createdBy}
                                </Typography>
                            }
                        />
                    </Grid>

                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <LabelValue
                            label={t("business.process.details.processData_userAssignees")}
                            value={
                                <Typography variant="body1" align="left" component="span">
                                    {processDataUserAssignees}
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>

            </Paper>

          <Typography component="span" variant="caption" color="textPrimary">
             {t("business.process.details.businessData")}
          </Typography>

          <Paper className={classes.paper}>
              <Grid container spacing={2}>
                  <Grid item xs={4}>
                      <LabelValue
                          label={t("business.process.details.businessData_dueDate")}
                          value={
                              <Typography variant="body1" align="left" component="span">
                                  {dateTimeFormatWithMilliSeconds(detail?.businessData?.dueDate)}
                              </Typography>
                          }
                      />
                  </Grid>

                  <Grid item xs={4}>
                      <LabelValue
                          label={t("business.process.details.businessData_entityId")}
                          value={
                              <Typography variant="body1" align="left" component="span">
                                  {detail?.businessData?.entityId}
                              </Typography>
                          }
                      />
                  </Grid>

                  <Grid item xs={4}>
                      <LabelValue
                          label={t("business.process.details.businessData_taskActionDate")}
                          value={
                              <Typography variant="body1" align="left" component="span">
                                  {dateTimeFormatWithMilliSeconds(detail?.businessData?.taskActionDate)}
                              </Typography>
                          }
                      />
                  </Grid>
              </Grid>
              <Grid container spacing={2}>
                  <Grid item xs={10}>
                      <LabelValue
                          label={t("business.process.details.businessData_assignedUsers")}
                          value={
                              <Typography variant="body1" align="left" component="span">
                                  {businessDataAssignedUsers}
                              </Typography>
                          }
                      />
                  </Grid>
              </Grid>

          </Paper>


          <Grid item xs={12}>
              <Typography component="span" variant="caption" color="textPrimary">
                  {t("business.process.details.businessHistory")}
              </Typography>
              <ProcessBusinessHistoryDataGrid
                  data={businessHistoryData}
                  totalRows={ businessHistoryData?.length}
              />
          </Grid>

          <Grid item xs={12}>
              <Typography component="span" variant="caption" color="textPrimary">
                  {t("business.process.details.processHistory")}
              </Typography>
              <ProcessHistoryDataGrid
                  data={historyData}
                  totalRows={ historyData?.length}
              />
          </Grid>
      </Grid>
          <TaskAddUserModal open={modal} setOpen={setModal} onSubmit={onAddUserHandler} />
      </Grid>
)

}
      </Page>
}

export default withStyles(ProcessDetails, styles);
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./OrdersOverviewDataGrid.styles";
import { listColumns } from "./OrdersOverviewDataGrid.columns";
import { csvFields } from "./OrdersOverviewDataGrid.export";
import { filterFields, filterSchema } from "./OrdersOverviewDataGrid.filter";
import { queriesReportOrdersOverviewDefaultFilter } from "../../_common/businessFilters";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";

function OrdersOverviewDataGrid({
  classes,
  data,
  filter,
  totalRows,
  onRowSelected,
  onFilterChange,
  onLoadMore,
}) {
  const { t } = useTranslation();

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          columns={listColumns(t, classes)}
          rows={data}
          onFilter={onFilterHandler}
          onLoadMore={onLoadMore}
          onRowSelected={onRowSelected}
          total={totalRows}
          exportCsvFields={csvFields(t)}
          exportXlsxFields={csvFields(t)}
          topActions={
            <FilterForm
              style={{ marginBottom: 8 }}
              storageKey="OrdersOverviewDataGrid"
              onStorageLoad={onFilterHandler}
              slim
              fields={filterFields()}
              schema={filterSchema()}
              onFilterChange={onFilterHandler}
              filter={filter}
              defaultFilter={queriesReportOrdersOverviewDefaultFilter}
            />
          }
        />
      )}
    </div>
  );
}

export default withStyles(OrdersOverviewDataGrid, styles)

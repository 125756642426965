const getStateOptions = (promoter, country) => {
  let states = (promoter?.refData?.state || []).find(item => item?.countryCode === country)?.states || [];
  if (!states) {
    return [];
  }
  return states.map((item) => {
    return {
      value: item,
      text: item,
    };
  });
};

export default getStateOptions;
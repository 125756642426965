import * as Yup from "yup";
import {SelectField, TextField} from "../../../../common/forms";
import React from "react";

export const filterFields = () => (t) => {
    return [
        {
            size: 12,
            field: (
                <SelectField
                    id="status"
                    label={t("promoter.timer.filter.status")}
                    options={getActiveOptions(t)}/>
            ),
        },
        {
            size: 12,
            field: (
                <SelectField
                    id="subType"
                    label={t("promoter.timer.filter.subType")}
                    options={getSubTypesOptions(t)}
                />
            ),
        },
        {
            size: 12,
            field: <TextField
                id="description"
                label={t("promoter.timer.filter.description")}/>,
        },
    ];
};

export const filterSchema = () => (t) => {
    return Yup.object().shape({

    });
};
const getActiveOptions = (t) => {
    return [
        {
            value: null,
            text: t("promoter.timer.filter.optionAll"),
        },
        {
            value: true,
            text: t("promoter.timer.filter.optionActive"),
        },
        {
            value: false,
            text: t("promoter.timer.filter.optionInactive"),
        },
    ];
};

const getSubTypesOptions = (t) => {
    return ["AUTO_CLOSE","CHANGE_AVAILABILITY","CHECK_DOC_EXP","CHECK_DPROM_EXP","CHECK_EXTERNAL_CHANGES","CHECK_SCHEDULED_TAXES","CHECK_TASK_DUE_DATE","CUT_OFF","FILE_CLEANER","IMPORT_DOCUMENT","OUT_DATED_CAMPAIGNS","PRICE_TO_UPDATE","PROCESS_AWAITING","REMOVE_EXPIRED_PRICE_PROMOTIONS","TASK_BATCHES","TASK_PLACES"]
        .map((value) => {
                return {
                    value,
                    text: value,
                };
            });
};

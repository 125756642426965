import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const entityUpdateAddressCall = async ({ entityGet }, dispatch, { id, entityId, description, contactId }) => {
  let rh = requestHelper(dispatch, "ENTITY/UPDATE_ADDRESS");
  try {
    await call({
      type: "ENTITY",
      subType: "UPDATE",
      request: {
        id,
        entityId,
        address: {
          type: "O",
          description,
          contactId
        },
      },
    });
    dispatch(setSuccess("entities", "ENTITY_UPDATE_ADDRESS"));
    setTimeout(() => {
      dispatch(setRedirect(`/entities/${entityId}/addresses`));
      dispatch(entityGet(entityId));
    }, 300);
  } catch (ex) {
    rh.error("entities", ex);
  }
  setTimeout(() => {
    rh.close();
  }, 250);
};

export default entityUpdateAddressCall;


import { processImageUrl } from "../../../api/s3";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { asyncForEach } from "../../../common/utils";
import { getOrderDocumentsSignedUrls } from "./_auxiliars";


const orderDetailsLoCall = async ({ setOperatorOrderDetails }, dispatch, state, { id, dispatchId }) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "GET_ORDER_LO");
  try {
    let result = await call({
      type: "ORDER",
      subType: "DETAILS_LO",
      request: {
        id,
        dispatchId,
      },
    });
    let products = [];
    if (result.details.products) {
      await asyncForEach(result.details.products, async (product) => {
        products.push({
          ...product,
          image: await processImageUrl(fileStorageConfigs,  product.image),
          thumbnail: await processImageUrl(fileStorageConfigs,  product.thumbnail),
          key: product.orderId + "_" + product.dispatchId + "_" + product.productId,
        });
      });
    }
    let volumes = [];
    if (result.details.volumes) {
      result.details.volumes.forEach((volume) => {
        volumes.push({
          ...volume,
          checked: false,
        });
      });
    }

    dispatch(
      setOperatorOrderDetails({
        ...result.details,
        products,
        volumes,
        documents: await getOrderDocumentsSignedUrls(fileStorageConfigs, result?.details?.documents)
      })
    );
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
};

export default orderDetailsLoCall;


import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, IconButton, Stack } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import Permission from "../../../../../app/components/Permission";
import { useDispatch } from "react-redux";
import { productRemoveTax, removeTempProductTax } from "../../../productsSlice";
import ActionsPopover from "../../../../../common/components/ActionsPopover/ActionsPopover";
import HistoryIcon from "@mui/icons-material/History";

export default function ProductTaxOthersDataGridActions({ classes, row, product, validation, onValidate, onHistory }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const processTax = row?.process?.other || {};

  const onCancelTempOtherTaxHandler = (e) => {
    e.preventDefault();
    dispatch(removeTempProductTax({ productId: product.productId, code: row.code }))
  };

  const onRemoveOtherHandler = (e) => {
    e.preventDefault();
    dispatch(productRemoveTax({
      productId: product.productId,
      code: row?.process?.other?.code || row?.code,
      value: row?.process?.other?.value || row?.value,
      type: row?.process?.other?.type || row?.type
    }));
  };

  const setValidHandler = (value) => {
    onValidate && onValidate(row, value);
  }

  const onHistoryHandler = (e) => {
    e.preventDefault();
    onHistory && onHistory(product, (row?.process?.other?.code || row?.code));
  };

  let actionsButtons = [];

  // CANCEL TEMP
  if (row.hasChanges) {
    actionsButtons.push(<Button onClick={onCancelTempOtherTaxHandler} color="error" fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item style={{ display: "flex" }}>
          <DeleteIcon />
        </Grid>
        <Grid item>{t("products.ProductTaxList.actions.cancel")}</Grid>
      </Grid>
    </Button>);
  }
  else {
    // CANCEL PENDING
    if (row?.process?.status === "PENDING") {
      actionsButtons.push(<Button onClick={onRemoveOtherHandler} color="error" fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <DeleteIcon />
          </Grid>
          <Grid item>{t("products.ProductTaxList.actions.cancel")}</Grid>
        </Grid>
      </Button>);
    }

    // REMOVE
    if ((row?.process?.status === "ACCEPTED" || !row?.process?.status) && (!row?.process?.removeDate || row?.process?.status === "CANCELLED")) {
      actionsButtons.push(<Button onClick={onRemoveOtherHandler} color="error" fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <DeleteIcon />
          </Grid>
          <Grid item>{t("products.ProductTaxList.actions.remove")}</Grid>
        </Grid>
      </Button>);
    }
  }

  return (
    <Grid container spacing={1} justifyContent="flex-end" alignItems="center" wrap="nowrap">
      {!validation && actionsButtons.length > 0 &&
        <Grid item>
          <ActionsPopover
            actions={actionsButtons}
          />
        </Grid>
      }
      {validation && row?.process?.taxes?.[processTax.code] === "PENDING" && row?.process?.waitingAction &&
        <Permission code="products.actions.taxes.action">
          <Grid item>
            <Stack direction="row" spacing={0} >
              <IconButton onClick={() => setValidHandler(false)}>
                <CancelOutlinedIcon color={row.valid === false ? "error" : "default"} />
              </IconButton>
              <IconButton onClick={() => setValidHandler(true)}>
                <CheckCircleOutlineIcon color={row.valid === true ? "success" : "default"} />
              </IconButton>
            </Stack>
          </Grid>
        </Permission>
      }
      <Permission code="products.actions.taxes.history">
        <Grid item>
          <IconButton size="small" onClick={onHistoryHandler}>
            <HistoryIcon className={classes.icon} />
          </IconButton>
        </Grid>
      </Permission>
    </Grid>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import businessUnitListCall from "./_sliceCalls/businessUnitListCall";
import { appendItemsToList } from "../../common/utils";
import businessUnitCreateCall from "./_sliceCalls/businessUnitCreateCall";
import businessUnitUpdateCall from "./_sliceCalls/businessUnitUpdateCall";
import businessUnitGetCall from "./_sliceCalls/businessUnitGetCall";
import businessUnitDeleteCall from "./_sliceCalls/businessUnitDeleteCall";
import businessUnitDisableCall from "./_sliceCalls/businessUnitDisableCall";
import businessUnitEnableCall from "./_sliceCalls/businessUnitEnableCall";
import businessUnitList_FullCall from "./_sliceCalls/businessUnitList_FullCall";
import placeList_FullCall from "./_sliceCalls/placesList_FullCall";
import businessUnitStepsCall from "./_sliceCalls/businessUnitStepsCall";
import businessUnitChangeStepsOrderCall from "./_sliceCalls/businessUnitChangeStepsOrderCall";
import businessUnitCreateStepCall from "./_sliceCalls/businessUnitCreateStepCall";
import businessUnitDeleteStepCall from "./_sliceCalls/businessUnitDeleteStepCall";
import businessUnitEnableStepCall from "./_sliceCalls/businessUnitEnableStepCall";
import businessUnitDisableStepCall from "./_sliceCalls/businessUnitDisableStepCall";
import businessUnitUpdateStepCall from "./_sliceCalls/businessUnitUpdateStepCall";
import businessUnitDeleteStepTaskCall from "./_sliceCalls/businessUnitDeleteStepTaskCall";
import businessUnitEnableStepTaskCall from "./_sliceCalls/businessUnitEnableStepTaskCall";
import businessUnitDisableStepTaskCall from "./_sliceCalls/businessUnitDisableStepTaskCall";
import businessUnitUpdateStepTaskCall from "./_sliceCalls/businessUnitUpdateStepTaskCall";
import businessUnitCreateStepTaskCall from "./_sliceCalls/businessUnitCreateStepTaskCall";
import businessUnitGetStepTaskCall from "./_sliceCalls/businessUnitGetStepTaskCall";
import businessUnitTasksCall from "./_sliceCalls/businessUnitTasksCall";

export const businessUnitsSlice = createSlice({
  name: "businessUnits",
  initialState: {
    businessUnitList: [],
    businessUnitListFull: [],
    businessUnitDetails: null,
    businessUnitListDetails: [],
    placesList: [],
    businessUnitSteps: null,
    taskDetails: null,
    businessUnitTasks: null,
  },
  reducers: {
    clearBusinessUnitList: (state) => {
      state.businessUnitList = [];
    },

    appendToBusinessUnitList: (state, action) => {
      appendItemsToList(state.businessUnitList, action.payload, true, "id");
    },

    setBusinessUnitDetails: (state, action) => {
      state.businessUnitDetails = action.payload;
    },

    setBusinessUnitListDetails: (state, action) => {
      state.businessUnitListDetails = action.payload;
    },

    setBusinessUnitListFull: (state, action) => {
      state.businessUnitListFull = action.payload;
    },

    setPlaceList: (state, action) => {
      state.placesList = action.payload;
    },

    setBusinessUnitSteps: (state, action) => {
      state.businessUnitSteps = action.payload;
    },

    setTaskDetails: (state, action) => {
      state.taskDetails = action.payload;
    },

    setBusinessUnitTasks: (state, action) => {
      state.businessUnitTasks = action.payload;
    },
  },
});

export default businessUnitsSlice.reducer;

export const {
  setBusinessUnitSteps
} = businessUnitsSlice.actions;


// SELECTS
export const selectBusinessUnitList = (state) => state.businessUnits.businessUnitList;
export const selectBusinessUnitDetails = (state) => state.businessUnits.businessUnitDetails;
export const selectBusinessUnitListDetails = (state) => state.businessUnits.businessUnitListDetails;
export const selectBusinessUnitListFull = (state) => state.businessUnits.businessUnitListFull;
export const selectPlacesListFull = (state) => state.businessUnits.placesList;
export const selectBusinessUnitSteps = (state) => state.businessUnits.businessUnitSteps;
export const selectTaskDetails = (state) => state.businessUnits.taskDetails;
export const selectBusinessUnitTasks = (state) => state.businessUnits.businessUnitTasks;

// CALLS
export const businessUnitList = () => (dispatch, getState) => {
  businessUnitListCall(businessUnitsSlice.actions, dispatch, getState());
};

export const businessUnitCreate = ({ name,
  entityId,
  places,
  users,
  groups,
  sectors,
}) => (dispatch) => {
  businessUnitCreateCall(dispatch, {
    name,
    entityId,
    places,
    users,
    groups,
    sectors,
  });
};

export const businessUnitUpdate = ({
  id,
  name,
  entityId,
  places,
  users,
  groups,
  sectors,
  lines,
  config
}) => (dispatch) => {
  businessUnitUpdateCall(dispatch, {
    id,
    name,
    entityId,
    places,
    users,
    groups,
    sectors,
    lines,
    config
  });
};

export const businessUnitGet = ({ id }) => (dispatch, getState) => {
  businessUnitGetCall(businessUnitsSlice.actions, dispatch, getState(), { id });
};

export const businessUnitDelete = ({ id }) => (dispatch, getState) => {
  businessUnitDeleteCall(dispatch, { id }, getState());
};

export const businessUnitDisable = ({ id }) => (dispatch, getState) => {
  businessUnitDisableCall(dispatch, { id }, getState());
};

export const businessUnitEnable = ({ id }) => (dispatch, getState) => {
  businessUnitEnableCall(dispatch, { id }, getState());
};

export const businessUnitList_Full = () => (dispatch) => {
  businessUnitList_FullCall(businessUnitsSlice.actions, dispatch);
};

export const placeList_Full = () => (dispatch) => {
  placeList_FullCall(businessUnitsSlice.actions, dispatch)
}

export const businessUnitSteps = ({ businessUnitId, lineId, internal }) => (dispatch,) => {
  businessUnitStepsCall(businessUnitsSlice.actions, dispatch, { businessUnitId, lineId, internal });
};

export const businessUnitChangeStepsOrder = (items) => (dispatch) => {
  dispatch(setBusinessUnitSteps(items));
  businessUnitChangeStepsOrderCall(dispatch, {
    order: items.reduce((acc, item, index) => {
      acc[item.id] = index;
      return acc;
    }, {})
  });
};

export const businessUnitCreateStep = ({
  name,
  businessUnitId,
  lineId,
  internal,
  places
}) => (dispatch) => {
  businessUnitCreateStepCall({ businessUnitSteps }, dispatch, {
    name,
    businessUnitId,
    lineId,
    internal,
    places
  });
};

export const businessUnitUpdateStep = ({
  businessUnitId,
  lineId,
  internal,
  id,
  name,
  places,
  startConditions,
  stopConditions,
}) => (dispatch) => {
  businessUnitUpdateStepCall({ businessUnitSteps }, dispatch, {
    businessUnitId,
    lineId,
    internal,
    id,
    name,
    places,
    startConditions,
    stopConditions,
  });
};

export const businessUnitDisableStep = ({ id, businessUnitId, lineId, internal }) => (dispatch) => {
  businessUnitDisableStepCall({ businessUnitSteps }, dispatch, { id, businessUnitId, lineId, internal });
};

export const businessUnitEnableStep = ({ id, businessUnitId, lineId, internal }) => (dispatch) => {
  businessUnitEnableStepCall({ businessUnitSteps }, dispatch, { id, businessUnitId, lineId, internal });
};

export const businessUnitDeleteStep = ({ id, businessUnitId, lineId, internal }) => (dispatch) => {
  businessUnitDeleteStepCall({ businessUnitSteps }, dispatch, { id, businessUnitId, lineId, internal });
};

export const businessUnitCreateStepTask = ({
  businessUnitId,
  lineId,
  internal,
  businessUnitStepId,
  placeId,
  name,
  type,
  users,
  groups,
  sectors,
  input,
  info,
  schedule,
}) => (dispatch) => {
  businessUnitCreateStepTaskCall(dispatch, {
    businessUnitId,
    lineId,
    internal,
    businessUnitStepId,
    placeId,
    name,
    type,
    users,
    groups,
    sectors,
    input,
    info,
    schedule,
  });
};

export const businessUnitUpdateStepTask = ({
  id,
  businessUnitId,
  lineId,
  internal,
  businessUnitStepId,
  placeId,
  name,
  type,
  users,
  groups,
  sectors,
  input,
  info,
  schedule,
}) => (dispatch) => {
  businessUnitUpdateStepTaskCall(dispatch, {
    id,
    businessUnitId,
    lineId,
    internal,
    businessUnitStepId,
    placeId,
    name,
    type,
    users,
    groups,
    sectors,
    input,
    info,
    schedule,
  });
};

export const businessUnitDisableStepTask = ({ id, businessUnitId, lineId, internal, placeId }) => (dispatch) => {
  businessUnitDisableStepTaskCall({ businessUnitSteps, businessUnitTasks }, dispatch, { id, businessUnitId, lineId, internal, placeId });
};

export const businessUnitEnableStepTask = ({ id, businessUnitId, lineId, internal, placeId }) => (dispatch) => {
  businessUnitEnableStepTaskCall({ businessUnitSteps, businessUnitTasks }, dispatch, { id, businessUnitId, lineId, internal, placeId });
};

export const businessUnitDeleteStepTask = ({ id, businessUnitId, lineId, internal, placeId }) => (dispatch) => {
  businessUnitDeleteStepTaskCall({ businessUnitSteps, businessUnitTasks }, dispatch, { id, businessUnitId, lineId, internal, placeId });
};

export const businessUnitGetStepTask = ({ id }) => (dispatch) => {
  businessUnitGetStepTaskCall(businessUnitsSlice.actions, dispatch, { id });
};

export const businessUnitTasks = ({ businessUnitId, lineId }) => (dispatch) => {
  businessUnitTasksCall(businessUnitsSlice.actions, dispatch, { businessUnitId, lineId });
};
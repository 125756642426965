import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Chip, Divider, Typography } from "@mui/material";

import { styles } from "./RemovePricesConfirmModal.styles";
import CustomModal from "../../../../../../common/displays/CustomModal/CustomModal";
import ScrollBox from "../../../../../../common/displays/ScrollBox/ScrollBox";
import { CustomDataGrid } from "../../../../../../common/displays/CustomDataGrid";
import { productRemovePrice, selectEntityGroups, selectLocations } from "../../../../productsPricesSlice";
import { useDispatch, useSelector } from "react-redux";
import { usePromoter } from "../../../../../../common/hooks";
import Currency from "../../../../../../common/displays/Currency/Currency";
import { dateFormat } from "../../../../../../common/utils";

function RemovePricesConfirmModal({ classes, open, setOpen, prices }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const height = 400;
  const entityGroups = useSelector(selectEntityGroups);
  const locations = useSelector(selectLocations);
  const promoter = usePromoter();

  const onRemoveConfirmHandler = () => {
    prices.forEach(({ priceId, productId, ownerId, contactPoint, removeDate, ownerName }) => {
      dispatch(productRemovePrice({ priceId, productId, ownerId, contactPoint, removeDate, ownerName }));
    });
    setOpen(false);
  }

  return (
    <CustomModal open={open} setOpen={setOpen} size="extralarge" height={height} title={t("productsPrices.RemovePricesConfirmModal.modal.title")}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <ScrollBox style={{ height: height - 130 }}>
            <Divider />
            <CustomDataGrid
              columns={getColumns({ t, promoter, entityGroups, locations })}
              rows={prices}
              hasHeader={false}
            />
          </ScrollBox>
        </Grid>
        <Grid item>
          <Button variant="contained" fullWidth onClick={onRemoveConfirmHandler}>{t("productsPrices.RemovePricesConfirmModal.modal.confirmBtn")}</Button>
        </Grid>
      </Grid>
    </CustomModal>
  );
}

export default withStyles(RemovePricesConfirmModal, styles);


const getColumns = ({ t, promoter, entityGroups, locations }) => {
  let columns = [];

  columns.push({
    field: "description",
    align: "left",
    headerAlign: "left",
    disableSort: true,
    customValue: (v) => {
      return v?.product?.description;
    },
  });

  columns.push({
    field: "priceType",
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return t("common.Enums.priceType." + v.priceType)
    }
  });

  columns.push({
    field: "extraInfo",
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      if (v.priceType === "G") {
        let group = (entityGroups || []).find(item => v.groupEntity === item.groupId || v.groupId === item.groupId);
        return <Chip label={group?.groupName || v?.groupName} size="small" />
      }
      if (v.priceType === "D" || v.priceType === "W") {
        let locationsObjs = (locations || []).filter(item => (v.locations || []).includes(item.id));
        return (
          <Grid container direction="row" spacing={1} flexWrap="wrap">
            {locationsObjs.map(loc => <Grid item key={loc.id} ><Chip label={loc.name} size="small" /></Grid>)}
          </Grid>
        );
      }
      return "";
    }
  });

  columns.push({
    field: "salePrice",
    align: "right",
    headerAlign: "right",
    customValue: (v) => {
      return <Currency value={v.salePrice} />
    }
  });
  if (promoter?.getConfig("price", "allowRetailPrice")) {
    columns.push({
      field: "retailPrice",
      align: "right",
      headerAlign: "right",
      customValue: (v) => {
        return <Currency value={v.retailPrice} />
      }
    });
  }

  columns.push({
    field: "removeDate",
    align: "left",
    headerAlign: "left",
    width: 110,
    customValue: (v) => {
      return <Typography style={{ margin: 0 }}>{dateFormat(v.removeDate)}</Typography>
    }
  });

  return columns;
}
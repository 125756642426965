import React from "react";
import {Route, Routes} from "react-router-dom";

import ReportsList from "./ReportsList/ReportsList";
import QueriesList from "./QueriesList/QueriesList";
import AuditsList from "./AuditsList/AuditsList";
import HistoryList from "./HistoryList/HistoryList";
import AccountingList from "./AccountingList/AccountingList";
import AccountingDetails from "./AccountingDetails/AccountingDetails";
import AuditDataDetails from "./AuditDataDetails/AuditDataDetails";
import AuditImportDetails from "./AuditImportDetails/AuditImportDetails";
import AuditWsDetails from "./AuditWsDetails/AuditWsDetails";
import AuditLoginsDetails from "./AuditLoginsDetails/AuditLoginsDetails";
import AuditExternalDetails from "./AuditExternalDetails/AuditExternalDetails";
import Security from "../../app/components/Security/Security";
import AuditJobDetails from "./AuditJobDetails/AuditJobDetails";
import AuditI18nDetails from "./AuditI18nDetails/AuditI18nDetails";
import ProcessList from "./ProcessList/ProcessList";
import ProcessDetails from "./ProcessDetails/ProcessDetails";

function BusinessRouting({ classes }) {
  return (
    <Routes>
      <Route path="/history" element={<HistoryList />} />
      <Route path="/queries" element={<QueriesList />} />
      <Route path="/reports" element={<ReportsList />} />
      <Route path="/audits" element={<AuditsList />} />
      <Route path="/audits/database" element={<AuditsList defaultAudit="audit_data" />} />
      <Route path="/audits/import" element={<AuditsList defaultAudit="audit_import" />} />
      <Route path="/audits/logins" element={<AuditsList defaultAudit="audit_login" />} />
      <Route path="/audits/ws" element={<AuditsList defaultAudit="audit_ws" />} />
      <Route path="/audits/external" element={<AuditsList defaultAudit="audit_external" />} />
      <Route path="/audits/job" element={<AuditsList defaultAudit="audit_job" />} />
      <Route path="/audits/i18n" element={<AuditsList defaultAudit="audit_i18n" />} />
      <Route path="/audits/database/:id" element={
        <Security component={<AuditDataDetails />} paramValidation={[{ name: "id", type: "number" }]} />
      } />
      <Route path="/audits/import/:id" element={
        <Security component={<AuditImportDetails />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/audits/logins/:sessionId/:action" element={
        <Security component={<AuditLoginsDetails />} paramValidation={[{ name: "sessionId", type: "string" }, { name: "action", type: "letter" }]} />
      } />
      <Route path="/audits/ws/:id" element={
        <Security component={<AuditWsDetails />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/audits/external/:id" element={
        <Security component={<AuditExternalDetails />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/audits/job/:id" element={
        <Security component={<AuditJobDetails />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/audits/i18n/:id" element={
        <Security component={<AuditI18nDetails />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/accounting" element={<AccountingList />} />
      <Route path="/accounting/:id" element={
        <Security component={<AccountingDetails />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/process" element={<ProcessList />} />
      <Route path="/process/:processId" element={
            <Security component={<ProcessDetails />} paramValidation={[{ name: "processId", type: "number" }]} />
      } />
    </Routes>
  );
}

export default BusinessRouting;

import React from "react";

import { Grid, TextField, Typography } from "@mui/material";

function I18nRow({ classes, label, value, defaultValue, onChange }) {
  return (
    <Grid item container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
      <Grid item xs={4}>
        <Typography className={classes.textfieldlabel}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          className={classes.textfield}
          fullWidth
          size="small"
          variant="outlined"
          disabled
          value={defaultValue}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          className={classes.textfield}
          fullWidth
          size="small"
          variant="outlined"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </Grid>
    </Grid>
  );
}

export default I18nRow;

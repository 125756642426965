import React from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./OperatorOrderVolumesDataGridField.styles";
import { Field } from "react-final-form";
import OperatorOrderVolumesDataGrid from "./OperatorOrderVolumesDataGrid/OperatorOrderVolumesDataGrid";
import { replaceObjectInArray } from "../../../../common/utils";
import MetaError from "../../../../common/forms/MetaError";

function OperatorOrderVolumesDataGridField({ classes, id, hideCheckbox }) {
  const onVolumeCheckChangeHandler = (volume, input) => {
    let nVolumes = [...input.value];
    nVolumes = replaceObjectInArray(nVolumes, volume, "id");
    input.onChange && input.onChange(nVolumes);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => {
          return (
            <div style={{ textAlign: "right" }}>
              <OperatorOrderVolumesDataGrid
                data={input.value}
                totalRows={input.value?.length || 0}
                onVolumeCheckChange={(volume) => onVolumeCheckChangeHandler(volume, input)}
                hideCheckbox={hideCheckbox}
              />

              <MetaError className={classes.error} meta={meta} />
            </div>
          );
        }}
      </Field>
    </div>
  );
}

export default withStyles(OperatorOrderVolumesDataGridField, styles)

export const styles = (theme) => ({
  paper: {
    margin: "4px 0",
    padding: theme.spacing(1),
    display: "inline-block"
  },
  hasDiff: {
    backgroundColor: "#e8f9f8",
  },
});

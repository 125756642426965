import * as Yup from "yup";
import { SelectField } from "../../../../common/forms";
import { getYearOptions } from "./PlanningCreateModal.options";
import { MeasureField, ProductPickerField } from "../../../../common/components/formFields";

export function formSchema({ t }) {
  return Yup.object().shape({
    year: Yup.string().required(t("planning.PlanningCreateModal.form.yearRequired")),
    product: Yup.array().ensure().min(1, t("planning.PlanningCreateModal.form.productRequired")),
  });
}

export function formFields({ t, user, hideTarget }) {
  return [
    {
      size: 12,
      field: <ProductPickerField
        single
        simple
        id="product"
        customFilters={{ justConsumer: false }}
        ownerId={user.entity?.id}
        onChange
        label={t("planning.PlanningCreateModal.form.product")}
      />,
    },
    {
      size: 12,
      field: <SelectField
        id="year"
        options={getYearOptions()}
        label={t("planning.PlanningCreateModal.form.year")}
      />,
    },
    {
      size: 12,
      field: <MeasureField
        id="target"
        label={t("planning.PlanningCreateModal.form.target")}
      />,
      hidden: hideTarget
    },
  ];
}


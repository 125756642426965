import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, IconButton } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import xlsx from "json-as-xlsx";

import { styles } from "./XlsxExport.styles";

function XlsxExport({ classes, data, fields }) {
  const onClickHandler = () => {
    let xlsxData = [
      {
        sheet: "Data",
        columns: fields.map(({ field, header, customValue }) => {
          return {
            label: header,
            value: customValue || field,
          };
        }),
        content: data,
      },
    ];

    let settings = {
      fileName: "export",
      extraLength: 3,
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };

    xlsx(xlsxData, settings);
  };

  return (
    <div className={classes.root}>
      <IconButton id="export-button-xlsx" className="export-button-xlsx" onClick={onClickHandler} disabled={!Boolean(data?.length > 0)}>
        <InsertDriveFileOutlinedIcon />
      </IconButton>
      <Typography className={classes.label}>XLSX</Typography>
    </div>
  );
}

export default withStyles(XlsxExport, styles)

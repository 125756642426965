

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const promoterGetCustomFormsCall = async ({ setCustomFormDetails }, dispatch, { formId }) => {
  let rh = requestHelper(dispatch, "PROMOTER_GET_CUSTOM_FORM");
  try {
    let result = await call({
      type: "PROMOTER",
      subType: "GET_CUSTOM_FORM",
      request: { formId },
    });
    dispatch(setCustomFormDetails(result?.form));
  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default promoterGetCustomFormsCall;

import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography } from "@mui/material";

import { styles } from "./BlockCard.styles";

function BlockCard({ classes, title, children }) {
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.nomargin} variant="h6">{title}</Typography>
      </div>
      <div className={classes.body}>
        {children}
      </div>
    </div>
  );
}

export default withStyles(BlockCard, styles);
import React, { useState, useEffect } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { styles } from "./QuantityInput.styles";
import ErrorSubtitle from "../../../components/displays/ErrorSubtitle";
import NumberWithSelectInput from "../../base/NumberWithSelectInput";

function QuantityInput({
  classes,
  style,
  label,
  max,
  min,
  multi,
  value,
  onChange,
  disabled,
  hierarchy,
  slim,
}) {
  const { t } = useTranslation();
  const [error, setError] = useState();
  const [localValue, setValue] = useState(value || 0);
  const [realValue, setRealValue] = useState(0);
  const [quantityMultiplier, setQuantityMultiplier] = useState(1);
  const [unitsOptions, setUnitsOptions] = useState([
    {
      value: 1,
      text: t("common.Enums.transportShort.BASE_UNIT_OR_EACH"),
    },
  ]);

  useEffect(() => {
    if (hierarchy) {
      let units = [];
      Object.keys(hierarchy || {}).forEach((key) => {
        if (hierarchy[key] > 1) {
          units.push({
            value: hierarchy[key],
            text: t("common.Enums.transportShort." + key),
          });
        }
      });
      setUnitsOptions([
        {
          value: 1,
          text: t("common.Enums.transportShort.BASE_UNIT_OR_EACH"),
        },
        ...units,
      ]);
    }
    // eslint-disable-next-line
  }, [hierarchy]);

  useEffect(() => {
    if (value) {
      setValue(parseInt(value / quantityMultiplier));
    }
    // eslint-disable-next-line
  }, [value]);

  const validateValue = (val) => {
    if (val !== 0) {
      if (val < min) {
        setError(t("common.QuantitySelect.errors.min", { min }));
        return;
      }
      if (val > max) {
        setError(t("common.QuantitySelect.errors.max", { max }));
        return;
      }
      if (multi && multi > 0 && val % multi !== 0) {
        setError(t("common.QuantitySelect.errors.multi", { multi }));
        return;
      }
    }
    setError();
    onChange && onChange(val);
  };

  const onChangeHandler = (val) => {
    val = parseInt(val) || 0;
    setRealValue(quantityMultiplier * val);
    validateValue(quantityMultiplier * val);
  };

  const onChangeTypeHandler = (multiplier) => {
    setQuantityMultiplier(multiplier);
    setRealValue(multiplier * localValue);
    validateValue(multiplier * localValue);
  };

  return (
    <div className={classes.root} style={style}>
      <NumberWithSelectInput
        className={slim && classes.quantityInput}
        label={label}
        value={localValue}
        onChange={onChangeHandler}
        selectValue={quantityMultiplier}
        onSelectChange={onChangeTypeHandler}
        disabled={disabled}
        error={Boolean(error)}
        options={unitsOptions}
        decimalScale={0}
      />
      {quantityMultiplier !== 1 && (
        <Typography color="textSecondary" className={classes.unitsQuantity}>
          {realValue} {t("common.Enums.transportShort.BASE_UNIT_OR_EACH")}
        </Typography>
      )}
      {error && (
        <div className={slim ? classes.qtyErrorSlim : classes.qtyError}>
          <ErrorSubtitle error={error} />
        </div>
      )}
    </div>
  );
}

export default withStyles(QuantityInput, styles);

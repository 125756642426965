import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { Badge, ListItem, ListItemIcon, ListItemText, Popover } from "@mui/material";
import { Notifications } from "@mui/icons-material";

import { styles } from "./AlertsMain.styles";

import { selectAlertsListFilter, alertList, selectAlertsListTotalRows } from "../alertsSlice";

import AlertsList from "../AlertsList/AlertsList";
import { selectConnected } from "../../../app/websocket/websocketSlice";

function AlertsMain({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filter = useSelector(selectAlertsListFilter);

  const total = useSelector(selectAlertsListTotalRows);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const connected = useSelector(selectConnected);

  const handlePopoverOpen = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (connected && !total) {
      dispatch(alertList({ ...filter, resolved: false }, true));
    }
    // eslint-disable-next-line
  }, [connected]);


  return (
    <li className={open ? classes.activeLink : null}>
      <ListItem button onClick={handlePopoverOpen}>
        <ListItemIcon>
          <Badge badgeContent={total} color="error" showZero={false}>
            <Notifications className={classes.icon} />
          </Badge>
        </ListItemIcon>
        <ListItemText primary={t("alerts.AlertsList.tabs.title")} />

        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          className={classes.popover}
        >
          <div className={classes.alertsContent}>
            <AlertsList />
          </div>
        </Popover>
      </ListItem>
    </li>
  );
}

export default withStyles(AlertsMain, styles);

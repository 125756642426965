export const styles = (theme) => ({
  root: {},
  totalLabel: {
    margin: 0,
    width: 100,
    textAlign: "right",
  },
  totalbox: {
    width: 200,
  },
  noMargin: {
    margin: 0,
  },
});

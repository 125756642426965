export const styles = () => ({
  root: {},
  avatar: {
    width: 24,
    height: 24,
  },
  name: {
    margin: 0,
    textTransform: "capitalize"
  },
});

import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Alert, Button, Grid, Stack, ToggleButton, ToggleButtonGroup} from "@mui/material";

import {styles} from "./ProductTaxList.styles";
import ProductTaxDataGrid from "./ProductTaxDataGrid/ProductTaxDataGrid";

import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import Permission from "../../../app/components/Permission";
import {
  clearTempProductTax,
  clearTempProductTaxValidation,
  formProductListFamily,
  productCreateTax,
  productGroupContacts,
  productListTax,
  productListTaxHistory,
  productPendingTaxAction,
  selectProductTaxHistoryListFilter,
  selectProductTaxList,
  selectProductTaxListFilter,
  selectProductTaxListTotalRows,
  selectTempProductTaxList,
  selectTempProductTaxValidationList,
  updateTempProductTax,
  updateTempProductTaxValidation
} from "../productsSlice";
import {selectLoader} from "../../../app/coreSlice";
import SearchBox from "../../../common/components/inputs/SearchBox";
import {useFirstLoad, useNavigate, useUser} from "../../../common/hooks";
import ProductVatFormModal from "./ProductVatFormModal/ProductVatFormModal";
import ProductTaxValidateCommentModal from "./ProductTaxValidateCommentModal/ProductTaxValidateCommentModal";
import ProductOtherTaxFormModal from "./ProductOtherTaxFormModal/ProductOtherTaxFormModal";
import TaxHistoryModal from "../ProductTaxHistory/TaxHistoryModal";

function ProductTaxList({ classes, validation }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [otherTaxModal, setOtherTaxModal] = useState(false);
  const [vatModal, setVatModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedOtherTax, setSelectedOtherTax] = useState();
  const [selectedTaxToValidate, setSelectedTaxToValidate] = useState();
  const [productHistory, setProductHistory] = useState();
  const taxes = useSelector(selectProductTaxList);
  const tempTaxes = useSelector(selectTempProductTaxList);
  const tempTaxesValidations = useSelector(selectTempProductTaxValidationList);
  const totalRows = useSelector(selectProductTaxListTotalRows);
  const filter = useSelector(selectProductTaxListFilter);
  const filterTaxHistory = useSelector(selectProductTaxHistoryListFilter);
  const loading = useSelector(selectLoader);
  const navigate = useNavigate();
  const user = useUser();

  useFirstLoad(() => {
    dispatch(formProductListFamily());
    dispatch(productGroupContacts());
  });

  const selectedOgProduct = useMemo(() => {
    if (selectedProduct) {
      return (taxes || []).find(item => item.productId === selectedProduct.productId);
    }
    return null;
  }, [selectedProduct, taxes]);

  const selectedTempProduct = useMemo(() => {
    if (selectedProduct) {
      return tempTaxes?.[selectedProduct.productId];
    }
    return null;
  }, [selectedProduct, tempTaxes]);

  const data = useMemo(() => {
    let nTaxes = [];
    if (validation) {
      (taxes || []).forEach(product => {
        let tempTax = tempTaxesValidations[product.productId]?.[product.tax?.process?.taskId];

        let nOther = []; // add validation other list
        (product.tax?.other || []).forEach(oTax => {
          let tempOtherTax = tempTaxesValidations[product.productId]?.[oTax?.process?.taskId]?.[oTax?.process?.other?.code];
          nOther.push({
            ...oTax,
            valid: tempOtherTax?.valid,
            comment: tempOtherTax?.comment,
            hasChanges: tempOtherTax?.valid !== undefined,
          })
        });
        nTaxes.push({
          ...product,
          valid: tempTax?.[product.tax?.process?.vatType]?.valid,
          comment: tempTax?.[product.tax?.process?.vatType]?.comment,
          hasChanges: tempTax?.[product.tax?.process?.vatType]?.valid !== undefined,
          ogTax: product.tax,
          tax: {
            ...product.tax,
            other: nOther
          }
        })
      });
    }
    else {
      (taxes || []).forEach(product => {
        let nTax = tempTaxes[product.productId];
        let nOther = [...(nTax?.other || [])];
        (product?.tax?.other || []).forEach(other => {
          // push og other not in temp
          if (!nOther.map(item => item.code || item?.process?.other?.code).includes(other.code)) {
            nOther.push(other);
          }
        });
        nTaxes.push({
          ...product,
          tax: {
            vat: nTax?.vat || product?.tax?.vat,
            startDate: nTax?.startDate || product?.tax?.startDate,
            other: nOther,
          },
          hasChanges: nTax && nTax?.vat && nTax.vat !== product.tax?.vat,
          ogTax: product.tax
        })
      });
    }
    return nTaxes;
  }, [taxes, tempTaxes, tempTaxesValidations, validation]);

  const hasChanges = useMemo(() => {
    if (validation) {
      return Object.keys(tempTaxesValidations)?.filter(
        productId => Object.keys(tempTaxesValidations[productId])?.filter(
          taskId => Object.keys(tempTaxesValidations[productId][taskId])?.filter(
            tax => tempTaxesValidations[productId][taskId][tax].valid !== undefined).length > 0
        ).length > 0
      ).length > 0;
    }
    return Object.keys(tempTaxes)?.length > 0;

  }, [validation, tempTaxesValidations, tempTaxes]);

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search,
      offset: 0,
    };

    dispatch(productListTax(nFilter, true));
  }

  const onFilterChangeHandler = (v) => {
    dispatch(productListTax({ ...filter, ...v.filter }, v.reset));
  }

  const onSubmitHandler = () => {
    if (validation) {
      dispatch(productPendingTaxAction());
    }
    else {
      dispatch(productCreateTax());
    }
  }

  const onOtherTaxClickHandler = (product, otherTax) => {
    setSelectedProduct(product);
    const { code, startDate, type, value } = otherTax;
    setSelectedOtherTax({ code, startDate, amount: { type, value } });
    setOtherTaxModal(true);
  }

  const onOtherTaxAddHandler = (product) => {
    setSelectedProduct(product);
    setSelectedOtherTax();
    setOtherTaxModal(true);
  }

  const onVatClickHandler = (product) => {
    setSelectedProduct(product);
    setVatModal(true);
  }

  const onVatFormSubmitHandler = (product, { vat, startDate }) => {
    setVatModal(false);
    const nTax = { other: (selectedTempProduct?.other || []), vat, startDate };
    dispatch(updateTempProductTax({ productId: product.productId, tax: nTax }));
  }

  const onOtherTaxSubmitHandler = (product, { startDate, code, amount }) => {
    setOtherTaxModal(false);
    const { value, type } = amount;
    let other = [];
    (product.tax?.other || []).forEach(oTax => {
      if (oTax.code === code) {
        // update
        other.push({ code, value, type, startDate, hasChanges: true });
      }
      else if (oTax.hasChanges) {
        // keep
        other.push(oTax);
      }
    });
    if (!(product.tax?.other || []).some(item => item.code === code)) {
      // new
      other.push({ code, value, type, startDate, hasChanges: true });
    }
    const nTax = { ...(selectedTempProduct?.tax || {}), other };
    dispatch(updateTempProductTax({ productId: product.productId, tax: nTax }))
  }

  // VALIDATION - Vat (Open Comment Modal or Removal)
  const onValidateVatHandler = (product, valid) => {
    const productId = product.productId;
    const taskId = product.tax?.process?.taskId;
    const taxCode = product.tax?.process?.vatType;

    if (tempTaxesValidations[productId]?.[taskId]?.[taxCode]?.valid !== valid) {
      setSelectedTaxToValidate({ productId, taskId, taxCode, valid })
      setCommentModal(true);
    }
    else {
      dispatch(updateTempProductTaxValidation({ productId, taskId, taxCode, valid: undefined, comment: undefined }));
    }
  }

  // VALIDATION - Other Tax (Open Comment Modal or Removal)
  const onValidateOtherTaxHandler = (product, tax, valid) => {
    const productId = product.productId;
    const taxCode = tax?.process?.other?.code;
    const taskId = tax?.process?.taskId

    if (tempTaxesValidations[productId]?.[taskId]?.[taxCode]?.valid !== valid) {
      setSelectedTaxToValidate({ productId, taskId, taxCode, valid })
      setCommentModal(true);
    }
    else {
      dispatch(updateTempProductTaxValidation({ productId, taskId, taxCode, valid: undefined, comment: undefined }));
    }
  }

  // VALIDATION - Confirmation + Comment
  const onTaxValidationConfirmHandler = ({ comment }) => {
    dispatch(updateTempProductTaxValidation({ ...selectedTaxToValidate, comment }));
    setCommentModal(false);
  }

  const onTaxHistoryOpenHandler = (product, taxType) => {

    console.log("product", product);
    console.log("taxType", taxType);
    const { productId } = product;
    dispatch(productListTaxHistory({ ...filterTaxHistory, productId: productId , tax: taxType}, true));
    setProductHistory(product);
    setHistoryModal(true);
  }

  const onFilterTaxHistoryChangeHandler = (v) => {
    dispatch(productListTaxHistory({ ...filterTaxHistory, ...v.filter }, v.reset));
  }

  return (
    <Page
      permission={validation ? "products.actions.taxes.action" : "products.actions.taxes.list"}
      header={<PageTitle
        title={t("products.ProductTaxList.header.title")}
        info={`${totalRows} ${t("products.ProductTaxList.header.rowsInfo")}`}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <SearchBox onEnter={(v) => onSearchHandler(v)} value={filter?.search} />
            </Grid>
            <Permission code={validation ? "products.actions.taxes.action" : "products.actions.taxes.create"}>
              <Grid item>
                <Button
                  variant="contained"
                  size="large"
                  onClick={onSubmitHandler}
                  disabled={loading || !hasChanges}
                >
                  {t("products.ProductTaxList.header.btnSubmit")}
                </Button>
              </Grid>
            </Permission>
          </Grid>
        }
      />}>
      <Stack spacing={1} className={classes.root}>
        {user?.rolePermission?.products?.actions?.taxes?.action &&
          <div className={classes.links}>
            <ToggleButtonGroup color="primary" size="small" value={validation ? "validation" : "taxes"} >
              <ToggleButton color="primary" value="taxes" onClick={() => validation && navigate('/products/taxes')} >
                {t("products.ProductTaxList.header.taxesLink")}
              </ToggleButton>
              <ToggleButton color="primary" value="validation" onClick={() => !validation && navigate('/products/taxes/validation')} >
                {t("products.ProductTaxList.header.validationLink")}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        }
        {hasChanges &&
          <Alert variant="outlined" severity="info" className={classes.alert}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {validation ? t("products.ProductTaxList.header.changesToSubmitValidation") : t("products.ProductTaxList.header.changesToSubmit")}
              <Button size="small" variant="text" color="info" disableRipple style={{ height: "auto", padding: 0 }} onClick={() => validation ? dispatch(clearTempProductTaxValidation()) : dispatch(clearTempProductTax())}>
                {t("products.ProductTaxList.header.clearChanges")}
              </Button>
            </Stack>
          </Alert>
        }
        <ProductTaxDataGrid
          data={data}
          totalRows={totalRows}
          onFilterChange={onFilterChangeHandler}
          filter={filter}
          validation={validation}
          onOtherTaxClick={onOtherTaxClickHandler}
          onOtherTaxAdd={onOtherTaxAddHandler}
          onVatClick={onVatClickHandler}
          onValidateVat={onValidateVatHandler}
          onValidateOtherTax={onValidateOtherTaxHandler}
          onTaxHistory={onTaxHistoryOpenHandler}
        />
        <ProductVatFormModal open={vatModal} setOpen={setVatModal} product={selectedProduct} ogProduct={selectedOgProduct || {}} onSubmit={onVatFormSubmitHandler} />
        <ProductTaxValidateCommentModal open={commentModal} setOpen={setCommentModal} onConfirm={onTaxValidationConfirmHandler} taxToValidate={selectedTaxToValidate} />
        <ProductOtherTaxFormModal open={otherTaxModal} setOpen={setOtherTaxModal} product={selectedProduct} onSubmit={onOtherTaxSubmitHandler} otherTax={selectedOtherTax} />
        <TaxHistoryModal open={historyModal} setOpen={setHistoryModal} product={productHistory} onFilterChange={onFilterTaxHistoryChangeHandler} />
      </Stack>
    </Page>
  );
}

export default withStyles(ProductTaxList, styles);
import { Grid, Typography } from "@mui/material";
import { PicturePlaceholder } from "../../../../../common/icons";
import { currencyFormat } from "../../../../../common/utils";

export function listColumns(t, classes) {
  return [
    {
      field: "description",
      headerName: t("orders.OrdersMissingProducts.columns.description"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <Grid
              item
              style={{
                width: 60,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {v.image ? (
                <img style={{ maxWidth: 44, maxHeight: 40 }} alt={v.description} src={v.image} />
              ) : (
                <PicturePlaceholder />
              )}
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.nomargin}>{v.description}</Typography>
                </Grid>
                <Grid item>
                  <Typography color="textSecondary" className={classes.nomargin}>
                    {v.gtin}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "quantity",
      headerName: t("orders.OrdersMissingProducts.columns.quantity"),
      align: "right",
      headerAlign: "right",
      width: 130,
    },

    {
      field: "price",
      headerName: t("orders.OrdersMissingProducts.columns.price"),
      align: "right",
      headerAlign: "right",
      width: 130,
      customValue: (v) => {
        return currencyFormat(v.price);
      },
    },
    {
      field: "tax",
      headerName: t("orders.OrdersMissingProducts.columns.tax"),
      align: "right",
      headerAlign: "right",
      width: 130,
    },
    {
      field: "total",
      headerName: t("orders.OrdersMissingProducts.columns.total"),
      align: "right",
      width: 130,
      headerAlign: "right",
      customValue: (v) => {
        return currencyFormat(v.price * v.quantity);
      },
    },
  ];
}

import React from "react";
import { withStyles } from "tss-react/mui";
import { Grid, Typography, Avatar } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

import { styles } from "./UserSnip.styles";

function UserSnip({ classes, contact, star = false }) {
  if (!contact) {
    return null;
  }

  let displayName =
    contact?.firstName || contact?.lastName
      ? (contact?.firstName || "") + " " + (contact?.lastName || "")
      : contact?.email;

  return (
    <Grid container className={classes.root} justifyContent="flexStart" alignItems="center" spacing={1} wrap="nowrap">
      <Grid item>
        <Avatar alt={(displayName || "").toUpperCase()} src={contact.logo} className={classes.avatar} />
      </Grid>
      {star && (
        <Grid item>
          <StarIcon fontSize="small" style={{ display: "block" }} />
        </Grid>
      )}
      <Grid item>
        <Typography variant="h5" align="left" component="h5" className={classes.name}>
          {displayName}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(UserSnip, styles);

import {createSlice} from "@reduxjs/toolkit";
import productPriceListCall from "./_sliceCalls/productPriceListCall";
import {priceHistoryListDefaultFilter, priceListDefaultFilter} from "../products/_common/productsFilters";
import {extractDataForCreate} from "./_sliceCalls/_auxiliars";
import productCreatePriceCall from "./_sliceCalls/productCreatePriceCall";
import entityListGroup_fullCall from "./_sliceCalls/entityListGroup_fullCall";
import promoterListLocations_fullCall from "./_sliceCalls/promoterListLocations_fullCall";
import {appendItemsToList, guid} from "../../common/utils";
import productPendingPriceActionCall from "./_sliceCalls/productPendingPriceActionCall";
import productRemovePriceCall from "./_sliceCalls/productRemovePriceCall";
import productPriceComparisonCall from "./_sliceCalls/productPriceComparisonCall";
import productDpromList_fullCall from "./_sliceCalls/productDpromList_fullCall";
import productExtraPriceListCall from "./_sliceCalls/productExtraPriceListCall";
import productSetExtraPriceCall from "./_sliceCalls/productSetExtraPriceCall";
import productPriceHistoryListCall from "./_sliceCalls/productPriceHistoryListCall";

export const productsPrices = createSlice({
  name: "productsPrices",
  initialState: {
    pricesList: [],
    pricesListTotalRows: 0,
    pricesListFilter: priceListDefaultFilter,
    extraPriceList: [],
    extraPriceListTotalRows: 0,
    extraPriceListFilter: priceListDefaultFilter,
    tempPrices: {},
    tempExtraPrices: {},
    entityGroups: [],
    locations: [],
    tempValidation: {},
    pricesComparerLists: null,
    dproms: [],
    priceListToExport: [],
    priceHistoryList: [],
    priceHistoryListTotalRows: 0,
    priceHistoryListFilter: priceHistoryListDefaultFilter,

  },
  reducers: {
    clearPricesList: (state) => {
      state.pricesList = [];
    },

    appendToPricesList: (state, action) => {
      appendItemsToList(state.pricesList, action.payload, true, "productId");
    },

    prependToPricesList: (state, action) => {
      appendItemsToList(state.pricesList, action.payload, false, "productId");
    },

    updatePricesListThumbnails: (state, action) => {
      let nProductList = state.pricesList;
      (action.payload || []).forEach(({ productId, thumbnail }) => {
        let productIndex = nProductList.findIndex(item => item.productId === productId);
        if (nProductList[productIndex]) {
          nProductList[productIndex].thumbnail = thumbnail;
        }
      });
      state.productList = nProductList;
    },

    setPricesListTotalRows: (state, action) => {
      state.pricesListTotalRows = action.payload;
    },

    setPricesListFilter: (state, action) => {
      state.pricesListFilter = action.payload;
    },

    clearExtraPricesList: (state) => {
      state.extraPriceList = [];
    },

    appendToExtraPricesList: (state, action) => {
      appendItemsToList(state.extraPriceList, action.payload, true, "productId");
    },

    prependToExtraPricesList: (state, action) => {
      appendItemsToList(state.extraPriceList, action.payload, false, "productId");
    },

    updateExtraPricesListThumbnails: (state, action) => {
      let nProductList = state.extraPriceList;
      (action.payload || []).forEach(({ productId, thumbnail }) => {
        let productIndex = nProductList.findIndex(item => item.productId === productId);
        if (nProductList[productIndex]) {
          nProductList[productIndex].thumbnail = thumbnail;
        }
      });
      state.productList = nProductList;
    },

    setExtraPricesListTotalRows: (state, action) => {
      state.extraPriceListTotalRows = action.payload;
    },

    setExtraPricesListFilter: (state, action) => {
      state.extraPriceListFilter = action.payload;
    },

    addTempPrice: (state, action) => {
      const { productId, ...price } = action.payload;
      let productPrices = (state.tempPrices[productId] || [])
      let nPrices = {
        ...state.tempPrices, [productId]: [...productPrices, price]
      }
      state.tempPrices = nPrices;
    },

    updateTempSalePrice: (state, action) => {
      const { productId, priceId, newSalePrice } = action.payload;
      let nProductTempPrices = [];
      let processed = false;
      (state.tempPrices[productId] || []).forEach(item => {
        if (item.priceId === priceId) {
          processed = true;
          nProductTempPrices.push({
            ...item,
            priceId,
            newSalePrice,
            hasChanges: true
          })
        }
        else {
          nProductTempPrices.push(item)
        }
      })
      if (!processed) {
        nProductTempPrices.push({
          priceId,
          newSalePrice,
          hasChanges: true
        });
      }
      let nPrices = {
        ...state.tempPrices, [productId]: nProductTempPrices
      }
      state.tempPrices = nPrices;
    },

    updateTempRetailPrice: (state, action) => {
      const { productId, priceId, newRetailPrice } = action.payload;
      let productOtherTempPrices = (state.tempPrices[productId] || []).filter(item => item.priceId !== priceId);
      let tempPriceObject = (state.tempPrices[productId] || []).find(item => item.priceId === priceId);
      let nPrices = {
        ...state.tempPrices, [productId]: [...productOtherTempPrices, {
          ...tempPriceObject,
          priceId,
          newRetailPrice,
          hasChanges: true
        }]
      }
      state.tempPrices = nPrices;
    },

    removeTempSalePrice: (state, action) => {
      const { productId, priceId } = action.payload;
      let productOtherTempPrices = (state.tempPrices[productId] || []).filter(item => item.priceId !== priceId);
      let tempPriceObject = (state.tempPrices[productId] || []).find(item => item.priceId === priceId);
      // if the temp price has retailPrice, dont remove the price object but only the sale price
      if (tempPriceObject?.newRetailPrice && parseFloat(tempPriceObject?.newRetailPrice) !== 0) {
        state.tempPrices = {
          ...state.tempPrices, [productId]: [...productOtherTempPrices, {
            ...tempPriceObject,
            newSalePrice: undefined
          }]
        };
      }
      else {
        state.tempPrices = {
          ...state.tempPrices, [productId]: [...productOtherTempPrices]
        };
      }
    },

    removeTempRetailPrice: (state, action) => {
      const { productId, priceId } = action.payload;
      let productOtherTempPrices = (state.tempPrices[productId] || []).filter(item => item.priceId !== priceId);
      let tempPriceObject = (state.tempPrices[productId] || []).find(item => item.priceId === priceId);
      // if the temp price has salePrice, dont remove the price object but only the retail price
      if (tempPriceObject?.newSalePrice && parseFloat(tempPriceObject?.newSalePrice) !== 0) {
        state.tempPrices = {
          ...state.tempPrices, [productId]: [...productOtherTempPrices, {
            ...tempPriceObject,
            newRetailPrice: undefined
          }]
        };
      }
      else {
        state.tempPrices = {
          ...state.tempPrices, [productId]: [...productOtherTempPrices]
        };
      }
    },

    removeTempPrice: (state, action) => {
      const { productId, priceId } = action.payload;
      let productOtherTempPrices = (state.tempPrices[productId] || []).filter(item => item.priceId !== priceId);
      state.tempPrices = {
        ...state.tempPrices, [productId]: [...productOtherTempPrices]
      };
    },

    addPromotion: (state, action) => {
      const { productId, priceId, promotion } = action.payload;
      let productPrices = (state.tempPrices[productId] || []).filter(item => item.priceId !== priceId);
      let priceObj = (state.tempPrices[productId] || []).find(item => item.priceId === priceId);

      let nPrices = {
        ...state.tempPrices, [productId]: [...productPrices, {
          ...priceObj,
          priceId,
          promotions: [...(priceObj?.promotions || []), { ...promotion, id: guid(), isNew: true }]
        }]
      }
      state.tempPrices = nPrices;
    },

    updatePromotion: (state, action) => {
      const { productId, priceId, promotion } = action.payload;
      let productPrices = (state.tempPrices[productId] || []).filter(item => item.priceId !== priceId);
      let priceObj = (state.tempPrices[productId] || []).find(item => item.priceId === priceId);

      let nPrices = {
        ...state.tempPrices, [productId]: [...productPrices, {
          ...priceObj,
          priceId,
          promotions: [...(priceObj?.promotions || []).filter(prm => prm.id !== promotion.id), { ...promotion, hasChanges: true }]
        }]
      }
      state.tempPrices = nPrices;
    },

    deletePromotion: (state, action) => {
      const { productId, priceId, promotion } = action.payload;
      let productPrices = (state.tempPrices[productId] || []).filter(item => item.priceId !== priceId);
      let priceObj = (state.tempPrices[productId] || []).find(item => item.priceId === priceId);

      let nPromotions = promotion?.isNew ? (priceObj?.promotions || []).filter(prm => prm.id !== promotion.id) : (priceObj?.promotions || []);
      if (!promotion?.isNew) {
        let price = ((state.pricesList || []).find(product => product.productId === productId)?.prices || []).find(price => price.priceId === priceId);
        if (price) {
          let promotionToDelete = price.promotions.find(promo => promo.id === promotion.id);
          if (promotionToDelete) {
            promotionToDelete.toDelete = true;
            nPromotions.push(promotionToDelete);
          }
        }
      }

      let nPrices = {
        ...state.tempPrices, [productId]: [...productPrices, {
          ...priceObj,
          priceId,
          promotions: nPromotions
        }]
      }
      state.tempPrices = nPrices;
    },

    clearTempPrices: (state) => {
      state.tempPrices = {};
    },

    addTempExtraPrice: (state, action) => {
      const { productId, ...price } = action.payload;
      let productPrices = (state.tempExtraPrices[productId] || [])
      let nPrices = {
        ...state.tempExtraPrices, [productId]: [...productPrices, { ...price, isNew: true, priceId: guid() }]
      }
      state.tempExtraPrices = nPrices;
    },

    updateTempExtraPrice: (state, action) => {
      const { productId, priceId, priceType, locations, newExtraPrice } = action.payload;
      let productPrices = (state.tempExtraPrices[productId] || []).filter(item => item.priceId !== priceId);
      let priceObj = (state.tempExtraPrices[productId] || []).find(item => item.priceId === priceId);
      let nPrices = {
        ...state.tempExtraPrices, [productId]: [...productPrices, {
          ...priceObj,
          priceId,
          priceType,
          locations,
          newExtraPrice
        }]
      }
      state.tempExtraPrices = nPrices;
    },

    removeTempExtraPrice: (state, action) => {
      const { productId, priceId } = action.payload;

      let productPrices = (state.tempExtraPrices[productId] || []).filter(item => item.priceId !== priceId);
      state.tempExtraPrices = {
        ...state.tempExtraPrices, [productId]: [...productPrices]
      };
    },

    clearTempExtraPrices: (state) => {
      state.tempExtraPrices = {};
    },

    setGroupsFull: (state, action) => {
      state.entityGroups = action.payload;
    },

    setLocationsFull: (state, action) => {
      state.locations = action.payload;
    },

    updateTempValidation: (state, action) => {
      const { priceId, valid, comment, taskId, productId } = action.payload;
      state.tempValidation[priceId] = { valid, comment, taskId, productId };
    },

    clearTempValidation: (state, action) => {
      state.tempValidation = {};
    },

    setPricesComparerLists: (state, action) => {
      state.pricesComparerLists = action.payload;
    },

    updatePricesComparerListsThumbnails: (state, action) => {
      let nPricesComparerList = state.pricesComparerLists;
      (action.payload.similarProducts || []).forEach(({ productId, thumbnail }) => {
        let productIndex = nPricesComparerList.similarProducts.findIndex(item => item.productId === productId);
        if (nPricesComparerList.similarProducts[productIndex]) {
          nPricesComparerList.similarProducts[productIndex].thumbnail = thumbnail;
        }
      });
      (action.payload.subFamilyProducts || []).forEach(({ productId, thumbnail }) => {
        let productIndex = nPricesComparerList.subFamilyProducts.findIndex(item => item.productId === productId);
        if (nPricesComparerList.subFamilyProducts[productIndex]) {
          nPricesComparerList.subFamilyProducts[productIndex].thumbnail = thumbnail;
        }
      });
      state.pricesComparerLists = nPricesComparerList;
    },

    setDproms: (state, action) => {
      state.dproms = action.payload;
    },

    setPriceListToExport: (state, action) => {
      state.priceListToExport = action.payload;
    },
    setPriceHistoryListTotalRows: (state, action) => {
      state.priceHistoryListTotalRows = action.payload;
    },

    appendToPriceHistoryList: (state, action) => {
      appendItemsToList(state.priceHistoryList, action.payload, true, "actionDate");
    },
    setPriceHistoryListFilter: (state, action) => {
      state.priceHistoryListFilter = action.payload;
    },
    clearPriceHistoryList: (state) => {
      state.priceHistoryList = [];
    },
  },
});

export default productsPrices.reducer;
export const {
  updateTempSalePrice,
  updateTempRetailPrice,
  removeTempPrice,
  removeTempSalePrice,
  removeTempRetailPrice,
  clearTempPrices,
  addTempPrice,
  addTempExtraPrice,
  updateTempExtraPrice,
  removeTempExtraPrice,
  updateTempValidation,
  clearTempValidation,
  addPromotion,
  updatePromotion,
  deletePromotion,
  prependToPricesList,
  clearTempExtraPrices,
} = productsPrices.actions;

// SELECTS
export const selectLoader = (state) => state.core.loader.length > 0;
export const selectPricesList = (state) => state.productsPrices.pricesList;
export const selectPricesListTotalRows = (state) => state.productsPrices.pricesListTotalRows;
export const selectPricesListFilter = (state) => state.productsPrices.pricesListFilter;
export const selectEntityGroups = (state) => state.productsPrices.entityGroups;
export const selectLocations = (state) => state.productsPrices.locations;
export const selectTempPrices = (state) => state.productsPrices.tempPrices;
export const selectTempValidations = (state) => state.productsPrices.tempValidation;
export const selectDproms = (state) => state.productsPrices.dproms;
export const selectPricesComparerLists = (state) => state.productsPrices.pricesComparerLists;
export const selectExtraPriceList = (state) => state.productsPrices.extraPriceList;
export const selectExtraPriceListTotalRows = (state) => state.productsPrices.extraPriceListTotalRows;
export const selectExtraPriceListFilter = (state) => state.productsPrices.extraPriceListFilter;
export const selectTempExtraPrices = (state) => state.productsPrices.tempExtraPrices;
export const selectPriceListToExport = (state) => state.productsPrices.priceListToExport;
export const selectPriceHistoryList = (state) => state.productsPrices.priceHistoryList;
export const selectPriceHistoryListTotalRows = (state) => state.productsPrices.priceHistoryListTotalRows;
export const selectPriceHistoryListFilter = (state) => state.productsPrices.priceHistoryListFilter;

// CALLS
export const productPriceList = (filter, clear = false) => (dispatch, getState) => {
  productPriceListCall(productsPrices.actions, dispatch, getState(), { filter, clear });
};

export const productExtraPriceList = (filter, clear = false) => (dispatch, getState) => {
  productExtraPriceListCall(productsPrices.actions, dispatch, getState(), { filter, clear });
};

export const productCreatePrice = () => (dispatch, getState) => {
  let state = getState();
  let prices = extractDataForCreate(state.productsPrices.pricesList, state.productsPrices.tempPrices);
  console.log('prices: %o', prices);
  productCreatePriceCall({ ...productsPrices.actions, productPriceList }, dispatch, state, prices);
};

export const entityListGroup_full = () => (dispatch) => {
  entityListGroup_fullCall(productsPrices.actions, dispatch);
}

export const promoterListLocations_full = () => (dispatch) => {
  promoterListLocations_fullCall(productsPrices.actions, dispatch);
}

export const productPendingPriceAction = () => (dispatch, getState) => {
  let state = getState();
  let validationPrices = state.productsPrices.tempValidation;
  if (validationPrices && Object.keys(validationPrices).length > 0) {
    productPendingPriceActionCall({ ...productsPrices.actions, productPriceList }, dispatch, state, validationPrices);
  }
}

export const productRemovePrice = ({ priceId, productId, ownerId, contactPoint, removeDate, ownerName }) => (dispatch, getState) => {
  productRemovePriceCall({ ...productsPrices.actions, productPriceList }, dispatch, getState(), { priceId, productId, ownerId, contactPoint, removeDate, ownerName });
};

export const productPriceComparison = ({ productId }) => (dispatch, getState) => {
  productPriceComparisonCall(productsPrices.actions, dispatch, getState(), { productId });
}

export const productDpromList_full = () => (dispatch) => {
  productDpromList_fullCall(productsPrices.actions, dispatch);
}

export const productSetExtraPrice = () => (dispatch, getState) => {
  let state = getState();
  let prices = [];
  Object.keys(state.productsPrices.tempExtraPrices).forEach(key => {
    let tempPrice = state.productsPrices.tempExtraPrices[key];
    (tempPrice || []).forEach(({ priceId, newExtraPrice: extraPrice, locations, priceType }) => {
      if (parseFloat(extraPrice?.value || "0") > 0) {
        prices.push({
          productId: key,
          priceId,
          extraPrice,
          locations,
          priceType
        })
      }
    })
  })
  productSetExtraPriceCall({ ...productsPrices.actions, productExtraPriceList }, dispatch, state, prices);
}

export const productSetExtraPrice_remove = ({ productId, priceId, extraPrice, locations, priceType }) => (dispatch, getState) => {
  productSetExtraPriceCall({ ...productsPrices.actions, productExtraPriceList }, dispatch, getState(), [{ productId, priceId, extraPrice, locations, priceType }]);
}

export const productPriceHistoryList = (filter, clear = false) => (dispatch, getState) => {
  productPriceHistoryListCall(productsPrices.actions, dispatch, getState(), { filter, clear });
};
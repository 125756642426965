export const styles = () => ({
  root: {
  },
  alert: {
    backgroundColor: "#FFF",
    padding: "2px 12px",
    "& .MuiAlert-message": {
      width: "100%"
    }
  }
});
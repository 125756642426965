import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { entityInitialValues } from "./_auxiliars";

export const entityGetByTaxNumberCall = async ({ setEntityCheckDetails }, dispatch, taxNumber) => {
  let rh = requestHelper(dispatch, "CHECK_ENTITY");
  try {
    let details = await call({
      type: "ENTITY",
      subType: "GET_BY_TAX_NUMBER",
      request: {
        taxNumber,
      },
    });
    dispatch(setEntityCheckDetails(entityInitialValues(false, details)));
  } catch (ex) {
    if (ex.tag === "GET_BY_TAX_NUMBER_ERROR") {
      dispatch(setEntityCheckDetails(entityInitialValues(true, { taxNumber })));
    } else {
      rh.error("entities", ex);
    }
  }
  rh.close();
};

export default entityGetByTaxNumberCall;

import * as Yup from "yup";
import { NumberField, ProductSelectField, DatePickerField } from "../../../../common/forms";

export function formSchema({ t }) {
  return Yup.object().shape({
    productId: Yup.string().required(t("batches.CreateInternalOrderModal.form.productIdRequired")),
    date: Yup.string().required(t("batches.CreateInternalOrderModal.form.dateRequired")),
    quantity: Yup.string().required(t("batches.CreateInternalOrderModal.form.quantityRequired")),
  });
}

export function formFields({ t, products }) {
  return [
    {
      size: 12,
      field: <ProductSelectField
        id="productId"
        products={products}
        label={t("batches.CreateInternalOrderModal.form.product")}
        emptyMsg={t("batches.CreateInternalOrderModal.form.productEmpty")}
        errorMsg={t("batches.CreateInternalOrderModal.form.productError")}
        required
      />,
    },
    {
      size: 12,
      field: <DatePickerField
        id="date"
        required
        label={t("batches.CreateInternalOrderModal.form.date")}
      />,
    },
    {
      size: 12,
      field: <NumberField
        id="quantity"
        required
        label={t("batches.CreateInternalOrderModal.form.quantity")}
      />,
    },
  ];
}



// productId,
// date,
// quantity,
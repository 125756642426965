import React from "react";
import { withStyles } from "tss-react/mui";
import { Field } from "react-final-form";
import MetaError from "../MetaError";
import { styles } from "./_base.styles";
import TaxNumberInput from "../../inputs/custom/TaxNumberInput";

function TaxNumberField({
  classes,
  id,
  label,
  disabled,
  required,
  onChange,
  autoValidate = false,
  forceAutoValidate = false,
  valuesThatPreventChange,
  onCheck,
  checkLabel
}) {
  const onChangeHandler = (v, input) => {
    if (!(valuesThatPreventChange || []).find((item) => item === v)) {
      input.onChange && input.onChange(v);
      onChange && onChange(v);
    }
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => {
          return (
            <>
              <TaxNumberInput
                className={classes.input}
                id={id}
                label={label}
                type="text"
                value={input.value}
                onChange={(value) => onChangeHandler(value, input)}
                disabled={disabled}
                required={required}
                error={Boolean(meta.error && (meta.touched || (autoValidate && (meta.modified || forceAutoValidate))))}
         
                onCheck={onCheck}
                checkLabel={checkLabel}
              />
              <div className={classes.error}>
                <MetaError meta={meta} autoValidate={autoValidate} forceAutoValidate={forceAutoValidate} />
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
}

export default withStyles(TaxNumberField, styles);

export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
    minHeight: 330,
  },
  defaultPic: {
    width: "100%",
    height: 0,
    paddingBottom: "100%",
    backgroundSize: "auto 100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    border: "1px solid #eee",
    borderRadius: 5
  },
  noMargin: {
    margin: 0
  },
  statusChip: {
    float: "right",
  }
});
import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid } from "@mui/material";

import { styles } from "./PercentageInput.styles";
import { Field } from "react-final-form";
import { NumberInput } from "../../../../../common/inputs";

// PERCENTAGE_DISCOUNT
// "campaignType": "PD",
// "percentageCampaign": {
//   "percentage": 10.0,
//   "price": 40.0
// },

function PercentageInput({ classes, id, disabled }) {
  const percentageHandler = (input, val) => {
    let nValue = input.value || { percentage: 0 };
    input.onChange && input.onChange({ ...nValue, percentage: val });
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <div>
            <Grid container spacing={1}>
              <Grid item>
                <Typography component="span" variant="caption" color="textPrimary" className={classes.lbl}>
                  Desconto a aplicar
                </Typography>
              </Grid>
              <Grid item style={{ textAlign: "center" }}>
                <NumberInput
                  className={classes.input}
                  decimalScale={2}
                  value={input.value?.percentage || 0}
                  onChange={(v) => percentageHandler(input, v)}
                  endAdornment="%"
                  disabled={disabled}
                  textAlign="right"
                />
              </Grid>
            </Grid>
          </div>
        )}
      </Field>
    </div>
  );
}

export default withStyles(PercentageInput, styles)

export const styles = () => ({
  root: {
    border: "1px solid #DDD",
    borderRadius: 2,
    height: 24,
    width: 24,
    display: "block",
    "& img": {
      display: "block",
      maxHeight: "100%",
      maxWidth: "100%"
    }
  }
});
import * as Yup from "yup";
import { MultiSelectField } from "../../../../common/forms";

export const filterFields = () => (t) => {
  return [
    {
      size: 12,
      field: (
        <MultiSelectField
          id="orderStatus"
          label={t("orders.AggregatedOrders.filter.status")}
          options={getOrderStatusOptions(t)}
        />
      ),
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    entityId: Yup.string(),
  });
};

const getOrderStatusOptions = (t) => {
  return ["CR", "CO", "AC", "DI", "RE", "IT", "DE", "CD", "IN"].map((value) => {
    return {
      value,
      text: t("common.Enums.orderStatus." + value),
    };
  });
};

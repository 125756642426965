import React, { useMemo } from "react";
import { withStyles } from "tss-react/mui";
import { Step, Stepper, Box, StepLabel, StepConnector } from "@mui/material";

import { styles } from "./OrderStatusStepper.styles";
import OrderStatusStepperIcon from "./OrderStatusStepperIcon/OrderStatusStepperIcon";
import { usePromoter } from "../../../../../common/hooks";

function OrderStatusStepper({ classes, status, statusToCompleteList = [] }) {
  const promoter = usePromoter();
  const hasLO = promoter?.getConfig("order", "hasLogisticOperator");

  const availableStatus = useMemo(() => {
    if (promoter?.getConfig("order", "hasLogisticOperator")) {
      return ["CR", "CO", "AC", "DI", "RE", "IT", "DE", "CD"];
    }
    return ["CR", "CO", "AC", "DI", "DE"];
  }, [promoter]);

  // "IN" 
  const activeStep = availableStatus.indexOf(status === "IN" ? hasLO ? "CD" : "DE" : status)

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel connector={<StepConnector className={classes.connector}></StepConnector>}>
        {availableStatus.map((label) => (
          <Step key={label} className={classes.step} >
            <StepLabel icon={<OrderStatusStepperIcon statusCompleted={statusToCompleteList.indexOf(label) === -1} error={((hasLO && label === "CD") || (!hasLO && label === "DE")) && status === "IN"} />}></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export default withStyles(OrderStatusStepper, styles);
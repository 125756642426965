
import React from "react";
import { useParams } from "react-router-dom";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./RolesDataGrid.styles";
import { listColumns } from "./RolesDataGrid.columns";
import RolesDataGridActions from "./RolesDataGrid.actions";

import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import { useSelector } from "react-redux";
import { selectRolesUserList } from "../../rolesSlice";

function RolesDataGrid({
  classes,
  data,
  totalRows,
  filter,
  onFilterChange,
  onRemove,
  onEdit
}) {
  const { t } = useTranslation();
  const usersList = useSelector(selectRolesUserList);
  const columns = listColumns({ t, usersList });
  const { processId } = useParams();

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };

      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={columns}
        rows={(data || []).map((item) => ({
          ...item,
          className: classes[item.status],
          arrow: showRowArrow(item, processId)
        }))}
        // onRowSelected={(row) => console.log(row)}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMoreHandler}
        total={totalRows}
        rowKeyField="tempId"
        actionsComponent={<RolesDataGridActions onRemove={onRemove} onEdit={onEdit} />}
      />
    </div>
  );
}

export default withStyles(RolesDataGrid, styles);

const showRowArrow = (item, processId) => {
  return processId && item && (item.processes || []).some(item => item.processId === processId);
}
import { createSlice } from "@reduxjs/toolkit";
import resourceListCall from "./_sliceCalls/resourceListCall";
import { resourceListDefaultFilter } from "./_common/resourcesFilters";
import { appendItemsToList } from "../../common/utils";
import resourceCreateCall from "./_sliceCalls/resourceCreateCall";
import resourceUpdateCall from "./_sliceCalls/resourceUpdateCall";
import placeList_fullCall from "./_sliceCalls/placeList_fullCall";
import resourceGetCall from "./_sliceCalls/resourceGetCall";
import resourceEnableCall from "./_sliceCalls/resourceEnableCall";
import resourceDisableCall from "./_sliceCalls/resourceDisableCall";
import resourceList_fullCall from "./_sliceCalls/resourceList_fullCall";
import resourceDeleteCall from "./_sliceCalls/resourceDeleteCall";

export const resourcesSlice = createSlice({
  name: "resources",
  initialState: {
    resourceList: [],
    resourceListTotalRows: 0,
    resourceListFilter: resourceListDefaultFilter,
    resourceDetails: null,
    placesList: [],
    resourceListFull: []
  },
  reducers: {
    clearResourceList: (state) => {
      state.resourceList = [];
    },

    appendToResourceList: (state, action) => {
      appendItemsToList(state.resourceList, action.payload, true, "id");
    },

    setResourceListTotalRows: (state, action) => {
      state.resourceListTotalRows = action.payload;
    },

    setResourceListFilter: (state, action) => {
      state.resourceListFilter = action.payload;
    },

    setPlaceList: (state, action) => {
      state.placesList = action.payload;
    },

    setResourceDetails: (state, action) => {
      state.resourceDetails = action.payload;
    },

    setFullResourcesList: (state, action) => {
      state.resourceListFull = action.payload;
    },
  },
});

export default resourcesSlice.reducer;


// SELECTS
export const selectResourceList = (state) => state.resources.resourceList;
export const selectResourceListTotalRows = (state) => state.resources.resourceListTotalRows;
export const selectResourceListFilter = (state) => state.resources.resourceListFilter;
export const selectPlacesList = (state) => state.resources.placesList;
export const selectResourceDetails = (state) => state.resources.resourceDetails;
export const selectResourceListFull = (state) => state.resources.resourceListFull;

// CALLS
export const resourceList = (filter, clear = false) => (dispatch) => {
  resourceListCall(resourcesSlice.actions, dispatch, { filter, clear });
};

export const resourceCreate = ({ title, type, placeId, parentId, state, height, width, depth, weight, attributes }) => (dispatch) => {
  resourceCreateCall(dispatch, { title, type, placeId, parentId,state, height, width, depth, weight, attributes });
};

export const resourceUpdate = ({ id, title, type, placeId, parentId, state, height, width, depth, weight, attributes }) => (dispatch) => {
  resourceUpdateCall(dispatch, { id, title, type, placeId, parentId,state, height, width, depth, weight, attributes });
};

export const placesList = () => (dispatch) => {
  placeList_fullCall(resourcesSlice.actions, dispatch);
};

export const resourceGet = (id) => (dispatch) => {
  resourceGetCall(resourcesSlice.actions, dispatch, id);
};

export const resourceEnable = (id) => (dispatch, getState) => {
  resourceEnableCall({ resourceList }, dispatch, getState(), id);
};

export const resourceDisable = (id) => (dispatch, getState) => {
  resourceDisableCall({ resourceList }, dispatch, getState(), id);
};

export const resourceListFull = () => (dispatch) => {
  resourceList_fullCall(resourcesSlice.actions, dispatch);
};

export const resourceDelete = (id) => (dispatch, getState) => {
  resourceDeleteCall({ resourceList }, dispatch, getState(), id);
};
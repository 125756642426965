import * as Yup from "yup";
import {SelectField, TextField} from "../../../../common/forms";

export const filterFields = () => (t) => {
  return [
    {
      size: 12,
      field: <SelectField id="month" label={t("business.history.filter.month")} options={getMonthOptions(t)} />,
    },
    {
      size: 12,
      field: <SelectField id="year" label={t("business.history.filter.year")} options={getYearOptions()} />,
    },
    {
      size: 12,
      field: (
        <SelectField
          id="searchField"
          label={t("business.history.filter.searchField")}
          options={getSearchFieldMovementsOptions(t)}
        />
      ),
    },
    {
      size: 12,
      field: <TextField id="search" label={t("business.history.filter.search")} />,
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    month: Yup.string(),
    year: Yup.string(),
    searchField: Yup.string(),
    search: Yup.string(),
  });
};

const getMonthOptions = (t) => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => {
    return {
      value: item,
      text: t("common.Enums.month." + item),
    };
  });
};

const getYearOptions = () => {
  let yearsArr = [];
  for (let i = 2020; i <= new Date().getFullYear(); i++) {
    yearsArr.push(i);
  }

  return yearsArr.map((item) => {
    return {
      value: item,
      text: item,
    };
  });
};


const getSearchFieldMovementsOptions = (t) => {
  return ["NONE", "ORDERSELLERID", "SELLERNAME", "SELLERTAX", "BUYERNAME", "BUYERTAX", "ADDRESS"].map((item) => {
    return {
      value: item,
      text: t("business.history.filter.searchField_" + item),
    };
  });
};

import { DatePickerField, TextField } from "../../../../common/forms";

export const formFieldsDocMetadata = (item, t, metadata = {}) => {
  return [
    {
      size: 6,
      field: (
        <TextField
          id={`${item}.metadata.NME`}
          type="text"
          label={t(`common.Enums.documentMetadata.NME`)}
          required={metadata?.["NME"]}
        />
      ),
      hidden: !Object.keys(metadata).find((item) => item === "NME"),
    },
    {
      size: 6,
      field: (
        <TextField
          id={`${item}.metadata.DSC`}
          type="text"
          label={t(`common.Enums.documentMetadata.DSC`)}
          required={metadata?.["DSC"]}
        />
      ),
      hidden: !Object.keys(metadata).find((item) => item === "DSC"),
    },
    {
      size: 6,
      field: (
        <DatePickerField
          id={`${item}.metadata.EXD`}
          type="text"
          label={t(`common.Enums.documentMetadata.EXD`)}
          required={metadata?.["EXD"]}
        />
      ),
      hidden: !Object.keys(metadata).find((item) => item === "EXD"),
    },
    {
      size: 6,
      field: (
        <DatePickerField
          id={`${item}.metadata.RCD`}
          type="text"
          label={t(`common.Enums.documentMetadata.RCD`)}
          required={metadata?.["RCD"]}
        />
      ),
      hidden: !Object.keys(metadata).find((item) => item === "RCD"),
    },
    {
      size: 6,
      field: (
        <TextField
          id={`${item}.metadata.ADD`}
          type="text"
          label={t(`common.Enums.documentMetadata.ADD`)}
          required={metadata?.["ADD"]}
        />
      ),
      hidden: !Object.keys(metadata).find((item) => item === "ADD"),
    },
    {
      size: 6,
      field: (
        <TextField
          id={`${item}.metadata.DVS`}
          type="text"
          label={t(`common.Enums.documentMetadata.DVS`)}
          required={metadata?.["DVS"]}
        />
      ),
      hidden: !Object.keys(metadata).find((item) => item === "DVS"),
    },
    {
      size: 6,
      field: (
        <TextField
          id={`${item}.metadata.APV`}
          type="text"
          label={t(`common.Enums.documentMetadata.APV`)}
          required={metadata?.["APV"]}
        />
      ),
      hidden: !Object.keys(metadata).find((item) => item === "APV"),
    },
  ];
};

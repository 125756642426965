import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Paper } from "@mui/material";

import { styles } from "./FamilyList.styles";
import FamilyListDataTree from "./FamilyListDataTree/FamilyListDataTree";
import SearchBox from "../../../common/components/inputs/SearchBox";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";

import FamilyForm from "./FamilyForm/FamilyForm";
import { useFirstLoad } from "../../../common/hooks";
import Page from "../../../common/displays/Page/Page";
import { productListFamily, selectFamilyList, selectFamilyListFilter, selectSelectedFamilyId } from "../productsFamiliesSlice";
import { useEffect } from "react";

function FamilyList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filter = useSelector(selectFamilyListFilter);
  const data = useSelector(selectFamilyList);
  const [selectedItem, setSelectedItem] = useState();
  const selectedFamilyId = useSelector(selectSelectedFamilyId);

  useFirstLoad(() => {
    dispatch(productListFamily(filter, true));
  });

  useEffect(() => {
    if (data) {
      setSelectedItem({ create: true, id: null });
    }
  }, [data])

  useEffect(() => {
    if (selectedFamilyId) {
      setSelectedItem(data.find(item => item.id === selectedFamilyId));
    }
  }, [selectedFamilyId, data])

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search,
      offset: 0,
    };
    dispatch(productListFamily(nFilter, true));
  };

  const onSelectHandler = (row) => {
    setSelectedItem(row);
  };

  const onAddHandler = (row) => {
    setSelectedItem({ ...row, create: true, id: null });
  }

  return (
    <Page
      permission="promoter.actions.family.list"
      header={<PageTitle
        title={t("productsFamilies.FamilyList.header.title")}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <SearchBox onEnter={(v) => onSearchHandler(v)} />
            </Grid>
          </Grid>
        }
      />}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div className={classes.scroll}>
              <FamilyListDataTree data={data} onSelect={onSelectHandler} onAdd={onAddHandler} />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.scroll}>
              <FamilyForm selectedItem={selectedItem} families={data} />
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Page>
  );
}

export default withStyles(FamilyList, styles);

export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
  },
  title: {
    margin: 0,
  },
  finished: {
    marginTop: 30,
  },
  paper: {
    display: "flex",
    padding: theme.spacing(2),
  },
});

import * as Yup from "yup";
import { EntityAutoCompleteField } from "../../../../common/forms";

export const filterFields = () => (t) => {
  return [
    {
      size: 12,
      field: (
        <EntityAutoCompleteField
          id="entityId"
          label={t("entities.GroupList.filter.ownerLabel")}
          typeFilter={["P", "S", "W"]}
        />
      ),
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    entityId: Yup.string(),
  });
};

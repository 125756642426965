import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const batchListOrdersOpen_FullCall = async (
  { setOpenOrders },
  dispatch,
  { businessUnitId }
) => {
  let rh = requestHelper(dispatch, "BATCH_LIST_ORDERS");
  try {
    let result = await call({
      type: "BATCH",
      subType: "LIST_ORDERS",
      request: {
        limit: 50000,
        offset: 0,
        sortField: "DATE",
        sortType: "DESC",
        businessUnitId,
        // status: [] - ONLY OPEN or IN_PROGRESS
      },
    });
    dispatch(setOpenOrders(result.items));

  } catch (ex) {
    rh.error("batches", ex);
  }
  rh.close();
};

export default batchListOrdersOpen_FullCall;
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Grid} from "@mui/material";

import {styles} from "./AuditsList.styles";
import AuditDataDataGrid from "./AuditDataDataGrid/AuditDataDataGrid";
import AuditImportDataGrid from "./AuditImportDataGrid/AuditImportDataGrid";
import AuditLoginsDataGrid from "./AuditLoginsDataGrid/AuditLoginsDataGrid";
import AuditWsDataGrid from "./AuditWsDataGrid/AuditWsDataGrid";
import {getAuditOptions} from "../BusinessForm.options";

import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {SelectInput} from "../../../common/inputs";
import {useNavigate, useUser} from "../../../common/hooks";
import Page from "../../../common/displays/Page/Page";
import {
  auditListAuditData,
  auditListAuditExternal,
  auditListAuditI18n,
  auditListAuditImport, auditListAuditJob,
  auditListAuditLogins,
  auditListAuditWs,
  selectAuditDataFilter,
  selectAuditDataList,
  selectAuditDataListTotalRows,
  selectAuditExternalFilter,
  selectAuditExternalList,
  selectAuditExternalListTotalRows,
  selectAuditI18nFilter,
  selectAuditI18nList,
  selectAuditI18nListTotalRows,
  selectAuditImportFilter,
  selectAuditImportList,
  selectAuditImportListTotalRows,
  selectAuditJobFilter,
  selectAuditJobList,
  selectAuditJobListTotalRows,
  selectAuditLoginsFilter,
  selectAuditLoginsList,
  selectAuditLoginsListTotalRows,
  selectAuditWsFilter,
  selectAuditWsList,
  selectAuditWsListTotalRows
} from "../businessSlice";

import {selectLoader} from "../../../app/coreSlice";
import AuditExternalDataGrid from "./AuditExternalDataGrid/AuditExternalDataGrid";
import AuditJobDataGrid from "./AuditJobDataGrid/AuditJobDataGrid";
import AuditI18nDataGrid from "./AuditI18nDataGrid/AuditI18nDataGrid";

function AuditsList({ classes, defaultAudit }) {
  const { t } = useTranslation();
  const loading = useSelector(selectLoader);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useUser();
  const [selectedOption, setSelectedOption] = useState(defaultAudit || "empty");

  const auditDataFilter = useSelector(selectAuditDataFilter);
  const auditDataList = useSelector(selectAuditDataList);
  const auditDataListTotalRows = useSelector(selectAuditDataListTotalRows);

  const auditImportFilter = useSelector(selectAuditImportFilter);
  const auditImportList = useSelector(selectAuditImportList);
  const auditImportListTotalRows = useSelector(selectAuditImportListTotalRows);

  const auditLoginsFilter = useSelector(selectAuditLoginsFilter);
  const auditLoginsList = useSelector(selectAuditLoginsList);
  const auditLoginsListTotalRows = useSelector(selectAuditLoginsListTotalRows);

  const auditWsFilter = useSelector(selectAuditWsFilter);
  const auditWsList = useSelector(selectAuditWsList);
  const auditWsListTotalRows = useSelector(selectAuditWsListTotalRows);

  const auditExternalFilter = useSelector(selectAuditExternalFilter);
  const auditExternalList = useSelector(selectAuditExternalList);
  const auditExternalListTotalRows = useSelector(selectAuditExternalListTotalRows);

  const auditJobFilter = useSelector(selectAuditJobFilter);
  const auditJobList = useSelector(selectAuditJobList);
  const auditJobListTotalRows = useSelector(selectAuditJobListTotalRows);

  const auditI18nFilter = useSelector(selectAuditI18nFilter);
  const auditI18nList = useSelector(selectAuditI18nList);
  const auditI18nListTotalRows = useSelector(selectAuditI18nListTotalRows);

  return (
    <Page
      permission="business.actions.audit.list"
      header={<PageTitle title={t("business.audit.header.title")} />}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <SelectInput
            value={selectedOption}
            label={t("business.audit.filter.report")}
            onChange={(v) => setSelectedOption(v)}
            options={getAuditOptions(t, user)}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12}>
          {selectedOption === "audit_data" && (
            <AuditDataDataGrid
              data={auditDataList}
              totalRows={auditDataListTotalRows}
              filter={auditDataFilter}
              onFilterChange={({ filter, reset }) => dispatch(auditListAuditData(filter, reset))}
              onRowSelected={({ eventId }) => navigate('/activity/audits/database/' + eventId)}
            />
          )}
          {selectedOption === "audit_import" && (
            <AuditImportDataGrid
              data={auditImportList}
              totalRows={auditImportListTotalRows}
              filter={auditImportFilter}
              onFilterChange={({ filter, reset }) => dispatch(auditListAuditImport(filter, reset))}
              onRowSelected={({ eventId }) => navigate('/activity/audits/import/' + eventId)}
            />
          )}
          {selectedOption === "audit_login" && (
            <AuditLoginsDataGrid
              data={auditLoginsList}
              totalRows={auditLoginsListTotalRows}
              filter={auditLoginsFilter}
              onFilterChange={({ filter, reset }) => dispatch(auditListAuditLogins(filter, reset))}
              onRowSelected={({ sessionId, action }) => navigate('/activity/audits/logins/' + sessionId + "/" + action)}
            />
          )}
          {selectedOption === "audit_ws" && (
            <AuditWsDataGrid
              data={auditWsList}
              totalRows={auditWsListTotalRows}
              filter={auditWsFilter}
              onFilterChange={({ filter, reset }) => dispatch(auditListAuditWs(filter, reset))}
              onRowSelected={({ eventId }) => navigate('/activity/audits/ws/' + eventId)}
            />
          )}
          {selectedOption === "audit_external" && (
            <AuditExternalDataGrid
              data={auditExternalList}
              totalRows={auditExternalListTotalRows}
              filter={auditExternalFilter}
              onFilterChange={({ filter, reset }) => dispatch(auditListAuditExternal(filter, reset))}
              onRowSelected={({ eventId }) => navigate('/activity/audits/external/' + eventId)}
            />
          )}
          {selectedOption === "audit_job" && (
            <AuditJobDataGrid
              data={auditJobList}
              totalRows={auditJobListTotalRows}
              filter={auditJobFilter}
              onFilterChange={({ filter, reset }) => dispatch(auditListAuditJob(filter, reset))}
              onRowSelected={({ id }) => navigate('/activity/audits/job/' + id)}
            />
          )}
          {selectedOption === "audit_i18n" && (
            <AuditI18nDataGrid
              data={auditI18nList}
              totalRows={auditI18nListTotalRows}
              filter={auditI18nFilter}
              onFilterChange={({ filter, reset }) => dispatch(auditListAuditI18n(filter, reset))}
              onRowSelected={({ id }) => navigate('/activity/audits/i18n/' + id)}
            />
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default withStyles(AuditsList, styles);

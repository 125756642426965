import {createSlice} from "@reduxjs/toolkit";
import formProductListFamilyCall from "./_sliceCalls/formProductListFamilyCall";
import productCatalogCall from "./_sliceCalls/productCatalogCall";
import productCreateCall from "./_sliceCalls/productCreateCall";
import productDefineHierarchyCall from "./_sliceCalls/productDefineHierarchyCall";
import productDraftCall from "./_sliceCalls/productDraftCall";
import productGetBaseCall from "./_sliceCalls/productGetBaseCall";
import productGetCall from "./_sliceCalls/productGetCall";
import productImportProductImagesCall from "./_sliceCalls/productImportProductImagesCall";
import productListCall from "./_sliceCalls/productListCall";
import productPendingProductActionCall from "./_sliceCalls/productPendingProductActionCall";
import productPendingProductGetCall from "./_sliceCalls/productPendingProductGetCall";
import productPendingProductListCall from "./_sliceCalls/productPendingProductListCall";
import productUpdateCall from "./_sliceCalls/productUpdateCall";
import {
  campaignsListDefaultFilter,
  catalogListDefaultFilter,
  pendingProductsDefaultFilter,
  productDetailCommentsDefaultFilter,
  productListDefaultFilter,
  productPickerListDefaultFilter,
  productsTaxHistoryListDefaultFilter,
  productsTaxListDefaultFilter
} from "./_common/productsFilters";
import productRecoverCall from "./_sliceCalls/productRecoverCall";
import productGroupContactsCall from "./_sliceCalls/productGroupContactsCall";
import productCheckInternalCodeCall from "./_sliceCalls/productCheckInternalCodeCall";
import productList_pickerCall from "./_sliceCalls/productList_pickerCall";
import {appendItemsToList} from "../../common/utils";
import productListTaxCall from "./_sliceCalls/productListTaxCall";
import productCreateTaxCall from "./_sliceCalls/productCreateTaxCall";
import productPendingTaxActionCall from "./_sliceCalls/productPendingTaxActionCall";
import productRemoveTaxCall from "./_sliceCalls/productRemoveTaxCall";
import productGetCommentsCall from "./_sliceCalls/productGetCommentsCall";
import productChangeContactPointCall from "./_sliceCalls/productChangeContactPointCall";
import productExportDocumentsCall from "./_sliceCalls/productExportDocumentsCall";
import productListTaxHistoryCall from "./_sliceCalls/productListTaxHistoryCall";

export const productsSlice = createSlice({
  name: "products",
  initialState: {
    productList: null,
    productListTotalRows: 0,
    productListFilter: productListDefaultFilter,
    orderProductList: null,
    orderProductListTotalRows: 0,
    orderProductListFilter: {
      limit: 30,
      offset: 0,
      sortField: "DESCRIPTION",
      sortType: "ASC",
      search: null,
    },
    catalogList: null,
    catalogListTotalRows: 0,
    catalogListFilter: catalogListDefaultFilter,
    catalogDetails: null,
    productBase: null,
    productDetails: null,
    productDetailComments: null,
    productDetailCommentsFilter: productDetailCommentsDefaultFilter,
    productDetailCommentsTotalRows: 0,
    campaignList: null,
    campaignListTotalRows: 0,
    campaignListFilter: campaignsListDefaultFilter,
    campaignDetails: null,
    import: undefined,
    familyList: null,
    familyListTotalRows: 0,
    familyListFilter: {
      limit: 10000,
      offset: 0,
      search: null,
      active: true,
    },
    formFamilyList: [],
    pendingProductList: null,
    pendingProductListTotalRows: 0,
    pendingProductListFilter: pendingProductsDefaultFilter,
    pendingProductDetails: null,
    groupContacts: [],
    checkInternalCodeResult: {},
    productBasePendingHierarchy: false,
    productBaseError: false,
    productPickerList: null,
    productPickerListTotalRows: 0,
    productPickerListFilter: productPickerListDefaultFilter,
    productTaxList: [],
    productTaxListTotalRows: 0,
    productTaxListFilter: productsTaxListDefaultFilter,
    tempProductTax: [],
    tempProductTaxValidation: {},
    productTaxHistoryList: [],
    productTaxHistoryListTotalRows: 0,
    productTaxHistoryListFilter: productsTaxHistoryListDefaultFilter,
  },
  reducers: {
    clearProductList: (state) => {
      state.productList = [];
    },

    appendToProductList: (state, action) => {
      state.productList = [...(state.productList || []), ...action.payload];
    },

    updateProductListThumbnails: (state, action) => {
      let nProductList = state.productList;
      (action.payload || []).forEach(({ productId, thumbnail, image }) => {
        let productIndex = nProductList.findIndex(item => item.productId === productId);
        if (nProductList[productIndex]) {
          nProductList[productIndex].thumbnail = thumbnail;
          nProductList[productIndex].image = image;
        }
      });
      state.productList = nProductList;
    },

    setProductListTotalRows: (state, action) => {
      state.productListTotalRows = action.payload;
    },

    setProductListFilter: (state, action) => {
      state.productListFilter = action.payload;
    },

    clearOrderProductList: (state) => {
      state.orderProductList = [];
    },

    appendToOrderProductList: (state, action) => {
      state.orderProductList = [...(state.orderProductList || []), ...action.payload];
    },

    setOrderProductListTotalRows: (state, action) => {
      state.orderProductListTotalRows = action.payload;
    },

    setOrderProductListFilter: (state, action) => {
      state.orderProductListFilter = action.payload;
    },

    clearCatalogList: (state) => {
      state.catalogList = [];
    },

    appendToCatalogList: (state, action) => {
      state.catalogList = [...(state.catalogList || []), ...action.payload];
    },

    setCatalogListTotalRows: (state, action) => {
      state.catalogListTotalRows = action.payload;
    },

    setCatalogListFilter: (state, action) => {
      state.catalogListFilter = action.payload;
    },

    activateCatalog: (state, action) => {
      state.catalogList = state.catalogList.map((item) => {
        if (item.id === action.payload.id) {
          item.active = action.payload.active;
        }
        return item;
      });
    },

    setCatalogDetails: (state, action) => {
      state.catalogDetails = action.payload;
    },

    setProductBase: (state, action) => {
      state.productBase = action.payload;
    },

    updateProductBaseHierarchy: (state, action) => {
      state.productBase = {
        ...state.productBase,
        hierarchy: action.payload
      };
    },

    setProductDetails: (state, action) => {
      state.productDetails = action.payload;
    },

    clearCampaignList: (state) => {
      state.campaignList = [];
    },

    appendToCampaignList: (state, action) => {
      state.campaignList = [...(state.campaignList || []), ...action.payload];
    },

    setCampaignListTotalRows: (state, action) => {
      state.campaignListTotalRows = action.payload;
    },

    setCampaignListFilter: (state, action) => {
      state.campaignListFilter = action.payload;
    },

    setCampaignDetails: (state, action) => {
      state.campaignDetails = action.payload;
    },

    setImport: (state, action) => {
      state.import = action.payload;
    },

    clearFamilyList: (state) => {
      state.familyList = [];
    },

    appendToFamilyList: (state, action) => {
      state.familyList = [...(state.familyList || []), ...action.payload];
    },

    setFamilyListTotalRows: (state, action) => {
      state.familyListTotalRows = action.payload;
    },

    setFamilyListFilter: (state, action) => {
      state.familyListFilter = action.payload;
    },

    setFormProductFamilies: (state, action) => {
      state.formFamilyList = action.payload;
    },

    clearPendingProductList: (state) => {
      state.pendingProductList = [];
    },

    appendToPendingProductList: (state, action) => {
      appendItemsToList(state.pendingProductList, action.payload, true, "processId");
    },

    updatePendingProductsThumbnails: (state, action) => {
      let nPendingProducts = state.pendingProductList;
      (action.payload || []).forEach(({ productId, thumbnail }) => {
        let productIndex = nPendingProducts.findIndex(item => item.productId === productId);
        if (nPendingProducts[productIndex]) {
          nPendingProducts[productIndex].thumbnail = thumbnail;
        }
      });
      state.pendingProductList = nPendingProducts;
    },

    insertItemIntoPendingProductList: (state, action) => {
      appendItemsToList(state.pendingProductList, [action.payload], false, "processId");
      state.pendingProductListTotalRows++;
    },

    setPendingProductListTotalRows: (state, action) => {
      state.pendingProductListTotalRows = action.payload;
    },

    setPendingProductListFilter: (state, action) => {
      state.pendingProductListFilter = action.payload;
    },

    setPendingProductDetails: (state, action) => {
      state.pendingProductDetails = action.payload;
    },

    setGroupContacts: (state, action) => {
      state.groupContacts = action.payload;
    },

    setCheckInternalCodeResult: (state, action) => {
      state.checkInternalCodeResult = action.payload;
    },

    setProductBasePendingHierarchy: (state, action) => {
      state.productBasePendingHierarchy = action.payload;
    },
    setProductBaseError: (state, action) => {
      state.productBaseError = action.payload;
    },

    clearProductPickerList: (state) => {
      state.productPickerList = [];
    },
    setProductPickerListFilter: (state, action) => {
      state.productPickerListFilter = action.payload;
    },
    appendToProductPickerList: (state, action) => {
      state.productPickerList = [...(state.productPickerList || []), ...action.payload];
    },
    setProductPickerListTotalRows: (state, action) => {
      state.productPickerListTotalRows = action.payload;
    },

    clearProductTaxList: (state) => {
      state.productTaxList = [];
    },
    appendToProductTaxList: (state, action) => {
      appendItemsToList(state.productTaxList, action.payload, true, "productId");
    },
    refreshProductTaxList: (state, action) => {
      state.productTaxList = action.payload;
    },
    setProductTaxListTotalRows: (state, action) => {
      state.productTaxListTotalRows = action.payload;
    },
    setProductTaxListFilter: (state, action) => {
      state.productTaxListFilter = action.payload;
    },

    clearProductTaxHistoryList: (state) => {
      state.productTaxHistoryList = [];
    },
    appendToProductTaxHistoryList: (state, action) => {
      appendItemsToList(state.productTaxHistoryList, action.payload, true, "actionDate");
    },
    refreshProductTaxHistoryList: (state, action) => {
      state.productTaxHistoryList = action.payload;
    },
    setProductTaxHistoryListTotalRows: (state, action) => {
      state.productTaxHistoryListTotalRows = action.payload;
    },
    setProductTaxHistoryListFilter: (state, action) => {
      state.productTaxHistoryListFilter = action.payload;
    },

    // addTempProductTax: (state, action) => {
    //   const { productId, ...tax } = action.payload;
    //   let productTaxs = (state.tempProductTax[productId] || [])
    //   let nTax = {
    //     ...state.tempProductTax, [productId]: [...productTaxs, tax]
    //   }
    //   state.tempProductTax = nTax;
    // },

    updateTempProductTax: (state, action) => {
      const { productId, tax } = action.payload;
      const currentTempProductTaxes = state.tempProductTax || {};
      const productTaxs = (currentTempProductTaxes?.[productId] || {});
      const nTax = {
        vat: tax.vat || productTaxs.vat,
        startDate: tax.startDate || productTaxs.startDate,
        other: tax.other || productTaxs.other,
      };
      let nProductTaxes = {
        ...currentTempProductTaxes,
        [productId]: nTax
      }
      state.tempProductTax = nProductTaxes;
    },

    clearTempProductTax: (state) => {
      state.tempProductTax = {};
    },

    updateTempProductTaxValidation: (state, action) => {
      const { productId, taxCode, taskId, valid, comment } = action.payload;
      if (!state.tempProductTaxValidation?.[productId]) {
        state.tempProductTaxValidation[productId] = {};
      }
      if (!state.tempProductTaxValidation?.[productId][taskId]) {
        state.tempProductTaxValidation[productId][taskId] = {};
      }
      state.tempProductTaxValidation[productId][taskId][taxCode] = { valid, comment };
    },

    clearTempProductTaxValidation: (state) => {
      state.tempProductTaxValidation = {};
    },

    removeTempProductTax: (state, action) => {
      const { productId, code, isVat } = action.payload;

      // vat
      if (isVat) {
        if (state.tempProductTax[productId].others) {
          state.tempProductTax[productId].vat = undefined;
          state.tempProductTax[productId].startDate = undefined;
          state.tempProductTax[productId].hasChanges = undefined;
        }
        else {
          delete state.tempProductTax[productId];
        }
      }
      // other taxs
      else {
        let nOthers = [];
        (state.tempProductTax[productId].other || []).forEach(item => {
          if (item.code !== code) {
            nOthers.push(item);
          }
        })
        state.tempProductTax[productId].other = nOthers;
      }
    },

    setProductDetailComments: (state, action) => {
      state.productDetailComments = action.payload;
    },

    clearProductDetailComments: (state) => {
      state.productDetailComments = [];
    },

    appendToProductDetailComments: (state, action) => {
      appendItemsToList(state.productDetailComments, action.payload, true, "id");
    },

    setProductDetailCommentsTotalRows: (state, action) => {
      state.productDetailCommentsTotalRows = action.payload;
    },
    setProductDetailCommentsFilter: (state, action) => {
      state.productDetailCommentsFilter = action.payload;
    },
  },
});

export default productsSlice.reducer;

export const {
  clearProductList,
  appendToProductList,
  setProductListTotalRows,
  setProductListFilter,
  clearOrderProductList,
  setOrderProductListFilter,
  appendToOrderProductList,
  setOrderProductListTotalRows,
  setProductBase,
  setProductDetails,
  setProductDetailComments,
  setCheckInternalCodeResult,
  updateProductBaseHierarchy,
  setProductBasePendingHierarchy,
  setProductBaseError,
  insertItemIntoPendingProductList,
  updateTempProductTax,
  removeTempProductTax,
  clearTempProductTax,
  updateTempProductTaxValidation,
  clearTempProductTaxValidation,
  appendToProductTaxList,
  refreshProductTaxList,
  clearProductDetailComments
} = productsSlice.actions;

// SELECTS
export const selectLoader = (state) => state.core.loader.length > 0;
export const selectProductListTotalRows = (state) => state.products.productListTotalRows;
export const selectProductListFilter = (state) => state.products.productListFilter;
export const selectProductList = (state) => state.products.productList;
export const selectOrderProductListTotalRows = (state) => state.products.orderProductListTotalRows;
export const selectOrderProductListFilter = (state) => state.products.orderProductListFilter;
export const selectOrderProductList = (state) => state.products.orderProductList;
export const selectProductBase = (state) => state.products.productBase;
export const selectProductDetails = (state) => state.products.productDetails;
export const selectProductDetailComment = (state) => state.products.productDetailComments;
export const selectProductDetailCommentsFilter = (state) => state.products.productDetailCommentsFilter;
export const selectProductDetailCommentsTotalRows = (state) => state.products.productDetailCommentsTotalRows;
export const selectFormFamilyList = (state) => state.products.formFamilyList;
export const selectPendingProductList = (state) => state.products.pendingProductList;
export const selectPendingProductListTotalRows = (state) => state.products.pendingProductListTotalRows;
export const selectPendingProductListFilter = (state) => state.products.pendingProductListFilter;
export const selectPendingProductDetails = (state) => state.products.pendingProductDetails;
export const selectGroupContacts = (state) => state.products.groupContacts;
export const selectCheckInternalCodeResults = (state) => state.products.checkInternalCodeResult;
export const selectProductBasePendingHierarchy = (state) => state.products.productBasePendingHierarchy;
export const selectProductBaseError = (state) => state.products.productBaseError;
export const selectProductPickerListTotalRows = (state) => state.products.productPickerListTotalRows;
export const selectProductPickerListFilter = (state) => state.products.productPickerListFilter;
export const selectProductPickerList = (state) => state.products.productPickerList;
export const selectProductTaxList = (state) => state.products.productTaxList;
export const selectProductTaxListTotalRows = (state) => state.products.productTaxListTotalRows;
export const selectProductTaxListFilter = (state) => state.products.productTaxListFilter;
export const selectTempProductTaxList = (state) => state.products.tempProductTax;
export const selectTempProductTaxValidationList = (state) => state.products.tempProductTaxValidation;
export const selectProductTaxHistoryList = (state) => state.products.productTaxHistoryList;
export const selectProductTaxHistoryListTotalRows = (state) => state.products.productTaxHistoryListTotalRows;
export const selectProductTaxHistoryListFilter = (state) => state.products.productTaxHistoryListFilter;

// CALLS
export const productCatalog =
  (filter, orderBy, clear = false) =>
    (dispatch, getState) => {
      productCatalogCall(productsSlice.actions, dispatch, getState(), { filter, orderBy, clear });
    };

export const productCreate = (product) => (dispatch, getState) => {
  productCreateCall(dispatch, getState(), product);
};

export const productDefineHierarchy = (productId, hierarchy) => (dispatch) => {
  productDefineHierarchyCall({ productGet }, dispatch, { productId, hierarchy });
};

export const productGetBase = (gtin, gln, ignoreExternalProviders = false) => (dispatch, getState) => {
  productGetBaseCall(productsSlice.actions, dispatch, getState(), { gtin, gln, ignoreExternalProviders });
};

export const productGet = (productId) => (dispatch, getState) => {
  productGetCall(productsSlice.actions, dispatch, getState(), { productId });
};

export const productGetComments = (productId, filter, clear = false) => (dispatch, getState) => {
  productGetCommentsCall(productsSlice.actions, dispatch, getState(), { productId, filter, clear });
};

export const productImportProductImages =
  ({ owner, gln, file }) =>
    (dispatch, getState) => {
      productImportProductImagesCall(dispatch, getState(), { owner, gln, file });
    };

export const productList =
  (filter, clear = false) =>
    (dispatch, getState) => {
      productListCall(productsSlice.actions, dispatch, getState(), { filter, clear });
    };

export const productUpdate = (product, ogProduct) => (dispatch, getState) => {
  productUpdateCall({ productGet }, dispatch, getState(), product, ogProduct);
};

export const formProductListFamily = () => (dispatch) => {
  formProductListFamilyCall(productsSlice.actions, dispatch);
};

export const productPendingProductList =
  (filter, clear = false) =>
    (dispatch, getState) => {
      productPendingProductListCall(productsSlice.actions, dispatch, getState(), { filter, clear });
    };

export const productPendingProductGet =
  ({ productId, taskId, processId }) =>
    (dispatch, getState) => {
      productPendingProductGetCall(productsSlice.actions, dispatch, getState(), { productId, taskId, processId });
    };

export const productPendingProductAction =
  ({ taskId, productId, status, comment, product, productTemplate }) =>
    (dispatch) => {
      productPendingProductActionCall(dispatch, { taskId, productId, status, comment, product, productTemplate });
    };

export const productDraft = (product) => (dispatch, getState) => {
  productDraftCall({ productGet }, dispatch, getState(), product);
};

export const productRecover = (id, processId) => (dispatch) => {
  productRecoverCall(productsSlice.actions, dispatch, { id, processId });
};

export const productGroupContacts = () => (dispatch) => {
  productGroupContactsCall(productsSlice.actions, dispatch);
};

export const productCheckInternalCode = ({ internalCode }) => (dispatch) => {
  productCheckInternalCodeCall(productsSlice.actions, dispatch, { internalCode });
};

export const productPickerList = (filter, clear = false) => (dispatch, getState) => {
  productList_pickerCall(productsSlice.actions, dispatch, getState(), { filter, clear });
};

export const productListTax = (filter, clear = false) => (dispatch) => {
  productListTaxCall(productsSlice.actions, dispatch, { filter, clear });
};

export const productCreateTax = () => (dispatch, getState) => {
  productCreateTaxCall(productsSlice.actions, dispatch, getState())
}

export const productPendingTaxAction = () => (dispatch, getState) => {
  productPendingTaxActionCall(productsSlice.actions, dispatch, getState());
};

export const productRemoveTax = ({ productId, code, startDate, value, type }) => (dispatch) => {
  productRemoveTaxCall(dispatch, { productId, code, startDate, value, type });
};

export const productChangeContactPoint = ({ taskId, user, contactId, productId }) => (dispatch) => {
  productChangeContactPointCall({ productGet }, dispatch, { taskId, user, contactId, productId });
};

export const productExportDocuments = ({ productIds }) => (dispatch) => {
  productExportDocumentsCall(dispatch, {productIds});
};
export const productListTaxHistory = (filter, clear = false) => (dispatch) => {
  productListTaxHistoryCall(productsSlice.actions, dispatch, { filter, clear });
};
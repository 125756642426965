import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Box } from "@mui/material";

import { styles } from "./OrderStatus.styles";
import OrderStatusStepper from "./OrderStatusStepper/OrderStatusStepper";
import OrderStatusDetails from "./OrderStatusDetails/OrderStatusDetails";
import CustomTooltip from "../../../../common/displays/CustomTooltip/CustomTooltip";

function OrderStatus({ classes, order, justifyContent = "center", allowAccept, allowDispatch }) {
  const { t } = useTranslation();
  const { status, statusCompleted, statusToCompleteList } = order || {};

  return (
    <Grid container className={classes.root} direction="row" wrap="nowrap" spacing={1} justifyContent={justifyContent} alignItems="center">
      <Grid item>
        <Grid container direction="column" spacing={1} alignItems="center" >
          <Grid item>
            <CustomTooltip title={<OrderStatusDetails order={order} allowAccept={allowAccept} allowDispatch={allowDispatch} />} placement="left">
              <Box>
                <OrderStatusStepper status={status} statusToCompleteList={statusToCompleteList} />
              </Box>
            </CustomTooltip>
          </Grid>
          <Grid item>
            <Typography component="span" color="textPrimary">
              {statusCompleted === false && "Partial "}
              {t("common.Enums.orderStatus." + status)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(OrderStatus, styles);

export const styles = (theme) => ({
  root: {},
  qty: {
    width: 90,
  },
  input: {
    maxWidth: 90,
    "& .MuiInputBase-input": {
      padding: "4px 0px 4px 4px !important",
    },
  },
  btnAdd: {
    minWidth: 30,
  },
  lbl: {
    fontWeight: "normal",
  },
});

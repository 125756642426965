export const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: 0,
  },
  scroll: {
    overflow: "hidden",
    overflowX: "hidden !important",
    position: "relative",
    height: "calc(100vh - 610px)",
    transition: "width .30s ease-in-out",
  },
  tabs: {
    width: "100%",
    "& .MuiTab-root": {
      minWidth: 0,
    },
  },
  item: {
    width: "100%",
    margin: 0,
    padding: 0,
  },
});

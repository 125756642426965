import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const promoterAddExternalAccessCall = async ({ entityGet }, dispatch, { entityId, username }) => {
  let rh = requestHelper(dispatch, "ADD_EXTERNAL_ACCESS");
  try {
    await call({
      type: "PROMOTER",
      subType: "ADD_EXTERNAL_ACCESS",
      request: {
        entityId,
        username,
        type: "WS",
      },
    });
    dispatch(setSuccess("entities", "PROMOTER_ADD_EXTERNAL_ACCESS"));
    setTimeout(() => {
      dispatch(entityGet(entityId));
    }, 300);
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default promoterAddExternalAccessCall;

import { Chip, Grid, Tooltip, Typography } from "@mui/material";
import MessageIcon from '@mui/icons-material/Message';
import DateTime from "../../../../common/displays/DateTime/DateTime";

export function listColumns(t, classes) {
  return [
    {
      field: "entityName",
      headerName: t("entities.PendingEntityList.columns.entityName"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "date",
      headerName: t("entities.PendingEntityList.columns.date"),
      align: "center",
      headerAlign: "center",
      customValue: (v) => {
        return <DateTime date={v.date} />;
      },
    },
    {
      field: "processType",
      headerName: t("entities.PendingEntityList.columns.processType"),
      align: "center",
      headerAlign: "center",
      disableSort: true,
      customValue: (v) => {
        return v.processType === "FlowUpdateEntity"
          ? <Chip size="small" label={t("entities.PendingEntityList.columns.valueUpdate")} />
          : <Chip size="small" color="primary" label={t("entities.PendingEntityList.columns.valueCreate")} />
      },
    },
    {
      field: "status",
      headerName: t("entities.PendingEntityList.columns.status"),
      align: "center",
      headerAlign: "center",
      customValue: (v) => {
        return v.comment ? (
          <Tooltip title={v.comment}>
            <Grid container spacing={1} justifyContent="center" alignItems="center">
              <Grid item>
                <Typography>{t("common.Enums.entityWorkflow." + v.status)}</Typography>
              </Grid>
              <Grid item>
                <MessageIcon fontSize="small" style={{ display: "inline" }} />
              </Grid>
            </Grid>
          </Tooltip>
        ) : (
          <Typography>{t("common.Enums.entityWorkflow." + v.status)}</Typography>
        );
      },
    },
  ];
}

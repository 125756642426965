import React from "react";
import { withStyles } from "tss-react/mui";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { styles } from "./SortableTabs.styles";
import { Stack, Typography } from "@mui/material";

function SortableTabs({ classes, tab, setTab, items, setItems }) {

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }
    setItems(moveArrayItem(items, oldIndex, newIndex));
    if (tab === oldIndex) {
      setTab(newIndex);
    }
    if (tab === newIndex) {
      setTab(oldIndex);
    }
  };

  return (
    <div className={classes.root}>
      <SortableList items={items} tab={tab} setTab={setTab} onSortEnd={onSortEnd} useDragHandle axis="x" />
    </div>
  );
}

export default withStyles(SortableTabs, styles);

const moveArrayItem = (array, fromIndex, toIndex) => {
  const newArray = [...array];
  const [movedItem] = newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, movedItem);
  return newArray;
};

const DragHandle = SortableHandle(() => (
  <span style={{ cursor: 'grab', paddingRight: 8 }}>
    <DragIndicatorIcon style={{ display: "block" }} fontSize="small" />
  </span>
));

const SortableItem = SortableElement(({ value, text, active, onTabClick }) => (
  <Tab value={value} onClick={() => onTabClick(value)} label={<Stack direction="row" alignItems="center">
    <DragHandle />
    <Typography style={{ margin: 0, whiteSpace: "nowrap", textDecoration: active ? "" : "line-through" }}>{text}</Typography>
  </Stack>} />
));

const SortableList = SortableContainer(({ items, tab, setTab }) => (
  <Tabs value={tab}>
    {items.map(({ text, active }, index) => (
      <SortableItem key={`item-${text}`} index={index} value={index} text={text} active={active} onTabClick={(newTab) => setTab(newTab)} />
    ))}
  </Tabs>
));
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import moment from "moment";

import { styles } from "./ProductVatFormModal.styles";
import { formFields, formSchema } from "./ProductVatFormModal.schema";

import { Form, FormFields } from "../../../../common/forms";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";
import { usePromoter } from "../../../../common/hooks";

function ProductVatFormModal({ classes, open, setOpen, product, ogProduct, onSubmit }) {
  const { t } = useTranslation();
  const promoter = usePromoter();

  const onSubmitHandler = async (values) => {
    const { vat, startDate } = values;
    onSubmit && onSubmit(
      product,
      {
        vat,
        startDate: moment(startDate).toISOString()
      }
    );
  };

  return (
    <CustomModal open={open} setOpen={setOpen} size="medium" height={320} title={t("products.ProductVatFormModal.modal.title")}>
      <div className={classes.root}>
        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t })}
          initialValues={{
            vat: product?.tax?.vat || "VAT_0",
            startDate: product?.tax?.startDate ? moment(product?.tax?.startDate) : moment()
          }}
          render={({ values }) => {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormFields fields={formFields({ t, values, ogProduct, promoter })} />
                </Grid>
                <Grid item>
                  <Button variant="contained" disabled={ogProduct?.tax?.vat === values.vat} color="primary" type="submit" fullWidth>
                    {t("products.ProductVatFormModal.form.btnSubmit")}
                  </Button>
                </Grid>
              </Grid>
            );
          }}
        />
      </div>
    </CustomModal>
  );
}

export default withStyles(ProductVatFormModal, styles);

import React from "react";
import { withStyles } from "tss-react/mui";
import { Paper, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { styles } from "./ProductTile.styles";
import { PicturePlaceholder } from "../../../../../common/icons";
import CampaignTooltip from "../../../_common/CampaignTooltip/CampaignTooltip";
import PriceSelector from "../../common/PriceSelector/PriceSelector";
import { QuantityInput } from "../../../../../common/inputs/custom";

function ProductTile({ classes, product, onPriceChanged, onQuantityChanged, currentOrder }) {
  const { t } = useTranslation();

  let currentOrderProduct = (currentOrder?.products || []).find((item) => item.productId === product.productId);
  let availableCampaigns = (product.campaigns || []).filter((item) => item.active);
  let merchCampaign = availableCampaigns.find((camp) => camp.campaignType === "MB");
  return (
    <Paper className={classes.mosaic}>
      {product.thumbnail || product.image  ? (
        <div className={classes.picBox}>
          <img alt={product.description} src={product.thumbnail ? product.thumbnail : product.image } />
        </div>
      ) : (
        <div className={classes.picBox}>
          <PicturePlaceholder />
        </div>
      )}
      <Grid container className={classes.content} direction="column" justifyContent="space-between" wrap="nowrap">
        <Grid item>
          <Grid container spacing={1} direction="column">
            {availableCampaigns && (
              <Grid item>
                <Grid container spacing={1}>
                  {availableCampaigns.map((item, index) => (
                    <Grid item key={`campaign_${index}`}>
                      <CampaignTooltip
                        campaign={item}
                        quantity={currentOrderProduct?.quantity || 0}
                        currentOrder={currentOrder}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            <Grid item>
              <Typography component="span" variant="caption" color="textPrimary">
                {product.description}
              </Typography>
              <Typography component="span" variant="caption" color="textSecondary">
                {product.ownerName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  {product.isOffer ? t("orders.ProductsMosaic.card.offer") : t("orders.ProductsMosaic.card.quantity")}
                </Grid>
                <Grid item>
                  <QuantityInput
                    slim
                    style={{ width: 120 }}
                    min={product.minQuantity}
                    max={product.maxQuantity}
                    multi={product.quantityMultiple}
                    value={product.isOffer ? product.quantity : currentOrderProduct?.quantity || 0}
                    onChange={(value) => onQuantityChanged({ ...product, ...currentOrderProduct, quantity: value })}
                    disabled={product.isOffer}
                    hierarchy={product.hierarchy}
                  />
                  {merchCampaign && currentOrderProduct?.quantity >= merchCampaign?.merchandiseBonusesCampaign?.qtBuy && (
                    <Typography className={classes.offerMerch}>
                      {t("orders.OrdersCreate.columns.quantityOffer", {
                        offer: Math.floor(
                          (currentOrderProduct?.quantity / (merchCampaign?.merchandiseBonusesCampaign?.qtBuy || 1)) *
                            merchCampaign?.merchandiseBonusesCampaign?.qtOffer
                        ),
                      })}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {!product.isOffer && (
              <Grid item>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>{t("orders.ProductsMosaic.card.price")}</Grid>
                  <Grid item>
                    <PriceSelector
                      product={{ ...product, ...currentOrderProduct }}
                      quantity={currentOrderProduct?.quantity}
                      onChange={(value) => onPriceChanged({ ...product, ...currentOrderProduct, ...value })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(ProductTile, styles)

import React from "react";
import { withStyles } from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {CustomDataGrid} from "../../../../common/displays/CustomDataGrid";

import {styles} from "./ImportResultsGrid.styles";
import {listColumns} from "./ImportResultsGrid.columns";
import {Grid, Paper, Typography} from "@mui/material";

function ImportResultsGrid({
  classes,
  data,
  totalRows,
  onEntitySelected,
  onLoadMore,
  onFilter,
    title
}) {
  const { t } = useTranslation();

  return (
      <Paper className={classes.root} elevation={0}>
    <div >

      <Grid container direction="column">
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Typography variant="h6" color="textSecondary">
              {title}
            </Typography>
          </Grid>
        </Grid>
        {data && (
            <CustomDataGrid
                columns={listColumns(t, classes)}
                rows={data}
                total={totalRows}
            />
        )}
      </Grid>
    </div>
      </Paper>
  );
}

export default withStyles(ImportResultsGrid, styles)

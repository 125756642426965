import * as Yup from "yup";
import { SelectField } from "../../../../../common/forms";
import { getContactPointOptions } from "../../../ProductForm/ProductForm.options";

export function formSchema({ t }) {
  return Yup.object().shape({
    contactId: Yup.string().required(t("products.ContactPointChangeModal.form.contactPointRequired")),
  });
}

export function formFields({ t, groupContacts }) {
  return [
    {
      size: 12,
      field: <SelectField
        id="contactId"
        required
        label={t("products.ContactPointChangeModal.form.contactPoint")}
        options={getContactPointOptions(groupContacts)}
      />,
    },
  ];
}


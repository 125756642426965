import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const batchListPlacesCall = async (
  { setPlacesList },
  dispatch,
  { businessUnitId }
) => {
  let rh = requestHelper(dispatch, "BATCH_LIST_PLACES");
  try {
    let result = await call({
      type: "BATCH",
      subType: "LIST_PLACES",
      request: {
        businessUnitId
      },
    });

    dispatch(setPlacesList(result.places));

  } catch (ex) {
    rh.error("batches", ex);
  }
  rh.close();
};

export default batchListPlacesCall;


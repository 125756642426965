import { Slider, Stack, Tooltip, Typography } from "@mui/material";
import { dateFormat } from "../../../../common/utils";
import MeasureInput from "../../../../common/components/inputs/MeasureInput";
import { getValue } from "../../_common/planningUtils";
import StarIcon from '@mui/icons-material/Star';

export function listColumns(t, tempData, defaultPlanning, onMeasureChange, total) {
  let columns = [];

  const onSliderChangeHandler = (val, row) => {
    const nValue = val / 100 * parseFloat(total);
    onMeasureChange({ ...defaultPlanning?.target, value: nValue.toFixed(0) }, row)
  }

  columns.push({
    field: "name",
    headerName: t("planning.PlanningList.columns.name"),
    align: "left",
    headerAlign: "left",
    disableSort: true,
    customValue: (v) => {
      return <Stack direction="row" spacing={1} alignItems="center">
        {v.defaultPlan &&
          <Tooltip title={t("planning.PlanningList.columns.defaultTooltip")}>
            <StarIcon fontSize="small" />
          </Tooltip>
        }
        <div>
          <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{v.name}</Typography>
        </div>
      </Stack>
    }
  });

  columns.push({
    field: "startDate",
    headerName: t("planning.PlanningList.columns.startDate"),
    align: "left",
    headerAlign: "center",
    disableSort: true,
    customValue: (v) => {
      return <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{dateFormat(v.startDate)}</Typography>
    }
  });

  columns.push({
    field: "endDate",
    headerName: t("planning.PlanningList.columns.endDate"),
    align: "left",
    headerAlign: "center",
    disableSort: true,
    customValue: (v) => {
      return <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{dateFormat(v.endDate)}</Typography>
    }
  });

  columns.push({
    field: "percentage",
    headerName: t("planning.PlanningList.columns.percentage"),
    align: "center",
    headerAlign: "center",
    width: 100,
    disableSort: true,
    customValue: (v) => {
      const tempValue = tempData.find(item => item.id === v.id)?.target?.value;
      const percentage = parseFloat(getValue(tempValue, v.target?.value)) / parseFloat(total) * 100;
      return <Stack style={{ width: 100 }}>
        <Typography style={{ margin: 0, fontSize: 12, lineHeight: 1 }}>{(percentage).toFixed(1)} %</Typography>
        <Slider
          size="small"
          value={percentage}
          step={0.1}
          min={0}
          max={100}
          onChange={(_, val) => onSliderChangeHandler(val, v)}
        />
      </Stack>
    }
  });

  columns.push({
    field: "target",
    headerName: t("planning.PlanningList.columns.target"),
    align: "right",
    headerAlign: "center",
    width: 290,
    disableSort: true,
    customValue: (v) => {
      const tempValue = tempData.find(item => item.id === v.id)?.target?.value;
      return <MeasureInput
        value={{ ...defaultPlanning?.target, value: getValue(tempValue, v.target?.value) }}
        fixedCategory={defaultPlanning?.target?.category}
        onChange={(val) => onMeasureChange(val, v)}
      />
    }
  });



  return columns;
}

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const batchListInternalCall = async (
  { setInternalBatchList },
  dispatch,
  { businessUnitId }
) => {
  let rh = requestHelper(dispatch, "BATCH_LIST_INTERNAL");
  try {
    let result = await call({
      type: "BATCH",
      subType: "LIST_INTERNAL",
      request: {
        businessUnitId
      },
    });

    if (result) {
      dispatch(setInternalBatchList(result.items));
    }
  } catch (ex) {
    rh.error("batches", ex);
  }
  rh.close();
};

export default batchListInternalCall;


export const getOperatorOptions = (operators) => {
  return (operators || []).map((item) => {
    return {
      value: item.id,
      text: item.description,
    };
  })
}

export const getContactOptions = (entity) => {
  return (entity?.contacts || []).map((item) => {
    return {
      value: item.id,
      text: item.firstName + " " + item.lastName,
    };
  })
}

import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography } from "@mui/material";

import { Field } from "react-final-form";
import MetaError from "../../forms/MetaError";

import { styles } from "./ProductPickerField.styles";

import ProductPicker from "../../inputs/custom/ProductPicker/ProductPicker";

function ProductPickerField({
  classes,
  id,
  label,
  disabled,
  required,
  height = 300,
  single,
  simple,
  readonly,
  ownerId,
  blackListedProducts,
  customFilters
}) {
  const onProductsSelectedChangeHandler = (products, input) => {
    input.onChange && input.onChange(products);
  };

  const onAllProductsChangeHandler = (v, input) => {
    input.onChange && input.onChange(v ? "ALL" : []);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <div>
            <ProductPicker
              label={
                <Typography
                  component="span"
                  variant="caption"
                  htmlFor={id}
                  color="textPrimary"
                  className={disabled ? classes.disabledLabel : ""}
                >
                  {label}
                  {required && <span> *</span>}
                </Typography>
              }
              height={height}
              single={single}
              simple={simple}
              readonly={readonly}
              selectedProducts={input.value !== "ALL" ? input.value : []}
              allProducts={input.value === "ALL"}
              onAllProductsChange={(v) => onAllProductsChangeHandler(v, input)}
              onProductsSelectedChange={(v) => onProductsSelectedChangeHandler(v, input)}
              ownerId={ownerId}
              blackListedProducts={blackListedProducts}
              disabled={disabled}
              customFilters={customFilters}
            />

            <MetaError className={classes.error} meta={meta} />
          </div>
        )}
      </Field>
    </div>
  );
}

export default withStyles(ProductPickerField, styles)

const productsFamilies = {
  flowsMessages: {
    success: {
      PRODUCT_CREATE_FAMILY: "Product family created successfully!",
      PRODUCT_UPDATE_FAMILY: "Product family updated successfully!",
    },
    error: {
      GENERIC: "Something went wrong",
    },
  },
  FamilyList: {
    header: {
      title: "Product Families",
    },
    item: {
      empty: "(not defined)",
    },
  },
  FamilyForm: {
    form: {
      id: "Code",
      parent: "Parent",
      type: "Type",
      description: "Description ({{language}})",
      requiredId: "Code is required",
      requiredType: "Type is required",
      requiredDescription: "This description is required",
      updateBtn: "Update",
      addBtn: "Create",
      deleteBtn: "Delete",
    },
    deleteConfirm: {
      title: "Are you sure?",
      description: "This action is not reversible",
      ok: "Ok",
      cancel: "Cancel",
    },
  },
};

export default productsFamilies;

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { styles } from "./DocumentsDataGrid.styles";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import { listColumns } from "./DocumentsDataGrid.columns";

function DocumentsDataGrid({ classes,
  data,
  totalRows,
  onRowSelected,
  onEdit }) {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          localFilters
          columns={listColumns(t, classes)}
          rows={data}
          onRowSelected={onRowSelected}
          total={totalRows}
        />
      )}
    </div>
  );
}

export default withStyles(DocumentsDataGrid, styles)

import { dateTimeFormatWithMilliSeconds } from "../../../../common/utils/formats";

export function listColumns(t, classes) {
  return [
    {
      field: "type",
      headerName: t("business.audit.ws.columns_type"),
      align: "left",
      headerAlign: "left",
    },{
      field: "operation",
      headerName: t("business.audit.ws.columns_operation"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "entity",
      headerName: t("business.audit.ws.columns_entity"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "userRequest",
      headerName: t("business.audit.ws.columns_userRequest"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "requestTime",
      headerName: t("business.audit.ws.columns_requestTime"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.requestTime));
      },
    },
    /*{
      field: "request",
      headerName: t("business.audit.ws.columns_request"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },*/
    {
      field: "responseTime",
      headerName: t("business.audit.ws.columns_responseTime"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.responseTime));
      },
    },
    /*{
      field: "response",
      headerName: t("business.audit.ws.columns_response"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },*/
    {
      field: "error",
      headerName: t("business.audit.ws.columns_error"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (v.error ? t("business.audit.ws.columns_error_true") : t("business.audit.ws.columns_error_false"));
      },
    },
    {
      field: "ipAddress",
      headerName: t("business.audit.ws.columns_ipAddress"),
      align: "left",
      headerAlign: "left",
    }
    /*,{
      field: "promoterCode",
      headerName: t("business.audit.ws.columns_promoterCode"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    }*/
  ];
}

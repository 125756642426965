import { processImageUrl } from "../../../api/s3";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { asyncForEach } from "../../../common/utils";

const productExtraPriceListCall = async (
  { clearExtraPricesList, setExtraPricesListFilter, appendToExtraPricesList, setExtraPricesListTotalRows, updateExtraPricesListThumbnails },
  dispatch,
  state,
  { filter, clear }
) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "PRODUCT_PRICE_LIST");
  if (clear) {
    dispatch(clearExtraPricesList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setExtraPricesListFilter(filter));
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "PRICE_LIST",
      request: {
        ...filter,
        justPriceAccepted: true,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    dispatch(appendToExtraPricesList(result.products));
    dispatch(setExtraPricesListTotalRows(result.rows));

    let products = [];
    await asyncForEach(result.products, async (product) => {
      products.push({
        ...product,
        thumbnail: await processImageUrl(fileStorageConfigs,  product.thumbnail),
      });
    });

    dispatch(updateExtraPricesListThumbnails(products));

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productExtraPriceListCall;

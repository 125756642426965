import React from "react";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";
import {
  Paper,
  Typography,
  Grid,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";

import { styles } from "./EntityContacts.styles";
import Permission from "../../../../app/components/Permission";

function EntityContacts({ classes, onMore, contacts, disabled }) {
  const { t } = useTranslation();
  return (
    <Paper className={classes.root} elevation={0} id="entityContacts">
      <div className={classes.container}>
        <Grid container direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            align="left"
            component="span"
            color="textSecondary"
            className={classes.title}
          >
            {t("entities.Entities.contacts.title")} ({contacts?.length || 0})
          </Typography>
          {!disabled &&
            <Permission code="entities.actions.contact.add">
              <Button
                color="primary"
                variant="text"
                onClick={onMore}
                className={classes.btn}
                style={{ marginRight: 10 }}
              >
                {t("entities.Entities.contacts.btnMore")}
              </Button>
            </Permission>
          }
        </Grid>
        <List>
          {(contacts || []).slice(0, 5).map((contact) => {
            let displayName =
              contact?.firstName || contact?.lastName
                ? (contact?.firstName || "") + " " + (contact?.lastName || "")
                : contact?.email;
            return (
              <ListItem key={"item_ " + contact.id} disableGutters>
                <ListItemAvatar>
                  <Avatar alt={displayName} src={contact.logo} />
                </ListItemAvatar>
                <ListItemText
                  disableTypography
                  primary={
                    <Grid container direction="row" justifyContent="space-between">
                      <Typography
                        variant="h4"
                        align="left"
                        component="h4"
                        className={classes.name}
                      >
                        {displayName}
                      </Typography>
                      <Typography
                        variant="body1"
                        align="left"
                        component="span"
                        className={classes.details}
                      >
                        <a href={`tel:${contact.phoneNumber}`}>
                          {contact.phoneNumber}
                        </a>
                      </Typography>
                    </Grid>
                  }
                  secondary={
                    <Grid container direction="row" justifyContent="space-between">
                      <Typography
                        variant="body1"
                        align="left"
                        component="span"
                        className={classes.details}
                      >
                        {t("common.Enums.roleType." + contact.roleType)}
                      </Typography>
                      <Typography
                        variant="body1"
                        align="left"
                        component="span"
                        className={classes.details}
                      >
                        <a href={`mailto:${contact.email}`}>{contact.email}</a>
                      </Typography>
                    </Grid>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </div>
    </Paper>
  );
}

export default withStyles(EntityContacts, styles)

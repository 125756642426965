import React from "react";
import { withStyles } from "tss-react/mui";
import { Grid, Typography } from "@mui/material";

import { styles } from "./CommentBox.styles";
import UserSnip from "../snips/UserSnip/UserSnip";
import { dateTimeFormatWithSeconds } from "../../utils/formats";

function CommentBox({ classes, comment }) {

  return (
    <div className={classes.root}>
      <Grid container direction="column">
        <Grid item>
          <div className={classes.avatar}>
            <UserSnip contact={comment} />
          </div>
        </Grid>
        <Grid item>
          <Typography className={classes.comment}>{comment?.comment}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.date}>{dateTimeFormatWithSeconds(comment?.createdTime)}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(CommentBox, styles);
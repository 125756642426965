import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Stack, Button } from "@mui/material";
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import i18next from "i18next";

import { styles } from "./ProductCustomForms.styles";
import { useFirstLoad, usePromoter } from "../../../../common/hooks";
import { promoterGetCustomForms, promoterListCustomForms, selectCustomFormDetails, selectCustomFormsList } from "../../../promoter/promoterSlice";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";
import DynamicForm from "../../../../common/forms/DynamicForm/DynamicForm";
import { useField, useForm } from "react-final-form";
import ProductPicker from "../../../../common/inputs/custom/ProductPicker/ProductPicker";
import productGetFormData from "../../_directCalls/productGetFormData";

function ProductCustomForms({ classes, product }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const promoter = usePromoter();
  const listCustomForms = promoter?.getConfig("product", "listCustomForms");
  const customForms = useSelector(selectCustomFormsList);
  const [selectedFormKey, setSelectedFormKey] = useState();
  const customFormsDetails = useSelector(selectCustomFormDetails);
  const selectedForm = customFormsDetails?.[selectedFormKey];
  const [modal, setModal] = useState(false);
  const [modalCopy, setModalCopy] = useState(false);
  const form = useForm();
  const { meta } = useField("forms");
  const [selectedProductToCopy, setSelectedProductToCopy] = useState()

  useFirstLoad(() => {
    if (listCustomForms?.length > 0 && (!customForms || customForms.length === 0)) {
      dispatch(promoterListCustomForms());
    }
  });

  const onFromOpenHandler = (form) => {
    setSelectedFormKey(form.key);
    if (!customFormsDetails?.[form.key]) {
      dispatch(promoterGetCustomForms(form.id));
    }
    setModal(true);
  }

  const onSubmitHandler = (values) => {
    const existingValue = form.getState().values.forms || {};
    form.change('forms', { ...existingValue, [selectedForm.key]: values });
    setModal(false);
  }

  const onProductSelectHandler = async () => {
    let values = await productGetFormData(selectedProductToCopy.productId, selectedForm.key);
    if (values) {
      const existingValue = form.getState().values.forms || {};
      form.change('forms', { ...existingValue, [selectedForm.key]: values });
    }
    setModalCopy(false);
  }

  const onFormClear = () => {
    const existingValue = form.getState().values.forms || {};
    const { [selectedForm.key]: _, ...rest } = existingValue;
    form.change('forms', rest);
    setModal(false);
  }

  return (
    <div className={classes.root} id="forms">
      <Grid container spacing={2}>
        {meta.error &&
          <Grid item xs={12}>
            <Typography className={classes.error}>{meta.error}</Typography>
          </Grid>
        }
        {
          customForms.filter(item => listCustomForms.includes(item.key)).map((form) => {
            return (
              <Grid item xs={6} key={form.key}>
                <Stack direction="row" justifyContent="space-between" className={classes.formButton} onClick={() => onFromOpenHandler(form)} id={`forms.${form.key}`}>
                  <Stack direction="row" alignItems="center" spacing={2} >
                    <DnsOutlinedIcon />
                    <Typography>{getFormName(form.name)}</Typography>
                  </Stack>
                  {Object.keys(product?.forms || {}).includes(form.key) && <CheckOutlinedIcon color="success" />}
                </Stack>
              </Grid>
            )
          })
        }
      </Grid>
      <CustomModal open={modal} setOpen={setModal} size="large" height={680} title={getFormName(selectedForm?.name)}>
        <DynamicForm
          formObject={selectedForm?.fields}
          extras={
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Button variant="outlined" size="small" onClick={() => setModalCopy(true)}>{t("products.ProductCustomForms.modalCopy.title")}</Button>
              <Button variant="outlined" size="small" color="error" onClick={() => onFormClear()}>{t("products.ProductCustomForms.modal.btnClear")}</Button>
            </Stack>
          }
          height={500}
          initialValues={product?.forms?.[selectedForm?.key]}
          submitLabel={t("products.ProductCustomForms.modal.btnSubmit")}
          onSubmit={onSubmitHandler}
        />
      </CustomModal>
      <CustomModal open={modalCopy} setOpen={setModalCopy} size="medium" height={230} title={t("products.ProductCustomForms.modalCopy.title")}>
        <Stack spacing={4}>
          <ProductPicker
            onProductsSelectedChange={(product => setSelectedProductToCopy(product?.[0]))}
            single
            simple
            selectedProducts={selectedProductToCopy ? [selectedProductToCopy] : null}
            customFilters={{ formKey: selectedForm?.key, justConsumer: false }}
          />
          <Button variant="contained" fullWidth onClick={onProductSelectHandler}>{t("products.ProductCustomForms.modalCopy.btnSubmit")}</Button>
        </Stack>
      </CustomModal>
    </div>
  );
}

export default withStyles(ProductCustomForms, styles);

const getFormName = (name) => {
  if (!name) {
    return "";
  }
  let altLang = Object.keys(name).filter((k) => k !== i18next.language)?.[0];
  return name[i18next.language] || (altLang && name[altLang])
}
import React from "react";
import {useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

import {styles} from "./ProductTaxDataGrid.styles";
import {listColumns} from "./ProductTaxDataGrid.columns";
import ProductTaxDataGridActions from "./ProductTaxDataGrid.actions";
import {filterFields, filterSchema} from "./ProductTaxDataGrid.filter";

import {CustomDataGrid} from "../../../../common/displays/CustomDataGrid";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";
import {productsTaxListDefaultFilter} from "../../_common/productsFilters";
import {usePromoter, useUser} from "../../../../common/hooks";
import {selectFormFamilyList, selectGroupContacts} from "../../productsSlice";
import ProductTaxOthersDataGrid from "./ProductTaxOthersDataGrid/ProductTaxOthersDataGrid";
import moment from "moment";

function ProductTaxDataGrid({
  classes,
  data,
  totalRows,
  filter,
  onFilterChange,
  onRemove,
  loading,
  validation,
  onValidateVat,
  onCopy,
  onEdit,
  onCancel,
  onOtherTaxClick,
  onOtherTaxAdd,
  onVatClick,
  onValidateOtherTax,
  onTaxHistory
}) {
  const { t } = useTranslation();
  const user = useUser();
  const promoter = usePromoter();
  const columns = listColumns({ t, user, promoter, validation, onVatClick });
  const { processId } = useParams();
  const groupContacts = useSelector(selectGroupContacts);
  const families = useSelector(selectFormFamilyList);

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };

      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={columns}
        rows={(data || []).map((row) => ({
          ...row,
          className: getRowClass(classes, row),
          arrow: showRowArrow(row, processId)
        }))}
        // onRowSelected={(row) => console.log(row)}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMoreHandler}
        total={totalRows}
        rowKeyField="tempId"
        actionsComponent={<ProductTaxDataGridActions classes={classes} onRemove={onRemove} validation={validation} onOtherTaxAdd={onOtherTaxAdd} onValidate={onValidateVat} onCopy={onCopy} onEdit={onEdit} onCancel={onCancel} user={user} promoter={promoter} onHistory={onTaxHistory}/>}
        topActions={
          <FilterForm
            style={{ marginBottom: 8 }}
            loading={loading}
            storageKey="ProductTaxDataGrid"
            onStorageLoad={onFilterHandler}
            slim
            fields={filterFields({ families, groupContacts, user, promoter })}
            schema={filterSchema()}
            onFilterChange={onFilterHandler}
            filter={filter}
            defaultFilter={productsTaxListDefaultFilter}
          />
        }
        innerRow={
          (row) => row.tax?.other?.length > 0 ?
            <ProductTaxOthersDataGrid data={row.tax.other} product={row} validation={validation} onOtherTaxClick={onOtherTaxClick} onValidateOtherTax={onValidateOtherTax} onHistory={onTaxHistory} /> :
            null
        }
      />
    </div>
  );
}

export default withStyles(ProductTaxDataGrid, styles);

const getRowClass = (classes, row) => {
  if (row?.hasChanges) {
    return classes.hasChanges;
  }
  if (row?.ogTax?.process?.taxes?.[row?.ogTax?.process?.vatType] === "PENDING") {
    return classes.pending;
  }
  if (row?.ogTax?.process?.taxes?.[row?.ogTax?.process?.vatType] === "ACCEPTED" && moment(row?.ogTax?.process?.date).isAfter(moment().subtract(7, 'days'))) {
    return classes.accepted;
  }
  if (row?.ogTax?.process?.taxes?.[row?.ogTax?.process?.vatType] === "REFUSED" && moment(row?.ogTax?.process?.date).isAfter(moment().subtract(7, 'days'))) {
    return classes.rejected;
  }
  return "";
}

const showRowArrow = (item, processId) => {
  return processId && item && item?.tax?.process?.processId === processId;
}
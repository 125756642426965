import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Tab, Tabs } from "@mui/material";

import { styles } from "./SuppliersTabGroup.styles";
import SuppliersDataGrid from "./SuppliersDataGrid/SuppliersDataGrid";
import CostDeliveryDataGrid from "./CostDeliveryDataGrid/CostDeliveryDataGrid";
import PlatformCostItemsDataGrid from "./PlatformCostItemsDataGrid/PlatformCostItemsDataGrid";
import TransportCostItemsDataGrid from "./TransportCostItemsDataGrid/TransportCostItemsDataGrid";

function SuppliersTabGroup({
  classes,
  dataSuppliers,
  dataCostDelivery,
  dataPlatformCost,
  dataTransportCost,
  filter,
  onFilterChange
}) {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);

  const handleChange = (e, i) => {
    setTab(i);
  };

  return (
    <div>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={tab}
            onChange={handleChange}
            className={classes.tabs}
          >
            <Tab key={"tab_SuppliersList"} label={t("business.reports.SuppliersList.title")} />
            <Tab key={"tab_CostDeliveryList"} label={t("business.reports.CostDeliveryList.title")} />
            <Tab key={"tab_PlatformCostItemsList"} label={t("business.reports.PlatformCostItemsList.title")} />
            <Tab key={"tab_TransportCostItemsList"} label={t("business.reports.TransportCostItemsList.title")} />
          </Tabs>
        </Grid>
        <Grid item>
          {tab === 0 &&
            <SuppliersDataGrid
              data={dataSuppliers}
              filter={filter}
              onFilterChange={onFilterChange}
            />
          }
          {tab === 1 &&
            <CostDeliveryDataGrid
              data={dataCostDelivery}
              filter={filter}
              onFilterChange={onFilterChange}
            />
          }
          {tab === 2 &&
            <PlatformCostItemsDataGrid
              data={dataPlatformCost}
              filter={filter}
              onFilterChange={onFilterChange}
            />
          }
          {tab === 3 &&
            <TransportCostItemsDataGrid
              data={dataTransportCost}
              filter={filter}
              onFilterChange={onFilterChange}
            />
          }
        </Grid>
      </Grid>


    </div>
  );
}

export default withStyles(SuppliersTabGroup, styles)

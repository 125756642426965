


import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const productListTaxCall = async (
  { clearProductTaxList, setProductTaxListFilter, appendToProductTaxList, setProductTaxListTotalRows },
  dispatch,
  { filter, clear }
) => {
  let rh = requestHelper(dispatch, "LIST_TAX_PRODUCT");
  if (clear) {
    dispatch(clearProductTaxList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setProductTaxListFilter(filter));
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "LIST_TAX",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    dispatch(appendToProductTaxList(result.products || []));
    dispatch(setProductTaxListTotalRows(result.rows));

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productListTaxCall;
import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Button, Chip, Stack, Avatar } from "@mui/material";

import { styles } from "./RoleChip.styles";
import { useDispatch, useSelector } from "react-redux";

import CustomTooltip from "../../../../../common/displays/CustomTooltip/CustomTooltip";
import { dateTimeFormat } from "../../../../../common/utils";
import { useNavigate } from "../../../../../common/hooks";
import { rolesCreate, rolesDelete } from "../../../rolesSlice";
import { selectLoader } from "../../../../../app/coreSlice";
import Permission from "../../../../../app/components/Permission";

function RoleChip({ classes, roleType, updatedBy, updatedTime, roleId, isDefault, entityType, entityId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteMode, setDeleteMode] = useState(false);
  const loading = useSelector(selectLoader);

  const onCreateRoleHandler = () => {
    dispatch(rolesCreate({ entityId, entityType, roleType }));
  }

  const onDeleteRoleHandler = () => {
    dispatch(rolesDelete({ roleID: roleId }));
  }

  return isDefault ? (
    <CustomTooltip title={
      <div className={classes.root}>
        <Stack spacing={1}>
          <Typography className={classes.nomargin} variant="body1">{t("roles.RolesList.columns.defaultRoleMessage")}</Typography>
          <Permission code="promoter.actions.role.update">
            <Button size="small" variant="outlined" disabled={loading} onClick={() => navigate("/roles/edit/" + roleId)}>{t("roles.RolesList.columns.editDefaultBtn")}</Button>
          </Permission>
          <Permission code="promoter.actions.role.create">
            <Button size="small" variant="contained" disabled={loading} onClick={onCreateRoleHandler}>{t("roles.RolesList.columns.createBtn")}</Button>
          </Permission>
        </Stack>
      </div>
    }>
      <Chip avatar={<Avatar>{roleType}</Avatar>} label={t("common.Enums.roleType." + roleType)} variant="outlined" />
    </CustomTooltip>
  ) : (
    <CustomTooltip
      onClose={() => setDeleteMode(false)}
      title={
        <div className={classes.root}>
          {
            deleteMode
              ? <Stack spacing={2}>
                <Typography className={classes.nomargin} variant="caption">{t("roles.RolesList.columns.deleteTitle")}</Typography>
                <Typography className={classes.nomargin} variant="body1">{t("roles.RolesList.columns.deleteMessage")}</Typography>
                <Button size="small" variant="outlined" color="error" disabled={loading} onClick={onDeleteRoleHandler}>{t("roles.RolesList.columns.confirmDeleteBtn")}</Button>
              </Stack>
              : <Stack spacing={2}>
                <Stack spacing={1} alignItems="flex-start">
                  <Typography className={classes.nomargin} variant="caption">{t("roles.RolesList.columns.updatedBy")}</Typography>
                  <Typography className={classes.nomargin} variant="body1">{updatedBy}</Typography>
                  <Typography className={classes.nomargin} variant="body1">{dateTimeFormat(updatedTime)}</Typography>
                </Stack>
                <Stack spacing={1}>
                  <Permission code="promoter.actions.role.update">
                    <Button size="small" variant="outlined" disabled={loading} onClick={() => navigate("/roles/edit/" + roleId)}>{t("roles.RolesList.columns.editBtn")}</Button>
                  </Permission>
                  {entityId && roleType !== "A" &&
                    <Permission code="promoter.actions.role.delete">
                      <Button size="small" variant="outlined" color="error" disabled={loading} onClick={() => setDeleteMode(true)}>{t("roles.RolesList.columns.deleteBtn")}</Button>
                    </Permission>
                  }
                </Stack>
              </Stack>
          }
        </div>
      }>
      <Chip avatar={<Avatar>{roleType}</Avatar>} label={t("common.Enums.roleType." + roleType)} color="primary" />
    </CustomTooltip>
  );
}

export default withStyles(RoleChip, styles);
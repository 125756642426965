import {requestHelper} from "../../../app/coreSlice";
import {call} from "../../../app/websocket/_sliceCalls/websocket";

const promoterGetTimerCall = async ({ setPromoterTimerDetails }, dispatch, { id }) => {
  let rh = requestHelper(dispatch, "PROMOTER_GET_TIMER");
  try {
    let result = await call({
      type: "PROMOTER",
      subType: "GET_TIMER",
      request: { id },
    });

    if(result?.detail){
      let nDetail = {
        ...result.detail,
        frequency: result.detail?.dayOfMonth !== 0 ? "dayOfMonth" : "dayOfWeek",
        time: "1970-01-01T" + ("00" + result.detail?.hour).slice(-2) + ":" + ("00" + result.detail?.minute).slice(-2) + ":00",
      }
      dispatch(setPromoterTimerDetails(nDetail));
    }

  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default promoterGetTimerCall;


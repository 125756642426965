import { processImageUrl } from "../../../api/s3";
import { asyncForEach } from "../../../common/utils";

export const processUnitsImages = async (fileStorageConfigs, units) => {
  let processedUnits = [];
  await asyncForEach(units, async (unit) => {
    processedUnits.push(await mappingBusinnessUnit(unit, fileStorageConfigs, false));
  });

  return processedUnits;
}


export const mappingBusinnessUnit = async (bu, fileStorageConfigs, formMapping) => {
  let nLines = [];

  await asyncForEach(bu.lines, async (line) => {
    let nInputs = [];
    let nOutputs = [];

    await asyncForEach(line.inputs, async (input) => {
      let product = {
        ...input.product,
        image: await processImageUrl(fileStorageConfigs, input.product?.image),
        thumbnail: await processImageUrl(fileStorageConfigs, input.product?.thumbnail),
      }
      nInputs.push({
        ...input,
        businessUnits: formMapping ? (input.businessUnits || []).map(bu => bu.id) : input.businessUnits,
        product: formMapping ? (input.product ? [product] : []) : product
      })
    });

    await asyncForEach(line.outputs, async (output) => {
      let product = {
        ...output.product,
        image: await processImageUrl(fileStorageConfigs, output.product?.image),
        thumbnail: await processImageUrl(fileStorageConfigs, output.product?.thumbnail),
      }
      nOutputs.push({
        ...output,
        product: formMapping ? (output.product ? [product] : []) : product
      })
    });

    nLines.push({
      ...line,
      inputs: nInputs,
      outputs: nOutputs,
    })
  });

  return {
    ...bu,
    lines: nLines,
  };
}
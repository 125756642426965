import { Grid, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconWithTooltip from "../../../../common/components/displays/IconWithTooltip";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import Currency from "../../../../common/displays/Currency/Currency";
import PurchaseFlowHistory from "./PurchaseFlowHistory/PurchaseFlowHistory";

export function listColumns(t, classes) {
  const columns = [
    {
      field: "description",
      headerName: t("productsPurchases.PendingPurchasesList.columns.description"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return <ProductSnip
          image={v.thumbnail}
          description={v.description}
          ownerName={v.ownerName}
          gtin={v.gtin}
          promoterInternalCode={v.promoterInternalCode}
        />;
      }
    },
    {
      field: "quantity",
      headerName: t("productsPurchases.PendingPurchasesList.columns.quantity"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "newPrice",
      headerName: t("productsPurchases.PendingPurchasesList.columns.newPrice"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return <Currency value={v.newPrice} />
      }
    },
    {
      field: "status",
      headerName: t("productsPurchases.PendingPurchasesList.columns.status"),
      align: "center",
      headerAlign: "center",
      customValue: (v) => (
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item>
            <Typography>{v.status}</Typography>
          </Grid>
          <Grid item>
            <IconWithTooltip icon={<InfoOutlinedIcon className={classes.icon} />}>
              <PurchaseFlowHistory history={v.history} processId={v.processId} />
            </IconWithTooltip>
          </Grid>
        </Grid>
      ),
    }
  ];

  return columns;
}

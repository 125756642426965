import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./LocationsDataGrid.styles";
import { listColumns } from "./LocationsDataGrid.columns";
import LocationsDataGridActions from "./LocationsDataGrid.actions";

function LocationsDataGrid({ classes, data, onDelete, onEdit }) {
  const { t } = useTranslation();
  const columns = listColumns(t, classes);

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          columns={columns}
          rows={data}
          total={data.length}
          actionsComponent={<LocationsDataGridActions onDelete={onDelete} onEdit={onEdit} />}
        />
      )}
    </div>
  );
}

export default withStyles(LocationsDataGrid, styles)

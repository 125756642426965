import React, { useMemo } from "react";
import { withStyles } from "tss-react/mui";
import { Stack, Tooltip } from "@mui/material";

import { styles } from "./HatcheryProgress.styles";

function HatcheryProgress({ classes, values, height }) {
  const barHeight = height || 50;
  const processedValues = useMemo(() => {
    const max = Math.max(...values) || 0;
    return (values || [0, 0, 0, 0, 0, 0]).map(value => ({
      value,
      percentage: value / max
    }))
  }, [values]);

  return (
    <Stack className={classes.root} direction="row" alignItems="baseline">
      <Tooltip title={processedValues?.[0]?.value + " UN"} placement="bottom">
        <div className={classes.bar} style={{ height: processedValues?.[0]?.percentage ? barHeight * processedValues?.[0]?.percentage : 1 }}></div>
      </Tooltip>
      <div className={classes.divider} style={{ marginTop: barHeight * 0.6 }}></div>
      <Tooltip title={processedValues?.[1]?.value + " UN"} placement="bottom">
        <div className={classes.bar} style={{ height: processedValues?.[1]?.percentage ? barHeight * processedValues?.[1]?.percentage : 1 }}></div>
      </Tooltip>
      <Tooltip title={processedValues?.[2]?.value + " UN"} placement="bottom">
        <div className={classes.bar} style={{ height: processedValues?.[2]?.percentage ? barHeight * processedValues?.[2]?.percentage : 1 }}></div>
      </Tooltip>
      <div className={classes.divider} style={{ marginTop: barHeight * 0.6 }}></div>
      <Tooltip title={processedValues?.[3]?.value + " UN"} placement="bottom">
        <div className={classes.bar} style={{ height: processedValues?.[3]?.percentage ? barHeight * processedValues?.[3]?.percentage : 1 }}></div>
      </Tooltip>
      <Tooltip title={processedValues?.[4]?.value + " UN"} placement="bottom">
        <div className={classes.bar} style={{ height: processedValues?.[4]?.percentage ? barHeight * processedValues?.[4]?.percentage : 1 }}></div>
      </Tooltip>
      <Tooltip title={processedValues?.[5]?.value + " UN"} placement="bottom">
        <div className={classes.bar} style={{ height: processedValues?.[5]?.percentage ? barHeight * processedValues?.[5]?.percentage : 1 }}></div>
      </Tooltip>
    </Stack>
  );
}

export default withStyles(HatcheryProgress, styles);
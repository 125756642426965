import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const orderListLoCall = async ({ clearOperatorOrders, setOperatorOrdersFilter, appendToOperatorOrders, setOperatorOrdersTotalRows }, dispatch, { filter, clear }) => {
  let rh = requestHelper(dispatch, "LIST_LO_ORDERS");
  if (clear) {
    dispatch(clearOperatorOrders());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setOperatorOrdersFilter(filter));
  try {
    let list = await call({
      type: "ORDER",
      subType: "LIST_LO",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    dispatch(appendToOperatorOrders(list.orderDetails));
    dispatch(setOperatorOrdersTotalRows(list.rows));
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
}

export default orderListLoCall;

import React from "react";
import { withStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "../../../common/hooks";
import { Button, Grid } from "@mui/material";

import { styles } from "./GroupList.styles";
import GroupDataGrid from "./GroupDataGrid/GroupDataGrid";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import SearchBox from "../../../common/components/inputs/SearchBox";

import {
  deleteGroup,
  listGroupsAppend,
  selectGroupList,
  selectGroupListFilter,
  selectGroupListTotalRows,
  setGroupDetails,
} from "../entitiesSlice";

import Permission from "../../../app/components/Permission";
import Page from "../../../common/displays/Page/Page";

function GroupList({ classes }) {
  const { t } = useTranslation();
  const data = useSelector(selectGroupList);
  const filter = useSelector(selectGroupListFilter);
  const totalRows = useSelector(selectGroupListTotalRows);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search,
      offset: 0,
    };
    dispatch(listGroupsAppend(nFilter, true));
  };

  const onFilterChangeHandler = (v) => {
    dispatch(listGroupsAppend(v.filter, v.reset));
  };

  const onEditHandler = (row) => {
    dispatch(
      setGroupDetails({
        name: row.groupName,
        code: row.groupCode,
        groupId: row.groupId,
        entityId: row.entityId,
        groupEntities: row.listEntities,
      })
    );
    navigate("/entities/groups/edit/" + row.groupId);
  };

  const onDeleteHandler = (row) => {
    dispatch(deleteGroup(row.groupId, row.entityId));
  };

  return (
    <Page
      permission="entities.actions.group.list"
      header={<PageTitle
        title={t("entities.GroupList.header.title")}
        info={`${totalRows} ${t("entities.GroupList.header.rowsInfo")}`}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <SearchBox onEnter={(v) => onSearchHandler(v)} />
            </Grid>

            <Grid item>
              <Permission code="entities.actions.group.create">
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => navigate("/entities/groups/add")}
                >
                  {t("entities.GroupList.header.addBtn")}
                </Button>
              </Permission>
            </Grid>
          </Grid>
        }
      />}>

      <GroupDataGrid
        data={data}
        filter={filter}
        totalRows={totalRows}
        onFilterChange={onFilterChangeHandler}
        onDelete={onDeleteHandler}
        onEdit={onEditHandler}
      />
    </Page>
  );
}

export default withStyles(GroupList, styles);

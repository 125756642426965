import { NumberField, TextField } from "../../../common/forms";
import { multiLanguagePropToString } from "../../../common/utils";
import ResourceTransferField from "../TasksList/TaskDetailsModal/ResourceTransferField/ResourceTransferField";


export const getTaskInputField = ({ i18n, input, disabled, id, resources, places, task, resourceId }) => {
  const label = typeof input.label === "string" ? input.label : multiLanguagePropToString(input.label, i18n.language);
  const note = typeof input.note === "string" ? input.note : multiLanguagePropToString(input.note, i18n.language);
  const { type, key, required } = input;

  if (type === "text") {
    return <TextField
      id={`${id}.${key}`}
      required={required}
      label={label}
      note={note}
      disabled={disabled}
    />
  }
  if (type === "number") {
    return <NumberField
      decimalScale={2}
      id={`${id}.${key}`}
      required={required}
      label={label}
      note={note}
      disabled={disabled}
    />
  }
  if (type === "transfer") {
    return <ResourceTransferField
      input={input}
      id={`${id}.${key}`}
      required={required}
      label={label}
      note={note}
      disabled={disabled}
      resources={resources}
      places={places}
      task={task}
      resourceId={resourceId}
    />
  }
  return "";
}




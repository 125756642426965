import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper } from "@mui/material";

import { styles } from "./Management.styles";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";
import { usePromoter } from "../../../../common/hooks";

function Management({ classes, product }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h3" align="left" component="h2" className={classes.noMargin}>
            {t("products.ProductDetails.details.managementTitle")}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {enabledProductFields.includes("hasBatchNumber") && <Grid item xs={2}>
              <LabelValue
                label={t("products.ProductDetails.details.hasBatchNumber")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {product?.management?.hasBatchNumber
                      ? t("products.ProductDetails.details.yes")
                      : t("products.ProductDetails.details.no")}
                  </Typography>
                }
              />
            </Grid>}
            {enabledProductFields.includes("hasExpirationDate") && <Grid item xs={2}>
              <LabelValue
                label={t("products.ProductDetails.details.hasExpirationDate")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {product?.management?.hasExpirationDate
                      ? t("products.ProductDetails.details.yes")
                      : t("products.ProductDetails.details.no")}
                  </Typography>
                }
              />
            </Grid>}
            {enabledProductFields.includes("hasVariableWeight") && <Grid item xs={2}>
              <LabelValue
                label={t("products.ProductDetails.details.hasVariableWeight")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {product?.management?.hasVariableWeight
                      ? t("products.ProductDetails.details.yes")
                      : t("products.ProductDetails.details.no")}
                  </Typography>
                }
              />
            </Grid>}
            {enabledProductFields.includes("hasSerialNumber") && <Grid item xs={2}>
              <LabelValue
                label={t("products.ProductDetails.details.hasSerialNumber")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {product?.management?.hasSerialNumber
                      ? t("products.ProductDetails.details.yes")
                      : t("products.ProductDetails.details.no")}
                  </Typography>
                }
              />
            </Grid>}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(Management, styles);
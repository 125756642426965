import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Paper } from "@mui/material";

import { styles } from "./EntityAddressForm.styles";
import { formFields, formSchema } from "./EntityAddressForm.schema";

import { Form, FormFields } from "../../../common/forms"
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { addAddress, entityGet, entityUpdateAddress, getOperators, selectEntityDetails, selectOperators } from "../entitiesSlice";
import { useFirstLoad, useNavigate, usePromoter } from "../../../common/hooks";
import { useParams } from "react-router-dom";
import Permission from "../../../app/components/Permission";
import { getContactOptions, getOperatorOptions } from "./EntityAddressForm.options";
import Page from "../../../common/displays/Page/Page";

function EntityAddressForm({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const operators = useSelector(selectOperators);
  const promoter = usePromoter();
  const navigate = useNavigate();
  const { id, addressId } = useParams();

  const entity = useSelector(selectEntityDetails);

  useFirstLoad(() => {
    dispatch(getOperators());
    dispatch(entityGet(id));
  });

  const options = useMemo(() => {
    return {
      operatorOptions: getOperatorOptions(operators),
      contactOptions: getContactOptions(entity)
    }
  }, [operators, entity])

  const onSubmit = async (values) => {
    if (addressId) {
      const { description, contactId } = values;
      dispatch(entityUpdateAddress({ id: addressId, entityId: id, description, contactId }));
    }
    else {
      dispatch(addAddress(promoter, id, values));
    }
  };

  let initialValues = {};
  if (addressId) {
    initialValues = (entity?.addresses || []).find(item => item.id === addressId) || {};
  }
  else if (operators && operators.length === 1 && promoter?.getConfig("order", "hasLogisticOperator")) {
    initialValues.operatorAddress = operators[0].id
  }

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        schema={formSchema({ t, entityType: entity?.type, promoter, edit: Boolean(addressId) })}
        render={({ submitting, values }) => {
          return (
            <Page
              permission={addressId ? "entities.actions.address.update" : "entities.actions.address.add"}
              header={
                <PageTitle
                  title={t("entities.CreateEntity.form.addAddressForm")}
                  onBack={() => navigate(`/entities/${id}/addresses`)}
                  actions={
                    <Grid container direction="row" spacing={1}>
                      <Grid item>
                        <Permission code={addressId ? "entities.actions.address.update" : "entities.actions.address.add"}>
                          <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                            {addressId ? t("entities.CreateEntity.form.updateAddressBtn") : t("entities.CreateEntity.form.addAddressBtn")}
                          </Button>
                        </Permission>
                      </Grid>
                    </Grid>
                  }
                />}>
              <Paper className={classes.paper} elevation={0}>
                <FormFields fields={formFields({ t, options, entityType: entity?.type, promoter, values, edit: Boolean(addressId) })} />
              </Paper>
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(EntityAddressForm, styles);
import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { TextField, InputAdornment } from "@mui/material";

import { styles } from "./SearchBox.styles";
import { ClearIcon, SearchIcon } from "../../icons";
import { useEffect } from "react";

function SearchBox({ classes, onEnter, fast, value, disabled }) {
  const [query, setQuery] = useState("");

  useEffect(() => {
    setQuery(value || "");
  }, [value])

  const onChangeHandler = (e) => {
    if (disabled) {
      return;
    }
    setQuery(e.target.value);
    if (fast) {
      onEnter && onEnter(e.target.value);
    }
  };

  const onKeyDownHandler = (e) => {
    if (disabled) {
      return;
    }
    if (e.keyCode === 13 && ((query && query.length > 2) || query === "")) {
      onEnter && onEnter(query);
    }
  };

  const onClearHandler = () => {
    if (disabled) {
      return;
    }
    setQuery("");
    onEnter && onEnter(null);
  };

  return (
    <div className={classes.root}>
      <TextField
        className={classes.input}
        margin="none"
        variant="outlined"
        size="small"
        value={query}
        disabled={disabled}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: query && (
            <InputAdornment position="end">
              <ClearIcon
                onClick={onClearHandler}
                className={classes.clear}
              />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default withStyles(SearchBox, styles)

import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const alertResolveCall = async ({ alertList }, dispatch, state, alertId, alertStatusId) => {
  const filter = state.alerts.alertsListFilter;
  let rh = requestHelper(dispatch, "ALERT_RESOLVE");
  try {
    await call({
      type: "ALERT",
      subType: "RESOLVE",
      request: { alertId, alertStatusId },
    });
    dispatch(setSuccess("alerts", "ALERT_RESOLVE"));
    dispatch(alertList(filter, true));
  } catch (ex) {
    rh.error("alerts", ex);
  }
  rh.close();
};

export default alertResolveCall;

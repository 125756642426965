import React, { useEffect, useMemo, useState } from "react";
import { Field } from "react-final-form";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Checkbox, IconButton } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";

import { styles } from "./ProductDiffField.styles";
import {
  diffFieldsParser,
  getDeepValueWithLongKey,
  productFields,
  setDeepValueWithLongKey,
} from "./ProductDiffFields.auxiliars";
import DocumentsDiff from "./DocumentsDiff/DocumentsDiff";
import SustainabilityDiff from "./SustainabilityDiff/SustainabilityDiff";
import HierarchyDiff from "./HierarchyDiff/HierarchyDiff";
import FormsDiff from "./FormsDiff/FormsDiff";
import SectionDiff from "./SectionDiff/SectionDiff";
import MediaDiff from "./MediaDiff/MediaDiff";

import { compareObjects } from "../../../../common/utils/utils";
import { usePromoter } from "../../../../common/hooks";

function ProductDiffField({ classes, id, details, viewMode }) {
  const { t, i18n } = useTranslation();
  const promoter = usePromoter();
  const sections = Object.keys(productFields);
  const [parsedData, setParsedData] = useState({});
  const [showAllFields, setShowAllFields] = useState(false);

  const mediaHasDiff = useMemo(() => !compareObjects((details?.product?.media || []), (details?.newProduct?.media || [])), [details]);
  const documentsHaveDiff = useMemo(() => !compareObjects((details?.product?.documents || []), (details?.newProduct?.documents || [])), [details]);
  const sustainabilityHaveDiff = useMemo(() => !compareObjects((details?.product?.sustainability || []), (details?.newProduct?.sustainability || [])), [details]);
  const hierarchyHaveDiff = useMemo(() => !compareObjects((details?.product?.hierarchy || []), (details?.newProduct?.hierarchy || [])), [details]);
  const formsHaveDiff = useMemo(() => !compareObjects((details?.product?.forms || []), (details?.newProduct?.forms || [])), [details]);

  const totalDiffCount = parsedData?.countDiffs + (mediaHasDiff ? 1 : 0) + (documentsHaveDiff ? 1 : 0) + (sustainabilityHaveDiff ? 1 : 0) + (hierarchyHaveDiff ? 1 : 0) + (formsHaveDiff ? 1 : 0)

  useEffect(() => {
    if (details) {
      setParsedData(diffFieldsParser(promoter, details, t, i18n.language));
    }
    // eslint-disable-next-line
  }, [details]);

  const onCheckFieldHandler = (checked, keys, input) => {
    let value = [...(input.value?.acceptedFields || [])];
    value = value.filter((el) => keys.indexOf(el) === -1);
    let acceptedFields = checked ? [...value, ...keys] : value;
    let product = acceptedFields.reduce((agg, k) => {
      return setDeepValueWithLongKey(agg, k, getDeepValueWithLongKey(details?.newProduct, k));
    }, {});
    input.onChange &&
      input.onChange({
        acceptedFields,
        product,
        total: totalDiffCount,
      });
  };

  const onCustomSectionCheckHandler = (section, checked, input) => {
    let value = [...(input.value?.acceptedFields || [])];
    value = value.filter((el) => el !== section);
    let acceptedFields = checked ? [...value, section] : value;
    let product = acceptedFields.reduce((agg, k) => {
      return setDeepValueWithLongKey(agg, k, getDeepValueWithLongKey(details?.newProduct, k));
    }, {});
    input.onChange &&
      input.onChange({
        acceptedFields,
        product,
        total: totalDiffCount,
      });
  }

  return (
    <div className={classes.root}>
      <Field name={id} allowNull>
        {({ input, meta }) => {
          return parsedData?.parsedFields ? (
            <Grid container direction="column" spacing={0}>
              <Grid item>
                <Grid container wrap="nowrap" alignItems="center" className={classes.diffsHeader}>
                  <Grid item flexGrow={1}>
                    <Grid container wrap="nowrap" spacing={1}>
                      <Grid item>
                        <IconButton onClick={() => setShowAllFields((prev) => !prev)} size="small">
                          {showAllFields ? (
                            <IndeterminateCheckBoxOutlinedIcon style={{ color: "#999" }} fontSize="inherit" />
                          ) : (
                            <AddBoxOutlinedIcon style={{ color: "#999" }} fontSize="inherit" />
                          )}
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" component="span">
                          {t("products.ProductValidationForm.columns.field")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="caption" component="span">
                      {t("products.ProductValidationForm.columns.value")}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="caption" component="span">
                      {t("products.ProductValidationForm.columns.newValue")}
                    </Typography>
                  </Grid>
                  {!viewMode &&
                    <Grid item style={{ width: 40 }}>
                      <Checkbox
                        color="primary"
                        checked={input.value?.acceptedFields?.length > 0 && input.value?.acceptedFields?.length === totalDiffCount}
                        onChange={(e) => {
                          let keys = Object.values(parsedData?.keysToCheck).flat(1);
                          if (mediaHasDiff) {
                            keys.push("media");
                          }
                          if (documentsHaveDiff) {
                            keys.push("documents");
                          }
                          if (sustainabilityHaveDiff) {
                            keys.push("sustainability");
                          }
                          if (hierarchyHaveDiff) {
                            keys.push("hierarchy");
                          }
                          if (formsHaveDiff) {
                            keys.push("forms");
                          }
                          onCheckFieldHandler(e.target.checked, keys, input)
                        }}
                      />
                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={1}>
                  {(sections || []).map(
                    (section, index) =>
                      (showAllFields || parsedData?.keysToCheck?.[section]?.length > 0) && (
                        <Grid item key={"section_" + index}>
                          <SectionDiff
                            section={section}
                            fields={parsedData?.parsedFields?.[section]}
                            inputValue={input.value?.acceptedFields || []}
                            showAllFields={showAllFields}
                            keysToCheck={parsedData?.keysToCheck?.[section]}
                            onCheckField={(checked, field) => onCheckFieldHandler(checked, [field], input)}
                            onCheckSection={(checked, keys) => onCheckFieldHandler(checked, keys, input)}
                            viewMode={viewMode}
                          />
                        </Grid>
                      )
                  )}
                  {/* MEDIA */}
                  {
                    (showAllFields || mediaHasDiff) &&
                    <Grid item key="section_media">
                      <MediaDiff
                        value={details?.product?.media}
                        newValue={details?.newProduct?.media}
                        hasDiff={mediaHasDiff}
                        checked={(input.value?.acceptedFields || []).indexOf("media") > -1}
                        onCheck={(checked) => onCustomSectionCheckHandler("media", checked, input)}
                        viewMode={viewMode}
                      />
                    </Grid>
                  }
                  {
                    (showAllFields || documentsHaveDiff) &&
                    <Grid item key="section_documents">
                      <DocumentsDiff
                        value={details?.product?.documents}
                        newValue={details?.newProduct?.documents}
                        hasDiff={documentsHaveDiff}
                        checked={(input.value?.acceptedFields || []).indexOf("documents") > -1}
                        onCheck={(checked) => onCustomSectionCheckHandler("documents", checked, input)}
                        viewMode={viewMode}
                      />
                    </Grid>
                  }
                  {
                    (showAllFields || sustainabilityHaveDiff) &&
                    <Grid item key="section_sustainability">
                      <SustainabilityDiff
                        value={details?.product?.sustainability}
                        newValue={details?.newProduct?.sustainability}
                        hasDiff={sustainabilityHaveDiff}
                        checked={(input.value?.acceptedFields || []).indexOf("sustainability") > -1}
                        onCheck={(checked) => onCustomSectionCheckHandler("sustainability", checked, input)}
                        viewMode={viewMode}
                      />
                    </Grid>
                  }
                  {
                    (showAllFields || hierarchyHaveDiff) &&
                    <Grid item key="section_hierarchy">
                      <HierarchyDiff
                        value={details?.product?.hierarchy}
                        newValue={details?.newProduct?.hierarchy}
                        hasDiff={hierarchyHaveDiff}
                        checked={(input.value?.acceptedFields || []).indexOf("hierarchy") > -1}
                        onCheck={(checked) => onCustomSectionCheckHandler("hierarchy", checked, input)}
                        productThumbnail={details?.product?.media?.[0]?.thumbnail}
                        viewMode={viewMode}
                      />
                    </Grid>
                  }
                  {
                    (showAllFields || formsHaveDiff) &&
                    <Grid item key="section_forms">
                      <FormsDiff
                        value={details?.product?.forms}
                        newValue={details?.newProduct?.forms}
                        hasDiff={formsHaveDiff}
                        checked={(input.value?.acceptedFields || []).indexOf("forms") > -1}
                        onCheck={(checked) => onCustomSectionCheckHandler("forms", checked, input)}
                        viewMode={viewMode}
                      />
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Grid>
          ) : null;
        }}
      </Field>
    </div>
  );
}

export default withStyles(ProductDiffField, styles);

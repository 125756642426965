export const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  carouselItem: {
    height: "100%",
    width: "100%",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  loaderBox: {
    height: "100%",
  }
});



import moment from "moment";
import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const auditListAuditJobCall = async ({ clearAuditJobList, setAuditJobList, setAuditJobListTotalRows, setAuditJobFilter }, dispatch, { filter, clear }) => {
  let rh = requestHelper(dispatch, "AUDIT_LIST_AUDIT_JOB");
  if (clear) {
    dispatch(clearAuditJobList());
  }

  const startTime = (filter.isDefaultFilter || !filter.startTime) ? moment().add(-1, "day").toISOString() : filter.startTime;
  const endTime = (filter.isDefaultFilter || !filter.endTime) ? moment().toISOString() : filter.endTime;

  dispatch(setAuditJobFilter({
    ...filter,
    startTime,
    endTime
  }));

  try {
    let result = await call({
      type: "AUDIT",
      subType: "LIST_AUDIT_JOB",
      request: {
        ...filter,
        startTime,
        endTime
      }
    });

    if (result && result.items && result.items.length > 0) {
      dispatch(setAuditJobList(result.items));
      dispatch(setAuditJobListTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default auditListAuditJobCall;

import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

import { styles } from "./ProductDocumentsField.styles";
import MetaError from "../../../../common/forms/MetaError";
import { FileUploadInput, SelectInput } from "../../../../common/inputs";
import { FieldArray } from "react-final-form-arrays";
import { FormFields } from "../../../../common/forms";
import { formFieldsDocMetadata } from "./ProductDocumentsField.schema";
import { usePromoter } from "../../../../common/hooks";

function ProductDocumentsField({
  classes,
  id,
  label,
  disabled,
  required,
  multiple,
  placeholder,
  image,
  documentTypes,
  metadata,
}) {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState();
  const [showDocTypeError, setShowDocTypeError] = useState(false);
  const [fileError, setFileError] = useState();
  const promoter = usePromoter();

  const onChangeHandler = ({ base64, name, type }, fields) => {
    setFileError();
    fields.push({
      base64,
      name,
      type: selectedType,
      mediaType: type,
    });
  };

  return (
    <div className={classes.root}>
      <FieldArray name={id}>
        {({ fields, meta }) => {
          return (
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography
                  component="span"
                  variant="caption"
                  htmlFor={id}
                  color="textPrimary"
                  style={{ display: "inline" }}
                  className={disabled ? classes.disabledLabel : ""}
                >
                  {label}
                  {required && <span> *</span>}
                </Typography>
              </Grid>
              {(fields || []).map((field, index) => {
                let item = fields.value[index];
                return (
                  <Grid item key={`${id}_${index}`}>
                    <div className={classes.documentsWrapper}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={2}>
                          <div className={classes.file}>
                            {item.url ? (
                              <IconButton disableRipple size="large" href={item.url} rel="noreferrer" target="_blank">
                                <Grid container direction="column">
                                  <Grid item>
                                    <FileOpenOutlinedIcon fontSize="large" color="action" />
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="caption">
                                      {t(`common.Enums.documentType.${item.type}`)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </IconButton>
                            ) : (
                              <Tooltip title={item?.name}>
                                <Grid container direction="column">
                                  <Grid item>
                                    <InsertDriveFileOutlinedIcon fontSize="large" color="action" />
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="caption">
                                      {t(`common.Enums.documentType.${item.type}`)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Tooltip>
                            )}

                            <Tooltip title={t("products.ProductCreate.imagesField.delete")}>
                              <IconButton className={classes.delete} size="small" onClick={() => fields.remove(index)}>
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </Grid>
                        <Grid item xs={10}>
                          <FormFields fields={formFieldsDocMetadata(field, t, metadata?.[item.type])} />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                );
              })}
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={6}>
                    <SelectInput
                      options={Object.keys(documentTypes || []).map((item) => ({
                        value: item,
                        text: t(`common.Enums.documentType.${item}`) + (documentTypes[item] ? " *" : ""),
                      }))}
                      value={selectedType || ""}
                      onChange={(value) => setSelectedType(value)}
                    />
                    <MetaError
                      meta={
                        !selectedType
                          ? {
                            error: t("products.ProductCreate.documentsField.documentTypeRequired"),
                            touched: showDocTypeError,
                          }
                          : {}
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    onClick={(e) => {
                      if (!selectedType) {
                        e.preventDefault();
                        setShowDocTypeError(true);
                      }
                    }}
                  >
                    <FileUploadInput
                      id="uploadDocument"
                      placeholder={placeholder}
                      required={required}
                      disabled={disabled}
                      multiple={multiple}
                      onChange={(v) => onChangeHandler(v, fields)}
                      onError={(ex) => {
                        setFileError(ex);
                      }}
                      variant="outlined"
                      image={image}
                      maxFileSize={promoter?.getConfig("document")?.maxFileSize}
                      accept={promoter?.getConfig("document")?.acceptedMediaTypes}
                      clearOnChange
                    />
                    <MetaError
                      meta={
                        fileError === "SIZE_TOO_BIG"
                          ? {
                            error: t("products.ProductCreate.documentsField.fileSize"),
                            touched: showDocTypeError,
                          }
                          : {}
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <div className={classes.error}>
                  <MetaError meta={meta} />
                </div>
              </Grid>
            </Grid>
          );
        }}
      </FieldArray>
    </div>
  );
}

export default withStyles(ProductDocumentsField, styles);

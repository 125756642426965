import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Grid, IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import moment from "moment";

import { styles } from "./TaskDetailsModal.styles";
import { formFields, formFieldsComments, formSchema } from "./TaskDetailsModal.schema";
import TaskInfoDisplay from "./TaskInfoDisplay/TaskInfoDisplay";
import CommentsGrid from "./CommentsGrid/CommentsGrid";

import CustomModal from "../../../../common/displays/CustomModal/CustomModal";
import { Form, FormFields } from "../../../../common/forms";
import { entityGet, selectEntityDetails } from "../../../entities/entitiesSlice";
import { useFirstLoad, useUser } from "../../../../common/hooks";
import ScrollBox from "../../../../common/displays/ScrollBox/ScrollBox";
import { CheckboxInput, DateTimePickerInput } from "../../../../common/inputs";
import { selectLoader } from "../../../../app/coreSlice";
import { placeListFull, resourceListFull, selectPlaces, selectResources, taskAction, taskAssign } from "../../tasksSlice";
import Assignee from "../../../../common/displays/Assignee/Assignee";
import { multiLanguagePropToString } from "../../../../common/utils";

function TaskDetailsModal({ classes, open, setOpen, task, view }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const entity = useSelector(selectEntityDetails);
  const user = useUser();
  const [selectedTab, setSelectedTab] = useState("inputs");
  const [completeCheck, setCompleteCheck] = useState(false);
  const loading = useSelector(selectLoader);
  const [newDueDate, setNewDueDate] = useState();
  const resources = useSelector(selectResources);
  const places = useSelector(selectPlaces);

  const height = 640;

  useFirstLoad(() => {
    if (!entity) {
      dispatch(entityGet(user?.entity?.id));
    }
  });

  useEffect(() => {
    setNewDueDate();
    setCompleteCheck(false);
    if (task?.input?.length > 0) {
      setSelectedTab("inputs")
    }
    else {
      setSelectedTab("comments")
    }
    // if the task have resource tasks, should get all resources of the type and respective parents (if exist)
    if ((task?.input || []).some(item => item.type === "resource")) {
      dispatch(resourceListFull());
    }
    if ((task?.input || []).some(item => item.type === "transfer")) {
      dispatch(placeListFull());
    }
    // eslint-disable-next-line
  }, [task]);

  const onSubmitHandler = async (values) => {
    const { payload } = values;
    console.log('values: %o', values);
    dispatch(taskAction({ taskId: task?.taskId, status: "UPDATED", payload }));
  };

  const onCompleteHandler = (e, values) => {
    e.preventDefault();
    const { payload } = values;
    dispatch(taskAction({ taskId: task?.taskId, status: "COMPLETED", payload }));
    setOpen(false);
  }

  const onSubmitCommentHandler = async (e, values) => {
    e.preventDefault();
    dispatch(taskAction({ taskId: task?.taskId, status: "UPDATED", comment: values.comment }));
  }

  const onReassignHandler = (values) => {
    const { users, groups, sectors } = values;
    dispatch(taskAssign({ taskId: task?.taskId, entityId: task?.entityId, groupId: groups, userId: users, sectorCode: sectors }));
  }

  const onClickSetNewDueDateHandler = () => {
    if (dueDateHasChanges(task?.dueDate, newDueDate)) {
      dispatch(taskAction({ taskId: task?.taskId, status: "UPDATED", dueDate: newDueDate }));
    }
  }

  // console.log('task: %o', task);

  return (
    <CustomModal open={open} setOpen={setOpen} size="extralarge" height={height} title={multiLanguagePropToString(task?.name, i18n.language)}>
      <div className={classes.root}>
        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t, inputs: task?.input })}
          initialValues={{ ...task, payload: { ...processTaskInitialPayload(task?.batch?.processedData, task?.input, resources, places), ...task?.payload } }}
          render={({ values }) => {
            return (
              <Grid container direction="row" spacing={2}>

                {/* LEFT COLUMN */}
                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>

                    {/* ASSIGNEE + DUE DATE */}
                    <Grid container direction="row" spacing={1}>
                      <Grid item xs={4}>
                        <Stack>
                          <Typography variant="caption">{t("tasks.TaskDetailsModal.header.assignee")}</Typography>
                          <Assignee sectors={task?.sectors} groups={task?.groups} users={task?.users} onReassign={!view && onReassignHandler} />
                        </Stack>
                      </Grid>
                      <Grid item xs={8}>
                        <Stack direction="row" alignItems="flex-end">
                          <DateTimePickerInput label={t("tasks.TaskDetailsModal.header.dueDate")} value={task?.dueDate ? moment.utc(task?.dueDate).local() : null} onChange={(v) => setNewDueDate(moment.isMoment(v) && v.isValid() ? v.toISOString() : undefined)} />
                          <IconButton onClick={onClickSetNewDueDateHandler}>
                            <CheckIcon color={dueDateHasChanges(task?.dueDate, newDueDate) ? "primary" : "disabled"} />
                          </IconButton>
                        </Stack>
                      </Grid>
                    </Grid>

                    {/* COMPLETE TASK BUTTON */}
                    <Stack direction="row" alignItems="center" >
                      <CheckboxInput value={completeCheck} onChange={() => setCompleteCheck((prev) => !prev)} className={classes.check} disabled={loading || view} />
                      <Button variant="contained" color="primary" fullWidth disabled={loading || !completeCheck} onClick={(e) => onCompleteHandler(e, values)}>
                        {t("tasks.TaskDetailsModal.form.btnComplete")}
                      </Button>
                    </Stack>

                    {/* TASK INFO */}
                    <ScrollBox style={{ height: height - 218 }}>
                      <Stack spacing={1}>
                        {(task?.info || []).map(item => (<TaskInfoDisplay key={item.id} info={item} />))}
                      </Stack>
                    </ScrollBox>
                  </Stack>
                </Grid>

                {/* RIGHT COLUMN */}
                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    {/* MENU */}
                    {task?.input && task?.input?.length > 0 &&
                      <ToggleButtonGroup color="primary" size="small" exclusive fullWidth value={selectedTab} onChange={(e, v) => setSelectedTab(v)} >
                        <ToggleButton color="primary" value="inputs">
                          {t("tasks.TaskDetailsModal.header.inputs")}
                        </ToggleButton>
                        <ToggleButton color="primary" value="comments">
                          {t("tasks.TaskDetailsModal.header.comments")}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    }

                    {/* INPUTS */}
                    {selectedTab === "inputs" &&
                      <div>
                        <ScrollBox style={{ height: height - 160 }}>
                          <FormFields fields={formFields({ i18n, inputs: task?.input, disabled: loading || view, view, resources, places, task })} />
                        </ScrollBox>
                        <Button variant="contained" color="primary" fullWidth type="submit" disabled={loading || view || completeCheck}>
                          {t("tasks.TaskDetailsModal.form.btnSave")}
                        </Button>
                      </div>
                    }

                    {/* COMMENTS */}
                    {selectedTab === "comments" &&
                      <div>
                        <ScrollBox style={{ height: task?.input && task?.input?.length > 0 ? (height - 266) : (height - 220) }}>
                          <CommentsGrid task={task} />
                        </ScrollBox>
                        <FormFields fields={formFieldsComments({ t, disabled: loading || view })} />
                        <Button variant="contained" color="primary" fullWidth disabled={loading || view} onClick={(e) => onSubmitCommentHandler(e, values)}>
                          {t("tasks.TaskDetailsModal.form.btnSubmit")}
                        </Button>
                      </div>
                    }
                  </Stack>
                </Grid>
              </Grid>
            );
          }}
        />
      </div>
    </CustomModal >
  );
}

export default withStyles(TaskDetailsModal, styles);

const dueDateHasChanges = (taskDate, nDate) => {
  const taskDueDate = moment.utc(taskDate).local().toISOString();
  return nDate && moment(taskDueDate).diff(moment(nDate)) !== 0
}

const processTaskInitialPayload = (processedData, input, resources) => {
  let payload = { resources: {} };
  // for all inputs of type resource
  (input || []).filter(item => item.type === "resource").forEach(inp => {
    // for all resources in batch data
    Object.keys(processedData?.resources || {}).forEach(resourceId => {
      // only for resources that are the same type of the processing input
      if ((resources || []).filter(item => item.type === inp.resourceType).some((item => item.id === resourceId))) {
        // console.log('.... : %o', processedData?.resources[resourceId])
        // copy data from the batch data to the payload (this could be improved by only copy data that make sense - that have inputs with that key as children of the resource input)
        if (parseFloat(processedData?.resources[resourceId]?.quantity) > 0) {
          payload.resources[resourceId] = processedData?.resources[resourceId];
        }
      }
    })
  })
  return payload;
}




import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const productListTaxHistoryCall = async (
  { clearProductTaxHistoryList, setProductTaxHistoryListFilter, appendToProductTaxHistoryList, setProductTaxHistoryListTotalRows },
  dispatch,
  { filter, clear }
) => {
  let rh = requestHelper(dispatch, "LIST_TAX_HISTORY_PRODUCT");
  if (clear) {
    dispatch(clearProductTaxHistoryList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setProductTaxHistoryListFilter(filter));
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "LIST_TAX_HISTORY",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    dispatch(appendToProductTaxHistoryList(result.items || []));
    dispatch(setProductTaxHistoryListTotalRows(result.rows));

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productListTaxHistoryCall;
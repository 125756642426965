export const getYearOptions = () => {
  const startYear = new Date().getFullYear();
  let years = [];
  for (let i = startYear; i < startYear + 5; i++) {
    years.push({
      value: i,
      text: i,
    });
  }
  return years;
}
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";

import { styles } from "./ContactPointChangeModal.styles";
import { formFields, formSchema } from "./ContactPointChangeModal.schema";
import CustomModal from "../../../../../common/displays/CustomModal/CustomModal";

import { Form, FormFields } from "../../../../../common/forms";
import { productChangeContactPoint, productGroupContacts, selectGroupContacts } from "../../../productsSlice";
import { useFirstLoad } from "../../../../../common/hooks";

function ContactPointChangeModal({ classes, open, setOpen, taskId, productId, contactPoint }) {
  const { t } = useTranslation();
  const groupContacts = useSelector(selectGroupContacts);
  const dispatch = useDispatch();

  useFirstLoad(() => {
    dispatch(productGroupContacts());

  });

  const onSubmitHandler = async (values) => {
    const { contactId } = values;
    const user = (groupContacts || []).find(item => item.contactId === contactId)?.username
    console.log('values: %o', { taskId, user, contactId, productId });
    dispatch(productChangeContactPoint({ taskId, user, contactId, productId }));
    setOpen && setOpen(false);
  };

  return (
    <CustomModal open={open} setOpen={setOpen} size="medium" height={225} title={t("products.ContactPointChangeModal.modal.title")}>
      <div className={classes.root}>
        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t })}
          initialValues={{ contactId: contactPoint }}
          render={({ values }) => {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormFields fields={formFields({ t, groupContacts })} />
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" type="submit" fullWidth>
                    {t("products.ContactPointChangeModal.form.btnSubmit")}
                  </Button>
                </Grid>
              </Grid>
            );
          }}
        />
      </div>
    </CustomModal>
  );
}

export default withStyles(ContactPointChangeModal, styles);

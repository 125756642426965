import * as Yup from "yup";
import { NumberField, SelectField, TextField } from "../../../../common/forms";

export function formSchema(t, language, promoter) {
  let requiredLanguage = {};
  requiredLanguage[language] = Yup.string().required(t("productsFamilies.FamilyForm.form.requiredDescription"));
  return Yup.object().shape({
    description: Yup.object().shape(requiredLanguage),
    type: Yup.string().required(t("productsFamilies.FamilyForm.form.requiredType")),
    id: promoter?.getConfig("product", "allowFamilyCode") ? Yup.number().required(t("productsFamilies.FamilyForm.form.requiredId")) : Yup.number().nullable()
  });
}

export function formFields({ t, languages, promoter, create }) {
  let fields = [
    {
      size: 12,
      field: (
        <NumberField
          decimalScale={0}
          id="id"
          label={t("productsFamilies.FamilyForm.form.id")}
          required
          disabled={!create}
          allowLeadingZeros
        />
      ),
      hidden: !promoter?.getConfig("product", "allowFamilyCode")
    },
    {
      size: 12,
      field: (
        <SelectField
          id="type"
          options={getTemperatureOptions(t, promoter)}
          label={t("productsFamilies.FamilyForm.form.type")}
        />
      ),
    },
  ];
  let descriptionFields = languages.map((lang) => {
    return {
      size: 12,
      field: (
        <TextField
          id={`description.${lang}`}
          type="text"
          label={t("productsFamilies.FamilyForm.form.description", { language: lang })}
        />
      ),
    };
  });

  // return fields;
  return [...fields, ...descriptionFields];
}

const getTemperatureOptions = (t, promoter) => {
  return (promoter?.refData?.temperature || []).map((item) => {
    return {
      value: item,
      text: t("common.Enums.temperature." + item),
    };
  });
};

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Paper, Grid, Typography } from "@mui/material";
import { styles } from "./ProductMediaDetails.styles";

function ProductMediaDetails({ classes, media }) {
  const { t } = useTranslation();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={2} justifyContent="space-between" direction="column">
        <Grid item>
          <Typography variant="h3" align="left" component="h4" className={classes.noMargin}>
            {t("products.ProductMediaDetails.header.title")}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {(media || []).map((item, index) => (
              <Grid item xs={3} key={`media_${index}`}>
                <div className={classes.image}>
                  <div
                    className={classes.img}
                    style={{ backgroundImage: `url('${item.thumbnail || item.base64}')` }}
                  ></div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(ProductMediaDetails, styles);

export const styles = (theme) => ({
  root: {
    "& .CdgRow": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#eee",
      },
    },
  },
  id: {
    margin: 0,
  },
  orderBy: {
    margin: 0,
    color: "#aaa",
  },
  nomargin: {
    margin: 0,
  },
  campaign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 24,
    width: 24,
    borderRadius: 12,
    backgroundColor: "#bfc5d2",
    padding: 4,
    "& svg": {
      height: 14,
    },
  },
  campaignMet: {
    backgroundColor: "#2e5bff",
    "& svg": {
      fill: "#FFF !important",
    },
  }
});

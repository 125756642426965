import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const productDpromListCall = async (
  { clearDpromsList, setDpromsListFilter, appendToDpromsList, setDpromsListTotalRows },
  dispatch,
  { filter, clear }
) => {
  let rh = requestHelper(dispatch, "PRODUCT_DPROM_LIST");
  if (clear) {
    dispatch(clearDpromsList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setDpromsListFilter(filter));
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "DPROM_LIST",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });

    dispatch(appendToDpromsList(result.items));
    dispatch(setDpromsListTotalRows(result.rows));
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productDpromListCall;

import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const userUpdateCall = async (
  dispatch,
  { firstName, lastName }
) => {
  let rh = requestHelper(dispatch, "USER_UPDATE");
  try {
    let result = await call({
      type: "USER",
      subType: "UPDATE",
      request: {
        firstName,
        lastName
      },
    });
    if (result) {
      dispatch(setSuccess("users", "USER_UPDATE"));
    }
  } catch (ex) {
    rh.error("users", ex);
  }
  rh.close();
};

export default userUpdateCall;

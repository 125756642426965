import { Stack } from "@mui/material"
import BlockCard from "../BlockCard/BlockCard"
import SlimCheckBoxField from "../SlimCheckBoxField/SlimCheckBoxField"

export const tasksFormFields = (t) => {
  return [
    {
      size: 3,
      field: <BlockCard title="tasks">
        <Stack>
          <SlimCheckBoxField id="permissions.tasks.actions.tasks.list" label="list" />
          <SlimCheckBoxField id="permissions.tasks.actions.tasks.create" label="create" />
          <SlimCheckBoxField id="permissions.tasks.actions.tasks.action" label="action" />
          <SlimCheckBoxField id="permissions.tasks.actions.tasks.assign" label="assign" />
        </Stack>
      </BlockCard>,
    },
  ]
}
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { ToggleButtonGroup, ToggleButton, Stack, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import MoveDownOutlinedIcon from '@mui/icons-material/MoveDownOutlined';

import { styles } from "./BusinessUnitsHeaderMenu.styles";

import { useFirstLoad, useNavigate } from "../../../../common/hooks";
import { businessUnitGet, selectBusinessUnitDetails } from "../../businessUnitsSlice";

function BusinessUnitsHeaderMenu({ classes, businessUnitId, selected }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const unit = useSelector(selectBusinessUnitDetails);

  // console.log('unit: %o', unit);
  const lineId = unit?.lines?.[0]?.id;

  useFirstLoad(() => {
    if (unit?.id !== businessUnitId) {
      dispatch(businessUnitGet({ id: businessUnitId }));
    }
  })

  return (
    <div className={classes.root}>
      <ToggleButtonGroup color="primary" size="small" value={selected} >
        <ToggleButton color="primary" value="edit" onClick={() => navigate(`/business/units/${businessUnitId}/edit/`)} >
          <Stack direction="row" spacing={1} alignItems="center">
            <EditIcon sx={{ display: "block" }} fontSize="small" />
            <Typography sx={{ margin: 0 }}>
              {t("businessUnits.BusinessUnitCard.actions.edit")}
            </Typography>
          </Stack>
        </ToggleButton>
        <ToggleButton color="primary" value="lines" onClick={() => navigate(`/business/units/${businessUnitId}/lines/`)} >
          <Stack direction="row" spacing={1} alignItems="center">
            <MoveDownOutlinedIcon sx={{ display: "block" }} fontSize="small" />
            <Typography sx={{ margin: 0 }}>
              {t("businessUnits.BusinessUnitCard.actions.editLines")}
            </Typography>
          </Stack>
        </ToggleButton>
        <ToggleButton color="primary" value="placeTasks" onClick={() => navigate(`/business/units/${businessUnitId}/placetasks/`)} >
          <Stack direction="row" spacing={1} alignItems="center">
            <ExploreOutlinedIcon sx={{ display: "block" }} fontSize="small" />
            <Typography sx={{ margin: 0 }}>
              {t("businessUnits.BusinessUnitCard.actions.placeTasks")}
            </Typography>
          </Stack>
        </ToggleButton>
        <ToggleButton color="primary" value="steps" onClick={() => navigate(`/business/units/${businessUnitId}/${lineId}/steps/`)} >
          <Stack direction="row" spacing={1} alignItems="center">
            <BuildCircleOutlinedIcon sx={{ display: "block" }} fontSize="small" />
            <Typography sx={{ margin: 0 }}>
              {t("businessUnits.BusinessUnitCard.actions.steps")}
            </Typography>
          </Stack>
        </ToggleButton>
        {unit?.config?.allowInternalBatches &&
          <ToggleButton color="primary" value="internal" onClick={() => navigate(`/business/units/${businessUnitId}/${lineId}/steps/internal/`)} >
            <Stack direction="row" spacing={1} alignItems="center">
              <BuildCircleOutlinedIcon sx={{ display: "block" }} fontSize="small" />
              <Typography sx={{ margin: 0 }}>
                {t("businessUnits.BusinessUnitCard.actions.internalSteps")}
              </Typography>
            </Stack>
          </ToggleButton>
        }
      </ToggleButtonGroup>
    </div>
  );
}

export default withStyles(BusinessUnitsHeaderMenu, styles);
import DateTime from "../../../../common/displays/DateTime/DateTime";

export function listColumns(t, classes, currentVersion) {
  return [
    {
      field: "version",
      headerName: t("emails.EmailTemplate.versions.columns_version"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.version === currentVersion ? v.version + " - " + t("emails.EmailTemplate.versions.selectedVersion") : v.version;
      },

    },
    {
      field: "date",
      headerName: t("emails.EmailTemplate.versions.columns_date"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return <DateTime date={v.date} />;
      },
    }

  ];
}

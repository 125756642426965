import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";


const orderConfirmationCall = async (dispatch, { orderId }) => {

  let rh = requestHelper(dispatch, "CONFIRM_ORDER");
  try {
    await call({
      type: "ORDER",
      subType: "CONFIRMATION",
      request: {
        orders: [orderId],
      },
    });
    setTimeout(() => {
      dispatch(setRedirect(`/orders/placed`));
      dispatch(setSuccess("orders", "ORDER_CONFIRMATION"));
    }, 300);
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
};

export default orderConfirmationCall;
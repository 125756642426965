import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";

import { styles } from "./DeleteButton.styles";

function DeleteButton({ classes, labels, onConfirm, size, fullWidth, disabled }) {
  const { button, title, description, ok, cancel } = labels;
  const [open, setOpen] = useState(false);
  return (
    <div className={classes.root}>
      <Button
        className={classes.deleteButton}
        variant="contained"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        fullWidth={fullWidth}
        size={size}
        disabled={disabled}
      >
        {button}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.stopPropagation();

              setOpen(false);
            }}
            color="primary"
          >
            {cancel}
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
              onConfirm && onConfirm();
            }}
            color="primary"
            autoFocus
          >
            {ok}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(DeleteButton, styles)

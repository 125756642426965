import React from "react";
import { useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";

import { styles } from "./ExtraPricesVariantsDataGrid.styles";
import { listColumns } from "./ExtraPricesVariantsDataGrid.columns";

import { CustomDataGrid } from "../../../../../common/displays/CustomDataGrid";
import { selectEntityGroups, selectLocations } from "../../../productsPricesSlice";
import { usePromoter, useUser } from "../../../../../common/hooks";
import ExtraPricesVariantsDataGridActions from "./ExtraPricesVariantsDataGrid.actions";

function ExtraPricesVariantsDataGrid({
  classes,
  data,
  product,
  onExtraPriceChange,
  onRemove,
}) {
  const { t } = useTranslation();
  const user = useUser();
  const promoter = usePromoter();
  const entityGroups = useSelector(selectEntityGroups);
  const locations = useSelector(selectLocations);
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('xl'));
  const columns = listColumns({ t, smallScreen, product, user, promoter, entityGroups, locations, onExtraPriceChange });

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={columns}
        actions={<ExtraPricesVariantsDataGridActions />}
        actionsComponent={<ExtraPricesVariantsDataGridActions onRemove={onRemove} product={product} />}
        rows={(data || []).map((item) => ({
          ...item,
          className: getRowClass(classes, item)
        }))}
        hasHeader={false}
        total={data?.length}
        rowKeyField="tempId"
      />
    </div>
  );
}

export default withStyles(ExtraPricesVariantsDataGrid, styles);

const getRowClass = (classes, price) => {
  if (price.hasChanges && parseFloat(price?.newExtraPrice?.value || "0") > 0) {
    return classes.hasChanges;
  }
  return "";
}
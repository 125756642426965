import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, List, ListItem, Checkbox, Grid } from "@mui/material";

import { styles } from "./_base.styles";

function CheckListInput({ classes, id, label, options, value, onChange, disabled, required }) {
  const onClickHandler = (val) => {
    if (disabled) {
      return;
    }
    let nValues;
    if ((value || []).find((v) => v === val)) {
      nValues = (value || []).filter((v) => v !== val);
    } else {
      nValues = [...value, val];
    }
    onChange && onChange(nValues);
  };
  return (
    <div className={classes.root}>
      {label &&
        (typeof label === "string" ? (
          <Typography
            component="span"
            variant="caption"
            htmlFor={id}
            color="textPrimary"
            className={disabled ? classes.disabledLabel : ""}
          >
            {label}
            {required && <span> *</span>}
          </Typography>
        ) : (
          label
        ))}

      <List>
        {(options || []).map((item) => (
          <ListItem
            key={`list_item_${item.value}`}
            onClick={() => onClickHandler(item.value)}
            disableGutters
            style={{ cursor: disabled ? "default" : "pointer" }}
          >
            <Grid container direction="row" wrap="nowrap" alignItems="center">
              <Grid item>
                <Checkbox
                  className={classes.checkbox}
                  disabled={Boolean(disabled)}
                  checked={Boolean((value || []).find((v) => v === item.value))}
                  disableRipple
                  color="primary"
                />
              </Grid>
              <Grid item>
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                  className={disabled ? classes.disabledLabel : ""}
                >
                  {item.text || item.component}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default withStyles(CheckListInput, styles);

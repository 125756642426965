import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { detailProductsToCurrentOrderProducts } from "../ordersMapper";

const orderCreateCall = async ({ setCurrentOrder }, dispatch, order) => {
  let rh = requestHelper(dispatch, "CREATE_ORDER");
  try {
    let result = await call({
      type: "ORDER",
      subType: "CREATE",
      request: {
        ...order,
        products: detailProductsToCurrentOrderProducts(
          order.products.filter((item) => item.quantity && item.quantity > 0 && !item.isOffer)
        ).map((item) => {
          if (item.priceType !== "C") {
            return { ...item, price: undefined };
          }
          return item;
        }),
        orderBy: order.orderBy || order.deliverToId,
      },
    });
    dispatch(setCurrentOrder(null));
    setTimeout(() => {
      dispatch(setRedirect(`/orders/${result.orderId}`));
      dispatch(setSuccess("orders", "ORDER_CREATE"));
    }, 300);
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
};

export default orderCreateCall;

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const entityPendingEntityListCall = async (
  { clearPendingEntityList, setPendingEntityListFilter, appendToPendingEntityList, setPendingEntityListTotalRows },
  dispatch,
  { filter, clear }
) => {
  let rh = requestHelper(dispatch, "ENTITY_PENDING_ENTITY_LIST");
  if (clear) {
    dispatch(clearPendingEntityList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setPendingEntityListFilter(filter));
  try {
    let result = await call({
      type: "ENTITY",
      subType: "PENDING_ENTITY_LIST",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    dispatch(appendToPendingEntityList(result.items));
    dispatch(setPendingEntityListTotalRows(result.rows));
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityPendingEntityListCall;

export const styles = (theme) => ({
  root: {
  },
  box: {
    padding: theme.spacing(2),
    border: "1px solid #DDD",
    borderRadius: 4,
    display: "inline-block",
    cursor: "pointer"
  },
  fileuploadInput: {
    display: "none",
  },
});
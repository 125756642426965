import AWS from "aws-sdk";
import axios from "axios";
import { cleanUrlQueryString } from "../../common/utils";

export function initAWSsdk(accessKeyId, secretAccessKey, region) {
  AWS.config.update({
    accessKeyId,
    secretAccessKey,
  });
  AWS.config.region = region;
}

export const processImageUrl = async (fileStorageConfig, url) => {
  const { provider, privateBucket } = fileStorageConfig;
  if (url && url.indexOf(privateBucket) > 0) {
    return await getImageUrlBase64(
      provider,
      privateBucket,
      cleanUrlQueryString(url ? url.substr(url.indexOf("/", url.indexOf(privateBucket)) + 1, url.length) : "")
    );
  }
  return url;
};

export const getImageUrlBase64 = async (provider, Bucket, Key) => {
  return new Promise((resolve, reject) => {
    let s3 =
      provider === "do"
        ? new AWS.S3({
          endpoint: new AWS.Endpoint(AWS.config.region + ".digitaloceanspaces.com"),
        })
        : new AWS.S3();
    s3.getSignedUrl('getObject', {
      Bucket,
      Key,
      Expires: 7200
    }, async function (err, data) {
      if (err) {
        resolve("");
      } else {
        resolve(data);
      }
    })
  });
};

export const isImageURLValid = async (url) => {
  try {
    const response = await axios.get(url, { responseType: 'blob' });
    if (response.status === 200) {
      return true;
    }
  } catch (_error) {
  }

  return false;
}

export const processDocumentUrl = async (fileStorageConfigs, url) => {
  const { privateBucket, provider } = fileStorageConfigs;
  if (url && url.indexOf(privateBucket) > 0) {
    return await getDocumentSignedUrl(
      provider,
      privateBucket,
      url.substr(url.indexOf("/", url.indexOf(privateBucket)) + 1, url.length)
    );
  }
  return url;
};

export const getDocumentSignedUrl = async (provider, Bucket, Key) => {
  return new Promise((resolve, reject) => {
    let s3 =
      provider === "do"
        ? new AWS.S3({
          endpoint: new AWS.Endpoint(AWS.config.region + ".digitaloceanspaces.com"),
        })
        : new AWS.S3();

    s3.getSignedUrl(
      "getObject",
      {
        Bucket,
        Key,
        Expires: 7200,
      },
      function (err, data) {
        if (err) {
          // reject(err);
          resolve("");
        } else {
          resolve(data);
        }
      }
    );
  });
};

export const saveFile = async (provider, Bucket, Key, Body, ContentType, aclPublic) => {
  if (process.env.REACT_APP_ENV !== "production") {
    console.log('SAVE FILE: %o', { provider, Bucket, Key, Body, ContentType, aclPublic })
  }
  return new Promise((resolve, reject) => {
    let s3 =
      provider === "do"
        ? new AWS.S3({
          endpoint: new AWS.Endpoint(AWS.config.region + ".digitaloceanspaces.com"),
        })
        : new AWS.S3();
    let ACL = aclPublic ? "public-read" : "private";

    s3.upload(
      {
        ACL,
        Bucket,
        Key,
        Body,
        ContentType,
      },
      function (err, data) {
        if (err || !data) {
          reject(err);
        } else {
          resolve(data.Location);
        }
      }
    );
  });
};

export const copyFile = async (provider, Bucket, Key, CopySource, host) => {
  if (process.env.REACT_APP_ENV !== "production") {
    console.log('COPY FILE: %o', { provider, Bucket, Key, CopySource, host })
  }
  return new Promise((resolve, reject) => {
    let s3 =
      provider === "do"
        ? new AWS.S3({
          endpoint: new AWS.Endpoint(AWS.config.region + ".digitaloceanspaces.com"),
        })
        : new AWS.S3();

    s3.copyObject(
      {
        Bucket,
        Key,
        CopySource
      },
      function (err, data) {
        if (err || !data) {
          reject(err);
        } else {
          resolve(host + Key);
        }
      }
    );
  });
}

export const downloadFile = async (promoter, Key) => {
  const { provider, privateBucket } = promoter?.getConfig("admin", "fileStorage");
  await getFile(provider, privateBucket, Key);
};

export const getFile = async (provider, Bucket, Key) => {
  return new Promise((resolve, reject) => {
    let s3 =
      provider === "do"
        ? new AWS.S3({
          endpoint: new AWS.Endpoint(AWS.config.region + ".digitaloceanspaces.com"),
        })
        : new AWS.S3();

    s3.getObject(
      {
        Bucket,
        Key,
      },
      function (err, data) {
        if (err) {
          // reject(err);
          resolve("");
        } else {
          let blob = new Blob([new Uint8Array(data.Body)], {
            type: data.ContentType + ";utf-8,",
          });
          downloadBlob(blob, Key.slice(Key.lastIndexOf("/") + 1));
        }
      }
    );
  });
};

function downloadBlob(blob, name) {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);
  // Create a link element
  const link = document.createElement("a");
  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;
  // Append link to the body
  document.body.appendChild(link);
  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
}

import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const rolesUpdateCall = async (
  // { rolesList, rolesListDefault },
  dispatch,
  // state,
  { id, designation, entityId, roleType, entityType, permissions, user, group, sector }
) => {
  let rh = requestHelper(dispatch, "ROLES_UPDATE");
  // const filter = state.roles.rolesListFilter;
  try {
    let result = await call({
      type: "ROLES",
      subType: "UPDATE",
      request: {
        id,
        designation,
        entityId,
        entityType,
        roleType,
        permissions,
        user,
        group,
        sector
      },
    });
    if (result) {
      dispatch(setSuccess("roles", "ROLES_UPDATE"));
      dispatch(setRedirect(entityId ? `/roles/${entityId}` : "/roles"));
    }
  } catch (ex) {
    rh.error("roles", ex);
  }
  rh.close();
};

export default rolesUpdateCall;

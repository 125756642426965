import { processImageUrl } from "../../../api/s3";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { asyncForEach } from "../../../common/utils";

const productPriceListComplete = async (fileStorageConfigs, filter = {}, limit = 50000) => {
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "PRICE_LIST",
      request: {
        offset: 0,
        sortField: "PRICEACTIONDATE",
        sortType: "DESC",
        search: null,
        productFamily: [],
        contactPoint: null,
        owner: null,
        status: [],
        ...filter,
        limit,
      },
    });

    let products = [];
    await asyncForEach(result.products, async (product) => {
      products.push({
        ...product,
        thumbnail: await processImageUrl(fileStorageConfigs, product.thumbnail),
      });
    });
    return products;
  } catch (ex) {
    console.error("ERROR: %o", ex);
  }
};

export default productPriceListComplete;

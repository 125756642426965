import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";

import { styles } from "./ProductValidationList.styles";
import ProductValidationDataGrid from "./ProductValidationDataGrid/ProductValidationDataGrid";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {
  productPendingProductList,
  selectPendingProductList,
  selectPendingProductListFilter,
  selectPendingProductListTotalRows,
} from "../productsSlice";
import SearchBox from "../../../common/components/inputs/SearchBox";
import Page from "../../../common/displays/Page/Page";
import { useNavigate, usePromoter, useUser } from "../../../common/hooks";

function ProductValidationList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filter = useSelector(selectPendingProductListFilter);
  const data = useSelector(selectPendingProductList);
  const totalRows = useSelector(selectPendingProductListTotalRows);
  const navigate = useNavigate();
  const user = useUser();
  const promoter = usePromoter();

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search,
      offset: 0,
    };

    dispatch(productPendingProductList(nFilter, true));
  };

  const onFilterChangeHandler = (v) => {
    dispatch(productPendingProductList(v.filter, v.reset));
  };

  return (
    <Page
      permission="products.actions.pendingProduct.list"
      header={<PageTitle
        title={t("products.ProductValidationList.header.title")}
        info={`${totalRows} ${t("products.ProductValidationList.header.rowsInfo")}`}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <SearchBox onEnter={(v) => onSearchHandler(v)} />
            </Grid>
          </Grid>
        }
      />}>
      <Stack spacing={1}>
        {user?.rolePermission?.products?.actions?.pendingProduct?.list && (!promoter?.getConfig("product", "autoAcceptCreate") || !promoter?.getConfig("product", "autoAcceptUpdate") || !promoter?.getConfig("product", "autoAcceptUpdateExternal")) &&
          <div className={classes.links}>
            <ToggleButtonGroup color="primary" size="small" value="validation" >
              <ToggleButton color="primary" value="products" onClick={() => navigate('/products')}>
                {t("products.ProductList.header.productsLink")}
              </ToggleButton>
              <ToggleButton color="primary" value="validation"  >
                {t("products.ProductList.header.validationLink")}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        }
        <ProductValidationDataGrid
          data={data || []}
          filter={filter}
          totalRows={totalRows}
          onFilterChange={onFilterChangeHandler}
        />
      </Stack>
    </Page>
  );
}

export default withStyles(ProductValidationList, styles);

import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import DeleteButton from "../../../../common/components/dialogs/DeleteButton";
import { useUser } from "../../../../common/hooks";
import Permission from "../../../../app/components/Permission";

export default function CampaignDataGridActions({ row, onDelete, onEdit, onView }) {
  const { t } = useTranslation();
  const user = useUser();

  if (row?.status !== "3") {
    return (
      <Grid container spacing={1} justifyContent="flex-end">
        {user?.entity?.type === "P" && onEdit && row?.status !== "2" && (
          <Grid item>
            <Permission code="products.actions.campaign.update">
              <Button variant="contained" color="primary" onClick={() => onEdit(row)}>
                {t("products.CampaignList.columns.editBtn")}
              </Button>
            </Permission>
          </Grid>
        )}
        {onView && (
          <Grid item>
            <Permission code="products.actions.campaign.get">
              <Button variant="contained" color="primary" onClick={() => onView(row)}>
                {t("products.CampaignList.columns.viewBtn")}
              </Button>
            </Permission>
          </Grid>
        )}
        {row?.status !== "2" && (
          <Grid item>
            <Permission code="products.actions.campaign.disable">
              <DeleteButton
                labels={{
                  button: t("products.CampaignList.columns.deleteBtn"),
                  title: t("products.CampaignList.deleteConfirm.title"),
                  description: t("products.CampaignList.deleteConfirm.description"),
                  ok: t("products.CampaignList.deleteConfirm.ok"),
                  cancel: t("products.CampaignList.deleteConfirm.cancel"),
                }}
                onConfirm={() => {
                  onDelete && onDelete(row);
                }}
              />
            </Permission>
          </Grid>
        )}
      </Grid>
    );
  }
  return null;
}

import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Button, Grid, Paper} from "@mui/material";

import {styles} from "./TimerForm.styles";
import {formFields, formSchema} from "./TimerForm.schema";

import {Form, FormFields} from "../../../common/forms"
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {useFirstLoad, useNavigate} from "../../../common/hooks";
import {useParams} from "react-router-dom";
import {promoterCreateTimer, promoterGetTimer, promoterUpdateTimer, selectTimerDetails} from "../promoterSlice";
import Page from "../../../common/displays/Page/Page";
import moment from "moment";
import {jsonTryParse} from "../../../common/utils";

function TimerForm({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const detail = useSelector(selectTimerDetails);

  useFirstLoad(() => {
    if (id && id !== detail?.id) {
      dispatch(promoterGetTimer(id));
    }
  });

    const convertSubmit = (values) => {
        let momentTime = moment(values.time);
        return {
            description: values.description,
            dayOfMonth: values.frequency === "dayOfMonth" ? values.dayOfMonth : "0",
            dayOfWeek: values.frequency === "dayOfWeek" ? values.dayOfWeek : "0",
            hour: momentTime.hours(),
            minute: momentTime.minutes(),
            type: values.type,
            subtype: values.subtype,
            userRequest: values.userRequest,
            request: values.request ?  jsonTryParse(values.request) : {}
        }
    };

  const onSubmit = async (values) => {

    if (id) {
      dispatch(promoterUpdateTimer( id, convertSubmit(values)));
    }
    else {
      dispatch(promoterCreateTimer(convertSubmit(values)));
    }
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        schema={formSchema(t)}
        initialValues={id ? { ...detail, request: JSON.stringify(detail?.request) } || {} : {}}
        render={({ submitting, values, pristine }) => {
          return (
            <Page
              permission="promoter.actions.location.list"
              header={<PageTitle
                title={id ? `${t("promoter.TimerForm.header.updateTitle")} ${values.description}` : t("promoter.TimerForm.header.title")}
                onBack={() => navigate("/promoter/timer")}
                actions={
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit" size="large" disabled={submitting || pristine}>
                        {t("promoter.TimerForm.header.submitBtn")}
                      </Button>
                    </Grid>
                  </Grid>
                }
              />}>
              <Paper className={classes.paper}>
                <FormFields fields={formFields({ t, isEdit : Boolean(id), values })} />
              </Paper>
            </Page>
          );
        }}
      />

    </div>
  );
}

export default withStyles(TimerForm, styles);
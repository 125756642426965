

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const taskGetCall = async (
  { setTaskDetails },
  dispatch,
  { taskId }
) => {
  let rh = requestHelper(dispatch, "TASK_GET");
  try {
    let result = await call({
      type: "TASK",
      subType: "GET",
      request: {
        taskId
      },
    });

    dispatch(setTaskDetails(result?.task))

  } catch (ex) {
    rh.error("tasks", ex);
  }
  rh.close();
};

export default taskGetCall;


export const styles = (theme) => ({
  root: {
    padding: 8,
    color: "#333",
    width: 280
  },
  nomargin: {
    margin: 0
  },
  price: {
    color: "#2E5BFF"
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 24,
    width: 24,
    borderRadius: 12,
    backgroundColor: theme.palette.primary.main,
    padding: 4,
    "& svg": {
      height: 14,
      fill: "white !important"
    },
  },
  iconDelete: {
    backgroundColor: theme.palette.error.main,
  },
  hasChanges: {
    backgroundColor: theme.palette.info.main + " !important",
  },
  promotion: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.info.back,
    }
  },
  inactive: {
    opacity: 0.5,
  },
  promotionToDelete: {
    textDecoration: "line-through"
  }
});
const i18n = {
  flowsMessages: {
    success: {
      PROMOTER_UPDATE_I18N: "i18n updated successfully!",
    },
    error: {
      GENERIC: "Something went wrong",
    },
  },
};

export default i18n;

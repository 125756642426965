
import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { styles } from "./BatchForm.styles";
import { formFields, formSchema } from "./BatchForm.schema";

import { Form, FormFields } from "../../../common/forms";
import Permission from "../../../app/components/Permission";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {
  // batchUpdate,
  batchCreate,
  batchCreateInternalOrder,
  batchListOrdersOpen_Full,
  batchOutputProducts,
  selectOpenOrders,
  selectOutputProductsList,
} from "../batchesSlice";
import { useFirstLoad, useNavigate, usePromoter } from "../../../common/hooks";
import CreateInternalOrderModal from "./CreateInternalOrderModal/CreateInternalOrderModal";

function BatchForm({ classes }) {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const products = useSelector(selectOutputProductsList);
  const orders = useSelector(selectOpenOrders);
  const { businessUnitId } = useParams();
  const [createOrderModal, setCreateOrderModal] = useState(false);
  const promoter = usePromoter();

  useFirstLoad(() => {
    dispatch(batchOutputProducts({ businessUnitId }));
    dispatch(batchListOrdersOpen_Full({ businessUnitId }));
  })

  const onSubmitHandler = async (values) => {
    console.log('values: %o', values);
    dispatch(batchCreate({ ...values, businessUnitId, type: "M" }));
    // dispatch(batchCreate(values));
    // if (id) {
    //   dispatch(batchUpdate({...values, id}));
    // }
    // else {
    // }
  };

  const onCreateInternalOrderClick = () => {
    setCreateOrderModal(true);
  }

  const onCreateInternalOrderSubmit = (values) => {
    console.log('values: %o', values);
    dispatch(batchCreateInternalOrder({ ...values, businessUnitId }));
  }

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmitHandler}
        schema={formSchema({ t })}
        initialValues={{}}
        render={({ values }) => {
          return (
            <Page
              // permission={"batches.actions.batches.list"}
              header={<PageTitle
                title={id ? t("batches.BatchForm.header.titleEdit") : t("batches.BatchForm.header.title")}
                onBack={() => navigate("/batches/" + businessUnitId)}
                actions={
                  <Grid container direction="row" spacing={2}>
                    {/* <Permission code={"batches.actions.batches.create"}> */}
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit">
                        {t("batches.BatchForm.form.btnSubmit")}
                      </Button>
                    </Grid>
                    {/* </Permission> */}
                  </Grid>
                }
              />}>
              <Paper className={classes.paper}>
                <FormFields fields={formFields({ t, i18n, promoter, products, orders, onCreateInternalOrderClick })} />
              </Paper>
              <CreateInternalOrderModal open={createOrderModal} setOpen={setCreateOrderModal} products={products} onSubmit={onCreateInternalOrderSubmit} />
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(BatchForm, styles);

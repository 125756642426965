import React from "react";
// import { useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { styles } from "./ProductTaxOthersDataGrid.styles";
import { listColumns } from "./ProductTaxOthersDataGrid.columns";

import { CustomDataGrid } from "../../../../../common/displays/CustomDataGrid";
import ProductTaxOthersDataGridActions from "./ProductTaxOthersDataGrid.actions";
import { useParams } from "react-router";
// import { selectEntityGroups, selectLocations } from "../../../productsPricesSlice";
// import { usePromoter } from "../../../../../common/hooks";

function ProductTaxOthersDataGrid({
  classes,
  data,
  validation,
  product,
  onOtherTaxClick,
  onValidateOtherTax,
  onHistory
}) {
  const { t } = useTranslation();
  const { processId } = useParams();
  // const promoter = usePromoter();
  // const entityGroups = useSelector(selectEntityGroups);
  // const locations = useSelector(selectLocations);
  const columns = listColumns({ t, validation, product, onOtherTaxClick });

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={columns}
        rows={(data || []).map((item) => ({
          ...item,
          className: getRowClass(classes, item),
          arrow: showRowArrow(item, processId)
        }))}
        hasHeader={false}
        total={data?.length}
        rowKeyField="tempId"
        actionsComponent={<ProductTaxOthersDataGridActions classes={classes} validation={validation} product={product} onValidate={(row, value) => onValidateOtherTax(product, row, value)} onHistory={onHistory}/>}
      />
    </div>
  );
}

export default withStyles(ProductTaxOthersDataGrid, styles);

const getRowClass = (classes, tax) => {
  if (tax.hasChanges) {
    return classes.hasChanges;
  }
  if (tax?.process?.status === "PENDING") {
    return classes.pending;
  }
  if (tax?.process?.removeDate && tax?.process?.status !== "CANCELLED") {
    return classes.rejected;
  }
  if ((tax?.process?.status === "ACCEPTED" || (tax?.process?.status === "CANCELLED" && tax?.process?.removeDate)) && moment(tax?.process?.other?.date).isAfter(moment().subtract(7, 'days'))) {
    return classes.accepted;
  }
  if (tax?.process?.status === "REFUSED" && moment(tax?.process?.other?.date).isAfter(moment().subtract(7, 'days'))) {
    return classes.rejected;
  }
  if (tax?.process?.status === "CANCELLED" && !tax?.process?.removeDate) {
    return classes.rejected;
  }
  return "";
}

const showRowArrow = (row, processId) => {
  return processId && processId === row.process?.processId;
}
import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const alertListCall = async (
  {
    clearAlertsList,
    clearAlertsResolvedList,
    setAlertsListFilter,
    setAlertsResolvedListFilter,
    appendToAlertsList,
    appendToAlertsResolvedList,
    setAlertsListTotalRows,
    setAlertsResolvedListTotalRows,
  },
  dispatch,
  filter,
  clear
) => {
  let rh = requestHelper(dispatch, "ALERT_LIST");
  if (clear) {
    filter = { ...filter, offset: 0 };
    if (filter?.resolved) {
      dispatch(clearAlertsResolvedList());
    } else {
      dispatch(clearAlertsList());
    }
  }
  if (filter?.resolved) {
    dispatch(setAlertsResolvedListFilter(filter));
  } else {
    dispatch(setAlertsListFilter(filter));
  }
  try {
    let result = await call({
      type: "ALERT",
      subType: "LIST",
      request: { ...filter, sortField: filter.sortField?.toUpperCase() },
    });
    if (filter?.resolved) {
      dispatch(appendToAlertsResolvedList(result.alerts));
      dispatch(setAlertsResolvedListTotalRows(result.rows));
    } else {
      dispatch(appendToAlertsList(result.alerts));
      dispatch(setAlertsListTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("alerts", ex);
  }
  rh.close();
};

export default alertListCall;

import React from "react";
import { withStyles } from "tss-react/mui";
import { Grid, Typography } from "@mui/material";

import { styles } from "./IconTextItem.styles";

function IconTextItem({ classes, icon, title, subtitle }) {
  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center" wrap="nowrap" >
        <Grid
          item
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {icon}
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography className={classes.nomargin}>{title}</Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" className={classes.nomargin}>
                {subtitle}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(IconTextItem, styles);
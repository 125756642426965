import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Stack, Typography, Grid, Button } from "@mui/material";
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import ToggleOffIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnIcon from '@mui/icons-material/ToggleOnOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

import { styles } from "./TaskListItem.styles";

import { multiLanguagePropToString } from "../../../../common/utils";
import ActionsPopover from "../../../../common/components/ActionsPopover/ActionsPopover";

function TaskListItem({ classes, task, onTaskClick, onDisable, onEnable, onRemove }) {
  const { t, i18n } = useTranslation();

  let actionsButtons = [];

  const onDisableHandler = (e) => {
    e.preventDefault();
    onDisable && onDisable(task);
  };

  const onEnableHandler = (e) => {
    e.preventDefault();
    onEnable && onEnable(task);
  };

  const onRemoveHandler = (e) => {
    e.preventDefault();
    onRemove && onRemove(task);
  };


  // ENABLE
  if (!task.active) {
    actionsButtons.push(
      // <Permission code="promoter.actions.businessUnit.enable">
      <Button onClick={onEnableHandler} fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <ToggleOnIcon />
          </Grid>
          <Grid item>{t("businessUnits.BusinessUnitCard.actions.enable")}</Grid>
        </Grid>
      </Button>
      // </Permission>
    );
  }

  // DISABLE
  if (task.active) {
    actionsButtons.push(
      // <Permission code="promoter.actions.businessUnit.disable">
      <Button onClick={onDisableHandler} fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <ToggleOffIcon />
          </Grid>
          <Grid item>{t("businessUnits.BusinessUnitCard.actions.disable")}</Grid>
        </Grid>
      </Button>
      // </Permission>
    );
  }

  // DELETE
  if (!task.active) {
    actionsButtons.push(
      // <Permission code="promoter.actions.businessUnit.delete">
      <Button onClick={onRemoveHandler} color="error" fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <DeleteIcon />
          </Grid>
          <Grid item>{t("businessUnits.BusinessUnitCard.actions.remove")}</Grid>
        </Grid>
      </Button>
      // </Permission>
    );
  }

  return (
    <div className={classes.root} onClick={() => onTaskClick(task?.id)}>
      <Stack direction="row" className={task.active ? classes.taskActive : classes.taskDisabled}>
        <ExploreOutlinedIcon className={classes.icon} fontSize="medium" />
        <Typography style={{ margin: 0 }}>{multiLanguagePropToString(task.name, i18n.language)}</Typography>
      </Stack>
      <ActionsPopover actions={actionsButtons} />
    </div>
  );
}

export default withStyles(TaskListItem, styles);
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Chip, Stack } from "@mui/material";

import { styles } from "./ConditionItem.styles";

function ConditionItem({ classes, condition }) {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Stack direction="row" spacing={1}>
        <Chip color="primary" size="small" label={t("common.Enums.conditionVariable." + condition?.variable)} />
        {condition?.payload?.start_date_diff && <Chip size="small" label={condition?.payload?.start_date_diff} />}
      </Stack>
    </div>
  );
}

export default withStyles(ConditionItem, styles);
import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';

import { styles } from "./EntityInfo.styles";

import { getCountryName } from "../../../../common/utils";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";

function EntityInfo({ classes, entity }) {
  const { t } = useTranslation();
  const [logoLoaded, setLogoLoaded] = useState(false);
  return (
    <Paper elevation={0} className={classes.root} id="entityInfo">
      <Grid container>
        <Grid item xs={6}>
          {/* <Typography variant="h3" align="left" component="h2" className={classes.title}>
          {entity.name}
        </Typography>
        <Typography variant="body1" align="left" component="span" color="textSecondary" className={classes.type}>
          {t("common.Enums.entityType." + entity.type)}
        </Typography> */}
          {!logoLoaded && <BrokenImageOutlinedIcon fontSize="large" color="disabled" />}
          <img
            className={classes.entityImage}
            src={entity?.logo?.url}
            alt={entity?.taxNumber}
            style={{ display: logoLoaded ? "block" : "none" }}
            onError={() => setLogoLoaded(false)}
            onLoad={() => setLogoLoaded(true)} />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <LabelValue
                label={t("entities.Entities.details.taxNumber")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {entity?.taxNumber}
                  </Typography>
                }
              />
              <LabelValue
                label={t("entities.Entities.details.phone")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {entity?.phone}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={4}>
              <LabelValue
                label={t("entities.Entities.details.address")}
                type="address"
                value={
                  <Grid container direction="column">
                    <Typography variant="body1" align="left" component="span" className={classes.noMargin}>
                      {entity?.taxAddress?.address}
                    </Typography>
                    {(entity?.taxAddress?.locale || entity?.taxAddress?.postalCode) && <Typography variant="body1" align="left" component="span" className={classes.noMargin}>
                      {[entity?.taxAddress?.locale, entity?.taxAddress?.postalCode].filter(item => item).join(" - ")}
                    </Typography>}
                    <Typography variant="body1" align="left" component="span" className={classes.noMargin}>
                      {getCountryName(entity?.taxAddress?.country)}
                    </Typography>
                  </Grid>
                }
              />
            </Grid>
            <Grid item xs={4}>
              <LabelValue
                label={t("entities.Entities.details.fax")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {entity?.fax}
                  </Typography>
                }
              />
              <LabelValue
                label={t("entities.Entities.details.email")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {entity?.email}
                  </Typography>
                }
              />
              <LabelValue
                label={t("entities.Entities.details.website")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {entity?.website}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(EntityInfo, styles)

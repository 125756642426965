import React from "react";
import * as Yup from "yup";
import { MultiSelectField } from "../../../../common/forms";

export const filterFields = (locations) => (t) => {
  return [
    {
      size: 12,
      field: <MultiSelectField id="locations" label={t("promoter.RefDataTaxsList.filter.locations")} options={getLocationsOptions(locations)} />,
    },
  ];
};

export const filterSchema = () => () => {
  return Yup.object().shape({
    locations: Yup.array().nullable(),
  });
};

const getLocationsOptions = (locations) => {
  return (locations || []).map(({ id, name }) => {
    return {
      value: id,
      text: name,
    };
  });
};
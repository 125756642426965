export const batchListDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "BATCHNUMBER",
  sortType: "ASC",
  search: '',
  type: [],
  status: []
}

export const batchOrdersListDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "ORDERNUMBER",
  sortType: "ASC",
  search: '',
  type: [],
  status: []
}

export const batchActivityListDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "DATE",
  sortType: "ASC",
  search: '',
  type: [],
  status: []
}

export const batchTasksListDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "DUEDATE",
  sortType: "ASC",
  search: '',
  type: [],
  status: []
}
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { toGroupCreate } from "../entitiesMapper";

const entityCreateGroupCall = async (dispatch, group) => {
  let rh = requestHelper(dispatch, "CREATE_GROUP");
  try {
    await call({
      type: "ENTITY",
      subType: "CREATE_GROUP",
      request: toGroupCreate(group),
    });
    setTimeout(() => {
      dispatch(setSuccess("entities", "ENTITY_CREATE_GROUP"));
      dispatch(setRedirect(`/entities/groups`));
    }, 500);
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityCreateGroupCall;

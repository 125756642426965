import DateTime from "../../../../common/displays/DateTime/DateTime";
import { currencyFormat } from "../../../../common/utils";

export function listColumns(t, classes) {
  return [
    {
      field: "entityName",
      headerName: t("business.queries.ProductPricesList.columns_entityName"),
      align: "left",
      headerAlign: "left"
    },
    {
      field: "productName",
      headerName: t("business.queries.ProductPricesList.columns_productName"),
      align: "left",
      headerAlign: "left"
    },
    {
      field: "gtin",
      headerName: t("business.queries.ProductPricesList.columns_gtin"),
      align: "left",
      headerAlign: "left"
    },
    {
      field: "gln",
      headerName: t("business.queries.ProductPricesList.columns_gln"),
      align: "left",
      headerAlign: "left"
    },
    {
      field: "priceType",
      headerName: t("business.queries.ProductPricesList.columns_priceType"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return t("common.Enums.priceType." + v.priceType);
      },
      disableSort: true,
    },
    {
      field: "groupName",
      headerName: t("business.queries.ProductPricesList.columns_groupName"),
      align: "left",
      headerAlign: "left"
    },
    {
      field: "retailPrice",
      headerName: t("business.queries.ProductPricesList.columns_retailPrice"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return currencyFormat(v.retailPrice);
      },
    },
    {
      field: "salePrice",
      headerName: t("business.queries.ProductPricesList.columns_salePrice"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return currencyFormat(v.salePrice);
      },
    },
    {
      field: "active",
      headerName: t("business.queries.ProductPricesList.columns_active"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return t("business.queries.ProductPricesList.status_" + v.active);
      },
    },
    {
      field: "createdBy",
      headerName: t("business.queries.ProductPricesList.columns_createdBy"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "createdDate",
      headerName: t("business.queries.ProductPricesList.columns_createdDate"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return <DateTime date={v.createdDate} />
      },
    },
    {
      field: "updatedBy",
      headerName: t("business.queries.ProductPricesList.columns_updatedBy"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "updatedDate",
      headerName: t("business.queries.ProductPricesList.columns_updatedDate"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return <DateTime date={v.updatedDate} />
      },
    }
  ];
}

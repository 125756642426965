import { Checkbox } from "@mui/material";
import { weightFormat } from "../../../../../common/utils";

export function listColumns(t, classes, onVolumeCheckChange, hideCheckbox) {
  return [
    {
      field: "check",
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <Checkbox
            onChange={(e) => onVolumeCheckChange({ ...v, checked: e.target.checked })}
            checked={v.checked}
            color="primary"
          />
        );
      },
      hidden: hideCheckbox,
    },
    {
      field: "id",
      headerName: t("orders.OperatorOrderDetails.columns.id"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "code",
      headerName: t("orders.OperatorOrderDetails.columns.code"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "full",
      headerName: t("orders.OperatorOrderDetails.columns.full"),
      align: "center",
      headerAlign: "center",
      customValue: (v) => {
        return v.full
          ? t("orders.OperatorOrderDetails.columns.fullyes")
          : t("orders.OperatorOrderDetails.columns.fullno");
      },
    },
    {
      field: "weight",
      headerName: t("orders.OperatorOrderDetails.columns.weight"),
      align: "right",
      headerAlign: "right",
      customValue: (v) => {
        return weightFormat(v.weight);
      },
    },
  ];
}

import * as Yup from "yup";
import { MultiLangTextField, MultiSelectField, NumberField, SelectField, WeekdaysSelectorField } from "../../../common/forms";
import { getContactOptions, getGroupOptions, getSectorOptions } from "../../tasks/TasksForm/TasksForm.options";
import { getScheduleOptions } from "./BusinessUnitStepTaskForm.options";

export function formSchema({ t }) {
  return Yup.object().shape({
    name: Yup.object().oneLanguageRequired(t("businessUnits.BusinessUnitStepTaskForm.form.nameRequired")),
    users: Yup.array().when("associationType",
      {
        is: "USER",
        then: schema => schema.ensure().min(1, t("businessUnits.BusinessUnitForm.form.usersRequired")),
        otherwise: schema => schema,
      }),
    groups: Yup.array().when("associationType",
      {
        is: "GROUP",
        then: schema => schema.ensure().min(1, t("businessUnits.BusinessUnitForm.form.groupsRequired")),
        otherwise: schema => schema,
      }),
    sectors: Yup.array().when("associationType",
      {
        is: "SECTOR",
        then: schema => schema.ensure().min(1, t("businessUnits.BusinessUnitForm.form.sectorsRequired")),
        otherwise: schema => schema,
      }),
  });
}

export function formFields({ t, values, promoter, contacts, sectorsGroups, i18n, taskType }) {
  const associationTypeOptions = ["GROUP", "USER", "SECTOR"].map(item => ({
    value: item,
    text: t("roles.enum.associationType." + item),
  }))
  return [
    {
      size: 12,
      field: <MultiLangTextField
        id="name"
        defaultLanguage={i18n.language}
        languages={promoter?.getConfig("admin", "language") || []}
        required
        label={t("businessUnits.BusinessUnitStepTaskForm.form.name")}
      />,
    },
    {
      size: 4,
      field: <SelectField
        id="associationType"
        options={associationTypeOptions}
        label={t("businessUnits.BusinessUnitStepTaskForm.form.associationType")}
      />,
    },
    {
      size: 8,
      field: (
        <MultiSelectField
          id="users"
          options={getContactOptions(contacts)}
          label={t("businessUnits.BusinessUnitStepTaskForm.form.users")}
          required
        />
      ),
      hidden: values?.associationType !== "USER"
    },
    {
      size: 8,
      field: (
        <MultiSelectField
          id="groups"
          options={getGroupOptions(sectorsGroups)}
          label={t("businessUnits.BusinessUnitStepTaskForm.form.groups")}
          required
        />
      ),
      hidden: values?.associationType !== "GROUP"
    },
    {
      size: 8,
      field: (
        <MultiSelectField
          id="sectors"
          options={getSectorOptions(t, promoter?.refData)}
          label={t("businessUnits.BusinessUnitStepTaskForm.form.sectors")}
          required
        />
      ),
      hidden: values?.associationType !== "SECTOR"
    },
    {
      size: 12,
    },
    {
      size: 6,
      field: (
        <SelectField
          id="schedule.type"
          options={getScheduleOptions(t, taskType)}
          label={t("businessUnits.BusinessUnitStepTaskForm.form.schedule")}
          required
        />
      ),
    },
    {
      size: 6,
      field: (
        <WeekdaysSelectorField
          id="schedule.config.weekdays"
          label={t("businessUnits.BusinessUnitStepTaskForm.form.scheduleWeekdays")}
        />
      ),
      hidden: values?.schedule?.type !== "W"
    },
    {
      size: 6,
      field: (
        <NumberField
          id="schedule.config.numberOfDays"
          label={t("businessUnits.BusinessUnitStepTaskForm.form.scheduleNumberOfDays")}
          required
        />
      ),
      hidden: values?.schedule?.type !== "D"
    },
  ];
}


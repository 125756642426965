import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Stack } from "@mui/material";

import { styles } from "./ForecastTableCell.styles";
import { measureFormat } from "../../../../common/utils";

function ForecastTableCell({ classes, target, date }) {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Stack>
        <Typography className={classes.nomargin} variant="caption">{date}</Typography>
        <Typography className={classes.nomargin}>{`${measureFormat({ t, measure: target, decimalPlaces: 0 })}`}</Typography>
      </Stack>
    </div>
  );
}

export default withStyles(ForecastTableCell, styles);
import React from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Grid, Stack, Typography } from "@mui/material";

import { styles } from "./BatchCard.styles";
import { dateFormat } from "../../../../../common/utils";
import HatcheryProgress from "../../../_common/HatcheryProgress/HatcheryProgress";

function BatchCard({ classes, batch }) {
  // const { t } = useTranslation();
  const { batchNumber, startDate } = batch;

  return (
    <div className={classes.root}>
      <Stack spacing={0.5}>
        <HatcheryProgress height={30} values={[1000, 2000, 3000, 4000, 1000, 5000, 2000]} />
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography className={classes.batchNumber} color="textSecondary">{batchNumber}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.date} color="textSecondary">{dateFormat(startDate)}</Typography>
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
}

export default withStyles(BatchCard, styles);
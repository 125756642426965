import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";

import { styles } from "./PromotionForm.styles";
import { formFields, formSchema } from "./PromotionForm.schema";

import { Form, FormFields } from "../../../../common/forms"
import { useDispatch } from "react-redux";
import { addPromotion, deletePromotion, updatePromotion } from "../../productsPricesSlice";
import moment from "moment";

function PromotionForm({ classes, onAddPromotionSubmit, price, promotion, onDelete }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    const { productId, priceId } = price;
    if (promotion) {
      dispatch(updatePromotion({ productId, priceId, promotion: values }))
    }
    else {
      dispatch(addPromotion({ productId, priceId, promotion: values }))
    }
    onAddPromotionSubmit && onAddPromotionSubmit();
  };

  const onDeleteHandler = () => {
    const { productId, priceId } = price;
    dispatch(deletePromotion({ productId, priceId, promotion }));
    onDelete && onDelete();
  }

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmit}
        schema={formSchema({ t })}
        initialValues={promotion || { startDate: moment().toISOString(), type: "PD" }}
        render={({ values }) => {
          return (
            <div>
              <FormFields fields={formFields({ t, values })} />
              <br />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  {promotion &&
                    <Button variant="contained" type="button" fullWidth color="error" onClick={onDeleteHandler}>{t('productsPrices.PromotionForm.form.btnDelete')}</Button>
                  }
                </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" type="submit" fullWidth>{promotion ? t('productsPrices.PromotionForm.form.btnUpdate') : t('productsPrices.PromotionForm.form.btnSubmit')}</Button>
                </Grid>
              </Grid>
            </div>
          );
        }}
      />

    </div>
  );
}

export default withStyles(PromotionForm, styles);
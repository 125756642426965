import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Stack } from "@mui/material";

import { styles } from "./CampaignTooltip.styles";
import IconWithTooltip from "../../../../common/components/displays/IconWithTooltip";
import { CampaignTypeIcon, PicturePlaceholder } from "../../../../common/icons";
import { currencyFormat } from "../../../../common/utils";
import { isCampaignMet } from "../../orders.utils";

function CampaignTooltip({ classes, campaign, quantity, currentOrder, outdated, forceHighlight = false, groups }) {
  const { t } = useTranslation();

  let campaignInfo = null;
  switch (campaign.campaignType) {
    case "PR": {
      campaignInfo = (
        <Grid item>
          <Grid container direction="column" spacing={1}>
            {campaign?.promotionCampaign?.productPrice.map(({ quantity, price }, index) => (
              <Grid item key={"promotion_" + index}>
                <Typography component="span" color="textSecondary" className={classes.description}>
                  {t("orders.CampaignTooltip.promotion.description", { quantity, price: currencyFormat(price) })}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      );
      break;
    }
    case "BU": {
      campaignInfo = (
        <Grid item>
          <Grid container spacing={1} direction="row" wrap="nowrap">
            <Grid item>
              <Grid container spacing={1} direction="column">
                <Grid item>{t("orders.CampaignTooltip.bundle.buy")}</Grid>
                {(campaign?.bundlingCampaign?.productBuy || []).map((item, index) => {
                  let product = (campaign?.products || []).find((p) => p.productId === item.productId);
                  return product ? (
                    <Grid item key={"bundle_buy_" + product?.productId || index}>
                      {item.quantity}X {productCard(classes, product)}
                    </Grid>
                  ) : null;
                })}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1} direction="column">
                <Grid item>{t("orders.CampaignTooltip.bundle.offer")}</Grid>
                {(campaign?.bundlingCampaign?.productOffers || []).map((item, index) => {
                  let product = (campaign?.products || []).find((p) => p.productId === item.productId);
                  return product ? (
                    <Grid item key={"bundle_offer_" + product?.productId || index}>
                      {item.quantity}X {productCard(classes, product)}
                    </Grid>
                  ) : null;
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
      break;
    }
    case "MB": {
      campaignInfo = (
        <Grid item>
          <Typography component="span" color="textSecondary">
            {t("orders.CampaignTooltip.merchandise.description", {
              offer: campaign?.merchandiseBonusesCampaign?.qtOffer,
              buy: campaign?.merchandiseBonusesCampaign?.qtBuy,
            })}
          </Typography>
        </Grid>
      );
      break;
    }
    case "PD": {
      campaignInfo = (
        <Grid item>
          <Typography component="span" color="textSecondary">
            {t("orders.CampaignTooltip.percentage.description", {
              percentage: campaign?.percentageCampaign?.percentage,
            })}
          </Typography>
        </Grid>
      );
      break;
    }
    default: {
      break;
    }
  }

  let title = t("common.Enums.campaignType." + campaign.campaignType);
  let iconClass = classes.campaign;
  if (outdated) {
    iconClass += " " + classes.outdated;
    title += ` (${t("orders.CampaignTooltip.title.outdated")})`;
  } else if (forceHighlight || isCampaignMet(campaign, quantity, currentOrder)) {
    iconClass += " " + classes.campaignMet;
  }

  return (
    <div className={classes.root}>
      <IconWithTooltip
        icon={
          <div className={iconClass}>
            <CampaignTypeIcon type={campaign.campaignType} />
          </div>
        }
      >
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography component="h6" variant="caption" color="textPrimary">
              {title}
            </Typography>
          </Grid>
          {campaignInfo}
          {
            groups?.length > 0 && <Grid item>
              <Stack spacing={1}>
                <hr />
                {groups.map((item, index) => <div key={"campaign_" + index}><Typography >{item.groupName}</Typography></div>)}
              </Stack>
            </Grid>
          }
        </Grid>
      </IconWithTooltip>
    </div>
  );
}

export default withStyles(CampaignTooltip, styles)

const productCard = (classes, product) => {
  return (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid
        item
        style={{
          width: 40,
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {(product.thumbnail || product.image) ? (
          <img style={{ maxWidth: 30, maxHeight: 30 }} alt={product.description} src={product.thumbnail || product.image} />
        ) : (
          <PicturePlaceholder />
        )}
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography className={classes.product}>{product.description}</Typography>
          </Grid>
          <Grid item>
            <Typography color="textSecondary" className={classes.product}>
              {product.gtin}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

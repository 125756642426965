import { createSlice } from "@reduxjs/toolkit";
import { productsDpromsDefaultFilter } from "../products/_common/productsFilters";
import productDpromListCall from "./_sliceCalls/productDpromListCall";
import { appendItemsToList, getPromoterStorage, guid, setPromoterStorage } from "../../common/utils/utils";
import productDpromCreateCall from "./_sliceCalls/productDpromCreateCall";
import productDpromActionCall from "./_sliceCalls/productDpromActionCall";
import productDpromRemoveCall from "./_sliceCalls/productDpromRemoveCall";

export const productsDproms = createSlice({
  name: "productsDproms",
  initialState: {
    dpromsList: [],
    dpromsListTotalRows: 0,
    dpromsListFilter: productsDpromsDefaultFilter,
    tempDpromsList: [],
    tempValidation: {},
  },
  reducers: {
    clearDpromsList: (state) => {
      state.dpromsList = [];
    },

    appendToDpromsList: (state, action) => {
      // state.dpromsList = [...(state.dpromsList || []), ...action.payload];
      appendItemsToList(state.dpromsList, action.payload, true, "id");
    },

    prependToDpromsList: (state, action) => {
      appendItemsToList(state.dpromsList, action.payload, false, "id");
    },

    setDpromsListTotalRows: (state, action) => {
      state.dpromsListTotalRows = action.payload;
    },

    setDpromsListFilter: (state, action) => {
      state.dpromsListFilter = action.payload;
    },

    initTempDproms: (state) => {
      state.tempDpromsList = getPromoterStorage("dproms") || [];
    },

    addTempDprom: (state, action) => {
      let nTempDproms = [...state.tempDpromsList, { ...action.payload, tempId: guid() }];
      setPromoterStorage("dproms", nTempDproms);
      state.tempDpromsList = nTempDproms;
    },

    updateTempDprom: (state, action) => {
      let nTempDproms = [...state.tempDpromsList.filter(item => item.tempId !== action.payload.tempId), { ...action.payload }];
      setPromoterStorage("dproms", nTempDproms);
      state.tempDpromsList = nTempDproms;
    },

    removeTempDprom: (state, action) => {
      let nTempDproms = state.tempDpromsList.filter(item => item.tempId !== action.payload);
      setPromoterStorage("dproms", nTempDproms);
      state.tempDpromsList = nTempDproms;
    },

    clearTempDproms: (state) => {
      state.tempDpromsList = [];
      setPromoterStorage("dproms", []);
    },

    updateTempValidation: (state, action) => {
      const { id, valid, comment, processes } = action.payload;
      const taskId = (processes || []).find(item => item.processType === "FlowCreatePromoDiscount" || item.processType === "FlowRemovePromoDiscount")?.taskId;
      state.tempValidation[id] = { valid, comment, taskId };
    },

    clearTempValidation: (state, action) => {
      state.tempValidation = {};
    }
  },
});

export default productsDproms.reducer;
export const {
  addTempDprom,
  removeTempDprom,
  updateTempValidation,
  clearTempValidation,
  updateTempDprom,
  initTempDproms,
  prependToDpromsList,
  appendToDpromsList,
  clearTempDproms
} = productsDproms.actions;

// SELECTS
export const selectDpromsList = (state) => state.productsDproms.dpromsList;
export const selectDpromsListTotalRows = (state) => state.productsDproms.dpromsListTotalRows + state.productsDproms.tempDpromsList.length;
export const selectDpromsListFilter = (state) => state.productsDproms.dpromsListFilter;
export const selectTempDpromsList = (state) => state.productsDproms.tempDpromsList;
export const selectTempDpromsValidation = (state) => state.productsDproms.tempValidation;

// CALLS
export const productDpromList = (filter, clear = false) =>
  (dispatch) => {
    productDpromListCall(productsDproms.actions, dispatch, { filter, clear });
  };

export const productDpromCreate = () => (dispatch, getState) => {
  let state = getState();
  let dproms = state.productsDproms.tempDpromsList;
  if (dproms && dproms.length > 0) {
    productDpromCreateCall({ ...productsDproms.actions, productDpromList }, dispatch, state, dproms);
  }
}

export const productDpromAction = () => (dispatch, getState) => {
  let state = getState();
  let dproms = state.productsDproms.tempValidation;
  if (dproms && Object.keys(dproms).length > 0) {
    productDpromActionCall({ ...productsDproms.actions, productDpromList }, dispatch, state, dproms);
  }
}

export const productDpromRemove = ({ id, type, ownerId, ownerName, locations, products, family, removeDate }) => (dispatch) => {
  productDpromRemoveCall(dispatch, { id, type, ownerId, ownerName, locations, products, family, removeDate });
}


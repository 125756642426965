import * as Yup from "yup";
import {TextField} from "../../../../common/forms";

export function formSchema({ t }) {
  return Yup.object().shape({
    user: Yup.string().emailValidator(t("business.process.TaskAddUserModal.invalidEmail")).required(t("business.process.TaskAddUserModal.userRequired")),
  });
}

export function formFields({ t }) {
  return [
    {
      size: 12,
      field: <TextField
        id="user"
        type="email"
        rows={3}
        required
        label={t("business.process.TaskAddUserModal.user")}
      />,
    },
  ];
}


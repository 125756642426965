import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";
import moment from "moment";

const productCreatePriceCall = async (
  { clearTempPrices },
  dispatch,
  state,
  prices
) => {
  let rh = requestHelper(dispatch, "PRODUCT_CREATE_PRICE");
  // let filter = state.productsPrices.pricesListFilter;
  try {
    let nPrices = prices.map(({
      productId,
      ownerId,
      ownerName,
      priceId,
      priceType,
      locations,
      effectiveDate,
      expireDate,
      groupEntity,
      promotions,
      retailPrice,
      salePrice,
      contactPoint,
      groupId,
      newSalePrice,
      newRetailPrice,
      isNew,
      hasChanges,
    }) => ({
      productId,
      ownerId,
      ownerName,
      priceId: isNew ? undefined : priceId,
      type: priceType,
      locations,
      effectiveDate,
      expireDate,
      groupEntity: groupEntity || groupId,
      promotions: (promotions || []).map(item => ({
        ...item,
        isNew: undefined,
        hasChanges: item.hasChanges || item.isNew,
        startDate: item.startDate ? moment(item.startDate).format("YYYY-MM-DD") : undefined,
        endDate: item.endDate ? moment(item.endDate).format("YYYY-MM-DD") : undefined,
      })),
      salePrice: newSalePrice || salePrice,
      retailPrice: newRetailPrice || retailPrice,
      contactPoint,
      hasChanges: hasChanges || isNew,
    }));
    let result = await call({
      type: "PRODUCT",
      subType: "CREATE_PRICE",
      request: {
        prices: nPrices
      }
    });
    if (result) {
      dispatch(clearTempPrices());
    }
    setTimeout(() => {
      if (result) {
        dispatch(setSuccess("productsPrices", "PRODUCT_CREATE_PRICE"));
        // dispatch(productPriceList(filter, true))
      }
    }, 300);

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productCreatePriceCall;

export const styles = (theme) => ({
  root: {
    "& .CdgHeader-actions": {
      width: 120
    },
    position: "relative",
  },
  hightLightRow: {
    backgroundColor: "#fffbb8",
  },
  zero: {
    // opacity: "0.3",
  },
  hasChanges: {
    outline: "1px solid " + theme.palette.info.main,
    outlineOffset: "-1px",
    borderRadius: "5px"
  },
  accepted: {
    backgroundColor: theme.palette.success.back,
  },
  rejected: {
    backgroundColor: theme.palette.error.back,
  },
  pending: {
    backgroundColor: theme.palette.warning.back,
  },icon: {
    display: "block",
    color: theme.palette.text.secondary,
  }
});

export function listColumns(t, classes) {
  return [
    {
      field: "sellerName",
      headerName: t("business.reports.WeeklyUnits.columns_sellerName"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "buyerName",
      headerName: t("business.reports.WeeklyUnits.columns_buyerName"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "deliveryPlace",
      headerName: t("business.reports.WeeklyUnits.columns_deliveryPlace"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "totalTransportUnits",
      headerName: t("business.reports.WeeklyUnits.columns_totalTransportUnits"),
      align: "left",
      headerAlign: "left",
    },
  ];
}

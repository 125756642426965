import React, { useEffect, useRef, useState } from "react";
import { withStyles } from "tss-react/mui";
import { List, ListItem, CircularProgress, Button } from "@mui/material";

import { styles } from "./InfiniteList.styles";

function InfiniteList({ classes, data, renderElement, style, loadMore, isLoading, className }) {
  const [obsOn, setObsOn] = useState(false);
  const loader = useRef(null);
  const loadMoreBtn = useRef();

  useEffect(() => {
    if (!obsOn && !isLoading) {
      const observer = new IntersectionObserver(
        (entities) => {
          const target = entities[0];
          if (target.isIntersecting) {
            loadMoreBtn?.current && loadMoreBtn.current.click();
          }
        },
        {
          root: null,
          rootMargin: "20px",
          threshold: 1.0,
        }
      );
      if (loader.current) {
        observer.observe(loader.current);
        setObsOn(true);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <List className={classes.root + (className ? " " + className : "")} style={style}>
      {(data || []).map((row, index) => {
        return <ListItem disablePadding disableGutters key={"list_item_" + index}>{renderElement(row)}</ListItem>;
      })}
      <ListItem className={classes.loader}>
        <div ref={loader}>{isLoading && <CircularProgress color="primary" />}</div>
        <Button
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (data && data.length > 0) {
              loadMore && loadMore();
            }
          }}
          style={{ display: "none" }}
          ref={loadMoreBtn}
        >
          Load More
        </Button>
      </ListItem>
    </List>
  );
}

export default withStyles(InfiniteList, styles)

import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Avatar,
  Grid,
} from "@mui/material";
import AvatarGroup from '@mui/material/AvatarGroup';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styles } from "./ContactsLiteList.styles";

function ContactsLiteList({ classes, contacts }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded}
        className={classes.accordion}
        onChange={() => setExpanded(!expanded)}
        component="div"
      >
        {!expanded && (
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <AvatarGroup max={4}>
              {contacts.map((contact) => {
                let displayName =
                  contact?.firstName || contact?.lastName
                    ? (contact?.firstName || "") +
                      " " +
                      (contact?.lastName || "")
                    : contact?.email;
                return <Avatar alt={displayName} key={"avatar_ " + contact.id} src={contact.logo} />;
              })}
            </AvatarGroup>
          </AccordionSummary>
        )}
        <AccordionDetails >
          <Grid container direction="column" spacing={2}>
            {contacts.map((contact) => {
              let displayName =
              contact?.firstName || contact?.lastName
                ? (contact?.firstName || "") +
                  " " +
                  (contact?.lastName || "")
                : contact?.email;
              return (
                <Grid item key={"item_ " + contact.id}>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                    <Avatar alt={displayName} src={contact.logo} />
                    </Grid>
                    <Grid item>
                      <Grid container direction="column">
                        <Typography
                          variant="h4"
                          align="left"
                          component="h4"
                          className={classes.name}
                        >
                          {displayName}
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          component="span"
                          className={classes.details}
                        >
                          {t("common.Enums.roleType." + contact.roleType)}
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          component="span"
                          className={classes.details}
                        >
                          <a href={`tel:${contact.phoneNumber}`}>{contact.phoneNumber}</a>
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          component="span"
                          className={classes.details}
                        >
                          <a href={`mailto:${contact.email}`}>{contact.email}</a>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default withStyles(ContactsLiteList, styles)

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const productDpromRemoveCall = async (
  dispatch,
  { id, type, ownerId, ownerName, locations, products, family, removeDate }
) => {
  let rh = requestHelper(dispatch, "PRODUCT_DPROM_REMOVE");
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "DPROM_REMOVE",
      request: {
        promoDiscounts: [{ id, type, ownerId, ownerName, locations, products, family, removeDate }]
      }
    });
    setTimeout(() => {
      if (result) {
        dispatch(setSuccess("productsDproms", "PRODUCT_DPROM_REMOVE"));
      }
    }, 300);

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productDpromRemoveCall;

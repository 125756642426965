import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import { listColumns } from "./AddressesDataGrid.columns";
import { styles } from "./AddressesDataGrid.styles";
import AddressesDataGridActions from "./AddressesDataGrid.actions";
import { Drawer, Typography } from "@mui/material";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";
import { getCountryName } from "../../../../common/utils";
import { useParams } from "react-router-dom";

function AddressesDataGrid({ classes, data, entity, onDelete, onConfirm, onEdit }) {
  const { t } = useTranslation();
  const columns = listColumns(t, classes);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const { addressId } = useParams();

  const onRowSelectedHandler = (row) => {
    setSelectedRow(row);
    setDrawerOpen(true);
  };
  const selectedRowContact = entity.contacts.find((item) => item.id === selectedRow?.contactId);
  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          columns={columns}
          rows={data.map((item) => {
            return {
              ...item,
              className: addressId === item.id ? classes.selectedAddress : undefined,
            };
          })}
          actionsComponent={
            <AddressesDataGridActions onDelete={onDelete} t={t} allowDelete={data?.length > 1} onConfirm={onConfirm} onEdit={onEdit} />
          }
          onRowSelected={onRowSelectedHandler}
        />
      )}
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div className={classes.drawer}>
          {selectedRow?.description !== undefined && (
            <LabelValue
              label={t("entities.AddressesList.details.description")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.description}
                </Typography>
              }
            />
          )}
          {selectedRowContact !== undefined && (
            <LabelValue
              label={t("entities.AddressesList.details.contact")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRowContact.firstName + " " + selectedRowContact.lastName}
                </Typography>
              }
            />
          )}
          {selectedRow?.address !== undefined && (
            <LabelValue
              label={t("entities.AddressesList.details.address")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.address}
                </Typography>
              }
            />
          )}
          {selectedRow?.locale !== undefined && (
            <LabelValue
              label={t("entities.AddressesList.details.locale")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.locale}
                </Typography>
              }
            />
          )}
          {selectedRow?.country !== undefined && (
            <LabelValue
              label={t("entities.AddressesList.details.country")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {getCountryName(selectedRow?.country)}
                </Typography>
              }
            />
          )}
          {selectedRow?.postalCode !== undefined && (
            <LabelValue
              label={t("entities.AddressesList.details.postalCode")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.postalCode}
                </Typography>
              }
            />
          )}
          {selectedRow?.latitude !== undefined && (
            <LabelValue
              label={t("entities.AddressesList.details.latitude")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.latitude}
                </Typography>
              }
            />
          )}
          {selectedRow?.longitude !== undefined && (
            <LabelValue
              label={t("entities.AddressesList.details.longitude")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.longitude}
                </Typography>
              }
            />
          )}
          {selectedRow?.operatorName !== undefined && (
            <LabelValue
              label={t("entities.AddressesList.details.operatorName")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.operatorName}
                </Typography>
              }
            />
          )}
          {selectedRow?.operatorAddressDesc !== undefined && (
            <LabelValue
              label={t("entities.AddressesList.details.operatorAddressDesc")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.operatorAddressDesc}
                </Typography>
              }
            />
          )}
        </div>
      </Drawer>
    </div>
  );
}

export default withStyles(AddressesDataGrid, styles)

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";
import { jsonTryParse } from "../../../common/utils";

const formProductListFamilyCall = async ({ setFormProductFamilies }, dispatch) => {
  let rh = requestHelper(dispatch, "PRODUCT_LIST_FAMILY");
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "LIST_FAMILY",
      request: { limit: 5000, offset: 0, parentWithChild: true },
    });
    dispatch(
      setFormProductFamilies(
        (result.productFamily || []).map((item) => {
          return {
            ...item,
            description: jsonTryParse(item.description),
          };
        })
      )
    );
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default formProductListFamilyCall;

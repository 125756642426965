import React from "react";
import { withStyles } from "tss-react/mui";
import { Grid } from "@mui/material";

import { styles } from "./MeasureDiff.styles";
import MeasureAttribute from "./MeasureAttribute/MeasureAttribute";

function MeasureDiff({ classes, showAllFields, parsedField, ...props }) {
  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={0}>
        {(showAllFields || parsedField.category.hasDiff) && (
          <Grid item>
            <MeasureAttribute {...props} parsedField={parsedField} attribute="category" />
          </Grid>
        )}
        {(showAllFields || parsedField.unit.hasDiff) && (
          <Grid item>
            <MeasureAttribute {...props} parsedField={parsedField} attribute="unit" />
          </Grid>
        )}
        {(showAllFields || parsedField.value.hasDiff) && (
          <Grid item>
            <MeasureAttribute {...props} parsedField={parsedField} attribute="value" />
          </Grid>
        )}
        {(showAllFields || parsedField.valueMax.hasDiff) && (
          <Grid item>
            <MeasureAttribute {...props} parsedField={parsedField} attribute="valueMax" />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default withStyles(MeasureDiff, styles);

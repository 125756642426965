import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./ProductsDataGrid.styles";
import { CustomDataGrid } from "../../../../../common/displays/CustomDataGrid";
import { listColumns } from "./ProductsDataGrid.columns";

function ProductsDataGrid({ classes, products = [] }) {
  const { t } = useTranslation();
  const columns = listColumns(t, classes);

  return (
    <div className={classes.root}>
      {products && <CustomDataGrid className={classes.table} localFilters columns={columns} rows={products} total={products.length} />}
    </div>
  );
}

export default withStyles(ProductsDataGrid, styles)

import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Stack, Tabs, Tab, Grid } from "@mui/material";

import { styles } from "./PriceComparerModal.styles";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";
import { useSelector } from "react-redux";
import { selectPricesComparerLists } from "../../productsPricesSlice";
import PricesDataGrid from "../PricesDataGrid/PricesDataGrid";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import { usePromoter, useUser } from "../../../../common/hooks";
import { currencyFormat } from "../../../../common/utils";
import ScrollBox from "../../../../common/displays/ScrollBox/ScrollBox";

function PriceComparerModal({ classes, open, setOpen, product, price }) {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const comparerLists = useSelector(selectPricesComparerLists);
  const promoter = usePromoter();
  const user = useUser();
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];
  const showPromoterDescription = Boolean(enabledProductFields.includes("promoterDescription") && user?.entity?.type === "P" && product?.promoterDescription);

  return (
    <CustomModal open={open} setOpen={setOpen} size="extralarge" height={700} title={t("productsPrices.PriceComparerModal.modal.title")}>
      <Stack spacing={1}>
        <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          <Grid item>
            <ProductSnip
              image={product?.thumbnail}
              description={showPromoterDescription ? product?.promoterDescription : product?.description}
              gtin={product?.gtin}
              promoterInternalCode={product?.promoterInternalCode}
              ownerName={product?.ownerName}
            />
          </Grid>
          <Grid item>
            <Typography style={{ whiteSpace: "nowrap", margin: "0 16px" }}>{currencyFormat(price?.salePrice)}</Typography>
          </Grid>
        </Grid>
        <Stack>
          <Tabs
            value={tab}
            onChange={(e, newValue) => setTab(newValue)}
          >
            <Tab value={0} label={<Typography variant="inherit" style={{ margin: 0 }}>{t("productsPrices.PriceComparerModal.modal.similarProductsTab")}</Typography>} />
            <Tab value={1} label={<Typography variant="inherit" style={{ margin: 0 }}>{t("productsPrices.PriceComparerModal.modal.subFamilyProductsTab")}</Typography>} />
          </Tabs>

            <ScrollBox style={{ height: 510 }}>
              {tab === 0 &&
                <div>
                  {comparerLists?.similarProducts?.length > 0 ?
                    <PricesDataGrid
                      data={comparerLists.similarProducts}
                      totalRows={comparerLists.similarProducts?.length}
                      comparerView
                    /> :
                    <Typography variant="inherit" className={classes.empty}>{t("productsPrices.PriceComparerModal.modal.similarProductsEmpty")}</Typography>
                  }
                </div>
              }
              {tab === 1 &&
                <div>
                  {comparerLists?.subFamilyProducts?.length > 0 ?
                    <PricesDataGrid
                      data={comparerLists.subFamilyProducts}
                      totalRows={comparerLists.subFamilyProducts?.length}
                      comparerView
                    /> :
                    <Typography variant="inherit" className={classes.empty}>{t("productsPrices.PriceComparerModal.modal.subFamilyProductsEmpty")}</Typography>
                  }
                </div>
              }
            </ScrollBox>
        </Stack>
      </Stack>
    </CustomModal>
  );
}

export default withStyles(PriceComparerModal, styles);
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { useTranslation } from "react-i18next";
import { Paper, Grid, Typography, Button } from "@mui/material";
import { withStyles } from "tss-react/mui";
import { styles } from "./CostsForm.styles";

import { tenseFlowFormFields, crossDockingFormFields, formSchema } from "./CostsForm.schema";
import { getOperatorCost, selectCost, defineOrderCost } from "../operatorSlice.calls";
import { entityGet, selectEntityDetails } from "../../entities/entitiesSlice";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import TransportTableField from "./components/TransportTableField";
import Form from "../../../common/forms/Form";
import Page from "../../../common/displays/Page/Page";

function CostsForm({ classes }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const cost = useSelector(selectCost);
  const entity = useSelector(selectEntityDetails);
  const dispatch = useDispatch();

  useFirstLoad(() => {
    dispatch(getOperatorCost(id));
    if (entity?.entityId !== id) {
      dispatch(entityGet(id));
    }
  });

  const onSubmit = async (values) => {
    dispatch(defineOrderCost(id, values));
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        schema={formSchema(t)}
        initialValues={cost}
        render={({ submitting, values }) => {
          const states = [
            ...new Set([
              ...Object.keys(values?.transport?.weight || {}),
              ...Object.keys(values?.transport?.pallet || {}),
            ]),
          ];

          return (
            <Page
              permission="entities.actions.entity.update"
              header={<PageTitle
                title={entity?.name}
                onBack={() => navigate(`/entities/${id}`)}
                actions={
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                        {t("operator.CostsForm.form.btnSave")}
                      </Button>
                    </Grid>
                  </Grid>
                }
              />}>

              <Paper className={classes.paper} elevation={0}>
                <Typography variant="h3">{t("operator.CostsForm.form.tenseFlow")}</Typography>
                <Grid container alignItems="flex-start" spacing={2}>
                  {tenseFlowFormFields(t).map((item, idx) => (
                    <Grid item xs={item.size} key={idx}>
                      {item.field}
                    </Grid>
                  ))}
                </Grid>
              </Paper>
              <Paper className={classes.paper} elevation={0}>
                <Typography variant="h3">{t("operator.CostsForm.form.crossDocking")}</Typography>
                <Grid container alignItems="flex-start" spacing={2}>
                  {crossDockingFormFields(t).map((item, idx) => (
                    <Grid item xs={item.size} key={idx}>
                      {item.field}
                    </Grid>
                  ))}
                </Grid>
              </Paper>
              <Paper className={classes.paper} elevation={0}>
                <Typography variant="h3">{t("operator.CostsForm.form.transport")}</Typography>
                <TransportTableField
                  id="transport.weight"
                  label={t("operator.CostsForm.form.weight")}
                  adornment="Kg"
                  states={states}
                  minDiff={0.01}
                />
                <br />
                <br />
                <TransportTableField
                  id="transport.pallet"
                  states={states}
                  label={t("operator.CostsForm.form.pallet")}
                  minDiff={1}
                />
              </Paper>
            </Page>
          );
        }}
      />
    </div >
  );
}

export default withStyles(CostsForm, styles);

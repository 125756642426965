import {dateTimeFormatWithMilliSeconds} from "../../../../common/utils/formats";

export function listColumns(t, classes) {

  return [

    {
      field: "actionDate",
      headerName: t("business.process.processHistoryList.columns_actionDate"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.actionDate));
      },
    }, {
      field: "actionUser",
      headerName: t("business.process.processHistoryList.columns_actionUser"),
      align: "left",
      headerAlign: "left",
    },{
      field: "assignedUsers",
      headerName: t("business.process.processHistoryList.columns_assignedUsers"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v?.assignedUsers?.map((value) => {
          return value+", "
        });
      },
    },{
      field: "businessId",
      headerName: t("business.process.processHistoryList.columns_businessId"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v?.businessId?.map((value) => {
          return value+", "
        });
      },
    },{
      field: "comment",
      headerName: t("business.process.processHistoryList.columns_comment"),
      align: "left",
      headerAlign: "left",
    },{
      field: "state",
      headerName: t("business.process.processHistoryList.columns_state"),
      align: "left",
      headerAlign: "left",
    },{
      field: "status",
      headerName: t("business.process.processHistoryList.columns_status"),
      align: "left",
      headerAlign: "left",
    }
  ];
}

import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../app/coreSlice";
import { extractPermissionValue } from "../utils";

export function useUser() {
  let user = { ...useSelector(selectLoggedUser) };

  if (!user.name && user.username) {
    user.name = user.username;
  }
  if (Object.keys(user).length > 0) {
    return {
      ...user,
      hasPermission: (permission) => {
        if (user?.roleType === "A") {
          return true;
        }
        let rootKey = permission.split('.')[0];
        return user?.rolePermission?.[rootKey]?.available && Boolean(extractPermissionValue(user?.rolePermission || {}, permission))
      },
      permissionFilter: (filterKey) => {
        let rootKey = filterKey.split('.')[0];
        return user?.rolePermission?.[rootKey]?.available && extractPermissionValue(user?.rolePermission || {}, filterKey)
      },
      isPromoter: user?.entity?.type === "P"
    };
  }
  return null;
}

const orders = {
  flowsMessages: {
    success: {
      ORDER_CREATE: "Order created successfully!",
      ORDER_UPDATE: "Order updated successfully!",
      ORDER_CONFIRMATION: "Order confirmed successfully!",
      ORDER_ACCEPT: "Order accepted successfully!",
      ORDER_DISPATCH: "Order dispatched successfully!",
      ORDER_RECEPTION: "Order set received successfully!",
      ORDER_IN_TRANSIT: "Order set in transit successfully!",
      ORDER_DELIVERY: "Order set delivered successfully!",
      ORDER_CANCEL: "Order canceled successfully!",
      ORDER_ADD_DOCUMENT: "Attach saved successfully!"
    },
    error: {
      GENERIC: "Something went wrong",
    },
  },
  OrdersSummary: {
    header: {
      title: "Orders",
    },
    metricsActive: {
      label: "Active",
      valueLabel: "Orders",
      moreInfo: "more info...",
    },
    metricsOngoing: {
      label: "Ongoing",
      valueLabel: "Orders",
      moreInfo: "more info...",
    },
    metricsCancelled: {
      label: "Cancelled",
      valueLabel: "Orders",
      moreInfo: "more info...",
    },
  },
  OrderProductsDataGrid: {
    columns: {
      description: "Product",
      quantity: "Order",
      price: "Price",
      tax: "VAT",
      total: "Total",
      dispatchedQuantity: "Dispatched"
    },
    tooltip: {
      dispatchedQuantity: "Products dispatched:",
      deliveredQuantity: "Products delivered:"
    },
    footer: {
      subtotal: "Total líquido",
      ivatotal: "Total IVA",
      total: "Total",
    },
  },
  AggregatedOrderCommentsDataGrid: {
    columns: {
      orderId: "Order Id",
      comment: "Comment"
    }
  },
  VolumesModal: {
    modal: {
      volumesBtn: "Volumes ({{length}})",
      title: "Volumes",
      closeBtn: "Finish",
      code: "Code",
      weight: "Weight",
      full: "Full",
      addVolumeBtn: "Add Volume",
    },
  },
  OrdersCreate: {
    header: {
      title: "New Order",
      filterBtn: "Filter",
    },
    columns: {
      orderId: "Order",
      description: "Product",
      quantity: "Order",
      quantityOffer: "(+ {{offer}} for free)",
      price: "Price",
      tax: "VAT",
      total: "Total",
      offer: "Offer"
    },
  },
  PriceSelector: {
    type: {
      S: "Table",
      C: "Custom",
      CMP: "Campaign",
      G: "Group",
    },
    typeShort: {
      S: "TBL",
      C: "CST",
      CMP: "CMP",
      G: "GRP",
    },
  },
  DeliveryDayAndAddressForm: {
    form: {
      deliveryDay: "Select the delivery day",
      address: "Select the delivery address",
      requiredDeliveryDay: "Delivery day is required",
      requiredAddress: "Delivery address is required",
      deliveryDayDescription: "Delivery",
      submitBtn: "Next",
      importBtn: "Import",
      cancelBtn: "Cancel",
      orderBy: "Order by",
      minQuantity: "Min quantity:",
      location: "Location",
      comments: "Comments"
    },
  },
  ImportOrder: {
    header: {
      title: "Import",
      results: "Results",
      template: "Template",
    },
    template: {
      order: "Download Order Template",
    },
    form: {
      type: "Import Type",
      upload: "Upload Order File",
      uploadInput: "Upload",
      importBtn: "Import",
      requiredAtLeastOneFile: "Upload at least one file",
    },
    column: {
      lineId: "File Line",
      success: "Status",
      statusTrue: "Success",
      statusFalse: "Fail",
      message: "Message",
    },
  },
  OrdersList: {
    header: {
      title: "Orders",
      info: "rows",
    },
    columns: {
      id: "ID",
      cutOffType: "CutOff Type",
      address: "Deliver Address",
      numberOfProducts: "Number of Products",
      price: "Price",
      status: "Status",
    },
    actions: {
      reportIncident: "Report Incident",
    },
  },
  OrderDetails: {
    header: {
      title: "Order N.",
      editBtn: "Edit",
      copyBtn: "Copy",
      createTime: "Created at",
      deliverTo: "Deliver To",
      cutoffType: "CutOff",
      confirmBtn: "Confirm",
      status: "Status",
      confirmDeliveryBtn: "Confirm Delivery",
      confirmDeliveryModalPallets: "Number of pallets",
      confirmDeliveryModalBtn: "Confirm",
      deliveryBtn: "Delivery",
      comments: "Comments",
    },
    confirmCancelOrder: {
      button: "Cancel",
      title: "Cancel Order",
      description: "Are you sure?",
      ok: "Ok",
      cancel: "Back",
    },
  },
  AggregatedOrders: {
    header: {
      title: "Aggregated Orders",
      subtitle: "Aggregated Order N.",
      filterBtn: "Filter",
      rowsInfo: "orders",
    },
    columns: {
      id: "Code",
      numberOfOrders: "Orders",
      address: "Deliver to:",
      numberOfProducts: "Products",
      cutOffTime: "Cut-Off",
      price: "Total",
      status: "Status",
    },
    filter: {
      status: "Status",
      startDate: "Start Date",
      endDate: "End Date",
    },
    actions: {
      acceptAll: "Accept All",
    },
  },
  AggregatedDetails: {
    header: {
      description: "Aggregate Order by Client ",
      title: "{{description}} - Address: {{address}}",
      cutoff: "Cutoff",
      dispatchBtn: "Dispatch",
      acceptBtn: "Accept",
      finishAccept: "Finished accept",
      finishAcceptNote: "This check will finish the acceptance, you will not be able to accept more products later"
    },
    form: {
      document: "Document",
      documentDate: "Document Date",
      transportDocument: "Transport Document",
      transportDocumentDate: "Transport Document Date",
      documentRequired: "Document is required",
      documentDateRequired: "Document date is required",
      transportDocumentRequired: "Transport Document is required",
      transportDocumentDateRequired: "Transport Document date is required",
      requiredVolumes: "Volumes are required",
      requiredBatch: "Products batchs are required",
      requiredBatchNumber: "The batch number is required",
      requiredBatchExpire: "The expiration date is required",
      requiredBatchWeight: "The weight is required",
    },
    grid: {
      addBatch: "Add Batch",
      batch: "Batch Number",
      expirationDate: "Expiration Date",
      weight: "Weight",
      quantity: "Quantity",
    },
    aggregatedInfo: {
      title: "Aggregated Order Info",
      id: "Aggregated ID",
      numberOfOrders: "# Orders",
      numberOfProducts: "# Products",
      status: "Status",
      price: "Price",
      comments: "Comments",
    },
    orderInfo: {
      title: "Order Info",
    },
  },
  CampaignTooltip: {
    title: {
      outdated: "outdated",
    },
    promotion: {
      description: "{{quantity}} or more, will be {{price}}",
    },
    percentage: {
      description: "{{percentage}}% discount",
    },
    merchandise: {
      description: "Buy {{buy}} get {{offer}} free",
    },
    bundle: {
      buy: "Buy",
      offer: "Offer",
    },
  },
  ProductsMosaic: {
    card: {
      offer: "Offer",
      quantity: "Quantity",
      price: "Price",
    },
  },
  OperatorOrdersList: {
    header: {
      title: "Operator Orders",
      rowsInfo: "orders",
    },
    columns: {
      id: "ID",
      numberOfProducts: "# Products",
      cutOffTime: "Cutoff",
      price: "Price",
      status: "Status",
    },
  },
  OperatorOrderDetails: {
    header: {
      title: "Order",
      receptionBtn: "Set Received",
      intransitBtn: "Set In transit",
      deliveryBtn: "Set Delivered",
    },
    columns: {
      id: "ID",
      code: "Code",
      full: "Is full",
      fullyes: "Yes",
      fullno: "No",
      weight: "Weight",
      description: "Description",
      quantity: "Quantity",
    },
    form: {
      finished: "Complete",
      finishedTooltip: "Not included products will no longer be avaible",
      document: "Document",
      documentDate: "Document Date",
      documentRequired: "Document is required",
      documentDateRequired: "Document date is required",
      requiredVolumes: "Volumes are required",
      requiredBatch: "Products batchs are required",
      totalPalletsFull: "Full Pallets",
      totalPalletsNotFull: "Not Full Pallets",
    },
  },
  OrdersMissingProducts: {
    header: {
      title: "Missing Products",
      rowsInfo: "orders",
      filterBtn: "Filter",
    },
    columns: {
      description: "Product",
      quantity: "Quantity",
      price: "Price",
      tax: "Tax",
      total: "Total",
      orderSellerId: "Code",
      numberOfProducts: "Number of Products",
      seller: "Seller",
      buyer: "Buyer",
      address: "Address",
      orderPrice: "Price",
      dispatchBtn: "Dispatch",
    },
    filter: {
      seller: "Seller",
      orderStatus: "Status",
    },
  },
  OrderStatusDetails: {
    navigation: {
      accept: "Go to Accept",
      dispatch: "Go to Dispatch"
    }
  },
  OrderAttachments: {
    card: {
      title: "Attachments ({{count}})",
      btnAdd: "Attach new file",
    },
    modal: {
      title: "Attach new file",
      btnSave: "Save"
    },
    form: {
      type: "Document type",
      file: "File"
    }
  }
};

export default orders;

import { setPromoterDetails, setPromoterDetailsHighlightsImages } from "../../coreSlice";
import { processHomeHighlights } from "../_auxiliars/processHomeHighLights";

export const promoterSubscriptionHandlers = async (body, dispatch, state) => {
  const { type, subType, processMessage } = body;
  if (process.env.REACT_APP_ENV !== "production") {
    console.log("%c <<< PROMOTER SUB [%s/%s] %o", "color:#9302ed; font-weight:bold", type, subType, processMessage);
  }

  switch (type + "/" + subType) {
    case "PROMOTER/DETAILS":
      let promoterDetails = {
        ...processMessage,
        configs: {
          ...processMessage.configs,
          functional: {
            ...processMessage.configs.functional,
            home: {
              ...processMessage.configs.functional?.home,
            },
          }
        },
      };
      dispatch(setPromoterDetails({ ...promoterDetails, refData: state.core.promoterDetails?.refData }));
      // process highLightImages for home
      processHomeHighlights(setPromoterDetailsHighlightsImages, dispatch, promoterDetails);
      break;
    case "PROMOTER/REF_DATA":
      dispatch(setPromoterDetails({ ...state.core.promoterDetails, refData: processMessage }));
      break;
    default:
      break;
  }
};

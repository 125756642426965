import React from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Stack, Typography } from "@mui/material";

import { styles } from "./PasswordChangeForm.styles";
import { formFields, formSchema } from "./PasswordChangeForm.schema";

import { Form, FormFields } from "../../../../common/forms"
import { userChangePassword } from "../../usersSlice";

function PasswordChangeForm({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    dispatch(userChangePassword(values))
  };

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmit}
        schema={formSchema({ t })}
        initialValues={{}}
        render={() => {
          return (
            <Stack spacing={2}>
              <Typography variant="h3">{t("users.PasswordChangeForm.form.title")}</Typography>
              <div>
                <FormFields fields={formFields({ t })} />
              </div>
              <Button type="submit" variant="contained" >{t("users.PasswordChangeForm.form.btnSubmit")}</Button>
            </Stack>
          );
        }}
      />

    </div>
  );
}

export default withStyles(PasswordChangeForm, styles);
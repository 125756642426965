import React, { useMemo, useState } from "react";
import { withStyles } from "tss-react/mui";
import { Stack, TextField, Typography } from "@mui/material";

import { styles } from "./MultiLangTextInput.styles";

function MultiLangTextInput({
  classes,
  id,
  className,
  label,
  value,
  onChange,
  disabled,
  type = "text",
  error,
  required,
  variant = "outlined",
  rows = 0,
  maxLength,
  languages,
  defaultLanguage
}) {
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);
  const [hoverLanguage, setHoverLanguage] = useState();
  const selectedValue = useMemo(() => {
    return value?.[hoverLanguage || selectedLanguage || defaultLanguage] || "";
    // eslint-disable-next-line
  }, [value, hoverLanguage, selectedLanguage]);

  const onChangeHandler = (e, currentValue) => {
    let val = maxLength ? e.target.value.slice(0, maxLength) : e.target.value;
    let nValue = {
      ...(currentValue || {}),
      [selectedLanguage]: val
    }
    onChange && onChange(nValue);
  }

  return (
    <div className={classes.root + " " + className}>
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="flex-end" >
        {variant === "outlined" && label && (
          <Typography
            style={{ marginBottom: 8, marginTop: 8 }}
            component="span"
            variant="caption"
            htmlFor={id}
            color="textPrimary"
            className={disabled ? classes.disabledLabel : ""}
          >
            {label}
            {required && <span> *</span>}
          </Typography>
        )}
        <Stack direction="row">
          {(languages || []).map(lang => {
            return (
              <div key={lang} onMouseEnter={() => setHoverLanguage(lang)} onMouseLeave={() => setHoverLanguage()}>
                <Typography className={classes.lang + " " + (value?.[lang] ? classes.hasValue : "") + " " + (lang === selectedLanguage ? classes.selected : "")} onClick={() => setSelectedLanguage(lang)}>{lang}</Typography>
              </div>
            )
          })}
        </Stack>
      </Stack>
      <TextField
        id={id}
        fullWidth
        type={type}
        className={classes.input + (disabled ? " " + classes.disabledInput : "")}
        error={error}
        label={variant === "outlined" ? undefined : label + (required ? " *" : "")}
        onChange={(e) => onChangeHandler(e, value)}
        value={selectedValue}
        disabled={Boolean(disabled)}
        variant={variant}
        onFocus={(e) => e.target && e.target.select && e.target.select()}
        size="small"
        multiline={rows > 0}
        rows={rows}
      />
    </div>
  );
}

export default withStyles(MultiLangTextInput, styles);

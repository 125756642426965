

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess, setRedirect } from "../../../app/coreSlice";

const placeCreateCall = async (
  dispatch,
  { name, type, gln, attributes, addressId, contactId, geoCoordinates, parentId, entityId }
) => {
  let rh = requestHelper(dispatch, "PLACE_CREATE");
  try {
    let result = await call({
      type: "PLACE",
      subType: "CREATE",
      request: {
        name, type, gln, attributes, addressId, contactId, geoCoordinates, parentId, entityId
      },
    });

    setTimeout(() => {
      if (result) {
        dispatch(setRedirect("/places"));
        dispatch(setSuccess("places", "PLACE_CREATE"));
      }
    }, 300);
    
  } catch (ex) {
    rh.error("places", ex);
  }
  rh.close();
};

export default placeCreateCall;


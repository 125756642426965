import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { Button, Grid, Paper, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";

import { styles } from "./PromoterConfigs.styles";
import { formSchema } from "./PromoterConfigs.schema";
import { generalFields } from "./fields/general";
import { contactFields } from "./fields/contact";
import { cutOffFields } from "./fields/cutoff";
import { documentFields } from "./fields/document";
import { homeFields } from "./fields/home";
import { orderFields } from "./fields/order";
import { priceFields } from "./fields/price";
import { productFields } from "./fields/product";
import { notificationsFields } from "./fields/notifications";
import { adminFields } from "./fields/admin";
import { entityFields } from "./fields/entity";
import { taxesFields } from "./fields/taxes";
import {
  getCurrencyTypeOptions,
  getCustomFormsOptions,
  getCutOffTypeOptions,
  getEntityPermissionOptions,
  getEntityTypeOptions,
  getLanguageTypeOptions,
  getLanguages,
  getListProductColumnsOptions,
  getListProductFieldsOptions,
  getOperationTypeOptions,
  getPriceTypeOptions,
  getRoleTypeOptions,
  getSectorOptions,
  getServiceTypes,
  getTaxGroupOptions,
  getTrafficTypeOptions,
  getTransportOptions,
  getWeekDayOptions,
  getResourceTypeOptions, getPlaceTypeOptions, getListEntityColumnsOptions
} from "./PromoterConfigs.options";

import { promoterConfigs, promoterListCustomForms, promoterUpdate, selectConfigs, selectCustomFormsList } from "../promoterSlice";
import { Form, FormFields } from "../../../common/forms";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { useFirstLoad, useNavigate, usePromoter, useUser } from "../../../common/hooks";
import Page from "../../../common/displays/Page/Page";
import Permission from "../../../app/components/Permission";

function PromoterConfigs({ classes }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const configs = useSelector(selectConfigs);
  const user = useUser();
  const promoter = usePromoter();
  const navigate = useNavigate();
  const [tab, setTab] = useState("general");
  const customForms = useSelector(selectCustomFormsList);

  const formOptions = useMemo(() => {
    return {
      languageType: getLanguageTypeOptions(t, promoter),
      currencyType: getCurrencyTypeOptions(t),
      entityType: getEntityTypeOptions(t),
      roleType: getRoleTypeOptions(t),
      operationType: getOperationTypeOptions(t),
      trafficType: getTrafficTypeOptions(t),
      transport: getTransportOptions(t),
      weekDay: getWeekDayOptions(t),
      entityPermission: getEntityPermissionOptions(t),
      priceType: getPriceTypeOptions(t),
      cutOffType: getCutOffTypeOptions(t),
      sector: getSectorOptions(t, promoter?.refData),
      listProductColumns: getListProductColumnsOptions(t),
      listProductFields: getListProductFieldsOptions(t),
      listEntityColumns: getListEntityColumnsOptions(t),
      taxGroup: getTaxGroupOptions(t, promoter?.refData),
      languages: getLanguages(t),
      serviceTypes: getServiceTypes(t),
      customForms: getCustomFormsOptions(customForms),
      resourceType: getResourceTypeOptions(t, promoter?.refData),
      placeType: getPlaceTypeOptions(t, promoter?.refData),
    }
    // eslint-disable-next-line
  }, [promoter, customForms, i18n.language]);

  useFirstLoad(() => {
    dispatch(promoterConfigs());
    dispatch(promoterListCustomForms());
  });

  const onSubmit = (values) => {
    dispatch(promoterUpdate(values));
  };

  const generalFieldsUI = useMemo(() => {
    return <FormFields fields={generalFields({ t, user, formOptions })} />
    // eslint-disable-next-line
  }, [formOptions, user]);

  const contactFieldsUI = useMemo(() => {
    return <FormFields fields={contactFields({ t, user })} />
    // eslint-disable-next-line
  }, [formOptions, user]);

  const orderFieldsUI = useMemo(() => {
    return <FormFields fields={orderFields({ t, user, refData: promoter?.refData, formOptions })} />
    // eslint-disable-next-line
  }, [formOptions, user]);

  const priceFieldsUI = useMemo(() => {
    return <FormFields fields={priceFields({ t, user })} />
    // eslint-disable-next-line
  }, [formOptions, user]);

  const productFieldsUI = useMemo(() => {
    return <FormFields fields={productFields({ t, user, refData: promoter?.refData, formOptions })} />
    // eslint-disable-next-line
  }, [formOptions, user]);

  const documentFieldsUI = useMemo(() => {
    return <FormFields fields={documentFields({ t, user, refData: promoter?.refData })} />
    // eslint-disable-next-line
  }, [formOptions, user]);

  const taxesFieldsUI = useMemo(() => {
    return <FormFields fields={taxesFields({ t, user, formOptions })} />
    // eslint-disable-next-line
  }, [formOptions, user]);

  const entityFieldsUI = useMemo(() => {
    return <FormFields fields={entityFields({ t, user, refData: promoter?.refData, formOptions })} />
    // eslint-disable-next-line
  }, [formOptions, user])

  if (!configs) {
    return null;
  }

  return (
    <div>
      <Form
        schema={formSchema({ t, user })}
        initialValues={{
          ...configs,
          admin: {
            ...configs.admin,
            baseUrl: configs.admin?.baseUrl || "https://" + window.location.host
          }
        }}
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        render={({ submitting, values }) => {
          return (
            <Page
              permission="promoter.actions.configs.list"
              header={<PageTitle
                title={t("promoter.PromoterConfigs.form.title")}
                actions={
                  <Grid container direction="row" spacing={2}>
                    <Permission code="promoter.actions.auditRetention.list">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          onClick={() => navigate("/promoter/configs/retention")}
                          disabled={submitting}
                        >
                          {t("promoter.PromoterConfigs.form.retentionBtn")}
                        </Button>
                      </Grid>
                    </Permission>
                    <Permission code="promoter.actions.location.list">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          onClick={() => navigate("/promoter/configs/locations")}
                          disabled={submitting}
                        >
                          {t("promoter.PromoterConfigs.form.locationsBtn")}
                        </Button>
                      </Grid>
                    </Permission>
                    <Permission code="promoter.actions.vat.list">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          onClick={() => navigate("/promoter/configs/vats")}
                          disabled={submitting}
                        >
                          {t("promoter.PromoterConfigs.form.taxsBtn")}
                        </Button>
                      </Grid>
                    </Permission>
                    <Permission code="promoter.actions.configs.update">
                      <Grid item>
                        <Button variant="contained" color="primary" type="submit" size="large" disabled={submitting}>
                          {t("promoter.PromoterConfigs.form.submitBtn")}
                        </Button>
                      </Grid>
                    </Permission>
                  </Grid>
                }
              />}>

              <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                <Grid item xs={2}>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tab}
                    onChange={(e, i) => setTab(i)}
                    className={classes.tabs}
                  >
                    {user.hasPermission("promoter.actions.configs.general") && <Tab label={t("promoter.PromoterConfigs.tabs.general")} value="general" />}
                    {user.hasPermission("promoter.actions.configs.contact") && <Tab label={t("promoter.PromoterConfigs.tabs.contact")} value="contact" />}
                    {user.hasPermission("promoter.actions.configs.cutoff") && <Tab label={t("promoter.PromoterConfigs.tabs.cutoff")} value="cutoff" />}
                    {user.hasPermission("promoter.actions.configs.entity") && <Tab label={t("promoter.PromoterConfigs.tabs.entity")} value="entity" />}
                    {user.hasPermission("promoter.actions.configs.order") && <Tab label={t("promoter.PromoterConfigs.tabs.order")} value="order" />}
                    {user.hasPermission("promoter.actions.configs.price") && <Tab label={t("promoter.PromoterConfigs.tabs.price")} value="price" />}
                    {user.hasPermission("promoter.actions.configs.product") && <Tab label={t("promoter.PromoterConfigs.tabs.product")} value="product" />}
                    {user.hasPermission("promoter.actions.configs.taxes") && <Tab label={t("promoter.PromoterConfigs.tabs.taxes")} value="taxes" />}
                    {user.hasPermission("promoter.actions.configs.home") && <Tab label={t("promoter.PromoterConfigs.tabs.home")} value="home" />}
                    {user.hasPermission("promoter.actions.configs.document") && <Tab label={t("promoter.PromoterConfigs.tabs.document")} value="document" />}
                    {user.hasPermission("promoter.actions.configs.notification") && <Tab label={t("promoter.PromoterConfigs.tabs.notification")} value="notification" />}
                    {user.hasPermission("promoter.actions.configs.admin") && <Tab label={t("promoter.PromoterConfigs.tabs.admin")} value="admin" />}
                  </Tabs>
                </Grid>
                <Grid item xs={10}>
                  <Paper className={classes.paper} elevation={0}>
                    <div hidden={tab !== "general"}>
                      {generalFieldsUI}
                    </div>
                    <div hidden={tab !== "contact"}>
                      {contactFieldsUI}
                    </div>
                    <div hidden={tab !== "cutoff"}>
                      <FormFields fields={cutOffFields({ t, user, values, formOptions })} />
                    </div>
                    <div hidden={tab !== "entity"}>
                      {entityFieldsUI}
                    </div>
                    <div hidden={tab !== "order"}>
                      {orderFieldsUI}
                    </div>
                    <div hidden={tab !== "price"}>
                      {priceFieldsUI}
                    </div>
                    <div hidden={tab !== "product"}>
                      {productFieldsUI}
                    </div>
                    <div hidden={tab !== "taxes"}>
                      {taxesFieldsUI}
                    </div>
                    <div hidden={tab !== "home"}>
                      <FormFields fields={homeFields({ t, user, values })} />
                    </div>
                    <div hidden={tab !== "document"}>
                      {documentFieldsUI}
                    </div>
                    <div hidden={tab !== "notification"}>
                      <FormFields fields={notificationsFields({ t, user, values, formOptions })} />
                    </div>
                    <div hidden={tab !== "admin"}>
                      <FormFields fields={adminFields({ t, user, values, formOptions })} />
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(PromoterConfigs, styles);

import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./EntityPickerDataGrid.styles";
import { listColumns } from "./EntityPickerDataGrid.columns";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import { Grid, Typography } from "@mui/material";

function EntityPickerDataGrid({
  classes,
  entities,
  totalRows,
  localFilters,
  onFilter,
  onLoadMore,
  selectedEntities,
  onEntitiesSelectedChange,
  hideCheckbox,
  style,
  allowBulkSelect,
  allEntities,
  onAllEntitiesChange,
}) {
  const { t } = useTranslation();

  const [bulkSelected, setBulkSelected] = useState(allEntities);

  const onBulkSelectChange = (checked) => {
    setBulkSelected(checked);
    onEntitiesSelectedChange && onEntitiesSelectedChange(checked ? [...entities] : []);
  };

  const columns = listColumns(
    t,
    classes,
    selectedEntities,
    onEntitiesSelectedChange,
    hideCheckbox,
    allowBulkSelect,
    bulkSelected,
    allEntities,
    onBulkSelectChange
  );
  return (
    <div className={classes.root} style={style}>
      {bulkSelected && (
        <Grid container justifyContent="center">
          <Grid item>
            {allEntities ? (
              <Typography
                variant="caption"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onAllEntitiesChange(false);
                  setBulkSelected(false);
                }}
              >
                {t("common.ProductPicker.modal.unselectAll")}
              </Typography>
            ) : (
              <Typography
                variant="caption"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => onAllEntitiesChange(true)}
              >
                {t("common.ProductPicker.modal.selectAll", {
                  selected: selectedEntities.length,
                  total: totalRows,
                })}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
      {entities && (
        <CustomDataGrid
          localFilters={localFilters}
          columns={columns}
          rows={entities}
          onFilter={onFilter}
          onLoadMore={onLoadMore}
          total={totalRows}
        />
      )}
    </div>
  );
}

export default withStyles(EntityPickerDataGrid, styles)

import {requestHelper} from "../../../app/coreSlice";
import {call} from "../../../app/websocket/_sliceCalls/websocket";

const businessGetProcessDetailCall = async ({ setBusinessProcessDetails }, dispatch, { processId }) => {
  let rh = requestHelper(dispatch, "BUSINESS_GET_PROCESS_DETAIL");
  try {
    let result = await call({
      type: "PROCESS",
      subType: "DETAILS",
      request: {
        processId
      },
    });

    if (result && result.details) {
      dispatch(setBusinessProcessDetails(result.details));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default businessGetProcessDetailCall;

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { withStyles } from "tss-react/mui";
// import { Button, Drawer } from "@mui/material";

import { styles } from "./OrdersList.styles";

import OrdersDataGrid from "./OrdersDataGrid/OrdersDataGrid";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { Button, Grid } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import {
  selectOrderList,
  selectLoader,
  selectOrderListTotalRows,
  selectOrderListFilter,
} from "../ordersSlice.calls";
import { orderList } from "../ordersSlice";
import Page from "../../../common/displays/Page/Page";

function OrdersList({ classes }) {
  const { t } = useTranslation();
  const data = useSelector(selectOrderList);
  const filter = useSelector(selectOrderListFilter);
  const loading = useSelector(selectLoader);
  const totalRows = useSelector(selectOrderListTotalRows);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useFirstLoad(() => {
    dispatch(orderList(filter, true));
  });

  const onOrderSelectedHandler = (row) => {
    navigate(`/orders/${row.id}`);
  };

  const onFilterChangeHandler = (v) => {
    dispatch(orderList(v.filter, v.reset));
  };

  const refreshHandler = () => {
    dispatch(orderList(filter, true));
  };

  return (
    <Page
      permission="orders.actions.placed.list"
      header={<PageTitle
        title={t("orders.OrdersList.header.title")}
        info={`${totalRows} ` + t("orders.OrdersList.header.info")}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={refreshHandler}
                disabled={Boolean(loading)}
              >
                <RefreshIcon />
              </Button>
            </Grid>
          </Grid>
        }
      />}>
      <OrdersDataGrid
        data={data}
        filter={filter}
        totalRows={totalRows}
        onRowSelected={onOrderSelectedHandler}
        onFilterChange={onFilterChangeHandler}
      />
    </Page>
  );
}

export default withStyles(OrdersList, styles);

const entities = {
  flowsMessages: {
    success: {
      ENTITY_CREATE: "Entity created successfully!",
      ENTITY_UPDATE_CONFIG: "Entity configurations updated successfully!",
      ENTITY_UPDATE_TAX_ADDRESS: "Entity tax address updated successfully!",
      ENTITY_UPDATE_NAME: "Entity name updated successfully!",
      ENTITY_ADD_CONTACT: "Contact added successfully!",
      ENTITY_UPDATE_CONTACT: "Contact updated successfully!",
      ENTITY_DISABLE_CONTACT: "Contact removed successfully!",
      ENTITY_DISABLE_ADDRESS: "Address removed successfully!",
      ENTITY_DISABLE: "Entity disabled successfully!",
      ENTITY_ENABLE: "Entity enabled successfully!",
      ENTITY_CONFIRM: "Entity confirmed successfully!",
      ENTITY_CREATE_GROUP: "Group created successfully!",
      ENTITY_UPDATE_GROUP: "Group updated successfully!",
      ENTITY_DISABLE_GROUP: "Group removed successfully!",
      PROMOTER_CREATE_CUT_OFF: "Cutoff created successfully!",
      PROMOTER_UPDATE_CUT_OFF: "Cutoof updated successfully!",
      ORDER_CUT_OFF: "Cutoff forced successfully!",
      PROMOTER_ENABLE_CUT_OFF: "Cutoff enabled successfully!",
      PROMOTER_DISABLE_CUT_OFF: "Cutoff disabled successfully!",
      BUSINESS_UNIT_ADDED: "Business Unit added successfully!",
      PROMOTER_ADD_EXTERNAL_ACCESS: "Access created successfully!",
      PROMOTER_DISABLE_EXTERNAL_ACCESS: "Access revoked successfully!",
      USER_DEFINE_EXTERNAL_ACCESS: "New access key defined successfully!",
      ENTITY_CREATE_SECTOR_GROUP: "Group created successfully!",
      ENTITY_UPDATE_SECTOR_GROUP: "Group updated successfully!",
      ENTITY_DELETE_SECTOR_GROUP: "Group removed successfully!",
      ENTITY_ADD_ADDRESS: "Entity address created successfully!",
      ENTITY_UPDATE_ADDRESS: "Entity address updated successfully!",
      ENTITY_PENDING_ENTITY_ACTION_ACCEPTED: "Entity confirmed successfully!",
      ENTITY_ADD_DOCUMENT: "Entity document saved successfully!"
    },
    error: {
      GENERIC: "Something went wrong",
      WORKFLOW_VALIDATION_ERROR: "Invalid Request!",
      USER_ALREADY_EXISTS: "E-mail already in use.",
      CONTACT_ALREADY_EXISTS: "Contact already in use.",
    },
  },
  CreateEntity: {
    form: {
      title: "Create Entity",
      editTitle: "Update Entity",
      dataTitle: "Entity information",
      dataSubtitle: "Complete form with entity information",
      submitBtn: "Create Entity",
      submitEditBtn: "Update Entity",
      taxNumber: "Tax number",
      invalidTaxNumber: "Invalid tax number",
      name: "Company Name",
      address: "Address",
      county: "County",
      locale: "Locale",
      postalCode: "Postal Code",
      country: "Country",
      type: "Entity Type",
      logo: "Logo",
      logoPlaceholder: "Select image",
      website: "Website",
      fax: "Fax",
      phone: "Phone",
      email: "Email",
      invalidEmail: "Email is invalid",
      requiredType: "Entity type is required",
      usersTitle: "Users",
      usersSubtitle: "Add/Remove users",
      usersAddMore: "Add more",
      userFirstName: "First name",
      userLastName: "Last name",
      userRole: "Role",
      userRoleSpecific: "The selected role is used as default role, this user have specific roles that override the default:",
      userEmail: "E-mail",
      userPhone: "Phone",
      userPhoto: "Photo",
      notifications: "Notifications",
      documentation: "Receives documentation",
      userPhotoPlaceholder: "Select image",
      requiredUserWithFullAccess: "At least one user should have Full access",
      requiredUserFirstName: "First name is required",
      requiredUserLastName: "Last name is required",
      requiredUserRole: "role is required",
      requiredUserEmail: "E-mail is required",
      invalidUserEmail: "Invalid E-mail",
      addressTitle: "Address List",
      addressSubtitle: "Add/remove address",
      addressAddMore: "Add more",
      addressName: "Name",
      addressAddress: "Address",
      addressCounty: "County",
      addressLocale: "Locale",
      addressPostalCode: "Postal code",
      addressCountry: "Country",
      addressOperator: "Logistic operator",
      requiredAddressContactId: "Contact is required",
      requiredAddressDescription: "Name is required",
      requiredAddressAddress: "Address is required",
      requiredAddressCounty: "County is required",
      requiredAddressLocale: "Locale is required",
      requiredAddressPostalCode: "PostalCode is required",
      requiredAddressCountry: "Country is required",
      requiredOperatorAddress: "Logistic operator is required",
      requiredAtLeastOneAddress: "At least one adddress is required",
      gln: "GLN",
      requiredGln: "GLN is required",
      onlyDigits: "only digits ",
      specificDigits: "should have exactly {{digits}} digits ",
      atLeastDigits: "should have at least {{digits}} digits ",
      atTopDigits: "should have at top {{digits}} digits ",
      cep: "CEP",
      requiredCep: "CEP is required",
      trafficType: "Traffic Type",
      requiredTrafficType: "Traffic Type is required",
      operationType: "Operation Type",
      requiredOperationType: "Operation Type is required",
      requiredCmpAccumulation: "Campaign Accumulation is required",
      requiredCpAccumulation: "Price Accumulation is required",
      priceDiscount: "Price Discount (%)",
      requiredPriceDiscount: "Price Discount is required",
      number: "You must specify a number",
      fee: "Service Fee",
      frequencyType: "Fee Frequency",
      requiredFeeFrequency: "Fee Frequency is required",
      requiredFeeQuantity: "Service Fee is required",
      requiredFeeType: "Service Fee Type is required",
      businessUnitsTitle: "Business Units",
      businessUnitsSubtitle: "Create independent business units",
      businessUnitsDefaultName: "New Entity",
      businessUnitsAddMore: "Add New",
      businessUnitsRemove: "Remove",
      businessUnitDesc: "Business Unit name",
      requiredBusinessUnitDesc: "Business Unit name is required",
      businessUnit: "Business Unit",
      feeType: "Fee Type",
      addressContact: "Contact",
      addressState: "State",
      requiredAddressState: "State is required",
      requiredAddressContact: "Contact is required",
      btnCopyAddress: "Copy entity address",
      cmpAccumulation: "Campaign Accumulation",
      cpAccumulation: "Price Accumulation",
      addAddressForm: "Add Address",
      addAddressBtn: "Add",
      addUserForm: "Add User",
      addUserBtn: "Add",
      sector: "Department",
      updateUserBtn: "Update",
      invalidPhone: "Invalid phone number",
      requiredName: "Company name is required",
      taxNumberCheck: "Check",
      requiredAddress: "Address is required",
      requiredLocale: "Locale is required",
      requiredPostalCode: "PostalCode is required",
      requiredCountry: "Country is required",
      updateAddressBtn: "Update",
      promoterInternalCode: "Promoter Internal Code"
    },
  },
  Entities: {
    common: {
      title: "Entities",
      rowsInfo: " entities",
      filterBtn: "Filter",
      addBtn: "Add Entity",
    },
    columns: {
      name: "Name",
      type: "Type",
      taxNumber: "Tax Number",
      gln: "GLN",
      promoterInternalCode: "Promoter Internal Code",
      phone: "Phone",
      status: "Status",
    },
    filter: {
      entityType: "Entity Type",
    },
    status: {
      active: "Active",
      pending: "Pending",
      disabled: "Disabled",
    },
    details: {
      title: "Entity details",
      btnEdit: "Edit",
      taxNumber: "Tax number",
      operatorCosts: "Operator Costs",
      phone: "Phone",
      website: "Website",
      address: "Address",
      fax: "Fax",
      email: "Email",
      units: "Business Units",
      cep: "CEP",
      operationType: "Operation Type",
      gln: "GLN",
      trafficType: "Traffic Type",
      cmpAccumulation: "Campaign Accumulation",
      cpAccumulation: "Price Accumulation",
      feeQuantity: "Fee",
      feeFrequencyType: "Frequency",
      feeType: "Type",
      confirmBtn: "Confirm",
      pendingValidation: "This entity is in a pending process for validation",
      promoterInternalCode: "Promoter Internal Code"
    },
    drawer: {
      enableBtn: "Enable",
      detailsBtn: "Details",
      editBtn: "Edit",
    },
    confirmDisable: {
      button: "Disable",
      title: "Disable Entity",
      description: "Are you sure you want to do it?",
      ok: "ok",
      cancel: "cancel",
    },
    confirmDeleteAddress: {
      button: "Delete",
      title: "Delete Address",
      description: "Are you sure you want to do it?",
      ok: "ok",
      cancel: "cancel",
    },
    confirmDeleteContact: {
      button: "Delete",
      title: "Delete Contact",
      description: "Are you sure you want to do it?",
      ok: "ok",
      cancel: "cancel",
    },
    contacts: {
      title: "Contacts",
      btnAdd: "Add",
      btnMore: "More",
    },
    addresses: {
      title: "Addresses",
      btnMore: "More",
      btnAdd: "Add",
      defaultName: "Address",
    },
    groups: {
      title: "Groups",
      btnMore: "More",
    },
  },
  CutOffs: {
    common: {
      addBtn: "Add Cut Off",
      scheduleCutOffBtn: "Schedule Cut Off",
      title: "Cut Offs",
      filterBtn: "Filter",
      totalRows: " rows",
    },
    columns: {
      entityName: "Entity Name",
      active: "Active",
      lastCutOff: "Last Cut Off",
      nextCutOff: "Next Cut Off",
      editBtn: "Edit",
      enableBtn: "Enable",
      disableBtn: "Disable",
      forceCutOffBtn: "Force CutOff",
      cutOffType: "Type",
    },
    filter: {
      entityName: "Entity Name",
      activeLabel: "Active",
      optionAll: "All",
      optionActive: "Active",
      optionInactive: "Inactive",
    },
    dateType: {
      ANY: "ANY",
      0: "ANY",
      MON: "MONDAY",
      1: "MONDAY",
      TUE: "TUESDAY",
      2: "TUESDAY",
      WED: "WEDNESDAY",
      3: "WEDNESDAY",
      THU: "THURSDAY",
      4: "THURSDAY",
      FRI: "FRIDAY",
      5: "FRIDAY",
      SAT: "SATURDAY",
      6: "SATURDAY",
      SUN: "SUNDAY",
      7: "SUNDAY",
    },
    frequency: {
      dayOfWeek: "Day Of Week",
      dayOfMonth: "Day Of Month",
    },
    active: {
      true: "Active",
      false: "Disabled",
    },
    details: {
      active: "Status",
      dayOfMonth: "Day Of Month",
      dayOfWeek: "Day Of Week",
      time: "Time",
      cutOffType: "CutOff Type",
      deliveryDay: "Delivery Day",
      lastCutOff: "Last CutOff",
      nextCutOff: "Next CutOff",
    },
    drawer: {
      enableBtn: "Enable",
      detailsBtn: "Details",
    },
    confirmDisable: {
      button: "Disable",
      title: "Disable CutOff",
      description: "Are you sure you want to do it?",
      ok: "ok",
      cancel: "cancel",
    },
    confirmForceCutOff: {
      button: "Force CutOff",
      title: "Force CutOff",
      description: "Are you sure you want to do it?",
      ok: "ok",
      cancel: "cancel",
      form_entity: "Entity",
      entity_ALL: "All Entities",
      form_type: "CutOff Type",
      form_requiredCutOffType: "CutOff Type is required",
    },
    scheduleCutOff: {
      title: "Schedule CutOff",
      description: "Are you sure you want to schedule it?",
      ok: "ok",
      cancel: "cancel",
      entity: "Entity",
      type: "Type",
      frequency: "Frequency",
      dayOfMonth: "Day Of Month",
      dayOfWeek: "Day Of Week",
      time: "Time",
      entity_ALL: "All Entities",
      form_type: "CutOff Type",
      form_requiredCutOffType: "CutOff Type is required",
      form_requiredDeliveryDay: "Delivery Day is required",
      form_requiredFrequency: "Frequency is required",
      form_requiredTime: "Time is required",
    },
  },
  CutOffCreate: {
    header: {
      title: "Create new Cut Off",
      editTitle: "Update Cut Off: ",
      btnSave: "Save",
    },
    form: {
      dataTitle: "Cut Off information",
      dataSubtitle: "Complete form with cut off information",
      submitBtn: "Submit",
      entity: "Entity",
      description: "Description",
      frequency: "Frequency",
      type: "Type",
      dayOfMonth: "Day Of Month",
      dayOfWeek: "Day Of Week",
      time: "Time",
      deliveryDay: "Delivery Day",
      requiredCutOffType: "Type is required",
      requiredDeliveryDay: "Delivery Day is required",
      requiredFrequency: "Frequency is required",
      requiredHour: "Hour is required",
      requiredMinute: "Minute is required",
    },
  },
  UsersList: {
    columns: {
      name: "Name",
      email: "Email",
      phone: "Phone",
      edit: "Edit",
      sector: "Department",
      editWarn: "This contact is marked to be disabled, you can't edit it",
    },
    details: {
      firstName: "firstName",
      lastName: "lastName",
      email: "email",
      phoneNumber: "phoneNumber",
      roleType: "roleType",
      faxNumber: "faxNumber",
      mobileNumber: "mobileNumber",
      website: "website",
      notify: "notify",
      sendDoc: "sendDoc",
    },
  },
  AddressesList: {
    columns: {
      description: "Name",
      county: "County",
      locale: "Locale",
      postalCode: "Postal Code",
      country: "Country",
      state: "State",
      confirmed: "Confirmed",
      editBtn: "Edit"
    },
    details: {
      description: "description",
      address: "address",
      county: "county",
      locale: "locale",
      country: "country",
      postalCode: "postalCode",
      latitude: "latitude",
      longitude: "longitude",
      contact: "contact",
      operatorName: "Operator",
      operatorAddressDesc: "Operator Address",
    },
  },
  GroupList: {
    header: {
      title: "Entities Groups",
      rowsInfo: "groups",
      filters: "Filters",
      addBtn: "Add Group",
    },
    filter: {
      ownerLabel: "Owner",
    },
    columns: {
      groupCode: "Code",
      groupName: "Name",
      entityName: "Owner",
      editBtn: "Edit",
      deleteBtn: "Delete",
    },
    deleteConfirm: {
      title: "Are you sure?",
      description: "This action is not reversible",
      ok: "OK",
      cancel: "Cancel",
    },
  },
  GroupCreate: {
    header: {
      title: "Create new group",
      editTitle: "Update group: ",
      btnSave: "Save",
    },
    form: {
      name: "Name",
      code: "Code",
      entityId: "Owner",
      groupEntities: "Entities",
      requiredName: "Name is required",
      requiredEntity: "Owner is required",
      requiredAtLeastOneGroupEntity: "At least one entity should be in the group",
    },
  },
  EntityUpdateName: {
    modal: {
      title: "Update Entity name",
      closeBtn: "Close",
      submitBtn: "Update",
    },
    form: {
      name: "Name",
      requiredName: "This field is required",
    },
  },
  BusinessUnitForm: {
    form: {
      title: "Add Business Unit",
      submitBtn: "Add",
      updateBtn: "Update",
    },
  },
  EntityExternalAccess: {
    form: {
      title: "External Access",
      username: "API Username",
      password: "API Key",
    },
    tooltip: {
      revoke: "Revoke API Access",
      create: "Create Access User",
      reset: "Reset API Key",
    },
    confirmRevoke: {
      title: "Revoke API Access",
      description:
        "Please confirm your intent to revoke this access, the current account will be unable to connect anymore.",
      btnOk: "OK",
      btnCancel: "CANCEL",
    },
    resetKey: {
      title: "Reset API Access Key",
      description:
        "Please confirm your intent to reset this access, the current account will be unable to connect anymore.",
      warning: "Please copy this value to a safe location, this is the only time you will be able to view it.",
      resetBtn: "Reset API Key",
      btnOk: "OK",
      btnCancel: "CANCEL",
    },
  },
  EntitySectorGroupsList: {
    header: {
      title: "Sector Groups",
      btnAdd: "Add group",
    },
    columns: {
      groupName: "Name",
      sector: "Sector",
      header: "Header",
      users: "Users",
      edit: "Edit"
    },
  },
  EntitySectorGroupForm: {
    header: {
      updateTitle: "Update",
      createTitle: "Create New Group",
      updateBtn: "Update",
      createBtn: "Create",
    },
    form: {
      requiredGroupName: "Name is required",
      requiredHeader: "Header is required",
      requiredSector: "Sector is required",
      groupName: "Name",
      header: "Header",
      sector: "Sector",
      productFamily: "Product family",
      users: "Users",
      deleteBtn: "Remove"
    },
    confirmDelete: {
      title: "Delete sector group",
      description: "Are you sure you want to delete this group",
      ok: "Ok",
      cancel: "Cancel"
    }
  },
  PendingEntityList: {
    header: {
      title: "Validate Entities",
      rowsInfo: "changes",
    },
    columns: {
      entityName: "Entity",
      date: "Date",
      processType: "Process",
      valueUpdate: "Update",
      valueCreate: "Create",
      status: "Status",
    },
    actions: {
      confirm: "Confirm"
    },
    filter: {
      status: "Status",
      processTypes: "Process Types"
    }
  },
  EntityDetails: {
    header: {
      acceptBtn: "Accept",
      rejectBtn: "Reject",
    },
    confirmAccept: {
      title: "Accept Entity",
      ok: "Ok",
      cancel: "Cancel",
      comment: "Comment",
      description: "You can provide a comment regarding your approval.",
    },
    confirmReject: {
      title: "Reject Entity",
      ok: "Ok",
      cancel: "Cancel",
      comment: "Comment",
      description: "Are you sure you want to reject this Entity? A comment will me required.",
    },
    tabs: {
      generalTitle: "General",
      businessUnitsTitle: "Business Units",
      contactsTitle: "Contacts",
      adressesTitle: "Addresses",
      groupsTitle: "Groups",
      externalAccessTitle: "External Access",
      documentsTitle: "Documents"
    },
    documents: {
      title: "Documents",
      attachFile: "Attach document",
    },
    comments: {
      commentsTooltip: "Comments",
      noCommentsTooltip: "No comments",
      modalTitle: "Comments",
    },
    modalDocuments: {
      title: "Entity document",
      btnSave: "Save",
    }
  }
};

export default entities;

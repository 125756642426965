import React, { useEffect } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Grid, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { styles } from "./PlaceForm.styles";
import { formFields, formSchema } from "./PlaceForm.schema";

import { Form, FormFields } from "../../../common/forms";
import Permission from "../../../app/components/Permission";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {
  placeCreate,
  placeGet,
  placeList,
  placeUpdate,
  selectPlaceDetails,
  selectPlaceListDetails,
} from "../placesSlice";
import { entityGet, selectEntityDetails } from "../../entities/entitiesSlice";
import { useFirstLoad, useNavigate, usePromoter, useUser } from "../../../common/hooks";
import { selectLoader } from "../../products/productsSlice";

function PlaceForm({ classes, copy }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const promoter = usePromoter();
  const loading = useSelector(selectLoader);
  const entity = useSelector(selectEntityDetails);
  const user = useUser();
  const placeDetails = useSelector(selectPlaceDetails);
  const placeListDetails = useSelector(selectPlaceListDetails);
  const isEdit = id && !copy;

  useFirstLoad(() => {
    if (!entity) {
      dispatch(entityGet(user?.entity?.id));
    }
    if (id) {
      dispatch(placeGet({ id: id, isToEdit: isEdit, isToCopy: copy }));
    }
  });

  useEffect(() => {
    if (placeDetails && placeDetails?.type)
      typeChangeHandler(placeDetails?.type, null);
    // eslint-disable-next-line
  }, [placeDetails])

  const onSubmitHandler = async (values) => {
    const coordinates = {
      latitude: values.latitude,
      longitude: values.longitude,
    };
    if (isEdit)
      dispatch(placeUpdate({ ...values, id: id, geoCoordinates: coordinates, entityId: user?.entity?.id }));
    else
      dispatch(placeCreate({ ...values, geoCoordinates: coordinates, entityId: user?.entity?.id }));
  };

  const typeChangeHandler = (type, form) => {

    let placeTypesAllowed = promoter?.getConfig("general", "placeTypes") || [];

    const wantedLevel = (promoter?.refData?.placeTypes || []).filter((t) => type === t.type).map((item) => {
      return item.level > 0 ? item.level - 1 : 0;
    });

    let wantedItems = (promoter?.refData?.placeTypes || []).filter((k) => k.level <= wantedLevel && placeTypesAllowed.includes(k.type)).map((item) => {
      return item.type;
    });
    if (wantedItems && wantedItems.length > 0) {
      const filter = {
        limit: 10000,
        offset: 0,
        sortField: "TITLE",
        sortType: "ASC",
        search: '',
        type: wantedItems,
        entityId: null,
        active: true
      };
      dispatch(placeList(filter, true, false));
    }
  };

  const addressIdChangeHandler = (addressId, form) => {
    let addressItem = (entity?.addresses || []).filter((k) => k.id === addressId);
    if (addressItem && addressItem.length > 0) {
      form.change("latitude", addressItem[0]?.latitude);
      form.change("longitude", addressItem[0]?.longitude);
    }
  };

  const defaultValues = {
    type: "B",
    attributes: {
      height: { category: "COMP", value: 0, unit: "CMT" },
      width: { category: "COMP", value: 0, unit: "CMT" },
      depth: { category: "COMP", value: 0, unit: "CMT" },
    }
  };

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmitHandler}
        schema={formSchema({ t })}
        initialValues={id ? placeDetails : defaultValues}
        keepDirtyOnReinitialize
        render={({ values, form }) => {
          return (
            <Page
              permission={"promoter.actions.places.list"}
              header={<PageTitle
                onBack={() => navigate("/places")}
                title={isEdit ? t("places.PlaceForm.header.titleEdit") : t("places.PlaceForm.header.title")}
                actions={
                  <Grid container direction="row" spacing={2}>
                    <Permission code={!isEdit ? "promoter.actions.places.create" : "promoter.actions.places.update"}>
                      <Grid item>
                        <Button variant="contained" color="primary" type="submit">
                          {t("places.PlaceForm.form.btnSubmit")}
                        </Button>
                      </Grid>
                    </Permission>
                  </Grid>
                }
              />}>
              <Paper className={classes.paper}>
                <FormFields fields={formFields({
                  t, values, contacts: entity?.contacts, addresses: entity?.addresses, parentPlaces: placeListDetails, loading, promoter,
                  onTypeChange: (v) => typeChangeHandler(v, form),
                  onAddressChange: (v) => addressIdChangeHandler(v, form),
                })} />
              </Paper>
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(PlaceForm, styles);

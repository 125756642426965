import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Typography,
} from "@mui/material";

import { styles } from "./ResetAccessKeyDialog.styles";

function ResetAccessKeyDialog({ classes, open, setOpen, externalAccessKey, onResetAccessKey }) {
  const { t } = useTranslation();

  const onResetKeyHandler = () => {
    onResetAccessKey && onResetAccessKey();
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-title">{t("entities.EntityExternalAccess.resetKey.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("entities.EntityExternalAccess.resetKey.description")}
        </DialogContentText>
        <br />
        {externalAccessKey ? (
          <>
            <Typography
              className={classes.password}
              onClick={() => {
                navigator.clipboard.writeText(externalAccessKey);
              }}
            >
              {externalAccessKey}
            </Typography>
            <Typography className={classes.warning} variant="caption">
              {t("entities.EntityExternalAccess.resetKey.warning")}
            </Typography>
          </>
        ) : (
          <Button variant="contained" size="large" color="primary" fullWidth onClick={onResetKeyHandler}>
            {t("entities.EntityExternalAccess.resetKey.resetBtn")}
          </Button>
        )}
        <br />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}
          color="primary"
          autoFocus
        >
          {t("entities.EntityExternalAccess.resetKey.btnOk")}
        </Button>
      </DialogActions>
      <br />
    </Dialog>
  );
}

export default withStyles(ResetAccessKeyDialog, styles)

export const styles = (theme) => ({
  root: {},
  disabledLabel: {
    color: "#97A1AF",
  },
  modal: {
    paddingTop: "10vh",
  },
  modalroot: {
    width: "50vw",
    margin: "0 auto",
    backgroundColor: theme.palette.secondary.main,
    height: "80vh",
  },
  modalbody: {
    padding: theme.spacing(2),
  },
  nomargin: {
    margin: 0,
  },
});

import React from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

import { styles } from "./StepDetails.styles";
import { dateFormat } from "../../../../../common/utils";
import TasksCounter from "../../BatchListItem/TasksCounter/TasksCounter";

function StepDetails({ classes, title, date, completed, inprogress, isLast, tasks, tanks }) {
  // const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.colLeft}>
        {
          completed ?
            <div className={classes.circleFull}></div> :
            <div className={classes.circle}>
              {inprogress && <div className={classes.inprogress}></div>}
            </div>
        }
        {!isLast && <div className={classes.connector}></div>}
      </div>
      <div className={classes.colRight}>
        <Grid container justifyContent="space-between" style={{ width: "100%" }}>
          <Grid item>
            <Typography className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.date} color="textSecondary">
              {dateFormat(date)}
            </Typography>
          </Grid>
        </Grid>
        <TasksCounter tasksToComplete={tasks.toComplete} taskCompleted={tasks.completed} />
      </div>
    </div>
  );
}

export default withStyles(StepDetails, styles);
import * as Yup from "yup";

export function formSchema({ t, user }) {
  return Yup.object().shape({
    admin: Yup.object().shape({
      baseUrl: user?.roleType === "A" ? Yup.string().matches(/https:\/\//, t("promoter.PromoterConfigs.form.includeHttps")) : Yup.string()
    }),
    functional: Yup.object().shape({
      contact: Yup.object().shape({
        website: Yup.string().required(t("promoter.PromoterConfigs.form.requiredWebsite")),
        notificationUsers: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required("required"),
            email: Yup.string().required("required"),
          })
        ),
      }),
    })
  });
}


// The fields are on separeted files in the fields folder
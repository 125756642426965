import * as Yup from "yup";
import {DateTimePickerField, EntityAutoCompleteField, SelectField} from "../../../../common/forms";
import React from "react";
import moment from "moment";
import {getAuditErrorTypeOptions, getAuditExternalMethodsOptions} from "../../BusinessForm.options";
import TextField from "../../../../common/forms/fields/TextField";

export const filterFields = () => (t) => {
  return [
    {
      size: 12,
      field: (
        <DateTimePickerField
          id="requestDateIni"
          label={t("business.audit.filter.requestStartDate")}
          maxDate={moment().toISOString()}
        />
      )
    },
    {
      size: 12,
      field: (
        <DateTimePickerField
          id="requestDateEnd"
          label={t("business.audit.filter.requestEndDate")}
          maxDate={moment().toISOString()}
        />
      )
    },
    {
      size: 12,
      field: (
        <SelectField
          id="method"
          label={t("business.audit.filter.externalMethod")}
          options={getAuditExternalMethodsOptions(t)}
          allowEmpty
          clearLabel={t("business.audit.filter.externalMethodClear")}
        />
      ),
    },
    {
      size: 12,
      field: (
          <SelectField
              id="error"
              label={t("business.audit.filter.externalError")}
              options={getAuditErrorTypeOptions(t)}
          />
      ),
    },
    {
      size: 12,
      field: (
          <TextField
              id="type"
              label={t("business.audit.filter.externalType")}
          />
      ),
    },{
      size: 12,
      field: (
          <TextField
              id="subType"
              label={t("business.audit.filter.externalSubType")}
          />
      ),
    },
    {
      size: 12,
      field: (
          <EntityAutoCompleteField id="entity" label={t("business.audit.filter.externalEntity")} typeFilter={["S", "ST", "W", "LO", "P"]} />
      ),
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    requestDateIni: Yup.string(),
    requestDateEnd: Yup.string(),
  });
};

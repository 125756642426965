export const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(0),
    backgroundColor: "#ffffff",
    boxShadow: "inset 0px -1px 0px #EEF3F5",
  },
  header: {
    zIndex: 1000,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(2),
    },
    marginBottom: 0,
    margin: "0 auto"
  },

  back: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(-2),
  },
  title: {
    margin: 0,
    marginRight: theme.spacing(2),
  },
  info: {
    margin: 0,
  },
  subtitle: {
    color: "#686F7A",
  },
});

import React from "react";
import { Routes, Route } from "react-router-dom";

import PlanningOverview from "./PlanningOverview/PlanningOverview";
import PlanningList from "./PlanningList/PlanningList";
import Security from "../../app/components/Security/Security";
import PlanningForecast from "./PlanningForecast/PlanningForecast";

function PlanningRouting({ classes }) {
  return (
    <Routes>
      <Route path="/forecast/:productId/:year" element={
        <Security component={<PlanningForecast />} paramValidation={[{ name: "productId", type: "uuid" }, { name: "year", type: "number" }]} />
      } />
      <Route path="/:productId/:year" element={
        <Security component={<PlanningList />} paramValidation={[{ name: "productId", type: "uuid" }, { name: "year", type: "number" }]} />
      } />
      <Route path="/" element={<PlanningOverview />} />
    </Routes>
  );
}

export default PlanningRouting;

import { Grid } from "@mui/material";
// import {useTranslation} from "react-i18next";
import React from "react";
import Permission from "../../../../app/components/Permission";
import UpdateRetentionDialog from "./UpdateRetentionDialog";

export default function RetentionDataGridActions({ row, onEdit }) {
  // const { t } = useTranslation();
  return (
    <Grid container spacing={1} justifyContent="flex-end">
      <Grid item>
        <Permission code="promoter.actions.auditRetention.update">
          <UpdateRetentionDialog
            variant="contained"
            color="primary"
            canEdit={true}
            initialValues={{ tableName: row.tableName, numberDays: row.numberDays }}
            onConfirm={(values) => {
              onEdit && onEdit(values);
            }}
          />
        </Permission>
      </Grid>
    </Grid>
  );
}



import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const planningForecastGetCall = async (
  { setPlanningForecast },
  dispatch,
  { year, productId }
) => {
  let rh = requestHelper(dispatch, "PLANNING_FORECAST_GET");

  try {
    let result = await call({
      type: "PLANNING",
      subType: "FORECAST_GET",
      request: {
        year,
        productId
      },
    });
    dispatch(setPlanningForecast(result.items));
  } catch (ex) {
    rh.error("planning", ex);
  }
  rh.close();
};

export default planningForecastGetCall;
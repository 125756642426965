import { ordersSlice } from "./ordersSlice";
import { call } from "../../app/websocket/_sliceCalls/websocket";
import { setSuccess, setRedirect, requestHelper, getPromoterConfig } from "../../app/coreSlice";

import { v4 as uuidv4 } from "uuid";
import { saveFile, processImageUrl } from "../../api/s3";
import { base64ToArrayBuffer, asyncForEach } from "../../common/utils";

export const selectLoader = (state) => state.core.loader.length > 0;
export const selectOrderList = (state) => state.orders.orderList;
export const selectOrderListTotalRows = (state) => state.orders.orderListTotalRows;
export const selectOrderListFilter = (state) => state.orders.orderListFilter;
export const selectCurrentOrder = (state) => state.orders.currentOrder;


export const selectAggregatedDetails = (state) => state.orders.aggregatedOrderDetails;
export const importData = (state) => state.orders.import;

export const selectOrdersMissingProducts = (state) => state.orders.ordersMissingProducts;
export const selectOrdersMissingProductsTotalRows = (state) => state.orders.ordersMissingProductsTotalRows;
export const selectOrdersMissingProductsFilter = (state) => state.orders.ordersMissingProductsFilter;

export const {
  clearOrderList,
  setOrderListFilter,
  appendToOrderList,
  setOrderListTotalRows,
  setOrderDetails,
  setCurrentOrder,
  setCurrentOrderProducts,
  clearAddressAggregatedOrders,
  appendToAddressAggregatedOrders,
  setAddressAggregatedOrdersTotalRows,
  setAddressAggregatedOrdersFilter,
  clearAggregatedOrders,
  appendToAggregatedOrders,
  setAggregatedOrdersTotalRows,
  setAggregatedOrdersFilter,
  setAddressAggregatedOrdersDetails,
  setAggregatedOrderDetails,
  setImport,
  clearOperatorOrders,
  appendToOperatorOrders,
  setOperatorOrdersTotalRows,
  setOperatorOrdersFilter,
  setOperatorOrderDetails,
  clearOrdersMissingProducts,
  appendToOrdersMissingProducts,
  setOrdersMissingProductsTotalRows,
  setOrdersMissingProductsFilter,
  setOrderAddresses
} = ordersSlice.actions;


export const initImportOrder = (orderBy, deliverToAddress, comments, cutOff) => async (dispatch) => {
  dispatch(
    setImport({
      params: {
        orderBy: orderBy,
        orderByAddress: deliverToAddress,
        comments: comments,
        cutOff: cutOff,
      },
    })
  );
};

export const setProductsCurrentOrder = (products) => async (dispatch) => {
  dispatch(setCurrentOrderProducts(products));
};



export const importOrder = (promoter, values, currentOrder) => async (dispatch) => {
  let rh = requestHelper(dispatch, "ORDER_IMPORT_CREATE");

  try {
    let filename = uuidv4();
    let fileType = undefined;
    switch (values?.file?.type) {
      case "application/vnd.ms-excel":
        fileType = 0;
        filename += ".xls";
        break;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        fileType = 1;
        filename += ".xlsx";
        break;
      case "text/csv":
        fileType = 2;
        filename += ".csv";
        break;
      default:
        throw new Error("Invalid File Type");
    }

    let fileUrl = await sendFileToBucket(
      promoter?.getConfig("admin", "fileStorage"),
      promoter?.details?.code,
      "price",
      filename,
      values?.file?.base64,
      values?.file?.type
    );

    let processData = {
      orderBy: values?.params?.orderBy,
      orderByAddress: values?.params?.orderByAddress,
      cutOff: values?.params?.cutOff, //cutOffToString(values?.params?.cutOff),
      comments: values?.params?.comments,
    };

    let data = await call({
      type: "ORDER",
      subType: "IMPORT_CREATE",
      request: {
        url: fileUrl,
        fileType: fileType,
        processData: processData,
      },
    });

    if (data && data.result && data.result.status === 1) {
      dispatch(
        setImport({
          ...importData,
          fileProcessing: false,
          results: data.result.lineData,
        })
      );
      // importFile.fileProcessing
    }
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
};


export const getOperatorOrderDetails = (id, dispatchId) => async (dispatch, getState) => {
  const state = getState();
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "GET_ORDER_LO");
  try {
    let result = await call({
      type: "ORDER",
      subType: "DETAILS_LO",
      request: {
        id,
        dispatchId,
      },
    });
    let products = [];
    if (result.details.products) {
      await asyncForEach(result.details.products, async (product) => {
        products.push({
          ...product,
          image: await processImageUrl(fileStorageConfigs, product.image),
          thumbnail: await processImageUrl(fileStorageConfigs, product.thumbnail),
          key: product.orderId + "_" + product.dispatchId + "_" + product.productId,
        });
      });
    }
    let volumes = [];
    if (result.details.volumes) {
      result.details.volumes.forEach((volume) => {
        volumes.push({
          ...volume,
          checked: false,
        });
      });
    }

    dispatch(
      setOperatorOrderDetails({
        ...result.details,
        products,
        volumes,
      })
    );
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
};

export const orderReception =
  ({ id, dispatchId, finished, products, document, documentDate, volumes }) =>
    async (dispatch) => {
      let rh = requestHelper(dispatch, "RECEPTION_ORDER");
      try {
        await call({
          type: "ORDER",
          subType: "RECEPTION",
          request: {
            orders: [
              {
                orderSellerId: id,
                dispatchId,
                finished,
                document,
                documentDate: documentDate.format("YYYY-MM-DDTHH:mm:ss"),
                products: products
                  .filter((product) => product.quantity > 0)
                  .map((product) => {
                    return {
                      productId: product.productId,
                      orderId: product.orderId,
                      quantity: product.quantity,
                    };
                  }),
                volumes: volumes
                  .filter((volume) => volume.checked)
                  .map((volume) => {
                    return volume.id;
                  }),
              },
            ],
          },
        });
        setTimeout(() => {
          dispatch(setRedirect(`/orders/operator`));
          dispatch(getOperatorOrderDetails(id, dispatchId));
          dispatch(setSuccess("orders", "ORDER_RECEPTION"));
        }, 500);
      } catch (ex) {
        rh.error("orders", ex);
      }
      rh.close();
    };

export const orderIntransit =
  ({ id, dispatchId, finished, document, documentDate, products, volumes }) =>
    async (dispatch) => {
      let rh = requestHelper(dispatch, "IN_TRANSIT_ORDER");
      try {
        await call({
          type: "ORDER",
          subType: "IN_TRANSIT",
          request: {
            orders: [
              {
                orderSellerId: id,
                dispatchId,
                finished,
                document,
                documentDate: documentDate.format("YYYY-MM-DDTHH:mm:ss"),
                products: products
                  .filter((product) => product.quantity > 0)
                  .map((product) => {
                    return {
                      productId: product.productId,
                      orderId: product.orderId,
                      quantity: product.quantity,
                    };
                  }),
                volumes: volumes
                  .filter((volume) => volume.checked)
                  .map((volume) => {
                    return volume.id;
                  }),
              },
            ],
          },
        });
        setTimeout(() => {
          dispatch(setRedirect(`/orders/operator`));
          dispatch(getOperatorOrderDetails(id, dispatchId));
          dispatch(setSuccess("orders", "ORDER_IN_TRANSIT"));
        }, 500);
      } catch (ex) {
        rh.error("orders", ex);
      }
      rh.close();
    };

export const ordersMissingProductsAppend =
  (filter, clear = false) =>
    async (dispatch, getState) => {
      const state = getState();
      const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
      let rh = requestHelper(dispatch, "ORDER_MISSING_PRODUCTS");
      if (clear) {
        dispatch(clearOrdersMissingProducts());
        filter = { ...filter, offset: 0 };
      }
      dispatch(setOrdersMissingProductsFilter(filter));
      try {
        let result = await call({
          type: "ORDER",
          subType: "MISSING_PRODUCTS",
          request: {
            ...filter,
            sortField: (filter.sortField || "").toUpperCase(),
          },
        });
        let orders = [];
        await asyncForEach(result.orders, async (order) => {
          let products = [];
          await asyncForEach(order.products, async (product) => {
            products.push({
              ...product,
              image: await processImageUrl(fileStorageConfigs, product.image),
              thumbnail: await processImageUrl(fileStorageConfigs, product.thumbnail),
            });
          });
          orders.push({
            ...order,
            products,
          });
        });
        dispatch(appendToOrdersMissingProducts(orders));
        dispatch(setOrdersMissingProductsTotalRows(result.rows));
      } catch (ex) {
        rh.error("orders", ex);
      }
      rh.close();
    };

/// AUXILIARS

const sendFileToBucket = async (filestorageConfigs, promoterCode, uploadType, filename, file, contentType) => {
  const filePath =
    promoterCode.toUpperCase() + "/" + process.env.REACT_APP_ENV + "/import/" + uploadType + "/" + filename;
  return await saveFile(
    filestorageConfigs?.provider,
    filestorageConfigs?.tempBucket,
    filePath,
    base64ToArrayBuffer(file),
    contentType
  );
};

export const getMonthOptions = (t) => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => {
    return {
      value: item,
      text: t("common.Enums.month." + item),
    };
  });
};

export const getYearOptions = () => {
  let yearsArr = [];
  for (let i = 2020; i <= new Date().getFullYear(); i++) {
    yearsArr.push(i);
  }

  return yearsArr.map((item) => {
    return {
      value: item,
      text: item,
    };
  });
};

export const getWeekOptions = () => {
  let weekArr = [];
  for (let i = 1; i <= 53; i++) {
    weekArr.push(i);
  }

  return weekArr.map((item) => {
    return {
      value: item,
      text: item,
    };
  });
};

export const getDiscrepanciesOptions = (t) => {
  return [true, false].map((item) => {
    return {
      value: item,
      text: t("business.queries.discrepancies." + item),
    };
  });
};

export const getQueriesOptions = (t, user) => {
  let queryOptions = ["empty", "report_orders_overview", "report_product_prices","report_missing_shipments","report_product"];

  let queriesAllowed = user?.rolePermission?.business?.actions?.queries?.list.available ? user?.rolePermission?.business?.actions?.queries?.list?.options || [] : [];

  return [...queryOptions.filter((value) => queriesAllowed.includes(value) || value === "empty")].map((item) => {
    return {
      value: item,
      text: t("business.queries.options." + item),
    };
  });
};

export const getReportsOptions = (t, user) => {
  let reportsAllowed = user?.rolePermission?.business?.actions?.report?.list.available ? user?.rolePermission?.business?.actions?.report?.list?.options || [] :  [];
  let options =[
    "empty",
    "report_suppliers",
    "report_weekly_units",
    "report_weekly_units_accepted",
    "report_weekly_units_delivered",
  ];

  return [...options.filter((value) => reportsAllowed.includes(value) || value === "empty")].map((item) => {
    return {
      value: item,
      text: t("business.reports.options." + item),
    };
  });

};

export const getSearchFieldMovementsOptions = (t) => {
  return ["NONE", "ORDERSELLERID", "SELLERNAME", "SELLERTAX", "BUYERNAME", "BUYERTAX", "ADDRESS"].map((item) => {
    return {
      value: item,
      text: t("business.queries.filter.searchField_" + item),
    };
  });
};

export const getSearchFieldOverviewOptions = (t) => {
  return ["NONE", "SELLERNAME", "BUYERNAME", "GTIN", "GLN", "DESCRIPTION", "ORDERSELLERID", "ORDERID", "CUTOFFID"].map(
    (item) => {
      return {
        value: item,
        text: t("business.queries.filter.searchField_" + item),
      };
    }
  );
};

export const getCutOffTypeOptions = (t) => {
  return ["ANY", "MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"].map((item) => {
    return {
      value: item,
      text: t("entities.CutOffs.dateType." + item),
    };
  });
};

export const getAuditOptions = (t, user) => {
  let auditOptions = ["empty", "audit_ws", "audit_data", "audit_import", "audit_login", "audit_external", "audit_job", "audit_i18n"];

  let auditAllowed = user?.rolePermission?.business?.actions?.audit?.list.available ? user?.rolePermission?.business?.actions?.audit?.list?.options || [] : [];

  return [...auditOptions.filter((value) => auditAllowed.includes(value) || value === "empty")].map((item) => {
    return {
      value: item,
      text: t("business.audit.options." + item),
    };
  });

};

export const getAuditDataTypeOptions = (t) => {
  return ["ANY", "ENTITY", "PRODUCT", "ORDER"].map((item) => {
    return {
      value: item,
      text: t("business.audit.filter.data_" + item),
    };
  });
};

export const getAuditWSTypeOptions = (t, user) => {
  return [
    "ListNewEntities",
    "ListNewOrderCreation",
    "ListNewOrderCreation2",
    "ListNewOrderMovements",
    "OrderAccept",
    "OrderDelivery",
    "OrderReceivement",
    "OrderShipment",
    "OrdersShipment",
    "ListNewItems",
  ].map((item) => {
    return {
      value: item,
      text: t("business.audit.filter.ws_" + item),
    };
  });
};

export const getAuditWSTypeofWSOptions = (t, user) => {
  return ['SOAP', 'REST'].map((item) => {
    return {
      value: item,
      text: t("business.audit.filter.type_" + item),
    };
  });
};
export const getAuditErrorTypeOptions = (t, user) => {
  return [true, false].map((item) => {
    return {
      value: item,
      text: t("business.audit.filter.error_" + item),
    };
  });
};

export const getAuditSubTypeTypeOptions = (t, user) => {
  return ["IMPORT_CREATE", "IMPORT_PRODUCT", "IMPORT_PRICE"].map((item) => {
    return {
      value: item,
      text: t("business.audit.filter.subtype_" + item),
    };
  });
};

export const getAuditDataSchemaTypeOptions = (t, user) => {
  return [
    "batch",
    "document",
    "entity",
    "notification",
    "order",
    "planning",
    "product",
    "promoter",
    "ref_data",
    "task",
    "user",
    "workflow",
  ].map((item) => {
    return {
      value: item,
      text: t("business.audit.filter.schema_" + item),
    };
  });
};

export const getAuditExternalMethodsOptions = (t, user) => {
  return [
    "CATALOG_PRODUCT",
    "CATALOG_PRODUCT_CATEGORIES",
    "CATALOG_PRODUCT_CATEGORY",
    "CATALOG_PRODUCT_CONNECTORS",
    "CATALOG_NOTIFY_NEW_GLN",
    "CATALOG_GET_ENTITY",
    "CATALOG_CREATE_ENTITY",
    "CATALOG_CONFIRM_ENTITY",
    "CATALOG_EDIT_ENTITY",
    "CATALOG_EDIT_ENTITY_CONTACTS",
    "CATALOG_EDIT_ENTITY_ADDRESS",
    "CATALOG_UPDATE_ENTITY_CHANGE",
    "CATALOG_LIST_ENTITY_CHANGES",

    "SGN_GET_ENTITIES",
    "SGN_GET_MATERIAL",
    "SGN_GET_PRODUCT_STATUS",
    "SGN_CHANGE_PRODUCT",
    "SGN_SEND_PRODUCT_TECHNICAL_SHEETS",
    "SGN_CHANGE_PRICE",
    "SGN_CHANGE_PRICING",

    "FTP_UPLOAD_DATA_FROM_S3",

  ].map((item) => {
    return {
      value: item,
      text: t("business.audit.filter.method_" + item),
    };
  });
};

export const getAuditLoginWebSocketOptions = (t, user) => {
  return ["C", "D", "U", ].map((item) => {
    return {
      value: item,
      text: t("business.audit.filter.websocket_" + item),
    };
  });
};


import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const businessUnitDeleteStepTaskCall = async (
  { businessUnitSteps, businessUnitTasks },
  dispatch,
  { id, businessUnitId, lineId, internal, placeId }
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_DELETE_TASK");
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "DELETE_TASK",
      request: {
        id
      },
    });

    if (result) {
      dispatch(setSuccess("businessUnits", "BUSINESS_UNIT_DELETE_TASK"));
      setTimeout(() => {
        if (placeId) {
          dispatch(businessUnitTasks({ businessUnitId }))
        }
        else {
          dispatch(businessUnitSteps({ businessUnitId, lineId, internal }));
        }
      }, 300);
    }

  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitDeleteStepTaskCall;


import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./OrdersMovementDataGrid.styles";
import { listColumns } from "./OrdersMovementDataGrid.columns";
import OrdersTFDataGrid from "./OrdersTFDataGrid/OrdersTFDataGrid";
import OrdersCDDataGrid from "./OrdersCDDataGrid/OrdersCDDataGrid";
import { csvFields } from "./OrdersMovementDataGrid.export";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";
import { filterFields, filterSchema } from "./OrdersMovementDataGrid.filter";
import { historyOrderMovementsDefaultFilter } from "../../_common/businessFilters";

function OrdersMovementDataGrid({
  classes,
  data,
  filter,
  totalRows,
  onRowSelected,
  onFilterChange,
  onLoadMore,
}) {

  const { t } = useTranslation();

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  return (
    <div className={classes.root}>

      <CustomDataGrid
        columns={listColumns(t, classes)}
        rows={data || []}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMore}
        onRowSelected={onRowSelected}
        total={totalRows}
        rowKeyField="orderSellerId"
        innerRow={(row) => row.operationType === "CD" ? <OrdersCDDataGrid data={row?.items} total={row?.items?.length || 0} /> : <OrdersTFDataGrid data={row?.items} total={row?.items?.length || 0} />}
        exportCsvFields={csvFields(t)}
        exportXlsxFields={csvFields(t)}
        topActions={
          <FilterForm
            style={{ marginBottom: 8 }}
            storageKey="OrdersMovementDataGrid"
            onStorageLoad={onFilterHandler}
            slim
            fields={filterFields()}
            schema={filterSchema()}
            onFilterChange={onFilterHandler}
            filter={filter}
            defaultFilter={historyOrderMovementsDefaultFilter}
          />
        }
      />

    </div>
  );
}

export default withStyles(OrdersMovementDataGrid, styles)

import DateTime from "../../../../common/displays/DateTime/DateTime";

export function listColumns(t, classes) {
  return [
    {
      field: "type",
      headerName: t("documents.list.columns.type"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "subType",
      headerName: t("documents.list.columns.subtype"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "version",
      headerName: t("documents.list.columns.version"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "user",
      headerName: t("documents.list.columns.user"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "date",
      headerName: t("documents.list.columns.date"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return <DateTime date={v.date} />;
      },
    }

  ];
}

import * as Yup from "yup";
import { MultiLangTextField, SelectField } from "../../../../common/forms";
import { getInfoTypeOptions } from "./TaskInfoModal.options";

export function formSchema({ t }) {
  return Yup.object().shape({
    type: Yup.string().required(t("businessUnits.TaskInfoModal.form.typeRequired")),
    content: Yup.object().when('type', {
      is: (value) => value === 'text' || value === 'warning',
      then: schema => schema.oneLanguageRequired(t("businessUnits.TaskInfoModal.form.contentRequired")),
      otherwise: schema => schema,
    }),
  });
}

export function formFields({ t, i18n, taskType, promoter, values }) {
  return [
    {
      size: 12,
      field: <SelectField
        id="type"
        options={getInfoTypeOptions(t, taskType)}
        required
        label={t("businessUnits.TaskInfoModal.form.type")}
      />,
    },
    {
      size: 12,
      field: <MultiLangTextField
        id="content"
        required
        defaultLanguage={i18n.language}
        languages={promoter?.getConfig("admin", "language") || []}
        rows={5}
        label={t("businessUnits.TaskInfoModal.form.content")}
      />,
      hidden: values?.type !== "text" && values?.type !== "warning"
    },
  ];
}


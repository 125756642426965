import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { IconButton, Stack, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { styles } from "./TaskInputItem.styles";
import InputLine from "./InputLine/InputLine";

function TaskInputItem({ classes, collection, input, onTaskInputSelected, onResourceInputAdd }) {
  const { t } = useTranslation();

  const onResourceAddHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onResourceInputAdd && onResourceInputAdd(input);
  }

  if (input?.type === "resource") {
    return (
      <div className={classes.root}  >
        <div className={classes.select} onClick={() => onTaskInputSelected(input)}>
          <Typography className={classes.resourceTitle}>{t("businessUnits.TaskInputItem.details.eachLabel")} {t("common.Enums.resourceTypes." + input.resourceType)}</Typography>
        </div>
        <Stack spacing={1} className={classes.resource} >
          <Stack spacing={1} >
            {(collection || []).filter(item => item.parentId === input.id).map((input, index) => (
              <div key={"taskInput_" + input.id + "_" + index} className={classes.root + " " + classes.select} onClick={() => onTaskInputSelected(input)}>
                <InputLine input={input} />
              </div>
            ))}
          </Stack>
          <IconButton size="small" className={classes.circleBtn} onClick={onResourceAddHandler}>
            <AddIcon fontSize="small" />
          </IconButton>
        </Stack>
      </div>
    )
  }

  return (
    <div className={classes.root + " " + classes.select} onClick={() => onTaskInputSelected(input)}>
      <InputLine input={input} />
    </div>
  );
}

export default withStyles(TaskInputItem, styles);
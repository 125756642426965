import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Chip, Checkbox } from "@mui/material";

import { styles } from "./DocumentTypeField.styles";
import { Field } from "react-final-form";
import MetaError from "../../../../common/forms/MetaError";

function DocumentTypeField({ classes, id, documentType, label, required, disabled }) {
  const { t } = useTranslation();

  const toggleDocumentTypeHandler = (doc, input) => {
    let nValue = {
      ...input.value,
      [doc]: input.value?.[doc] !== undefined ? undefined : false,
    };
    input.onChange && input.onChange(nValue);
  };

  const onCheckChange = (doc, input, checked) => {
    input.onChange &&
      input.onChange({
        ...input.value,
        [doc]: checked,
      });
  };

  return (
    <div>
      <Field name={id}>
        {({ input, meta }) => {
          return (
            <>
              <Typography
                component="span"
                variant="caption"
                htmlFor={id}
                color="textPrimary"
                className={disabled ? classes.disabledLabel : ""}
              >
                {label}
                {required && <span> *</span>}
              </Typography>
              <Grid container direction="column" className={classes.root}>
                <Grid item>
                  <Grid container spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Grid item>
                      <Typography component="span" variant="caption" color="textPrimary">
                        {t("promoter.DocumentTypeField.labels.documentType")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography component="span" variant="caption" color="textPrimary">
                        {t("promoter.DocumentTypeField.labels.required")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {(documentType || []).map((doc) => {
                  let selected = input.value?.[doc] !== undefined;
                  return (
                    <Grid item key={doc}>
                      <Grid container spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item>
                          <Chip
                            variant={selected ? "filled" : "outlined"}
                            color="primary"
                            size="small"
                            label={t(`common.Enums.documentType.${doc}`)}
                            onClick={() => toggleDocumentTypeHandler(doc, input)}
                          />
                        </Grid>
                        <Grid item>
                          <Checkbox
                            checked={Boolean(input.value?.[doc])}
                            disabled={!selected}
                            size="small"
                            onChange={(e) => onCheckChange(doc, input, e.target.checked)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>

              <div className={classes.error}>
                <MetaError meta={meta} />
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
}

export default withStyles(DocumentTypeField, styles);

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "../../../common/hooks";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";
import { Typography, LinearProgress, Grid, Button } from "@mui/material";
import { Form, FormFields } from "../../../common/forms";

import { recoverPassword, selectLoader, selectRecoverError, selectStatus, cleanErrors } from "../authenticationSlice";

import { styles } from "./RecoverPass.styles";
import { formFields, formSchema } from "./RecoverPass.schema";

import AuthenticationContainer from "../../../common/components/containers/AuthenticationContainer";
import { logout } from "../../../app/websocket/websocketSlice";

function RecoverPass({ classes }) {
  const { t } = useTranslation();
  const loader = useSelector(selectLoader);
  const error = useSelector(selectRecoverError);
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logout());
    dispatch(cleanErrors());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (status === "RECOVER_SUCCESS") {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, [status]);

  const onSubmit = async (values) => {
    dispatch(recoverPassword({ email: (values.email || "").toLowerCase() }));
  };

  return (
    <AuthenticationContainer imgUrl={process.env.REACT_APP_BACKGROUND_IMAGE_URL}>
      {status !== "RECOVER_SUCCESS" && (
        <div className={classes.root}>
          <Typography variant="h2" align="left" component="h1">
            {t("authentication.RecoverPass.form.title")}
          </Typography>
          <Typography variant="subtitle1" align="left" component="h6" color="textSecondary">
            {t("authentication.RecoverPass.form.subtitle")}
          </Typography>
          <Form
            schema={formSchema(t)}
            initialValues={{}}
            onSubmit={onSubmit}
            render={({ submitting }) => {
              return (
                <>
                  <FormFields fields={formFields(t)} />
                  <br />
                  <Grid container spacing={1}>
                    {error && (
                      <Grid item xs={12}>
                        <Alert variant="filled" severity="error">
                          {t("authentication.RecoverPass.form." + error)}
                        </Alert>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        className={classes.btn}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={submitting}
                      >
                        {t("authentication.RecoverPass.form.submit")}
                      </Button>
                    </Grid>
                  </Grid>
                </>
              );
            }}
          />
          <Link className={classes.link} to={`/login`}>
            {t("authentication.RecoverPass.form.back")}
          </Link>
        </div>
      )}

      {loader && <LinearProgress className={classes.loader} />}
    </AuthenticationContainer>
  );
}

export default withStyles(RecoverPass, styles);

import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Paper, Grid, Button } from "@mui/material";
import { withStyles } from "tss-react/mui";

import { styles } from "./DeliveryDayAndAddressForm.styles";
import { formFields, formSchema } from "./DeliveryDayAndAddressForm.schema";
import { getAddressOptions, getDeliveryDayOptions } from "./DeliveryDayAndAddressForm.options";

import { usePromoter, useUser } from "../../../../common/hooks";
import { Form, FormFields } from "../../../../common/forms";
import { orderEntityAddresses } from "../../ordersSlice";

function DeliveryDayAndAddressForm({
  classes,
  onComplete,
  onCancel,
  onImport,
  currentOrder,
  orderAddresses,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [importMode, setImportMode] = useState(false);
  const promoter = usePromoter();
  const user = useUser();
  const [selectedAddress, setSelectedAddress] = useState(!currentOrder?.address && orderAddresses && orderAddresses.length === 1
    ? orderAddresses[0]
    : undefined);

  const formOptions = useMemo(() => {
    return {
      deliveryDay: getDeliveryDayOptions(t, promoter),
      addresses: getAddressOptions((orderAddresses || []).filter(item => item.confirmed))
    }
    // eslint-disable-next-line
  }, [promoter, orderAddresses]);

  const onSubmit = async (values) => {
    let nValues = {
      ...values,
      locationId: selectedAddress.locations?.[0]?.id
    }
    console.log('values: %o', nValues);
    if (importMode) {
      onImport && onImport(nValues);
    } else {
      onComplete && onComplete(nValues);
    }
  };

  const entityChangeHandler = (entity, form) => {
    form.change("address", null);
    setSelectedAddress();
    if (entity?.entityId) {
      dispatch(orderEntityAddresses(entity?.entityId));
    }
  };

  const onAddressChangeHandler = (address, form) => {
    let nAddress = orderAddresses.find(item => item.id === address);
    console.log('nAddress: %o', nAddress);
    setSelectedAddress(nAddress);
  };

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmit}
        schema={formSchema(t, promoter)}
        keepDirtyOnReinitialize
        initialValues={{
          ...currentOrder,
          orderBy: currentOrder?.orderBy,
          address: selectedAddress?.id,
        }}
        render={({ submitting, form, values, valid }) => {
          return (
            <>
              <Paper className={classes.paper} elevation={0}>
                <FormFields fields={formFields({
                  t,
                  formOptions,
                  user,
                  onEntityChange: (v) => entityChangeHandler(v, form),
                  onAddressChange: (v) => onAddressChangeHandler(v, form),
                  values,
                  promoter,
                  selectedAddress
                })} />
              </Paper>
              <Grid container direction="row" spacing={2} justifyContent="space-between" className={classes.actions}>
                <Grid item>
                  <Button
                    onClick={() => setImportMode(true)}
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={submitting || !valid}
                  >
                    {t("orders.DeliveryDayAndAddressForm.form.importBtn")}
                  </Button>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button variant="contained" color="secondary" size="large" onClick={() => onCancel && onCancel()}>
                        {t("orders.DeliveryDayAndAddressForm.form.cancelBtn")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => setImportMode(false)}
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                        disabled={submitting || !valid}
                      >
                        {t("orders.DeliveryDayAndAddressForm.form.submitBtn")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(DeliveryDayAndAddressForm, styles)

export const getDpromValueOptions = () => {
  return [
    {
      value: "P",
      text: "%",
    },
  ];
};

export const getDpromsTypesOptions = (t, promoter) => {
  return (promoter?.refData?.promotionalDiscountType || []).map(item => ({
    value: item,
    text: t("common.Enums.dpromType." + item),
  }))
}


import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const accountingSetAvailableCall = async ({ businessListAccountingDocs }, dispatch, { filter, documents }) => {
  let rh = requestHelper(dispatch, "ACCOUNTING_SET_AVAILABLE");
  try {
    await call({
      type: "ACCOUNTING",
      subType: "SET_AVAILABLE",
      request: { documents },
    });
    dispatch(setSuccess("business", "ACCOUNTING_SET_AVAILABLE"));
    setTimeout(() => {
      dispatch(businessListAccountingDocs(filter, true));
    }, 300);
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default accountingSetAvailableCall;

export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
  },
  paper: {
    padding: 20,
    marginBottom: 10,
    minHeight: "calc(100vh - 122px)",
  },
  tabs: {
    "& .MuiTab-root": {
      alignItems: "center",
      maxWidth: "none",
    },
    "& .MuiTab-wrapper": {
      alignItems: "flex-start",
    },
    "& .Mui-selected": {
      backgroundColor: "#FFF",
      borderRadius: "2px 0 0 2px",
    },
  },
});
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Grid, Stack } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { styles } from "./PlaceList.styles";
import PlaceDataGrid from "./PlaceDataGrid/PlaceDataGrid";

import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import Permission from "../../../app/components/Permission";
import {
  placeDelete,
  placeDisable,
  placeEnable,
  placeList,
  selectPlaceList,
  selectPlaceListFilter,
  selectPlaceListTotalRows,
} from "../placesSlice";
import { selectLoader } from "../../../app/coreSlice";
import { useNavigate } from "../../../common/hooks";

function PlaceList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(selectPlaceList);
  const totalRows = useSelector(selectPlaceListTotalRows);
  const filter = useSelector(selectPlaceListFilter);
  const loading = useSelector(selectLoader);

  const onAddHandler = () => {
    navigate("/places/create")
  }

  const onFilterChangeHandler = (v) => {
    dispatch(placeList({ ...filter, ...v.filter }, v.reset));
  }

  const onRowSelectedHandler = (row) => {
    //navigate("/places/"+row.id) ;
  }

  const onEditHandler = (row) => {
    navigate("/places/edit/" + row.id);
  }

  const onCopyHandler = (row) => {
    navigate("/places/copy/" + row.id);
  }

  const onRemoveHandler = (row) => {
    dispatch(placeDelete({ id: row.id }));
  }

  const onDisableHandler = (row) => {
    dispatch(placeDisable({ id: row.id }));
  }
  const onEnableHandler = (row) => {
    dispatch(placeEnable({ id: row.id }));
  }

  return (
    <Page
      permission={"promoter.actions.places.list"}
      header={<PageTitle
        title={t("places.PlaceList.header.title")}
        actions={
          <Grid container direction="row" spacing={2}>
            <Permission code={"promoter.actions.places.create"}>
              <Grid item>
                <Button variant="outlined" size="large" onClick={onAddHandler} disabled={loading} id="add"><AddIcon fontSize="inherit" /></Button>
              </Grid>
            </Permission>
          </Grid>
        }
      />}>
      <Stack spacing={1} className={classes.root}>
        <PlaceDataGrid
          data={data}
          totalRows={totalRows}
          onFilterChange={onFilterChangeHandler}
          onRowSelected={onRowSelectedHandler}
          onEdit={onEditHandler}
          onRemove={onRemoveHandler}
          onCopy={onCopyHandler}
          onEnable={onEnableHandler}
          onDisable={onDisableHandler}
          filter={filter}
        />
      </Stack>
    </Page>
  );
}

export default withStyles(PlaceList, styles);
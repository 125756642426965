import * as Yup from "yup";
import { CheckListField, MultiLangTextField, SelectField, TextField } from "../../../../common/forms";
import { getInputTypeOptions, getPlaceTypeOptions, getResourceTypeOptions } from "./TaskInputModal.options";

export function formSchema({ t }) {
  return Yup.object().shape({
    type: Yup.string().required(t("businessUnits.TaskInputModal.form.typeRequired")),
    key: Yup.string().when('type', {
      is: "resource",
      then: schema => schema,
      otherwise: schema => schema.required(t("businessUnits.TaskInputModal.form.keyRequired")),
    }),
    label: Yup.object().when('type', {
      is: "resource",
      then: schema => schema,
      otherwise: schema => schema.oneLanguageRequired(t("businessUnits.TaskInputModal.form.labelRequired")),
    }),
    resourceType: Yup.string().when('type', {
      is: "resource",
      then: schema => schema.required(t("businessUnits.TaskInputModal.form.resourceTypeRequired")),
      otherwise: schema => schema,
    }),
  });
}

export function formFields({ t, promoter, i18n, values, inputs, selectedResourceInput, form, edit }) {
  const onTypeChangeHandler = (type) => {
    if (type === "transfer") {
      form.change("key", "target");
    }
    else {
      form.change("key", "");
    }
  }

  return [
    {
      size: 12,
      field: <SelectField
        id="type"
        required
        options={getInputTypeOptions(t, inputs, selectedResourceInput, edit)}
        onChange={onTypeChangeHandler}
        label={t("businessUnits.TaskInputModal.form.type")}
      />,
    },
    {
      size: 12,
      field: <SelectField
        id="resourceType"
        required
        options={getResourceTypeOptions(t, promoter)}
        label={t("businessUnits.TaskInputModal.form.resourceType")}
      />,
      hidden: values?.type !== "resource"
    },
    {
      size: 12,
      field: <TextField
        id="key"
        disabled={values?.type === "transfer"}
        label={t("businessUnits.TaskInputModal.form.key")}
      />,
      hidden: values?.type === "resource"
    },
    {
      size: 12,
      field: <MultiLangTextField
        id="label"
        defaultLanguage={i18n.language}
        languages={promoter?.getConfig("admin", "language") || []}
        label={t("businessUnits.TaskInputModal.form.label")}
      />,
      hidden: values?.type === "resource"
    },
    {
      size: 12,
      field: <MultiLangTextField
        id="note"
        defaultLanguage={i18n.language}
        languages={promoter?.getConfig("admin", "language") || []}
        rows={4}
        label={t("businessUnits.TaskInputModal.form.note")}
      />,
      hidden: values?.type === "resource"
    },
    {
      size: 6,
      field: <CheckListField
        id="transferAllowedResourceType"
        options={getResourceTypeOptions(t, promoter)}
        label={t("businessUnits.TaskInputModal.form.transferAllowedResourceType")}
      />,
      hidden: values?.type !== "transfer"
    },
    {
      size: 6,
      field: <CheckListField
        id="transferAllowedPlaceType"
        options={getPlaceTypeOptions(t, promoter)}
        label={t("businessUnits.TaskInputModal.form.transferAllowedPlaceType")}
      />,
      hidden: values?.type !== "transfer"
    },
  ];
}


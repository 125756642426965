import * as Yup from "yup";
import { MultiSelectField, SelectField, TextField } from "../../../common/forms";
import { getSectorOptions } from "../UserForm/UserForm.options";
import { getContactsOptions, getFamiliesOptions } from "./EntitySectorGroupForm.options";

export function formSchema(t) {
  return Yup.object().shape({
    groupName: Yup.string().required(t("entities.EntitySectorGroupForm.form.requiredHeader")),
    header: Yup.string().required(t("entities.EntitySectorGroupForm.form.requiredGroupName")),
    sector: Yup.string().required(t("entities.EntitySectorGroupForm.form.requiredSector")),
  });
}

export function formFields({ t, promoter, contacts, families, values, onHeaderChange }) {
  return [
    {
      size: 6,
      field: (
        <TextField id="groupName" type="text" label={t("entities.EntitySectorGroupForm.form.groupName")} required />
      ),
    },
    {
      size: 6,
    },
    {
      size: 3,
      field: (
        <SelectField
          id="header"
          label={t("entities.EntitySectorGroupForm.form.header")}
          options={getContactsOptions(contacts)}
          onChange={onHeaderChange}
          required
        />
      ),
    },
    {
      size: 3,
      field: (
        <SelectField
          id="sector"
          label={t("entities.EntitySectorGroupForm.form.sector")}
          options={getSectorOptions(t, promoter?.refData)}
          required
        />
      ),
    },
    {
      size: 3,
      field: (
        <SelectField
          id="productFamily"
          options={getFamiliesOptions(families)}
          label={t("entities.EntitySectorGroupForm.form.productFamily")}
        />
      ),
      hidden: values?.sector !== "PCH",
      keepSpace: true
    },
    {
      size: 3,
    },
    {
      size: 9,
      field: (
        <MultiSelectField
          id="users"
          options={getContactsOptions(contacts, values?.header)}
          label={t("entities.EntitySectorGroupForm.form.users")}
        />
      ),
    },
  ];
}

export const getSectorOptions = (t, refData) => {
  return (refData?.sector || []).map((item) => {
    return {
      value: item,
      text: t("common.Enums.sector." + item),
    };
  });
};


export const getUserRoleOptions = (t, promoter, user) => {
  let roleTypes = (promoter?.getConfig("general", "roleTypes") || [])
    .filter(item => item !== "C");
  if (user?.roleType !== "F" && user?.roleType !== "A") {
    roleTypes = roleTypes.filter(item => item !== "F");
  }

  return roleTypes.map((item) => {
    return {
      value: item,
      text: t("common.Enums.roleType." + item),
    };
  });
}
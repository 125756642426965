import { createSlice } from "@reduxjs/toolkit";
import { default as homeScreenCall } from "./_sliceCalls/homeScreen";

// SLICE
export const homeSlice = createSlice({
  name: "home",
  initialState: {
    screenData: null,
  },
  reducers: {
    setScreenData: (state, action) => {
      state.screenData = action.payload;
    },
  },
});

// SELECTS
export const selectDataHome = (state) => state.home.screenData;
export const selectLoader = (state) => state.core.loader.length > 0;

// CALLS
/**
 * Gets metrics and overview data to show to promoter
 */
export const homeScreen = () => async (dispatch, getState) => {
  await homeScreenCall(homeSlice.actions, dispatch, getState());
};

export default homeSlice.reducer;

import * as Yup from "yup";
import { SelectField, EntityAutoCompleteField } from "../../../../common/forms";

export const filterFields = () => (t) => {
  return [
    {
      size: 12,
      field: <SelectField id="week" label={t("business.reports.filter.week")} options={getWeekOptions()} />,
    },
    {
      size: 12,
      field: <SelectField id="year" label={t("business.queries.filter.year")} options={getYearOptions()} />,
    },
    {
      size: 12,
      field: (
        <EntityAutoCompleteField id="supplier" label={t("business.reports.filter.entity")} typeFilter={["S", "W"]} />
      ),
    },
    {
      size: 12,
      field: <SelectField id="cutOff" label={t("business.reports.filter.cutoff")} options={getCutOffTypeOptions(t)} />,
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    week: Yup.string(),
    year: Yup.string(),
    supplier: Yup.string(),
    cutOff: Yup.string(),
  });
};

const getWeekOptions = () => {
  let weekArr = [];
  for (let i = 1; i <= 53; i++) {
    weekArr.push(i);
  }

  return weekArr.map((item) => {
    return {
      value: item,
      text: item,
    };
  });
};

const getYearOptions = () => {
  let yearsArr = [];
  for (let i = 2020; i <= new Date().getFullYear(); i++) {
    yearsArr.push(i);
  }

  return yearsArr.map((item) => {
    return {
      value: item,
      text: item,
    };
  });
};

const getCutOffTypeOptions = (t) => {
  return ["ANY", "MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"].map((item) => {
    return {
      value: item,
      text: t("entities.CutOffs.dateType." + item),
    };
  });
};

export const styles = (theme) => ({
  root: {},
  input: {
    display: "none"
  },
  imgPlaceholder: {
    height: 40,
    width: 40,
    marginRight: theme.spacing(2),
    color: "#c4c4c4",
    stroke: "#c4c4c4",
    cursor: "pointer",
  },
  linkLabel: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  img: {
    maxHeight: 35,
    cursor: "pointer",
  }
});

import { processImageUrl } from "../../../api/s3";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { asyncForEach } from "../../../common/utils";

const productList_pickerCall = async (
  { clearProductPickerList, setProductPickerListFilter, appendToProductPickerList, setProductPickerListTotalRows },
  dispatch,
  state,
  { filter, clear }
) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "LIST_PRODUCTS");
  if (clear) {
    dispatch(clearProductPickerList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setProductPickerListFilter(filter));
  try {
    let list = await call({
      type: "PRODUCT",
      subType: "LIST_LITE",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    let products = [];
    await asyncForEach(list.products, async (product) => {
      products.push({
        ...product,
        image: await processImageUrl(fileStorageConfigs,  product.image),
        thumbnail: await processImageUrl(fileStorageConfigs,  product.thumbnail),
      });
    });
    dispatch(appendToProductPickerList(products));
    dispatch(setProductPickerListTotalRows(list.rows));
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productList_pickerCall;

import * as Yup from "yup";
import { DatePickerField, FileUploadField, SelectField, TextField } from "../../../../common/forms";


export function formSchema({ t, metadata }) {
  let metadataSchemas = Object.keys(metadata).reduce((aggMeta, elMeta) => {
    return {
      ...aggMeta,
      [elMeta]: Object.keys(metadata[elMeta]).reduce((agg, el) => {
        return {
          ...agg,
          [el]: metadata[elMeta][el]
            ? Yup.string().required(t(`products.ProductCreate.form.required${el}`))
            : Yup.string(),
        };
      }, {}),
    };
  }, {});
  return Yup.object().shape({
    type: Yup.string().required(t(`orders.OrderAttachments.form.requiredType`)),
    file: Yup.object().shape({
      base64: Yup.string().required('file required')
    }),
    metadata: Yup.object().when("type", (type) => {
      return Yup.object().shape(metadataSchemas[type]);
    }),
  });
}

export const formFields = ({ t, metadata, documentTypes }) => {
  return [
    {
      size: 6,
      field: (
        <SelectField
          id="type"
          label={t(`orders.OrderAttachments.form.type`)}
          required
          options={Object.keys(documentTypes || []).map((item) => ({
            value: item,
            text: t(`common.Enums.documentType.${item}`),
          }))}
        />
      ),
    },
    {
      size: 6,
      field: (
        <FileUploadField
          id="file"
          label={t(`orders.OrderAttachments.form.file`)}
          required
        />
      ),
      hidden: Object.keys(metadata).length === 0,
    },
    {
      size: 6,
      field: (
        <TextField
          id={`metadata.NME`}
          type="text"
          label={t(`common.Enums.documentMetadata.NME`)}
          required={metadata?.["NME"]}
        />
      ),
      hidden: !Object.keys(metadata).find((item) => item === "NME"),
    },
    {
      size: 6,
      field: (
        <TextField
          id={`metadata.DSC`}
          type="text"
          label={t(`common.Enums.documentMetadata.DSC`)}
          required={metadata?.["DSC"]}
        />
      ),
      hidden: !Object.keys(metadata).find((item) => item === "DSC"),
    },
    {
      size: 6,
      field: (
        <DatePickerField
          id={`metadata.EXD`}
          type="text"
          label={t(`common.Enums.documentMetadata.EXD`)}
          required={metadata?.["EXD"]}
        />
      ),
      hidden: !Object.keys(metadata).find((item) => item === "EXD"),
    },
    {
      size: 6,
      field: (
        <DatePickerField
          id={`metadata.RCD`}
          type="text"
          label={t(`common.Enums.documentMetadata.RCD`)}
          required={metadata?.["RCD"]}
        />
      ),
      hidden: !Object.keys(metadata).find((item) => item === "RCD"),
    },
    {
      size: 6,
      field: (
        <TextField
          id={`metadata.ADD`}
          type="text"
          label={t(`common.Enums.documentMetadata.ADD`)}
          required={metadata?.["ADD"]}
        />
      ),
      hidden: !Object.keys(metadata).find((item) => item === "ADD"),
    },
    {
      size: 6,
      field: (
        <TextField
          id={`metadata.DVS`}
          type="text"
          label={t(`common.Enums.documentMetadata.DVS`)}
          required={metadata?.["DVS"]}
        />
      ),
      hidden: !Object.keys(metadata).find((item) => item === "DVS"),
    },
    {
      size: 6,
      field: (
        <TextField
          id={`metadata.APV`}
          type="text"
          label={t(`common.Enums.documentMetadata.APV`)}
          required={metadata?.["APV"]}
        />
      ),
      hidden: !Object.keys(metadata).find((item) => item === "APV"),
    },
  ];
};

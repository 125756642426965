import {
  getCutOffTypeOptions,
  getDayOfMonthTypeOptions,
  getDayOfWeekTypeOptions,
  getFrequencyTypeOptions,
} from "./CutOffForm.options";
import * as Yup from "yup";

import { EntityAutoCompleteField, SelectField, TextField, TimePickerField } from "../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    entityId: Yup.string().required(t("entities.CutOffCreate.form.requiredEntityId")),
    description: Yup.string().required(t("entities.CutOffCreate.form.requiredDescription")),
    cutOffType: Yup.string().required(t("entities.CutOffCreate.form.requiredCutOffType")),
    deliveryDay: Yup.string().required(t("entities.CutOffCreate.form.requiredDeliveryDay")),
    frequency: Yup.string().required(t("entities.CutOffCreate.form.requiredFrequency")),
    time: Yup.string().required(t("entities.CutOffCreate.form.requiredTime")),
  });
}

export function formFields(t, edit, values, promoter) {
  return [
    {
      size: 12,
      field: (
        <EntityAutoCompleteField
          typeFilter={promoter?.getConfig("product", "allowedEntityTypes")}
          id="entityId"
          label={t("entities.CutOffCreate.form.entity")}
          required
          disabled={edit}
        />
      ),
    },
    {
      size: 12,
      field: <TextField id="description" type="text" label={t("entities.CutOffCreate.form.description")} required />,
    },
    {
      size: 2,
      field: (
        <SelectField
          id="cutOffType"
          label={t("entities.CutOffCreate.form.type")}
          options={getCutOffTypeOptions(t)}
          required
        />
      ),
    },
    {
      size: 2,
      field: (
        <SelectField
          id="deliveryDay"
          label={t("entities.CutOffCreate.form.deliveryDay")}
          options={getDayOfWeekTypeOptions(t)}
          required
        />
      ),
    },
    {
      size: 2,
      field: (
        <SelectField
          id="frequency"
          label={t("entities.CutOffCreate.form.frequency")}
          options={getFrequencyTypeOptions(t)}
          required
        />
      ),
    },
    {
      size: 6,
      field: <div></div>,
    },
    {
      size: 2,
      field: (
        <SelectField
          id="dayOfMonth"
          label={t("entities.CutOffCreate.form.dayOfMonth")}
          options={getDayOfMonthTypeOptions(t)}
          required={values?.frequency === "dayOfMonth"}
          disabled={values?.frequency !== "dayOfMonth"}
        />
      ),
      hidden: values.frequency !== "dayOfMonth",
    },
    {
      size: 2,
      field: (
        <SelectField
          id="dayOfWeek"
          label={t("entities.CutOffCreate.form.dayOfWeek")}
          options={getDayOfWeekTypeOptions(t)}
          required={values?.frequency === "dayOfWeek"}
          disabled={values?.frequency !== "dayOfWeek"}
        />
      ),
      hidden: values.frequency !== "dayOfWeek",
    },
    {
      size: 2,
      field: <TimePickerField id="time" label={t("entities.CutOffCreate.form.time")} required timeSteps={{ hours: 1, minutes: 15 }} />,
    },
  ];
}

import {Grid, Typography} from "@mui/material";
import {getCountryName} from "../../../../common/utils";
import DoneIcon from "@mui/icons-material/Done";

export function listColumns(t, classes) {
  return [
    {
      field: "description",
      headerName: t("entities.AddressesList.columns.description"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <Grid container direction="column">
            <Typography variant="h4" align="left" component="h4" className={classes.nomargin}>
              {v.description}
            </Typography>
            <Typography variant="body1" align="left" component="span" className={classes.nomargin}>
              {v.address}
            </Typography>
          </Grid>
        );
      },
    },
    // {
    //   field: "county",
    //   headerName: t("entities.AddressesList.columns.county"),
    //   align: "left",
    //   headerAlign: "left",
    // },
    {
      field: "state",
      headerName: t("entities.AddressesList.columns.state"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "locale",
      headerName: t("entities.AddressesList.columns.locale"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "postalCode",
      headerName: t("entities.AddressesList.columns.postalCode"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "country",
      headerName: t("entities.AddressesList.columns.country"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return getCountryName(v.country);
      },
    },
    {
      field: "confirmed",
      headerName: t("entities.AddressesList.columns.confirmed"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v.confirmed ? <DoneIcon /> : "";
      }
    },
  ];
}

// address: "rua x"
// confirmed: true
// country: "PT"
// county: "lisbon"
// description: "teste"
// entityId: "51ed166c-08e9-4c28-9cb9-92f1e73182e0"
// id: "6ec8c256-2641-47fd-93e6-870d3da3c7ea"
// latitude: "38.7289539"
// locale: "lisbon"
// longitude: "-9.167658699999999"
// operator: "049d119e-1660-4ceb-be1a-128027a335d3"
// operatorAddress: "1dea2b8c-5a8e-49cc-9b45-056de537eca0"
// postalCode: "1234-123"

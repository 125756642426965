import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";


const businessListAccountingDocsCall = async ({ clearAccountingList, setAccountingList, setAccountingListTotalRows }, dispatch, { filter, clear }) => {
  let rh = requestHelper(dispatch, "LIST_ACCOUNTING_DOCS");
  if (clear) {
    dispatch(clearAccountingList());
  }

  try {
    let list = await call({
      type: "BUSINESS",
      subType: "LIST_ACCOUNTING_DOCS",
      request: {
        limit: filter.limit,
        offset: filter.offset,
        sortField: filter.sortField,
        sortType: filter.sortType,
      },
    });

    if (list && list.documents && list.documents.length > 0) {
      dispatch(setAccountingList(list.documents));
      dispatch(setAccountingListTotalRows(list.rows));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default businessListAccountingDocsCall;

import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Grid} from "@mui/material";

import {styles} from "./ProcessList.styles";
import ProcessDataGrid from "./ProcessDataGrid/ProcessDataGrid";

import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {useNavigate} from "../../../common/hooks";
import Page from "../../../common/displays/Page/Page";
import {
    businessListProcess,
    selectProcessFilter,
    selectProcessList,
    selectProcessListTotalRows
} from "../businessSlice";

function ProcessList({ classes, defaultAudit }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const processFilter = useSelector(selectProcessFilter);
  const processList = useSelector(selectProcessList);
  const processListTotalRows = useSelector(selectProcessListTotalRows);

  return (
    <Page
      permission="business.actions.process.list"
      header={<PageTitle title={t("business.process.header.title")} />}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
            <ProcessDataGrid
              data={processList}
              totalRows={processListTotalRows}
              filter={processFilter}
              onFilterChange={({ filter, reset }) => dispatch(businessListProcess(filter, reset))}
              onRowSelected={({ processId }) => navigate('/activity/process/' + processId)}
            />
        </Grid>
      </Grid>
    </Page>
  );
}

export default withStyles(ProcessList, styles);

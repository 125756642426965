import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./ProductsDataGrid.styles";
import { listColumns } from "./ProductsDataGrid.columns";


function ProductsDataGrid({ classes, data, totalRows }) {
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          columns={listColumns(t, classes)}
          rows={data}
          total={totalRows}
        />
      )}
    </div>
  );
}
export default withStyles(ProductsDataGrid, styles)

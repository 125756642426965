import React from "react";
import { Link, useMatch, useParams, useResolvedPath } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

export default function NavLink({ icon, primary, to, onClick, children, activeClass, hidden }) {

  const { promotercode } = useParams();
  let toUrl = to;
  if (promotercode) {
    toUrl = "/" + promotercode + to;
  }

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => {
        return <Link ref={ref} to={toUrl} {...linkProps} />;
      }),
    [toUrl]
  );

  let resolved = useResolvedPath(toUrl);
  let match = useMatch({ path: resolved.pathname, end: true });

  return hidden ? null : (
    <li
      className={match ? activeClass : null}
    >
      <ListItem button component={CustomLink} onClick={onClick}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={primary} />
        {children}
      </ListItem>
    </li>
  );
}

import {call} from "../../../app/websocket/_sliceCalls/websocket";
import {requestHelper, setSuccess} from "../../../app/coreSlice";

const productExportDocumentsCall = async (dispatch, { productIds }) => {
  let rh = requestHelper(dispatch, "PRODUCT_EXPORT_DOCUMENTS");
  console.log(productIds);
  try {
    await call({
      type: "PRODUCT",
      subType: "EXPORT_DOCUMENTS",
      request: {
        productIds
      },
    });
    dispatch(setSuccess("products", "PRODUCT_EXPORT_DOCUMENTS"));
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productExportDocumentsCall;

export const styles = (theme) => ({
  root: {
  },
  lang: {
    margin: 0,
    textTransform: "uppercase",
    cursor: "pointer",
    padding: "0 " + theme.spacing(1),
    "&:hover": {
      background: theme.palette.background.menuActive
    }
  },
  hasValue: {
    color: theme.palette.primary.main
  },
  selected: {
    fontWeight: "bold"
  },
  input: {
    borderRadius: 5,
    backgroundColor: theme.palette.background.input,
    "& div fieldset": {
      borderColor: theme.palette.action.active,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main + " !important",
    },
    "& input": {
      backgroundColor: "transparent"
    },
    "& textarea": {
      padding: "0px !important"
    }
  },
});
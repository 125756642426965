import i18next from "i18next";
import { diffArrays, diffWordsWithSpace } from "diff";
import { dateFormat, getCountryName, mergeObjects } from "../../../../common/utils";

export const productFields = {
  baseInfo: {
    gtin: "TEXT",
    gln: "TEXT",
    ownerId: "REF",
    brand: "TEXT",
    description: "TEXT",
    addressId: "REF",
    supplierInternalCode: "TEXT",
    promoterInternalCode: "HIDDEN",
    available: "ENUM",
    active: "TEXT",
    gpcCategoryCode: "REF",
    familyCode: "REF",
    familyDescription: "HIDDEN",
    ownerName: "HIDDEN",
    ownerTaxNumber: "HIDDEN",
    addressDescription: "HIDDEN",
    format: "TEXT",
    contactPoint: "TEXT",
    promoterDescription: "HIDDEN",
    productTemplate: "HIDDEN",
    countryOfOrigin: "REF",
    scheduleAvailable: "ENUM",
    scheduleAvailableDate: "DATE",
    replacementProduct: "PRODUCT",
    productClass: "REF"
  },
  units: {
    isBaseUnit: "TEXT",
    isDispatchUnit: "TEXT",
    isConsumerUnit: "TEXT",
    unitDescriptor: "ENUM",
    transportUnit: "ENUM",
    quantityOfLayers: "TEXT",
    quantityOfItemsByLayer: "TEXT",
    quantityOfItems: "TEXT",
    transactionUnit: "ENUM",
  },
  weightMeasure: {
    hasVariableDimensions: "TEXT",
    height: "MEASURE",
    width: "MEASURE",
    depth: "MEASURE",
    netWeight: "MEASURE",
    grossWeight: "MEASURE",
    netContent: "MEASURE",
    conversionFactor: "MEASURE",
  },
  orders: { minQuantity: "TEXT", maxQuantity: "TEXT", quantityMultiple: "TEXT" },
  management: {
    hasBatchNumber: "TEXT",
    hasExpirationDate: "TEXT",
    hasVariableWeight: "TEXT",
    hasSerialNumber: "TEXT",
  },
  storage: { transportTemperature: "ENUM", storageTemperature: "ENUM", lifecycle: "TEXT", perishable: "ENUM" },
  // taxes: {
  //   vat: "ENUM",
  //   other: "LIST"
  // }
};

export const diffFieldsParser = (promoter, details, t, lang) => {
  let parsedFields = {};
  let countDiffs = 0;

  Object.keys(productFields).forEach((section) => {
    parsedFields[section] = {};
    Object.keys(productFields[section]).forEach((field) => {
      let fieldType = productFields[section][field];
      if (fieldType !== "HIDDEN" && fieldType !== "MEASURE") {
        let value = details?.product?.[section]?.[field];
        let newValue = details?.newProduct?.[section]?.[field];
        let valueObj, newValueObj;
        switch (fieldType) {
          case "ENUM":
            switch (field) {
              case "available":
              case "scheduleAvailable":
                value = value ? t("common.Enums.productAvailable." + value) : "";
                newValue = newValue ? t("common.Enums.productAvailable." + newValue) : "";
                break;
              case "unitDescriptor":
              case "transportUnit":
              case "transactionUnit":
                value = value ? t("common.Enums.transport." + value) : "";
                newValue = newValue ? t("common.Enums.transport." + newValue) : "";
                break;
              case "transportTemperature":
              case "storageTemperature":
                value = value ? t("common.Enums.temperature." + value) : value;
                newValue = newValue ? t("common.Enums.temperature." + newValue) : newValue;
                break;
              case "vat":
                value = value ? t("common.Enums.vat." + value) : value;
                newValue = newValue ? t("common.Enums.vat." + newValue) : newValue;
                break;
              case "perishable":
                value = value ? t("common.Enums.perishable." + value) : value;
                newValue = newValue ? t("common.Enums.perishable." + newValue) : newValue;
                break;
              default:
                break;
            }
            break;
          case "REF":
            switch (field) {
              case "ownerId":
                value = details?.product?.[section]?.ownerName;
                newValue = details?.newProduct?.[section]?.ownerName;
                break;
              case "addressId":
                value = details?.product?.[section]?.addressDescription;
                newValue = details?.newProduct?.[section]?.addressDescription;
                break;
              case "familyCode":
                value = details?.product?.[section]?.familyDescription?.[lang] || value;
                newValue = details?.newProduct?.[section]?.familyDescription?.[lang] || newValue;
                break;
              case "countryOfOrigin":
                value = details?.product?.[section]?.countryOfOrigin ? getCountryName(details?.product?.[section]?.countryOfOrigin) : "";
                newValue = details?.newProduct?.[section]?.countryOfOrigin ? getCountryName(details?.newProduct?.[section]?.countryOfOrigin) : "";
                break;
              case "gpcCategoryCode":
                value = getProductCategoryDesignation(promoter, value);
                newValue = getProductCategoryDesignation(promoter, newValue);
                break;
              case "productClass":
                value = getProductClassDesignation(promoter, value);
                newValue = getProductClassDesignation(promoter, newValue);
                break;
              default:
                break;
            }
            break;
          case "JSON":
            value = value ? JSON.stringify(value) : "";
            newValue = newValue ? JSON.stringify(newValue) : "";
            break;
          case "LIST": {
            value = (value || []).map((item) => `${t('common.Enums.taxGroup.' + item.code)}: ${item.value} ${item.type === "A" ? "€" : "%"}`);
            newValue = (newValue || []).map((item) => `${t('common.Enums.taxGroup.' + item.code)}: ${item.value} ${item.type === "A" ? "€" : "%"}`);
            break;
          }
          case "DATE":
            value = dateFormat(value);
            newValue = dateFormat(newValue);
            break;
          case "PRODUCT":
            switch (field) {
              case "replacementProduct":
                valueObj = details?.product?.[section]?.replacementInfo;
                newValueObj = details?.newProduct?.[section]?.replacementInfo;
                break;
              default:
                break;
            }
            break;
          default:
            value = value?.toString();
            newValue = newValue?.toString();
            break;
        }


        let diff = fieldType === "LIST" ? diffArrays(value || [], newValue || []) : diffWordsWithSpace(value || "", newValue || "");
        let hasDiff = Boolean(diff?.length > 1 || diff?.[0]?.added || diff?.[0]?.removed);
        if (hasDiff) {
          countDiffs++;
        }
        if (hasDiff && (fieldType === "ENUM" || fieldType === "REF")) {
          diff = [
            {
              removed: true,
              value,
            },
            {
              added: true,
              value: newValue,
            },
          ];
        }
        parsedFields[section][field] = {
          type: fieldType,
          field,
          section,
          value,
          newValue,
          diff,
          hasDiff,
          valueObj,
          newValueObj
        };
      } else if (fieldType === "MEASURE") {
        // let value = details?.product?.[section]?.[field];
        // let newValue = details?.newProduct?.[section]?.[field];
        parsedFields[section][field] = {
          type: "MEASURE",
        };
        parsedFields[section][field].category = jsonFieldAttributeParser(
          details,
          fieldType,
          section,
          field,
          "category"
        );
        parsedFields[section][field].unit = jsonFieldAttributeParser(details, fieldType, section, field, "unit");
        parsedFields[section][field].value = jsonFieldAttributeParser(details, fieldType, section, field, "value");
        parsedFields[section][field].valueMax = jsonFieldAttributeParser(
          details,
          fieldType,
          section,
          field,
          "valueMax"
        );
        if (parsedFields[section][field].category.hasDiff) {
          countDiffs++;
        }
        if (parsedFields[section][field].unit.hasDiff) {
          countDiffs++;
        }
        if (parsedFields[section][field].value.hasDiff) {
          countDiffs++;
        }
        if (parsedFields[section][field].valueMax.hasDiff) {
          countDiffs++;
        }
        parsedFields[section][field].hasDiff =
          parsedFields[section][field].category.hasDiff ||
          parsedFields[section][field].unit.hasDiff ||
          parsedFields[section][field].value.hasDiff ||
          parsedFields[section][field].valueMax.hasDiff;
      }
    });
  });

  let keysToCheck = {};
  Object.keys(productFields).forEach((section) => {
    keysToCheck[section] = Object.values(parsedFields[section])
      .filter((item) => item.hasDiff && item.type !== "MEASURE")
      .map((item) => [section, item.field].join("."));
    if (section === "weightMeasure") {
      Object.values(parsedFields[section]).forEach((item) => {
        if (item.type === "MEASURE") {
          if (item.category.hasDiff) {
            keysToCheck[section].push([section, item?.category?.field, "category"].join("."));
          }
          if (item.unit.hasDiff) {
            keysToCheck[section].push([section, item?.unit?.field, "unit"].join("."));
          }
          if (item.value.hasDiff) {
            keysToCheck[section].push([section, item?.value?.field, "value"].join("."));
          }
          if (item.valueMax.hasDiff) {
            keysToCheck[section].push([section, item?.valueMax?.field, "valueMax"].join("."));
          }
        }
      });
    }
  });

  return { keysToCheck, parsedFields, countDiffs };
};

const jsonFieldAttributeParser = (details, fieldType, section, field, attribute) => {
  let value = details?.product?.[section]?.[field]?.[attribute]?.toString();
  let newValue = details?.newProduct?.[section]?.[field]?.[attribute]?.toString();
  let diff = diffWordsWithSpace(value || "", newValue || "");
  let hasDiff = Boolean(diff?.length > 1 || diff?.[0]?.added || diff?.[0]?.removed);
  return {
    type: fieldType,
    field,
    section,
    value,
    newValue,
    diff,
    hasDiff,
  };
};

export const getDeepValueWithLongKey = (object, key) => {
  let result = key.split(".").reduce((agg, k) => {
    if (agg === undefined) {
      return undefined;
    }
    return agg[k];
  }, object);
  return result;
};

export const setDeepValueWithLongKey = (object = {}, key, value) => {
  let result = key
    .split(".")
    .reverse()
    .reduce((agg, k) => {
      return { [k]: agg };
    }, value);
  return mergeObjects(object, result);
};

const getProductCategoryDesignation = (promoter, value) => {
  if (!value) {
    return "";
  }
  let productClass = promoter?.refData?.gpcCategories?.find(item => item.code === value);
  if (productClass) {
    let altLang = Object.keys(productClass.description).filter((k) => k !== i18next.language)[0];
    let description = productClass.description[i18next.language] || productClass.description[altLang];
    if (description) {
      return value + " - " + description;
    }
  }
  return "";
}

const getProductClassDesignation = (promoter, value) => {
  if (!value) {
    return "";
  }
  let productClass = promoter?.refData?.productClasses?.find(item => item.code === value);
  if (productClass) {
    let altLang = Object.keys(productClass.name).filter((k) => k !== i18next.language)[0];
    let name = productClass.name[i18next.language] || productClass.name[altLang];
    if (name) {
      return name;
    }
  }
  return "";
}
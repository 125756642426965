import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const entityDisableCall = async ({ entityList, updateSelectedEntity }, dispatch, { filter, id }) => {
  let rh = requestHelper(dispatch, "ENTITY_DISABLE");
  try {
    await call({
      type: "ENTITY",
      subType: "DISABLE",
      request: { id },
    });
    dispatch(setSuccess("entities", "ENTITY_DISABLE"));
    dispatch(updateSelectedEntity({ entityId: id, status: "DIS" }));
    setTimeout(() => {
      dispatch(entityList(filter, true));
    }, 300);
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityDisableCall;

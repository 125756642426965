import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { usePromoter } from "./usePromoter";
import { useUser } from "./useUser";
import { useLocation } from "react-router-dom";

export function useFirstLoad(cb) {
  const [loaded, setLoaded] = useState(false);
  const connected = useSelector((state) => state.websocket.connected);
  const connecting = useSelector((state) => state.websocket.connecting);
  const loading = useSelector((state) => state.core.loader.length > 0);
  const promoter = usePromoter();
  const user = useUser();
  const location = useLocation();
  let mountCb = useRef();
  let unmountCb = useRef();

  useEffect(() => {
    return () => {
      unmountCb.current && unmountCb.current();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (connected && !loading && !loaded && !connecting && promoter && user) {
      if (cb) {
        // console.log("### FIRST LOAD TRIGGER (%s) -> %o", location?.pathname, { location, loaded, connected, connecting, loading, promoter, user });
        mountCb.current = cb;
        unmountCb.current = cb();
      }
      setLoaded(true);
    }
    // eslint-disable-next-line
  }, [connected, loading, promoter, user, location]);

  useEffect(() => {
    if (loaded && cb) {
      // console.log("$$$ FIRST LOAD TRIGGER (location changed -> %s) -> %o", location?.pathname, { location, loaded, connected, loading, promoter, user });
      mountCb.current = cb;
      mountCb.current();
    }
    // eslint-disable-next-line
  }, [location]);
}

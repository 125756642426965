export const styles = (theme) => ({
  root: {
  },
  added: {
    color: theme.palette.success.main,
    backgroundColor: "#cff3f1",
  },
  removed: {
    color: theme.palette.error.main,
    backgroundColor: "#fad4d4",
    textDecoration: "line-through",
  },
});
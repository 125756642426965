import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid } from "@mui/material";

import { styles } from "./FormTitle.styles.js";

function FormTitle({ classes, title, subtitle, error, info }) {
  return (
    <Grid className={classes.root} container direction="column">
      <Grid className={classes.root} container direction="row">
        <Typography component="h3" variant="h3" className={classes.title}>
          {title}
        </Typography>

        {error && (
          <Typography variant="caption" color="error" className={classes.error}>
            {error}
          </Typography>
        )}
        {info && (
          <Typography variant="caption" color="secondary" className={classes.info}>
            {info}
          </Typography>
        )}
      </Grid>
      <Typography
        component="h6"
        variant="body1"
        color="textSecondary"
        className={classes.subtitle}
      >
        {subtitle}
      </Typography>
    </Grid>
  );
}

export default withStyles(FormTitle, styles)

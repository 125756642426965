import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Navigate, useParams, useLocation, Routes, useNavigate } from "react-router-dom";
import { useNavigate as useCustomNavigate, useUser, usePromoter } from "../common/hooks";

import Navigation from "./components/Navigation/Navigation";
import { selectRedirect, setRedirect } from "./coreSlice";

import LoadingScreen from "../features/authentication/LoadingScreen/LoadingScreen";

import EntitiesRouting from "../features/entities/EntitiesRouting";
import BusinessRouting from "../features/business/BusinessRouting";
import OrdersRouting from "../features/orders/OrdersRouting";
import ProductsRouting from "../features/products/ProductsRouting";
import OrdersIncidentsRouting from "../features/ordersIncidents/OrdersIncidentsRouting";

import I18n from "../features/i18n/I18n/I18n";
import HomeScreen from "../features/home/HomeScreen/HomeScreen";
import { isPromotercodeValid } from "../features/authentication/authentication.utils";
import PromoterRouting from "../features/promoter/promoterRouting";
import RolesRouting from "../features/roles/rolesRouting";
import EmailsList from "../features/notification/EmailsList/EmailsList";
import EmailTemplate from "../features/notification/EmailTemplate/EmailTemplate";
import DocumentsList from "../features/document/DocumentsList/DocumentsList";
import DocumentTemplate from "../features/document/DocumentTemplate/DocumentTemplate";
import { selectConnecting } from "./websocket/websocketSlice";
import NotAvailablePage from "../common/security/NotAvailablePage/NotAvailablePage";
import Security from "./components/Security/Security";
import Settings from "../features/users/Settings/Settings";
import TasksRouting from "../features/tasks/tasksRouting";
import PlacesRouting from "../features/places/placesRouting";
import ResourcesRouting from "../features/resources/resourcesRouting";
import ClamImageRecognition from "../features/document/ClamImageRecognition/ClamImageRecognition";
import PlanningRouting from "../features/planning/PlanningRouting";
import BusinessUnitsRouting from "../features/businessUnits/businessUnitsRouting";
import BatchesRouting from "../features/batches/batchesRouting";

const MainRouting = ({ classes }) => {
  const user = useUser();
  const promoter = usePromoter();
  const redirect = useSelector(selectRedirect);
  const connecting = useSelector(selectConnecting)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customNavigate = useCustomNavigate();
  let location = useLocation();
  let { promotercode } = useParams();

  useEffect(() => {
    if (redirect) {
      customNavigate(redirect);
      dispatch(setRedirect());
    }
    // eslint-disable-next-line
  }, [redirect]);

  useEffect(() => {
    if (!user && location.pathname.indexOf('/login') > 0) {
      navigate('/login');
    }
    // eslint-disable-next-line
  }, [location])

  // assume logged promoter code, when url dont have promotercode
  if (!promotercode && user?.promoterSelected) {
    return <Navigate to={`/${user?.promoterSelected}`} />;
  }

  if (!isPromotercodeValid(promotercode)) {
    return <Navigate to={`/`} />;
  }

  if (!user && !connecting) {
    return <Navigate to={`/login`} state={{ from: location }} />;
  }

  if (!user || !promoter) {
    return <LoadingScreen />;
  }

  if (
    user.entity.status === "PND" &&
    location.pathname.indexOf(`/entities/${user.entity.id}/confirm`) === -1
  ) {
    return <Navigate to={`/${promotercode}/entities/${user.entity.id}/confirm`} />;
  }

  return (
    <Navigation>
      <Routes>
        <Route path="not-available" element={<NotAvailablePage />} />
        <Route path="batches/*" element={<BatchesRouting />} />
        <Route path="activity/*" element={<BusinessRouting />} />
        <Route path="entities/*" element={<EntitiesRouting />} />
        <Route path="orders/*" element={<OrdersRouting />} />
        <Route path="orders-incidents/*" element={<OrdersIncidentsRouting />} />
        <Route path="products/*" element={<ProductsRouting />} />
        <Route path="promoter/*" element={<PromoterRouting />} />
        <Route path="roles/*" element={<RolesRouting />} />
        <Route path="tasks/*" element={<TasksRouting />} />
        <Route path="resources/*" element={<ResourcesRouting />} />
        <Route path="places/*" element={<PlacesRouting />} />
        <Route path="planning/*" element={<PlanningRouting />} />
        <Route path="business/units/*" element={<BusinessUnitsRouting />} />
        <Route path="i18n" element={<I18n />} />
        <Route path="emails/:id" element={
          <Security component={<EmailTemplate />} paramValidation={[{ name: "id", type: "uuid" }]} />
        } />
        <Route path="emails" element={<EmailsList />} />
        <Route path="documents/:id" element={
          <Security component={<DocumentTemplate />} paramValidation={[{ name: "id", type: "uuid" }]} />
        } />
        <Route path="documents" element={<DocumentsList />} />
        <Route path="settings" element={<Settings />} />
        <Route path="clam-images" element={<ClamImageRecognition />} />
        <Route path="/*" element={<HomeScreen />} />
      </Routes>
    </Navigation>
  );
};

export default MainRouting;

import moment from "moment";
import {requestHelper} from "../../../app/coreSlice";
import {call} from "../../../app/websocket/_sliceCalls/websocket";

const auditListAuditI18nCall = async ({ clearAuditI18nList, setAuditI18nList, setAuditI18nListTotalRows, setAuditI18nFilter }, dispatch, { filter, clear }) => {
  let rh = requestHelper(dispatch, "AUDIT_LIST_AUDIT_I18N");
  if (clear) {
    dispatch(clearAuditI18nList());
  }

  const dateIni = (filter.isDefaultFilter || !filter.dateIni) ? moment().add(-1, "day").toISOString() : filter.dateIni;
  const dateEnd = (filter.isDefaultFilter || !filter.dateEnd) ? moment().toISOString() : filter.dateEnd;

  dispatch(setAuditI18nFilter({
    ...filter,
    dateIni,
    dateEnd
  }));

  try {
    let result = await call({
      type: "AUDIT",
      subType: "LIST_AUDIT_I18N",
      request: {
        ...filter,
        dateIni,
        dateEnd
      }
    });

    if (result && result.items && result.items.length > 0) {
      dispatch(setAuditI18nList(result.items));
      dispatch(setAuditI18nListTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default auditListAuditI18nCall;

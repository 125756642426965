import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const businessReportWeeklyUnitsDeliveredCall = async ({
  clearReportWeeklyUnitsDeliveredList,
  setReportWeeklyUnitsDeliveredList,
  setReportWeeklyUnitsDeliveredListTotalRows,
  setReportWeeklyUnitsDeliveredFilter
}, dispatch, { filter, clear }) => {
  let rh = requestHelper(dispatch, "BUSINESS_REPORT_WEEKLY_UNITS_DELIVERED");
  if (clear) {
    dispatch(clearReportWeeklyUnitsDeliveredList());
  }
  dispatch(setReportWeeklyUnitsDeliveredFilter(filter));

  try {
    const { limit, offset, sortField = "", sortType, week, year, supplier, cutOff } = filter;
    let result = await call({
      type: "BUSINESS",
      subType: "REPORT_WEEKLY_UNITS_DELIVERED",
      request: {
        limit,
        offset,
        sortField: sortField.toUpperCase(),
        sortType,
        week,
        year,
        supplier,
        cutOff
      },
    });

    if (result && result.transportUnitsDeliveredPerDeliveryPlace && result.transportUnitsDeliveredPerDeliveryPlace.length > 0) {
      dispatch(setReportWeeklyUnitsDeliveredList(result.transportUnitsDeliveredPerDeliveryPlace));
      dispatch(setReportWeeklyUnitsDeliveredListTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default businessReportWeeklyUnitsDeliveredCall;
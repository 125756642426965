import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { styles } from "../base/_base.styles";
import moment from "moment";

function TimePickerInput({
  classes,
  id,
  className,
  label,
  value,
  onChange,
  disabled,
  error,
  required,
  variant,
  allowSeconds = false,
  timeSteps
}) {
  let tempId = new Date().getTime().toString();
  return (
    <div className={classes.root + " " + className}>
      {variant === "outlined" && (
        <Typography
          style={{ marginBottom: 8, marginTop: 8 }}
          component="span"
          variant="caption"
          htmlFor={id}
          color="textPrimary"
          className={disabled ? classes.disabledLabel : ""}
        >
          {label}
          {required && <span> *</span>}
        </Typography>
      )}
      <TimePicker
        ampm={false}
        timeSteps={timeSteps}
        value={value ? (moment.isMoment(value) ? value : moment(value)) : null}
        className={classes.input + (disabled ? " " + classes.disabledInput : "")}
        format={allowSeconds ? "HH:mm:ss" : "HH:mm"}
        disabled={Boolean(disabled)}
        onChange={(newValue) => {
          onChange && onChange(newValue);
        }}
        views={allowSeconds ? ["hours", "minutes", "seconds"] : ["hours", "minutes"]}
        slotProps={{ textField: { fullWidth: true, id: tempId, error: error } }}
      />
    </div>
  );
}

export default withStyles(TimePickerInput, styles);

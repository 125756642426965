import { dateTimeFormatWithMilliSeconds } from "../../../../common/utils/formats";

export const csvFields = (t) => {
  return [
    {
      field: "type",
      headerName: t("business.AuditsList.columns.type"),
    },
    {
      field: "subtype",
      headerName: t("business.AuditsList.columns.subType"),
    },
    {
      field: "jobDescription",
      headerName: t("business.AuditsList.columns.jobDescription"),
    },
    {
      field: "startTime",
      headerName: t("business.AuditsList.columns.startTime"),
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.startTime));
      },
    },
    {
      field: "endTime",
      headerName: t("business.AuditsList.columns.endTime"),
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.endTime));
      },
    },
    {
      field: "status",
      headerName: t("business.AuditsList.columns.status"),
      customValue: (v) => {
        return (v.status ? t("business.AuditsList.columns.errorFalse") : t("business.AuditsList.columns.errorTrue"));
      },
    },
  ];
};




import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";

const productRecoverCall = async ({ setProductDetails }, dispatch, { id, processId }) => {
  let rh = requestHelper(dispatch, "PRODUCT_RECOVER");
  try {
    await call({
      type: "PRODUCT",
      subType: "RECOVER",
      request: {
        id,
        processId
      },
    });
    dispatch(setSuccess("products", "PRODUCT_RECOVER"));
    setTimeout(() => {
      dispatch(setProductDetails());
      dispatch(setRedirect("/products/validation"));
    }, 300);
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productRecoverCall;

export const styles = theme => ({
  root: {},
  leftContainer: {
    position: "absolute",
    left: 0,
    width: "50vw",
    bottom: 0,
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      width: "100vw",
    },
  },
  rightContainer: {
    position: "absolute",
    right: 0,
    width: "50vw",
    bottom: 0,
    top: 0,
    zIndex: 0,
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down('sm')]: {
      display: "none"
    },
  },
  grid: {
    height: "100vh",
    [theme.breakpoints.down('sm')]: {
      padding: "80px 0",
    },
  },
  logo: {
    position: "absolute",
    width: 270,
    top: 16,
    right: 0,
    left: 0,
    margin: "0 auto",
    height: 70,
    "& img": {
      height: "100%"
    },
  },
  snackBar: {
    position: "absolute",
    left: 0,
    right: 0,
    width: "270px",
    margin: "0 auto",
    bottom: 20,
  },
});
import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid } from "@mui/material";

import { styles } from "./_base.styles";
import { toggleItem } from "../../utils/utils";

function CardSelectRawInput({ classes, options, xs, selectedValue, label, disabled, required, onChange, cardContent, multi = false }) {

  const onChangeHandler = (currentSelected, value) => {
    if (disabled) {
      return;
    }
    if (multi) {
      onChange && onChange(toggleItem(currentSelected || [], value));
    }
    else {
      onChange && onChange(value)
    }
  }

  return (
    <div className={classes.root}>
      {label && (
        <Typography
          component="span"
          variant="caption"
          color="textPrimary"
          className={disabled ? classes.disabledLabel : ""}
        >
          {label}
          {required && <span> *</span>}
        </Typography>
      )}
      <Grid container spacing={2}>
        {(options || []).map((item) => {
          const isSelected = multi ? (Array.isArray(selectedValue) ? selectedValue : []).some(v => v === item.value) : item.value === selectedValue
          return (
            <Grid item key={`option_${item.value}`} xs={xs}>
              <div
                className={
                  classes.card +
                  (isSelected ? " " + classes.cardSelected : "") +
                  (disabled ? " " + classes.cardDisabled : "")
                }
                onClick={() => onChangeHandler(selectedValue, item.value)}
              >
                <div className={classes.cardContainer}>
                  {cardContent(item)}
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default withStyles(CardSelectRawInput, styles);

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Checkbox } from "@mui/material";

import { styles } from "./FormsDiff.styles";
import CustomFormsDetails from "../../../ProductDetails/CustomFormsDetails/CustomFormsDetails";

function FormsDiff({ classes, value, newValue, hasDiff, onCheck, checked, viewMode }) {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container direction="column">
        <Grid item
          className={classes.headerContainer}>
          <Typography variant="h4" className={classes.header}>
            {t("products.ProductValidationForm.section.forms")}
          </Typography>
        </Grid>
        <Grid item >
          <Grid container spacing={2} wrap="nowrap" >
            <Grid item flex={1}>
              <Grid container spacing={1}>
                <CustomFormsDetails forms={value || {}} diff />
              </Grid>
            </Grid>
            <Grid item flex={1}>
              <Grid container spacing={1}>
                <CustomFormsDetails forms={newValue || {}} diff oldForms={value}  />
              </Grid>
            </Grid>
            <Grid item style={{ width: 56 }}>
              {hasDiff && !viewMode && (
                <Checkbox color="success" checked={checked} onChange={(e) => onCheck(e.target.checked)} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(FormsDiff, styles);
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";
import { Typography, Button, Grid } from "@mui/material";

import { styles } from "./ProductPicker.styles";

import ProductPickerDataGrid from "./ProductPickerDataGrid/ProductPickerDataGrid";
import ProductPickerModal from "./ProductPickerModal/ProductPickerModal";
import {
  selectProductPickerListTotalRows,
  selectProductPickerListFilter,
  selectProductPickerList,
  productPickerList,
} from "../../../../features/products/productsSlice";
import { classJoin } from "../../../utils";

function ProductPicker({
  classes,
  label,
  height = 300,
  single,
  simple,
  selectedProducts,
  onProductsSelectedChange,
  allProducts,
  onAllProductsChange,
  readonly,
  productsSet,
  customFilters = { justConsumer: true },
  blackListedProducts,
  disabled,
  ownerId
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const products = useSelector(selectProductPickerList);
  const filter = useSelector(selectProductPickerListFilter);
  const totalRows = useSelector(selectProductPickerListTotalRows);
  const [productCollection, setProductCollection] = useState();

  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (modal && !productsSet) {
      dispatch(productPickerList({ ...filter, ...customFilters, owner: ownerId || undefined, search: undefined }, true));
    }
    // eslint-disable-next-line
  }, [modal, ownerId]);

  useEffect(() => {
    if (products && !productsSet) {
      setProductCollection((products || []).filter(item => !(blackListedProducts || []).includes(item.productId)));
    }
    // eslint-disable-next-line
  }, [products]);

  useEffect(() => {
    if (productsSet) {
      setProductCollection((productsSet || []).filter(item => !(blackListedProducts || []).includes(item.productId)));
    }
    // eslint-disable-next-line
  }, [productsSet]);

  const onFilterChangeHandler = (filter, clear) => {
    if (!productsSet) {
      dispatch(productPickerList({ ...filter, ...customFilters }, clear));
    }
  };

  const onEditHandler = () => {
    setModal(true);
  }


  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between">
        <Grid item>{label}</Grid>
        {!disabled && <Grid item hidden={readonly}>
          <Button color="primary" onClick={onEditHandler}>
            {t("common.ProductPicker.input.editBtn")}
          </Button>
        </Grid>}
      </Grid>
      {selectedProducts && selectedProducts.length > 0 ? (
        <ProductPickerDataGrid
          products={selectedProducts}
          localFilters
          hideCheckbox
          simple={simple}
          style={height && !simple ? { maxHeight: height, overflowY: "auto" } : {}}
        />
      ) : allProducts ? (
        <Typography
          component="h5"
          variant="h5"
          color="primary"
          className={classes.emptytable}
          onClick={() => !disabled && setModal(true)}
        >
          {t("common.ProductPicker.input.all")}
        </Typography>
      ) : (
        <Typography
          component="h5"
          variant="h5"
          color="textSecondary"
          className={classJoin([classes.emptytable, disabled ? classes.disabled : ""])}
          onClick={() => !disabled && setModal(true)}
        >
          {t("common.ProductPicker.input.emptytable")}
        </Typography>
      )}
      <ProductPickerModal
        localFilters={Boolean(productsSet)}
        open={modal}
        setOpen={setModal}
        products={productCollection}
        totalRows={totalRows}
        selectedProducts={selectedProducts}
        filter={filter}
        onFilterChange={onFilterChangeHandler}
        onProductsSelectedChange={onProductsSelectedChange}
        single={single}
        allProducts={allProducts}
        onAllProductsChange={onAllProductsChange}
      />
    </div>
  );
}

export default withStyles(ProductPicker, styles);

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { styles } from "./MeasureInput.styles";
import { NumberWithSelectInput } from "../../inputs";

function MeasureInput({ classes, value, onChange, disabled, error, fixedCategory }) {
  const { t } = useTranslation();
  const onValueChangeHandler = (v) => {
    onChange && onChange({ ...value, value: v });
  };

  const onChangeUnitHandler = (v) => {
    let category = "";
    switch (v) {
      case "KGM":
      case "GRM":
      case "TNE":
        category = "MASS";
        break;
      case "MTR":
      case "CMT":
      case "MMT":
        category = "COMP";
        break;
      case "LTR":
      case "CLT":
      case "MLT":
        category = "COMP";
        break;
      case "H87":
        category = "OUTR";
        break;
      default:
        break;
    }
    onChange && onChange({ ...value, unit: v, category });
  };

  const optionsByCat = {
    MASS: [
      { value: "MASS", text: t("common.Enums.measureCategory.MASS"), disabled: true },
      { value: "KGM", text: t("common.Enums.measureUnits.KGM") },
      { value: "GRM", text: t("common.Enums.measureUnits.GRM") },
      { value: "TNE", text: t("common.Enums.measureUnits.TNE") },
    ],
    COMP: [
      { value: "COMP", text: t("common.Enums.measureCategory.COMP"), disabled: true },
      { value: "MTR", text: t("common.Enums.measureUnits.MTR") },
      { value: "CMT", text: t("common.Enums.measureUnits.CMT") },
      { value: "MMT", text: t("common.Enums.measureUnits.MMT") }
    ],
    VOLU: [
      { value: "VOLU", text: t("common.Enums.measureCategory.VOLU"), disabled: true },
      { value: "LTR", text: t("common.Enums.measureUnits.LTR") },
      { value: "CLT", text: t("common.Enums.measureUnits.CLT") },
      { value: "MLT", text: t("common.Enums.measureUnits.MLT") }
    ],
    OUTR: [
      { value: "OUTR", text: t("common.Enums.measureCategory.OUTR"), disabled: true },
      { value: "H87", text: t("common.Enums.measureUnits.H87") }
    ],
  };

  let options = []
  if (fixedCategory) {
    options = optionsByCat[fixedCategory];
  }
  else {
    options = [...optionsByCat["MASS"], ...optionsByCat["COMP"], ...optionsByCat["VOLU"], ...optionsByCat["OUTR"]];
  }

  let defaultUnit = null;
  switch (value.category) {
    case "COMP":
      defaultUnit = "CMT";
      break;
    case "VOLU":
      defaultUnit = "LTR";
      break;
    case "OUTR":
      defaultUnit = "H87";
      break;
    case "MASS":
      defaultUnit = "GRM";
      break;
    default:
      break;
  }

  return (
    <Grid className={classes.root}>
      <NumberWithSelectInput
        value={value.value}
        disabled={disabled}
        error={error}
        onChange={onValueChangeHandler}
        selectValue={value.unit || defaultUnit}
        onSelectChange={onChangeUnitHandler}
        options={options}
        allowNull
      />
    </Grid>
  );
}

export default withStyles(MeasureInput, styles);

import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Grid, Paper, Typography} from "@mui/material";
import beautify from "xml-beautifier";

import {styles} from "./AuditWsDetails.styles";

import {useFirstLoad, useNavigate} from "../../../common/hooks";
import {auditGetAuditWs, selectAuditWsDetails} from "../businessSlice";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import LabelValue from "../../../common/displays/LabelValue/LabelValue";
import {dateTimeFormatWithMilliSeconds} from "../../../common/utils/formats";
import {jsonTryParse} from "../../../common/utils";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

function AuditWsDetails({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const details = useSelector(selectAuditWsDetails);

  useFirstLoad(() => {
    dispatch(auditGetAuditWs(id))
  });

  if (!details) {
    return null;
  }

  let request = details?.type === "REST" ? jsonTryParse(details?.request) : beautify(details?.request);
  let response = details?.type === "REST" ? jsonTryParse(details?.response) : beautify(details?.response);

  return (
    <Page
      permission="business.actions.audit.get"
      header={
        <PageTitle
          title={t("business.audit.ws.title") + " - " + details.operation}
          onBack={() => navigate('/activity/audits/ws')}
        />
      }>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.audit.ws.details_operation")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.operation}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.audit.ws.details_status")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.error ? t("business.audit.ws.columns_error_true") : t("business.audit.ws.columns_error_false")}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.audit.ws.details_user")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.userRequest}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.audit.ws.details_ipAddress")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.ipAddress}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.audit.ws.details_entity")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.entity}
                    </Typography>
                  }
                /><LabelValue
                  label={t("business.audit.ws.details_type")}
                  value={
                      <Typography variant="body1" align="left" component="span">
                          {details.type}
                      </Typography>
                  }
              />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <LabelValue
                  label={t("business.audit.ws.details_requestTime")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {dateTimeFormatWithMilliSeconds(details.requestTime)}
                    </Typography>
                  }
                />
                  {details?.type === "REST" && <LabelValue
                      label={t("business.audit.ws.details_request")}
                      value={
                          typeof request === "object" ? <div className={classes.json}>
                              <JSONInput
                                  id="json-input"
                                  placeholder={request}
                                  viewOnly
                                  locale={locale}
                                  confirmGood={false}
                                  theme={"light_mitsuketa_tribute"}
                              />
                          </div> : request
                      }
                  /> }
                  {details?.type === "SOAP" &&
                      <LabelValue
                      label={t("business.audit.ws.details_request")}
                      value={
                          <Typography variant="body1" align="left" component="span">
                      <pre className={classes.soap} >
                        {request}
                      </pre>
                          </Typography>
                      }
                  />
                 }

              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <LabelValue
                  label={t("business.audit.ws.details_responseTime")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {dateTimeFormatWithMilliSeconds(details.responseTime)}
                    </Typography>
                  }
                />
                  {details?.type === "REST" && <LabelValue
                      label={t("business.audit.ws.details_response")}
                      value={
                          typeof response === "object" ? <div className={classes.json}>
                              <JSONInput
                                  id="json-input"
                                  placeholder={response}
                                  viewOnly
                                  locale={locale}
                                  confirmGood={false}
                                  theme={"light_mitsuketa_tribute"}
                              />
                          </div> : response
                      }
                  />
                  }
                  {details?.type === "SOAP" &&
                      <LabelValue
                          label={t("business.audit.ws.details_response")}
                          value={
                              <Typography variant="body1" align="left" component="span">
                      <pre className={classes.soap}>
                        {response}
                      </pre>
                              </Typography>
                          }
                      />

                  }
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

export default withStyles(AuditWsDetails, styles);
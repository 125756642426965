import React from "react";
import * as Yup from "yup";
import { TextField } from "../../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    numberDays: Yup.string().required(t("promoter.PromoterConfigs.retention.form_requiredNumberDays")),
  });
}

export function formFields(t, edit) {
  return [
    {
      size: 12,
      field: (
        <TextField
          id="tableName"
          disabled={true}
          type="text"
          label={t("promoter.PromoterConfigs.retention.form_tableName")}
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextField
          id="numberDays"
          label={t("promoter.PromoterConfigs.retention.form_numberDays")}
          required
          disabled={!edit}
        />
      ),
    },
  ];
}

import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, Button, Typography, Modal, TextField, Switch, IconButton } from "@mui/material";

import { styles } from "./VolumesModal.styles";
import PageTitle from "../../../../../common/displays/PageTitle/PageTitle";
import MeasureInput from "../../../../../common/components/inputs/MeasureInput";
import OrderProductsDataGrid from "../../OrderProductsDataGrid/OrderProductsDataGrid";
import { mergeArraysByKey, replaceObjectInArray } from "../../../../../common/utils";
import { getNewVolumeProducts } from "../../../orders.utils";

function VolumesModal({ classes, volumes, products, onChange }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [vProducts, setVProducts] = useState([]);

  useEffect(() => {
    if (products) {
      let tempProducts = [];
      products.forEach((prod) => {
        let prodIndex = tempProducts.findIndex((item) => item.productId === prod.productId);
        if (prodIndex >= 0) {
          tempProducts[prodIndex].quantity += prod.quantity;
        } else {
          tempProducts.push({ ...prod });
        }
      });
      setVProducts(tempProducts);
    } else {
      setVProducts([]);
    }
  }, [products]);

  const fieldChangeHandler = (field, index, val) => {
    let nValues = [...(volumes || [])];
    nValues[index][field] = val;
    onChange && onChange(nValues);
  };

  const removeVolumeHandler = (index) => {
    let nValues = [...(volumes || [])];
    nValues.splice(index, 1);
    onChange && onChange(nValues);
  };

  const addVolumeHandler = () => {
    let nValues = [...(volumes || [])];
    nValues.push({
      code: "",
      weight: {
        category: "MASS",
        unit: "KGM",
        value: 0,
      },
      full: false,
      products: getNewVolumeProducts(volumes, vProducts),
    });

    onChange && onChange(nValues);
  };

  const onQuantityChangedHandler = (index, product) => {
    let nValues = [...(volumes || [])];

    // if (product.quantity > product.quantityHist.accepted) {
    //   product.quantity = product.quantityHist.accepted;
    // }
    let nProducts = replaceObjectInArray(nValues[index]["products"], product, "key");
    nValues[index]["products"] = nProducts;
    onChange && onChange(nValues);
  };

  return (
    <div className={classes.root}>
      <Button variant="contained" color="primary" size="large" onClick={() => setOpen(true)}>
        {t("orders.VolumesModal.modal.volumesBtn", {
          length: (volumes || []).length,
        })}
      </Button>
      <Modal open={open} className={classes.modal} onClose={() => setOpen(false)}>
        <div className={classes.modalroot}>
          <PageTitle
            title={t("orders.VolumesModal.modal.title")}
            actions={
              <Grid container spacing={2}>
                <Grid item>
                  <Button variant="contained" color="primary" size="large" onClick={() => setOpen(false)}>
                    {t("orders.VolumesModal.modal.closeBtn")}
                  </Button>
                </Grid>
              </Grid>
            }
          />

          <Grid container className={classes.modalbody} direction="column">
            <Grid item style={{ flexGrow: 1 }} className={classes.gridBox}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {(volumes || []).map((item, index) => {
                    return (
                      <Grid container key={"volume_" + index} spacing={1}>
                        <Grid item xs={4}>
                          <Typography component="span" variant="caption" color="textPrimary">
                            {t("orders.VolumesModal.modal.code")}
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            value={item.code}
                            onChange={(e) => fieldChangeHandler("code", index, e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography component="span" variant="caption" color="textPrimary">
                            {t("orders.VolumesModal.modal.weight")}
                          </Typography>
                          <MeasureInput
                            value={item.weight}
                            onChange={(val) => fieldChangeHandler("weight", index, val)}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Typography component="span" variant="caption" color="textPrimary">
                            {t("orders.VolumesModal.modal.full")}
                          </Typography>
                          <Switch
                            color="primary"
                            checked={Boolean(item.full)}
                            onChange={(e) => fieldChangeHandler("full", index, e.target.checked)}
                          />
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "right" }}>
                          <IconButton
                            className={classes.deleteButton}
                            onClick={() => removeVolumeHandler(index)}
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                          <OrderProductsDataGrid
                            products={(mergeArraysByKey(vProducts, item.products, "key") || []).map((p) => {
                              return {
                                ...p,
                                maxQuantity: getProductMaxQuantity(volumes, products, p.productId, index),
                              };
                            })}
                            onQuantityChanged={(products) => onQuantityChangedHandler(index, products)}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" size="small" onClick={() => addVolumeHandler()}>
                    {t("orders.VolumesModal.modal.addVolumeBtn")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}

export default withStyles(VolumesModal, styles)

const getProductMaxQuantity = (volumes, products, productId, volIndex) => {
  let quantityAccepted = (products || []).reduce((agg, item) => {
    if (item.productId === productId) {
      // return agg + (item?.quantityHist?.accepted || 0);
      return agg + (item?.quantity || 0);
    }
    return agg;
  }, 0);

  let quantityPacked = (volumes || []).reduce((agg, vol, index) => {
    if (index === volIndex) {
      return agg;
    }
    return (vol.products || []).reduce((aggp, product) => {
      if (product.productId === productId) {
        return aggp + (product?.quantity || 0);
      }
      return aggp;
    }, 0);
  }, 0);

  let r = quantityAccepted - quantityPacked;

  return r;
};

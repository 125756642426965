const places = {
  flowsMessages: {
    success: {
      PLACE_CREATE: "Place created successfully!",
      PLACE_UPDATE: "Place updated successfully!",
      PLACE_DELETE: "Place deleted successfully!",
      PLACE_DISABLE: "Place disabled successfully!",
      PLACE_ENABLE: "Place enabled successfully!",
    },
    error: {
      GENERIC: "Something went wrong",
    },
  },
  PlaceList: {
    header: {
      title: "Places",
    },
    filter: {
      status: "Status",
      allStatusOption: "Clear",
      status_true: "Active",
      status_false: "Inactive",
      type: "Type",
      search: "Search",
    },
    columns: {
      children: "Contains",
      gln: "GLN",
      parent: "Parent",
      status: "Status",
      title: "Title",
      type: "Type",
    },
    actions: {
      edit: "Edit",
      copy: "Copy",
      remove: "Remove",
      enable: "Enable",
      disable: "Disable",
    }
  },
  PlaceForm: {
    header: {
      title: "Create Place",
      titleEdit: "Edit Place",
    },
    form: {
      btnSubmit: "Submit",
      name: "Designation",
      nameRequired: "Designation is required",
      type: "Type",
      typeRequired: "Type is required",
      gln: "GLN",
      glnRequired: "GLN is required",
      addressId: "Address",
      contactId: "Contact",
      parentId: "Parent Location",
      parentIdRequired: "Parent place is required",
      height: "Height",
      width: "Width",
      depth: "Depth",
      latitude: "Latitude",
      longitude: "Longitude",
      temperature: "Room temperature"
    }
  },
};

export default places;
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useParams } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { styles } from "./OrdersCreate.styles";
import DeliveryDayAndAddressForm from "./DeliveryDayAndAddressForm/DeliveryDayAndAddressForm";
import ProductsDataGrid from "./ProductsDataGrid/ProductsDataGrid";
import ProductsMosaic from "./ProductsMosaic/ProductsMosaic";
import ImportOrder from "./ImportOrder/ImportOrder";

import { useNavigate } from "../../../common/hooks";
import { usePromoter, useUser } from "../../../common/hooks";
import { getBundleOffers, getPriceWithCampaigns } from "../orders.utils";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import SearchBox from "../../../common/components/inputs/SearchBox";
import { DashboardIcon, ListIcon } from "../../../common/icons";
import Permission from "../../../app/components/Permission";
import {
  selectCurrentOrder,
  selectLoader,
  setProductsCurrentOrder,
  initImportOrder,
} from "../ordersSlice.calls";
import {
  selectOrderProductList,
  selectOrderProductListTotalRows,
  selectOrderProductListFilter,
  productCatalog,
} from "../../products/productsSlice";
import { orderCreate, orderEntityAddresses, orderUpdate, selectOrderAddressesList, setCurrentOrder, setOrderAddresses } from "../ordersSlice";
import Page from "../../../common/displays/Page/Page";

function OrdersCreate({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const loading = useSelector(selectLoader);
  const promoter = usePromoter();
  const user = useUser();
  const [index, setIndex] = useState(0);
  const data = useSelector(selectOrderProductList);
  const filter = useSelector(selectOrderProductListFilter);
  const totalRows = useSelector(selectOrderProductListTotalRows);
  const currentOrder = useSelector(selectCurrentOrder);

  const [mosaicView, setMosaicView] = useState(false);
  const [bundleCampaigns, setBundleCampaigns] = useState([]);
  const orderAddresses = useSelector(selectOrderAddressesList);
  const [autoLoadedAddressForm, setAutoLoadedAddressForm] = useState(false);

  useEffect(() => {
    dispatch(setOrderAddresses());
    if (user.entity?.type !== "P") {
      dispatch(orderEntityAddresses(user.entity.id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (index === 1) {
      dispatch(productCatalog(filter, currentOrder.orderBy, true));
    }
    //eslint-disable-next-line
  }, [index]);

  useEffect(() => {
    if (promoter && orderAddresses &&
      promoter?.getConfig("cutOff", "automatic") &&
      index === 0 &&
      user.entity?.type !== "P" &&
      orderAddresses.length === 1 &&
      (!orderAddresses[0].locations || orderAddresses[0].locations.length === 0 || orderAddresses[0].locations.length === 1)
    ) {
      // WHEN CONDITIONS ARE MET TO AUTO SELECT ADDRESS/LOCATION
      // This means that the user is not the promoter and deliverToId and orderBy are the user's entity
      dispatch(setCurrentOrder({
        deliverToId: user.entity.id,
        orderBy: user.entity.id,
        deliverToAddress: orderAddresses[0].id,
        locationId: orderAddresses[0].locations?.[0]?.id,
        products: [],
      }));
      setIndex(1);
      setAutoLoadedAddressForm(true);
    }
    //eslint-disable-next-line
  }, [promoter, index, orderAddresses]);

  useEffect(() => {
    if (!currentOrder || !currentOrder.deliverToId) {
      if (id && id > 0) {
        navigate("/orders/" + id);
      }
      setIndex(0);
    } else {
      setIndex(1);
    }
    // eslint-disable-next-line
  }, [currentOrder]);

  useEffect(() => {
    if (data) {
      let bc = [];
      data
        .filter((item) => item.campaigns && item.campaigns?.find((cp) => cp.active && cp.campaignType === "BU"))
        .forEach((product) => {
          (product?.campaigns || []).forEach((camp) => {
            if (camp.campaignType === "BU" && !bc.find((item) => item.id === camp.id)) {
              bc.push(camp);
            }
          });
        });
      setBundleCampaigns(bc);
    }
  }, [data]);

  const onDayAndAddressComplete = ({ deliveryDay, locationId, address, orderBy, comments }) => {
    dispatch(setCurrentOrder({
      deliverToId: orderBy || user?.entity?.id,
      orderBy: orderBy || user?.entity?.id,
      deliverToAddress: address,
      locationId,
      cutOff: deliveryDay,
      comments: comments,
      products: [],
    }));
    setIndex(1);
  };

  const onImportFile = (values) => {
    dispatch(initImportOrder(values.orderBy || user?.entity?.id, values.address, values.comments, values.deliveryDay));
    setIndex(2);
  };

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    dispatch(productCatalog(nFilter, currentOrder.orderBy, true));
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && index === 1) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };
      dispatch(productCatalog(nFilter, currentOrder.orderBy));
    }
  };

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search,
      offset: 0,
    };
    dispatch(productCatalog(nFilter, currentOrder.orderBy, true));
  };

  const createOrderHandler = () => {
    if (id && id > 0) {
      dispatch(orderUpdate(id, currentOrder));
    } else {
      dispatch(orderCreate(currentOrder));
    }
  };

  const offers = getBundleOffers(bundleCampaigns, currentOrder)?.map((item) => {
    return {
      ...item,
      isOffer: true,
      className: classes.offerItem,
    };
  });

  const onPriceChangedHandler = (product) => {
    if (loading || !currentOrder) {
      return;
    }
    let products = [...currentOrder?.products] || [];
    dispatch(
      setProductsCurrentOrder([
        ...products.filter((item) => item.productId !== product.productId || item.ownerId !== product.ownerId),
        product,
      ])
    );
  };

  const onQuantityChangedHandler = (product) => {
    if (loading) {
      return;
    }
    let products = [...currentOrder?.products] || [];
    let priceObj = {};
    let campaignPrice = getPriceWithCampaigns(product);
    let tablePrice = product.retailPrice < product.salePrice ? product.retailPrice : product.salePrice;
    if (campaignPrice && campaignPrice < tablePrice) {
      priceObj.price = campaignPrice;
      priceObj.priceType = "CMP";
    }
    if (!product.price && !campaignPrice) {
      priceObj.priceType = "S";
      priceObj.price = tablePrice;
    }
    let nProducts = [
      ...products.filter((item) => item.productId !== product.productId || item.ownerId !== product.ownerId),
      priceObj.priceType ? { ...product, ...priceObj } : product,
    ];

    dispatch(setProductsCurrentOrder(nProducts));
  };

  let offersWithKey = offers?.map((item) => {
    return {
      ...item,
      key: item.productId + item.ownerId + "_o",
    };
  });

  let rows = data?.map((item, index) => {
    return {
      ...item,
      key: index + "_" + item.productId + item.ownerId,
    };
  });

  const countProductsSelected = currentOrder?.products?.filter((item) => (item.quantity || 0) > 0)?.length || 0;
  return (
    <Page
      permission="orders.actions.placed.get"
      header={<PageTitle
        title={t("orders.OrdersCreate.header.title")}
        onBack={() =>
          autoLoadedAddressForm || index === 0 || id
            ? navigate("/orders/placed")
            : setIndex(0)
        }
        actions={
          index === 1 && (
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <SearchBox onEnter={(v) => onSearchHandler(v)} />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={() => setMosaicView((state) => !state)}
                >
                  {mosaicView ? <ListIcon /> : <DashboardIcon />}
                </Button>
              </Grid>
              <Permission code="orders.actions.placed.create">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={countProductsSelected <= 0}
                    onClick={createOrderHandler}
                  >
                    Criar {countProductsSelected > 0 && " (" + countProductsSelected + ")"}
                  </Button>
                </Grid>
              </Permission>
            </Grid>
          )
        }
      />}>

      <div hidden={index !== 0}>
        <DeliveryDayAndAddressForm
          onComplete={onDayAndAddressComplete}
          onCancel={() => navigate("/orders/placed")}
          onImport={onImportFile}
          currentOrder={currentOrder}
          orderAddresses={orderAddresses}
        />
      </div>
      <div hidden={index !== 1}>
        {!loading &&
          (mosaicView ? (
            <ProductsMosaic
              data={rows}
              currentOrder={currentOrder}
              onLoadMore={onLoadMoreHandler}
              offers={offersWithKey}
              onPriceChanged={onPriceChangedHandler}
              onQuantityChanged={onQuantityChangedHandler}
            />
          ) : (
            <ProductsDataGrid
              data={rows}
              currentOrder={currentOrder}
              onFilter={onFilterHandler}
              onLoadMore={onLoadMoreHandler}
              offers={offersWithKey}
              onPriceChanged={onPriceChangedHandler}
              onQuantityChanged={onQuantityChangedHandler}
            />
          ))}
      </div>
      <div hidden={index !== 2}>
        <ImportOrder />
      </div>
    </Page>
  );
}

export default withStyles(OrdersCreate, styles);

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const productChangeContactPointCall = async ({ productGet }, dispatch, { taskId, user, contactId, productId }) => {
  let rh = requestHelper(dispatch, "PRODUCT_CHANGE_CONTACT_POINT");
  try {
    await call({
      type: "PRODUCT",
      subType: "CHANGE_CONTACT_POINT",
      request: {
        taskId, user, contactId, productId
      },
    });
    dispatch(setSuccess("products", "PRODUCT_CHANGE_CONTACT_POINT"));
    setTimeout(() => {
      dispatch(productGet(productId));
    }, 300);
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productChangeContactPointCall;

import { getPromoterConfig, requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { uploadOrderAttachment } from "./_auxiliars";

const orderAddDocumentCall = async ({ orderAggregateDetails, orderDetails, orderDetailsLo }, dispatch, state, { orderId, orderSellerId, dispatchId, type, metadata, file }) => {
  let rh = requestHelper(dispatch, "ORDER_ADD_DOCUMENT");
  const promoterCode = state.core.promoterDetails?.details?.code;
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  const url = await uploadOrderAttachment(fileStorageConfigs, promoterCode, orderId || ("S" + orderSellerId), file);
  try {
    await call({
      type: "ORDER",
      subType: "ADD_DOCUMENT",
      request: {
        orderId,
        orderSellerId,
        dispatchId,
        documents: [{ type, metadata, url }]
      },
    });
    setTimeout(() => {
      dispatch(setSuccess("orders", "ORDER_ADD_DOCUMENT"));
      if (dispatchId) {
        dispatch(orderDetailsLo(orderSellerId, dispatchId))
      } else if (orderSellerId) {
        dispatch(orderAggregateDetails(orderSellerId))
      } else if (orderId) {
        dispatch(orderDetails(orderId))
      }
    }, 300);
  } catch (ex) {
    rh.error("orders", ex);
  }
  setTimeout(() => {
    rh.close();
  }, 350);
};


export default orderAddDocumentCall;

// private Long orderId;
// private Long orderSellerId;
// private long dispatchId;
// private List<@Valid OrderDocument> documents;
import {requestHelper} from "../../../app/coreSlice";
import {call} from "../../../app/websocket/_sliceCalls/websocket";

const promoterTestFlowCall = async ({ setSystemStatusDetails }, dispatch, { type }) => {
  let rh = requestHelper(dispatch, "PROMOTER_TEST_"+type);
  let timestamp = Date.now();
  try {
    let result = await call({
      type: type,
      subType: "TEST",
      request: { input : timestamp },
    });

    if(result){
      const responseTime = Date.now() - timestamp;
      dispatch(setSystemStatusDetails({key: type, result: {...result, timestamp: timestamp, responseTime: responseTime }}));
    }

  } catch (ex) {
    rh.error("promoter", ex);
    dispatch(setSystemStatusDetails({key: type, result: { ok: false, cache: undefined, db:{ coreRead: undefined, coreWrite: undefined, coreReadNode: undefined, bigdata: undefined }, timestamp: timestamp} }));
  }
  rh.close();
};

export default promoterTestFlowCall;


import moment from "moment";
import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const auditListAuditExternalCall = async ({ clearAuditExternalList, setAuditExternalList, setAuditExternalListTotalRows, setAuditExternalFilter }, dispatch, { filter, clear }) => {
  let rh = requestHelper(dispatch, "AUDIT_LIST_AUDIT_EXTERNAL");
  if (clear) {
    dispatch(clearAuditExternalList());
  }

  const requestDateIni = (filter.isDefaultFilter || !filter.requestDateIni) ? moment().add(-1, "day").toISOString() : filter.requestDateIni;
  const requestDateEnd = (filter.isDefaultFilter || !filter.requestDateEnd) ? moment().toISOString() : filter.requestDateEnd;

  dispatch(setAuditExternalFilter({
    ...filter,
    requestDateIni,
    requestDateEnd
  }));

  try {
    let result = await call({
      type: "AUDIT",
      subType: "LIST_AUDIT_EXTERNAL",
      request: {
        ...filter,
        requestDateIni,
        requestDateEnd
      }
    });

    if (result && result.items && result.items.length > 0) {
      dispatch(setAuditExternalList(result.items));
      dispatch(setAuditExternalListTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default auditListAuditExternalCall;

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { uploadDocuments, uploadMedia } from "./_auxiliars";

const productSaveFilesCall = async (dispatch, state, { productId, media, documents, copy }) => {
  const promoterCode = state.core.promoterDetails?.details?.code;
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");

  let rh = requestHelper(dispatch, "PRODUCT_SAVE_FILES");
  let nMedia = await uploadMedia(fileStorageConfigs, promoterCode, productId, media);
  let nDocs = await uploadDocuments(fileStorageConfigs, promoterCode, productId, documents);
  try {
    await call({
      type: "PRODUCT",
      subType: "SAVE_FILES",
      request: {
        productId,
        media: nMedia,
        documents: nDocs,
      },
    });

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
  return {
    media: nMedia, documents: nDocs
  }
};

export default productSaveFilesCall;

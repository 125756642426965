import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const auditUpdateRetentionCall = async ({ listAuditImport }, dispatch, { tableName, numberDays }) => {
  let rh = requestHelper(dispatch, "UPDATE_RETENTION");
  try {
    await call({
      type: "AUDIT",
      subType: "UPDATE_RETENTION",
      request: {
        tableName: tableName,
        numberDays: numberDays,
      },
    });
    setTimeout(() => {
      dispatch(setSuccess("promoter", "AUDIT_UPDATE_RETENTION"));
      dispatch(listAuditImport({}, true));
    }, 300);
  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default auditUpdateRetentionCall;

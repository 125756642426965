import { createSlice } from "@reduxjs/toolkit";
import productPendingPurchaseListCall from "./_sliceCalls/productPendingPurchaseListCall";
import productPendingPurchaseActionCall from "./_sliceCalls/productPendingPurchaseActionCall";
import productRequestPurchaseCall from "./_sliceCalls/productRequestPurchaseCall";
import { productsPurchasesDefaultFilter } from "../products/_common/productsFilters";
import { appendItemsToList } from "../../common/utils";

export const productsPurchases = createSlice({
  name: "productsPurchases",
  initialState: {
    purchasesList: [],
    purchasesListTotalRows: 0,
    purchasesListFilter: productsPurchasesDefaultFilter,
  },
  reducers: {
    clearPurchasesList: (state) => {
      state.purchasesList = [];
    },

    appendToPurchasesList: (state, action) => {
      appendItemsToList(state.purchasesList, action.payload, true, "processId");
    },

    updatePurchasesListThumbnails: (state, action) => {
      let nProductList = state.purchasesList;
      (action.payload || []).forEach(({ productId, thumbnail }) => {
        let productIndex = nProductList.findIndex(item => item.productId === productId);
        if (nProductList[productIndex]) {
          nProductList[productIndex].thumbnail = thumbnail;
        }
      });
      state.productList = nProductList;
    },

    insertItemIntoPurchasesList: (state, action) => {
      appendItemsToList(state.purchasesList, [action.payload], false, "processId");
    },

    setPurchasesListTotalRows: (state, action) => {
      state.purchasesListTotalRows = action.payload;
    },

    setPurchasesListFilter: (state, action) => {
      state.purchasesListFilter = action.payload;
    },
  },
});

export default productsPurchases.reducer;
export const { insertItemIntoPurchasesList } = productsPurchases.actions;

// SELECTS
export const selectPurchasesList = (state) => state.productsPurchases.purchasesList;
export const selectPurchasesListTotalRows = (state) => state.productsPurchases.purchasesListTotalRows;
export const selectPurchasesListFilter = (state) => state.productsPurchases.purchasesListFilter;

// CALLS
export const productPendingPurchaseList = (filter, clear = false) =>
  (dispatch, getState) => {
    productPendingPurchaseListCall(productsPurchases.actions, dispatch, getState(), { filter, clear });
  };

export const productPendingPurchaseAction = ({ productId, taskId, status, quantity, price, comment }) =>
  (dispatch) => {
    productPendingPurchaseActionCall(dispatch, {
      productId,
      taskId,
      status,
      quantity,
      price,
      comment,
    });
  };


export const productRequestPurchase = ({ productId, comment, quantity, price }) =>
  (dispatch, getState) => {
    productRequestPurchaseCall(dispatch, getState(), { productId, comment, quantity, price });
  };
import * as Yup from "yup";
import { DatePickerField, TextField } from "../../../../common/forms";
import moment from "moment";

export function formSchema({ t, year }) {
  const yearStart = moment(`${year}-01-01`).startOf('year');
  const yearEnd = moment(`${year}-01-01`).endOf('year');
  return Yup.object().shape({
    name: Yup.string().required(t("planning.PlanningSeasonFormModal.form.nameRequired")),
    startDate: Yup.string().required(t("planning.PlanningSeasonFormModal.form.startDateRequired"))
      .test(
        'minStartDateTest',
        t('planning.PlanningSeasonFormModal.form.startDateMin'),
        (value) => {
          return !value || moment(value).isSameOrAfter(yearStart)
        }
      ).test(
        'maxStartDateTest',
        t('planning.PlanningSeasonFormModal.form.startDateMax'),
        (value, context) => {
          return !value || !context?.parent?.endDate || moment(value).isSameOrBefore(context?.parent?.endDate)
        }
      ),
    endDate: Yup.string().required(t("planning.PlanningSeasonFormModal.form.endDateRequired"))
      .test(
        'minEndDateTest',
        t('planning.PlanningSeasonFormModal.form.endDateMin'),
        (value, context) => {
          return !value || !context?.parent?.startDate || moment(value).isSameOrAfter(context?.parent?.startDate)
        }
      ).test(
        'maxEndDateTest',
        t('planning.PlanningSeasonFormModal.form.endDateMax'),
        (value) => {
          return !value || moment(value).isSameOrBefore(yearEnd)
        }
      ),
  });
}

export function formFields({ t, values, year }) {
  const yearStart = moment(`${year}-01-01`).startOf('year');
  const yearEnd = moment(`${year}-01-01`).endOf('year');

  const maxStart = (values.endDate && yearEnd.isAfter(moment(values.endDate))) ? moment(values.endDate) : yearEnd;
  const minEnd = (values.startDate && yearStart.isBefore(moment(values.startDate))) ? moment(values.startDate) : yearStart;

  return [
    {
      size: 12,
      field: <TextField
        id="name"
        required
        maxLength={50}
        label={t("planning.PlanningSeasonFormModal.form.name")}
      />,
    },
    {
      size: 6,
      field: <DatePickerField
        id="startDate"
        required
        minDate={yearStart.format("YYYY-MM-DD")}
        maxDate={maxStart.format("YYYY-MM-DD")}
        label={t("planning.PlanningSeasonFormModal.form.startDate")}
      />,
    },
    {
      size: 6,
      field: <DatePickerField
        id="endDate"
        required
        minDate={minEnd.format("YYYY-MM-DD")}
        maxDate={yearEnd.format("YYYY-MM-DD")}
        label={t("planning.PlanningSeasonFormModal.form.endDate")}
      />,
    },
  ];
}


import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { styles } from "./PageTitle.styles";

function PageTitle({ classes, title, subtitle, info, actions, onBack }) {
  let minHeight = subtitle ? 102 : 64;
  return (
    <div className={classes.root} style={{ minHeight }}>
      <Grid
        className={classes.header}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ minHeight }}
        wrap="nowrap"
      >
        <Grid item>
          <Grid container direction="column" alignItems="flex-start">
            <Grid item>
              <Grid container direction="row" alignItems="center" wrap="nowrap">
                {onBack && (
                  <Grid item>
                    <IconButton onClick={onBack} className={classes.back} size="large">
                      <ArrowBackIcon />
                    </IconButton>
                  </Grid>
                )}
                {typeof title === "string" ? (
                  <Typography className={classes.title} variant="h3">
                    {title}
                  </Typography>
                ) : (
                  <Grid item>{title}</Grid>
                )}
                <Grid item>
                  <Typography className={classes.info} variant="body1">
                    {info}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {subtitle && (
              <Grid item>
                <Typography className={classes.subtitle} variant="body1">
                  {subtitle}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>{actions}</Grid>
      </Grid>
    </div>
  );
}

export default withStyles(PageTitle, styles);

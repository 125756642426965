export const csvFields = (t) => {

  return [
    {
      field: "gtin",
      header: t("products.ProductList.columns.gtin"),
    }, 
    {
      field: "promoterInternalCode",
      header: t("products.ProductList.columns.promoterInternalCode"),
    },
    {
      field: "description",
      header: t("products.ProductList.columns.description"),

    },
    {
      field: "ownerName",
      header: t("products.ProductList.columns.ownerName"),
    },
    {
      field: "brand",
      header: t("products.ProductList.columns.brand"),

    },
  ];
}
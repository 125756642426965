import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authenticationReducer from "../features/authentication/authenticationSlice";
import websocketReducer from "./websocket/websocketSlice";

import i18nReducer from "../features/i18n/i18nSlice";
import entitiesReducer from "../features/entities/entitiesSlice";
import ordersReducer from "../features/orders/ordersSlice";
import productsReducer from "../features/products/productsSlice";
import operatorReducer from "../features/operator/operatorSlice";
import businessReducer from "../features/business/businessSlice";
import homeReducer from "../features/home/homeSlice";
import promoterReducer from "../features/promoter/promoterSlice";
import rolesReducer from "../features/roles/rolesSlice";
import documentReducer from "../features/document/documentSlice";
import notificationReducer from "../features/notification/notificationSlice";
import alertsReducer from "../features/alerts/alertsSlice";
import coreReducer from "./coreSlice";
import ordersIncidentsReducer from "../features/ordersIncidents/ordersIncidentsSlice";
import productsPricesReducer from "../features/productsPrices/productsPricesSlice";
import productsFamiliesReducer from "../features/productsFamilies/productsFamiliesSlice";
import productsPurchasesReducer from "../features/productsPurchases/productsPurchasesSlice";
import productsDpromsReducer from "../features/productsDproms/productsDpromsSlice";
import usersReducer from "../features/users/usersSlice";
import tasksReducer from "../features/tasks/tasksSlice";
import placesReducer from "../features/places/placesSlice";
import resourcesReducer from "../features/resources/resourcesSlice";
import planningReducer from "../features/planning/planningSlice";
import businessUnitsReducer from "../features/businessUnits/businessUnitsSlice";
import batchesReducer from "../features/batches/batchesSlice";

const combinedReducer = combineReducers({
  authentication: authenticationReducer,
  websocket: websocketReducer,
  i18n: i18nReducer,
  entities: entitiesReducer,
  orders: ordersReducer,
  products: productsReducer,
  productsPrices: productsPricesReducer,
  productsFamilies: productsFamiliesReducer,
  business: businessReducer,
  home: homeReducer,
  core: coreReducer,
  operator: operatorReducer,
  promoter: promoterReducer,
  roles: rolesReducer,
  document: documentReducer,
  notification: notificationReducer,
  alerts: alertsReducer,
  ordersIncidents: ordersIncidentsReducer,
  productsPurchases: productsPurchasesReducer,
  productsDproms: productsDpromsReducer,
  users: usersReducer,
  tasks: tasksReducer,
  places: placesReducer,
  resources: resourcesReducer,
  planning: planningReducer,
  businessUnits: businessUnitsReducer,
  batches: batchesReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "authentication/setLogout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

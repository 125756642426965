import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const entityListGroupCall = async (
  { clearGroupList, setGroupListFilter, appendToGroupList, setGroupListTotalRows },
  dispatch,
  { filter, clear = false }
) => {
  let rh = requestHelper(dispatch, "LIST_GROUP");
  if (clear) {
    dispatch(clearGroupList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setGroupListFilter(filter));
  try {
    let list = await call({
      type: "ENTITY",
      subType: "LIST_GROUP",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    if (list && list.groupEntities && list.groupEntities.length > 0) {
      dispatch(appendToGroupList(list.groupEntities));
    }
    dispatch(setGroupListTotalRows(list.rows));
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityListGroupCall;

import React from "react";
import { withStyles } from "tss-react/mui";
import { Field } from "react-final-form";
import MetaError from "../MetaError";
import { styles } from "../fields/_base.styles";
import { CountryAutoComplete } from "../../inputs/custom";

function CountryAutoCompleteField({ classes, id, label, disabled, required, onChange }) {
  const onChangeHandler = (v, input) => {
    input.onChange && input.onChange(v);
    onChange && onChange(v);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <>
            <CountryAutoComplete
              id={id}
              label={label}
              value={input.value}
              onChange={(value) => onChangeHandler(value, input)}
              disabled={disabled}
              required={required}
              error={Boolean(meta.error && meta.touched)}
            />
            <div className={classes.error}>
              <MetaError meta={meta} />
            </div>
          </>
        )}
      </Field>
    </div>
  );
}

export default withStyles(CountryAutoCompleteField, styles)

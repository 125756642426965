import { Typography } from "@mui/material";
import { CheckboxField, CheckListField, FormArrayFields, NumberField, SelectField } from "../../../../common/forms";

export const cutOffFields = ({ t, user, values, formOptions }) => {
  return [
    {
      size: 4,
      field: (
        <>
          {user.hasPermission("promoter.actions.configs.functional") && <CheckboxField id="functional.cutOff.automatic" label={t("promoter.PromoterConfigs.form.cuttOffAutomatic")} />}
          {user.hasPermission("promoter.actions.configs.technical") && <CheckListField
            id="technical.cutOff.entityTypes"
            options={formOptions.entityType}
            label={t("promoter.PromoterConfigs.form.entityTypes")}
          />}
        </>
      ),
    },
    {
      size: 8,
      field: (
        <div>
          <Typography component="span" variant="caption" color="textPrimary">
            {t("promoter.PromoterConfigs.form.cutoff")}
          </Typography>
          <FormArrayFields
            name="functional.cutOff.details"
            formFields={formFieldsCutoffDetails(t, formOptions)}
            defaultItem={{ dayOfMonth: 0 }}
          />
        </div>
      ),
      hidden: values.cutOff?.automatic || !user.hasPermission("promoter.actions.configs.functional"),
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: <CheckboxField id="functional.cutOff.reminder.remind" label={t("promoter.PromoterConfigs.form.reminder")} />,
      hidden: values.cutOff?.automatic || !user.hasPermission("promoter.actions.configs.functional"),
    },
    {
      size: 4,
      field: (
        <CheckboxField
          id="functional.cutOff.reminder.oneDayBefore"
          label={t("promoter.PromoterConfigs.form.reminderOneDayBefore")}
        />
      ),
      hidden: values.cutOff?.automatic || !user.hasPermission("promoter.actions.configs.functional"),
    },
    {
      size: 4,
      field: (
        <CheckboxField
          id="functional.cutOff.reminder.twoDaysBefore"
          label={t("promoter.PromoterConfigs.form.remindertwoDaysBefore")}
        />
      ),
      hidden: values.cutOff?.automatic || !user.hasPermission("promoter.actions.configs.functional"),
    },
  ];
};


export const formFieldsCutoffDetails = (t, formOptions) => (item) => {
  return [
    {
      size: 4,
      field: (
        <SelectField
          id={`${item}.type`}
          label={t("promoter.PromoterConfigs.form.cutoffType")}
          options={formOptions.cutOffType}
        />
      ),
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`${item}.dayOfWeek`}
          label={t("promoter.PromoterConfigs.form.cutoffDayOfWeek")}
          options={formOptions.weekDay}
        />
      ),
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`${item}.deliveryDay`}
          label={t("promoter.PromoterConfigs.form.cutoffDeliveryDay")}
          options={formOptions.weekDay}
        />
      ),
    },
    {
      size: 4,
      field: (
        <NumberField
          id={`${item}.hour`}
          type="text"
          label={t("promoter.PromoterConfigs.form.cutoffHour")}
          decimalScale={0}
        />
      ),
    },
    {
      size: 4,
      field: (
        <NumberField
          id={`${item}.minute`}
          type="text"
          label={t("promoter.PromoterConfigs.form.cutoffMinute")}
          decimalScale={0}
        />
      ),
    },
    {
      size: 4,
    },
    {
      size: 4,
      field: (
        <NumberField
          id={`${item}.minQuantity.quantity`}
          type="text"
          label={t("promoter.PromoterConfigs.form.minQuantityValue")}
          decimalScale={0}
        />
      ),
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`${item}.minQuantity.unitType`}
          label={t("promoter.PromoterConfigs.form.unitType")}
          options={formOptions.transport}
        />
      ),
    },
  ];
};
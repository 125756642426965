import { IconButton, Stack, Typography } from "@mui/material";
import CustomTooltip from "../../../../common/displays/CustomTooltip/CustomTooltip";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import { CurrencyInput } from "../../../../common/inputs/custom";
import PriceOscilation from "../../_common/PriceOscilation/PriceOscilation";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PricePromotions from "./PricePromotions/PricePromotions";
import { currencyFormat } from "../../../../common/utils";
import Currency from "../../../../common/displays/Currency/Currency";
import CampaignTooltip from "../../../orders/_common/CampaignTooltip/CampaignTooltip";
import { getEntitiesInGroups } from "../../_common/auxiliars";

export function listColumns({ t, user, promoter, validation, onStandardPriceChange, onAddPromotion, onEditPromotion, onPriceComparerOpen, onStandardRetailPriceChange, comparerView, entityGroups }) {
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];

  let columns = [];
  columns.push({
    field: "description",
    headerName: t("productsPrices.PricesList.columns.description"),
    align: "left",
    headerAlign: "left",
    disableSort: comparerView,
    customValue: (v) => {
      const showPromoterDescription = Boolean(enabledProductFields.includes("promoterDescription") && user?.isPromoter && v.promoterDescription);
      return <ProductSnip
        image={v.thumbnail}
        description={showPromoterDescription ? v.promoterDescription : v.description}
        gtin={v.gtin}
        promoterInternalCode={v.promoterInternalCode}
        ownerName={comparerView ? v?.ownerName : undefined}
      />;
    },
  });
  columns.push({
      field: "unitDescriptor",
      headerName: t("productsPrices.PricesList.columns.unitDescriptor"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        if(v.unitDescriptor)
          return t("common.Enums.transport."+v.unitDescriptor)
      }
    });
  if (user?.isPromoter && !comparerView) {
    columns.push({
      field: "ownerName",
      headerName: t("productsPrices.PricesList.columns.ownerName"),
      align: "left",
      headerAlign: "left",
      disableSort: comparerView
    });
  }
  if (promoter?.getConfig("price", "allowStandard") && promoter?.getConfig("price", "allowPricePromotions")) {
    columns.push({
      field: "promotions",
      headerName: t("productsPrices.PricesList.columns.promotions"),
      disableSort: true,
      align: "right",
      width: 20,
      customValue: (v) => {
        const allowedToChange = user?.entity?.type !== "P" || !promoter?.getConfig("product", "requiresInternalCode") || v.promoterInternalCode;
        const { prices } = v;
        const priceObj = (prices || []).find(item => item.priceType === "S") || {};
        const { process } = priceObj;
        if ((priceObj?.salePrice || 0) > 0 || priceObj?.newSalePrice > 0) {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center">
              {priceObj.promotions && <PricePromotions price={priceObj} pending={validation || process?.status === "PENDING"} onEdit={(promotion) => allowedToChange && onEditPromotion(v, priceObj, promotion)} />}
              {!comparerView && !validation && process?.status !== "PENDING" && (priceObj.promotions || [])?.length < 3 && promoter?.getConfig("price", "allowPricePromotions") && allowedToChange &&
                <IconButton size="small" onClick={() => onAddPromotion(v, priceObj)}>
                  <AddCircleOutlineIcon color="default" />
                </IconButton>
              }
            </Stack>
          );
        }
        return null;
      }
    });
  }

  if (promoter?.getConfig("price", "allowPriceCampaigns")) {
    columns.push({
      field: "campaigns",
      headerName: t("productsPrices.PricesList.columns.campaigns"),
      disableSort: true,
      align: "right",
      width: 20,
      customValue: (v) => {
        let availableCampaigns = (v.campaigns || []).filter((item) => item.active && (user?.isPromoter || getEntitiesInGroups(entityGroups, item.groups).includes(user?.entity?.id)));
        // should show all campaigns to promoter, or if the user entity is part of the groups associated or created the campaign

        return <Stack spacing={1} direction="row">
          {availableCampaigns.map((item, index) => (
            <div key={`campaign_${index}`}>
              <CampaignTooltip campaign={item} forceHighlight groups={(entityGroups || []).filter(entityGroup => (item.groups || []).some(group => group === entityGroup.groupId))} />
            </div>
          ))}
        </Stack>
      }
    });
  }

  if (!comparerView) {
    columns.push({
      field: "comment",
      disableSort: true,
      align: "right",
      width: 20,
      customValue: (v) => {
        const { prices } = v;
        const priceObj = (prices || []).find(item => item.priceType === "S") || {};
        const { process, comment } = priceObj;
        return comment ? <CustomTooltip title={comment} placement="left">
          <InfoOutlinedIcon style={{ display: "block" }} color={process?.status === "REFUSED" ? "error" : "info"} />
        </CustomTooltip> : ""
      }
    });
  }
  if (promoter?.getConfig("price", "allowStandard")) {
    columns.push({
      field: "salePrice",
      headerName: t("productsPrices.PricesList.columns.salePrice"),
      disableSort: true,
      align: "right",
      width: comparerView ? 60 : 180,
      customValue: (v) => {
        const allowedToChange = user?.entity?.type !== "P" || !promoter?.getConfig("product", "requiresInternalCode") || v.promoterInternalCode;
        const { prices, productId } = v;
        const priceObj = (prices || []).find(item => item.priceType === "S") || {};
        const { priceId, process, salePrice, prevSalePrice, newSalePrice } = priceObj;
        if (comparerView) {
          return <Typography style={{ whiteSpace: "nowrap" }}>{currencyFormat(priceObj?.salePrice)}</Typography>
        }
        if (validation) {
          if (process?.status === "PENDING") {
            return <PriceOscilation oldPrice={salePrice} newPrice={process?.newSalePrice} showComparer={validation && process?.status === "PENDING"} onPriceComparerOpen={() => onPriceComparerOpen(v, priceObj)} />;
          }
          if (process?.status === "ACCEPTED") {
            return <PriceOscilation oldPrice={prevSalePrice} newPrice={salePrice} />;
          }
          if (process?.status === "REFUSED") {
            return <PriceOscilation oldPrice={salePrice} newPrice={process?.newSalePrice} rejected />;
          }
          return <Currency value={salePrice} />
        }
        else {
          // price List only show oscilation if pending
          if (process?.status === "PENDING") {
            // OSCILATION
            return <PriceOscilation oldPrice={salePrice} newPrice={process?.newSalePrice} />
          }
        }
        return <CurrencyInput
          value={(newSalePrice || salePrice || 0).toString()}
          disabled={!allowedToChange}
          onChange={(newSalePrice) => onStandardPriceChange({ productId, priceId, newSalePrice })}
        />
      }
    });
  }

  if (promoter?.getConfig("price", "allowRetailPrice")) {
    columns.push({
      field: "retailPrice",
      headerName: t("productsPrices.PricesList.columns.retailPrice"),
      disableSort: true,
      align: "right",
      width: comparerView ? 60 : 180,
      customValue: (v) => {
        const allowedToChange = user?.entity?.type !== "P" || !promoter?.getConfig("product", "requiresInternalCode") || v.promoterInternalCode;
        const { prices, productId } = v;
        const priceObj = (prices || []).find(item => item.priceType === "S") || {};
        const { priceId, process, retailPrice, prevRetailPrice, newRetailPrice } = priceObj;

        if (comparerView) {
          return <Typography style={{ whiteSpace: "nowrap" }}>{currencyFormat(priceObj?.retailPrice)}</Typography>
        }
        if (validation) {
          if (process?.status === "PENDING") {
            return <PriceOscilation oldPrice={retailPrice} newPrice={process?.newRetailPrice} />;
          }
          if (process?.status === "ACCEPTED") {
            return <PriceOscilation oldPrice={prevRetailPrice} newPrice={retailPrice} />;
          }
          if (process?.status === "REFUSED") {
            return <PriceOscilation oldPrice={retailPrice} newPrice={process?.newRetailPrice} rejected />;
          }
          return <Currency value={retailPrice} />
        }
        else {
          // price List only show oscilation if pending
          if (process?.status === "PENDING") {
            // OSCILATION
            return <PriceOscilation oldPrice={retailPrice} newPrice={process?.newRetailPrice} />
          }
        }
        return <CurrencyInput
          value={(newRetailPrice || retailPrice || 0).toString()}
          disabled={!allowedToChange}
          onChange={(newRetailPrice) => onStandardRetailPriceChange({ productId, priceId, newRetailPrice })}
        />
      }
    });
  }

  return columns;
}



import { processImageUrl } from "../../../api/s3";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { processHierarchy } from "./_auxiliars";

const productGetBaseCall = async ({ setProductBase, setProductBasePendingHierarchy, setProductBaseError }, dispatch, state, { gtin, gln, ignoreExternalProviders }) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");

  let rh = requestHelper(dispatch, "GET_BASE");
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "GET_BASE",
      request: {
        gtin,
        gln,
        ignoreExternalProviders
      },
    });
    dispatch(setProductBaseError(null));
    if (result.product?.units?.unitDescriptor === "BASE_UNIT_OR_EACH") {
      dispatch(
        setProductBase({
          ...result.product,
          logo: result.product.media?.length > 0 ? await getLogoObj(fileStorageConfigs, result.product.media) : null,
          storage: {
            ...result.product?.storage,
            perishable:
              result.product?.management?.hasExpirationDate && result.product?.storage?.lifecycle < 30 ? "Y" : "N",
          },
          hierarchy: await processHierarchy(result.product, result.product.hierarchy)
        })
      );
      dispatch(setProductBasePendingHierarchy(result?.pendingHierarchy));
    }
    else {
      dispatch(setProductBaseError("INVALID_UNIT"));
    }
  } catch (ex) {
    if (ex.tag === "NO_INFORMATION_FOUND") {
      dispatch(setProductBaseError("NOT_FOUND"));
    }
  }
  rh.close();
};

export default productGetBaseCall;

const getLogoObj = async (fileStorageConfigs, media) => {
  let med = media.find((item) => item.type === "PRODUCT_IMAGE");
  if (med) {
    let termination = med.reference.slice(med.reference.lastIndexOf(".") + 1);

    let url = await processImageUrl(fileStorageConfigs, med.reference);
    return {
      name: med.reference.slice(med.reference.lastIndexOf("/") + 1),
      type: "image/" + termination === "jpg" ? "jpeg" : termination,
      url,
    };
  }
  return;
};

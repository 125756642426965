const theme = {
  palette: {
    primary: {
      main: "#2E5BFF",
      back: "#f1f4ff"
    },
    secondary: {
      main: "#F4F6FC",
      back: "#F4F6FC11"
    },
    error: {
      main: "#EB5757",
      back: "#EB575711"
    },
    warning: {
      main: "#FEC514",
      back: "#FEC51411"
    },
    info: {
      main: "#00C1D4",
      back: "#EEFBFC",
    },
    success: {
      main: "#00bf67",
      back: "#00bf6711"
    },
    text: {
      primary: "#000",
      secondary: "#97A1AF",
      disabled: "#D2D9E4",
    },
    background: {
      default: "#f4f6fc",
      paper: "#FFF",
      input: "#FBFCFD",
      error: "#FAEEED",
      errorDark: "#f5d5d2",
      menuActive: "#EEF2FF",
      disabled: "#f5f7f9"
    },
    action: {
      active: "#D2D9E4",
      hover: "#ddd", // options hover
      selected: "#2E5BFF29", // selected
      selectedOpacity: 0.16,
      disabled: "#D2D9E4",
      disabledBackground: "#f9f9f9",
      focus: "#2E5BFF29",
    },
  },
  typography: {
    h1: {
      fontSize: "30px",
      lineHeight: "42px",
      fontWeight: 600,
      marginTop: 16,
      marginBottom: 24,
      whiteSpace: "nowrap",
    },
    h2: {
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: 600,
      marginTop: 16,
      marginBottom: 24,
      whiteSpace: "nowrap",
    },
    h3: {
      fontSize: "19px",
      lineHeight: "28px",
      fontWeight: 600,
      marginTop: 8,
      marginBottom: 16,
    },
    h4: {
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: 600,
      marginTop: 8,
      marginBottom: 16,
    },
    h5: {
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: 600,
      marginTop: 8,
      marginBottom: 8,
    },
    h6: {
      fontSize: "11px",
      lineHeight: "14px",
      fontWeight: 600,
      marginTop: 8,
      marginBottom: 8,
      textTransform: "uppercase",
    },
    subtitle1: {
      fontWeight: "normal",
      whiteSpace: "nowrap",
      fontSize: "15px",
      lineHeight: "22px",
      marginBottom: 16,
    },
    body1: {
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "22px",
      marginTop: 8,
      marginBottom: 8,
    },
    caption: {
      display: "block",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "14px",
      marginTop: 8,
      marginBottom: 8,
    },
    button: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "22px",
      textAlign: "center",
      textTransform: "initial",
    },
  },
  custom: {
    drawerWidth: 80,
    drawerWidthOpen: 215,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        outlined: {
          backgroundColor: "#FFF",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: "0 !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          margin: "0 !important",
        },
        input: {
          fontWeight: "normal",
          fontSize: 15,
          lineHeight: "22px",
          backgroundColor: "#FBFCFD",
          height: 21,
          padding: "10.5px 14px !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#D2D9E4",
          borderRadius: "5px",
          "&:hover": {
            borderColor: "yellow",
          },
        },
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D2D9E4",
            borderWidth: 1,
            borderBottomColor: "#2E5BFF",
            borderBottomWidth: 2,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D2D9E4 !important",
            borderWidth: 1,
            borderBottomColor: "#2E5BFF !important",
            borderBottomWidth: 2,
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D2D9E4",
            borderWidth: 1,
            borderBottomColor: "#EB5757 !important",
            borderBottomWidth: 2,
          },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D2D9E4 !important",
            borderWidth: 1,
          },
          "&.Mui-disabled": {
            backgroundColor: "#F5F7F9",
          },
        },
        input: {
          "&.Mui-disabled": {
            backgroundColor: "#F5F7F9",
            borderRadius: "5px",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: "0 !important",
          "&.Mui-error": {
            fontWeight: "normal",
            color: "#EB5757",
            fontSize: 11,
            lineHeight: "18px",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            backgroundColor: "#FBFCFD",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: "34px",
          fontSize: "14px",
          lineHeight: "22px",
          minWidth: "48px",
        },
        sizeLarge: {
          height: "40px",
          fontSize: "14px",
          lineHeight: "22px",
          padding: "8px 12px",
        },
        sizeSmall: {
          height: "28px",
          fontSize: "11px",
          lineHeight: "18px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#DDDDDD",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          lineHeight: 1,
          fontSize: 11
        },
      },
    }
  },
};

export default theme;

const users = {
  flowsMessages: {
    success: {
      CHANGE_PASSWORD: "Password changed successfully!",
      CHANGE_PROMOTER: "Promoter changed successfully!",
      USER_UPDATE: "User updated successfully!",
      USER_SET_PREFERENCES: "User preferences updated successfully!",
    },
    error: {
      GENERIC: "Something went wrong",
      CHANGE_PASSWORD_ERROR: "Invalid password!",
      NOT_SUPPORTED: "File type not supported"
    },
  },
  Settings: {
    header: {
      title: "Settings",
    },
    tabs: {
      profile: "Profile",
      security: "Security",
      preferences: "Preferences"
    },
  },
  PasswordChangeForm: {
    form: {
      title: "Change your password",
      btnSubmit: "Submit",
      password: "Password",
      newPassword: "New password",
      newPasswordConfirm: "Confirm new password",
      passwordRequired: "Password is required",
      newPasswordRequired: "New password is required",
      newPasswordConfirmRequired: "Confirmation is required",
      matchPassword: "Password confirmation mismatch",
      passwordPattern: "Password should have at least 6 characters"
    }
  },
  Preferences: {
    form: {
      language: "Language"
    }
  },
  Profile: {
    form: {
      avatar: "Profile Picture",
      firstName: "First Name",
      lastName: "Last Name",
      firstNameRequired: "First Name is required",
      lastNameRequired: "Last Name is required",
      btnSubmit: "Submit",
    }
  }
};

export default users;


import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper, Chip } from "@mui/material";

import { styles } from "./Units.styles";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";
import { usePromoter } from "../../../../common/hooks";

function Units({ classes, units = {} }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];
  const {
    isBaseUnit,
    isConsumerUnit,
    isDispatchUnit,
    unitDescriptor,
    transportUnit,
    quantityOfLayers,
    quantityOfItemsByLayer,
    quantityOfItems
  } = units;

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h3" align="left" component="h2" className={classes.noMargin}>
            {t("products.ProductDetails.details.unitsTitle")}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <Chip
                    label={t("products.ProductDetails.details.isBaseUnit")}
                    color={isBaseUnit ? "primary" : "secondary"}
                  />
                </Grid>
                <Grid item>
                  <Chip
                    label={t("products.ProductDetails.details.isConsumerUnit")}
                    color={isConsumerUnit ? "primary" : "secondary"}
                  />
                </Grid>
                {enabledProductFields.includes("isDispatchUnit") &&<Grid item>
                  <Chip
                    label={t("products.ProductDetails.details.isDispatchUnit")}
                    color={isDispatchUnit ? "primary" : "secondary"}
                  />
                </Grid>}
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <LabelValue
                label={t("products.ProductDetails.details.unitDescriptor")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {unitDescriptor ? t("common.Enums.transport." + unitDescriptor) : "-"}
                  </Typography>
                }
              />
            </Grid>
            {isConsumerUnit && (
              <Grid item xs={2}>
                <LabelValue
                  label={t("products.ProductDetails.details.transportUnit")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {transportUnit ? t("common.Enums.transport." + transportUnit) : "-"}
                    </Typography>
                  }
                />
              </Grid>
            )}
            {unitDescriptor !== "BASE_UNIT_OR_EACH" &&
              <Grid item xs={2}>
                <LabelValue
                  label={t("products.ProductDetails.details.quantityOfLayers")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {quantityOfLayers || "-"}
                    </Typography>
                  }
                />
              </Grid>}
            {unitDescriptor !== "BASE_UNIT_OR_EACH" &&
              <Grid item xs={2}>
                <LabelValue
                  label={t("products.ProductDetails.details.quantityOfItemsByLayer")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {quantityOfItemsByLayer || "-"}
                    </Typography>
                  }
                />
              </Grid>
            }
            {unitDescriptor !== "BASE_UNIT_OR_EACH" &&
              <Grid item xs={2}>
                <LabelValue
                  label={t("products.ProductDetails.details.quantityOfItems")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {quantityOfItems || "-"}
                    </Typography>
                  }
                />
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(Units, styles);
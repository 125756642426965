export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(3),
  },
  noMargin: {
    margin: 0,
  },
  divider: {
    borderBottom: "1px solid #D2D9E4",
    width: "100%",
    marginBottom: 16,
  },
  tabs: {
    "& .MuiTab-root": {
      alignItems: "center",
      maxWidth: "none",
    },
    "& .MuiTab-wrapper": {
      alignItems: "flex-start",
    },
    "& .Mui-selected": {
      backgroundColor: "#FFF",
      borderRadius: "2px 0 0 2px",
    },
  },
});

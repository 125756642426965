import * as Yup from "yup";
import { CurrencyField, NumberField, TextField } from "../../../../common/forms";
import { CurrencyInput } from "../../../../common/inputs/custom";

export function formSchema(t) {
  return Yup.object().shape({
    quantity: Yup.string().not(["0"], t("productsPurchases.PendingPurchaseForm.form.requiredQuantity")).required(t("productsPurchases.PendingPurchaseForm.form.requiredQuantity")),
    unitPrice: Yup.string().not(["0"], t("productsPurchases.PendingPurchaseForm.form.requiredPrice")).required(t("productsPurchases.PendingPurchaseForm.form.requiredPrice")),
  });
}

export function formFields({ t, values, action }) {
  return [
    {
      size: 6,
      field: (
        <NumberField
          id="quantity"
          decimalScale={0}
          label={t("productsPurchases.PendingPurchaseForm.form.quantity")}
          required
          disabled={action !== "CREATE" && action !== "PROPOSAL" && action !== "PENDING"}
        />
      ),
    },
    {
      size: 6,
      field: <CurrencyField
        id="unitPrice"
        label={t("productsPurchases.PendingPurchaseForm.form.unitPrice")}
        required
        disabled={action !== "CREATE" && action !== "PROPOSAL" && action !== "PENDING"}
      />,
    },
    {
      size: 12,
      field: <CurrencyInput
        id="price"
        label={t("productsPurchases.PendingPurchaseForm.form.price")}
        disabled
        value={(parseInt(values?.quantity) || 0) * (parseFloat(values?.unitPrice) || 0)}
      />,
    },
    {
      size: 12,
      field: <TextField
        id="comment"
        rows={3}
        label={t("productsPurchases.PendingPurchaseForm.form.comment")}
      />,
    },
  ];
}

import { Stack } from "@mui/material"
import BlockCard from "../BlockCard/BlockCard"
import SlimCheckBoxField from "../SlimCheckBoxField/SlimCheckBoxField"

export const planningFormFields = (t) => {
  return [
    {
      size: 3,
      field: <BlockCard title="planning">
        <Stack>
          <SlimCheckBoxField id="permissions.planning.actions.production.list" label="list" />
          <SlimCheckBoxField id="permissions.planning.actions.production.create" label="create" />
          <SlimCheckBoxField id="permissions.planning.actions.production.update" label="update" />
          <SlimCheckBoxField id="permissions.planning.actions.production.delete" label="delete" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="planning forecast">
        <Stack>
          <SlimCheckBoxField id="permissions.planning.actions.forecast.create" label="create" />
          <SlimCheckBoxField id="permissions.planning.actions.forecast.update" label="update" />
          <SlimCheckBoxField id="permissions.planning.actions.forecast.get" label="get" />
        </Stack>
      </BlockCard>,
    },
  ]
}
export const csvFields = (t) => {
  return [
    {
      field: "datetime",
      header: t("business.audit.login.columns_datetime"),
    },
    {
      field: "action",
      header: t("business.audit.login.columns_action"),
    },{
      field: "entity",
      header: t("business.audit.login.columns_entity"),
    },{
      field: "username",
      header: t("business.audit.login.columns_username"),
    },{
      field: "ipAddress",
      header: t("business.audit.login.columns_ipAddress"),
    },{
      field: "sessionId",
      header: t("business.audit.login.columns_sessionId"),
    }
  ];
}
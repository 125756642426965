import { call } from "./websocket";
import { getGpcCategories, getProductClasses } from "../../../api/ref_data/api";
import { initAWSsdk } from "../../../api/s3";
import { guid } from "../../../common/utils";

const promoterDetailsCall = async () => {
  let promoterDetails = await call({
    wsid: guid(),
    type: "PROMOTER",
    subType: "DETAILS",
  });
  const fileStorageConfig = promoterDetails?.configs?.admin?.fileStorage;
  initAWSsdk(
    fileStorageConfig.key,
    fileStorageConfig.secret,
    fileStorageConfig.region
  );
  let functionalHomeConfigs = {
    ...promoterDetails?.configs?.functional.home,
    flyer: {
      url: promoterDetails?.configs?.functional?.home?.flyer?.location,
      // eslint-disable-next-line
      name: promoterDetails?.configs?.functional?.home?.flyer?.location?.replace(/^.*[\\\/]/, ""),
    },
  };

  promoterDetails = {
    ...promoterDetails,
    configs: {
      ...promoterDetails.configs,
      functional: {
        ...promoterDetails.configs.functional,
        home: functionalHomeConfigs,
      }
    },
  };

  let refData = await call({
    wsid: guid(),
    type: "PROMOTER",
    subType: "REF_DATA",
  });

  let gpcCategories = localStorage.getItem(promoterDetails.details.code + "_refdata_gpcs");
  if (gpcCategories && gpcCategories !== "[]") {
    gpcCategories = JSON.parse(gpcCategories);
  } else {
    gpcCategories = await getGpcCategories(promoterDetails.details.code);
    localStorage.setItem(promoterDetails.details.code + "_refdata_gpcs", JSON.stringify(gpcCategories));
  }

  let productClasses = localStorage.getItem(promoterDetails.details.code + "_refdata_productClasses");
  if (productClasses && productClasses !== "[]") {
    productClasses = JSON.parse(productClasses);
  } else {
    productClasses = await getProductClasses(promoterDetails.details.code);
    localStorage.setItem(promoterDetails.details.code + "_refdata_productClasses", JSON.stringify(productClasses));
  }

  return { ...promoterDetails, refData: { ...refData, gpcCategories, productClasses } };
};

export default promoterDetailsCall;


import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const promoterListLocations_fullCall = async ({ setLocationsFull }, dispatch) => {
  let rh = requestHelper(dispatch, "PROMOTER_LIST_LOCATIONS");
  try {
    let list = await call({
      type: "PROMOTER",
      subType: "LIST_LOCATIONS",
      request: {},
    });
    dispatch(setLocationsFull(list.locations));
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default promoterListLocations_fullCall;

export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    margin: 0,
  },
  type: {
    margin: 0,
  },
  noMargin: {
    margin: 0,
  },
  entityImage: {
    maxWidth: 300,
    maxHeight: 100,
    // border: "1px solid #eee",
    // borderRadius: 5,
    // padding: 5,
    // backgroundColor: "#FFF",
  },
});

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";

const businessUnitUpdateCall = async (
  dispatch,
  { id,
    name,
    entityId,
    places,
    users,
    groups,
    sectors,
    lines,
    config
  }
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_UPDATE");
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "UPDATE",
      request: {
        id,
        name,
        entityId,
        places,
        users,
        groups,
        sectors,
        lines,
        config
      },
    });

    setTimeout(() => {
      if (result) {
        dispatch(setRedirect("/business/units"));
        dispatch(setSuccess("businessUnits", "BUSINESS_UNIT_UPDATE"));
      }
    }, 300);

  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitUpdateCall;


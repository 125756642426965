export const styles = (theme) => ({
  root: {},
  accordion: {
    background: "none",
    boxShadow: "none",
    "& .MuiAccordionSummary-root": {
      padding: 0,
    },
    "& .MuiAccordionDetails-root": {
      padding: 0,
    },
    "& .MuiAvatar-root": {
      backgroundColor: "white",
    }
  },
  name: {
    margin: 0,
  },
  details: {
    color: theme.palette.text.secondary,
    margin: 0,
    "& a": {
      color: theme.palette.text.secondary,
    },
  },
});

import i18n from "i18next";
import { requestHelper, setRedirect, setSelectedPromoter, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { setConnecting, subscribePromoter, unsubscribePromoter } from "../../../app/websocket/websocketSlice";
import { alertList } from "../../alerts/alertsSlice";

const userChangePromoterCall = async (
  dispatch,
  state,
  { promoterCode, pathToRedirect }
) => {
  let rh = requestHelper(dispatch, "CHANGE_PROMOTER");
  dispatch(setConnecting(true));
  try {
    dispatch(unsubscribePromoter());
    dispatch(subscribePromoter(promoterCode));
    await call({
      type: "USER",
      subType: "CHANGE_PROMOTER",
      request: {
        promoterCode
      },
    });
    dispatch(setSelectedPromoter(promoterCode));
    setTimeout(() => {
      dispatch(setConnecting(false));
      i18n.init({
        backend: {
          loadPath:
            process.env.REACT_APP_PUBLIC_FILE_STORAGE +
            "/" +
            promoterCode +
            "/" +
            process.env.REACT_APP_ENV +
            "/i18n/messages_{{lng}}.json?t=" +
            new Date().getTime(),
        },
      });
      i18n.reloadResources();
      dispatch(alertList(state.alerts.alertsListFilter, true));
      dispatch(setSuccess("users", "CHANGE_PROMOTER"));
      if (pathToRedirect) {
        dispatch(setRedirect(pathToRedirect));
      }
      else {
        dispatch(setRedirect("/"));
      }
    }, 500);
  } catch (ex) {
    rh.error("users", ex);
  }
  rh.close();
};

export default userChangePromoterCall;

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Paper, Grid, Button } from "@mui/material";

import { styles } from "./ProductImportImages.styles";
import { Form, FormFields } from "../../../common/forms";
import { formFields, formSchema } from "./ProductImportImages.schema";
import Permission from "../../../app/components/Permission";
import { usePromoter, useUser } from "../../../common/hooks";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { useDispatch } from "react-redux";
import { productImportProductImages } from "../productsSlice";
import Page from "../../../common/displays/Page/Page";

function ProductImportImages({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();
  const promoter = usePromoter();

  const onSubmitHandler = (values) => {
    dispatch(productImportProductImages(values));
  };

  const entityChangeHandler = (entity, form) => {
    if (entity?.gln) {
      form.change("gln", entity?.gln);
    }
  };

  return (
    <div>
      <Form
        onSubmit={onSubmitHandler}
        initialValues={user?.entity?.type === "P" ? {} : { owner: user?.entity?.id }}
        schema={formSchema(t)}
        render={({ submitting, form }) => {
          return (
            <Page
              permission="products.actions.product.get"
              header={<PageTitle
                title={t("products.ProductImportImages.form.title")}
                actions={
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <Permission code="products.actions.product.import">
                        <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                          {t("products.ProductImportImages.form.submitBtn")}
                        </Button>
                      </Permission>
                    </Grid>
                  </Grid>
                }
              />}>
              <Paper className={classes.paper}>
                <FormFields fields={formFields(t, { user, promoter, form, entityChangeHandler })} />
              </Paper>
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(ProductImportImages, styles)

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Checkbox, Grid, Typography } from "@mui/material";

import { styles } from "./MediaDiff.styles";

function MediaDiff({ classes, value, newValue, hasDiff, onCheck, checked, viewMode }) {
  const { t } = useTranslation();
  const lines = value && newValue ? Math.max(Math.ceil(value.length / 4), Math.ceil(newValue.length / 4)) : 1;

  return (
    <div className={classes.root}>
      <Grid container direction="column">
        <Grid item
          className={classes.headerContainer}>
          <Typography variant="h4" className={classes.header}>
            {t("products.ProductValidationForm.section.media")}
          </Typography>
        </Grid>
        <Grid item >
          <Grid container spacing={2} wrap="nowrap" style={{ height: 150 * lines }}>
            <Grid item flex={1}>
              <Grid container spacing={1}>
                {value.map((image, index) => (
                  <Grid item key={index} xs={3}>
                    <img
                      src={image?.reference}
                      alt={`Original media ${index}`}
                      className={classes.image}
                      style={{ height: 120 }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item flex={1}>
              <Grid container spacing={1}>
                {newValue.map((image, index) => (
                  <Grid item key={index} xs={3}>
                    <img
                      src={image?.reference}
                      alt={`New media ${index}`}
                      className={classes.image}
                      style={{ height: 120 }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item style={{ width: 56 }}>
              {hasDiff && !viewMode && (
                <Checkbox color="success" checked={checked} onChange={(e) => onCheck(e.target.checked)} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(MediaDiff, styles);

export const csvFields = (t) => {
  return [
    {
      field: "entityName",
      header: t("business.queries.ProductPricesList.columns_entityName"),
    },{
      field: "gtin",
      header: t("business.queries.ProductPricesList.columns_gtin"),
    },{
      field: "gln",
      header: t("business.queries.ProductPricesList.columns_gln"),
    },{
      field: "productName",
      header: t("business.queries.ProductPricesList.columns_productName"),
    },{
      field: "priceType",
      header: t("business.queries.ProductPricesList.columns_priceType"),
    },{
      field: "groupName",
      header: t("business.queries.ProductPricesList.columns_groupName"),
    },{
      field: "retailPrice",
      header: t("business.queries.ProductPricesList.columns_retailPrice"),
    },{
      field: "salePrice",
      header: t("business.queries.ProductPricesList.columns_salePrice"),
    },{
      field: "active",
      header: t("business.queries.ProductPricesList.columns_active"),
    }, {
      field: "createdBy",
      header: t("business.queries.ProductPricesList.columns_createdBy"),
    }, {
      field: "createdDate",
      header: t("business.queries.ProductPricesList.columns_createdDate"),
    }, {
      field: "updatedBy",
      header: t("business.queries.ProductPricesList.columns_updatedBy"),
    }, {
      field: "updatedDate",
      header: t("business.queries.ProductPricesList.columns_updatedDate"),
    }
  ];
}

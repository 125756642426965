export const styles = () => ({
  root: {
  },
  loader: {
    width: "100%",
    height: 240,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});
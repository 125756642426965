import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const rolesListDefaultCall = async (
  { setDefaultRoles },
  dispatch
) => {
  let rh = requestHelper(dispatch, "ROLES_LIST_DEFAULT");
  try {
    let list = await call({
      type: "ROLES",
      subType: "LIST_DEFAULT",
      request: {},
    });
    if (list?.defaultRoles) {
      dispatch(setDefaultRoles(list.defaultRoles));
    }
  } catch (ex) {
    rh.error("roles", ex);
  }
  rh.close();
};

export default rolesListDefaultCall;

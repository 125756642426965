export const getEntityCreationTypeOptions = (t, promoter, user) => {
  let userAllowed = user?.permissionFilter("entities.filters.entity.create.type") || [];
  let promoterAllowed = promoter?.getConfig("general", "entityTypes") || [];

  return promoterAllowed
    .filter((value) => userAllowed.includes(value))
    .map((item) => {
      return {
        value: item,
        text: t("common.Enums.entityType." + item),
      };
    });
};

export const getUserRoleOptions = (t, promoter) => {
  let roleTypes = (promoter?.getConfig("general", "roleTypes") || [])
    .filter(item => item !== "C");
  return roleTypes.map((item) => {
    return {
      value: item,
      text: t("common.Enums.roleType." + item),
    };
  })
};

export const getTrafficTypeOptions = (t) => {
  return ["S", "O"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.trafficType." + item),
    };
  });
};

export const getFeeTypeOptions = (t) => {
  return ["O", "I"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.feeType." + item),
    };
  });
};

export const getFeeValueTypeOptions = () => {
  return [
    {
      value: "P",
      text: "%",
    },
    {
      value: "A",
      text: "€",
    },
  ];
};

export const getFrequencyTypeOptions = (t) => {
  return ["A", "S", "Q", "M", "W", "D"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.frequencyType." + item),
    };
  });
};

export const getOperationTypeOptions = (t) => {
  return ["TF", "CD"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.operationType." + item),
    };
  });
};

export const getAddressOperatorOptions = (operators) => {
  return operators && operators.length > 0
    ? operators.map((item) => {
      return {
        value: item.id,
        text: item.description,
      };
    })
    : [];
};

export const getBusinessUnitsOptions = (values) => {
  return [
    { value: 0, text: values.name },
    ...(values.businessUnits || []).map((item, index) => {
      return {
        value: index + 1,
        text: item.businessUnitDesc,
      };
    }),
  ];
};

export const getAddressContactOptions = (values) => {
  return (values.users || []).map((item, index) => {
    return {
      value: index,
      text: (item?.firstName || "") + " " + (item?.lastName || ""),
    };
  });
};

export const getEntityPermissionOptions = (t) => {
  return ["AL", "NA"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.entityPermission." + item),
    };
  });
};

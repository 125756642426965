import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import I18nRow from "./I18nRow/I18nRow";
import defaultI18n from "../../../../../app/i18n/index.js";

///
// i18nKey - String - resource key (form.login)
// i18nValues - Array Strings - values by language
///
function I18nComponent({ classes, component, feature, i18nValues, setI18nValues }) {
  const { i18n } = useTranslation();

  const onChangeHandler = useCallback((area, key, value) => {
    let v = {};
    // base object
    v[i18n.language] = { ...i18nValues?.[i18n.language] } || {};
    // feature object
    v[i18n.language][feature] = i18nValues?.[i18n.language]?.[feature] ? { ...i18nValues?.[i18n.language]?.[feature] } : {};
    // component object
    v[i18n.language][feature][component] =
      i18nValues?.[i18n.language]?.[feature] && i18nValues[i18n.language][feature][component]
        ? { ...i18nValues?.[i18n.language]?.[feature]?.[component] }
        : {};
    // area object
    v[i18n.language][feature][component][area] =
      i18nValues?.[i18n.language]?.[feature] &&
        i18nValues?.[i18n.language]?.[feature]?.[component] &&
        i18nValues?.[i18n.language]?.[feature]?.[component]?.[area]
        ? { ...i18nValues?.[i18n.language]?.[feature]?.[component]?.[area] }
        : {};
    // key
    v[i18n.language][feature][component][area][key] = value;

    setI18nValues({ ...i18nValues, ...v });
    // eslint-disable-next-line
  }, [i18n.language, i18nValues, component, feature]);

  const uiKeys = useMemo(() => {
    const areas = Object.keys(defaultI18n[feature][component]);
    return areas.map((area) => {
      let keys = Object.keys(defaultI18n[feature][component][area]);
      return keys.map((key) => (
        <I18nRow
          classes={classes}
          key={`key_${area}.${key}`}
          label={`${area}.${key}`}
          defaultValue={defaultI18n[feature][component][area][key]}
          value={i18nValues?.[i18n.language]?.[feature]?.[component]?.[area]?.[key] || ""}
          onChange={(v) => onChangeHandler(area, key, v)}
        />
      ));
    });
    // eslint-disable-next-line
  }, [i18n.language, i18nValues?.[i18n.language]?.[feature], defaultI18n])

  return (
    <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={1}>
      <Grid container>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <b>default</b>
        </Grid>
        <Grid item xs={4}>
          <b>{i18n.language}</b>
        </Grid>
      </Grid>
      {uiKeys}
    </Grid>
  );
}

export default I18nComponent;

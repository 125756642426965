export const styles = (theme) => ({
  empty: {
    "& .MuiTreeItem-label": {
      color: theme.palette.error.main,
    },
  },

  add: {
    padding: 0,
  },
});

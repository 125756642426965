export const styles = (theme) => ({
  root: {},
  name: {
    margin: 0,
  },
  details: {
    color: theme.palette.text.secondary,
    margin: 0,
  },
  drawer: {
    width: "25vw",
    padding: theme.spacing(4),
  },
  avatar: {
    width: 80,
    height: 80,
    margin: "0 auto",
  },
  selectedContact: {
    backgroundColor: "#ffffc6",
  },
});

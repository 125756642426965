export const styles = (theme) => ({
  root: {
  },
  table: {
    minWidth: 400,
    overflow: "hidden",
    "& .CdgRow-arrow-wrapper": {
      height: "100%",
      display: "flex"
    },
    "& .CdgRow-arrow": {
      position: "absolute",
      color: theme.palette.error.main,
      left: -20,
      fontSize: "18px",
      fontWeight: 900,
      animation: "bounceArrow 1s infinite"
    }
  },
});


import * as Yup from "yup";
import moment from "moment";
import { getPriceTypesOptions } from "./PriceVariantForm.options";
import { CurrencyField, DatePickerField, MultiSelectField, SelectField } from "../../../../common/forms";

export const formSchema = ({ t }) => {
  return Yup.object().shape({
    priceType: Yup.string().required(t('productsPrices.PriceVariantForm.form.priceTypeRequired')),
    groupId: Yup.string().when("priceType", {
      is: "G",
      then: (schema) => schema.required(t('productsPrices.PriceVariantForm.form.groupIdRequired')),
      otherwise: (schema) => schema,
    }),
    // locations: Yup.array().when("priceType", {
    //   is: "G",
    //   then: (schema) => schema.min(1, t("productsPrices.PriceVariantForm.form.requiredLocations")),
    //   otherwise: (schema) => schema,
    // }),
    effectiveDate: Yup.date()
      // .min(moment().format("YYYY-MM-DDT00:00:00"), t('productsPrices.PriceVariantForm.form.startDateMin'))
      .required(t('productsPrices.PriceVariantForm.form.startDateRequired')),
    expireDate: Yup.date()
      .test(
        'minEndTest',
        t('productsPrices.PriceVariantForm.form.endDateMin'),
        (value, context) => !value || moment(value).isSameOrAfter(moment(context?.parent?.effectiveDate))
      ),
    newSalePrice: Yup.string()
      .not(["0"], t("productsPrices.PriceVariantForm.form.requiredNewPrice"))
      .required(t('productsPrices.PriceVariantForm.form.requiredNewPrice')),
  });
}

export const formFields = ({ t, values, promoter, user, entityGroups, locations }) => {
  return [
    {
      size: 12,
      field: <SelectField
        id="priceType"
        required
        label={t("productsPrices.PriceVariantForm.form.priceType")}
        options={getPriceTypesOptions(t, promoter)}
      />,
    },
    {
      size: 12,
      field: <SelectField
        id="groupId"
        required
        label={t("productsPrices.PriceVariantForm.form.groupId")}
        options={
          (entityGroups || [])
            .filter(eg => eg.entityId === values?.ownerId)
            .map(({ groupId, groupName }) => ({ value: groupId, text: groupName }))
        }
      />,
      hidden: values?.priceType !== "G"
    },
    {
      size: 12,
      field: <MultiSelectField
        id="locations"
        label={t("productsPrices.PriceVariantForm.form.locations")}
        options={(locations || []).map(({ id, name }) => ({ value: id, text: name }))}
      />,
      hidden: values?.priceType !== "W" && values?.priceType !== "D"
    },
    {
      size: 6,
      field: <DatePickerField
        id="effectiveDate"
        label={t("productsPrices.PriceVariantForm.form.startDate")}
        required
      // minDate={moment()} //sogenave_20231122
      />,
    },
    {
      size: 6,
      field: <DatePickerField
        id="expireDate"
        label={t("productsPrices.PriceVariantForm.form.endDate")}
        minDate={values.effectiveDate}
      />,
    },
    {
      size: 6,
      field: <CurrencyField
        id="newSalePrice"
        label={t("productsPrices.PriceVariantForm.form.newSalePrice")}
      />,
    },
    {
      size: 6,
      field: <CurrencyField
        id="newRetailPrice"
        label={t("productsPrices.PriceVariantForm.form.newRetailPrice")}
      />,
      hidden: !promoter?.getConfig("price", "allowRetailPrice")
    },

  ];
}


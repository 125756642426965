import {currencyFormat} from "../../../../../common/utils";

export function listColumns(t, classes) {
  return [
    {
      field: "sellerName",
      headerName: t("business.reports.CostDeliveryList.columns_sellerName"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "deliveryPlace",
      headerName: t("business.reports.CostDeliveryList.columns_deliveryPlace"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "platformCost",
      headerName: t("business.reports.CostDeliveryList.columns_platformCost"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return currencyFormat(v.platformCost);
      },
    },
    {
      field: "transportCost",
      headerName: t("business.reports.CostDeliveryList.columns_transportCost"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return currencyFormat(v.transportCost);
      },
    },
    {
      field: "totalCost",
      headerName: t("business.reports.CostDeliveryList.columns_totalCost"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return currencyFormat(v.totalCost);
      },
    },

  ];
}

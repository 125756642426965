import React from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./OrderStatusStepperIcon.styles";

function OrderStatusStepperIcon({ classes, statusCompleted, error }) {

  return (
    <div className={classes.root + " OrderStatusStepperIcon"}>
      {statusCompleted && !error && <div className={classes.fill + " OrderStatusStepperIconFill"}></div>}
      {error && <div className={classes.fillError + " OrderStatusStepperIconFill"}></div>}
    </div>
  );
}

export default withStyles(OrderStatusStepperIcon, styles);
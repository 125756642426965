import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const promoterDeleteLocationCall = async ({ promoterListLocations }, dispatch, { id }) => {
  let rh = requestHelper(dispatch, "PROMOTER_DELETE_LOCATION");
  try {
    await call({
      type: "PROMOTER",
      subType: "DELETE_LOCATION",
      request: { id },
    });
    setTimeout(() => {
      dispatch(setSuccess("promoter", "PROMOTER_DELETE_LOCATION"));
      dispatch(promoterListLocations());
    }, 300);
  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default promoterDeleteLocationCall;
const documents = {
  flowsMessages: {
    success: {
      DOCUMENT_NEW_VERSION_PDF: "Document with new version has been created successfully!",
      DOCUMENT_PROCESS_IMAGE_RECOGNITION: "Image recognition finished successfully!",
    },
    error: {
      GENERIC: "Something went wrong",
      TIMEOUT: "The request took too long to process",
    },
  },
  list: {
    header: {
      title: "Documents",
      table: "List",
    },
    columns: {
      type: "Type",
      subtype: "SubType",
      version: "Version",
      user: "User",
      date: "Date",
    },
  },
  DocumentTemplate: {
    header: {
      title: "Document Editor",
      subtitle: "Template:",
      saveBtn: "Save",
    },
    editor: {
      templateVars: "Template Variables",
      body: "Template Body",
      version: "Version",
      active: "Active",
      lastVersion: "Last Version",
    },
    versions: {
      title: "Changes list",
      columns_version: "Version",
      columns_date: "Date",
      selectedVersion: "Selected Version",
    },
  },
};

export default documents;

import * as Yup from "yup";
import { ProductPickerField } from "../../../../common/components/formFields";
import { CardSelectField } from "../../../../common/forms";
import { CampaignTypeIcon } from "../../../../common/icons";
import BundleInput from "./BundleInput/BundleInput";
import MerchandiseInput from "./MerchandiseInput/MerchandiseInput";
import PercentageInput from "./PercentageInput/PercentageInput";
import PromotionInput from "./PromotionInput/PromotionInput";

export function formSchema(t) {
  return Yup.object().shape({
    products: Yup.array().when("campaignType", {
      is: "BU",
      then: (schema) => schema,
      otherwise: (schema) => schema.ensure().min(1, t("products.CampaignConfiguration.form.requiredProduct")),
    }),
    bundlingCampaign: Yup.object().when("campaignType", {
      is: "BU",
      then: (schema) =>
        schema.shape({
          productBuy: Yup.array()
            .ensure()
            .min(1, t("products.CampaignConfiguration.form.requiredProduct"))
            .of(
              Yup.object().shape({
                quantity: Yup.number().min(1, t("products.CampaignConfiguration.form.requiredQuantity")),
              })
            ),
          productOffers: Yup.array()
            .ensure()
            .min(1, t("products.CampaignConfiguration.form.requiredProduct"))
            .of(
              Yup.object().shape({
                quantity: Yup.number().min(1, t("products.CampaignConfiguration.form.requiredQuantity")),
              })
            ),
        }),
      otherwise: (schema) => schema,
    }),
  });
}

export function formFields(t, campaignId, viewMode, selectedType, products) {
  let campaignTypeOptions = ["PR", "BU", "MB", "PD"].map((item) => {
    return {
      value: item,
      icon: <CampaignTypeIcon type={item} />,
      description: t("common.Enums.campaignType." + item),
    };
  });

  return [
    {
      size: 8,
      field: (
        <CardSelectField
          id="campaignType"
          label={t("products.CampaignConfiguration.form.campaignType")}
          options={campaignTypeOptions}
          textAlign="center"
          xs={3}
        />
      ),
      hidden: Boolean(campaignId) || viewMode,
    },
    {
      size: 4,
      hidden: Boolean(campaignId) || viewMode,
    },
    {
      size: 6,
      field: (
        <ProductPickerField
          id="products"
          readonly={Boolean(campaignId)}
          label={t("products.CampaignConfiguration.form.products")}
          single
          simple
          disabled={viewMode}
        />
      ),
      hidden: !selectedType || selectedType === "BU",
    },
    {
      size: 6,
      field: (
        <PromotionInput
          id="promotionCampaign.productPrice"
          disabled={viewMode}
          campaignId={campaignId}
          products={products}
        />
      ),
      hidden: selectedType !== "PR",
    },
    {
      size: 6,
      field: <MerchandiseInput id="merchandiseBonusesCampaign" disabled={viewMode} products={products} />,
      hidden: selectedType !== "MB",
    },
    {
      size: 6,
      field: <PercentageInput id="percentageCampaign" disabled={viewMode} />,
      hidden: selectedType !== "PD",
    },
    {
      size: 12,
      field: <BundleInput id="bundlingCampaign" campaignId={campaignId} disabled={viewMode} />,
      hidden: selectedType !== "BU",
    },
  ];
}

export const styles = theme => ({
  root: {
    margin: 0,
  },
  title: {
    margin: 0,
    marginRight: theme.spacing(1)
  },
  subtitle: {
    margin: 0
  },
  error: {
    fontWeight: "normal",
    marginRight: 8
  },
  info: {
    fontWeight: "normal",
    color: "#707784"
  }
});
import DoneIcon from '@mui/icons-material/Done';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ProductSnip from '../../../../common/displays/ProductSnip/ProductSnip';

export function listColumns(t, classes) {
  return [
    {
      field: "productid",
      headerName: t("business.queries.OrdersOverviewList.columns_description"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return <ProductSnip image={v.thumbnail} description={v.description} gtin={v.gtin} />
      },
    },
    {
      field: "sellerName",
      headerName: t("business.queries.OrdersOverviewList.columns_sellerName"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "buyerName",
      headerName: t("business.queries.OrdersOverviewList.columns_buyerName"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "orderId",
      headerName: t("business.queries.OrdersOverviewList.columns_orderId"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "lineId",
      headerName: t("business.queries.OrdersOverviewList.columns_lineId"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "ordered",
      headerName: t("business.queries.OrdersOverviewList.columns_ordered"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "orderDone",
      headerName: t("business.queries.OrdersOverviewList.columns_orderDone"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.orderDone ? (<DoneIcon />) : (<HourglassEmptyIcon />)
      }
    },
    {
      field: "accepted",
      headerName: t("business.queries.OrdersOverviewList.columns_accepted"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "acceptDone",
      headerName: t("business.queries.OrdersOverviewList.columns_acceptDone"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.acceptDone ? (<DoneIcon />) : (<HourglassEmptyIcon />)
      },
    },
    {
      field: "dispatched",
      headerName: t("business.queries.OrdersOverviewList.columns_dispatched"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "dispatchDone",
      headerName: t("business.queries.OrdersOverviewList.columns_dispatchDone"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.dispatchDone ? (<DoneIcon />) : (<HourglassEmptyIcon />)
      }
    },
    {
      field: "reception",
      headerName: t("business.queries.OrdersOverviewList.columns_reception"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "receptionDone",
      headerName: t("business.queries.OrdersOverviewList.columns_receptionDone"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.receptionDone ? (<DoneIcon />) : (<HourglassEmptyIcon />)
      }
    },
    {
      field: "inTransit",
      headerName: t("business.queries.OrdersOverviewList.columns_inTransit"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "inTransitDone",
      headerName: t("business.queries.OrdersOverviewList.columns_inTransitDone"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.inTransitDone ? (<DoneIcon />) : (<HourglassEmptyIcon />)
      }
    },
    {
      field: "delivered",
      headerName: t("business.queries.OrdersOverviewList.columns_delivered"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "deliveryDone",
      headerName: t("business.queries.OrdersOverviewList.columns_deliveryDone"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.deliveryDone ? (<DoneIcon />) : (<HourglassEmptyIcon />)
      }
    }

  ];
}

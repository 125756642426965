import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./AuditRetention.styles";
import {
  listAuditImport,
  selectAuditRetentionList,
  selectAuditRetentionListTotalRows,
  updateAuditImport,
} from "../promoterSlice";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import RetentionDataGrid from "./components/RetentionDataGrid";
import { useFirstLoad } from "../../../common/hooks";
import Page from "../../../common/displays/Page/Page";

function AuditRetention({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const totalRows = useSelector(selectAuditRetentionListTotalRows);
  const data = useSelector(selectAuditRetentionList);

  useFirstLoad(() => {
    dispatch(listAuditImport({}, true));
  });

  const onEditHandler = (values) => {
    dispatch(updateAuditImport(values.tableName, values.numberDays));
  };

  return (
    <Page
      permission="promoter.actions.auditRetention.list"
      header={<PageTitle title={t("promoter.PromoterConfigs.retention.title")} />}>
      <RetentionDataGrid data={data} totalRows={totalRows} onEdit={onEditHandler} />
    </Page>
  );
}

export default withStyles(AuditRetention, styles);

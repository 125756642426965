import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Stepper, Step, StepLabel, StepContent, Box, Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import DoneOutlineOutlinedIcon from '@mui/icons-material/DoneOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

import { styles } from "./OrderStatusDetails.styles";
import StepDetails from "./StepDetails/StepDetails";
import { useNavigate, usePromoter } from "../../../../../common/hooks";
import StepDate from "./StepDate/StepDate";

function OrderStatusDetails({ classes, order, allowAccept, allowDispatch }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const navigate = useNavigate();
  // ["CR", "CO", "AC", "DI", "RE", "IT", "DE", "CD"]
  const confirm = getOrderStatusInfo(classes, order, "CO");
  const accept = getOrderStatusInfo(classes, order, "AC");
  const dispatch = getOrderStatusInfo(classes, order, "DI");
  const reception = getOrderStatusInfo(classes, order, "RE");
  const inTransit = getOrderStatusInfo(classes, order, "IT");
  const delivery = getOrderStatusInfo(classes, order, "DE");
  const confirmDelivery = getOrderStatusInfo(classes, order, "CD");

  const hasLO = promoter?.getConfig("order", "hasLogisticOperator");
  const showCutoff = promoter?.getConfig("cutOff") && !promoter?.getConfig("cutOff", "automatic");

  const orderSellerId = order?.orderSellerIdList?.[0] || order.id;

  const acceptHandler = (e) => {
    e.stopPropagation();
    navigate(`/orders/received/${orderSellerId}/accept`)
  }

  const dispatchHandler = (e) => {
    e.stopPropagation();
    navigate(`/orders/received/${orderSellerId}/dispatch`)
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={7} orientation="vertical">
        <Step expanded>
          <StepLabel icon={<Box className={classes.completedIcon}><AddIcon /></Box>}>
            <Typography variant="caption">{t("common.Enums.orderStatus.CR")}</Typography>
          </StepLabel>
          <StepContent>
            {order.createdTime && <StepDate date={order.createdTime} />}
            {showCutoff && order.cutOffType &&
              <Typography className={classes.cutoff}
                component="span"
                variant="subtitle1"
                color="textSecondary">{`${t("orders.OrderDetails.header.cutoffType")}: ${t("entities.CutOffs.dateType." + order.cutOffType)}`}</Typography>
            }
          </StepContent>
        </Step>
        <Step expanded>
          <StepLabel icon={<Box className={confirm.class}><CheckIcon /></Box>}>
            <Typography className={confirm.textClass} variant="caption">{t("common.Enums.orderStatus.CO")}</Typography>
          </StepLabel>
          {
            confirm.content && <StepContent>
              <StepDetails statusHistory={confirm.statusHistory} />
              {allowAccept && (
                <Button size="small" variant="outlined" color="primary" className={classes.navigateBtn} onClick={acceptHandler}>
                  {t("orders.OrderStatusDetails.navigation.accept")}
                </Button>
              )}
            </StepContent>
          }
        </Step>
        <Step expanded>
          <StepLabel icon={<Box className={accept.class}><CheckIcon /></Box>}>
            <Typography className={accept.textClass} variant="caption">{t("common.Enums.orderStatus.AC")}</Typography>
          </StepLabel>
          {
            accept.content && <StepContent>
              <StepDetails statusHistory={accept.statusHistory} />
              {allowDispatch && (
                <Button size="small" variant="outlined" color="primary" className={classes.navigateBtn} onClick={dispatchHandler}>
                  {t("orders.OrderStatusDetails.navigation.dispatch")}
                </Button>
              )}
            </StepContent>
          }
        </Step>
        <Step expanded>
          <StepLabel icon={<Box className={dispatch.class}><Inventory2OutlinedIcon /></Box>}>
            <Typography className={dispatch.textClass} variant="caption">{t("common.Enums.orderStatus.DI")}</Typography>
          </StepLabel>
          {
            dispatch.content && <StepContent>
              <StepDetails statusHistory={dispatch.statusHistory} />
            </StepContent>
          }
        </Step>
        {hasLO && <Step expanded>
          <StepLabel icon={<Box className={reception.class}><InventoryOutlinedIcon /></Box>}>
            <Typography className={reception.textClass} variant="caption">{t("common.Enums.orderStatus.RE")}</Typography>
          </StepLabel>
          {
            reception.content && <StepContent>
              <StepDetails statusHistory={reception.statusHistory} />
            </StepContent>
          }
        </Step>}
        {hasLO && <Step expanded>
          <StepLabel icon={<Box className={inTransit.class}><LocalShippingOutlinedIcon /></Box>}>
            <Typography className={inTransit.textClass} variant="caption">{t("common.Enums.orderStatus.IT")}</Typography>
          </StepLabel>
          {
            inTransit.content && <StepContent>
              <StepDetails statusHistory={inTransit.statusHistory} />
            </StepContent>
          }
        </Step>}
        <Step expanded>
          <StepLabel icon={<Box className={delivery.class}><StorefrontOutlinedIcon /></Box>}>
            <Typography className={delivery.textClass} variant="caption">{t("common.Enums.orderStatus.DE")}</Typography>
          </StepLabel>
          {
            delivery.content && <StepContent>
              <StepDetails statusHistory={delivery.statusHistory} />
            </StepContent>
          }
        </Step>
        {order?.status === "IN" ?
          <Step expanded>
            <StepLabel icon={<Box className={classes.warnIcon}><WarningAmberOutlinedIcon /></Box>}>
              <Typography className={classes.warnText} variant="caption">{t("common.Enums.orderStatus.IN")}</Typography>
            </StepLabel>
          </Step>
          :
          (hasLO && <Step expanded>
            <StepLabel icon={<Box className={confirmDelivery.class}><DoneOutlineOutlinedIcon /></Box>}>
              <Typography className={confirmDelivery.textClass} variant="caption">{t("common.Enums.orderStatus.CD")}</Typography>
            </StepLabel>
            {
              confirmDelivery.content && <StepContent>
                <StepDetails statusHistory={confirmDelivery.statusHistory} />
              </StepContent>
            }
          </Step>)
        }
      </Stepper>
    </div>
  );
}

export default withStyles(OrderStatusDetails, styles);

const getOrderStatusInfo = (classes, order, status) => {
  let statusHistory = (order?.statusHistory || []).filter(item => item.status === status);
  let pending = (order?.statusToCompleteList || []).some(item => item === status);
  let completeClass = pending ? classes.pendingIcon : classes.completedIcon;

  return {
    statusHistory,
    class: statusHistory.length > 0 ? completeClass : classes.icon,
    content: statusHistory.length > 0,
    textClass: statusHistory.length > 0 ? "" : classes.disabledText,
  }
}
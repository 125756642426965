import {Button, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import React from "react";
import DeleteButton from "../../../../common/components/dialogs/DeleteButton";
import Permission from "../../../../app/components/Permission";
import ForceCutOffDialog from "../ForceCutOffDialog/ForceCutOffDialog";

export default function CutOffsDataGridActions({ row, onEnable, onDisable, onEdit, onForceCutOff }) {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1} justifyContent="flex-end">
      {row.active && (
        <Grid item>
          <Permission code="promoter.actions.cutoff.update">
            <ForceCutOffDialog
              variant="contained"
              color="primary"
              canEdit={false}
              initialValues={{ cutOffType: row.cutOffType, entityId: row.entityId, entity: row.entityName }}
              onConfirm={(values) => {
                onForceCutOff && onForceCutOff(values);
              }}
            />
          </Permission>
        </Grid>
      )}

      <Grid item>
        <Permission code="promoter.actions.cutoff.update">
          <Button variant="contained" color="primary" onClick={() => onEdit && onEdit(row)}>
            {t("entities.CutOffs.columns.editBtn")}
          </Button>
        </Permission>
      </Grid>
      {row.active === false && (
        <Grid item>
          <Permission code="promoter.actions.cutoff.enable">
            <Button variant="contained" color="primary" onClick={() => onEnable && onEnable(row)}>
              {t("entities.CutOffs.columns.enableBtn")}
            </Button>
          </Permission>
        </Grid>
      )}
      {row.active && (
        <Grid item>
          <Permission code="promoter.actions.cutoff.disable">
            <DeleteButton
              labels={{
                button: t("entities.CutOffs.columns.disableBtn"),
                title: t("entities.CutOffs.confirmDisable.title"),
                description: t("entities.CutOffs.confirmDisable.description"),
                ok: t("entities.CutOffs.confirmDisable.ok"),
                cancel: t("entities.CutOffs.confirmDisable.cancel"),
              }}
              onConfirm={() => {
                onDisable && onDisable(row);
              }}
            />
          </Permission>
        </Grid>
      )}
    </Grid>
  );
}

export function listColumns(t, classes) {
  return [
    {
      field: "name",
      headerName: t("common.EntityPicker.columns.name"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "taxNumber",
      headerName: t("common.EntityPicker.columns.taxNumber"),
      align: "left",
      headerAlign: "left",
    },
  ];
}

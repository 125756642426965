import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Typography } from "@mui/material";

import { styles } from "./DpromRemoveModal.styles";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";
import { DatePickerInput } from "../../../../common/inputs";
import moment from "moment";

function DpromRemoveModal({ classes, open, setOpen, onRemoveConfirm, dpromToRemove }) {
  const { t } = useTranslation();
  const [removeDate, setRemoveDate] = useState(moment().format('YYYY-MM-DD'));

  return dpromToRemove ? (
    <CustomModal open={open} setOpen={setOpen} size="medium" height={205} title={t("productsDproms.DpromRemoveModal.modal.title")}>
      <Grid container spacing={2} direction="column">
        {dpromToRemove.status === "PND" ?
          <Grid item>
            <Typography>{t("productsDproms.DpromRemoveModal.modal.cancelMessage")}</Typography>
          </Grid> :
          <Grid item>
            <DatePickerInput label={t("productsDproms.DpromRemoveModal.modal.removeDate")} value={removeDate} onChange={(date) => setRemoveDate(moment(date).format('YYYY-MM-DD'))} minDate={moment()} />
          </Grid>
        }
        <Grid item>
          <Button variant="contained" fullWidth onClick={() => onRemoveConfirm(removeDate)}>{t("productsDproms.DpromRemoveModal.modal.confirmBtn")}</Button>
        </Grid>
      </Grid>
    </CustomModal>
  ) : null;
}

export default withStyles(DpromRemoveModal, styles);

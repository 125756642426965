import React from "react";
import { Checkbox } from "@mui/material";

export function listColumns(
  t,
  classes,
  selectedEntities,
  onEntitysSelectedChange,
  hideCheckbox,
  allowBulkSelect,
  bulkSelected,
  allEntities,
  onBulkSelectChange
) {
  const onEntityCheckedChange = (checked, entity) => {
    if (checked) {
      onEntitysSelectedChange && onEntitysSelectedChange([...selectedEntities, entity]);
    } else {
      onEntitysSelectedChange &&
        onEntitysSelectedChange([...selectedEntities.filter((item) => item.entityId !== entity.entityId)]);
    }
  };
  return [
    {
      field: "entityId",
      align: "left",
      headerAlign: "left",
      disableSort: true,
      width: 64,
      hidden: hideCheckbox,
      headerComponent: allowBulkSelect && (
        <Checkbox
          className={classes.checkbox}
          color="primary"
          checked={Boolean(bulkSelected || allEntities)}
          disabled={allEntities}
          onChange={(e) => onBulkSelectChange(e.target.checked)}
        />
      ),
      customValue: (v) => {
        let checked = selectedEntities ? Boolean(selectedEntities.find((item) => item.entityId === v.entityId)) : false;
        return (
          <Checkbox
            className={classes.checkbox}
            color="primary"
            checked={checked}
            onChange={(e) => onEntityCheckedChange(e.target.checked, v)}
          />
        );
      }
    },
    {
      field: "name",
      headerName: t("common.EntityPicker.columns.name"),
      align: "left",
      headerAlign: "left",
      disableSort: true
    },
    {
      field: "taxNumber",
      headerName: t("common.EntityPicker.columns.taxNumber"),
      align: "left",
      headerAlign: "left",
      disableSort: true
    },
  ];
}

import { requestHelper, setSuccess, setRedirect, getPromoterConfig } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { saveFile } from "../../../api/s3";
import { base64ToArrayBuffer } from "../../../common/utils";

const productImportProductImagesCall = async (dispatch, state, { owner, gln, file }) => {
  let rh = requestHelper(dispatch, "PRODUCT_IMPORT_PRODUCT_IMAGES");
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  const { provider, tempBucket } = fileStorageConfigs
  try {
    let url = await saveFile(
      provider,
      tempBucket,
      gln + "_" + file.name,
      base64ToArrayBuffer(file.base64)
    );
    await call({
      type: "PRODUCT",
      subType: "IMPORT_PRODUCT_IMAGES",
      request: {
        url,
        fileType: 3,
        processData: {
          owner,
          gln,
        },
      },
    });
    setTimeout(() => {
      dispatch(setRedirect("/products/"));
      dispatch(setSuccess("products", "PRODUCT_IMPORT_PRODUCT_IMAGES"));
    }, 300);
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productImportProductImagesCall;

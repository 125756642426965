export const resourceListDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "TITLE",
  sortType: "ASC",
  type: [],
  search: "",
  placeId: [],
  active: true,
}


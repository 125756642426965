import { notificationSlice } from "./notificationSlice";
import { requestHelper, setSuccess } from "../../app/coreSlice";
import { call } from "../../app/websocket/_sliceCalls/websocket";

export const selectEmailList = (state) => state.notification.emailsList;
export const selectEmailTemplate = (state) => state.notification.emailTemplate;
export const selectLoader = (state) => state.core.loader.length > 0;

export const { setEmailsList, setEmailTemplate } = notificationSlice.actions;

export const notificationListEmail = () => async (dispatch) => {
  let rh = requestHelper(dispatch, "NOTIFICATION/LIST_EMAIL");

  try {
    let result = await call({
      type: "NOTIFICATION",
      subType: "LIST_EMAIL",
      request: {},
    });

    dispatch(setEmailsList(result.messages));
  } catch (ex) {
    rh.error("notification", ex);
  }
  rh.close();
};

export const notificationGetEmail = (id) => async (dispatch) => {
  let rh = requestHelper(dispatch, "NOTIFICATION/GET_EMAIL");

  try {
    let result = await call({
      type: "NOTIFICATION",
      subType: "GET_EMAIL",
      request: {
        id,
      },
    });
    dispatch(
      setEmailTemplate({
        ...result.message,
        active: result.message?.versions?.length === result.message?.version + 1,
      })
    );
  } catch (ex) {
    rh.error("notification", ex);
  }
  rh.close();
};

export const notificationNewVersionEmail = (id, subject, body, navigate) => async (dispatch) => {
  let rh = requestHelper(dispatch, "NOTIFICATION/NEW_VERSION_EMAIL");

  try {
    let result = await call({
      type: "NOTIFICATION",
      subType: "NEW_VERSION_EMAIL",
      request: {
        id,
        subject,
        body,
      },
    });

    if (result && result.id) {
      dispatch(setSuccess("emails", "NOTIFICATION_NEW_VERSION_EMAIL"));
      navigate("/emails/" + result.id);
    }
  } catch (ex) {
    rh.error("notification", ex);
  }
  rh.close();
};

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useNavigate } from "react-router-dom";

import { styles } from "./LoadingScreen.styles";

import { useUser } from "../../../common/hooks";
import { selectLoader, selectPromoterDetails } from "../../../app/coreSlice";
import {
  selectConnecting,
  selectConnected,
  logout,
} from "../../../app/websocket/websocketSlice";

function LoadingScreen({ classes }) {
  const loading = useSelector(selectLoader);
  const connecting = useSelector(selectConnecting);
  const connected = useSelector(selectConnected);
  const user = useUser();
  const promoter = useSelector(selectPromoterDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && connected && !connecting && (!user || !promoter)) {
      dispatch(logout());
      navigate("/login");
    }
    // eslint-disable-next-line
  }, [loading, user, promoter]);

  return (
    <div className={classes.root}>
      <h3>LOADING...</h3>
    </div>
  );
}

export default withStyles(LoadingScreen, styles);

export const styles = theme => ({
  root: {
  },
  deleteButton: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.background.error,
    "&:hover": {
      backgroundColor: theme.palette.background.errorDark
    }
  }
});
export const aggregatedOrdersDefaultFilter = {
  limit: 30,
  offset: 0,
  sortField: "ID",
  sortType: "DESC",
  orderStatus: ["CR", "CO", "AC", "DI", "RE", "IT", "DE"],
  startDate: undefined,
  endDate: undefined,
}

export const addressAggregatedOrdersDefaultFilter = {
  limit: 30,
  offset: 0,
  sortField: "ID",
  sortType: "DESC",
  orderStatus: ["CR", "CO", "AC", "DI", "RE", "IT", "DE", "CD", "IN"],
}

export const ordersMissingProductsDataGridDefaultFilter = {
  limit: 30,
  offset: 0,
  sortField: "ID",
  sortType: "DESC",
  orderStatus: "DI",
  seller: null,
}

export const ordersIncidentsDefaultFilter = {
  limit: 30,
  offset: 0,
  sortField: "CODE",
  sortType: "ASC",
  codes: null,
  searchField: null,
  startDate: null,
  endDate: null,
  search: null,
}
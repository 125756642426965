export const styles = (theme) => ({
  root: {},
  nomargin: {
    margin: 0,
  },
  id: {
    margin: 0,
  },
  orderBy: {
    margin: 0,
    color: "#aaa",
  },
});

import React from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./ProductsMosaic.styles";
import MosaicGrid from "../../../../common/components/MosaicGrid/MosaicGrid";
import ProductTile from "./ProductTile/ProductTile";

function ProductsMosaic({ classes, data, currentOrder, onLoadMore, offers, onPriceChanged, onQuantityChanged }) {
  return (
    <div className={classes.root}>
      <MosaicGrid
        data={data}
        dataExtra={offers}
        onLoadMore={onLoadMore}
        itemKeyField="key"
        render={(v) => {
          return (
            <ProductTile
              product={v}
              onPriceChanged={onPriceChanged}
              onQuantityChanged={onQuantityChanged}
              currentOrder={currentOrder}
            />
          );
        }}
      />
    </div>
  );
}

export default withStyles(ProductsMosaic, styles)

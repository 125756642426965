import { Typography } from "@mui/material";

export function listColumns(t) {
  let columns = [];


  columns.push({
    field: "title",
    headerName: t("resources.ResourceList.columns.title"),
    align: "left",
    headerAlign: "left",
  });

  columns.push({
    field: "type",
    headerName: t("resources.ResourceList.columns.type"),
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{t("common.Enums.resourceTypes." + v.type)}</Typography>
    }
  });

  columns.push({
    field: "placeName",
    headerName: t("resources.ResourceList.columns.placeName"),
    align: "left",
    headerAlign: "left",
  });

  columns.push({
    field: "parentName",
    headerName: t("resources.ResourceList.columns.parentName"),
    align: "left",
    headerAlign: "left",
  });

  columns.push({
    field: "state",
    headerName: t("resources.ResourceList.columns.state"),
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{t("common.Enums.resourceStates." + v.state)}</Typography>
    }
  });

  columns.push({
    field: "active",
    headerName: t("resources.ResourceList.columns.status"),
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      if (v.active) {
        return t("resources.ResourceList.columns.statusActive");
      }
      return t("resources.ResourceList.columns.statusInactive");
    },
  });


  return columns;
}

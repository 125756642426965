export const getInfoTypeOptions = (t, taskType) => {
  let options = ["text", "warning"];
  if (taskType === "B") {
    options.push("batch_distribution")
  }
  return (options)
    .map((item) => {
      return {
        value: item,
        text: t("common.Enums.taskInfoType." + item),
      };
    });
};
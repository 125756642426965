import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";

import { styles } from "./PriceRemoveModal.styles";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";
import { DatePickerInput } from "../../../../common/inputs";
import moment from "moment";

function PriceRemoveModal({ classes, open, setOpen, onRemoveConfirm }) {
  const { t } = useTranslation();
  const [removeDate, setRemoveDate] = useState(moment().format('YYYY-MM-DD'));

  return (
    <CustomModal open={open} setOpen={setOpen} size="medium" height={205} title={t("productsPrices.PriceRemoveModal.modal.title")}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <DatePickerInput label={t("productsPrices.PriceRemoveModal.modal.removeDate")} value={removeDate} onChange={(date) => setRemoveDate(moment(date).format('YYYY-MM-DD'))} minDate={moment()} />
        </Grid>
        <Grid item>
          <Button variant="contained" fullWidth onClick={() => onRemoveConfirm(removeDate)}>{t("productsPrices.PriceRemoveModal.modal.confirmBtn")}</Button>
        </Grid>
      </Grid>
    </CustomModal>
  );
}

export default withStyles(PriceRemoveModal, styles);

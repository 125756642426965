import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { businessUnitList } from "../businessUnitsSlice";

const businessUnitDeleteCall = async (
  dispatch,
  { id },
  state
) => {
  let filter = state.businessUnits.businessUnitListFilter;
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_DELETE");
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "DELETE",
      request: {
        id
      },
    });

    setTimeout(() => {
      if (result && result.success) {
        dispatch(setSuccess("businessUnits", "BUSINESS_UNIT_DELETE"));
        dispatch(businessUnitList(filter, true))
      }
    }, 300);

  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitDeleteCall;


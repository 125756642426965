import { createSlice } from "@reduxjs/toolkit";
import rolesGetCall from "./_sliceCalls/rolesGetCall";
import rolesListCall from "./_sliceCalls/rolesListCall";
import rolesListDefaultCall from "./_sliceCalls/rolesListDefault";
import rolesUpdateCall from "./_sliceCalls/rolesUpdateCall";
import { rolesDefaultFilter } from "./_common/rolesFilters";
import rolesCreateCall from "./_sliceCalls/rolesCreateCall";
import rolesDeleteCall from "./_sliceCalls/rolesDeleteCall";

export const rolesSlice = createSlice({
  name: "roles",
  initialState: {
    rolesList: null,
    rolesListTotalRows: 0,
    rolesListFilter: rolesDefaultFilter,
    roleDetails: null,
    defaultRoles: null,
    rolesUserList: null,
  },
  reducers: {
    clearRolesList: (state) => {
      state.rolesList = [];
    },
    appendToRolesList: (state, action) => {
      state.rolesList = [...state.rolesList, ...action.payload];
    },

    setRolesListTotalRows: (state, action) => {
      state.rolesListTotalRows = action.payload;
    },

    setRolesListFilter: (state, action) => {
      state.rolesListFilter = action.payload;
    },

    setRoleDetails: (state, action) => {
      state.roleDetails = action.payload;
    },

    setDefaultRoles: (state, action) => {
      state.defaultRoles = action.payload;
    },

    setRolesUsersList: (state, action) => {
      state.rolesUserList = action.payload;
    },
  },
});

export default rolesSlice.reducer;


// SELECTS
export const selectRolesListTotalRows = (state) => state.roles.rolesListTotalRows;
export const selectRolesListFilter = (state) => state.roles.rolesListFilter;
export const selectRolesList = (state) => state.roles.rolesList;
export const selectRolesUserList = (state) => state.roles.rolesUserList;
export const selectDefaultRolesList = (state) => state.roles.defaultRoles;
export const selectRoleDetails = (state) => state.roles.roleDetails;

// CALLS
export const rolesList = (filter, clear = false) => (dispatch) => {
  rolesListCall(rolesSlice.actions, dispatch, { filter, clear });
};

export const rolesListDefault = () => (dispatch) => {
  rolesListDefaultCall(rolesSlice.actions, dispatch);
};

export const rolesUpdate = ({ id, designation, entityId, entityType, roleType, permissions, user, group, sector }) => (dispatch) => {
  rolesUpdateCall(dispatch, { id, designation, entityId, entityType, roleType, permissions, user, group, sector });
};

export const rolesCreate = ({ entityId, entityType, designation, permissions, user, group, sector }) => (dispatch) => {
  rolesCreateCall(dispatch, { entityId, entityType, designation, permissions, user, group, sector });
};

export const rolesGet = ({ roleID }) => (dispatch) => {
  rolesGetCall(rolesSlice.actions, dispatch, { roleID });
};

export const rolesDelete = ({ roleID }) => (dispatch, getState) => {
  rolesDeleteCall({ rolesList }, dispatch, getState(), { roleID });
}
import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Modal, Button, Grid, Typography } from "@mui/material";

import { styles } from "./ProductPickerModal.styles";
import ProductPickerDataGrid from "../ProductPickerDataGrid/ProductPickerDataGrid";

import PageTitle from "../../../../displays/PageTitle/PageTitle";
import SearchBox from "../../../../components/inputs/SearchBox";

function ProductPickerModal({
  classes,
  open,
  setOpen,
  products,
  totalRows,
  selectedProducts,
  filter,
  onFilterChange,
  onProductsSelectedChange,
  single,
  allProducts,
  onAllProductsChange,
  localFilters,
}) {
  const { t } = useTranslation();

  const [bulkSelected, setBulkSelected] = useState(allProducts);

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange && onFilterChange(nFilter, true);
  };

  const onLoadMoreHandler = () => {
    if (products.length < totalRows) {
      let nFilter = {
        ...filter,
        offset: products.length,
      };
      onFilterChange && onFilterChange(nFilter);
    }
  };

  const onProductsSelectedChangeHandler = (products) => {
    onProductsSelectedChange && onProductsSelectedChange(products);
  };

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search,
      offset: 0,
    };
    onFilterChange && onFilterChange(nFilter, true);
  };
  return (
    <Modal open={open} className={classes.modal} onClose={() => setOpen(false)}>
      <div className={classes.modalroot}>
        <PageTitle
          title={t("common.ProductPicker.modal.title")}
          actions={
            <Grid container spacing={2}>
              <Grid item>
                <SearchBox onEnter={(v) => onSearchHandler(v)} />
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" size="large" onClick={() => setOpen(false)}>
                  {t("common.ProductPicker.modal.closeBtn")}
                </Button>
              </Grid>
            </Grid>
          }
        />

        <Grid container className={classes.modalbody} direction="column">
          <Grid item style={{ flexGrow: 1 }} className={classes.gridBox}>
            {bulkSelected && (
              <Grid container justifyContent="center">
                <Grid item>
                  {allProducts ? (
                    <Typography
                      variant="caption"
                      color="primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        onAllProductsChange(false);
                        setBulkSelected(false);
                      }}
                    >
                      {t("common.ProductPicker.modal.unselectAll")}
                    </Typography>
                  ) : (
                    <Typography
                      variant="caption"
                      color="primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => onAllProductsChange(true)}
                    >
                      {t("common.ProductPicker.modal.selectAll", {
                        selected: selectedProducts.length,
                        total: totalRows,
                      })}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
            <div className={classes.grid}>
              <ProductPickerDataGrid
                bulkSelected={bulkSelected}
                localFilters={localFilters}
                setBulkSelected={setBulkSelected}
                products={products}
                totalRows={totalRows}
                selectedProducts={selectedProducts}
                onFilter={onFilterHandler}
                onLoadMore={onLoadMoreHandler}
                onProductsSelectedChange={(v) => onProductsSelectedChangeHandler(v)}
                single={single}
                allowBulkSelect={!Boolean(filter.search) && !single}
                allProducts={allProducts}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}

export default withStyles(ProductPickerModal, styles);

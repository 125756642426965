import { Chip, Stack, Tooltip } from "@mui/material";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import moment from "moment";

export function listColumns({ t, user, promoter, validation, onVatClick }) {
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];


  const onVatClickHandler = (v) => {
    const allowedToChange = user?.entity?.type !== "P" || !promoter?.getConfig("product", "requiresInternalCode") || v.promoterInternalCode;
    if (user.hasPermission("products.actions.taxes.create") && allowedToChange) {
      onVatClick && onVatClick(v);
    }
  }

  let columns = [];

  columns.push({
    field: "description",
    headerName: t("products.ProductTaxList.columns.productId"),
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      const showPromoterDescription = Boolean(enabledProductFields.includes("promoterDescription") && user?.entity?.type === "P" && v.promoterDescription);
      return <ProductSnip image={v.thumbnail} description={showPromoterDescription ? v.promoterDescription : v.description} gtin={v.gtin} promoterInternalCode={v.promoterInternalCode} />;
    }
  });

  if (user?.entity?.type === "P") {
    columns.push({
      field: "ownerName",
      headerName: t("products.ProductTaxList.columns.ownerName"),
      align: "center",
      headerAlign: "center",
    });
  }

  columns.push({
    field: "vat",
    headerName: t("products.ProductTaxList.columns.vat"),
    align: "center",
    headerAlign: "center",
    disableSort: true,
    width: 200,
    customValue: (v) => {
      if (v.hasChanges && !validation) {
        return <Stack direction="row" spacing={1} alignItems="center" style={{ width: "100%" }} justifyContent="flex-end">
          <Chip
            label={t('common.Enums.vat.' + (v.ogTax?.vat || "VAT_0"))}
            variant="filled"
            color="primary"
          />
          <ArrowRightAltIcon style={{ display: "block" }} color="info" />
          <Chip
            label={t('common.Enums.vat.' + (v.tax?.vat || "VAT_0"))}
            variant="outlined"
            color="info"
            onClick={() => onVatClickHandler(v)}
          />
        </Stack>
      }
      if (v?.ogTax?.process?.taxes?.[v?.ogTax?.process?.vatType] === "PENDING") {
        return <Stack direction="row" spacing={1} alignItems="center" style={{ width: "100%" }} justifyContent="flex-end">
          <Chip
            label={t('common.Enums.vat.' + (v.ogTax?.vat || "VAT_0"))}
            variant="filled"
            color="primary"
          />
          <ArrowRightAltIcon style={{ display: "block" }} color="warning" />
          <Chip
            label={t('common.Enums.vat.' + (v.ogTax?.process?.vatType || "VAT_0"))}
            variant="filled"
            color="warning"
          />
          {
            (v.ogTax?.process?.startDate && moment(v.ogTax?.process?.startDate).isAfter(moment().startOf('day'))) ?
              <Tooltip title={t("products.ProductTaxList.columns.startDateWarn", { date: moment(v.ogTax?.process?.startDate).format("yyyy-MM-DD") })}>
                <WarningAmberIcon style={{ display: "block" }} color="warning" />
              </Tooltip> :
              null
          }
        </Stack>
      }
      if (v?.ogTax?.process?.taxes?.[v?.ogTax?.process?.vatType] === "REFUSED") {
        return <Stack direction="row" spacing={1} alignItems="center" style={{ width: "100%" }} justifyContent="flex-end">
          <Chip
            label={t('common.Enums.vat.' + (v.ogTax?.vat || "VAT_0"))}
            variant="filled"
            color="primary"
          />
          <ArrowRightAltIcon style={{ display: "block" }} color="primary" />
          <Tooltip title="REFUSED">
            <Chip
              label={t('common.Enums.vat.' + (v.ogTax?.process?.vatType || "VAT_0"))}
              style={{ textDecoration: "line-through" }}
              variant={validation ? "filled" : "outlined"}
              color="primary"
              onClick={validation ? undefined : () => onVatClickHandler(v)}
            />
          </Tooltip>
        </Stack>
      }
      if (v.ogTax?.process?.startDate && moment(v.ogTax?.process?.startDate).isAfter(moment().startOf('day')) && v?.ogTax?.process?.taxes?.[v?.ogTax?.process?.vatType] !== "CANCELLED") {
        return <Stack direction="row" spacing={1} alignItems="center" style={{ width: "100%" }} justifyContent="flex-end">
          <Chip
            label={t('common.Enums.vat.' + (v.ogTax?.vat || "VAT_0"))}
            variant="filled"
            color="primary"
          />
          <ArrowRightAltIcon style={{ display: "block" }} color="primary" />
          <Chip
            label={t('common.Enums.vat.' + (v.ogTax?.process?.vatType || "VAT_0"))}
            variant="outlined"
            color="primary"
            onClick={validation ? undefined : () => onVatClickHandler({
              ...v,
              tax: {
                ...v.tax,
                vat: v.ogTax?.process?.vatType,
                startDate: (v.ogTax?.process?.startDate && moment(v.ogTax?.process?.startDate).isAfter(moment().startOf('day'))) ?
                  v.ogTax?.process?.startDate :
                  moment().format("yyyy-MM-DD")
              },
            })}
          />
          {
            (v.ogTax?.process?.startDate && moment(v.ogTax?.process?.startDate).isAfter(moment().startOf('day'))) ?
              <Tooltip title={t("products.ProductTaxList.columns.startDateWarn", { date: moment(v.ogTax?.process?.startDate).format("yyyy-MM-DD") })}>
                <WarningAmberIcon style={{ display: "block" }} color="primary" />
              </Tooltip> :
              null
          }
        </Stack>
      }
      return <Stack direction="row" spacing={1} alignItems="center" style={{ width: "100%" }} justifyContent="flex-end">
        <Chip
          label={t('common.Enums.vat.' + (v.tax?.vat || "VAT_0"))}
          variant={validation ? "filled" : "outlined"}
          color="primary"
          onClick={validation ? undefined : () => onVatClickHandler(v)}
        />
      </Stack >
    }
  });

  return columns;
}
import React from "react";
import { withStyles } from "tss-react/mui";
import { Stack, Typography } from "@mui/material";
import SouthEastIcon from '@mui/icons-material/SouthEast';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { styles } from "./Currency.styles";
import { usePromoter } from "../../hooks";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

function Currency({ classes, value, previousValue }) {
  const promoter = usePromoter();
  let currency;

  if (!value && value !== 0) {
    return "-";
  }

  switch (promoter?.getConfig("general", "defaultCurrency")) {
    case "USD":
      currency = "$";
      break;
    default:
      currency = "€";
      break;
  }

  const diff = (value && previousValue) ? parseFloat(value) - parseFloat(previousValue) : undefined;
  const change = (value && previousValue) ? (Math.abs(1 - (parseFloat(value) / parseFloat(previousValue))) * 100).toFixed(1) : undefined;

  return (
    <CustomTooltip title={diff && change ?
      <Stack justifyContent="center" alignItems="center">
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>{`${parseFloat(previousValue).toFixed(2)} ${currency}`}</Typography>
          <ArrowRightAltIcon />
          <Typography>{`${parseFloat(value).toFixed(2)} ${currency}`}</Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>{`${diff > 0 ? "+" : "-"} ${Math.abs(diff).toFixed(2)} ${currency} (${change}%)`}</Typography>
        </Stack>
      </Stack> : null
    }>
      <Stack className={classes.currency} direction="row" flexWrap="nowrap" alignItems="center" justifyContent="flex-end" spacing={1}>
        <Typography>
          {parseFloat(value) !== 0 ? `${parseFloat(value).toFixed(2)} ${currency}` : "-"}
        </Typography>
        {diff && (diff < 0 ? <SouthEastIcon color="success" /> : <NorthEastIcon color="error" />)}
      </Stack>
    </CustomTooltip>
  );
}

export default withStyles(Currency, styles);

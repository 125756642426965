import moment from "moment"

export const accountingDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "OWNERNAME",
  sortType: "DESC",
}

export const auditDataDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "CHANGEDATE",
  sortType: "DESC",
  dateIni: undefined,
  dateEnd: undefined,
  schema: undefined,
  table: undefined,
  username: undefined,
  defaultDates: true,
}

export const auditImportDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "TIME",
  sortType: "DESC",
  dateIni: undefined,
  dateEnd: undefined,
  error: undefined,
  type: undefined,
  subType: undefined
}

export const auditLoginsDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "DATETIME",
  sortType: "DESC",
  dateIni: undefined,
  dateEnd: undefined,
  ipAddress: undefined,
  action: undefined,
  username: undefined,
  entity: undefined,
}

export const auditWsDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "REQUESTTIME",
  sortType: "DESC",
  requestDateIni: undefined,
  requestDateEnd: undefined,
  ipAddress: undefined,
  operation: undefined,
  error: undefined,
  entity: undefined,
  type: undefined,
}

export const historyOrderMovementsDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "ORDERSELLERID",
  sortType: "DESC",
  month: new Date().getMonth() + 1,
  year: new Date().getFullYear(),
  searchField: undefined,
  search: undefined,
}

export const queriesReportMissingShipmentsDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "ORDERSELLERID",
  sortType: "DESC",
  month: new Date().getMonth() + 1,
  year: new Date().getFullYear(),
  entityId: undefined,
}

export const queriesReportOrdersOverviewDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "ORDERSELLERID",
  sortType: "DESC",
  month: new Date().getMonth() + 1,
  year: new Date().getFullYear(),
  searchField: undefined,
  search: undefined,
  discrepancies: false,
  entityId: undefined,
}

export const queriesReportProductPricesDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "ENTITYNAME",
  sortType: "DESC",
  search: undefined,
  active: undefined,
  entityId: undefined,
}

export const queriesReportProductDefaultFilter = {
  limit: 250,
  offset: 0,
  sortField: "CREATEDTIME",
  sortType: "DESC",
  search: undefined,
  createdTimeIni: undefined,
  createdTimeEnd: undefined,
  createdBy: undefined,
  createdByEntity: undefined,
}

export const reportWeeklyUnitsDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "SELLERNAME",
  sortType: "DESC",
  week: moment().isoWeek(),
  year: new Date().getFullYear(),
  supplier: undefined,
  cutOff: "ANY"
}

export const reportWeeklyUnitsAcceptedDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "SELLERNAME",
  sortType: "DESC",
  week: moment().isoWeek(),
  year: new Date().getFullYear(),
  supplier: undefined,
  cutOff: "ANY"
}

export const reportWeeklyUnitsDeliveredDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "SELLERNAME",
  sortType: "DESC",
  week: moment().isoWeek(),
  year: new Date().getFullYear(),
  supplier: undefined,
  cutOff: "ANY"
}

export const reportSuppliersDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "SELLERNAME",
  sortType: "DESC",
  month: new Date().getMonth() + 1,
  year: new Date().getFullYear(),
  supplier: undefined
}

export const auditExternalDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "REQUESTTIME",
  sortType: "DESC",
  requestDateIni: undefined,
  requestDateEnd: undefined,
  method: undefined,
  ipAddress: undefined,
  operation: undefined,
  error: undefined,
  type: undefined,
  subType: undefined,
  entity: undefined,
}

export const auditJobDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "STARTTIME",
  sortType: "DESC",
  startTime: undefined,
  endTime: undefined,
  type: undefined,
  subType: undefined,
  error: undefined,
  jobId: undefined,
  status: undefined,
}
export const auditI18nDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "DATETIME",
  sortType: "DESC",
  dateIni: undefined,
  dateEnd: undefined,
  language: undefined,
  username: undefined,
  entity: undefined,
}
export const processListDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "CREATEDTIME",
  sortType: "DESC",
  dateIni: undefined,
  dateEnd: undefined,
  processTypes: [],
  status: [],
  active: undefined,
  search: null,
}

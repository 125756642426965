import React from "react";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";

import { useNavigate } from "../../../../common/hooks";
import Permission from "../../../../app/components/Permission";

export default function PendingEntityDataGridActions({ row }) {
  const { t } = useTranslation();
  // add permissions
  const navigate = useNavigate();

  return (
    <Grid container spacing={1} justifyContent="flex-end">
      {row?.waitingAction && (row?.status === "PENDING" || row?.status === "VALIDATING") && (
        <Permission code="entities.actions.pendingEntity.action">
          <Grid item>
            <Button
              onClick={() => navigate(`/entities/${row?.entityId}/validation/${row?.taskId}`)}
              color="primary"
              variant="outlined"
            >
              <Grid container spacing={1} alignItems="center" wrap="nowrap">
                <Grid item style={{ display: "flex" }}>
                  <CheckIcon />
                </Grid>
                <Grid item>{t("entities.PendingEntityList.actions.confirm")}</Grid>
              </Grid>
            </Button>
          </Grid>
        </Permission>
      )}

    </Grid>
  );
}

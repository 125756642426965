import * as Yup from "yup";
import { MultiSelectField, TextField } from "../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    name: Yup.string().required(t("promoter.LocationsForm.form.requiredName")),
    code: Yup.string()
  });
}

export function formFields({ t, formOptions }) {
  return [
    {
      size: 6,
      field: (
        <TextField
          id="name"
          type="text"
          label={t("promoter.LocationsForm.form.name")}
          required
        />
      ),
    },
    {
      size: 12,
    },
    {
      size: 6,
      field: (
        <TextField
          id="code"
          type="text"
          label={t("promoter.LocationsForm.form.code")}
        />
      ),
    },
    {
      size: 12,
    },
    {
      size: 6,
      field: (
        <MultiSelectField
          id="states"
          label={t("promoter.LocationsForm.form.states")}
          options={formOptions.states}
        />
      ),
    },
  ];
}

import React from "react";
import {withStyles} from "tss-react/mui";
import {Grid, Typography} from "@mui/material";
import {dateTimeFormatWithSeconds} from "../../utils/formats";
import {styles} from "./ActionSnip.styles";

function EntitySnip({ classes, username, date }) {

    let title = dateTimeFormatWithSeconds(date);
    let description = username;

    return (
        <div className={classes.root}>
            <Grid container alignItems="center" >
                <Grid item xs={12}>
                    <Typography className={classes.nomargin}>{title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography color="textSecondary" className={classes.nomargin}>
                        {description}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default withStyles(EntitySnip, styles)

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess, setRedirect } from "../../../app/coreSlice";

const batchCreateCall = async (
  dispatch,
  {
    batchNumber,
    productId,
    businessUnitId,
    type,
    startDate,
    description,
    batchOrders,
  }
) => {
  let rh = requestHelper(dispatch, "BATCH_CREATE");
  try {
    let result = await call({
      type: "BATCH",
      subType: "CREATE",
      request: {
        batchNumber,
        productId,
        businessUnitId,
        type,
        startDate,
        description,
        batchOrders,
      },
    });

    setTimeout(() => {
      if (result) {
        dispatch(setRedirect("/batches/" + businessUnitId));
        dispatch(setSuccess("batches", "BATCH_CREATE"));
      }
    }, 300);

  } catch (ex) {
    rh.error("batches", ex);
  }
  rh.close();
};

export default batchCreateCall;


import { processDocumentUrl, processImageUrl } from "../../../api/s3";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper, setRedirect } from "../../../app/coreSlice";
import { asyncForEach } from "../../../common/utils";

const productGetCall = async ({ setProductDetails }, dispatch, state, { productId }) => {
  let fileStorageConfig = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "PRODUCT_GET");
  try {
    const result = await call({
      type: "PRODUCT",
      subType: "GET",
      request: {
        productId
      },
    });

    let product = {
      ...result.product,
      logo: await getLogoObj(fileStorageConfig, result.product.media),
      media: await processMediaUrls(fileStorageConfig, result.product?.media),
      documents: await processDocumentsUrls(fileStorageConfig, result.product?.documents),
      hierarchy: await processHierarchyUrls(fileStorageConfig, result.product?.hierarchy),
    };
    dispatch(setProductDetails(product));
  } catch (ex) {
    dispatch(setRedirect("/not-available"));
    rh.error("products", ex);
  }
  rh.close();
};

export default productGetCall;

const processDocumentsUrls = async (fileStorageConfigs, documents) => {
  let nDocuments = [];
  await asyncForEach(documents, async (doc) => {
    nDocuments.push({
      ...doc,
      url: await processDocumentUrl(fileStorageConfigs, doc.url),
      urlOg: doc.url
    });
  });
  return nDocuments;
};

const processMediaUrls = async (fileStorageConfigs, media) => {
  let nMedia = [];
  await asyncForEach(media, async ({ reference, thumbnail, type }) => {
    nMedia.push({
      referenceOg: reference,
      thumbnailOg: thumbnail,
      reference: await processImageUrl(fileStorageConfigs, reference),
      thumbnail: await processImageUrl(fileStorageConfigs, thumbnail),
      type,
    });
  });
  return nMedia;
};

const processHierarchyUrls = async (fileStorageConfigs, hierarchy) => {
  let nHierarchy = [];
  await asyncForEach(hierarchy, async ({ thumbnail, ...node }) => {
    nHierarchy.push({
      ...node,
      thumbnailOg: thumbnail,
      thumbnail: await processImageUrl(fileStorageConfigs, thumbnail)
    });
  });
  return nHierarchy;
};

const getLogoObj = async (fileStorageConfigs, media) => {
  let med = media.find((item) => item.type === "PRODUCT_IMAGE");
  if (med) {
    let termination = med.reference.slice(med.reference.lastIndexOf(".") + 1);

    let url = await processImageUrl(fileStorageConfigs, med.reference);
    return {
      name: med.reference.slice(med.reference.lastIndexOf("/") + 1),
      type: "image/" + termination === "jpg" ? "jpeg" : termination,
      url,
    };
  }
  return;
};

import moment from "moment";

export const calculatePriceWithPromotions = (price, promotions, promoter) => {
  // if has price accumulation, should sum the promotions, if not use the biggest one
  const cpAccumulation = promoter?.getConfig("order", "cpAccumulation");
  let percentageFactor = 0;
  let activePromotions = promotions.filter(item => moment().isBetween(moment(item.startDate), moment(item.endDate), "day", "[]"));
  if (cpAccumulation === "AL") {
    percentageFactor = activePromotions.reduce((agg, item) => item.type === "PD" ? agg + parseInt(item.percentage) : agg, 0);
    if (percentageFactor > 100) {
      percentageFactor = 100;
    }
  }
  else {
    percentageFactor = activePromotions.reduce((agg, item) => item.type === "PD" ? (agg > parseInt(item.percentage) ? agg : parseInt(item.percentage)) : agg, 0);
  }
  return price * (1 - (percentageFactor / 100));
}

export const calculatePriceWithDproms = (price, dproms) => {
  let processedDproms = [];
  if (dproms && dproms.length > 0) {
    dproms.forEach(dprom => {
      let discount = getDpromDiscount(dprom.value, price);
      processedDproms.push({ ...dprom, discount })
    })
  }
  if (processedDproms && processedDproms.length > 0) {
    let maxDiscount = 0;
    processedDproms.forEach(({ discount }) => {
      maxDiscount = Math.max(maxDiscount, discount);
    });
    let total = price - maxDiscount;
    return total <= 0 ? 0 : total;
  }
  return price;
}

export const getDpromDiscount = (value, price) => {
  if (!value) {
    return 0
  }
  if (value.type === "P") {
    return price * value.amount / 100
  }
  else
    return value.amount;
}

export const getEntitiesInGroups = (entityGroups, groups) => {
  // console.log('getEntitiesInGroups: %o', { entityGroups, groups })
  // return all entities Ids, that are connected to groups (list of ids)
  let filteredGroups = (entityGroups || []).filter(item => (groups || []).some(group => group === item.groupId));
  let result = [];
  filteredGroups.forEach(item => {
    result.push(item.entityId);
    (item?.listEntities || []).forEach(entity => result.push(entity.entityId))
  });

  return [...new Set(result)];
}

export const processDate = (date) => {
  let nDate = !moment.isMoment(date) ? moment(date) : date ;
  return nDate.isValid() ? date.format('YYYY-MM-DD') : null;
}
import { createSlice } from "@reduxjs/toolkit";
import { guid, sleep } from "../common/utils";
import i18n from "i18next";
import { compareObjects } from "../common/utils/utils";
import { extractPromoterConfig } from "../common/utils/security";
import { getImageUrlBase64 } from "../api/s3";

export const coreSlice = createSlice({
  name: "core",
  initialState: {
    loader: [],
    snacks: [],
    redirect: null,
    loggedUser: null,
    promoterDetails: null,
    loadedI18n: process.env.REACT_APP_DEFAULT_PROMOTER,
    selectedPromoter: null,
    highlights: null,
    highlightsLoaded: false,
  },
  reducers: {
    addToLoader: (state, action) => {
      let nLoader = state.loader;
      nLoader.push(action.payload);
      state.loader = nLoader;
    },
    removeFromLoader: (state, action) => {
      let nLoader = state.loader;
      state.loader = nLoader.filter((item) => !compareObjects(item, action.payload));
    },
    addSnack: (state, action) => {
      state.snacks = [...state.snacks, action.payload];
    },
    removeSnack: (state, action) => {
      state.snacks = [...state.snacks.filter((item) => item.id !== action.payload)];
    },
    cleanSnack: (state) => {
      state.snacks = [];
    },
    setRedirect: (state, action) => {
      state.redirect = action.payload;
    },
    setLoggedUser: (state, action) => {
      state.loggedUser = action.payload;
    },
    setSelectedPromoter: (state, action) => {
      state.selectedPromoter = action.payload;
    },
    setPromoterDetails: (state, action) => {
      state.promoterDetails = action.payload;
    },
    setLoadedI18n: (state, action) => {
      state.loadedI18n = action.payload;
    },
    setPromoterDetailsHighlightsImages: (state, action) => {
      state.highlights = action.payload;
      state.highlightsLoaded = true;
    },
    refreshUserAvatarUrl: (state, action) => {
      state.loggedUser.avatarSignedUrl = action.payload;
    }
  },
});

export const {
  addToLoader,
  removeFromLoader,
  addSnack,
  removeSnack,
  cleanSnack,
  setRedirect,
  setLoggedUser,
  setPromoterDetails,
  setPromoterDetailsHighlightsImages,
  setLoadedI18n,
  setSelectedPromoter,
  refreshUserAvatarUrl
} = coreSlice.actions;

export const setError = (feature, error) => async (dispatch) => {
  if (error) {
    let i18nKey = `${feature}.flowsMessages.error.${error?.tag || "GENERIC"}`;
    // console.log('i18nKey: %o', i18nKey);

    let msg = i18n.t(i18nKey);
    // console.log('msg: %o', msg);

    if (msg === i18nKey) {
      // uncomment the following lines to protect server errors to be explicit on production environment (for now it can be usefull to be shown)
      // if (process.env.REACT_APP_ENV === "dev") {
      msg = `${error?.tag} - ${error?.description}`;
      // }
      // else {
      //   msg = i18n.t(`${feature}.flowsMessages.error.GENERIC`);
      // }
    }

    let snack = {
      id: guid(),
      type: "error",
      msg,
    };
    dispatch(addSnack(snack));
    await sleep(8000);
    dispatch(removeSnack(snack.id));
  }
};

export const setSuccess = (feature, code, options) => async (dispatch) => {
  if (code) {
    let i18nKey = `${feature}.flowsMessages.success.${code}`;
    let msg = i18n.t(i18nKey) === i18nKey ? code : i18n.t(i18nKey, options);

    let snack = {
      id: guid(),
      type: "success",
      msg,
    };

    dispatch(addSnack(snack));
    await sleep(8000);
    dispatch(removeSnack(snack.id));
  }
};

export const refreshUserAvatar = () => async (dispatch, getState) => {
  let avatarInfo = getState().core.loggedUser?.avatar;
  if (avatarInfo) {
    let avatarSignedUrl = await getImageUrlBase64(avatarInfo?.provider, avatarInfo?.bucket, avatarInfo?.path);
    dispatch(refreshUserAvatarUrl(avatarSignedUrl))
  }
}

export const requestHelper = (dispatch, loader) => {
  //dispatch(cleanSnack());
  let timestamp = new Date().getTime();
  if (loader) {
    dispatch(addToLoader({ call: loader, date: timestamp }));
  }
  return {
    error: (feature, error) => {
      if (process.env.REACT_APP_ENV !== "production") {
        console.log("%c::: ERROR - %s ::: %o", "color:#ff9a38; font-weight:bold", loader, error);
      }
      dispatch(setError(feature, error));
    }, //dispatch(setError(ex.tag)),
    close: () => {
      if (loader) {
        dispatch(removeFromLoader({ call: loader, date: timestamp }));
      }
    },
  };
};

export const getPromoterConfig = (state, feature, key) => {
  let promoter = state.core.promoterDetails;
  return extractPromoterConfig(promoter, feature, key);
}

export const selectLoader = (state) => state.core.loader.length > 0;
export const selectSnacks = (state) => state.core.snacks;
export const selectRedirect = (state) => state.core.redirect;
export const selectLoggedUser = (state) => state.core.loggedUser;
export const selectPromoterDetails = (state) => state.core.promoterDetails;
export const selectSelectedPromoter = (state) => state.core.selectedPromoter;
export const selectPromoterHighlights = (state) => state.core.highlights;
export const selectPromoterHighlightsLoaded = (state) => state.core.highlightsLoaded;

export default coreSlice.reducer;

import {getPromoterConfig, requestHelper} from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import moment from "moment";
import {asyncForEach} from "../../../common/utils";
import {processImageUrl} from "../../../api/s3";

const orderListIncidentsCall = async (
  { clearList, appendToList, setListFilter, setListTotalRows },
  dispatch,
  state,
  filter,
  clear
) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "ORDER_LIST_INCIDENTS");
  if (clear) {
    dispatch(clearList());
    filter = { ...filter, offset: 0 };
  }
  if (moment.isMoment(filter.startDate)) {
    filter.startDate = filter.startDate.toISOString();
  }
  if (moment.isMoment(filter.endDate)) {
    filter.endDate = filter.endDate.toISOString();
  }
  
  dispatch(setListFilter(filter));
  const {codes, startDate, endDate, search, searchField} = filter;
  try {
    let list = await call({
      type: "ORDER",
      subType: "LIST_INCIDENTS",
      request: {
        ...filter,
        codes: codes?.length === 0 ? undefined : codes,
        sortField: (filter.sortField || "").toUpperCase(),
        search: ["ORDERID", "PRODUCT"].includes(searchField) ? search : undefined,
        startDate: ["CREATEDTIME", "DELIVERYTIME"].includes(searchField) ? startDate : undefined,
        endDate: ["CREATEDTIME", "DELIVERYTIME"].includes(searchField) ? endDate : undefined,
      },
    });
    if (list && list.incidents && list.incidents.length > 0) {

      let incidents = [];
      for (const incident of list.incidents) {
        if(incident.products && incident.products.length > 0) {
          let products = [];
          await asyncForEach(incident.products, async (item) => {
            products.push({
              ...item,
              image: await processImageUrl(fileStorageConfigs, item.image),
              thumbnail: await processImageUrl(fileStorageConfigs, item.thumbnail),
            });
          });
          incidents.push({
            ...incident,
            products: products
          });
        } else {
          incidents.push(incident);
        }
      }

      dispatch(appendToList(incidents));
    }
    dispatch(setListTotalRows(list.rows));
  } catch (ex) {
    rh.error("ordersIncidents", ex);
  }
  rh.close();
};

export default orderListIncidentsCall;

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";

const promoterCreateVatCall = async ({ promoterListVat }, dispatch, state, { locations, code, value }) => {
  const filter = state.promoter.vatListFilter;
  let rh = requestHelper(dispatch, "PROMOTER_CREATE_VAT");
  try {
    await call({
      type: "PROMOTER",
      subType: "CREATE_VAT",
      request: { locations, code, value },
    });
    setTimeout(() => {
      dispatch(setRedirect("/promoter/configs/vats"));
      dispatch(setSuccess("promoter", "PROMOTER_CREATE_VAT"));
      dispatch(promoterListVat(filter));
    }, 300);
  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default promoterCreateVatCall;

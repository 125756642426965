import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import arrayMutators from "final-form-arrays";
import { useTranslation } from "react-i18next";
import { Paper, Grid, Button } from "@mui/material";
import { withStyles } from "tss-react/mui";

import { useFirstLoad, useNavigate, usePromoter } from "../../../common/hooks";
import { styles } from "./GroupCreate.styles";
import { useUser } from "../../../common/hooks";

import { formFields, formSchema } from "./GroupCreate.schema";
import { selectGroupDetails, createGroup, updateGroup, getGroupDetails } from "../entitiesSlice";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { Form, FormFields } from "../../../common/forms";
import Permission from "../../../app/components/Permission";
import Page from "../../../common/displays/Page/Page";

function GroupCreate({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useUser();
  const details = useSelector(selectGroupDetails);
  const promoter = usePromoter();

  useFirstLoad(() => {
    if (id && details?.groupId !== id) {
      dispatch(getGroupDetails(id));
    }
  });

  const onSubmit = async (values) => {
    if (!id) {
      dispatch(createGroup(values));
    } else {
      dispatch(updateGroup(id, values));
    }
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        schema={formSchema(t)}
        initialValues={id ? details : { entityId: user?.entity?.id }}
        mutators={{
          ...arrayMutators,
        }}
        render={() => {
          return (
            <Page
              permission={id ? "entities.actions.group.update" : "entities.actions.group.create"}
              header={<PageTitle
                title={
                  id
                    ? t("entities.GroupCreate.header.editTitle") + " " + details?.name
                    : t("entities.GroupCreate.header.title")
                }
                onBack={() => navigate("/entities/groups")}
                actions={
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <Permission code="entities.actions.group.create">
                        <Button variant="contained" color="primary" size="large" type="submit">
                          {t("entities.GroupCreate.header.btnSave")}
                        </Button>
                      </Permission>
                    </Grid>
                  </Grid>
                }
              />}>
              <Paper className={classes.paper} elevation={0}>
                <FormFields fields={formFields({ t, user, promoter })} />
              </Paper>
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(GroupCreate, styles);

import React from "react";
import * as Yup from "yup";
import { TextField } from "../../../common/forms";

export function formFields(t) {
  return [
    {
      size: 12,
      field: (
        <TextField
          id="email"
          type="email"
          label={t("authentication.RecoverPass.form.email")}
          required
        />
      ),
    },
  ];
}

export function formSchema(t) {
  return Yup.object().shape({
    email: Yup.string()
      .emailValidator(t("authentication.RecoverPass.form.invalidEmail"))
      .required(t("authentication.RecoverPass.form.requiredEmail")),
  });
}
export const styles = (theme) => ({
  root: {
  },
  avatar: {
    height: 24,
    width: 24,
    fontSize: 12,
    textTransform: "uppercase",
    backgroundColor: theme.palette.primary.back,
    color: theme.palette.primary.main,
  }
});
import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const businessReportWeeklyUnitsCall = async ({
  clearReportWeeklyUnitsList,
  setReportWeeklyUnitsList,
  setReportWeeklyUnitsListTotalRows,
  setReportWeeklyUnitsFilter
}, dispatch, { filter, clear }) => {
  let rh = requestHelper(dispatch, "BUSINESS_REPORT_WEEKLY_UNITS");
  if (clear) {
    dispatch(clearReportWeeklyUnitsList());
  }
  dispatch(setReportWeeklyUnitsFilter(filter));

  try {
    const { limit, offset, sortField = "", sortType, week, year, supplier, cutOff } = filter;
    let result = await call({
      type: "BUSINESS",
      subType: "REPORT_WEEKLY_UNITS",
      request: {
        limit,
        offset,
        sortField: sortField.toUpperCase(),
        sortType,
        week,
        year,
        supplier,
        cutOff
      },
    });

    if (result && result.transportUnitsPerDeliveryPlace && result.transportUnitsPerDeliveryPlace.length > 0) {
      dispatch(setReportWeeklyUnitsList(result.transportUnitsPerDeliveryPlace));
      dispatch(setReportWeeklyUnitsListTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default businessReportWeeklyUnitsCall;

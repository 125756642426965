import {currencyFormat} from "../../../../common/utils";

export function listColumns(t, classes) {
  return [
      {
          field: "description",
          headerName: t("business.accounting.Accounting.details_products_description"),
          align: "left",
          headerAlign: "left",
          disableSort: true
      },{
          field: "gtin",
          headerName: t("business.accounting.Accounting.details_products_gtin"),
          align: "left",
          headerAlign: "left",
          disableSort: true
      },{
          field: "quantity",
          headerName: t("business.accounting.Accounting.details_products_quantity"),
          align: "left",
          headerAlign: "left",
          disableSort: true
      },{
          field: "lineID",
          headerName: t("business.accounting.Accounting.details_products_lineID"),
          align: "left",
          headerAlign: "left",
          disableSort: true
      },{
          field: "price",
          headerName: t("business.accounting.Accounting.details_products_price"),
          align: "left",
          headerAlign: "left",
          disableSort: true,
          customValue: (v) => {
              return (currencyFormat(v.price));
          },
      },{
          field: "referenceLineID",
          headerName: t("business.accounting.Accounting.details_products_referenceLineID"),
          align: "left",
          headerAlign: "left",
          disableSort: true
      },{
          field: "referenceID",
          headerName: t("business.accounting.Accounting.details_products_referenceID"),
          align: "left",
          headerAlign: "left",
          disableSort: true
      },{
          field: "referenceType",
          headerName: t("business.accounting.Accounting.details_products_referenceType"),
          align: "left",
          headerAlign: "left",
          disableSort: true
      },{
          field: "tax",
          headerName: t("business.accounting.Accounting.details_products_tax"),
          align: "left",
          headerAlign: "left",
          disableSort: true
      },{
          field: "unitOfMeasureID",
          headerName: t("business.accounting.Accounting.details_products_unitOfMeasureID"),
          align: "left",
          headerAlign: "left",
          disableSort: true,
          customValue: (v) => {
              return t("common.Enums.transport."+v.unitOfMeasureID);
          },

      }
  ];
}

import React from "react";
import * as Yup from "yup";
import { MultiSelectField, SelectField, TextField } from "../../../common/forms";
import { getContactOptions, getGroupOptions, getSectorOptions } from "./RolesForm.options";

export function formSchema({ t, isDefault, isEdit }) {
  return isDefault && isEdit ?
    Yup.object().shape({
      designation: Yup.string()
    }) :
    Yup.object().shape({
      designation: Yup.string().required(t("roles.RolesForm.form.designationRequired")),
      user: Yup.array().when("associationType",
        {
          is: "USER",
          then: schema => schema.ensure().min(1, t("roles.RolesForm.form.userRequired")),
          otherwise: schema => schema,
        }),
      group: Yup.array().when("associationType",
        {
          is: "GROUP",
          then: schema => schema.ensure().min(1, t("roles.RolesForm.form.groupRequired")),
          otherwise: schema => schema,
        }),
      sector: Yup.array().when("associationType",
        {
          is: "SECTOR",
          then: schema => schema.ensure().min(1, t("roles.RolesForm.form.sectorRequired")),
          otherwise: schema => schema,
        }),
    });
}

export function formFields({ t, contacts, sectorsGroups, promoter, values, isEdit }) {
  const options = ["SECTOR", "GROUP", "USER"].map(item => ({
    value: item,
    text: t("roles.enum.associationType." + item),
  }))
  return [
    {
      size: 6,
      field: <TextField id="designation" type="text" label={t("roles.RolesForm.form.designation")} required />,
    },
    {
      size: 12,
    },
    {
      size: 6,
      field: <SelectField id="associationType" type="text" options={options} label={t("roles.RolesForm.form.associationType")} disabled={isEdit} />,
    },
    {
      size: 6,
      field: (
        <MultiSelectField
          id="user"
          options={getContactOptions(contacts)}
          label={t("roles.RolesForm.form.users")}
          required
        />
      ),
      hidden: values?.associationType !== "USER"
    },
    {
      size: 6,
      field: (
        <MultiSelectField
          id="group"
          options={getGroupOptions(sectorsGroups)}
          label={t("roles.RolesForm.form.groups")}
          required
        />
      ),
      hidden: values?.associationType !== "GROUP"
    },
    {
      size: 6,
      field: (
        <MultiSelectField
          id="sector"
          options={getSectorOptions(t, promoter?.refData)}
          label={t("roles.RolesForm.form.sector")}
          required
        />
      ),
      hidden: values?.associationType !== "SECTOR"
    },
  ];
}

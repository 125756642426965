import React from "react";
import { withStyles } from "tss-react/mui";
import { useNavigate, usePromoter, useUser } from "../../../../common/hooks";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";

import { styles } from "./ProductDrawerDetails.styles";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";
import Currency from "../../../../common/displays/Currency/Currency";
import Permission from "../../../../app/components/Permission";

function ProductDrawerDetails({ classes, product, loading }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useUser();
  const promoter = usePromoter();

  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];
  const showPromoterDescription = Boolean(enabledProductFields.includes("promoterDescription") && user?.isPromoter && product?.baseInfo?.promoterDescription);

  return (
    <div className={classes.root}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="h3" align="left" component="h2" className={classes.noMargin}>
              {showPromoterDescription ? product?.baseInfo?.promoterDescription : product?.baseInfo?.description}
            </Typography>
            <Typography
                variant="body1"
                align="left"
                component="span"
                color="textSecondary"
                className={classes.noMargin}
            >
              {product?.baseInfo?.ownerName}
            </Typography>
            <Divider className={classes.divider}/>

            <LabelValue
                label={t("products.ProductList.details.gtin")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {product?.baseInfo?.gtin}
                  </Typography>
                }
            />

            {enabledProductFields.includes("promoterInternalCode") && user?.isPromoter && <LabelValue
                label={t("products.ProductDetails.details.promoterInternalCode")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {product?.baseInfo?.promoterInternalCode || "-"}
                  </Typography>
                }
            />}

            {enabledProductFields.includes("supplierInternalCode") && <LabelValue
                label={t("products.ProductDetails.details.supplierInternalCode")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {product?.baseInfo?.supplierInternalCode}
                  </Typography>
                }
            />}
            <div>
              <br/>
            </div>
            {product?.baseInfo?.gln && !product.baseInfo.gln.includes(product?.baseInfo?.ownerTaxNumber) &&
                <LabelValue
                    label={t("products.ProductList.details.gln")}
                    value={
                      <Typography variant="body1" align="left" component="span">
                        {product?.baseInfo?.gln}
                      </Typography>
                    }
                />}

            {product?.baseInfo?.ownerPromoterInternalCode && user?.isPromoter && <LabelValue
                label={t("products.ProductDetails.details.ownerPromoterInternalCode")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {product?.baseInfo?.ownerPromoterInternalCode || "-"}
                  </Typography>
                }
            />}

            <div>
              <br/>
            </div>
            <LabelValue
                label={t("products.ProductList.details.brand")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {product?.baseInfo?.brand}
                  </Typography>
                }
            />


            {promoter?.getConfig("price", "allowStandard") && <LabelValue
                label={t("products.ProductList.details.price")}
                value={
                  <Grid container direction="column">
                    {promoter?.getConfig("price", "allowRetailPrice") && <Grid item>
                      <Grid container direction="row" alignItems="center" spacing={1}>
                        <Grid item>
                          <Typography variant="body1" align="left" component="span">
                            {t("products.ProductDetails.priceDetails.retailPrice") + ":"}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Currency value={product.price?.retailPrice}/>
                        </Grid>
                      </Grid>
                    </Grid>}
                    {promoter?.getConfig("price", "allowStandard") && <Grid item>
                      <Grid container direction="row" alignItems="center" spacing={1}>
                        <Grid item>
                          <Typography variant="body1" align="left" component="span">
                            {t("products.ProductDetails.priceDetails.salePrice") + ":"}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Currency value={product.price?.salePrice}/>
                        </Grid>
                      </Grid>
                    </Grid>}
                  </Grid>
                }
            />}
          </Grid>
          <Permission code="products.actions.product.get">
            <Grid item>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => navigate(`/products/${product.id}`)}
              >
                {t("products.ProductList.details.btnDetails")}
              </Button>
            </Grid>
          </Permission>
        </Grid>
      )}
    </div>
  );
}

export default withStyles(ProductDrawerDetails, styles);

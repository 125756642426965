import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

import { styles } from "./EntitySectorGroupsList.styles";
import { useFirstLoad, useNavigate, useUser } from "../../../common/hooks";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { entityGet, selectEntityDetails } from "../entitiesSlice";
import EntitySectorGroupDataGrid from "./EntitySectorGroupDataGrid/EntitySectorGroupDataGrid";
import Page from "../../../common/displays/Page/Page";
import Permission from "../../../app/components/Permission";

function EntitySectorGroupsList({ classes }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const details = useSelector(selectEntityDetails);
  const user = useUser();

  useFirstLoad(() => {
    if (details?.entityId?.toString() !== id) {
      dispatch(entityGet(id));
    }
  });

  return details ? (
    <Page
      permission="entities.actions.sectorGroup.list"
      header={<PageTitle
        title={t("entities.EntitySectorGroupsList.header.title")}
        onBack={() => navigate(`/entities/${id}`)}
        actions={
          <Permission code="entities.actions.sectorGroup.create">
            {details?.status !== "PND" && (user?.entity?.type === "P" || user?.entity?.id === id) &&
              <Button variant="contained" color="primary" onClick={() => navigate(`/entities/${id}/sectorgroups/add`)}>
                {t("entities.EntitySectorGroupsList.header.btnAdd")}
              </Button>
            }
          </Permission>
        }
      />}>
      <EntitySectorGroupDataGrid data={details?.sectorsGroups} contacts={details?.contacts} onEdit={(row) => {
        navigate(`/entities/${id}/sectorgroups/edit/${row.id}`)
      }} />
    </Page>
  ) : null;
}

export default withStyles(EntitySectorGroupsList, styles);

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button, Paper, ListItemText, ListItem, List } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { styles } from "./EntitySectorGroups.styles";
import Permission from "../../../../app/components/Permission";
import IconWithTooltip from "../../../../common/components/displays/IconWithTooltip";
import { useNavigate } from "../../../../common/hooks";
import ContactCardSlim from "../../_common/ContactCardSlim/ContactCardSlim";

function EntitySectorGroups({ classes, groups, contacts, entityId, disabled }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onMoreHandler = () => {
    navigate(`/entities/${entityId}/sectorgroups`);
  };

  return (
    <Paper className={classes.root} elevation={0} id="entitySectorGroups">
      <div className={classes.container}>
        <Grid container direction="row" justifyContent="space-between">
          <Typography variant="h6" align="left" component="span" color="textSecondary" className={classes.title}>
            {t("entities.Entities.groups.title")} ({groups?.length || 0})
          </Typography>
          {!disabled &&
            <Permission code="entities.actions.sectorGroup.create">
              <Button color="primary" variant="text" onClick={onMoreHandler} className={classes.btn}
                style={{ marginRight: 10 }}>
                {t("entities.Entities.groups.btnMore")}
              </Button>
            </Permission>
          }
        </Grid>
        <List>
          {(groups || []).map(({ groupName, sector, users, header }, index) => (
            <ListItem key={"group_" + index} disableGutters>
              <ListItemText
                disableTypography
                primary={
                  <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography variant="h4" align="left" component="h4" className={classes.name}>
                        {groupName}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" align="left" component="span" className={classes.details}>
                        {t("common.Enums.sector." + sector)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconWithTooltip
                        icon={
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className={classes.details}
                          >
                            <Grid item>
                              <AccountCircleIcon fontSize="small" style={{ display: "block", marginRight: 4 }} />
                            </Grid>
                            <Grid item>{users?.length + 1}</Grid>
                          </Grid>
                        }
                      >
                        <List>
                          {[header, ...users].map((user) => {
                            let contact = contacts.find((item) => item.id === user);
                            return (
                              <ListItem key={`group_${index}_user_${user}`}>
                                <ContactCardSlim contact={contact} star={Boolean(user === header)} />
                              </ListItem>
                            );
                          })}
                        </List>
                      </IconWithTooltip>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          ))}
        </List>
      </div>
    </Paper>
  );
}

export default withStyles(EntitySectorGroups, styles);

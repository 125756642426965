import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const orderAcceptCall = async ({ orderAggregateDetails, orderListOrdersAggregate }, dispatch, state, { aggregateId, orderSellerId, products, finished, allProducts = false }) => {

  let rh = requestHelper(dispatch, "ORDER_ACCEPT");
  try {
    await call({
      type: "ORDER",
      subType: "ACCEPT",
      request: {
        orders: [
          {
            orderSellerId,
            products,
            finished,
            allProducts
          },
        ],
      },
    });
    setTimeout(() => {
      if (allProducts) {
        let filter = state.orders.addressAggregatedOrdersFilter;
        dispatch(orderListOrdersAggregate(aggregateId, filter, true));
      }
      else {
        dispatch(orderAggregateDetails(orderSellerId));
        dispatch(setRedirect(`/orders/received/${aggregateId}`));
      }
      dispatch(setSuccess("orders", "ORDER_ACCEPT"));
    }, 300);
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
};

export default orderAcceptCall;

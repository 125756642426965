
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./PlanningOverviewDataGrid.styles";
import { listColumns } from "./PlanningOverviewDataGrid.columns";

import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import { useNavigate } from "../../../../common/hooks";

function PlanningOverviewDataGrid({
  classes,
  data,
  years
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onPlanningClick = (year, productId) => {
    navigate(`/planning/${productId}/${year}`);
  }

  const columns = listColumns(t, years, onPlanningClick);

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={columns}
        rowKeyField="productId"
        rows={data}
      />
    </div>
  );
}

export default withStyles(PlanningOverviewDataGrid, styles);

import { useTheme } from "@mui/material";
import React from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./HierarchyNode.styles";
import box from "./img/box.jpeg";
import empty from "./img/empty.png";
import pallet from "./img/pallet.jpg";

function HierarchyNode({ classes, node, onNodeClick, onAddNodeClick, productThumbnail, selected, hasErrors }) {
  const theme = useTheme();
  let defaultImg;
  switch (node?.unitDescriptor) {
    case "CASE":
    case "PACK_OR_INNER_PACK":
      defaultImg = box;
      break;
    case "BASE_UNIT_OR_EACH":
      defaultImg = productThumbnail || empty;
      break;
    default:
      defaultImg = pallet;
      break;
  }

  return node.add ? (
    <g onClick={() => onAddNodeClick(node)}>
      <rect y="0" x="-15" rx="5" width="30px" height="30px" fill="#FFFFFF" stroke="#DDDDDD" strokeWidth="1" />
      <text fill="#AAAAAA" strokeWidth="0" x="-5" y="20" style={{ fontSize: 16 }}>
        +
      </text>
    </g>
  ) : (
    <g onClick={() => onNodeClick(node)}>
      <rect
        y="0"
        x="-100"
        rx="5"
        width="200px"
        height="80px"
        fill="#FFFFFF"
        stroke={selected ? "#2E5BFF" : "#DDDDDD"}
        strokeWidth="1"
      />
      {hasErrors && (
        <rect y="2" x="92" rx="5" width="5px" height="5px" fill={theme.palette.error.main} strokeWidth="0" />
      )}
      <image href={node?.thumbnail || defaultImg} x="-85" y="15" height="50px" width="50px" />
      <text fill="black" strokeWidth="0" x="-5" y="15" style={{ fontSize: 10 }}>
        {node?.child?.childQuantity && "[" + node?.child?.childQuantity + "]"}
      </text>
      <text fill="black" strokeWidth="0" x="-30" y="35">
        {node.gtin}
      </text>
      <foreignObject x="-30" y="40" width="125" height="40">
        <span className={classes.description} xmlns="http://www.w3.org/1999/xhtml">
          {node.description}
        </span>
      </foreignObject>
    </g>
  );
}

export default withStyles(HierarchyNode, styles);

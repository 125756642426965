import React from "react";
import { Route, Routes } from "react-router-dom";
import ResourceList from "./ResourceList/ResourceList";
import ResourceForm from "./ResourceForm/ResourceForm";
import Security from "../../app/components/Security/Security";

function ResourcesRouting() {
  return (
    <Routes>
      <Route path="/" element={<ResourceList />} />
      <Route path="/create" element={<ResourceForm />} />
      <Route path="/edit/:id" element={
        <Security component={<ResourceForm />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/copy/:id" element={
        <Security component={<ResourceForm copy />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
    </Routes>
  );
}

export default ResourcesRouting;

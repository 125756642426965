import { useSelector } from "react-redux";
import { selectPromoterDetails } from "../../app/coreSlice";
import { extractPromoterConfig } from "../utils/security";

export function usePromoter() {
  let promoter = { ...useSelector(selectPromoterDetails) }
  // return promoter;

  if (Object.keys(promoter).length > 0) {
    return {
      ...promoter,
      getConfig: (feature, key) => {
        return extractPromoterConfig(promoter, feature, key);
      },
    };
  }
  return undefined;
}

import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid, Checkbox, Tooltip, Stack } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { styles } from "./_base.styles";

function CheckBoxInput({ classes, id, className, label, disabled, value, onChange, note }) {
  return (
    <Grid
      className={className}
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      style={{ cursor: disabled ? "default" : "pointer" }}
      onClick={(e) => {
        !disabled && onChange(!value);
      }}
    >
      <Grid item>
        <Checkbox id={id} disabled={Boolean(disabled)} color="primary" checked={Boolean(value)} />
      </Grid>
      <Grid item>
        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" >
          <Typography
            component="span"
            variant="caption"
            htmlFor={id}
            color="textPrimary"
            className={disabled ? classes.disabledLabel : ""}
          >
            {label}
          </Typography>
          {note && <Tooltip title={note} placement="left"><InfoOutlinedIcon color="primary" className={classes.noteIconCheck} /></Tooltip>}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default withStyles(CheckBoxInput, styles);

import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import ActionsPopover from "../../../../common/components/ActionsPopover/ActionsPopover";
import { useUser } from "../../../../common/hooks";

export default function RolesDataGridActions({ row, onRemove, onEdit }) {
  const { t } = useTranslation();
  const user = useUser();

  const onEditHandler = (e) => {
    e.preventDefault();
    onEdit && onEdit(row);
  };
  const onRemoveHandler = (e) => {
    e.preventDefault();
    onRemove && onRemove(row);
  };
  let actionsButtons = [];

  // EDIT
  if (user.hasPermission("promoter.actions.role.update")) {
    actionsButtons.push(<Button onClick={onEditHandler} fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item style={{ display: "flex" }}>
          <EditIcon />
        </Grid>
        <Grid item>{t("roles.RolesList.actions.edit")}</Grid>
      </Grid>
    </Button>);
  }

  // DELETE
  if (user.hasPermission("promoter.actions.role.delete")) {
    actionsButtons.push(<Button onClick={onRemoveHandler} color="error" fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item style={{ display: "flex" }}>
          <DeleteIcon />
        </Grid>
        <Grid item>{t("roles.RolesList.actions.delete")}</Grid>
      </Grid>
    </Button>);
  }


  return (
    <Grid container spacing={1} justifyContent="flex-end" alignItems="center" wrap="nowrap">
      {actionsButtons.length > 0 &&
        <Grid item>
          <ActionsPopover
            actions={actionsButtons}
          />
        </Grid>
      }
    </Grid>
  );
}

import React from "react";
import { withStyles } from "tss-react/mui";
import { Avatar, Grid, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

import { styles } from "./ContactCardSlim.styles";
import { useTranslation } from "react-i18next";

function ContactCardSlim({ classes, contact, star = false, sector = false }) {
  const { t } = useTranslation();
  if (!contact) {
    return null;
  }

  let displayName =
    contact?.firstName || contact?.lastName
      ? (contact?.firstName || "") + " " + (contact?.lastName || "")
      : contact?.email;

  return (
    <Grid container className={classes.root} justifyContent="flexStart" alignItems="center" spacing={1} wrap="nowrap">
      <Grid item sx={{ paddingLeft: "0px !important" }}>
        <Avatar alt={displayName} src={contact.logo} className={classes.avatar} sx={{ scale: "0.9" }} />
      </Grid>
      {star && (
        <Grid item>
          <StarIcon fontSize="small" sx={{ display: "block" }} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h5" align="left" component="h5" className={classes.name}>
          {displayName}
        </Typography>
        {sector && contact?.sector && (<Typography variant="h6" align="left" component="h5" className={classes.name}>
          {t("common.Enums.sector." + contact?.sector)}
        </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default withStyles(ContactCardSlim, styles);

import React from "react";
import { withStyles } from "tss-react/mui";
import { Box, Tooltip, IconButton } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

import { styles, tooltipStyles } from "./FormErrors.styles";

const CustomTooltip = withStyles(Tooltip, tooltipStyles);

function FormErrors({ classes, errors }) {
  const writeElement = (k, error) => {
    return (
      <div
        className={classes.error}
        key={k}
        data-key={k}
        onClick={() => {
          document.getElementById(k)?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }}
      >
        {error}
      </div>
    );
  };

  const extractErrors = (obj, lvl, key = "") => {
    if (typeof obj === "string") {
      return writeElement(key.slice(1), obj);
    } else if (Array.isArray(obj)) {
      let agg = [];
      let agg2 = [];
      obj.forEach((el, i) => {
        if (typeof el === "string") {
          agg.push(el);
        } else if (typeof el === "object") {
          agg2.push(
            <div key={"box_" + key + i}>
              {Object.keys(el).map((item) => extractErrors(el[item], lvl + 1, `${key}[${i}].${item}`))}
            </div>
          );
        }
      });
      if (agg?.length > 0) {
        return writeElement(key.slice(1), agg.join(" "));
      }
      if (agg2?.length > 0) {
        return agg2.map((item) => item);
      }
    } else if (typeof obj === "object") {
      return (
        <div key={"box2_" + key}>
          {Object.keys(obj).map((item) => extractErrors(obj[item], lvl + 1, `${key}.${item}`))}
        </div>
      );
    }
  };

  // return <Box className={classes.content}>{extractErrors(errors, -1)}</Box>;

  return (
    <CustomTooltip title={<Box className={classes.content}>{extractErrors(errors, -1)}</Box>}>
      <IconButton aria-label="erros" size="small">
        <WarningAmberRoundedIcon fontSize="inherit" color="error" />
      </IconButton>
    </CustomTooltip>
  );
}

export default withStyles(FormErrors, styles);

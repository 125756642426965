import React from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Stack, Divider } from "@mui/material";

import { styles } from "./BatchOrderDrawerDetails.styles";
import IconTextItem from "../../../../common/displays/IconTextItem/IconTextItem";
import OrderIcon from "../../_common/OrderIcon/OrderIcon";
import { selectBatchOrderDetails } from "../../batchesSlice";
import { useSelector } from "react-redux";
import { dateFormat } from "../../../../common/utils";
import BatchOrderProduct from "./BatchOrderProduct/BatchOrderProduct";

function BatchOrderDrawerDetails({ classes }) {
  // const { t } = useTranslation();
  const batchOrderDetails = useSelector(selectBatchOrderDetails);
  const { type, orderNumber, date, products } = (batchOrderDetails || {});

  console.log('batchOrderDetails: %o', batchOrderDetails);

  return (
    <div className={classes.root}>
      <Stack spacing={1}>
        <IconTextItem icon={<div style={{ scale: "1.5" }}><OrderIcon type={type} /></div>} title={orderNumber} subtitle={dateFormat(date)} />
        <Divider />
        {(products || []).map((element, index) => <div key={orderNumber + "_" + index}><BatchOrderProduct {...element} /></div>)}
      </Stack>
    </div>
  );
}

export default withStyles(BatchOrderDrawerDetails, styles);
import { PicturePlaceholder } from "../../../../../common/icons";
import { Grid, Typography } from "@mui/material";

export function listColumns(t, classes) {
  return [
    {
      field: "description",
      headerName: t("products.ProductList.columns.description"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <Grid
              item
              style={{
                width: 60,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {(v.thumbnail || v.image) ? (
                <img style={{ maxWidth: 44, maxHeight: 40 }} alt={v.column1} src={v.thumbnail || v.image} />
              ) : (
                <PicturePlaceholder />
              )}
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.nomargin}>{v.column1}</Typography>
                </Grid>
                <Grid item>
                  <Typography color="textSecondary" className={classes.nomargin}>
                    {v.column2}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      },
    },
    /* {
      field: "image",
      headerName: t("business.reports.TransportCostItemsList.columns_sellerName"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return ( v.image ? (
              <img
                  style={{ maxWidth: 44, maxHeight: 40 }}
                  alt={v.column1}
                  src={v.image}
              />
          ) : (
              <PicturePlaceholder />
          ));
      },

    },{
      field: "column1",
      headerName: t("home.screen.topProducts.column1"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "column2",
      headerName: t("home.screen.topProducts.column2"),
      align: "left",
      headerAlign: "left",
    },*/
    {
      field: "quantity",
      headerName: t("home.screen.topProducts.column_qty"),
      align: "left",
      headerAlign: "left",
    },
  ];
}

export const csvFields = (t) => {
  return [
    {
      field: "type",
      header: t("business.audit.import.columns_type"),
    },
    {
      field: "subType",
      header: t("business.audit.import.columns_subtype"),
    },
    {
      field: "user",
      header: t("business.audit.import.columns_user"),
    },
    {
      field: "time",
      header: t("business.audit.import.columns_time"),
    },
    {
      field: "resultTime",
      header: t("business.audit.import.columns_resultTime"),
    },
    {
      field: "status",
      header: t("business.audit.import.columns_status"),
    },
    {
      field: "totalLines",
      header: t("business.audit.import.columns_totalLines"),
    },
    {
      field: "linesWithError",
      header: t("business.audit.import.columns_linesWithError"),
    },
  ];
};

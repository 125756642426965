import moment from "moment";

export const getValue = (tempValue, ogValue) => {
  // console.log('getValue: %o', { tempValue, ogValue })
  if (tempValue !== undefined) {
    return tempValue.toString();
  }
  if (ogValue !== undefined) {
    return ogValue.toString();
  }
  return "0";
}


export const distributeQuantity = (dataArray) => {
  const result = {};

  dataArray.forEach(({ startDate, endDate, target }) => {
    const quantity = target?.value;
    const start = moment(startDate);
    const end = moment(endDate);
    const days = end.diff(start, 'days') + 1;
    const dailyQuantity = quantity / days;

    for (let i = 0; i < days; i++) {
      result[start.clone().add(i, 'days').format('YYYY-MM-DD')] = (result[start.clone().add(i, 'days').format('YYYY-MM-DD')] || 0) + dailyQuantity;
    }
  });

  return result;
}

export const aggregateToFridays = (dailyQuantities) => {
  const weeklySums = {};
  Object.keys(dailyQuantities).forEach(date => {
    const current = moment(date);
    // Find the next Friday from this date
    const nextFriday = current.clone().add((5 - current.day() + 7) % 7, 'days');
    const fridayKey = nextFriday.format('YYYY-MM-DD');

    if (!weeklySums[fridayKey]) {
      weeklySums[fridayKey] = 0;
    }
    weeklySums[fridayKey] += dailyQuantities[date];
  });

  return weeklySums;
}


// export const getFinalBusinessUnits = (businessUnits, productId) => {

// }

export const getFinalBusinessUnits = (units, productId) => {
  const inputUnitIds = new Set();

  // Collect all business unit IDs from the inputs of all units
  units.forEach(unit => {
    unit.lines.forEach(line => {
      line.inputs.forEach(input => {
        if (input.businessUnits) {
          input.businessUnits.forEach(businessUnit => {
            inputUnitIds.add(businessUnit.id);
          });
        }
      });
    });
  });

  // console.log('inputUnitIds: %o', inputUnitIds);
  // Filter out units whose IDs are present in inputUnitIds
  return units.filter(unit => !inputUnitIds.has(unit.id) && (unit?.lines || []).some(line => (line.outputs || []).some(item => item.productId === productId)));
};

export const getInputBusinessUnits = (units, outputUnit) => {
  // console.log('getInputBusinessUnits: %o', { units, outputUnit })
  let result = [];
  (outputUnit?.lines || []).forEach((line) => {
    (line?.inputs || []).forEach((input) => {
      (input?.businessUnits || []).forEach((bu) => {
        result.push((units || []).find(item => item.id === bu.id))
      });
    });
  });
  return result;
}

export const getUnitsSorted = (units, targetProductId) => {
  const targetUnits = getFinalBusinessUnits(units, targetProductId);
  let resultUnits = [...targetUnits];
  let unitsToProcess = [...targetUnits];

  while (unitsToProcess?.length > 0) {
    // console.log('resultUnits: %s', resultUnits.map(item => item.name).join(','));
    // console.log('unitsToProcess: %s', unitsToProcess.map(item => item.name).join(','));
    const targetUnit = unitsToProcess.shift();
    const childUnits = getInputBusinessUnits(units, targetUnit);
    if (!resultUnits.some(item => childUnits.some(childUnit => childUnit.id === item.id))) {
      resultUnits = resultUnits.concat(childUnits);
      unitsToProcess = unitsToProcess.concat(childUnits);
    }
  }

  return resultUnits;
}


export const generateForecast = ({ businessUnits, productId, year, planning, defaultPlanning }) => {
  let weeklyTarget = aggregateToFridays(distributeQuantity(planning));

  let weeklyPlanning = [];

  Object.keys(weeklyTarget).forEach(date => {
    const targetUnits = getFinalBusinessUnits(businessUnits, productId);


    weeklyPlanning.push({
      year,
      productId,
      untilDate: date,
      target: { ...defaultPlanning?.target, value: weeklyTarget[date] },
      forecast: forecastBuilder(targetUnits, businessUnits, weeklyTarget[date], date, productId)
    })
  })

  // console.log('weeklyPlanning: %o', weeklyPlanning);
  return weeklyPlanning;
}

export const forecastBuilder = (units, baseUnits, target, untilDate, productId) => {
  // console.log('forecastBuilder: %o', { units, baseUnits, target, untilDate });
  // need to calculate the target and untilDate based on unit configurations
  let forecasts = [];
  units.forEach(unit => {
    // console.log('unit: %o', unit);
    const line = getBusinessLineByOutputProduct(unit, productId);
    const mainInput = line?.inputs?.[0];
    const mainOutput = line?.outputs?.[0];

    let targetCompensation = target / (parseFloat(line?.successRate) / 100);
    let nTarget = (targetCompensation * parseFloat(mainInput?.quantity?.value || 1) / parseFloat(mainOutput?.quantity?.value || 1));
    // console.log('%s: %o', unit?.name, { line, mainInput, mainOutput, target, targetCompensation, nTarget });
    let nUntilDate = moment(untilDate).subtract(parseInt(line?.productionTime), "d").format("YYYY-MM-DD");

    forecasts.push({
      buId: unit.id,
      buName: unit.name,
      productId: mainInput.productId,
      target: { ...mainOutput?.quantity, value: nTarget },
      untilDate: nUntilDate,
      forecast: forecastBuilder(getInputBusinessUnits(baseUnits, unit), baseUnits, nTarget, nUntilDate, mainInput.productId)
    })
  });
  return forecasts;
}

export const forecastSpread = (forecasts) => {
  let result = [];

  (forecasts || []).forEach(item => {
    result.push({ ...item })
    result = result.concat(forecastSpread(item?.forecast))
  });
  return result;
}

export const processForecast = (weeklyForecast) => {
  let result = [];
  weeklyForecast.forEach(target => {
    result.push({ target, bus: forecastSpread(target?.forecast) })
  })
  return result;
}

export const getBusinessLineByOutputProduct = (unit, productId) => {
  let result = undefined;
  // console.log('getBusinessLineByOutputProduct: %o', { unit, productId });
  (unit?.lines || []).forEach(line => {
    // console.log('---> line.outputs: %o', line.outputs);
    if ((line.outputs || []).some(output => output.productId === productId)) {
      result = line;
    }
  });
  return result;
}
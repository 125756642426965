import {Grid, Typography} from "@mui/material";
import {currencyFormat} from "../../../../common/utils";

export function listColumns(t, classes) {
  return [
    {
      field: "orderSellerId",
      headerName: t("orders.OrdersMissingProducts.columns.orderSellerId"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <Grid container direction="column">
            <Typography className={classes.id} variant="h4">
              {v.orderSellerId}
            </Typography>
            <Typography className={classes.orderBy}>{v.description}</Typography>
          </Grid>
        );
      },
    },
    {
      field: "seller",
      headerName: t("orders.OrdersMissingProducts.columns.seller"),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "buyer",
      headerName: t("orders.OrdersMissingProducts.columns.buyer"),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "address",
      headerName: t("orders.OrdersMissingProducts.columns.address"),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "numberOfProducts",
      headerName: t("orders.OrdersMissingProducts.columns.numberOfProducts"),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "price",
      headerName: t("orders.OrdersMissingProducts.columns.orderPrice"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return currencyFormat(v.price);
      },
    },
  ];
}

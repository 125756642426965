
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";

const orderDeliveryCall = async (
  dispatch,
  { orderId, orderSellerId, dispatchId, document, documentDate, receivedBy, finished, products, volumes, totalPallets, allProducts, operationType }
) => {
  let rh = requestHelper(dispatch, "DELIVERY_ORDER");
  try {
    await call({
      type: "ORDER",
      subType: "DELIVERY",
      request: {
        orders: [
          {
            orderId,
            orderSellerId,
            dispatchId,
            finished,
            receivedBy,
            document,
            documentDate: documentDate.format("YYYY-MM-DDTHH:mm:ss"),
            totalPallets,
            allProducts,
            products: products
              .filter((product) => product.quantity > 0)
              .map((product) => {
                return {
                  productId: product.productId,
                  orderId: product.orderId,
                  quantity: product.quantity,
                  productBatch: (product.productBatch || []).map(({ batch, quantity, weight, expirationDate }) => {
                    return {
                      quantity: quantity,
                      batch: batch ? batch : undefined,
                      weight: weight ? weight : undefined,
                      expirationDate: expirationDate ? expirationDate.format("YYYY-MM-DD") : undefined,
                    };
                  }),
                };
              }),
            volumes: operationType === "CD" ? volumes.filter((volume) => volume.checked) : volumes,
          },
        ],
      },
    });
    setTimeout(() => {
      dispatch(setRedirect(`/orders/operator`));
      dispatch(setSuccess("orders", "ORDER_DELIVERY"));
    }, 500);
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
};

export default orderDeliveryCall;

import React from "react";
import * as Yup from "yup";
import { TextField } from "../../../common/forms";

export function formFields(t) {
  return [
    {
      size: 12,
      field: (
        <TextField
          id="password"
          type="password"
          label={t("authentication.PasswordUpdate.form.password")}
          required
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextField
          id="password2"
          type="password"
          label={t("authentication.PasswordUpdate.form.password2")}
          required
        />
      ),
    },
  ];
}

export function formSchema(t, values) {
  return Yup.object().shape({
    password: Yup.string().required(t("authentication.PasswordUpdate.form.requiredPassword"))
      .matches(process.env.REACT_APP_PASSWORD_PATTERN, t('users.PasswordChangeForm.form.passwordPattern')),
    password2: Yup.string().required(t("authentication.PasswordUpdate.form.requiredPassword"))
      .oneOf([Yup.ref("password")],
        t("authentication.PasswordUpdate.form.matchPassword")
      ),
  });
}
import * as Yup from "yup";
import { MultiSelectField } from "../../../../common/forms";

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    status: Yup.array(),
  });
};

export const filterFields = (promoter) => (t) => {
  return [
    {
      size: 12,
      field: (
        <MultiSelectField id="status" label={t("productsPurchases.PendingPurchasesList.filter.status")} options={getStatusOptions(t)} />
      ),
      hidden: (promoter?.getConfig("tax", "autoAcceptCreateTax"))
    },
  ];
};


const getStatusOptions = (t) => {
  return ["PENDING", "REFUSED", "ACCEPTED", "PROPOSAL", "EXECUTED"].map((value) => {
    return {
      value,
      text: value,
    };
  });
};



import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const resourceList_fullCall = async (
  { setFullResourcesList },
  dispatch
) => {
  let rh = requestHelper(dispatch, "RESOURCE_LIST");
  try {
    let result = await call({
      type: "RESOURCE",
      subType: "LIST",
      request: {
        limit: 10000,
        offset: 0,
        sortField: "TITLE",
        sortType: "ASC",
      },
    });
    dispatch(setFullResourcesList(result.items));
    
  } catch (ex) {
    rh.error("resources", ex);
  }
  rh.close();
};

export default resourceList_fullCall;
import React from "react";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";

import {styles} from "./InputField.styles";

import {FormArrayFields, MultiSelectField} from "../../../../../common/forms";
import {MeasureField, ProductPickerField} from "../../../../../common/components/formFields";
import {useUser} from "../../../../../common/hooks";

const formFields = ({ t, user, businessUnits }) => (item) => {
  return [
    {
      size: 9,
      field: <ProductPickerField
        id={`${item}.product`}
        ownerId={user.entity?.id}
        customFilters={{ justConsumer: false }}
        single
        simple
        label={t("businessUnits.BusinessUnitProductionForm.form.product")}
      />,
    },
    {
      size: 3,
      field: <MeasureField
          id={`${item}.quantity`}
          label={t("businessUnits.BusinessUnitProductionForm.form.quantity")}
      />,
    },
    {
      size: 6,
      field: <MultiSelectField
        id={`${item}.businessUnits`}
        options={businessUnits.map(item => ({
          value: item.id,
          text: item.name
        }))}
        label={t("businessUnits.BusinessUnitProductionForm.form.businessUnits")}
      />,
    },
  ];
}

function InputField({ classes, id, label, businessUnits }) {
  const { t } = useTranslation();
  const user = useUser();

  return (
    <div className={classes.root}>
      <Typography className={classes.label}>{label}</Typography>
      <FormArrayFields
        name={id}
        formFields={formFields({ t, user, businessUnits })}
        defaultItem={{}}
      />
    </div>
  );
}

export default withStyles(InputField, styles);
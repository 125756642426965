import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
import { Field, useFormState } from "react-final-form";
import MetaError from "../MetaError";
import { styles } from "../fields/_base.styles";
import { TextInput } from "../../inputs";

function TextFieldCommaArray({
  classes,
  id,
  type,
  label,
  disabled,
  required,
  onChange,
  autoValidate = false,
  forceAutoValidate = false,
  valuesThatPreventChange,
  rows,
  maxLength,
  note
}) {
  const [localValue, setLocalValue] = useState("");
  const formState = useFormState();
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const idParts = id.split('.');
    const fieldValue = idParts.reduce((acc, part) => acc && acc[part], formState.values);

    if (fieldValue) {
      setLocalValue(fieldValue.join(', '))
    }
    // eslint-disable-next-line
  }, [formState.values])

  const onChangeHandler = (v, input) => {
    if (!(valuesThatPreventChange || []).find((item) => item === v)) {
      clearTimeout(timer);
      setLocalValue(v);
      setTimer(setTimeout(() => {
        let value = v.split(',').map(item => item.trim()).filter(item => item.length > 0);
        input.onChange && input.onChange(value);
        onChange && onChange(value);
      }, 1000));
    }
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => {
          return (
            <>
              <TextInput
                className={classes.input}
                id={id}
                label={label}
                type={type || "text"}
                value={localValue}
                onChange={(value) => onChangeHandler(value, input)}
                disabled={disabled}
                required={required}
                error={Boolean(meta.error && (meta.touched || (autoValidate && (meta.modified || forceAutoValidate))))}
                variant={"outlined"}
                rows={rows}
                maxLength={maxLength}
                note={note}
              />
              <div className={classes.error}>
                <MetaError meta={meta} autoValidate={autoValidate} forceAutoValidate={forceAutoValidate} />
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
}

export default withStyles(TextFieldCommaArray, styles);
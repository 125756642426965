import React from "react";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteButton from "../../../../common/components/dialogs/DeleteButton";
import Permission from "../../../../app/components/Permission";
import { useUser } from "../../../../common/hooks";

export default function GroupDataGridActions({ row, onDelete, onEdit }) {
  const { t } = useTranslation();
  const user = useUser();


  return (
    <Grid container spacing={1} justifyContent="flex-end">
      <Permission code="entities.actions.group.update">
        {(user?.entity?.type === "P" || user?.entity?.id === row.entityId) &&
          <Grid item>
            <Button variant="outlined" color="primary" onClick={() => onEdit && onEdit(row)}>
              {t("entities.GroupList.columns.editBtn")}
            </Button>
          </Grid>
        }
      </Permission>
      <Permission code="entities.actions.group.disable">
        {(user?.entity?.type === "P" || user?.entity?.id === row.entityId) &&
          <Grid item>
            <DeleteButton
              labels={{
                button: t("entities.GroupList.columns.deleteBtn"),
                title: t("entities.GroupList.deleteConfirm.title"),
                description: t("entities.GroupList.deleteConfirm.description"),
                ok: t("entities.GroupList.deleteConfirm.ok"),
                cancel: t("entities.GroupList.deleteConfirm.cancel"),
              }}
              onConfirm={() => {
                onDelete && onDelete(row);
              }}
            />
          </Grid>
        }
      </Permission>
    </Grid>
  );
}

import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Stack } from "@mui/material";

import { styles } from "./ForecastTableHeader.styles";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";

function ForecastTableHeader({ classes, unitName, unit, target }) {
  return (
    <div className={classes.root}>
      <Stack alignItems="center">
        <Typography variant="caption">{unitName}</Typography>
        {(unit?.lines || []).map((line, index) => (
          <div key={"line_" + index}>
            {(target ? (line?.outputs || []) : (line?.inputs || [])).map((input, index2) => (
              <div key={"line_" + index + "_product_" + index2}>
                <ProductSnip
                  image={input.product?.thumbnail}
                  description={input.product?.description}
                  gtin={input.product?.gtin}
                  promoterInternalCode={input.product?.promoterInternalCode}
                // ownerName={input.product?.ownerName}
                />
              </div>
            ))}
          </div>
        ))}
      </Stack>
    </div>
  );
}

export default withStyles(ForecastTableHeader, styles);
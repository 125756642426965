


export const getFrequencyTypeOptions = (t) => {

  return ["dayOfWeek", "dayOfMonth"].map((item) => {
    return {
      value: item,
      text: t("entities.CutOffs.frequency." + item),
    };
  })
};

export const getDayOfWeekTypeOptions = (t) => {

  return [0, 1, 2, 3, 4, 5, 6, 7].map((item) => {
    return {
      value: item,
      text: t("entities.CutOffs.dateType." + item),
    };
  })
};

export const getCutOffTypeOptions = (t) => {

  return ["ANY", "MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"].map((item) => {
    return {
      value: item,
      text: t("entities.CutOffs.dateType." + item),
    };
  })
};


export const getDayOfMonthTypeOptions = (t) => {
  let days = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 31];

  return days.map((item) => {
    return {
      value: item,
      text: item === 0 ? " " : item,
    };
  })
};

import * as Yup from "yup";
import { DateTimePickerField, MultiLangTextField, MultiSelectField, SelectField, TextField } from "../../../common/forms";
import { getContactOptions, getGroupOptions, getSectorOptions } from "./TasksForm.options";
import React from "react";
import moment from "moment/moment";

export function formSchema({ t }) {
  return Yup.object().shape({
    name: Yup.object().oneLanguageRequired(t("tasks.TasksForm.form.titleRequired")),
    user: Yup.array().when("associationType",
      {
        is: "USER",
        then: schema => schema.ensure().min(1, t("roles.TasksForm.form.userRequired")),
        otherwise: schema => schema,
      }),
    group: Yup.array().when("associationType",
      {
        is: "GROUP",
        then: schema => schema.ensure().min(1, t("roles.TasksForm.form.groupRequired")),
        otherwise: schema => schema,
      }),
    sector: Yup.array().when("associationType",
      {
        is: "SECTOR",
        then: schema => schema.ensure().min(1, t("roles.TasksForm.form.sectorRequired")),
        otherwise: schema => schema,
      }),
  });
}

export function formFields({ t, values, contacts, sectorsGroups, promoter }) {
  const associationTypeOptions = ["GROUP", "USER", "SECTOR"].map(item => ({
    value: item,
    text: t("roles.enum.associationType." + item),
  }))

  return [
    {
      size: 12,
      field: <MultiLangTextField
        id="name"
        defaultLanguage={promoter?.getConfig("general", "defaultLanguage")}
        languages={[promoter?.getConfig("general", "defaultLanguage")]}
        required
        maxLength={50}
        label={t("tasks.TasksForm.form.title")}
      />,
    },
    {
      size: 6,
      field: <SelectField
        id="associationType"
        options={associationTypeOptions}
        label={t("tasks.TasksForm.form.associationType")}
      />,
    },
    {
      size: 6,
      field: (
        <MultiSelectField
          id="user"
          options={getContactOptions(contacts)}
          label={t("tasks.TasksForm.form.users")}
          required
        />
      ),
      hidden: values?.associationType !== "USER"
    },
    {
      size: 6,
      field: (
        <MultiSelectField
          id="group"
          options={getGroupOptions(sectorsGroups)}
          label={t("tasks.TasksForm.form.groups")}
          required
        />
      ),
      hidden: values?.associationType !== "GROUP"
    },
    {
      size: 6,
      field: (
        <MultiSelectField
          id="sector"
          options={getSectorOptions(t, promoter?.refData)}
          label={t("roles.RolesForm.form.sector")}
          required
        />
      ),
      hidden: values?.associationType !== "SECTOR"
    },
    {
      size: 12,
      field: <TextField
        id="message"
        rows={5}
        required
        label={t("tasks.TasksForm.form.message")}
      />,
    },
    {
      size: 6,
      field: <DateTimePickerField
        id="dueDate"
        label={t("tasks.TasksForm.form.dueDate")}
        minDate={moment(new Date())}
      />,
    },
  ];
}


import { processImageUrl } from "../../../api/s3";
import { asyncForEach, guid } from "../../../common/utils";


export const processHomeHighlights = async (setPromoterDetailsHighlightsImages, dispatch, promoterDetails) => {
  let nHighlights = [];
  const fileStorageConfigs = promoterDetails?.configs?.admin?.fileStorage;
  await asyncForEach(promoterDetails?.configs?.functional?.home?.highlights, async (highlight) => {
    nHighlights.push({
      //url: highlight?.location,
      base64: await processImageUrl(fileStorageConfigs, highlight?.location),
      // eslint-disable-next-line
      name: highlight?.location?.replace(/^.*[\\\/]/, ""),
      id: guid(),
    });
  });
  dispatch(setPromoterDetailsHighlightsImages(nHighlights));
}
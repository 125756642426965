import React from "react";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {styles} from "./ProcessBusinessHistoryDataGrid.styles";
import {CustomDataGrid} from "../../../../common/displays/CustomDataGrid";
import {listColumns} from "./ProcessBusinessHistoryDataGrid.columns";

function ProcessBusinessHistoryDataGrid({ classes, data, totalRows, onRowSelected }) {
  const { t } = useTranslation();

  return (
      <div className={classes.root}>
          {data && (
              <CustomDataGrid
                  localFilters
                  columns={listColumns(t, classes)}
                  rows={data}
                  total={totalRows}
                  onRowSelected={onRowSelected}
              />
          )}
      </div>
  );
}

export default withStyles(ProcessBusinessHistoryDataGrid, styles);
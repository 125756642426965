import * as Yup from "yup";
import { SelectField } from "../../../../../../common/forms";

export function formSchema({ t }) {
  return Yup.object().shape({
    resource: Yup.string().required(t("tasks.ResourceSelectorModal.form.resourceRequired")),
  });
}

export function formFields({ t, resources }) {
  return [
    {
      size: 12,
      field: <SelectField
        id="resource"
        options={getResourceOptions(resources)}
        label={t("tasks.ResourceSelectorModal.form.resource")}
      />,
    },
  ];
}

const getResourceOptions = (resources) => {
  return (resources || []).filter(item => item.type === "C" && item.active && item.state === "A")
    .map(({ id, title }) => {
      return {
        value: id, text: title
      }
    });
};
import React from "react";
import { withStyles } from "tss-react/mui";
import { Field } from "react-final-form";
import MetaError from "../MetaError";
import { styles } from "./_base.styles";
import { TextWithSelectInput } from "../../inputs";

function TextWithSelectField({ classes, id, textKey, selectKey, label, disabled, required, options, selectWidth }) {
  const onChangeHandler = (v, input) => {
    let nValue = { ...input.value };
    nValue[textKey] = v;
    input.onChange && input.onChange(nValue);
  };

  const onSelectChangeHandler = (v, input) => {
    let nValue = { ...input.value };
    nValue[selectKey] = v;
    input.onChange && input.onChange(nValue);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <>
            <TextWithSelectInput
              className={classes.input}
              id={id}
              label={label}
              value={input.value[textKey] || ""}
              selectValue={input.value[selectKey] || ""}
              onChange={(value) => onChangeHandler(value, input)}
              onSelectChange={(value) => onSelectChangeHandler(value, input)}
              disabled={disabled}
              required={required}
              error={Boolean(meta.error && meta.touched)}
              variant={"outlined"}
              options={options}
              selectWidth={selectWidth}
            />
            <div className={classes.error}>
              <MetaError meta={meta} />
            </div>
          </>
        )}
      </Field>
    </div>
  );
}

export default withStyles(TextWithSelectField, styles);

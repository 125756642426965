import React from "react";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {CustomDataGrid} from "../../../../common/displays/CustomDataGrid";

import {styles} from "./ProductsDataGrid.styles";
import {listColumns} from "./ProductsDataGrid.columns";
import {csvFields} from "./ProductsDataGrid.export";
import {usePromoter, useUser} from "../../../../common/hooks";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";
import {productListDefaultFilter} from "../../_common/productsFilters";
import {filterFields, filterSchema} from "./ProductsDataGrid.filter";
import {productExportDocuments, selectGroupContacts} from "../../productsSlice";
import {useDispatch, useSelector} from "react-redux";
import Permission from "../../../../app/components/Permission";
import {IconButton, Stack, Tooltip} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function ProductsDataGrid({ classes, data, totalRows, filter, onFilterChange, onProductSelected, loading, families }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const user = useUser();
  const columns = listColumns(t, classes, promoter, user);
  const groupContacts = useSelector(selectGroupContacts);
  const dispatch = useDispatch();

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };

      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  const onExportDocsHandler = () => {
   if(data && filter?.owner) {
      let products = data.map((p) => p.productId);
      if (products.length > 0)
        dispatch(productExportDocuments({productIds: products}));
    }
  };

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={columns}
        rows={data || []}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMoreHandler}
        total={totalRows}
        onRowSelected={onProductSelected}
        exportCsvFields={csvFields(t)}
        exportXlsxFields={csvFields(t)}
        topActions={
          <Stack spacing={1} direction="row" alignItems="center">
          <FilterForm
            style={{ marginBottom: 8 }}
            loading={loading}
            storageKey="ProductsDataGrid"
            onStorageLoad={onFilterHandler}
            slim
            fields={filterFields({ families, groupContacts, user, promoter })}
            schema={filterSchema()}
            onFilterChange={onFilterHandler}
            filter={filter}
            defaultFilter={productListDefaultFilter}
          />
            { filter?.owner &&
              <Permission code="products.actions.product.exportDocuments">
                <Tooltip title={t("products.ProductList.header.exportDocuments")}>
                  <IconButton id="exportDocuments" onClick={onExportDocsHandler} size="small">
                    <FileDownloadIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Permission>
            }
          </Stack>
        }
      />
    </div>
  );
}

export default withStyles(ProductsDataGrid, styles);

import * as Yup from "yup";
import {getCutOffTypeOptions} from "../../CutOffCreate/CutOffForm.options";
import {SelectField, TextField} from "../../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    cutOffType: Yup.string().required(t("entities.CutOffs.confirmForceCutOff.form_requiredCutOffType")),
  });
}

export function formFields(t, edit) {
  return [
    {
      size: 12,
      field: (
        <TextField
          id="entity"
          disabled={true}
          type="text"
          label={t("entities.CutOffs.confirmForceCutOff.form_entity")}
        />
      ),
    },
    {
      size: 6,
      field: (
        <SelectField
          id="cutOffType"
          label={t("entities.CutOffs.confirmForceCutOff.form_type")}
          options={getCutOffTypeOptions(t)}
          required
          disabled={!edit}
        />
      ),
    },
  ];
}

import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const entityListCall = async ({ clearList, appendToList, setListFilter, setListTotalRows }, dispatch, filter, clear) => {
  let rh = requestHelper(dispatch, "LIST_ENTITIES");
  if (clear) {
    dispatch(clearList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setListFilter(filter));
  const { types } = filter;
  try {
    let list = await call({
      type: "ENTITY",
      subType: "LIST",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
        types: !types || types.length === 0 ? undefined : types
      },
    });
    if (list && list.entities && list.entities.length > 0) {
      dispatch(appendToList(list.entities));
    }
    dispatch(setListTotalRows(list.rows));
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityListCall;

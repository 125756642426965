import * as Yup from "yup";
import {SelectField, TextField, TimePickerField} from "../../../common/forms";
import {getDayOfMonthTypeOptions, getDayOfWeekTypeOptions, getFrequencyTypeOptions, getTypeOptions, getSubTypeOptions} from "./TimerForm.options";

export function formSchema(t) {
  return Yup.object().shape({
    description: Yup.string().required(t("promoter.LocationsForm.form.requiredDescription")),
    frequency: Yup.string().required(t("promoter.LocationsForm.form.requiredFrequency")),
    time: Yup.string().required(t("promoter.LocationsForm.form.requiredTime")),
    type: Yup.string().required(t("promoter.LocationsForm.form.requiredType")),
    subtype: Yup.string().required(t("promoter.LocationsForm.form.requiredSubtype")),
    userRequest: Yup.string().required(t("promoter.LocationsForm.form.requiredUserRequest")),
  });
}

export function formFields({ t, isEdit, values }) {
  return [
    {
      size: 6,
      field: (
          <SelectField
              id="type"
              label={t("promoter.TimerForm.form.type")}
              options={getTypeOptions()}
              disabled={isEdit}
              required
          />
      ),
    },{
      size: 6,
      field: (
          <SelectField
              id="subtype"
              label={t("promoter.TimerForm.form.subtype")}
              options={getSubTypeOptions()}
              disabled={isEdit}
              required
          />
      ),
    },{
      size: 6,
      field: (
          <TextField
              id="description"
              type="text"
              label={t("promoter.TimerForm.form.description")}
              required
          />
      ),
    },{
      size: 6,
      field: (
        <TextField
          id="userRequest"
          type="text"
          label={t("promoter.TimerForm.form.userRequest")}
          required
          disabled={isEdit}
        />
      ),
    },
    {
      size: 2,
      field: (
          <SelectField
              id="frequency"
              label={t("promoter.TimerForm.form.frequency")}
              options={getFrequencyTypeOptions(t)}
              required
          />
      ),
    },
    
    
    {
      size: 2,
      field: (
          <SelectField
              id="dayOfMonth"
              label={t("promoter.TimerForm.form.dayOfMonth")}
              options={getDayOfMonthTypeOptions(t)}
              required={values?.frequency === "dayOfMonth"}
              disabled={values?.frequency !== "dayOfMonth"}
          />
      ),
      hidden: values.frequency !== "dayOfMonth",
    },
    {
      size: 2,
      field: (
          <SelectField
              id="dayOfWeek"
              label={t("promoter.TimerForm.form.dayOfWeek")}
              options={getDayOfWeekTypeOptions(t)}
              required={values?.frequency === "dayOfWeek"}
              disabled={values?.frequency !== "dayOfWeek"}
          />
      ),
      hidden: values.frequency !== "dayOfWeek",
    },
    {
      size: 2,
      field: 
          <TimePickerField 
            id="time" 
            label={t("promoter.TimerForm.form.time")}
            required 
            timeSteps={{ hours: 1, minutes: 15 }} />,
    },
    {
      size: 6,
      field:
          <div></div>
    },

    {
      size: 6,
      field: (
        <TextField
          id="request"
          type="text"
          label={t("promoter.TimerForm.form.request")}
          disabled={isEdit}
        />
      ),
    },
  ];
}

const avatar = {
  height: 24,
  width: 24,
  fontSize: 12,
  textTransform: "uppercase",
}

export const styles = (theme) => ({
  root: {
  },
  userAvatar: {
    justifyContent: "flex-end",
    "& .MuiAvatarGroup-avatar": {
      ...avatar,
    }
  },
  sectorAvatar: {
    justifyContent: "flex-end",
    "& .MuiAvatarGroup-avatar": {
      ...avatar,
      backgroundColor: theme.palette.primary.back,
      color: theme.palette.primary.main,
    }
  },
  sectorAvatarList: {
    ...avatar,
    backgroundColor: theme.palette.primary.back,
    color: theme.palette.primary.main,
  },
  groupAvatar: {
    justifyContent: "flex-end",
    "& .MuiAvatarGroup-avatar": {
      ...avatar,
      backgroundColor: theme.palette.info.back,
      color: theme.palette.info.main,
    }
  },
  groupAvatarList: {
    backgroundColor: theme.palette.info.back,
    color: theme.palette.info.main,
  },
});
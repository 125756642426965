import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./LineField.styles";
import { FormArrayFields, NumberField } from "../../../../common/forms";
import InputField from "./InputField/InputField";
import OutputField from "./OutputField/OutputField";
import { Divider } from "@mui/material";


const formFields = ({ t, businessUnits }) => (item) => {
  return [
    {
      size: 6,
      field: <InputField
        id={`${item}.inputs`}
        required
        label={t("businessUnits.BusinessUnitProductionForm.form.input")}
        businessUnits={businessUnits}
      />,
    },
    {
      size: 6,
      field: <OutputField
        id={`${item}.outputs`}
        required
        label={t("businessUnits.BusinessUnitProductionForm.form.output")}
      />,
    },
    {
      size: 12,
      field: <Divider />
    },
    {
      size: 4,
      field: <NumberField
        id={`${item}.productionTime`}
        label={t("businessUnits.BusinessUnitProductionForm.form.productionTime")}
      />,
    },
    {
      size: 4,
      field: <NumberField
        id={`${item}.successRate`}
        label={t("businessUnits.BusinessUnitProductionForm.form.successRate")}
        endAdornment="%"
      />,
    },
    {
      size: 4,
      field: <NumberField
        id={`${item}.agregator`}
        label={t("businessUnits.BusinessUnitProductionForm.form.agregator")}
      />,
    },
    {
      size: 4,
      field: <NumberField
        id={`${item}.spreader`}
        label={t("businessUnits.BusinessUnitProductionForm.form.spreader")}
      />,
    },
    {
      size: 4,
      field: <NumberField
        id={`${item}.anticipation`}
        label={t("businessUnits.BusinessUnitProductionForm.form.anticipation")}
      />,
    },
    {
      size: 4,
      field: <NumberField
        id={`${item}.loadBalance`}
        label={t("businessUnits.BusinessUnitProductionForm.form.loadBalance")}
        endAdornment="%"
      />,
    },

  ];
}

// productionTime: 0,
// successRate: 0,
// agregator: 0,
// spreader: 0,
// anticipation: 0,
// loadBalance: 0,
// inputs: [
//   {
//     productId: "",
//     quantity: 0,
//     businessUnits: "",
//   }
// ],
// outputs: [
//   {
//     productId: "",
//     quantity: 0,
//   }
// ]



function LineField({ classes, id, businessUnits }) {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <FormArrayFields
        name={id}
        formFields={formFields({ t, businessUnits })}
        defaultItem={{
          input: [],
          output: []
        }}
      />
    </div>
  );
}

export default withStyles(LineField, styles);
import React, { useEffect, useRef } from "react";
import { withStyles } from "tss-react/mui";
import { Button, Grid } from "@mui/material";

import { styles } from "./MosaicGrid.styles";

function MosaicGrid({ classes, data, render, itemKeyField, onLoadMore, dataExtra }) {
  const loader = useRef(null);
  const loadMoreBtn = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {
          setTimeout(() => {
            loadMoreBtn?.current && loadMoreBtn.current.click();
          }, 300);
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      }
    );
    if (loader.current) {
      observer.observe(loader.current);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {(dataExtra || []).map((item, index) => (
          <Grid item xs={2} key={"mosaic_extra_" + index + "_" + item[itemKeyField]}>
            {render && render(item)}
          </Grid>
        ))}
        {(data || []).map((item, index) => (
          <Grid item xs={2} key={"mosaic_" + index + "_" + item[itemKeyField]}>
            {render && render(item)}
          </Grid>
        ))}
      </Grid>
      <div className="loading" ref={loader}>
        <Button
          variant="contained"
          onClick={() => {
            onLoadMore && onLoadMore();
          }}
          style={{ display: "none" }}
          ref={loadMoreBtn}
        >
          Load More
        </Button>
      </div>
    </div>
  );
}

export default withStyles(MosaicGrid, styles)

import React from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOffIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnIcon from '@mui/icons-material/ToggleOnOutlined';

import { styles } from "./StepActions.styles";
import ActionsPopover from "../../../../common/components/ActionsPopover/ActionsPopover";
import { businessUnitDisableStep, businessUnitEnableStep } from "../../businessUnitsSlice";
// import Permission from "../../../../app/components/Permission";

function StepActions({ classes, step, onEdit, onRemove, internal, businessUnitId, lineId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let actionsButtons = [];

  const onEditHandler = () => {
    onEdit && onEdit(step);
  }

  const onEnableHandler = () => {
    dispatch(businessUnitEnableStep({ id: step?.id, internal, businessUnitId, lineId }));
  }

  const onDisableHandler = () => {
    dispatch(businessUnitDisableStep({ id: step?.id, internal, businessUnitId, lineId }));
  }

  const onRemoveHandler = () => {
    onRemove && onRemove();
  }

  // EDIT
  actionsButtons.push(
    // <Permission code="promoter.actions.places.update">
    <Button onClick={onEditHandler} fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item style={{ display: "flex" }}>
          <EditIcon />
        </Grid>
        <Grid item>{t("businessUnits.StepActions.actions.edit")}</Grid>
      </Grid>
    </Button>
    // </Permission>
  );

  // ENABLE
  if (!step.active) {
    actionsButtons.push(
      // <Permission code="promoter.actions.businessUnit.enable">
      <Button onClick={onEnableHandler} fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <ToggleOnIcon />
          </Grid>
          <Grid item>{t("businessUnits.StepActions.actions.enable")}</Grid>
        </Grid>
      </Button>
      // </Permission>
    );
  }

  // DISABLE
  if (step.active) {
    actionsButtons.push(
      // <Permission code="promoter.actions.businessUnit.disable">
      <Button onClick={onDisableHandler} fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <ToggleOffIcon />
          </Grid>
          <Grid item>{t("businessUnits.StepActions.actions.disable")}</Grid>
        </Grid>
      </Button>
      // </Permission>
    );
  }

  // REMOVE
  if (!step.active) {
    actionsButtons.push(
      <Button onClick={onRemoveHandler} color="error" fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <DeleteIcon />
          </Grid>
          <Grid item>{t("businessUnits.StepActions.actions.remove")}</Grid>
        </Grid>
      </Button>
    );
  }

  return (
    <div className={classes.root}>
      <ActionsPopover
        actions={actionsButtons}
      />
    </div>
  );
}

export default withStyles(StepActions, styles);
import { saveFile } from "../../../api/s3";
import { getPromoterConfig } from "../../../app/coreSlice";
import { base64ToArrayBuffer, getEntityImageUrl } from "../../../common/utils";

const entityUpdateLogoCall = async (dispatch, state, { entityId, logo }) => {
  const promoterCode = state.core.promoterDetails?.details?.code;
  const filePath = getEntityImageUrl(promoterCode, entityId);
  const { provider, privateBucket } = getPromoterConfig(state, "admin", "fileStorage");
  await saveFile(
    provider,
    privateBucket,
    filePath,
    base64ToArrayBuffer(logo.base64)
  );
  // dispatch(setSuccess("entities", "LOGO_UPDATED"));
};

export default entityUpdateLogoCall;

import React from "react";
import { Route, Routes } from "react-router-dom";
import PlaceList from "./PlaceList/PlaceList";
import Security from "../../app/components/Security/Security";
import PlaceForm from "./PlaceForm/PlaceForm";

function PlacesRouting() {
  return (
    <Routes>
      <Route path="/" element={<PlaceList />} />
      <Route path="/create" element={<PlaceForm />} />
      <Route path="/edit/:id" element={
        <Security component={<PlaceForm />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/copy/:id" element={
        <Security component={<PlaceForm copy />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
    </Routes>
  );
}

export default PlacesRouting;

import {call} from "../../../app/websocket/_sliceCalls/websocket";
import {requestHelper, setRedirect, setSuccess} from "../../../app/coreSlice";

const promoterUpdateTimerCall = async ({ promoterListTimer }, dispatch, state, { id, timer }) => {
  const filter = state.promoter.timerFilter;
  let rh = requestHelper(dispatch, "PROMOTER_UPDATE_TIMER");
  try {
    await call({
      type: "PROMOTER",
      subType: "UPDATE_TIMER",
      request:{
        ...timer,
        id: id,
      }
    });
    setTimeout(() => {
      dispatch(setRedirect("/promoter/timer"));
      dispatch(setSuccess("promoter", "PROMOTER_UPDATE_TIMER"));
      dispatch(promoterListTimer(filter, true));
    }, 300);
  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default promoterUpdateTimerCall;

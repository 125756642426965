export const styles = () => ({
  root: {
  },
  datetime: {
    margin: 0,
    fontSize: 12,
  },
  nomargin: {
    margin: 0,
  },
  dispatchId: {
    fontSize: 11,
    lineHeight: 1.1,
    fontWeight: "bold",
    height: 18
  }
});
import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, IconButton, Modal, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { styles } from "./EntityUpdateName.styles";
import PageTitle from "../../../../common/displays/PageTitle/PageTitle";
import { Form, FormFields } from "../../../../common/forms";
import { formFields, formSchema } from "./EntityUpdateName.schema";
import { useDispatch } from "react-redux";
import { entityUpdateName } from "../../entitiesSlice";

function EntityUpdateName({ classes, hidden, entity }) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    dispatch(entityUpdateName(values));
    setModal(false);
  };

  return (
    <div className={classes.root}>
      <IconButton
        disableRipple
        size="small"
        onClick={() => setModal(true)}
        className={classes.icon}
        style={hidden ? { display: "none" } : { display: "inline" }}
      >
        <EditIcon fontSize="small" className={classes.edit} />
      </IconButton>
      <Modal open={modal} className={classes.modal} onClose={() => setModal(false)}>
        <div className={classes.modalroot}>
          <PageTitle
            title={t("entities.EntityUpdateName.modal.title")}
            actions={
              <Grid container spacing={2}>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={() => setModal(false)}>
                    {t("entities.EntityUpdateName.modal.closeBtn")}
                  </Button>
                </Grid>
              </Grid>
            }
          />

          <Grid container className={classes.modalbody} direction="column">
            <Grid item style={{ flexGrow: 1 }}>
              <Form
                schema={formSchema(t)}
                initialValues={{ name: entity?.name, entityId: entity?.entityId }}
                onSubmit={onSubmit}
                render={({ submitting }) => {
                  return (
                    <div>
                      <FormFields fields={formFields(t)} />
                      <br />
                      <br />
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                            {t("entities.EntityUpdateName.modal.submitBtn")}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  );
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}

export default withStyles(EntityUpdateName, styles)

export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: 10,
  },
});

import moment from "moment";
import { guid } from "../../common/utils";
import { convertCsvJsonToObjects } from "../../common/utils/csv";
import productPriceListComplete from "./_directCalls/productPriceListComplete";
import xlsx from "json-as-xlsx";
import { prependToPricesList } from "./productsPricesSlice";

export const processPricesImport = async (importJson, t, dispatch, locations, isPromoter, fileStorageConfigs, allowRetailPrice, allowPricePromotions) => {
  // console.log('processPricesImport: %o', { importJson, locations, isPromoter, fileStorageConfigs, allowRetailPrice, allowPricePromotions });

  let priceList = await productPriceListComplete(fileStorageConfigs);
  let processedJson = processImportedPricesJson(importJson, t, locations, isPromoter, allowRetailPrice, allowPricePromotions);
  // console.log('processedJson: %o', processedJson);

  let pricesToCreate = [];
  let pricesToUpdate = [];
  let pricesToDelete = [];
  let productsWithChanges = [];

  (processedJson || []).forEach((ip) => {
    // console.log('[IMP] %s', ip.product);
    // console.log('[IMP] %o', ip);
    let ogProduct = (priceList || []).find(item =>
      ((item.promoterDescription ? escapeStringToCSV(item.promoterDescription) : escapeStringToCSV(item.description)) === ip.product)
      && (!isPromoter || escapeStringToCSV(item.ownerName) === ip.owner)
    );
    // console.log('[IMP] ogProduct: %o', ogProduct);
    if (ogProduct) {
      let ogPrice = (ogProduct?.prices || []).find(item =>
        item.priceType === ip.priceType
        && JSON.stringify((item.locations || []).filter(a => a).sort()) === JSON.stringify((ip.locations || []).filter(a => a).sort())
        && item.status === "ACC"
      );
      // console.log('[IMP] ogPrice: %o', ogPrice);
      if ((parseFloat(ip.salePrice) > 0 || parseFloat(ip.retailPrice) > 0)) {
        if (ogPrice) {
          const promsHaveChanges = promotionsHaveChanges(ogPrice.promotions, ip.promotions);
          // console.log('promsHaveChanges: %o', promsHaveChanges);
          // console.log('[IMP] price: %s - %s ||| (%s) %s - %s', parseFloat(ogPrice?.salePrice || "0"), parseFloat(ip.salePrice || "0"), allowRetailPrice, parseFloat(ogPrice?.retailPrice || "0"), parseFloat(ip.retailPrice || "0"));
          if (parseFloat(ogPrice?.salePrice || "0") !== parseFloat(ip.salePrice || "0") || (allowRetailPrice && parseFloat(ogPrice?.retailPrice || "0") !== parseFloat(ip.retailPrice || "0")) || promsHaveChanges) {
            // console.log('[IMP](U): %o | %o', ogPrice, ip);
            pricesToUpdate.push({
              productId: ogProduct.productId,
              onwerId: ogProduct.owner,
              contactPoint: ogProduct.contactPoint,
              ...ogPrice,
              salePrice: ip.salePrice,
              retailPrice: ip.retailPrice,
              priceType: ip.priceType,
              locations: ip.locations,
              promotions: promsHaveChanges ? mergePromotions(ogPrice?.promotions, ip?.promotions) : ogPrice.promotions
            });
            productsWithChanges.push(ogProduct);
          }
          else if (ip?.expireDate && ip?.expireDate !== ogPrice?.expireDate) {
            pricesToDelete.push({
              product: ogProduct,
              ...ogPrice,
              productId: ogProduct.productId,
              ownerId: ogProduct.owner,
              contactPoint: ogProduct.contactPoint,
              removeDate: ip.expireDate,
              ownerName: ogProduct.ownerName
            });
            productsWithChanges.push(ogProduct);
          }
        }
        else if (ip.priceType) {
          // console.log('[IMP](C): %o | %o', ip, ogProduct?.prices);
          pricesToCreate.push({
            productId: ogProduct.productId,
            onwerId: ogProduct.onwerId,
            contactPoint: ogProduct.contactPoint,
            salePrice: ip.salePrice,
            retailPrice: ip.retailPrice,
            priceType: ip.priceType,
            locations: ip.locations,
            promotions: mergePromotions(ogPrice?.promotions, ip?.promotions)
          });
          productsWithChanges.push(ogProduct);
        }
      }
    }
  });
  console.log('productsWithChanges: %o', productsWithChanges);
  dispatch(prependToPricesList(productsWithChanges));
  console.log('IMPORT result: %o', { pricesToCreate, pricesToUpdate, pricesToDelete });
  return { pricesToCreate, pricesToUpdate, pricesToDelete }
}

export const priceJsonToCsv = (t, priceList, fileName, isPromoter, locations, allowRetailPrice, allowStandard, allowPricePromotions) => {
  let data = [];
  (priceList || []).forEach((product) => {
    let setDummyPrice = true;
    (product?.prices || []).forEach((price) => {
      if (price.status === "ACC" && (allowStandard || price.priceType !== "S")) {
        data.push(pricesToCsvObject(t, product, price, isPromoter, locations, allowRetailPrice, allowPricePromotions));
        setDummyPrice = false;
      }
    })
    if (setDummyPrice) {
      data.push(pricesToCsvObject(t, product, {}, isPromoter, locations, allowRetailPrice, allowPricePromotions));
    }
  });
  // set headers with t()
  if (data?.length > 0) {
    let fields = Object.keys(data[0]).map(item => ({ field: item, header: t("productsPrices.PricesList.exportHeader." + item) }));

    let xlsxData = [
      {
        sheet: "Data",
        columns: fields.map(({ field, header, customValue }) => {
          return {
            label: header,
            value: customValue || field,
          };
        }),
        content: data,
      },
    ];

    let settings = {
      fileName: fileName ? fileName : "export",
      extraLength: 3,
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };

    xlsx(xlsxData, settings);
    return true;
  }
  return false;
}

const escapeStringToCSV = (str) => {
  return str.replace(/[\n\r]/g, '').replace(/,/g, '.');
}

export const pricesToCsvObject = (t, product, price, isPromoter, locationsList, allowRetailPrice, allowPricePromotions) => {
  const { promoterDescription, description, ownerName, promoterInternalCode, supplierInternalCode } = product;
  const { priceType, salePrice, locations, retailPrice, promotions, effectiveDate, expireDate } = price;

  // console.log('product: %o, price: %o', product, price);

  let obj = {
    product: (isPromoter && promoterDescription) ? escapeStringToCSV(promoterDescription) : escapeStringToCSV(description),
    promoterInternalCode: escapeStringToCSV(promoterInternalCode || ""),
    supplierInternalCode: escapeStringToCSV(supplierInternalCode || ""),
    owner: escapeStringToCSV(ownerName),
    locations: (locationsList || []).filter(loc => (locations || []).includes(loc.id)).map(loc => loc.name).join(' | '),
    priceType: priceType ? t("common.Enums.priceType." + priceType) : "",
    salePrice: salePrice || "",
    retailPrice: retailPrice || "",
    effectiveDate: effectiveDate || "",
    expireDate: expireDate || "",
    promotion1_name: promotions?.[0]?.name || "",
    promotion1_percentage: promotions?.[0]?.percentage || "",
    promotion1_startDate: promotions?.[0]?.startDate || "",
    promotion1_endDate: promotions?.[0]?.endDate || "",
    promotion2_name: promotions?.[1]?.name || "",
    promotion2_percentage: promotions?.[1]?.percentage || "",
    promotion2_startDate: promotions?.[1]?.startDate || "",
    promotion2_endDate: promotions?.[1]?.endDate || "",
    promotion3_name: promotions?.[2]?.name || "",
    promotion3_percentage: promotions?.[2]?.percentage || "",
    promotion3_startDate: promotions?.[2]?.startDate || "",
    promotion3_endDate: promotions?.[2]?.endDate || "",
  }

  if (!allowPricePromotions) {
    delete obj.promotion1_name;
    delete obj.promotion1_percentage;
    delete obj.promotion1_startDate;
    delete obj.promotion1_endDate;
    delete obj.promotion2_name;
    delete obj.promotion2_percentage;
    delete obj.promotion2_startDate;
    delete obj.promotion2_endDate;
    delete obj.promotion3_name;
    delete obj.promotion3_percentage;
    delete obj.promotion3_startDate;
    delete obj.promotion3_endDate;
  }

  if (!isPromoter) {
    delete obj.owner;
    delete obj.promoterInternalCode;
  }

  if (!allowRetailPrice) {
    delete obj.retailPrice;
  }
  console.log('obj: %o', obj);
  return obj;
}

const processImportedPricesJson = (importJson, t, locationsList, isPromoter, allowRetailPrice, allowPricePromotions) => {
  // se for promoter tem dois campos extra o promoterInternalCode e o owner

  let fieldMapper;
  if (isPromoter) {
    fieldMapper = [
      { key: "product" },
      { key: "promoterInternalCode" },
      { key: "supplierInternalCode" },
      { key: "owner" },
      { key: "locations" },
      { key: "priceType" },
      { key: "salePrice", type: "float" },
    ]
  }
  else {
    fieldMapper = [
      { key: "product" },
      { key: "supplierInternalCode" },
      { key: "locations" },
      { key: "priceType" },
      { key: "salePrice", type: "float" },
    ]
  }

  if (allowRetailPrice) {
    fieldMapper.push({ key: "retailPrice", type: "float" })
  }

  fieldMapper.push({ key: "effectiveDate" });
  fieldMapper.push({ key: "expireDate" });

  if (allowPricePromotions) {
    fieldMapper = fieldMapper.concat([
      { key: "prom1_name" },
      { key: "prom1_value", type: "float" },
      { key: "prom1_startDate" },
      { key: "prom1_endDate" },
      { key: "prom2_name" },
      { key: "prom2_value", type: "float" },
      { key: "prom2_startDate" },
      { key: "prom2_endDate" },
      { key: "prom3_name" },
      { key: "prom3_value", type: "float" },
      { key: "prom3_startDate" },
      { key: "prom3_endDate" },
    ])
  }

  let processedJson = convertCsvJsonToObjects(importJson, fieldMapper);

  const priceTypes = (["S", "G", "D", "W"]).map(item => ({
    value: item,
    text: t("common.Enums.priceType." + item),
  }));

  return processedJson.map(({ product,
    promoterInternalCode,
    supplierInternalCode,
    owner,
    locations,
    priceType,
    salePrice,
    retailPrice,
    effectiveDate,
    expireDate,
    prom1_name,
    prom1_value,
    prom1_startDate,
    prom1_endDate,
    prom2_name,
    prom2_value,
    prom2_startDate,
    prom2_endDate,
    prom3_name,
    prom3_value,
    prom3_startDate,
    prom3_endDate,
  }) => ({
    product,
    promoterInternalCode,
    supplierInternalCode,
    owner,
    locations: locations.split(' | ').map(item => (locationsList || []).find(loc => loc.name === item)?.id).filter(item => item !== undefined),
    priceType: priceTypes.find(item => item.text === priceType)?.value,
    salePrice,
    retailPrice,
    effectiveDate,
    expireDate,
    promotions: processPromotionsJson(prom1_name, prom1_value, prom1_startDate, prom1_endDate, prom2_name, prom2_value, prom2_startDate, prom2_endDate, prom3_name, prom3_value, prom3_startDate, prom3_endDate)
  }));
}

const processPromotionsJson = (prom1_name, prom1_value, prom1_startDate, prom1_endDate, prom2_name, prom2_value, prom2_startDate, prom2_endDate, prom3_name, prom3_value, prom3_startDate, prom3_endDate) => {
  // console.log('--> %o', { prom1_name, prom1_value, prom2_name, prom2_value, prom3_name, prom3_value })
  let proms = [];
  if (prom1_name && prom1_value) {
    proms.push({
      name: prom1_name,
      percentage: parseFloat(prom1_value),
      startDate: prom1_startDate,
      endDate: prom1_endDate,
    })
  }
  if (prom2_name && prom2_value) {
    proms.push({
      name: prom2_name,
      percentage: parseFloat(prom2_value),
      startDate: prom2_startDate,
      endDate: prom2_endDate,
    })
  }
  if (prom3_name && prom3_value) {
    proms.push({
      name: prom3_name,
      percentage: parseFloat(prom3_value),
      startDate: prom3_startDate,
      endDate: prom3_endDate,
    })
  }
  return proms;
}

const promotionsHaveChanges = (ogPromotions, importedPromotions) => {
  for (let i = 0; i < 3; i++) {
    if (importedPromotions?.[i]?.percentage && !ogPromotions?.[i]?.percentage) {
      return true;
    }
    if (parseFloat(importedPromotions?.[i]?.percentage || "0") !== parseFloat(ogPromotions?.[i]?.percentage || "0")) {
      return true;
    }
  }
  return false;
}

const mergePromotions = (ogPromotions, importedPromotions) => {
  // console.log("proms: %o", { ogPromotions, importedPromotions });

  if (!ogPromotions || ogPromotions.length === 0) {
    // ALL NEW
    return (importedPromotions || []).map(({ name, percentage }) => ({
      name,
      percentage,
      type: "PD",
      startDate: moment().toISOString(),
      id: guid(),
      isNew: true
    }))
  }

  let proms = [];
  for (let i = 0; i < 3; i++) {
    // console.log('[%s] %o %o', i, importedPromotions?.[i], ogPromotions?.[i]);
    if (importedPromotions?.[i]?.percentage && !ogPromotions?.[i]?.percentage) {
      const { name, percentage } = importedPromotions?.[i];
      // NEW
      // console.log('PROM NEW')
      proms.push({
        name,
        percentage,
        type: "PD",
        startDate: moment().toISOString(),
        id: guid(),
        isNew: true
      })
    }
    else if (importedPromotions?.[i]?.percentage && importedPromotions?.[i]?.percentage !== parseFloat(ogPromotions?.[i]?.percentage || "0")) {
      const { percentage } = importedPromotions?.[i];
      const { name, type, startDate, endDate, id } = ogPromotions?.[i];
      // UPDATE
      // console.log('PROM UPDATE')
      proms.push({
        name,
        percentage,
        type,
        startDate,
        endDate,
        id,
        hasChanges: true
      })
    }
  }
  return proms;
}
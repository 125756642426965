import React from "react";
import { withStyles } from "tss-react/mui";
import { List, ListItem } from "@mui/material";

import { styles } from "./CustomList.styles";

function CustomList({ classes, items, render, customKey = "key" }) {
  return (
    <List className={classes.root} disablePadding>
      {items?.map((item, index) => (
        <ListItem divider={index < items?.length - 1} key={`${customKey}-${index}`}>
          {render(item, index)}
        </ListItem>
      ))}
    </List>
  );
}

export default withStyles(CustomList, styles);

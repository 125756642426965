import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, IconButton, Stack } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { styles } from "./FeatureAvailableField.styles";
import { Field } from "react-final-form";

function FeatureAvailableField({ classes, id, label, onCheck }) {
  const onCheckHandler = (e, input) => {
    e.stopPropagation();
    onCheck && onCheck(!input.value);
    input.onChange && input.onChange(!input.value)
  }

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <Stack direction="row" spacing={2} alignItems="center">
            <IconButton size="large" onClick={(e) => onCheckHandler(e, input)} color={input.value ? "primary" : "default"}>
              {input.value ? <CheckCircleOutlineIcon fontSize="inherit" /> : <RadioButtonUncheckedIcon fontSize="inherit" />}
            </IconButton>
            <Typography variant="h3" sx={!input.value ? { color: 'text.disabled' } : {}}>{label}</Typography>
          </Stack>
        )}
      </Field>
    </div>
  );
}

export default withStyles(FeatureAvailableField, styles);
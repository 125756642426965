import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";

import { styles } from "./LocationsList.styles";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { promoterDeleteLocation, promoterListLocations, selectLocationsList } from "../promoterSlice";
import LocationsDataGrid from "./LocationsDataGrid/LocationsDataGrid";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import Page from "../../../common/displays/Page/Page";
import Permission from "../../../app/components/Permission";


function LocationsList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locations = useSelector(selectLocationsList);
  const navigate = useNavigate();

  useFirstLoad(() => {
    dispatch(promoterListLocations());
  });

  const onDeleteHandler = (location) => {
    dispatch(promoterDeleteLocation({ id: location.id }));
  }

  const onEditHandler = (location) => {
    navigate("/promoter/configs/locations/edit/" + location?.id);
  }

  return (
    <Page
      permission="promoter.actions.location.list"
      header={<PageTitle
        title={t("promoter.LocationsList.header.title")}
        onBack={() => navigate("/promoter/configs")}
        actions={
          <Grid container direction="row" spacing={2}>
            <Permission code="promoter.actions.location.create">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => navigate("/promoter/configs/locations/add")}
                >
                  {t("promoter.LocationsList.header.addBtn")}
                </Button>
              </Grid>
            </Permission>
          </Grid>
        }
      />}>
      <LocationsDataGrid
        data={locations}
        onDelete={onDeleteHandler}
        onEdit={onEditHandler}
      />
    </Page>
  );
}

export default withStyles(LocationsList, styles);
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./IncidentProductsDataGrid.styles";
import { listColumns } from "./IncidentProductsDataGrid.columns";
import { CustomDataGrid } from "../../../../../common/displays/CustomDataGrid";

function IncidentProductsDataGrid({ classes, products }) {
  const { t } = useTranslation();
  const columns = listColumns(t, classes);

  return (
    <div className={classes.root}>
      {products && <CustomDataGrid columns={columns} rows={products} total={products.length} />}
    </div>
  );
}

export default withStyles(IncidentProductsDataGrid, styles)

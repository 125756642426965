import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const productPendingPurchaseActionCall = async (
  dispatch,
  { productId, taskId, status, quantity, price, comment }
) => {
  let rh = requestHelper(dispatch, "PRODUCT_PENDING_PURCHASE_ACTION");
  try {
    await call({
      type: "PRODUCT",
      subType: "PENDING_PURCHASE_ACTION",
      request: {
        productId,
        taskId,
        status,
        quantity,
        price,
        comment
      },
    });
    dispatch(setSuccess("products", "PRODUCT_PENDING_PURCHASE_ACTION_" + status));
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productPendingPurchaseActionCall;




import { CampaignTypeIcon } from "../../../../common/icons";

export const promotionTypeOptions = (t) => {
  return ["PD"].map((item) => {
    return {
      value: item,
      icon: <CampaignTypeIcon type={item} />,
      description: t("productsPrices.PromotionForm.promotionType." + item),
    };
  });
} 

export const styles = (theme) => ({
  root: {
    cursor: "pointer"
  },
  taskOpen: {
    color: theme.palette.text.secondary,
    "& p": {
      fontWeight: "bold",
      fontSize: 16
    }
  },
  taskCompleted: {
    color: theme.palette.success.main,
    "& p": {
      fontWeight: "bold",
      fontSize: 16
    }
  }
});
import { processImageUrl } from "../../../api/s3";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { asyncForEach } from "../../../common/utils";

const productPendingProductListCall = async (
  { clearPendingProductList, setPendingProductListFilter, appendToPendingProductList, setPendingProductListTotalRows, updatePendingProductsThumbnails },
  dispatch,
  state,
  { filter, clear }
) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "PRODUCT_PENDING_PRODUCT_LIST");
  if (clear) {
    dispatch(clearPendingProductList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setPendingProductListFilter(filter));
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "PENDING_PRODUCT_LIST",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    dispatch(appendToPendingProductList(result.items));
    dispatch(setPendingProductListTotalRows(result.rows));

    //update thumbnails
    let products = [];
    await asyncForEach(result.items, async (product, index) => {
      let nThumbnail = await processImageUrl(fileStorageConfigs,  product.thumbnail);
      if (product.thumbnail !== nThumbnail) {
        products.push({ productId: product.productId, thumbnail: nThumbnail });
      }
    });
    dispatch(updatePendingProductsThumbnails(products));

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productPendingProductListCall;

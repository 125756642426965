export const styles = () => ({
  root: {
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "21px",
      boxShadow: "inset 0 0 21px rgba(0,0,0,.3)",
    },
    "& .MuiListItem-root": {
      padding: 0,
      paddingLeft: 5,
      paddingRight: 10,
    },
  }
});
import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, IconButton, Modal, Chip, Checkbox, Avatar, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { styles } from "./MetadataField.styles";
import { Field } from "react-final-form";
import MetaError from "../../../../common/forms/MetaError";

function MetadataField({ classes, id, label, required, disabled, documentType, documentMetadata }) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState();

  const editMetadataHandler = (doc) => {
    setSelectedDoc(doc);
    setModal(true);
  };

  const toggleMetadataHandler = (doc, metadata, input) => {
    let nValue = {
      ...input.value,
      [doc]: {
        ...input.value[doc],
        ...(input.value?.[doc]?.[metadata] !== undefined ? { [metadata]: undefined } : { [metadata]: false }),
      },
    };
    input.onChange && input.onChange(nValue);
  };

  const onCheckChange = (doc, metadata, input, checked) => {
    let nValue = {
      ...input.value,
      [doc]: {
        ...input.value[doc],
        [metadata]: checked,
      },
    };
    input.onChange && input.onChange(nValue);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => {
          return (
            <>
              <Typography
                component="span"
                variant="caption"
                htmlFor={id}
                color="textPrimary"
                className={disabled ? classes.disabledLabel : ""}
              >
                {label}
                {required && <span> *</span>}
              </Typography>
              <Grid container direction="column" spacing={1}>
                {(documentType || []).map((doc) => (
                  <Grid item key={doc}>
                    <Grid container spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                      <Grid item>
                        <Typography>{t(`common.Enums.documentType.${doc}`)}</Typography>
                      </Grid>
                      <Grid item>
                        <IconButton aria-label="delete" size="small" onClick={() => editMetadataHandler(doc)}>
                          <EditIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                    </Grid>
                    {input.value[doc] && (
                      <Grid container spacing={1}>
                        {(Object.keys(input.value[doc]) || []).map(
                          (metadata) =>
                            input.value[doc][metadata] !== undefined && (
                              <Grid item key={doc + "_" + metadata}>
                                <Chip
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  avatar={
                                    input.value[doc][metadata] ? (
                                      <Tooltip title={t("promoter.MetadataField.labels.required")}>
                                        <Avatar>R</Avatar>
                                      </Tooltip>
                                    ) : null
                                  }
                                  label={t(`common.Enums.documentMetadata.${metadata}`)}
                                />
                              </Grid>
                            )
                        )}
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Grid>

              <div className={classes.error}>
                <MetaError meta={meta} />
              </div>
              <Modal open={modal} className={classes.modal} onClose={() => setModal(false)}>
                <div className={classes.modalroot}>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item>
                          <Typography component="span" variant="caption" color="textPrimary">
                          {t("promoter.MetadataField.labels.metadata")}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography component="span" variant="caption" color="textPrimary">
                          {t("promoter.MetadataField.labels.required")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {(documentMetadata || []).map((metadata) => {
                      let selected = input.value?.[selectedDoc]?.[metadata] !== undefined;
                      return (
                        <Grid item key={metadata}>
                          <Grid
                            container
                            spacing={1}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <Chip
                                variant={selected ? "filled" : "outlined"}
                                color="primary"
                                size="small"
                                label={t(`common.Enums.documentMetadata.${metadata}`)}
                                onClick={() => toggleMetadataHandler(selectedDoc, metadata, input)}
                              />
                            </Grid>
                            <Grid item>
                              <Checkbox
                                checked={Boolean(input.value?.[selectedDoc]?.[metadata])}
                                disabled={!selected}
                                size="small"
                                onChange={(e) => onCheckChange(selectedDoc, metadata, input, e.target.checked)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </Modal>
            </>
          );
        }}
      </Field>
    </div>
  );
}

export default withStyles(MetadataField, styles);

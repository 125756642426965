import React from "react";
import { withStyles } from "tss-react/mui";
import { Box, Tooltip } from "@mui/material";

import { styles, tooltipStyles } from "./ErrorSubtitle.styles";
import { InfoIcon } from "../../icons";

const CustomTooltip = withStyles(Tooltip, tooltipStyles);

function ErrorSubtitle({ classes, error }) {
  return (
    <div className={classes.root}>
      <CustomTooltip title={error} placement="top">
        <Box className={classes.box}>
          <InfoIcon className={classes.icon} viewBox="0 0 20 20" />
        </Box>
      </CustomTooltip>
    </div>
  );
}

export default withStyles(ErrorSubtitle, styles);

import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";

import { styles } from "./UpdateRetentionDialog.styles";
import Form from "../../../../common/forms/Form";
import { formFields, formSchema } from "./UpdateRetentionDialog.schema";
import { useTranslation } from "react-i18next";
import Permission from "../../../../app/components/Permission";

function UpdateRetentionDialog({ classes, initialValues, canEdit, onConfirm, ...btnProps }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const onSubmit = async (values) => {
    onConfirm(values);
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Permission code="promoter.actions.auditRetention.update">
        <Button
          {...btnProps}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          {t("promoter.PromoterConfigs.retention.editBtn")}
        </Button>
      </Permission>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Form
          onSubmit={onSubmit}
          schema={formSchema(t)}
          initialValues={initialValues}
          render={({ submitting, values }) => {
            return (
              <>
                <DialogTitle id="alert-dialog-title">{t("promoter.PromoterConfigs.retention.dialogTitle")}</DialogTitle>
                <DialogContent>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} key="description">
                      {t("promoter.PromoterConfigs.retention.dialogDescription")}
                    </Grid>

                    {formFields(t, canEdit).map((item, idx) => (
                      <Grid item xs={item.size} key={idx}>
                        {item.field}
                      </Grid>
                    ))}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(false);
                    }}
                    color="primary"
                  >
                    {t("promoter.PromoterConfigs.retention.dialogCancel")}
                  </Button>

                  <Button variant="contained" color="primary" type="submit" size="large" disabled={submitting}>
                    {t("promoter.PromoterConfigs.retention.dialogOk")}
                  </Button>
                </DialogActions>
              </>
            );
          }}
        />
      </Dialog>
    </div>
  );
}

export default withStyles(UpdateRetentionDialog, styles)

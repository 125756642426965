import { dateTimeFormatWithMilliSeconds } from "../../../../common/utils/formats";

export function listColumns(t, classes) {
  return [
    {
      field: "datetime",
      headerName: t("business.audit.login.columns_datetime"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.datetime));
      },
    },
    {
      field: "action",
      headerName: t("business.audit.login.columns_action"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (t("business.audit.filter.websocket_" + v.action));
      },
    },
    {
      field: "entity",
      headerName: t("business.audit.login.columns_entity"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "username",
      headerName: t("business.audit.login.columns_username"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "ipAddress",
      headerName: t("business.audit.login.columns_ipAddress"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "sessionId",
      headerName: t("business.audit.login.columns_sessionId"),
      align: "left",
      disableSort: true,
      headerAlign: "left",
    }
  ];
}

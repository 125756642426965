import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./PendingEntityDataGrid.styles";
import { listColumns } from "./PendingEntityDataGrid.columns";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import PendingEntityDataGridActions from "./PendingEntityDataGrid.actions";
import { useParams } from "react-router-dom";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";
import { filterFields, filterSchema } from "./PendingEntityDataGrid.filter";
import { pendingEntityListDefaultFilter } from "../../_common/entitiesFilters";

function PendingEntityDataGrid({
  classes,
  data,
  totalRows,
  filter,
  onFilterChange,
  onProductSelected,
  onAccept,
  onReject,
}) {
  const { t } = useTranslation();
  const columns = listColumns(t, classes);
  const { processId } = useParams();

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };

      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={columns}
        rows={data?.map((item) => ({
          ...item,
          className: processId && item.processId && item.processId === processId ? classes.hightLightRow : undefined,
        }))}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMoreHandler}
        total={totalRows}
        onRowSelected={onProductSelected}
        actionsComponent={<PendingEntityDataGridActions onAccept={onAccept} onReject={onReject} />}
        topActions={
          <FilterForm
            style={{ marginBottom: 8 }}
            storageKey="PendingEntityDataGrid"
            onStorageLoad={onFilterHandler}
            slim
            fields={filterFields()}
            schema={filterSchema()}
            onFilterChange={onFilterHandler}
            filter={filter}
            defaultFilter={pendingEntityListDefaultFilter}
          />
        }
      />
    </div>
  );
}

export default withStyles(PendingEntityDataGrid, styles);

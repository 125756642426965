import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Paper, Typography, Grid, IconButton, Tooltip } from "@mui/material";

import { styles } from "./EntityExternalAccess.styles";
import { TextInput } from "../../../../common/inputs";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import DeleteIcon from "@mui/icons-material/Delete";
import LockResetIcon from "@mui/icons-material/LockReset";
import { useUser } from "../../../../common/hooks";
import RevokeAccessConfirmDialog from "./RevokeAccessConfirmDialog/RevokeAccessConfirmDialog";
import ResetAccessKeyDialog from "./ResetAccessKeyDialog/ResetAccessKeyDialog";
import {
  promoterAddExternalAccess,
  promoterDisableExternalAccess,
  selectExternalAccessKey,
  userDefineExternalAccess,
  setExternalAccessKey,
} from "../../entitiesSlice";

function EntityExternalAccess({ classes, entityId, externalAccess }) {
  const { t } = useTranslation();
  const user = useUser();
  const [username, setUsername] = useState(externalAccess?.username);
  const [confirmRevokeDialog, setConfirmRevokeDialog] = useState(false);
  const [confirmResetDialog, setConfirmResetDialog] = useState(false);
  const dispatch = useDispatch();
  const externalAccessKey = useSelector(selectExternalAccessKey);

  const onCreateAccountHandler = () => {
    if (username?.length > 5) {
      dispatch(promoterAddExternalAccess({ entityId, username }));
    }
    if (!username) {
      setUsername("");
    }
  };

  const onDisableAccountHandler = () => {
    setConfirmRevokeDialog(true);
  };

  const onConfirmRevokeHandler = () => {
    dispatch(promoterDisableExternalAccess({ entityId, username }));
  };

  const onRefreshPasswordHandler = () => {
    setConfirmResetDialog(true);
  };

  const onResetAccessKeyHandler = () => {
    dispatch(userDefineExternalAccess({ entityId, username }));
  };

  return (
    <Paper className={classes.root} elevation={0} id="entityExternalAccess">
      <Typography variant="h6" align="left" component="span" color="textSecondary" className={classes.title}>
        {t("entities.EntityExternalAccess.form.title")}
      </Typography>
      <br />
      <br />
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item>
          <TextInput
            error={username?.length < 6}
            label={t("entities.EntityExternalAccess.form.username")}
            value={username || ""}
            onChange={(v) => setUsername(v.slice(0, 50))}
          />
        </Grid>
        <Grid item>
          <TextInput
            type="password"
            label={t("entities.EntityExternalAccess.form.password")}
            disabled
            value={externalAccess?.hasPassword ? "****************" : ""}
          />
        </Grid>
        <Grid item>
          {user?.roleType === "F" &&
            (user?.entity?.type === "P" ? (
              externalAccess?.username ? (
                <Tooltip title={t("entities.EntityExternalAccess.tooltip.revoke")}>
                  <IconButton id="revokeAccess" onClick={onDisableAccountHandler}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={t("entities.EntityExternalAccess.tooltip.create")}>
                  <IconButton id="createAccess" onClick={onCreateAccountHandler}>
                    <EnhancedEncryptionIcon />
                  </IconButton>
                </Tooltip>
              )
            ) : (
              <Tooltip title={t("entities.EntityExternalAccess.tooltip.reset")}>
                <IconButton id="resetAccess" onClick={onRefreshPasswordHandler}>
                  <LockResetIcon />
                </IconButton>
              </Tooltip>
            ))}
        </Grid>
      </Grid>
      <RevokeAccessConfirmDialog
        open={confirmRevokeDialog}
        setOpen={setConfirmRevokeDialog}
        onConfirm={onConfirmRevokeHandler}
      />
      <ResetAccessKeyDialog
        open={confirmResetDialog}
        setOpen={(v) => {
          setConfirmResetDialog(v);
          dispatch(setExternalAccessKey());
        }}
        externalAccessKey={externalAccessKey}
        onResetAccessKey={onResetAccessKeyHandler}
      />
    </Paper>
  );
}

export default withStyles(EntityExternalAccess, styles)

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper, Button } from "@mui/material";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

import { styles } from "./AuditJobDetails.styles";

import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { auditGetAuditJob, auditRetryJob, selectAuditJobDetails } from "../businessSlice";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import LabelValue from "../../../common/displays/LabelValue/LabelValue";
import { dateTimeFormatWithMilliSeconds } from "../../../common/utils/formats";
import { jsonTryParse } from "../../../common/utils";

function AuditJobDetails({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const details = useSelector(selectAuditJobDetails);

  useFirstLoad(() => {
    dispatch(auditGetAuditJob(id))
  });

  if (!details) {
    return null;
  }

  const onRetryJobHandler = () => {
    dispatch(auditRetryJob(details?.jobId));
  }

  const parsedRequest = jsonTryParse(details?.request);
  const parsedResponse = jsonTryParse(details?.response);

  return (
    <Page
      permission="business.actions.audit.get"
      header={
        <PageTitle
          title={details?.type + "/" + details?.subtype}
          onBack={() => navigate('/activity/audits/job')}
          actions={<Grid container spacing={1}>
            {!details?.status && <Grid item>
              <Button variant="contained" onClick={onRetryJobHandler}>{t("business.AuditJobDetails.header.retryJobBtn")}</Button>
            </Grid>}
          </Grid>}
        />
      }>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LabelValue
                  label={t("business.AuditJobDetails.details.jobDescription")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details?.jobDescription}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.AuditJobDetails.details.status")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details?.status ? t("business.AuditJobDetails.details.true") : t("business.AuditJobDetails.details.false")}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.AuditJobDetails.details.startTime")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {dateTimeFormatWithMilliSeconds(details?.startTime)}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.AuditJobDetails.details.endTime")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {dateTimeFormatWithMilliSeconds(details?.endTime)}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <LabelValue
                  label={t("business.AuditJobDetails.details.request")}
                  value={
                    typeof parsedRequest === "object" ? <div className={classes.json}>
                      <JSONInput
                        id="json-input"
                        placeholder={parsedRequest}
                        viewOnly
                        locale={locale}
                        confirmGood={false}
                        theme={"light_mitsuketa_tribute"}
                      />
                    </div> : parsedRequest
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <LabelValue
                  label={t("business.AuditJobDetails.details.response")}
                  value={
                    typeof parsedResponse === "object" ? <div className={classes.json}>
                      <JSONInput
                        id="json-input"
                        placeholder={parsedResponse}
                        viewOnly
                        locale={locale}
                        confirmGood={false}
                        theme={"light_mitsuketa_tribute"}
                      />
                    </div> : parsedResponse
                  }
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

export default withStyles(AuditJobDetails, styles);

import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { hash } from "../../../common/utils/crypto";

const userChangePasswordCall = async (
  dispatch,
  { password, newPassword }
) => {
  let rh = requestHelper(dispatch, "CHANGE_PASSWORD");
  try {
    let result = await call({
      type: "USER",
      subType: "CHANGE_PASSWORD",
      request: {
        password: hash(password).toString(),
        newPassword: hash(newPassword).toString()
      },
    });
    if (result) {
      dispatch(setSuccess("users", "CHANGE_PASSWORD"));
    }
  } catch (ex) {
    rh.error("users", ex);
  }
  rh.close();
};

export default userChangePasswordCall;


export const getPlaceTypeOptions = (t, promoter) => {
  let placeTypesAllowed = promoter?.getConfig("general", "placeTypes") || [];
  return placeTypesAllowed.map((item) => {
    return {
      value: item,
      text: t("common.Enums.placeTypes." + item),
    };
  });
}

export const getParentIdOptions = (t, parentPlaces) => {
  return (parentPlaces || []).map((item) => {
    return {
      value: item.id,
      text: t("common.Enums.placeTypes." + item.type) + " - " + item.title,
    };
  });
}

export const getContactIdOptions = (contacts) => {
  return (contacts || []).map((item) => {
    return {
      value: item.id,
      text: item.firstName + " " + item.lastName,
    };
  });
};

export const getAddressIdOptions = (addresses) => {
  return (addresses || []).map((item) => {
    return {
      value: item.id,
      text: item.description,
    };
  });
};

export const getTemperatureOptions = (t, promoter) => {
  return (promoter?.refData?.temperature || []).map((item) => {
    return {
      value: item,
      text: t("common.Enums.temperature." + item),
    };
  });
};
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

import { styles } from "./HierarchyNodeDetails.styles";
import LabelValue from "../../../../../common/displays/LabelValue/LabelValue";

function HierarchyNodeDetails({ classes, node, oldNode, showDiff }) {
  const { t } = useTranslation();

  return node ? (
    <Grid container className={classes.root} spacing={1}>
      <Grid item xs={12}>
        <LabelValue
          label={t("products.ProductDetails.hierarchyDetails.description")}
          value={node?.description}
          valueClassName={showDiff && node?.description !== oldNode?.description && classes.newValue}
        />
      </Grid>
      <Grid item xs={6}>
        <LabelValue
          label={t("products.ProductDetails.hierarchyDetails.gtin")}
          value={node?.gtin}
          valueClassName={showDiff && node?.gtin !== oldNode?.gtin && classes.newValue}
        />
      </Grid>
      <Grid item xs={6}>
        <LabelValue
          label={t("products.ProductDetails.hierarchyDetails.unitDescriptor")}
          value={t("common.Enums.transport." + node?.unitDescriptor)}
          valueClassName={showDiff && node?.unitDescriptor !== oldNode?.unitDescriptor && classes.newValue}
        />
      </Grid>
      {
        node?.level > 0 && <>
          <Grid item xs={6}>
            <LabelValue
              label={t("products.ProductDetails.hierarchyDetails.childQuantity")}
              value={node?.child?.childQuantity}
              valueClassName={showDiff && node?.child?.childQuantity !== oldNode?.child?.childQuantity && classes.newValue}
            />
          </Grid>
          <Grid item xs={6}>
            <LabelValue
              label={t("products.ProductDetails.hierarchyDetails.childUnitDescriptor")}
              value={t("common.Enums.transport." + node?.child?.childUnitDescriptor)}
              valueClassName={showDiff && node?.child?.childUnitDescriptor !== oldNode?.child?.childUnitDescriptor && classes.newValue}
            />
          </Grid>
          <Grid item xs={6}>
            <LabelValue
              label={t("products.ProductDetails.details.quantityOfLayers")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {node?.quantityOfLayers || "-"}
                </Typography>
              }
              valueClassName={showDiff && node?.quantityOfLayers !== oldNode?.quantityOfLayers && classes.newValue}
            />
          </Grid>
          <Grid item xs={6}>
            <LabelValue
              label={t("products.ProductDetails.details.quantityOfItemsByLayer")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {node?.quantityOfItemsByLayer || "-"}
                </Typography>
              }
              valueClassName={showDiff && node?.quantityOfItemsByLayer !== oldNode?.quantityOfItemsByLayer && classes.newValue}
            />
          </Grid>
          <Grid item xs={4}>
            <LabelValue
              label={t("products.ProductDetails.details.height")}
              value={
                node?.height ? (
                  <Typography variant="body1" align="left" component="span">
                    {unitLabel(t, node?.hasVariableDimensions, node?.height)}
                  </Typography>
                ) : (
                  "N/D"
                )
              }
              valueClassName={showDiff && (node?.height?.value !== oldNode?.height?.value || node?.node?.height?.valueMax !== oldNode?.node?.height?.valueMax) && classes.newValue}
            />
          </Grid>
          <Grid item xs={4}>
            <LabelValue
              label={t("products.ProductDetails.details.width")}
              value={
                node?.width ? (
                  <Typography variant="body1" align="left" component="span">
                    {unitLabel(t, node?.hasVariableDimensions, node?.width)}
                  </Typography>
                ) : (
                  "N/D"
                )
              }
              valueClassName={showDiff && (node?.width?.value !== oldNode?.width?.value || node?.node?.width?.valueMax !== oldNode?.node?.width?.valueMax) && classes.newValue}
            />
          </Grid>
          <Grid item xs={4}>
            <LabelValue
              label={t("products.ProductDetails.details.depth")}
              value={
                node?.depth ? (
                  <Typography variant="body1" align="left" component="span">
                    {unitLabel(t, node?.hasVariableDimensions, node?.depth)}
                  </Typography>
                ) : (
                  "N/D"
                )
              }
              valueClassName={showDiff && (node?.depth?.value !== oldNode?.depth?.value || node?.node?.depth?.valueMax !== oldNode?.node?.depth?.valueMax) && classes.newValue}
            />
          </Grid>
          <Grid item xs={4}>
            <LabelValue
              label={t("products.ProductDetails.details.netWeight")}
              value={
                node?.netWeight ? (
                  <Typography variant="body1" align="left" component="span">
                    {unitLabel(t, node?.hasVariableDimensions, node?.netWeight)}
                  </Typography>
                ) : (
                  "N/D"
                )
              }
              valueClassName={showDiff && (node?.netWeight?.value !== oldNode?.netWeight?.value || node?.node?.netWeight?.valueMax !== oldNode?.node?.netWeight?.valueMax) && classes.newValue}
            />
          </Grid>
          <Grid item xs={4}>
            <LabelValue
              label={t("products.ProductDetails.details.grossWeight")}
              value={
                node?.grossWeight ? (
                  <Typography variant="body1" align="left" component="span">
                    {unitLabel(t, node?.hasVariableDimensions, node?.grossWeight)}
                  </Typography>
                ) : (
                  "N/D"
                )
              }
              valueClassName={showDiff && (node?.grossWeight?.value !== oldNode?.grossWeight?.value || node?.node?.grossWeight?.valueMax !== oldNode?.node?.grossWeight?.valueMax) && classes.newValue}
            />
          </Grid>
          <Grid item xs={4}>
            <LabelValue
              label={t("products.ProductDetails.details.netContent")}
              value={
                node?.netContent ? (
                  <Typography variant="body1" align="left" component="span">
                    {unitLabel(t, node?.hasVariableDimensions, node?.netContent)}
                  </Typography>
                ) : (
                  "N/D"
                )
              }
              valueClassName={showDiff && (node?.netContent?.value !== oldNode?.netContent?.value || node?.node?.netContent?.valueMax !== oldNode?.node?.netContent?.valueMax) && classes.newValue}
            />
          </Grid>
        </>
      }
    </Grid>
  ) : null;
}

export default withStyles(HierarchyNodeDetails, styles);

const unitLabel = (t, hasVariableDimensions, measure) => {
  if (!measure) {
    return "N/D"
  }

  const { value, valueMax, unit } = measure;
  let label = value;
  if (hasVariableDimensions) {
    label += ` ${t("products.ProductDetails.details.to")} ${valueMax}`
  }
  if (!unit) {
    return label;
  }
  return `${label} ${t("common.Enums.measureUnits." + unit)}`;
}
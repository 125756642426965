import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const planningDeleteCall = async (
  { planningList },
  dispatch,
  { id, year, productId }
) => {
  let rh = requestHelper(dispatch, "PLANNING_DELETE");
  try {
    let result = await call({
      type: "PLANNING",
      subType: "DELETE",
      request: {
        id
      },
    });
    if (result) {
      dispatch(planningList({ year, productId }));
      dispatch(setSuccess("planning", "PLANNING_DELETE"));
    }

  } catch (ex) {
    rh.error("planning", ex);
  }
  rh.close();
};

export default planningDeleteCall;


export const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
  },
  title: {
    margin: 0,
  },
  type: {
    margin: 0,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: "1px solid #D2D9E4",
    width: "100%"
  },
  noMargin: {
    margin: 0,
  },
  totalLabel: {
    margin: 0,
    width: 100,
    textAlign: "right",
  },
  totalbox: {
    width: 200
  },
  paper: {
    display: "flex",
    padding: theme.spacing(2),
  },
});
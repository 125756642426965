import React from "react";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";
import { Typography, Grid, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { styles } from "./AlertItem.styles";
import { dateTimeFormat } from "../../../../common/utils";

function AlertItem({ classes, alert, onClick, onResolve }) {
  const { t } = useTranslation();

  return alert ? (
    <div className={classes.root}>
      <Grid container direction="column" onClick={() => onClick && onClick(alert)}>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography className={classes.nomargin} variant="h4">
                {t("alerts.AlertsList.title." + alert.type)}
              </Typography>
            </Grid>
            <Grid item>
              {alert.resolved ? (
                <Tooltip
                  title={t("alerts.AlertsList.tooltip.resolved", {
                    resolvedBy: alert.resolvedBy,
                    resolvedDate: dateTimeFormat(alert.resolvedDate),
                  })}
                  placement="top"
                >
                  <CheckCircleOutlineIcon className={classes.resolve} />
                </Tooltip>
              ) : (
                <Tooltip title={t("alerts.AlertsList.tooltip.resolve")} placement="top">
                  <CheckCircleIcon
                    color="primary"
                    className={classes.resolve}
                    onClick={(e) => {
                      e.stopPropagation();
                      onResolve && onResolve(alert);
                    }}
                  />
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography className={classes.description} variant="body1">
            {t("alerts.AlertsList.description." + alert.type + "_" + alert.subType, alert?.payload)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" color="textSecondary" className={classes.date}>
            {dateTimeFormat(alert.date)}
          </Typography>
        </Grid>
      </Grid>
    </div>
  ) : null;
}

export default withStyles(AlertItem, styles)

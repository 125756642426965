import { Grid, List, ListItem } from "@mui/material";
import IconWithTooltip from "../../../../common/components/displays/IconWithTooltip";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ContactCardSlim from "../../_common/ContactCardSlim/ContactCardSlim";

export function listColumns(t, contacts, classes) {
  return [
    {
      field: "groupName",
      headerName: t("entities.EntitySectorGroupsList.columns.groupName"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "sector",
      headerName: t("entities.EntitySectorGroupsList.columns.sector"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return t("common.Enums.sector." + v.sector);
      },
    },
    {
      field: "header",
      headerName: t("entities.EntitySectorGroupsList.columns.header"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return <ContactCardSlim contact={(contacts || []).find((item) => item?.id === v.header)} />;
      },
    },
    {
      field: "users",
      headerName: t("entities.EntitySectorGroupsList.columns.users"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <IconWithTooltip
            icon={
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.details}
              >
                <Grid item>
                  <AccountCircleIcon fontSize="small" style={{ display: "block", marginRight: 4 }} />
                </Grid>
                <Grid item>{v.users?.length + 1}</Grid>
              </Grid>
            }
          >
            <List>
              {[v.header, ...v.users].map((user) => {
                let contact = contacts.find((item) => item.id === user);
                return (
                  <ListItem key={`user_${user}`}>
                    <ContactCardSlim contact={contact} star={Boolean(user === v.header)} />
                  </ListItem>
                );
              })}
            </List>
          </IconWithTooltip>
        );
      },
    },
  ];
}

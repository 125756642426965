import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const placeGetCall = async (
  { setPlaceDetails },
  dispatch,
  { id }
) => {
  let rh = requestHelper(dispatch, "PLACE_GET");
  try {
    let result = await call({
      type: "PLACE",
      subType: "GET",
      request: {
        id
      },
    });

    setTimeout(() => {
      if (result && result.place) {
        dispatch(setPlaceDetails(processPlace(result.place)));
      }
    }, 300);

  } catch (ex) {
    rh.error("places", ex);
  }
  rh.close();
};

export const processPlace = (data) => {
  let place = { ...data };

  if (data && data?.geoCoordinates) {
    place = {
      ...place,
      latitude: data?.geoCoordinates?.latitude,
      longitude: data?.geoCoordinates?.longitude,
    };
  }

  return place;
};


export default placeGetCall;


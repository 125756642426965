import { Tooltip } from "@mui/material";
import * as Yup from "yup";
import {
  CheckboxField,
  DatePickerField,
  // NumberField,
  TextField,
} from "../../../common/forms";
import OrderProductsDataGridField from "../_common/fields/OrderProductsDataGridField/OrderProductsDataGridField";
import Volumes from "../_common/fields/Volumes/Volumes";
import OperatorOrderVolumesDataGridField from "./OperatorOrderVolumesDataGridField/OperatorOrderVolumesDataGridField";

export function formSchema(t, details, selectedStatus) {
  return Yup.object().shape({
    document: Yup.string().required(t("orders.OperatorOrderDetails.form.documentRequired")),
    documentDate: Yup.string().required(t("orders.OperatorOrderDetails.form.documentDateRequired")),
    volumes:
      details.operationType === "CD" && (selectedStatus === "DI" || selectedStatus === "RE")
        ? Yup.array()
            .ensure()
            .requiredArrayWithCheck(t("orders.OperatorOrderDetails.form.requiredVolumes"))
        : Yup.array(),
    products:
      details.operationType === "TF" && selectedStatus === "IT"
        ? Yup.array().of(
            Yup.object().shape({
              productBatch: Yup.array()
                .ensure()
                .of(
                  Yup.object().shape({
                    batch: Yup.string().when(["hasBatchNumber"], {
                      is: (hasBatchNumber) => {
                        return hasBatchNumber;
                      },
                      then: (schema) => schema.required(t("orders.OperatorOrderDetails.form.requiredBatchNumber")),
                      otherwise: (schema) => schema,
                    }),
                    expirationDate: Yup.string().when(["hasExpirationDate"], {
                      is: (hasExpirationDate) => {
                        return hasExpirationDate;
                      },
                      then: (schema) => schema.required(t("orders.OperatorOrderDetails.form.requiredBatchExpire")),
                      otherwise: (schema) => schema,
                    }),
                    weight: Yup.string().when(["hasVariableWeight"], {
                      is: (hasVariableWeight) => {
                        return hasVariableWeight;
                      },
                      then: (schema) => schema.required(t("orders.OperatorOrderDetails.form.requiredBatchWeight")),
                      otherwise: (schema) => schema,
                    }),
                  })
                )
                .when(["hasBatchNumber", "hasExpirationDate", "hasVariableWeight"], {
                  is: (hasBatchNumber, hasExpirationDate, hasVariableWeight) => {
                    return hasBatchNumber || hasExpirationDate || hasVariableWeight;
                  },
                  then: (schema) => schema.min(1, t("orders.OperatorOrderDetails.form.requiredBatch")),
                  otherwise: (schema) => schema,
                }),
            })
          )
        : Yup.array(),
  });
}

export function formFields(t, classes, details, selectedStatus, products) {
  return [
    {
      size: 4,
      field: <TextField id="document" type="text" label={t("orders.OperatorOrderDetails.form.document")} required />,
      hidden: ["DI", "RE", "IT"].indexOf(selectedStatus) === -1,
    },
    {
      size: 4,
      field: <DatePickerField id="documentDate" label={t("orders.OperatorOrderDetails.form.documentDate")} required />,
      hidden: ["DI", "RE", "IT"].indexOf(selectedStatus) === -1,
    },
    {
      size: 4,
      field: (
        <Tooltip title={t("orders.OperatorOrderDetails.form.finishedTooltip")} placement="left">
          <div className={classes.finished}>
            <CheckboxField id="finished" label={t("orders.OperatorOrderDetails.form.finished")} />
          </div>
        </Tooltip>
      ),
      hidden: ["DI", "RE", "IT"].indexOf(selectedStatus) === -1,
    },
    // {
    //   size: 4,
    //   field: <NumberField id="totalPallets.true" decimalScale={0} label={t("orders.OperatorOrderDetails.form.totalPalletsFull")} />,
    //   hidden: details.operationType === "CD" && selectedStatus !== "IT",
    // },
    // {
    //   size: 4,
    //   field: <NumberField id="totalPallets.false" decimalScale={0} label={t("orders.OperatorOrderDetails.form.totalPalletsNotFull")} />,
    //   hidden: details.operationType === "CD" && selectedStatus !== "IT",
    // },
    {
      size: 12,
      field: <Volumes id="volumes" label="&nbsp;" products={products} />,
      hidden: details.operationType === "CD" || selectedStatus !== "IT",
    },
    {
      size: 12,
      field: (
        <OrderProductsDataGridField
          id="products"
          allowBatching={details?.operationType === "TF" && selectedStatus === "IT"}
          disabledQuantity={selectedStatus === "DE"}
          allowExport
        />
      ),
      hidden: details.operationType === "CD",
    },
    {
      size: 12,
      field: (
        <OperatorOrderVolumesDataGridField
          id="volumes"
          hideCheckbox={["DI", "RE", "IT"].indexOf(selectedStatus) === -1}
        />
      ),
      hidden: details.operationType === "TF",
    },
  ];
}

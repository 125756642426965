import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const productUpdateFamilyCall = async ({ productListFamily }, dispatch, state, { id, description, type, parent }) => {
  let filter = state.productsFamilies.familyListFilter;
  let rh = requestHelper(dispatch, "UPDATE_FAMILY");
  try {
    await call({
      type: "PRODUCT",
      subType: "UPDATE_FAMILY",
      request: {
        id,
        description: JSON.stringify(description),
        type,
        parent,
      },
    });
    setTimeout(() => {
      dispatch(productListFamily(filter, true));
      dispatch(setSuccess("productsFamilies", "PRODUCT_UPDATE_FAMILY"));
    }, 300);
  } catch (ex) {
    rh.error("productsFamilies", ex);
  }
  rh.close();
};

export default productUpdateFamilyCall;
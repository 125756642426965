import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Button, Grid} from "@mui/material";

import {styles} from "./TimerList.styles";
import TimerDataGrid from "./TimerDataGrid/TimerDataGrid";

import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {useNavigate} from "../../../common/hooks";
import Page from "../../../common/displays/Page/Page";
import {promoterListTimer, selectTimerFilter, selectTimerList, selectTimerListTotalRows} from "../promoterSlice";
import Permission from "../../../app/components/Permission";


function TimerList({ classes, defaultAudit }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const timerFilter = useSelector(selectTimerFilter);
  const timerList = useSelector(selectTimerList);
  const timerListTotalRows = useSelector(selectTimerListTotalRows);

  return (
    <Page
      permission="promoter.actions.timer.list"
      header={<PageTitle
          title={t("promoter.timer.header.title")}
          actions={
              <Grid container direction="row" spacing={2}>
                  <Permission code="promoter.actions.timer.create">
                      <Grid item>
                          <Button variant="text" color="primary" size="large" onClick={() => navigate("/promoter/timer/add")}>
                              {t("promoter.timer.header.addBtn")}
                          </Button>
                      </Grid>
                  </Permission>
              </Grid>
          }
      />}    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
            <TimerDataGrid
              data={timerList}
              totalRows={timerListTotalRows}
              filter={timerFilter}
              onFilterChange={({ filter, reset }) => dispatch(promoterListTimer(filter, reset))}
              onRowSelected={({ id }) => navigate('/promoter/timer/' + id)}
            />
        </Grid>
      </Grid>
    </Page>
  );
}

export default withStyles(TimerList, styles);

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./CatalogProductsDataGrid.styles";
import { listColumns } from "./CatalogProductsDataGrid.columns";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

function CatalogProductsDataGrid({ classes, products }) {
  const { t } = useTranslation();
  const columns = listColumns(t, classes);
  return (
    <div className={classes.root}>
      {products && (
        <CustomDataGrid
          localFilters
          columns={columns}
          rows={products}
          total={products.length}
        />
      )}
    </div>
  );
}

export default withStyles(CatalogProductsDataGrid, styles)
import React, { useState, useEffect, useMemo } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Box, CircularProgress } from "@mui/material";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { styles } from "./ProductHierarchyField.styles";
import { formFields } from "./ProductHierarchyField.schema";

import { FormFields } from "../../../../common/forms";
import HierarchyTree from "../../_common/HierarchyTree/HierarchyTree";
import { usePromoter, useUser } from "../../../../common/hooks";
import productGetBaseHierarchy from "../../_directCalls/productGetBaseHierachy";

const defaultMeasures = {
  height: { category: "COMP", value: 0, unit: "CMT", valueMax: 0 },
  width: { category: "COMP", value: 0, unit: "CMT", valueMax: 0 },
  depth: { category: "COMP", value: 0, unit: "CMT", valueMax: 0 },
  netWeight: { category: "MASS", value: 0, unit: "GRM", valueMax: 0 },
  grossWeight: { category: "MASS", value: 0, unit: "GRM", valueMax: 0 },
  netContent: { category: "MASS", value: 0, unit: "GRM", valueMax: 0 },
}

function ProductHierarchyField({ classes, id, values, showGlnAndGtin, disabled, form }) {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const promoter = usePromoter();
  const user = useUser();
  const [errorCreate, setErrorCreate] = useState();

  const productThumbnail = values?.media?.[0]?.thumbnail || values?.media?.[0]?.base64;
  const { gtin, supplierInternalCode, description } = values?.baseInfo || {};

  const toplevel = useMemo(() => {
    let arr = (values?.hierarchy || []);
    if (arr?.length > 0) {
      return arr.reduce((max, obj) => (obj.level > max ? obj.level : max), arr[0].level)
    }
    return 0;
  }, [values.hierarchy]);

  useEffect(() => {
    let hierarchyRoot = values?.hierarchy?.[0] ? { ...values?.hierarchy?.[0] } : null;
    if (hierarchyRoot) {
      if (hierarchyRoot.unitDescriptor !== values?.units?.unitDescriptor) {
        hierarchyRoot.unitDescriptor = values?.units?.unitDescriptor;
        form.change('hierarchy[0]', hierarchyRoot);
      }
      if (hierarchyRoot.description !== values?.baseInfo?.description) {
        hierarchyRoot.description = values?.baseInfo?.description;
        form.change('hierarchy[0]', hierarchyRoot);
      }
      if (!showGlnAndGtin && hierarchyRoot.gtin !== values?.baseInfo?.supplierInternalCode) {
        hierarchyRoot.gtin = values?.baseInfo?.supplierInternalCode;
        form.change('hierarchy[0]', hierarchyRoot);
      }
      if (hierarchyRoot.thumbnail !== (values?.media?.[0]?.thumbnail || values?.media?.[0]?.base64)) {
        hierarchyRoot.thumbnail = values?.media?.[0]?.thumbnail || values?.media?.[0]?.base64;
        form.change('hierarchy[0]', hierarchyRoot);
      }
    }
    // eslint-disable-next-line
  }, [values?.units?.unitDescriptor, values?.baseInfo?.description, values?.baseInfo?.supplierInternalCode, values?.media?.[0]])

  const onNodeSelectHandler = (node, input) => {
    if (node) {
      let index = input.value.findIndex((item) => item?.gtin === node?.gtin);
      setSelectedIndex(index);
    } else {
      setSelectedIndex(null);
    }
  };

  const onAddNodeHandler = (node, input) => {
    if (disabled) {
      return;
    }
    let nValue = [...(input.value || [])];
    if (node) {
      // New node
      nValue.push({
        ...node,
        add: undefined,
        description: description,
        gtin: ".".repeat(node.level + 1), // gtin.slice(0, 11) + node.level,
        unitDescriptor: "CASE",
        ...defaultMeasures
      });
    } else {
      // Root node
      if (((showGlnAndGtin && gtin) || (!showGlnAndGtin && supplierInternalCode)) && description) {
        setErrorCreate();
        nValue.push({
          description: description,
          gtin: showGlnAndGtin ? gtin : supplierInternalCode,
          unitDescriptor: values?.units?.unitDescriptor,
          level: 0,
          thumbnail: productThumbnail,
        });
      }
      else {
        setErrorCreate(showGlnAndGtin ? t("products.ProductHierarchy.form.createErrorGtin") : t("products.ProductHierarchy.form.createErrorInternalCode"));
      }
    }
    input.onChange && input.onChange(nValue);
  };

  const onGtinChangeHandler = (oldGtin, newGtin, fields) => {
    (fields?.value || []).forEach((item, index) => {
      if (item?.child?.childGtin && item?.child?.childGtin === oldGtin) {
        fields.update(index, { ...item, child: { ...item.child, childGtin: newGtin } });
      }
    });
  };

  const onGS1CheckHandler = async (itemValue, index, fields) => {
    let result = await productGetBaseHierarchy(itemValue.gtin, values?.baseInfo?.gln);
    fields.update(index, { ...itemValue, ...result });
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1} wrap="nowrap">
        <Grid item xs={5}>
          <Field name={id}>
            {({ input, meta }) => {
              return (
                <HierarchyTree
                  hierarchy={(input.value || []).map((item, index) => ({
                    ...item,
                    hasErrors: Boolean(meta.error?.[index]),
                  }))}
                  onSelect={(node) => onNodeSelectHandler(node, input)}
                  productThumbnail={productThumbnail}
                  onAddNode={disabled ? undefined : (node) => onAddNodeHandler(node, input)}
                  error={errorCreate}
                />
              );
            }}
          </Field>
        </Grid>
        <Grid item xs={7}>
          {disabled ? <Box className={classes.loader}><CircularProgress /></Box> :
            <FieldArray name={id}>
              {({ fields, meta }) => {
                return (
                  <Grid container direction="column" spacing={1}>
                    {(fields || []).map((field, index) => {
                      let itemValue = fields.value[index];

                      return (
                        <Grid
                          item
                          key={field + index}
                          style={{ display: selectedIndex !== null && selectedIndex === index ? "block" : "none" }}
                        >
                          <Grid container direction="column" spacing={3}>
                            <Grid item>
                              <FormFields
                                fields={formFields({
                                  item: field,
                                  t,
                                  values,
                                  promoter,
                                  classes,
                                  itemValue,
                                  user,
                                  onGtinChange: (v) => onGtinChangeHandler(itemValue.gtin, v, fields, user),
                                  showGlnAndGtin,
                                  onGS1Check: () => onGS1CheckHandler(itemValue, index, fields),
                                })}
                              />
                            </Grid>
                            {itemValue.level === toplevel && (
                              <Grid item>
                                {console.log('itemValue: %o', itemValue)}
                                <Button
                                  variant="contained"
                                  color="error"
                                  size="small"
                                  onClick={() => fields.remove(index)}
                                  style={{ marginLeft: "auto", display: "block" }}
                                >
                                  {t("products.ProductHierarchy.form.removeNode")}
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              }}
            </FieldArray>}
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(ProductHierarchyField, styles);

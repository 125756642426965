import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess, setRedirect } from "../../../app/coreSlice";

const businessUnitCreateStepTaskCall = async (
  dispatch,
  {
    businessUnitId,
    lineId,
    internal,
    businessUnitStepId,
    placeId,
    name,
    type,
    users,
    groups,
    sectors,
    input,
    info,
    schedule,
  }
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_CREATE_TASK");
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "CREATE_TASK",
      request: {
        businessUnitId,
        lineId,
        businessUnitStepId,
        placeId,
        name,
        type,
        users,
        groups,
        sectors,
        input,
        info,
        schedule,
      },
    });

    dispatch(setSuccess("businessUnits", "BUSINESS_UNIT_CREATE_TASK"));
    setTimeout(() => {
      if (result) {
        if (placeId) {
          dispatch(setRedirect(`/business/units/${businessUnitId}/placetasks`));
        }
        else if (internal) {
          dispatch(setRedirect(`/business/units/${businessUnitId}/${lineId}/steps/internal`));
        }
        else {
          dispatch(setRedirect(`/business/units/${businessUnitId}/${lineId}/steps`));
        }
      }
    }, 300);

  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitCreateStepTaskCall;


import {Typography} from "@mui/material";
import moment from "moment";

export function listColumns(t, classes) {
  return [
    {
      field: "entityName",
      headerName: t("entities.CutOffs.columns.entityName"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "active",
      headerName: t("entities.CutOffs.columns.active"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v.active ? (
          <Typography className={classes.statusActive}>{t("entities.CutOffs.active.true")}</Typography>
        ) : (
          <Typography className={classes.statusDisabled}>{t("entities.CutOffs.active.false")}</Typography>
        );
      },
    },
    {
      field: "lastCutOff",
      headerName: t("entities.CutOffs.columns.lastCutOff"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v.lastCutOff !== undefined ? moment(v.lastCutOff).format('yyyy-MM-DD HH:mm') : '-'
      }
    },
    {
      field: "nextCutOff",
      headerName: t("entities.CutOffs.columns.nextCutOff"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v.nextCutOff !== undefined ? moment(v.nextCutOff).format('yyyy-MM-DD HH:mm') : '-'
      }
    },
    {
      field: "cutOffType",
      headerName: t("entities.CutOffs.columns.cutOffType"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.cutOffType !== undefined ? t("entities.CutOffs.dateType." + v.cutOffType) : '-'
      }
    },
  ];
}

import React from "react";
import { Route, Routes } from "react-router-dom";
import RolesForm from "./RolesForm/RolesForm";
import Security from "../../app/components/Security/Security";
import RolesList from "./RolesList/RolesList";

function RolesRouting() {
  return (
    <Routes>
      <Route path="create" element={
        <RolesForm />
      } />
      <Route path="create/:entityId" element={
        <Security component={<RolesForm />} paramValidation={[{ name: "entityId", type: "uuid" }]} />
      } />
      <Route path="edit/:id" element={
        <Security component={<RolesForm />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path=":entityId" element={
        <Security component={<RolesList />} paramValidation={[{ name: "entityId", type: "uuid" }]} />
      } />

      <Route path="/" element={<RolesList />} />
    </Routes>
  );
}

export default RolesRouting;


import * as Yup from "yup";
import { DatePickerField, EntityAutoCompleteField, MultiSelectField, NumberWithSelectField, SelectField, TextField } from "../../../../common/forms";
import { ProductPickerField } from "../../../../common/components/formFields";
import { getDpromValueOptions, getDpromsTypesOptions } from "./DPromForm.options";
import ScrollBox from "../../../../common/displays/ScrollBox/ScrollBox";
import moment from "moment";
import { getFamiliesOptions } from "../../../products/ProductForm/ProductForm.options";
import i18next from "i18next";

export const formSchema = ({ t }) => {
  return Yup.object().shape({
    ownerId: Yup.string().required(t('productsDproms.DPromForm.form.ownerIdRequired')),
    name: Yup.string().required(t('productsDproms.DPromForm.form.nameRequired')),
    type: Yup.string().required(t('productsDproms.DPromForm.form.typeRequired')),
    value: Yup.object().shape({
      amount: Yup.string().when("type",
        {
          is: "P",
          then: schema => schema.not(["0"], t("productsDproms.DPromForm.form.valueRequired")).required(t('productsDproms.DPromForm.form.valueRequired'))
            .test(
              "maxPercentage",
              t('productsDproms.DPromForm.form.valueMax'),
              (value) => parseFloat(value) <= 100
            ),
          otherwise: schema => schema.not(["0"], t("productsDproms.DPromForm.form.valueRequired")).required(t('productsDproms.DPromForm.form.valueRequired')),
        }),
    }),
    startDate: Yup.date().nullable()
      .required(t('productsDproms.DPromForm.form.startDateRequired')),
    endDate: Yup.date().nullable()
      .test(
        'minEndTest',
        t('productsDproms.DPromForm.form.endDateMin'),
        (value, context) => !value || moment(value).isSameOrAfter(moment(context?.parent?.startDate))
      ),
  });
}

export const formFields = ({ t, form, values, promoter, families, user, locations }) => {
  return [
    {
      size: 12,
      field: (
        <EntityAutoCompleteField
          typeFilter={promoter?.getConfig("product", "allowedEntityTypes")}
          id="ownerId"
          label={t("productsDproms.DPromForm.form.ownerId")}
          required
          onChange={(owner) => form.change('products', [])}
        />
      ),
      hidden: user?.entity?.type !== "P",
    },
    {
      size: 12,
      field: <TextField
        id="name"
        required
        label={t("productsDproms.DPromForm.form.name")}
      />,
    },
    {
      size: 6,
      field: <SelectField
        id="type"
        required
        label={t("productsDproms.DPromForm.form.type")}
        options={getDpromsTypesOptions(t, promoter)}
      />,
    },
    {
      size: 6,
      field: <NumberWithSelectField
        id="value"
        required
        label={t("productsDproms.DPromForm.form.value")}
        textKey="amount"
        selectKey="type"
        options={getDpromValueOptions()}
        decimalScale={2}
      />
    },
    {
      size: 6,
      field: <DatePickerField
        id="startDate"
        label={t("productsDproms.DPromForm.form.startDate")}
        required
      />,
    },
    {
      size: 6,
      field: <DatePickerField
        id="endDate"
        label={t("productsDproms.DPromForm.form.endDate")}
        min={values.endDate}
      />,
    },
    {
      size: 12,
      field: <MultiSelectField
        id="locations"
        label={t("productsDproms.DPromForm.form.locations")}
        options={(locations || []).map(({ id, name }) => ({ value: id, text: name }))}
      />,
    },
    {
      size: 12,
      field: <MultiSelectField
        allowEmpty
        clearLabel={t("productsDproms.DPromForm.form.familyClear")}
        id="family"
        label={t("productsDproms.DPromForm.form.family")}
        options={getFamiliesOptions(t, families)}
        renderValueText={(item) => {
          const id = item?.value;
          let selectedFamily = families.find(item => item.id.toString() === (id || "").toString());
          if (selectedFamily) {
            let selectedParent = families.find(item => item.id === selectedFamily.parent);
            let altLang = Object.keys(selectedFamily.description).filter((k) => k !== i18next.language)[0];
            let family = selectedFamily.description[i18next.language] || selectedFamily.description[altLang];
            let parent = selectedParent?.description ? (selectedParent.description[i18next.language] || selectedParent.description[altLang]) : "";
            return `${parent} - ${family}`
          }
          return "";
        }}
        disabled={values?.products?.length > 0}
      />,
    },
    {
      size: 12,
      field: (
        <ScrollBox style={{ height: 155 }}>
          <ProductPickerField
            ownerId={values?.ownerId}
            id="products"
            label={t("productsDproms.DPromForm.form.products")}
            simple
            disabled={values?.family || !Boolean(values?.ownerId)}
            customFilters={{ justConsumer: false }}
          />
        </ScrollBox>
      ),
    },
  ];
}


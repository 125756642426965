import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import productSaveFilesCall from "./productSaveFilesCall";
import { processHierarchyToSave, processWeightMeasuresToSave } from "./_auxiliars";

const productCreateCall = async (dispatch, state, product) => {
  let rh = requestHelper(dispatch, "PRODUCT_CREATE");

  try {
    let result = await call({
      type: "PRODUCT",
      subType: "CREATE",
      request: {
        product: {
          ...product,
          hierarchy: undefined,
          logo: undefined,
          media: undefined,
          documents: undefined,
          weightMeasure: processWeightMeasuresToSave(product?.weightMeasure),
        },
      },
    });

    let { media } = await productSaveFilesCall(dispatch, state, {
      productId: result.productId,
      media: product.media,
      documents: product.documents
    });

    if (product?.hierarchy && result?.productId && product?.hierarchy.length > 0) {
      await call({
        type: "PRODUCT",
        subType: "DEFINE_HIERARCHY",
        request: {
          id: result.productId,
          hierarchy: processHierarchyToSave(product?.hierarchy, media?.[0]),
        },
      });
    }

    setTimeout(() => {
      if (result.productAutoAccepted) {
        dispatch(setRedirect("/products"));
        dispatch(setSuccess("products", "PRODUCT_CREATE"));
      } else {
        dispatch(setRedirect("/products/validation"));
        dispatch(setSuccess("products", "PRODUCT_CREATE_PENDING"));
      }
    }, 300);
  } catch (ex) {
    rh.error("products", ex);
  }
  setTimeout(() => {
    rh.close();
  }, 350);
};

export default productCreateCall;

export const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2)
  },
  soap: {
    fontSize: 11,
    lineHeight: 1.3
  },
  json: {
    "& #json-input-outer-box": {
      width: "calc((100vw / 2) - 140px) !important",
      height: "calc(100vh - 370px) !important",
    },
    "& #json-input-container": {
      width: "calc((100vw / 2) - 140px) !important",
      height: "calc(100vh - 370px) !important",
    },
    "& #json-input": {
      height: "100% !important",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "5px",
        boxShadow: "inset 0 0 15px #009bcc60",
      },
    },
  },
});
// extractPermissionValue checks if the user has the required permissions.
// If code is an array, it checks if any of the permissions in the array exist.
// Otherwise, it retrieves the permission value from the user permissions object.
export const extractPermissionValue = (userPermissions, code) => {
  try {
    if (Array.isArray(code)) {
      return code.some(permission => getPermissionValue(userPermissions, permission));
    } else {
      return getPermissionValue(userPermissions, code);
    }
  } catch (ex) {
    return false;
  }
}

// getPermissionValue navigates through the permissions object using the path
// provided and returns the value at the end of the path or false if not found.
const getPermissionValue = (permissions, path) => {
  const pathParts = path.split(".");
  let current = permissions;

  for (const part of pathParts) {
    if (current.hasOwnProperty(part)) {
      current = current[part];
    } else {
      return false;
    }
  }

  return current;
};


export const extractPromoterConfig = (promoter, feature, key) => {
  // get from root
  let config = key ? promoter?.configs?.[feature]?.[key] : promoter?.configs?.[feature];
  // if not - get from technical
  if (config === undefined) {
    config = key ? promoter?.configs?.technical?.[feature]?.[key] : promoter?.configs?.technical?.[feature];
  }
  // if not - get from functional
  if (config === undefined) {
    config = key ? promoter?.configs?.functional?.[feature]?.[key] : promoter?.configs?.functional?.[feature];
  }
  return config;
}
import { createSlice } from "@reduxjs/toolkit";
import placeListCall from "./_sliceCalls/placeListCall";
import { placeListDefaultFilter } from "./_common/placesFilters";
import { appendItemsToList } from "../../common/utils";
import placeCreateCall from "./_sliceCalls/placeCreateCall";
import placeUpdateCall from "./_sliceCalls/placeUpdateCall";
import placeGetCall from "./_sliceCalls/placeGetCall";
import placeDeleteCall from "./_sliceCalls/placeDeleteCall";
import placeDisableCall from "./_sliceCalls/placeDisableCall";
import placeEnableCall from "./_sliceCalls/placeEnableCall";

export const placesSlice = createSlice({
  name: "places",
  initialState: {
    placeList: [],
    placeListTotalRows: 0,
    placeListFilter: placeListDefaultFilter,
    placeDetails: null,
    placeListDetails: [],
  },
  reducers: {
    clearPlaceList: (state) => {
      state.placeList = [];
    },

    appendToPlaceList: (state, action) => {
      appendItemsToList(state.placeList, action.payload, true, "id");
    },

    setPlaceListTotalRows: (state, action) => {
      state.placeListTotalRows = action.payload;
    },

    setPlaceListFilter: (state, action) => {
      state.placeListFilter = action.payload;
    },

    setPlaceDetails: (state, action) => {
      state.placeDetails = action.payload;
    },

    setPlaceListDetails: (state, action) => {
      state.placeListDetails = action.payload;
    },
  },
});

export default placesSlice.reducer;


// SELECTS
export const selectPlaceList = (state) => state.places.placeList;
export const selectPlaceListTotalRows = (state) => state.places.placeListTotalRows;
export const selectPlaceListFilter = (state) => state.places.placeListFilter;
export const selectPlaceDetails = (state) => state.places.placeDetails;
export const selectPlaceListDetails = (state) => state.places.placeListDetails;

// CALLS
export const placeList = (filter, clear = false, reloadList = true) => (dispatch,  getState) => {
  placeListCall(placesSlice.actions, dispatch,  getState(),{ filter, clear, reloadList });
};

export const placeCreate = ({  name, type, gln, attributes, addressId, contactId, geoCoordinates, parentId, entityId }) => (dispatch) => {
  placeCreateCall(dispatch, {  name, type, gln, attributes, addressId, contactId, geoCoordinates, parentId, entityId });
};

export const placeUpdate = ({ id, name, type, gln, attributes, addressId, contactId, geoCoordinates, parentId, entityId }) => (dispatch) => {
  placeUpdateCall(dispatch, { id, name, type, gln, attributes, addressId, contactId, geoCoordinates, parentId, entityId });
};

export const placeGet = ({ id }) => (dispatch) => {
  placeGetCall(placesSlice.actions,dispatch, { id });
};

export const placeDelete = ({ id }) => (dispatch, getState) => {
  placeDeleteCall(dispatch, { id }, getState());
};

export const placeDisable = ({ id }) => (dispatch, getState) => {
  placeDisableCall(dispatch, { id }, getState());
};

export const placeEnable = ({ id }) => (dispatch, getState) => {
  placeEnableCall(dispatch, { id }, getState());
};
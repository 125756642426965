export function listColumns(t) {
  return [
    {
      field: "orderId",
      headerName: t("orders.AggregatedOrderCommentsDataGrid.columns.orderId"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "comment",
      headerName: t("orders.AggregatedOrderCommentsDataGrid.columns.comment"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },

  ];
}

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { processImageUrl } from "../../../api/s3";
import { asyncForEach } from "../../../common/utils";

const batchOutputProductsCall = async (
  { setOutputProductsList },
  dispatch,
  state,
  { businessUnitId }
) => {
  let rh = requestHelper(dispatch, "BATCH_OUTPUT_PRODUCTS");
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  try {
    let result = await call({
      type: "BATCH",
      subType: "OUTPUT_PRODUCTS",
      request: {
        businessUnitId
      },
    });

    let products = [];
    await asyncForEach(result.items || [], async (product) => {
      products.push({
        ...product,
        image: await processImageUrl(fileStorageConfigs, product.image),
        thumbnail: await processImageUrl(fileStorageConfigs, product.thumbnail),
      });
    });
    dispatch(setOutputProductsList(products));

  } catch (ex) {
    rh.error("batches", ex);
  }
  rh.close();
};

export default batchOutputProductsCall;


import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";
import { Typography, Modal, Button, Grid } from "@mui/material";

import { Field } from "react-final-form";
import MetaError from "../../forms/MetaError";

import { styles } from "./EntityPicker.styles";

import {
  selectPickerEntities,
  loadEntitiesForPicker,
} from "../../../features/entities/entitiesSlice";

import EntityPickerDataGrid from "./auxiliars/EntityPickerDataGrid";

import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import SearchBox from "../inputs/SearchBox";
import { useFirstLoad } from "../../hooks";

function EntityPicker({ classes, id, label, disabled, required, height, typeFilter }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const entities = useSelector(selectPickerEntities);
  // const filter = useSelector(selectPickerEntitiesFilter);
  const [modal, setModal] = useState(false);
  const [query, setQuery] = useState(null);

  useFirstLoad(() => {
    dispatch(loadEntitiesForPicker(typeFilter));
  });

  const onEntitiesSelectedChangeHandler = (entities, input) => {
    input.onChange && input.onChange(entities);
  };

  const onAllEntitiesChangeHandler = (v, input) => {
    input.onChange && input.onChange(v ? "ALL" : []);
  };

  const onSearchHandler = (search) => {
    setQuery(search);
  };

  const hasQuery = (item) => {
    return !query || item?.name.indexOf(query) > -1 || item?.taxNumber.indexOf(query) > -1
  }

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <div>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography
                  component="span"
                  variant="caption"
                  htmlFor={id}
                  color="textPrimary"
                  className={disabled ? classes.disabledLabel : ""}
                >
                  {label}
                  {required && <span> *</span>}
                </Typography>
              </Grid>
              <Grid item>
                <Button color="primary" onClick={() => setModal(true)}>
                  {t("common.EntityPicker.input.editBtn")}
                </Button>
              </Grid>
            </Grid>
            {input.value && input.value.length > 0 ? (
              <EntityPickerDataGrid
                style={height ? { maxHeight: height, overflowY: "auto" } : false}
                entities={input.value}
                localFilters
                hideCheckbox
              />
            ) : (
              <Typography
                component="h5"
                variant="h5"
                color="textSecondary"
                className={classes.emptytable}
                onClick={() => setModal(true)}
              >
                {t("common.EntityPicker.input.emptytable")}
              </Typography>
            )}

            <Modal open={modal} className={classes.modal} onClose={() => setModal(false)}>
              <div className={classes.modalroot}>
                <PageTitle
                  title={t("common.EntityPicker.modal.title")}
                  actions={
                    <Grid container spacing={2}>
                      <Grid item>
                        <SearchBox onEnter={(v) => onSearchHandler(v)} fast />
                      </Grid>
                      <Grid item>
                        <Button variant="contained" color="primary" size="large" onClick={() => setModal(false)}>
                          {t("common.EntityPicker.modal.closeBtn")}
                        </Button>
                      </Grid>
                    </Grid>
                  }
                />

                <Grid container className={classes.modalbody} direction="column">
                  <Grid item style={{ flexGrow: 1 }}>
                    <div className={classes.grid}>
                      <EntityPickerDataGrid
                        entities={entities.filter(item => hasQuery(item))}
                        totalRows={entities.length}
                        selectedEntities={input.value}
                        // onFilter={onFilterHandler}
                        // onLoadMore={onLoadMoreHandler}
                        onEntitiesSelectedChange={(v) => onEntitiesSelectedChangeHandler(v, input)}
                        allowBulkSelect={false}
                        allEntities={input.value === "ALL"}
                        onAllEntitiesChange={onAllEntitiesChangeHandler}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Modal>
            <MetaError className={classes.error} meta={meta} />
          </div>
        )}
      </Field>
    </div>
  );
}

export default withStyles(EntityPicker, styles);

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Chip, Tooltip } from "@mui/material";

import { styles } from "./Weekdays.styles";

function Weekdays({ classes, edit, weekdays, onChange }) {
  const { t } = useTranslation();
  const wd = [1, 2, 3, 4, 5, 6, 7];

  const onWeekdayClick = (d) => {
    let nWeekdays = (weekdays || []).find((item) => item === d)
      ? [...(weekdays || []).filter((item) => item !== d)]
      : [...(weekdays || []).filter((item) => item !== d), d]
    onChange && onChange(nWeekdays);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {wd.map((d) => {
          return (
            <Grid item key={"week_" + d}>
              <Tooltip title={t("common.Enums.weekday." + d)}>
                <Chip
                  className={classes.chip}
                  color="primary"
                  size="small"
                  variant={(weekdays || []).find((item) => item === d) ? "default" : "outlined"}
                  label={t("common.Enums.weekday." + d)?.[0]}
                  onClick={edit ? () => onWeekdayClick(d) : null}
                />

              </Tooltip>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default withStyles(Weekdays, styles);

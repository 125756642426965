import React from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./SlimCheckBoxField.styles";
import { CheckboxField } from "../../../../common/forms";

function SlimCheckBoxField({ classes, id, label, ident }) {
  return (
    <div className={classes.root} style={ident && { marginLeft: 30 }}>
      <CheckboxField id={id} label={label} />
    </div>
  );
}

export default withStyles(SlimCheckBoxField, styles);


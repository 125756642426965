import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper } from "@mui/material";

import { styles } from "./AuditDataDetails.styles";

import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { auditGetAuditData, selectAuditDataDetails } from "../businessSlice";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import LabelValue from "../../../common/displays/LabelValue/LabelValue";
import { dateTimeFormatWithMilliSeconds } from "../../../common/utils/formats";
import { sanitizeStringForJSON } from "../../../common/utils";

function AuditDataDetails({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const details = useSelector(selectAuditDataDetails);

  useFirstLoad(() => {
    dispatch(auditGetAuditData(id))
  });

  if (!details) {
    return null;
  }

  let action;
  switch (details?.action) {
    case "I":
      action = t("business.audit.data.columns_action_insert");
      break;
    case "U":
      action = t("business.audit.data.columns_action_update");
      break;
    case "D":
      action = t("business.audit.data.columns_action_delete");
      break;
    case "T":
      action = t("business.audit.data.columns_action_truncate");
      break;
    default:
      return;
  }
  let rawDataHTML;
  if (details?.rowData) {
    let rawDataProcessed = [];
    details.rowData.split('", "').map((item) => rawDataProcessed.push(parseRawData(item)));
    rawDataHTML = createHTML(rawDataProcessed, "raw");
  }

  let changedFieldsHTML;
  if (details?.changedFields) {
    let changedFieldsProcessed = [];
    details.changedFields.split('", "').map((item) => changedFieldsProcessed.push(parseRawData(item)));
    changedFieldsHTML = createHTML(changedFieldsProcessed, "changedFields");
  }

  return (
    <Page
      permission="business.actions.audit.get"
      header={
        <PageTitle
          title={t("business.audit.data.title") + " " + action}
          onBack={() => navigate('/activity/audits/database')}
        />
      }>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.audit.data.details_action")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {action}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.audit.data.details_schema")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.schema}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.audit.data.details_table")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.table}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.audit.data.details_user")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.user}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.audit.data.details_changedate")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {dateTimeFormatWithMilliSeconds(details.changeDate)}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <LabelValue
                  label={t("business.audit.data.details_rawData")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {rawDataHTML}
                    </Typography>
                  }
                />
              </Paper>
            </Grid>
            {details?.action === "U" && <Grid item xs={6}>
              <Paper className={classes.paper}>
                <LabelValue
                  label={t("business.audit.data.details_changedFields")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {changedFieldsHTML}
                    </Typography>
                  }
                />
              </Paper>
            </Grid>}
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
}

export default withStyles(AuditDataDetails, styles);

const parseRawData = (item) => {
  try {
    let arr = item.replaceAll('"', "").split("=>");
    let key = arr[0];
    let value = arr[1];
    if ((value.startsWith("{") && value.endsWith("}")) || (value.startsWith("[") && value.endsWith("]"))) {
      value = JSON.parse(value.replaceAll("\\", '"'));

      return JSON.parse('{"' + key + '":' + JSON.stringify(value) + "}");
    } else {
      value = sanitizeStringForJSON(value);
      return JSON.parse('{"' + key + '":"' + value + '"}');
    }
  } catch (e) {
    console.warn(item);
    console.error(e);
  }
};

const createHTML = (items, key) => {
  return (
    <div>
      {items.map((option) => {
        return Object.keys(option).map((k) => {
          if (typeof option[k] === "object")
            return (
              <div key={key + "_" + k}>
                <b>{k + ": "}</b> <pre>{JSON.stringify(option[k], null, 2)}</pre>
              </div>
            );
          else
            return (
              <div key={key + "_" + k}>
                <b>{k + ": "}</b>
                {option[k]}
              </div>
            );
        });
      })}
    </div>
  );
};
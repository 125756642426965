
const circle = {
  width: 16,
  height: 16,
  borderRadius: 8,
}

export const styles = (theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  colLeft: {
    maxWidth: 16,
    marginRight: theme.spacing(1),
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  colRight: {
    width: "100%",
    flex: 1,
    marginBottom: theme.spacing(1),
  },
  circleFull: {
    ...circle,
    background: "#BFD9FF"
  },
  circle: {
    ...circle,
    border: "2px solid #BFD9FF",
  },
  inprogress: {
    width: 4,
    height: 4,
    margin: 4,
    borderRadius: 2,
    background: "#BFD9FF"
  },
  connector: {
    width: 2,
    minHeight: theme.spacing(2),
    marginLeft: 7,
    background: "#BFD9FF",
    flex: 1,
  },
  title: {
    margin: 0,
    color: "#BFD9FF",
    lineHeight: 1,
    fontWeight: "bold"
  },
  date: {
    margin: 0,
    fontWeight: "bold",
  }
});
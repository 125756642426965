import React from "react";
import { useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { Alert } from '@mui/material';

import { styles } from "./Snack.styles";
import { selectSnacks } from "../coreSlice";

function Snack({ classes }) {
  const snacks = useSelector(selectSnacks);
  return (
    <div id="Snack" className={classes.root}>
      {snacks.map((item, i) => (
        <Alert
          className={classes.alert}
          variant="filled"
          key={"snack_" + i}
          severity={item.type}
        >
          {item.msg}
        </Alert>
      ))}
    </div>
  );
}

export default withStyles(Snack, styles)

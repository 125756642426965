export const styles = (theme) => ({
  root: {
  },
  formButton: {
    border: "1px #ddd solid",
    padding: 16,
    borderRadius: 4,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.back
    }
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 12
  }
});
import React from "react";
import { withStyles } from "tss-react/mui";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";

import { styles } from "../base/_base.styles";
import { isNifValid } from "../../utils";
import { useState } from "react";

function TaxNumberInput({
  classes,
  id,
  className,
  label,
  value,
  onChange,
  disabled,
  error,
  required,
  onCheck,
  checkLabel,
}) {
  const [nifValid, setNifValid] = useState(false);

  const onChangeHandler = (e) => {
    let value = e.target.value;
    let valid = isNifValid(e.target.value);
    setNifValid(valid);
    if (valid && !isNaN(parseInt(value.substring(0, 2)))) {
      value = "PT" + value;
    }
    onChange && onChange(value);
  }

  return (
    <div className={classes.root + " " + className}>
      <Typography
        component="span"
        variant="caption"
        htmlFor={id}
        color="textPrimary"
        className={disabled ? classes.disabledLabel : ""}
      >
        {label}
        {required && <span> *</span>}
      </Typography>
      <TextField
        id={id}
        fullWidth
        type="text"
        className={classes.input + (disabled ? " " + classes.disabledInput : "")}
        error={error}
        onChange={onChangeHandler}
        value={value}
        disabled={disabled}
        variant="outlined"
        onFocus={(e) => e.target && e.target.select && e.target.select()}
        size="small"
        InputProps={{
          endAdornment: nifValid && (
            <InputAdornment position="end">
              <Button color="primary" variant="outlined" size="small" onClick={() => onCheck(value)}>{checkLabel}</Button>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default withStyles(TaxNumberInput, styles);

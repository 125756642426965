import React, { useEffect, useMemo } from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./Page.styles";
import { useNavigate, useUser } from "../../hooks";
import { extractPermissionValue } from "../../utils";

function Page({ classes, header, children, permission }) {
  const user = useUser();
  const navigate = useNavigate();

  const hasPermission = useMemo(() => {
    if (permission) {
      return extractPermissionValue(user?.rolePermission || {}, permission)
    }
    return true;
    // eslint-disable-next-line
  }, [permission]);

  useEffect(() => {
    if (!hasPermission) {
      navigate('/not-available');
    }
    else {
      const header = document.getElementById('header');
      const content = document.getElementById('content');
      const headerHeight = header.getBoundingClientRect().height;
      content.style.setProperty('--header-height', `${headerHeight}px`);
    }
    // eslint-disable-next-line
  }, [])

  return hasPermission ? (
    <div className={classes.root}>
      <div className={classes.header} id="header">
        {header}
      </div>
      <div className={classes.content} id="content">
        {children}
      </div>
    </div>
  ) : null;
}

export default withStyles(Page, styles);
import {Grid, Typography} from "@mui/material";
import OrderStatus from "../../_common/OrderStatus/OrderStatus";

export function listColumns(t, classes, promoter) {
  let columns = [];

  // ID
  columns.push({
    field: "id",
    headerName: t("orders.OperatorOrdersList.columns.id"),
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return (
        <Grid container direction="column">
          <Typography className={classes.id} variant="h4">
            {`${v.id} (${v.dispatchId})`}
          </Typography>
          <Typography className={classes.orderBy}>{v.description}</Typography>
        </Grid>
      );
    },
  });

  // # PRODUCTS
  columns.push({
    field: "numberOfProducts",
    headerName: t("orders.OperatorOrdersList.columns.numberOfProducts"),
    align: "left",
    headerAlign: "left",
  });


  // STATUS
  columns.push({
    field: "status",
    headerName: t("orders.OperatorOrdersList.columns.status"),
    align: "left",
    headerAlign: "center",
    customValue: (v) => {
      return <OrderStatus order={v} />;
    },
  });

  return columns;
}

// address: "Rua da Paz, Cacia, Aveiro, 3800-587"
// addressId: "fd264002-5eec-43c5-8574-d0f207f5cc3a"
// cutOffTime: "2022-02-22T17:06:46.468274"
// cutOffType: "FRI"
// description: "Marabuto - Produtos Alimentares S.a"
// dispatchId: 19
// documentNumber: "Document Test"
// id: 25
// numberOfProducts: 1
// operationType: "TF"
// status: "DI"

export const styles = (theme) => ({
  root: {
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
  edit: {
    fontSize: 16,
  },
  modal: {
    paddingTop: "30vh",
  },
  modalroot: {
    width: "70vw",
    maxWidth: 480,
    margin: "0 auto",
    backgroundColor: theme.palette.secondary.main,
    height: 270,
  },
  modalbody: {
    padding: theme.spacing(3),
  },
});

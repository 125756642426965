import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const promoterDisableExternalAccessCall = async ({ entityGet }, dispatch, { entityId, username }) => {
  let rh = requestHelper(dispatch, "DISABLE_EXTERNAL_ACCESS");
  try {
    await call({
      type: "PROMOTER",
      subType: "DISABLE_EXTERNAL_ACCESS",
      request: {
        entityId,
        username,
        type: "WS",
      },
    });
    dispatch(setSuccess("entities", "PROMOTER_DISABLE_EXTERNAL_ACCESS"));
    setTimeout(() => {
      dispatch(entityGet(entityId));
    }, 300);
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default promoterDisableExternalAccessCall;

import { Button } from "@mui/material";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import { measureFormat } from "../../../../common/utils";

export function listColumns(t, years, onPlanningClick) {
  let columns = [];

  columns.push({
    field: "productId",
    align: "left",
    headerAlign: "left",
    disableSort: true,
    customValue: (v) => {
      return <ProductSnip
        image={v.thumbnail}
        description={v.description}
        gtin={v.gtin}
        promoterInternalCode={v.promoterInternalCode}
        ownerName={v.ownerName}
      />
    }
  });

  (years || []).forEach(year => {
    columns.push({
      field: year,
      headerName: year,
      align: "center",
      headerAlign: "center",
      disableSort: true,
      customValue: (v) => {
        if (v?.targets?.[year]) {
          return <Button onClick={() => onPlanningClick && onPlanningClick(year, v.productId)}>{measureFormat({ t, measure: v.targets[year], decimalPlaces: 0 })}</Button>
        }
        return;
      }
    });
  })

  return columns;
}



import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess, setRedirect } from "../../../app/coreSlice";

const resourceCreateCall = async (
  dispatch,
  { title, type, placeId, parentId, state, height, width, depth, weight, attributes }
) => {
  let rh = requestHelper(dispatch, "RESOURCE_CREATE");
  try {
    let result = await call({
      type: "RESOURCE",
      subType: "CREATE",
      request: {
        title, type, placeId, parentId, state, height, width, depth, weight, attributes
      },
    });

    setTimeout(() => {
      if (result) {
        dispatch(setRedirect("/resources"));
        dispatch(setSuccess("resources", "RESOURCE_CREATE"));
      }
    }, 300);

  } catch (ex) {
    rh.error("resources", ex);
  }
  rh.close();
};

export default resourceCreateCall;


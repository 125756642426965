import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Stack } from "@mui/material";

import { styles } from "./HelperLine.styles";

function HelperLine({ classes, width, label, value }) {
  return (
    <Stack className={classes.root} direction="row" spacing={1} alignItems="center">
      <div>
        <Typography style={{ margin: 0, width }} variant="caption">{label}</Typography>
      </div>
      <div>
        <Typography style={{ margin: 0 }}>{value}</Typography>
      </div>
    </Stack>
  );
}

export default withStyles(HelperLine, styles);
import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Stack } from "@mui/material";
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';

import { styles } from "./TasksCounter.styles";

function TasksCounter({ classes, tasksToComplete = 12, taskCompleted = 12, onClick }) {
  return (
    <Stack direction="row" spacing={2} className={classes.root} onClick={onClick}>
      {/* tasks to do */}
      {tasksToComplete > 0 && <Stack direction="row" className={classes.taskOpen} spacing={1} alignItems="center">
        <ExploreOutlinedIcon />
        <Typography>{tasksToComplete}</Typography>
      </Stack>}
      {/* tasks done */}
      <Stack direction="row" className={classes.taskCompleted} spacing={1} alignItems="center">
        <ExploreOutlinedIcon />
        <Typography>{taskCompleted}</Typography>
      </Stack>
    </Stack>
  );
}

export default withStyles(TasksCounter, styles);
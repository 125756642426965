
import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Paper, Typography, IconButton, Divider, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { styles } from "./BusinessUnitStepTaskForm.styles";
import { formFields, formSchema } from "./BusinessUnitStepTaskForm.schema";
import TaskInfoModal from "./TaskInfoModal/TaskInfoModal";
import TaskInputModal from "./TaskInputModal/TaskInputModal";
import TaskInfoItem from "./TaskInfoItem/TaskInfoItem";
import TaskInputItem from "./TaskInputItem/TaskInputItem";

import { Form, FormFields } from "../../../common/forms";
// import Permission from "../../../app/components/Permission";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { useFirstLoad, useNavigate, usePromoter, useUser } from "../../../common/hooks";
import { businessUnitCreateStepTask, businessUnitGetStepTask, businessUnitUpdateStepTask, selectTaskDetails } from "../businessUnitsSlice";
import { entityGet, selectEntityDetails } from "../../entities/entitiesSlice";
import { appendItemsToList, guid, removeItemsFromList } from "../../../common/utils";
import TaskDetailsModal from "../../tasks/TasksList/TaskDetailsModal/TaskDetailsModal";

function BusinessUnitStepTaskForm({ classes, type, internal = false }) {
  const { t, i18n } = useTranslation();
  const { businessUnitId, stepId, id, placeId, lineId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useUser();
  const promoter = usePromoter();
  const entity = useSelector(selectEntityDetails);
  const details = useSelector(selectTaskDetails);
  const [infoModal, setInfoModal] = useState(false);
  const [inputModal, setInputModal] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState();
  const [selectedInput, setSelectedInput] = useState();
  const [previewModal, setPreviewModal] = useState(false);
  const [selectedResourceInput, setSelectedResourceInput] = useState();

  useFirstLoad(() => {
    if (!entity && entity?.id !== user?.entity?.id) {
      dispatch(entityGet(user?.entity?.id));
    }
    if (id) {
      dispatch(businessUnitGetStepTask({ id }));
    }
  })

  const onSubmitHandler = async (values) => {
    console.log('values: %o', values);
    console.log('params: %o', { businessUnitId, stepId, id, placeId, lineId });

    if (id) {
      dispatch(businessUnitUpdateStepTask({ ...values, businessUnitId, internal, businessUnitStepId: stepId, placeId, type, id, lineId }));
    }
    else {
      dispatch(businessUnitCreateStepTask({ ...values, businessUnitId, internal, businessUnitStepId: stepId, placeId, type, lineId }));
    }
  };

  const infoCreateHandler = () => {
    setSelectedInfo();
    setInfoModal(true);
  }

  const infoUpdateHandler = (info) => {
    setSelectedInfo(info);
    setInfoModal(true);
  }

  const inputCreateHandler = () => {
    setSelectedInput();
    setSelectedResourceInput();
    setInputModal(true);
  }

  const onResourceInputAddHandler = (resourceInput) => {
    setSelectedResourceInput(resourceInput);
    setInputModal(true);
  }

  const inputUpdateHandler = (input) => {
    setSelectedInput(input);
    setSelectedResourceInput();
    setInputModal(true);
  }

  const onInfoSubmit = (val, info, form) => {
    setInfoModal(false);
    let nList = [...(info || [])];
    appendItemsToList(nList, [{ ...val, id: val?.id || guid() }], true, "id");
    form.change("info", nList);
  }

  const onInputSubmit = (val, input, form) => {

    setInputModal(false);
    let nList = [...(input || [])];
    appendItemsToList(nList, [{ ...val, id: val?.id || guid() }], true, "id");
    // console.log('onInputSubmit: %o', { val, nList })
    form.change("input", nList);
  }

  const onInfoRemove = (list, itemToRemove, form) => {
    let result = removeItemsFromList(list, [itemToRemove], "id");
    form.change("info", result);
    setInputModal(false);
    setSelectedInfo();
  }

  const onInputRemove = (list, itemToRemove, form) => {
    let result = removeItemsFromList(list, [itemToRemove], "id");
    form.change("input", result);
    setInputModal(false);
    setSelectedInfo();
  }

  const onPreviewHandler = () => {
    setPreviewModal(true);
  }

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmitHandler}
        schema={formSchema({ t })}
        initialValues={id ? details : { associationType: "GROUP" }}
        render={({ values, form }) => {
          return (
            <Page
              // permission={"businessUnits.actions.businessUnits.list"}
              header={<PageTitle
                title={id ? t("businessUnits.BusinessUnitStepTaskForm.header.titleEdit") : t("businessUnits.BusinessUnitStepTaskForm.header.title")}
                onBack={() => internal ?
                  navigate(`/business/units/${businessUnitId}/${lineId}/steps/internal/`) :
                  placeId ?
                    navigate(`/business/units/${businessUnitId}/placetasks/`) :
                    navigate(`/business/units/${businessUnitId}/${lineId}/steps/`)}
                actions={
                  <Grid container direction="row" spacing={2}>
                    {/* <Permission code={"businessUnits.actions.businessUnits.create"}> */}
                    <Grid item>
                      <Button variant="contained" color="primary" onClick={onPreviewHandler}>
                        <VisibilityIcon />
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit">
                        {t("businessUnits.BusinessUnitStepTaskForm.form.btnSubmit")}
                      </Button>
                    </Grid>
                    {/* </Permission> */}
                  </Grid>
                }
              />}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={7}>
                    <Stack spacing={1}>
                      {/* DETAILS */}
                      <Stack>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography variant="h4" style={{ marginBottom: 0 }}>{t("businessUnits.BusinessUnitStepTaskForm.header.details")}</Typography>
                        </Stack>
                        <Divider />
                        <FormFields fields={formFields({
                          t,
                          values,
                          contacts: entity?.contacts,
                          sectorsGroups: entity?.sectorsGroups,
                          promoter,
                          i18n,
                          taskType: type,
                        })} />
                      </Stack>

                      {/* INFO */}
                      <Stack>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography variant="h4" style={{ marginBottom: 0 }}>{t("businessUnits.BusinessUnitStepTaskForm.header.info")}</Typography>
                          <IconButton size="small" className={classes.circleBtn} onClick={infoCreateHandler}>
                            <AddIcon fontSize="small" />
                          </IconButton>
                        </Stack>
                        <Divider />
                      </Stack>
                      {values.info?.length > 0 ?
                        <Stack spacing={1}>
                          {(values.info || []).map((info, index) => (
                            <div key={"taskInfo_" + index}>
                              <TaskInfoItem info={info} onTaskInfoSelected={infoUpdateHandler} />
                            </div>
                          ))}
                        </Stack> :
                        <Typography color="textSecondary">{t("businessUnits.BusinessUnitStepTaskForm.details.infoEmpty")}</Typography>
                      }
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Stack spacing={1}>
                      {/* INPUTS */}
                      <Stack>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography variant="h4" style={{ marginBottom: 0 }} onClick={() => console.log('inputs: %o', values.input)}>{t("businessUnits.BusinessUnitStepTaskForm.header.inputs")}</Typography>
                          <IconButton size="small" className={classes.circleBtn} onClick={inputCreateHandler}>
                            <AddIcon fontSize="small" />
                          </IconButton>
                        </Stack>
                        <Divider />
                      </Stack>
                      {values.input?.length > 0 ?
                        <Stack spacing={1} >
                          {(values.input || []).filter(item => !item.parentId).map((input, index) => (
                            <div key={"taskInput_" + index}>
                              <TaskInputItem collection={values.input} input={input} onTaskInputSelected={inputUpdateHandler} onResourceInputAdd={onResourceInputAddHandler} />
                            </div>
                          ))}
                        </Stack> :
                        <Typography color="textSecondary">{t("businessUnits.BusinessUnitStepTaskForm.details.inputsEmpty")}</Typography>
                      }
                    </Stack>
                  </Grid>
                </Grid>

              </Paper>
              <TaskInfoModal
                open={infoModal}
                setOpen={setInfoModal}
                taskType={type}
                info={selectedInfo}
                onSubmit={(val) => onInfoSubmit(val, values.info, form)}
                onInfoRemove={(item) => onInfoRemove(values.info, item, form)}
              />
              <TaskInputModal
                open={inputModal}
                setOpen={setInputModal}
                taskType={type}
                input={selectedInput}
                onSubmit={(val) => onInputSubmit(val, values.input, form)}
                onInputRemove={(item) => onInputRemove(values.input, item, form)}
                selectedResourceInput={selectedResourceInput}
                inputs={values.input}
              />
              <TaskDetailsModal task={details} open={previewModal} setOpen={setPreviewModal} view />
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(BusinessUnitStepTaskForm, styles);

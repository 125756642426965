import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { toAddressList, toEntity, toLiteEntity } from "../entitiesMapper";
import { asyncForEach } from "../../../common/utils";

const entityCreateCall = async ({ setCreateResult, entityUpdateLogo }, dispatch, state, values) => {
  let rh = requestHelper(dispatch, "CREATING_ENTITY");
  dispatch(setCreateResult(null));

  try {
    let createResult;
    if (values.create) {
      createResult = await call({
        type: "ENTITY",
        subType: "CREATE",
        request: toEntity(values),
      });
    } else {
      createResult = await call({
        type: "PROMOTER",
        subType: "ADD_ENTITY",
        request: toLiteEntity(values),
      });
    }
    if (values.logo?.base64) {
      if (createResult && createResult.entitiesId) {
        createResult.entitiesId.forEach((item) => {
          dispatch(entityUpdateLogo({ entityId: item, logo: values.logo }));
        });
      }
    }
    setTimeout(() => {
      dispatch(setSuccess("entities", "ENTITY_CREATE"));
      dispatch(setRedirect("/entities"));
    }, 300);

    let { users } = values;
    let contactIds = [];
    if (Array.isArray(users)) {
      await asyncForEach(users, async (user) => {
        let contact = await call({
          type: "ENTITY",
          subType: "ADD_CONTACT",
          request: {
            ...user,
            id: [createResult.entitiesId[user.businessUnitIndexs || 0]],
          },
        });
        contactIds.push(contact.uuid);
      });
    }
    let addressList = toAddressList(createResult.entitiesId, contactIds, values.addressList);
    if (Array.isArray(addressList)) {
      addressList.forEach(async (address) => {
        await call({
          type: "ENTITY",
          subType: "ADD_ADDRESS",
          request: address,
        });
      });
    }
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityCreateCall;

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const businessUnitEnableStepCall = async (
  { businessUnitSteps },
  dispatch,
  { id, businessUnitId, lineId, internal }
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_ENABLE_STEP");
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "ENABLE_STEP",
      request: {
        id
      },
    });

    if (result) {
      dispatch(setSuccess("businessUnits", "BUSINESS_UNIT_ENABLE_STEP"));
      setTimeout(() => {
        dispatch(businessUnitSteps({ businessUnitId, lineId, internal }));
      }, 300);
    }
    
  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitEnableStepCall;


import { Grid, Typography } from "@mui/material";
import { dateFormat, currencyFormat } from "../../../../common/utils";
import OrderStatus from "../../_common/OrderStatus/OrderStatus";
import { canAcceptOrder, canDispatchOrder } from "../../_common/ordersUtils";

export function listColumns(t, classes) {
  return [
    {
      field: "id",
      headerName: t("orders.AggregatedOrders.columns.id"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <Grid container direction="column">
            <Typography className={classes.id} variant="h4">
              {v.id}
            </Typography>
            <Typography className={classes.orderBy}>{v.description}</Typography>
          </Grid>
        );
      },
    },
    {
      field: "numberOfOrders",
      headerName: t("orders.AggregatedOrders.columns.numberOfOrders"),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "numberOfProducts",
      headerName: t("orders.AggregatedOrders.columns.numberOfProducts"),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cutOffTime",
      headerName: t("orders.AggregatedOrders.columns.cutOffTime"),
      align: "center",
      headerAlign: "center",
      customValue: (v) => {
        return dateFormat(v.cutOffTime);
      },
    },
    {
      field: "price",
      headerName: t("orders.AggregatedOrders.columns.price"),
      align: "right",
      headerAlign: "right",
      customValue: (v) => {
        return currencyFormat(v.price);
      },
    },
    {
      field: "status",
      headerName: t("orders.AggregatedOrders.columns.status"),
      headerAlign: "center",
      customValue: (v) => {
        return <OrderStatus order={v} allowAccept={canAcceptOrder(v) && v?.orderSellerIdList?.length === 1} allowDispatch={canDispatchOrder(v) && v?.orderSellerIdList?.length === 1} />;
      },
    },
  ];
}

// cutOffTime: "2021-09-01T12:00:00.388143"
// cutOffType: "FRI"
// description: ""
// id: 658
// numberOfOrders: 1
// numberOfProducts: 2
// price: 900
// status: "CO"

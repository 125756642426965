import { useCallback, useState } from "react";

export const processHierarchy = (hierarchy) => {
  let rootLevel = 0;
  let rootItem;

  if (hierarchy && hierarchy.length > 0) {
    hierarchy.forEach((item) => {
      if (item.level <= rootLevel) {
        rootLevel = item.level;
        rootItem = item;
      }
    });

    return [recursiveTree(hierarchy, rootItem.gtin)];
  }
  return [];
};

export const recursiveTree = (hierarchy, gtin) => {
  const item = hierarchy?.find((item) => item.gtin === gtin);
  const childs = hierarchy?.filter((item) => item.child?.childGtin && item.child?.childGtin === gtin);

  if (item) {
    let itemChilds = [];
    (childs || []).forEach((child) => {
      itemChilds.push(recursiveTree(hierarchy, child.gtin));
    });
    return {
      ...item,
      children: itemChilds.length > 0 ? itemChilds : undefined,
    };
  }
};

export const getSpacialLength = (hierarchy) => {
  let max = 0,
    min = 0;
  (hierarchy || []).forEach((item) => {
    if (item.level > max) {
      max = item.level;
    }
    if (item.level < min) {
      min = item.level;
    }
  });
  return max - min + 1;
};

export const useCenteredTree = () => {
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 2, y: 50 });
    }
  }, []);
  return [translate, containerRef];
};

export const insertAddButtonOnLastChild = (org, node) => {
  if (!org || org?.length === 0) {
    return [{ add: true, ...node }];
  } else {
    let children = insertAddButtonOnLastChild(org[0].children, {
      child: { childGtin: org[0].gtin, childUnitDescriptor: org[0].unitDescriptor },
      level: org[0].level + 1,
    });
    let nOrg = [...org];
    nOrg[0].children = children;
    return nOrg;
  }
};

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper } from "@mui/material";

import { styles } from "./Taxes.styles";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";

function Taxes({ classes, taxes }) {
  const { t } = useTranslation();

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h3" align="left" component="h2" className={classes.noMargin}>
            {t("products.ProductCreate.form.taxesTitle")}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <LabelValue
                label={t("products.ProductCreate.form.vat")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {t('common.Enums.vat.' + (taxes?.vat || "VAT_0"))}
                  </Typography>
                }
              />
            </Grid>
            {
              (taxes?.other || []).map(({ code, value, type }, index) => <Grid item xs={2} key={"tax_" + index}>
                <LabelValue
                  label={t('common.Enums.taxGroup.' + code)}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {`${value} ${type === "A" ? "€" : "%"}`}
                    </Typography>
                  }
                />
              </Grid>)
            }
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(Taxes, styles);
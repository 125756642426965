import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../../common/displays/CustomDataGrid";

import { styles } from "./OrdersTFDataGrid.styles";
import { listColumns } from "./OrdersTFDataGrid.columns";
import { useFirstLoad } from "../../../../../common/hooks";

function OrdersTFDataGrid({ classes, data, totalRows }) {
  const { t } = useTranslation();
  const [dataProcessed, setDataProcessed] = useState([]);

  useFirstLoad(() => {
    let newData = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        if (item && item.details) {
          item.details.forEach(function (detail) {
            newData.push({
              description: item.description,
              gln: item.gln,
              gtin: item.gtin,
              image: item.image,
              productId: item.productId,
              thumbnail: item.thumbnail,
              status: detail.status,
              updatedBy: detail.updatedBy,
              updatedTime: detail.updatedTime,
              quantity: detail.quantity,
              totalTransportUnits: detail.totalTransportUnits,
            });
          });
        }
      }
      setDataProcessed(newData);
    }
  });

  return (
    <div className={classes.root}>
      {data && <CustomDataGrid columns={listColumns(t, classes)} rows={dataProcessed} total={totalRows} />}
    </div>
  );
}

export default withStyles(OrdersTFDataGrid, styles);

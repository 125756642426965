import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Stack, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Alert } from "@mui/material";

import { styles } from "./PlanningForecast.styles";
import Permission from "../../../app/components/Permission";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import Page from "../../../common/displays/Page/Page";
import { selectLoader } from "../../../app/coreSlice";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { businessUnitList_Full, planningForecast, planningForecastGet, planningList, selectBusinessUnitList, selectPlanningForecast, selectPlanningList } from "../planningSlice";
import { useParams } from "react-router-dom";
import { generateForecast, getUnitsSorted, processForecast } from "../_common/planningUtils";

import ForecastTableHeader from "./ForecastTableHeader/ForecastTableHeader";
import ForecastTableCell from "./ForecastTableCell/ForecastTableCell";

function PlanningForecast({ classes }) {
  const { t } = useTranslation();
  const { year, productId } = useParams();
  const dispatch = useDispatch();
  const businessUnits = useSelector(selectBusinessUnitList);
  const planning = useSelector(selectPlanningList);
  const loading = useSelector(selectLoader);
  const [sortedBusinessUnits, setSortedBusinessUnits] = useState([]);
  const [weeklyForecast, setWeeklyForecast] = useState([]);
  const defaultPlanning = (planning || []).find(item => item.defaultPlan);
  const savedForecast = useSelector(selectPlanningForecast);
  const navigate = useNavigate();

  useFirstLoad(() => {
    dispatch(planningForecastGet({ year, productId }))
    dispatch(planningList({ year, productId }));
    dispatch(businessUnitList_Full());
  });

  useEffect(() => {
    if (businessUnits && businessUnits?.length > 0) {
      let busSorted = getUnitsSorted(businessUnits, productId);
      setSortedBusinessUnits(busSorted);
    }
    // eslint-disable-next-line
  }, [businessUnits]);

  const onGenerateHandler = () => {
    const wForecast = generateForecast({ businessUnits, productId, year, planning, defaultPlanning });
    setWeeklyForecast(wForecast);
  };

  const onSaveHandler = () => {
    console.log('--> weeklyForecast: %o', weeklyForecast);
    dispatch(planningForecast({ year, productId, forecasts: weeklyForecast }));
  }

  return (
    <Page
      permission="planning.actions.forecast.get"
      header={<PageTitle
        title={t("planning.PlanningForecast.header.title")}
        onBack={() => navigate('/planning')}
        actions={
          <Grid container direction="row" spacing={2}>
            <Permission code="planning.actions.forecast.create">
              <Grid item>
                <Button variant="contained" size="large" disabled={loading || weeklyForecast?.length === 0} onClick={onSaveHandler}>{t("planning.PlanningForecast.header.btnSave")}</Button>
              </Grid>
            </Permission>
          </Grid>
        }
      />}>
      <Stack spacing={2}>
        {weeklyForecast?.length > 0 ?
          <Alert variant="outlined" severity="warning" action={
            <Button variant="contained" size="small" disabled={loading} color="warning" onClick={onGenerateHandler}>{t("planning.PlanningForecast.alert.btnGenerate")}</Button>
          }>
            {t("planning.PlanningForecast.alert.pendingForecast")}
          </Alert> :
          <Alert variant="outlined" severity="info" action={
            <Button variant="contained" size="small" disabled={loading} color="info" onClick={onGenerateHandler}>{t("planning.PlanningForecast.alert.btnGenerate")}</Button>
          }>
            {savedForecast?.length > 0 ? t("planning.PlanningForecast.alert.currentForecast") : t("planning.PlanningForecast.alert.noForecast")}
          </Alert>
        }
        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 650 }} >
            <TableHead>
              <TableRow>
                <TableCell>
                  <ForecastTableHeader target unit={sortedBusinessUnits?.[0]} unitName={t("planning.PlanningForecast.table.targetTitle")} />
                </TableCell>
                {sortedBusinessUnits?.map((item) => (
                  <TableCell key={"header_" + item.id}>
                    <ForecastTableHeader unit={item} unitName={item.name} />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                (weeklyForecast?.length > 0 ? processForecast(weeklyForecast) : processForecast(savedForecast)).map(week => (
                  <TableRow key={week.target?.untilDate}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      <ForecastTableCell target={week.target?.target} date={week.target?.untilDate} />
                    </TableCell>
                    {week.bus.map(bu => (
                      <TableCell key={week.untilDate + "_" + bu.untilDate}>
                        <ForecastTableCell target={bu?.target} date={bu?.untilDate} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Page>
  );
}

export default withStyles(PlanningForecast, styles);
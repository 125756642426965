export const styles = (theme) => ({
  root: {},
  box: {
    height: 18,
  },
  icon: {
    color: theme.palette.error.main,
    fontSize: 18,
  },
});

export const tooltipStyles = (theme) => ({
  tooltip: {
    backgroundColor: theme.palette.error.main,
    color: "#FFF",
    boxShadow: theme.shadows[2],
    maxWidth: 200,
    padding: theme.spacing(1),
  },
});

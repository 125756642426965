const productsDproms = {
  flowsMessages: {
    success: {
      PRODUCT_DPROM_CREATE: "Promotional discount created successfully!",
    },
    error: {
      GENERIC: "Something went wrong",
    },
  },
  DpromsList: {
    header: {
      title: "Promotional discounts",
      btnSubmit: "Submit",
      changesToSubmit: "You have pending promotional discounts to submit; they will be available for review only after submission.",
      changesToSubmitValidation: "You have pending promotinal discounts to submit; they will be validated only after submission.",
      clearChanges: "Clear changes",
      dpromsLink: "Promotional Discounts",
      validationLink: "Validation",
      rowsInfo: "discounts"
    },
    modal: {
      title: "Promotional discount"
    },
    columns: {
      name: "Name",
      date: "Date",
      type: "Type",
      value: "Value",
      status: "Status",
      ownerName: "Owner",
      locations: "Locations",
      products: "Products",
      family: "Family",
      pendingRemove: "Remove pending",
      removeDate: "Date to remove:"
    },
    modalComment: {
      titleAccept: "Accept promotional discount",
      titleRefuse: "Refuse promotional discount",
      cancelBtn: "Cancel",
      acceptBtn: "Accept",
      refuseBtn: "Refuse",
      commentLabel: "Comment",
    },
    actions: {
      remove: "Remove",
      edit: "Edit",
      copy: "Copy",
      cancel: "Cancel"
    }
  },
  DPromForm: {
    form: {
      addBtn: "Add",
      updateBtn: "Update",
      name: "Name",
      type: "Type",
      value: "Value",
      startDate: "Start Date",
      endDate: "End Date",
      family: "Family",
      products: "Products",
      ownerId: "Owner",
      ownerIdRequired: "Onwer is required",
      nameRequired: "Name is required",
      typeRequired: "Type is required",
      valueRequired: "Value is required",
      startDateRequired: "Start Date is required",
      startDateMin: "Start Date should be today or after today",
      endDateMin: "End date should be after start date",
      valueMax: "The max value for percentage here is 100%",
      locations: "Locations",
      familyClear: "Clear"
    }
  },
  DpromRemoveModal: {
    modal: {
      title: "Remove promotional discount",
      cancelMessage: "Are you sure you want to cancel this changes?",
      removeDate: "Date to remove",
      confirmBtn: "Confirm Remove"
    }
  },
  DpromValidationCommentModal: {
    form: {
      comment: "Comment",
      commentRequired: "Comment is required",
      btnCancel: "Cancel",
      btnAccept: "Accept",
      btnRefuse: "Refuse",
    },
    modal: {
      title: "Accept promotional discount",
      titleRefuse: "Refuse promotional discount",
    }
  }
}

export default productsDproms;
export const styles = (theme) => ({
  root: {
    "& .CdgRow": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#ddd",
      },
    },
  },
  id: {
    margin: 0,
  },
  orderBy: {
    margin: 0,
    color: "#aaa",
  },
  nomargin: {
    margin: 0,
  },
  totalLabel: {
    margin: 0,
    width: 100,
    textAlign: "right",
  },
  totalbox: {
    width: 200,
  },
  outdated: {
    textDecoration: "line-through",
    color: "#777",
  },
  outdatedred: {
    textDecoration: "line-through",
    color: theme.palette.error.main,
  },
});



import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper, setSuccess } from "../../../app/coreSlice";
import { processImageUrl, saveFile } from "../../../api/s3";
import { asyncForEach, base64ToArrayBuffer, guid } from "../../../common/utils";

const documentProcessImageRecognitionCall = async (
  { setImageProcessingResult },
  dispatch,
  state,
  files
) => {
  let rh = requestHelper(dispatch, "DOCUMENT_PROCESS_IMAGE_RECOGNITION");
  const promoterCode = state.core.promoterDetails?.details?.code;
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  const folder = `${promoterCode}/${process.env.REACT_APP_ENV}/images-recognition/${guid()}`;
  await uploadFiles(fileStorageConfigs, folder, files);
  dispatch(setImageProcessingResult(null));
  try {
    let result = await call({
      type: "DOCUMENT",
      subType: "PROCESS_IMAGE_RECOGNITION",
      request: {
        key: folder
      },
      customTimeout: process.env.REACT_APP_WS_TIMEOUT_IMAGE
    });

    if (result) {
      //process images
      let signedOutputFiles = await signFilesUrls(fileStorageConfigs, result?.outputFiles);
      let signedImages = await signFilesUrls(fileStorageConfigs, result?.images);
      let files = (signedOutputFiles || []).filter(item => item.indexOf('.csv') > 0 || item.indexOf('.json') > 0);

      dispatch(setImageProcessingResult({ items: parseResults(result?.result, result?.images, signedImages), files }));
      dispatch(setSuccess("document", "DOCUMENT_PROCESS_IMAGE_RECOGNITION"));
    }

  } catch (ex) {
    rh.error("document", ex);
  }
  rh.close();
};

export default documentProcessImageRecognitionCall;

const uploadFiles = async (fileStorageConfigs, folder, files) => {
  const { provider, privateBucket } = fileStorageConfigs;
  await asyncForEach(files, async ({ name, mediaType, base64 }) => {
    await saveFile(
      provider,
      privateBucket,
      `${folder}/IN/${name}`,
      base64ToArrayBuffer(base64),
      mediaType
    );
  });
};

const signFilesUrls = async (fileStorageConfigs, files) => {
  let signedFiles = [];
  await asyncForEach(files, async (url) => {
    signedFiles.push(await processImageUrl(fileStorageConfigs, url))
  });
  return signedFiles;
}

const parseResults = (data, ogImages, signedImages) => {
  let result = [];

  ogImages.forEach((img, index) => {
    let imageName = img.split('/').slice(-1)[0];
    result.push({
      imageName,
      outputImage: signedImages[index],
      count: data[imageName]?.length,
      data: data[imageName],
    })
  });
  return result;
}
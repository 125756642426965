import React from "react";
import { withStyles } from "tss-react/mui";
import { Box, Tooltip } from "@mui/material";

import { styles, tooltipStyles } from "./IconWithTooltip.styles";

const CustomTooltip = withStyles(Tooltip, tooltipStyles);

function IconWithTooltip({ classes, icon, children, placement }) {
  return (
    <div className={classes.root}>
      <CustomTooltip title={<Box className={classes.content}>{children}</Box>} placement={placement}>
        <Box>{icon}</Box>
      </CustomTooltip>
    </div>
  );
}

export default withStyles(IconWithTooltip, styles)

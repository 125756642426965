import * as Yup from "yup";
import {
  DatePickerField,
  TextField,
} from "../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    document: Yup.string().required(t("orders.OperatorOrderDetails.form.documentRequired")),
    documentDate: Yup.string().required(t("orders.OperatorOrderDetails.form.documentDateRequired")),
  });
}

export function formFields(t, selectedStatus) {
  return [
    {
      size: 6,
      field: <TextField id="document" type="text" label={t("orders.OperatorOrderDetails.form.document")} required />,
      hidden: ["DI", "RE", "IT"].indexOf(selectedStatus) === -1,
    },
    {
      size: 6,
      field: <DatePickerField id="documentDate" label={t("orders.OperatorOrderDetails.form.documentDate")} required />,
      hidden: ["DI", "RE", "IT"].indexOf(selectedStatus) === -1,
    },
  ];
}

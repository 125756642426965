import React from "react";
import { withStyles } from "tss-react/mui";
import { Paper } from "@mui/material";
import Chart from "react-apexcharts";

import { styles } from "./WeeklyPlanChart.styles";
import { aggregateToFridays, distributeQuantity } from "../../_common/planningUtils";
import { useTranslation } from "react-i18next";

function WeeklyPlanChart({ classes, data }) {
  const { t } = useTranslation();
  const result = aggregateToFridays(distributeQuantity(data));
  // console.log('result: %o', result);
  const categories = Object.keys(result).sort();
  const series = [{ name: t("planning.WeeklyPlanChart.labels.series"), data: categories.map(key => result[key].toFixed(1)) }];

  const options = {
    chart: {
      toolbar: {
        show: false
      },
    },
    stroke: {
      curve: 'smooth',
      width: [3]
    },
    xaxis: {
      type: 'category',
      categories,
    },
    yaxis: {
      min: 0
    },
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Chart options={options} series={series} type="line" height={350} />
      </Paper>
    </div>
  );
}

export default withStyles(WeeklyPlanChart, styles);
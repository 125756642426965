import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Paper, Accordion, AccordionSummary, Typography, AccordionDetails, Stack } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styles } from "./RolesForm.styles";
import { formFields, formSchema } from "./RolesForm.schema";
import FeatureAvailableField from "./FeatureAvailableField/FeatureAvailableField";

import { Form, FormFields } from "../../../common/forms"
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { useFirstLoad, useNavigate, usePromoter, useUser } from "../../../common/hooks";
import LabelValue from "../../../common/displays/LabelValue/LabelValue";
import { rolesCreate, rolesGet, rolesUpdate, selectRoleDetails } from "../rolesSlice";
import { useParams } from "react-router-dom";
import Permission from "../../../app/components/Permission";
import { entityGet, selectEntityDetails } from "../../entities/entitiesSlice";
import { EntityAutoComplete } from "../../../common/inputs/custom";
import { businessFormFields } from "./fields/businessFormFields";
import { entitiesFormFields } from "./fields/entitiesFormFields";
import { homeFormFields } from "./fields/homeFormFields";
import { ordersFormFields } from "./fields/ordersFormFields";
import { productsFormFields } from "./fields/productsFormFields";
import { promoterFormFields } from "./fields/promoterFormFields";
import SelectAllField from "./SelectAllField/SelectAllField";
import { tasksFormFields } from "./fields/tasksFormFields";
import { planningFormFields } from "./fields/planningFormFields";
import { batchesFormFields } from "./fields/batchesFormFields";

function RolesForm({ classes }) {
  const { t } = useTranslation();
  const { id, entityId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useUser();

  const details = useSelector(selectRoleDetails);
  const entity = useSelector(selectEntityDetails);
  const promoter = usePromoter();
  const [selectedEntity, setSelectedEntity] = useState(entityId || user?.entity?.id);
  const [expandedPanels, setExpandedPanels] = useState({
    business: false,
    entities: false,
    home: false,
    orders: false,
    products: false,
    promoter: false,
    tasks: false,
    planning: false,
    batches: false
  });

  useFirstLoad(() => {
    if (id) {
      dispatch(rolesGet({ roleID: id }));
    }
  });

  useEffect(() => {
    if (id) {
      if (details?.entityId && entity?.entityId !== details?.entityId) {
        dispatch(entityGet(details?.entityId));
      }
    }
    else {
      if (selectedEntity && entity?.entityId !== selectedEntity) {
        dispatch(entityGet(selectedEntity));
      }
    }
    // eslint-disable-next-line
  }, [details, selectedEntity, entity]);

  const initialValues = useMemo(() => {
    if (id && details) {
      return {
        ...details,
        associationType: details?.user?.length > 0 ? "USER" : (details?.group?.length > 0 ? "GROUP" : "SECTOR"),
        permissions: details?.permissions || {}
      }
    }
    else {
      return {
        permissions: {},
        associationType: "SECTOR",
      }
    }
    // eslint-disable-next-line
  }, [details, selectedEntity])

  const onSubmit = async (values) => {
    let { user, group, sector, associationType } = values;

    switch (associationType) {
      case "USER":
        group = undefined;
        sector = undefined;
        break;
      case "GROUP":
        user = undefined;
        sector = undefined;
        break;
      case "SECTOR":
        user = undefined;
        group = undefined;
        break;
      default:
        user = undefined;
        group = undefined;
        sector = undefined;
        break;
    }

    if (id) {
      dispatch(rolesUpdate({ ...values, id, user, group, sector, }));
    }
    else {
      dispatch(rolesCreate({ ...values, user, group, sector, entityId: entity?.entityId, entityType: entity?.type }));
    }
  };

  const onEntityChangeHandler = (entity) => {
    setSelectedEntity(entity?.entityId);
  }

  const isDefault = () =>
  {
    if(details?.roleType === "A")
      return true;
    else return (details?.roleType !== "C" && !details?.entityId);
  }

  return !id || details?.id === id ? (
    <div>
      <Form
        onSubmit={onSubmit}
        schema={formSchema({ t, isDefault: Boolean(isDefault()), isEdit: Boolean(id) })}
        initialValues={initialValues}
        // keepDirtyOnReinitialize
        render={({ submitting, values }) => {
          return (
            <Page
              permission="promoter.actions.role.get"
              header={<PageTitle
                onBack={() => navigate("/roles")}
                title={t("roles.RolesForm.form.permissions")}
                actions={
                  <Grid container direction="row" spacing={2}>
                    <Permission code="promoter.actions.role.update">
                      <Grid item>
                        <Button variant="contained" color="primary" type="submit" size="large" disabled={submitting}>
                          {t("roles.RolesForm.header.submitBtn")}
                        </Button>
                      </Grid>
                    </Permission>
                  </Grid>
                }
              />}>
              <Paper className={classes.paper}>
                <Stack spacing={4}>
                  {!id &&
                    <EntityAutoComplete
                      label={t("roles.RolesForm.form.entity")}
                      typeFilter={["P", "S", "W", "LO", "ST"]}
                      value={selectedEntity}
                      onChange={onEntityChangeHandler}
                    />
                  }
                  {id && <Grid container spacing={1}>
                    {details?.entityId && <Grid item xs={6}>
                      <LabelValue
                        label={t("roles.RolesForm.form.entityName")}
                        value={
                          <Typography variant="body1" align="left" component="span">
                            {details?.entityName}
                          </Typography>
                        }
                      />
                    </Grid>}
                    <Grid item xs={3}>
                      <LabelValue
                        label={t("roles.RolesForm.form.entityType")}
                        value={
                          <Typography variant="body1" align="left" component="span">
                            {details?.entityType ? t("common.Enums.entityType." + details?.entityType) : "-"}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <LabelValue
                        label={t("roles.RolesForm.form.roleType")}
                        value={
                          <Typography variant="body1" align="left" component="span">
                            {details?.roleType ? t("common.Enums.roleType." + details?.roleType) : "-"}
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>}
                  <div>
                    {(!id || (details?.entityId && details?.roleType !== "A")) && <FormFields fields={formFields({ t, contacts: entity?.contacts, sectorsGroups: entity?.sectorsGroups, promoter, values, isEdit: Boolean(id) })} />}

                    <br />
                    <br />
                    <Accordion expanded={expandedPanels.business} onChange={() => values?.permissions?.business?.available && setExpandedPanels(prev => ({ ...prev, business: !prev.business }))}>
                      <AccordionSummary expandIcon={values?.permissions?.business?.available && <ExpandMoreIcon />}>
                        <FeatureAvailableField id="permissions.business.available" label="Business" onCheck={(check) => setExpandedPanels(prev => ({ ...prev, business: check }))} />
                      </AccordionSummary>
                      <AccordionDetails>
                        <SelectAllField baseKey="business" />
                        <FormFields masonry fields={businessFormFields(t, values)} />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expandedPanels.entities} onChange={() => values?.permissions?.entities?.available && setExpandedPanels(prev => ({ ...prev, entities: !prev.entities }))}>
                      <AccordionSummary expandIcon={values?.permissions?.entities?.available && <ExpandMoreIcon />}>
                        <FeatureAvailableField id="permissions.entities.available" label="Entities" onCheck={(check) => setExpandedPanels(prev => ({ ...prev, entities: check }))} />
                      </AccordionSummary>
                      <AccordionDetails>
                        <SelectAllField baseKey="entities" />
                        <FormFields masonry fields={entitiesFormFields({ t, values, entityType: details?.entityType })} />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expandedPanels.home} onChange={() => values?.permissions?.home?.available && setExpandedPanels(prev => ({ ...prev, home: !prev.home }))}>
                      <AccordionSummary expandIcon={values?.permissions?.home?.available && <ExpandMoreIcon />}>
                        <FeatureAvailableField id="permissions.home.available" label="Home" onCheck={(check) => setExpandedPanels(prev => ({ ...prev, home: check }))} />
                      </AccordionSummary>
                      <AccordionDetails>
                        <SelectAllField baseKey="home" />
                        <FormFields masonry fields={homeFormFields(t)} />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expandedPanels.orders} onChange={() => values?.permissions?.orders?.available && setExpandedPanels(prev => ({ ...prev, orders: !prev.orders }))}>
                      <AccordionSummary expandIcon={values?.permissions?.orders?.available && <ExpandMoreIcon />}>
                        <FeatureAvailableField id="permissions.orders.available" label="Orders" onCheck={(check) => setExpandedPanels(prev => ({ ...prev, orders: check }))} />
                      </AccordionSummary>
                      <AccordionDetails>
                        <SelectAllField baseKey="orders" />
                        <FormFields masonry fields={ordersFormFields(t)} />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expandedPanels.products} onChange={() => values?.permissions?.products?.available && setExpandedPanels(prev => ({ ...prev, products: !prev.products }))}>
                      <AccordionSummary expandIcon={values?.permissions?.products?.available && <ExpandMoreIcon />}>
                        <FeatureAvailableField id="permissions.products.available" label="Products" onCheck={(check) => setExpandedPanels(prev => ({ ...prev, products: check }))} />
                      </AccordionSummary>
                      <AccordionDetails>
                        <SelectAllField baseKey="products" />
                        <FormFields masonry fields={productsFormFields({ t, values, entityType: details?.entityType })} />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expandedPanels.tasks} onChange={() => values?.permissions?.tasks?.available && setExpandedPanels(prev => ({ ...prev, tasks: !prev.tasks }))}>
                      <AccordionSummary expandIcon={values?.permissions?.tasks?.available && <ExpandMoreIcon />}>
                        <FeatureAvailableField id="permissions.tasks.available" label="Tasks" onCheck={(check) => setExpandedPanels(prev => ({ ...prev, tasks: check }))} />
                      </AccordionSummary>
                      <AccordionDetails>
                        <SelectAllField baseKey="tasks" />
                        <FormFields masonry fields={tasksFormFields({ t })} />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expandedPanels.planning} onChange={() => values?.permissions?.planning?.available && setExpandedPanels(prev => ({ ...prev, planning: !prev.planning }))}>
                      <AccordionSummary expandIcon={values?.permissions?.planning?.available && <ExpandMoreIcon />}>
                        <FeatureAvailableField id="permissions.planning.available" label="Planning" onCheck={(check) => setExpandedPanels(prev => ({ ...prev, planning: check }))} />
                      </AccordionSummary>
                      <AccordionDetails>
                        <SelectAllField baseKey="planning" />
                        <FormFields masonry fields={planningFormFields({ t })} />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expandedPanels.batches} onChange={() => values?.permissions?.batches?.available && setExpandedPanels(prev => ({ ...prev, batches: !prev.batches }))}>
                      <AccordionSummary expandIcon={values?.permissions?.batches?.available && <ExpandMoreIcon />}>
                        <FeatureAvailableField id="permissions.batches.available" label="Batches" onCheck={(check) => setExpandedPanels(prev => ({ ...prev, batches: check }))} />
                      </AccordionSummary>
                      <AccordionDetails>
                        <SelectAllField baseKey="batches" />
                        <FormFields masonry fields={batchesFormFields({ t })} />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expandedPanels.promoter} onChange={() => values?.permissions?.promoter?.available && setExpandedPanels(prev => ({ ...prev, promoter: !prev.promoter }))}>
                      <AccordionSummary expandIcon={values?.permissions?.promoter?.available && <ExpandMoreIcon />}>
                        <FeatureAvailableField id="permissions.promoter.available" label="Promoter" onCheck={(check) => setExpandedPanels(prev => ({ ...prev, promoter: check }))} />
                      </AccordionSummary>
                      <AccordionDetails>
                        <SelectAllField baseKey="promoter" />
                        <FormFields masonry fields={promoterFormFields(t)} />
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </Stack>
              </Paper>
            </Page>
          );
        }}
      />

    </div>
  ) : null;
}

export default withStyles(RolesForm, styles);
import {call} from "../../../app/websocket/_sliceCalls/websocket";
import {requestHelper} from "../../../app/coreSlice";

const productPriceHistoryListCall = async (
  { clearPriceHistoryList, setPriceHistoryListFilter, appendToPriceHistoryList, setPriceHistoryListTotalRows, updatePriceHistoryListThumbnails },
  dispatch,
  state,
  { filter, clear }
) => {

  let rh = requestHelper(dispatch, "PRODUCT_LIST_PRICE_HISTORY");
  if (clear) {
    dispatch(clearPriceHistoryList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setPriceHistoryListFilter(filter));
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "LIST_PRICE_HISTORY",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    dispatch(appendToPriceHistoryList(result.items));
    dispatch(setPriceHistoryListTotalRows(result.rows));
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productPriceHistoryListCall;

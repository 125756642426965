import React from "react";
import {withStyles} from "tss-react/mui";

import {styles} from "./CutOffCreate.styles";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createCutOff, selectCutOffDetails, setCutOffDetails, updateCutOff} from "../entitiesSlice";
import {useFirstLoad, usePromoter} from "../../../common/hooks";
import moment from "moment/moment";
import Form from "../../../common/forms/Form";
import {formFields, formSchema} from "./CutOffCreateForm.schema";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {Button, Grid, Paper} from "@mui/material";
import Permission from "../../../app/components/Permission";
import {FormFields} from "../../../common/forms";

function CutOffCreate({ classes }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const details = useSelector(selectCutOffDetails);
    const { id } = useParams();
    const promoter = usePromoter();

    useFirstLoad(() => {
        if (!id) {
            dispatch(setCutOffDetails({}));
        }
    });

    const onSubmit = async (values) => {
        if (!id) {
            dispatch(createCutOff(convertSubmit(values)));
        } else {
            dispatch(updateCutOff(id, convertSubmit(values)));
        }
    };

    const convertSubmit = (values) => {
        let momentTime = moment(values.time);
        return {
            entityId: values.entityId,
            type: values.cutOffType,
            description: values.description,
            dayOfMonth: values.frequency === "dayOfMonth" ? values.dayOfMonth : undefined,
            dayOfWeek: values.frequency === "dayOfWeek" ? values.dayOfWeek : undefined,
            hour: momentTime.hours(),
            minute: momentTime.minutes(),
            deliveryDay: values.deliveryDay,
        };
    };

    return (
        <div>
            <Form
                onSubmit={onSubmit}
                schema={formSchema(t)}
                initialValues={details}
                render={({ submitting, values }) => {
                    return (
                        <Page
                            permission={id ? "promoter.actions.cutoff.update" : "promoter.actions.cutoff.create"}
                            header={<PageTitle
                                title={
                                    id ? t("entities.CutOffCreate.header.editTitle") : t("entities.CutOffCreate.header.title")
                                }
                                onBack={() => navigate("/LGT/entities/cutoffs")}
                                actions={
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item>
                                            <Permission code="promoter.actions.cutoff.create">
                                                <Button variant="contained" color="primary" type="submit" size="large" disabled={submitting}>
                                                    {t("entities.CutOffCreate.form.submitBtn")}
                                                </Button>
                                            </Permission>
                                        </Grid>
                                    </Grid>
                                }
                            />}>
                            <Paper className={classes.paper} elevation={0}>
                                <FormFields fields={formFields(t, Boolean(id), values, promoter)} />
                            </Paper>
                        </Page>
                    );
                }}
            />
        </div>
    );
}

export default withStyles(CutOffCreate, styles)

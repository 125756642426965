

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const productSetExtraPriceCall = async (
  { productExtraPriceList, clearTempExtraPrices },
  dispatch,
  state,
  prices
) => {
  let rh = requestHelper(dispatch, "PRODUCT_SET_EXTRA_PRICE");
  let filter = state.productsPrices.extraPriceListFilter;
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "SET_EXTRA_PRICE",
      request: {
        extraPrices: prices.map(({ priceId, productId, extraPrice, priceType, locations }) => ({
          productId,
          priceId,
          extraPrice,
          priceType, 
          locations
        }))
      }
    });
    if (result) {
      dispatch(clearTempExtraPrices());
    }
    setTimeout(() => {
      if (result) {
        dispatch(setSuccess("productsPrices", "PRODUCT_SET_EXTRA_PRICE"));
        dispatch(productExtraPriceList(filter, true))
      }
    }, 300);
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productSetExtraPriceCall;

import * as Yup from "yup";
import { getContactOptions, getGroupOptions, getSectorOptions } from "./ReassignModal.options";
import { MultiSelectField, SelectField } from "../../../forms";


export function formSchema({ t }) {
  return Yup.object().shape({
    users: Yup.array().when("associationType",
      {
        is: "USER",
        then: schema => schema.ensure().min(1, t("common.ReassignModal.form.usersRequired")),
        otherwise: schema => schema,
      }),
    groups: Yup.array().when("associationType",
      {
        is: "GROUP",
        then: schema => schema.ensure().min(1, t("common.ReassignModal.form.groupsRequired")),
        otherwise: schema => schema,
      }),
    sectors: Yup.array().when("associationType",
      {
        is: "SECTOR",
        then: schema => schema.ensure().min(1, t("common.ReassignModal.form.sectorsRequired")),
        otherwise: schema => schema,
      }),
  });
}

export function formFields({ t, values, promoter, contacts, sectorsGroups }) {
  const associationTypeOptions = ["GROUP", "USER", "SECTOR"].map(item => ({
    value: item,
    text: t("roles.enum.associationType." + item),
  }))
  return [
    {
      size: 12,
      field: <SelectField
        id="associationType"
        options={associationTypeOptions}
        label={t("common.ReassignModal.form.associationType")}
      />,
    },
    {
      size: 12,
      field: (
        <MultiSelectField
          id="users"
          options={getContactOptions(contacts)}
          label={t("common.ReassignModal.form.users")}
          required
        />
      ),
      hidden: values?.associationType !== "USER"
    },
    {
      size: 12,
      field: (
        <MultiSelectField
          id="groups"
          options={getGroupOptions(sectorsGroups)}
          label={t("common.ReassignModal.form.groups")}
          required
        />
      ),
      hidden: values?.associationType !== "GROUP"
    },
    {
      size: 12,
      field: (
        <MultiSelectField
          id="sectors"
          options={getSectorOptions(t, promoter?.refData)}
          label={t("common.ReassignModal.form.sectors")}
          required
        />
      ),
      hidden: values?.associationType !== "SECTOR"
    },
  ];
}


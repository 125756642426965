import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Tabs, Tab, Paper } from "@mui/material";

import { styles } from "./Settings.styles";
import PasswordChangeForm from "./PasswordChangeForm/PasswordChangeForm";
import Preferences from "./Preferences/Preferences";
import Profile from "./Profile/Profile";

import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";

function Settings({ classes }) {
  const { t } = useTranslation();
  const [tab, setTab] = useState("profile");

  return (
    <Page
      header={<PageTitle
        title={t("users.Settings.header.title")}
      />}>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={2}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tab}
            onChange={(e, i) => setTab(i)}
            className={classes.tabs}
          >
            <Tab label={t("users.Settings.tabs.profile")} value="profile" />
            <Tab label={t("users.Settings.tabs.preferences")} value="preferences" />
            <Tab label={t("users.Settings.tabs.security")} value="security" />
          </Tabs>
        </Grid>
        <Grid item xs={10}>
          <Paper className={classes.paper} elevation={0}>
            <div hidden={tab !== "profile"}>
              <Profile />
            </div>
            <div hidden={tab !== "preferences"}>
              <div style={{ maxWidth: 400 }}>
                <Preferences />
              </div>
            </div>
            <div hidden={tab !== "security"}>
              <div style={{ maxWidth: 400 }}>
                <PasswordChangeForm />
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Page>
  );
}

export default withStyles(Settings, styles);
export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
  },
  drawerFilter: {
    width: "25vw",
    padding: theme.spacing(2),
  },
});

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { Button, Grid } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import { styles } from "./OrdersMissingProducts.styles";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import OrdersMissingProductsDataGrid from "./OrdersMissingProductsDataGrid/OrdersMissingProductsDataGrid";

import {
  selectLoader,
  ordersMissingProductsAppend,
  selectOrdersMissingProducts,
  selectOrdersMissingProductsTotalRows,
  selectOrdersMissingProductsFilter,
} from "../ordersSlice.calls";

import { useNavigate, useUser } from "../../../common/hooks";
import Page from "../../../common/displays/Page/Page";

function OrdersMissingProducts({ classes }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = useSelector(selectOrdersMissingProducts);
  const filter = useSelector(selectOrdersMissingProductsFilter);
  const loading = useSelector(selectLoader);
  const totalRows = useSelector(selectOrdersMissingProductsTotalRows);
  const dispatch = useDispatch();
  const user = useUser();

  const onFilterChangeHandler = (f) => {
    dispatch(ordersMissingProductsAppend({ ...f.filter, seller: user?.entity?.type !== "P" ? user?.entity?.id : f.filter.seller }, true));
  };

  const refreshHandler = () => {
    dispatch(ordersMissingProductsAppend(filter, true));
  };

  return (
    <Page
      permission="orders.actions.missingProduct.list"
      header={<PageTitle
        title={t("orders.OrdersMissingProducts.header.title")}
        info={`${totalRows} ${t("orders.OrdersMissingProducts.header.rowsInfo")}`}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={refreshHandler}
                disabled={Boolean(loading)}
              >
                <RefreshIcon />
              </Button>
            </Grid>
          </Grid>
        }
      />}>
      <OrdersMissingProductsDataGrid
        data={data}
        filter={filter}
        totalRows={totalRows}
        onFilterChange={onFilterChangeHandler}
        onDispatch={(row) => navigate("/orders/missing-products/products/" + row.orderSellerId)}
      />
    </Page>
  );
}

export default withStyles(OrdersMissingProducts, styles);

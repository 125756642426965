import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const resourceGetCall = async (
  { setResourceDetails },
  dispatch,
  id
) => {
  let rh = requestHelper(dispatch, "RESOURCE_GET");
  try {
    let result = await call({
      type: "RESOURCE",
      subType: "GET",
      request: {
        id
      },
    });

    setTimeout(() => {
      if (result && result.resource) {
        dispatch(setResourceDetails({ ...result.resource, title: result.resource?.title || result.resource?.name })); // to change in BE name -> title
      }
    }, 300);

  } catch (ex) {
    rh.error("resources", ex);
  }
  rh.close();
};

export default resourceGetCall;


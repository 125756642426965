export const styles = (theme) => ({
  root: {},
  mosaic: {
    "& img": {
      maxWidth: "100%",
      maxHeight: 180,
      display: "block",
      margin: "0 auto",
    },
  },
  picBox: {
    height: 180,
    padding: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    padding: 10,
    height: 210,
  },
  offerMerch: {
    textAlign: "center",
    margin: 0,
    fontSize: 11,
    lineHeight: 1,
  },
});

import React from "react";
import {useSelector} from "react-redux";
import { withStyles } from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Divider, Grid, Typography} from "@mui/material";

import {selectCutOffDetails} from "../../entitiesSlice";
import {styles} from "./CutOffsDrawerDetails.styles";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";
import moment from "moment";

function CutOffsDrawerDetails({ classes, id, status, onClose }) {
  const { t } = useTranslation();
  const details = useSelector(selectCutOffDetails);

  if (details) {
    return (
      <div className={classes.root}>
        <Typography variant="h3" align="left" component="h2" className={classes.title}>
          {details.entityName}
        </Typography>
        <Typography variant="body1" align="left" component="span" color="textSecondary" className={classes.type}>
          {details.description}
        </Typography>

        <Divider className={classes.divider} />

        <Grid container direction="row" spacing={4}>
          {details.frequency === "dayOfMonth" && (
            <Grid item>
              <LabelValue
                label={t("entities.CutOffs.details.dayOfMonth")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {details.dayOfMonth}
                  </Typography>
                }
              />
            </Grid>
          )}

          {details.frequency === "dayOfWeek" && (
            <Grid item>
              <LabelValue
                label={t("entities.CutOffs.details.dayOfWeek")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {t("entities.CutOffs.dateType." + details.dayOfWeek)}
                  </Typography>
                }
              />
            </Grid>
          )}

          <Grid item>
            <LabelValue
              label={t("entities.CutOffs.details.time")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {("00" + details.hour).slice(-2) + ":" + ("00" + details.minute).slice(-2)}
                </Typography>
              }
            />
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={4}>
          <Grid item>
            <LabelValue
              label={t("entities.CutOffs.details.cutOffType")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {t("entities.CutOffs.dateType." + details.cutOffType)}
                </Typography>
              }
            />
          </Grid>
          <Grid item>
            <LabelValue
              label={t("entities.CutOffs.details.deliveryDay")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {t("entities.CutOffs.dateType." + details.deliveryDay)}
                </Typography>
              }
            />
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <LabelValue
          label={t("entities.CutOffs.details.lastCutOff")}
          value={
            <Typography variant="body1" align="left" component="span">
              {details.lastCutOff !== undefined ? moment(details.lastCutOff).format("yyyy-MM-DD HH:mm") : "-"}
            </Typography>
          }
        />

        <LabelValue
          label={t("entities.CutOffs.details.nextCutOff")}
          value={
            <Typography variant="body1" align="left" component="span">
              {details.nextCutOff !== undefined ? moment(details.nextCutOff).format("yyyy-MM-DD HH:mm") : "-"}
            </Typography>
          }
        />
        <Divider className={classes.divider} />

        {details.active && (
          <LabelValue
            label={t("entities.CutOffs.details.active")}
            value={
              <Typography variant="body1" align="left" component="span">
                {t("entities.CutOffs.active.true")}
              </Typography>
            }
          />
        )}

        {details.active === false && (
          <LabelValue
            label={t("entities.CutOffs.details.active")}
            value={
              <Typography variant="body1" align="left" component="span">
                {t("entities.CutOffs.active.false")}
              </Typography>
            }
          />
        )}
      </div>
    );
  }
  return "";
}

export default withStyles(CutOffsDrawerDetails, styles)

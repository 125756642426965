import * as Yup from "yup";
import {DateTimePickerField, MultiSelectField} from "../../../../common/forms";
import React from "react";
import moment from "moment";
import TextField from "../../../../common/forms/fields/TextField";
import {getLanguages} from "../../../promoter/PromoterConfigs/PromoterConfigs.options";

export const filterFields = () => (t) => {
  return [
    {
      size: 12,
      field: (
        <DateTimePickerField
          id="dateIni"
          label={t("business.audit.filter.dateIni")}
          maxDate={moment().toISOString()}
        />
      )
    },
    {
      size: 12,
      field: (
        <DateTimePickerField
          id="dateEnd"
          label={t("business.audit.filter.dateEnd")}
          maxDate={moment().toISOString()}
        />
      )
    },
    {
      size: 12,
      field: (
          <TextField
              id="username"
              label={t("business.audit.filter.username")}
          />
      ),
    },
    {
      size: 12,
      field: (
          <MultiSelectField
              id="language"
              options={getLanguages(t)}
              label={t("promoter.PromoterConfigs.form.adminLanguages")}
          />
      ),
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    startTime: Yup.string(),
    endTime: Yup.string(),
  });
};

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Drawer, List, Divider, LinearProgress, useMediaQuery } from "@mui/material";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import PerfectScrollbar from "react-perfect-scrollbar";
import BusinessIcon from '@mui/icons-material/Business';

import { logout } from "../../websocket/websocketSlice";
import { selectLoader } from "../../coreSlice";
import { usePromoter } from "../../../common/hooks/usePromoter";
import { useUser } from "../../../common/hooks/useUser";

import styles from "./Navigation.styles";
import NavLink from "./NavLink";
import NavLinkExpander from "./NavLinkExpander";
import UserMenu from "./UserMenu/UserMenu";
import "react-perfect-scrollbar/dist/css/styles.css";

import {
  HomeIcon,
  MenuIcon,
  PeopleIcon,
  OrdersIcon,
  InventoryIcon,
  DashboardIcon,
  TasksIcon
} from "../../../common/icons";
import Snack from "../Snack";
import { useLocation } from "react-router-dom";
import AlertsMain from "../../../features/alerts/AlertsMain/AlertsMain";
import SessionLost from "../SessionLost/SessionLost";

function Navigation({ classes, children }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const promoter = usePromoter();
  const user = useUser();
  const [expandedMenu, setExpandedMenu] = useState();
  const logoUrl = `${process.env.REACT_APP_PUBLIC_FILE_STORAGE}/${promoter?.details?.code?.toUpperCase()}/${process.env.REACT_APP_ENV}/logo/${promoter?.getConfig("admin", "logo")}`;
  const location = useLocation();
  const loader = useSelector(selectLoader);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const mobile = !useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const logoutHandler = () => {
    dispatch(logout());
  };

  const drawerContent = (
    <div className={classes.drawerContainer}>
      {promoter && (
        <div className={classes.logo + " logo"}>
          <div className="imgBox">
            <img src={logoUrl} alt="logo"></img>
          </div>
          <MenuIcon className={classes.menuIcon} />
        </div>
      )}
      <div className={classes.drawerContainerContent}>
        <PerfectScrollbar className={classes.drawerContainerMenu}>
          <List className={classes.menu}>
            {/* HOME */}
            <NavLink
              icon={<HomeIcon />}
              primary={t("common.Navigation.menu.home")}
              to="/"
              activeClass={classes.activeLink}
              hidden={!user?.rolePermission?.home?.available}
            />

            {/* TASKS */}
            <NavLinkExpander
              icon={<TasksIcon viewBox="0 0 18 20" />}
              primary={t("common.Navigation.menu.tasks")}
              activeClass={
                location.pathname.indexOf(promoter?.details?.code + "/tasks") > 0 ? classes.activeLink : undefined
              }
              hidden={!user?.rolePermission?.tasks?.available}
              expanded={expandedMenu === 1}
              onClick={() => setExpandedMenu((prev) => (prev === 1 ? null : 1))}
            >
              <NavLink
                primary={t("common.Navigation.menu.tasksList")}
                to="/tasks"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.tasks?.actions?.tasks?.list}
              />
            </NavLinkExpander>

            {/* ENTITIES */}
            <NavLinkExpander
              icon={<PeopleIcon />}
              primary={t("common.Navigation.menu.entities")}
              activeClass={
                location.pathname.indexOf(promoter?.details?.code + "/entities") > 0
                  ? classes.activeLink
                  : undefined
              }
              hidden={!user?.rolePermission?.entities?.available}
              expanded={expandedMenu === 2}
              onClick={() => setExpandedMenu((prev) => (prev === 2 ? null : 2))}
            >
              <NavLink
                primary={t("common.Navigation.menu.entitiesCreate")}
                to="/entities/add"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.entities?.actions?.entity?.create}
              />
              <NavLink
                primary={t("common.Navigation.menu.entitiesList")}
                to="/entities"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.entities?.actions?.entity?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.entitiesValidate")}
                to="/entities/validation"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.entities?.actions?.pendingEntity?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.entitiesGroups")}
                to="/entities/groups"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.entities?.actions?.group?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.cutOffs")}
                to="/entities/cutoffs"
                activeClass={classes.activeInnerLink}
                hidden={
                  !user?.rolePermission?.promoter?.actions?.cutoff?.list || promoter?.getConfig("cutOff", "automatic")
                }
              />
            </NavLinkExpander>

            {/* PRODUCTS */}
            <NavLinkExpander
              icon={<InventoryIcon />}
              // to={["W", "ST"].includes(user?.entity?.type) ? "/products/catalogs" : "/products"}
              primary={t("common.Navigation.menu.products")}
              activeClass={
                location.pathname.indexOf(promoter?.details?.code + "/products") > 0
                  ? classes.activeLink
                  : undefined
              }
              hidden={!user?.rolePermission?.products?.available}
              expanded={expandedMenu === 3}
              onClick={() => setExpandedMenu((prev) => (prev === 3 ? null : 3))}
            >
              <NavLink
                primary={t("common.Navigation.menu.productsList")}
                to="/products"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.products?.actions?.product?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.productsCatalogs")}
                to="/products/catalogs"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.products?.actions?.catalog?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.productsCampaigns")}
                to="/products/campaigns"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.products?.actions?.campaign?.list}
              />
              {/* <NavLink
                primary={t("common.Navigation.menu.productsPending")}
                to="/products/validation"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.products?.actions?.pendingProduct?.list || (
                  promoter?.getConfig("product", "autoAcceptCreate") &&
                  promoter?.getConfig("product", "autoAcceptUpdate") &&
                  promoter?.getConfig("product", "autoAcceptUpdateExternal"))
                }
              /> */}
              <NavLink
                primary={t("common.Navigation.menu.productsPrices")}
                to="/products/prices"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.products?.actions?.price?.list}
              />
              {/* <NavLink
                primary={t("common.Navigation.menu.productsPricesValidation")}
                to="/products/prices/validation"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.products?.actions?.pendingPrice?.list || promoter?.getConfig("price")?.autoAcceptPrice}
              /> */}
              <NavLink
                primary={t("common.Navigation.menu.productsExtraPrices")}
                to="/products/prices/extra"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.products?.actions?.extraPrice?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.productsPurchases")}
                to="/products/purchases"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.products?.actions?.purchase?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.dproms")}
                to="/products/dproms"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.products?.actions?.dprom?.list}
              />
              {/* <NavLink
                primary={t("common.Navigation.menu.dpromsValidation")}
                to="/products/dproms/validation"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.products?.actions?.pendingDprom?.list}
              /> */}
              <NavLink
                primary={t("common.Navigation.menu.taxes")}
                to="/products/taxes"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.products?.actions?.taxes?.list}
              />
              {/* <NavLink
                primary={t("common.Navigation.menu.taxesValidation")}
                to="/products/taxes/validation"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.products?.actions?.taxes?.action}
              /> */}
            </NavLinkExpander>

            {/* ORDERS */}
            <NavLinkExpander
              icon={<OrdersIcon viewBox="0 0 18 20" />}
              // to={["P", "LO"].includes(user?.entity?.type) ? "/orders/received" : "/orders"}
              primary={t("common.Navigation.menu.orders")}
              activeClass={
                location.pathname.indexOf(promoter?.details?.code + "/orders") > 0 ? classes.activeLink : undefined
              }
              hidden={!user?.rolePermission?.orders?.available}
              expanded={expandedMenu === 4}
              onClick={() => setExpandedMenu((prev) => (prev === 4 ? null : 4))}
            >
              <NavLink
                primary={t("common.Navigation.menu.ordersCreate")}
                to="/orders/add"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.orders?.actions?.placed?.create}
              />
              <NavLink
                primary={t("common.Navigation.menu.ordersPlaced")}
                to="/orders/placed"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.orders?.actions?.placed?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.ordersOperator")}
                to="/orders/operator"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.orders?.actions?.operator?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.ordersReceived")}
                to="/orders/received"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.orders?.actions?.received?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.ordersMissingProducts")}
                to="/orders/missing-products"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.orders?.actions?.missingProduct?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.ordersIncidents")}
                to="/orders-incidents/"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.orders?.actions?.incident?.list}
              />
            </NavLinkExpander>

            {/* ACTIVITY (OLD BUSINESS) */}
            <NavLinkExpander
              icon={<DashboardIcon />}
              // to={"/business"}
              primary={t("common.Navigation.menu.activity")}
              activeClass={
                location.pathname.indexOf(promoter?.details?.code + "/activity/") > 0
                  ? classes.activeLink
                  : undefined
              }
              hidden={!user?.rolePermission?.business?.available}
              expanded={expandedMenu === 5}
              onClick={() => setExpandedMenu((prev) => (prev === 5 ? null : 5))}
            >
              <NavLink
                primary={t("common.Navigation.menu.activityHistory")}
                to="/activity/history"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.business?.actions?.history?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.activityQueries")}
                to="/activity/queries"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.business?.actions?.queries?.list?.available}
              />
              <NavLink
                primary={t("common.Navigation.menu.activityReports")}
                to="/activity/reports"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.business?.actions?.report?.list?.available}
              />
              <NavLink
                primary={t("common.Navigation.menu.activityAudit")}
                to="/activity/audits"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.business?.actions?.audit?.list?.available}
              />
              <NavLink
                primary={t("common.Navigation.menu.activityAccounting")}
                to="/activity/accounting"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.business?.actions?.accounting?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.activityProcess")}
                to="/activity/process"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.business?.actions?.process?.list}
              />
            </NavLinkExpander>

            {/* BUSINESS */}
            <NavLinkExpander
              icon={<BusinessIcon />}
              // to={"/business"}
              primary={t("common.Navigation.menu.business")}
              activeClass={
                location.pathname.indexOf(promoter?.details?.code + "/business/") > 0
                  ? classes.activeLink
                  : undefined
              }
              hidden={!user?.rolePermission?.planning?.actions?.production?.list && !user?.rolePermission?.promoter?.actions?.businessUnit?.list && user.businessUnits?.length === 0 }
              expanded={expandedMenu === 7}
              onClick={() => setExpandedMenu((prev) => (prev === 7 ? null : 7))}
            >
              {
                (user.businessUnits || []).map((item, index) => (
                  <NavLink
                    key={"bu_link_" + index}
                    primary={item.name}
                    to={`/batches/${item.id}`}
                    activeClass={classes.activeInnerLink}
                  />
                ))
              }
              <Divider />
              <NavLink
                primary={t("common.Navigation.menu.planning")}
                to="/planning/"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.planning?.actions?.production?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.businessUnits")}
                to="/business/units"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.promoter?.actions?.businessUnit?.list}
              />
                 <NavLink
                primary={t("common.Navigation.menu.places")}
                to="/places"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.promoter?.actions?.places?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.resources")}
                to="/resources"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.promoter?.actions?.resources?.list}
              />
            </NavLinkExpander>
          </List>

          <Divider />

          {/* ADMIN */}

          <List className={classes.menu}>
            <NavLinkExpander
              icon={<WebAssetIcon />}
              primary={t("common.Navigation.menu.administration")}
              activeClass={
                location.pathname.indexOf(promoter?.details?.code + "/promoter") > 0 ||
                  location.pathname.indexOf(promoter?.details?.code + "/roles") > 0 ||
                  location.pathname.indexOf(promoter?.details?.code + "/documents") > 0 ||
                  location.pathname.indexOf(promoter?.details?.code + "/emails") > 0 ||
                  location.pathname.indexOf(promoter?.details?.code + "/i18n") > 0 ||
                  location.pathname.indexOf(promoter?.details?.code + "/places") > 0 ||
                  location.pathname.indexOf(promoter?.details?.code + "/timer") > 0 ||
                  location.pathname.indexOf(promoter?.details?.code + "/resources") > 0
                  ? classes.activeLink
                  : undefined
              }
              hidden={!user?.rolePermission?.promoter?.available}
              expanded={expandedMenu === 8}
              onClick={() => setExpandedMenu((prev) => (prev === 8 ? null : 8))}
            >
              <NavLink
                primary={t("common.Navigation.menu.promoter")}
                to="/promoter/configs"
                activeClass={classes.activeLink}
                hidden={!user?.rolePermission?.promoter?.actions?.configs?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.roles")}
                to="/roles"
                activeClass={classes.activeLink}
                hidden={!user?.rolePermission?.promoter?.actions?.role?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.i18n")}
                to="/i18n"
                activeClass={classes.activeLink}
                hidden={!user?.rolePermission?.promoter?.actions?.i18n?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.documents")}
                to="/documents"
                activeClass={classes.activeLink}
                hidden={!user?.rolePermission?.promoter?.actions?.document?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.emails")}
                to="/emails"
                activeClass={classes.activeLink}
                hidden={!user?.rolePermission?.promoter?.actions?.email?.list}
              />
              <NavLink
                primary={t("common.Navigation.menu.families")}
                to="/products/families"
                activeClass={classes.activeInnerLink}
                hidden={!user?.rolePermission?.promoter?.actions?.family?.list}
              />
              <NavLink
                  primary={t("common.Navigation.menu.activityTimer")}
                  to="/promoter/timer"
                  activeClass={classes.activeInnerLink}
                  hidden={!user?.rolePermission?.promoter?.actions?.timer?.list}
              />
              <NavLink
                  primary={t("common.Navigation.menu.systemStatus")}
                  to="/promoter/status"
                  activeClass={classes.activeInnerLink}
                  hidden={!user?.rolePermission?.promoter?.actions?.systemStatus?.list}
              />
           
              
            </NavLinkExpander>
          </List>
          <SessionLost />
        </PerfectScrollbar>
        <List className={classes.menu}>
          <AlertsMain />
          <UserMenu onLogout={logoutHandler} />
        </List>
      </div>
    </div>
  )

  return (
    <div className={classes.root}>
      {loader && <LinearProgress className={classes.loader} />}
      <input type="hidden" id="loggedUser" value={user?.username} />
      {mobile && <MenuIcon className={classes.menuIconMobile} onClick={() => setDrawerOpen(true)} />}
      {mobile && <Drawer
        anchor="left"
        open={drawerOpen}
        className={classes.drawer}
        PaperProps={{
          elevation: 1,
        }}
        onClose={() => setDrawerOpen(false)}
      >
        {drawerContent}
      </Drawer>
      }
      {!mobile && <Drawer
        variant="permanent"
        anchor="left"
        open={true}
        className={classes.drawer}
        PaperProps={{
          elevation: 1,
        }}
      >
        {drawerContent}
      </Drawer>
      }
      <div className={classes.content}>
        {children}
        <Snack />
      </div>
    </div>
  );
}

export default withStyles(Navigation, styles);

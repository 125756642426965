import { Stack } from "@mui/material";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import { CurrencyInput } from "../../../../common/inputs/custom";
import PricePromotions from "../../PricesList/PricesDataGrid/PricePromotions/PricePromotions";
import Currency from "../../../../common/displays/Currency/Currency";
import { DatePickerInput, SelectInput } from "../../../../common/inputs";
import { calculatePriceWithDproms, calculatePriceWithPromotions, getEntitiesInGroups, processDate } from "../../_common/auxiliars";
import CampaignTooltip from "../../../orders/_common/CampaignTooltip/CampaignTooltip";

export function listColumns({ t, user, promoter, onExtraPriceChange, entityGroups }) {
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];
  let columns = [];
  columns.push({
    field: "description",
    headerName: t("productsPrices.ExtraPriceList.columns.description"),
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      const showPromoterDescription = Boolean(enabledProductFields.includes("promoterDescription") && user?.entity?.type === "P" && v.promoterDescription);
      return <ProductSnip image={v.thumbnail} description={showPromoterDescription ? v.promoterDescription : v.description} gtin={v.gtin} promoterInternalCode={v.promoterInternalCode} />;
    },
  });
  columns.push({
    field: "unitDescriptor",
    headerName: t("productsPrices.ExtraPriceList.columns.unitDescriptor"),
    align: "left",
    headerAlign: "left",
    disableSort: true,
    customValue: (v) => {
     if(v.unitDescriptor)
       return t("common.Enums.transport."+v.unitDescriptor)
    }
  });
  if (user?.entity?.type === "P") {
    columns.push({
      field: "ownerName",
      headerName: t("productsPrices.ExtraPriceList.columns.ownerName"),
      align: "left",
      headerAlign: "left",
    });
  }

  if (promoter?.getConfig("price", "allowStandard") && promoter?.getConfig("price", "allowPricePromotions")) {
    columns.push({
      field: "promotions",
      headerName: t("productsPrices.ExtraPriceList.columns.promotions"),
      disableSort: true,
      align: "right",
      width: 40,
      customValue: (v) => {
        const { prices } = v;
        const priceObj = (prices || []).find(item => item.priceType === "S") || {};
        return (
          <Stack direction="row" alignItems="center" justifyContent="center">
            {priceObj.promotions && <PricePromotions price={priceObj} />}
          </Stack>
        );
      }
    });
  }

  if (promoter?.getConfig("price", "allowPriceCampaigns")) {
    columns.push({
      field: "campaigns",
      headerName: t("productsPrices.PricesList.columns.campaigns"),
      disableSort: true,
      align: "right",
      width: 20,
      customValue: (v) => {
        let availableCampaigns = (v.campaigns || []).filter((item) => item.active && (user?.isPromoter || getEntitiesInGroups(entityGroups, item.groups).includes(user?.entity?.id)));
        // should show all campaigns to promoter, or if the user entity is part of the groups associated or created the campaign

        return <Stack spacing={1} direction="row">
          {availableCampaigns.map((item, index) => (
            <div item key={`campaign_${index}`}>
              <CampaignTooltip campaign={item} forceHighlight groups={(entityGroups || []).filter(entityGroup => (item.groups || []).some(group => group === entityGroup.groupId))} />
            </div>
          ))}
        </Stack>
      }
    });
  }

  if (promoter?.getConfig("price", "allowStandard")) {
    columns.push({
      field: "salePrice",
      headerName: t("productsPrices.ExtraPriceList.columns.salePrice"),
      disableSort: true,
      align: "right",
      width: 180,
      customValue: (v) => {
        const { prices } = v;
        const priceObj = (prices || []).find(item => item.priceType === "S") || {};
        const { priceId } = priceObj;

        let price = parseFloat(priceObj?.salePrice || "0");
        if (price > 0) {
          const filteredPromotions = (priceObj?.promotions || []).filter(item => !item.toDelete);
          price = calculatePriceWithPromotions(price, filteredPromotions, promoter);
          price = calculatePriceWithDproms(price, priceObj?.dproms);
        }

        return priceId ? <Currency value={price} /> : null;
      }
    });
  }

  if (promoter?.getConfig("price", "allowStandard")) {
    columns.push({
      field: "extraPrice",
      headerName: t("productsPrices.ExtraPriceList.columns.extraPrice"),
      disableSort: true,
      align: "right",
      width: 400,
      customValue: (v) => {
        const allowedToChange = user?.entity?.type !== "P" || !promoter?.getConfig("product", "requiresInternalCode") || v.promoterInternalCode;
        const extraPriceTypes = promoter?.getConfig("price", "allowedExtraPriceTypes") || [];
        const { prices, productId } = v;
        const priceObj = (prices || []).find(item => item.priceType === "S") || {};
        const { priceId, priceType, locations } = priceObj;
        const extraPriceOptions = extraPriceTypes.map(item => ({ value: item, text: item }));
        const extraPrice = {
          value: priceObj?.newExtraPrice?.value || priceObj?.extraPrice?.value || "0",
          type: priceObj?.newExtraPrice?.type !== undefined ? priceObj.newExtraPrice.type : (priceObj?.extraPrice?.type || null),
          startDate: priceObj?.newExtraPrice?.startDate || priceObj?.extraPrice?.startDate || ""
        };
        return priceId ?
          <Stack direction="row" spacing={1}>
            <div style={{ minWidth: 180 }}>
              <CurrencyInput
                value={extraPrice.value}
                disabled={!allowedToChange}
                onChange={(value) => onExtraPriceChange({ productId, priceId, priceType, locations, newExtraPrice: { ...extraPrice, value } })}
              />
            </div>
            {extraPriceTypes.length > 0 &&
              <div style={{ minWidth: 140 }}>
                <SelectInput
                  options={extraPriceOptions}
                  value={extraPrice.type || ""}
                  allowEmpty
                  disabled={!allowedToChange}
                  clearLabel={t("productsPrices.ExtraPriceList.columns.extraPriceTypeClear")}
                  onChange={(type) => onExtraPriceChange({ productId, priceId, priceType, locations, newExtraPrice: { ...extraPrice, type } })}
                />
              </div>
            }
            {promoter?.getConfig("price", "allowExtraPriceStartDate") &&
              <Stack direction="row" spacing={1}>
                <div style={{ minWidth: 175 }}>
                  <DatePickerInput
                    value={extraPrice.startDate}
                    disabled={!allowedToChange}
                    maxDate={extraPrice.endDate}
                    onChange={(startDate) => onExtraPriceChange({ productId, priceId, priceType, locations, newExtraPrice: { ...extraPrice, startDate: processDate(startDate) } })}
                  />
                </div>
                <div style={{ minWidth: 175 }}>
                  <DatePickerInput
                    value={extraPrice.endDate}
                    disabled={!allowedToChange}
                    minDate={extraPrice.startDate}
                    onChange={(endDate) => onExtraPriceChange({ productId, priceId, priceType, locations, newExtraPrice: { ...extraPrice, endDate: processDate(endDate) } })}
                  />
                </div>
              </Stack>
            }
          </Stack> :
          null;
      }
    });
  }
  return columns;
}


export const getBusinessUnitTypeOptions = (t, promoter) => {
  let businessUnitTypesAllowed = promoter?.getConfig("general", "businessUnitTypes") || [];
  return businessUnitTypesAllowed.map((item) => {
    return {
      value: item,
      text: t("common.Enums.businessUnitTypes." + item),
    };
  });
}

export const getParentIdOptions = (t, parentBusinessUnits) => {
  return (parentBusinessUnits || []).map((item) => {
    return {
      value: item.id,
      text: t("common.Enums.businessUnitTypes." + item.type) + " - " + item.title,
    };
  });
}

export const getContactIdOptions = (contacts) => {
  return (contacts || []).map((item) => {
    return {
      value: item.id,
      text: item.firstName + " " + item.lastName,
    };
  });
};

export const getAddressIdOptions = (addresses) => {
  return (addresses || []).map((item) => {
    return {
      value: item.id,
      text: item.description,
    };
  });
};

export const getTemperatureOptions = (t, promoter) => {
  return (promoter?.refData?.temperature || []).map((item) => {
    return {
      value: item,
      text: t("common.Enums.temperature." + item),
    };
  });
};

export const getPlacesOptions = (places, selectedPlaces) => {
  // console.log('getPlacesOptions: %o', { places, selectedPlaces });
  return (places || [])
    .filter(item => item.type === "R")
    .map((item) => {
      return {
        value: item.id,
        text: item.title,
      };
    });
}
import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { Grid } from "@mui/material";
import { styles } from "./AuthenticationContainer.styles";
import back from "../../../features/authentication/res/back2.jpg";

function AuthenticationContainer({ classes, children, imgUrl }) {
  const [bgImg, setBgImg] = useState(imgUrl || back);

  const onImageErrorHandler = () => {
    setBgImg(imgUrl || back)
  }

  const onImageLoadHandler = () => {
    setBgImg(`/login_background.png`)
  }

  return (
    <div className={classes.root}>
      <div className={classes.leftContainer}>
        <Grid className={classes.grid} container direction="row" justifyContent="center" alignItems="center">
          {children}
        </Grid>
      </div>
      <div className={classes.rightContainer} style={{ backgroundImage: `url('${bgImg}')` }}>
        <img
          src={`/login_background.png`}
          onError={onImageErrorHandler}
          onLoad={onImageLoadHandler}
          alt="back"
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
}

export default withStyles(AuthenticationContainer, styles);

import {Button} from "@mui/material";
import React from "react";

export function listColumns(t, downloadAttachment) {
  return [
      {
          field: "description",
          headerName: t("business.accounting.Accounting.details_attachments_description"),
          align: "left",
          headerAlign: "left"
      },{
          field: "type",
          headerName: t("business.accounting.Accounting.details_attachments_type"),
          align: "left",
          headerAlign: "left",
          customValue: (v) => {
              return (t("business.accounting.options.attachment_type_"+v.type));
          },

      },{
          field: "url",
          headerName: t("business.accounting.Accounting.details_attachments_url"),
          align: "left",
          headerAlign: "left",
          customValue: (v) => {
              return  <Button
                  color="primary"
                  variant="contained"
                  onClick={() => downloadAttachment(v.url) }
              >
                  {t("business.accounting.Accounting.details_attachments_download")}
              </Button>;
          },


      }
  ];
}

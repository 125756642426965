export const styles = (theme) => ({
  root: {
    width: "100%",
    borderBottom: "1px solid #e0e0e0",
    padding: theme.spacing(1),
    paddingRight: 0,
    paddingLeft: 0,
    "&:hover": {
      background: theme.palette.background.default,
    },
    cursor: "pointer",
  },
  nomargin: {
    margin: 0,
  },
  description: {
    fontSize: 13,
    lineHeight: 1.1,
    margin: 0,
  },
  date: {
    textAlign: "right",
    margin: 0,
    fontSize: 12,
    lineHeight: 1.1,
    marginTop: 5,
  },
  resolve: {
    height: 16,
    width: 16,
    cursor: "pointer",
  },
});

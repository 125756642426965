import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Checkbox, Stack } from "@mui/material";

import { styles } from "./TextDiff.styles.js";

function TextDiff({ classes, field, onCheck, checked, parsedField, viewMode }) {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container wrap="nowrap" alignItems="center" className={parsedField?.hasDiff ? classes.hasDiff : ""}>
        <Grid item flexGrow={1}>
          <Typography variant="caption" component="span" style={{ marginLeft: 8 }}>
            {t("products.ProductValidationForm.field." + field)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {parsedField.type === "LIST" ?
            <Stack spacing={1}>
              {parsedField?.value.map((item, index) => <Typography key={"value_" + index} variant="body1" component="span" style={{ margin: 0 }}>
                {item}
              </Typography>)}
            </Stack>
            :
            <Typography variant="body1" component="span">
              {parsedField?.value}
            </Typography>
          }
        </Grid>
        <Grid item xs={5}>
          {parsedField.type === "LIST" ?
            <Stack spacing={1}>
              {parsedField?.newValue.map((item, index) => <Typography key={"value_" + index} variant="body1" component="span" style={{ margin: 0 }}>
                {item}
              </Typography>)}
            </Stack>
            :
            (parsedField?.diff || []).map((part, index) =>
              part.added ? (
                <Typography key={field + "_" + index} variant="body1" component="span" className={classes.added}>
                  {part.value}
                </Typography>
              ) : part.removed ? (
                <Typography key={field + "_" + index} variant="body1" component="span" className={classes.removed}>
                  {part.value}
                </Typography>
              ) : (
                <Typography key={field + "_" + index} variant="body1" component="span">
                  {part.value}
                </Typography>
              )
            )}
        </Grid>
        <Grid item style={{ width: 40 }}>
          {parsedField?.hasDiff && !viewMode && (
            <Checkbox color="success" checked={checked} onChange={(e) => onCheck(e.target.checked, field)} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(TextDiff, styles);

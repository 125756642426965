import React, { useCallback, useEffect } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./AccountingDataGrid.styles";
import { listColumns } from "./AccountingDataGrid.columns";

function AccountingDataGrid({
  classes,
  data,
  filter,
  totalRows,
  onRowSelected,
  onFilterChange,
  onLoadMore,
  checkedItems,
  setCheckedItems
}) {
  const { t } = useTranslation();

  useEffect(() => {
    let checkedItemsTemp = {};
    (data || []).forEach(item => {
      checkedItemsTemp[item.id] = item.availableSend;
    });
    setCheckedItems(checkedItemsTemp);
    // eslint-disable-next-line
  }, [data])

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onCheckChangedHandler = (nValues) => {
    setCheckedItems(nValues);
  }

  const onCheckAllHandler = useCallback(() => {
    let obj = { ...checkedItems };
    const allTrue = Object.values(obj).every(Boolean);
    for (let key in obj) {
      obj[key] = !allTrue;
    }
    setCheckedItems(obj);
    // eslint-disable-next-line
  }, [checkedItems]);

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          columns={listColumns(t, classes, checkedItems, onCheckChangedHandler, onCheckAllHandler)}
          rows={data || []}
          onFilter={onFilterHandler}
          onLoadMore={onLoadMore}
          onRowSelected={onRowSelected}
          total={totalRows}
        />
      )}
    </div>
  );
}

export default withStyles(AccountingDataGrid, styles)

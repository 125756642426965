export const styles = (theme) => ({
  root: {},
  error: {
    minHeight: 13,
    marginTop: 2,
    "& .MuiTypography-caption": {
      display: "block",
      lineHeight: "1.1 !important",
      fontSize: 11,
    },
  },
  disabledLabel: {
    color: "#97A1AF",
  },
  modal: {
    paddingTop: "30vh",
  },
  modalroot: {
    width: "70vw",
    maxWidth: 400,
    margin: "0 auto",
    backgroundColor: theme.palette.secondary.main,
    height: 340,
    padding: theme.spacing(2),
  },
});

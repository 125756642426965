import { Typography } from "@mui/material";
import { CheckboxField, MultiSelectField, NumberField } from "../../../../common/forms";

export const notificationsFields = ({ t, user, values, formOptions }) => {
  return [
    // ENTITY
    {
      size: 12,
      field: (
        <Typography variant="h3" style={{ borderBottom: "1px solid #aaa", paddingBottom: 4 }}>
          {t("promoter.PromoterConfigs.form.notificationTitleEntities")}
        </Typography>
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.checkDocExpEntity")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.checkDocExpEntity.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.checkDocExpEntity.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.checkDocExpEntity.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <NumberField
          id="functional.document.daysBeforeExpireEntity"
          label={t("promoter.PromoterConfigs.form.daysBeforeExpireEntity")}
          options={formOptions.sector}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.acceptEntity")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.acceptEntity.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.acceptEntity.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.acceptEntity.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.validateEntity")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.validateEntity.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.validateEntity.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.validateEntity.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.acceptValidateEntity")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.acceptValidateEntity.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.acceptValidateEntity.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.acceptValidateEntity.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },

    // PRODUCT
    {
      size: 12,
      field: (
        <Typography variant="h3" style={{ borderBottom: "1px solid #aaa", paddingBottom: 4 }}>
          {t("promoter.PromoterConfigs.form.notificationTitleProducts")}
        </Typography>
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.notificationAcceptValidateProductOwner")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.acceptValidateProductOwner.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.acceptValidateProductOwner.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.acceptValidateProductOwner.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.notificationAcceptProduct")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.acceptProduct.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
          disabled={Boolean(values?.product?.autoAcceptCreate)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.acceptProduct.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
          disabled={Boolean(values?.product?.autoAcceptCreate)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.acceptProduct.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
          disabled={Boolean(values?.product?.autoAcceptCreate)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: (
        <Typography variant="caption">{t("promoter.PromoterConfigs.form.notificationValidateProduct")}</Typography>
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.validateProduct.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
          disabled={Boolean(values?.product?.autoAcceptCreate && values?.product?.autoAcceptUpdate && values?.product?.autoAcceptUpdateExternal)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.validateProduct.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
          disabled={Boolean(values?.product?.autoAcceptCreate && values?.product?.autoAcceptUpdate && values?.product?.autoAcceptUpdateExternal)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.validateProduct.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
          disabled={Boolean(values?.product?.autoAcceptCreate && values?.product?.autoAcceptUpdate && values?.product?.autoAcceptUpdateExternal)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },

    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.checkDocExpProduct")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.checkDocExpProduct.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.checkDocExpProduct.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.checkDocExpProduct.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <NumberField
          id="functional.document.daysBeforeExpireProduct"
          label={t("promoter.PromoterConfigs.form.daysBeforeExpireProduct")}
          options={formOptions.sector}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },

    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.checkPurchase")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.checkPurchase.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
          disabled={Boolean(values?.price?.autoAcceptPurchase)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.checkPurchase.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
          disabled={Boolean(values?.price?.autoAcceptPurchase)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.checkPurchase.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
          disabled={Boolean(values?.price?.autoAcceptPurchase)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },

    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.checkPurchaseProposal")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.checkPurchaseProposal.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
          disabled={Boolean(values?.price?.autoAcceptPurchase)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.checkPurchaseProposal.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
          disabled={Boolean(values?.price?.autoAcceptPurchase)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.checkPurchaseProposal.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
          disabled={Boolean(values?.price?.autoAcceptPurchase)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.acceptTax")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.acceptTax.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.acceptTax.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.acceptTax.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.processTax")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.processTax.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.processTax.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.processTax.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },

    // PRICES
    {
      size: 12,
      field: (
        <Typography variant="h3" style={{ borderBottom: "1px solid #aaa", paddingBottom: 4 }}>
          {t("promoter.PromoterConfigs.form.notificationTitlePrices")}
        </Typography>
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.notificationAcceptPrice")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.acceptPrice.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.acceptPrice.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.acceptPrice.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.notificationProcessPrice")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.processPrice.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.processPrice.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.processPrice.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.notificationAcceptPromoDiscount")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.acceptPromoDiscount.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.acceptPromoDiscount.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.acceptPromoDiscount.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.notificationProcessPromoDiscount")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.processPromoDiscount.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.processPromoDiscount.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.processPromoDiscount.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.notificationCheckPromoDiscountExp")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.checkPromoDiscountExp.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.checkPromoDiscountExp.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.checkPromoDiscountExp.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    
    
    // PRODUCT PURCHASES
    {
      size: 12,
      field: (
        <Typography variant="h3" style={{ borderBottom: "1px solid #aaa", paddingBottom: 4 }}>
          {t("promoter.PromoterConfigs.form.notificationTitleProductPurchases")}
        </Typography>
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.checkPurchase")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.checkPurchase.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.checkPurchase.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.checkPurchase.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.checkPurchaseProposal")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.checkPurchaseProposal.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.checkPurchaseProposal.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.checkPurchaseProposal.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.processPurchase")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.processPurchase.alert"
          label={t("promoter.PromoterConfigs.form.notificationAlert")}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <CheckboxField
          id="functional.notification.processPurchase.email"
          label={t("promoter.PromoterConfigs.form.notificationEmail")}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
        <MultiSelectField
          id="functional.notification.processPurchase.sector"
          label={t("promoter.PromoterConfigs.form.notificationDepartment")}
          options={formOptions.sector}
          disabled={Boolean(values?.price?.autoAcceptPrice)}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },

    // TASKS
    {
      size: 12,
      field: (
          <Typography variant="h3" style={{ borderBottom: "1px solid #aaa", paddingBottom: 4 }}>
            {t("promoter.PromoterConfigs.form.notificationTitleTasks")}
          </Typography>
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.checkTask")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
          <CheckboxField
              id="functional.notification.checkTask.alert"
              label={t("promoter.PromoterConfigs.form.notificationAlert")}
              //disabled={Boolean(values?.price?.autoAcceptPrice)}
          />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
          <CheckboxField
              id="functional.notification.checkTask.email"
              label={t("promoter.PromoterConfigs.form.notificationEmail")}
              //disabled={Boolean(values?.price?.autoAcceptPrice)}
          />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
          <MultiSelectField
              id="functional.notification.checkTask.sector"
              label={t("promoter.PromoterConfigs.form.notificationDepartment")}
              options={formOptions.sector}
             // disabled={Boolean(values?.price?.autoAcceptPrice)}
          />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.processTask")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
          <CheckboxField
              id="functional.notification.processTask.alert"
              label={t("promoter.PromoterConfigs.form.notificationAlert")}
              //disabled={Boolean(values?.price?.autoAcceptPrice)}
          />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
          <CheckboxField
              id="functional.notification.processTask.email"
              label={t("promoter.PromoterConfigs.form.notificationEmail")}
              //disabled={Boolean(values?.price?.autoAcceptPrice)}
          />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
          <MultiSelectField
              id="functional.notification.processTask.sector"
              label={t("promoter.PromoterConfigs.form.notificationDepartment")}
              options={formOptions.sector}
             // disabled={Boolean(values?.price?.autoAcceptPrice)}
          />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
      field: <Typography variant="caption">{t("promoter.PromoterConfigs.form.checkTaskDueDate")}</Typography>,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
          <CheckboxField
              id="functional.notification.checkTaskDueDate.alert"
              label={t("promoter.PromoterConfigs.form.notificationAlert")}
          />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
          <CheckboxField
              id="functional.notification.checkTaskDueDate.email"
              label={t("promoter.PromoterConfigs.form.notificationEmail")}
          />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
          <MultiSelectField
              id="functional.notification.checkTaskDueDate.sector"
              label={t("promoter.PromoterConfigs.form.notificationDepartment")}
              options={formOptions.sector}
          />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 3,
      field: (
          <NumberField
              id="functional.task.daysBeforeTaskDueDate"
              label={t("promoter.PromoterConfigs.form.daysBeforeExpireEntity")}
              options={formOptions.sector}
          />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },

  ];
};
 
import React from "react";
import { withStyles } from "tss-react/mui";
import { Field } from "react-final-form";
import MetaError from "../MetaError";
import { styles } from "../fields/_base.styles";
import ProductSelect from "../../inputs/custom/ProductSelect/ProductSelect";

function ProductSelectField({ classes, id, label, disabled, note, products, emptyMsg, errorMsg, required }) {
  const onChangeHandler = (v, input) => {
    input.onChange && input.onChange(v);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <>
            <ProductSelect
              id={id}
              label={label}
              required={required}
              value={input.value}
              products={products}
              onChange={(value) => onChangeHandler(value, input)}
              disabled={disabled}
              note={note}
              emptyMsg={emptyMsg} 
              errorMsg={errorMsg}
            />
            <div className={classes.error}>
              <MetaError meta={meta} />
            </div>
          </>
        )}
      </Field>
    </div>
  );
}

export default withStyles(ProductSelectField, styles);

import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography } from "@mui/material";
import { Field, useForm } from "react-final-form";
import { styles } from "./Volumes.styles";
import MetaError from "../../../../../common/forms/MetaError";
import VolumesModal from "../../inputs/VolumesModal/VolumesModal";

function Volumes({ classes, id, label, disabled, required, products, quantities }) {
  const form = useForm();

  return (
    <div className={classes.root}>
      <Typography
        component="span"
        variant="caption"
        htmlFor={id}
        color="textPrimary"
        className={disabled ? classes.disabledLabel : ""}
      >
        {label}
        {required && <span> *</span>}
      </Typography>
      <Field name={id}>
        {({ input, meta }) => {
          return (
            <div style={{ textAlign: "right" }}>
              <VolumesModal volumes={input.value} products={form?.getFieldState('products')?.value || []} onChange={input.onChange} />

              <MetaError className={classes.error} meta={meta} />
            </div>
          );
        }}
      </Field>
    </div>
  );
}

export default withStyles(Volumes, styles)

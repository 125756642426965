import moment from "moment";
import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const auditListAuditDataCall = async ({ clearAuditDataList, setAuditDataList, setAuditDataListTotalRows, setAuditDataFilter }, dispatch, { filter, clear }) => {
  let rh = requestHelper(dispatch, "LIST_AUDIT_DATA");
  if (clear) {
    dispatch(clearAuditDataList());
  }

  const dateIni = (filter.isDefaultFilter || !filter.dateIni) ? moment().add(-1, "day").toISOString() : filter.dateIni;
  const dateEnd = (filter.isDefaultFilter || !filter.dateEnd) ? moment().toISOString() : filter.dateEnd;

  dispatch(setAuditDataFilter({
    ...filter,
    dateIni,
    dateEnd
  }));

  try {
    let result = await call({
      type: "AUDIT",
      subType: "LIST_AUDIT_DATA",
      request: {
        ...filter,
        dateIni,
        dateEnd
      }
    });

    if (result && result.items && result.items.length > 0) {
      dispatch(setAuditDataList(result.items));
      dispatch(setAuditDataListTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default auditListAuditDataCall;

import React from "react";
import { withStyles } from "tss-react/mui";
import { Chip, Grid, Paper, Typography } from "@mui/material";
import { styles } from "./HTMLEditor.styles";
import { Editor } from "@tinymce/tinymce-react";
import { useTranslation } from "react-i18next";

function HTMLEditor({ classes, label, initialValue, disabled, variables, editorRef }) {
  const { t } = useTranslation();

  const variableHandler = (variable) => {
    editorRef.current.execCommand("mceInsertContent", false, "$" + variable);
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Typography component="span" variant="caption" htmlFor="entityAutocomplete" color="textPrimary">
            {t(label)}
          </Typography>
          <Paper className={classes.paper}>
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              apiKey={process.env.REACT_APP_TINY_EDITOR_API_KEY}
              initialValue={initialValue}
              // inline
              disabled={disabled}
              init={{
                height: 600,
                menubar: true,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  // "print",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  // "fullpage",
                  "insertdatetime",
                  "media",
                  "table",
                  // "paste",
                  "code",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat",
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          {variables &&
            variables.map((itemArr, index) => (
              <div key={"var_" + index}>
                <Typography component="span" variant="caption" color="textPrimary">
                  {itemArr.label && t(itemArr.label)}
                </Typography>
                <Grid container spacing={1} direction="row">
                  {itemArr.vars &&
                    itemArr.vars.map((item, index) => (
                      <Grid item key={"editor_key_" + itemArr.label + "_" + item + "_" + index}>
                        <Chip label={item} variant="outlined" onClick={() => variableHandler(item)} />
                      </Grid>
                    ))}
                </Grid>
              </div>
            ))}
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(HTMLEditor, styles);

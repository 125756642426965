export const styles = (theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    overflow: "hidden",
    fontWeight: "bold",
    textOverflow: "ellipsis",
  },
  inactive: {
    backgroundColor: theme.palette.background.disabled,
  }
});
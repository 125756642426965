import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const fileStorage = process.env.REACT_APP_PUBLIC_FILE_STORAGE;

export const loadNewI18n = (promoter) => {
  i18next.init({
    resources: {},
    backend: {
      loadPath: `${fileStorage}/${promoter}/${
        process.env.REACT_APP_ENV
      }/i18n/messages_{{lng}}.json?t=${new Date().getTime()}`,
    },
    partialBundledLanguages: true,
  });
};

i18next
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "pt",
    supportedLngs: ["pt", "en", "es"],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {},
    backend: {
      loadPath:
        fileStorage +
        "/" +
        process.env.REACT_APP_DEFAULT_PROMOTER +
        "/" +
        process.env.REACT_APP_ENV +
        "/i18n/messages_{{lng}}.json?t=" +
        new Date().getTime(),
      //queryStringParams: { t },
    },
    partialBundledLanguages: true,
  });

export default i18next;

import React, { useCallback, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { ImageDraggable } from "./ImageDraggable.js";

export const ImageDropContainer = ({ images, onStopDragging, onDelete }) => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    if (images) {
      setCards(images);
    }
  }, [images]);

  const moveImage = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) => {
      let cards = [...prevCards];
      cards.splice(dragIndex, 1);
      cards.splice(hoverIndex, 0, prevCards[dragIndex]);
      return cards;
    });
  }, []);

  const renderImage = useCallback(
    (image, index) => {
      return (
        <ImageDraggable
          key={image.id}
          index={index}
          imageUrl={image.base64 || image.url}
          moveImage={moveImage}
          onStopDragging={() => onStopDragging(cards)}
          onDelete={() => onDelete(image.id)}
        />
      );
    },
    // eslint-disable-next-line
    [cards, onDelete]
  );

  return (
    <>
      <Grid container direction="column">
        {cards.map((image, i) => renderImage(image, i))}
      </Grid>
    </>
  );
};

import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Button, Grid, Paper, Typography} from "@mui/material";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

import {styles} from "./AuditExternalDetails.styles";

import {useFirstLoad, useNavigate} from "../../../common/hooks";
import {auditGetAuditExternal, auditRetryExternal, selectAuditExternalDetails} from "../businessSlice";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import LabelValue from "../../../common/displays/LabelValue/LabelValue";
import {dateTimeFormatWithMilliSeconds} from "../../../common/utils/formats";
import {jsonTryParse} from "../../../common/utils";

function AuditExternalDetails({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const details = useSelector(selectAuditExternalDetails);

  useFirstLoad(() => {
    dispatch(auditGetAuditExternal(id))
  });


  if (!details) {
    return null;
  }

  const onRetryHandler = () => {
    dispatch(auditRetryExternal(details?.wsid, details?.eventId));
  }

  const parsedRequest = jsonTryParse(details?.request);
  const parsedResponse = jsonTryParse(details?.response);

  return (
    <Page
      permission="business.actions.audit.get"
      header={
        <PageTitle
          title={details?.type + "/" + details?.subType}
          onBack={() => navigate('/activity/audits/external')}
          actions={<Grid container spacing={1}>
              {details?.hadError && !details?.retried && <Grid item>
                  <Button variant="contained" onClick={onRetryHandler}>{t("business.AuditExternalDetails.header.retryBtn")}</Button>
              </Grid>}
          </Grid>}
        />
      }>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.AuditExternalDetails.details.method")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                        {details?.method}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.AuditExternalDetails.details.error")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details?.error ? t("business.AuditExternalDetails.details.errorTrue") : t("business.AuditExternalDetails.details.errorFalse")}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.AuditExternalDetails.details.userRequest")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details?.userRequest}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.AuditExternalDetails.details.entity")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details?.entity}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={8}>
                <LabelValue
                  label={t("business.AuditExternalDetails.details.url")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details?.url}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.AuditExternalDetails.details.httpCode")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details?.httpCode}
                    </Typography>
                  }
                />
              </Grid>
                <Grid item xs={4}>
                <LabelValue
                  label={t("business.AuditExternalDetails.details.hadError")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details?.hadError ? t("business.AuditExternalDetails.details.true") : t("business.AuditExternalDetails.details.false")}
                    </Typography>
                  }
                />
              </Grid>
                <Grid item xs={4}>
                <LabelValue
                  label={t("business.AuditExternalDetails.details.retried")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details?.retried  ? t("business.AuditExternalDetails.details.true") : t("business.AuditExternalDetails.details.false")}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <LabelValue
                  label={t("business.AuditExternalDetails.details.requestTime")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {dateTimeFormatWithMilliSeconds(details?.requestTime)}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.AuditExternalDetails.details.request")}
                  value={
                    typeof parsedRequest === "object" ? <div className={classes.json}>
                      <JSONInput
                        id="json-input"
                        placeholder={parsedRequest}
                        viewOnly
                        locale={locale}
                        confirmGood={false}
                        theme={"light_mitsuketa_tribute"}
                      />
                    </div> : parsedRequest
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <LabelValue
                  label={t("business.AuditExternalDetails.details.responseTime")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {dateTimeFormatWithMilliSeconds(details?.responseTime)}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.AuditExternalDetails.details.response")}
                  value={
                    typeof parsedResponse === "object" ? <div className={classes.json}>
                      <JSONInput
                        id="json-input"
                        placeholder={parsedResponse}
                        viewOnly
                        locale={locale}
                        confirmGood={false}
                        theme={"light_mitsuketa_tribute"}
                      />
                    </div> : parsedResponse
                  }
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

export default withStyles(AuditExternalDetails, styles);

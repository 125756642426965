const styles = (theme) => ({
  root: {},
  snackBar: {
    position: "absolute",
    left: 0,
    right: 0,
    width: "270px",
    margin: "0 auto",
    bottom: 20,
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  form: {
    width: 270,
  },
  formDrunk: {
    transition: "transform 0.3s ease-in-out, scale 0.3s",
    "&:hover": {
      // transform: "rotate(-360deg)",
      scale: "1.2"
    }
  },
  link: {
    ...theme.typography.body1,
    textAlign: "center",
    display: "block !important",
    textDecoration: "none !important",
    color: theme.palette.text.secondary,
  },
  btn: {
    height: 50,
  },
});
export default styles;

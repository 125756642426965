export const styles = (theme) => ({
  root: {
  },
  chip: {
    "& .MuiChip-label": {
      margin: 0,
      padding: theme.spacing(0.5) + " " + theme.spacing(1),
      fontSize: 14
    }
  }
});
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Grid, Paper, Typography} from "@mui/material";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

import {styles} from "./AuditI18nDetails.styles";

import {useFirstLoad, useNavigate} from "../../../common/hooks";
import {auditGetAuditI18n, selectAuditI18nDetails} from "../businessSlice";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import LabelValue from "../../../common/displays/LabelValue/LabelValue";
import {dateTimeFormatWithMilliSeconds} from "../../../common/utils/formats";
import {jsonTryParse} from "../../../common/utils";

function AuditI18nDetails({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const details = useSelector(selectAuditI18nDetails);

  useFirstLoad(() => {
    dispatch(auditGetAuditI18n(id))
  });

  if (!details) {
    return null;
  }

  const parsedI18n = jsonTryParse(details?.i18n);

  return (
    <Page
      permission="business.actions.audit.get"
      header={
        <PageTitle
          title={t("business.AuditI18nDetails.header.title")}
          onBack={() => navigate('/activity/audits/i18n')}
        />
      }>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <LabelValue
                  label={t("business.AuditI18nDetails.details.promoter")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                        {details?.promoterCode}
                    </Typography>
                  }
                />
              </Grid>
                <Grid item xs={6}>
                <LabelValue
                  label={t("business.AuditI18nDetails.details.entity")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                        {details?.entity}
                    </Typography>
                  }
                />
              </Grid>
                <Grid item xs={6}>
                    <LabelValue
                        label={t("business.AuditI18nDetails.details.username")}
                        value={
                            <Typography variant="body1" align="left" component="span">
                                {details?.username}
                            </Typography>
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                <LabelValue
                  label={t("business.AuditI18nDetails.details.dateTime")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {dateTimeFormatWithMilliSeconds(details?.datetime)}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <LabelValue
                  label={t("business.AuditI18nDetails.details.i18n")}
                  value={
                    typeof parsedI18n === "object" ? <div className={classes.json}>
                      <JSONInput
                        id="json-input"
                        placeholder={parsedI18n}
                        viewOnly
                        locale={locale}
                        confirmGood={false}
                        theme={"light_mitsuketa_tribute"}
                      />
                    </div> : parsedI18n
                  }
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

export default withStyles(AuditI18nDetails, styles);

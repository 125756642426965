import React, { useRef, useState } from "react";
import { withStyles } from "tss-react/mui";
import { Avatar } from "@mui/material";

import { styles } from "./AvatarUpload.styles";

import { base64ToArrayBuffer, toBase64 } from "../../../utils";
import { usePromoter } from "../../../hooks";
import { saveFile } from "../../../../api/s3";

function AvatarUpload({ classes, keyId, folderToStore, displayName, imageSrc, onUploadComplete, onError }) {
  const inputFileRef = useRef();
  const promoter = usePromoter();
  const [localFile, setLocaFile] = useState();
  const { provider, privateBucket } = promoter?.getConfig("admin", "fileStorage");

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'bmp', 'svg', 'gif'];

    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        const fileBase64 = await toBase64(file);
        setLocaFile(fileBase64);
        await saveFile(provider, privateBucket, `${folderToStore}/${keyId}`, base64ToArrayBuffer(fileBase64), file.type);
        onUploadComplete && onUploadComplete(fileBase64);
      }
      else {
        onError && onError("NOT_SUPPORTED");
      }
    }
  };

  const handleClick = () => {
    inputFileRef.current.click();
  };


  return (
    <div className={classes.root}>
      <div className={classes.box}>
        <Avatar alt={displayName} src={localFile || imageSrc} onClick={handleClick} sx={{ width: 100, height: 100 }} />
        <input
          className={classes.fileuploadInput}
          type="file"
          ref={inputFileRef}
          onChange={(e) => handleUpload(e)}
        />
      </div>
    </div>
  );
}

export default withStyles(AvatarUpload, styles);
import * as Yup from "yup";
import { EntityAutoCompleteField, MultiSelectField, SelectField } from "../../../../common/forms";
import { getContactPointOptions, getFamiliesOptions } from "../../../products/ProductForm/ProductForm.options";

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    productFamily: Yup.array(),
    showDrafts: Yup.boolean(),
  });
};

export const filterFields = ({ families, groupContacts, user, promoter }) => (t) => {
  return [
    {
      size: 12,
      field: (
        <EntityAutoCompleteField
          typeFilter={promoter?.getConfig("product", "allowedEntityTypes")}
          id="owner"
          label={t("products.ProductList.filter.owner")}
          required
        />
      ),
      hidden: user?.entity?.type !== "P",
    },
    {
      size: 12,
      field: (
        <SelectField
          id="contactPoint"
          label={t("products.ProductList.filter.contactPoint")}
          options={[
            { value: null, text: t("products.ProductList.filter.allContactPointsOption") },
            ...getContactPointOptions(groupContacts)
          ]}
        />
      ),
      hidden: !(promoter?.getConfig("product", "listProductFields") || []).includes("contactPoint")
    },
    {
      size: 12,
      field: (
        <MultiSelectField
          id="productFamily"
          label={t("products.ProductList.filter.productFamily")}
          options={getFamiliesOptions(t, families)}
        />
      ),
    },
  ];
};


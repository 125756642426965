import * as Yup from "yup";
import { EntityAutoCompleteField, SelectField, TextField } from "../../../../common/forms";

export const filterFields = (user) => (t) => {
  return [
    {
      size: 12,
      field: (
        <EntityAutoCompleteField
          id="entityId"
          label={t("business.queries.filter.entity")}
          typeFilter={["S", "ST", "W", "LO"]}
        />
      ),
      hidden: !["P", "A"].includes(user?.entity?.type),
    },
    {
      size: 12,
      field: <TextField id="search" label={t("business.queries.filter.search")} />,
    },
    {
      size: 12,
      field: (
        <SelectField
          id="active"
          label={t("business.queries.filter.active")}
          options={getStatusOptions(t)}
        />
      ),
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    month: Yup.string(),
    year: Yup.string(),
    entityId: Yup.string(),
    discrepancies: Yup.string(),
    searchField: Yup.string(),
    search: Yup.string(),
    active: Yup.string(),
  });
};

const getStatusOptions = (t) => {
  return ["NONE", "true", "false"].map(
    (item) => {
      return {
        value: item,
        text: t("business.queries.ProductPricesList.status_" + item),
      };
    }
  );
};

export const styles = (theme) => ({
  root: {
    padding: theme.spacing(0.5) + " " + theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.back,
    }
  },
  chip: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  }
});
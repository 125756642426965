import moment from "moment";
import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const auditListAuditImportCall = async ({ clearAuditImportList, setAuditImportList, setAuditImportListTotalRows, setAuditImportFilter }, dispatch, { filter, clear }) => {
  let rh = requestHelper(dispatch, "LIST_AUDIT_IMPORT");
  if (clear) {
    dispatch(clearAuditImportList());
  }

  const dateIni = (filter.isDefaultFilter || !filter.dateIni) ? moment().add(-1, "day").toISOString() : filter.dateIni;
  const dateEnd = (filter.isDefaultFilter || !filter.dateEnd) ? moment().toISOString() : filter.dateEnd;

  dispatch(setAuditImportFilter({
    ...filter,
    dateIni,
    dateEnd
  }));

  try {
    let result = await call({
      type: "AUDIT",
      subType: "LIST_AUDIT_IMPORT",
      request: {
        ...filter,
        dateIni,
        dateEnd
      },
    });

    if (result && result.items && result.items.length > 0) {
      dispatch(setAuditImportList(result.items));
      dispatch(setAuditImportListTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default auditListAuditImportCall;

import { Stack } from "@mui/material"
import BlockCard from "../BlockCard/BlockCard"
import SlimCheckBoxField from "../SlimCheckBoxField/SlimCheckBoxField"

export const ordersFormFields = (t) => {
  return [
    {
      size: 3,
      field: <BlockCard title="placed">
        <Stack>
          <SlimCheckBoxField id="permissions.orders.actions.placed.get" label="get" />
          <SlimCheckBoxField id="permissions.orders.actions.placed.list" label="list" />
          <SlimCheckBoxField id="permissions.orders.actions.placed.create" label="create" />
          <SlimCheckBoxField id="permissions.orders.actions.placed.cancel" label="cancel" />
          <SlimCheckBoxField id="permissions.orders.actions.placed.confirm" label="confirm" />
          <SlimCheckBoxField id="permissions.orders.actions.placed.update" label="update" />
          <SlimCheckBoxField id="permissions.orders.actions.placed.delivery" label="delivery" />
          <SlimCheckBoxField id="permissions.orders.actions.placed.confirmDelivery" label="confirmDelivery" />
          <SlimCheckBoxField id="permissions.orders.actions.placed.import" label="import" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="received">
        <Stack>
          <SlimCheckBoxField id="permissions.orders.actions.received.get" label="get" />
          <SlimCheckBoxField id="permissions.orders.actions.received.list" label="list" />
          <SlimCheckBoxField id="permissions.orders.actions.received.accept" label="accept" />
          <SlimCheckBoxField id="permissions.orders.actions.received.dispatch" label="dispatch" />
          <SlimCheckBoxField id="permissions.orders.actions.received.modifyQuantity" label="modifyQuantity" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="missingProduct">
        <Stack>
          <SlimCheckBoxField id="permissions.orders.actions.missingProduct.get" label="get" />
          <SlimCheckBoxField id="permissions.orders.actions.missingProduct.list" label="list" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="operator">
        <Stack>
          <SlimCheckBoxField id="permissions.orders.actions.operator.get" label="get" />
          <SlimCheckBoxField id="permissions.orders.actions.operator.list" label="list" />
          <SlimCheckBoxField id="permissions.orders.actions.operator.reception" label="reception" />
          <SlimCheckBoxField id="permissions.orders.actions.operator.inTransit" label="inTransit" />
          <SlimCheckBoxField id="permissions.orders.actions.operator.delivery" label="delivery" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="incident">
        <Stack>
          <SlimCheckBoxField id="permissions.orders.actions.incident.get" label="get" />
          <SlimCheckBoxField id="permissions.orders.actions.incident.list" label="list" />
          <SlimCheckBoxField id="permissions.orders.actions.incident.create" label="create" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="document">
        <Stack>
          <SlimCheckBoxField id="permissions.orders.actions.document.add" label="add" />
        </Stack>
      </BlockCard>,
    },
  ]
}
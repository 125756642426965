import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const promoterListCustomFormsCall = async ({ setCustomFormsList }, dispatch) => {
  let rh = requestHelper(dispatch, "PROMOTER_LIST_CUSTOM_FORMS");
  try {
    let result = await call({
      type: "PROMOTER",
      subType: "LIST_CUSTOM_FORMS",
      request: { type: "PRODUCT" },
    });
    dispatch(setCustomFormsList(result?.forms || []));
  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default promoterListCustomFormsCall;

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const resourceDisableCall = async (
  { resourceList },
  dispatch,
  state,
  id,
) => {
  let filter = state.resources.resourceListFilter;
  let rh = requestHelper(dispatch, "RESOURCE_DISABLE");
  try {
    let result = await call({
      type: "RESOURCE",
      subType: "DISABLE",
      request: {
        id
      },
    });

    setTimeout(() => {
      if (result) {
        dispatch(setSuccess("resources", "RESOURCE_DISABLE"));
        dispatch(resourceList(filter, true))
      }
    }, 300);

  } catch (ex) {
    rh.error("resources", ex);
  }
  rh.close();
};

export default resourceDisableCall;


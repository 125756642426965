import { productsSlice } from "./productsSlice";
import { call } from "../../app/websocket/_sliceCalls/websocket";
import { setSuccess, setRedirect, requestHelper, getPromoterConfig } from "../../app/coreSlice";
import { toCatalogCreate } from "./productsMapper";
import { asyncForEach } from "../../common/utils";
import { processImageUrl } from "../../api/s3";

export const selectLoader = (state) => state.core.loader.length > 0;

export const selectCatalogListTotalRows = (state) => state.products.catalogListTotalRows;
export const selectCatalogListFilter = (state) => state.products.catalogListFilter;
export const selectCatalogList = (state) => state.products.catalogList;
export const selectCatalogDetails = (state) => state.products.catalogDetails;

const {
  clearCatalogList,
  setCatalogListFilter,
  appendToCatalogList,
  setCatalogListTotalRows,
  activateCatalog,
  setCatalogDetails,
} = productsSlice.actions;

export const listCatalogsAppend =
  (filter, clear = false) =>
  async (dispatch) => {
    let rh = requestHelper(dispatch, "LIST_CATALOG");
    if (clear) {
      dispatch(clearCatalogList());
      filter = { ...filter, offset: 0 };
    }
    dispatch(setCatalogListFilter(filter));
    try {
      let list = await call({
        type: "PRODUCT",
        subType: "LIST_CATALOG",
        request: {
          ...filter,
          sortField: (filter.sortField || "").toUpperCase(),
        },
      });
      dispatch(appendToCatalogList(list.catalogs));
      dispatch(setCatalogListTotalRows(list.rows));
    } catch (ex) {
      rh.error("products", ex);
    }
    rh.close();
  };

export const createCatalog = (catalog) => async (dispatch) => {
  let rh = requestHelper(dispatch, "CREATE_CATALOG");

  try {
    await call({
      type: "PRODUCT",
      subType: "CREATE_CATALOG",
      request: toCatalogCreate(catalog),
    });
    setTimeout(() => {
      dispatch(setRedirect("/products/catalogs"));
      dispatch(setSuccess("products", "PRODUCT_CREATE_CATALOG"));
    }, 300);
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export const updateCatalog = (id, catalog) => async (dispatch) => {
  let rh = requestHelper(dispatch, "UPDATE_CATALOG");
  try {
    await call({
      type: "PRODUCT",
      subType: "UPDATE_CATALOG",
      request: {
        id,
        ...toCatalogCreate(catalog),
      },
    });
    dispatch(setCatalogDetails(null));
    setTimeout(() => {
      dispatch(setRedirect("/products/catalogs"));
      dispatch(setSuccess("products", "PRODUCT_UPDATE_CATALOG"));
    }, 300);
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export const getCatalog = (id) => async (dispatch, getState) => {
  const state = getState();
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "GET_CATALOG");
  try {
    let catalog = await call({
      type: "PRODUCT",
      subType: "GET_CATALOG",
      request: {
        id,
      },
    });

    let products = [];
    await asyncForEach(catalog?.products, async (product) => {
      products.push({
        ...product,
        image: await processImageUrl(fileStorageConfigs,  product.image),
        thumbnail: await processImageUrl(fileStorageConfigs,  product.thumbnail),
      });
    });
    dispatch(setCatalogDetails({ ...catalog, products }));
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export const enableCatalog = (id) => async (dispatch) => {
  let rh = requestHelper(dispatch, "ENABLE_CATALOG");
  try {
    await call({
      type: "PRODUCT",
      subType: "ENABLE_CATALOG",
      request: {
        id,
      },
    });
    dispatch(activateCatalog({ id, active: true }));
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export const disableCatalog = (id) => async (dispatch) => {
  let rh = requestHelper(dispatch, "DISABLE_CATALOG");
  try {
    await call({
      type: "PRODUCT",
      subType: "DISABLE_CATALOG",
      request: {
        id,
      },
    });
    dispatch(activateCatalog({ id, active: false }));
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

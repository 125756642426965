import React from "react";
import { withStyles } from "tss-react/mui";
import { Field } from "react-final-form";
import MetaError from "../MetaError";
import { styles } from "./_base.styles";
import { FileUploadInput } from "../../inputs";

function FileUploadField({ classes, id, label, disabled, required, multiple, placeholder, image, accept }) {
  const onChangeHandler = (v, input) => {
    input.onChange && input.onChange(v);
  };

  // value:
  // {
  //   url, // file url path
  //   name, // file name
  //   type, // file type ex: image/png
  //   base64, // base64 after (setted after upload)
  // }

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <>
            <FileUploadInput
              id={id}
              label={label}
              placeholder={placeholder}
              required={required}
              disabled={disabled}
              multiple={multiple}
              file={input.value}
              onChange={(v) => onChangeHandler(v, input)}
              variant="outlined"
              image={image}
              accept={accept}
            />
            <div className={classes.error}>
              <MetaError meta={meta} />
            </div>
          </>
        )}
      </Field>
    </div>
  );
}

export default withStyles(FileUploadField, styles);

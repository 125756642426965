export const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  noMargin: {
    margin: 0
  },
  list: {
    "& li": {
      padding: 0
    }
  }
});
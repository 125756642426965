import * as Yup from "yup";
import { TextField } from "../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    subject: Yup.string().required(t("emails.EmailTemplate.editor.requiredSubject")),
  });
}

export function formFields(t, user) {
  return [
    {
      size: 6,
      field: <TextField id="version" type="text" label={t("emails.EmailTemplate.editor.version")} disabled />,
    },
    {
      size: 6,
      field: <TextField id="active" type="text" label={t("emails.EmailTemplate.editor.active")} disabled />,
    },
    {
      size: 12,
      field: (
        <TextField
          id="subject"
          type="text"
          label={t("emails.EmailTemplate.editor.subject")}
          required
          disabled={!user.hasPermission("promoter.actions.email.update")}
        />
      ),
    },
  ];
}

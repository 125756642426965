export const styles = (theme) => ({
  root: {},
  content: {
    backgroundColor: "#FFFFFF",
    padding: theme.spacing(1),
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: "bold",
    cursor: "pointer",
  },
});

export const tooltipStyles = (theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "#999",
    boxShadow: theme.shadows[2],
    padding: theme.spacing(1),
    maxWidth: 420,
  },
});

import * as Yup from "yup";
import { MeasureField } from "../../../common/components/formFields";
import {
  SelectField,
  TextField,
  AutoCompleteField,
  NumberField,
  SwitchField,
  CountryAutoCompleteField,
} from "../../../common/forms";
import {
  getAddressIdOptions,
  getAvailableOptions,
  getTemperatureOptions,
  getTransportOptions,
  getGpcCategoriesOptions,
  getFamiliesOptions,
  getPackagingTypeOptions,
  getPerishableOptions,
  getContactPointOptions,
  getBaseUnitsOptions,
  getPackagingMaterialComponentType,
  getProductClassOptions,
} from "./ProductForm.options";
import ProductImagesField from "./ProductImagesField/ProductImagesField";
import ProductDocumentsField from "./ProductDocumentsField/ProductDocumentsField";
import ProductHierarchyField from "./ProductHierarchyField/ProductHierarchyField";
import ProductTaxesField from "./ProductTaxesField/ProductTaxesField";
import { Typography } from "@mui/material";
import i18next from "i18next";
import InternalCodeField from "../_common/InternalCodeField/InternalCodeField";

export function draftFormSchema(t) {
  return Yup.object().shape({
    baseInfo: Yup.object().shape({
      gtin: Yup.string(),
      ownerId: Yup.string().required(t("products.ProductCreate.form.requiredProductEntityId")),
      active: Yup.boolean().default(false),
    }),
  });
}

export function formSchema({ t, promoter, entity, showGlnAndGtin, groupContacts, edit, user, externalSource, copy }) {
  const { metadata } = promoter?.getConfig("document");
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];
  const mandotoryDocs = Object.keys(promoter?.getConfig("product", "documentTypes") || {}).filter(item => promoter?.getConfig("product", "documentTypes")?.[item] === true);
  const productClasses = promoter?.refData?.productClasses;
  const lifeCycleRequiredClasses = (productClasses || []).filter(item => item.lifecycle).map(item => item.code);

  let metadataSchemas = Object.keys(metadata).reduce((aggMeta, elMeta) => {
    return {
      ...aggMeta,
      [elMeta]: Object.keys(metadata[elMeta]).reduce((agg, el) => {
        return {
          ...agg,
          [el]: metadata[elMeta][el]
            ? Yup.string().required(t(`products.ProductCreate.form.required${el}`))
            : Yup.string(),
        };
      }, {}),
    };
  }, {});

  return Yup.object().shape({
    baseInfo: Yup.object().shape({
      ownerId: Yup.string(),
      gln: Yup.string(),
      gtin: copy && showGlnAndGtin ? Yup.string().gtin(t("products.ProductCreate.form.invalidGtin")) : Yup.string(),
      description: Yup.string().required(t("products.ProductCreate.form.requiredDescription")),
      active: Yup.boolean().default(false),
      available: enabledProductFields.includes("available")
        ? Yup.string().required(t("products.ProductCreate.form.requiredAvailable"))
        : Yup.string(),
      contactPoint: !edit && enabledProductFields.includes("contactPoint") && groupContacts && groupContacts?.length > 0
        ? Yup.string().required(t("products.ProductCreate.form.requiredContactPoint"))
        : Yup.string(),
      addressId: entity?.addresses?.length > 0 && enabledProductFields.includes("addressId")
        ? Yup.string().required(t("products.ProductCreate.form.requiredAddressId"))
        : Yup.string(),
      supplierInternalCode: !showGlnAndGtin && enabledProductFields.includes("supplierInternalCode")
        ? Yup.string().required(t("products.ProductCreate.form.requiredSupplierInternalCode"))
        : Yup.string(),
      productTemplate: (edit || !enabledProductFields.includes("productTemplate") || user?.entity?.type !== "P") ? Yup.object() : Yup.object().shape({
        valid: Yup.bool().isTrue(t("products.ProductCreate.form.requiredProductTemplate")),
        value: Yup.string().required(t("products.ProductCreate.form.requiredProductTemplate"))
      }),
      countryOfOrigin: !externalSource
        ? Yup.string().required(t("products.ProductCreate.form.requiredCountryOfOrigin"))
        : Yup.string(),
      productClass: Yup.string().required(t("products.ProductCreate.form.requiredProductClass"))
    }),
    units: Yup.object().shape({
      unitDescriptor: Yup.string().required(t("products.ProductCreate.form.requiredUnitDescriptor")),
      transactionUnit: user?.entity?.type === "P" && enabledProductFields.includes("transactionUnit") ? Yup.string().required(t("products.ProductCreate.form.requiredUnitDescriptor")) : Yup.string()
    }),
    documents: Yup.array().ensure().requiredDocuments(t("products.ProductCreate.form.requiredDocuments") + ": " + mandotoryDocs.map(doc => t(`common.Enums.documentType.${doc}`)).join(', '), mandotoryDocs).of(
      Yup.object().shape({
        type: Yup.string(),
        metadata: Yup.object().when("type", (type) => {
          return Yup.object().shape(metadataSchemas[type]);
        }),
      })
    ),
    weightMeasure: Yup.object().when("units.unitDescriptor", {
      is: "BULK",
      then: (schema) => schema.shape({
        grossWeight: Yup.object().shape({
          value: entity?.type === "P" ? Yup.string() : Yup.string()
            .not(["0"], t("products.ProductCreate.form.requiredGrossWeight"))
            .required(t("products.ProductCreate.form.requiredGrossWeight")),
        }),
        netContent: Yup.object().shape({
          value: entity?.type === "P" ? Yup.string() : Yup.string()
            .not(["0"], t("products.ProductCreate.form.requiredNetContent"))
            .required(t("products.ProductCreate.form.requiredNetContent")),
        }),
      }),
      otherwise: (schema) => schema.shape({
        height: Yup.object().shape({
          value: entity?.type === "P" ? Yup.string() : Yup.string()
            .not(["0"], t("products.ProductCreate.form.requiredHeight"))
            .required(t("products.ProductCreate.form.requiredHeight")),
        }),
        width: Yup.object().shape({
          value: entity?.type === "P" ? Yup.string() : Yup.string()
            .not(["0"], t("products.ProductCreate.form.requiredWidth"))
            .required(t("products.ProductCreate.form.requiredWidth")),
        }),
        depth: Yup.object().shape({
          value: entity?.type === "P" ? Yup.string() : Yup.string()
            .not(["0"], t("products.ProductCreate.form.requiredDepth"))
            .required(t("products.ProductCreate.form.requiredDepth")),
        }),
        grossWeight: Yup.object().shape({
          value: entity?.type === "P" ? Yup.string() : Yup.string()
            .not(["0"], t("products.ProductCreate.form.requiredGrossWeight"))
            .required(t("products.ProductCreate.form.requiredGrossWeight")),
        }),
        netContent: Yup.object().shape({
          value: entity?.type === "P" ? Yup.string() : Yup.string()
            .not(["0"], t("products.ProductCreate.form.requiredNetContent"))
            .required(t("products.ProductCreate.form.requiredNetContent")),
        }),
      }),
    }),
    hierarchy: Yup.array().of(
      Yup.object().shape({
        gtin: showGlnAndGtin
          ? Yup.string().gtin(t("products.ProductCreate.form.invalidGtin")).required(t("products.ProductCreate.form.invalidGtin"))
          : Yup.string().required(t("products.ProductCreate.form.invalidCode")).matches(/^(?=.*[^\s.])^[^\s]*$/, t("products.ProductCreate.form.invalidCode")),
      })
    ),
    sustainability: Yup.array().of(
      Yup.object().shape({
        packagingType: Yup.string().required(t("products.ProductCreate.form.requiredPackagingType")),
        packagingMaterialComponentType: Yup.string().required(t("products.ProductCreate.form.requiredPackagingMaterialComponentType")),
        packagingWeight: Yup.object().shape({
          value: Yup.string().not(["0"], t("products.ProductCreate.form.requiredPackagingWeight")).required(t("products.ProductCreate.form.requiredPackagingWeight"))
        }),
        quantity: Yup.string().required(t("products.ProductCreate.form.requiredQuantity")),
      })
    ),
    taxes: Yup.object().shape({
      vat: Yup.string().required(t("products.ProductCreate.form.requiredVat")),
      other: Yup.array().of(
        Yup.object().shape({
          value: Yup.string().when("type",
            {
              is: "P",
              then: schema => schema.not(["0"], t("products.ProductCreate.form.otherTaxValueRequired")).required(t('products.ProductCreate.form.otherTaxValueRequired'))
                .test(
                  "maxPercentage",
                  t('products.ProductCreate.form.otherTaxValueMax'),
                  (value) => parseFloat(value) <= 100
                ),
              otherwise: schema => schema.not(["0"], t("products.ProductCreate.form.otherTaxValueRequired")).required(t('products.ProductCreate.form.otherTaxValueRequired')),
            }),
        }),
      )
    }),
    media: promoter?.getConfig("product", "mandatoryImage") ? Yup.array().ensure().min(1, t("products.ProductCreate.form.requiredMedia")) : Yup.array(),
    forms: !externalSource && promoter?.getConfig("product", "listCustomForms?.length") > 0 ? Yup.object().test(
      'atLeastOneForm',
      t('products.ProductCreate.form.formMandatory'),
      (value) => value && Object.keys(value).length >= 1
    ) : Yup.object().nullable(),
    lifecycle: Yup.string().when('baseInfo.productClass', {
      is: (value) => lifeCycleRequiredClasses.includes(value),
      then: schema => schema.required(t('products.ProductCreate.form.requiredLifecycle')),
      otherwise: schema => schema
    }),
  });
}

const externalLock = (currentValue, base, isExternal) => {
  return currentValue && currentValue === base && isExternal;
};

export function formFields({
  t,
  user,
  promoter,
  edit,
  // onOwnerChange,
  // onGlnChange,
  // onGtinChange,
  entity,
  families,
  loading,
  onFamilyChange,
  values,
  productBase,
  groupContacts,
  // onCheckGS1,
  showGlnAndGtin,
  externalSource,
  copy
}) {
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];
  const addressIdOptions = getAddressIdOptions(entity);
  const showOnwername = user?.entity?.type === "P" || (entity?.businessUnitId === 0 && entity?.businessUnits?.length > 1);

  return [
    {
      size: 12,
      field: (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <SwitchField
            id="baseInfo.active"
            label={t("products.ProductCreate.form.active")}
            disabled={loading}
            size="medium"
          />
        </div>
      ),
    },
    {
      size: 8,
      field: (
        // <EntityAutoCompleteField
        //   typeFilter={promoter?.getConfig("product", "allowedEntityTypes")}
        //   id="baseInfo.ownerId"
        //   label={t("products.ProductCreate.form.ownerId")}
        //   disabled
        // />
        <TextField
          id="baseInfo.ownerName"
          type="text"
          label={t("products.ProductCreate.form.ownerId")}
          disabled
        />
      ),
      hidden: !showOnwername,
    },
    {
      size: 12,
      hidden: !showGlnAndGtin
    },
    {
      size: 4,
      field: (
        <TextField
          id="baseInfo.gln"
          label={t("products.ProductCreate.form.gln")}
          disabled
        />
      ),
      hidden: !showGlnAndGtin
    },
    {
      size: 4,
      field: (
        <TextField
          id="baseInfo.gtin"
          label={t("products.ProductCreate.form.gtin")}
          disabled={!copy}
        />
      ),
      hidden: !showGlnAndGtin
    },
    {
      size: 4,
      field: (
        <TextField
          id="baseInfo.supplierInternalCode"
          type="text"
          label={t("products.ProductCreate.form.supplierInternalCode")}
          // required={(user?.entity?.type !== "P" && !user?.entity?.gln) || (user?.entity?.type === "P" && !entity?.gln)}
          // disabled={loading}
          disabled={!values.baseInfo.gtin && !copy}
        />
      ),
      hidden: !enabledProductFields.includes("supplierInternalCode"),
    },

    {
      size: 12
    },
    {
      size: 8,
      field: (
        <TextField
          id="baseInfo.promoterDescription"
          type="text"
          label={t("products.ProductCreate.form.promoterDescription")}
          disabled
        />
      ),
      hidden: !edit || !enabledProductFields.includes("promoterDescription") || user?.entity?.type !== "P",
    },
    {
      size: 4,
      field: (
        <TextField
          id="baseInfo.promoterInternalCode"
          type="text"
          label={t("products.ProductCreate.form.promoterInternalCode")}
          disabled
        />
      ),
      hidden: !edit || !enabledProductFields.includes("promoterInternalCode") || user?.entity?.type !== "P",
    },
    {
      size: 8,
      field: (
        <TextField
          id="baseInfo.description"
          type="text"
          label={t("products.ProductCreate.form.description")}
          required
          maxLength={200}
          disabled={
            loading ||
            externalLock(values?.baseInfo?.description, productBase?.baseInfo?.description, productBase?.externalSource)
          }
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField id="baseInfo.brand" type="text" label={t("products.ProductCreate.form.brand")} maxLength={70} disabled={loading} />
      ),
    },
    {
      size: 12,
    },
    {
      size: 8,
      field: (
        <AutoCompleteField
          id="baseInfo.productClass"
          options={getProductClassOptions(t, promoter)}
          label={t("products.ProductCreate.form.productClass")}
          disabled={loading}
          required
        />
      ),
    },
    {
      size: 4,
      field: (
        <SelectField
          id="baseInfo.familyCode"
          options={getFamiliesOptions(t, families)}
          label={t("products.ProductCreate.form.familyCode")}
          disabled={loading || user?.entity?.type !== "P"}
          onChange={onFamilyChange}
          renderValue={(id) => {
            let selectedFamily = families.find(item => item.id.toString() === id.toString());
            if (selectedFamily) {
              let selectedParent = families.find(item => item.id === selectedFamily.parent);
              let altLang = Object.keys(selectedFamily.description).filter((k) => k !== i18next.language)[0];
              let family = selectedFamily.description[i18next.language] || selectedFamily.description[altLang];
              let parent = selectedParent?.description ? (selectedParent.description[i18next.language] || selectedParent.description[altLang]) : "";
              return <Typography style={{ margin: 0 }}>{`${parent} - ${family}`}</Typography>
            }
          }}
        />
      ),
      hidden: !families || families.length === 0,
      keepSpace: true,
    },
    {
      size: 12,
    },
    {
      size: 8,
      field: (
        <AutoCompleteField
          id="baseInfo.gpcCategoryCode"
          label={t("products.ProductCreate.form.gpcCategoryCode")}
          options={getGpcCategoriesOptions(promoter)}
          disabled={
            loading ||
            externalLock(
              values?.baseInfo?.gpcCategoryCode,
              productBase?.baseInfo?.gpcCategoryCode,
              productBase?.externalSource
            )
          }
        />
      ),
    },
    {
      size: 4,
      field: (
        <SelectField
          id="baseInfo.available"
          label={t("products.ProductCreate.form.available")}
          options={getAvailableOptions(t)}
          required
          disabled={Boolean(
            loading ||
            externalLock(values?.baseInfo?.available, productBase?.baseInfo?.available, productBase?.externalSource)
          )}
        />
      ),
      hidden: !enabledProductFields.includes("available"),
    },
    {
      size: 4,
      field: (
        <SelectField
          id="baseInfo.addressId"
          label={t("products.ProductCreate.form.addressId")}
          options={addressIdOptions}
          required
          disabled={loading}
        />
      ),
      hidden: !addressIdOptions || addressIdOptions?.length === 0 || !enabledProductFields.includes("addressId")
    },
    {
      size: 4,
      field: (
        <InternalCodeField
          id="baseInfo.productTemplate"
          label={t("products.ProductCreate.form.productTemplate")}
          note={t("products.ProductCreate.form.productTemplateNote")}
          required
          disabled={loading || (edit && values?.status !== "DFT" && !copy)}
        />
      ),
      hidden: !enabledProductFields.includes("productTemplate") || user?.entity?.type !== "P" || (edit && values?.status !== "DFT" && !copy && !values?.baseInfo.productTemplate)
    },
    {
      size: 4,
      field: (
        <SelectField
          id="baseInfo.contactPoint"
          required={!edit || copy}
          label={t("products.ProductCreate.form.contactPoint")}
          disabled={loading || (edit && values?.status !== "DFT" && !copy)}
          options={getContactPointOptions(groupContacts)}
        />
      ),
      hidden: !groupContacts || groupContacts?.length === 0 || !enabledProductFields.includes("contactPoint"),
      keepSpace: true,
    },
    {
      size: 4,
      field: (
        <CountryAutoCompleteField
          id="baseInfo.countryOfOrigin"
          label={t("products.ProductCreate.form.countryOfOrigin")}
          disabled={loading}
          required={!externalSource}
        />
      ),
    },
    {
      size: 12,
      field: <ProductImagesField id="media" label={t("products.ProductCreate.form.media")} required={promoter?.getConfig("product", "mandatoryImage")} disabled={loading} />,
    },
  ];
}

export function formUnitsFields({ t, promoter, values, loading, productBase, user }) {
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];
  return [
    {
      size: 4,
      field: (
        <SelectField
          id="units.unitDescriptor"
          label={t("products.ProductCreate.form.unitDescriptor")}
          options={getBaseUnitsOptions(t)}
          required
          disabled={Boolean(
            loading ||
            externalLock(
              values?.units?.unitDescriptor,
              productBase?.units?.unitDescriptor,
              productBase?.externalSource
            )
          )}
        />
      ),
    },
    {
      size: 8,
    },
    {
      size: 4,
      field: (
        <SwitchField
          id="units.isBaseUnit"
          label={t("products.ProductCreate.form.isBaseUnit")}
          note={t("products.ProductCreate.form.isBaseUnitNote")}
          disabled={Boolean(
            loading ||
            externalLock(values?.units?.isBaseUnit, productBase?.units?.isBaseUnit, productBase?.externalSource)
          )}
          size="medium"
        />
      ),
    },
    {
      size: 4,
      field: (
        <SwitchField
          id="units.isConsumerUnit"
          label={t("products.ProductCreate.form.isConsumerUnit")}
          note={t("products.ProductCreate.form.isConsumerUnitNote")}
          disabled={Boolean(
            loading ||
            externalLock(
              values?.units?.isConsumerUnit,
              productBase?.units?.isConsumerUnit,
              productBase?.externalSource
            )
          )}
          size="medium"
        />
      ),
    },
    {
      size: 4,
      field: (
        <SwitchField
          id="units.isDispatchUnit"
          label={t("products.ProductCreate.form.isDispatchUnit")}
          note={t("products.ProductCreate.form.isDispatchUnitNote")}
          disabled={Boolean(
            loading ||
            externalLock(
              values?.units?.isDispatchUnit,
              productBase?.units?.isDispatchUnit,
              productBase?.externalSource
            )
          )}
          size="medium"
        />
      ),
      hidden: !enabledProductFields.includes("isDispatchUnit")
    },

    {
      size: 4,
      field: (
        <SelectField
          id="units.transportUnit"
          label={t("products.ProductCreate.form.transportUnit")}
          options={getTransportOptions(t, promoter)}
          disabled={Boolean(
            loading ||
            externalLock(values?.units?.transportUnit, productBase?.units?.transportUnit, productBase?.externalSource)
          )}
        />
      ),
      hidden: !values?.units?.isConsumerUnit || values?.units?.isDispatchUnit,
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: (
        <SelectField
          id="units.transactionUnit"
          label={t("products.ProductCreate.form.transactionUnit")}
          options={getTransportOptions(t, promoter)}
          required={user?.entity?.type === "P"}
          disabled={loading}
        />
      ),
      hidden: user?.entity?.type !== "P" || !enabledProductFields.includes("transactionUnit"),
    },
  ];
}

export function formMeasuresFields({ t, values, loading, productBase, promoter, entity, user }) {
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];
  return [
    {
      size: 12,
      field: (
        <SwitchField
          id="weightMeasure.hasVariableDimensions"
          label={t("products.ProductCreate.form.hasVariableDimensions")}
          disabled={
            loading ||
            externalLock(
              values?.weightMeasure?.hasVariableDimensions,
              productBase?.weightMeasure?.hasVariableDimensions,
              productBase?.externalSource
            )
          }
          size="medium"
        />
      ),
    },
    {
      size: 4,
      field: (
        <MeasureField
          id="weightMeasure.height"
          label={t("products.ProductCreate.form.height")}
          required={Boolean(entity?.type !== "P")}
          toLabel={t("products.ProductCreate.form.to")}
          variable={values?.weightMeasure?.hasVariableDimensions}
          disabled={
            loading ||
            externalLock(values?.weightMeasure?.height, productBase?.weightMeasure?.height, productBase?.externalSource)
          }
        />
      ),
      hidden: values?.units?.unitDescriptor === "BULK"
    },
    {
      size: 4,
      field: (
        <MeasureField
          id="weightMeasure.width"
          label={t("products.ProductCreate.form.width")}
          required={Boolean(entity?.type !== "P")}
          toLabel={t("products.ProductCreate.form.to")}
          variable={values?.weightMeasure?.hasVariableDimensions}
          disabled={
            loading ||
            externalLock(values?.weightMeasure?.width, productBase?.weightMeasure?.width, productBase?.externalSource)
          }
        />
      ),
      hidden: values?.units?.unitDescriptor === "BULK"
    },
    {
      size: 4,
      field: (
        <MeasureField
          id="weightMeasure.depth"
          label={t("products.ProductCreate.form.depth")}
          required={Boolean(entity?.type !== "P")}
          toLabel={t("products.ProductCreate.form.to")}
          variable={values?.weightMeasure?.hasVariableDimensions}
          disabled={
            loading ||
            externalLock(values?.weightMeasure?.depth, productBase?.weightMeasure?.depth, productBase?.externalSource)
          }
        />
      ),
      hidden: values?.units?.unitDescriptor === "BULK"
    },
    {
      size: 4,
      field: (
        <MeasureField
          id="weightMeasure.netWeight"
          label={t("products.ProductCreate.form.netWeight")}
          toLabel={t("products.ProductCreate.form.to")}
          variable={values?.weightMeasure?.hasVariableDimensions}
          disabled={
            loading ||
            externalLock(
              values?.weightMeasure?.netWeight,
              productBase?.weightMeasure?.netWeight,
              productBase?.externalSource
            )
          }
        />
      ),
    },
    {
      size: 4,
      field: (
        <MeasureField
          id="weightMeasure.grossWeight"
          label={t("products.ProductCreate.form.grossWeight")}
          required={Boolean(entity?.type !== "P")}
          toLabel={t("products.ProductCreate.form.to")}
          variable={values?.weightMeasure?.hasVariableDimensions}
          disabled={
            loading ||
            externalLock(
              values?.weightMeasure?.grossWeight,
              productBase?.weightMeasure?.grossWeight,
              productBase?.externalSource
            )
          }
        />
      ),
    },
    {
      size: 4,
      field: (
        <MeasureField
          id="weightMeasure.netContent"
          label={t("products.ProductCreate.form.netContent")}
          required={Boolean(entity?.type !== "P")}
          toLabel={t("products.ProductCreate.form.to")}
          variable={values?.weightMeasure?.hasVariableDimensions}
          disabled={
            loading ||
            externalLock(
              values?.weightMeasure?.netContent,
              productBase?.weightMeasure?.netContent,
              productBase?.externalSource
            )
          }
        />
      ),
    },
    {
      size: 4,
      field: (
        <MeasureField
          id="weightMeasure.conversionFactor"
          label={t("products.ProductCreate.form.conversionFactor")}
          disabled={loading}
        />
      ),
      hidden: user?.entity?.type !== "P" || !enabledProductFields.includes("conversionFactor"),
    }
  ];
}

export function formOrderFields({ t, loading, values, productBase }) {
  return [
    {
      size: 3,
      field: (
        <NumberField
          decimalScale={0}
          id="orders.minQuantity"
          label={t("products.ProductCreate.form.minQuantity")}
          disabled={
            loading ||
            externalLock(values?.orders?.minQuantity, productBase?.orders?.minQuantity, productBase?.externalSource)
          }
        />
      ),
    },
    {
      size: 3,
      field: (
        <NumberField
          decimalScale={0}
          id="orders.maxQuantity"
          label={t("products.ProductCreate.form.maxQuantity")}
          disabled={
            loading ||
            externalLock(values?.orders?.maxQuantity, productBase?.orders?.maxQuantity, productBase?.externalSource)
          }
        />
      ),
    },
    {
      size: 3,
      field: (
        <NumberField
          decimalScale={0}
          id="orders.quantityMultiple"
          label={t("products.ProductCreate.form.quantityMultiple")}
          disabled={
            loading ||
            externalLock(
              values?.orders?.quantityMultiple,
              productBase?.orders?.quantityMultiple,
              productBase?.externalSource
            )
          }
        />
      ),
    },
  ];
}

export function formTaxesFields({ t, loading, values, productBase, vatOptions }) {
  return [
    {
      size: 3,
      field: (
        <SelectField
          id="taxes.vat"
          label={t("products.ProductCreate.form.vat")}
          options={vatOptions}
          required
          disabled={loading}
        />
      ),
    },
    {
      size: 12,
      field: (
        <ProductTaxesField id="taxes.other" label={t("products.ProductCreate.form.otherTaxes")} note={t("products.ProductCreate.form.otherTaxesNote")} disabled={loading} selectedTaxes={values?.taxes?.other || []} />
      ),
    },
  ]
}

export function formManagementFields({ t, promoter, loading, values, productBase }) {
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];
  return [
    {
      size: 3,
      field: (
        <SwitchField
          id="management.hasBatchNumber"
          label={t("products.ProductCreate.form.hasBatchNumber")}
          disabled={
            loading ||
            externalLock(
              values?.management?.hasBatchNumber,
              productBase?.management?.hasBatchNumber,
              productBase?.externalSource
            )
          }
          size="medium"
        />
      ),
      hidden: !enabledProductFields.includes("hasBatchNumber"),
    },
    {
      size: 3,
      field: (
        <SwitchField
          id="management.hasExpirationDate"
          label={t("products.ProductCreate.form.hasExpirationDate")}
          disabled={
            loading ||
            externalLock(
              values?.management?.hasExpirationDate,
              productBase?.management?.hasExpirationDate,
              productBase?.externalSource
            )
          }
          size="medium"
        />
      ),
      hidden: !enabledProductFields.includes("hasExpirationDate"),
    },
    {
      size: 3,
      field: (
        <SwitchField
          id="management.hasVariableWeight"
          label={t("products.ProductCreate.form.hasVariableWeight")}
          disabled={
            loading ||
            externalLock(
              values?.management?.hasVariableWeight,
              productBase?.management?.hasVariableWeight,
              productBase?.externalSource
            )
          }
          size="medium"
        />
      ),
      hidden: !enabledProductFields.includes("hasVariableWeight"),
    },
    {
      size: 3,
      field: (
        <SwitchField
          id="management.hasSerialNumber"
          label={t("products.ProductCreate.form.hasSerialNumber")}
          disabled={
            loading ||
            externalLock(
              values?.management?.hasSerialNumber,
              productBase?.management?.hasSerialNumber,
              productBase?.externalSource
            )
          }
          size="medium"
        />
      ),
      hidden: !enabledProductFields.includes("hasSerialNumber"),
    },
  ];
}

export function formStorageFields({ t, promoter, loading, values, productBase }) {
  const productClasses = promoter?.refData?.productClasses;
  return [
    {
      size: 3,
      field: (
        <SelectField
          id="storage.transportTemperature"
          label={t("products.ProductCreate.form.transportTemperature")}
          options={getTemperatureOptions(t, promoter)}
          disabled={
            loading ||
            externalLock(
              values?.storage?.transportTemperature,
              productBase?.storage?.transportTemperature,
              productBase?.externalSource
            )
          }
        />
      ),
    },
    {
      size: 3,
      field: (
        <SelectField
          id="storage.storageTemperature"
          label={t("products.ProductCreate.form.storageTemperature")}
          options={getTemperatureOptions(t, promoter)}
          disabled={
            loading ||
            externalLock(
              values?.storage?.storageTemperature,
              productBase?.storage?.storageTemperature,
              productBase?.externalSource
            )
          }
        />
      ),
    },
    {
      size: 3,
      field: (
        <NumberField
          id="lifecycle"
          decimalScale={0}
          label={t("products.ProductCreate.form.lifecycle")}
          required={(productClasses || []).find(item => item.code === values?.baseInfo?.productClass)?.lifecycle || false}
          disabled={
            loading ||
            externalLock(values?.storage?.lifecycle, productBase?.storage?.lifecycle, productBase?.externalSource)
          }
        />
      ),
    },
    {
      size: 3,
      field: (
        <SelectField
          id="storage.perishable"
          label={t("products.ProductCreate.form.perishable")}
          options={getPerishableOptions(t)}
          disabled={loading}
        />
      ),
    },
  ];
}

export function formDocumentsFields({ promoter, loading }) {
  return [
    {
      size: 12,
      field: (
        <ProductDocumentsField
          id="documents"
          documentTypes={promoter?.getConfig("product", "documentTypes")}
          metadata={promoter?.getConfig("document")?.metadata}
          disabled={loading}
        />
      ),
    },
  ];
}

export function formHierarchyFields({ loading, values, showGlnAndGtin, pendingHierarchy, form }) {
  return [
    {
      size: 12,
      field: <ProductHierarchyField id="hierarchy" disabled={loading || pendingHierarchy} values={values} showGlnAndGtin={showGlnAndGtin} form={form} />,
    },
  ];
}

export const formSustainabilityFields =
  ({ t, loading, values, promoter }) =>
    (item) => {
      return [
        {
          size: 6,
          field: (
            <SelectField
              label={t("products.ProductCreate.form.packagingType")}
              id={`${item}.packagingType`}
              options={getPackagingTypeOptions(t, promoter, values)}
              disabled={loading}
            />
          ),
        },
        {
          size: 6,
        },
        {
          size: 4,
          field: (
            <SelectField
              label={t("products.ProductCreate.form.packagingMaterialComponentType")}
              id={`${item}.packagingMaterialComponentType`}
              options={getPackagingMaterialComponentType(t, promoter)}
              disabled={loading}
              required
            />
          ),
        },
        {
          size: 4,
          field: (
            <TextField
              label={t("products.ProductCreate.form.packagingMaterialType")}
              id={`${item}.packagingMaterialType`}
              disabled={loading}
            />
          ),
        },
        {
          size: 4,
          field: (
            <TextField
              label={t("products.ProductCreate.form.otherPackagingMaterials")}
              id={`${item}.otherPackagingMaterials`}
              disabled={loading}
            />
          ),
        },
        {
          size: 4,
          field: (
            <TextField
              label={t("products.ProductCreate.form.packagingColor")}
              id={`${item}.packagingColor`}
              disabled={loading}
            />
          ),
        },
        {
          size: 4,
          field: (
            <NumberField label={t("products.ProductCreate.form.opacity")} id={`${item}.opacity`} disabled={loading} />
          ),
        },
        {
          size: 4,
          field: (
            <TextField
              label={t("products.ProductCreate.form.recyclingDestination")}
              id={`${item}.recyclingDestination`}
              disabled={loading}
            />
          ),
        },
        {
          size: 4,
          field: (
            <MeasureField
              label={t("products.ProductCreate.form.packagingWeight")}
              id={`${item}.packagingWeight`}
              disabled={loading}
              required
            />
          ),
        },
        {
          size: 4,
          field: (
            <NumberField label={t("products.ProductCreate.form.quantity")} id={`${item}.quantity`} disabled={loading} required decimalScale={0} />
          ),
        },
        {
          size: 4,
          field: (
            <NumberField
              label={t("products.ProductCreate.form.plasticDensity")}
              id={`${item}.plasticDensity`}
              disabled={loading}
            />
          ),
        },
      ];
    };

import React from "react";
import { Grid, TextField, InputAdornment } from "@mui/material";

function MinmaxInput({ classes, json, value, index, onChange, adornment, minDiff }) {
  const minmaxChange = (v) => {
    let nValue = JSON.parse(JSON.stringify(json));
    Object.keys(json).forEach((item) => {
      nValue[item][index].max = v;

      if (index < nValue[item].length - 1) {
        nValue[item][index + 1].min = parseFloat(v) + minDiff;
      }
    });

    onChange && onChange(nValue);
  };

  return (
    <Grid container spacing={1} justifyContent="center" direction="column">
      <Grid item>
        <TextField
          value={value.min}
          className={classes.minmaxInput}
          margin="none"
          variant="outlined"
          size="small"
          disabled
          //onChange={(e) => minmaxChange(e.target.value, "min")}
          InputProps={
            adornment && {
              endAdornment: <InputAdornment position="end">{adornment}</InputAdornment>,
            }
          }
        />
      </Grid>
      <Grid item>
        <TextField
          value={value.max}
          type="number"
          className={classes.minmaxInput}
          margin="none"
          variant="outlined"
          size="small"
          onFocus={(e) => e.target && e.target.select && e.target.select()}
          onChange={(e) => minmaxChange(e.target.value)}
          InputProps={
            adornment && {
              endAdornment: <InputAdornment position="end">{adornment}</InputAdornment>,
            }
          }
        />
      </Grid>
    </Grid>
  );
}

export default MinmaxInput;

export const styles = (theme) => ({
  root: {
    border: "1px solid #DDD",
    width: "100%",
    padding: theme.spacing(1) + " " + theme.spacing(1.5) + " " + theme.spacing(1) + " " + theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.back,
    }
  }
});
import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const entityListGroup_fullCall = async ({ setGroupsFull }, dispatch) => {
  let rh = requestHelper(dispatch, "LIST_GROUP_FULL");
  try {
    let list = await call({
      type: "ENTITY",
      subType: "LIST_GROUP",
      request: {
        limit: 15000,
        offset: 0,
        sortField: "GROUPNAME",
        sortType: "ASC",
        status: true,
      },
    });
    dispatch(setGroupsFull(list.groupEntities));
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityListGroup_fullCall;

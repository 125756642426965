import { Grid, Typography } from "@mui/material";
import { currencyFormat } from "../../../../common/utils";
import OrderStatus from "../../_common/OrderStatus/OrderStatus";

export function listColumns(t, classes, promoter) {
  let columns = [];

  // ID
  columns.push({
    field: "id",
    headerName: t("orders.OrdersList.columns.id"),
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return (
        <Grid container direction="column">
          <Typography className={classes.id} variant="h4">
            {v.id}
          </Typography>
          <Typography className={classes.orderBy}>{v.description}</Typography>
          <div>
            <Typography className={classes.addressTitle}>{t("orders.OrdersList.columns.address")}&nbsp;</Typography>
            <Typography className={classes.address}>{v.address}</Typography>
          </div>
        </Grid>
      );
    },
  });

  // CUTOFF
  if (promoter?.getConfig("cutOff") && !promoter?.getConfig("cutOff", "automatic")) {
    columns.push({
      field: "cutOffType",
      headerName: t("orders.OrdersList.columns.cutOffType"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return t("entities.CutOffs.dateType." + v.cutOffType);
      },
    });
  }

  // # PRODUCTS
  columns.push({
    field: "numberOfProducts",
    headerName: t("orders.OrdersList.columns.numberOfProducts"),
    align: "left",
    headerAlign: "left",
  });

  // PRICE
  columns.push({
    field: "price",
    headerName: t("orders.OrdersList.columns.price"),
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return currencyFormat(v.price);
    },
  });

  // STATUS
  columns.push({
    field: "status",
    headerName: t("orders.OrdersList.columns.status"),
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return <OrderStatus order={v} />;
    },
  });

  return columns;
}

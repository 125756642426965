import { CheckboxField, CheckListField, FileUploadField, NumberField, SelectField } from "../../../../common/forms";
import DocumentTypeField from "../DocumentTypeField/DocumentTypeField";

export const orderFields = ({ t, user, refData, formOptions }) => {
  return [
    {
      size: 4,
      field: (
        <FileUploadField
          id="technical.order.importTemplate"
          label={t("promoter.PromoterConfigs.form.importTemplateOrder")}
          placeholder={t("promoter.PromoterConfigs.form.importTemplateOrderPlaceholder")}
          image
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
    {
      size: 12,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },{
      size: 6,
      field: (
        <CheckboxField id="functional.order.hasLogisticOperator" label={t("promoter.PromoterConfigs.form.hasLogisticOperator")} />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },{
      size: 6,
      field: (
        <CheckboxField id="functional.order.checkFinishedAccept" label={t("promoter.PromoterConfigs.form.checkFinishedAccept")} />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <SelectField
          id="functional.order.operationType"
          label={t("promoter.PromoterConfigs.form.operationType")}
          options={formOptions.operationType}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <SelectField
          id="functional.order.trafficType"
          label={t("promoter.PromoterConfigs.form.trafficType")}
          options={formOptions.trafficType}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <NumberField
          id="functional.order.reportIncidentDeadline"
          type="text"
          label={t("promoter.PromoterConfigs.form.reportIncidentDeadline")}
          decimalScale={0}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <CheckListField
          id="technical.order.allowedEntityTypes"
          options={formOptions.entityType}
          label={t("promoter.PromoterConfigs.form.orderAllowedEntityTypes")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
    {
      size: 4,
      field: (
        <DocumentTypeField
          id="functional.order.documentTypes"
          label={t("promoter.PromoterConfigs.form.orderDocumentType")}
          documentType={refData?.documentType}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: (
        <SelectField
          id="technical.order.cmpAccumulation"
          label={t("promoter.PromoterConfigs.form.cmpAccumulation")}
          note={t("promoter.PromoterConfigs.form.cmpAccumulationNote")}
          options={formOptions.entityPermission}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <SelectField
          id="technical.order.cpAccumulation"
          label={t("promoter.PromoterConfigs.form.cpAccumulation")}
          note={t("promoter.PromoterConfigs.form.cpAccumulationNote")}
          options={formOptions.entityPermission}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: (
        <SelectField
          id="technical.order.defaultType"
          label={t("promoter.PromoterConfigs.form.defaultType")}
          options={formOptions.priceType}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <NumberField
          id="technical.order.numberQuantityRange"
          label={t("promoter.PromoterConfigs.form.numberQuantityRange")}
          decimalScale={0}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: (
        <CheckListField
          id="technical.order.minimumQuantityUnitTypes"
          options={formOptions.transport}
          label={t("promoter.PromoterConfigs.form.minimumQuantityUnitTypes")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
  ];
};

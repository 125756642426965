const home = {
  flowsMessages: {
    error: {
      GENERIC: "Something went wrong",
    },
  },
  screen: {
    averageWeekOrder: {
      label: "Average Week Order",
      valueLabel: "Orders",
    },
    qtTransit: {
      label: "Quantity in Transit",
      valueLabel: "Orders",
    },
    qtAwaiting: {
      label: "Quantity Awaiting",
      valueLabel: "Orders",
    },
    cxTenseFlow: {
      label: "Tense Flow",
      valueLabel: "Packages",
    },
    cxCrossDock: {
      label: "Cross Dock",
      valueLabel: "Packages",
    },
    topSellers: {
      label: "Top Sellers",
    },
    topProducts: {
      label: "Top Products",
    },
  },
  Highlights: {
    flyer: {
      flyerBtn: "Flyer",
    },
  },
};

export default home;

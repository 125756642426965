import * as Yup from "yup";
import { TextField } from "../../../../common/forms";

export function formSchema({ t, taxToValidate }) {
  return Yup.object().shape({
    comment: taxToValidate?.valid ?
      Yup.string() :
      Yup.string()
        .required(t("products.ProductTaxValidateCommentModal.form.commentRequired"))
        .trim("products.ProductTaxValidateCommentModal.form.commentRequired"),
  });
}

export function formFields({ t }) {
  return [
    {
      size: 12,
      field: <TextField
        id="comment"
        rows={3}
        label={t("products.ProductTaxValidateCommentModal.form.comment")}
      />,
    },
  ];
}


import {Typography} from "@mui/material";
import ActionSnip from "../../../../common/displays/ActionSnip/ActionSnip";

export function listColumns(t, classes, taxType) {
  return [
    {
      field: "actionDate",
      headerName: t("productsPrices.PriceHistoryModal.columns.actionDate"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return <ActionSnip username={v.actionUser} date={v.actionDate}/>;
      },
    }, {
      field: "tax",
      headerName: t("products.TaxHistoryModal.columns.tax"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v.tax?.tax ? t('common.Enums.taxGroup.' + v.tax?.tax) !== 'common.Enums.taxGroup.' + v.tax?.tax ? t('common.Enums.taxGroup.' + v.tax?.tax) : t("common.Enums.vat." + v.tax?.tax)  : "";
      },
    }, {
      field: "startDate",
      headerName: t("products.TaxHistoryModal.columns.startDate"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v.tax?.startDate ? v.tax?.startDate : "";
      },
    }, {
      field: "value",
      headerName: t("products.TaxHistoryModal.columns.value"),
      align: "center",
      headerAlign: "left",
      disableSort: true,
      hidden: taxType === "VAT",
      customValue: (v) => {
        return v.tax?.value ? v.tax?.value +" "+ (v.tax?.type === "A" ? "€" : "%") : "-";
      },
    }, {
      field: "removed",
      headerName: t("products.TaxHistoryModal.columns.removed"),
      align: "left",
      headerAlign: "left",
      hidden: taxType === "VAT",
      customValue: (v) => {
        return v.tax?.removed ? t('products.TaxHistoryModal.columns.removed_'+v.tax?.removed) : "";
      },
    }, {
      field: "status",
      headerName: t("products.TaxHistoryModal.columns.status"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        if(v.tax?.status){
          const status = v.tax?.status;
          if (status === "ACC") {
            return <Typography className={classes.statusAcc}>{t("common.Enums.taxStatus." + status)}</Typography>
          } else if (status === "PND") {
            return <Typography className={classes.statusPnd}>{t("common.Enums.taxStatus." + status)}</Typography>
          } else
            return <Typography className={classes.statusRef}>{t("common.Enums.taxStatus." + status)}</Typography>
       }
      }
    }
  ];
}

import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./OrderProductsDataGrid.styles";
import { listColumns } from "./OrderProductsDataGrid.columns";
import { currencyFormat } from "../../../../common/utils";
import { Typography, Grid } from "@mui/material";
import BatchInput from "../inputs/BatchInput/BatchInput";
import { csvFields } from "./OrderProductsDataGrid.export";

function OrderProductsDataGrid({
  classes,
  products,
  onRowSelected,
  searchQuery,
  showTotals,
  showPrices,
  showCampaigns,
  onQuantityChanged,
  allowBatching,
  onBatchsChange,
  disabledQuantity,
  allowExport,
  checkRows,
  onCheckChanged,
  allChecked,
  onBulkCheckChange,
  showDispatchedQuantity
}) {
  const { t } = useTranslation();
  const columns = listColumns({
    t,
    classes,
    showPrices,
    showCampaigns,
    onQuantityChanged,
    disabledQuantity,
    checkRows,
    onCheckChanged,
    allChecked,
    onBulkCheckChange,
    showDispatchedQuantity
  });
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [totals, setTotals] = useState({});

  useEffect(() => {
    if (products && showTotals) {
      let subtotal = 0;
      let ivatotal = 0;
      products.forEach((product) => {
        if (product.active) {
          let productTotal = product.quantity * product.price;
          subtotal += productTotal;
          ivatotal += (productTotal * product.vat) / 100;
        }
      });
      setTotals({
        subtotal,
        ivatotal,
      });
    }
    // eslint-disable-next-line
  }, [products]);

  useEffect(() => {
    if (products) {
      if (searchQuery) {
        setFilteredProducts(
          products.filter(
            (item) =>
              item.description.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
              item.gtin.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
          )
        );
      } else {
        setFilteredProducts(products);
      }
    }
    // eslint-disable-next-line
  }, [searchQuery, products]);

  let innerRow = undefined;
  if (allowBatching) {
    innerRow = (row, index) => {
      return row.hasBatchNumber || row.hasExpirationDate || row.hasVariableDimensions ? (
        <BatchInput
          product={row}
          batchs={row.productBatch || []}
          onChange={(batchs) => onBatchsChange(row?.key, batchs)}
        />
      ) : null;
    };
  }

  return (
    <div className={classes.root}>
      {products && (
        <CustomDataGrid
          localFilters
          columns={columns}
          rows={filteredProducts}
          onRowSelected={onRowSelected}
          total={filteredProducts?.length || 0}
          innerRow={innerRow ? (row, index) => innerRow(row, index) : undefined}
          rowKeyField="key"
          exportCsvFields={allowExport && csvFields(t)}
          exportXlsxFields={allowExport && csvFields(t)}
          footer={
            showTotals &&
            !searchQuery && (
              <Grid container direction="column" alignItems="flex-end">
                <Grid item className={classes.totalbox}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Typography className={classes.totalLabel}>
                      {t("orders.OrderProductsDataGrid.footer.subtotal")}
                    </Typography>
                    <Typography className={classes.nomargin} color="textPrimary">
                      {currencyFormat(totals.subtotal)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item className={classes.totalbox}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Typography className={classes.totalLabel}>
                      {t("orders.OrderProductsDataGrid.footer.ivatotal")}
                    </Typography>
                    <Typography className={classes.nomargin} color="textPrimary">
                      {currencyFormat(totals.ivatotal)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item className={classes.totalbox}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Typography className={classes.totalLabel} color="textPrimary">
                      {t("orders.OrderProductsDataGrid.footer.total")}
                    </Typography>
                    <Typography className={classes.nomargin} color="textPrimary">
                      <b>{currencyFormat(totals.subtotal + totals.ivatotal)}</b>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )
          }
        />
      )}
    </div>
  );
}

export default withStyles(OrderProductsDataGrid, styles)

import React, { useRef } from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid } from "@mui/material";
import { Field } from "react-final-form";

import { ImagePlaceholder } from "../../icons";
import { styles } from "./ImageUpload.styles";
import { toBase64 } from "../../utils";

function ImageUpload({ classes, id, label, disabled, required, linkLabel }) {
  const inputFileRef = useRef();

  const handleUpload = async (e, input) => {
    /*Selected files data can be collected here.*/

    let base64 = await toBase64(e.target.files[0]);
    input.onChange && input.onChange(base64);
  };
  const handleClick = () => {
    /*Collecting node-element and performing click*/
    if (!disabled) {
      inputFileRef.current.click();
    }
  };
  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <div>
            <Typography
              component="span"
              variant="caption"
              htmlFor={id}
              color="textPrimary"
              className={disabled ? classes.disabledLabel : ""}
            >
              {label}
              {required && <span> *</span>}
            </Typography>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              ref={inputFileRef}
              onChange={(e) => handleUpload(e, input)}
            />
            {input.value ? (
              <img src={input.value} className={classes.img} alt={"image_" + id} onClick={handleClick} />
            ) : (
              <Grid container spacing={0} direction="row">
                <ImagePlaceholder className={classes.imgPlaceholder} onClick={handleClick} />
                <Typography
                  onClick={handleClick}
                  variant="body1"
                  color="textSecondary"
                  component="label"
                  className={classes.linkLabel}
                >
                  {linkLabel}
                </Typography>
              </Grid>
            )}
            {meta.error && meta.touched && (
              <Grid item xs={12}>
                <Typography variant="caption" color="error">
                  {meta.error}
                </Typography>
              </Grid>
            )}
          </div>
        )}
      </Field>
    </div>
  );
}

export default withStyles(ImageUpload, styles)

import { call } from "../../../app/websocket/_sliceCalls/websocket";

const productGetBaseHierarchy = async (gtin, gln) => {
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "GET_BASE",
      request: {
        gtin,
        gln,
      },
    });
    let node = result?.product ? {
      height: result?.product?.weightMeasure?.height || { category: "COMP", value: 0, unit: "CMT", valueMax: 0 },
      width: result?.product?.weightMeasure?.width || { category: "COMP", value: 0, unit: "CMT", valueMax: 0 },
      depth: result?.product?.weightMeasure?.depth || { category: "COMP", value: 0, unit: "CMT", valueMax: 0 },
      netWeight: result?.product?.weightMeasure?.netWeight || { category: "MASS", value: 0, unit: "GRM", valueMax: 0 },
      grossWeight: result?.product?.weightMeasure?.grossWeight || { category: "MASS", value: 0, unit: "GRM", valueMax: 0 },
      netContent: result?.product?.weightMeasure?.netContent || { category: "MASS", value: 0, unit: "GRM", valueMax: 0 },
    } : {};
    if (result?.product?.baseInfo?.description) {
      node.description = result?.product?.baseInfo?.description;
    }
    if (result?.product?.units?.unitDescriptor) {
      node.unitDescriptor = result?.product?.units?.unitDescriptor;
    }
    if (result?.product?.media?.[0]?.thumbnail) {
      node.thumbnail = result?.product?.media?.[0]?.thumbnail;
    }
    return node;
  } catch (_ex) {
  }
};

export default productGetBaseHierarchy;

import { Button } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { CheckboxField, FileUploadField, GalleryUploadField } from "../../../../common/forms";

export const homeFields = ({ t, user, values }) => {
  const carouselImageLoaded = (e) => {
    let carouselDiv = e.target.parentElement.parentElement.parentElement.parentElement.parentElement;
    if (e.target.clientHeight) {
      setTimeout(() => {
        carouselDiv.style.height = e.target.clientHeight + "px"
      }, 300)
    }
  }
  return [
    {
      size: 3,
      field: <CheckboxField id="functional.home.hasDashboard" label={t("promoter.PromoterConfigs.form.hasDashboard")} />,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
    },
    {
      size: 3,
      field: <CheckboxField id="functional.home.hasFlyer" label={t("promoter.PromoterConfigs.form.hasFlyer")} />,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <FileUploadField id="functional.home.flyer" label={t("promoter.PromoterConfigs.form.flyer")} accept="application/pdf" />
      ),
      hidden: !values?.functional?.home?.hasFlyer || !user.hasPermission("promoter.actions.configs.functional"),
    },
    {
      size: 5,
      field: values?.home?.flyer?.url ? (
        <Button style={{ marginTop: 35 }} variant="outlined" href={values?.home?.flyer?.url} target="_blank">
          {t("promoter.PromoterConfigs.form.flyerPreview")}
        </Button>
      ) : null,
      hidden: !values?.functional?.home?.hasFlyer || !user.hasPermission("promoter.actions.configs.functional"),
    },
    {
      size: 9,
      hidden: values?.functional?.home?.hasFlyer || !user.hasPermission("promoter.actions.configs.functional"),
    },
    {
      size: 3,
      field: <CheckboxField id="functional.home.hasHighlights" label={t("promoter.PromoterConfigs.form.hasHighlights")} />,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: <GalleryUploadField id="functional.home.highlights" label={t("promoter.PromoterConfigs.form.highlights")} />,
      hidden: !values?.functional?.home?.hasHighlights || !user.hasPermission("promoter.actions.configs.functional"),
    },
    {
      size: 5,
      field: (
        <Carousel indicators={false} style={{ minHeight: 200 }}>
          {(values?.functional?.home?.highlights || []).map((highligh) => (
            <img key={highligh.id} alt={highligh.id} style={{ maxWidth: "100%" }} onLoad={carouselImageLoaded} src={highligh.base64} />
          ))}
        </Carousel>
      ),
      hidden: !values?.functional?.home?.hasHighlights || !user.hasPermission("promoter.actions.configs.functional"),
    },
  ];
};

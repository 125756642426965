export const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "100%",
  },
  info: {
    color: theme.palette.text.secondary,
  },
  percentageUp: {
    color: theme.palette.success.main,
    margin: 0,
  },
  percentageDown: {
    color: theme.palette.warning.main,
    margin: 0,
  },
  value: {
    marginRight: 10,
    marginBottom: 0,
    marginTop: 0,
  },
  valueLabel: {
    marginBottom: 0,
    marginTop: 0,
  },
  nomargin: {
    margin: 0,
  },
});

import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const entityGetGroupCall = async ({ setGroupDetails }, dispatch, { groupId }) => {
  let rh = requestHelper(dispatch, "GET_GROUP");
  try {
    let details = await call({
      type: "ENTITY",
      subType: "GET_GROUP",
      request: {
        id: groupId,
      },
    });

    dispatch(
      setGroupDetails({
        name: details?.groupEntity?.groupName,
        code: details?.groupEntity?.groupCode,
        groupId: details?.groupEntity?.groupId,
        entityId: details?.groupEntity?.entityId,
        groupEntities: details?.groupEntity?.listEntities,
      })
    );
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityGetGroupCall;

export const placeListDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "TITLE",
  sortType: "ASC",
  search: '',
  type: [],
  entityId: null,
  active: true
}


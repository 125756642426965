import React from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Typography, Stack } from "@mui/material";

import { styles } from "./BatchOrderProduct.styles";
import ProductSnip from "../../../../../common/displays/ProductSnip/ProductSnip";

function BatchOrderProduct({ classes, product, quantity }) {
  // const { t } = useTranslation();
  const { thumbnail, description, gtin } = product;

  return (
    <div className={classes.root}>
      <Stack spacing={1}>
        <ProductSnip image={thumbnail} description={description} gtin={gtin} />
        <Typography style={{ margin: 0, textAlign: "right" }}>
          {quantity + " UN"}
        </Typography>
      </Stack>
    </div>
  );
}

export default withStyles(BatchOrderProduct, styles);
export const styles = (theme) => ({
  root: {},
  input: {
    //maxWidth: 100,
    backgroundColor: "#FFFFFF",
    "& .MuiOutlinedInput-inputMarginDense": {
      // paddingTop: 4,
      // paddingBottom: 4,
      paddingLeft: 4,
      backgroundColor: "transparent",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    "& input": {
      textAlign: "right",
    },
  },
  unitsQuantity: {
    margin: 0,
    textAlign: "center",
  },
  select: {
    "&.MuiInput-underline": {
      "&::before": {
        border: "none !important",
      },
      "&::after": {
        border: "none !important",
      },
    },
    "& .MuiSelect-select.MuiSelect-select": {
      backgroundColor: "transparent",
    },
  },
  disabledInput: {
    backgroundColor: "#F5F7F9",
    color: "#97A1AF"
  }
});

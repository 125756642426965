import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const operatorListAddressCall = async ({ setOperators }, dispatch) => {
  let rh = requestHelper(dispatch, "GET_OPERATORS");
  try {
    let operators = await call({
      type: "OPERATOR",
      subType: "LIST_ADDRESS",
    });
    dispatch(setOperators(operators.addresses));
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default operatorListAddressCall;

import React, { cloneElement } from "react";
import { TableBody, TableRow, TableCell } from "@mui/material";
import ForwardIcon from '@mui/icons-material/Forward';

export default function CustomDataGridBody({ columns, rows, keyField, actionsComponent, onRowSelected, innerRow }) {
  let k = keyField;
  const onRowSelectedHandler = (row) => {
    if (row && onRowSelected) {
      onRowSelected(row);
    }
  };


  const getTableCells = (columns, row, row_index) => {
    let cells = [];
    let arrowDone = false;
    columns.forEach((col, index) => {
      if (!col.hidden) {
        cells.push(
          <TableCell style={col.width && col.width > 0 ? { width: col.width } : {}} hidden={col.hidden} key={"tbody_" + col.field + "_" + row_index} align={col.align}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: col.align === "center" ? "center" : col.align === "right" ? "flex-end" : "flex-start" }}>
              {!arrowDone && row.arrow && <div className="CdgRow-arrow"><ForwardIcon color="error" /></div>}
              {col.customValue ? col.customValue(row) : row[col.field]}
            </div>
          </TableCell>
        );
        arrowDone = true;
      }
    });
    return cells
  }

  return (
    <TableBody>
      {rows.map((row, row_index) => {
        return (
          <React.Fragment key={"frag_" + (k && row[k] ? row[k] : row_index)}>
            <TableRow
              key={"row_" + (k && row[k] ? row[k] : row_index)}
              className={"CdgRow" + (row.className ? " " + row.className : "")}
              onClick={() => onRowSelectedHandler(row)}
            >
              {getTableCells(columns, row, row_index)}
              {actionsComponent && (
                <TableCell key={"tbody_actions_" + row_index} align="right" className="CdgRow-actions">
                  {cloneElement(actionsComponent, { row })}
                </TableCell>
              )}
            </TableRow>
            {innerRow && innerRow(row) && (
              <TableRow key={row[k] + "_inner_" + row_index} className="CdgRow">
                <TableCell
                  colSpan={actionsComponent ? columns.length + 1 : columns.length}
                  style={{ paddingRight: 12, paddingLeft: 12 }}
                >
                  {innerRow(row, row_index)}
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        );
      })}
    </TableBody>
  );
}

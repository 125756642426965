import React from "react";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";
import {
  Typography,
  Grid,
  ListItem,
  ListItemText,
  List,
  Button,
  Paper,
} from "@mui/material";
import { getCountryName } from "../../../../common/utils";

import { styles } from "./EntityAddresses.styles";
import Permission from "../../../../app/components/Permission";

function EntityAddresses({ classes, onMore, addresses, disabled }) {
  const { t } = useTranslation();
  return (
    <Paper className={classes.root} elevation={0} id="entityAddresses">
      <div className={classes.container}>
        <Grid container direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            align="left"
            component="span"
            color="textSecondary"
            className={classes.title}
          >
            {t("entities.Entities.addresses.title")} ({addresses?.length || 0})
          </Typography>
          {!disabled &&
            <Permission code="entities.actions.address.add">
              <Button
                color="primary"
                variant="text"
                onClick={onMore}
                className={classes.btn}
                style={{ marginRight: 10 }}
              >
                {t("entities.Entities.addresses.btnMore")}
              </Button>
            </Permission>
          }
        </Grid>
        <List>
          {(addresses || []).slice(0, 5).map((address) => {
            return (
              <ListItem key={"item_ " + address.id} disableGutters>
                <ListItemText
                  disableTypography
                  primary={
                    <Grid container direction="row" justifyContent="space-between">
                      <Typography
                        variant="h4"
                        align="left"
                        component="h4"
                        className={classes.name}
                      >
                        {address.description ||
                          t("entities.Entities.addresses.defaultName")}
                      </Typography>
                    </Grid>
                  }
                  secondary={
                    <Grid container direction="row" justifyContent="space-between">
                      <Typography
                        variant="body1"
                        align="left"
                        component="span"
                        className={classes.details}
                      >
                        {address.address}, {address.postalCode} - {address.locale},{" "}
                        {getCountryName(address.country)}
                      </Typography>
                    </Grid>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </div>
    </Paper>
  );
}

export default withStyles(EntityAddresses, styles)

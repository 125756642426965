import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Paper, Grid, Button } from "@mui/material";

import { styles } from "./OrdersIncidentsForm.styles";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { formFields, formSchema } from "./OrdersIncidentsForm.schema";
import moment from "moment";
import { Form, FormFields } from "../../../common/forms";
import { orderCreateIncident } from "../ordersIncidentsSlice";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { orderDetails, selectOrderDetails } from "../../orders/ordersSlice";
import Page from "../../../common/displays/Page/Page";

function OrdersIncidentsForm({ classes }) {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const order = useSelector(selectOrderDetails);
  const navigate = useNavigate();

  useFirstLoad(() => {
    if (order?.id?.toString() !== orderId) {
      dispatch(orderDetails(orderId));
    }
  });

  const onSubmit = ({ code, document, deliveryTime, products }) => {
    let values = {
      orderId,
      code,
      document,
      deliveryTime,
      products: products
        .filter((item) => item.checked)
        .map(({ productId, quantity, productBatches, priceType }) => {
          return {
            productId,
            quantity,
            productBatches,
            priceType
          };
        }),
    };

    dispatch(orderCreateIncident(values));
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        schema={formSchema(t)}
        initialValues={{
          code: "MISSING",
          deliveryTime: moment(),
          products: (order?.products || []).map((item) => {
            return {
              ...item,
              checked: false,
              key: item.productId + item.orderSellerId + item.priceType,
            };
          }),
        }}
        render={({ submitting }) => {
          return (
            <Page
              permission="orders.actions.incident.create"
              header={<PageTitle
                title={t("ordersIncidents.OrdersIncidentsForm.header.title")}
                subtitle={t("ordersIncidents.OrdersIncidentsForm.header.subtitle", { orderId })}
                onBack={() => navigate("/orders/placed")}
                actions={
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit" size="large" disabled={submitting}>
                        {t("ordersIncidents.OrdersIncidentsForm.form.submitBtn")}
                      </Button>
                    </Grid>
                  </Grid>
                }
              />}>
              <Paper className={classes.paper}>
                <FormFields fields={formFields(t)} />
              </Paper>
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(OrdersIncidentsForm, styles);

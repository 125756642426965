import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";

const productPendingPriceActionCall = async (
  { clearTempValidation },
  dispatch,
  state,
  validationPrices
) => {
  let rh = requestHelper(dispatch, "PRODUCT_PENDING_PRICE_ACTION");
  // let filter = state.productsPrices.pricesListFilter;

  let actions = [];
  Object.keys(validationPrices).forEach(priceId => {
    let { valid, comment, taskId, productId } = validationPrices[priceId];
    if (valid !== undefined && taskId) {
      let req = actions.find(item => item.taskId === taskId);
      if (req) {
        req.prices.push({
          productId,
          priceId,
          comment,
          status: valid ? "ACCEPTED" : "REFUSED"
        })
      }
      else {
        actions.push({
          taskId,
          prices: [{
            productId,
            priceId,
            comment,
            status: valid ? "ACCEPTED" : "REFUSED"
          }]
        })
      }
    }
  })

  try {
    let result = await call({
      type: "PRODUCT",
      subType: "PENDING_PRICE_ACTION",
      request: {
        actions
      }
    });
    setTimeout(() => {
      if (result) {
        dispatch(setSuccess("productsPrices", "PRODUCT_PENDING_PRICE_ACTION"));
        dispatch(clearTempValidation());
        dispatch(setRedirect("/products/prices/validation"));
        // dispatch(productPriceList(filter, true))
      }
    }, 300);

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productPendingPriceActionCall;

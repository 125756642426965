// checks if quantity mets the campaign objective
export const isCampaignMet = (campaign, quantity, order) => {
  switch (campaign.campaignType) {
    case "PR": {
      return Boolean(campaign?.promotionCampaign?.productPrice.find((item) => quantity >= item.quantity));
    }
    case "BU": {
      let isMet = true;
      (campaign?.bundlingCampaign?.productBuy || []).forEach((item) => {
        let p = (order?.products || []).find((orderProduct) => orderProduct.productId === item.productId);
        if (!p?.quantity || p.quantity < item.quantity) {
          isMet = false;
        }
      });
      return Boolean(isMet);
    }
    case "MB": {
      return quantity >= campaign?.merchandiseBonusesCampaign?.qtBuy;
    }
    case "PD": {
      return quantity > 0;
    }
    default: {
      return false;
    }
  }
};

export const getBundleOffers = (bundleCampaigns, order) => {
  let products = [];

  (bundleCampaigns || []).forEach((campaign) => {
    if (isCampaignMet(campaign, 0, order)) {
      let multipliers = campaign?.bundlingCampaign?.productBuy?.map((item) => {
        let product = (order?.products || []).find((op) => op.productId === item.productId);
        return Math.floor(product.quantity > 0 ? product.quantity / item.quantity : 0);
      });

      (campaign?.bundlingCampaign?.productOffers || []).forEach((p) => {
        let product = (campaign?.products || []).find((cp) => cp.productId === p.productId);
        products.push({ ...product, quantity: p.quantity * Math.min(...multipliers), campaign });
      });
    }
  });
  return products;
};

export const getPriceWithCampaigns = (product) => {
  let price = null;
  (product.campaigns || [])
    .filter((c) => c.campaignType === "PR")
    .forEach((campaign) => {
      let casesMet = campaign?.promotionCampaign?.productPrice
        .filter((item) => product.quantity >= item.quantity)
        .sort((a, b) => (a.price < b.price ? -1 : 1));
      if (casesMet.length > 0) {
        price = parseFloat(casesMet[0].price);
      }
    });
  if (product.quantity > 0) {
    (product.campaigns || [])
      .filter((c) => c.campaignType === "PD")
      .forEach((campaign) => {
        if (price) {
          price = price - (price * parseFloat(campaign?.percentageCampaign?.percentage)) / 100;
        } else {
          let priceBase = product.retailPrice < product.salePrice ? product.retailPrice : product.salePrice;
          price = priceBase - (priceBase * parseFloat(campaign?.percentageCampaign?.percentage)) / 100;
        }
      });
  }
  return price;
};

export const getProductPrice = (product) => {
  if (product.price) {
    return product.price;
  }
  return product.retailPrice < product.salePrice ? product.retailPrice : product.salePrice;
};

export const getNewVolumeProducts = (volumes, products) => {
  return products.map((product) => {
    let selectedQuantity =
      volumes?.reduce((pv, cv) => {
        return pv + ((cv.products || []).find((p) => p.key === product.key)?.quantity || 0);
      }, 0) || 0;
    let qty = product.quantity - selectedQuantity;
    return {
      ...product,
      quantity: qty > 0 ? qty : 0,
    };
  });
};

export const getNewBatchQuantity = (product) => {
  let selectedQuantity =
    product.productBatch?.reduce((pv, cv) => {
      return pv + cv?.quantity || 0;
    }, 0) || 0;

  let qty = product.quantity - selectedQuantity;
  return qty > 0 ? qty : 0;
};

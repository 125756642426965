import * as Yup from "yup";
import { DatePickerField, NumberWithSelectField, SelectField } from "../../../../common/forms";
import moment from "moment";

export function formSchema({ t, ogAmount }) {
  return Yup.object().shape({
    code: Yup.string().required(t("products.ProductOtherTaxFormModal.form.codeRequired")),
    amount: Yup.object().shape({
      value: Yup.string()
        .not(["0"], t("products.ProductOtherTaxFormModal.form.amountValueRequired"))
        .required(t('products.ProductOtherTaxFormModal.form.amountValueRequired'))
        .when("type", {
          is: "P",
          then: (schema) => schema.test(
            "maxPercentage",
            t('products.ProductOtherTaxFormModal.form.maxPercentage'),
            (value) => parseFloat(value) <= 100
          ),
          otherwise: (schema) => schema
        })
        .test(
          "sameAmount",
          t('products.ProductOtherTaxFormModal.form.sameAmount'),
          (value, context) => !ogAmount || context?.parent?.type !== ogAmount.type || parseFloat(value) !== parseFloat(ogAmount.value)
        ),
      type: Yup.string().required(t("products.ProductOtherTaxFormModal.form.amountTypeRequired")),
    }),
    startDate: Yup.date()
      .required(t("products.ProductOtherTaxFormModal.form.startDateRequired"))
      .min(moment().format("YYYY-MM-DDT00:00:00"), t('products.ProductOtherTaxFormModal.form.startDateMin')),
  });
}


export function formFields({ t, otherTaxes, values, isEdit }) {
  const typeOptions = [];
  if (values.code === "EXC") {
    typeOptions.push(amountOption);
  }
  else {
    typeOptions.push(amountOption);
    typeOptions.push(percentOption);
  }

  return [
    {
      size: 12,
      field: <SelectField
        id="code"
        label={t("products.ProductOtherTaxFormModal.form.code")}
        options={otherTaxes}
        disabled={isEdit}
      />,
    },
    {
      size: 12,
      field: <NumberWithSelectField
        id="amount"
        label={t("products.ProductOtherTaxFormModal.form.amount")}
        inputType="number"
        textKey="value"
        selectKey="type"
        options={typeOptions}
        decimalScale={5}
      />,
    },
    {
      size: 12,
      field: <DatePickerField
        id="startDate"
        minDate={moment()}
        label={t("products.ProductOtherTaxFormModal.form.startDate")}
      />,
    },
  ];
}

const amountOption = { value: "A", text: "€" };
const percentOption = { value: "P", text: "%" };
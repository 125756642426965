import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Paper, Grid, Button } from "@mui/material";
import { usePromoter } from "../../../common/hooks/usePromoter";

import { styles } from "./UserForm.styles";
import { formFields, formSchema } from "./UserForm.schema";
import { useParams } from "react-router-dom";

import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import DeleteButton from "../../../common/components/dialogs/DeleteButton";
import Form from "../../../common/forms/Form";
import Permission from "../../../app/components/Permission";
import { useFirstLoad, useNavigate, useUser } from "../../../common/hooks";

import {
  addContact,
  updateContact,
  deleteContact,
  selectEntityDetails,
  entityGet,
  entityGetContact,
  selectContactDetails,
} from "../entitiesSlice";
import { FormFields } from "../../../common/forms";
import Page from "../../../common/displays/Page/Page";

function UserForm({ classes }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, contactId } = useParams();
  const details = useSelector(selectEntityDetails);
  const contactDetails = useSelector(selectContactDetails);
  //const contact = details && details.contacts && details.contacts.find((item) => item.id === contactId);
  const promoter = usePromoter();
  const user = useUser();

  useFirstLoad(() => {
    if (details?.entityId?.toString() !== id) {
      dispatch(entityGet(id));
    }
  });

  useEffect(() => {
    if (details && contactDetails?.contact?.id !== contactId) {
      dispatch(entityGetContact({ entityId: id, contactId }));
    }
    // eslint-disable-next-line
  }, [details]);

  const contact = useMemo(() => {
    let c = (details?.contacts || []).find((item) => item.id === contactId)
    if (c?.logo) {
      return { ...c, logo: { url: c.logo } }
    }
    return c;
    // eslint-disable-next-line
  }, [details]);

  const isLastFullAccessUser = useMemo(() => {
    return contact?.roleType === "F" && (details?.contacts || []).filter((item) => item.roleType === "F").length === 1;
  }, [contact, details]);

  const onSubmitHandler = async (values) => {
    if (contactId) {
      dispatch(updateContact(promoter, id, contactId, values));
    } else {
      dispatch(addContact(promoter, id, values));
    }
  };

  return (
    <Form
      onSubmit={onSubmitHandler}
      initialValues={contact || {}}
      schema={formSchema({ t, isEdit: Boolean(contactId) })}
      render={({ submitting, values }) => (
        <Page
          permission={id ? "entities.actions.contact.update" : "entities.actions.contact.add"}
          header={<PageTitle
            title={
              contactId
                ? contact && (contact.firstName ? contact.firstName + " " : "") + (contact.lastName || "")
                : t("entities.CreateEntity.form.addUserForm")
            }
            onBack={() => navigate(`/entities/${id}/contacts`)}
            actions={
              <Grid container direction="row" spacing={1}>
                {contactId && !isLastFullAccessUser && (
                  <Grid item>
                    <Permission code="entities.actions.contact.disable">
                      { (user?.entity?.type === "P" || user?.entity?.id === id) && !contact?.toDisable && <DeleteButton
                        labels={{
                          button: t("entities.Entities.confirmDeleteContact.button"),
                          title: t("entities.Entities.confirmDeleteContact.title"),
                          description: t("entities.Entities.confirmDeleteContact.description"),
                          ok: t("entities.Entities.confirmDeleteContact.ok"),
                          cancel: t("entities.Entities.confirmDeleteContact.cancel"),
                        }}
                        onConfirm={() => {
                          dispatch(deleteContact(promoter, id, contactId));
                        }}
                      />}
                    </Permission>
                  </Grid>
                )}
                <Grid item>
                  <Permission code={contactId ? "entities.actions.contact.update" : "entities.actions.contact.add"}>
                    <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                      {contactId
                        ? t("entities.CreateEntity.form.updateUserBtn")
                        : t("entities.CreateEntity.form.addUserBtn")}
                    </Button>
                  </Permission>
                </Grid>
              </Grid>
            }
          />}>
          <Paper className={classes.paper} elevation={0}>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item>
                <FormFields
                  fields={formFields({ t, user, isLastFullAccessUser, promoter, isEdit: Boolean(contactId), values, roleList: contactDetails?.roleList || [] })}
                />
              </Grid>
            </Grid>
          </Paper>
        </Page>
      )}
    />
  );
}

export default withStyles(UserForm, styles);

import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, IconButton } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

import { styles } from "./CsvExport.styles";
import { getCsvDataUrl } from "../../../utils";

function CsvExport({ classes, data, fields }) {
  let filteredData = (data || []).map((item) => {
    let nItem = {};
    fields?.forEach(({ header, field, customValue }) => {
      let key = header || field;
      let value = customValue ? customValue(item) : item[field];
      nItem[key] = value !== undefined ? value.toString() : "";
    });
    return nItem;
  });

  const onClickHandler = () => {
    JSONToCSVConvertor(filteredData);
  };

  return (
    <div className={classes.root}>
      <IconButton id="export-button-csv" className="export-button-csv" onClick={onClickHandler} disabled={!Boolean(data?.length > 0)}>
        <InsertDriveFileOutlinedIcon />
      </IconButton>
      <Typography className={classes.label}>CSV</Typography>
    </div>
  );
}

export default withStyles(CsvExport, styles)

function JSONToCSVConvertor(data, fileName) {
  let uri = getCsvDataUrl(data);
  let link = document.createElement("a");
  link.href = uri;
  link.style = "visibility:hidden";
  link.download = fileName ? fileName + ".csv" : "export.csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const styles = (theme) => ({
  root: {},
  modal: {
    paddingTop: "40vh",
  },
  modalroot: {
    width: "30vw",
    margin: "0 auto",
    backgroundColor: theme.palette.secondary.main,
    height: "20vh",
    padding: theme.spacing(2),
  },
  modalBody: {
    height: "calc(20vh - 40px)",
  },
});

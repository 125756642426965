import * as Yup from "yup";
import { TextField } from "../../../common/forms";
import ProductDiffField from "./ProductDiffField/ProductDiffField";

export function formSchema(t) {
  return Yup.object().shape({
    comment: Yup.string().required(t("products.ProductValidationForm.form.requiredCommnent")),
  });
}

export function formSchemaAux() {
  return Yup.object().shape({
    comment: Yup.string(),
  });
}

export function formFields(t, details, values, viewMode) {
  return [
    {
      size: 12,
      field: (
        <TextField
          id="comment"
          label={t("products.ProductValidationForm.form.comment")}
          required={
            (values.selected?.acceptedFields || []).length === 0 ||
            (values.selected?.acceptedFields || []).length !== values.selected?.total
          }
        />
      ),
      hidden: viewMode
    },
    {
      size: 12,
      field: <ProductDiffField id="selected" details={details} viewMode={viewMode} />,
    },
  ];
}

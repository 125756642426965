import { Typography } from "@mui/material";
import * as Yup from "yup";
import { EntityAutoCompleteField, FileUploadField, NumberField } from "../../../common/forms";

export const formSchema = (t) => {
  return Yup.object().shape({
    owner: Yup.string().required(t("products.ProductImportImages.form.requiredOwner")),
    gln: Yup.string()
      .required(t("products.ProductImportImages.form.requiredGln"))
      .min(13, t("products.ProductImportImages.form.specificDigits", { digits: 13 }))
      .max(13, t("products.ProductImportImages.form.specificDigits", { digits: 13 })),
  });
};

export const formFields = (t, { user, promoter, form, entityChangeHandler }) => {
  return [
    {
      size: 8,
      field: (
        <EntityAutoCompleteField
          typeFilter={promoter?.getConfig("product", "allowedEntityTypes")}
          id="owner"
          label={t("products.ProductImportImages.form.owner")}
          required
          onChange={(entity) => entityChangeHandler(entity, form)}
        />
      ),
      hidden: user?.entity?.type !== "P",
    },
    {
      size: 8,
      field: (
        <NumberField
          id="gln"
          type="text"
          label={t("products.ProductImportImages.form.gln")}
          required
          maxLength={13}
          decimalScale={0}
        />
      ),
    },
    {
      size: 12,
      field: <Typography>{t("products.ProductImportImages.form.fileNotes")}</Typography>,
    },
    {
      size: 4,
      field: (
        <FileUploadField
          id="file"
          accept="application/zip"
          label={t("products.ProductImportImages.form.file")}
          required
        />
      ),
    },
  ];
};

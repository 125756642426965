import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button, Paper, Divider, IconButton, Stack } from "@mui/material";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";

import { styles } from "./EntityDocuments.styles";
import { formFields, formSchema } from "./EntityDocuments.schema";

import Permission from "../../../../app/components/Permission";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";
import { usePromoter, useUser } from "../../../../common/hooks";
import { Form, FormFields } from "../../../../common/forms";
import { entityAddDocument } from "../../entitiesSlice";
import { dateFormat } from "../../../../common/utils";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";

function EntityDocuments({ classes, documents, entityId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const promoter = usePromoter();
  const user = useUser();
  const height = 600;

  const onAttachHandler = () => {
    setModal(true);
  }

  const onSubmitHandler = async (values) => {
    dispatch(entityAddDocument(values));
    setModal(false);
  }

  return (
    <Paper className={classes.root} elevation={0} id="entityDocuments">
      <div className={classes.container}>
        <Grid container direction="row" justifyContent="space-between">
          <Typography
            variant="h6"
            align="left"
            component="span"
            color="textSecondary"
            className={classes.title}
          >
            {t("entities.EntityDetails.documents.title")} ({documents?.length || 0})
          </Typography>
          <Permission code="entities.actions.entity.update">
            {((user.permissionFilter("entities.filters.entity.update.onlySelf") && user?.entity?.id === entityId) || !user.permissionFilter("entities.filters.entity.update.onlySelf")) &&
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={onAttachHandler}
                className={classes.btn}
                style={{ marginRight: 10 }}
              >
                {t("entities.EntityDetails.documents.attachFile")}
              </Button>
            }
          </Permission>
        </Grid>
        <Stack spacing={2}>
          {(documents || []).map((item, index) => (
            <div key={`documents_${index}`}>
              <Grid container spacing={2} alignItems="center">
                {index > 0 && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
                <Grid item xs={3} style={{ textAlign: "center" }}>
                  <IconButton disableRipple size="large" href={item.signedUrl} rel="noreferrer" target="_blank">
                    <Grid container direction="column">
                      <Grid item>
                        <FileOpenOutlinedIcon fontSize="large" color="action" />
                      </Grid>
                      <Grid item>
                        <Typography variant="caption">{t(`common.Enums.documentType.${item.type}`)}</Typography>
                      </Grid>
                    </Grid>
                  </IconButton>
                </Grid>
                {item?.metadata && (
                  <Grid item xs={9}>
                    <Grid container spacing={1}>
                      {Object.keys(item?.metadata).map((meta) => {
                        let value = item?.metadata?.[meta];
                        if (meta === "EXD" || meta === "RCD") {
                          value = dateFormat(value);
                        }
                        return (
                          <Grid item xs={6} key={`documents_meta_${index}_${meta}`}>
                            <LabelValue label={t(`common.Enums.documentMetadata.${meta}`)} value={value} />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          ))}
        </Stack>
      </div>
      <CustomModal open={modal} setOpen={setModal} size="large" height={height} title={t("entities.EntityDetails.modalDocuments.title")}>
        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t, metadata: promoter?.getConfig("document", "metadata") })}
          initialValues={{ entityId }}
          render={({ values }) => {
            return (
              <Grid container direction="column" spacing={2} justifyContent="space-between" style={{ height: height - 64 }}>
                <Grid item>
                  <FormFields fields={formFields({ t, metadata: promoter?.getConfig("document", "metadata")?.[values.type] || {}, documentTypes: promoter?.getConfig("entity", "documentTypes") })} />
                </Grid>
                <Grid item alignSelf="flex-end">
                  <Button variant="contained" color="primary" type="submit">{t("entities.EntityDetails.modalDocuments.btnSave")}</Button>
                </Grid>
              </Grid>
            )
          }}
        />
      </CustomModal>
    </Paper>
  );
}

export default withStyles(EntityDocuments, styles);
export const styles = () => ({
  root: {
  },
  links: {
    width: "100%",
    textAlign: "right",
    "& .MuiToggleButtonGroup-root": {
      backgroundColor: "#FFF"
    }
  },
  alert: {
    backgroundColor: "#FFF",
    padding: "2px 12px",
    "& .MuiAlert-message": {
      width: "100%"
    }
  }
});
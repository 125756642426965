import { dateTimeFormatWithMilliSeconds } from "../../../../common/utils/formats";

export function listColumns(t, classes) {
  return [
    {
      field: "datetime",
      headerName: t("business.AuditsList.columns.requestTime"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.datetime));
      },
    },
    {
      field: "username",
      headerName: t("business.AuditsList.columns.userRequest"),
      align: "left",
      headerAlign: "left"
    },
    {
      field: "entity",
      headerName: t("business.AuditsList.columns.entity"),
      align: "left",
      headerAlign: "left"
    },
    {
      field: "language",
      headerName: t("business.AuditsList.columns.language"),
      align: "left",
      headerAlign: "left"
    }
  ];
}

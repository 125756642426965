
import { SvgIcon } from "@mui/material";

export default function CampaignTypeIcon(props) {
  const { type } = props;
  switch (type) {
    case "MB":
      return (
        <SvgIcon {...props} style={{ fill: "#97A1AF" }}>
          <path d="M4 2H20C21 2 22 2.9 22 4V7.01C22 7.73 21.57 8.35 21 8.7V20C21 21.1 19.9 22 19 22H5C4.1 22 3 21.1 3 20V8.7C2.43 8.35 2 7.73 2 7.01V4C2 2.9 3 2 4 2ZM5 20H19V9H5V20ZM4 7H20V4H4V7ZM9 12H15V14H9V12Z" />
        </SvgIcon>
      );
    case "PD":
      return (
        <SvgIcon {...props} style={{ fill: "#97A1AF" }}>
          <path d="M13.0238 16.6119C13.0305 18.2177 14.0494 19.5874 15.9453 19.5874C17.8345 19.5874 18.8736 18.2177 18.8668 16.6119V15.8832C18.8736 14.2638 17.8615 12.9077 15.9453 12.9077C14.0763 12.9077 13.0305 14.2773 13.0238 15.8832V16.6119ZM6 8.70419C6.00675 10.31 7.02557 11.6662 8.92827 11.6662C10.8107 11.6662 11.8498 10.3235 11.843 8.70419V7.9755C11.8498 6.35618 10.8377 5 8.92827 5C7.06605 5 6.00675 6.35618 6 7.9755V8.70419ZM6.60724 19.2028H8.30078L17.8008 5.38459H16.1072L6.60724 19.2028ZM14.7848 15.8832C14.7915 15.168 15.0952 14.446 15.9453 14.446C16.8359 14.446 17.0991 15.168 17.0923 15.8832V16.6119C17.0991 17.3271 16.8089 18.0355 15.9453 18.0355C15.0817 18.0355 14.7915 17.3203 14.7848 16.6119V15.8832ZM7.7745 7.9755C7.78125 7.26705 8.07138 6.53835 8.92827 6.53835C9.81889 6.53835 10.0753 7.2603 10.0685 7.9755V8.70419C10.0753 9.41939 9.7919 10.1278 8.92827 10.1278C8.06463 10.1278 7.78125 9.41939 7.7745 8.70419V7.9755Z" />
        </SvgIcon>
      );
    case "PR":
      return (
        <SvgIcon {...props} style={{ fill: "#97A1AF" }}>
          <path d="M8 6.5C8 7.32843 7.32843 8 6.5 8C5.67157 8 5 7.32843 5 6.5C5 5.67157 5.67157 5 6.5 5C7.32843 5 8 5.67157 8 6.5Z" />
          <path d="M12.41 2.58L21.41 11.58C21.77 11.94 22 12.45 22 13C22 13.55 21.78 14.05 21.41 14.41L14.41 21.41C14.05 21.78 13.55 22 13 22C12.45 22 11.95 21.78 11.59 21.42L2.59 12.42C2.22 12.05 2 11.55 2 11V4C2 2.9 2.9 2 4 2H11C11.55 2 12.05 2.22 12.41 2.58ZM4 11L13 20.01L20 12.99L11 3.99V4H4V11Z" />
        </SvgIcon>
      );
    default:
      return (
        <SvgIcon {...props} style={{ fill: "#97A1AF" }}>
          <path d="M11.99 18.54L4.62 12.81L3 14.07L12 21.07L21 14.07L19.37 12.8L11.99 18.54ZM12 16L19.36 10.27L21 9L12 2L3 9L4.63 10.27L12 16ZM12 4.53L17.74 9L12 13.47L6.26 9L12 4.53Z" />
        </SvgIcon>
      );
  }
}

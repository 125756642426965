import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import ActionsPopover from "../../../../common/components/ActionsPopover/ActionsPopover";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ReplyIcon from "@mui/icons-material/Reply";
import Permission from "../../../../app/components/Permission";

export default function PurchasesDataGridActions({ row, onAccept, onReject, onProposal, onExecute }) {
  const { t } = useTranslation();

  const onAcceptHandler = (e) => {
    e.preventDefault();
    onAccept && onAccept(row);
  };

  const onRejectHandler = (e) => {
    e.preventDefault();
    onReject && onReject(row);
  };

  const onProposalHandler = (e) => {
    e.preventDefault();
    onProposal && onProposal(row);
  };

  const onExecuteHandler = (e) => {
    e.preventDefault();
    onExecute && onExecute(row);
  }

  let actionsButtons = [];
  // ACCEPT
  if (row.status !== "ACCEPTED") {
    actionsButtons.push(<Button onClick={onAcceptHandler} color="success" fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item style={{ display: "flex" }}>
          <CheckIcon />
        </Grid>
        <Grid item>{t("productsPurchases.PendingPurchasesList.actions.acceptPurchase")}</Grid>
      </Grid>
    </Button>);
  }

  // REJECT
  if (row.status !== "ACCEPTED") {
    actionsButtons.push(<Button onClick={onRejectHandler} color="error" fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item style={{ display: "flex" }}>
          <CloseIcon />
        </Grid>
        <Grid item>{t("productsPurchases.PendingPurchasesList.actions.rejectPurchase")}</Grid>
      </Grid>
    </Button>);
  }

  // PROPOSE
  if (row.status !== "ACCEPTED") {
    actionsButtons.push(<Button onClick={onProposalHandler} color="primary" fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item style={{ display: "flex" }}>
          <ReplyIcon />
        </Grid>
        <Grid item>{t("productsPurchases.PendingPurchasesList.actions.proposePurchase")}</Grid>
      </Grid>
    </Button>);
  }

  // EXECUTE
  if (row.status === "ACCEPTED") {
    actionsButtons.push(<Button onClick={onExecuteHandler} color="primary" fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item style={{ display: "flex" }}>
          <CheckIcon />
        </Grid>
        <Grid item>{t("productsPurchases.PendingPurchasesList.actions.executePurchase")}</Grid>
      </Grid>
    </Button>);
  }

  return (
    <Grid container spacing={1} justifyContent="flex-end">
      {row?.waitingAction && (
        <Permission code="products.actions.purchase.action">
          <Grid item>
            <ActionsPopover
              actions={actionsButtons}
            />
          </Grid>
        </Permission>
      )}
    </Grid>
  );
}

import { Stack } from "@mui/material"
import BlockCard from "../BlockCard/BlockCard"
import SlimCheckBoxField from "../SlimCheckBoxField/SlimCheckBoxField"

export const batchesFormFields = (t) => {
  return [
    {
      size: 3,
      field: <BlockCard title="batches">
        <Stack>
          <SlimCheckBoxField id="permissions.batches.actions.batches.list" label="list" />
          <SlimCheckBoxField id="permissions.batches.actions.batches.create" label="create" />
          <SlimCheckBoxField id="permissions.batches.actions.batches.update" label="update" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="batchesOrders">
        <Stack>
          <SlimCheckBoxField id="permissions.batches.actions.batchesOrders.list" label="list" />
          <SlimCheckBoxField id="permissions.batches.actions.batchesOrders.create" label="create" />
        </Stack>
      </BlockCard>,
    },
  ]
}
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useFirstLoad, useNavigate } from "../../../common/hooks";

import { styles } from "./CatalogDetails.styles";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { getCatalog, selectCatalogDetails } from "../productsSlice.catalogCalls";
import CatalogProductsDataGrid from "./components/CatalogProductsDataGrid";
import CatalogBuyersDataGrid from "./components/CatalogBuyersDataGrid";
import Page from "../../../common/displays/Page/Page";
import Permission from "../../../app/components/Permission";

function CatalogDetails({ classes }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const details = useSelector(selectCatalogDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useFirstLoad(() => {
    if (id && details?.id !== id) {
      dispatch(getCatalog(id));
    }
  });

  return (
    <Page
      permission="products.actions.catalog.get"
      header={<PageTitle
        title={details?.name}
        subtitle={details?.ownerName}
        onBack={() => navigate("/products/catalogs")}
        actions={
          <Grid container direction="row" spacing={2}>
            <Permission code="products.actions.catalog.update">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => navigate("/products/catalogs/edit/" + id)}
                >
                  {t("products.CatalogDetails.header.editBtn")}
                </Button>
              </Grid>
            </Permission>
          </Grid>
        }
      />}>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper} elevation={0}>
            <Typography variant="h6" align="left" component="span" color="textSecondary">
              {t("products.CatalogDetails.details.products")}
            </Typography>
            <CatalogProductsDataGrid products={details?.products} />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper} elevation={0}>
            <Typography variant="h6" align="left" component="span" color="textSecondary">
              {t("products.CatalogDetails.details.buyers")}
            </Typography>
            <CatalogBuyersDataGrid entities={details?.buyers} />
          </Paper>
        </Grid>
      </Grid>
    </Page>
  );
}

export default withStyles(CatalogDetails, styles);

import React from "react";
import { withStyles } from "tss-react/mui";
import { Grid, Paper, Typography } from "@mui/material";
import { styles } from "./DashboardBlock.styles";
import { CompletedIcon, InTransitIcon, WaitingIcon } from "../../icons";

function DashboardBlock({ classes, label, value, valueLabel, icon }) {
  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container direction="column">
        <Grid item>
          <Grid container direction="row" alignItems="flex-start">
            <Grid item xs={3}>
              {icon === "waiting" && <WaitingIcon className={classes.icon} />}
              {icon === "transit" && <InTransitIcon className={classes.icon} />}
              {icon === "complete" && <CompletedIcon className={classes.icon} />}
            </Grid>

            <Grid item xs={9}>
              <Typography className={classes.value} variant="h1">
                {value}
              </Typography>
              <Typography className={classes.valueLabel} variant="h3">
                {valueLabel}
              </Typography>

              <Typography variant="h6" color="textSecondary" className={classes.category}>
                {label}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(DashboardBlock, styles)



import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const productRemoveTaxCall = async (
  dispatch,
  { productId, code, startDate, value, type }
) => {
  let rh = requestHelper(dispatch, "PRODUCT_REMOVE_TAX");
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "REMOVE_TAX",
      request: {
        productId,
        code,
        startDate,
        value,
        type,
      },
    });

    setTimeout(() => {
      if (result) {
        dispatch(setSuccess("products", "PRODUCT_REMOVE_TAX"));
      }
    }, 300);

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productRemoveTaxCall;


import * as Yup from "yup";
import { TextField } from "../../../../common/forms";


export function formSchema({ t }) {
  return Yup.object().shape({
    name: Yup.string().required(t("businessUnits.CreateStepModal.form.nameRequired")),
  });
}

export function formFields({ t }) {
  return [
    {
      size: 12,
      field: <TextField
        id="name"
        label={t("businessUnits.CreateStepModal.form.name")}
      />,
    },
  ];
}


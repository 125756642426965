import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Stack } from "@mui/material";

import { styles } from "./DpromValidationCommentModal.styles";
import { formFields, formSchema } from "./DpromValidationCommentModal.schema";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";

import { Form, FormFields } from "../../../../common/forms";

function DpromValidationCommentModal({ classes, open, setOpen, onSubmit, accept }) {
  const { t } = useTranslation();

  const onSubmitHandler = async (values) => {
    onSubmit && onSubmit(values.comment);
  };

  return (
    <CustomModal open={open} setOpen={setOpen} size="medium" height={265} title={accept ? t("productsDproms.DpromValidationCommentModal.modal.title") : t("productsDproms.DpromValidationCommentModal.modal.titleRefuse")}>
      <div className={classes.root}>
        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t, accept })}
          initialValues={{}}
          render={({ values }) => {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormFields fields={formFields({ t, accept })} />
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <Button variant="outlined" color="primary" type="submit" fullWidth>
                      {t("productsDproms.DpromValidationCommentModal.form.btnCancel")}
                    </Button>
                    <Button variant="contained" color={accept ? "primary" : "error"} type="submit" fullWidth>
                      {accept ? t("productsDproms.DpromValidationCommentModal.form.btnAccept") : t("productsDproms.DpromValidationCommentModal.form.btnRefuse")}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            );
          }}
        />
      </div>
    </CustomModal>
  );
}

export default withStyles(DpromValidationCommentModal, styles);

import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid } from "@mui/material";

import { styles } from "./MerchandiseInput.styles";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { QuantityInput } from "../../../../../common/inputs/custom";

// "campaignType": "MB",
// "merchandiseBonusesCampaign": {
//   "qtBuy": 120,
//   "qtOffer": 100
// },

function MerchandiseInput({ classes, id, disabled, products }) {
  const { t } = useTranslation();
  const quantityHandler = (input, val, key) => {
    let nValue = input.value || { qtBuy: 0, qtOffer: 0 };
    if (key === "qtBuy") {
      input.onChange && input.onChange({ ...nValue, qtBuy: val });
    } else {
      input.onChange && input.onChange({ ...nValue, qtOffer: val });
    }
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <div>
            <Grid container spacing={1}>
              <Grid item>
                <Grid container justifyContent="space-between" direction="column" spacing={1}>
                  <Grid item>
                    <Typography
                      component="span"
                      variant="caption"
                      htmlFor={id}
                      color="textPrimary"
                      className={classes.lbl}
                    >
                      {t("products.CampaignConfiguration.form.merchandiseBuy")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      component="span"
                      variant="caption"
                      htmlFor={id}
                      color="textPrimary"
                      className={classes.lbl}
                    >
                      {t("products.CampaignConfiguration.form.merchandiseOffer")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ textAlign: "center" }}>
                <Grid container justifyContent="space-between" direction="column" spacing={1}>
                  <Grid item>
                    <QuantityInput
                      slim
                      style={{ width: 105 }}
                      multi={1}
                      value={input.value.qtBuy}
                      disabled={disabled}
                      onChange={(val) => quantityHandler(input, val, "qtBuy")}
                      hierarchy={products?.length > 0 ? products[0].hierarchy : undefined}
                    />
                  </Grid>
                  <Grid item>
                    <QuantityInput
                      slim
                      style={{ width: 105 }}
                      multi={1}
                      value={input.value.qtOffer}
                      disabled={disabled}
                      onChange={(val) => quantityHandler(input, val, "qtOffer")}
                      hierarchy={products?.length > 0 ? products[0].hierarchy : undefined}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </Field>
    </div>
  );
}

export default withStyles(MerchandiseInput, styles)

import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import { withStyles } from "tss-react/mui";
import { styles } from "./CampaignConfiguration.styles";
import { formFields, formSchema } from "./CampaignConfiguration.schema";

import { createCampaign, updateCampaign, disableCampaign } from "../../productsSlice.campaignsCalls";
import DeleteButton from "../../../../common/components/dialogs/DeleteButton";
import { sleep } from "../../../../common/utils";
import Form from "../../../../common/forms/Form";
import Permission from "../../../../app/components/Permission";

function CampaignConfiguration({ classes, campaignGroupId, campaign, viewMode }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = async (values, form) => {
    let filteredValues = { ...values };
    if (values?.promotionCampaign?.productPrice) {
      filteredValues.promotionCampaign.productPrice = values?.promotionCampaign?.productPrice.filter(
        (item) => item.quantity > 0 && item.price > 0
      );
    }

    if (campaign) {
      dispatch(updateCampaign({ ...filteredValues, campaignGroupId, campaignId: campaign.id }));
    } else {
      dispatch(createCampaign({ ...filteredValues, campaignGroupId }));
    }

    await sleep(300);
    if (!campaign) {
      form.reset();
    }
  };

  const onDeleteHandler = () => {
    if (campaign) {
      dispatch(disableCampaign({ campaignGroupId, campaignId: campaign.id }));
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      schema={formSchema(t)}
      initialValues={campaign}
      render={({ values }) => {
        return (
          <div style={{ width: "100%" }}>
            <Grid container alignItems="flex-start" spacing={2}>
              {formFields(t, campaign?.id, viewMode, values.campaignType, values.products).map((item, idx) => {
                if (!item.hidden) {
                  return (
                    <Grid item xs={item.size} key={(campaign?.id || "new") + "_" + idx}>
                      {item.field}
                    </Grid>
                  );
                }
                return null;
              })}
              <Grid item xs={12}>
                <Grid container spacing={1} justifyContent="flex-end">
                  {campaign && !viewMode && (
                    <Grid item>
                      <Permission code="products.actions.campaign.update">
                        <DeleteButton
                          labels={{
                            button: t("products.CampaignConfiguration.form.deleteBtn"),
                            title: t("products.CampaignConfiguration.deleteConfirm.title"),
                            description: t("products.CampaignConfiguration.deleteConfirm.description"),
                            ok: t("products.CampaignConfiguration.deleteConfirm.ok"),
                            cancel: t("products.CampaignConfiguration.deleteConfirm.cancel"),
                          }}
                          onConfirm={onDeleteHandler}
                        />
                      </Permission>
                    </Grid>
                  )}
                  {!viewMode && (
                    <Grid item>
                      <Permission code="products.actions.campaign.update">
                        <Button variant="contained" color="primary" type="submit">
                          {t("products.CampaignConfiguration.form.addConfigurationBtn")}
                        </Button>
                      </Permission>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      }}
    />
  );
}

export default withStyles(CampaignConfiguration, styles)

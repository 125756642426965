export const styles = (theme) => ({
  root: {},
  warning: {
    color: theme.palette.error.main,
  },
  password: {
    width: "100%",
    borderRadius: 3,
    border: "1px solid #ddd",
    backgroundColor: "#F5F7F9",
    textAlign: "center",
    padding: "20px 0",
    fontWeight: "bold",
    marginBottom: 20,
    userSelect: "all",
  },
});

import DateTime from "../../../../../common/displays/DateTime/DateTime";

export function listColumns(t, classes) {
  return [
    {
      field: "volumeId",
      headerName: t("business.history.OrdersCDList.columns_volume"),
      align: "left",
      disableSort: true,
      headerAlign: "left",
    },
    {
      field: "status",
      headerName: t("business.history.OrdersCDList.columns_status"),
      align: "left",
      disableSort: true,
      headerAlign: "left",
      customValue: (v) => {
        return t("common.Enums.orderStatus." + v.status);
      },
    },
    {
      field: "updatedBy",
      headerName: t("business.history.OrdersCDList.columns_updatedBy"),
      align: "left",
      disableSort: true,
      headerAlign: "left",
    },
    {
      field: "updatedTime",
      headerName: t("business.history.OrdersCDList.columns_updatedTime"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return <DateTime date={v.updatedTime} />
      },
    },
  ];
}

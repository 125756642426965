import * as Yup from "yup";
import { TextField, SelectField, NumberWithSelectField, NumberField } from "../../../common/forms";
import {
  getEntityPermissionOptions,
  getFeeTypeOptions,
  getFeeValueTypeOptions,
  getFrequencyTypeOptions,
  getOperationTypeOptions,
  getTrafficTypeOptions,
} from "../EntityForm/EntityForm.options";

let yupCep = (t) => Yup.string()
  .matches(/^[0-9]+$/, t("entities.CreateEntity.form.onlyDigits"))
  .min(7, t("entities.CreateEntity.form.atLeastDigits", { digits: 7 }))
  .max(11, t("entities.CreateEntity.form.atTopDigits", { digits: 11 }));

let yupGln = (t) => Yup.string()
  .matches(/^[0-9]+$/, t("entities.CreateEntity.form.onlyDigits"))
  .min(13, t("entities.CreateEntity.form.specificDigits", { digits: 13 }))
  .max(13, t("entities.CreateEntity.form.specificDigits", { digits: 13 }));

export const formSchema = (t, promoter, selectedType) => {
  return Yup.object().shape({
    businessUnitDesc: Yup.string().required(t("entities.CreateEntity.form.requiredBusinessUnitDesc")),
    gln: yupGln(t),
    cep: yupCep(t),
    trafficType: Yup.string().required(t("entities.CreateEntity.form.requiredTrafficType")),
    operationType: promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType) ? Yup.string().required(t("entities.CreateEntity.form.requiredOperationType")) : Yup.string(),
    cmpAccumulation:
      promoter?.getConfig("price", "cmpAccumulation") === "EC" &&
      promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
        ? Yup.string().required(t("entities.CreateEntity.form.requiredCmpAccumulation"))
        : Yup.string(),
    cpAccumulation:
      promoter?.getConfig("price", "cpAccumulation") === "EC" &&
      promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
        ? Yup.string().required(t("entities.CreateEntity.form.requiredCpAccumulation"))
        : Yup.string(),
    // priceDiscount: Yup.number()
    //   .typeError(t("entities.CreateEntity.form.number"))
    //   .default(0),
    fee: Yup.object().shape({
      frequencyType: Yup.string(),
      quantity: Yup.string(),
      type: Yup.string(),
    }),
  });
};

export const formFields = (t, promoter, selectedType) => {
  return [
    {
      size: 8,
      field: (
        <TextField
          id={`businessUnitDesc`}
          type="text"
          label={t("entities.CreateEntity.form.businessUnitDesc")}
          required
        />
      ),
    },
    {
      size: 4,
      field: <NumberField id={`cep`} label={t("entities.CreateEntity.form.cep")} maxLength={11} />,
    },
    {
      size: 4,
      field: <NumberField id={`gln`} label={t("entities.CreateEntity.form.gln")} maxLength={13} />,
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`trafficType`}
          label={t("entities.CreateEntity.form.trafficType")}
          options={getTrafficTypeOptions(t)}
          required
        />
      ),
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`operationType`}
          label={t("entities.CreateEntity.form.operationType")}
          options={getOperationTypeOptions(t)}
          required
        />
      ),
      hidden: !promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType),
    },
    {
      size: 4,
      field: (
        <NumberWithSelectField
          id="fee"
          label={t("entities.CreateEntity.form.fee")}
          textKey="quantity"
          selectKey="valueType"
          options={getFeeValueTypeOptions()}
          decimalScale={0}
        />
      ),
      hidden: !promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType),
    },
    {
      size: 4,
      field: (
        <SelectField id={`fee.type`} label={t("entities.CreateEntity.form.feeType")} options={getFeeTypeOptions(t)} />
      ),
      hidden: !promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType),
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`fee.frequencyType`}
          label={t("entities.CreateEntity.form.frequencyType")}
          options={getFrequencyTypeOptions(t)}
        />
      ),
      hidden: !promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType),
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`cmpAccumulation`}
          label={t("entities.CreateEntity.form.cmpAccumulation")}
          options={getEntityPermissionOptions(t)}
          required={
            promoter?.getConfig("price", "cmpAccumulation") === "EC" &&
            promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
          }
        />
      ),
      hidden: !(
        promoter?.getConfig("price", "cmpAccumulation") === "EC" &&
        promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
      ),
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`cpAccumulation`}
          label={t("entities.CreateEntity.form.cpAccumulation")}
          options={getEntityPermissionOptions(t)}
          required={
            promoter?.getConfig("price", "cpAccumulation") === "EC" &&
            promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
          }
        />
      ),
      hidden: !(
        promoter?.getConfig("price", "cpAccumulation") === "EC" &&
        promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
      ),
    },
  ];
};

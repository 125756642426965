export const styles = (theme) => ({
  root: {},
  headerContainer: {
    width: "100%",
    borderBottom: "1px solid #DDD",
    height: 43,
  },
  header: {
    margin: 0,
  },
  added: {
    color: theme.palette.success.main,
    backgroundColor: "#cff3f1",
  },
  removed: {
    color: theme.palette.error.main,
    backgroundColor: "#fad4d4",
    textDecoration: "line-through",
  },
  hasDiff: {
    backgroundColor: "#e8f9f8",
  },
  row: {
    borderBottom: "1px solid #e0e0e0",
  },
});

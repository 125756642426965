const weekdays = ["ANY", "MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

export const cutOffToString = (pos) => {
  return weekdays[pos];
};

export const cutOffFromString = (str) => {
  return weekdays.indexOf(str) || 0;
};

export const detailProductsToCurrentOrderProducts = (products) => {
  return products.map(({ productId, ownerId, quantity, priceType, price, seller }) => {
    return {
      productId,
      ownerId: ownerId || seller,
      quantity,
      priceType,
      price
    };
  });
};

import React from "react";
import { withStyles } from "tss-react/mui";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { Button, Grid } from "@mui/material";

import { styles } from "./CampaignList.styles";
import CampaignDataGrid from "./CampaignDataGrid/CampaignDataGrid";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import SearchBox from "../../../common/components/inputs/SearchBox";

import {
  selectCampaignList,
  listCampaignsAppend,
  selectCampaignListTotalRows,
  selectCampaignListFilter,
  disableCampaignGroup,
} from "../productsSlice.campaignsCalls";

import { selectGroupFull, listGroupsFull } from "../../entities/entitiesSlice";

import Permission from "../../../app/components/Permission";
import Page from "../../../common/displays/Page/Page";

function CampaignList({ classes }) {
  const { t } = useTranslation();
  const data = useSelector(selectCampaignList);
  const filter = useSelector(selectCampaignListFilter);
  const groupsList = useSelector(selectGroupFull);
  const totalRows = useSelector(selectCampaignListTotalRows);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useFirstLoad(() => {
    dispatch(listGroupsFull());
  });

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search,
      offset: 0,
    };
    dispatch(listCampaignsAppend(nFilter, true));
  };

  const onFilterChangeHandler = (v) => {
    dispatch(listCampaignsAppend(v.filter, v.reset));
  };

  const onEditHandler = (row) => {
    navigate("/products/campaigns/edit/" + row.id);
  };

  const onViewHandler = (row) => {
    navigate("/products/campaigns/" + row.id);
  };

  const onDeleteHandler = (row) => {
    dispatch(disableCampaignGroup(row.id));
  };

  return (
    <Page
      permission="products.actions.campaign.list"
      header={<PageTitle
        title={t("products.CampaignList.header.title")}
        info={`${totalRows} ${t("products.CampaignList.header.rowsInfo")}`}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <SearchBox onEnter={(v) => onSearchHandler(v)} />
            </Grid>
            <Permission code="products.actions.campaign.create">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => navigate("/products/campaigns/add")}
                >
                  {t("products.CampaignList.header.addBtn")}
                </Button>
              </Grid>
            </Permission>
          </Grid>
        }
      />}>
      <CampaignDataGrid
        data={data}
        filter={filter}
        totalRows={totalRows}
        onFilterChange={onFilterChangeHandler}
        onDelete={onDeleteHandler}
        onEdit={onEditHandler}
        onView={onViewHandler}
        groupsList={groupsList}
      />
    </Page>
  );
}

export default withStyles(CampaignList, styles);

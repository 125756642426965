import * as Yup from "yup";
import {
  // EntitySelect,
  EntityPicker,
} from "../../../common/components/formFields";
import { EntityAutoCompleteField, TextField } from "../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    name: Yup.string().required(t("entities.GroupCreate.form.requiredName")),
    entityId: Yup.string().required(t("entities.GroupCreate.form.requiredEntity")),
    groupEntities: Yup.array().ensure().min(1, t("entities.GroupCreate.form.requiredAtLeastOneGroupEntity")),
  });
}

export function formFields({ t, promoter, user }) {
  return [
    {
      size: 6,
      field: <TextField id="name" type="text" label={t("entities.GroupCreate.form.name")} required />,
    },
    {
      size: 6,
      field: <TextField id="code" type="text" label={t("entities.GroupCreate.form.code")} required maxLength={10} />,
    },
    {
      size: 12,
      field: (
        <EntityAutoCompleteField
          typeFilter={["P", ...promoter?.getConfig("product", "allowedEntityTypes")]}
          id="entityId"
          label={t("entities.GroupCreate.form.entityId")}
          required
        />
      ),
      hidden: user?.entity?.type !== "P"
    },
    {
      size: 12,
      field: (
        <EntityPicker
          typeFilter={promoter?.getConfig("order", "hasLogisticOperator") ? ["W"] : ["ST"]}
          id="groupEntities"
          label={t("entities.GroupCreate.form.groupEntities")}
          height={300}
        />
      ),
    },
  ];
}

// "entityId":"a6e1a698-f3b3-409b-84c1-069a737d6868",
// "groupEntities":[
//   "6f4f23f9-7cc7-42f8-97e5-c3c50b37d84b"
// ],
// "name":"teste criação group no portal"

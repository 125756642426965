import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Badge, Grid, IconButton, Tooltip} from "@mui/material";
import CommentIcon from '@mui/icons-material/Comment';

import {styles} from "./EntityComments.styles";
import {
  clearEntityDetailComments,
  entityListComments,
  selectEntityDetailComment,
  selectEntityDetailCommentsFilter,
  selectEntityDetailCommentsTotalRows
} from "../../entitiesSlice";
import EntityCommentsModal from "./EntityCommentsModal/EntityCommentsModal";

function EntityComments({ classes, entityId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filter = useSelector(selectEntityDetailCommentsFilter);
  const comments = useSelector(selectEntityDetailComment);
  const totalRows = useSelector(selectEntityDetailCommentsTotalRows);
  const hasComments = comments?.length > 0;
  const [modalOpen, setModalOpen] = useState(false);


  useEffect(() => {
    dispatch(clearEntityDetailComments())
    if (entityId) {
      console.log("entityId", entityId);
      dispatch(entityListComments({entityId: entityId, filter: filter, clear: true}));
    }
    // eslint-disable-next-line
  }, [entityId])

  const onCommentClickHandler = () => {
    setModalOpen(true);
  }

  return (
    <div>
      <Grid container className={classes.root} alignItems="center">
        <Grid item>
          {
            hasComments ?
              <Tooltip title={t("entities.EntityDetails.comments.commentsTooltip")}>
                <IconButton onClick={onCommentClickHandler}>
                  <Badge badgeContent={hasComments ? totalRows : 0} color="primary">
                    <CommentIcon color="action" style={{ display: "block", cursor: "pointer" }} />
                  </Badge>
                </IconButton>
              </Tooltip> :
              <Tooltip title={t("entities.EntityDetails.comments.noCommentsTooltip")}>
                <CommentIcon color="disabled" style={{ display: "block", cursor: "default" }} />
              </Tooltip>
          }
        </Grid>
      </Grid>
      <EntityCommentsModal open={modalOpen} setOpen={setModalOpen} comments={comments} />
    </div>
  );
}

export default withStyles(EntityComments, styles);

import {CheckboxField, CheckListField} from "../../../../common/forms";
import DocumentTypeField from "../DocumentTypeField/DocumentTypeField";

export const entityFields = ({ t, user, refData, formOptions }) => {
  return [
    {
      size: 4,
      field: (
        <CheckboxField id="functional.entity.autoAcceptCreate" label={t("promoter.PromoterConfigs.form.entityAutoCreate")} />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <CheckboxField id="functional.entity.autoAcceptUpdate" label={t("promoter.PromoterConfigs.form.entityAutoUpdate")} />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <CheckboxField id="technical.entity.wsListEntitiesByCatalog" label={t("promoter.PromoterConfigs.form.entityWsListEntitiesByCatalog")} />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
    {
      size: 4,
      field: (
        <CheckboxField id="technical.entity.requiresInternalCode" label={t("promoter.PromoterConfigs.form.entityRequiresInternalCode")} />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
    {
      size: 4,
      field: (
        <CheckboxField id="technical.entity.allowUpdateInternalCode" label={t("promoter.PromoterConfigs.form.entityAllowUpdateInternalCode")} />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: (
        <DocumentTypeField
          id="functional.entity.documentTypes"
          label={t("promoter.PromoterConfigs.form.entityDocumentType")}
          documentType={refData?.documentType}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },{
      size: 4,
      field: (
        <CheckListField
          id="functional.entity.listEntityColumns"
          label={t("promoter.PromoterConfigs.form.entityListEntityColumns")}
          options={formOptions.listEntityColumns}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
   
  ];
};

import {requestHelper, setSuccess} from "../../../app/coreSlice";
import {call} from "../../../app/websocket/_sliceCalls/websocket";

const auditRetryExternalCall = async ({ auditGetAuditExternal }, dispatch, { wsid, eventId }) => {
  let rh = requestHelper(dispatch, "RETRY_WSID");

  try {
    await call({
      type: "RETRY",
      subType: "WSID",
      request: {
        wsid
      }
    });
    dispatch(setSuccess("business", "RETRY_WSID"));
    setTimeout(() => {
      dispatch(auditGetAuditExternal(eventId));
    }, 300);
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close(); 
};

export default auditRetryExternalCall;

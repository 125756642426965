export const mixPricesWithTemp = (products, tempPrices, tempValidation) => {
  return (products || []).map(product => {
    let productTempPrices = tempPrices[product.productId];
    if (!productTempPrices) {
      return {
        ...product,
        prices: (product.prices || []).map(price => {
          return {
            ...price,
            ...(tempValidation?.[price.priceId] || {}),
            promotions: price?.process?.price?.promotions || price?.promotions
          }
        })
      }
    }

    return {
      ...product,
      prices: [
        ...(product.prices || []).map(price => {
          let tempPrice = productTempPrices.find(item => item.priceId === price.priceId);
          return {
            ...price,
            newSalePrice: tempPrice?.newSalePrice,
            newRetailPrice: tempPrice?.newRetailPrice,
            promotions: mixedPromotions(price, tempPrice),
            hasChanges: Boolean(tempPrice),
            ...(tempValidation?.[price.priceId] || {})
          }
        }),
        ...productTempPrices.filter(item => item.isNew)
      ]
    }
  })
}

export const mixExtraPricesWithTemp = (products, tempPrices) => {
  return (products || []).map(product => {
    let productTempPrices = tempPrices[product.productId];
    if (!productTempPrices) {
      return {
        ...product,
        prices: (product.prices || []).map(price => {
          return {
            ...price,
            promotions: price?.process?.price?.promotions || price?.promotions
          }
        })
      }
    }

    return {
      ...product,
      prices: [
        ...(product.prices || []).map(price => {
          let tempPrice = productTempPrices.find(item => item.priceId === price.priceId);
          return {
            ...price,
            newExtraPrice: tempPrice?.newExtraPrice,
            hasChanges: Boolean(tempPrice),
          }
        }),
        ...productTempPrices.filter(item => item.isNew)
      ]
    }
  })
}


export const extractDataForCreate = (products, tempPrices) => {
  console.log('extractDataForCreate: %o', { products, tempPrices })
  let pricesList = [];
  Object.keys(tempPrices).forEach(key => {
    const product = products.find(item => item.productId === key);
    tempPrices[key].forEach((priceObj) => {
      const { productId, owner: ownerId, contactPoint, ownerName } = product;
      if (priceObj.isNew) {
        pricesList.push({ ...priceObj, productId, ownerId, ownerName, contactPoint })
      }
      else {
        const originalPrice = (product.prices || []).find(item => item.priceId === priceObj.priceId);
        const nPromotions = mixedPromotions(originalPrice, priceObj);
        pricesList.push({ ...originalPrice, ...priceObj, productId, ownerId, ownerName, contactPoint, promotions: nPromotions })
      }
    })
  });
  return pricesList;
}

const mixedPromotions = (originalPrice, tempPrice) => {
  let nPromotions = (originalPrice?.process?.price?.promotions || originalPrice?.promotions || []);
  let processed = [];

  (tempPrice?.promotions || []).forEach(newPromotion => {
    let oldPromotion = (nPromotions).find(item => item.id === newPromotion.id);
    if (oldPromotion) {
      nPromotions = nPromotions.filter(item => item.id !== newPromotion.id);
      processed.push({ ...oldPromotion, ...newPromotion })
    }
    else {
      processed.push(newPromotion);
    }
  });

  // processed = processed.filter(item => !item.toDelete)

  return [...nPromotions, ...processed];
}
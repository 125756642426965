import React from "react";
import { withStyles } from "tss-react/mui";
import {Typography} from "@mui/material";
import {Field} from "react-final-form";
import MetaError from "../../forms/MetaError";
import {styles} from "./FileUploadField.styles";
import FileUpload from "../inputs/FileUpload";

function FileUploadField({ classes, id, label, labelInput, disabled, required }) {
  const onChangeHandler = (v, input) => {
    input.onChange && input.onChange(v);
  };

  return (
    <div className={classes.root}>
      <Typography
        component="span"
        variant="caption"
        htmlFor={id}
        color="textPrimary"
        className={disabled ? classes.disabledLabel : ""}
      >
        {label}
        {required && <span> *</span>}
      </Typography>
      <Field name={id}>
        {({ input, meta }) => (
          <div>
            <FileUpload onChange={(f) => onChangeHandler(f, input)} filename={input.value?.name} label={labelInput} />
            <MetaError className={classes.error} meta={meta} />
          </div>
        )}
      </Field>
    </div>
  );
}

export default withStyles(FileUploadField, styles)

import {createSlice} from "@reduxjs/toolkit";

import promoterEnableVatCall from "./_sliceCalls/promoterEnableVatCall";
import promoterDisableVatCall from "./_sliceCalls/promoterDisableVatCall";
import promoterCreateVatCall from "./_sliceCalls/promoterCreateVatCall";
import promoterListVatCall from "./_sliceCalls/promoterListVatCall";
import auditUpdateRetentionCall from "./_sliceCalls/auditUpdateRetentionCall";
import auditListRetentionCall from "./_sliceCalls/auditListRetentionCall";
import promoterConfigsCall from "./_sliceCalls/promoterConfigsCall";
import promoterUpdateCall from "./_sliceCalls/promoterUpdateCall";
import promoterListLocationsCall from "./_sliceCalls/promoterListLocationsCall";
import promoterCreateLocationCall from "./_sliceCalls/promoterCreateLocationCall";
import promoterUpdateLocationCall from "./_sliceCalls/promoterUpdateLocationCall";
import promoterDeleteLocationCall from "./_sliceCalls/promoterDeleteLocationCall";
import promoterUpdateVatCall from "./_sliceCalls/promoterUpdateVatCall";
import {timerListDefaultFilter, vatListDefaultFilter} from "./_common/promoterFilters";
import promoterListCustomFormsCall from "./_sliceCalls/promoterListCustomFormsCall";
import promoterGetCustomFormsCall from "./_sliceCalls/promoterGetCustomFormsCall";
import promoterListTimerCall from "./_sliceCalls/promoterListTimerCall";
import promoterGetTimerCall from "./_sliceCalls/promoterGetTimerCall";
import promoterCreateTimerCall from "./_sliceCalls/promoterCreateTimerCall";
import promoterUpdateTimerCall from "./_sliceCalls/promoterUpdateTimerCall";
import promoterChangeStatusTimerCall from "./_sliceCalls/promoterChangeStatusTimerCall";
import promoterTestFlowCall from "./_sliceCalls/promoterTestFlowCall";


export const promoterSlice = createSlice({
  name: "promoter",
  initialState: {
    configs: null,
    auditRetentionList: [],
    auditRetentionListTotalRows: 0,
    vatList: [],
    vatListFilter: vatListDefaultFilter,
    locationsList: [],
    customFormsList: [],
    customFormDetails: null,

    timerFilter: timerListDefaultFilter,
    timerList: null,
    timerListTotalRows: 0,
    timerDetails: null,

    systemStatusDetails: null,
  },
  reducers: {
    setConfigs: (state, action) => {
      state.configs = action.payload;
    },
    clearAuditRetentionList: (state) => {
      state.auditRetentionList = [];
      state.auditRetentionListTotalRows = 0;
    },
    setAuditRetentionList: (state, action) => {
      state.auditRetentionList = [...state.auditRetentionList, ...action.payload];
    },
    setAuditRetentionListTotalRows: (state, action) => {
      state.auditRetentionListTotalRows = action.payload;
    },
    setVatList: (state, action) => {
      state.vatList = action.payload;
    },
    setVatListFilter: (state, action) => {
      state.vatListFilter = action.payload;
    },
    setLocationsList: (state, action) => {
      state.locationsList = action.payload;
    },
    setCustomFormsList: (state, action) => {
      state.customFormsList = action.payload;
    },
    setCustomFormDetails: (state, action) => {
      let currentDetails = state.customFormDetails || {}
      state.customFormDetails = {
        ...currentDetails,
        [action.payload.key]: action.payload
      }
    },
    // TIMER
    clearPromoterTimerList: (state) => {
      state.timerList = [];
      state.timerListTotalRows = 0;
    },
    setPromoterTimerList: (state, action) => {
      state.timerList = [...state.timerList, ...action.payload];
    },
    setPromoterTimerListTotalRows: (state, action) => {
      state.timerListTotalRows = action.payload;
    },
    setPromoterTimerFilter: (state, action) => {
      state.timerFilter = action.payload;
    },
    setPromoterTimerDetails: (state, action) => {
      state.timerDetails = action.payload;
    },
    setSystemStatusDetails: (state, action) => {
      let systemStatusDetails = state.systemStatusDetails || {}
      state.systemStatusDetails = {
        ...systemStatusDetails,
        [action.payload.key]: action.payload.result
      }
    },
  },
});

export default promoterSlice.reducer;

export const {
  setConfigs,
  clearAuditRetentionList,
  setAuditRetentionList,
  setAuditRetentionListTotalRows,
  setVatList,
  setVatListFilter,
  setLocationsList,
  setCustomFormDetails,
  setSystemStatusDetails
} = promoterSlice.actions;

// SELECTS
export const selectLoader = (state) => state.core.loader.length > 0;
export const selectConfigs = (state) => state.promoter?.configs || {};

export const selectAuditRetentionList = (state) => state.promoter.auditRetentionList;
export const selectAuditRetentionListTotalRows = (state) => state.promoter.auditRetentionListTotalRows;
export const selectVatList = (state) => state.promoter.vatList;
export const selectVatListFilter = (state) => state.promoter.vatListFilter;
export const selectLocationsList = (state) => state.promoter.locationsList;
export const selectCustomFormsList = (state) => state.promoter.customFormsList;
export const selectCustomFormDetails = (state) => state.promoter.customFormDetails;
export const selectTimerFilter = (state) => state.promoter.timerFilter;
export const selectTimerList = (state) => state.promoter.timerList;
export const selectTimerListTotalRows = (state) => state.promoter.timerListTotalRows;
export const selectTimerDetails = (state) => state.promoter.timerDetails;
export const selectSystemStatusDetails = (state) => state.promoter.systemStatusDetails;


// CALLS
export const promoterConfigs = () => async (dispatch, getState) => {
  promoterConfigsCall(promoterSlice.actions, dispatch, getState());
};

export const promoterUpdate = (configs) => async (dispatch, getState) => {
  promoterUpdateCall({ promoterConfigs }, dispatch, getState(), { configs });
};

export const listAuditImport =
  (filter, clear = false) =>
    async (dispatch) => {
      auditListRetentionCall(promoterSlice.actions, dispatch, { clear });
    };

export const updateAuditImport = (tableName, numberDays) => async (dispatch) => {
  auditUpdateRetentionCall({ listAuditImport }, dispatch, { tableName, numberDays });
};

export const promoterListVat = (filter) => async (dispatch) => {
  promoterListVatCall(promoterSlice.actions, dispatch, filter);
};

export const promoterCreateVat =
  ({ locations, code, value }) =>
    async (dispatch, getState) => {
      promoterCreateVatCall({ promoterListVat }, dispatch, getState(), { locations, code, value });
    };

export const promoterDisableVat =
  ({ id }) =>
    async (dispatch, getState) => {
      promoterDisableVatCall({ promoterListVat }, dispatch, getState(), { id });
    };

export const promoterEnableVat =
  ({ id }) =>
    (dispatch, getState) => {
      promoterEnableVatCall({ promoterListVat }, dispatch, getState(), { id });
    };

export const promoterListLocations = () => async (dispatch) => {
  promoterListLocationsCall(promoterSlice.actions, dispatch);
};

export const promoterCreateLocation = ({ code, name, states }) => async (dispatch) => {
  promoterCreateLocationCall({ promoterListLocations }, dispatch, { code, name, states });
};

export const promoterUpdateLocation = ({ id, name, code, states }) => async (dispatch) => {
  promoterUpdateLocationCall({ promoterListLocations }, dispatch, { id, name, code, states });
};

export const promoterDeleteLocation = ({ id }) => async (dispatch) => {
  promoterDeleteLocationCall({ promoterListLocations }, dispatch, { id });
};

export const promoterUpdateVat =
  ({ id, locations, value }) =>
    async (dispatch, getState) => {
      promoterUpdateVatCall({ promoterListVat }, dispatch, getState(), { id, locations, value });
    };

export const promoterListCustomForms = () => (dispatch) => {
  promoterListCustomFormsCall(promoterSlice.actions, dispatch);
}

export const promoterGetCustomForms = (formId) => (dispatch) => {
  promoterGetCustomFormsCall(promoterSlice.actions, dispatch, { formId });
}

export const promoterListTimer = (filter, clear = false) => (dispatch, getState) => {
  promoterListTimerCall(promoterSlice.actions, dispatch, getState(), { filter, clear });
};

export const promoterGetTimer = (id) => (dispatch) => {
  promoterGetTimerCall(promoterSlice.actions, dispatch, { id });
};

export const promoterCreateTimer = (timer) => (dispatch, getState) => {
  promoterCreateTimerCall({promoterListTimer}, dispatch,getState(), {timer});
};

export const promoterUpdateTimer = (id, timer) => (dispatch, getState) => {
  promoterUpdateTimerCall({promoterListTimer}, dispatch, getState(),{ id, timer });
};

export const promoterChangeStatusTimer = (id, active) => (dispatch, getState) => {
  promoterChangeStatusTimerCall({promoterGetTimer}, dispatch, getState(),{ id, active });
};

export const promoterTestFlow = (type) => (dispatch) => {
  promoterTestFlowCall(promoterSlice.actions, dispatch, { type});
};

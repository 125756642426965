import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper } from "@mui/material";

import { styles } from "./Storage.styles";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";

function Storage({ classes, product }) {
  const { t } = useTranslation();

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h3" align="left" component="h2" className={classes.noMargin}>
            {t("products.ProductDetails.details.storageTitle")}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <LabelValue
                label={t("products.ProductDetails.details.transportTemperature")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {
                      product?.storage?.transportTemperature
                        ? t("common.Enums.temperature." + product?.storage?.transportTemperature)
                        : "N/D"
                    }
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={2}>
              <LabelValue
                label={t("products.ProductDetails.details.storageTemperature")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {
                      product?.storage?.storageTemperature
                        ? t("common.Enums.temperature." + product?.storage?.storageTemperature)
                        : "N/D"
                    }
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={2}>
              <LabelValue
                label={t("products.ProductDetails.details.lifecycle")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {product?.storage?.lifecycle}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={2}>
              <LabelValue
                label={t("products.ProductDetails.details.perishable")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {
                      product?.storage?.perishable
                        ? t("common.Enums.perishable." + product?.storage?.perishable)
                        : "N/D"
                    }
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(Storage, styles);
import React from "react";
import { withStyles } from "tss-react/mui";
import SouthEastIcon from '@mui/icons-material/SouthEast';
import NorthEastIcon from '@mui/icons-material/NorthEast';

import { styles } from "./PriceDiff.styles";
import { Chip } from "@mui/material";

function PriceDiff({ classes, oldPrice = 0, newPrice = 0 }) {

  return oldPrice !== newPrice && (
    <Chip
      size="small"
      icon={oldPrice > newPrice 
        ? <SouthEastIcon color="success" fontSize="small" style={{ display: "block" }} /> 
        : <NorthEastIcon color="error" fontSize="small" style={{ display: "block" }} />}
      label={oldPrice > 0 ? ((Math.abs(1 - (newPrice / oldPrice)) * 100).toFixed(1) + "%") : ""}
      variant="outlined"
    />
  );
}

export default withStyles(PriceDiff, styles);
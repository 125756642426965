import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Stack } from "@mui/material";
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import FontDownloadOutlinedIcon from '@mui/icons-material/FontDownloadOutlined';
import MoveDownOutlinedIcon from '@mui/icons-material/MoveDownOutlined';

import { styles } from "./InputLine.styles";
import { multiLanguagePropToString } from "../../../../../common/utils";

function InputLine({ classes, input }) {
  const { i18n } = useTranslation();

  return (
    <div className={classes.root}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {getInputIcon(input.type)}
        <Typography>{input.key || multiLanguagePropToString(input.label, i18n.language) || "N/D"}</Typography>
      </Stack>
    </div>
  );
}

export default withStyles(InputLine, styles);

const getInputIcon = (type) => {
  let icon = <FontDownloadOutlinedIcon fontSize="small" />
  switch (type) {
    case "number":
      icon = <LooksOneOutlinedIcon fontSize="small" />
      break;
    case "transfer":
      icon = <MoveDownOutlinedIcon fontSize="small" />
      break;
    default:
      break;
  }
  return icon
}
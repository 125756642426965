import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Grid, Paper, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import arrayMutators from "final-form-arrays";

import { styles } from "./BusinessUnitForm.styles";
import { formFields, formSchema } from "./BusinessUnitForm.schema";

import { Form, FormFields } from "../../../common/forms";
import Permission from "../../../app/components/Permission";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {
  businessUnitCreate,
  businessUnitGet,
  businessUnitList_Full,
  businessUnitUpdate,
  placeList_Full,
  selectBusinessUnitDetails,
  selectBusinessUnitListFull,
  selectPlacesListFull,
} from "../businessUnitsSlice";
import { entityGet, selectEntityDetails } from "../../entities/entitiesSlice";
import { useFirstLoad, useNavigate, usePromoter, useUser } from "../../../common/hooks";
import { selectLoader } from "../../products/productsSlice";
import BusinessUnitsHeaderMenu from "../_common/BusinessUnitsHeaderMenu/BusinessUnitsHeaderMenu";

function BusinessUnitForm({ classes }) {
  const { t } = useTranslation();
  const { businessUnitId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectLoader);
  const entity = useSelector(selectEntityDetails);
  const user = useUser();
  const businessUnitDetails = useSelector(selectBusinessUnitDetails);
  const businessUnits = useSelector(selectBusinessUnitListFull);
  const isEdit = Boolean(businessUnitId);
  const promoter = usePromoter();
  const places = useSelector(selectPlacesListFull);

  useFirstLoad(() => {
    if (!entity && entity?.id !== user?.entity?.id) {
      dispatch(entityGet(user?.entity?.id));
    }
    if (businessUnitId) {
      dispatch(businessUnitGet({ id: businessUnitId }));
    }
    dispatch(businessUnitList_Full());
    dispatch(placeList_Full());
  });

  const onEntityChange = (entity, form) => {
    if (entity) {
      dispatch(entityGet(entity?.entityId));
      form.change("users", undefined);
      form.change("groups", undefined);
      form.change("sectors", undefined);
    }
  }

  const onSubmitHandler = async (values) => {
    // console.log('values: %o', values);
    const { associationType, users, groups, sectors } = values;
    let nValues = {
      ...values,
      users: associationType === "USER" ? users : undefined,
      groups: associationType === "GROUP" ? groups : undefined,
      sectors: associationType === "SECTOR" ? sectors : undefined,
    };
    // console.log('nValues: %o', nValues);

    if (isEdit) {
      dispatch(businessUnitUpdate({ ...nValues, id: businessUnitId }));
    }
    else {
      dispatch(businessUnitCreate({ ...nValues }));
    }
  };

  const defaultValues = {
    entityId: user?.entity?.id,
    lines: [
      {
        inputs: [{}],
        outputs: [{}],
      }
    ]
  };

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmitHandler}
        schema={formSchema({ t })}
        initialValues={
          businessUnitId ?
            {
              ...businessUnitDetails, associationType: (
                businessUnitDetails?.groups?.length > 0 ?
                  "GROUP" : businessUnitDetails?.users?.length > 0 ?
                    "USER" : businessUnitDetails?.sectors?.length > 0 ?
                      "SECTOR" : undefined
              )
            } :
            defaultValues}
        keepDirtyOnReinitialize
        mutators={{
          ...arrayMutators,
        }}
        render={({ values, form }) => {
          return (
            <Page
              permission={"promoter.actions.businessUnit.list"}
              header={<PageTitle
                onBack={() => navigate("/business/units")}
                title={isEdit ? t("businessUnits.BusinessUnitForm.header.titleEdit") : t("businessUnits.BusinessUnitForm.header.title")}
                actions={
                  <Grid container direction="row" spacing={2}>
                    <Permission code={!isEdit ? "promoter.actions.businessUnit.create" : "promoter.actions.businessUnit.update"}>
                      <Grid item>
                        <Button variant="contained" color="primary" type="submit" disabled={loading}>
                          {t("businessUnits.BusinessUnitForm.form.btnSubmit")}
                        </Button>
                      </Grid>
                    </Permission>
                  </Grid>
                }
              />}>
              <Stack spacing={1}>
                {businessUnitId && <BusinessUnitsHeaderMenu businessUnitId={businessUnitId} selected="edit" />}
                <Paper className={classes.paper}>
                  <FormFields fields={formFields({
                    t,
                    values,
                    contacts: entity?.contacts,
                    sectorsGroups: entity?.sectorsGroups,
                    businessUnits,
                    promoter,
                    form,
                    loading,
                    onEntityChange,
                    places,
                  })} />
                </Paper>
              </Stack>
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(BusinessUnitForm, styles);

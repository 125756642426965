export const styles = (theme) => ({
  root: {},
  documentsWrapper: {
    border: "1px solid #D2D9E4",
    borderRadius: 4,
    padding: theme.spacing(2),
    position: "relative",
    "&:hover .MuiIconButton-root": {
      display: "inline-flex",
    },
  },
  file: {
    textAlign: "center",
  },
  delete: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "none",
  },
});

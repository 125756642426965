import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {withStyles} from "tss-react/mui";
import {useNavigate} from "../../../common/hooks";
import {Button, Drawer, Grid} from "@mui/material";

import {styles} from "./CutOffsList.styles";
import CutOffsDrawerDetails from "./CutOffsDrawerDetails/CutOffsDrawerDetails";

import CutOffsDataGrid from "./CutOffsDataGrid/CutOffsDataGrid";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";

import {
  disableCutOff,
  enableCutOff,
  forceCutOff,
  listCutOffsAppend,
  scheduleCutOff,
  selectCutOffList,
  selectCutOffListFilter,
  selectCutOffListTotalRows,
  setCutOffDetails,
} from "../entitiesSlice";
import Permission from "../../../app/components/Permission";
import ForceCutOffDialog from "./ForceCutOffDialog/ForceCutOffDialog";
import Page from "../../../common/displays/Page/Page";
import ScheduleCutOffDialog from "./ScheduleCutOffDialog/ScheduleCutOffDialog";
import moment from "moment";

function CutOffsList({ classes }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const totalRows = useSelector(selectCutOffListTotalRows);
  const data = useSelector(selectCutOffList);
  const filter = useSelector(selectCutOffListFilter);
  const [cutOffId, setCutOffId] = useState("");
  const [cutOffStatus, setCutOffStatus] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    dispatch(listCutOffsAppend(nFilter, true));
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };
      dispatch(listCutOffsAppend(nFilter));
    }
  };

  const onCutOffSelectedHandler = (row) => {
    setCutOffId(row.id);
    setCutOffStatus(row.status);
    dispatch(setCutOffDetails(row));
    setDrawerOpen(true);
  };

  // const onFilterChangeHandler = (v) => {
  //   dispatch(listCutOffsAppend(v.filter, true));
  // };

  const onEditHandler = (row) => {
    setCutOffId(row.id);
    dispatch(setCutOffDetails(row));
    navigate("/entities/cutoffs/edit/" + row.id);
  };

  const onForceCutOff = (row) => {
    dispatch(forceCutOff(row.entityId, row.cutOffType));
  };

  const onScheduleCutOff = (values) => {
    let momentTime = moment(values.time);

    dispatch(scheduleCutOff(values.cutOffType,
        "Scheduled CutOff",
        values.frequency === "dayOfMonth" ? values.dayOfMonth : undefined,
        values.frequency === "dayOfWeek" ? values.dayOfWeek : undefined,
        momentTime.hours(),
        momentTime.minutes(),
        1));
  };

  const onEnableHandler = (row) => {
    dispatch(enableCutOff(row?.id));
  };

  const onDisableHandler = (row) => {
    dispatch(disableCutOff(row?.id));
  };

  return (
    <Page
      permission="promoter.actions.cutoff.list"
      header={<PageTitle
        title={t("entities.CutOffs.common.title")}
        info={`${totalRows} ` + t("entities.CutOffs.common.totalRows")}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Permission code="promoter.actions.cutoff.update">
                <ForceCutOffDialog
                  variant="contained"
                  color="primary"
                  size="large"
                  canEdit={true}
                  initialValues={{
                    cutOffType: undefined,
                    entityId: undefined,
                    entity: t("entities.CutOffs.confirmForceCutOff.entity_ALL"),
                  }}
                  onConfirm={(values) => {
                    onForceCutOff && onForceCutOff(values);
                  }}
                />
              </Permission>
            </Grid>
            <Grid item>
              <Permission code="promoter.actions.cutoff.update">
                <ScheduleCutOffDialog
                  variant="contained"
                  color="primary"
                  size="large"
                  canEdit={true}
                  initialValues={{
                    cutOffType: undefined,
                    entityId: undefined,
                    entity: t("entities.CutOffs.confirmForceCutOff.entity_ALL"),
                  }}
                  onConfirm={(values) => {
                    onScheduleCutOff && onScheduleCutOff(values);
                  }}
                />
              </Permission>
            </Grid>
            <Grid item>
              <Permission code="promoter.actions.cutoff.update">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => navigate("/entities/cutoffs/add")}
                >
                  {t("entities.CutOffs.common.addBtn")}
                </Button>
              </Permission>
            </Grid>
          </Grid>
        }
      />}>
      <CutOffsDataGrid
        data={data}
        totalRows={totalRows}
        onCutOffSelected={onCutOffSelectedHandler}
        onLoadMore={onLoadMoreHandler}
        onFilter={onFilterHandler}
        onEnable={onEnableHandler}
        onDisable={onDisableHandler}
        onEdit={onEditHandler}
        onForceCutOff={onForceCutOff}
        filter={filter}
      />
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div className={classes.drawer}>
          <CutOffsDrawerDetails id={cutOffId} status={cutOffStatus} onClose={() => setDrawerOpen(false)} />
        </div>
      </Drawer>
    </Page>
  );
}

export default withStyles(CutOffsList, styles);

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const batchCreateInternalOrderCall = async (
  { batchListOrdersOpen_Full },
  dispatch,
  state,
  {
    productId,
    date,
    quantity,
    businessUnitId,
  }
) => {
  let rh = requestHelper(dispatch, "BATCH_CREATE_INTERNAL_ORDER");
  try {
    let result = await call({
      type: "BATCH",
      subType: "CREATE_INTERNAL_ORDER",
      request: {
        productId,
        date,
        quantity,
        businessUnitId,
      },
    });

    if (result) {
      dispatch(setSuccess("batches", "BATCH_CREATE_INTERNAL_ORDER"));
      setTimeout(() => {
        dispatch(batchListOrdersOpen_Full({ businessUnitId }));
      }, 300);
    }

  } catch (ex) {
    rh.error("batches", ex);
  }
  rh.close();
};

export default batchCreateInternalOrderCall;


import React from "react";
import { useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./PricesVariantsDataGrid.styles";
import { listColumns } from "./PricesVariantsDataGrid.columns";
import PricesVariantsDataGridActions from "./PricesVariantsDataGrid.actions";

import { CustomDataGrid } from "../../../../../common/displays/CustomDataGrid";
import { selectEntityGroups, selectLocations } from "../../../productsPricesSlice";
import { usePromoter, useUser } from "../../../../../common/hooks";
import { useParams } from "react-router-dom";

function PricesVariantsDataGrid({
  classes,
  data,
  product,
  onVariantPriceChange,
  validation,
  onValidate,
  onRemove,
  onAddPromotion,
  onEditPromotion,
  comparerView,
  onPriceComparerOpen,
  onVariantRetailPriceChange,
  onHistory
}) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const user = useUser();
  const entityGroups = useSelector(selectEntityGroups);
  const locations = useSelector(selectLocations);
  const columns = listColumns({ t, promoter, user, product, validation, entityGroups, locations, onVariantPriceChange, onVariantRetailPriceChange, onAddPromotion, onEditPromotion, comparerView, onPriceComparerOpen });
  const { processId } = useParams();

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={columns}
        onRowSelected={(price) => console.log(price)}
        actionsComponent={comparerView ? null : <PricesVariantsDataGridActions classes={classes} validation={validation} onValidate={onValidate} onRemove={onRemove} product={product} onHistory={onHistory} />}
        rows={(data || []).map((item) => ({
          ...item,
          className: getRowClass(classes, validation, item, user),
          arrow: showRowArrow(item, processId)
        }))}
        hasHeader={false}
        total={data?.length}
        rowKeyField="tempId"
      />
    </div>
  );
}

const getRowClass = (classes, validation, price, user) => {
  if (!validation && (price.hasChanges || price.isNew)) {
    return classes.hasChanges;
  }
  if (price.status === "END" || price.status === "REM") {
    return classes.end;
  }
  if (price.process && (price.process.status === "ACCEPTED" || (price.process.status === "CANCELLED" && price.status === "ACC"))) {
    return classes.accepted;
  }
  if (price.process && price.process.status === "PENDING") {
    // return (validation || !user?.isPromoter) ? classes.pending : classes.accepted; - not sure why this was created in the first place, pending should appear yellow in both screens because actions depend on the status
    return classes.pending;
  }
  if (price.process && price.process.status === "REFUSED") {
    return classes.rejected;
  }
  return "";
}

const showRowArrow = (price, processId) => {
  return processId && price && processId === price.process?.processId;
}

export default withStyles(PricesVariantsDataGrid, styles);

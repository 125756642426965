import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";
import moment from "moment";

const productDpromCreateCall = async (
  { clearTempDproms },
  dispatch,
  state,
  dproms
) => {
  let rh = requestHelper(dispatch, "PRODUCT_DPROM_CREATE");

  try {
    let result = await call({
      type: "PRODUCT",
      subType: "DPROM_CREATE",
      request: {
        promoDiscounts: dproms.map(({ name, type, value, ownerId, ownerName, startDate, endDate, family, products, locations }) => {
          return {
            status: undefined,
            tempId: undefined,
            name,
            type,
            value,
            ownerId,
            ownerName,
            startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : undefined,
            endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
            family: family,
            products: (products && products !== "ALL") ? products.map(({ productId, thumbnail, description, gtin, image }) => ({ productId, thumbnail, description, gtin, image })) : undefined,
            locations
          }
        })
      }
    });
    if (result) {
      dispatch(clearTempDproms());
    }
    setTimeout(() => {
      if (result) {
        dispatch(setSuccess("productsDproms", "PRODUCT_DPROM_CREATE"));
      }
    }, 300);

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productDpromCreateCall;

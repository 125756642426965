import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const orderUpdateCall = async ({ orderDetails, setCurrentOrder }, dispatch, { orderId, order }) => {
    let rh = requestHelper(dispatch, "UPDATE_ORDER");
    try {
      let result = await call({
        type: "ORDER",
        subType: "UPDATE",
        request: {
          ...order,
          products: order.products.filter((item) => item.quantity && item.quantity > 0 && !item.isOffer), // remove products without quantity
          orderId,
        },
      });
      dispatch(setCurrentOrder(null));
      // dispatch(orderDetails(orderId));
      setTimeout(() => {
        dispatch(setRedirect(`/orders/${result.orderId}`));
        dispatch(setSuccess("orders", "ORDER_UPDATE"));
      }, 300);
    } catch (ex) {
      rh.error("orders", ex);
    }
    rh.close();
  };

export default orderUpdateCall;
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Grid, IconButton, Stack } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { styles } from "./PlanningList.styles";
import PlanningListDataGrid from "./PlanningListDataGrid/PlanningListDataGrid";
import PlanningSeasonFormModal from "./PlanningSeasonFormModal/PlanningSeasonFormModal";

import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { selectLoader } from "../../../app/coreSlice";
import { planningAggregateList, planningDelete, planningList, planningUpdate, selectPlanningAggregated, selectPlanningList, selectTempPlanningList } from "../planningSlice";
import Permission from "../../../app/components/Permission";
import WeeklyPlanChart from "./WeeklyPlanChart/WeeklyPlanChart";
import { SelectInput } from "../../../common/inputs";
import ProductSnip from "../../../common/displays/ProductSnip/ProductSnip";

function PlanningList({ classes }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addSeasonModal, setAddSeasonModal] = useState(false);
  const loading = useSelector(selectLoader);
  const planning = useSelector(selectPlanningList);
  const planningOverview = useSelector(selectPlanningAggregated);
  const tempPlanning = useSelector(selectTempPlanningList);
  const { year, productId } = useParams();
  const [selectedPlanning, setSelectedPlanning] = useState();
  const defaultPlanning = (planning || []).find(item => item.defaultPlan);

  useFirstLoad(() => {
    dispatch(planningList({ year, productId }));
    if (planningOverview?.length === 0) {
      dispatch(planningAggregateList());
    }
  });

  console.log('planningOverview: %o', planningOverview);

  const onAddHandler = () => {
    setSelectedPlanning();
    setAddSeasonModal(true);
  }

  const onRemoveHandler = (row) => {
    dispatch(planningDelete(row))
  }

  const onCopyHandler = (row) => {
    setSelectedPlanning({ ...row, id: undefined, defaultPlan: false });
    setAddSeasonModal(true);
  }

  const onEditHandler = (row) => {
    setSelectedPlanning(row);
    setAddSeasonModal(true);
  }

  const onSaveHandler = () => {
    dispatch(planningUpdate(year, productId))
  }

  return (
    <Page
      permission="planning.actions.production.list"
      header={<PageTitle
        title={t("planning.PlanningList.header.title") + " " + year}
        onBack={() => navigate('/planning')}
        actions={
          <Grid container direction="row" spacing={2}>
            <Permission code="planning.actions.production.update">
              <Grid item>
                <Button variant="contained" size="large" onClick={onSaveHandler} disabled={loading || (tempPlanning || []).length === 0}>{t("planning.PlanningList.header.btnSave")}</Button>
              </Grid>
            </Permission>
          </Grid>
        }
      />}>
      {!loading && planning?.length > 0 && planningOverview?.length > 0 &&
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Grid container direction="row" alignContent="space-between" spacing={2}>
              <Grid item>
                <div style={{ minWidth: 240 }}>
                  <SelectInput
                    label="year"
                    value={year}
                    options={(planningOverview || []).filter(item => item.productId === productId).map(item => ({ value: item.year, text: item.year }))}
                    onChange={(nYear) => navigate(`/planning/${productId}/${nYear}`)} />
                </div>
              </Grid>
              <Grid item flexGrow={1}>
              </Grid>
              <Grid item sm={4}>
                <SelectInput
                  label="product"
                  value={productId}
                  options={(planningOverview || [])
                    .filter(item => item.year?.toString() === year?.toString())
                    .map(item => (
                      {
                        value: item.productId,
                        text: (<ProductSnip description={item.product?.description} image={item.product?.thumbnail} gtin={item.product?.gtin} />)
                      }
                    )
                    )}
                  onChange={(nProductId) => navigate(`/planning/${nProductId}/${year}`)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={8}>
            <Stack spacing={2}>
              <PlanningListDataGrid
                data={planning}
                tempData={tempPlanning}
                onRemove={onRemoveHandler}
                defaultPlanning={defaultPlanning}
                onCopy={onCopyHandler}
                onEdit={onEditHandler}
              />
              <div>
                <IconButton size="large" onClick={onAddHandler} disabled={loading}>
                  <AddCircleOutlineIcon fontSize="inherit" />
                </IconButton>
              </div>
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack spacing={1}>
              <WeeklyPlanChart data={planning} />
              <Permission code="planning.actions.forecast.get">
                <Button
                  variant="outlined"
                  size="small"
                  fullWidth
                  onClick={() => navigate(`/planning/forecast/${productId}/${year}`)} disabled={loading}>
                  {t("planning.PlanningList.header.btnForecast")}
                </Button>
              </Permission>
            </Stack>
          </Grid>
        </Grid>
      }
      <PlanningSeasonFormModal open={addSeasonModal} setOpen={setAddSeasonModal} data={planning} selectedPlanning={selectedPlanning} defaultPlanning={defaultPlanning} />
    </Page >
  );
}

export default withStyles(PlanningList, styles);


import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const planningUpdateCall = async (
  { planningList },
  dispatch,
  items,
  year,
  productId
) => {
  let rh = requestHelper(dispatch, "PLANNING_UPDATE");
  try {
    let result = await call({
      type: "PLANNING",
      subType: "UPDATE",
      request: {
        items
      },
    });

    if (result) {
      dispatch(planningList({ year, productId }));
      dispatch(setSuccess("planning", "PLANNING_UPDATE"));
    }

  } catch (ex) {
    rh.error("planning", ex);
  }
  rh.close();
};

export default planningUpdateCall;


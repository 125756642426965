import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./PendingPurchasesList.styles";
import { productPendingPurchaseList, selectPurchasesList, selectPurchasesListFilter, selectPurchasesListTotalRows } from "../productsPurchasesSlice";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import PurchasesDataGrid from "./PurchasesDataGrid/PurchasesDataGrid";
import CustomModal from "../../../common/displays/CustomModal/CustomModal";
import PendingPurchaseForm from "./PendingPurchaseForm/PendingPurchaseForm";

function PendingPurchasesList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(selectPurchasesList);
  const filter = useSelector(selectPurchasesListFilter);
  const totalRows = useSelector(selectPurchasesListTotalRows);
  const [modal, setModal] = useState(false);
  const [selectedPurchaseAction, setSelectedPurchaseAction] = useState({});

  const onFilterChangeHandler = (v) => {
    dispatch(productPendingPurchaseList(v.filter, v.reset));
  };

  const onAcceptHandler = (row) => {
    setSelectedPurchaseAction({ row, action: "ACCEPTED" });
    setModal(true);
  }

  const onRejectHandler = (row) => {
    setSelectedPurchaseAction({ row, action: "REFUSED" });
    setModal(true);
  }

  const onProposalHandler = (row) => {
    setSelectedPurchaseAction({ row, action: row.status === "PROPOSAL" ? "PENDING" : "PROPOSAL" });
    setModal(true);
  }

  const onExecuteHandler = (row) => {
    setSelectedPurchaseAction({ row, action: "EXECUTED" });
    setModal(true);
  }

  return (
    <Page
      permission="products.actions.purchase.list"
      header={<PageTitle
        title={t("productsPurchases.PendingPurchasesList.header.title")}
        info={`${totalRows} ${t("productsPurchases.PendingPurchasesList.header.rowsInfo")}`}
      />}>
      <PurchasesDataGrid
        data={data}
        filter={filter}
        totalRows={totalRows}
        onFilterChange={onFilterChangeHandler}
        onAccept={onAcceptHandler}
        onReject={onRejectHandler}
        onProposal={onProposalHandler}
        onExecute={onExecuteHandler}
      />
      <CustomModal open={modal} setOpen={setModal} size="small" height={440}>
        <PendingPurchaseForm
          action={selectedPurchaseAction?.action}
          purchase={selectedPurchaseAction?.row}
          onSubmit={() => {
            setModal(false);
            setSelectedPurchaseAction();
          }}
        />
      </CustomModal>
    </Page>
  );
}

export default withStyles(PendingPurchasesList, styles);
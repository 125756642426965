import { Grid, Typography, Avatar } from "@mui/material";

export function listColumns(t, classes) {
  return [
    {
      field: "firstName",
      headerName: t("entities.UsersList.columns.name"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Avatar alt={(v.firstName || "") + " " + (v.lastName || "")} src={v.logo} />
            </Grid>
            <Grid item>
              <Grid container direction="column" justifyContent="space-between">
                <Typography variant="h4" align="left" component="h4" className={classes.name}>
                  {(v.firstName || "") + " " + (v.lastName || "")}
                </Typography>
                <Typography variant="body1" align="left" component="span" className={classes.details}>
                  {t("common.Enums.roleType." + v.roleType)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "email",
      headerName: t("entities.UsersList.columns.email"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "phoneNumber",
      headerName: t("entities.UsersList.columns.phone"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "sector",
      headerName: t("entities.UsersList.columns.sector"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => (v.sector ? t("common.Enums.sector." + v.sector) : ""),
    },
  ];
}

// email: "pastel@mailinator.com"
// firstName: "test"
// id: "79aca970-1dcc-41bf-a246-35447f9b3bc9"
// lastName: "surname"
// notify: true
// phoneNumber: "123123"
// roleType: "F"
// sendDoc: false

import React from "react";
import { Route, Routes } from "react-router-dom";
import BusinessUnitList from "./BusinessUnitList/BusinessUnitList";
import Security from "../../app/components/Security/Security";
import BusinessUnitForm from "./BusinessUnitForm/BusinessUnitForm";
import BusinessUnitSteps from "./BusinessUnitSteps/BusinessUnitSteps";
import BusinessUnitStepTaskForm from "./BusinessUnitStepTaskForm/BusinessUnitStepTaskForm";
import BusinessUnitProductionForm from "./BusinessUnitProductionForm/BusinessUnitProductionForm";

function BusinessUnitsRouting() {
  return (
    <Routes>
      <Route path="/" element={<BusinessUnitList />} />
      {/* INTERNAL */}
      <Route path="/:businessUnitId/:lineId/steps/:stepId/internal/batchtask/edit/:id" element={
        <Security component={<BusinessUnitStepTaskForm type="B" internal />} paramValidation={[{ name: "businessUnitId", type: "uuid" }]} />
      } />
      <Route path="/:businessUnitId/:lineId/steps/:stepId/internal/batchtask/create" element={
        <Security component={<BusinessUnitStepTaskForm type="B" internal />} paramValidation={[{ name: "businessUnitId", type: "uuid" }]} />
      } />
      {/* STEP TASKS */}
      <Route path="/:businessUnitId/:lineId/steps/:stepId/batchtask/edit/:id" element={
        <Security component={<BusinessUnitStepTaskForm type="B" />} paramValidation={[{ name: "businessUnitId", type: "uuid" }]} />
      } />
      <Route path="/:businessUnitId/:lineId/steps/:stepId/batchtask/create" element={
        <Security component={<BusinessUnitStepTaskForm type="B" />} paramValidation={[{ name: "businessUnitId", type: "uuid" }]} />
      } />
      {/* PLACE TASKS */}
      <Route path="/:businessUnitId/:placeId/placetask/edit/:id" element={
        <Security component={<BusinessUnitStepTaskForm type="P" />} paramValidation={[{ name: "businessUnitId", type: "uuid" }]} />
      } />
      <Route path="/:businessUnitId/:placeId/placetask/create" element={
        <Security component={<BusinessUnitStepTaskForm type="P" />} paramValidation={[{ name: "businessUnitId", type: "uuid" }]} />
      } />
      
      <Route path="/:businessUnitId/:lineId/steps/internal" element={
        <Security component={<BusinessUnitSteps internal />} paramValidation={[{ name: "businessUnitId", type: "uuid" }]} />
      } />
      <Route path="/:businessUnitId/:lineId/steps" element={
        <Security component={<BusinessUnitSteps />} paramValidation={[{ name: "businessUnitId", type: "uuid" }]} />
      } />
      <Route path="/:businessUnitId/placetasks" element={
        <Security component={<BusinessUnitSteps placeTasks />} paramValidation={[{ name: "businessUnitId", type: "uuid" }]} />
      } />
      <Route path="/create" element={
        <BusinessUnitForm />
      } />
      <Route path="/:businessUnitId/edit" element={
        <Security component={<BusinessUnitForm />} paramValidation={[{ name: "businessUnitId", type: "uuid" }]} />
      } />
      <Route path="/:businessUnitId/lines" element={
        <Security component={<BusinessUnitProductionForm />} paramValidation={[{ name: "businessUnitId", type: "uuid" }]} />
      } />
    </Routes>
  );
}

export default BusinessUnitsRouting;

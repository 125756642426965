export const styles = (theme) => ({
  root: {},
  nomargin: {
    margin: 0,
  },
  table: {
    "& table": {
      backgroundColor: "#f4f6fc",
    },
  },
});

export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
  },
  details: {
    color: theme.palette.text.secondary,
    margin: 0,
  },
});

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./ProductValidationDataGrid.styles";
import { listColumns } from "./ProductValidationDataGrid.columns";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import ProductValidationDataGridActions from "./ProductValidationDataGrid.actions";
import { useParams } from "react-router-dom";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";
import { filterFields, filterSchema } from "./ProductValidationDataGrid.filter";
import { pendingProductsDefaultFilter, pendingProductsDefaultFilterSupplier } from "../../_common/productsFilters";
import {usePromoter, useUser} from "../../../../common/hooks";

function ProductValidationDataGrid({
  classes,
  data,
  totalRows,
  filter,
  onFilterChange,
  onProductSelected,
  onAccept,
  onReject,
  loading,
}) {
  const { t } = useTranslation();
  const user = useUser();
  const promoter = usePromoter();
  const columns = listColumns({ t, user });
  const { processId } = useParams();

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };

      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={columns}
        rows={data?.map((item) => ({
          ...item,
          className: processId && item.processId && item.processId === processId ? classes.hightLightRow : undefined,
        }))}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMoreHandler}
        total={totalRows}
        onRowSelected={onProductSelected}
        actionsComponent={<ProductValidationDataGridActions onAccept={onAccept} onReject={onReject} />}
        topActions={
          <FilterForm
            style={{ marginBottom: 8 }}
            storageKey="ProductValidationDataGrid"
            onStorageLoad={onFilterHandler}
            slim
            fields={filterFields(promoter)}
            schema={filterSchema()}
            onFilterChange={onFilterHandler}
            filter={filter}
            defaultFilter={user?.entity?.type === "S" ? pendingProductsDefaultFilterSupplier : pendingProductsDefaultFilter}
          />
        }
      />
    </div>
  );
}

export default withStyles(ProductValidationDataGrid, styles);

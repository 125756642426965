import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Paper } from "@mui/material";

import { styles } from "./ProductValidationForm.styles";
import { formFields, formSchema, formSchemaAux } from "./ProductValidationForm.schema";

import { Form, FormFields } from "../../../common/forms";
import { useNavigate } from "../../../common/hooks";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { useFirstLoad } from "../../../common/hooks/useFirstLoad";
import {
  productPendingProductAction,
  productPendingProductGet,
  selectLoader,
  selectPendingProductDetails,
} from "../productsSlice";

import { mergeObjects } from "../../../common/utils";
import Page from "../../../common/displays/Page/Page";
import ProductComments from "../ProductDetails/ProductComments/ProductComments";
import Permission from "../../../app/components/Permission";

function ProductValidationForm({ classes, viewMode }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, task, processId } = useParams();
  const navigate = useNavigate();
  const details = useSelector(selectPendingProductDetails);
  const loading = useSelector(selectLoader);
  const [status, setStatus] = useState();
  const [schema, setSchema] = useState(formSchema(t));

  useFirstLoad(() => {
    dispatch(productPendingProductGet({ productId: id, taskId: task, processId }));
  });

  const onSubmit = async (values) => {
    dispatch(
      productPendingProductAction({
        ...values,
        product: mergeObjects(details?.product, values?.selected?.product),
        status,
        taskId: task,
        productId: id,
      })
    );
  };

  return task === details?.taskId ? (
    <Form
      onSubmit={onSubmit}
      schema={schema}
      initialValues={{}}
      render={({ values, form }) => {
        const rejectMode = (values.selected?.acceptedFields || []).length === 0;

        const acceptMode =
          (values.selected?.acceptedFields || []).length > 0 &&
          (values.selected?.acceptedFields || []).length === values.selected?.total;

        return (
          <Page
            permission="products.actions.pendingProduct.get"
            header={<PageTitle
              title={details?.product?.baseInfo?.description}
              subtitle={details?.product?.baseInfo?.gtin}
              onBack={() => navigate("/products/validation")}
              actions={
                <Grid container direction="row" spacing={2}>
                  <Permission code="products.actions.product.listComments">
                    <Grid item>
                      <ProductComments productId={id} />
                    </Grid>
                  </Permission>
                  {rejectMode && !viewMode && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="error"
                        size="large"
                        type="submit"
                        disabled={loading || acceptMode}
                        onClick={() => {
                          setStatus("NOT_VALIDATED");
                          setSchema(formSchema(t));
                        }}
                      >
                        {t("products.ProductValidationForm.header.btnReject")}
                      </Button>
                    </Grid>
                  )}
                  {!viewMode && (<Grid item>
                    {acceptMode ? (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        disabled={loading || rejectMode}
                        onClick={() => {
                          setStatus("ACCEPTED");
                          setSchema(formSchemaAux());
                        }}
                      >
                        {t("products.ProductValidationForm.header.btnConfirm")}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="warning"
                        size="large"
                        type="submit"
                        disabled={loading || rejectMode}
                        onClick={() => {
                          setStatus("NOT_OK");
                          setSchema(formSchema(t));
                        }}
                      >
                        {t("products.ProductValidationForm.header.btnNotOk")}
                      </Button>
                    )}
                  </Grid>)
                  }
                </Grid>
              }
            />}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Paper className={classes.paper} elevation={0}>
                  <FormFields fields={formFields(t, details, values, viewMode)} />
                </Paper>
              </Grid>
            </Grid>
          </Page >
        );
      }}
    />
  ) : null;
}

export default withStyles(ProductValidationForm, styles);

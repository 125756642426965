import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { useTranslation } from "react-i18next";
import { Paper, Grid, Button, Accordion, AccordionDetails } from "@mui/material";
import { withStyles } from "tss-react/mui";
import { styles } from "./CampaignCreate.styles";
import { formFields, formSchema } from "./CampaignCreate.schema";

import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import moment from "moment";
import {
  createCampaignGroup,
  updateCampaignGroup,
  getCampaignDetails,
  selectCampaignDetails,
} from "../productsSlice.campaignsCalls";
import { selectGroupFull, listGroupsFull } from "../../entities/entitiesSlice";
import CampaignConfiguration from "./CampaignConfiguration/CampaignConfiguration";
import ConfigurationAccordionSummary from "./ConfigurationAccordionSummary/ConfigurationAccordionSummary";
import Form from "../../../common/forms/Form";
import Permission from "../../../app/components/Permission";
import Page from "../../../common/displays/Page/Page";

function CampaignCreate({ classes, viewMode }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const groupsList = useSelector(selectGroupFull);
  const details = useSelector(selectCampaignDetails);
  const { id } = useParams();
  const navigate = useNavigate();
  const submitRef = useRef();

  const [defaultValues, setDefaultValues] = useState({ startDate: moment(new Date()), endDate: moment(new Date()) });

  useFirstLoad(() => {
    dispatch(listGroupsFull());
    if (id && details?.id !== id) {
      dispatch(getCampaignDetails(id));
    }
  });

  const onSubmit = async (values) => {
    setDefaultValues(values);
    if (!id) {
      dispatch(createCampaignGroup(values));
    } else {
      dispatch(updateCampaignGroup(id, values));
    }
  };

  return (
    <div>
      {(!id || details) && (
        <Page
          permission="products.actions.campaign.get"
          header={<PageTitle
            title={id ? details.name : t("products.CampaignCreate.header.title")}
            onBack={() => navigate("/products/campaigns")}
            actions={
              <Grid container direction="row" spacing={2}>
                {!viewMode && (
                  <Grid item>
                    <Permission code="products.actions.campaign.create">
                      <Button variant="contained" color="primary" size="large" onClick={() => submitRef.current.click()}>
                        {t("products.CampaignCreate.header.btnSave")}
                      </Button>
                    </Permission>
                  </Grid>
                )}
              </Grid>
            }
          />}>
          <Form
            onSubmit={onSubmit}
            schema={formSchema(t)}
            initialValues={id ? details : defaultValues}
            render={({ values }) => {
              return (

                <Paper className={classes.paper} elevation={0}>
                  <Grid container alignItems="flex-start" spacing={2}>
                    {formFields(t, Boolean(id), viewMode, values.startDate, groupsList).map((item, idx) => (
                      <Grid item xs={item.size} key={idx}>
                        {item.field}
                      </Grid>
                    ))}
                    <Button type="submit" ref={submitRef} style={{ display: "none" }} />
                  </Grid>
                </Paper>

              );
            }}
          />
          <br />
          {id && details && (
            <Paper className={classes.paper} elevation={0}>
              {(details.campaigns || []).map((campaign, i) => (
                <Accordion key={"campaign_" + i}>
                  <ConfigurationAccordionSummary campaign={campaign} />
                  <AccordionDetails>
                    <CampaignConfiguration campaignGroupId={id} campaign={campaign} viewMode={viewMode} />
                  </AccordionDetails>
                </Accordion>
              ))}
              <br />
              <CampaignConfiguration campaignGroupId={id} viewMode={viewMode} />
            </Paper>
          )}
        </Page>
      )}
    </div>
  );
}

export default withStyles(CampaignCreate, styles);

export const styles = (theme) => ({
  root: {
    border: `1px solid #ddd`,
    borderRadius: 5
  },
  nomargin: {
    margin: 0
  },
  header: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottom: `1px solid #ddd`,
    padding: theme.spacing(1),
    backgroundColor: "#f4f6fc"
  },
  body: {
    padding: theme.spacing(0),
  }
});
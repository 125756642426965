import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../../common/displays/CustomDataGrid";

import { styles } from "./OrdersCDDataGrid.styles";
import { listColumns } from "./OrdersCDDataGrid.columns";
import { useFirstLoad } from "../../../../../common/hooks";

function OrdersCDDataGrid({ classes, data, totalRows }) {
  const { t } = useTranslation();
  const [dataProcessed, setDataProcessed] = useState([]);

  useFirstLoad(() => {
    let newData = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let details = data[i].details;
        if (details) {
          details.forEach(function (item) {
            newData.push({
              volumeId: i,
              status: item.status,
              updatedBy: item.updatedBy,
              updatedTime: item.updatedTime,
            });
          });
        }
      }
      setDataProcessed(newData);
    }
  });

  return (
    <div className={classes.root}>
      {data && <CustomDataGrid columns={listColumns(t, classes)} rows={dataProcessed} total={totalRows} />}
    </div>
  );
}

export default withStyles(OrdersCDDataGrid, styles);

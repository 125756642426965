import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./OrdersMissingProductsDataGrid.styles";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import { listColumns } from "./OrdersMissingProductsDataGrid.columns";
import OrdersMissingProductsDataGridActions from "./OrdersMissingProductsDataGrid.actions";
import ProductsDataGrid from "./ProductsDataGrid/ProductsDataGrid";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";
import { filterFields, filterSchema } from "./OrdersMissingProductsDataGrid.filter";
import { ordersMissingProductsDataGridDefaultFilter } from "../../_common/ordersFilters";
import { usePromoter, useUser } from "../../../../common/hooks";

function OrdersMissingProductsDataGrid({
  classes,
  data,
  totalRows,
  onRowSelected,
  filter,
  onFilterChange,
  onDispatch,
}) {
  const { t } = useTranslation();
  const columns = listColumns(t, classes);
  const user = useUser();
  const promoter = usePromoter();

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };
      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          columns={columns}
          rows={data}
          onFilter={onFilterHandler}
          onLoadMore={onLoadMoreHandler}
          onRowSelected={onRowSelected}
          total={totalRows}
          innerRow={(row) => <ProductsDataGrid products={row.products} />}
          actionsComponent={<OrdersMissingProductsDataGridActions onDispatch={onDispatch} />}
          topActions={<FilterForm
            style={{ marginBottom: 8 }}
            storageKey="OrdersMissingProductsDataGrid"
            onStorageLoad={onFilterHandler}
            slim
            fields={filterFields(user, promoter)}
            schema={filterSchema()}
            onFilterChange={onFilterHandler}
            filter={filter}
            defaultFilter={ordersMissingProductsDataGridDefaultFilter}
          />}
        />
      )}
    </div>
  );
}

export default withStyles(OrdersMissingProductsDataGrid, styles)

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";

const entityDeleteSectorGroupCall = async (
  { entityGet },
  dispatch,
  { groupId, entityId }
) => {
  let rh = requestHelper(dispatch, "ENTITY_DELETE_SECTOR_GROUP");
  try {
    const result = await call({
      type: "ENTITY",
      subType: "DELETE_SECTOR_GROUP",
      request: {
        groupId
      },
    });
    if (result) {
      dispatch(setSuccess("entities", "ENTITY_DELETE_SECTOR_GROUP"));
      dispatch(setRedirect(`/entities/${entityId}/sectorgroups`));
      dispatch(entityGet(entityId));
    }
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityDeleteSectorGroupCall;

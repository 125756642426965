export const isNifValid = (value) => {
  var regex = /^([A-Za-z]{1,2})?(.*)$/;
  var match = regex.exec(value);
  let country = match[2] ? match[1] : null;
  let valueToTest = match[2] ? match[2] : value;

  if ((!country || country === "PT") && valueToTest && valueToTest.length === 9) {
    let check = 0;
    for (let i = 0; i < 8; i++) {
      check += valueToTest.substr(i, 1) * (9 - i);
    }
    check = 11 - (check % 11);
    if (check >= 10) {
      check = 0;
    }
    return check.toString() === valueToTest.substr(8, 1);
  }
  if (country && country !== "PT") {
    return true;
  }
  return false;
};

export const arrayIsEmpty = (arr) => {
  return !arr || !Array.isArray(arr) || arr.length === 0;
};

export const isGtinValid = (v) => {
  let value = v;
  let zeros = "00000000000000";
  if (value) {
    value = value.toString();
    if (value.length < 14) {
      value = zeros.substring(value.length) + value;
    }
    if (value === "00000000000000") {
      return false;
    }
    if (value.length === 14) {
      let check = 0;
      for (let i = 0; i < 13; i++) {
        check += parseInt(value.substr(i, 1)) * (i % 2 === 0 ? 3 : 1);
      }
      check = 10 - parseInt(check.toString().substr(-1, 1));
      if (check === 10) {
        check = 0;
      }

      return check.toString() === value.substr(13, 1);
    }
  }
  return false;
};


export const isValidGuid = (guid) => {
  const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/i;
  return regex.test(guid);
}
export const isValidNumber = (value) => {
  const regex = /^\d+$/i;
  return regex.test(value);
}

export const isValidPhoneNumber = (phoneNumber) => {
  // ignore if is empty
  if (!phoneNumber) {
    return true;
  }
  // This regular expression allows for a variety of phone number formats, including country and area codes.
  // It accommodates different international phone number standards and allows for some flexibility in formatting.
  const phoneNumberRegex = /^(\+?\d{1,4}[-\s.]?)?(\(?\d{1,4}\)?[-\s.]?)?[\d-\s.]{1,12}(\s?(x|ext)\s?\d{1,6})?$/;
  return phoneNumberRegex.test(phoneNumber);
}

export const isValidEmail = (email) => {
  if (!email) {
    return true;
  }
  const emailRegex = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;
  return emailRegex.test(email);
}

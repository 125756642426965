import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "./AccountingList.styles";

import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import AccountingDataGrid from "./AccountingDataGrid/AccountingDataGrid";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import Page from "../../../common/displays/Page/Page";
import { accountingSetAvailable, businessListAccountingDocs, selectAccountingFilter, selectAccountingList, selectAccountingListTotalRows } from "../businessSlice";
import { selectLoader } from "../../../app/coreSlice";
import Permission from "../../../app/components/Permission";

function AccountingList({ classes }) {
  const { t } = useTranslation();
  const loading = useSelector(selectLoader);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterAccounting = useSelector(selectAccountingFilter);
  const totalRows = useSelector(selectAccountingListTotalRows);
  const data = useSelector(selectAccountingList);
  const [checkedItems, setCheckedItems] = useState({});

  useFirstLoad(() => {
    dispatch(businessListAccountingDocs(filterAccounting, true));
  });

  const onFilterChangeHandler = (v) => {
    dispatch(selectAccountingFilter(v.filter));
    businessListAccountingDocs(v.filter, v.reset);
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0) {
      let nFilter = {
        ...filterAccounting,
        offset: data.length,
      };

      onFilterChangeHandler({ filter: nFilter, reset: false });
    }
  };

  const onRowSelectedHandler = (row) => {
    navigate('/activity/accounting/' + row.id)
  };

  const refreshHandler = () => {
    dispatch(businessListAccountingDocs(filterAccounting, true));
  };

  const submitHandler = () => {
    dispatch(accountingSetAvailable(checkedItems));
  }

  return (
    <Page
      permission="business.actions.accounting.list"
      header={<PageTitle
        title={t("business.AccountingList.header.title")}
        info={`${totalRows} ${t("business.AccountingList.header.rowsInfo")}`}
        actions={
          <Grid container direction="row" spacing={2}>
            <Permission code="business.actions.accounting.setAvailable">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={submitHandler}
                  disabled={loading}
                >
                  {t("business.AccountingList.header.btnSave")}
                </Button>
              </Grid>
            </Permission>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={refreshHandler}
                disabled={loading}
              >
                <RefreshIcon />
              </Button>
            </Grid>
          </Grid>
        }
      />}>

      <AccountingDataGrid
        onLoadMore={onLoadMoreHandler}
        data={data}
        totalRows={totalRows}
        filter={filterAccounting}
        onFilterChange={onFilterChangeHandler}
        onRowSelected={onRowSelectedHandler}
        checkedItems={checkedItems}
        setCheckedItems={setCheckedItems}
      />

    </Page>
  );
}

export default withStyles(AccountingList, styles);

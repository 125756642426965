import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const productGroupContactsCall = async ({ setGroupContacts }, dispatch) => {
  let rh = requestHelper(dispatch, "PRODUCT_GROUP_CONTACTS");
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "GROUP_CONTACTS",
    });
    dispatch(setGroupContacts(result?.groups || []));
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productGroupContactsCall;

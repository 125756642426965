import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const entityList_autocompleteCall = async ({ setAutoCompleteEntitiesList }, dispatch) => {
  let rh = requestHelper(dispatch, "ENTITY_LIST");
  try {
    let list = await call({
      type: "ENTITY",
      subType: "LIST",
      request: {
        limit: 10000,
        sortField: "NAME",
        sortType: "ASC",
        status: "ACT"
      },
    });
    dispatch(setAutoCompleteEntitiesList(list.entities));
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityList_autocompleteCall;

export const styles = theme => ({
  root: {
    width: 270
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  btn: {
    height: 50,
  },
  link: {
    ...theme.typography.body1,
    textAlign: "center",
    display: "block !important",
    textDecoration: "none !important",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2)
  },
});
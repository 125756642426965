import React from "react";
import * as Yup from "yup";
import moment from "moment";
import { DateTimePickerField, EntityAutoCompleteField, SelectField } from "../../../../common/forms";
import { getAuditLoginWebSocketOptions } from "../../BusinessForm.options";
import TextField from "../../../../common/forms/fields/TextField";

export const filterFields = () => (t) => {
  return [
    {
      size: 12,
      field: (
        <DateTimePickerField
          id="dateIni"
          label={t("business.audit.filter.requestStartDate")}
          maxDate={moment().toISOString()}
        />
      )
    },
    {
      size: 12,
      field: (
        <DateTimePickerField
          id="dateEnd"
          label={t("business.audit.filter.requestEndDate")}
          maxDate={moment().toISOString()}
        />
      )
    },
    {
      size: 12,
      field: (
        <TextField
          id="ipAddress"
          label={t("business.audit.filter.ipAddress")}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextField
          id="username"
          label={t("business.audit.filter.username")}
        />
      ),
    },
    {
      size: 12,
      field: (
        <SelectField
          id="action"
          label={t("business.audit.filter.action")}
          options={getAuditLoginWebSocketOptions(t)}
        />
      ),
    },
    {
      size: 12,
      field: (
        <EntityAutoCompleteField id="entity" label={t("business.audit.filter.entity")} typeFilter={["S", "ST", "W", "LO", "P"]} />
      ),
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    dateIni: Yup.string(),
    dateEnd: Yup.string(),
  });
};

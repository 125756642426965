
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Stack, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { styles } from "./RolesList.styles";
import DefaultRolesDataGrid from "./DefaultRolesDataGrid/DefaultRolesDataGrid";

import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import Permission from "../../../app/components/Permission";
import {
  selectRolesList,
  selectRolesListFilter,
  selectRolesListTotalRows,
  rolesList,
  rolesListDefault,
  selectDefaultRolesList,
  rolesDelete,
} from "../rolesSlice";
import { selectLoader } from "../../../app/coreSlice";
import { useFirstLoad, useNavigate, usePromoter } from "../../../common/hooks";
import { EntityAutoComplete } from "../../../common/inputs/custom";
import RolesDataGrid from "./RolesDataGrid/RolesDataGrid";
import ConfirmRemoveModal from "./ConfirmRemoveModal/ConfirmRemoveModal";
import { useParams } from "react-router-dom";

function RolesList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { entityId } = useParams();
  const data = useSelector(selectRolesList);
  const totalRows = useSelector(selectRolesListTotalRows);
  const filter = useSelector(selectRolesListFilter);
  const defaultRoles = useSelector(selectDefaultRolesList);
  const loading = useSelector(selectLoader);
  const [selectedEntity, setSelectedEntity] = useState();
  const [confirmRemoveModal, setConfirmRemoveModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const promoter = usePromoter();

  useFirstLoad(() => {
    dispatch(rolesListDefault());
  });

  useEffect(() => {
    if (entityId) {
      setSelectedEntity({ entityId });
      dispatch(rolesList({ ...filter, entityId }, true));
    }
    // eslint-disable-next-line
  }, [entityId])

  const onEditHandler = (row) => {
    navigate("/roles/edit/" + row.id);
  };

  const onAddHandler = () => {
    if (selectedEntity?.entityId) {
      navigate("/roles/create/" + selectedEntity?.entityId);
    }
    else {
      navigate("/roles/create");
    }
  }

  const onEntityChangeHandler = (entity) => {
    setSelectedEntity(entity);
    if (entity?.entityId) {
      dispatch(rolesList({ ...filter, entityId: entity?.entityId }, true));
    }
  }

  const onFilterChangeHandler = ({ filter, reset }) => {
    dispatch(rolesList({ ...filter, entityId: selectedEntity?.entityId }, reset));
  }

  const onRemoveHandler = (row) => {
    setConfirmRemoveModal(true);
    setSelectedRole(row)
  }

  const onRemoveConfirmHandler = ({ roleID }) => {
    dispatch(rolesDelete({ roleID }));
    setConfirmRemoveModal(false);
    setSelectedRole();
  }

  return (
    <Page
      permission={"promoter.actions.role.list"}
      header={<PageTitle
        title={t("roles.RolesList.header.title")}
        actions={
          <Grid container direction="row" spacing={2}>
            {(promoter?.getConfig("general", "roleTypes") || []).includes("C") &&
              <Permission code={"promoter.actions.role.create"}>
                <Grid item>
                  <Button variant="outlined" size="large" disabled={loading} onClick={onAddHandler} id="add"><AddIcon fontSize="inherit" /></Button>
                </Grid>
              </Permission>
            }
          </Grid>
        }
      />}>
      <Stack spacing={3} className={classes.root}>
        <DefaultRolesDataGrid
          data={defaultRoles}
          totalRows={defaultRoles?.length || 0}
          onEdit={onEditHandler}
          filter={filter}
        />
        <EntityAutoComplete
          label={t("roles.RolesList.filter.entity")}
          typeFilter={["P", "S", "W", "LO", "ST"]}
          value={selectedEntity?.entityId}
          onChange={onEntityChangeHandler}
        />
        {
          !selectedEntity?.entityId ?
            <Typography className={classes.warn}>{t("roles.RolesList.warn.noEntity")}</Typography> :
            data?.length > 0 ?
              <RolesDataGrid
                data={data}
                totalRows={totalRows}
                filter={filter}
                onFilterChange={onFilterChangeHandler}
                onRemove={onRemoveHandler}
                onEdit={onEditHandler}
              /> :
              <Typography className={classes.warn}>{t("roles.RolesList.warn.noCustomRoles")}</Typography>
        }
      </Stack>
      <ConfirmRemoveModal open={confirmRemoveModal} setOpen={setConfirmRemoveModal} onRemoveConfirm={onRemoveConfirmHandler} selectedRole={selectedRole} />
    </Page>
  );
}

export default withStyles(RolesList, styles);
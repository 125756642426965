import * as Yup from "yup";
import { NumberField, SelectField } from "../../../../common/forms";
import { getVariableOptions } from "./SetConditionFormModal.options";

export function formSchema({ t }) {
  return Yup.object().shape({
    variable: Yup.string().required(t("businessUnits.SetConditionFormModal.form.variableRequired")),
  });
}

export function formFields({ t, values }) {
  return [
    {
      size: 12,
      field: <SelectField
        id="variable"
        required
        options={getVariableOptions(t)}
        label={t("businessUnits.SetConditionFormModal.form.variable")}
      />,
    },
    {
      size: 12,
      field: <NumberField
        id="payload.start_date_diff"
        allowNegative
        decimalScale={0}
        label={t("businessUnits.SetConditionFormModal.form.start_date_diff")}
      />,
      hidden: values?.variable !== "batch_start_date"
    },
    {
      size: 12,
      field: <NumberField
        id="payload.min_size"
        allowNegative
        decimalScale={0}
        label={t("businessUnits.SetConditionFormModal.form.min_size")}
      />,
      hidden: values?.variable !== "batch_quantity_distribution"
    },
  ];
}


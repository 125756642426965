import {currencyFormat} from "../../../../../common/utils";

export function listColumns(t, classes) {
  return [
    {
      field: "sellerName",
      headerName: t("business.reports.PlatformCostItemsList.columns_sellerName"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "deliveryPlace",
      headerName: t("business.reports.PlatformCostItemsList.columns_deliveryPlace"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "platformCost",
      headerName: t("business.reports.PlatformCostItemsList.columns_platformCost"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return currencyFormat(v.platformCost);
      },
    },
    {
      field: "administrativeCost",
      headerName: t("business.reports.PlatformCostItemsList.columns_administrativeCost"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return currencyFormat(v.administrativeCost);
      },
    },
    {
      field: "totalTransportUnits",
      headerName: t("business.reports.PlatformCostItemsList.columns_totalTransportUnits"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "averageCost",
      headerName: t("business.reports.PlatformCostItemsList.columns_averageCost"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return currencyFormat(v.averageCost);
      },
    },

  ];
}

import {dateTimeFormat} from "../../../../common/utils";

export function listColumns(t, classes) {
  return [

    {
      field: "description",
      headerName: t("promoter.timer.timerList.columns_description"),
      align: "left",
      headerAlign: "left",
    }, {
      field: "dayOfMonth",
      headerName: t("promoter.timer.timerList.columns_dayOfMonth"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.dayOfMonth === 0 ? "-" : v.dayOfMonth;
      },
    }, {
      field: "dayOfWeek",
      headerName: t("promoter.timer.timerList.columns_dayOfWeek"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.dayOfWeek === 0 ? "-" : t("common.Enums.weekday."+v.dayOfWeek);
      },
    } , {
      field: "time",
      headerName: t("promoter.timer.timerList.columns_time"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return dateTimeFormat(v.time, "HH:mm");
      },
    }, {
      field: "status",
      headerName: t("promoter.timer.timerList.columns_active"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return t("promoter.timer.timerList.columns_active_"+v.active);
      },
    }
  ];
}
/*
{
  "id": "0bacc250-02c7-4446-be67-d5e95a422636",
    "description": "Cut-off - WEDNESDAY - Viteno - Comércio de Bebidas Lda",
    "dayOfMonth": 0,
    "dayOfWeek": 3,
    "hour": 10,
    "minute": 10,
    "active": true
}*/
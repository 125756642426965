import * as Yup from "yup";
import { MultiSelectField } from "../../../../common/forms";

export const filterFields = (promoter, user) => (t) => {
  return [
    {
      size: 12,
      field: (
        <MultiSelectField
          id="types"
          label={t("entities.Entities.filter.entityType")}
          options={getEntityTypeAllowedOptions(t, promoter, user)}
        />
      ),
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    types: Yup.array(),
  });
};

const getEntityTypeAllowedOptions = (t, promoter, user) => {
  let userAllowed = user?.permissionFilter("entities.filters.entity.list.type") || [];
  let promoterAllowed = promoter?.getConfig("general", "entityTypes") || [];

  return [...promoterAllowed.filter((value) => userAllowed.includes(value))].map((item) => {
    return {
      value: item,
      text: t("common.Enums.entityType." + item),
    };
  });
};

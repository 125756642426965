import React from "react";
import { withStyles } from "tss-react/mui";
import { Field } from "react-final-form";
import MetaError from "../MetaError";
import { styles } from "./_base.styles";
import { NumberInput } from "../../inputs";

function NumberField({
  classes,
  id,
  label,
  disabled,
  required,
  decimalScale,
  allowNegative,
  endAdornment,
  onChange,
  maxLength,
  startAdornment,
  textAlign,
  allowLeadingZeros,
  note,
}) {
  const onChangeHandler = (v, input) => {
    input.onChange && input.onChange(v);
    onChange && onChange(v);
  };

  return (
    <div className={classes.root}>
      <Field
        name={id}
      >
        {({ input, meta }) => {
          return (
            <>
              <NumberInput
                className={classes.input}
                id={id}
                label={label}
                value={input.value}
                onChange={(value) => onChangeHandler(value, input)}
                disabled={disabled}
                required={required}
                error={Boolean(meta.error && meta.touched)}
                variant={"outlined"}
                decimalScale={decimalScale}
                allowNegative={allowNegative}
                endAdornment={endAdornment}
                startAdornment={startAdornment}
                textAlign={textAlign}
                allowLeadingZeros={allowLeadingZeros}
                maxLength={maxLength}
                note={note}
              />
              <div className={classes.error}>
                <MetaError meta={meta} />
              </div>
            </>
          )
        }}
      </Field>
    </div>
  );
}

export default withStyles(NumberField, styles);

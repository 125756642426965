import * as Yup from "yup";
import { CheckboxField, EntityAutoCompleteField, MultiSelectField, NumberField, SelectField, TextField } from "../../../common/forms";
import React from "react";
import { getContactOptions, getGroupOptions, getSectorOptions } from "../../tasks/TasksForm/TasksForm.options";
import { getPlacesOptions } from "./BusinessUnitForm.options";
import { Divider } from "@mui/material";

export function formSchema({ t }) {
  return Yup.object().shape({
    name: Yup.string().required(t("businessUnits.BusinessUnitForm.form.nameRequired")),
    users: Yup.array().when("associationType",
      {
        is: "USER",
        then: schema => schema.ensure().min(1, t("businessUnits.BusinessUnitForm.form.usersRequired")),
        otherwise: schema => schema,
      }),
    groups: Yup.array().when("associationType",
      {
        is: "GROUP",
        then: schema => schema.ensure().min(1, t("businessUnits.BusinessUnitForm.form.groupsRequired")),
        otherwise: schema => schema,
      }),
    sectors: Yup.array().when("associationType",
      {
        is: "SECTOR",
        then: schema => schema.ensure().min(1, t("businessUnits.BusinessUnitForm.form.sectorsRequired")),
        otherwise: schema => schema,
      }),
  });
}

export function formFields({ t, values, contacts, sectorsGroups, businessUnits, promoter, form, loading, onEntityChange, places }) {
  const associationTypeOptions = ["GROUP", "USER", "SECTOR"].map(item => ({
    value: item,
    text: t("roles.enum.associationType." + item),
  }))

  return [
    {
      size: 12,
      field: <TextField
        id="name"
        required
        label={t("businessUnits.BusinessUnitForm.form.name")}
      />,
    }, {
      size: 12,
      field: (
        <EntityAutoCompleteField
          typeFilter={promoter?.getConfig("product", "allowedEntityTypes")}
          id="entityId"
          label={t("businessUnits.BusinessUnitForm.form.entityId")}
          required
          disabled={loading}
          onChange={(entity) => onEntityChange(entity, form)}
        />
      ),
    },
    {
      size: 6,
      field: <SelectField
        id="associationType"
        options={associationTypeOptions}
        label={t("businessUnits.BusinessUnitForm.form.associationType")}
      />,
    },
    {
      size: 6,
      field: (
        <MultiSelectField
          id="users"
          options={getContactOptions(contacts)}
          label={t("businessUnits.BusinessUnitForm.form.users")}
          required
        />
      ),
      hidden: values?.associationType !== "USER"
    },
    {
      size: 6,
      field: (
        <MultiSelectField
          id="groups"
          options={getGroupOptions(sectorsGroups)}
          label={t("businessUnits.BusinessUnitForm.form.groups")}
          required
        />
      ),
      hidden: values?.associationType !== "GROUP"
    },
    {
      size: 6,
      field: (
        <MultiSelectField
          id="sectors"
          options={getSectorOptions(t, promoter?.refData)}
          label={t("businessUnits.BusinessUnitForm.form.sectors")}
          required
        />
      ),
      hidden: values?.associationType !== "SECTOR"
    },
    {
      size: 12,
    },
    {
      size: 6,
      field: <MultiSelectField
        id="places"
        label={t("businessUnits.BusinessUnitForm.form.places")}
        options={getPlacesOptions(places, values?.places)}
      />
    },
    {
      size: 12,
      field: <Divider />
    },
    {
      size: 4,
      field: <CheckboxField
        id="config.allowInternalBatches"
        label={t("businessUnits.BusinessUnitForm.form.allowInternalBatches")}
      />
    },
    {
      size: 4,
      field: <NumberField
        decimalScale={0}
        id="config.taskInitializationOffset"
        label={t("businessUnits.BusinessUnitForm.form.taskInitializationOffset")}
      />
    },
  ];
}


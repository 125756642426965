import React from "react";
import { Typography } from "@mui/material";

const parseError = (error) => {
  let errorMessages = [];
  if (Array.isArray(error)) {
    error.forEach((item) => {
      errorMessages = errorMessages.concat(parseError(item));
    });
  } else if (typeof error === "object") {
    Object.keys(error).forEach((k) => {
      errorMessages = errorMessages.concat(parseError(error[k]));
    });
  } else {
    errorMessages.push(error);
  }
  return errorMessages;
};

function MetaError({ className, meta, code, autoValidate, forceAutoValidate }) {
  if (meta.error && (meta.touched || (autoValidate && (meta.modified || forceAutoValidate)))) {
    let parsedError = parseError(code ? meta.error[code] : meta.error);
    // remove repeats
    parsedError = parsedError.filter((item, index) => {
      return parsedError.indexOf(item) === index;
    });
    return (
      <div className={className}>
        <Typography variant="caption" color="error" style={{ fontWeight: 300 }}>
          {parsedError.join(", ")}
        </Typography>
      </div>
    );
  }
  return null;
}

export default MetaError;

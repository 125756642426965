import React, { useState, useEffect } from "react";
import { withStyles } from "tss-react/mui";
import Tree from "react-d3-tree";
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { styles } from "./HierarchyTree.styles";
import {
  getSpacialLength,
  insertAddButtonOnLastChild,
  processHierarchy,
  useCenteredTree,
} from "./HierarchyTree.auxiliars";
import HierarchyNode from "./HierarchyNode/HierarchyNode";

function HierarchyTree({ classes, hierarchy, onSelect, productThumbnail, onAddNode, error }) {
  const { t } = useTranslation();
  const [translate, containerRef] = useCenteredTree();
  const [selectedNode, setSelectedNode] = useState();
  const [orgData, setOrgData] = useState([]);

  useEffect(() => {
    if (hierarchy) {
      let oData = processHierarchy(hierarchy);
      if (oData?.length > 0 && onAddNode) {
        oData = insertAddButtonOnLastChild(oData, { child: { childGtin: oData[0].gtin }, level: 1 });
      }
      setOrgData(oData);
    }
    // eslint-disable-next-line
  }, [hierarchy]);

  const height = getSpacialLength(hierarchy) * 100 + 80;

  const onNodeClickHandler = (nodeDatum) => {
    let nSelectedNode = selectedNode?.gtin === nodeDatum?.gtin ? null : nodeDatum;
    setSelectedNode(nSelectedNode);
    onSelect && onSelect(nSelectedNode);
  };

  return (
    <div className={classes.root} ref={containerRef} style={{ height: height }}>
      {orgData?.length > 0 ? (
        <Tree
          translate={translate}
          data={orgData}
          orientation="vertical"
          zoomable={false}
          collapsible={false}
          draggable={Boolean(onAddNode)}
          pathFunc="straight"
          nodeSize={{ x: 210, y: 100 }}
          renderCustomNodeElement={({ nodeDatum }) => (
            <HierarchyNode
              node={nodeDatum}
              hasErrors={nodeDatum.hasErrors}
              productThumbnail={productThumbnail}
              onNodeClick={onNodeClickHandler}
              onAddNodeClick={onAddNode}
              selected={selectedNode?.gtin && selectedNode?.gtin === nodeDatum?.gtin}
            />
          )}
        />
      ) : (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ height: "100%" }}
          spacing={1}
          direction="column"
        >
          <Grid item>
            <Typography className={classes.empty}>{t("products.HierarchyTree.tree.emptyHierarchy")}</Typography>
          </Grid>
          {onAddNode && (
            <Grid item>
              <Button variant="text" onClick={() => onAddNode(null)} size="small">
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <AddCircleOutlineIcon fontSize="small" className={classes.empty} style={{ display: "block" }} />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.empty}>{t("products.HierarchyTree.tree.addNode")}</Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          )}
          {error && <Grid item>
            <Typography color="error">{error}</Typography>
          </Grid>}
        </Grid>
      )}
    </div>
  );
}

export default withStyles(HierarchyTree, styles);

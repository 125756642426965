import DateTime from "../../../../../common/displays/DateTime/DateTime";
import ProductSnip from "../../../../../common/displays/ProductSnip/ProductSnip";

export function listColumns(t, classes) {
  return [
    {
      field: "description",
      headerName: t("business.history.OrdersTFList.columns_description"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return <ProductSnip image={v.thumbnail} description={v.description} gtin={v.gtin} />
      },
    },
    {
      field: "quantity",
      headerName: t("business.history.OrdersTFList.columns_quantity"),
      align: "left",
      disableSort: true,
      headerAlign: "left",
    },
    {
      field: "totalTransportUnits",
      headerName: t("business.history.OrdersTFList.columns_totalTransportUnits"),
      align: "left",
      disableSort: true,
      headerAlign: "left",
    },
    {
      field: "status",
      headerName: t("business.history.OrdersTFList.columns_status"),
      align: "left",
      disableSort: true,
      headerAlign: "left",
      customValue: (v) => {
        return t("common.Enums.orderStatus." + v.status);
      },
    },
    {
      field: "updatedBy",
      headerName: t("business.history.OrdersTFList.columns_updatedBy"),
      align: "left",
      disableSort: true,
      headerAlign: "left",
    },
    {
      field: "updatedTime",
      headerName: t("business.history.OrdersTFList.columns_updatedTime"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return <DateTime date={v.updatedTime} />;
      },
    },
  ];
}

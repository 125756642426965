import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./AggregatedOrderProductsDataGrid.styles";
import { listColumns } from "./AggregatedOrderProductsDataGrid.columns";
import { Grid } from "@mui/material";
import { currencyFormat } from "../../../../common/utils";
import { QuantityInput } from "../../../../common/inputs/custom";

function AggregatedOrderProductsDataGrid({
  classes,
  data,
  totalRows,
  onRowSelected,
  footer,
  buyerId,
  innerRow,
  onQuantitiesChange,
  onOtherBuyersQuantitiesChange,
  order,
  dispatchScreen,
  acceptScreen,
}) {
  const { t } = useTranslation();
  const [customQuantities, setCustomQuantities] = useState([]);
  const [otherBuyersQuantities, setOtherBuyersQuantities] = useState([]);

  const setProductQuantity = (product, quantity) => {
    let p = customQuantities.find((item) => item.key === product.key);
    let nCustomQuantities;
    if (p) {
      p.quantity = quantity;
      let other = customQuantities.filter((item) => item.key !== product.key);
      nCustomQuantities = [...other, p];
    } else {
      nCustomQuantities = [...customQuantities, { key: product.key, quantity }];
    }
    setCustomQuantities(nCustomQuantities);
    onQuantitiesChange && onQuantitiesChange(nCustomQuantities);
  };

  const onOtherBuyersQuantitiesChangeHandler = (row, item, value) => {
    let modifyProductInput = {
      orderSellerId: item.orderSellerId,
      productId: row.productId,
      quantity: value,
      priceType: item.priceType,
      key: row.productId + item.orderSellerId + item.priceType,
    };
    let nOtherBuyersQuantities = [
      ...otherBuyersQuantities.filter((item) => item.key !== modifyProductInput.key),
      modifyProductInput,
    ];
    setOtherBuyersQuantities(nOtherBuyersQuantities);
    onOtherBuyersQuantitiesChange && onOtherBuyersQuantitiesChange(nOtherBuyersQuantities);
  };

  const innerRowAccept = (row) => {
    let otherBuyers = (row?.aggregateInfo?.buyersInfo || []).filter((item) => item.buyerId !== buyerId && item.status === "CO");

    let buyers =
      otherBuyers &&
      otherBuyers.map((item) => {
        return (
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
            key={row.productId + "_" + item.buyerId}
          >
            <Grid item> {item.buyer}</Grid>
            <Grid item style={{ width: 160 }}>
              <QuantityInput
                slim
                style={{ width: 120 }}
                multi={1}
                value={item.quantity}
                onChange={(value) => onOtherBuyersQuantitiesChangeHandler(row, item, value)}
              />
            </Grid>
            <Grid
              item
              style={{
                width: 130,
                textAlign: "right",
                paddingRight: 16,
              }}
            >
              {currencyFormat(item.price)}
            </Grid>
            <Grid
              item
              style={{
                width: 240,
                textAlign: "right",
                paddingRight: 16,
              }}
            >
              {currencyFormat(item.totalPrice)}
            </Grid>
            <Grid item style={{ width: 20 }}>
              &nbsp;
            </Grid>
          </Grid>
        );
      });
    return order?.status === "CO" && otherBuyers.length > 0 ? (
      <>
        {buyers}

        <Grid container spacing={2} justifyContent="flex-end" alignItems="center" key={row.productId + "_total"}>
          <Grid item>
            <b>{t("orders.OrdersCreate.columns.total")}:</b>
          </Grid>
          <Grid item style={{ width: 160, textAlign: "center" }}>
            <b>{row?.aggregateInfo?.totalQuantity} UN</b>
          </Grid>
          <Grid
            item
            style={{
              width: 130,
              textAlign: "right",
              paddingRight: 16,
            }}
          ></Grid>
          <Grid
            item
            style={{
              width: 240,
              textAlign: "right",
              paddingRight: 16,
            }}
          >
            <b>{currencyFormat(row?.aggregateInfo?.totalPrice)}</b>
          </Grid>
          <Grid item style={{ width: 20 }}>
            &nbsp;
          </Grid>
        </Grid>
      </>
    ) : null;
  };

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          localFilters
          columns={listColumns(t, classes, customQuantities, setProductQuantity, order, dispatchScreen, acceptScreen)}
          rows={data}
          onRowSelected={onRowSelected}
          total={totalRows}
          footer={footer}
          rowKeyField="key"
          innerRow={innerRow ? innerRow : innerRowAccept}
        />
      )}
    </div>
  );
}

export default withStyles(AggregatedOrderProductsDataGrid, styles)

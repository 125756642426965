import { dateTimeFormatWithMilliSeconds } from "../../../../common/utils/formats";

export function listColumns(t, classes) {
  return [
    {
      field: "type",
      headerName: t("business.AuditsList.columns.type"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (v.type + "/" + v.subtype);
      },
    },
    {
      field: "jobDescription",
      headerName: t("business.AuditsList.columns.jobDescription"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "startTime",
      headerName: t("business.AuditsList.columns.startTime"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.startTime));
      },
    },
    {
      field: "endTime",
      headerName: t("business.AuditsList.columns.endTime"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.endTime));
      },
    },
    {
      field: "status",
      headerName: t("business.AuditsList.columns.status"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (v.status ? t("business.AuditsList.columns.errorFalse") : t("business.AuditsList.columns.errorTrue"));
      },
    },
  ];
}

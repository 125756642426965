import { createSlice } from "@reduxjs/toolkit";
import userChangePasswordCall from "./_sliceCalls/userChangePasswordCall";
import userChangePromoterCall from "./_sliceCalls/userChangePromoterCall";
import userUpdateCall from "./_sliceCalls/userUpdateCall";
import userSetPreferencesCall from "./_sliceCalls/userSetPreferencesCall";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
  },
  reducers: {
  },
});

export default usersSlice.reducer;


// SELECTS

// CALLS
export const userChangePassword = ({ password, newPassword }) => (dispatch) => {
  userChangePasswordCall(dispatch, { password, newPassword });
};

export const userChangePromoter = ({ promoterCode, pathToRedirect }) => (dispatch, getState) => {
  userChangePromoterCall(dispatch, getState(), { promoterCode, pathToRedirect });
};

export const userUpdate = ({ firstName, lastName }) => (dispatch, getState) => {
  userUpdateCall(dispatch, { firstName, lastName });
};

export const userSetPreferences = ({ preferences }) => (dispatch) => {
  userSetPreferencesCall(dispatch, { preferences });
};
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Checkbox, Divider, Grid, Typography } from "@mui/material";

import { styles } from "./HierarchyDiff.styles";
import HierarchyTree from "../../../_common/HierarchyTree/HierarchyTree";
import HierarchyNodeDetails from "../../../ProductDetails/ProductHierarchyDetails/HierarchyNodeDetails/HierarchyNodeDetails";

function HierarchyDiff({ classes, value, newValue, hasDiff, onCheck, checked, productThumbnail, viewMode }) {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container direction="column">
        <Grid item
          className={classes.headerContainer}>
          <Typography variant="h4" className={classes.header}>
            {t("products.ProductValidationForm.section.hierarchy")}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2} wrap="nowrap">
            <Grid item flex={1}>
              {value && value.length > 0 && <HierarchyTree hierarchy={value} productThumbnail={productThumbnail} />}
            </Grid>
            <Grid item flex={2}>
              {newValue && newValue.length > 0 &&
                <Grid container spacing={1} justifyContent="space-between">
                  <Grid item xs={6}>
                    <HierarchyTree hierarchy={newValue} productThumbnail={productThumbnail} />
                  </Grid>
                  <Grid item xs={6}>
                    {newValue.map((node, index) => (
                      <div key={"hierarchyNode_" + index}>
                        {index > 0 && <Divider className={classes.divider} />}
                        <HierarchyNodeDetails node={node} oldNode={value?.find(item => item.gtin === node.gtin)} showDiff />
                      </div>
                    ))}
                  </Grid>
                </Grid>
              }
            </Grid>
            <Grid item style={{ width: 56 }}>
              {hasDiff && !viewMode && (
                <Checkbox color="success" checked={checked} onChange={(e) => onCheck(e.target.checked)} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(HierarchyDiff, styles);
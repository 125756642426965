import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { processHierarchyToSave, processWeightMeasuresToSave, uploadDocuments, uploadMedia } from "./_auxiliars";
import productSaveFilesCall from "./productSaveFilesCall";

const productDraftCall = async ({ productGet }, dispatch, state, product) => {
  let rh = requestHelper(dispatch, "PRODUCT_DRAFT");
  const promoterCode = state.core.promoterDetails?.details?.code;
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage")

  try {
    let nMedia, nDocs;
    const isUpdate = Boolean(product.id)
    if (isUpdate) {
      nMedia = await uploadMedia(fileStorageConfigs, promoterCode, product.id, product.media);
      nDocs = await uploadDocuments(fileStorageConfigs, promoterCode, product.id, product.documents);
    }

    let result = await call({
      type: "PRODUCT",
      subType: "DRAFT",
      request: {
        product: {
          ...product,
          productId: product.id,
          logo: undefined,
          media: nMedia,
          documents: nDocs,
          weightMeasure: processWeightMeasuresToSave(product?.weightMeasure),
          hierarchy: processHierarchyToSave(product?.hierarchy, nMedia?.[0]),
        },
      },
    });

    if (!isUpdate) {
      await productSaveFilesCall(dispatch, state, {
        productId: result.product.id,
        media: product.media,
        documents: product.documents,
      });
    }

    setTimeout(() => {
      dispatch(productGet(result.product.id));
      if (!product.id) {
        dispatch(setRedirect("/products/edit/" + result.product.id));
      }
      dispatch(setSuccess("products", "PRODUCT_DRAFT"));
    }, 300);
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productDraftCall;

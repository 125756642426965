import * as Yup from "yup";
import { CardSelectRawField, DatePickerField, TextField } from "../../../common/forms";
import ProductSelectField from "../../../common/forms/custom/ProductSelectField";
import { getOrdersOptions } from "./BatchForm.options";
import OrderCardItem from "./OrderCardItem/OrderCardItem";
import { Button } from "@mui/material";

export function formSchema({ t }) {
  return Yup.object().shape({
    productId: Yup.string().required(t("batches.BatchForm.form.productIdRequired")),
    startDate: Yup.string().required(t("batches.BatchForm.form.startDateRequired")),
    batchNumber: Yup.string().required(t("batches.BatchForm.form.batchNumberRequired")),
    description: Yup.string(),
  });
}


// productId,
// startDate,
// batchNumber,
// description,
// type = "M" // main "I" // internal (broodstock),
// batchOrders,

// businessUnitId,

export function formFields({ t, products, orders, internalBatches, onCreateInternalOrderClick }) {
  return [
    {
      size: 4,
      field: <ProductSelectField
        id="productId"
        products={products}
        label={t("batches.BatchForm.form.product")}
        emptyMsg={t("batches.BatchForm.form.productEmpty")}
        errorMsg={t("batches.BatchForm.form.productError")}
        required
      />,
    },
    {
      size: 4,
      field: <DatePickerField
        id="startDate"
        required
        label={t("batches.BatchForm.form.startDate")}
      />,
    },
    {
      size: 4,
      field: <TextField
        id="batchNumber"
        required
        label={t("batches.BatchForm.form.batchNumber")}
      />,
    },
    {
      size: 12,
      field: <TextField
        id="description"
        label={t("batches.BatchForm.form.description")}
      />,
    },
    {
      size: 12,
      field: <CardSelectRawField
        id="batchOrders"
        label={t("batches.BatchForm.form.batchOrders")}
        multi
        xs={4}
        options={getOrdersOptions(orders)}
        cardContent={(item) => (<OrderCardItem order={item} />)}
      />,
    },
    {
      size: 12,
      field: <Button variant="contained" color="primary" size="small" onClick={onCreateInternalOrderClick}>
        {t("batches.BatchForm.form.btnCreateInternalOrder")}
      </Button>,
    },
    {
      size: 12,
      field: <br />,
    },
    // {
    //   size: 12,
    //   field: <CardSelectRawField
    //     id="internalBatches"
    //     label={t("batches.BatchForm.form.internalBatches")}
    //     multi
    //     xs={4}
    //     options={getInternalBatchs(internalBatches)}
    //     cardContent={(item) => (<InternalBatchCardItem batch={item} />)}
    //   />,
    // },
  ];
}


import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Grid, Paper, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import arrayMutators from "final-form-arrays";

import { styles } from "./BusinessUnitProductionForm.styles";
import { formFields, formSchema } from "./BusinessUnitProductionForm.schema";

import { Form, FormFields } from "../../../common/forms";
import Permission from "../../../app/components/Permission";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {
  businessUnitCreate,
  businessUnitGet,
  businessUnitList_Full,
  businessUnitUpdate,
  placeList_Full,
  selectBusinessUnitDetails,
  selectBusinessUnitListFull,
} from "../businessUnitsSlice";
import { entityGet, selectEntityDetails } from "../../entities/entitiesSlice";
import { useFirstLoad, useNavigate, useUser } from "../../../common/hooks";
import { selectLoader } from "../../products/productsSlice";
import BusinessUnitsHeaderMenu from "../_common/BusinessUnitsHeaderMenu/BusinessUnitsHeaderMenu";

function BusinessUnitProductionForm({ classes }) {
  const { t } = useTranslation();
  const { businessUnitId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectLoader);
  const entity = useSelector(selectEntityDetails);
  const user = useUser();
  const businessUnitDetails = useSelector(selectBusinessUnitDetails);
  const businessUnits = useSelector(selectBusinessUnitListFull);
  const isEdit = Boolean(businessUnitId);

  useFirstLoad(() => {
    if (!entity && entity?.id !== user?.entity?.id) {
      dispatch(entityGet(user?.entity?.id));
    }
    if (businessUnitId) {
      dispatch(businessUnitGet({ id: businessUnitId }));
    }
    dispatch(businessUnitList_Full());
    dispatch(placeList_Full());
  });


  const onSubmitHandler = async (values) => {
    // console.log('values: %o', values);
    let nValues = { ...values };
    nValues.lines = [];
    (values.lines || []).forEach(item => {
      let inputs = [];
      let outputs = [];
      (item.inputs || []).forEach(input => {
        inputs.push({ ...input, product: undefined, productId: input.product?.[0]?.productId })
      });
      (item.outputs || []).forEach(output => {
        outputs.push({ ...output, product: undefined, productId: output.product?.[0]?.productId })
      });
      nValues.lines.push({
        ...item,
        inputs,
        outputs,
      });
    })
    console.log('nValues: %o', nValues);

    if (isEdit) {
      dispatch(businessUnitUpdate({ ...nValues, id: businessUnitId }));
    }
    else {
      dispatch(businessUnitCreate({ ...nValues }));
    }
  };

  const defaultValues = {
    entityId: user?.entity?.id,
    lines: [
      {
        inputs: [{}],
        outputs: [{}],
      }
    ]
  };

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmitHandler}
        schema={formSchema({ t })}
        initialValues={businessUnitId ? { ...businessUnitDetails, associationType: (businessUnitDetails?.groups?.length > 0 ? "GROUP" : businessUnitDetails?.users?.length > 0 ? "USER" : businessUnitDetails?.sectors?.length > 0 ? "SECTOR" : undefined) } : defaultValues}
        keepDirtyOnReinitialize
        mutators={{
          ...arrayMutators,
        }}
        render={({ values, form }) => {
          return (
            <Page
              permission={"promoter.actions.businessUnit.list"}
              header={<PageTitle
                onBack={() => navigate("/business/units")}
                title={businessUnitDetails?.name + " " + t("businessUnits.BusinessUnitProductionForm.header.title")}
                actions={
                  <Grid container direction="row" spacing={2}>
                    <Permission code={!isEdit ? "promoter.actions.businessUnit.create" : "promoter.actions.businessUnit.update"}>
                      <Grid item>
                        <Button variant="contained" color="primary" type="submit" disabled={loading}>
                          {t("businessUnits.BusinessUnitProductionForm.form.btnSubmit")}
                        </Button>
                      </Grid>
                    </Permission>
                  </Grid>
                }
              />}>
              <Stack spacing={1}>
                <BusinessUnitsHeaderMenu businessUnitId={businessUnitId} selected="lines" />
                <Paper className={classes.paper}>
                  <FormFields fields={formFields({
                    t,
                    businessUnits,
                  })} />
                </Paper>
              </Stack>
            </Page>
          );
        }}
      />
    </div >
  );
}

export default withStyles(BusinessUnitProductionForm, styles);

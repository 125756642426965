export const styles = (theme) => ({
  root: {
    marginTop: 28
  },
  image: {
    display: "block",
    maxWidth: "100%",
    border: `1px solid #ddd`,
  },
  headerContainer: {
    width: "100%",
    height: 43,
  },
  header: {
    margin: 0,
  },
});
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./GroupDataGrid.styles";
import { listColumns } from "./GroupDataGrid.columns";
import GroupDataGridActions from "./GroupDataGrid.actions";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";
import { groupDataGridDefaultFilter } from "../../_common/entitiesFilters";
import { filterFields, filterSchema } from "./GroupDataGrid.filter";

function GroupDataGrid({
  classes,
  data,
  filter,
  totalRows,
  onGroupSelected,
  onFilterChange,
  onDelete,
  onEdit,
}) {
  const { t } = useTranslation();
  const columns = listColumns(t, classes);

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };
      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={columns}
        rows={data || []}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMoreHandler}
        onRowSelected={onGroupSelected}
        total={totalRows}
        actionsComponent={<GroupDataGridActions onDelete={onDelete} onEdit={onEdit} />}
        topActions={<FilterForm
          style={{ marginBottom: 8 }}
          storageKey="GroupDataGrid"
          onStorageLoad={onFilterHandler}
          slim
          fields={filterFields()}
          schema={filterSchema()}
          onFilterChange={onFilterHandler}
          filter={filter}
          defaultFilter={groupDataGridDefaultFilter}
        />}
      />
    </div>
  );
}

export default withStyles(GroupDataGrid, styles)

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./AddressAggregatedDataGrid.styles";
import { listColumns } from "./AddressAggregatedDataGrid.columns";
import AddressAggregatedDataGridActions from "./AddressAggregatedDataGrid.actions";
import { csvFields } from "./AddressAggregatedDataGrid.export";
import { filterFields, filterSchema } from "./AddressAggregatedDataGrid.filter";
import { addressAggregatedOrdersDefaultFilter } from "../../_common/ordersFilters";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";

function AddressAggregatedDataGrid({ classes, data, totalRows, onRowSelected, filter, onFilterChange, onAcceptAll }) {
  const { t } = useTranslation();
  const columns = listColumns(t, classes);

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };
      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={columns}
        rows={data || []}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMoreHandler}
        onRowSelected={onRowSelected}
        total={totalRows}
        actionsComponent={<AddressAggregatedDataGridActions onAcceptAll={onAcceptAll} />}
        exportCsvFields={csvFields(t)}
        exportXlsxFields={csvFields(t)}
        topActions={
          <FilterForm
            style={{ marginBottom: 8 }}
            storageKey="AddressAggregatedDataGrid"
            onStorageLoad={onFilterHandler}
            slim
            fields={filterFields()}
            schema={filterSchema()}
            onFilterChange={onFilterHandler}
            filter={filter}
            defaultFilter={addressAggregatedOrdersDefaultFilter}
          />
        }
      />
    </div>
  );
}

export default withStyles(AddressAggregatedDataGrid, styles)

import React, { useMemo } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import moment from "moment";

import { styles } from "./ProductOtherTaxFormModal.styles";
import { formFields, formSchema } from "./ProductOtherTaxFormModal.schema";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";

import { Form, FormFields } from "../../../../common/forms";
import { usePromoter } from "../../../../common/hooks";

function ProductOtherTaxFormModal({ classes, open, setOpen, onSubmit, product, otherTax }) {
  const { t } = useTranslation();
  const promoter = usePromoter();

  const otherTaxes = useMemo(() => {
    const selectedTaxes = (product?.tax?.other || [])
      .filter(item => item.code || (item.process?.status === "ACCEPTED" && !item.process?.removeDate) || item.process?.status === "PENDING")
      .map(item => item.code || item.process?.other?.code);

    return (promoter?.getConfig("tax", "allowTaxes") || [])
      .filter(taxType => taxType !== "VAT")
      .filter(taxType => !selectedTaxes.includes(taxType) || taxType === otherTax?.code)
      .map((taxType) => ({
        text: t('common.Enums.taxGroup.' + taxType),
        value: taxType
      }))
    // eslint-disable-next-line
  }, [promoter, product])

  const onSubmitHandler = async (values) => {
    onSubmit && onSubmit(product, values);
  };

  const initialValues = {
    amount: {
      value: "0",
      type: "A"
    },
    startDate: moment().format('YYYY-MM-DD')
  }

  return (
    <CustomModal open={open} setOpen={setOpen} size="medium" height={430} title={otherTax ? t("products.ProductOtherTaxFormModal.modal.titleUpdate") : t("products.ProductOtherTaxFormModal.modal.title")}>
      <div className={classes.root}>
        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t, ogAmount: otherTax?.amount })}
          initialValues={
            otherTax ?
              {
                ...otherTax,
                startDate: (otherTax?.startDate && moment(otherTax?.startDate).isAfter(moment().startOf('day'))) ?
                  otherTax.startDate :
                  moment().format("yyyy-MM-DD")
              } :
              initialValues
          }
          render={({ values }) => {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormFields fields={formFields({ t, otherTaxes, values, isEdit: Boolean(otherTax) })} />
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" type="submit" fullWidth>
                    {t("products.ProductOtherTaxFormModal.form.btnSubmit")}
                  </Button>
                </Grid>
              </Grid>
            );
          }}
        />
      </div>
    </CustomModal>
  );
}

export default withStyles(ProductOtherTaxFormModal, styles);

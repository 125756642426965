import { CheckboxField, FileUploadField, MultiSelectField, NumberField, SelectField, TextField } from "../../../../common/forms";

export const adminFields = ({ t, formOptions, values }) => {

  return [
    {
      size: 12,
      field: <b>{t("promoter.PromoterConfigs.form.adminTitle")}</b>,
    },
    {
      size: 4,
      field: <TextField id="admin.baseUrl" type="text" label={t("promoter.PromoterConfigs.form.baseUrl")} />,
    },
    {
      size: 4,
      field: (
        <FileUploadField
          id="admin.logoFile"
          label={t("promoter.PromoterConfigs.form.adminLogo")}
          placeholder={t("promoter.PromoterConfigs.form.adminLogo")}
          image
        />
      ),
    },
    {
      size: 4,
    },
    {
      size: 6,
      field: (
        <MultiSelectField
          id="admin.language"
          options={formOptions.languages}
          label={t("promoter.PromoterConfigs.form.adminLanguages")}
        />
      ),
    },
    {
      size: 12,
      field: <b>{t("promoter.PromoterConfigs.form.adminEmailTitle")}</b>,
    },
    {
      size: 4,
      field: <TextField id="admin.email.host" type="text" label={t("promoter.PromoterConfigs.form.adminEmailHost")} />,
    },
    {
      size: 4,
      field: (
        <TextField
          id="admin.email.username"
          type="text"
          label={t("promoter.PromoterConfigs.form.adminEmailUsername")}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField
          id="admin.email.password"
          type="text"
          label={t("promoter.PromoterConfigs.form.adminEmailPassword")}
        />
      ),
    },
    {
      size: 4,
      field: (
        <NumberField id="admin.email.port" label={t("promoter.PromoterConfigs.form.adminEmailPort")} decimalScale={0} />
      ),
    },
    {
      size: 4,
      field: <TextField id="admin.email.from" type="text" label={t("promoter.PromoterConfigs.form.adminEmailFrom")} />,
    },
    {
      size: 12,
      field: <b>{t("promoter.PromoterConfigs.form.adminFileStorageTitle")}</b>,
    },
    {
      size: 4,
      field: (
        <TextField
          id="admin.fileStorage.provider"
          type="text"
          label={t("promoter.PromoterConfigs.form.adminFileStorageProvider")}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField
          id="admin.fileStorage.region"
          type="text"
          label={t("promoter.PromoterConfigs.form.adminFileStorageRegion")}
        />
      ),
    },
    {
      size: 4,
    },
    {
      size: 4,
      field: (
        <TextField
          id="admin.fileStorage.key"
          type="text"
          label={t("promoter.PromoterConfigs.form.adminFileStorageKey")}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField
          id="admin.fileStorage.secret"
          type="text"
          label={t("promoter.PromoterConfigs.form.adminFileStorageSecret")}
        />
      ),
    },
    {
      size: 4,
    },
    {
      size: 4,
      field: (
        <TextField
          id="admin.fileStorage.tempBucket"
          type="text"
          label={t("promoter.PromoterConfigs.form.adminFileStorageTempBucket")}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField
          id="admin.fileStorage.privateBucket"
          type="text"
          label={t("promoter.PromoterConfigs.form.adminFileStoragePrivateBucket")}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField
          id="admin.fileStorage.publicBucket"
          type="text"
          label={t("promoter.PromoterConfigs.form.adminFileStoragePublicBucket")}
        />
      ),
    },
    {
      size: 12,
      field: <b>{t("promoter.PromoterConfigs.form.adminCatalogApiTitle")}</b>,
    },
    {
      size: 4,
      field: (
        <TextField
          id="admin.catalogApi.apiKey"
          type="text"
          label={t("promoter.PromoterConfigs.form.adminCatalogApiKey")}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField
          id="admin.catalogApi.callbackApikey"
          type="text"
          label={t("promoter.PromoterConfigs.form.adminCatalogApiCallback")}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField id="admin.catalogApi.id" type="text" label={t("promoter.PromoterConfigs.form.adminCatalogApiId")} />
      ),
    },
    {
      size: 4,
      field: (
        <TextField
          id="admin.catalogApi.productCategory"
          type="text"
          label={t("promoter.PromoterConfigs.form.adminCatalogApiProductCategory")}
        />
      ),
    },
    {
      size: 12,
      field: <b>{t("promoter.PromoterConfigs.form.adminGoogleApiTitle")}</b>,
    },
    {
      size: 4,
      field: (
        <TextField id="admin.googleApis.key" type="text" label={t("promoter.PromoterConfigs.form.adminGoogleApiKey")} />
      ),
    },
    {
      size: 12,
      field: <b>{t("promoter.PromoterConfigs.form.adminAppApi")}</b>,
    },
    {
      size: 12,
      field: <CheckboxField id="admin.appApi.enable" label={t("promoter.PromoterConfigs.form.adminAppApiEnable")} />,
    },
    {
      size: 4,
      field: <TextField id="admin.appApi.apiKey" type="text" label={t("promoter.PromoterConfigs.form.adminAppApiKey")} />,
      hidden: !Boolean(values?.admin?.appApi?.enable)
    },
    {
      size: 12,
      field: <CheckboxField id="admin.appApi.productsApiAvailable" label={t("promoter.PromoterConfigs.form.adminAppApiProductsApiAvailable")} />,
      hidden: !Boolean(values?.admin?.appApi?.enable)
    },

    {
      size: 12,
      field: <b>{t("promoter.PromoterConfigs.form.adminExternalProvider")}</b>,
    },
    {
      size: 12,
      field: <CheckboxField id="admin.external.enable" label={t("promoter.PromoterConfigs.form.adminExternalEnable")} />,
    },
    {
      size: 4,
      field: <SelectField id="admin.external.service.type" label={t("promoter.PromoterConfigs.form.adminExternalType")} options={formOptions.serviceTypes} />,
      hidden: !Boolean(values?.admin?.external?.enable)
    },
    {
      size: 6,
      field: <TextField id="admin.external.service.baseUrl" type="text" label={t("promoter.PromoterConfigs.form.adminExternalBaseUrl")} />,
      hidden: !Boolean(values?.admin?.external?.enable)
    },
    {
      size: 2,
      hidden: !Boolean(values?.admin?.external?.enable)
    },
    {
      size: 4,
      field: <CheckboxField id="admin.external.service.basicAuth" label={t("promoter.PromoterConfigs.form.adminExternalBasicAuth")} />,
      hidden: !Boolean(values?.admin?.external?.enable)
    },
    {
      size: 4,
      field: <TextField id="admin.external.service.user" type="text" label={t("promoter.PromoterConfigs.form.adminExternalUsername")} />,
      hidden: !Boolean(values?.admin?.external?.enable)
    },
    {
      size: 4,
      field: <TextField id="admin.external.service.password" type="text" label={t("promoter.PromoterConfigs.form.adminExternalPassword")} />,
      hidden: !Boolean(values?.admin?.external?.enable)
    },
    {
      size: 3,
      field: <CheckboxField id="admin.external.entity.useDataFromExternal" label={t("promoter.PromoterConfigs.form.adminExternalEntityUseDataFromExternal")} />,
      hidden: !Boolean(values?.admin?.external?.enable)
    },
    {
      size: 3,
      field: <CheckboxField id="admin.external.entity.sendDataToExternal" label={t("promoter.PromoterConfigs.form.adminExternalEntitySendDataToExternal")} />,
      hidden: !Boolean(values?.admin?.external?.enable)
    },
    {
      size: 6,
      hidden: !Boolean(values?.admin?.external?.enable)
    },
    {
      size: 3,
      field: <CheckboxField id="admin.external.product.useDataFromExternal" label={t("promoter.PromoterConfigs.form.adminExternalProductUseDataFromExternal")} />,
      hidden: !Boolean(values?.admin?.external?.enable)
    },
    {
      size: 3,
      field: <CheckboxField id="admin.external.product.sendDataToExternal" label={t("promoter.PromoterConfigs.form.adminExternalProductSendDataToExternal")} />,
      hidden: !Boolean(values?.admin?.external?.enable)
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: <CheckboxField id="admin.external.product.sendDataSheetToExternal" label={t("promoter.PromoterConfigs.form.adminExternalProductSendDataSheetToExternal")} />,
      hidden: !Boolean(values?.admin?.external?.enable)
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: <SelectField id="admin.external.dataSheetService.type" label={t("promoter.PromoterConfigs.form.dataSheetServiceType")} options={formOptions.serviceTypes} />,
      hidden: !Boolean(values?.admin?.external?.product?.sendDataSheetToExternal)
    },
    {
      size: 4,
      field: <TextField id="admin.external.dataSheetService.baseUrl" type="text" label={t("promoter.PromoterConfigs.form.dataSheetServiceBaseUrl")} />,
      hidden: !Boolean(values?.admin?.external?.product?.sendDataSheetToExternal)
    },
    {
      size: 4,
      field: <TextField id="admin.external.dataSheetService.apiKey" type="text" label={t("promoter.PromoterConfigs.form.dataSheetServiceApiKey")} />,
      hidden: !Boolean(values?.admin?.external?.product?.sendDataSheetToExternal)
    },

    {
      size: 12,
      field: <b>{t("promoter.PromoterConfigs.form.adminFtp")}</b>,
    },
    {
      size: 12,
      field: <CheckboxField id="admin.ftp.enable" label={t("promoter.PromoterConfigs.form.adminFtpEnable")} />,
    },
    {
      size: 4,
      field: <TextField id="admin.ftp.host" type="text" label={t("promoter.PromoterConfigs.form.ftpHost")} />,
      hidden: !Boolean(values?.admin?.ftp?.enable)
    },
    {
      size: 4,
      field: <TextField id="admin.ftp.port" type="text" label={t("promoter.PromoterConfigs.form.ftpPort")} />,
      hidden: !Boolean(values?.admin?.ftp?.enable)
    },
    {
      size: 4,
      field: <TextField id="admin.ftp.user" type="text" label={t("promoter.PromoterConfigs.form.ftpUser")} />,
      hidden: !Boolean(values?.admin?.ftp?.enable)
    },
    {
      size: 4,
      field: <TextField id="admin.ftp.password" type="text" label={t("promoter.PromoterConfigs.form.ftpPassword")} />,
      hidden: !Boolean(values?.admin?.ftp?.enable)
    },
    {
      size: 4,
      field: <CheckboxField id="admin.ftp.secureFTP" label={t("promoter.PromoterConfigs.form.adminFtpSecureFTP")} />,
      hidden: !Boolean(values?.admin?.ftp?.enable)
    },
    {
      size: 4,
      field: <CheckboxField id="admin.ftp.product.sendDataToExternal" label={t("promoter.PromoterConfigs.form.adminFtpProductSendDataToExternal")} />,
      hidden: !Boolean(values?.admin?.ftp?.enable)
    },

  ];
};

export const styles = (theme) => ({
  root: {
    position: "relative",
    color: theme.palette.text.secondary
  },
  iconBase: {
    display: "block",
  },
  orderType: {
    display: "block",
    position: "absolute",
    scale: "0.5",
    right: "0",
    left: "0",
    bottom: "-1px"
  }
});
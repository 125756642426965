import React, { useState, useEffect } from "react";
import { withStyles } from "tss-react/mui";
import { Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

import { styles } from "./ScrollNav.styles";

function ScrollNav({ classes, tabs }) {
  const [tab, setTab] = useState(tabs?.[0]?.id);
  const [delayIntersector, setDelayIntersector] = useState(false);

  useEffect(() => {
    let observer = new IntersectionObserver(
      (entities) => {
        let delayIntersection = entities[0].target.getAttribute("data-intersection");
        if (entities[0].isIntersecting && delayIntersection === "false") {
          setTab(entities[0].target.children[0].id);
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 0.5,
      }
    );
    setTimeout(() => {
      let elements = document.getElementsByClassName("section");
      Array.from(elements || []).forEach((element) => {
        observer.observe(element);
      });
    }, 300);
  }, []);

  const scrollToTab = (tab) => {
    setDelayIntersector(true);
    const element = document.getElementById(tab);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setTimeout(() => {
      setDelayIntersector(false);
    }, 700);
    setTab(tab);
  };

  return (
    <div className={classes.root}>

      <Grid container wrap="nowrap">
        <Grid item style={{ width: 220 }}>
          <Tabs
            style={{ position: "fixed", width: 220 }}
            orientation="vertical"
            variant="scrollable"
            value={tab}
            onChange={(e, newValue) => scrollToTab(newValue)}
            className={classes.tabs}
            scrollButtons={false}
          >
            {tabs.map(({ id, title, hidden, error }) =>
              !hidden ? (<Tab value={id} key={id} label={
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="inherit" style={{ margin: 0 }}>{title}</Typography>
                  {error && <WarningAmberRoundedIcon fontSize="inherit" color="error" />}
                </Stack>
              } />) : null
            )}
          </Tabs>
        </Grid>
        <Grid item style={{ width: "calc(100% - 220px)" }} id="scrollArea">
          <Stack spacing={2}>
            {tabs.map(({ id, content, hidden }) => !hidden ? (
              <div key={"content_" + id} className={classes.section + " section"} data-intersection={delayIntersector}>
                <div id={id} className={classes.anchor}></div>
                {content}
              </div>
            ) : null)}
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(ScrollNav, styles);

import DateTime from "../../../../common/displays/DateTime/DateTime";

export function listColumns(t, classes) {
  return [
    {
      field: "tableName",
      headerName: t("promoter.PromoterConfigs.retention.columns_tableName"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "numberDays",
      headerName: t("promoter.PromoterConfigs.retention.columns_numberDays"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "active",
      headerName: t("promoter.PromoterConfigs.retention.columns_active"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        if (v.active) {
          return t("promoter.PromoterConfigs.retention.statusTrue");
        } else if (!v.active) {
          return t("promoter.PromoterConfigs.retention.statusFalse");
        }
      }
    },
    {
      field: "createdBy",
      headerName: t("promoter.PromoterConfigs.retention.columns_createdBy"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "createdTime",
      headerName: t("promoter.PromoterConfigs.retention.columns_createdTime"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return <DateTime date={v.createdTime} />;
      },
    },
    {
      field: "updatedBy",
      headerName: t("promoter.PromoterConfigs.retention.columns_updatedBy"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "updatedTime",
      headerName: t("promoter.PromoterConfigs.retention.columns_updatedTime"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return <DateTime date={v.updatedTime} />;
      },
    }

  ];
}


import { Checkbox } from "@mui/material";
import { currencyFormat, dateFormat, dateTimeFormatWithMilliSeconds } from "../../../../common/utils/formats";
import Permission from "../../../../app/components/Permission";

export function listColumns(t, classes, checkedItems, onCheckChanged, onCheckAll) {
  const allChecked = Object.values(checkedItems || {}).every(Boolean);
  return [
    {
      field: "availableSend",
      align: "left",
      headerAlign: "left",
      disableSort: true,
      headerComponent: (
        <Permission code="business.actions.accounting.setAvailable">
          <Checkbox
            className={classes.checkbox}
            color="primary"
            checked={Boolean(allChecked)}
            onChange={() => onCheckAll && onCheckAll()}
          />
        </Permission>
      ),
      customValue: (v) => {
        const checked = checkedItems[v.id];
        return (
          <Permission code="business.actions.accounting.setAvailable">
            <Checkbox
              className={classes.checkbox}
              color="primary"
              checked={Boolean(checked)}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={() => {
                onCheckChanged && onCheckChanged({ ...checkedItems, [v.id]: !checked })
              }}
            />
          </Permission>
        );
      },
    },
    {
      field: "documentDate",
      headerName: t("business.accounting.Accounting.columns_documentDate"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateFormat(v.documentDate));
      },
      disableSort: true
    },
    {
      field: "ownerName",
      headerName: t("business.accounting.Accounting.columns_ownerName"),
      align: "left",
      headerAlign: "left",
      disableSort: true
    },
    {
      field: "customerDocNumber",
      headerName: t("business.accounting.Accounting.columns_customerDocNumber"),
      align: "left",
      headerAlign: "left",
      disableSort: true
    },
    {
      field: "documentId",
      headerName: t("business.accounting.Accounting.columns_documentId"),
      align: "left",
      headerAlign: "left",
      disableSort: true
    },
    {
      field: "documentType",
      headerName: t("business.accounting.Accounting.columns_documentType"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (t("business.accounting.options.doc_type_" + v.documentType));
      },
      disableSort: true
    }, {
      field: "refDocumentId",
      headerName: t("business.accounting.Accounting.columns_refDocumentId"),
      align: "left",
      headerAlign: "left",
      disableSort: true
    },
    {
      field: "refDocumentType",
      headerName: t("business.accounting.Accounting.columns_refDocumentType"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (t("business.accounting.options.doc_type_" + v.refDocumentType));
      },
      disableSort: true
    },
    {
      field: "supplierDocNumber",
      headerName: t("business.accounting.Accounting.columns_supplierDocNumber"),
      align: "left",
      headerAlign: "left",
      disableSort: true
    },
    {
      field: "total",
      headerName: t("business.accounting.Accounting.columns_total"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (currencyFormat(v.total));
      },
      disableSort: true
    },
    {
      field: "createdBy",
      headerName: t("business.accounting.Accounting.columns_createdBy"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (v.createdBy);
      },
      disableSort: true
    },
    {
      field: "createdDate",
      headerName: t("business.accounting.Accounting.columns_createdDate"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.createdDate));
      },
      disableSort: true
    },
  ];
}

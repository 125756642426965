import React from "react";
import { withStyles } from "tss-react/mui";
import { Grid, Typography, Divider, Chip } from "@mui/material";

import { styles } from "./StepDetails.styles";
import { dateFormat } from "../../../../../../common/utils";
import StepDate from "../StepDate/StepDate";

function StepDetails({ classes, statusHistory }) {

  return (
    <div className={classes.root}>
      <Grid container direction="row" wrap="nowrap" spacing={2}>
        {statusHistory.map((item, index) => (
          <React.Fragment key={item.status + "_" + index}>
            <Grid item>
              <Grid container direction="column" spacing={2}>
                {/* DATE */}
                <Grid item>
                  <Grid container direction="row" spacing={1} alignItems="center">
                    <Grid item>
                      <StepDate date={item.updatedTime} />
                    </Grid>
                    {
                      item.dispatchId && <Grid item><Chip className={classes.dispatchId} label={item.dispatchId} size="small" /></Grid>
                    }
                  </Grid>
                </Grid>
                {/* DOCUMENT */}
                {item.documentNumber && <Grid item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography
                        className={classes.nomargin}
                        component="span"
                        variant="caption"
                        color="textSecondary"
                      >
                        {item.documentNumber}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        className={classes.datetime}
                        component="span"
                        variant="caption"
                        color="textSecondary"
                      >
                        {dateFormat(item.documentDate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>}
              </Grid>
            </Grid>
            {index < statusHistory.length - 1 && (
              <Grid item>
                <Divider orientation="vertical" light />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </div >
  );
}

export default withStyles(StepDetails, styles);
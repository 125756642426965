import * as Yup from "yup";
import i18next from "i18next";
import { TextField, NumberField, CheckboxField, DatePickerField, DateTimePickerField } from "../../forms";
import { Stack, Typography } from "@mui/material";

export const extractFormSchema = (form, t) => {
  let formShape = {};
  (form || []).forEach(({ key, type, required }) => {
    switch (type) {
      case "text":
      case "textarea":
        if (required) {
          formShape[key] = Yup.string().required(t("common.DynamicForm.schema.requiredField"));
        }
        else {
          formShape[key] = Yup.string();
        }
        break;
      case "number":
        if (required) {
          formShape[key] = Yup.number().required(t("common.DynamicForm.schema.requiredField"));
        }
        else {
          formShape[key] = Yup.number();
        }
        break;
      case "checkbox":
        if (required) {
          formShape[key] = Yup.bool().required(t("common.DynamicForm.schema.requiredField"));
        }
        else {
          formShape[key] = Yup.bool()
        }
        break;
      case "date":
      case "datetime":
        if (required) {
          formShape[key] = Yup.date().required(t("common.DynamicForm.schema.requiredField"));
        }
        else {
          formShape[key] = Yup.date();
        }
        break;
      default:
        break;
    }
  })

  return Yup.object().shape(formShape);
}

export const extractFormFields = (form, classes) => {
  let fields = [];
  (form || []).forEach(({ key, type, required, label, obs }) => {
    let localLabel = label[i18next.language];
    let localObs = obs ? obs[i18next.language] : undefined;
    switch (type) {
      case "text":
        fields.push({
          size: 12,
          field: <TextField id={key} label={localLabel} required={required} type="text" note={localObs} />,
        });
        break;
      case "textarea":
        fields.push({
          size: 12,
          field: <TextField id={key} rows={3} label={localLabel} required={required} type="text" note={localObs} />,
        });
        break;
      case "number":
        fields.push({
          size: 12,
          field: <NumberField id={key} label={localLabel} required={required} note={localObs} />,
        });
        break;
      case "checkbox":
        fields.push({
          size: 12,
          field: <CheckboxField id={key} label={localLabel} required={required} note={localObs} />,
        });
        break;
      case "date":
        fields.push({
          size: 12,
          field: <DatePickerField id={key} label={localLabel} required={required} note={localObs} />,
        });
        break;
      case "datetime":
        fields.push({
          size: 12,
          field: <DateTimePickerField id={key} label={localLabel} required={required} note={localObs} />,
        });
        break;
      case "separator":
        fields.push({
          size: 12,
          field: (
            <div className={classes.separator}>
              <Stack>
                <Typography className={classes.separatorTitle}>{localLabel}</Typography>
                {localObs && <Typography className={classes.separatorObs}>{localObs}</Typography>}
              </Stack>
            </div>
          ),
        });
        break;
      default:
        break;
    }
  })

  return fields;
}
import {currencyFormat} from "../../../../common/utils";

export const csvFields = (t) => {
  return [
    {
      field: "sellerName",
      header: t("business.reports.WeeklyUnitsDelivered.columns_sellerName"),
    },{
      field: "buyerName",
      header: t("business.reports.WeeklyUnitsDelivered.columns_buyerName"),
    },{
      field: "deliveryPlace",
      header: t("business.reports.WeeklyUnitsDelivered.columns_deliveryPlace"),
    },{
      field: "totalTransportUnits",
      header: t("business.reports.WeeklyUnitsDelivered.columns_totalTransportUnits"),
    },{
      field: "price",
      header: t("business.reports.WeeklyUnitsDelivered.columns_price"),
      customValue: (v) => {
        return currencyFormat(v.price);
      },
    },
  ];
}

import React from "react";
import { withStyles } from "tss-react/mui";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { styles } from "./CheckboxIcon.styles";

function CheckboxIcon({ classes, value, oldValue }) {
  let hasChanges = oldValue !== undefined && value !== oldValue;
  return value ? <CheckCircleOutlineIcon fontSize="small" className={hasChanges ? classes.added : ""} /> : <RadioButtonUncheckedIcon fontSize="small" className={hasChanges ? classes.removed : ""}/>;
}

export default withStyles(CheckboxIcon, styles);
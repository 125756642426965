export const styles = (theme) => ({
  root: {
    width: "100%",
    // background: theme.palette.background.paper,
    display: "flex"
  },
  nomargin: {
    margin: 0,
  },
  incidentIcon: {
    color: theme.palette.warning.main,
  },
});

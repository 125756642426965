import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { getImageUrlBase64 } from "../../../api/s3";

const productGetCommentsCall = async (
  { clearProductDetailComments, setProductDetailCommentsFilter, setProductDetailComments, setProductDetailCommentsTotalRows },
  dispatch,
  state,
  { productId, filter, clear }) => {
  let rh = requestHelper(dispatch, "PRODUCT_LIST_COMMENTS");
  if (clear) {
    dispatch(clearProductDetailComments());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setProductDetailCommentsFilter(filter));
  try {
    const result = await call({
      type: "PRODUCT",
      subType: "LIST_COMMENT",
      request: {
        ...filter,
        productId,
      }
    });
    if (result && result.items && result.items.length > 0) {
      let items = [];
      const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
      const promoterCode = state.core.promoterDetails?.details?.code;
      for (let i = 0; i < result.items.length; i++) {
        let item = result.items[i];
        items.push({ ...item, logo: (item ? await fromUser(fileStorageConfigs, promoterCode, item) : undefined) });
      }
      dispatch(setProductDetailComments(items));
      dispatch(setProductDetailCommentsTotalRows(result.rows));
    }

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productGetCommentsCall;


export const fromUser = async (filestorageConfigs, promoterCode, contact) => {
  let logo = "";
  try {
    logo = await getImageUrlBase64(filestorageConfigs?.provider, filestorageConfigs?.privateBucket, contact.avatar?.path);
  } catch (ex) {
    logo = "";
  }
  return logo;
};
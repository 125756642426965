import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useFirstLoad, useNavigate, usePromoter } from "../../../common/hooks";
import { useTranslation } from "react-i18next";
import { Paper, Grid, Button } from "@mui/material";
import { withStyles } from "tss-react/mui";
import { styles } from "./CatalogCreate.styles";
import { useUser } from "../../../common/hooks";

import { formFields, formSchema } from "./CatalogCreate.schema";
import { createCatalog, updateCatalog, selectCatalogDetails, getCatalog } from "../productsSlice.catalogCalls";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";

import Form from "../../../common/forms/Form";
import Permission from "../../../app/components/Permission";
import Page from "../../../common/displays/Page/Page";

function CatalogCreate({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const details = useSelector(selectCatalogDetails);
  const user = useUser();
  const promoter = usePromoter();

  useFirstLoad(() => {
    if (id && details?.id !== id) {
      dispatch(getCatalog(id));
    }
  });

  const onSubmit = async (values) => {
    if (!id) {
      dispatch(createCatalog(values));
    } else {
      dispatch(updateCatalog(id, values));
    }
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        schema={formSchema(t)}
        initialValues={id ? details : { ownerId: user?.entity?.id }}
        render={({ values }) => {
          return (
            <Page
              permission="products.actions.catalog.get"
              header={<PageTitle
                title={
                  id
                    ? t("products.CatalogCreate.header.editTitle") + " " + details?.name
                    : t("products.CatalogCreate.header.title")
                }
                onBack={() => navigate("/products/catalogs")}
                actions={
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <Permission code="products.actions.catalog.create">
                        <Button variant="contained" color="primary" size="large" type="submit">
                          {t("products.CatalogCreate.header.addBtn")}
                        </Button>
                      </Permission>
                    </Grid>
                  </Grid>
                }
              />}>

              <Paper className={classes.paper} elevation={0}>
                <Grid container alignItems="flex-start" spacing={2}>
                  {formFields(t, promoter, Boolean(id), user, values).map((item, idx) => (
                    <Grid item xs={item.size} key={idx}>
                      {item.field}
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(CatalogCreate, styles);

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper } from "@mui/material";

import { styles } from "./ProductHierarchyDetails.styles";
import HierarchyTree from "../../_common/HierarchyTree/HierarchyTree";
import HierarchyNodeDetails from "./HierarchyNodeDetails/HierarchyNodeDetails";
import { useState } from "react";

function ProductHierarchyDetails({ classes, product }) {
  const { t } = useTranslation();
  const [selectedHierarchyNode, setSelectedHierarchyNode] = useState();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item>
          <Typography variant="h3" align="left" component="h4" className={classes.noMargin}>
            {t("products.ProductDetails.hierarchyDetails.title")}
          </Typography>
        </Grid>
      </Grid>
      {product?.hierarchy && (
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={6}>
            <HierarchyTree hierarchy={product?.hierarchy} productThumbnail={product?.logo?.url} onSelect={node => setSelectedHierarchyNode(node)} />
          </Grid>
          <Grid item xs={6}>
            <HierarchyNodeDetails node={selectedHierarchyNode} />
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

export default withStyles(ProductHierarchyDetails, styles);



import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const userSetPreferencesCall = async (
  dispatch,
  { preferences }
) => {
  let rh = requestHelper(dispatch, "USER_SET_PREFERENCES");
  try {
    let result = await call({
      type: "USER",
      subType: "SET_PREFERENCES",
      request: {
        preferences
      },
    });
    if (result) {
      dispatch(setSuccess("users", "USER_SET_PREFERENCES"));
    }
  } catch (ex) {
    rh.error("users", ex);
  }
  rh.close();
};

export default userSetPreferencesCall;


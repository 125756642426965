import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { styles } from "./BundleInput.styles";
import { Field } from "react-final-form";
import MetaError from "../../../../../common/forms/MetaError";
import { QuantityInput } from "../../../../../common/inputs/custom";
import ProductPicker from "../../../../../common/inputs/custom/ProductPicker/ProductPicker";

function BundleInput({ classes, id, campaignId, disabled }) {
  const { t } = useTranslation();

  const onProductsSelectedChangeHandler = (input, v, key) => {
    let nValue = input.value || { productBuy: [], productOffers: [] };
    let list = [];
    // v - lista de novos produtos selecionados
    v.forEach((element) => {
      list = [
        ...list,
        { ...element, quantity: nValue[key].find((el) => el.productId === element.productId)?.quantity || 0 },
      ];
    });
    if (key === "productBuy") {
      input.onChange && input.onChange({ ...nValue, productBuy: list });
    } else {
      input.onChange && input.onChange({ ...nValue, productOffers: list });
    }
  };

  const quantityHandler = (input, v, id, key) => {
    let nValue = input.value || { productBuy: [], productOffers: [] };
    let list = [];
    if (nValue[key]) {
      list = nValue[key].map((p) => {
        if (p.productId === id) {
          return {
            ...p,
            quantity: v,
          };
        }
        return p;
      });
    }
    if (key === "productBuy") {
      input.onChange && input.onChange({ ...nValue, productBuy: list });
    } else {
      input.onChange && input.onChange({ ...nValue, productOffers: list });
    }
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => {
          // let productsToBuy = (input.value.productBuy || []).map(item => item.product);

          return (
            <div>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <Grid container direction="row" wrap="nowrap" spacing={1} justifyContent="space-between">
                    <Grid item>
                      <ProductPicker
                        label={
                          <Typography component="span" variant="caption" color="textPrimary">
                            {t("products.CampaignConfiguration.form.bundleBuy")}
                          </Typography>
                        }
                        readonly={Boolean(campaignId)}
                        disabled={disabled}
                        simple
                        selectedProducts={input.value.productBuy || []}
                        onProductsSelectedChange={(val) => onProductsSelectedChangeHandler(input, val, "productBuy")}
                      />
                    </Grid>
                    <Grid item style={{ paddingTop: 35 }}>
                      <Grid container style={{ height: "100%" }} direction="column" justifyContent="space-between">
                        {(input.value?.productBuy || []).map((item, i) => (
                          <QuantityInput
                            slim
                            style={{ width: 105 }}
                            className={classes.qty}
                            multi={1}
                            value={item.quantity}
                            disabled={disabled || Boolean(campaignId)}
                            key={item.productId + "_buy_" + campaignId + "_" + i}
                            hierarchy={item.hierarchy}
                            onChange={(val) => quantityHandler(input, val, item.productId, "productBuy")}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  <MetaError meta={meta} code="productBuy" />
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction="row" wrap="nowrap" spacing={1} justifyContent="space-between">
                    <Grid item>
                      <ProductPicker
                        label={
                          <Typography component="span" variant="caption" color="textPrimary">
                            {t("products.CampaignConfiguration.form.bundleOffers")}
                          </Typography>
                        }
                        simple
                        readonly={Boolean(campaignId)}
                        selectedProducts={input.value.productOffers || []}
                        onProductsSelectedChange={(val) => onProductsSelectedChangeHandler(input, val, "productOffers")}
                      />
                    </Grid>
                    <Grid item style={{ paddingTop: 35 }}>
                      <Grid container style={{ height: "100%" }} direction="column" justifyContent="space-between">
                        {(input.value?.productOffers || []).map((item, i) => (
                          <QuantityInput
                            slim
                            style={{ width: 105 }}
                            className={classes.qty}
                            multi={1}
                            value={item.quantity}
                            disabled={disabled}
                            hierarchy={item.hierarchy}
                            key={id + "_offer_" + campaignId + "_" + i}
                            onChange={(val) => quantityHandler(input, val, item.productId, "productOffers")}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  <MetaError meta={meta} code="productOffers" />
                </Grid>
              </Grid>
            </div>
          );
        }}
      </Field>
    </div>
  );
}

export default withStyles(BundleInput, styles)

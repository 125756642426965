import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { cleanUrlQueryString } from "../../../common/utils";

const productPendingProductActionCall = async (dispatch, { taskId, productId, status, comment, product, productTemplate }) => {
  let rh = requestHelper(dispatch, "PRODUCT_PENDING_PRODUCT_ACTION");

  try {
    let result = await call({
      type: "PRODUCT",
      subType: "PENDING_PRODUCT_ACTION",
      request: {
        taskId,
        productId,
        status,
        comment,
        product: product ? {
          ...product,
          media: (product?.media || []).map(item => ({
            ...item,
            reference: cleanUrlQueryString(item?.reference || ""),
            thumbnail: cleanUrlQueryString(item?.thumbnail || "")
          })),
          documents: (product?.documents || []).map(item => ({
            ...item,
            url: cleanUrlQueryString(item?.url || "")
          })),
          hierarchy: (product?.hierarchy || []).map(item => ({
            ...item,
            thumbnail: cleanUrlQueryString(item?.thumbnail || "")
          })),
        } : undefined,
        productTemplate
      },
    });
    dispatch(setSuccess("products", "PRODUCT_PENDING_PRODUCT_ACTION_" + status + "_" + result?.state));
    setTimeout(() => {
      dispatch(setRedirect("/products/validation/" + result?.processId || ""));
    }, 300);
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productPendingProductActionCall;

export const styles = (theme) => {
  let disabledProps = {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.text.disabled,
    borderColor: theme.palette.action.disabled + " !important",
    cursor: "default",
    "&:hover": {
      borderColor: theme.palette.action.disabled + " !important",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.action.disabled + " !important",
    },
  };
  return {
    root: {
      diplay: "flex",
      flexDirection: "column",
      borderRadius: 8,
      backgroundColor: "transparent"
    },
    nomargin: {
      margin: 0,
    },
    input: {
      borderRadius: 5,
      backgroundColor: theme.palette.background.input,
      "& div fieldset": {
        borderColor: theme.palette.action.active,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main + " !important",
      },
      "& input": {
        backgroundColor: "transparent"
      },
      "& textarea": {
        padding: "0px !important"
      }
    },
    disabledLabel: {
      color: theme.palette.text.disabled,
    },
    disabledInput: {
      ...disabledProps,
      "& .MuiInputAdornment-root p": {
        color: theme.palette.text.disabled + " !important",
      }
    },
    switch: {
      margin: 0,
    },
    checkbox: {
      padding: 0,
      paddingRight: 10,
    },
    select: {
      margin: 0,
      "& .MuiSelect-select": {
        paddingRight: "32px !important",
      },
      "& fieldset": {
        borderColor: theme.palette.action.active,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main + " !important",
      },
    },
    selectIcon: {
      display: "flex",
      marginRight: theme.spacing(1),
      minWidth: "auto",
    },
    fileupload: {
      cursor: "pointer",
      border: "1px solid #D2D9E4",
      borderColor: theme.palette.action.active,
      backgroundColor: theme.palette.background.input,
      borderRadius: 4,
      padding: 5,
      height: 43,
      "& > .MuiGrid-container": {
        height: 39,
      },
      "& .MuiSvgIcon-root path": {
        fill: theme.palette.action.active,
      },
      "&:hover": {
        borderBottomWidth: 2,
        borderBottomColor: theme.palette.primary.main + " !important",
      },
    },
    fileuploadTextPlaceholder: {
      margin: 0,
      cursor: "pointer",
      textDecoration: "underline",
    },
    fileuploadInput: {
      display: "none",
    },
    fileuploadImagePlaceholder: {
      height: 32,
      width: 32,
      marginRight: theme.spacing(2),
      color: "#c4c4c4",
      stroke: "#c4c4c4",
    },
    fileuploadImage: {
      height: 32,
      margin: "-3px 0 0 0",
      "& img": {
        height: 32,
      },
    },
    fileuploadDisabled: {
      ...disabledProps,
      "& .MuiSvgIcon-root path": {
        fill: theme.palette.action.disabled,
      },
    },
    card: {
      border: "1px solid #D2D9E4",
      backgroundColor: theme.palette.background.input,
      borderRadius: 4,
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      cursor: "pointer",
      minHeight: 40,
      borderColor: theme.palette.action.active + " !important",
      "&:hover": {
        borderColor: theme.palette.primary.main + " !important",
      },
    },
    cardContainer: {
      minHeight: 28,
    },
    cardSelected: {
      backgroundColor: theme.palette.action.selected + " !important",
      borderColor: theme.palette.action.active + " !important",
    },
    cardDisabled: {
      ...disabledProps,
      "& span": {
        color: theme.palette.text.disabled,
      },
    },
    autocomplete: {
      "& .MuiAutocomplete-inputRoot": {
        padding: "0 !important",
        paddingRight: "65px !important",
      },
    },
    textselectContainer: {
      position: "relative",
      "& .MuiInputBase-input": {
        lineHeight: 1,
      },
      "&:hover fieldset": {
        borderBottomWidth: 2,
        borderBottomColor: theme.palette.primary.main + " !important",
      },
    },
    textselect: {
      position: "absolute",
      top: 0,
      right: 0,
      "& .MuiSelect-select": {
        padding: "8px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "transparent !important",
        paddingRight: "32px !important",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
      "&.Mui-disabled": {
        backgroundColor: "transparent !important",
      },
    },
    adornment: {
      "& p": {
        color: "#000 !important",
      },
    },
    filename: {
      whiteSpace: "nowrap",
    },
    noteIcon: {
      display: "block",
      height: 16,
      width: 16,
      marginBottom: "8px !important"
    },
    noteIconCheck: {
      display: "block",
      height: 16,
      width: 16,
      marginBottom: "0px !important"
    },
    datepicker: {
      "& input": {
        paddingRight: "0px !important"
      }
    }
  };
};

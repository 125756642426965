import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from '../../../common/hooks';
import { isValidGuid, isValidNumber } from '../../../common/utils';

const Security = ({ component, paramValidation }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [secured, setSecured] = useState(false);

  useEffect(() => {
    if (!securityValidation(params, paramValidation)) {
      navigate('/not-available');
    }
    else {
      setSecured(true);
    }
    // eslint-disable-next-line
  }, [params]);

  return secured ? component : null;
};

export default Security;

const securityValidation = (params, paramValidation) => {
  if (!paramValidation) {
    return true;
  }

  for (const param of paramValidation) {
    const { name, type } = param;
    if (!params[name] ||
      (type === "uuid" && !isValidGuid(params[name])) ||
      (type === "number" && !isValidNumber(params[name])) ||
      (type === "letter" && params[name].length !== 1)
    ) {
      return false;
    }
  }

  return true;
};
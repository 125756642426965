const iconBase = {
  height: 24,
  width: 24,
  borderRadius: "50%",
  padding: 3,
  "& svg": {
    display: "block",
    color: "#FFF",
    width: "100%",
    height: "100%"
  }
}

export const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    "& .MuiStepLabel-vertical": {
      padding: 0
    },
    "& .MuiStepConnector-line": {
      minHeight: 12
    }
  },
  cutoff: {
    margin: 0,
    fontSize: 12,
  },
  disabledText: {
    color: "#AAAAAA !important"
  },
  icon: {
    ...iconBase,
    backgroundColor: "#AAAAAA",
  },
  completedIcon: {
    ...iconBase,
    backgroundColor: theme.palette.primary.main,
  },
  pendingIcon: {
    ...iconBase,
    border: "2px solid " + theme.palette.primary.main,
    backgroundColor: "#FFF",
    "& svg": {
      color: theme.palette.primary.main,
      display: "block",
      width: "100%",
      height: "100%"
    }
  },
  warnIcon: {
    ...iconBase,
    backgroundColor: theme.palette.error.main,
  },
  warnText: {
    color: theme.palette.error.main
  },
  navigateBtn: {
    marginTop: theme.spacing(2)
  }
});
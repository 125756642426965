import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Typography } from "@mui/material";

import { styles } from "./ConfirmRemoveModal.styles";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";

function ConfirmRemoveModal({ classes, open, setOpen, onRemoveConfirm, selectedRole }) {
  const { t } = useTranslation();

  const onRemoveConfirmHandler = () => {
    onRemoveConfirm && onRemoveConfirm({ roleID: selectedRole?.id })
  }

  return (
    <CustomModal open={open} setOpen={setOpen} size="medium" height={190} title={t("roles.ConfirmRemoveModal.modal.title")}>
      <Grid container spacing={2} direction="column" style={{ height: "100%" }} justifyContent="space-between">
        <Grid item>
          <Typography>{t("roles.ConfirmRemoveModal.modal.message")}</Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" fullWidth onClick={onRemoveConfirmHandler}>{t("roles.ConfirmRemoveModal.modal.confirmBtn")}</Button>
        </Grid>
      </Grid>
    </CustomModal>
  );
}

export default withStyles(ConfirmRemoveModal, styles);
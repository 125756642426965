import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Badge, IconButton, Tooltip } from "@mui/material";
import CommentIcon from '@mui/icons-material/Comment';

import { styles } from "./ProductComments.styles";
import { clearProductDetailComments, productGetComments, selectProductDetailComment, selectProductDetailCommentsFilter, selectProductDetailCommentsTotalRows } from "../../productsSlice";
import ProductCommentsModal from "./ProductCommentsModal/ProductCommentsModal";

function ProductComments({ classes, productId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filter = useSelector(selectProductDetailCommentsFilter);
  const comments = useSelector(selectProductDetailComment);
  const totalRows = useSelector(selectProductDetailCommentsTotalRows);
  const hasComments = comments?.length > 0;
  const [modalOpen, setModalOpen] = useState(false);


  useEffect(() => {
    dispatch(clearProductDetailComments())
    if (productId) {
      dispatch(productGetComments(productId, filter, true));
    }
    // eslint-disable-next-line
  }, [productId])

  const onCommentClickHandler = () => {
    setModalOpen(true);
  }

  return (
    <div>
      <Grid container className={classes.root} alignItems="center">
        <Grid item>
          {
            hasComments ?
              <Tooltip title={t("products.ProductDetails.comments.commentsTooltip")}>
                <IconButton onClick={onCommentClickHandler}>
                  <Badge badgeContent={hasComments ? totalRows : 0} color="primary">
                    <CommentIcon color="action" style={{ display: "block", cursor: "pointer" }} />
                  </Badge>
                </IconButton>
              </Tooltip> :
              <Tooltip title={t("products.ProductDetails.comments.noCommentsTooltip")}>
                <CommentIcon color="disabled" style={{ display: "block", cursor: "default" }} />
              </Tooltip>
          }
        </Grid>
      </Grid>
      <ProductCommentsModal open={modalOpen} setOpen={setModalOpen} comments={comments} />
    </div>
  );
}

export default withStyles(ProductComments, styles);
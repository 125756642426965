import i18next from "i18next";

export const getLanguageTypeOptions = (t, promoter) => {
  return (promoter?.getConfig("admin", "language") || []).map((item) => {
    return {
      value: item,
      text: t("common.Enums.language." + item),
    };
  });
};

export const getCurrencyTypeOptions = (t) => {
  return ["EUR"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.currency." + item),
    };
  });
};

export const getEntityTypeOptions = (t) => {
  return ["P", "S", "W", "LO", "ST"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.entityType." + item),
    };
  });
};

export const getRoleTypeOptions = (t) => {
  return ["F", "W", "R", "N", "C"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.roleType." + item),
    };
  });
};

export const getOperationTypeOptions = (t) => {
  return ["TF", "CD"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.operationType." + item),
    };
  });
};

export const getTrafficTypeOptions = (t) => {
  return ["S", "O"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.trafficType." + item),
    };
  });
};

export const getTransportOptions = (t) => {
  return ["BASE_UNIT_OR_EACH", "CASE", "DISPLAY_SHIPPER", "MIXED_MODULE", "PACK_OR_INNER_PACK", "PALLET"].map(
    (item) => {
      return {
        value: item,
        text: t("common.Enums.transport." + item),
      };
    }
  );
};

export const getWeekDayOptions = (t) => {
  return [1, 2, 3, 4, 5, 6, 7].map((item) => {
    return {
      value: item,
      text: t("common.Enums.weekday." + item),
    };
  });
};

export const getEntityPermissionOptions = (t) => {
  return ["EC", "AL", "NA"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.entityPermission." + item),
    };
  });
};

export const getPriceTypeOptions = (t) => {
  return ["S", "G", "C", "CMP", "O"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.priceType." + item),
    };
  });
};

export const getCutOffTypeOptions = (t) => {
  return ["ANY", "MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"].map((item) => {
    return {
      value: item,
      text: t("entities.CutOffs.dateType." + item),
    };
  });
};

export const getSectorOptions = (t, refData) => {
  return (refData?.sector || []).map((item) => {
    return {
      value: item,
      text: t("common.Enums.sector." + item),
    };
  });
};

export const getListProductColumnsOptions = (t) => {
  return ["gln", "owner", "brand", "available"].map((item) => {
    return {
      value: item,
      text: item,
    };
  });
};

export const getListProductFieldsOptions = (t) => {
  return ["available", "contactPoint", "supplierInternalCode", "promoterInternalCode", "promoterDescription", "transactionUnit", "conversionFactor", "productTemplate", "addressId", "isDispatchUnit", "hasBatchNumber", "hasExpirationDate", "hasVariableWeight", "hasSerialNumber"].map((item) => {
    return {
      value: item,
      text: item,
    };
  });
};
export const getListEntityColumnsOptions = (t) => {
  return ["type", "taxNumber","gln", "promoterInternalCode", "status"].map((item) => {
    return {
      value: item,
      text: item,
    };
  });
};

export const getTaxGroupOptions = (t, refData, removeVat = true) => {
  let options = refData?.taxType || [];
  if (removeVat) {
    options = options.filter(item => item !== "VAT");
  }
  return options.map((item) => {
    return {
      value: item,
      text: t("common.Enums.taxGroup." + item),
    };
  });
};

export const getLanguages = (t) => {
  return ["en", "pt", "es"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.language." + item),
    };
  });
}

export const getServiceTypes = (t) => {
  return [
    { value: null, text: t("promoter.PromoterConfigs.serviceType.clear") },
    { value: 1, text: t("promoter.PromoterConfigs.serviceType.sogenave") },
  ]
}

export const getCustomFormsOptions = (customForms) => {
  return customForms.map(({ key, name }) => {
    let altLang = Object.keys(name).filter((k) => k !== i18next.language)?.[0];
    return { value: key, text: name[i18next.language] || (altLang && name[altLang]) };
  })
}

export const getResourceTypeOptions = (t, refData) => {
  return (refData?.resourceTypes || []).map((item) => {
    return {
      value: item,
      text: t("common.Enums.resourceTypes." + item),
    };
  });
};

export const getPlaceTypeOptions = (t, refData) => {
  return (refData?.placeTypes || []).map((item) => {
    return {
      value: item.type,
      text: t("common.Enums.placeTypes." + item.type),
    };
  });
};
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Grid, Paper } from "@mui/material";

import { styles } from "./RefDataTaxsForm.styles";
import { formFields, formSchema } from "./RefDataTaxsForm.schema";
import { Form, FormFields } from "../../../common/forms";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { promoterCreateVat, promoterListLocations, promoterListVat, promoterUpdateVat, selectLocationsList, selectVatList } from "../promoterSlice";
import { useFirstLoad, useNavigate, usePromoter } from "../../../common/hooks";
import { getLocationsOptions, getVatOptions } from "./RefDataTaxsForm.options";
import { useParams } from "react-router-dom";
import Page from "../../../common/displays/Page/Page";
import Permission from "../../../app/components/Permission";

function RefDataTaxsForm({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const promoter = usePromoter();
  const locations = useSelector(selectLocationsList);
  const vats = useSelector(selectVatList);

  const { id } = useParams();

  useFirstLoad(() => {
    dispatch(promoterListVat({
      sortField: "CODE",
      sortType: "ASC",
      locations: null,
    }));
    if (!locations || locations.length === 0) {
      dispatch(promoterListLocations());
    }
  });

  const vatOptions = useMemo(() => {
    return getVatOptions(t, promoter);
    // eslint-disable-next-line
  }, [promoter]);

  const locationsOptions = useMemo(() => {
    return getLocationsOptions(locations);
    // eslint-disable-next-line
  }, [locations]);

  const selectedVat = useMemo(() => {
    if (vats && id) {
      return vats.find(a => a.id === id);
    }
    return {}
  }, [vats, id])

  const vatAssignedLocations = useMemo(() => {
    return processVats(vats, selectedVat);
  }, [vats, selectedVat])

  const onSubmit = (values) => {
    if (id) {
      dispatch(promoterUpdateVat({ id, ...values }));
    }
    else {
      dispatch(promoterCreateVat(values));
    }
  };

  return (
    <div>
      <Form
        schema={formSchema(t)}
        initialValues={selectedVat}
        onSubmit={onSubmit}
        render={({ submitting, values }) => {
          return (
            <Page
              permission={id ? "promoter.actions.vat.update" : "promoter.actions.vat.create"}
              header={<PageTitle
                title={id ? t("promoter.RefDataTaxsForm.form.titleUpdate") : t("promoter.RefDataTaxsForm.form.title")}
                onBack={() => navigate("/promoter/configs/vats")}
                actions={
                  <Grid container direction="row" spacing={2}>
                    <Permission code={id ? "promoter.actions.vat.update" : "promoter.actions.vat.create"}>
                      <Grid item>
                        <Button variant="contained" color="primary" type="submit" size="large" disabled={submitting}>
                          {t("promoter.RefDataTaxsForm.form.submitBtn")}
                        </Button>
                      </Grid>
                    </Permission>
                  </Grid>
                }
              />}>
              <Paper className={classes.paper}>
                <FormFields fields={formFields({ t, isEdit: Boolean(id), values, vatOptions, locationsOptions, vatAssignedLocations })} />
              </Paper>
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(RefDataTaxsForm, styles)


function processVats(vats, selectedVat) {
  const codeToLocations = {};
  vats.forEach((vat) => {
    if (vat.active && vat.id !== selectedVat?.id) {
      if (!codeToLocations.hasOwnProperty(vat.code)) {
        codeToLocations[vat.code] = [];
      }
      vat.locations.forEach((location) => {
        if (location !== null) {
          codeToLocations[vat.code].push(location);
        }
      });
    }
  });
  return codeToLocations;
}
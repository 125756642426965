import React from "react";
import {Route, Routes} from "react-router-dom";
import PromoterConfigs from "./PromoterConfigs/PromoterConfigs";
import AuditRetention from "./AuditRetention/AuditRetention";
import RefDataTaxsList from "./RefDataTaxsList/RefDataTaxsList";
import RefDataTaxsForm from "./RefDataTaxsForm/RefDataTaxsForm";
import LocationsList from "./LocationsList/LocationsList";
import LocationsForm from "./LocationsForm/LocationsForm";
import Security from "../../app/components/Security/Security";
import TimerList from "./TimerList/TimerList";
import TimerForm from "./TimerForm/TimerForm";
import TimerDetails from "./TimerDetails/TimerDetails";
import SystemStatus from "./SystemStatus/SystemStatus";

function PromoterRouting() {
  return (
    <Routes>
      <Route path="/configs" element={<PromoterConfigs />} />
      <Route path="/configs/retention" element={<AuditRetention />} />
      <Route path="/configs/vats" element={<RefDataTaxsList />} />
      <Route path="/configs/vats/add" element={<RefDataTaxsForm />} />
      <Route path="/configs/vats/edit/:id" element={
        <Security component={<RefDataTaxsForm />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/configs/locations" element={<LocationsList />} />
      <Route path="/configs/locations/add" element={<LocationsForm />} />
      <Route path="/configs/locations/edit/:id" element={
        <Security component={<LocationsForm />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/timer" element={<TimerList />} />
      <Route path="/timer/:id" element={
          <Security component={<TimerDetails />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/timer/add" element={<TimerForm />} />
      <Route path="/timer/edit/:id" element={
            <Security component={<TimerForm />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/status" element={<SystemStatus />} />
    </Routes>
  );
}

export default PromoterRouting;

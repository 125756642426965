import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button, Chip, Stack } from "@mui/material";

import { styles } from "./ProductAvaibleState.styles";
import CustomModal from "../../../../../common/displays/CustomModal/CustomModal";
import { formFields, formSchema } from "./ProductAvaibleState.schema";
import { Form, FormFields } from "../../../../../common/forms";
import { productUpdate } from "../../../productsSlice";
import { dateFormat } from "../../../../../common/utils";
import Permission from "../../../../../app/components/Permission";
import { useUser } from "../../../../../common/hooks";

function ProductAvaibleState({ classes, product }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();
  const [modal, setModal] = useState(false);
  const { available, scheduleAvailable, scheduleAvailableDate, replacementInfo, replacementProduct } = product?.baseInfo || {};

  const onSubmitHandler = (values) => {
    if (!values.scheduleAvailableDate) {
      values.available = values.scheduleAvailable;
      values.scheduleAvailable = null;
      values.scheduleAvailableDate = null;
    }
    values.replacementProduct = values.replacementProduct?.[0]?.productId;
    dispatch(productUpdate({ ...product, baseInfo: { ...product.baseInfo, ...values } }));
    setModal(false);
  }

  return (
    <div className={classes.root}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end" style={{ cursor: "pointer" }} onClick={() => setModal(true)} >
        <Chip label={t("common.Enums.productAvailable." + (available || "N"))} color={available === "O" ? "primary" : available === "S" ? "warning" : "default"} />
        {scheduleAvailable && (
          <Stack spacing={1} alignItems="center">
            <Typography variant="caption" style={{ margin: 0, whiteSpace: "nowrap" }}>{t("products.ProductAvaibleState.label.scheduleAvailable", { scheduleAvailable: t("common.Enums.productAvailable." + scheduleAvailable) })}</Typography>
            <Typography variant="caption" style={{ margin: 0 }}>{dateFormat(scheduleAvailableDate)}</Typography>
          </Stack>
        )}
      </Stack>
      <CustomModal open={modal} setOpen={setModal} size="medium" height={410} title={t("products.ProductAvaibleState.modal.title")}>
        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t })}
          keepDirtyOnReinitialize
          initialValues={{ scheduleAvailableDate: scheduleAvailableDate || null, scheduleAvailable: scheduleAvailable || available, replacementProduct: replacementProduct ? [{ ...replacementInfo, productId: replacementProduct }] : undefined }}
          render={({ values }) => {
            return (
              <Grid container spacing={2} direction="column" justifyContent="space-between" height={410 - 65}>
                <Grid item>
                  <FormFields fields={formFields({ t, values, productId: product.id, disabled: !user.hasPermission("products.actions.product.update") })} />
                </Grid>
                <Grid item>
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        type="button"
                        size="medium"
                        onClick={(e) => {
                          e.stopPropagation();
                          setModal(false);
                        }}
                      >
                        {t("products.ProductAvaibleState.modal.btnCancel")}
                      </Button>
                    </Grid>
                    <Permission code="products.actions.product.update">
                      <Grid item>
                        <Button
                          variant="contained"
                          type="submit"
                          size="medium"
                          disabled={available === values.scheduleAvailable || !user.hasPermission("products.actions.product.update")}
                        >
                          {t("products.ProductAvaibleState.modal.btnSubmit")}
                        </Button>
                      </Grid>
                    </Permission>
                  </Grid>
                </Grid>
              </Grid>
            )
          }}
        />
      </CustomModal>
    </div>
  );
}

export default withStyles(ProductAvaibleState, styles);
export function listColumns(t, classes) {
  return [
    {
      field: "sellerName",
      headerName: t("business.reports.WeeklyUnitsAccepted.columns_sellerName"),
      align: "left",
      headerAlign: "left",
    },{
      field: "buyerName",
      headerName: t("business.reports.WeeklyUnitsAccepted.columns_buyerName"),
      align: "left",
      headerAlign: "left",
    },{
      field: "deliveryPlace",
      headerName: t("business.reports.WeeklyUnitsAccepted.columns_deliveryPlace"),
      align: "left",
      headerAlign: "left",
    },{
      field: "totalTransportUnits",
      headerName: t("business.reports.WeeklyUnitsAccepted.columns_totalTransportUnits"),
      align: "left",
      headerAlign: "left",
    }
  ];
}

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper } from "@mui/material";

import { styles } from "./Sustainability.styles";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";

function Sustainability({ classes, sustainability }) {
  const { t } = useTranslation();

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h3" align="left" component="h2" className={classes.noMargin}>
            {t("products.ProductCreate.form.sustainabilityTitle")}
          </Typography>
        </Grid>
        {sustainability.map(({
          packagingType,
          packagingMaterialComponentType,
          packagingMaterialType,
          otherPackagingMaterials,
          packagingColor,
          opacity,
          recyclingDestination,
          packagingWeight,
          quantity,
          plasticDensity
        }) => (

          <Grid item key={packagingType}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>{t("common.Enums.packagingType." + packagingType)}</Typography>
              </Grid>
              <Grid item xs={2}>
                <LabelValue
                  label={t("products.ProductDetails.details.packagingMaterialComponentType")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {packagingMaterialComponentType ? t("common.Enums.packagingMaterialComponentType." + packagingMaterialComponentType) : "-"}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <LabelValue
                  label={t("products.ProductDetails.details.packagingMaterialType")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {packagingMaterialType || "-"}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <LabelValue
                  label={t("products.ProductDetails.details.otherPackagingMaterials")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {otherPackagingMaterials || "-"}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <LabelValue
                  label={t("products.ProductDetails.details.packagingColor")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {packagingColor || "-"}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <LabelValue
                  label={t("products.ProductDetails.details.opacity")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {opacity || "-"}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <LabelValue
                  label={t("products.ProductDetails.details.recyclingDestination")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {recyclingDestination || "-"}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <LabelValue
                  label={t("products.ProductDetails.details.packagingWeight")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {packagingWeight ? (
                        <Typography variant="body1" align="left" component="span">
                          {packagingWeight?.value}
                          {" " + t("common.Enums.measureUnits." + packagingWeight?.unit)}
                        </Typography>
                      ) : (
                        "N/D"
                      )}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <LabelValue
                  label={t("products.ProductDetails.details.quantity")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {quantity || "-"}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <LabelValue
                  label={t("products.ProductDetails.details.plasticDensity")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {plasticDensity || "-"}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

export default withStyles(Sustainability, styles);

// {
//   "packagingType": "PRIMARY",
//   "packagingMaterialComponentType": "1",
//   "packagingMaterialType": "2",
//   "otherPackagingMaterials": "3",
//   "packagingColor": "4",
//   "opacity": 5,
//   "recyclingDestination": "6",
//   "packagingWeight": {
//       "value": 70,
//       "valueMax": 0,
//       "category": "MASS",
//       "unit": "GRM"
//   },
//   "quantity": 8,
//   "plasticDensity": 9
// }
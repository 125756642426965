const ordersIncidents = {
  flowsMessages: {
    success: {
      ORDER_CREATE_INCIDENT: "Incident Successfully created.",
    },
    error: {
      GENERIC: "Something went wrong",
      ORDER_CREATE_INCIDENT: "Error creating incident",
      CREATE_ORDER_INCIDENT_DUPLICATED: "Incident already reported",
    },
  },
  OrdersIncidentsList: {
    header: {
      title: "Incidents",
      rowsInfo: "records",
      filters: "Filters",
    },
    columns: {
      orderId: "Order ID",
      code: "code",
      createdTime: "createdTime",
      deliveryTime: "deliveryTime",
      document: "document",
      description: "Product",
      incidentQuantity: "incident Quantity",
      orderQuantity: "order Quantity",
    },
    filter: {
      code: "code",
      searchType: "searchType",
      startDate: "startDate",
      endDate: "endDate",
      search: "search",
      requiredDate: "This date is required",
      requiredSearch: "This field is required",
    },
  },
  OrdersIncidentsForm: {
    header: {
      title: "Create Incident",
      subtitle: "Order: {{orderId}}",
    },
    form: {
      submitBtn: "Submit",
      code: "Code",
      document: "Document",
      deliveryTime: "Delivery Time",
      deliveryTimeRequired: "Delivery Time is required",
      productRequired: "At least one product should be selected",
    },
  },
};

export default ordersIncidents;

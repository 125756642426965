import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const promoterListCutOffCall = async (
  { clearCutOffList, setCutOffListFilter, appendToCutOffList, setCutOffListTotalRows },
  dispatch,
  { filter, clear = false }
) => {
  let rh = requestHelper(dispatch, "LIST_CUTOFFS");
  if (clear) {
    dispatch(clearCutOffList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setCutOffListFilter(filter));
  try {
    let list = await call({
      type: "PROMOTER",
      subType: "LIST_CUT_OFF",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    if (list && list.cutOffs && list.cutOffs.length > 0) {
      let cutoffList = list.cutOffs.map((item) => {
        return {
          ...item,
          time: "1970-01-01T" + ("00" + item.hour).slice(-2) + ":" + ("00" + item.minute).slice(-2) + ":00",
          frequency: item.dayOfWeek !== 0 ? "dayOfWeek" : "dayOfMonth",
        };
      });

      dispatch(appendToCutOffList(cutoffList));
    }
    dispatch(setCutOffListTotalRows(list.rows));
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default promoterListCutOffCall;

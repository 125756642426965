import * as Yup from "yup";
import {
  EntityAutoCompleteField,
  // SelectField
} from "../../../../common/forms";

export const filterFields = (user, promoter) => (t) => {
  return [
    {
      size: 12,
      field: (
        <EntityAutoCompleteField
          id="seller"
          label={t("orders.OrdersMissingProducts.filter.seller")}
          typeFilter={promoter?.getConfig("product", "allowedEntityTypes")}
        />
      ),
      hidden: user?.entity?.type !== "P",
    },
    // {
    //   size: 12,
    //   field: (
    //     <SelectField
    //       id="orderStatus"
    //       label={t("orders.OrdersMissingProducts.filter.orderStatus")}
    //       options={getOrderStatus(t)}
    //     />
    //   ),
    // },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    seller: Yup.string(),
    // orderStatus: Yup.string(),
  });
};

// const getOrderStatus = (t) => {
//   return ["DI", "RE", "IT", "DE"].map((item) => {
//     return {
//       value: item,
//       text: t("common.Enums.orderActionStatus." + item),
//     };
//   });
// };

export const csvFields = (t) => {
  return [
    {
      field: "description",
      header: t("business.queries.OrdersOverviewList.columns_description"),
    }, {
      field: "gtin",
      header: t("business.queries.OrdersOverviewList.columns_gtin"),
    }, {
      field: "sellerName",
      header: t("business.queries.OrdersOverviewList.columns_sellerName"),
    },
    {
      field: "buyerName",
      header: t("business.queries.OrdersOverviewList.columns_buyerName"),
    },{
      field: "orderId",
      header: t("business.queries.OrdersOverviewList.columns_orderId"),
    },{
      field: "lineId",
      header: t("business.queries.OrdersOverviewList.columns_lineId"),
    },{
      field: "ordered",
      header: t("business.queries.OrdersOverviewList.columns_ordered"),
    },{
      field: "orderDone",
      header: t("business.queries.OrdersOverviewList.columns_orderDone"),

    },{
      field: "accepted",
      header: t("business.queries.OrdersOverviewList.columns_accepted"),

    }, {
      field: "acceptDone",
      header: t("business.queries.OrdersOverviewList.columns_acceptDone"),
    },
    {
      field: "dispatched",
      header: t("business.queries.OrdersOverviewList.columns_dispatched"),
    },{
      field: "dispatchDone",
      header: t("business.queries.OrdersOverviewList.columns_dispatchDone"),
    },{
      field: "reception",
      header: t("business.queries.OrdersOverviewList.columns_reception"),
    },{
      field: "receptionDone",
      header: t("business.queries.OrdersOverviewList.columns_receptionDone"),
    } ,{
      field: "inTransit",
      header: t("business.queries.OrdersOverviewList.columns_inTransit"),
    },{
      field: "inTransitDone",
      header: t("business.queries.OrdersOverviewList.columns_inTransitDone"),
    },{
      field: "delivered",
      header: t("business.queries.OrdersOverviewList.columns_delivered"),
    },{
      field: "deliveryDone",
      header: t("business.queries.OrdersOverviewList.columns_deliveryDone"),
    }

  ];
}

import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./DynamicForm.styles";

import { Form, FormFields } from "../../../common/forms"
import { extractFormFields, extractFormSchema } from "./_auxiliars";
import { Button, Stack } from "@mui/material";
import ScrollBox from "../../displays/ScrollBox/ScrollBox";
import { useTranslation } from "react-i18next";

function DynamicForm({ classes, formObject, extras, submitLabel, height = 400, onSubmit, initialValues }) {
  const { t } = useTranslation();
  const [formSchema, setFormSchema] = useState();
  const [formFields, setFormFields] = useState();

  useEffect(() => {
    setFormSchema(extractFormSchema(formObject, t));
    setFormFields(extractFormFields(formObject, classes));
    // eslint-disable-next-line
  }, [formObject])

  const onSubmitHandler = async (values) => {
    onSubmit && onSubmit(values);
  };

  return formSchema && formFields ? (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmitHandler}
        schema={formSchema}
        initialValues={initialValues}
        render={() => {
          return (
            <div>
              <Stack spacing={2}>
                {extras}
                <ScrollBox style={{
                  height,
                  paddingRight: 8
                }}>
                  <FormFields fields={formFields} />
                </ScrollBox>
                <div>
                <Button variant="contained" type="submit" style={{ float: "right" }}>
                  {submitLabel}
                </Button>

                </div>

              </Stack>
            </div>
          );
        }}
      />

    </div>
  ) : null;
}

export default withStyles(DynamicForm, styles);
import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography } from "@mui/material";

import { styles } from "./DiffSpan.styles";

function DiffSpan({ classes, diff, field }) {
  return (
    <div className={classes.root}>
      {(diff || []).map((part, index) =>
        part.added ? (
          <Typography key={field + "_" + index} variant="body1" component="span" className={classes.added}>
            {part.value}
          </Typography>
        ) : part.removed ? (
          <Typography key={field + "_" + index} variant="body1" component="span" className={classes.removed}>
            {part.value}
          </Typography>
        ) : (
          <Typography key={field + "_" + index} variant="body1" component="span">
            {part.value}
          </Typography>
        )
      )}
    </div>
  );
}

export default withStyles(DiffSpan, styles);
import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button, IconButton, Stack, Tooltip } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { styles } from "./ProductTaxesField.styles";
import { FieldArray } from "react-final-form-arrays";
import { SelectInput } from "../../../../common/inputs";
import { NumberWithSelectField } from "../../../../common/forms";
import { usePromoter } from "../../../../common/hooks";
import { useMemo } from "react";

function ProductTaxesField({ classes, id, label, disabled, required, note, selectedTaxes }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const [otherTax, setOtherTax] = useState("");

  const onAddNewTaxHandler = (fields, otherTax) => {
    if (otherTax) {
      fields.push({
        code: otherTax,
        value: 0,
        type: "P"
      });
      setOtherTax("");
    }
  }

  const typeOptions = [{ value: "P", text: "%" }, { value: "A", text: "€" }]

  const otherTaxes = useMemo(() => {
    return (promoter?.getConfig("tax", "allowTaxes") || [])
      .filter(taxType => taxType !== "VAT")
      .filter(taxType => !(selectedTaxes.map(item => item.code) || []).includes(taxType))
      .map((taxType) => ({
        text: t('common.Enums.taxGroup.' + taxType),
        value: taxType
      }))
    // eslint-disable-next-line
  }, [promoter])

  return (
    <div className={classes.root}>
      <FieldArray name={id}>
        {({ fields }) => {
          return (
            <Grid container spacing={1}>
              {fields.map((field, index) => (
                <Grid item xs={3} key={`${field}`}>
                  <Stack direction="row" spacing={0} alignItems="center">
                    <NumberWithSelectField id={`${field}`} label={t('common.Enums.taxGroup.' + fields.value[index]?.code)} textKey="value" selectKey="type" options={typeOptions} decimalScale={2} />
                    <IconButton style={{ marginTop: 15 }} size="small" onClick={() => fields.remove(index)}><HighlightOffIcon fontSize="inherit" /></IconButton>
                  </Stack>
                </Grid>
              ))}
              <Grid item xs={12}>
              </Grid>
              {otherTaxes.length > 0 &&
                <Grid item xs={6}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-end">
                        <Typography
                          style={{ marginBottom: 8, marginTop: 8 }}
                          component="span"
                          variant="caption"
                          htmlFor={id}
                          color="textPrimary"
                          className={disabled ? classes.disabledLabel : ""}
                        >
                          {label}
                          {required && <span> *</span>}
                        </Typography>
                        {note && <Tooltip title={note} placement="left"><InfoOutlinedIcon color="primary" className={classes.noteIcon} /></Tooltip>}
                      </Stack>
                    </Grid>
                    <Grid item >
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={6}>
                          <SelectInput options={otherTaxes} value={otherTax} onChange={(v) => setOtherTax(v)} />
                        </Grid>
                        <Grid item xs={6}>
                          <Button onClick={() => onAddNewTaxHandler(fields, otherTax)}>{t("products.ProductCreate.form.addOtherTax")}</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          )
        }}
      </FieldArray>
    </div>
  );
}

export default withStyles(ProductTaxesField, styles);
import * as Yup from "yup";

import { SelectField, TextField, SwitchField } from "../../../common/forms";
import { getSectorOptions, getUserRoleOptions } from "./UserForm.options";

export function formSchema({ t, isEdit }) {
  return Yup.object().shape({
    firstName: Yup.string().required(t("entities.CreateEntity.form.requiredUserFirstName")),
    lastName: Yup.string().required(t("entities.CreateEntity.form.requiredUserLastName")),
    roleType: Yup.string().required(t("entities.CreateEntity.form.requiredUserRole")),
    phoneNumber: Yup.string().phone(t("entities.CreateEntity.form.invalidPhone")),
    email: isEdit ? Yup.string() : Yup.string()
      .emailValidator(t("entities.CreateEntity.form.invalidUserEmail"))
      .required(t("entities.CreateEntity.form.requiredUserEmail")),
  });
}

export function formFields({ t, isLastFullAccessUser, promoter, isEdit, user, values, roleList }) {
  return [
    {
      size: 4,
      field: <TextField id={`firstName`} type="text" label={t("entities.CreateEntity.form.userFirstName")} required disabled={isEdit && values?.roleType !== "N"} />,
    },
    {
      size: 4,
      field: <TextField id={`lastName`} type="text" label={t("entities.CreateEntity.form.userLastName")} required disabled={isEdit && values?.roleType !== "N"} />,
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`roleType`}
          label={t("entities.CreateEntity.form.userRole")}
          options={getUserRoleOptions(t, promoter, user)}
          required
          disabled={isLastFullAccessUser}
          note={roleList?.length > 0 ? t("entities.CreateEntity.form.userRoleSpecific") + " " + roleList.map(item => item.designation).join(', ') : undefined}
        />
      ),
    },
    {
      size: 4,
      field: <TextField id={`email`} type="email" label={t("entities.CreateEntity.form.userEmail")} required disabled={isEdit} />,
    },
    {
      size: 4,
      field: <TextField id={`phoneNumber`} type="text" label={t("entities.CreateEntity.form.userPhone")} />,
    },
    {
      size: 4,
      field: (
        <SelectField
          id="sector"
          label={t("entities.CreateEntity.form.sector")}
          options={getSectorOptions(t, promoter?.refData)}
        />
      ),
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: (
        <SwitchField
          id="notify"
          label={t("entities.CreateEntity.form.notifications")}
          required
          size="medium"
        />
      ),
    },
    {
      size: 4,
      field: (
        <SwitchField
          id="sendDoc"
          label={t("entities.CreateEntity.form.documentation")}
          required
          size="medium"
        />
      ),
    },
  ];
}

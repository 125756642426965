import DoneIcon from '@mui/icons-material/Done';
import DateTime from '../../../../common/displays/DateTime/DateTime';

export function listColumns(t, classes) {
  return [
    {
      field: "date",
      headerName: t("business.queries.MissingShipmentsList.columns_date"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return <DateTime date={v.date} />;
      },
    },
    {
      field: "operatorName",
      headerName: t("business.queries.MissingShipmentsList.columns_operatorName"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "sellerName",
      headerName: t("business.queries.MissingShipmentsList.columns_sellerName"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "orderSellerId",
      headerName: t("business.queries.MissingShipmentsList.columns_orderSellerId"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "dispatchId",
      headerName: t("business.queries.MissingShipmentsList.columns_dispatchId"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "receptionDone",
      headerName: t("business.queries.MissingShipmentsList.columns_receptionDone"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v.receptionDone ? (<DoneIcon />) : ("")
      }
    },
    {
      field: "dispatchDone",
      headerName: t("business.queries.MissingShipmentsList.columns_dispatchDone"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v.dispatchDone ? (<DoneIcon />) : ("")
      }
    },
    {
      field: "deliveryDone",
      headerName: t("business.queries.MissingShipmentsList.columns_deliveryDone"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v.deliveryDone ? (<DoneIcon />) : ("")
      }
    },


  ];
}

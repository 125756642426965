import React from "react";
import { withStyles } from "tss-react/mui";
import { Grid, Typography } from "@mui/material";

import { styles } from "./StepDate.styles";
import { dateTimeFormat } from "../../../../../../common/utils";

function StepDate({ classes, date }) {

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={1} alignItems="center">
        <Grid item>
          <Typography className={classes.datetime} component="span" variant="caption" color="textPrimary">
            {dateTimeFormat(date, "DD MMM")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className={classes.datetime}
            component="span"
            variant="subtitle1"
            color="textSecondary"
          >
            {dateTimeFormat(date, "HH:mm")}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(StepDate, styles);
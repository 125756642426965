import { CheckListField, SelectField, TextField } from "../../../../common/forms";

export const generalFields = ({ t, user, formOptions }) => {
  return [
    {
      size: 6,
      field: <TextField id="functional.general.portalName" type="text" label={t("promoter.PromoterConfigs.form.portalName")} />,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: (
        <SelectField
          id="functional.general.defaultCurrency"
          label={t("promoter.PromoterConfigs.form.defaultCurrency")}
          options={formOptions.currencyType}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <SelectField
          id="functional.general.defaultLanguage"
          label={t("promoter.PromoterConfigs.form.defaultLanguage")}
          options={formOptions.languageType}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: (
        <CheckListField
          id="technical.general.entityTypes"
          options={formOptions.entityType}
          label={t("promoter.PromoterConfigs.form.entityTypes")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
    {
      size: 4,
      field: (
        <CheckListField
          id="technical.general.roleTypes"
          options={formOptions.roleType}
          label={t("promoter.PromoterConfigs.form.roleTypes")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: (
        <CheckListField
          id="technical.general.placeTypes"
          options={formOptions.placeType}
          label={t("promoter.PromoterConfigs.form.placeTypes")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
    {
      size: 4,
      field: (
        <CheckListField
          id="technical.general.resourceTypes"
          options={formOptions.resourceType}
          label={t("promoter.PromoterConfigs.form.resourceTypes")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
  ];
};

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./DocumentsList.styles";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { useDispatch, useSelector } from "react-redux";

import DocumentsDataGrid from "./DocumentsDataGrid/DocumentsDataGrid";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { Typography } from "@mui/material";
import { documentListPdf, selectPdfList } from "../documentSlice.calls";
import Page from "../../../common/displays/Page/Page";

function DocumentsList({ classes }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(selectPdfList);

  useFirstLoad(() => {
    dispatch(documentListPdf());
  });

  const onRowSelectedHandler = (row) => {
    navigate("/documents/" + row.id);
  };

  return (
    <Page
      permission="promoter.actions.document.list"
      header={<PageTitle title={t("documents.list.header.title")} />}>
      <Typography component="span" variant="caption" htmlFor="entityAutocomplete" color="textPrimary">
        {t("documents.list.header.table")}
      </Typography>
      <DocumentsDataGrid data={data} totalRows={data?.length} onRowSelected={onRowSelectedHandler} />
    </Page>
  );
}

export default withStyles(DocumentsList, styles);

import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid, Table, TableBody, TableRow, TableCell, Button, Select, MenuItem } from "@mui/material";

import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";

import { styles } from "./TransportTableField.styles";
import MinmaxInput from "./MinmaxInput";
import CostInput from "./CostInput";
import { usePromoter } from "../../../../common/hooks";
import MetaError from "../../../../common/forms/MetaError";

const getDataForHeaders = (states, values) => {
  let r;
  states.forEach((state) => {
    if (values[state] && (!r || r.length < values[state].length)) {
      r = values[state];
    }
  });
  return r;
};

function TransportTableField({ classes, id, label, adornment, minDiff, states }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  let stateOptions = (promoter?.refData?.state || []).find(item => item?.countryCode === "PT")?.states || [];
  const [selectedState, setSelectedState] = useState("");

  const minmaxHandler = (v, input) => {
    input.onChange && input.onChange(v);
  };

  const costHandler = (v, input) => {
    input.onChange && input.onChange(v);
  };

  const addStateHandler = (input) => {
    if (selectedState) {
      let nValue = JSON.parse(JSON.stringify(input.value));
      if ((Object.keys(nValue) || []).length > 0) {
        nValue[selectedState] = nValue[Object.keys(nValue)[0]];
        nValue[selectedState] = nValue[selectedState].map((item) => {
          return { ...item, cost: 0 };
        });
      } else {
        nValue[selectedState] = [];
        nValue[selectedState].push({
          min: minDiff,
          max: 10,
          cost: 0,
        });
      }
      setSelectedState("");
      input.onChange && input.onChange(nValue);
    }
  };

  const addMinMaxHandler = (states, input, headers) => {
    let nValue = JSON.parse(JSON.stringify(input.value));
    states.forEach((state) => {
      let nMin = parseFloat(headers[headers.length - 1].max) + minDiff;
      let nMax = parseFloat(headers[headers.length - 1].max) + 10;
      if (!nValue[state]) {
        nValue[state] = [...headers].map((item) => {
          return { ...item, cost: 0 };
        });
      }
      nValue[state].push({
        min: nMin,
        max: nMax,
        cost: 0,
      });
    });
    input.onChange && input.onChange(nValue);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => {
          const firstState = states[0];

          stateOptions = stateOptions.filter((item) => states.indexOf(item) === -1);
          let minmaxHeaders = getDataForHeaders(states, input.value); // input.value[firstState];
          if (!minmaxHeaders) {
            minmaxHeaders = firstState
              ? [
                {
                  min: minDiff,
                  max: 10,
                  cost: 0,
                },
              ]
              : [];
          }
          return (
            <div>
              {input.value && (
                <Table size="small" className={classes.table}>
                  <TableBody>
                    <TableRow className={classes.transportHeader}>
                      <TableCell>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                          <Grid item>
                            <Typography component="span" variant="caption" htmlFor={id} color="textPrimary">
                              {label}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Grid container direction="column" justifyContent="center" alignItems="center">
                              <Grid item>
                                <Typography component="span" variant="caption" color="textSecondary">
                                  {t("operator.CostsForm.transportTable.from")}
                                </Typography>
                              </Grid>

                              <Grid item>
                                <Typography component="span" variant="caption" color="textSecondary">
                                  {t("operator.CostsForm.transportTable.to")}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </TableCell>
                      {minmaxHeaders.map((item, i) => (
                        <TableCell key={"minmax_" + i} align="center">
                          <MinmaxInput
                            classes={classes}
                            index={i}
                            value={item}
                            json={input.value}
                            onChange={(v) => minmaxHandler(v, input)}
                            adornment={adornment}
                            minDiff={minDiff}
                          />
                        </TableCell>
                      ))}
                      <TableCell>
                        {(minmaxHeaders || []).length > 0 && (
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            type="button"
                            className={classes.smallButton}
                            onClick={() => addMinMaxHandler(states, input, minmaxHeaders)}
                          >
                            +
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                    {(states || []).map((item) => {
                      let itemValues = input.value[item];
                      if (!itemValues) {
                        if (input.value[firstState]) {
                          itemValues = input.value[firstState].map((item) => {
                            return { ...item, cost: 0 };
                          });
                        } else {
                          itemValues = [
                            {
                              min: minDiff,
                              max: 10,
                              cost: 0,
                            },
                          ];
                        }
                      }
                      return (
                        <TableRow key={item}>
                          <TableCell>
                            <b>{item}</b>
                          </TableCell>
                          {itemValues.map((itemValue, i) => (
                            <TableCell key={"cost_" + i} align="center">
                              <CostInput
                                classes={classes}
                                json={input.value}
                                value={itemValue}
                                state={item}
                                index={i}
                                onChange={(v) => costHandler(v, input)}
                                adornment="€"
                                minmaxHeaders={minmaxHeaders}
                              />
                            </TableCell>
                          ))}
                          <TableCell></TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell colSpan={(input.value[firstState] || []).length + 2}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <Select
                              className={classes.select}
                              margin="none"
                              variant="outlined"
                              size="small"
                              value={selectedState}
                              onChange={(e) => setSelectedState(e.target.value)}
                            >
                              {stateOptions.map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              type="button"
                              className={classes.smallButton}
                              onClick={() => addStateHandler(input)}
                            >
                              +
                            </Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
              <MetaError className={classes.error} meta={meta} />
            </div>
          );
        }}
      </Field>
    </div>
  );
}

export default withStyles(TransportTableField, styles)

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const businessUnitCreateStepCall = async (
  { businessUnitSteps },
  dispatch,
  {
    name,
    businessUnitId,
    lineId, 
    internal,
    places
  }
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_CREATE_STEP");
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "CREATE_STEP",
      request: {
        name,
        businessUnitId,
        lineId, 
        internal,
        places
      },
    });

    dispatch(setSuccess("businessUnits", "BUSINESS_UNIT_CREATE_STEP"));
    setTimeout(() => {
      if (result) {
        dispatch(businessUnitSteps({ businessUnitId, lineId, internal }));
      }
    }, 300);

  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitCreateStepCall;


import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid } from "@mui/material";

import { styles } from "./_base.styles";
import CardSelectRawInput from "./CardSelectRawInput";

function CardSelectInput({ classes, options, xs, selectedValue, textAlign, label, disabled, required, onChange }) {
  let alignItems = textAlign === "right" ? "flex-end" : textAlign === "left" ? "flex-start" : "center";
  return (
    <CardSelectRawInput
      options={options}
      xs={xs}
      selectedValue={selectedValue}
      textAlign={textAlign}
      label={label}
      disabled={disabled}
      required={required}
      onChange={onChange}
      cardContent={(item) => (
        <Grid
          container
          direction="column"
          alignItems={alignItems}
          justifyContent="center"
        >
          {item.icon && (
            <Grid item style={{ display: "flex" }}>
              {item.icon}
            </Grid>
          )}
          {item.text && (
            <Grid item style={{ display: "flex" }}>
              <Typography variant="caption" color="textPrimary">
                {item.text}
              </Typography>
            </Grid>
          )}
          {item.description && (
            <Grid item style={{ display: "flex" }}>
              <Typography variant="caption" color="textSecondary" styles={textAlign === "center" ? { textAlign: "center" } : {}}>
                {item.description}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    />

  );
}

export default withStyles(CardSelectInput, styles);

const planning = {
  flowsMessages: {
    success: {
      PLANNING_CREATE: "Planning created successfully!",
      PLANNING_UPDATE: "Planning updated successfully!",
      PLANNING_DELETE: "Planning deleted successfully!",
      PLANNING_DISABLE: "Planning disabled successfully!",
      PLANNING_ENABLE: "Planning enabled successfully!",
      PLANNING_FORECAST: "Planning forecast created successfully!",
    },
    error: {
      GENERIC: "Something went wrong",
    },
  },
  PlanningOverview: {
    header: {
      title: "Planning Overview"
    },
    columns: {
      year: "Year"
    }
  },
  PlanningCreateModal: {
    form: {
      modalTitle: "Create planning",
      btnSubmit: "Submit",
      btnView: "View plan",
      year: "Year",
      target: "Target",
      product: "Product",
      yearRequired: "Year is required",
      productRequired: "Product is required",
      planningDefaultLabel: "Yearly planning",
    }
  },
  PlanningList: {
    header: {
      title: "Planning",
      btnSave: "Save",
      btnForecast: "Check production forecast"
    },
    columns: {
      name: "Name",
      defaultTooltip: "Default planning",
      startDate: "Start Date",
      endDate: "End Date",
      percentage: "",
      target: "Target",
    },
    actions: {
      edit: "Edit",
      copy: "Copy",
      remove: "Remove",
    }
  },
  PlanningSeasonFormModal: {
    form: {
      modalTitle: "Create planning",
      modalTitleUpdate: "Update planning",
      btnSubmit: "Submit",
      name: "Name",
      nameRequired: "Name is required",
      startDate: "Start Date",
      startDateRequired: "Start Date is required",
      endDate: "End Date",
      endDateRequired: "End Date is required",
      target: "Target",
      startDateMin: "Start Date invalid",
      startDateMax: "Start Date needs to be lower than the End Date",
      endDateMin: "End Date needs to be higher than the Start Date",
      endDateMax: "End Date invalid",
    }
  },
  WeeklyPlanChart: {
    labels: {
      series: "Target"
    }
  },
  PlanningForecast: {
    header: {
      title: "Weekly production forecast",
      btnSave: "Save",
    },
    alert: {
      btnGenerate: "Generate new forecast",
      pendingForecast: "New forecast generated, press Save to keep changes",
      currentForecast: "Current forecast",
      noForecast: "No forecast saved"
    },
    table: {
      targetTitle: "Target",
    }
  }
};

export default planning;
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Checkbox } from "@mui/material";

import { styles } from "./SectionDiff.styles";
// import { getDeepValueWithLongKey } from "../ProductDiffFields.auxiliars";
import TextDiff from "./TextDiff/TextDiff";
import MeasureDiff from "./MeasureDiff/MeasureDiff";
import ProductDiff from "./ProductDiff/ProductDiff";
// import { compareObjects } from "../../../../../common/utils/utils";

function SectionDiff({ classes, section, fields, onCheckField, inputValue, showAllFields, onCheckSection, keysToCheck, viewMode }) {
  const { t } = useTranslation();
  const totalChecked = inputValue.filter((el) => el.indexOf(section) === 0).length;
  const totalToCheck = keysToCheck.length;

  return (
    <Grid className={classes.root}>
      <Grid item>
        <Grid
          container
          justifyContent="space-between"
          wrap="nowrap"
          className={classes.headerContainer}
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h4" className={classes.header}>
              {t("products.ProductValidationForm.section." + section)}
            </Typography>
          </Grid>
          {totalToCheck > 0 && !viewMode && (
            <Grid item style={{ width: 40 }}>
              <Checkbox
                color="primary"
                checked={totalChecked > 0 && totalChecked === totalToCheck}
                onChange={(e) => onCheckSection(e.target.checked, keysToCheck)}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        {(Object.keys(fields) || []).map(
          (field, index) =>
            fields[field]?.type !== "HIDDEN" &&
            (showAllFields || fields[field]?.hasDiff) && (
              <div key={section + "_field_" + index} className={classes.row}>
                {fields[field]?.type !== "MEASURE" && fields[field]?.type !== "PRODUCT" && (
                  <TextDiff
                    classes={classes}
                    field={field}
                    parsedField={fields[field]}
                    checked={inputValue.indexOf([section, field].join(".")) > -1}
                    onCheck={(checked, key) => onCheckField(checked, [section, key].join("."))}
                    viewMode={viewMode}
                  />
                )}
                {fields[field]?.type === "MEASURE" && (
                  <MeasureDiff
                    classes={classes}
                    field={field}
                    parsedField={fields[field]}
                    showAllFields={showAllFields}
                    inputValue={inputValue}
                    onCheck={onCheckField}
                    viewMode={viewMode}
                  />
                )}
                {fields[field]?.type === "PRODUCT" && (
                  <ProductDiff
                    classes={classes}
                    field={field}
                    parsedField={fields[field]}
                    checked={inputValue.indexOf([section, field].join(".")) > -1}
                    onCheck={(checked, key) => onCheckField(checked, [section, key].join("."))}
                    viewMode={viewMode}
                  />
                )}
              </div>
            )
        )}
      </Grid>
    </Grid>
  );
}

export default withStyles(SectionDiff, styles);

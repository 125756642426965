import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "tss-react/mui";
import { Grid, IconButton, Modal, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { styles } from "./CustomModal.styles";
import ScrollBox from "../ScrollBox/ScrollBox";

function CustomModal({ classes, open, setOpen, children, size, height, title }) {

  const onCloseHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
  }

  return (
    <Modal open={open} onClose={onCloseHandler}>
      <div className={classes.root}>
        <Paper className={size && " " + classes[size]} sx={{ height, maxHeight: "calc(100vh - 32px)" }}>
          <Grid container direction="column" wrap="nowrap">
            <Grid item className={classes.header}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                className="CustomModalHeader"
              >
                <Grid item>
                  <Typography className={classes.title} variant="caption">
                    {title}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton size="small" onClick={onCloseHandler}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.content} style={{ paddingRight: 8, height: `calc(${height - 80}px)` }}>
              <ScrollBox style={{ maxHeight: "calc(100vh - 114px)", height: `calc(${height - 80}px)` }}>
                <div style={{ paddingRight: 8 }}>
                  {children}
                </div>
              </ScrollBox>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </Modal>
  );
}

CustomModal.propTypes = {
  children: PropTypes.element.isRequired,
  size: PropTypes.oneOf(["extraextralarge", "extralarge", "large", "medium", "small"]),
};

export default withStyles(CustomModal, styles);

import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const rolesDeleteCall = async ({ rolesList }, dispatch, state, { roleID }) => {
  let rh = requestHelper(dispatch, "ROLES_DELETE");
  const filter = state.roles.rolesListFilter;
  try {
    let result = await call({
      type: "ROLES",
      subType: "DELETE",
      request: {
        roleID
      },
    });
    if (result) {
      dispatch(setSuccess("roles", "ROLES_DELETE"));
      dispatch(rolesList(filter, true));
      dispatch(setRedirect(`/roles`));
    }
  } catch (ex) {
    rh.error("roles", ex);
  }
  rh.close();
};

export default rolesDeleteCall;

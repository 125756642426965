import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";

import { styles } from "./CreateStepModal.styles";
import { formFields, formSchema } from "./CreateStepModal.schema";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";

import { Form, FormFields } from "../../../../common/forms";

function CreateStepModal({ classes, open, setOpen, onSubmit, step }) {
  const { t } = useTranslation();
  const onSubmitHandler = async (values) => {
    onSubmit && onSubmit(values);
    setOpen(false);
  };

  return (
    <CustomModal open={open} setOpen={setOpen} size="medium" height={405} title={step ? `${t("businessUnits.CreateStepModal.modal.titleEdit")} ${step.name}` : t("businessUnits.CreateStepModal.modal.title")}>
      <div className={classes.root}>
        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t })}
          initialValues={step ? { ...step, places: (step.places || []).map(item => item.placeId) } : {}}
          render={({ values }) => {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormFields fields={formFields({ t })} />
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" type="submit" fullWidth>
                    {t("businessUnits.CreateStepModal.form.btnSubmit")}
                  </Button>
                </Grid>
              </Grid>
            );
          }}
        />
      </div>
    </CustomModal>
  );
}

export default withStyles(CreateStepModal, styles);

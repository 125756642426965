import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const entityUpdateNameCall = async ({ entityList, entityGet }, dispatch, state, { entityId, name }) => {
  const filter = state.entities.listFilter;
  let rh = requestHelper(dispatch, "ENTITY/UPDATE_NAME");
  try {
    await call({
      type: "ENTITY",
      subType: "UPDATE_NAME",
      request: {
        id: entityId,
        name,
      },
    });
    setTimeout(() => {
      dispatch(setSuccess("entities", "ENTITY_UPDATE_NAME"));
      dispatch(entityList(filter, true));
      dispatch(entityGet(entityId));
    }, 300);
  } catch (ex) {
    rh.error("entities", ex);
  }
  setTimeout(() => {
    rh.close();
  }, 350);
};

export default entityUpdateNameCall;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";

import { Button, Grid, Tabs, Tab, Badge } from "@mui/material";
import styles from "./I18n.styles";

import { updateI18n, selectLoader, selectError, selectBundle, createI18nFullBundle } from "../i18nSlice";
import I18nFeature from "./I18nFeature/I18nFeature";

import defaultI18n from "../../../app/i18n/index.js";
import Permission from "../../../app/components/Permission";
import { usePromoter } from "../../../common/hooks";
import { mergeObjects } from "../../../common/utils";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { collapseI18nKeys, hasEmptyKeys } from "../i18n.auxiliars";
import Page from "../../../common/displays/Page/Page";

function I18n({ classes }) {
  const dispatch = useDispatch();
  const loader = useSelector(selectLoader);
  const error = useSelector(selectError);
  const i18nBundle = useSelector(selectBundle);
  const [tab, setTab] = useState(0);
  const [i18nValues, setI18nValues] = useState();
  const { i18n } = useTranslation();
  const promoter = usePromoter();
  const [emptyFields, setEmptyFields] = useState([]);

  useEffect(() => {
    dispatch(createI18nFullBundle(promoter));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (i18n.language && i18nBundle?.[i18n.language]) {
      let collapseI18n = collapseI18nKeys(defaultI18n, i18nBundle[i18n.language]);
      setEmptyFields(collapseI18n.filter((item) => item.current === undefined));
    }
    // eslint-disable-next-line
  }, [i18nBundle, i18n.language]);

  useEffect(() => {
    setI18nValues(i18nBundle);
    // eslint-disable-next-line
  }, [i18nBundle]);

  const clickHandler = async () => {
    dispatch(updateI18n(i18n.language, i18nValues));
  };

  const fillerHandler = () => {
    setI18nValues(mergeObjects({ [i18n.language]: defaultI18n }, i18nValues));
  };

  const handleChange = (e, i) => {
    setTab(i);
  };

  let uiTabs;
  let uiFeatures;

  const features = Object.keys(defaultI18n);
  uiTabs = (
    <Tabs orientation="vertical" variant="scrollable" value={tab} onChange={handleChange} className={classes.tabs}>
      {features.map((item) => {
        return (
          <Tab
            key={"tab_" + item}
            label={
              <Badge color="error" variant="dot" invisible={!hasEmptyKeys(emptyFields, item)}>
                {item}
              </Badge>
            }
          />
        );
      })}
    </Tabs>
  );

  uiFeatures = features.map((item, i) => {
    return (
      <I18nFeature
        key={"feature_" + item}
        classes={classes}
        tab={tab}
        index={i}
        feature={item}
        i18nValues={i18nValues}
        setI18nValues={setI18nValues}
        emptyFields={emptyFields}
      />
    );
  });


  return (
    <Page
      permission="promoter.actions.i18n.list"
      header={<PageTitle
        title="i18n translations"
        actions={
          <Grid container direction="row" spacing={2}>
            <Permission code="promoter.actions.i18n.update">
              <Grid item>
                <Button variant="contained" color="secondary" disabled={Boolean(loader)} onClick={fillerHandler}>
                  FILL empty
                </Button>
              </Grid>
            </Permission>
            <Permission code="promoter.actions.i18n.update">
              <Grid item>
                <Button variant="contained" color="primary" disabled={Boolean(loader)} onClick={clickHandler}>
                  SAVE
                </Button>
              </Grid>
            </Permission>
          </Grid>
        }
      />}>

      <Grid container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={2}>
          {uiTabs}
        </Grid>
        <Grid item xs={10}>
          {uiFeatures}
        </Grid>
      </Grid>

      {error && (
        <p>
          ERROR: {error.type} : {error?.error?.description || error?.error?.toString()}
        </p>
      )}
    </Page>

  );
}

export default withStyles(I18n, styles);

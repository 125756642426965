import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "../CutOffsDrawerDetails/CutOffsDataGrid.styles";
import { listColumns } from "./CutOffsDataGrid.columns";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import CutOffsDataGridActions from "./CutOffsDataGrid.actions";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";
import { useUser } from "../../../../common/hooks";
import { cutOffsDataGridDefaultFilter } from "../../_common/entitiesFilters";
import { filterFields, filterSchema } from "./CutOffsDataGrid.filter";

function CutOffsDataGrid({
  classes,
  data,
  totalRows,
  onCutOffSelected,
  onLoadMore,
  onFilter,
  onEnable,
  onDisable,
  onEdit,
  onForceCutOff,
  filter
}) {
  const { t } = useTranslation();
  const user = useUser();

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={listColumns(t, classes)}
        rows={data || []}
        onFilter={onFilter}
        onLoadMore={onLoadMore}
        onRowSelected={onCutOffSelected}
        total={totalRows}
        actionsComponent={<CutOffsDataGridActions onEnable={onEnable} onDisable={onDisable} onEdit={onEdit} onForceCutOff={onForceCutOff} />}
        topActions={
          <FilterForm
            style={{ marginBottom: 8 }}
            storageKey="CutOffsDataGrid"
            onStorageLoad={onFilter}
            slim
            fields={filterFields(user)}
            schema={filterSchema()}
            onFilterChange={onFilter}
            filter={filter}
            defaultFilter={cutOffsDataGridDefaultFilter}
          />
        }
      />
    </div>
  );
}

export default withStyles(CutOffsDataGrid, styles)

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./ProductPickerDataGrid.styles";
import { listColumns } from "./ProductPickerDataGrid.columns";

import { CustomDataGrid } from "../../../../../common/displays/CustomDataGrid";
import ProductSnip from "../../../../displays/ProductSnip/ProductSnip";

function ProductPickerDataGrid({
  classes,
  products,
  totalRows,
  localFilters,
  onFilter,
  onLoadMore,
  selectedProducts,
  onProductsSelectedChange,
  hideCheckbox,
  style,
  single,
  simple,
  bulkSelected,
  setBulkSelected,
  allowBulkSelect,
  allProducts,
}) {
  const { t } = useTranslation();

  const onBulkSelectChange = (checked) => {
    setBulkSelected(checked);
    onProductsSelectedChange && onProductsSelectedChange(checked ? [...products] : []);
  };

  const columns = listColumns(
    t,
    classes,
    selectedProducts,
    onProductsSelectedChange,
    hideCheckbox,
    single,
    onBulkSelectChange,
    bulkSelected,
    allowBulkSelect,
    allProducts
  );

  return (
    <div className={classes.root} style={style}>
      {products &&
        (simple ? (
          products.map((v, i) => {
            return (
              v && (
                <div key={"prod_" + i}>
                  <ProductSnip
                    gtin={v.gtin}
                    description={v.description}
                    image={v.thumbnail || v.image}
                    promoterInternalCode={v.promoterInternalCode}
                  />
                </div>
              )
            );
          })
        ) : (
          <CustomDataGrid
            localFilters={localFilters}
            columns={columns}
            rows={products}
            onFilter={onFilter}
            onLoadMore={onLoadMore}
            total={totalRows}
            fixedHeight={400}
          />
        ))}
    </div>
  );
}

export default withStyles(ProductPickerDataGrid, styles)

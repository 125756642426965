import {Typography} from "@mui/material";
import ActionSnip from "../../../../common/displays/ActionSnip/ActionSnip";
import {currencyFormat} from "../../../../common/utils";

export function listColumns( t, classes ) {

  return [
    {
      field: "actionDate",
      headerName: t("productsPrices.PriceHistoryModal.columns.actionDate"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return <ActionSnip username={v.actionUser} date={v.actionDate}/>;
      },
    },
    {
      field: "effectiveDate",
      headerName: t("productsPrices.PriceHistoryModal.columns.effectiveDate"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.price?.effectiveDate ? v.price?.effectiveDate : "";
      }
    },
    {
      field: "removeDate",
      headerName: t("productsPrices.PriceHistoryModal.columns.removeDate"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.price?.removeDate ? v.price?.removeDate : "";
      }
    },{
      field: "priceType",
      headerName: t("productsPrices.PriceHistoryModal.columns.priceType"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.price?.priceType ? t("common.Enums.priceType." + v.price?.priceType)  : "";
      }
    },{
      field: "salePrice",
      headerName: t("productsPrices.PriceHistoryModal.columns.salePrice"),
      align: "center",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return currencyFormat(v.price?.salePrice);
      }
    },{
      field: "retailPrice",
      headerName: t("productsPrices.PriceHistoryModal.columns.retailPrice"),
      align: "center",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return currencyFormat(v.price?.retailPrice);
      }
    },{
      field: "active",
      headerName: t("productsPrices.PriceHistoryModal.columns.active"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.price?.active ? t('products.PriceHistoryModal.columns.active_'+v.price?.active) : "";
      }
    }, {
      field: "status",
      headerName: t("productsPrices.PriceHistoryModal.columns.status"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        if(v.price?.status) {
          const status = v.price?.status;
          if (status === "ACC" || status === "END") {
            return <Typography className={classes.statusAcc}>{t("common.Enums.priceStatus." + status)}</Typography>
          } else if (status === "PND") {
            return <Typography className={classes.statusPnd}>{t("common.Enums.priceStatus." + status)}</Typography>
          } else {
            return <Typography className={classes.statusRef}>{t("common.Enums.priceStatus." + status)}</Typography>
          }
        }
      }
    }
  ];
}

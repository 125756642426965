import React from "react";
import { Grid, IconButton, Stack } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Permission from "../../../../app/components/Permission";
import { usePromoter, useUser } from "../../../../common/hooks";
import HistoryIcon from "@mui/icons-material/History";

export default function PricesDataGridActions({ classes, row, validation, onValidate, onVariantAdd, onHistory }) {
  const standardPrice = (row?.prices || []).find(item => item.priceType === "S") || {};
  const promoter = usePromoter();
  const user = useUser();
  const allowedToChange = user?.entity?.type !== "P" || !promoter?.getConfig("product", "requiresInternalCode") || row.promoterInternalCode;

  const listCreationTypes = promoter?.getConfig("price", "listCreationTypes") || [];

  const onHistoryHandler = (e) => {
    e.preventDefault();
    const priceObj = (row?.prices || []).find(item => item.priceType === "S") || {};
    onHistory && onHistory(row, priceObj.priceId);
  };
  const onVariantAddHandler = (e) => {
    e.preventDefault();
    onVariantAdd && onVariantAdd(row);
  };
  const setValidHandler = (value) => {
    const { priceId, valid, process } = standardPrice;
    onValidate && onValidate({ priceId, valid, taskId: process?.taskId, productId: row?.productId }, value);
  }

  return (
    <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
      {/* ADD VARIANT */}
      {!validation && listCreationTypes.length > 0 && allowedToChange &&
        <Permission code="products.actions.price.create">
          <Grid item>
            <IconButton size="small" onClick={onVariantAddHandler}>
              <AddCircleOutlineIcon color="primary" />
            </IconButton>
          </Grid>
        </Permission>
      }
      {/* VALIDATION */}
      {validation && standardPrice?.process?.waitingAction && standardPrice?.process?.status === "PENDING" && allowedToChange && promoter?.getConfig("price", "allowStandard") &&
        <Permission code="products.actions.pendingPrice.action">
          <Grid item>
            <Stack direction="row" spacing={0} >
              <IconButton onClick={() => setValidHandler(false)}>
                <CancelOutlinedIcon color={standardPrice.valid === false ? "error" : "default"} />
              </IconButton>
              <IconButton onClick={() => setValidHandler(true)}>
                <CheckCircleOutlineIcon color={standardPrice.valid === true ? "success" : "default"} />
              </IconButton>
            </Stack>
          </Grid>
        </Permission>
      }
      {promoter?.getConfig("price", "allowStandard") &&
          <Permission code="products.actions.price.history">
            <Grid item>
              <IconButton size="small" onClick={onHistoryHandler}>
                <HistoryIcon className={classes.icon} />
              </IconButton>
            </Grid>
          </Permission>
      }
    </Grid>
  );
}

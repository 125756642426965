import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Stack } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { styles } from "./PlanningOverview.styles";
import { useFirstLoad } from "../../../common/hooks";
import { useDispatch, useSelector } from "react-redux";
import { planningAggregateList, selectPlanningAggregated } from "../planningSlice";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import Permission from "../../../app/components/Permission";
import PlanningCreateModal from "./PlanningCreateModal/PlanningCreateModal";
import { selectLoader } from "../../../app/coreSlice";
import PlanningOverviewDataGrid from "./PlanningOverviewDataGrid/PlanningOverviewDataGrid";
import PlanningChart from "./PlanningChart/PlanningChart";

function PlanningOverview({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [addModal, setAddModal] = useState(false);
  const loading = useSelector(selectLoader);
  const planning = useSelector(selectPlanningAggregated);

  const years = [];
  const data = [];
  (planning || []).forEach(entry => {
    if (!years.find(item => item === entry.year)) {
      years.push(entry.year);
    }
    if (!data.find(item => item.productId === entry.productId)) {
      const targets = {};
      (planning || []).filter(item => item.productId === entry.productId).forEach(entry2 => {
        targets[entry2.year] = entry2.target;
      });
      data.push({ ...entry.product, targets });
    }
  });

  useFirstLoad(() => {
    dispatch(planningAggregateList());
  });

  const onAddHandler = () => {
    setAddModal(true);
  }

  return (
    <Page
      permission="planning.actions.production.list"
      header={<PageTitle
        title={t("planning.PlanningOverview.header.title")}
        actions={
          <Grid container direction="row" spacing={2}>
            <Permission code="planning.actions.production.create">
              <Grid item>
                <Button variant="outlined" size="large" onClick={onAddHandler} disabled={loading} id="add"><AddIcon fontSize="inherit" /></Button>
              </Grid>
            </Permission>
          </Grid>
        }
      />}>
      {data &&
        <Stack spacing={2}>
          <PlanningChart data={data} years={years} />
          <PlanningOverviewDataGrid data={data} years={years} />
        </Stack>
      }

      <PlanningCreateModal open={addModal} setOpen={setAddModal} data={planning} />
    </Page>
  );
}

export default withStyles(PlanningOverview, styles);
import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button, Paper, List, ListItem } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { styles } from "./OrderAttachments.styles";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";
import { usePromoter } from "../../../../common/hooks";
import { Form, FormFields } from "../../../../common/forms";
import { formFields, formSchema } from "./OrderAttachments.schema";
import { useDispatch } from "react-redux";
import { orderAddDocument } from "../../ordersSlice";
import AttachItem from "./AttachItem/AttachItem";
import Permission from "../../../../app/components/Permission";

function OrderAttachments({ classes, attachments, orderId, orderSellerId, dispatchId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const promoter = usePromoter();
  const height = 600;

  const onSubmitHandler = async (values) => {
    dispatch(orderAddDocument(values));
    setModal(false);
  }

  return (
    <Paper className={classes.root} elevation={2}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Grid container wrap="nowrap" spacing={1} alignItems="center">
            <Grid item>
              <AttachFileIcon fontSize="small" style={{ display: "block" }} />
            </Grid>
            <Grid item>
              <Typography variant="h4" align="left" component="h4" className={classes.noMargin}>{t("orders.OrderAttachments.card.title", { count: attachments?.length || 0 })}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <List dense className={classes.list} >
            {(attachments || []).map((attach, index) => (
              <ListItem key={"attach_" + index} disableGutters disablePadding divider={index < attachments.length - 1}>
                <AttachItem attach={attach} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Permission code="orders.actions.document.add">
          <Grid item alignSelf="flex-end">
            <Button variant="contained" color="secondary" size="small" onClick={() => setModal(true)}>{t("orders.OrderAttachments.card.btnAdd")}</Button>
          </Grid>
        </Permission>
      </Grid>
      <CustomModal open={modal} setOpen={setModal} size="large" height={height} title={t("orders.OrderAttachments.modal.title")}>
        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t, metadata: promoter?.getConfig("document", "metadata") })}
          initialValues={{ orderId, orderSellerId, dispatchId }}
          render={({ values }) => {
            return (
              <Grid container direction="column" spacing={2} justifyContent="space-between" style={{ height: height - 64 }}>
                <Grid item>
                  <FormFields fields={formFields({ t, metadata: promoter?.getConfig("document", "metadata")?.[values.type] || {}, documentTypes: promoter?.getConfig("order", "documentTypes") })} />
                </Grid>
                <Grid item alignSelf="flex-end">
                  <Button variant="contained" color="primary" type="submit">{t("orders.OrderAttachments.modal.btnSave")}</Button>
                </Grid>
              </Grid>
            )
          }}
        />
      </CustomModal>
    </Paper >
  );
}

export default withStyles(OrderAttachments, styles);
import { processImageUrl } from "../../../api/s3";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { asyncForEach } from "../../../common/utils";

const productPendingPurchaseListCall = async (
  { clearPurchasesList, setPurchasesListFilter, appendToPurchasesList, setPurchasesListTotalRows, updatePurchasesListThumbnails },
  dispatch,
  state,
  { filter, clear }
) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "PRODUCT_PENDING_PURCHASE_LIST");
  if (clear) {
    dispatch(clearPurchasesList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setPurchasesListFilter(filter));
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "PENDING_PURCHASE_LIST",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    dispatch(appendToPurchasesList(result.items));
    dispatch(setPurchasesListTotalRows(result.rows));

    let pendingPurchases = [];
    await asyncForEach(result.items, async (product) => {
      pendingPurchases.push({
        ...product,
        thumbnail: await processImageUrl(fileStorageConfigs,  product.thumbnail),
      });
    });

    dispatch(updatePurchasesListThumbnails(pendingPurchases));
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productPendingPurchaseListCall;

import React from "react";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {styles} from "./StatusSnip.styles";
import {Badge, Button, Grid, Typography} from "@mui/material";
import {dateTimeFormatWithSeconds} from "../../../../common/utils/formats";
import MemoryIcon from '@mui/icons-material/Memory';
import StorageIcon from '@mui/icons-material/Storage';
import CloudIcon from '@mui/icons-material/Cloud';
import RefreshIcon from '@mui/icons-material/Refresh';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

function StatusSnip({classes, type, data, refreshHandler}) {
    const {t} = useTranslation();


const processComponent = (values) => {
    let html = [];
    html.push(<Grid item xs={12}></Grid>);
    for (let key in values) {
        let value = values[key];
        html.push(<Grid item><AccountTreeIcon fontSize="large"
                                    color={value === undefined ? "warning" : value ? "success" : "error"}
                                    titleAccess={t("promoter.systemStatus.detail."+key) + ": " + t("promoter.systemStatus.detail." + value)}/></Grid>);
    }
    return html;
};

    return (
        <div className={classes.root}>

            <Grid container direction="row" spacing={2}>
                <Grid item xs={10}>
                    <Typography variant="h4" align="left" component="h4" className={classes.noMargin}>
                        {type}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => refreshHandler(type)}
                    >
                        <RefreshIcon/>
                    </Button>
                </Grid>
            </Grid>

            <Grid container direction="row" spacing={2}>
                <Grid item><CloudIcon fontSize="large"
                                      color={data?.ok === undefined ? "warning" : data?.ok ? "success" : "error"}
                                      titleAccess={t("promoter.systemStatus.detail.pod") + ": " + t("promoter.systemStatus.detail." + data?.ok)}/></Grid>
                {data?.cache && <Grid item><MemoryIcon fontSize="large"
                                       color={data?.cache === undefined ? "warning" : data?.cache ? "success" : "error"}
                                       titleAccess={t("promoter.systemStatus.detail.cache") + ": " + t("promoter.systemStatus.detail." + data?.cache)}/></Grid>}
                {data?.db?.coreRead && <Grid item>
                    <Badge badgeContent="R" color="primary">
                        <StorageIcon fontSize="large"
                                             color={data?.db?.coreRead === undefined ? "warning" : data?.db?.coreRead ? "success" : "error"}
                                             titleAccess={t("promoter.systemStatus.detail.dbCoreRead") + ": " + t("promoter.systemStatus.detail." + data?.db?.coreRead)}/>
                    </Badge></Grid>}
                {data?.db?.coreWrite && <Grid item>
                    <Badge badgeContent="W" color="primary">
                        <StorageIcon fontSize="large"
                                        color={data?.db?.coreWrite === undefined ? "warning" : data?.db?.coreWrite ? "success" : "error"}
                                        titleAccess={t("promoter.systemStatus.detail.dbCoreWrite") + ": " + t("promoter.systemStatus.detail." + data?.db?.coreWrite)}/></Badge></Grid>}
                {data?.db?.coreReadNode && <Grid item>
                    <Badge badgeContent="RO" color="primary">
                        <StorageIcon fontSize="large"
                                        color={data?.db?.coreReadNode === undefined ? "warning" : data?.db?.coreReadNode ? "success" : "error"}
                                        titleAccess={t("promoter.systemStatus.detail.dbCoreReadNode") + ": " + t("promoter.systemStatus.detail." + data?.db?.coreReadNode)}/></Badge></Grid>}
                {data?.db?.bigdata && <Grid item>
                    <Badge badgeContent="Big" color="primary">
                        <StorageIcon fontSize="large"
                                            color={data?.db?.bigdata === undefined ? "warning" : data?.db?.bigdata ? "success" : "error"}
                                        titleAccess={t("promoter.systemStatus.detail.dbBigdata") + ": " + t("promoter.systemStatus.detail." + data?.db?.bigdata)}/></Badge></Grid>}
                {data?.component && processComponent(data.component) }
                    </Grid>
                    <Grid container direction="row" spacing={2}>
                {data?.timestamp && <Grid item xs={5}>
                    <Typography variant="body1" align="left" component="span">
                        {dateTimeFormatWithSeconds(data.timestamp)}
                    </Typography>
                </Grid>}
                {data?.responseTime && <Grid item xs={5}>
                    <Typography variant="body1" align="left" component="span">
                        {t("promoter.systemStatus.detail.responseTime") +": "+ data.responseTime}
                    </Typography>
                </Grid>}
            </Grid>

        </div>
    );
}

export default withStyles(StatusSnip, styles)

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./CampaignDataGrid.styles";
import { listColumns } from "./CampaignDataGrid.columns";
import CampaignDataGridActions from "./CampaignDataGrid.actions";
import { campaignsListDefaultFilter } from "../../_common/productsFilters";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";
import { filterFields, filterSchema } from "./CampaignDataGrid.filter";

function CampaignDataGrid({
  classes,
  data,
  filter,
  totalRows,
  onCampaignSelected,
  onFilterChange,
  onDelete,
  onEdit,
  onView,
  groupsList
}) {
  const { t } = useTranslation();
  const columns = listColumns(t, classes);

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };
      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={columns}
        rows={data || []}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMoreHandler}
        onRowSelected={onCampaignSelected}
        total={totalRows}
        actionsComponent={<CampaignDataGridActions onDelete={onDelete} onEdit={onEdit} onView={onView} />}
        topActions={
          <FilterForm
            style={{ marginBottom: 8 }}
            storageKey="CampaignDataGrid"
            onStorageLoad={onFilterHandler}
            slim
            fields={filterFields(groupsList)}
            schema={filterSchema()}
            onFilterChange={(filter) =>
              onFilterChange({
                filter: {
                  ...filter,
                  active: filter.active === "ALL" ? undefined : filter.active,
                },
                reset: true,
              })
            }
            filter={{
              ...filter,
              active: filter.active === undefined ? "ALL" : filter.active,
            }}
            defaultFilter={campaignsListDefaultFilter}
          />
        }
      />
    </div>
  );
}

export default withStyles(CampaignDataGrid, styles)

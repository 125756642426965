/* eslint-disable */
import { Button, Grid, Paper } from "@mui/material";
import React from "react";
import { useState } from "react";
import * as Yup from "yup";

import {
  Form,
} from "../common/forms";
import InternalCodeField from "../features/products/_common/InternalCodeField/InternalCodeField";
import DynamicForm from "../common/forms/DynamicForm/DynamicForm";
import CustomModal from "../common/displays/CustomModal/CustomModal";
import ScrollBox from "../common/displays/ScrollBox/ScrollBox";

function Sandbox() {
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(true);

  const onSubmit = (values) => {
    setData([...data, values]);
  };

  const formObject = [
    {
      key: "country_of_origin",
      type: "text",
      required: true,
      label: { en: "País de origem", pt: "Pais" },
    },
    {
      key: "other_field1",
      type: "text",
      required: true,
      label: { en: "Mercado-Alvo", pt: "..." },
      obs: { en: "País", pt: ".." }
    },
    {
      key: "other_field2",
      type: "text",
      required: true,
      label: { en: "Produtor (descrição)", pt: "..." },
      obs: { en: "(caso o fornecedor não seja o produtor, quem é o produtor?)", pt: ".." }
    },
    {
      key: "other_field3",
      type: "text",
      required: true,
      label: { en: "Peso Escorrido", pt: "..." },
      obs: { en: "Obrigatório para os produtos com vidragem e com liquido de conservação. O campo deve ser obrigatório para todas as famílias à exceção de: Frutas e legumes Natureza, charcutaria, bacalhau seco.", pt: ".." }
    },
    {
      key: "other_field3",
      type: "text",
      required: true,
      label: { en: "Peso Líquido (médio)", pt: "..." },
      obs: { en: "Só artigos peso variável (g)", pt: ".." }
    },
    {
      key: "other_field3",
      type: "text",
      required: true,
      label: { en: "Calibre", pt: "..." },
      obs: { en: "Obrigatório a todas as famílias, incluir com opção NA", pt: ".." }
    },
  ]

  return (
    <div style={{ padding: 20 }}>
      <Paper style={{ padding: 20 }}>
        <Grid container spacing={4} direction="column">
          <Grid item>
            <CustomModal open={modal} setOpen={setModal} size="large" height={680} title="Dados artigos alimentares">
              <div>
                <Button variant="outlined" size="small">Copiar dados de outro produto</Button>
                <br />
                <br />
                <ScrollBox style={{
                  height: 460,
                  paddingRight: 8
                }}>
                  <DynamicForm formObject={formObject} />
                  <br />
                  <br /><br />
                  <br /><br />
                  <br /><br />
                  <br /><br />
                  <br /><br />
                  <br /><br />
                  <br /><br />
                  <br /><br />
                  <br /><br />
                  <br /><br />
                  <br />
                </ScrollBox>
                <br />
                <br />
                <Button variant="contained" type="submit" style={{ float: "right" }}>
                  Submit
                </Button>
              </div>
            </CustomModal>
          </Grid>
          <Grid item xs={12}>
            <Form
              schema={formSchema}
              initialValues={{}}
              onSubmit={onSubmit}
              render={({ values }) => {
                return (
                  <>
                    {/* <InternalCodeField id="code" label="Internal Code" required /> */}
                    <br />
                    <br />
                    <Button variant="contained" type="submit">
                      Submit
                    </Button>
                  </>
                );
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default Sandbox;

const formSchema = Yup.object().shape({
  // fee: Yup.object().shape({
  //   name: Yup.string().required(),
  // }),
});


import React from "react";
import { Routes, Route } from "react-router-dom";

import OrdersCreate from "./OrdersCreate/OrdersCreate";
import OrdersList from "./OrdersList/OrdersList";
import AddressAggregatedOrders from "./AddressAggregatedOrders/AddressAggregatedOrders";
import OrderDetails from "./OrderDetails/OrderDetails";
import OperatorOrderDetails from "./OperatorOrderDetails/OperatorOrderDetails";
import AggregatedOrders from "./AggregatedOrders/AggregatedOrders";
import AggregatedDetails from "./AggregatedDetails/AggregatedDetails";
import OperatorOrdersList from "./OperatorOrdersList/OperatorOrdersList";
import { useUser } from "../../common/hooks";
import OrdersMissingProducts from "./OrdersMissingProducts/OrdersMissingProducts";
import Security from "../../app/components/Security/Security";

function OrdersRouting() {
  const user = useUser();

  let orderBase = "";
  if (user?.entity?.type === "S")
    orderBase = <AggregatedOrders />;
  else if (user?.entity?.type === "LO")
    orderBase = <OperatorOrdersList />;
  else
    orderBase = <OrdersList />;

  return (
    <Routes>
      <Route path="/add" element={<OrdersCreate />} />
      <Route path="/edit/:id" element={
        <Security component={<OrdersCreate />} paramValidation={[{ name: "id", type: "number" }]} />
      } />
      <Route path="/placed" element={<OrdersList />} />
      <Route path="/received/:id/accept" element={
        <Security component={<AggregatedDetails acceptScreen />} paramValidation={[{ name: "id", type: "number" }]} />
      } />
      <Route path="/received/:id/dispatch" element={
        <Security component={<AggregatedDetails dispatchScreen />} paramValidation={[{ name: "id", type: "number" }]} />
      } />
      <Route path="/received/products/:id" element={
        <Security component={<AggregatedDetails />} paramValidation={[{ name: "id", type: "number" }]} />
      } />
      <Route path="/received/:id" element={
        <Security component={<AddressAggregatedOrders />} paramValidation={[{ name: "id", type: "number" }]} />
      } />
      <Route path="/received" element={<AggregatedOrders />} />
      <Route path="/operator/:id/:dispatchId" element={
        <Security component={<OperatorOrderDetails />} paramValidation={[{ name: "id", type: "number" }, { name: "dispatchId", type: "number" }]} />
      } />
      <Route path="/operator" element={<OperatorOrdersList />} />
      <Route path="/missing-products" element={<OrdersMissingProducts />} />
      <Route path="/missing-products/products/:id" element={
        <Security component={<AggregatedDetails fromMissingProducts />} paramValidation={[{ name: "id", type: "number" }]} />
      } />
      <Route path="/:id" element={
        <Security component={<OrderDetails />} paramValidation={[{ name: "id", type: "number" }]} />
      } />
      <Route path="/" element={orderBase} />
    </Routes>
  );
}

export default OrdersRouting;

import { call } from "../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../app/coreSlice";
import { productsSlice } from "./productsSlice";
import { saveFile } from "../../api/s3";
import { base64ToArrayBuffer } from "../../common/utils";
import { v4 as uuidv4 } from "uuid";

export const selectLoader = (state) => state.core.loader.length > 0;
export const importData = (state) => state.products.import;

export const { setImport } = productsSlice.actions;

export const importProduct = (promoter, values) => async (dispatch) => {
  let rh = requestHelper(dispatch, "PRODUCT_IMPORT_" + values?.type.toUpperCase());

  try {
    let filename = uuidv4();

    let fileType = undefined;
    switch (values?.file?.type) {
      case "application/vnd.ms-excel":
        fileType = 0;
        filename += ".xls";
        break;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        fileType = 1;
        filename += ".xlsx";
        break;
      case "text/csv":
        fileType = 2;
        filename += ".csv";
        break;
      default:
        throw new Error("Invalid File Type");
    }

    let fileUrl = await sendFileToBucket(
      promoter?.getConfig("admin", "fileStorage"),
      promoter?.details?.code,
      values?.type,
      filename,
      values?.file?.base64,
      values?.file?.type
    );

    let processData = {
      owner: values?.ownerId,
      gln: values?.gln,
      address: values?.type === "product" ? values?.addressId : undefined,
    };

    let data = await call({
      type: "PRODUCT",
      subType: "IMPORT_" + values?.type.toUpperCase(),
      request: {
        url: fileUrl,
        fileType: fileType,
        processData: processData,
      },
    });

    if (data && data.processFileId) {
      let resultsArr = [];

      for (let i = 1; i <= data.totalLines; i++) {
        resultsArr.push({
          lineId: i,
          success: "",
          message: "importing",
        });
      }
      dispatch(
        setImport({
          ...importData,
          ownerId: values?.ownerId,
          gln: values?.gln,
          type: values?.type,
          addressId: values?.addressId,
          processFileId: data.processFileId,
          fileProcessing: true,
          totalLines: data.totalLines,
          results: resultsArr,
          file: [],
        })
      );
    }
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export const processFileResult = (processFileId) => async (dispatch) => {
  let rh = requestHelper(dispatch, "DOCUMENT_PROCESS_FILE_RESULT");

  try {
    let data = await call({
      type: "DOCUMENT",
      subType: "PROCESS_FILE_RESULT",
      request: {
        processFileId: processFileId,
      },
    });

    if (data && data.result && data.result.status === 1) {
      dispatch(
        setImport({
          ...importData,
          fileProcessing: false,
          results: data.result.lineData,
        })
      );
      // importFile.fileProcessing
    } else if (
      data &&
      data.result &&
      data.result.status === 0 &&
      data.result.lineData &&
      data.result.lineData.length > 0
    ) {
      let resultsArr = importData.results;

      for (let i = 0; i <= data.result.lineData.length; i++) {
        if (data.result.lineData != null) {
          resultsArr[i] = data.result.lineData[i];
        }
      }

      dispatch(
        setImport({
          ...importData,
          results: resultsArr,
        })
      );
    }
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

const sendFileToBucket = async (filestorageConfigs, promoterCode, uploadType, filename, file, contentType) => {
  const filePath =
    promoterCode.toUpperCase() + "/" + process.env.REACT_APP_ENV + "/import/" + uploadType + "/" + filename;
  return await saveFile(
    filestorageConfigs?.provider,
    filestorageConfigs?.tempBucket,
    filePath,
    base64ToArrayBuffer(file),
    contentType
  );
};

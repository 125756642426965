import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";

const promoterCreateLocationCall = async ({ promoterListLocations }, dispatch, { name, code, states }) => {
  let rh = requestHelper(dispatch, "PROMOTER_CREATE_LOCATION");
  try {
    await call({
      type: "PROMOTER",
      subType: "CREATE_LOCATION",
      request: { name, code, states },
    });
    setTimeout(() => {
      dispatch(setRedirect("/promoter/configs/locations"));
      dispatch(setSuccess("promoter", "PROMOTER_CREATE_LOCATION"));
      dispatch(promoterListLocations());
    }, 300);
  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default promoterCreateLocationCall;
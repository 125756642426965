import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Avatar, AvatarGroup, List, ListItem, Stack } from "@mui/material";

import { styles } from "./Assignee.styles";
import { useFirstLoad, usePromoter, useUser } from "../../hooks";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { entityGet, selectEntityDetails } from "../../../features/entities/entitiesSlice";
import ReassignModal from "./ReassignModal/ReassignModal";

function Assignee({ classes, users, groups, sectors, onReassign }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const entity = useSelector(selectEntityDetails);
  const promoter = usePromoter();
  const user = useUser();
  const [modal, setModal] = useState(false);

  useFirstLoad(() => {
    if (!entity) {
      dispatch(entityGet(user?.entity?.id));
    }
  });

  const onClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onReassign) {
      setModal(true);
    }
  }

  return (
    <div className={classes.root} >
      <div onClick={onClickHandler} style={onReassign ? { cursor: "pointer" } : {}}>


        {/* USERS */}
        {users?.length > 0 &&

          <CustomTooltip title={
            <List>
              {(users || []).map((userId) => {
                const contactObj = getContact(entity, userId);
                let displayName =
                  contactObj?.firstName || contactObj?.lastName
                    ? (contactObj?.firstName || "") + " " + (contactObj?.lastName || "")
                    : contactObj?.email;
                return (
                  <ListItem key={`user_${userId}`}>
                    <Grid container className={classes.root} justifyContent="flexStart" alignItems="center" spacing={1} wrap="nowrap">
                      <Grid item>
                        <Avatar alt={displayName} src={contactObj?.logo} />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption" style={{ margin: 0 }}>
                          {displayName}
                        </Typography>
                        <Typography style={{ margin: 0 }} >
                          {t("common.Enums.sector." + contactObj?.sector)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
          }>
            <AvatarGroup className={classes.userAvatar} max={4}>
              {
                (users || []).map((userId) => {
                  const contactObj = getContact(entity, userId);
                  let displayName =
                    contactObj?.firstName || contactObj?.lastName
                      ? (contactObj?.firstName || "") + " " + (contactObj?.lastName || "")
                      : contactObj?.email;
                  return (
                    <Avatar key={`avatar_${userId}`} alt={displayName} src={contactObj?.logo} />
                  )
                })
              }
            </AvatarGroup>
          </CustomTooltip>
        }

        {/* GROUPS */}
        {groups?.length > 0 &&
          <CustomTooltip title={
            <List>
              {(groups || []).map((groupId) => {
                const groupObj = getSectorGroup(entity, groupId);
                return (
                  <ListItem key={`group_${groupId}`}>
                    <Grid container className={classes.root} justifyContent="flexStart" alignItems="center" spacing={1} wrap="nowrap">
                      <Grid item>
                        <Avatar className={classes.groupAvatarList}>
                          {getDesignationInitials(groupObj?.groupName)}
                        </Avatar>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption" style={{ margin: 0 }}>
                          {groupObj?.groupName}
                        </Typography>
                        <Typography style={{ margin: 0 }} >
                          {t("common.Enums.sector." + groupObj?.sector)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
          }>
            <AvatarGroup className={classes.groupAvatar} max={4}>
              {
                (groups || []).map((groupId) => {
                  const groupObj = getSectorGroup(entity, groupId);
                  return (
                    <Avatar key={`avatar_${groupId}`}>{getDesignationInitials(groupObj?.groupName)}</Avatar>
                  )
                })
              }
            </AvatarGroup>
          </CustomTooltip>
        }

        {/* SECTORS */}
        {sectors?.length > 0 &&
          <CustomTooltip title={
            <List>
              {(sectors || []).map((sectorCode) => {
                return (
                  <ListItem key={`sector_${sectorCode}`}>
                    <Stack direction="row" spaicng={1} alignItems="center">
                      <Avatar className={classes.sectorAvatarList} key={`avatar_${sectorCode}`}>{getDesignationInitials(t("common.Enums.sector." + sectorCode))}</Avatar>
                      <Typography variant="caption" style={{ margin: 0 }}>{t("common.Enums.sector." + sectorCode)}</Typography>
                    </Stack>
                  </ListItem>
                );
              })}
            </List>
          }>
            <AvatarGroup className={classes.sectorAvatar} max={4}>
              {
                (sectors || []).filter(sector => (promoter?.refData?.sector || []).some(str => str === sector)).map((sectorCode) => (
                  <Avatar key={`avatar_${sectorCode}`}>{getDesignationInitials(t("common.Enums.sector." + sectorCode))}</Avatar>
                ))
              }
            </AvatarGroup>
          </CustomTooltip>
        }
      </div>
      <ReassignModal open={modal} setOpen={setModal} users={users} groups={groups} sectors={sectors} entity={entity} onSubmit={onReassign} />
    </div>
  );
}

export default withStyles(Assignee, styles);


const getDesignationInitials = (designation) => {
  const names = (designation || "").split(" ");
  let result = "";
  for (let i = 0; i < 2; i++) {
    if (names[i]) {
      result += names[i][0];
    }
  }
  return result;
}

const getSectorGroup = (entity, groupId) => {
  return (entity?.sectorsGroups || []).find(item => item.id === groupId);
}

const getContact = (entity, userId) => {
  return (entity?.contacts || []).find(item => item.id === userId);
}
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { processImageUrl } from "../../../api/s3";
import { asyncForEach } from "../../../common/utils";

const batchListOrdersCall = async (
  { clearBatchOrdersList, setBatchOrdersListFilter, appendToBatchOrdersList, setBatchOrdersListTotalRows },
  dispatch,
  state,
  { businessUnitId, filter, clear }
) => {
  let rh = requestHelper(dispatch, "BATCH_LIST_ORDERS");
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  if (clear) {
    dispatch(clearBatchOrdersList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setBatchOrdersListFilter(filter));
  try {
    let result = await call({
      type: "BATCH",
      subType: "LIST_ORDERS",
      request: {
        ...filter,
        businessUnitId,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    let orders = [];
    await asyncForEach(result.items, async (order) => {
      let products = [];
      await asyncForEach(order.products, async (element) => {
        products.push({
          ...element,
          product: {
            ...element.product,
            thumbnail: await processImageUrl(fileStorageConfigs, element?.product?.thumbnail),
          }
        });
      });
      orders.push({
        ...order,
        products
      });
    });
    dispatch(appendToBatchOrdersList(orders));
    dispatch(setBatchOrdersListTotalRows(result.rows));
    
  } catch (ex) {
    rh.error("batches", ex);
  }
  rh.close();
};

export default batchListOrdersCall;
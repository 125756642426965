import { productsSlice } from "./productsSlice";
import { call } from "../../app/websocket/_sliceCalls/websocket";
import { setSuccess, setRedirect, requestHelper, getPromoterConfig } from "../../app/coreSlice";
import moment from "moment";
import { processImageUrl } from "../../api/s3";
import { asyncForEach } from "../../common/utils";

export const selectLoader = (state) => state.core.loader.length > 0;
export const selectCampaignListTotalRows = (state) => state.products.campaignListTotalRows;
export const selectCampaignListFilter = (state) => state.products.campaignListFilter;
export const selectCampaignList = (state) => state.products.campaignList;
export const selectCampaignDetails = (state) => state.products.campaignDetails;

const { clearCampaignList, appendToCampaignList, setCampaignListTotalRows, setCampaignListFilter, setCampaignDetails } =
  productsSlice.actions;

export const listCampaignsAppend =
  (filter, clear = false) =>
  async (dispatch) => {
    let rh = requestHelper(dispatch, "LIST_CAMPAIGN_GROUP");
    if (clear) {
      dispatch(clearCampaignList());
      filter = {
        ...filter,
        offset: 0,
        startDate: moment.isMoment(filter.startDate) ? filter.startDate.toISOString() : filter.startDate,
        endDate: moment.isMoment(filter.endDate) ? filter.endDate.toISOString() : filter.endDate,
      };
    }
    dispatch(setCampaignListFilter(filter));
    try {
      let list = await call({
        type: "PRODUCT",
        subType: "LIST_CAMPAIGN_GROUP",
        request: {
          ...filter,
          sortField: (filter.sortField || "").toUpperCase(),
        },
      });
      dispatch(appendToCampaignList(list.campaigns));
      dispatch(setCampaignListTotalRows(list.rows));
    } catch (ex) {
      rh.error("products", ex);
    }
    rh.close();
  };

export const getCampaignDetails = (campaignGroupId) => async (dispatch, getState) => {
  const state = getState();
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");

  let rh = requestHelper(dispatch, "GET_CAMPAIGN_GROUP");
  try {
    const campaign = await call({
      type: "PRODUCT",
      subType: "GET_CAMPAIGN_GROUP",
      request: {
        campaignGroupId,
      },
    });

    let campaigns = [];
    await asyncForEach(campaign.campaigns, async (campaignItem) => {
      let products = [];
      await asyncForEach(campaignItem.products, async (product) => {
        products.push({
          ...product,
          image: await processImageUrl(fileStorageConfigs,  product.image),
          thumbnail: await processImageUrl(fileStorageConfigs,  product.thumbnail),
        });
      });
      campaigns.push({
        ...campaignItem,
        products,
      });
    });

    if (campaigns) {
      campaigns = campaigns.filter((item) => item.active);
      campaigns = campaigns.map((item) => {
        let filteredProducts = (item.products || []).filter((prod) => item.productId.find((e) => e === prod.productId));

        if (item.campaignType !== "BU") {
          filteredProducts = [filteredProducts[0]];
        } else {
          let bundlingCampaign = {};
          bundlingCampaign.productBuy = item.bundlingCampaign.productBuy.map((product) => {
            return { ...product, ...item.products.find((element) => element.productId === product.productId) };
          });
          bundlingCampaign.productOffers = item.bundlingCampaign.productOffers.map((product) => {
            return { ...product, ...item.products.find((element) => element.productId === product.productId) };
          });

          return { ...item, products: filteredProducts, bundlingCampaign };
        }
        return { ...item, products: filteredProducts };
      });
    }

    let groupEntity = campaign.groupEntity && campaign.groupEntity.length > 0 ? campaign.groupEntity : undefined;

    dispatch(setCampaignDetails({ ...campaign, campaigns, groupEntity }));
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export const createCampaignGroup =
  ({ name, startDate, endDate, groupEntity }) =>
  async (dispatch, getState) => {
    const state = getState();
    const filter = state.products.campaignListFilter;
    let rh = requestHelper(dispatch, "CREATE_CAMPAIGN_GROUP");

    try {
      let result = await call({
        type: "PRODUCT",
        subType: "CREATE_CAMPAIGN_GROUP",
        request: {
          name,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          groupEntity,
        },
      });
      setTimeout(() => {
        dispatch(setSuccess("products", "PRODUCT_CREATE_CAMPAIGN_GROUP"));
        dispatch(listCampaignsAppend(filter, true));
        dispatch(setRedirect("/products/campaigns/edit/" + result.campaignGroupId));
      }, 300);
    } catch (ex) {
      rh.error("products", ex);
    }
    setTimeout(() => {
      rh.close();
    }, 350);
  };

export const updateCampaignGroup =
  (campaignGroupId, { name, startDate, endDate, groupEntity }) =>
  async (dispatch, getState) => {
    const state = getState();
    const filter = state.products.campaignListFilter;
    let rh = requestHelper(dispatch, "UPDATE_CAMPAIGN_GROUP");
    try {
      await call({
        type: "PRODUCT",
        subType: "UPDATE_CAMPAIGN_GROUP",
        request: {
          campaignGroupId,
          name,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          groupEntity,
        },
      });

      setTimeout(() => {
        dispatch(setSuccess("products", "PRODUCT_UPDATE_CAMPAIGN_GROUP"));
        dispatch(getCampaignDetails(campaignGroupId));
        dispatch(listCampaignsAppend(filter, true));
      }, 300);
    } catch (ex) {
      rh.error("products", ex);
    }
    rh.close();
  };

export const disableCampaignGroup = (campaignGroupId) => async (dispatch, getState) => {
  const state = getState();
  const filter = state.products.campaignListFilter;
  let rh = requestHelper(dispatch, "DISABLE_CAMPAIGN_GROUP");
  try {
    await call({
      type: "PRODUCT",
      subType: "DISABLE_CAMPAIGN_GROUP",
      request: {
        campaignGroupId,
      },
    });
    setTimeout(() => {
      dispatch(setSuccess("products", "PRODUCT_DISABLE_CAMPAIGN_GROUP"));
      dispatch(listCampaignsAppend(filter, true));
    }, 300);
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export const createCampaign =
  ({
    campaignGroupId,
    products,
    campaignType,
    merchandiseBonusesCampaign,
    percentageCampaign,
    promotionCampaign,
    bundlingCampaign,
  }) =>
  async (dispatch) => {
    let rh = requestHelper(dispatch, "CREATE_CAMPAIGN");
    if (bundlingCampaign) {
      products = bundlingCampaign.productBuy;
      bundlingCampaign = {
        productBuy: bundlingCampaign.productBuy.map(({ productId, quantity }) => {
          return { productId, quantity };
        }),
        productOffers: bundlingCampaign.productOffers.map(({ productId, quantity }) => {
          return { productId, quantity };
        }),
      };
    }
    try {
      await call({
        type: "PRODUCT",
        subType: "CREATE_CAMPAIGN",
        request: {
          campaignGroupId,
          productId: products !== undefined ? products.map((item) => item.productId) : [],
          campaignType,
          merchandiseBonusesCampaign,
          percentageCampaign,
          promotionCampaign,
          bundlingCampaign,
        },
      });
      setTimeout(() => {
        dispatch(setSuccess("products", "PRODUCT_CREATE_CAMPAIGN"));
        dispatch(getCampaignDetails(campaignGroupId));
      }, 300);
    } catch (ex) {
      rh.error("products", ex);
    }
    rh.close();
  };

export const updateCampaign =
  ({
    campaignId,
    campaignGroupId,
    products,
    campaignType,
    merchandiseBonusesCampaign,
    percentageCampaign,
    promotionCampaign,
    bundlingCampaign,
  }) =>
  async (dispatch) => {
    let rh = requestHelper(dispatch, "UPDATE_CAMPAIGN");
    if (bundlingCampaign) {
      products = bundlingCampaign.productBuy;
      bundlingCampaign = {
        productBuy: bundlingCampaign.productBuy.map(({ productId, quantity }) => {
          return { productId, quantity };
        }),
        productOffers: bundlingCampaign.productOffers.map(({ productId, quantity }) => {
          return { productId, quantity };
        }),
      };
    }
    try {
      await call({
        type: "PRODUCT",
        subType: "UPDATE_CAMPAIGN",
        request: {
          campaignId,
          campaignGroupId,
          productId: products.map((item) => item.productId),
          campaignType,
          merchandiseBonusesCampaign,
          percentageCampaign,
          promotionCampaign,
          bundlingCampaign,
        },
      });
      setTimeout(() => {
        dispatch(setSuccess("products", "PRODUCT_UPDATE_CAMPAIGN"));
        dispatch(getCampaignDetails(campaignGroupId));
      }, 300);
    } catch (ex) {
      rh.error("products", ex);
    }
    rh.close();
  };

export const disableCampaign =
  ({ campaignId, campaignGroupId }) =>
  async (dispatch) => {
    let rh = requestHelper(dispatch, "DISABLE_CAMPAIGN");
    try {
      await call({
        type: "PRODUCT",
        subType: "DISABLE_CAMPAIGN",
        request: {
          campaignId,
          campaignGroupId,
        },
      });
      setTimeout(() => {
        dispatch(setSuccess("products", "PRODUCT_DISABLE_CAMPAIGN"));
        dispatch(getCampaignDetails(campaignGroupId));
      }, 300);
    } catch (ex) {
      rh.error("products", ex);
    }
    rh.close();
  };

export const getScheduleOptions = (t, taskType) => {
  let options = ["D", "W"];
  if (taskType === "B") {
    options.push("O")
  }

  return (options)
    .map((item) => {
      return {
        value: item,
        text: t("common.Enums.scheduleType." + item),
      };
    });
};
import React from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./VersionLabel.styles";
import { classJoin } from "../../common/utils";

function VersionLabel({ classes }) {
  let env = "";
  if (process.env.REACT_APP_ENV !== "production") {
    env = process.env.REACT_APP_ENV.toUpperCase() + " - ";
  }
  return (
    <div className={classJoin([classes.root, classes[process.env.REACT_APP_ENV]])}>
      {`${env}v${process.env.REACT_APP_VERSION} - Copyright © ${new Date().getFullYear()} `}
      <a href="https://www.logistema.pt/">Logistema</a>
    </div>
  );
}

export default withStyles(VersionLabel, styles);
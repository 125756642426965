import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const orderCutOffCall = async ({ listCutOffsAppend }, dispatch, state, { entityId, cutOff }) => {
  const filter = state.entities.cutOffListFilter;
  let rh = requestHelper(dispatch, "FORCE_CUT_OFF");
  try {
    await call({
      type: "ORDER",
      subType: "CUT_OFF",
      request: {
        entityId: entityId,
        cutOff: cutOff,
      },
    });
    setTimeout(() => {
      dispatch(setSuccess("entities", "ORDER_CUT_OFF"));
      dispatch(listCutOffsAppend(filter, true));
    }, 300);
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default orderCutOffCall;

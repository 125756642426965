

const businessUnits = {
  flowsMessages: {
    success: {
      BUSINESS_UNIT_CREATE: "Business Unit created successfully!",
      BUSINESS_UNIT_UPDATE: "Business Unit updated successfully!",
      BUSINESS_UNIT_DELETE: "Business Unit deleted successfully!",
      BUSINESS_UNIT_DISABLE: "Business Unit disabled successfully!",
      BUSINESS_UNIT_ENABLE: "Business Unit enabled successfully!",
      BUSINESS_UNIT_CHANGE_STEPS_ORDER: "Business Unit steps order changed successfully!",
      BUSINESS_UNIT_CREATE_STEP: "Business Unit step created successfully!",
      BUSINESS_UNIT_CREATE_TASK: "Business Unit task created successfully!",
      BUSINESS_UNIT_DELETE_STEP: "Business Unit step deleted successfully!",
      BUSINESS_UNIT_DELETE_TASK: "Business Unit task deleted successfully!",
      BUSINESS_UNIT_DISABLE_STEP: "Business Unit step disabled successfully!",
      BUSINESS_UNIT_DISABLE_TASK: "Business Unit task disabled successfully!",
      BUSINESS_UNIT_ENABLE_STEP: "Business Unit step enabled successfully!",
      BUSINESS_UNIT_ENABLE_TASK: "Business Unit task enabled successfully!",
      BUSINESS_UNIT_UPDATE_STEP: "Business Unit step updated successfully!",
      BUSINESS_UNIT_UPDATE_TASK: "Business Unit task updated successfully!",
    },
    error: {
      GENERIC: "Something went wrong",
    },
  },
  BusinessUnitList: {
    header: {
      title: "Business Units"
    },
    actions: {
      edit: "Edit",
      enable: "Enable",
      disable: "Disable",
      remove: "Remove",
    },
    columns: {
      name: "Name",
      entity: "Entity",
      lines_input: "Input",
      lines_output: "Output",
      status: "Status",
    },
    filter: {
      status: "Status",
      allStatus: "Clear",
      status_true: "Active",
      status_false: "Inactive",
    }
  },
  BusinessUnitForm: {
    header: {
      titleEdit: "Update Business Unit",
      title: "Create Business Unit",
    },
    form: {
      btnSubmit: "Submit",
      name: "Name",
      nameRequired: "Name is required",
      entityId: "Entity",
      entityIdRequired: "Entity is required",
      associationType: "Association Type",
      users: "Users",
      usersRequired: "Users is required",
      sectors: "Sectors",
      sectorsRequired: "Sectors is required",
      groups: "Groups",
      groupsRequired: "Groups is required",
      productionTime: "Production Time",
      successRate: "Success Rate",
      maxSuccessRate: "Success Rate must be less than or equal to 100",
      agregator: "Agregator",
      spreader: "Spreader",
      anticipation: "Anticipation",
      loadBalance: "Load Balance",
      maxLoadBalance: "Load Balance must be less than or equal to 100",
      input: "Input",
      output: "Output",
      product: "Product",
      quantity: "Quantity",
      businessUnits: "Business Units",
      allowInternalBatches: "Allow internal batches",
      taskInitializationOffset: "Task initialization offset",
      places: "Places",
    }
  },
  BusinessUnitProductionForm: {
    header: {
      title: "production steps",
    },
    form: {
      btnSubmit: "Submit",
      productionTime: "Production Time",
      successRate: "Success Rate",
      maxSuccessRate: "Success Rate must be less than or equal to 100",
      agregator: "Agregator",
      spreader: "Spreader",
      anticipation: "Anticipation",
      loadBalance: "Load Balance",
      maxLoadBalance: "Load Balance must be less than or equal to 100",
      input: "Input",
      output: "Output",
      product: "Product",
      quantity: "Quantity",
      businessUnits: "Business Units",
    }
  },
  BusinessUnitSteps: {
    details: {
      stepsEmpty: "No steps defined",
      placesTitle: "Places",
      placesEmpty: "No places associated to this step",
      placesTasksTitle: "Tasks",
      placesTasksEmpty: "No places tasks available",
      batchesTasksTitle: "Batches Tasks",
      batchesTasksEmpty: "No batches tasks available",
      startConditionsTitle: "Start conditions",
      startConditionsEmpty: "No start conditions defined",
      stopConditionsTitle: "Stop conditions",
      stopConditionsEmpty: "No stop conditions defined",
      alertsTitle: "Alerts",
      alertsEmpty: "No alerts defined",
    }
  },
  StepActions: {
    actions: {
      edit: "Edit",
      enable: "Enable",
      disable: "Disable",
      remove: "Remove",
    }
  },
  ConfirmRemoveModal: {
    modal: {
      title: "Remove step",
      message: "This action is not reversible, are you sure you want to remove this step?",
      confirmBtn: "Confirm"
    }
  },
  CreateStepModal: {
    modal: {
      title: "Create new step",
      titleEdit: "Update step:",
    },
    form: {
      btnSubmit: "Submit",
      nameRequired: "Name is required",
      name: "Name",
      places: "Places",
      placesClear: "Clear"
    }
  },
  BusinessUnitCard: {
    actions: {
      edit: "Edit",
      editLines: "Production",
      enable: "Enable",
      disable: "Disable",
      remove: "Remove",
      placeTasks: "Places Tasks",
      steps: "Steps",
      internalSteps: "Internal Steps",
    }
  },
  BusinessUnitStepTaskForm: {
    header: {
      titleEdit: "Update Step Task",
      title: "Create Step Task",
      details: "Details",
      info: "Info",
      inputs: "Inputs"
    },
    details: {
      infoEmpty: "Info blocks not defined",
      inputsEmpty: "Inputs not defined",
    },
    form: {
      btnSubmit: "Submit",
      nameRequired: "Name is required",
      name: "Name",
      associationType: "Default Assignee",
      users: "User",
      groups: "Groups",
      sectors: "Sectors",
      schedule: "Schedule",
      scheduleWeekdays: "Weekdays",
      scheduleNumberOfDays: "Every X days",
    }
  },
  SetConditionFormModal: {
    modal: {
      startTitle: "Start condition",
      stopTitle: "Stop condition",
    },
    form: {
      btnSubmit: "Submit",
      variableRequired: "Variable is required",
      variable: "Variable",
      start_date_diff: "Days before/after start date",
      min_size: "No quantity below this size"
    }
  },
  TaskInfoModal: {
    modal: {
      title: "Create new info block",
      titleEdit: "Update info block",
    },
    form: {
      btnRemove: "Remove",
      btnSubmit: "Submit",
      typeRequired: "Type is required",
      contentRequired: "Content is required",
      type: "Type",
      content: "Content"
    }
  },
  TaskInputModal: {
    modal: {
      title: "Create new input",
      titleEdit: "Update input",
    },
    form: {
      btnRemove: "Remove",
      btnSubmit: "Submit",
      typeRequired: "Type is required",
      keyRequired: "Key is required",
      labelRequired: "Label is required",
      type: "Type",
      key: "Key",
      label: "Label",
      note: "Note",
      resourceType: "Resource Type",
      resourceTypeRequired: "Resource Type is required",
      transferAllowedResourceType: "Allowed Target Resource Types",
      transferAllowedPlaceType: "Allowed Target Place Types"
    }
  },
  ConfirmRemoveTaskModal: {
    modal: {
      title: "Remove task",
      message: "This action is not reversible, are you sure you want to remove this task?",
      confirmBtn: "Confirm"
    }
  },
  TaskInputItem: {
    details: {
      eachLabel: "Each"
    }
  }
};

export default businessUnits;
import {call} from "../../../app/websocket/_sliceCalls/websocket";
import {requestHelper} from "../../../app/coreSlice";

const businessUnitList_FullCall = async (
  { setBusinessUnitListFull },
  dispatch
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_LIST");
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "LIST",
      request: {
        limit: 50000,
        offset: 0,
        sortField: "NAME",
        sortType: "ASC",
        active: true
      },
    });
    dispatch(setBusinessUnitListFull(result.items));
  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitList_FullCall;
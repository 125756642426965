import React from "react";
import { Grid, Typography } from "@mui/material";

export function listColumns(t, classes) {
  return [
    {
      field: "name",
      headerName: t("promoter.LocationsList.columns.name"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "code",
      headerName: t("promoter.LocationsList.columns.code"),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "states",
      headerName: t("promoter.LocationsList.columns.states"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <Grid container spacing={1}>
            {
              (v.states || []).map((state, index) => (<Grid item key={`state_${index}`}>
                <Typography>{state}{index < v.states.length - 1 ? "," : ""}</Typography>
              </Grid>))
            }
          </Grid>
        );
      }
    }
  ];
}


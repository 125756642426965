import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button, Paper, Divider, Stack } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ToggleOffIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnIcon from '@mui/icons-material/ToggleOnOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import MoveDownOutlinedIcon from '@mui/icons-material/MoveDownOutlined';

import { styles } from "./BusinessUnitCard.styles";
import { useNavigate } from "../../../../common/hooks";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import Permission from "../../../../app/components/Permission";
import ActionsPopover from "../../../../common/components/ActionsPopover/ActionsPopover";
import { classJoin } from "../../../../common/utils";

function BusinessUnitCard({ classes, unit, onRemove, onDisable, onEnable }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let actionsButtons = [];

  const onDisableHandler = (e) => {
    e.preventDefault();
    onDisable && onDisable(unit);
  };

  const onEnableHandler = (e) => {
    e.preventDefault();
    onEnable && onEnable(unit);
  };

  const onRemoveHandler = (e) => {
    e.preventDefault();
    onRemove && onRemove(unit);
  };

  console.log('unit: %o', unit)
  // EDIT
  actionsButtons.push(
    <Permission code="promoter.actions.businessUnit.update">
      <Button onClick={() => navigate(`/business/units/${unit?.id}/edit/`)} fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <EditIcon />
          </Grid>
          <Grid item>{t("businessUnits.BusinessUnitCard.actions.edit")}</Grid>
        </Grid>
      </Button>
    </Permission>
  );

  // EDIT - LINES
  actionsButtons.push(
    <Permission code="promoter.actions.businessUnit.update">
      <Button onClick={() => navigate(`/business/units/${unit?.id}/lines/`)} fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <MoveDownOutlinedIcon />
          </Grid>
          <Grid item>{t("businessUnits.BusinessUnitCard.actions.editLines")}</Grid>
        </Grid>
      </Button>
    </Permission>
  );

  // PLACE TASKS
  actionsButtons.push(
    <Permission code="promoter.actions.businessUnit.update">
      <Button onClick={() => navigate(`/business/units/${unit?.id}/placetasks/`)} fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <ExploreOutlinedIcon />
          </Grid>
          <Grid item>{t("businessUnits.BusinessUnitCard.actions.placeTasks")}</Grid>
        </Grid>
      </Button>
    </Permission>
  );

  // STEPS
  actionsButtons.push(
    <Permission code="promoter.actions.businessUnit.update">
      <Button onClick={() => navigate(`/business/units/${unit?.id}/${unit?.lines?.[0]?.id}/steps/`)} fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <BuildCircleOutlinedIcon />
          </Grid>
          <Grid item>{t("businessUnits.BusinessUnitCard.actions.steps")}</Grid>
        </Grid>
      </Button>
    </Permission>
  );

  // STEPS INTERNAL
  if (unit?.config?.allowInternalBatches) {
    actionsButtons.push(
      <Permission code="promoter.actions.businessUnit.update">
        <Button onClick={() => navigate(`/business/units/${unit?.id}/${unit?.lines?.[0]?.id}/steps/internal/`)} fullWidth>
          <Grid container spacing={1} alignItems="center">
            <Grid item style={{ display: "flex" }}>
              <BuildCircleOutlinedIcon />
            </Grid>
            <Grid item>{t("businessUnits.BusinessUnitCard.actions.internalSteps")}</Grid>
          </Grid>
        </Button>
      </Permission>
    );
  }

  // ENABLE
  if (!unit.active) {
    actionsButtons.push(
      <Permission code="promoter.actions.businessUnit.enable">
        <Button onClick={onEnableHandler} fullWidth>
          <Grid container spacing={1} alignItems="center">
            <Grid item style={{ display: "flex" }}>
              <ToggleOnIcon />
            </Grid>
            <Grid item>{t("businessUnits.BusinessUnitCard.actions.enable")}</Grid>
          </Grid>
        </Button>
      </Permission>
    );
  }

  // DISABLE
  if (unit.active) {
    actionsButtons.push(
      <Permission code="promoter.actions.businessUnit.disable">
        <Button onClick={onDisableHandler} fullWidth>
          <Grid container spacing={1} alignItems="center">
            <Grid item style={{ display: "flex" }}>
              <ToggleOffIcon />
            </Grid>
            <Grid item>{t("businessUnits.BusinessUnitCard.actions.disable")}</Grid>
          </Grid>
        </Button>
      </Permission>
    );
  }

  // DELETE
  if (!unit.active) {
    actionsButtons.push(
      <Permission code="promoter.actions.businessUnit.delete">
        <Button onClick={onRemoveHandler} color="error" fullWidth>
          <Grid container spacing={1} alignItems="center">
            <Grid item style={{ display: "flex" }}>
              <DeleteIcon />
            </Grid>
            <Grid item>{t("businessUnits.BusinessUnitCard.actions.remove")}</Grid>
          </Grid>
        </Button>
      </Permission>
    );
  }

  return (
    <div className={classes.root}>
      <Paper className={classJoin([classes.paper, (!unit.active ? classes.inactive : undefined)])} >
        <Stack spacing={1}>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <Typography className={classes.title} style={{ textDecoration: unit.active ? "" : "line-through" }}>{unit.name}</Typography>
            <div>
              <ActionsPopover actions={actionsButtons} />
            </div>
          </Stack>
          <Divider />
          <Grid container spacing={2} wrap="nowrap">
            <Grid item>
              <div>
                <Stack spacing={1}>
                  {unit.lines?.length > 0 && getProducts(unit.lines, true).map((product, index) => (
                    <div key={`product_in_${unit.id}_${product.productId}_${index}`}>
                      <ProductSnip key={`in_${unit.id}_${product.productId}_${index}`} image={product.thumbnail || product.image} description={product.description} gtin={product.gtin} promoterInternalCode={unit.promoterInternalCode} />
                    </div>
                  ))}
                </Stack>
              </div>
            </Grid>
            <Grid item>
              <div>
                <Stack spacing={1}>
                  {unit.lines?.length > 0 && getProducts(unit.lines, false).map((product, index) => (
                    <div key={`product_in_${unit.id}_${product.productId}_${index}`}>
                      <ProductSnip key={`in_${unit.id}_${product.productId}_${index}`} image={product.thumbnail || product.image} description={product.description} gtin={product.gtin} promoterInternalCode={unit.promoterInternalCode} />
                    </div>
                  ))}
                </Stack>
              </div>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
    </div>
  );
}

export default withStyles(BusinessUnitCard, styles);

const getProducts = (lines, isInput) => {
  const products = [];
  lines.forEach(line => {
    if (isInput && line.inputs?.length > 0) {
      line.inputs.forEach(p => {
        if (p.product) products.push(p.product);
      });
    } else if (!isInput && line.outputs?.length > 0) {
      line.outputs.forEach(p => {
        if (p.product) products.push(p.product);
      });
    }
  });
  return products;
}
export const styles = (theme) => ({
  root: {
    border: "1px solid #DDD",
    marginBottom: theme.spacing(1),
    width: "100%",
    padding: theme.spacing(1),
    borderRadius: 4,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.back,
    }
  }
});
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const orderConfirmDeliveryCall = async (dispatch, { orderId, totalPallets }) => {
  let rh = requestHelper(dispatch, "ORDER_CONFIRM_DELIVERY");
  try {
    await call({
      type: "ORDER",
      subType: "CONFIRM_DELIVERY",
      request: {
        orderId,
        totalPallets: {
          true: totalPallets,
          false: 0,
        },
      },
    });
    setTimeout(() => {
      dispatch(setRedirect(`/orders/placed`));
      dispatch(setSuccess("orders", "ORDER_CONFIRM_DELIVERY"));
    }, 300);
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
};

export default orderConfirmDeliveryCall;

import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const orderModifyQuantityAcceptCall = async (dispatch, { products }) => {
  let rh = requestHelper(dispatch, "ORDER_MODIFY_QUANTITY_ACCEPT");
  try {
    await call({
      type: "ORDER",
      subType: "MODIFY_QUANTITY_ACCEPT",
      request: {
        products,
      },
    });
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
};

export default orderModifyQuantityAcceptCall;
import { Chip, Stack, Tooltip } from "@mui/material";
import moment from "moment";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from "../../../../../common/displays/CustomTooltip/CustomTooltip";

export function listColumns({ t, validation, product, onOtherTaxClick }) {
  let columns = [];
  columns.push({
    field: "empty",
    align: "left",
    headerAlign: "left",
    customValue: () => {
      return ""
    }
  });
  columns.push({
    field: "code",
    align: "right",
    headerAlign: "right",
    width: 200,
    customValue: (v) => {
      return t('common.Enums.taxGroup.' + (v.code || v.process?.other?.code))
    }
  });
  columns.push({
    field: "value",
    align: "right",
    headerAlign: "right",
    width: 300,
    customValue: (v) => {
      let ogOther = (product.ogTax?.other || []).find(item => item.code === v.code);
      let processTax = v?.process?.other || {};
      let content = [];

      if (v.hasChanges && !validation) {
        if (ogOther) {
          content.push(
            <Chip
              label={`${ogOther.value || "0"} ${ogOther.type === "A" ? "€" : "%"}`}
              variant="filled"
              color="primary"
            />)
          content.push(
            <ArrowRightAltIcon style={{ display: "block" }} color="info" />
          )
        }
        content.push(<Chip
          label={`${v.value || "0"} ${v.type === "A" ? "€" : "%"}`}
          variant="outlined"
          color="info"
          onClick={() => onOtherTaxClick(product, v)}
        />)
        if (moment(v.startDate).isAfter(moment().startOf('day'))) {
          content.push(
            <Tooltip title={t("products.ProductTaxList.columns.startDateWarn", { date: moment(v.startDate).format("yyyy-MM-DD") })}>
              <WarningAmberIcon style={{ display: "block" }} color="info" />
            </Tooltip>
          )
        }
      }
      else if (v?.process?.status === "PENDING") {
        if (ogOther.value) {
          content.push(
            <Chip
              label={`${ogOther.value || "0"} ${ogOther.type === "A" ? "€" : "%"}`}
              variant="filled"
              color="primary"
            />
          );
          content.push(
            <ArrowRightAltIcon style={{ display: "block" }} color="warning" />
          );
        }
        if (processTax.value && processTax.value > 0) {
          content.push(
            <Chip
              label={`${processTax.value || "0"} ${processTax.type === "A" ? "€" : "%"}`}
              variant="filled"
              color="warning"
            />
          )
        }
        else {
          content.push(
            <Chip
              label={t("products.ProductTaxList.columns.removed")}
              variant="filled"
              color="warning"
            />
          )
        }
        if (moment(processTax.startDate).isAfter(moment().startOf('day'))) {
          content.push(
            <Tooltip title={t("products.ProductTaxList.columns.startDateWarn", { date: moment(processTax.startDate).format("yyyy-MM-DD") })}>
              <WarningAmberIcon style={{ display: "block" }} color="warning" />
            </Tooltip>
          )
        }
      }
      else if (v?.process?.status === "REFUSED") {
        if (ogOther.value) {
          content.push(
            <Chip
              label={`${ogOther.value || "0"} ${ogOther.type === "A" ? "€" : "%"}`}
              variant="filled"
              color="primary"
            />
          );
          content.push(
            <ArrowRightAltIcon style={{ display: "block" }} color="primary" />
          );
        }
        content.push(
          <Tooltip title="REFUSED">
            <Chip
              label={`${processTax.value || "0"} ${processTax.type === "A" ? "€" : "%"}`}
              style={{ textDecoration: "line-through" }}
              variant={ogOther.value ? "outlined" : "filled"}
              onClick={ogOther.value ? () => onOtherTaxClick(product, v) : undefined}
              color="error"
            />
          </Tooltip>)
      }
      else if (v?.process?.status === "CANCELLED" && !v?.process?.removeDate) {
        if (ogOther.value) {
          content.push(
            <Chip
              label={`${ogOther.value || "0"} ${ogOther.type === "A" ? "€" : "%"}`}
              variant="filled"
              color="primary"
            />
          );
          content.push(
            <ArrowRightAltIcon style={{ display: "block" }} color="primary" />
          );
        }
        content.push(
          <Tooltip title="CANCELLED">
            <Chip
              label={`${processTax.value || "0"} ${processTax.type === "A" ? "€" : "%"}`}
              style={{ textDecoration: "line-through" }}
              variant={ogOther.value ? "outlined" : "filled"}
              onClick={ogOther.value ? () => onOtherTaxClick(product, v) : undefined}
              color="error"
            />
          </Tooltip>
        )
        // if (processTax.value && processTax.value > 0) {
        // }
        // else {
        //   content.push(
        //     <Tooltip title="CANCELLED">
        //       <Chip
        //         label={t("products.ProductTaxList.columns.removed")}
        //         style={{ textDecoration: "line-through" }}
        //         variant="outlined"
        //         color="error"
        //       />
        //     </Tooltip>
        //   )
        // }
        // content.push(

        // )
      }
      else if (processTax.startDate && moment(processTax.startDate).isAfter(moment().startOf('day'))) {
        if (ogOther.value) {
          content.push(
            <Chip
              label={`${ogOther.value || "0"} ${ogOther.type === "A" ? "€" : "%"}`}
              variant="filled"
              color="primary"
            />
          );
          content.push(
            <ArrowRightAltIcon style={{ display: "block" }} color="primary" />
          );
        }
        content.push(
          <Chip
            label={`${processTax.value || "0"} ${processTax.type === "A" ? "€" : "%"}`}
            variant="filled"
            color="primary"
          />
        )
        if (moment(processTax.startDate).isAfter(moment().startOf('day'))) {
          content.push(
            <Tooltip title={t("products.ProductTaxList.columns.startDateWarn", { date: moment(processTax.startDate).format("yyyy-MM-DD") })}>
              <WarningAmberIcon style={{ display: "block" }} color="primary" />
            </Tooltip>
          )
        }
      }
      else if (v?.process?.removeDate && v?.process?.status !== "CANCELLED") {
        if (ogOther.value && ogOther.value > 0) {
          content.push(
            <Chip
              label={`${ogOther.value || "0"} ${ogOther.type === "A" ? "€" : "%"}`}
              variant="filled"
              color="primary"
            />
          );
        }
        else {
          content.push(
            <Chip
              label={t("products.ProductTaxList.columns.removed")}
              variant="filled"
              color="error"
            />
          );
        }
        if (moment(v?.process?.removeDate).isAfter(moment().startOf('day'))) {
          content.push(
            <Tooltip title={t("products.ProductTaxList.columns.removeDateWarn", { date: moment(v?.process?.removeDate).format("yyyy-MM-DD") })}>
              <WarningAmberIcon style={{ display: "block" }} color="error" />
            </Tooltip>
          );
        }
      }
      else {
        content.push(<>
          <Chip
            label={`${v.value || "0"} ${v.type === "A" ? "€" : "%"}`}
            variant="outlined"
            color={v.hasChanges ? "info" : "primary"}
            onClick={validation ? undefined : () => onOtherTaxClick(product, v)}
          />
        </>)
      }

      if (v?.process?.comment) {
        content.push(
          <CustomTooltip title={v?.process?.comment} placement="left">
            <InfoOutlinedIcon style={{ display: "block" }} color={v?.process?.status === "REFUSED" ? "error" : "info"} />
          </CustomTooltip>
        );
      }

      return (
        <Stack direction="row" spacing={1} alignItems="center" style={{ width: "100%" }} justifyContent="flex-end">
          {content.map((item, index) => <div key={v.priceId + "-" + index}>{item}</div>)}
        </Stack>
      );
    }
  });

  return columns;
}

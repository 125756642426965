import { createSlice } from "@reduxjs/toolkit";
import taskListCall from "./_sliceCalls/taskListCall";
import { taskListCommentsDefaultFilter, taskListDefaultFilter } from "./_common/tasksFilters";
import { appendItemsToList } from "../../common/utils";
import taskCreateCall from "./_sliceCalls/taskCreateCall";
import taskActionCall from "./_sliceCalls/taskActionCall";
import taskAssignCall from "./_sliceCalls/taskAssignCall";
import taskListCommentsCall from "./_sliceCalls/taskListCommentsCall";
import taskGetCall from "./_sliceCalls/taskGetCall";
import resourceList_fullCall from "./_sliceCalls/resourceList_fullCall";
import placeList_fullCall from "./_sliceCalls/placeList_fullCall";

export const tasksSlice = createSlice({
  name: "tasks",
  initialState: {
    taskList: [],
    taskListTotalRows: 0,
    taskListFilter: taskListDefaultFilter,
    taskListComments: [],
    taskListCommentsTotalRows: 0,
    taskListCommentsFilter: taskListCommentsDefaultFilter,
    taskDetails: null,
    resources: [],
    places: [],
  },
  reducers: {
    clearTaskList: (state) => {
      state.taskList = [];
    },

    appendToTaskList: (state, action) => {
      appendItemsToList(state.taskList, action.payload, true, "id");
    },

    setTaskListTotalRows: (state, action) => {
      state.taskListTotalRows = action.payload;
    },

    setTaskListFilter: (state, action) => {
      state.taskListFilter = action.payload;
    },

    clearTaskListComments: (state) => {
      state.taskListComments = [];
    },

    appendToTaskListComments: (state, action) => {
      appendItemsToList(state.taskListComments, action.payload, true, "id");
    },

    setTaskListCommentsTotalRows: (state, action) => {
      state.taskListCommentsTotalRows = action.payload;
    },

    setTaskListCommentsFilter: (state, action) => {
      state.taskListCommentsFilter = action.payload;
    },

    setTaskDetails: (state, action) => {
      state.taskDetails = action.payload;
    },

    setResources: (state, action) => {
      state.resources = action.payload;
    },

    setPlaces: (state, action) => {
      state.places = action.payload;
    },
  },
});

export default tasksSlice.reducer;


// SELECTS
export const selectTaskList = (state) => state.tasks.taskList;
export const selectTaskListTotalRows = (state) => state.tasks.taskListTotalRows;
export const selectTaskListFilter = (state) => state.tasks.taskListFilter;
export const selectTaskListComments = (state) => state.tasks.taskListComments;
export const selectTaskListCommentsTotalRows = (state) => state.tasks.taskListCommentsTotalRows;
export const selectTaskListCommentsFilter = (state) => state.tasks.taskListCommentsFilter;
export const selectTaskDetails = (state) => state.tasks.taskDetails;
export const selectResources = (state) => state.tasks.resources;
export const selectPlaces = (state) => state.tasks.places;

// CALLS
export const taskList = (filter, clear = false) => (dispatch, getState) => {
  taskListCall(tasksSlice.actions, dispatch, { filter, clear }, getState());
};

export const taskListComments = (filter, clear = false) => (dispatch, getState) => {
  taskListCommentsCall(tasksSlice.actions, dispatch, { filter, clear }, getState());
};

export const taskCreate = ({ name, info, dueDate, userId, groupId, sectorCode, entityId }) => (dispatch) => {
  taskCreateCall(dispatch, { name, info, dueDate, userId, groupId, sectorCode, entityId });
};

export const taskAction = ({ taskId, status, comment, payload, dueDate }) => (dispatch, getState) => {
  taskActionCall(dispatch, getState(), { taskId, status, comment, payload, dueDate });
};

export const taskAssign = ({ taskId, entityId, userId, groupId, sectorCode }) => (dispatch, getState) => {
  taskAssignCall(tasksSlice.actions, dispatch, getState(), { taskId, entityId, userId, groupId, sectorCode });
};

export const taskGet = ({ taskId }) => (dispatch) => {
  taskGetCall(tasksSlice.actions, dispatch, { taskId });
};

export const resourceListFull = () => (dispatch) => {
  resourceList_fullCall(tasksSlice.actions, dispatch);
};

export const placeListFull = () => (dispatch) => {
  placeList_fullCall(tasksSlice.actions, dispatch);
};
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Avatar, Chip, Divider, Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';

import { styles } from "./BusinessUnitSteps.styles";
import SortableTabs from "./SortableTabs/SortableTabs";
import CreateStepModal from "./CreateStepModal/CreateStepModal";
import StepActions from "./StepActions/StepActions";
import ConfirmRemoveModal from "./ConfirmRemoveModal/ConfirmRemoveModal";
import SetConditionFormModal from "./SetConditionFormModal/SetConditionFormModal";
import TaskListItem from "./TaskListItem/TaskListItem";
import ConditionItem from "./ConditionItem/ConditionItem";

import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { businessUnitChangeStepsOrder, businessUnitCreateStep, businessUnitDeleteStep, businessUnitDeleteStepTask, businessUnitDisableStepTask, businessUnitEnableStepTask, businessUnitGet, businessUnitSteps, businessUnitTasks, businessUnitUpdateStep, placeList_Full, selectBusinessUnitDetails, selectBusinessUnitSteps, selectBusinessUnitTasks, selectPlacesListFull } from "../businessUnitsSlice";
import ConfirmRemoveTaskModal from "./ConfirmRemoveTaskModal/ConfirmRemoveTaskModal";
import BusinessUnitsHeaderMenu from "../_common/BusinessUnitsHeaderMenu/BusinessUnitsHeaderMenu";
import { multiLanguagePropToString } from "../../../common/utils";


function BusinessUnitSteps({ classes, internal = false, placeTasks = false }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const steps = useSelector(selectBusinessUnitSteps)
  const [tab, setTab] = useState(0);
  const [stepModal, setStepModal] = useState(false);
  const [conditionModal, setConditionModal] = useState(false);
  const [conditionType, setConditionType] = useState("start");
  const [confirmRemoveModal, setConfirmRemoveModal] = useState(false);
  const [confirmRemoveTaskModal, setConfirmRemoveTaskModal] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState();
  const navigate = useNavigate();
  const { businessUnitId, lineId } = useParams();
  const [stepToEdit, setStepToEdit] = useState();
  const businessUnitDetails = useSelector(selectBusinessUnitDetails);
  const businessUnitTasksList = useSelector(selectBusinessUnitTasks);
  const places = useSelector(selectPlacesListFull);

  const selectedStep = useMemo(() => {
    return (steps || [])?.[tab];
  }, [tab, steps]);

  // console.log('steps: %o', steps);
  // console.log('businessUnitDetails: %o', businessUnitDetails);
  // console.log('selectedStep: %o', selectedStep);

  useFirstLoad(() => {
    dispatch(businessUnitGet({ id: businessUnitId }));
    dispatch(placeList_Full());
    if (placeTasks) {
      dispatch(businessUnitTasks({ businessUnitId }))
    }
    else {
      dispatch(businessUnitSteps({ businessUnitId, lineId, internal }))
    }
  })

  const createStepSubmitHandler = (values) => {
    console.log('createStepSubmitHandler - values: %o', values);
    if (values.id) {
      dispatch(businessUnitUpdateStep({ ...values, businessUnitId, lineId, internal }));
    }
    else {
      dispatch(businessUnitCreateStep({ ...values, businessUnitId, lineId, internal }));
    }
  }

  const setNewOrderHandler = (items) => {
    // console.log('--> items: %o', items);
    let nItems = [];
    items.forEach(item => {
      nItems.push(steps.find(step => step.id === item.value))
    })
    // console.log('--> nItems: %o', nItems);

    dispatch(businessUnitChangeStepsOrder(nItems));
  }

  const onRemoveConfirmHandler = () => {
    dispatch(businessUnitDeleteStep({ id: selectedStep?.id, businessUnitId, lineId, internal }));
    setConfirmRemoveModal(false);
    setTab(0);
  }

  const onCreateHandler = () => {
    setStepModal(true);
    setStepToEdit();
  }

  const onEditHandler = () => {
    setStepModal(true)
    setStepToEdit(selectedStep);
  }

  const startConditionsCreateHandler = () => {
    setConditionType("start");
    setConditionModal(true);
  }

  const stopConditionsCreateHandler = () => {
    setConditionType("stop");
    setConditionModal(true);
  }

  const onAddConditionHandler = (values) => {
    console.log('selectedStep: %o', selectedStep);
    let nStep = {
      ...selectedStep,
      businessUnitId,
      lineId,
      internal,
      places: undefined,
    }
    if (conditionType === "start") {
      nStep.startConditions = [...(selectedStep.startConditions || []), values]
    }
    if (conditionType === "stop") {
      nStep.stopConditions = [...(selectedStep.stopConditions || []), values]
    }
    dispatch(businessUnitUpdateStep(nStep));
    setConditionModal(false);
  }

  const onDisableTaskHandler = (task) => {
    const { id, placeId } = task;
    console.log('task: %o', task);
    dispatch(businessUnitDisableStepTask({ id, businessUnitId, lineId, internal, placeId }));
  }

  const onEnableTaskHandler = (task) => {
    const { id, placeId } = task;
    console.log('task: %o', task);
    dispatch(businessUnitEnableStepTask({ id, businessUnitId, lineId, internal, placeId }));
  }

  const onRemoveTaskHandler = (task) => {
    setConfirmRemoveTaskModal(true);
    setTaskToDelete(task);
  }

  const onRemoveTaskConfirmHandler = () => {
    const { id, placeId } = taskToDelete;
    dispatch(businessUnitDeleteStepTask({ id, businessUnitId, lineId, internal, placeId }))
    setConfirmRemoveTaskModal(false);
    setTaskToDelete();
  }

  return (
    <Page
      // permission={"promoter.actions.businessUnit.list"}

      header={<PageTitle
        title={businessUnitDetails?.name}
        onBack={() => navigate('/business/units')}
        actions={
          <Grid container direction="row" spacing={2}>
            {/* <Permission code={"promoter.actions.businessUnit.create"}> */}
            <Grid item>
              {/* <Button variant="outlined" size="large" onClick={onAddHandler} disabled={loading}><AddIcon fontSize="inherit" /></Button> */}
            </Grid>
            {/* </Permission> */}
          </Grid>
        }
      />}>
      <Stack spacing={1} >
        <BusinessUnitsHeaderMenu businessUnitId={businessUnitId} selected={placeTasks ? "placeTasks" : internal ? "internal" : "steps"} />
        <Stack spacing={0} >
          {!placeTasks &&
            <Stack direction="row" spacing={0} alignItems="center" className={classes.tabs} justifyContent="space-between">
              <Stack direction="row" spacing={1} alignItems="center">
                {
                  steps?.length > 0 ?
                    <SortableTabs tab={tab} setTab={setTab} items={steps.map(({ name, id, active }) => ({ text: name, value: id, active }))} setItems={setNewOrderHandler} /> :
                    <Typography style={{ marginLeft: 16 }} color="textSecondary">{t("businessUnits.BusinessUnitSteps.details.stepsEmpty")}</Typography>
                }
                <IconButton size="small" className={classes.circleBtn} onClick={onCreateHandler}>
                  <AddIcon fontSize="small" />
                </IconButton>
              </Stack>
              <div style={{ marginRight: 8 }}>
                {selectedStep && <StepActions step={selectedStep} internal={internal} businessUnitId={businessUnitId} lineId={lineId} onRemove={() => setConfirmRemoveModal(true)} onEdit={onEditHandler} />}
              </div>
            </Stack>
          }
          {/* PLACE TASKS */}
          {
            placeTasks &&
            <Paper elevation={0} className={classes.paper}>
              <Grid container spacing={2}>
                {(places || []).filter(item => (businessUnitDetails?.places || []).some(p => p === item.id)).map(place => (
                  <Grid item xs={12} sm={6} key={place.id}>
                    <Stack>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Chip size="small" avatar={<Avatar>{place.type}</Avatar>} label={place.title} />
                          <Typography variant="h4" style={{ marginBottom: 0 }}>{t("businessUnits.BusinessUnitSteps.details.placesTasksTitle")}</Typography>
                        </Stack>
                        <IconButton size="small" className={classes.circleBtn} onClick={() => navigate(`/business/units/${businessUnitId}/${place.id}/placetask/create`)} >
                          <AddIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                      <Divider />
                    </Stack>
                    {(businessUnitTasksList || []).filter(item => item.placeId === place.id)?.length > 0 ?
                      <Stack >
                        {processTasks((businessUnitTasksList || []).filter(item => item.placeId === place.id), i18n).map(task => (
                          <div key={task.id}>
                            <TaskListItem
                              task={task}
                              onTaskClick={(taskId) => navigate(`/business/units/${businessUnitId}/${place.id}/placetask/edit/${taskId}`)}
                              onDisable={onDisableTaskHandler}
                              onEnable={onEnableTaskHandler}
                              onRemove={onRemoveTaskHandler}
                            />
                          </div>
                        ))}
                      </Stack> :
                      <Typography color="textSecondary">{t("businessUnits.BusinessUnitSteps.details.placesTasksEmpty")}</Typography>
                    }
                  </Grid>
                ))}
              </Grid>
            </Paper>
          }
          {/* STEP TASKS */}
          {selectedStep && !placeTasks &&
            <Paper elevation={0} className={classes.paper}>
              <Grid container spacing={2}>

                <Grid item xs={12} sm={3}>

                  <Stack spacing={1}>
                    {/* START CONDITIONS */}
                    <Stack>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h4" style={{ marginBottom: 0 }}>{t("businessUnits.BusinessUnitSteps.details.startConditionsTitle")}</Typography>
                        <IconButton size="small" className={classes.circleBtn} onClick={startConditionsCreateHandler}>
                          <AddIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                      <Divider />
                    </Stack>
                    {selectedStep.startConditions?.length > 0 ?
                      <Stack spacing={1}>
                        {(selectedStep.startConditions || []).map((condition, index) => (
                          <div key={"startCondition_" + index}>
                            <ConditionItem condition={condition} />
                          </div>
                        ))}
                      </Stack> :
                      <Typography color="textSecondary">{t("businessUnits.BusinessUnitSteps.details.startConditionsEmpty")}</Typography>
                    }

                    {/* STOP CONDITIONS */}
                    <Stack>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h4" style={{ marginBottom: 0 }}>{t("businessUnits.BusinessUnitSteps.details.stopConditionsTitle")}</Typography>
                        <IconButton size="small" className={classes.circleBtn} onClick={stopConditionsCreateHandler}>
                          <AddIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                      <Divider />
                    </Stack>
                    {selectedStep.stopConditions?.length > 0 ?
                      <Stack spacing={1}>
                        {(selectedStep.stopConditions || []).map((condition, index) => (
                          <div key={"stopCondition_" + index}>
                            <ConditionItem condition={condition} />
                          </div>
                        ))}
                      </Stack> :
                      <Typography color="textSecondary">{t("businessUnits.BusinessUnitSteps.details.stopConditionsEmpty")}</Typography>
                    }
                  </Stack>

                </Grid>
                <Grid item xs={12} sm={9}>
                  <Stack spacing={1}>

                    {/* BATCHES TASK */}
                    <Stack>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h4" style={{ marginBottom: 0 }}>{t("businessUnits.BusinessUnitSteps.details.batchesTasksTitle")}</Typography>
                        {selectedStep.startConditions?.length > 0 && selectedStep.stopConditions?.length > 0 &&
                          <IconButton size="small" className={classes.circleBtn} onClick={() => navigate(`/business/units/${businessUnitId}/${lineId}/steps/${selectedStep?.id}/${internal ? "internal/" : ""}batchtask/create`)}>
                            <AddIcon fontSize="small" />
                          </IconButton>
                        }
                      </Stack>
                      <Divider />
                    </Stack>
                    {selectedStep.startConditions?.length > 0 && selectedStep.stopConditions?.length > 0 && selectedStep.batchTasks?.length > 0 ?
                      <Stack>
                        {
                          processTasks(selectedStep.batchTasks, i18n).map(task => (
                            <div key={task.id}>
                              <TaskListItem
                                task={task}
                                onTaskClick={(taskId) => navigate(`/business/units/${businessUnitId}/${lineId}/steps/${selectedStep?.id}/${internal ? "internal/" : ""}batchtask/edit/${taskId}`)}
                                onDisable={onDisableTaskHandler}
                                onEnable={onEnableTaskHandler}
                                onRemove={onRemoveTaskHandler}
                              />
                            </div>))
                        }
                      </Stack> :
                      <Typography color="textSecondary">{t("businessUnits.BusinessUnitSteps.details.batchesTasksEmpty")}</Typography>
                    }

                    {/* ALERTS */}
                    {/* <Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography variant="h4" style={{ marginBottom: 0 }}>{t("businessUnits.BusinessUnitSteps.details.alertsTitle")}</Typography>
                      <IconButton size="small" className={classes.circleBtn}>
                        <AddIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                    <Divider />
                  </Stack>
                  <Typography color="textSecondary">{t("businessUnits.BusinessUnitSteps.details.alertsEmpty")}</Typography> */}
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          }
        </Stack>
      </Stack>
      <CreateStepModal open={stepModal} setOpen={setStepModal} onSubmit={createStepSubmitHandler} step={stepToEdit} />
      <ConfirmRemoveModal open={confirmRemoveModal} setOpen={setConfirmRemoveModal} onRemoveConfirm={onRemoveConfirmHandler} />
      <ConfirmRemoveTaskModal open={confirmRemoveTaskModal} setOpen={setConfirmRemoveTaskModal} task={taskToDelete} onRemoveConfirm={onRemoveTaskConfirmHandler} />
      <SetConditionFormModal open={conditionModal} setOpen={setConditionModal} type={conditionType} onSubmit={onAddConditionHandler} />
    </Page>
  );
}

export default withStyles(BusinessUnitSteps, styles);

const processTasks = (tasks, i18n) => {
  return [...(tasks || [])].sort((a, b) => {
    if (a.active === b.active) {
      let aName = multiLanguagePropToString(a.name, i18n);
      let bName = multiLanguagePropToString(b.name, i18n);
      return aName.localeCompare(bName);
    }
    return a.active ? -1 : 1;
  });
}
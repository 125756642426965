import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const productDpromActionCall = async (
  { clearTempValidation },
  dispatch,
  _,
  dproms
) => {
  let rh = requestHelper(dispatch, "PRODUCT_DPROM_ACTION");
  // let filter = state.productsDproms.dpromsListFilter;

  let actions = [];
  Object.keys(dproms).forEach(id => {
    let { valid, comment, taskId } = dproms[id];
    if (valid !== undefined && taskId) {
      let req = actions.find(item => item.taskId === taskId);
      if (req) {
        req.promoDiscounts.push({
          promoDiscountId: id,
          comment,
          status: valid ? "ACCEPTED" : "REFUSED"
        })
      }
      else {
        actions.push({
          taskId,
          promoDiscounts: [{
            promoDiscountId: id,
            comment,
            status: valid ? "ACCEPTED" : "REFUSED"
          }]
        })
      }
    }
  })

  try {
    let result = await call({
      type: "PRODUCT",
      subType: "DPROM_ACTION",
      request: {
        actions
      }
    });
    setTimeout(() => {
      if (result) {
        dispatch(setSuccess("productsDproms", "PRODUCT_DPROM_ACTION"));
        dispatch(clearTempValidation());
        // dispatch(productDpromList(filter, true))
      }
    }, 300);

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productDpromActionCall;

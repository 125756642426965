

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const productCreateTaxCall = async (
  { clearTempProductTax },
  dispatch,
  state
) => {
  let rh = requestHelper(dispatch, "PRODUCT_CREATE_TAX");

  let tempTaxes = state.products.tempProductTax;
  let taxes = [];
  Object.keys(tempTaxes).forEach(productId => {
    const productTax = tempTaxes[productId];
    const { vat, startDate, other } = productTax;
    taxes.push({
      productId,
      taxes: {
        vat,
        startDate,
        other: other?.filter(item => item.hasChanges)
      }
    })
  })

  try {
    let result = await call({
      type: "PRODUCT",
      subType: "CREATE_TAX",
      request: {
        taxes
      },
    });
    if (result) {
      dispatch(clearTempProductTax());
    }
    setTimeout(() => {
      if (result) {
        dispatch(setSuccess("products", "PRODUCT_CREATE_TAX"));
      }
    }, 300);

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productCreateTaxCall;

// taxes: [
//   {
//     productId: "",
//     taxes: {
//       vat: "",
//       other: [
//         {
//           code: "",
//           value: "",
//           type: "", // A | P
//           startDate: "",
//         }
//       ],
//       startDate: ""
//     }
//   }
// ]
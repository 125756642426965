import React from "react";
import { TextField, InputAdornment } from "@mui/material";

function CostInput({ classes, json, value, state, index, onChange, adornment, minmaxHeaders }) {
  const costChange = (v) => {
    let nValue = JSON.parse(JSON.stringify(json));
    if (!nValue[state]) {
      nValue[state] = [...minmaxHeaders].map((item) => {
        return { ...item, cost: 0 };
      });
    }
    nValue[state][index].cost = v;

    onChange && onChange(nValue);
  };

  return (
    <TextField
      value={value.cost}
      className={classes.minmaxInput}
      margin="none"
      variant="outlined"
      size="small"
      InputProps={
        adornment && {
          endAdornment: <InputAdornment position="end">{adornment}</InputAdornment>,
        }
      }
      onFocus={(e) => e.target && e.target.select && e.target.select()}
      onChange={(e) => costChange(e.target.value)}
    />
  );
}

export default CostInput;

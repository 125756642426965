import * as Yup from "yup";
import { TextField } from "../../../common/forms";

export function tenseFlowFormFields(t) {
  return [
    {
      size: 4,
      field: <TextField id="tenseFlow.room" type="text" label={t("operator.CostsForm.form.room")} required />,
    },
    {
      size: 4,
      field: (
        <TextField id="tenseFlow.chilled" type="text" label={t("operator.CostsForm.form.chilled")} required />
      ),
    },
    {
      size: 4,
      field: (
        <TextField id="tenseFlow.frozen" type="text" label={t("operator.CostsForm.form.frozen")} required />
      ),
    },
  ];
}

export function crossDockingFormFields(t) {
  return [
    {
      size: 4,
      field: (
        <TextField
          id="crossDocking.administrative"
          type="text"
          label={t("operator.CostsForm.form.administrative")}
          required
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField id="crossDocking.pallet" type="text" label={t("operator.CostsForm.form.pallet")} required />
      ),
    },
    {
      size: 4,
      field: (
        <TextField
          id="crossDocking.halfPallet"
          type="text"
          label={t("operator.CostsForm.form.halfPallet")}
          required
        />
      ),
    },
  ];
}

export function formSchema(t) {
  return Yup.object().shape({});
}



import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess, setRedirect } from "../../../app/coreSlice";

const resourceUpdateCall = async (
  dispatch,
  { id, title, type, placeId, parentId,state, height, width, depth, weight, attributes }
) => {
  let rh = requestHelper(dispatch, "RESOURCE_UPDATE");
  try {
    let result = await call({
      type: "RESOURCE",
      subType: "UPDATE",
      request: {
        id,
        title,
        type,
        placeId,
        parentId,
        state,
        height,
        width,
        depth,
        weight,
        attributes
      },
    });

    setTimeout(() => {
      if (result) {
        dispatch(setRedirect("/resources"));
        dispatch(setSuccess("resources", "RESOURCE_UPDATE"));
      }
    }, 300);

  } catch (ex) {
    rh.error("resources", ex);
  }
  rh.close();
};

export default resourceUpdateCall;


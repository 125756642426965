import {getPromoterConfig, requestHelper} from "../../../app/coreSlice";
import {call} from "../../../app/websocket/_sliceCalls/websocket";
import moment from "moment/moment";
import {asyncForEach} from "../../../common/utils";
import {processImageUrl} from "../../../api/s3";

const businessReportProductCall = async ({
  clearQueriesReportProductList,
  setQueriesReportProductList,
  setQueriesReportProductListTotalRows,
  setQueriesReportProductFilter
}, dispatch, state, { filter, clear }) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");

  let rh = requestHelper(dispatch, "BUSINESS_REPORT_PRODUCTS");
  if (clear) {
    dispatch(clearQueriesReportProductList());
  }

  const createdTimeIni = (filter.isDefaultFilter || !filter.createdTimeIni) ? moment().add(-1, "month").toISOString() : filter.createdTimeIni;
  const createdTimeEnd = (filter.isDefaultFilter || !filter.createdTimeEnd) ? moment().toISOString() : filter.createdTimeEnd;

  dispatch(setQueriesReportProductFilter({
    ...filter,
    createdTimeIni,
    createdTimeEnd
  }));

  try {
    const { limit, offset, sortField = "", sortType, search, createdByEntityId, createdBy } = filter;
    let result = await call({
      type: "BUSINESS",
      subType: "REPORT_PRODUCTS",
      request: {
        limit,
        offset,
        sortField: sortField.toUpperCase(),
        sortType,
        search,
        createdTimeIni,
        createdTimeEnd,
        createdByEntityId,
        createdBy
      },
    });

    if (result && result.products && result.products.length > 0) {

      let products = [];
      await asyncForEach(result.products, async (product) => {
        products.push({
          ...product,
          image: await processImageUrl(fileStorageConfigs,  product.image),
          thumbnail: await processImageUrl(fileStorageConfigs,  product.thumbnail),
        });
      });

      dispatch(setQueriesReportProductList(products));
      dispatch(setQueriesReportProductListTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default businessReportProductCall;
import React from "react";
import {useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";

import {styles} from "./PriceHistoryModal.styles";
import {usePromoter, useUser} from "../../../common/hooks";

import CustomModal from "../../../common/displays/CustomModal/CustomModal";
import {Grid, Stack} from "@mui/material";
import ProductSnip from "../../../common/displays/ProductSnip/ProductSnip";
import PriceHistoryDataGrid from "./ProductPriceHistory/PriceHistoryDataGrid";
import {
  selectPriceHistoryList,
  selectPriceHistoryListFilter,
  selectPriceHistoryListTotalRows
} from "../productsPricesSlice";

function PriceHistoryModal({ classes,  open, setOpen, product, onFilterChange }) {
  const {t} = useTranslation();
  const promoter = usePromoter();
  const user = useUser();
  const data = useSelector(selectPriceHistoryList);
  const totalRows = useSelector(selectPriceHistoryListTotalRows);
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];
  const showPromoterDescription = Boolean(enabledProductFields.includes("promoterDescription") && user?.entity?.type === "P" && product?.promoterDescription);
  const filterPriceHistory = useSelector(selectPriceHistoryListFilter);


  return (
      <CustomModal open={open} setOpen={setOpen} size="extraextralarge" height={700}
                   title={t("productsPrices.PriceHistoryModal.modal.title")}>
        <Stack spacing={1}>
          <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center">
            <Grid item>
              <ProductSnip
                  image={product?.thumbnail}
                  description={showPromoterDescription ? product?.promoterDescription : product?.description}
                  gtin={product?.gtin}
                  promoterInternalCode={product?.promoterInternalCode}
                  ownerName={product?.ownerName}
              />
            </Grid>
          </Grid>
          <Stack>
            <PriceHistoryDataGrid data={data} totalRows={totalRows} filter={filterPriceHistory} onFilterChange={onFilterChange}/>
          </Stack>
        </Stack>
      </CustomModal>
  );
}

export default withStyles(PriceHistoryModal, styles);
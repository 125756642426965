import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const businessReportSuppliersCall = async ({
  setReportSuppliersList,
  setReportSuppliersCostByDeliveryList,
  setReportSuppliersPlatformCostList,
  setReportSuppliersTransportCostList,
  setReportSuppliersFilter
}, dispatch, { filter }) => {
  let rh = requestHelper(dispatch, "BUSINESS_REPORT_SUPPLIERS");

  dispatch(setReportSuppliersFilter(filter));

  try {
    const { limit, offset, sortField = "", sortType, month, year, supplier } = filter;
    let result = await call({
      type: "BUSINESS",
      subType: "REPORT_SUPPLIERS",
      request: {
        limit,
        offset,
        sortField: sortField.toUpperCase(),
        sortType,
        month,
        year,
        supplier
      },
    });

    if (result) {
      if (result.report && result.report.length > 0) {
        dispatch(setReportSuppliersList(result.report));
      }
      if (result.costByDeliveryPlace && result.costByDeliveryPlace.length > 0) {
        dispatch(setReportSuppliersCostByDeliveryList(result.costByDeliveryPlace));
      }
      if (result.platformCostItems && result.platformCostItems.length > 0) {
        dispatch(setReportSuppliersPlatformCostList(result.platformCostItems));
      }
      if (result.transportCostItems && result.transportCostItems.length > 0) {
        dispatch(setReportSuppliersTransportCostList(result.transportCostItems));
      }
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default businessReportSuppliersCall;

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Checkbox, Grid, Typography } from "@mui/material";

import { styles } from "./SustainabilityDiff.styles";
import LabelValue from "../../../../../common/displays/LabelValue/LabelValue";

function SustainabilityDiff({ classes, value, newValue, hasDiff, onCheck, checked, viewMode }) {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container direction="column">
        <Grid item
          className={classes.headerContainer}>
          <Typography variant="h4" className={classes.header}>
            {t("products.ProductValidationForm.section.sustainability")}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2} wrap="nowrap">
            <Grid item flex={1}>
              <Grid container direction="column" spacing={2}>
                {value.map(({
                  packagingType,
                  packagingMaterialComponentType,
                  packagingMaterialType,
                  otherPackagingMaterials,
                  packagingColor,
                  opacity,
                  recyclingDestination,
                  packagingWeight,
                  quantity,
                  plasticDensity
                }) => (

                  <Grid item key={packagingType}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography>{t("common.Enums.packagingType." + packagingType)}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.packagingMaterialComponentType")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {packagingMaterialComponentType ? t("common.Enums.packagingMaterialComponentType." + packagingMaterialComponentType) : "-"}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.packagingMaterialType")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {packagingMaterialType || "-"}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.otherPackagingMaterials")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {otherPackagingMaterials || "-"}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.packagingColor")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {packagingColor || "-"}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.opacity")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {opacity || "-"}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.recyclingDestination")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {recyclingDestination || "-"}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.packagingWeight")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {packagingWeight ? (
                                <Typography variant="body1" align="left" component="span">
                                  {packagingWeight?.value}
                                  {" " + t("common.Enums.measureUnits." + packagingWeight?.unit)}
                                </Typography>
                              ) : (
                                "N/D"
                              )}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.quantity")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {quantity || "-"}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.plasticDensity")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {plasticDensity || "-"}
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item flex={1}>
              <Grid container direction="column" spacing={2}>
                {newValue.map(({
                  packagingType,
                  packagingMaterialComponentType,
                  packagingMaterialType,
                  otherPackagingMaterials,
                  packagingColor,
                  opacity,
                  recyclingDestination,
                  packagingWeight,
                  quantity,
                  plasticDensity
                }) => (

                  <Grid item key={packagingType}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography>{t("common.Enums.packagingType." + packagingType)}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.packagingMaterialComponentType")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {packagingMaterialComponentType || "-"}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.packagingMaterialType")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {packagingMaterialType || "-"}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.otherPackagingMaterials")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {otherPackagingMaterials || "-"}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.packagingColor")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {packagingColor || "-"}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.opacity")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {opacity || "-"}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.recyclingDestination")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {recyclingDestination || "-"}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.packagingWeight")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {packagingWeight ? (
                                <Typography variant="body1" align="left" component="span">
                                  {packagingWeight?.value}
                                  {" " + t("common.Enums.measureUnits." + packagingWeight?.unit)}
                                </Typography>
                              ) : (
                                "N/D"
                              )}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.quantity")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {quantity || "-"}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabelValue
                          label={t("products.ProductDetails.details.plasticDensity")}
                          value={
                            <Typography variant="body1" align="left" component="span">
                              {plasticDensity || "-"}
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item style={{ width: 56 }}>
              {hasDiff && !viewMode && (
                <Checkbox color="success" checked={checked} onChange={(e) => onCheck(e.target.checked)} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(SustainabilityDiff, styles);
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { IconButton, Tooltip } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useTranslation } from "react-i18next";
import moment from "moment";

import { styles } from "./PriceImport.styles";

import { processPricesImport } from "../../../_auxiliars";
import { usePromoter, useUser } from "../../../../../common/hooks";
import { addPromotion, addTempPrice, selectLocations, updatePromotion } from "../../../productsPricesSlice";
import { csvFileToJson, guid } from "../../../../../common/utils";
import { setSuccess } from "../../../../../app/coreSlice";
import RemovePricesConfirmModal from "./RemovePricesConfirmModal/RemovePricesConfirmModal";

function PriceImport({ classes, onVariantPriceChange, onStandardPriceChange, onVariantRetailPriceChange, onStandardRetailPriceChange }) {
  const { t } = useTranslation();
  const user = useUser();
  const promoter = usePromoter();
  const dispatch = useDispatch();
  const inputFileRef = useRef();
  const locations = useSelector(selectLocations);
  const [pricesToDelete, setPricesToDelete] = useState();
  const [confirmRemoveModal, setConfirmRemoveModal] = useState(false);

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let jsonData = await csvFileToJson(file);

      inputFileRef.current.value = "";
      let processedPrices = await processPricesImport(
        jsonData,
        t,
        dispatch,
        locations,
        user?.entity?.type === "P",
        promoter?.configs?.admin?.fileStorage,
        promoter?.getConfig("price", "allowRetailPrice"),
        promoter?.getConfig("price", "allowPricePromotions")
      );

      let priceId = guid();
      // NEW PRICES
      processedPrices.pricesToCreate.forEach(({ productId, locations, priceType, contactPoint, ownerId, owner, effectiveDate, expireDate, salePrice, promotions }) => {
        dispatch(addTempPrice({
          productId,
          priceId,
          isNew: true,
          priceType,
          newSalePrice: salePrice,
          locations: locations,
          effectiveDate: effectiveDate || moment().toISOString(),
          expireDate,
          ownerId,
          ownerName: owner,
          contactPoint,
          promotions,
        }));
      });
      // UPDATES
      processedPrices.pricesToUpdate.forEach(({ productId, priceId, salePrice, retailPrice, priceType, promotions }) => {
        // console.log('PRICE TO UPDATE: %o', { productId, priceId, salePrice, retailPrice, priceType, promotions });
        if (priceType === "S") {
          onStandardPriceChange && onStandardPriceChange({ productId, priceId, newSalePrice: salePrice });
          onStandardRetailPriceChange && onStandardRetailPriceChange({ productId, priceId, newRetailPrice: retailPrice });
        }
        else {
          onVariantPriceChange && onVariantPriceChange({ productId, priceId, newSalePrice: salePrice, priceType });
          onVariantRetailPriceChange && onVariantRetailPriceChange({ productId, priceId, newRetailPrice: retailPrice, priceType });
        }
        (promotions || []).forEach(promotion => {
          if (promotion.isNew) {
            dispatch(addPromotion({ productId, priceId, promotion }))
          }
          else if (promotion.hasChanges) {
            dispatch(updatePromotion({ productId, priceId, promotion }))
          }
        })
      });
      // DELETES
      // processedPrices.pricesToDelete.forEach(({ priceId, productId, ownerId, contactPoint, removeDate, ownerName }) => {
      //   dispatch(productRemovePrice({ priceId, productId, ownerId, contactPoint, removeDate, ownerName }));
      // });
      if (processedPrices.pricesToDelete?.length > 0) {
        setPricesToDelete(processedPrices.pricesToDelete);
        setConfirmRemoveModal(true);
      }
      const totalChanges = processedPrices.pricesToCreate?.length || 0 +
        processedPrices.pricesToUpdate?.length || 0 +
        processedPrices.pricesToDelete?.length || 0;
      dispatch(setSuccess("productsPrices", "PRODUCT_IMPORT_PRICE", { totalChanges }));
    }
  };

  const onImportClickHandler = () => {
    inputFileRef.current.click();
  }

  return (
    <div className={classes.root}>
      <Tooltip title={t("productsPrices.PricesList.header.tooltipImport")}>
        <IconButton id="priceImport" onClick={onImportClickHandler} size="small">
          <FileUploadIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <input
        accept=".csv,text/csv"
        style={{ display: "none" }}
        type="file"
        ref={inputFileRef}
        onChange={(e) => handleUpload(e)}
      />
      <RemovePricesConfirmModal open={confirmRemoveModal} setOpen={setConfirmRemoveModal} prices={pricesToDelete} />
    </div>
  );
}

export default withStyles(PriceImport, styles);
import React from "react";
import { Link, useParams } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText, Collapse, List } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

function NavLinkExpander({ icon, primary, to, children, activeClass, hidden, onClick, expanded }) {
  const { promotercode } = useParams();

  let toUrl = to;
  if (promotercode) {
    toUrl = "/" + promotercode + to;
  }

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => {
        return <Link ref={ref} to={toUrl} {...linkProps} />;
      }),
    [toUrl]
  );

  // let resolved = useResolvedPath(toUrl);
  // let match = useMatch({ path: resolved.pathname, end: false });

  return hidden ? null : (
    <li className={activeClass /*Boolean(match) ? activeClass : null*/}>
      <ListItem button component={to && CustomLink} onClick={onClick}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={primary} />
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="ul" disablePadding>
          {children}
        </List>
      </Collapse>
    </li>
  );
}

export default NavLinkExpander;

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { mappingBusinnessUnit } from "../_common/businessUnitsUtils";

const businessUnitGetCall = async (
  { setBusinessUnitDetails },
  dispatch,
  state,
  { id }
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_GET");
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "GET",
      request: {
        id
      },
    });

    if (result && result.businessUnit) {
      dispatch(setBusinessUnitDetails(await mappingBusinnessUnit(result.businessUnit, fileStorageConfigs, true)));
    }

  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitGetCall;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";

import { styles } from "./EntityAutoComplete.styles";
import AutoCompleteInput from "../../base/AutoCompleteInput";

import { loadEntitiesForAutoComplete, selectAutoCompleteEntities } from "../../../../features/entities/entitiesSlice";
import { Grid, Typography } from "@mui/material";
import { useFirstLoad } from "../../../hooks";

function EntityAutoComplete({
  id,
  classes,
  label,
  value,
  onChange,
  disabled,
  required,
  typeFilter = ["S", "W"], // "P", "S", "W", "LO", "ST"
}) {
  const dispatch = useDispatch();
  const entities = useSelector(selectAutoCompleteEntities);

  useFirstLoad(() => {
    dispatch(loadEntitiesForAutoComplete());
  });

  const options = (entities || [])
    .filter((item) => typeFilter.indexOf(item.type) > -1)
    .map((item) => {
      return {
        value: item.entityId,
        text: item.name + ", " + item.taxNumber,
        component: (
          <Grid container direction="column" className={classes.item}>
            <Grid item>
              <Typography className={classes.nomargin}>{item.name}</Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" className={classes.nomargin}>
                {item.taxNumber}
              </Typography>
            </Grid>
          </Grid>
        ),
      };
    });

  const onChangeHandler = (id) => {
    let entity = entities.find((item) => item.entityId === id);
    onChange && onChange(entity || null);
  };

  return <AutoCompleteInput
    id={id}
    label={label}
    value={value || ""}
    onChange={onChangeHandler}
    disabled={disabled}
    options={options || []}
    required={required}
    variant="outlined"
  />;
}

// classes, label, value, onChange, disabled, options, defaultValue

export default withStyles(EntityAutoComplete, styles);

import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const orderListCall = async ({ clearOrderList, setOrderListFilter, appendToOrderList, setOrderListTotalRows }, dispatch, { filter, clear }) => {
  let rh = requestHelper(dispatch, "ORDER_LIST");
  if (clear) {
    dispatch(clearOrderList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setOrderListFilter(filter));
  try {
    let list = await call({
      type: "ORDER",
      subType: "LIST",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    dispatch(appendToOrderList(list.orderDetails));
    dispatch(setOrderListTotalRows(list.rows));
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
}

export default orderListCall;

import * as Yup from "yup";
import {
  getCutOffTypeOptions, getDayOfMonthTypeOptions,
  getDayOfWeekTypeOptions,
  getFrequencyTypeOptions
} from "../../CutOffCreate/CutOffForm.options";
import {SelectField, TextField, TimePickerField} from "../../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    cutOffType: Yup.string().required(t("entities.CutOffs.scheduleCutOff.form_requiredCutOffType")),
    frequency: Yup.string().required(t("entities.CutOffs.scheduleCutOff.form_requiredFrequency")),
    time: Yup.string().required(t("entities.CutOffs.scheduleCutOff.form_requiredTime")),
  });
}

export function formFields(t, values) {
  return [
    {
      size: 12,
      field: (
        <TextField
          id="entity"
          disabled={true}
          type="text"
          label={t("entities.CutOffs.scheduleCutOff.entity")}
        />
      ),
    },
    {
      size: 12,
      field: (
        <SelectField
          id="cutOffType"
          label={t("entities.CutOffs.scheduleCutOff.type")}
          options={getCutOffTypeOptions(t)}
          required
        />
      ),
    },
    {
      size: 12,
      field: (
          <SelectField
              id="frequency"
              label={t("entities.CutOffs.scheduleCutOff.frequency")}
              options={getFrequencyTypeOptions(t)}
              required
          />
      ),
    },
    {
      size: 6,
      field: (
          <SelectField
              id="dayOfMonth"
              label={t("entities.CutOffs.scheduleCutOff.dayOfMonth")}
              options={getDayOfMonthTypeOptions(t)}
              required={values?.frequency === "dayOfMonth"}
              disabled={values?.frequency !== "dayOfMonth"}
          />
      ),
      hidden: values.frequency !== "dayOfMonth",
    },
    {
      size: 6,
      field: (
          <SelectField
              id="dayOfWeek"
              label={t("entities.CutOffs.scheduleCutOff.dayOfWeek")}
              options={getDayOfWeekTypeOptions(t)}
              required={values?.frequency === "dayOfWeek"}
              disabled={values?.frequency !== "dayOfWeek"}
          />
      ),
      hidden: values.frequency !== "dayOfWeek",
    },
    {
      size: 6,
      field: <TimePickerField id="time" label={t("entities.CutOffs.scheduleCutOff.time")} required timeSteps={{ hours: 1, minutes: 15 }} />,
    },
  ];
}

export const styles = (theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    width: "30%",
    margin: "0 auto",
    right: 10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: "90%",
    },
    zIndex: 100000000,
  },
  alert: {
    marginBottom: 10,
    color: "white",
  },
});

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./OrdersDataGrid.styles";
import { listColumns } from "./OrdersDataGrid.columns";
import OrdersDataGridActions from "./OrdersDataGrid.actions";
import { useNavigate, usePromoter } from "../../../../common/hooks";

function OrdersDataGrid({ classes, data, totalRows, onRowSelected, filter, onFilterChange }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const columns = listColumns(t, classes, promoter);
  const navigate = useNavigate();

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };
      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  const onIncidentHandler = (row) => {
    navigate("/orders-incidents/add/" + row.id);
  };

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          localFilters
          columns={columns}
          rows={data}
          onFilter={onFilterHandler}
          onLoadMore={onLoadMoreHandler}
          onRowSelected={onRowSelected}
          total={totalRows}
          actionsComponent={<OrdersDataGridActions onIncident={onIncidentHandler} />}
          actions
        />
      )}
    </div>
  );
}

export default withStyles(OrdersDataGrid, styles);

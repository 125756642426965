import React, { useMemo } from "react";
import { withStyles } from "tss-react/mui";
import { Paper } from "@mui/material";
import Chart from "react-apexcharts";

import { styles } from "./PlanningChart.styles";

function PlanningChart({ classes, data, years }) {
  const series = useMemo(() => {
    return (data || []).map(item => {
      return {
        name: item.description,
        data: years.map(year => {
          return item?.targets?.[year]?.value || 0;
        })
      }
    })
    // eslint-disable-next-line
  }, [data]);

  const options = {
    chart: {
      toolbar: {
        show: false
      },
    },
    xaxis: {
      categories: years,
    },
    dataLabels: {
      enabled: false // Disable data labels
    },
    plotOptions: {
      bar: {
        columnWidth: 30
      }
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Chart options={options} series={series} type="bar" height={350} />
      </Paper>
    </div>
  );
}

export default withStyles(PlanningChart, styles);
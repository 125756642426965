export const csvFields = (t) => {
  return [
    {
      field: "type",
      header: t("business.process.columns_type"),
    }, {
      field: "processType",
      header: t("business.process.columns_processType"),
    },{
      field: "processId",
      header: t("business.process.columns_processId"),
    },{
      field: "state",
      header: t("business.process.columns_state"),
    },{
      field: "status",
      header: t("business.process.columns_status"),
    },{
      field: "active",
      header: t("business.process.columns_active"),
    },{
      field: "createdTime",
      header: t("business.process.columns_createdTime"),
    },{
      field: "updateTime",
      header: t("business.process.columns_updateTime"),
    },{
      field: "createdBy",
      header: t("business.process.columns_createdBy"),
    },{
      field: "updatedBy",
      header: t("business.process.columns_updatedBy"),
    }
  ];
}
import * as Yup from "yup";
import { TextField } from "../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({

  });

}

export function formFields(t) {
  return [
    {
      size: 6,
      field: <TextField id="version" type="text" label={t("documents.DocumentTemplate.editor.version")} disabled={true} />,
    },{
      size: 6,
      field: <TextField id="active" type="text" label={t("documents.DocumentTemplate.editor.active")} disabled={true} />,
    },
  ]
}

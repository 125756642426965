export const styles = (theme) => ({
  root: {
  },
  step: {
    padding: 0,
    maxWidth: 16,
    minWidth: 16,
    "& .Mui-completed .OrderStatusStepperIcon": {
      borderColor: theme.palette.primary.main,
      // backgroundColor: theme.palette.primary.main
    },
    "& .Mui-active .OrderStatusStepperIcon": {
      borderColor: theme.palette.primary.main,
    },
    "& .Mui-disabled .OrderStatusStepperIconFill": {
      display: "none",
    },
  },
  connector: {
    top: 6,
    left: "calc(-50% + 6px)",
    right: "calc(50% + 6px)",
    "& .MuiStepConnector-line": {
      borderTopWidth: 2,
    },
    "&.Mui-completed .MuiStepConnector-line": {
      borderColor: theme.palette.primary.main,
    },

    "&.Mui-active .MuiStepConnector-line": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-disabled .MuiStepConnector-line": {
      borderColor: "#AAA",
    }
  }
});
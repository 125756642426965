import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../../common/displays/CustomDataGrid";

import { styles } from "./SuppliersDataGrid.styles";
import { listColumns } from "./SuppliersDataGrid.columns";
import { csvFields } from "./SuppliersDataGrid.export";
import FilterForm from "../../../../../common/forms/custom/FilterForm/FilterForm";
import { filterFields, filterSchema } from "../SuppliersTabGroup.filter";
import { reportSuppliersDefaultFilter } from "../../../_common/businessFilters";

function SuppliersDataGrid({
  classes,
  data,
  filter,
  onFilterChange,
}) {
  const { t } = useTranslation();

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={listColumns(t, classes)}
        rows={data || []}
        onFilter={onFilterHandler}
        total={data?.length}
        exportCsvFields={csvFields(t)}
        exportXlsxFields={csvFields(t)}
        topActions={
          <FilterForm
            style={{ marginBottom: 8 }}
            storageKey="SuppliersDataGrid"
            slim
            fields={filterFields()}
            schema={filterSchema()}
            onFilterChange={onFilterHandler}
            filter={filter}
            defaultFilter={reportSuppliersDefaultFilter}
          />
        }
      />
    </div>
  );
}

export default withStyles(SuppliersDataGrid, styles)

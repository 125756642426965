import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const rolesListCall = async (
  { clearRolesList, setRolesListFilter, appendToRolesList, setRolesListTotalRows, setRolesUsersList },
  dispatch,
  { filter, clear }
) => {
  let rh = requestHelper(dispatch, "ROLES_LIST");
  if (clear) {
    dispatch(clearRolesList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setRolesListFilter(filter));

  try {
    let list = await call({
      type: "ROLES",
      subType: "LIST",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    if (list?.roles) {
      dispatch(appendToRolesList(list.roles));
      dispatch(setRolesUsersList(list.users));
      dispatch(setRolesListTotalRows(list.rows));
    }
  } catch (ex) {
    rh.error("roles", ex);
  }
  rh.close();
};

export default rolesListCall;

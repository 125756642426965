import React from "react";
import { Checkbox } from "@mui/material";
import ProductSnip from "../../../../displays/ProductSnip/ProductSnip";

export function listColumns(
  t,
  classes,
  selectedProducts,
  onProductsSelectedChange,
  hideCheckbox,
  single,
  onBulkSelectChange,
  bulkSelected,
  allowBulkSelect,
  allProducts
) {
  const onProductCheckedChange = (checked, product) => {
    if (checked) {
      if (single) {
        onProductsSelectedChange && onProductsSelectedChange([product]);
      } else {
        onProductsSelectedChange && onProductsSelectedChange([...selectedProducts, product]);
      }
    } else {
      onProductsSelectedChange &&
        onProductsSelectedChange([...selectedProducts.filter((item) => item.productId !== product.productId)]);
    }
  };

  return [
    {
      field: "productId",
      align: "left",
      headerAlign: "left",
      disableSort: true,
      hidden: hideCheckbox,
      headerComponent: allowBulkSelect && (
        <Checkbox
          className={classes.checkbox}
          color="primary"
          checked={Boolean(bulkSelected || allProducts)}
          disabled={allProducts}
          onChange={(e) => onBulkSelectChange(e.target.checked)}
        />
      ),
      customValue: (v) => {
        let checked = selectedProducts
          ? Boolean((selectedProducts || []).find((item) => item.productId === v.productId))
          : false;
        return (
          <Checkbox
            className={classes.checkbox}
            color="primary"
            checked={Boolean(checked || allProducts)}
            disabled={allProducts}
            onChange={(e) => onProductCheckedChange(e.target.checked, v)}
          />
        );
      },
    },
    {
      field: "description",
      headerName: t("common.ProductPicker.columns.description"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <ProductSnip
            gtin={v.gtin}
            description={v.description}
            image={v.thumbnail || v.image}
            promoterInternalCode={v.promoterInternalCode}
          />
        );
      },
    },
    {
      field: "brand",
      headerName: t("common.ProductPicker.columns.brand"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "ownerName",
      headerName: t("common.ProductPicker.columns.ownerName"),
      align: "left",
      headerAlign: "left",
    },
  ];
}

export const styles = (theme) => ({
  root: {},
  drawer: {
    width: "25vw",
    padding: theme.spacing(4),
  },
  nomargin: {
    margin: 0,
  },
  selectedAddress: {
    backgroundColor: "#ffffc6",
  },
});

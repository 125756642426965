import React from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";

import { styles } from "./FamilyForm.styles";
import { formSchema, formFields } from "./FamilyForm.schema";
import { Form, FormFields } from "../../../../common/forms";
import { usePromoter } from "../../../../common/hooks";
import { TextInput } from "../../../../common/inputs";
import DeleteButton from "../../../../common/components/dialogs/DeleteButton";
import { productCreateFamily, productDisableFamily, productUpdateFamily } from "../../productsFamiliesSlice";
import Permission from "../../../../app/components/Permission";

function FamilyForm({ classes, selectedItem, families, setSelectedItem }) {
  const { t, i18n } = useTranslation();
  const promoter = usePromoter();
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    if (values.create) {
      dispatch(productCreateFamily(values));
    } else {
      dispatch(productUpdateFamily(values));
    }
  };

  const onDeleteHandler = (id) => {
    dispatch(productDisableFamily({ id }));
  };
  const selectedParent = (families || []).find((item) => item.id === selectedItem?.parent);

  let initialValues = { ...(selectedItem || {}), children: undefined }; //getDefaultInitialValues(selectedItem || {}, promoter);
  if (!initialValues.type) {
    initialValues = { ...initialValues, type: selectedParent?.type };
  }

  const languages = [i18n.language, ...(promoter?.getConfig("admin", "language") || []).filter((item) => item !== i18n.language)];

  const allowDelete = initialValues?.id && (!selectedItem?.children || selectedItem?.children.length === 0) && !initialValues.create;

  return promoter ? (
    <div className={classes.root}>
      <Form
        schema={formSchema(t, i18n.language, promoter)}
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ submitting, values }) => {
          const { create } = values;
          return (
            <div>
              {selectedParent?.description && (
                <TextInput
                  className={classes.parent}
                  value={selectedParent?.description[i18n.language]}
                  disabled
                  label={t("productsFamilies.FamilyForm.form.parent")}
                />
              )}
              <FormFields fields={formFields({ t, languages, promoter, create })} />
              <br />
              <Grid container spacing={1}>
                <Permission code="promoter.actions.family.update">
                  <Grid item xs={allowDelete ? 6 : 12}>
                    <Button variant="contained" color="primary" type="submit" fullWidth disabled={submitting}>
                      {initialValues?.id ? t("productsFamilies.FamilyForm.form.updateBtn") : t("productsFamilies.FamilyForm.form.addBtn")}
                    </Button>
                  </Grid>
                </Permission>
                <Permission code="promoter.actions.family.disable">
                  {allowDelete && (
                    <Grid item xs={6}>
                      <DeleteButton
                        fullWidth
                        labels={{
                          button: t("productsFamilies.FamilyForm.form.deleteBtn"),
                          title: t("productsFamilies.FamilyForm.deleteConfirm.title"),
                          description: t("productsFamilies.FamilyForm.deleteConfirm.description"),
                          ok: t("productsFamilies.FamilyForm.deleteConfirm.ok"),
                          cancel: t("productsFamilies.FamilyForm.deleteConfirm.cancel"),
                        }}
                        onConfirm={() => {
                          onDeleteHandler(initialValues?.id);
                        }}
                      />
                    </Grid>
                  )}
                </Permission>
              </Grid>
            </div>
          );
        }}
      />
    </div>
  ) : null;
}

export default withStyles(FamilyForm, styles)

export const styles = (theme) => ({
  root: {},
  description: {
    color: "#999",
    fontSize: 12,
    margin: 0,
    display: "inline-block",
    lineHeight: 1,
  },
});

import React from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";

import { styles } from "./PlanningSeasonFormModal.styles";
import { formFields, formSchema } from "./PlanningSeasonFormModal.schema";

import CustomModal from "../../../../common/displays/CustomModal/CustomModal";
import { Form, FormFields } from "../../../../common/forms";
import { planningCreate, planningSingleUpdate } from "../../planningSlice";

function PlanningSeasonFormModal({ classes, open, setOpen, data, selectedPlanning, defaultPlanning }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmitHandler = async (values) => {
    const { target, startDate, endDate, name } = values;
    if (!selectedPlanning?.id) {
      dispatch(planningCreate({
        productId: defaultPlanning.productId,
        year: defaultPlanning.year,
        isDefault: false,
        target,
        startDate,
        endDate,
        name,
      }))
    }
    else {
      dispatch(planningSingleUpdate({
        id: selectedPlanning.id,
        productId: defaultPlanning.productId,
        year: defaultPlanning.year,
        isDefault: false,
        target,
        startDate,
        endDate,
        name,
      }))
    }
    setOpen(false);
  };

  const defaultValues = {
    target: {
      value: "0",
      unit: defaultPlanning?.target?.unit,
      category: defaultPlanning?.target?.category
    }
  }

  return (
    <CustomModal open={open} setOpen={setOpen} size="large" height={340} title={selectedPlanning?.id ? t("planning.PlanningSeasonFormModal.form.modalTitleUpdate") : t("planning.PlanningSeasonFormModal.form.modalTitle")}>
      <div className={classes.root}>
        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t, year: defaultPlanning?.year })}
          initialValues={selectedPlanning ? selectedPlanning : defaultValues}
          keepDirtyOnReinitialize
          render={({ values }) => {
            return (
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <FormFields fields={formFields({ t, values, year: defaultPlanning?.year })} />
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" type="submit" fullWidth>
                    {t("planning.PlanningSeasonFormModal.form.btnSubmit")}
                  </Button>
                </Grid>
              </Grid>
            );
          }}
        />
      </div>
    </CustomModal>
  );
}

export default withStyles(PlanningSeasonFormModal, styles);


import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const promoterCreateCutOffCall = async (dispatch, cutoff) => {
  let rh = requestHelper(dispatch, "CREATE_CUT_OFF");
  try {
    await call({
      type: "PROMOTER",
      subType: "CREATE_CUT_OFF",
      request: cutoff,
    });
    setTimeout(() => {
      dispatch(setSuccess("entities", "PROMOTER_CREATE_CUT_OFF"));
      dispatch(setRedirect(`/entities/cutoffs`));
    }, 500);
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default promoterCreateCutOffCall;

import React from "react";
import { withStyles } from "tss-react/mui";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "../../../common/hooks";
import { Button, Grid } from "@mui/material";

import { styles } from "./CatalogList.styles";
import CatalogDataGrid from "./CatalogDataGrid/CatalogDataGrid";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import SearchBox from "../../../common/components/inputs/SearchBox";

import {
  selectCatalogList,
  listCatalogsAppend,
  selectCatalogListTotalRows,
  selectCatalogListFilter,
  enableCatalog,
  disableCatalog,
} from "../productsSlice.catalogCalls";

import Permission from "../../../app/components/Permission";
import Page from "../../../common/displays/Page/Page";


function CatalogList({ classes }) {
  const { t } = useTranslation();
  const data = useSelector(selectCatalogList);
  const filter = useSelector(selectCatalogListFilter);
  const totalRows = useSelector(selectCatalogListTotalRows);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search,
      offset: 0,
    };
    dispatch(listCatalogsAppend(nFilter, true));
  };

  const onFilterHandler = ({ filter, reset }) => {
    dispatch(listCatalogsAppend(filter, reset));
  };

  const onActivateHandler = (id, value) => {
    if (value) {
      dispatch(enableCatalog(id));
    } else {
      dispatch(disableCatalog(id));
    }
  };

  return (
    <Page
      permission="products.actions.catalog.list"
      header={<PageTitle
        title={t("products.CatalogList.header.title")}
        info={`${totalRows} ${t("products.CatalogList.header.rowsInfo")}`}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <SearchBox onEnter={(v) => onSearchHandler(v)} />
            </Grid>
            <Permission code="products.actions.catalog.create">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => navigate("/products/catalogs/add")}
                >
                  {t("products.CatalogList.header.addBtn")}
                </Button>
              </Grid>
            </Permission>
          </Grid>
        }
      />}>

      <CatalogDataGrid
        data={data}
        totalRows={totalRows}
        onFilterChange={onFilterHandler}
        onActivate={onActivateHandler}
        onCatalogSelected={(cat) => navigate("/products/catalogs/" + cat.id)}
        filter={filter}
      />
    </Page>
  );
}

export default withStyles(CatalogList, styles);

import { getPromoterConfig, requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { uploadEntityDocument } from "./_auxiliars";

const entityAddDocumentCall = async ({ entityGet }, dispatch, state, { entityId, file, metadata, type }) => {
  const promoterCode = state.core.promoterDetails?.details?.code;
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  const url = await uploadEntityDocument(fileStorageConfigs, promoterCode, entityId, file);

  let rh = requestHelper(dispatch, "ADD_DOCUMENT");
  try {
    await call({
      type: "ENTITY",
      subType: "ADD_DOCUMENT",
      request: { 
        entityId, 
        documents: [{ type, metadata, url }] 
      },
    });
    dispatch(setSuccess("entities", "ENTITY_ADD_DOCUMENT"));
    setTimeout(() => {
      dispatch(entityGet(entityId))
    }, 300)
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityAddDocumentCall;

import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const orderCancelCall = async (dispatch, { orderId }) => {

  let rh = requestHelper(dispatch, "ORDER_CANCEL");
  try {
    await call({
      type: "ORDER",
      subType: "CANCEL",
      request: {
        orders: [orderId],
      },
    });
    setTimeout(() => {
      dispatch(setRedirect(`/orders/placed`));
      dispatch(setSuccess("orders", "ORDER_CANCEL"));
    }, 300);
  } catch (ex) {
    rh.error("orders", ex);
  }
  setTimeout(() => {
    rh.close();
  }, 350);
};


export default orderCancelCall;
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const businessUnitStepsCall = async (
  { setBusinessUnitSteps },
  dispatch,
  { businessUnitId, lineId, internal }
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_STEPS");
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "STEPS",
      request: {
        businessUnitId,
        lineId,
        internal
      },
    });
    dispatch(setBusinessUnitSteps(result.items || []))
  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitStepsCall;


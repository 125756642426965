import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./CatalogDataGrid.styles";
import { listColumns } from "./CatalogDataGrid.columns";
import { useUser } from "../../../../common/hooks";
import { csvFields } from "./CatalogDataGrid.export";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";
import { filterFields, filterSchema } from "./CatalogDataGrid.filter";
import { catalogListDefaultFilter } from "../../_common/productsFilters";

function CatalogDataGrid({ classes, data, totalRows, onCatalogSelected, onActivate, filter, loading, onFilterChange }) {
  const { t } = useTranslation();
  const user = useUser()
  const columns = listColumns(t, classes, onActivate, user);

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };

      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  return (
    <div className={classes.root}>

      <CustomDataGrid
        columns={columns}
        rows={data || []}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMoreHandler}
        onRowSelected={onCatalogSelected}
        total={totalRows}
        exportCsvFields={csvFields(t)}
        exportXlsxFields={csvFields(t)}
        topActions={
          <FilterForm
            style={{ marginBottom: 8 }}
            loading={loading}
            storageKey="CatalogDataGrid"
            onStorageLoad={onFilterHandler}
            slim
            fields={filterFields()}
            schema={filterSchema()}
            onFilterChange={onFilterHandler}
            filter={filter}
            defaultFilter={catalogListDefaultFilter}
          />
        }
      />

    </div>
  );
}

export default withStyles(CatalogDataGrid, styles)

import * as Yup from "yup";
import { MultiSelectField, DatePickerField, SelectField } from "../../../../common/forms";

export const filterFields = (groupsList) => (t, values) => {
  return [
    {
      size: 12,
      field: <SelectField id="active" label={t("products.CampaignList.filter.active")} options={getActiveOptions(t)} />,
    },
    {
      size: 12,
      field: (
        <MultiSelectField
          id="campaignType"
          label={t("products.CampaignList.filter.campaignType")}
          options={getCampaignTypesOptions(t)}
        />
      ),
    },
    {
      size: 12,
      field: (
        <DatePickerField id="startDate" label={t("products.CampaignList.filter.startDate")} />
      ),
    },
    {
      size: 12,
      field: (
        <DatePickerField id="endDate" label={t("products.CampaignList.filter.endDate")} minDate={values.startDate} />
      ),
    },
    {
      size: 12,
      field: (
        <MultiSelectField
          id="groupId"
          label={t("products.CampaignList.filter.entityGroup")}
          options={getGroupOptions(groupsList)}
        />
      ),
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    campaignType: Yup.array(),
  });
};

const getCampaignTypesOptions = (t) => {
  return ["PR", "PD", "MB", "BU"].map((value) => {
    return {
      value,
      text: t("common.Enums.campaignType." + value),
    };
  });
};

const getGroupOptions = (groupsList) => {
  return groupsList.map((group) => {
    return {
      value: group.groupId,
      text: group.groupName,
    };
  });
};

const getActiveOptions = (t) => {
  return [
    {
      value: "ALL",
      text: t("products.CatalogList.filter.optionAll"),
    },
    {
      value: true,
      text: t("products.CatalogList.filter.optionActive"),
    },
    {
      value: false,
      text: t("products.CatalogList.filter.optionInactive"),
    },
  ];
};

import React from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

import { styles } from "./SlimOrderItem.styles";
import OrderIcon from "../../../_common/OrderIcon/OrderIcon";
import { dateFormat } from "../../../../../common/utils";

function SlimOrderItem({ classes, order }) {
  // const { t } = useTranslation();
  const { orderNumber, type, date } = order;

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <OrderIcon type={type} />
            </Grid>
            <Grid item>
              <Typography color="textSecondary" style={{ margin: 0 }}>{orderNumber}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography color="textSecondary" style={{ margin: 0 }}>{dateFormat(date)}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(SlimOrderItem, styles);
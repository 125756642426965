import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
import {
  Switch,
  Typography,
  Grid,
  Modal,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { Field } from "react-final-form";
import MetaError from "../../forms/MetaError";

import { styles } from "./EntityGroupSelector.styles";
import SearchBox from "../inputs/SearchBox";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";

function EntityGroupSelector({ classes, id, label, info, disabled, required, groups }) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [groupsList, setGroupsList] = useState([]);

  useEffect(() => {
    if (groups) {
      setGroupsList(groups);
    }
    // eslint-disable-next-line
  }, [groups]);

  const onSearchHandler = (v) => {
    if (v) {
      setGroupsList(groups.filter((item) => item.groupName.indexOf(v) > -1 || item.entityName.indexOf(v) > -1));
    } else {
      setGroupsList(groups);
    }
  };

  const handleChange = (value, input, reset) => {
    if (reset) {
      return input.onChange && input.onChange(Boolean(input.value) ? null : []);
    }
    if (input.value.indexOf(value) > -1) {
      input.onChange && input.onChange(input.value.filter((item) => item !== value));
    } else {
      input.onChange && input.onChange([...input.value, value]);
    }
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => {
          return (
            <div>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography
                    component="span"
                    variant="caption"
                    htmlFor={id}
                    color="textPrimary"
                    className={disabled ? classes.disabledLabel : ""}
                  >
                    {label}
                    {required && <span> *</span>}
                  </Typography>
                </Grid>
                <Grid item>
                  <Switch
                    size="small"
                    color="primary"
                    checked={Boolean(input.value)}
                    disabled={disabled}
                    onChange={() => handleChange(undefined, input, true)}
                  ></Switch>
                </Grid>
              </Grid>
              {info && (
                <Typography
                  component="span"
                  variant="caption"
                  htmlFor={id}
                  color="textSecondary"
                  className={disabled ? classes.disabledLabel : ""}
                >
                  {info}
                </Typography>
              )}
              {Boolean(input.value) && (
                <div>
                  <List className={classes.root}>
                    {input.value &&
                      groups &&
                      input.value.map((selectedGroupId) => {
                        let group = groups.find((item) => item.groupId === selectedGroupId);
                        return (
                          group && (
                            <ListItem key={selectedGroupId} dense>
                              <ListItemText
                                primary={
                                  <Typography
                                    component="h5"
                                    variant="h4"
                                    color="textPrimary"
                                    className={classes.nomargin}
                                  >
                                    {group?.groupName}
                                  </Typography>
                                }
                                secondary={
                                  <Typography
                                    component="span"
                                    variant="caption"
                                    color="textSecondary"
                                    className={classes.nomargin}
                                  >
                                    {group?.entityName}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          )
                        );
                      })}
                  </List>
                  <Grid container justifyContent="center">
                    {!disabled && (
                      <Button variant="text" color="primary" size="large" onClick={() => setModal(true)}>
                        {t("common.EntityGroupSelector.modal.addMoreBtn")}
                      </Button>
                    )}{" "}
                  </Grid>
                </div>
              )}

              <Modal open={modal} className={classes.modal} onClose={() => setModal(false)}>
                <div className={classes.modalroot}>
                  <PageTitle
                    title={t("common.EntityGroupSelector.modal.title")}
                    actions={
                      <Grid container spacing={2}>
                        <Grid item>
                          <SearchBox onEnter={(v) => onSearchHandler(v)} fast />
                        </Grid>
                        <Grid item>
                          <Button variant="contained" color="primary" size="large" onClick={() => setModal(false)}>
                            {t("common.EntityGroupSelector.modal.closeBtn")}
                          </Button>
                        </Grid>
                      </Grid>
                    }
                  />

                  <Grid container className={classes.modalbody} direction="column">
                    <Grid item style={{ flexGrow: 1 }}>
                      <List className={classes.root}>
                        {groupsList &&
                          groupsList.map((item) => {
                            return (
                              <ListItem
                                key={item.groupId}
                                role={undefined}
                                dense
                                button
                                onClick={() => handleChange(item.groupId, input)}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    color="primary"
                                    checked={input.value.indexOf(item.groupId) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography
                                      component="h5"
                                      variant="h4"
                                      color="textPrimary"
                                      className={classes.nomargin}
                                    >
                                      {item.groupName}
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography
                                      component="span"
                                      variant="caption"
                                      color="textSecondary"
                                      className={classes.nomargin}
                                    >
                                      {item.entityName}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            );
                          })}
                      </List>
                    </Grid>
                  </Grid>
                </div>
              </Modal>
              <MetaError className={classes.error} meta={meta} />
            </div>
          );
        }}
      </Field>
    </div>
  );
}

export default withStyles(EntityGroupSelector, styles)

import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useFirstLoad, useNavigate, useUser } from "../../../common/hooks";
import { Button, Grid } from "@mui/material";

import { styles } from "./EmailTemplate.styles";
import { useParams } from "react-router-dom";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {
  notificationGetEmail,
  notificationListEmail,
  notificationNewVersionEmail,
  selectEmailTemplate,
  setEmailTemplate,
} from "../notificationSlice.calls";
import VersionsDataGrid from "./VersionsDataGrid/VersionsDataGrid";
import { formFields, formSchema } from "./EmailTemplateForm.schema";
import Form from "../../../common/forms/Form";
import Permission from "../../../app/components/Permission";
import HTMLEditor from "../../../common/components/displays/HTMLEditor";
import Page from "../../../common/displays/Page/Page";
import { FormFields } from "../../../common/forms";

function EmailTemplate({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const emailTemplate = useSelector(selectEmailTemplate);
  const user = useUser();
  const emailFixedVars = [
    "actionUrl",
    "promoterName",
    "promoterLogo",
    "promoterEmail",
    "promoterPhone",
    "promoterWebsite",
    "promoterContactName",
    "promoterContactEmail",
    "promoterContact2Name",
    "promoterContact2Email",
    "promoterContact3Name",
    "promoterContact3Email",
  ];

  useFirstLoad(() => {
    if (emailTemplate?.id !== id) {
      dispatch(notificationGetEmail(id));
    }
  });

  const editorRef = useRef();

  const onSubmit = async (values) => {
    dispatch(notificationNewVersionEmail(emailTemplate.id, values.subject, editorRef.current.getContent(), navigate));
  };

  const onRowSelectedHandler = (row) => {
    if (row.id !== emailTemplate?.version) {
      dispatch(notificationGetEmail(row.id));
      navigate("/emails/" + row.id);
    }
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        schema={formSchema(t)}
        initialValues={emailTemplate}
        render={({ submitting, values }) => {
          return (
            <Page
              permission="promoter.actions.email.get"
              header={<PageTitle
                title={t("emails.EmailTemplate.header.title")}
                subtitle={
                  t("emails.EmailTemplate.header.subtitle") + " " + emailTemplate?.type + "/" + emailTemplate?.subType
                }
                onBack={() => {
                  dispatch(notificationListEmail());
                  dispatch(setEmailTemplate({}));
                  navigate("/emails");
                }}
                actions={
                  <Permission code="promoter.actions.email.update">
                    <Button variant="contained" color="primary" type="submit" size="large" disabled={submitting}>
                      {t("emails.EmailTemplate.header.saveBtn")}
                    </Button>
                  </Permission>
                }
              />}>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormFields fields={formFields(t, user)} />
                </Grid>
                <Grid item xs={12}>
                  <HTMLEditor
                    label={"emails.EmailTemplate.editor.body"}
                    initialValue={emailTemplate?.body}
                    disabled={!user.hasPermission("promoter.actions.email.update")}
                    variables={[
                      { label: "emails.EmailTemplate.editor.globalVars", vars: emailFixedVars },
                      { label: "emails.EmailTemplate.editor.templateVars", vars: emailTemplate?.variables },
                    ]}
                    editorRef={editorRef}
                  />
                </Grid>
                <Grid item xs={6}>
                  <VersionsDataGrid
                    currentVersion={emailTemplate?.version}
                    data={emailTemplate?.versions}
                    totalRows={emailTemplate?.versions?.length}
                    onRowSelected={onRowSelectedHandler}
                  />
                </Grid>
              </Grid>
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(EmailTemplate, styles);

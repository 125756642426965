import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { LinearProgress, Stack, Typography } from "@mui/material";
import HistoryIcon from '@mui/icons-material/History';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import moment from "moment";

import { styles } from "./ExtraPriceHistory.styles";
import CustomTooltip from "../../../../../../common/displays/CustomTooltip/CustomTooltip";
import Currency from "../../../../../../common/displays/Currency/Currency";
import { dateFormat } from "../../../../../../common/utils";
import Permission from "../../../../../../app/components/Permission";

function ExtraPriceHistory({ classes, prices, removeDate }) {
  const { t } = useTranslation();

  if (removeDate) {
    console.log("removeDate: %o", removeDate)
  }

  return (
    <div className={classes.root}>
      <CustomTooltip title={
        <Stack spacing={1}>
          {uiPriceRemoved(t, removeDate)}
          {
            (prices || []).map((priceElement, index) => {
              let progressPercent = 0;
              if (priceElement.endDate && moment(priceElement.startDate).isBefore(moment())) {
                progressPercent = moment().diff(moment(priceElement.startDate), "days") / moment(priceElement.endDate).diff(moment(priceElement.startDate), "days") * 100;
                if (progressPercent > 100) {
                  progressPercent = 100;
                }
              }
              return (
                <div key={"price_" + index}>
                  <Stack direction="row" spacing={1}>
                    <Stack style={{ width: priceElement.endDate ? 180 : 100 }}>
                      <Stack direction="row" alignItems="center" justifyContent="center" style={{ minHeight: 20 }}>
                        <Typography style={{ margin: 0 }}>{dateFormat(priceElement.startDate)}</Typography>
                        {priceElement.endDate && <ArrowRightIcon />}
                        {priceElement.endDate && <Typography style={{ margin: 0 }}>{dateFormat(priceElement.endDate)}</Typography>}
                      </Stack>
                      <LinearProgress variant="determinate" color={moment(priceElement.startDate).isSameOrAfter(moment()) ? "inherit" : "primary"} value={progressPercent} />
                    </Stack>
                    <Currency value={priceElement?.value} />
                  </Stack>
                </div>
              )
            })
          }
        </Stack>
      }>
        <Permission code="products.actions.extraPrice.history">
          <HistoryIcon className={classes.icon} />
        </Permission>
      </CustomTooltip>
    </div>
  );
}

export default withStyles(ExtraPriceHistory, styles);

const uiPriceRemoved = (t, removeDate) => {
  if (!removeDate) {
    return "";
  }
  if (moment(removeDate).isSameOrBefore(moment())) {
    return <Typography>{t("productsPrices.ExtraPriceHistory.tooltip.removed", { date: dateFormat(removeDate) })}</Typography>
  }
  return <Typography>{t("productsPrices.ExtraPriceHistory.tooltip.toBeRemoved", { date: dateFormat(removeDate) })}</Typography>
}
import { dateTimeFormatWithMilliSeconds } from "../../../../common/utils/formats";

export function listColumns(t, classes) {
  return [

    {
      field: "type",
      headerName: t("business.process.processList.columns_type"),
      align: "left",
      headerAlign: "left",
    }, {
      field: "processType",
      headerName: t("business.process.processList.columns_processType"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },{
      field: "processId",
      headerName: t("business.process.processList.columns_processId"),
      align: "left",
      headerAlign: "left",
    },{
      field: "state",
      headerName: t("business.process.processList.columns_state"),
      align: "left",
      headerAlign: "left",
    },{
      field: "status",
      headerName: t("business.process.processList.columns_status"),
      align: "left",
      headerAlign: "left",
    },{
      field: "active",
      headerName: t("business.process.processList.columns_active"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return t("business.process.processList.status_" + v.active);
      },
    },{
      field: "createdTime",
      headerName: t("business.process.processList.columns_createdTime"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.createdTime));
      },
    },{
      field: "updatedTime",
      headerName: t("business.process.processList.columns_updateTime"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.updatedTime));
      },
    },{
      field: "createdBy",
      headerName: t("business.process.processList.columns_createdBy"),
      align: "left",
      headerAlign: "left",
    },{
      field: "updatedBy",
      headerName: t("business.process.processList.columns_updatedBy"),
      align: "left",
      headerAlign: "left",
    },
  ];
}

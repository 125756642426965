import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Paper, Grid, Typography, IconButton, Divider } from "@mui/material";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";

import { styles } from "./ProductDocumentsDetails.styles";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";
import { dateFormat } from "../../../../common/utils";

function ProductDocumentsDetails({ classes, documents }) {
  const { t } = useTranslation();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={2} justifyContent="space-between" direction="column">
        <Grid item>
          <Typography variant="h3" align="left" component="h4" className={classes.noMargin}>
            {t("products.ProductDocumentsDetails.header.title")}
          </Typography>
        </Grid>
        {(documents || []).map((item, index) => (
          <Grid item key={`documents_${index}`}>
            <Grid container spacing={2} alignItems="center">
              {index > 0 && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
              <Grid item xs={3} style={{ textAlign: "center" }}>
                <IconButton disableRipple size="large" href={item.url} rel="noreferrer" target="_blank">
                  <Grid container direction="column">
                    <Grid item>
                      <FileOpenOutlinedIcon fontSize="large" color="action" />
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">{t(`common.Enums.documentType.${item.type}`)}</Typography>
                    </Grid>
                  </Grid>
                </IconButton>
              </Grid>
              {item?.metadata && (
                <Grid item xs={9}>
                  <Grid container spacing={1}>
                    {Object.keys(item?.metadata).map((meta) => {
                      let value = item?.metadata?.[meta];
                      if (meta === "EXD" || meta === "RCD") {
                        value = dateFormat(value);
                      }
                      return (
                        <Grid item xs={6} key={`documents_meta_${index}_${meta}`}>
                          <LabelValue label={t(`common.Enums.documentMetadata.${meta}`)} value={value} />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

export default withStyles(ProductDocumentsDetails, styles);

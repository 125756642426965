
import { dateTimeFormat } from "../../../../common/utils";

export const csvFields = (t, promoter) => {
  return [
    {
      field: "productName",
      header: t("business.queries.ProductList.columns_productName"),
    },
    {
      field: "gtin",
      header: t("business.queries.ProductList.columns_gtin"),
    },
    {
      field: "gln",
      header: t("business.queries.ProductList.columns_gln"),
    },
    {
      field: "productInternalCode",
      header: t("business.queries.ProductList.columns_productInternalCode"),
      hidden: !promoter?.getConfig("product", "requiresInternalCode")
    },
    {
      field: "ownerTaxNumber",
      header: t("business.queries.ProductList.columns_ownerTaxNumber"),
    },
    {
      field: "ownerName",
      header: t("business.queries.ProductList.columns_ownerName"),
    },
    {
      field: "ownerInternalCode",
      header: t("business.queries.ProductList.columns_ownerInternalCode"),
      hidden: !promoter?.getConfig("entity", "requiresInternalCode")
    },
    {
      field: "createdByEntityTaxNumber",
      header: t("business.queries.ProductList.columns_createdByEntityTaxNumber"),
    },
    {
      field: "createdByEntityName",
      header: t("business.queries.ProductList.columns_createdByEntityName"),
    },
    {
      field: "createdBy",
      header: t("business.queries.ProductList.columns_createdBy"),
    },
    {
      field: "createdTime",
      header: t("business.queries.ProductList.columns_createdTime"),
      customValue: (v) => {
        return dateTimeFormat(v.createdTime);
      },
    },
    {
      field: "status",
      header: t("business.queries.ProductList.columns_status"),
      customValue: (v) => {
        return t("common.Enums.productStatus." + v?.status)
      }
    },
    {
      field: "available",
      header: t("business.queries.ProductList.columns_available"),
      customValue: (v) => {
        return t("common.Enums.productAvailable." + v?.available)
      }
    },
  ];
}

import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Modal } from "@mui/material";

import { styles } from "./ConfirmDelivery.styles";
import { useDispatch } from "react-redux";
import { orderConfirmDelivery } from "../../ordersSlice";
import { NumberInput } from "../../../../common/inputs";
import { usePromoter } from "../../../../common/hooks";

function ConfirmDelivery({ classes, order }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [totalPallets, setTotalPallets] = useState(0);
  const promoter = usePromoter();

  const confirmDeliveryHandler = () => {
    setModalOpen(false);
    dispatch(orderConfirmDelivery({ orderId: order.id, totalPallets }));
  };

  return order.lastOrderStatus === "DE" && promoter?.getConfig("order", "hasLogisticOperator") ? (
    <Grid item className={classes.root}>
      <Button variant="contained" color="primary" size="large" onClick={() => setModalOpen(true)}>
        {t("orders.OrderDetails.header.confirmDeliveryBtn")}
      </Button>
      <Modal open={modalOpen} className={classes.modal} onClose={() => setModalOpen(false)}>
        <div className={classes.modalroot}>
          <Grid container direction="column" spacing={1}>
            <Grid item style={{ flexGrow: 1 }}>
              <NumberInput
                value={totalPallets}
                onChange={(value) => setTotalPallets(parseInt(value))}
                label={t("orders.OrderDetails.header.confirmDeliveryModalPallets")}
              />
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <Button fullWidth variant="contained" color="primary" onClick={confirmDeliveryHandler}>
                {t("orders.OrderDetails.header.confirmDeliveryModalBtn")}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </Grid>
  ) : null;
}

export default withStyles(ConfirmDelivery, styles);

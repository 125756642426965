export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
  },
  links: {
    width: "100%",
    textAlign: "right",
    "& .MuiToggleButtonGroup-root": {
      backgroundColor: "#FFF"
    }
  },
  drawer: {
    width: "25vw",
    padding: theme.spacing(2),
  },
});

import { dateTimeFormatWithMilliSeconds } from "../../../../common/utils/formats";

export const csvFields = (t) => {
  return [
    {
      field: "datetime",
      headerName: t("business.AuditsList.columns.requestTime"),
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.datetime));
      },
    },
    {
      field: "username",
      headerName: t("business.AuditsList.columns.userRequest"),
    },
    {
      field: "entity",
      headerName: t("business.AuditsList.columns.entity"),
    },
    {
      field: "language",
      headerName: t("business.AuditsList.columns.language"),
    }
  ];
};

import { getCountryName } from "../../../../common/utils";

export const getDeliveryDayOptions = (t, promoter) => {
  return (promoter?.getConfig("cutOff", "details") || []).map((item) => {
    let minQty;
    if (item?.minQuantity?.quantity > 0) {
      minQty = `${t("orders.DeliveryDayAndAddressForm.form.minQuantity")} ${item?.minQuantity?.quantity} ${t("common.Enums.transportShort." + item?.minQuantity?.unitType)}`;;
    }
    return {
      value: item.type,
      text: t("entities.CutOffs.dateType." + item.type),
      description: `${t("orders.DeliveryDayAndAddressForm.form.deliveryDayDescription")} ${t("common.Enums.weekday." + item.deliveryDay)}` +
        (minQty ? " - " + minQty : ""),
    };
  });
}

export const getAddressOptions = (addresses) => {
  return (addresses || []).map((item) => {
    return {
      value: item.id,
      text: item.description,
      description: (
        <div>
          <span>{item.address}</span>
          <br />
          <span>{item.postalCode}</span>
          <br />
          <span>{`${item.locale}, ${item.county}, ${getCountryName(item.country)}`}</span>
        </div>
      ),
    };
  })
}

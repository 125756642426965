export const csvFields = (t) => {

  return [
    {
      field: "name",
      header: t("products.CatalogList.columns.name"),
    },
    {
      field: "ownerName",
      header: t("products.CatalogList.columns.ownerName"),
    },
    {
      field: "numberBuyer",
      header: t("products.CatalogList.columns.numberBuyer"),
      
    },
    {
      field: "numberProduct",
      header: t("products.CatalogList.columns.numberProduct"),
    },
    {
      field: "active",
      header: t("products.CatalogList.columns.active"),
    },
  ];
}
import { Typography } from "@mui/material";
import { FormArrayFields, TextField } from "../../../../common/forms";

export const contactFields = ({ t, user }) => {
  return [
    {
      size: 6,
      field: <TextField id="functional.contact.website" type="text" label={t("promoter.PromoterConfigs.form.website")} />,
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
    {
      size: 6,
      field: <TextField id="functional.contact.email" type="text" label={t("promoter.PromoterConfigs.form.email")} />,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: <TextField id="functional.contact.phoneNumber" type="text" label={t("promoter.PromoterConfigs.form.phoneNumber")} />,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <TextField id="functional.contact.mobileNumber" type="text" label={t("promoter.PromoterConfigs.form.mobileNumber")} />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: <TextField id="functional.contact.faxNumber" type="text" label={t("promoter.PromoterConfigs.form.faxNumber")} />,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
    },
    {
      size: 12,
      field: (
        <div>
          <Typography component="span" variant="caption" color="textPrimary">
            {t("promoter.PromoterConfigs.form.notifications")}
          </Typography>
          <FormArrayFields name="functional.contact.notificationUsers" formFields={formFieldsNotificationUsers(t)} />
        </div>
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
  ];
};

export const formFieldsNotificationUsers = (t) => (item) => {
  return [
    {
      size: 6,
      field: (
        <TextField id={`${item}.name`} type="text" label={t("promoter.PromoterConfigs.form.userNotificationName")} />
      ),
    },
    {
      size: 6,
      field: (
        <TextField id={`${item}.email`} type="text" label={t("promoter.PromoterConfigs.form.userNotificationEmail")} />
      ),
    },
  ];
};

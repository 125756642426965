import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./CatalogBuyersDataGrid.styles";
import { listColumns } from "./CatalogBuyersDataGrid.columns";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

function CatalogBuyersDataGrid({ classes, entities}) {
  const { t } = useTranslation();
  const columns = listColumns(t, classes);
  return (
    <div className={classes.root}>
      {entities && (
        <CustomDataGrid
          localFilters
          columns={columns}
          rows={entities}
          total={entities.length}
        />
      )}
    </div>
  );
}

export default withStyles(CatalogBuyersDataGrid, styles)
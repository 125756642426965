import React from "react";
import { withStyles } from "tss-react/mui";
import { Stack, Tooltip, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { styles } from "./_base.styles";
import moment from "moment";

function DatePickerInput({
  classes,
  id,
  className,
  label,
  value,
  onChange,
  disabled,
  error,
  required,
  format = "DD/MM/YYYY",
  minDate,
  maxDate,
  note,
}) {
  let tempId = new Date().getTime().toString();
  let minDateMoment = undefined;
  if (!disabled && minDate) {
    minDateMoment = !moment.isMoment(minDate) ? moment(minDate) : minDate;
  }
  let maxDateMoment = undefined;
  if (!disabled && maxDate) {
    maxDateMoment = !moment.isMoment(maxDate) ? moment(maxDate) : maxDate;
  }
  return (
    <div id={id} className={classes.root + (className ? " " + className : "")}>
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="flex-end" >
        {label && (
          <Typography
            style={{ marginBottom: 8, marginTop: 8 }}
            component="span"
            variant="caption"
            htmlFor={id}
            color="textPrimary"
            className={disabled ? classes.disabledLabel : ""}
          >
            {label}
            {required && <span> *</span>}
          </Typography>
        )}
        {note && <Tooltip title={note} placement="left"><InfoOutlinedIcon color="primary" className={classes.noteIcon} /></Tooltip>}
      </Stack>
      <DatePicker
        value={value ? moment(value) : null}
        className={classes.datepicker + " " + classes.input + (disabled ? " " + classes.disabledInput : "")}
        format={format}
        disabled={Boolean(disabled)}
        onChange={(newValue) => {
          onChange && onChange(moment(newValue));
        }}
        minDate={minDateMoment}
        maxDate={maxDateMoment}
        slotProps={{ textField: { fullWidth: true, id: tempId, error: error } }}

      />
    </div>
  );
}

export default withStyles(DatePickerInput, styles);

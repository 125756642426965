import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Paper, Typography, Stack, Box } from "@mui/material";

import { styles } from "./CreateInitStep.styles";
import { formFields, formSchema } from "./CreateInitStep.schema";

import { useNavigate, usePromoter, useUser } from "../../../../common/hooks";
import { selectLoader } from "../../../../app/coreSlice";
import { Form, FormFields } from "../../../../common/forms";
import Page from "../../../../common/displays/Page/Page";
import PageTitle from "../../../../common/displays/PageTitle/PageTitle";
import FormErrors from "../../../../common/forms/FormErrors/FormErrors";
import Permission from "../../../../app/components/Permission";
import { isGtinValid, zerosGtinParser } from "../../../../common/utils";
import { productGetBase, selectProductBase, selectProductBaseError, setProductBase, setProductBaseError } from "../../productsSlice";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import { selectAutoCompleteEntities } from "../../../entities/entitiesSlice";

function CreateInitStep({ classes, onSubmit, initialValues = {}, entity }) {
  const { t } = useTranslation();
  const user = useUser();
  const promoter = usePromoter();
  const loading = useSelector(selectLoader);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productBase = useSelector(selectProductBase);
  const productBaseError = useSelector(selectProductBaseError);
  const [localObject, setLocalObject] = useState(initialValues);
  const entities = useSelector(selectAutoCompleteEntities);

  useEffect(() => {
    if (initialValues) {
      setLocalObject({
        ...initialValues,
        baseInfo: {
          ...initialValues.baseInfo,
          gln: localObject?.baseInfo?.gln || initialValues.baseInfo?.gln,
          ownerId: localObject?.baseInfo?.ownerId || initialValues.baseInfo?.ownerId,
          gtin: localObject?.baseInfo?.gtin || initialValues.baseInfo?.gtin,
          supplierInternalCode: productBase?.baseInfo?.supplierInternalCode || localObject?.baseInfo?.supplierInternalCode || initialValues.baseInfo?.supplierInternalCode,
        }
      });
    }
    // eslint-disable-next-line
  }, [initialValues]);

  const onOwnerChange = (owner, form) => {
    if (owner?.gln) {
      form.change("baseInfo.gln", owner?.gln);
    }
  }

  const onGlnChange = () => {
    if (productBase) {
      dispatch(setProductBase(null));
      dispatch(setProductBaseError(null));
    }
  }

  const onGtinChange = () => {
    if (productBase) {
      dispatch(setProductBase(null));
      dispatch(setProductBaseError(null));
    }
  }

  const onGS1Check = (gtin, gln, form) => {
    let gtinParsed = zerosGtinParser(gtin);
    form.change("baseInfo.gtin", gtinParsed);
    dispatch(productGetBase(gtinParsed, gln));
  }

  const onSubmitHandler = (values, form) => {
    let gtinParsed = values.baseInfo?.gtin;
    if (gtinParsed) {
      gtinParsed = zerosGtinParser(gtinParsed);
      form.change("baseInfo.gtin", gtinParsed);
    }
    let ownerName = user?.entity?.type === "P"
      ? (entities || []).find(item => item.entityId === values.baseInfo?.ownerId)?.name
      : (entity?.businessUnits || []).find(item => item.id === values.baseInfo?.ownerId)?.name;
    let nValues = {
      ...values,
      baseInfo: {
        ...values.baseInfo,
        gtin: gtinParsed,
        ownerName,
      },
      externalSource: Boolean(productBase)
    }
    onSubmit && onSubmit(nValues);
    setLocalObject(nValues);
  }

  return (
    <div >
      <Form
        onSubmit={onSubmitHandler}
        schema={formSchema({ t, user })}
        initialValues={localObject}
        keepDirtyOnReinitialize
        render={({ submitFailed, errors, form, values }) => {
          return (
            <Page
              permission="products.actions.product.create"
              header={<PageTitle
                title={t("products.ProductCreate.header.title")}
                onBack={() => navigate("/products")}
                actions={
                  <Grid container direction="row" spacing={2} alignItems="center">

                    {submitFailed && Object.keys(errors)?.length > 0 && (
                      <Grid item>
                        <FormErrors errors={errors} />
                      </Grid>
                    )}

                    <Permission code="products.actions.product.create">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          type="submit"
                          disabled={loading || (!values.baseInfo?.gtin && !values.baseInfo?.supplierInternalCode)}
                        >
                          {t("products.ProductCreate.createForm.btnNext")}
                        </Button>
                      </Grid>
                    </Permission>
                  </Grid>
                }
              />}>
              <Paper className={classes.root} elevation={0}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <FormFields
                      fields={formFields({
                        t, user, promoter, loading, onOwnerChange, onGlnChange, onGtinChange, form, entity
                      })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {!values.baseInfo?.gtin && !values.baseInfo?.supplierInternalCode &&
                      <Typography variant="caption" className={classes.error}>
                        {t("products.ProductCreate.createForm.gtinInternalCodeRequired")}
                      </Typography>
                    }
                    {
                      isGtinValid(values.baseInfo?.gtin) && values.baseInfo?.gln?.length === 13 &&
                      <Stack spacing={2}>
                        <Typography className={classes.nomargin}>
                          {t("products.ProductCreate.createForm.gs1Message")}
                        </Typography>
                        <Button variant="contained" disabled={loading} onClick={() => onGS1Check(values.baseInfo?.gtin, values.baseInfo?.gln, form)}>{t("products.ProductCreate.createForm.gs1CheckBtn")}</Button>
                        {productBase && <Box className={classes.product}>
                          <ProductSnip
                            image={productBase.media?.[0]?.thumbnail || productBase.logo?.url}
                            description={productBase?.baseInfo?.description}
                          />
                        </Box>}
                        {productBaseError === "NOT_FOUND" &&
                          <Typography variant="caption" className={classes.error}>
                            {t("products.ProductCreate.createForm.gs1NotFound")}
                          </Typography>
                        }
                        {productBaseError === "INVALID_UNIT" &&
                          <Typography variant="caption" className={classes.error}>
                            {t("products.ProductCreate.createForm.gs1InvalidUnit")}
                          </Typography>
                        }
                        {productBaseError === "TIMEOUT_ERROR" &&
                          <Typography variant="caption" className={classes.error}>
                            {t("products.ProductCreate.createForm.gs1Timeout")}
                          </Typography>
                        }
                      </Stack>
                    }
                  </Grid>
                </Grid>
              </Paper>
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(CreateInitStep, styles);
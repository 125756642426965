import { Grid, Typography } from "@mui/material";
import { QuantityInput } from "../../../../common/inputs/custom";
import { currencyFormat } from "../../../../common/utils";
import CampaignTooltip from "../../_common/CampaignTooltip/CampaignTooltip";
import PriceSelector from "../common/PriceSelector/PriceSelector";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";

export function listColumns(t, classes, currentOrder, onPriceChanged, onQuantityChanged) {
  const getOrderProduct = ({ productId, ownerId, isOffer }) => {
    return (currentOrder?.products || []).find(
      (item) => item.productId === productId && item.ownerId === ownerId && item.isOffer === isOffer
    );
  };

  return [
    {
      field: "description",
      headerName: t("orders.OrdersCreate.columns.description"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <ProductSnip
            image={v.thumbnail || v.image}
            description={v.description}
            ownerName={v.ownerName}
            gtin={v.gtin}
            promoterInternalCode={v.promoterInternalCode}
          />
        );
      },
    },
    {
      field: "quantity",
      headerName: t("orders.OrdersCreate.columns.quantity"),
      align: "center",
      headerAlign: "center",
      disableSort: true,
      customValue: (v) => {
        let product = getOrderProduct(v);
        let availableCampaigns = (v.campaigns || []).filter((item) => item.active);
        let merchCampaign = availableCampaigns.find((camp) => camp.campaignType === "MB");

        return (
          <Grid container spacing={1} alignItems="center" justifyContent="center">
            <Grid item>
              <QuantityInput
                slim
                style={{ width: 120 }}
                min={v.minQuantity}
                max={v.maxQuantity}
                multi={v.quantityMultiple}
                value={v.isOffer ? v.quantity : product?.quantity || 0}
                onChange={(value) => onQuantityChanged({ ...v, ...product, quantity: value })}
                disabled={v.isOffer}
                hierarchy={v.hierarchy}
              />
            </Grid>
            {merchCampaign && product?.quantity >= merchCampaign?.merchandiseBonusesCampaign?.qtBuy && (
              <Grid item>
                <Typography className={classes.nomargin}>
                  {t("orders.OrdersCreate.columns.quantityOffer", {
                    offer: Math.floor(
                      (product?.quantity / (merchCampaign?.merchandiseBonusesCampaign?.qtBuy || 1)) *
                      merchCampaign?.merchandiseBonusesCampaign?.qtOffer
                    ),
                  })}
                </Typography>
              </Grid>
            )}
          </Grid>
        );
      },
    },
    {
      field: "price",
      headerName: t("orders.OrdersCreate.columns.price"),
      align: "center",
      headerAlign: "center",
      disableSort: true,
      customValue: (v) => {
        if (v.isOffer) {
          return (
            <Grid container alignItems="center" justifyContent="center">
              <CampaignTooltip campaign={v.campaign} quantity={v?.quantity || 0} currentOrder={currentOrder} />
            </Grid>
          );
        }
        let product = getOrderProduct(v);
        let availableCampaigns = (v.campaigns || []).filter((item) => item.active);

        return (
          <Grid container spacing={1} alignItems="center" justifyContent="flex-end" wrap="nowrap">
            {availableCampaigns.map((item, index) => (
              <Grid item key={`campaign_${index}`}>
                <CampaignTooltip campaign={item} quantity={product?.quantity || 0} currentOrder={currentOrder} />
              </Grid>
            ))}
            <Grid item>
              <PriceSelector
                product={{ ...v, ...product }}
                quantity={product?.quantity}
                onChange={(value) => onPriceChanged({ ...v, ...product, ...value })}
              />
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "vat",
      headerName: t("orders.OrdersCreate.columns.tax"),
      align: "center",
      headerAlign: "center",
      disableSort: true,
      customValue: (v) => v.vat !== undefined ? <Typography style={{ whiteSpace: "nowrap", margin: 0 }}>{v.vat} %</Typography> : ""
    },
    {
      field: "total",
      headerName: t("orders.OrdersCreate.columns.total"),
      align: "right",
      headerAlign: "right",
      disableSort: true,
      customValue: (v) => {
        let product = getOrderProduct(v);

        if (product && product.quantity > 0 && !v.isOffer) {
          return <Typography style={{ whiteSpace: "nowrap" }}>{currencyFormat(product.price * product.quantity)}</Typography>;
        } else {
          return <Typography style={{ whiteSpace: "nowrap" }}>{currencyFormat(0)}</Typography>;
        }
      },
    },
  ];
}

import {List, ListItem, Typography} from "@mui/material";
import CustomTooltip from "../../../../common/displays/CustomTooltip/CustomTooltip";

export function listColumns(t) {
  let columns = [];
  
  
  columns.push({
    field: "title",
    headerName: t("places.PlaceList.columns.title"),
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{v.title}</Typography>
    }
  });
  
  columns.push({
    field: "gln",
    headerName: t("places.PlaceList.columns.gln"),
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{v.gln}</Typography>
    }
  });
  
  columns.push({
    field: "type",
    headerName: t("places.PlaceList.columns.type"),
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return t("common.Enums.placeTypes." + v.type);
    },
  });
  
  columns.push({
    field: "parentName",
    headerName: t("places.PlaceList.columns.parent"),
    align: "left",
    headerAlign: "left",
    disableSort: true,
    customValue: (v) => {
      if (v.parentName) {
        return <Typography style={{margin: 0, whiteSpace: "nowrap"}}>{v.parentName}</Typography>
      } else
        return <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>-</Typography>
    }
  });
  
  columns.push({
    field: "children",
    headerName: t("places.PlaceList.columns.children"),
    align: "left",
    headerAlign: "left",
    disableSort: true,
    customValue: (v) => {
      if (v.children) {
        return (<List>
          {Object.keys(v.children).map(function(keyName, keyIndex) {
            return (
                <ListItem key={`children_${keyIndex}`}>
                  {
                    getCustomTooltip(keyName, v.children[keyName])
                  }
                </ListItem>
            );
          })}
        </List>);
      }
      else return <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>-</Typography>
    }
  });

  columns.push({
    field: "active",
    headerName: t("places.PlaceList.columns.status"),
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return t("places.PlaceList.filter.status_" + v.active);
    },
  });


  function getCustomTooltip(type, items) {
    return <CustomTooltip title={
      <List>
        {(items || []).map(function(place) {
          return (
              <ListItem key={`place_${place.id}`}>
                <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{`${place?.name}`}</Typography>
              </ListItem>
          );
        })}
      </List>
    }>
      <Typography style={{
        margin: 0,
        whiteSpace: "nowrap"
      }}>{`${t("common.Enums.placeTypes."+type)} (${items?.length})`}</Typography>
    </CustomTooltip>;
  }

  return columns;
}

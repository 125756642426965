import React from "react";

import { Accordion, AccordionSummary, AccordionDetails, Typography, Badge } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import I18nComponent from "./I18nComponent/I18nComponent";

import defaultI18n from "../../../../app/i18n/index.js";
import { hasEmptyKeys } from "../../i18n.auxiliars";

function I18nFeature({ classes, tab, index, feature, i18nValues, setI18nValues, emptyFields }) {
  let components = Object.keys(defaultI18n[feature]);

  let uiComponents = components.map((item) => {
    return (
      <Accordion key={"component_" + item}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Badge color="error" variant="dot" invisible={!hasEmptyKeys(emptyFields, feature + "." + item)}>
            <Typography>{item}</Typography>
          </Badge>
        </AccordionSummary>
        <AccordionDetails>
          <I18nComponent
            classes={classes}
            component={item}
            feature={feature}
            i18nValues={i18nValues}
            setI18nValues={setI18nValues}
          />
        </AccordionDetails>
      </Accordion>
    );
  });

  return <div>{tab === index && uiComponents}</div>;
}

export default I18nFeature;

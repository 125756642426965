export const styles = (theme) => ({
  root: {
    cursor: "pointer",
    // minHeight: 50,
    // backgroundColor: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0.5) + " " + theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.primary.back,
    }
  },
  icon: {
    display: "block",
    marginRight: theme.spacing(1),
  },
  taskActive: {
    color: theme.palette.text.secondary,
    "& svg": {
      color: theme.palette.text.secondary,
    }
  },
  taskDisabled: {
    color: theme.palette.text.disabled,
    textDecoration: "line-through",
    "& svg": {
      color: theme.palette.text.disabled,
    }
  }
});
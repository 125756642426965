import { Typography } from "@mui/material";
import ContactCardSlim from "../../entities/_common/ContactCardSlim/ContactCardSlim";

export const getContactOptions = (contacts) => {
  return (contacts || []).filter(item => item.roleType !== "A" && item.roleType !== "N").map((contact) => {
    return {
      value: contact.id,
      text: <ContactCardSlim contact={contact} />
    };
  });
};

export const getGroupOptions = (sectorsGroups) => {
  return (sectorsGroups || []).map((sectorsGroup) => {
    return {
      value: sectorsGroup.id,
      text: <Typography>{sectorsGroup.groupName}</Typography>
    };
  });
};

export const getSectorOptions = (t, refData) => {
  return (refData?.sector || []).map((item) => {
    return {
      value: item,
      text: t("common.Enums.sector." + item),
    };
  });
};
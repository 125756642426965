import { requestHelper, setRedirect } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";


const orderListOrdersAggregateCall = async ({
  clearAddressAggregatedOrders,
  setAddressAggregatedOrdersFilter,
  setAddressAggregatedOrdersDetails,
  appendToAddressAggregatedOrders,
  setAddressAggregatedOrdersTotalRows }, dispatch, { id, filter, clear }) => {
  let rh = requestHelper(dispatch, "LIST_ORDERS_AGGREGATE");
  if (clear) {
    dispatch(clearAddressAggregatedOrders());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setAddressAggregatedOrdersFilter(filter));
  try {
    let result = await call({
      type: "ORDER",
      subType: "LIST_ORDERS_AGGREGATE",
      request: {
        aggregateId: id,
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });

    dispatch(
      setAddressAggregatedOrdersDetails({
        aggregateId: result.aggregateId,
        cutOffTime: result.cutOffTime,
      })
    );
    dispatch(appendToAddressAggregatedOrders(result.ordersFromAggregate));
    dispatch(setAddressAggregatedOrdersTotalRows(result.rows));
  } catch (ex) {
    // rh.error("orders", ex);
    dispatch(setRedirect("/orders/received"));
  }
  rh.close();
};

export default orderListOrdersAggregateCall;
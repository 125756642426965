import React, { useRef, useEffect } from "react";
import { Grid, IconButton } from "@mui/material";
import { useDrag, useDrop } from "react-dnd";
import { usePrevious } from "../../../hooks";
import DeleteIcon from "@mui/icons-material/Delete";

const style = {
  border: "1px dashed gray",
  padding: "5px",
  marginBottom: "5px",
  backgroundColor: "white",
  cursor: "move",
  width: "100%",
  position: "relative",
};

export const ImageDraggable = ({ imageUrl, index, moveImage, onStopDragging, onDelete }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "image",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveImage(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "image",
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const wasDragging = usePrevious(isDragging);
  useEffect(() => {
    if (wasDragging && !isDragging) {
      onStopDragging && onStopDragging();
    }
    // eslint-disable-next-line
  }, [isDragging]);

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Grid item ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      <img src={imageUrl} style={{ maxWidth: 300, display: "flex", margin: "0 auto", width: "100%" }} alt={imageUrl} />
      <IconButton aria-label="delete" style={{ position: "absolute", top: 10, right: 10 }} onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </Grid>
  );
};

import React, { forwardRef, useState, useEffect } from "react";
import { withStyles } from "tss-react/mui";
import { InputAdornment, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { NumericFormat } from "react-number-format";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from "prop-types";

import { styles } from "./_base.styles";

function NumberInput({
  classes,
  id,
  className,
  label,
  value,
  onChange,
  disabled,
  required,
  error,
  decimalScale,
  allowNegative,
  endAdornment,
  startAdornment,
  textAlign = "right",
  maxLength = 9,
  allowLeadingZeros,
  note,
}) {

  const onChangeHandler = (value) => {
    if (disabled) {
      return;
    }
    onChange && onChange(value);
  };

  const focusHandler = (e) => {
    if (e?.target?.select) {
      setTimeout(() => {
        e.target.select()
      }, 50);
    }
  }

  return (
    <div className={classes.root + (className ? " " + className : "")}>
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="flex-end">
        {label && (
          <Typography
            style={{ marginBottom: 8, marginTop: 8 }}
            component="span"
            variant="caption"
            htmlFor={id}
            color="textPrimary"
            className={disabled ? classes.disabledLabel : ""}
          >
            {label}
            {required && <span> *</span>}
          </Typography>
        )}
        {note && <Tooltip title={note} placement="left"><InfoOutlinedIcon color="primary" className={classes.noteIcon} /></Tooltip>}
      </Stack>
      <TextField
        fullWidth
        InputProps={{
          inputComponent: NumericFormatCustom,
          endAdornment: endAdornment ? (
            <InputAdornment className={classes.adornment} position="end">
              {endAdornment}
            </InputAdornment>
          ) : undefined,
          startAdornment: startAdornment ? (
            <InputAdornment className={classes.adornment} position="start">
              {startAdornment}
            </InputAdornment>
          ) : undefined,
        }}
        // eslint-disable-next-line
        inputProps={{
          id,
          decimalScale,
          allowNegative,
          allowLeadingZeros,
          textAlign,
          maxLength
        }}
        className={classes.input + (disabled ? " " + classes.disabledInput : "")}
        onChange={(e) => onChangeHandler(e.target.value)}
        value={value}
        disabled={Boolean(disabled)}
        error={error}
        size="small"
        onFocus={focusHandler}
      />
    </div>
  );
}

export default withStyles(NumberInput, styles);

const NumericFormatCustom = forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, decimalScale, allowNegative = false, allowLeadingZeros = false, textAlign, maxLength, id, value, ...other } = props;
  const [lValue, setValue] = useState(value || "");

  useEffect(() => {
    if (value) {
      setValue(value);
    }
  }, [value])

  return (
    <NumericFormat
      {...other}
      id={id + "_input"}
      getInputRef={ref}
      value={lValue}
      onChange={(e) => {
        let nValue = e.target.value;
        if (nValue !== lValue) {
          if (nValue) {
            let hasTrailingDot = nValue.endsWith('.');
            if (!allowLeadingZeros) {
              let decimalScaleFix = nValue.split('.')?.[1]?.length || 0;
              nValue = parseFloat(nValue).toFixed(decimalScaleFix);
              if (maxLength && nValue && nValue.length > maxLength) {
                let splitValue = nValue.split('.');
                nValue = (splitValue?.[0] || "").slice(0, maxLength) + (splitValue?.[1] ? "." + splitValue[1] : "");
              }
            }
            if (hasTrailingDot) {
              nValue += ".";
            }
          }

          setTimeout(() => {
            e.target.value = nValue;
          }, 10);

          setValue(nValue);
          onChange({
            target: {
              name: props.name,
              value: nValue,
            },
          });
        }
      }}
      onBlur={(e) => e.target.value = lValue}
      style={{ textAlign }}
      allowNegative={allowNegative}
      valueIsNumericString
      decimalScale={decimalScale}
      allowLeadingZeros={allowLeadingZeros}
    />
  );
});

NumberInput.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  decimalScale: PropTypes.number,
  allowNegative: PropTypes.bool,
  endAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  startAdornment: PropTypes.object,
  textAlign: PropTypes.string,
  maxLength: PropTypes.number,
  allowLeadingZeros: PropTypes.bool,
  note: PropTypes.string
};

export const styles = (theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  noMargin: {
    margin: 0,
  },
  image: {
    padding: theme.spacing(2),
    position: "relative",
    "&:hover .MuiIconButton-root": {
      display: "block",
    },
  },
  img: {
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: 160,
  },
});

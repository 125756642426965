import { Grid, Typography } from "@mui/material";
import { PicturePlaceholder } from "../../../../common/icons";

export function listColumns(t, classes) {
  return [
    {
      field: "description",
      headerName: t("common.ProductPicker.columns.description"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <Grid
              item
              style={{
                width: 60,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {v.image ? (
                <img
                  style={{ maxWidth: 44, maxHeight: 40 }}
                  alt={v.description}
                  src={v.image}
                />
              ) : (
                <PicturePlaceholder />
              )}
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.nomargin}>
                    {v.description}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    className={classes.nomargin}
                  >
                    {v.gtin}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "brand",
      headerName: t("common.ProductPicker.columns.brand"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "price",
      headerName: t("common.ProductPicker.columns.price"),
      align: "left",
      headerAlign: "left",
    },
    // {
    //   field: "ownerName",
    //   headerName: t("common.ProductPicker.columns.ownerName"),
    //   align: "left",
    //   headerAlign: "left",
    // },
  ];
}

import React from "react";
import { Form as FinalForm } from "react-final-form";
import { useValidationSchema } from "../hooks";

function Form({ schema, render, ...props }) {
  const validate = useValidationSchema(schema);
  return (
    <FinalForm
      validate={validate}
      {...props}
      render={({ handleSubmit, ...rest }) => {
        return (
          <form onSubmit={handleSubmit} noValidate style={{ width: "100%" }}>
            {render && render(rest)}
          </form>
        );
      }}
    />
  );
}

export default Form;

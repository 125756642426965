import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const entityUpdateContactCall = async ({ entityGet }, dispatch, state, { entityId, contactId, contact }) => {
  let rh = requestHelper(dispatch, "UPDATE_CONTACT");
  try {
    await call({
      type: "ENTITY",
      subType: "UPDATE",
      request: {
        contact: {
          ...contact,
          logo: undefined,
          id: undefined,
        },
        id: contactId,
        entityId,
      },
    });
    dispatch(setSuccess("entities", "ENTITY_UPDATE_CONTACT"));
    dispatch(setRedirect(`/entities/${entityId}/contacts`));
    dispatch(entityGet(entityId));
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityUpdateContactCall;

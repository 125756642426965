import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const entityUpdateConfigCall = async (
  { entityGet },
  dispatch,
  { entityId, cep, fee, gln, trafficType, operationType, cmpAccumulation, cpAccumulation, businessUnitDesc, promoterInternalCode }
) => {
  let rh = requestHelper(dispatch, "ENTITY/UPDATE");
  if (!fee?.quantity || fee?.quantity === "0") {
    fee = undefined;
  }
  try {
    await call({
      type: "ENTITY",
      subType: "UPDATE",
      request: {
        id: entityId,
        config: {
          cep,
          fee,
          gln,
          trafficType,
          operationType,
          cmpAccumulation,
          cpAccumulation,
          businessUnitDesc,
          promoterInternalCode
        },
      },
    });
    setTimeout(() => {
      dispatch(setSuccess("entities", "ENTITY_UPDATE_CONFIG"));
      dispatch(entityGet(entityId));
      dispatch(setRedirect("/entities/" + entityId));
    }, 200);
  } catch (ex) {
    rh.error("entities", ex);
  }
  setTimeout(() => {
    rh.close();
  }, 250);
};

export default entityUpdateConfigCall;

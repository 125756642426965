import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const auditRetryJobCall = async ({ auditListAuditJob }, dispatch, { id, filter }) => {
  let rh = requestHelper(dispatch, "AUDIT_RETRY_JOB");

  try {
    await call({
      type: "AUDIT",
      subType: "RETRY_JOB",
      request: {
        id
      }
    });
    dispatch(setSuccess("business", "AUDIT_RETRY_JOB"));
    setTimeout(() => {
      dispatch(auditListAuditJob(filter, true));
    }, 300);
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default auditRetryJobCall;

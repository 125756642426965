import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { Button, Grid, List, ListItem, ListItemText } from "@mui/material";
import { FieldArray } from "react-final-form-arrays";
import { styles } from "./ListArrayFields.styles";
// import MetaError from "../../forms/MetaError";

function ListArrayFields({ classes, id, formFields, onAddMore, textField, addMoreLabel, deleteLabel }) {
  const [tab, setTab] = useState(0);

  const onAddMoreHandler = (e, fields) => {
    onAddMore && onAddMore(e);
    setTab(fields.length);
  };

  const onRemoveHandler = (fields, i) => {
    fields.remove(i);
    setTab(0);
  };

  return (
    <div className={classes.root}>
      <FieldArray name={id} id={id}>
        {({ fields, meta }) => {
          return (
            <div>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <List dense>
                    {(fields?.value || []).map((item, index) => (
                      <ListItem
                        key={"list_item_" + index}
                        button
                        onClick={() => setTab(index)}
                        className={tab === index ? classes.selected : null}
                      >
                        <ListItemText primary={item[textField]} />
                      </ListItem>
                    ))}
                  </List>
                  <Button onClick={(e) => onAddMoreHandler(e, fields)} variant="contained" color="secondary" fullWidth>
                    {addMoreLabel}
                  </Button>
                </Grid>
                <Grid item xs={8}>
                  {fields.map((item, index) => (
                    <div hidden={tab !== index} key={item}>
                      <Grid container alignItems="flex-start" spacing={2}>
                        {formFields(item).map((item, idx) =>
                          item.hidden ? null : (
                            <Grid item xs={item.size} key={idx}>
                              {item.field}
                            </Grid>
                          )
                        )}
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button variant="contained" color="secondary" onClick={() => onRemoveHandler(fields, index)}>
                            {deleteLabel}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </Grid>
              </Grid>
              {/* <MetaError className={classes.error} meta={meta} /> */}
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
}

export default withStyles(ListArrayFields, styles)

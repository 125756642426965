import {currencyFormat} from "../../../../../common/utils";

export function listColumns(t, classes) {
  return [
    {
      field: "buyerName",
      headerName: t("business.reports.TransportCostItemsList.columns_buyerName"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "deliveryPlace",
      headerName: t("business.reports.TransportCostItemsList.columns_deliveryPlace"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "transportCost",
      headerName: t("business.reports.TransportCostItemsList.columns_transportCost"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return currencyFormat(v.transportCost);
      },
    },
    {
      field: "totalTransportUnits",
      headerName: t("business.reports.TransportCostItemsList.columns_totalTransportUnits"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "averageCost",
      headerName: t("business.reports.TransportCostItemsList.columns_averageCost"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return currencyFormat(v.averageCost);
      },
    },

  ];
}

import { operatorSlice } from "./operatorSlice";
import { call } from "../../app/websocket/_sliceCalls/websocket";
import { setSuccess, requestHelper } from "../../app/coreSlice";

export const selectLoader = (state) => state.core.loader.length > 0;
export const selectCost = (state) => state.operator.cost;

const { setOperatorCost } = operatorSlice.actions;

export const getOperatorCost = (id) => async (dispatch) => {
  let rh = requestHelper(dispatch, "GET_OPERATOR_COST");
  try {
    let result = await call({
      type: "OPERATOR",
      subType: "COST",
      request: {
        id,
      },
    });
    let cost = { ...result.cost };
    if (!cost.transport) {
      cost.transport = {
        weight: {},
        pallet: {},
      };
    }
    dispatch(setOperatorCost(cost));
  } catch (ex) {
    rh.error("operator", ex);
  }
  rh.close();
};

export const defineOrderCost = (id, cost) => async (dispatch) => {
  let rh = requestHelper(dispatch, "DEFINE_COST");
  try {
    await call({
      type: "OPERATOR",
      subType: "DEFINE_COST",
      request: {
        id,
        cost,
      },
    });
    setTimeout(() => {
      dispatch(getOperatorCost(id));
      dispatch(setSuccess("operator", "OPERATOR_DEFINE_COST"));
    }, 300);
  } catch (ex) {
    rh.error("operator", ex);
  }
  rh.close();
};

import { Switch } from "@mui/material";

export function listColumns(t, classes, onActivate, user) {
  return [
    {
      field: "name",
      headerName: t("products.CatalogList.columns.name"),
      align: "left",
      headerAlign: "left",
      // customValue: (v) => {
      //   return (
      //     <Grid container direction="column">
      //       <Typography className={classes.id} variant="h4">
      //         {v.id}
      //       </Typography>
      //       <Typography className={classes.orderBy}>{v.orderBy}</Typography>
      //     </Grid>
      //   );
      // },
    },
    {
      field: "ownerName",
      headerName: t("products.CatalogList.columns.ownerName"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "numberBuyer",
      headerName: t("products.CatalogList.columns.numberBuyer"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "numberProduct",
      headerName: t("products.CatalogList.columns.numberProduct"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "active",
      headerName: t("products.CatalogList.columns.active"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        let canEdit = user.hasPermission(v.active ? "products.actions.catalog.disable" : "products.actions.catalog.enable");

        return (
          <Switch
            checked={v.active}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => onActivate(v.id, e.target.checked)}
            color="primary"
            disabled={!canEdit}
          />
        );
      },
    },
  ];
}

import * as Yup from "yup";
import { EntityAutoCompleteField, SelectField, TextField } from "../../../../common/forms";

export const filterFields = () => (t) => {
  return [
    {
      size: 12,
      field: <SelectField id="month" label={t("business.queries.filter.month")} options={getMonthOptions(t)} />,
    },
    {
      size: 12,
      field: <SelectField id="year" label={t("business.queries.filter.year")} options={getYearOptions()} />,
    },
    {
      size: 12,
      field: (
        <EntityAutoCompleteField
          id="entityId"
          label={t("business.queries.filter.entity")}
          typeFilter={["S", "ST", "W", "LO"]}
        />
      )
    },
    {
      size: 12,
      field: (
        <SelectField
          id="discrepancies"
          label={t("business.queries.filter.discrepancies")}
          options={getDiscrepanciesOptions(t)}
        />
      ),
    },
    {
      size: 12,
      field: (
        <SelectField
          id="searchField"
          label={t("business.queries.filter.searchField")}
          options={getSearchFieldOverviewOptions(t)}
        />
      ),
    },
    {
      size: 12,
      field: <TextField id="search" label={t("business.queries.filter.search")} />,
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    month: Yup.string(),
    year: Yup.string(),
    entityId: Yup.string(),
    discrepancies: Yup.string(),
    searchField: Yup.string(),
    search: Yup.string(),
    active: Yup.string(),
  });
};

const getMonthOptions = (t) => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => {
    return {
      value: item,
      text: t("common.Enums.month." + item),
    };
  });
};

const getYearOptions = () => {
  let yearsArr = [];
  for (let i = 2020; i <= new Date().getFullYear(); i++) {
    yearsArr.push(i);
  }

  return yearsArr.map((item) => {
    return {
      value: item,
      text: item,
    };
  });
};

const getDiscrepanciesOptions = (t) => {
  return [true, false].map((item) => {
    return {
      value: item,
      text: t("business.queries.discrepancies." + item),
    };
  });
};

const getSearchFieldOverviewOptions = (t) => {
  return ["NONE", "SELLERNAME", "BUYERNAME", "GTIN", "GLN", "DESCRIPTION", "ORDERSELLERID", "ORDERID", "CUTOFFID"].map(
    (item) => {
      return {
        value: item,
        text: t("business.queries.filter.searchField_" + item),
      };
    }
  );
};

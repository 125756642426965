

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const businessUnitTasksCall = async (
  { setBusinessUnitTasks },
  dispatch,
  { businessUnitId }
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_TASKS");
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "TASKS",
      request: {
        businessUnitId,
      },
    });

    dispatch(setBusinessUnitTasks(result.items))

  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitTasksCall;


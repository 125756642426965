import React from "react";
import * as Yup from "yup";
import moment from "moment";
import { DateTimePickerField, SelectField } from "../../../../common/forms";
import { getAuditDataSchemaTypeOptions } from "../../BusinessForm.options";
import TextField from "../../../../common/forms/fields/TextField";

export const filterFields = () => (t) => {
  return [
    {
      size: 12,
      field: (
        <DateTimePickerField
          id="dateIni"
          label={t("business.audit.filter.requestStartDate")}
          maxDate={moment().toISOString()}
        />
      )
    },
    {
      size: 12,
      field: (
        <DateTimePickerField
          id="dateEnd"
          label={t("business.audit.filter.requestEndDate")}
          maxDate={moment().toISOString()}
        />
      )
    },
    {
      size: 12,
      field: (
        <SelectField
          id="schema"
          label={t("business.audit.filter.schema")}
          options={getAuditDataSchemaTypeOptions(t)}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextField
          id="table"
          label={t("business.audit.filter.table")}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextField
          id="username"
          label={t("business.audit.filter.username")}
        />
      ),
    },

  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    dateIni: Yup.string(),
    dateEnd: Yup.string(),
  });
};

import * as Yup from "yup";
import { EntityAutoCompleteField, MultiSelectField, SelectField } from "../../../../common/forms";
import { getContactPointOptions, getFamiliesOptions } from "../../../products/ProductForm/ProductForm.options";

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    productFamily: Yup.array(),
    showDrafts: Yup.boolean(),
    status: Yup.array(),
  });
};

export const filterFields = ({ families, groupContacts, user, promoter }) => (t) => {
  return [
    {
      size: 12,
      field: (
        <EntityAutoCompleteField
          typeFilter={promoter?.getConfig("product", "allowedEntityTypes")}
          id="owner"
          label={t("productsPrices.PricesList.filter.owner")}
          required
        />
      ),
      hidden: user?.entity?.type !== "P",
    },
    {
      size: 12,
      field: (
        <SelectField
          id="contactPoint"
          label={t("productsPrices.PricesList.filter.contactPoint")}
          options={[
            { value: null, text: t("productsPrices.PricesList.filter.allContactPointsOption") },
            ...getContactPointOptions(groupContacts)
          ]}
        />
      ),
      hidden: !(promoter?.getConfig("product", "listProductFields") || []).includes("contactPoint")
    },
    {
      size: 12,
      field: (
        <MultiSelectField
          id="productFamily"
          label={t("productsPrices.PricesList.filter.productFamily")}
          options={getFamiliesOptions(t, families)}
        />
      ),
    },
    {
      size: 12,
      field: (
        <MultiSelectField id="status" label={t("productsPrices.PricesList.filter.status")} options={getStatusOptions(t)} />
      ),
      hidden: (promoter?.getConfig("tax", "autoAcceptCreateTax"))
    },
  ];
};

const getStatusOptions = (t) => {
  return ["PND", "ACC", "REF"].map((value) => {
    return {
      value,
      text: t("common.Enums.priceStatus." + value),
    };
  });
};


import { Stack } from "@mui/material"
import BlockCard from "../BlockCard/BlockCard"
import SlimCheckBoxField from "../SlimCheckBoxField/SlimCheckBoxField"

export const homeFormFields = (t) => {
  return [
    {
      size: 3,
      field: <BlockCard title="home">
        <Stack>
          <SlimCheckBoxField id="permissions.home.actions.home.get" label="get" />
        </Stack>
      </BlockCard>,
    },
  ]
}


import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const productRemovePriceCall = async (
  { productPriceList },
  dispatch,
  state,
  {
    priceId,
    productId,
    ownerId,
    contactPoint,
    removeDate,
    ownerName
  }
) => {
  let rh = requestHelper(dispatch, "PRODUCT_REMOVE_PRICE");
  // let filter = state.productsPrices.pricesListFilter;
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "REMOVE_PRICE",
      request: {
        prices: [
          {
            priceId,
            productId,
            ownerId,
            contactPoint,
            removeDate,
            ownerName
          }
        ]
      }
    });
    setTimeout(() => {
      if (result) {
        dispatch(setSuccess("productsPrices", "PRODUCT_REMOVE_PRICE"));
        // dispatch(productPriceList(filter, true))
      }
    }, 300);

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productRemovePriceCall;

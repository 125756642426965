import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";

import { styles } from "./CreateInternalOrderModal.styles";
import { formFields, formSchema } from "./CreateInternalOrderModal.schema";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";

import { Form, FormFields } from "../../../../common/forms";

function CreateInternalOrderModal({ classes, open, setOpen, onSubmit, products }) {
  const { t } = useTranslation();

  const onSubmitHandler = async (values) => {
    onSubmit && onSubmit(values);
    setOpen(false);
  };

  return (
    <CustomModal open={open} setOpen={setOpen} size="medium" height={430} title={t("batches.CreateInternalOrderModal.modal.title")}>
      <div className={classes.root}>
        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t })}
          initialValues={{}}
          render={({ values }) => {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormFields fields={formFields({ t, products })} />
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" type="submit" fullWidth>
                    {t("batches.CreateInternalOrderModal.form.btnSubmit")}
                  </Button>
                </Grid>
              </Grid>
            );
          }}
        />
      </div>
    </CustomModal>
  );
}

export default withStyles(CreateInternalOrderModal, styles);

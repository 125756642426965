import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const rolesCreateCall = async (dispatch, { entityId, entityType, designation, permissions, user, group, sector }) => {
  let rh = requestHelper(dispatch, "ROLES_CREATE");
  try {
    let result = await call({
      type: "ROLES",
      subType: "CREATE",
      request: {
        entityId,
        entityType,
        designation,
        roleType: "C",
        permissions,
        user,
        group,
        sector
      }
    });
    if (result) {
      dispatch(setSuccess("roles", "ROLES_CREATE"));
      dispatch(setRedirect(entityId ? `/roles/${entityId}` : "/roles"));
    }
  } catch (ex) {
    rh.error("roles", ex);
  }
  rh.close();
};

export default rolesCreateCall;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./OrdersIncidentsList.styles";
import OrdersIncidentsDataGrid from "./OrdersIncidentsDataGrid/OrdersIncidentsDataGrid";

import { selectList, selectListFilter, selectListTotalRows, orderListIncidents } from "../ordersIncidentsSlice";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";

import Page from "../../../common/displays/Page/Page";

function OrdersIncidentsList({ classes }) {
  const { t } = useTranslation();
  const data = useSelector(selectList);
  const filter = useSelector(selectListFilter);
  const totalRows = useSelector(selectListTotalRows);
  const dispatch = useDispatch();

  const onFilterChangeHandler = (v) => {
    dispatch(orderListIncidents(v.filter, v.reset));
  };

  return (
    <Page
      permission="orders.actions.incident.list"
      header={<PageTitle
          title={t("ordersIncidents.OrdersIncidentsList.header.title")}
          info={`${totalRows} ${t("ordersIncidents.OrdersIncidentsList.header.rowsInfo")}`}
      />}>

      <OrdersIncidentsDataGrid
        data={data}
        filter={filter}
        totalRows={totalRows}
        onFilterChange={onFilterChangeHandler}
      />
    </Page>
  );
}

export default withStyles(OrdersIncidentsList, styles);

import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid, AccordionSummary } from "@mui/material";
import { useTranslation } from "react-i18next";

import { styles } from "./ConfigurationAccordionSummary.styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CampaignTypeIcon, PicturePlaceholder } from "../../../../common/icons";

function ConfigurationAccordionSummary({ classes, campaign }) {
  const { t } = useTranslation();

  return (
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={3}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item>
              <CampaignTypeIcon type={campaign.campaignType} />
            </Grid>
            <Grid item>
              <Typography className={classes.heading} color="textSecondary">
                {t("common.Enums.campaignType." + campaign.campaignType)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <Grid container alignItems="center" spacing={3}>
            {(campaign.products || []).map((v, i) => (
              <Grid key={"prod_" + i} item>
                <Grid container spacing={2} alignItems="center" wrap="nowrap">
                  <Grid
                    item
                    style={{
                      width: 60,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {v && v.image ? (
                      <img style={{ maxWidth: 44, maxHeight: 40 }} alt={v.description} src={v.thumbnail || v.image} />
                    ) : (
                      <PicturePlaceholder />
                    )}
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography className={classes.nomargin}>{v?.description}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography color="textSecondary" className={classes.nomargin}>
                          {v?.gtin}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </AccordionSummary>
  );
}

export default withStyles(ConfigurationAccordionSummary, styles)

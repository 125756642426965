import {getPromoterConfig, requestHelper} from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import {asyncForEach} from "../../../common/utils";
import {processImageUrl} from "../../../api/s3";

const businessReportOrdersMovementsCall = async ({
  clearHistoryOrderMovementsList,
  setHistoryOrderMovementsList,
  setHistoryOrderMovementsListTotalRows,
  setHistoryOrderMovementsFilter
}, dispatch,state, { filter, clear }) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "BUSINESS_REPORT_ORDERS_MOVEMENTS");
  if (clear) {
    dispatch(clearHistoryOrderMovementsList());
  }
  dispatch(setHistoryOrderMovementsFilter(filter));

  try {
    const { limit, offset, sortField = "", sortType, month, year, searchField, search } = filter;
    let result = await call({
      type: "BUSINESS",
      subType: "REPORT_ORDERS_MOVEMENTS",
      request: {
        limit,
        offset,
        sortField: sortField.toUpperCase(),
        sortType,
        month,
        year,
        searchField,
        search: searchField ? search : undefined,
      },
    });

    if (result && result.ordersMovements && result.ordersMovements.length > 0) {
     
      let ordersMovement = [];
      for (const orderMovement of result.ordersMovements) {
        if(orderMovement.operationType === 'TF'){
          let newItems = [];
          await asyncForEach(orderMovement.items, async (item) => {
            newItems.push({
              ...item,
              image: await processImageUrl(fileStorageConfigs,  item.image),
              thumbnail: await processImageUrl(fileStorageConfigs,  item.thumbnail),
            });
          });
          ordersMovement.push({
            ...orderMovement,
            items: newItems
          });
        } else {
          ordersMovement.push(orderMovement);
        }
      }

      dispatch(setHistoryOrderMovementsList(ordersMovement));
      dispatch(setHistoryOrderMovementsListTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default businessReportOrdersMovementsCall;
import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const entityConfirmAddressCall = async ({ entityGet }, dispatch, { entityId, id }) => {
  let rh = requestHelper(dispatch, "CONFIRM_ADDRESS");
  try {
    await call({
      type: "ENTITY",
      subType: "CONFIRM_ADDRESS",
      request: { entityId, id },
    });
    dispatch(setSuccess("entities", "ENTITY_CONFIRM_ADDRESS"));
    dispatch(entityGet(entityId));
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityConfirmAddressCall;

import { createSlice } from "@reduxjs/toolkit";

import { hash } from "../../common/utils/crypto";
import { sleep } from "../../common/utils/utils";

import { userPasswordReset, userPasswordUpdate } from "../../api/logisticsRest";
import { logout } from "../../app/websocket/websocketSlice";


export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    loader: null,
    loginError: null,
    recoverError: null,
    updatePassError: null,
    status: null,
  },
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },

    setLoginError: (state, action) => {
      state.loginError = action.payload;
    },

    setRecoverError: (state, action) => {
      state.recoverError = action.payload;
    },

    setUpdatePassError: (state, action) => {
      state.updatePassError = action.payload;
    },

    setUser: (state, action) => {
      state.user = action.payload;
    },

    setStatus: (state, action) => {
      state.status = action.payload;
    },

    setLogout: (state) => {
      // this will be handle on store
    },
  },
});

export const { setLoader, setLoginError, setRecoverError, setUpdatePassError, setStatus, setLogout } =
  authenticationSlice.actions;

export const cleanErrors = () => async (dispatch) => {
  dispatch(setRecoverError(null));
  dispatch(setUpdatePassError(null));
};

export const recoverPassword = ({ email }) => async (dispatch) => {
  dispatch(setRecoverError(null));
  dispatch(setLoader("RECOVER_PASSWORD"));
  try {
    let response = await userPasswordReset({ email });
    if (response.status === 200) {
      dispatch(setStatus("RECOVER_SUCCESS"));
    } else {
      dispatch(setRecoverError("FAILED"));
    }
  } catch (ex) {
    dispatch(setRecoverError("ERROR"));
  }
  dispatch(setLoader(null));
  // reset result, otherwise it cant be possible to recover again
  await sleep(10000);
  dispatch(setStatus(null));
};

export const passwordUpdate =
  ({ password, token }) =>
    async (dispatch) => {
      dispatch(setUpdatePassError(null));
      dispatch(setLoader("PASSWORD_UPDATE"));
      try {
        let response = await userPasswordUpdate({ token, password: hash(password).toString() });
        if (response.status === 200) {
          dispatch(logout());
          await sleep(100);
          dispatch(setStatus("PASSWORD_UPDATED"));
        } else {
          dispatch(setUpdatePassError("FAILED"));
        }
      } catch (ex) {
        dispatch(setUpdatePassError("ERROR"));
      }
      dispatch(setLoader(null));
      await sleep(10000);
      // reset result, otherwise it cant be possible to recover again
      dispatch(setStatus(null));
    };

export const selectLoader = (state) => state.authentication.loader;
export const selectLoginError = (state) => state.authentication.loginError;
export const selectRecoverError = (state) => state.authentication.recoverError;
export const selectUpdatePassError = (state) => state.authentication.updatePassError;
export const selectUser = (state) => state.authentication.user;
export const selectStatus = (state) => state.authentication.status;

export default authenticationSlice.reducer;

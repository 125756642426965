import { createSlice } from "@reduxjs/toolkit";
import orderCreateIncidentCall from "./_sliceCalls/orderCreateIncident";
import orderListIncidentsCall from "./_sliceCalls/orderListIncidents";
import { ordersIncidentsDefaultFilter } from "../orders/_common/ordersFilters";

export const ordersIncidentsSlice = createSlice({
  name: "ordersIncidents",
  initialState: {
    list: null,
    listTotalRows: 0,
    listFilter: ordersIncidentsDefaultFilter,
  },
  reducers: {
    clearList: (state) => {
      state.list = [];
    },
    appendToList: (state, action) => {
      state.list = [...state.list, ...action.payload];
    },
    setListTotalRows: (state, action) => {
      state.listTotalRows = action.payload;
    },
    setListFilter: (state, action) => {
      state.listFilter = action.payload;
    },
  },
});

export default ordersIncidentsSlice.reducer;

// SELECTS
export const selectIsLoading = (state) => state.core.loader.length > 0;

export const selectList = (state) => state.ordersIncidents.list;
export const selectListTotalRows = (state) => state.ordersIncidents.listTotalRows;
export const selectListFilter = (state) => state.ordersIncidents.listFilter;

// CALLS
export const orderListIncidents =
  (filter, clear = false) =>
  (dispatch, getState) => {
    orderListIncidentsCall(ordersIncidentsSlice.actions, dispatch, getState(), filter, clear);
  };

export const orderCreateIncident = (values) => (dispatch) => {
  orderCreateIncidentCall(dispatch, values);
};

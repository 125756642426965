import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const businessReportProductPricesCall = async ({
  clearQueriesReportProductPricesList,
  setQueriesReportProductPricesList,
  setQueriesReportProductPricesListTotalRows,
  setQueriesReportProductPricesFilter
}, dispatch, { filter, clear }) => {
  let rh = requestHelper(dispatch, "BUSINESS_REPORT_PRODUCT_PRICES");
  if (clear) {
    dispatch(clearQueriesReportProductPricesList());
  }
  dispatch(setQueriesReportProductPricesFilter(filter));

  try {
    const { limit, offset, sortField = "", sortType, search, active, entityId } = filter;
    let result = await call({
      type: "BUSINESS",
      subType: "REPORT_PRODUCT_PRICES",
      request: {
        limit,
        offset,
        sortField: sortField.toUpperCase(),
        sortType,
        search,
        active,
        entityId
      },
    });

    if (result && result.productPrices && result.productPrices.length > 0) {
      dispatch(setQueriesReportProductPricesList(result.productPrices));
      dispatch(setQueriesReportProductPricesListTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default businessReportProductPricesCall;
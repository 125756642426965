export const styles = theme => ({
  root: {
    "& .CdgRow": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: '#fdfdfd'
      }
    }
  },
  id: {
    margin: 0,
  },
  orderBy: {
    margin: 0,
    color: "#aaa"
  },
  nomargin: {
    margin: 0,
  },
  price: {
    whiteSpace: "nowrap"
  },
  availableS: {
    color: theme.palette.warning.main,
  },
  availableO: {
    color: theme.palette.success.main,
  },
  availableN: {
    color: theme.palette.error.main,
  }
});
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";


const entityAddContactCall = async ({ entityGet }, dispatch, state, { entityId, contact }) => {
  let rh = requestHelper(dispatch, "ADD_CONTACT");
  try {
    await call({
      type: "ENTITY",
      subType: "ADD_CONTACT",
      request: {
        ...contact,
        id: [entityId],
        logo: undefined,
      },
    });
    dispatch(setSuccess("entities", "ENTITY_ADD_CONTACT"));
    dispatch(setRedirect(`/entities/${entityId}/contacts`));
    dispatch(entityGet(entityId));
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityAddContactCall;

import { currencyFormat } from "../../../../../common/utils";
import { Typography } from "@mui/material";

export function listColumns(t, classes) {
  return [
    {
      field: "sellerName",
      headerName: t("business.reports.SuppliersList.columns_sellerName"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "fee",
      headerName: t("business.reports.SuppliersList.columns_fee"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v.feeType === 'P' ? v.fee + " %" : currencyFormat(v.price);
      },
    },
    /*{
      field: "feeType",
      headerName: t("business.reports.SuppliersList.columns_feeType"),
      align: "left",
      headerAlign: "left",
    },*/
    {
      field: "cost",
      headerName: t("business.reports.SuppliersList.columns_cost"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return currencyFormat(v.cost);
      },
    },
    {
      field: "purchasesVolume",
      headerName: t("business.reports.SuppliersList.columns_purchasesVolume"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return currencyFormat(v.purchasesVolume);
      },
    },
    {
      field: "balance",
      headerName: t("business.reports.SuppliersList.columns_balance"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return currencyFormat(v.balance);
      },
    },
    {
      field: "delta",
      headerName: t("business.reports.SuppliersList.columns_delta"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v.delta >= 0 ? (
          <Typography className={classes.statusActive}>{v.delta}</Typography>
        ) : (
          <Typography className={classes.statusDisabled}>{v.delta}</Typography>
        );
      },
    },

  ];
}

import React from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./ScrollBox.styles";

function ScrollBox({ classes, children, style }) {
  return (
    <div className={classes.root} style={style}>
      {children}
    </div>
  );
}

export default withStyles(ScrollBox, styles);
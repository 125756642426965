import React from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./CurrencyInput.styles";
import NumberInput from "../../base/NumberInput";
import { usePromoter } from "../../../hooks";

function CurrencyInput({ classes, className, label, value, onChange, disabled, required }) {
  const promoter = usePromoter();
  let nProps = {};
  switch (promoter?.getConfig("general", "defaultCurrency")) {
    case "USD":
      nProps = {
        startAdornment: "$",
        decimalScale: 2,
        textAlign: "left",
      };
      break;
    default:
      nProps = {
        endAdornment: "€",
        decimalScale: 2,
        textAlign: "right",
      };
      break;
  }

  return (
    <NumberInput
      onChange={onChange}
      label={label}
      className={className}
      value={value}
      disabled={disabled}
      required={required}
      {...nProps}
    />
  );
}

export default withStyles(CurrencyInput, styles)

import React from "react";
import { withStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { styles } from "./HistoryList.styles";
import OrdersMovementDataGrid from "./OrdersMovementDataGrid/OrdersMovementDataGrid";

import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import Page from "../../../common/displays/Page/Page";
import { selectLoader } from "../../../app/coreSlice";
import {
  businessReportOrdersMovements,
  selectHistoryOrderMovementsFilter,
  selectHistoryOrderMovementsList,
  selectHistoryOrderMovementsListTotalRows
} from "../businessSlice";

function HistoryList({ classes }) {
  const { t } = useTranslation();
  const loading = useSelector(selectLoader);
  const filter = useSelector(selectHistoryOrderMovementsFilter);
  const dispatch = useDispatch();

  const totalRowsOrdersMovement = useSelector(selectHistoryOrderMovementsListTotalRows);
  const dataOrdersMovement = useSelector(selectHistoryOrderMovementsList);

  const onFilterChangeHandler = ({ filter, reset }) => {
    dispatch(businessReportOrdersMovements(filter, reset));
  };

  const onLoadMoreOrdersMovementHandler = () => {
    if (dataOrdersMovement.length < totalRowsOrdersMovement && dataOrdersMovement.length > 0 && !loading) {
      let nFilter = {
        ...filter,
        offset: dataOrdersMovement.length,
      };

      onFilterChangeHandler({
        filter: nFilter,
        reset: false,
      });
    }
  };

  return (
    <Page
      permission="business.actions.history.list"
      header={<PageTitle
        title={t("business.history.header.title")}
        subtitle={`${t("business.history.filter.year")}: ${filter?.year} | ${t("business.history.filter.month")}: ${filter?.month}`}
      />}>
      <OrdersMovementDataGrid
        onLoadMore={onLoadMoreOrdersMovementHandler}
        data={dataOrdersMovement}
        totalRows={totalRowsOrdersMovement}
        filter={filter}
        onFilterChange={onFilterChangeHandler}
      />
    </Page>
  );
}

export default withStyles(HistoryList, styles);

import { dateTimeFormatWithMilliSeconds } from "../../../../common/utils/formats";

export function listColumns(t, classes) {
  return [
    {
      field: "action",
      headerName: t("business.audit.data.columns_action"),
      align: "left",
      disableSort: true,
      headerAlign: "left",
      customValue: (v) => {
        switch (v.action) {
          case "I": return t("business.audit.data.columns_action_insert");
          case "U": return t("business.audit.data.columns_action_update");
          case "D": return t("business.audit.data.columns_action_delete");
          case "T": return t("business.audit.data.columns_action_truncate");
          default: return v.action;
        }
      },
    },
    {
      field: "schema",
      headerName: t("business.audit.data.columns_schema"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "table",
      headerName: t("business.audit.data.columns_table"),
      align: "left",
      headerAlign: "left",
    },
    /*
    {
        field: "rowData",
        headerName: t("business.audit.data.columns_rawData"),
        align: "left",
        headerAlign: "left",
        disableSort: true,
    },
    {
        field: "changedFields",
        headerName: t("business.audit.data.columns_changedFields"),
        align: "left",
        headerAlign: "left",
        disableSort: true,
    }, 
    */
    {
      field: "user",
      headerName: t("business.audit.data.columns_user"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "changeDate",
      headerName: t("business.audit.data.columns_changeDate"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.changeDate));
      },
    }
  ];
}

/* 
action: "U"
changeDate: "2022-04-29T15:20:57.414759"
changedFields: "\"updated_by\"=>\"CATALOG_fb20014a-5c00-46e1-9447-5bceece12922\", \"updated_time\"=>\"2022-04-29 15:20:57.414759\""
eventId: 4002
promoterCode: "LGT"
rowData: "\"id\"=>\"679bdf9c-d35c-4fa3-8261-20bd602467fa\", \"name\"=>\"Alphacash - Comércio Grossista, Lda\", \"type\"=>\"W\", \"active\"=>\"t\", \"config\"=>\"{\\\"fee\\\": {\\\"type\\\": \\\"O\\\", \\\"quantity\\\": 0, \\\"valueType\\\": \\\"P\\\", \\\"frequencyType\\\": \\\"A\\\"}}\", \"confirmed\"=>\"t\", \"legacy_id\"=>\"68\", \"created_by\"=>\"administrator_lgt@mailinator.com\", \"tax_number\"=>\"514145315\", \"updated_by\"=>\"administrator_lgt@mailinator.com\", \"created_time\"=>\"2022-04-08 13:44:33.045603\", \"updated_time\"=>\"2022-04-29 15:20:57.107886\", \"promoter_code\"=>\"LGT\", \"business_unit_id\"=>\"0\""
schema: "entity"
table: "entity"
user: "CATALOG_fb20014a-5c00-46e1-9447-5bceece12922"


::::::::::::

action: "I"
changeDate: "2022-04-27T14:56:43.200963"
eventId: 3909
rowData: "\"id\"=>\"192333a0-889f-49c8-b8c9-a1b3b1eb730e\", \"type\"=>\"O\", \"state\"=>\"Lisboa\", \"active\"=>\"t\", \"county\"=>\"Lisboa\", \"locale\"=>\"Lisboa\", \"address\"=>\"Rua de teste de Changes\", \"country\"=>\"PT\", \"latitude\"=>\"38.7305964\", \"confirmed\"=>\"f\", \"entity_id\"=>\"7ae0635f-20dd-421b-908a-b9f7d439e772\", \"legacy_id\"=>\"120\", \"longitude\"=>\"-9.1406538\", \"contact_id\"=>\"73a55355-51f3-4199-a250-c6ec2cfb0568\", \"created_by\"=>\"marabuto.teste@mailinator.com\", \"updated_by\"=>\"marabuto.teste@mailinator.com\", \"description\"=>\"Criada pelo user Marabuto\", \"postal_code\"=>\"1000-010\", \"created_time\"=>\"2022-04-27 14:56:43.088582\", \"updated_time\"=>\"2022-04-27 14:56:43.088582\", \"operator_address\"=>\"06459dec-2976-41f8-aaff-b18f3623fd9c\""
schema: "entity"
table: "address"
user: "marabuto.teste@mailinator.com"

* */
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./ProductsDataGrid.styles";
import { listColumns } from "./ProductsDataGrid.columns";

function ProductsDataGrid({
  classes,
  data,
  totalRows,
  onFilter,
  onLoadMore,
  onRowSelected,
  currentOrder,
  offers,
  onPriceChanged,
  onQuantityChanged,
}) {
  const { t } = useTranslation();

  const columns = listColumns(t, classes, currentOrder, onPriceChanged, onQuantityChanged);

  return (
    <div className={classes.root}>
      {offers?.length > 0 && (
        <CustomDataGrid columns={columns} rows={offers} localFilters total={offers.length} rowKeyField="key" />
      )}
      {data && (
        <CustomDataGrid
          columns={columns}
          rows={data}
          onFilter={onFilter}
          onLoadMore={onLoadMore}
          onRowSelected={onRowSelected}
          total={totalRows}
          rowKeyField="key"
        />
      )}
    </div>
  );
}

export default withStyles(ProductsDataGrid, styles)

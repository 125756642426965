import React from "react";
import {useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Grid, Stack} from "@mui/material";

import {styles} from "./TaxHistoryModal.styles";
import {usePromoter, useUser} from "../../../common/hooks";
import CustomModal from "../../../common/displays/CustomModal/CustomModal";
import ProductSnip from "../../../common/displays/ProductSnip/ProductSnip";
import TaxHistoryDataGrid from "./TaxHistoryDataGrid/TaxHistoryDataGrid";
import {
    selectProductTaxHistoryList,
    selectProductTaxHistoryListFilter,
    selectProductTaxHistoryListTotalRows
} from "../productsSlice";


function TaxHistoryModal({classes, open, setOpen, product, onFilterChange}) {
    const {t} = useTranslation();
    const promoter = usePromoter();
    const user = useUser();
    const data = useSelector(selectProductTaxHistoryList);
    const totalRows = useSelector(selectProductTaxHistoryListTotalRows);
    const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];
    const showPromoterDescription = Boolean(enabledProductFields.includes("promoterDescription") && user?.entity?.type === "P" && product?.promoterDescription);
    const filterTaxHistory = useSelector(selectProductTaxHistoryListFilter);

    return (
        <CustomModal open={open} setOpen={setOpen} size="extralarge" height={700}
                     title={t("products.TaxHistoryModal.modal.title")}>
            <Stack spacing={1}>
                <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <ProductSnip
                            image={product?.thumbnail}
                            description={showPromoterDescription ? product?.promoterDescription : product?.description}
                            gtin={product?.gtin}
                            promoterInternalCode={product?.promoterInternalCode}
                            ownerName={product?.ownerName}
                        />
                    </Grid>
                </Grid>
                <Stack>
                    <TaxHistoryDataGrid data={data} totalRows={totalRows} filter={filterTaxHistory} onFilterChange={onFilterChange}/>
                </Stack>
            </Stack>
        </CustomModal>
    );
}

export default withStyles(TaxHistoryModal, styles);

export const styles = (theme) => ({
  root: {
    padding: theme.spacing(0.5) + " " + theme.spacing(1),
  },
  select: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.back,
    }
  },
  resourceTitle: {
    margin: 0,
    color: theme.palette.text.secondary,
  },
  resource: {
    borderTop: "1px solid " + theme.palette.text.secondary,
    borderLeft: "1px solid " + theme.palette.text.secondary,
    padding: theme.spacing(0.5) + " " + theme.spacing(1),
  },
  circleBtn: {
    backgroundColor: theme.palette.primary.back,
    color: theme.palette.text.secondary,
    height: 24,
    width: 24,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.paper,
    }
  }
});

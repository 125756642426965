import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, IconButton } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

import { styles } from "./AttachItem.styles";
import { dateFormat } from "../../../../../common/utils";

function AttachItem({ classes, attach }) {
  const { t } = useTranslation();
  const { type, url, metadata, signedUrl } = attach;

  return (
    <Grid container direction="column" className={classes.root} spacing={0}>
      <Grid item>
        <Grid container direction="row" alignItems="center" justifyContent="space-between" wrap="nowrap">
          <Grid item>
            <Typography className={classes.type}>{t(`common.Enums.documentType.${type}`)}</Typography>
          </Grid>
          <Grid item>
            <IconButton className={classes.button} aria-label="download" size="small" onClick={() => window.open(signedUrl || url, "blank")} >
              <DownloadIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {Object.keys(metadata).map((meta) => (
        <Grid item key={`documents_meta_${meta}`}>
          <Grid container direction="row" alignItems="center" justifyContent="space-between" wrap="nowrap" spacing={1}>
            <Grid item>
              <Typography className={classes.label}>{t(`common.Enums.documentMetadata.${meta}`)}</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.value}>{(meta === "EXD" || meta === "RCD") ? dateFormat(metadata?.[meta]) : metadata?.[meta]}</Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default withStyles(AttachItem, styles);

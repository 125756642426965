import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid } from "@mui/material";

import { styles } from "./LabelValue.styles";
import { classJoin } from "../../utils";

function LabelValue({ classes, label, value, align = "left", valueClassName }) {
  if (value && label) {
    return (
      <Grid container direction="column" className={classes.root}>
        <Typography variant="h6" align="left" component="label" color="textSecondary">
          {label}
        </Typography>
        <div className={classJoin([classes.value, valueClassName])} style={{ textAlign: align }}>{value}</div>
      </Grid>
    );
  }
  return null;
}

export default withStyles(LabelValue, styles)

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { asyncForEach } from "../../../common/utils";
import { processImageUrl } from "../../../api/s3";

const batchListCall = async (
  { clearBatchList, setBatchListFilter, appendToBatchList, setBatchListTotalRows },
  dispatch,
  state,
  { businessUnitId, filter, clear }
) => {
  let rh = requestHelper(dispatch, "BATCH_LIST");
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  if (clear) {
    dispatch(clearBatchList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setBatchListFilter(filter));
  try {
    let result = await call({
      type: "BATCH",
      subType: "LIST",
      request: {
        businessUnitId,
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    let batches = [];
    await asyncForEach(result.items, async (batch) => {
      batches.push({
        ...batch,
        product: {
          ...batch.product,
          thumbnail: await processImageUrl(fileStorageConfigs, batch.product?.thumbnail),
        }
      });
    });
    dispatch(appendToBatchList(batches));
    dispatch(setBatchListTotalRows(result.rows));
    
  } catch (ex) {
    rh.error("batches", ex);
  }
  rh.close();
};

export default batchListCall;
import { getImageUrlBase64 } from "../../api/s3";

export const toLiteEntity = ({
  taxNumber,
  type,
  cep,
  gln,
  trafficType,
  operationType,
  priceDiscount,
  fee,
  cmpAccumulation,
  cpAccumulation,
  businessUnits,
  users,
  promoterInternalCode,
}) => {
  return {
    entity: {
      taxNumber,
      type,
      config: [
        {
          cep,
          gln,
          trafficType,
          operationType,
          priceDiscount,
          fee,
          cmpAccumulation,
          cpAccumulation,
          promoterInternalCode,
        },
        ...(businessUnits || []),
      ],
      entityContacts: users.filter(item => item.roleType === "F").map(item => (item.email || "").toLowerCase())
    }
  };
};

export const toEntity = ({
  taxNumber,
  name,
  taxAddress: { address, county, locale, postalCode, country, state },
  website,
  fax,
  phone,
  email,
  type,
  cep,
  gln,
  trafficType,
  operationType,
  priceDiscount,
  fee,
  businessUnits,
  users,
  promoterInternalCode,
}) => {
  return {
    entity: {
      taxNumber,
      name,
      taxAddress: {
        address,
        county,
        locale,
        postalCode,
        country,
        type: "T",
        state,
      },
      website,
      fax,
      phone,
      email: (email || "").toLowerCase(),
      type,
      config: [
        {
          cep,
          gln,
          trafficType,
          operationType,
          priceDiscount,
          fee,
          promoterInternalCode,
        },
        ...(businessUnits || []),
      ],
      entityContacts: users.filter(item => item.roleType === "F").map(item => (item.email || "").toLowerCase())
    }
  };
};

export const toAddress = (
  ids,
  contactIds,
  {
    description,
    address,
    postalCode,
    locale,
    county,
    state,
    country,
    operatorAddress,
    businessUnitIndex,
    contactIdIndex,
    contactId,
  }
) => {
  return {
    id: [ids[businessUnitIndex || 0]],
    address: {
      type: "O",
      description,
      address,
      postalCode,
      locale,
      county,
      state,
      country,
      operatorAddress,
      contactId: contactId || contactIds[contactIdIndex],
    },
  };
};

export const toAddressList = (ids, contactIds, list) => {
  return list && list.map((address) => toAddress(ids, contactIds, address));
};

export const fromUser = async (filestorageConfigs, promoterCode, contact) => {
  const filePath = "common/" + process.env.REACT_APP_ENV + "/user/avatar/" + contact?.userId;
  let logo = "";
  try {
    logo = await getImageUrlBase64(filestorageConfigs?.provider, filestorageConfigs?.privateBucket, filePath);
  } catch (ex) {
    logo = "";
  }
  return {
    logo,
    ...contact,
  };
};

// "entityId":"a6e1a698-f3b3-409b-84c1-069a737d6868",
// "groupEntities":[
//   "6f4f23f9-7cc7-42f8-97e5-c3c50b37d84b"
// ],
// "name":"teste criação group no portal"

export const toGroupCreate = ({ entityId, groupEntities, name, code }) => {
  return {
    entityId,
    groupEntities: (groupEntities || []).map((item) => item.entityId),
    name,
    code,
  };
};

export const csvFields = (t) => {
  return [
    {
      field: "action",
      header: t("business.audit.data.columns_action"),
      customValue: (v) => {
        switch (v.action) {
          case "I":
            return t("business.audit.data.columns_action_insert");
          case "U":
            return t("business.audit.data.columns_action_update");
          case "D":
            return t("business.audit.data.columns_action_delete");
          case "T":
            return t("business.audit.data.columns_action_truncate");
          default:
            return v.action;
        }
      },
    },
    {
      field: "schema",
      header: t("business.audit.data.columns_schema"),
    },
    {
      field: "table",
      header: t("business.audit.data.columns_table"),
    },
    {
      field: "user",
      header: t("business.audit.data.columns_user"),
    },
    {
      field: "changeDate",
      header: t("business.audit.data.columns_changeDate"),
    },
  ];
};

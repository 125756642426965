import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Stack, Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { styles } from "./PriceOscilation.styles";
import PriceDiff from "./PriceDiff/PriceDiff";

import { currencyFormat, dateFormat } from "../../../../common/utils";
import CustomTooltip from "../../../../common/displays/CustomTooltip/CustomTooltip";

function PriceOscilation({ classes, oldPrice, newPrice, rejected, isTooltip, showComparer, onPriceComparerOpen, removePending, removeDate }) {
  const { t } = useTranslation();
  let oldPriceFloat = parseFloat(oldPrice) || 0;
  let newPriceFloat = parseFloat(newPrice) || 0;

  const onPriceComparerHandler = () => {
    onPriceComparerOpen && onPriceComparerOpen();
  }

  return (
    <>
      <CustomTooltip
        placement="right"
        title={newPrice && oldPriceFloat > 0 && newPriceFloat !== oldPriceFloat && !isTooltip ?
          <PriceDiff oldPrice={oldPriceFloat} newPrice={newPriceFloat} />
          : null
        }
      >
        <Stack className={classes.root} direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
          <Typography style={rejected && !newPrice ? { textDecoration: "line-through" } : {}}>{currencyFormat(oldPriceFloat)}</Typography>
          {newPrice && oldPriceFloat !== newPriceFloat ?
            <>
              <ArrowRightIcon />
              <Typography style={rejected ? { textDecoration: "line-through" } : {}}>{currencyFormat(newPriceFloat)}</Typography>
              {isTooltip && <div style={{ marginLeft: 5 }}><PriceDiff oldPrice={oldPriceFloat} newPrice={newPriceFloat} /></div>}
            </>
            : null
          }
          {showComparer && <Button variant="outlined" size="small" onClick={onPriceComparerHandler}>{t("productsPrices.PriceOscilation.base.compareBtn")}</Button>}
          {removePending && <Tooltip title={t("productsPrices.PriceOscilation.base.removePending", { date: dateFormat(removeDate) })}><InfoOutlinedIcon color="warning" /></Tooltip>}
        </Stack>
      </CustomTooltip>
    </>
  );
}

export default withStyles(PriceOscilation, styles);
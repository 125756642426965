import {requestHelper, setSuccess} from "../../../app/coreSlice";
import {call} from "../../../app/websocket/_sliceCalls/websocket";

const promoterChangeStatusTimerCall = async ({ promoterGetTimer }, dispatch, state, { id, active }) => {
  let rh = requestHelper(dispatch, "PROMOTER_CHANGE_STATUS_TIMER");
  try {
    let result = await call({
      type: "PROMOTER",
      subType: "CHANGE_STATUS_TIMER",
      request: { id, active },
    });

    if(result && result.changed){
      dispatch(setSuccess("promoter", "PROMOTER_CHANGE_STATUS_TIMER"));
      dispatch(promoterGetTimer(id));
    }
  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default promoterChangeStatusTimerCall;


export const entitiesDataGridDefaultFilter = {
  limit: 30,
  offset: 0,
  sortField: "NAME",
  sortType: "ASC",
  search: null,
  types: [],
}

export const groupDataGridDefaultFilter = {
  limit: 30,
  offset: 0,
  sortField: "GROUPNAME",
  sortType: "ASC",
  status: true,
  entityId: undefined
}

export const cutOffsDataGridDefaultFilter = {
  limit: 30,
  offset: 0,
  sortField: "ENTITYNAME",
  sortType: "ASC",
  status: null,
  entityId: null
}

export const pendingEntityListDefaultFilter = {
  limit: 30,
  offset: 0,
  sortField: "ENTITYACTIONDATE",
  sortType: "DESC",
  status: ["PENDING", "VALIDATING", "REFUSED", "ACCEPTED", "NOT_OK", "NOT_VALIDATED", "RECOVERED"],
  processTypes: ["FlowCreateEntity", "FlowUpdateEntity"],
}
export const entityDetailCommentsDefaultFilter = {
  limit: 10000,
  offset: 0,
  sortType: 'DESC',
  entityId: null
}
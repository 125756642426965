import React from "react";
import { withStyles } from "tss-react/mui";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { styles } from "./PriceVariantForm.styles";
import { formFields, formSchema } from "./PriceVariantForm.schema";
import { Form, FormFields } from "../../../../common/forms";
import { useDispatch, useSelector } from "react-redux";
import { usePromoter, useUser } from "../../../../common/hooks";
import { addTempPrice, selectEntityGroups, selectLocations } from "../../productsPricesSlice";
import { guid } from "../../../../common/utils";

function PriceVariantForm({ classes, price, onSubmit }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const promoter = usePromoter();
  const user = useUser();
  const entityGroups = useSelector(selectEntityGroups);
  const locations = useSelector(selectLocations);

  const onSubmitHandler = async (values) => {
    dispatch(addTempPrice({ ...values, isNew: true, priceId: guid() }));
    onSubmit && onSubmit();
  };

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmitHandler}
        schema={formSchema({ t })}
        keepDirtyOnReinitialize
        initialValues={{ effectiveDate: moment().toISOString(), newSalePrice: 0, ...price }}
        render={({ values }) => {
          return (
            <div>
              <FormFields fields={formFields({ t, values, promoter, user, entityGroups, locations })} />
              <br />
              <Button variant="contained" type="submit" style={{ float: "right" }}>{price?.tempId ? t("productsPrices.PriceVariantForm.form.updateBtn") : t("productsPrices.PriceVariantForm.form.addBtn")}</Button>
            </div>
          );
        }}
      />

    </div>
  );
}

export default withStyles(PriceVariantForm, styles);
export const styles = (theme) => ({
  root: {},
  grid: {
    padding: 2,
    height: "calc( 80vh - 90px )",
    overflow: "auto",
  },
  emptytable: {
    textAlign: "center",
    border: "1px solid " + theme.palette.text.secondary,
    padding: 10,
    borderRadius: 4,
    cursor: "pointer",
  },
  disabledLabel: {
    color: "#97A1AF",
  },
});

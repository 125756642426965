export const collapseI18nKeys = (defaultI18n, languageI18n) => {
  let collapseI18nKeys = [];
  Object.keys(defaultI18n).forEach((feature) => {
    Object.keys(defaultI18n[feature]).forEach((component) => {
      Object.keys(defaultI18n[feature][component]).forEach((area) => {
        Object.keys(defaultI18n[feature][component][area]).forEach((key) => {
          let fullKey = [feature, component, area, key].join(".");
          collapseI18nKeys.push({
            key: fullKey,
            default: defaultI18n[feature][component][area][key],
            current: languageI18n?.[feature]?.[component]?.[area]?.[key],
          });
        });
      });
    });
  });
  return collapseI18nKeys;
};

export const hasEmptyKeys = (collapsedEmptykeys, partialKey) => {
  let count = collapsedEmptykeys.filter((item) => item.key.indexOf(partialKey) === 0)?.length;
  return count > 0;
};

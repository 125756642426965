export const styles = (theme) => ({
  root: {
    maxHeight: "calc(100vh - 220px)",
    overflowY: "auto",
    "& .MuiTable-root": {
      minWidth: 400,
    },
  },
  orderBy: {
    margin: 0,
    color: "#aaa",
  },
  nomargin: {
    margin: 0,
  },
});

import * as Yup from "yup";
import {EntityAutoCompleteField, MultiSelectField} from "../../../../common/forms";

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    status: Yup.array(),
  });
};

export const filterFields = (user,promoter) => (t) => {
  return [
    {
      size: 12,
      field: (
        <MultiSelectField id="status"
                          label={t("productsPurchases.PendingPurchasesList.filter.status")} options={getStatusOptions(t)} />
      ),
    },{
      size: 12,
      field: (
          <EntityAutoCompleteField
              typeFilter={promoter?.getConfig("product", "allowedEntityTypes")}
              id="owner"
              label={t("productsPurchases.PendingPurchasesList.filter.owner")}
          />
      ),
      hidden: user?.entity?.type !== "P",
    },
  ];
};


const getStatusOptions = (t) => {
  return ["PND", "ACC", "REF", "CAN", "REM", "END"].map((value) => {
    return {
      value,
      text: t("common.Enums.dpromStatus." + value),
    };
  });
};

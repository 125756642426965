import React from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

import { styles } from "./ProductThumbnail.styles";

function ProductThumbnail({ classes, product, quantity }) {
  // const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Tooltip title={product.description + ": " + quantity + " UN"}>
        <div><img src={product.thumbnail} alt={product.productId} /></div>
      </Tooltip>
    </div >
  );
}

export default withStyles(ProductThumbnail, styles);
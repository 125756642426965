import { call } from "../../../app/websocket/_sliceCalls/websocket";

const productGetFormData = async (productId, formKey) => {
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "GET",
      request: {
        productId
      },
    });
    return result?.product?.forms?.[formKey];
  } catch (_ex) {
  }
};

export default productGetFormData;

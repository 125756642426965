import React from "react";
import { Route, Routes } from "react-router-dom";
import BatchesList from "./BatchesList/BatchesList";
import BatchesOrdersList from "./BatchesOrdersList/BatchesOrdersList";
import BatchForm from "./BatchForm/BatchForm";

//942fd67b-2a91-4dfc-aa02-1afce58b2da5

function BatchesRouting() {
  return (
    <Routes>
      <Route path=":businessUnitId/" element={<BatchesList />} />
      <Route path=":businessUnitId/orders" element={<BatchesOrdersList />} />
      <Route path=":businessUnitId/create" element={<BatchForm />} />
    </Routes>
  );
}

export default BatchesRouting;

import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { toAddress } from "../entitiesMapper";

const entityAddAddressCall = async ({ entityGet }, dispatch, { entityId, address }) => {
  let rh = requestHelper(dispatch, "ADD_ADDRESS");

  try {
    await call({
      type: "ENTITY",
      subType: "ADD_ADDRESS",
      request: toAddress([entityId], [address.contactId], {
        ...address,
        businessUnitIndex: [0],
        contactIdIndex: 0,
      }),
    });
    dispatch(setSuccess("entities", "ENTITY_ADD_ADDRESS"));
    setTimeout(() => {
      dispatch(setRedirect(`/entities/${entityId}/addresses`));
      dispatch(entityGet(entityId));
    }, 300);
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityAddAddressCall;

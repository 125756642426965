export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
  },
  error: {
    color: theme.palette.error.main,
  },
  product: {
    border: "1px solid #DDD",
    borderRadius: 5,
    padding: 5
  },
  nomargin: {
    margin: 0
  }
});
export const vatListDefaultFilter = {
  sortField: "CODE",
  sortType: "ASC",
  locations: null,
  active: true,
}
export const timerListDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "DESCRIPTION",
  sortType: "DESC",
  promoterCode: undefined,
  subType: undefined,
  description: undefined,
  status: true,

}
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Stack } from "@mui/material";

import { styles } from "./ProductTaxValidateCommentModal.styles";
import { formFields, formSchema } from "./ProductTaxValidateCommentModal.schema";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";

import { Form, FormFields } from "../../../../common/forms";

function ProductTaxValidateCommentModal({ classes, open, setOpen, onConfirm, taxToValidate }) {
  const { t } = useTranslation();

  const onSubmitHandler = async (values) => {
    onConfirm && onConfirm(values);
  };

  return (
    <CustomModal open={open} setOpen={setOpen} size="medium" height={265} title={t("products.ProductTaxValidateCommentModal.modal.title")}>
      <div className={classes.root}>
        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t, taxToValidate })}
          initialValues={{}}
          render={({ values }) => {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormFields fields={formFields({ t })} />
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <Button variant="outlined" color="primary" type="button" fullWidth onClick={() => setOpen(false)} >
                      {t("productsPrices.PricesList.modalComment.cancelBtn")}
                    </Button>
                    <Button variant="contained" color={taxToValidate?.valid ? "primary" : "error"} type="submit" fullWidth >
                      {taxToValidate?.valid ? t("products.ProductTaxValidateCommentModal.form.btnAccept") : t("products.ProductTaxValidateCommentModal.form.btnRefuse")}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            );
          }}
        />
      </div>
    </CustomModal>
  );
}

export default withStyles(ProductTaxValidateCommentModal, styles);

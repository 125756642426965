export const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "100%",
    "& .MuiTable-root": {
      width: "100%",
      border: "none",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiTypography-body1": {
      marginTop: "0px",
      marginBottom: "4px",
      fontSize: "12px",
    },
  },
});

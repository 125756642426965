import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";
import { Typography, Tabs, Tab, Stack, Button } from "@mui/material";
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';

import { styles } from "./AlertsList.styles";

import {
  selectIsLoading,
  selectAlertsList,
  selectAlertsResolvedList,
  selectAlertsResolvedListFilter,
  alertList,
  alertResolve,
  selectAlertsListTotalRows,
  selectAlertsResolvedListTotalRows,
  alertResolveAll,
} from "../alertsSlice";

import AlertItem from "./AlertItem/AlertItem";
import InfiniteList from "../../../common/components/displays/InfiniteList/InfiniteList";
import { useNavigate, usePromoter } from "../../../common/hooks";
import ActionsPopover from "../../../common/components/ActionsPopover/ActionsPopover";

function AlertsList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const alertsCollection = useSelector(selectAlertsList);
  const alertsResolved = useSelector(selectAlertsResolvedList);
  const [tab, setTab] = useState(0);
  // const filter = useSelector(selectAlertsListFilter);
  const filterResolved = useSelector(selectAlertsResolvedListFilter);
  const loading = useSelector(selectIsLoading);
  const navigate = useNavigate();
  const promoter = usePromoter();
  const alertsTotalRows = useSelector(selectAlertsListTotalRows);
  const alertsResolvedTotalRows = useSelector(selectAlertsResolvedListTotalRows);

  const setTabHandler = (e, i) => {
    e.preventDefault();
    e.stopPropagation();
    setTab(i);
    if (i === 1) {
      dispatch(alertList({ ...filterResolved, resolved: true }, true));
    }
  };

  const onResolveHandler = ({ alertId, alertStatusId }) => {
    dispatch(alertResolve(alertId, alertStatusId));
  };

  const onClickHandler = (alert) => {
    const { alertId, alertStatusId, payload, resolved } = alert;
    if (!resolved) {
      dispatch(alertResolve(alertId, alertStatusId));
    }
    if (payload?.url) {
      let path = payload.url.substring(
        payload.url.indexOf(promoter.details.code) + 3,
        payload.url.length
      );
      navigate(path);
    }
  };

  const onAlertsLoadMore = useCallback(() => {
    if (alertsTotalRows > 0 && alertsCollection.length < alertsTotalRows && !loading) {
      dispatch(alertList({ ...filterResolved, resolved: false, offset: alertsCollection.length }, false));
    }
    // eslint-disable-next-line
  }, [alertsCollection]);

  const onAlertsResolvedLoadMore = useCallback(() => {
    if (alertsResolvedTotalRows > 0 && alertsResolved.length < alertsResolvedTotalRows && !loading) {
      dispatch(alertList({ ...filterResolved, resolved: true, offset: alertsResolved.length }, false));
    }
    // eslint-disable-next-line
  }, [alertsResolved]);

  const onReadAllHandler = () => {
    dispatch(alertResolveAll());
  }

  return (
    <div className={classes.root}>
      <Stack direction="row" justifyContent="space-between">
        <Typography className={classes.title} variant="h3">
          {t("alerts.AlertsList.tabs.title")}
        </Typography>
        <ActionsPopover actions={[
          <Button onClick={onReadAllHandler} fullWidth >
            <Stack direction="row" alignItems="center" spacing={1}>
              <LibraryAddCheckOutlinedIcon />
              <Typography>{t("alerts.AlertsList.actions.markAllRead")}</Typography>
            </Stack>
          </Button>
        ]} />
      </Stack>
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={tab}
        onChange={(e, i) => setTabHandler(e, i)}
        className={classes.tabs}
      >
        <Tab label={t("alerts.AlertsList.tabs.unread")} />
        <Tab label={t("alerts.AlertsList.tabs.read")} />
      </Tabs>
      <div hidden={tab !== 0} style={{ width: "calc(100% + 10px)" }}>
        <InfiniteList
          data={alertsCollection}
          renderElement={(alert) => {
            return <AlertItem onResolve={onResolveHandler} onClick={onClickHandler} alert={alert}></AlertItem>;
          }}
          loadMore={onAlertsLoadMore}
          isLoading={loading}
        />
      </div>
      <div hidden={tab !== 1} style={{ width: "calc(100% + 10px)" }}>
        <InfiniteList
          data={alertsResolved}
          renderElement={(alert) => {
            return <AlertItem onResolve={onResolveHandler} onClick={onClickHandler} alert={alert}></AlertItem>;
          }}
          loadMore={onAlertsResolvedLoadMore}
          isLoading={loading}
        />
      </div>
    </div>
  );
}

export default withStyles(AlertsList, styles)

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

export const promoterListLocationsCall = async ({ setLocationsList }, dispatch) => {
  let rh = requestHelper(dispatch, "PROMOTER_LIST_LOCATIONS");

  try {
    let result = await call({
      type: "PROMOTER",
      subType: "LIST_LOCATIONS",
      request: {},
    });

    dispatch(setLocationsList(result.locations));
  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default promoterListLocationsCall;
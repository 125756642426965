import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { usePromoter, useNavigate, useFirstLoad } from "../../../common/hooks";
import { useParams } from "react-router-dom";
import { Paper, Button } from "@mui/material";

import { styles } from "./BusinessUnitForm.styles";
import { formSchema, formFields } from "./BusinessUnitForm.schema";
import { Form, FormFields } from "../../../common/forms";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";

import { addBusinessUnit, entityUpdateConfig } from "../entitiesSlice";
import { entityGet, selectEntityDetails } from "../entitiesSlice";
import Permission from "../../../app/components/Permission";
import Page from "../../../common/displays/Page/Page";

function BusinessUnitForm({ classes, isEdit }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const details = useSelector(selectEntityDetails);
  const promoter = usePromoter();

  useFirstLoad(() => {
    if (details?.entityId !== id) {
      dispatch(entityGet(id));
    }
  });

  const onSubmit = (values) => {
    if (isEdit) {
      dispatch(entityUpdateConfig(values));
    } else {
      dispatch(addBusinessUnit(details?.entityId, values));
    }
  };

  const initialValues = isEdit ? details : { taxNumber: details?.taxNumber, type: details?.type };

  return (
    <div>
      <Form
        schema={formSchema(t, promoter, details?.type)}
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ submitting, values, errors }) => {
          return (
            <Page
              permission="entities.actions.businessUnit.create"
              header={
                <PageTitle
                  onBack={() => navigate("/entities/" + id)}
                  title={details?.name}
                  subtitle={isEdit ? details?.businessUnitDesc : t("entities.BusinessUnitForm.form.title")}
                  actions={
                    <Permission code="entities.actions.businessUnit.create">
                      <Button variant="contained" color="primary" type="submit" size="large" disabled={submitting}>
                        {isEdit
                          ? t("entities.BusinessUnitForm.form.updateBtn")
                          : t("entities.BusinessUnitForm.form.submitBtn")}
                      </Button>
                    </Permission>
                  }
                />
              }>

              <div className={classes.root}>
                <Paper className={classes.paper} elevation={0}>
                  <FormFields fields={formFields(t, promoter, details?.type)} />
                </Paper>
              </div>
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(BusinessUnitForm, styles);

import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ToggleOffIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnIcon from '@mui/icons-material/ToggleOnOutlined';

import ActionsPopover from "../../../../common/components/ActionsPopover/ActionsPopover";
import Permission from "../../../../app/components/Permission";

export default function ResourceDataGridActions({ row, onEdit, onCopy, onDisable, onEnable, onRemove }) {
  const { t } = useTranslation();

  const onEditHandler = (e) => {
    e.preventDefault();
    onEdit && onEdit(row);
  };

  const onCopyHandler = (e) => {
    e.preventDefault();
    onCopy && onCopy(row);
  };

  const onRemoveHandler = (e) => {
    e.preventDefault();
    onRemove && onRemove(row);
  };

  const onDisableHandler = (e) => {
    e.preventDefault();
    onDisable && onDisable(row);
  };

  const onEnableHandler = (e) => {
    e.preventDefault();
    onEnable && onEnable(row);
  };

  let actionsButtons = [];

  // EDIT
  actionsButtons.push(<Button onClick={onEditHandler} fullWidth>
    <Grid container spacing={1} alignItems="center">
      <Grid item style={{ display: "flex" }}>
        <EditIcon />
      </Grid>
      <Grid item>{t("resources.ResourceList.actions.edit")}</Grid>
    </Grid>
  </Button>);

  // COPY
  actionsButtons.push(<Button onClick={onCopyHandler} fullWidth>
    <Grid container spacing={1} alignItems="center">
      <Grid item style={{ display: "flex" }}>
        <ContentCopyIcon />
      </Grid>
      <Grid item>{t("resources.ResourceList.actions.copy")}</Grid>
    </Grid>
  </Button>);

  // ENABLE
  if (!row.active) {
    actionsButtons.push(<Permission code="promoter.actions.places.enable">
      <Button onClick={onEnableHandler} fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <ToggleOnIcon />
          </Grid>
          <Grid item>{t("resources.ResourceList.actions.enable")}</Grid>
        </Grid>
      </Button>
    </Permission>);
  }

  // DISABLE
  if (row.active) {
    actionsButtons.push(<Permission code="promoter.actions.resources.disable">
      <Button onClick={onDisableHandler} fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <ToggleOffIcon />
          </Grid>
          <Grid item>{t("resources.ResourceList.actions.disable")}</Grid>
        </Grid>
      </Button>
    </Permission>);
  }

  // DELETE
  if (!row.active) {
    actionsButtons.push(<Button onClick={onRemoveHandler} color="error" fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item style={{ display: "flex" }}>
          <DeleteIcon />
        </Grid>
        <Grid item>{t("resources.ResourceList.actions.remove")}</Grid>
      </Grid>
    </Button>);
  }

  return (
    <Grid container spacing={1} justifyContent="flex-end" alignItems="center" wrap="nowrap">
      {actionsButtons.length > 0 &&
        <Grid item>
          <ActionsPopover
            actions={actionsButtons}
          />
        </Grid>
      }
    </Grid>
  );
}

import * as Yup from "yup";
import { SelectField, TextField, CountryAutoCompleteField } from "../../../common/forms";
import { getStateOptions } from "../../../common/forms/options";

export function formSchema({ t, entityType, promoter, edit }) {
  return Yup.object().shape(edit ? {
    description: Yup.string().required(t("entities.CreateEntity.form.requiredAddressDescription")),
    contactId: Yup.string().required(t("entities.CreateEntity.form.requiredAddressContactId")),
  } : {
    description: Yup.string().required(t("entities.CreateEntity.form.requiredAddressDescription")),
    address: Yup.string().required(t("entities.CreateEntity.form.requiredAddressAddress")),
    county: Yup.string().required(t("entities.CreateEntity.form.requiredAddressCounty")),
    locale: Yup.string().required(t("entities.CreateEntity.form.requiredAddressLocale")),
    postalCode: Yup.string().required(t("entities.CreateEntity.form.requiredAddressPostalCode")),
    country: Yup.string().required(t("entities.CreateEntity.form.requiredAddressCountry")),
    contactId: Yup.string().required(t("entities.CreateEntity.form.requiredAddressContactId")),
    operatorAddress:
      entityType !== "LO" && entityType !== "P" && promoter?.getConfig("order", "hasLogisticOperator")
        ? Yup.string().required(t("entities.CreateEntity.form.requiredOperatorAddress"))
        : Yup.string(),
  });
}

export function formFields({ t, options, entityType, promoter, values, edit }) {
  const addressStates = getStateOptions(promoter, values?.country);
  return [
    {
      size: 4,
      field: <TextField id={`description`} type="text" label={t("entities.CreateEntity.form.addressName")} required />,
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`contactId`}
          label={t("entities.CreateEntity.form.addressContact")}
          options={options.contactOptions}
          required
        />
      ),
    },
    {
      size: 4,
      field: <div></div>,
    },
    {
      size: 8,
      field: <TextField id={`address`} type="text" label={t("entities.CreateEntity.form.addressAddress")} required={!edit} disabled={edit} />,
    },
    {
      size: 4,
      field: <TextField id={`county`} type="text" label={t("entities.CreateEntity.form.addressCounty")} required={!edit} disabled={edit} />,
    },
    {
      size: 4,
      field: <TextField id={`locale`} type="text" label={t("entities.CreateEntity.form.addressLocale")} required={!edit} disabled={edit} />,
    },
    {
      size: 4,
      field: (
        <TextField id={`postalCode`} type="text" label={t("entities.CreateEntity.form.addressPostalCode")} required={!edit} disabled={edit} />
      ),
    },
    {
      size: 4,
      field: <CountryAutoCompleteField id={`country`} label={t("entities.CreateEntity.form.country")} required={!edit} disabled={edit} />,
    },
    {
      size: 4,
      field: (
        addressStates && addressStates.length > 0
          ? <SelectField
            id="state"
            label={t("entities.CreateEntity.form.addressState")}
            options={addressStates}
            disabled={edit}
          />
          : <TextField id="state" type="text" label={t("entities.CreateEntity.form.addressState")} disabled={edit} />
      ),
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`operatorAddress`}
          label={t("entities.CreateEntity.form.addressOperator")}
          options={options.operatorOptions}
          required={promoter?.getConfig("order", "hasLogisticOperator") && !edit}
          disabled={edit}
        />
      ),
      hidden: entityType === "LO" || !promoter?.getConfig("order", "hasLogisticOperator"),
    },
  ];
}

export const styles = (theme) => ({
  root: {
    position: "relative",
  },
  label: {
    position: "absolute",
    margin: 0,
    top: 12,
    left: 14,
    fontSize: 6,
    zIndex: -1,
    letterSpacing: "-0.8px",
  },
});

import React from "react";
import { withStyles } from "tss-react/mui";
import { Field } from "react-final-form";
import MetaError from "../MetaError";
import { styles } from "./_base.styles";
import { CardSelectRawInput } from "../../inputs";

function CardSelectRawField({ classes, id, label, disabled, required, options, xs, onChange, cardContent, multi }) {
  const onChangeHandler = (v, input) => {
    input.onChange && input.onChange(v);
    onChange && onChange(v);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <>
            <CardSelectRawInput
              multi={multi}
              label={label}
              disabled={disabled}
              required={required}
              options={options}
              xs={xs}
              selectedValue={input.value}
              onChange={(v) => onChangeHandler(v, input)}
              cardContent={cardContent}
            />
            <div className={classes.error}>
              <MetaError meta={meta} />
            </div>
          </>
        )}
      </Field>
    </div>
  );
}

export default withStyles(CardSelectRawField, styles);

import React from "react";
import { withStyles } from "tss-react/mui";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import RefreshIcon from "@mui/icons-material/Refresh";

import { styles } from "./OperatorOrdersList.styles";

import OperatorOrdersDataGrid from "./OperatorOrdersDataGrid/OperatorOrdersDataGrid";
import { Button, Grid } from "@mui/material";
import Page from "../../../common/displays/Page/Page";
import { orderListLo, selectOperatorOrders, selectOperatorOrdersFilter, selectOperatorOrdersTotalRows } from "../ordersSlice";
import { selectLoader } from "../../../app/coreSlice";

function OperatorOrdersList({ classes }) {
  // eslint-disable-next-line
  const { t } = useTranslation();
  const loading = useSelector(selectLoader);
  const data = useSelector(selectOperatorOrders);
  const filter = useSelector(selectOperatorOrdersFilter);
  const totalRows = useSelector(selectOperatorOrdersTotalRows);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useFirstLoad(() => {
    dispatch(orderListLo(filter, true));
  });

  const onRowSelectedHandler = ({ id, dispatchId }) => {
    navigate(`/orders/operator/${id}/${dispatchId}`);
  };

  const onFilterChangeHandler = (v) => {
    dispatch(orderListLo(v.filter, v.reset));
  };

  const refreshHandler = () => {
    dispatch(orderListLo(filter, true));
  };

  return (
    <Page
      permission="orders.actions.operator.list"
      header={<PageTitle
        title={t("orders.OperatorOrdersList.header.title")}
        info={`${totalRows} ${t("orders.OperatorOrdersList.header.rowsInfo")}`}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={refreshHandler}
                disabled={Boolean(loading)}
              >
                <RefreshIcon />
              </Button>
            </Grid>
          </Grid>
        }
      />}>
      <OperatorOrdersDataGrid
        data={data}
        totalRows={totalRows}
        filter={filter}
        onRowSelected={onRowSelectedHandler}
        onFilterChange={onFilterChangeHandler}
        loading={loading}
      />
    </Page>
  );
}

export default withStyles(OperatorOrdersList, styles);

export const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    margin: 0,
  },
  type: {
    margin: 0,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: "1px solid #D2D9E4",
    width: "100%"
  },
  noMargin: {
    margin: 0,
  },
  alert: {
    backgroundColor: "#FFF",
    padding: "2px 12px"
  }
});
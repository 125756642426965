
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { styles } from "./TasksForm.styles";
import { formFields, formSchema } from "./TasksForm.schema";

import { Form, FormFields } from "../../../common/forms";
import Permission from "../../../app/components/Permission";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {
  // taskUpdate,
  taskCreate,
} from "../tasksSlice";
import { entityGet, selectEntityDetails } from "../../entities/entitiesSlice";
import { useFirstLoad, useNavigate, usePromoter, useUser } from "../../../common/hooks";

function TasksForm({ classes }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const promoter = usePromoter();
  const entity = useSelector(selectEntityDetails);
  const user = useUser();

  useFirstLoad(() => {
    if (!entity) {
      dispatch(entityGet(user?.entity?.id));
    }
  });

  const onSubmitHandler = async (values) => {
    // console.log(values);
    const groupId = values.associationType === 'GROUP' ? values.group : undefined;
    const userId = values.associationType === 'USER' ? values.user : undefined;
    const sectorId = values.associationType === 'SECTOR' ? values.sector : undefined;
    const dLang = promoter?.getConfig("general", "defaultLanguage");
    dispatch(taskCreate({ ...values, entityId: user?.entity?.id, userId: userId, groupId: groupId, sectorCode: sectorId, info: [{ type: "text", content: { [dLang]: values?.message } }] }));
  };

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmitHandler}
        schema={formSchema({ t })}
        initialValues={{ associationType: "USER" }}
        render={({ values }) => {
          return (
            <Page
              permission={"tasks.actions.tasks.create"}
              header={<PageTitle
                onBack={() => navigate("/tasks")}
                title={id ? t("tasks.TasksForm.header.titleEdit") : t("tasks.TasksForm.header.title")}
                actions={
                  <Grid container direction="row" spacing={2}>
                    <Permission code={"tasks.actions.tasks.create"}>
                      <Grid item>
                        <Button variant="contained" color="primary" type="submit">
                          {t("tasks.TasksForm.form.btnSubmit")}
                        </Button>
                      </Grid>
                    </Permission>
                  </Grid>
                }
              />}>
              <Paper className={classes.paper}>
                <FormFields fields={formFields({ t, values, contacts: entity?.contacts, sectorsGroups: entity?.sectorsGroups, promoter })} />
              </Paper>
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(TasksForm, styles);

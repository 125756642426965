import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Grid, Button, Paper } from "@mui/material";

import { styles } from "./EntitySectorGroupForm.styles";
import { formFields, formSchema } from "./EntitySectorGroupForm.schema";

import { Form, FormFields } from "../../../common/forms";
import { useFirstLoad, useNavigate, usePromoter, useUser } from "../../../common/hooks";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { entityCreateSectorGroup, entityDeleteSectorGroup, entityGet, entityUpdateSectorGroup, selectEntityDetails } from "../entitiesSlice";
import { formProductListFamily, selectFormFamilyList } from "../../products/productsSlice";
import Permission from "../../../app/components/Permission";
import DeleteButton from "../../../common/components/dialogs/DeleteButton";
import Page from "../../../common/displays/Page/Page";

function EntitySectorGroupForm({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const details = useSelector(selectEntityDetails);
  const { id, groupId } = useParams();
  const promoter = usePromoter();
  const families = useSelector(selectFormFamilyList);
  const user = useUser();

  useFirstLoad(() => {
    dispatch(formProductListFamily());
    if (details?.entityId?.toString() !== id) {
      dispatch(entityGet(id));
    }
    if (user?.entity?.type !== "P" && user?.entity?.id !== id) {
      navigate('/not-available');
    }
  });

  const onSubmit = async (values) => {
    if (groupId) {
      dispatch(entityUpdateSectorGroup({ ...values, entityId: id, groupId }))
    }
    else {
      dispatch(entityCreateSectorGroup({ ...values, entityId: id }))
    }
  };

  const initialValues = groupId ? (details?.sectorsGroups || []).find((item) => item.id === groupId) : { users: [] };

  return families?.length > 0 ? (
    <div>
      <Form
        onSubmit={onSubmit}
        schema={formSchema(t)}
        initialValues={initialValues}
        render={({ submitting, values, form }) => {
          return (
            <Page
              permission={groupId ? "entities.actions.sectorGroup.update" : "entities.actions.sectorGroup.create"}
              header={<PageTitle
                title={
                  groupId
                    ? t("entities.EntitySectorGroupForm.header.updateTitle") + " " + initialValues?.groupName
                    : t("entities.EntitySectorGroupForm.header.createTitle")
                }
                onBack={() => navigate(`/entities/${id}/sectorgroups`)}
                actions={
                  <Grid container direction="row" spacing={1}>
                    <Permission code="entities.actions.sectorGroup.delete">
                      <Grid item>
                        {groupId && <DeleteButton
                          labels={{
                            button: t("entities.EntitySectorGroupForm.form.deleteBtn"),
                            title: t("entities.EntitySectorGroupForm.confirmDelete.title"),
                            description: t("entities.EntitySectorGroupForm.confirmDelete.description"),
                            ok: t("entities.EntitySectorGroupForm.confirmDelete.ok"),
                            cancel: t("entities.EntitySectorGroupForm.confirmDelete.cancel"),
                          }}
                          onConfirm={() => {
                            dispatch(entityDeleteSectorGroup({ groupId, entityId: id }))
                          }}
                        />}
                      </Grid>
                    </Permission>
                    <Permission code={groupId ? "entities.actions.sectorGroup.update" : "entities.actions.sectorGroup.create"}>
                      { }
                      <Grid item>
                        <Button variant="contained" color="primary" type="submit" disabled={submitting}>
                          {groupId
                            ? t("entities.EntitySectorGroupForm.header.updateBtn")
                            : t("entities.EntitySectorGroupForm.header.createBtn")}
                        </Button>
                      </Grid>
                    </Permission>

                  </Grid>
                }
              />}>

              <Paper className={classes.paper} elevation={0}>
                <FormFields fields={formFields({
                  t, promoter, contacts: details?.contacts, families, values, onHeaderChange: (v) => {
                    if ((values?.users || []).indexOf(v) > -1) {
                      form.change("users", (values?.users || []).filter(item => item !== v))
                    }
                  }
                })} />
              </Paper>
            </Page>

          );
        }}
      />
    </div>
  ) : null;
}

export default withStyles(EntitySectorGroupForm, styles);

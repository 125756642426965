import React from "react";
import { withStyles } from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {styles} from "./VersionsDataGrid.styles";
import {CustomDataGrid} from "../../../../common/displays/CustomDataGrid";
import {listColumns} from "./VersionsDataGrid.columns";
import {Typography} from "@mui/material";

function VersionsDataGrid({  classes,
                              label,
                          currentVersion,
                          data,
                          totalRows,
                          onRowSelected}) {
  const { t } = useTranslation();


  return (
      <div className={classes.root}>
        {data && (
            <div>
                <Typography component="span" variant="caption" color="textPrimary">
                    {t("documents.DocumentTemplate.versions.title")}
                </Typography>

                <CustomDataGrid
                    columns={listColumns(t, classes, currentVersion)}
                    rows={data}
                    onRowSelected={onRowSelected}
                    total={totalRows}
                />
            </div>
        )}
      </div>
  );
}

export default withStyles(VersionsDataGrid, styles)

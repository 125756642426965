import React from "react";
import { withStyles } from "tss-react/mui";
import { Field } from "react-final-form";
import MetaError from "../../MetaError";
import { FileUploadInput } from "../../../inputs";

import { styles } from "./GalleryUploadField.styles";
import { guid } from "../../../utils";
import { ImageDropContainer } from "./ImageDropContainer";

function GalleryUploadField({ classes, id, label, placeholder, required, disabled }) {
  const onChangeHandler = (v, input) => {
    let actualList = input.value || [];
    let images = [
      ...actualList,
      {
        id: guid(),
        base64: v.base64,
      },
    ];
    input.onChange && input.onChange(images);
    // onChange && onChange(v);
  };

  const onStopDraggingHandler = (images, input) => {
    input.onChange && input.onChange(images);
  };

  const onDeleteHandler = (id, input) => {
    let nValues = (input.value || []).filter((item) => item.id !== id);
    input.onChange && input.onChange(nValues);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => {
          return (
            <>
              <FileUploadInput
                label={label}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                onChange={(v) => onChangeHandler(v, input)}
                variant="outlined"
                image
                clearOnChange
              />
              <br />
              <ImageDropContainer
                images={input.value || []}
                onStopDragging={(images) => onStopDraggingHandler(images, input)}
                onDelete={(id) => onDeleteHandler(id, input)}
              />
              <div className={classes.error}>
                <MetaError meta={meta} />
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
}

export default withStyles(GalleryUploadField, styles)

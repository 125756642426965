import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const promoterScheduleCutOffCall = async ({ listCutOffsAppend }, dispatch, state, { type, description, dayOfMonth, dayOfWeek, hour, minute ,deliveryDay}) => {
  const filter = state.entities.cutOffListFilter;
  let rh = requestHelper(dispatch, "SCHEDULE_CUT_OFF");
  try {
    await call({
      type: "PROMOTER",
      subType: "SCHEDULE_CUT_OFF",
      request: {
        type,
        description,
        dayOfMonth,
        dayOfWeek,
        hour,
        minute,
        deliveryDay
      },
    });
    setTimeout(() => {
      dispatch(setSuccess("entities", "SCHEDULE_CUT_OFF"));
      dispatch(listCutOffsAppend(filter, true));
    }, 300);
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default promoterScheduleCutOffCall;

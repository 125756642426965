import { processImageUrl } from "../../../api/s3";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { asyncForEach } from "../../../common/utils";
import { getOrderDocumentsSignedUrls } from "./_auxiliars";


const orderDetailsCall = async ({ setOrderDetails }, dispatch, state, { id }) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "ORDER_DETAILS");
  try {
    let result = await call({
      type: "ORDER",
      subType: "DETAILS",
      request: {
        id,
      },
    });
    let products = [];
    await asyncForEach(result.orderDetails.products, async (product) => {
      products.push({
        ...product,
        image: await processImageUrl(fileStorageConfigs,  product.image),
        thumbnail: await processImageUrl(fileStorageConfigs,  product.thumbnail),
      });
    });
    let productsOffers = [];
    await asyncForEach(result.orderDetails.productsOffers, async (product) => {
      productsOffers.push({
        ...product,
        image: await processImageUrl(fileStorageConfigs,  product.image),
        thumbnail: await processImageUrl(fileStorageConfigs,  product.thumbnail),
      });
    });

    dispatch(setOrderDetails({
      ...result.orderDetails,
      products,
      productsOffers,
      documents: await getOrderDocumentsSignedUrls(fileStorageConfigs, result?.orderDetails?.documents)
    }));
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
};

export default orderDetailsCall;
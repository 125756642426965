import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const businessUnitUpdateStepCall = async (
  { businessUnitSteps },
  dispatch,
  { businessUnitId, lineId, internal, id, name, places, startConditions, stopConditions }
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_UPDATE_STEP");
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "UPDATE_STEP",
      request: {
        id,
        name,
        places,
        startConditions,
        stopConditions,
      },
    });

    dispatch(setSuccess("businessUnits", "BUSINESS_UNIT_UPDATE_STEP"));
    setTimeout(() => {
      if (result) {
        dispatch(businessUnitSteps({ businessUnitId, lineId, internal }));
      }
    }, 300);

  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitUpdateStepCall;


import React from "react";
import { withStyles } from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {CustomDataGrid} from "../../../../common/displays/CustomDataGrid";

import {styles} from "./RetentionDataGrid.styles";
import {listColumns} from "./RetentionDataGrid.columns";
import RetentionDataGridActions from "./RetentionDataGrid.actions";

function RetentionDataGrid({
      classes,
      data,
      filter,
      totalRows,
      onRowSelected,
      onFilterChange,
      onLoadMore,
      onEdit
}) {
    const { t } = useTranslation();

    const onFilterHandler = (nFilter) => {
        nFilter = {
            ...filter,
            ...nFilter,
            offset: 0,
        };
        onFilterChange &&
        onFilterChange({
            filter: nFilter,
            reset: true,
        });
    };

    return (
        <div className={classes.root}>
            {data && (
                <CustomDataGrid
                    columns={listColumns(t, classes)}
                    rows={data}
                    onFilter={onFilterHandler}
                    onLoadMore={onLoadMore}
                    onRowSelected={onRowSelected}
                    total={totalRows}
                    actionsComponent={onEdit ? <RetentionDataGridActions onEdit={onEdit} /> : undefined}
                />
            )}
        </div>
    );
}
export default withStyles(RetentionDataGrid, styles)

// import { dateTimeFormat } from "../../../../common/utils";
import {Stack} from "@mui/material";
import RoleChip from "./RoleChip/RoleChip";

export function listColumns(t, classes, defaultRoles,user) {

  let columns = [];
  columns.push({
    field: "entityType",
    headerName: t("roles.RolesList.columns.entityType"),
    align: "left",
    headerAlign: "left",
    disableSort: true,
    customValue: (v) => {
      return t("common.Enums.entityType." + v.entityType);
    }
  });
  columns.push({
    field: "roleType",
    headerName: t("roles.RolesList.columns.roleType"),
    align: "left",
    headerAlign: "left",
    disableSort: true,
    customValue: (v) => {
      const entityRolesTypes = (v.roles || []).filter(item => !item.isDefault).map(item => item.roleType);
      const roles = (v.roles || []).filter(item => !entityRolesTypes.includes(item.roleType) || !item.isDefault);
      return <Stack direction="row" spacing={1}>
        {roles.map((role) => {
            return ((role.roleType === "A" && user.roleType === "A") || role.roleType !== "A") ? <RoleChip key={v.entityId + "_" + role.id} isDefault={role.isDefault} updatedTime={role.updatedTime}
                             updatedBy={role.updatedBy} roleType={role.roleType} roleId={role.id}
                             entityType={v.entityType} entityId={v.entityId}/> : "";
        })}
      </Stack>
    }
  });
  return columns;
}

import React from "react";
import { withStyles } from "tss-react/mui";
import { Switch, Typography, Grid } from "@mui/material";
import { Field } from "react-final-form";
import MetaError from "../../forms/MetaError";
import { styles } from "./SwitchField.styles";

function SwitchField({ classes, id, label, disabled, required }) {
  const onChangeHandler = (v, input) => {
    input.onChange && input.onChange(v);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <div>
            <Grid container spacing={1} direction="row">
              <Grid item>
                <Typography
                  component="span"
                  variant="caption"
                  htmlFor={id}
                  color="textPrimary"
                  className={disabled ? classes.disabledLabel : ""}
                >
                  {label}
                  {required && <span> *</span>}
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  checked={Boolean(input.value)}
                  onChange={(e) => onChangeHandler(e.target.checked, input)}
                  disabled={disabled}
                  color="primary"
                />
              </Grid>
              <MetaError className={classes.error} meta={meta} />
            </Grid>
          </div>
        )}
      </Field>
    </div>
  );
}

export default withStyles(SwitchField, styles)

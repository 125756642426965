import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const productCheckInternalCodeCall = async ({ setCheckInternalCodeResult }, dispatch, { internalCode }) => {
  let rh = requestHelper(dispatch, "PRODUCT_CHECK_INTERNAL_CODE");

  try {
    let result = await call({
      type: "PRODUCT",
      subType: "CHECK_INTERNAL_CODE",
      request: {
        internalCode
      },
    });
    dispatch(setCheckInternalCodeResult({ ...result, internalCode }));
  } catch (ex) {
    dispatch(setCheckInternalCodeResult({ ...ex, error: true }));
    // rh.error("products", ex);
  }
  rh.close();
};

export default productCheckInternalCodeCall;

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setLoggedUser, setRedirect, setSuccess } from "../../../app/coreSlice";

const entityPendingEntityActionCall = async (dispatch, state, { status, taskId, comment, confirm, entityId }) => {
  let rh = requestHelper(dispatch, "ENTITY_PENDING_ENTITY_ACTION");
  let nUser = state.core.loggedUser;

  try {
    await call({
      type: "ENTITY",
      subType: "PENDING_ENTITY_ACTION",
      request: {
        status, taskId, comment, entityId
      },
    });
    dispatch(setSuccess("entities", "ENTITY_PENDING_ENTITY_ACTION_" + status));

    if (confirm) {
      let nStatus = "ACT";
      dispatch(setLoggedUser({ ...nUser, entity: { ...nUser.entity, status: nStatus } }));
      setTimeout(() => {
        //reload user or get notification
        dispatch(setRedirect("/home"));
      }, 300);
    }
    else {
      setTimeout(() => {
        dispatch(setRedirect("/entities/validation"));
      }, 300);
    }
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default entityPendingEntityActionCall;

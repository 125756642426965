import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./Preferences.styles";
import { usePromoter } from "../../../../common/hooks";
import { SelectInput } from "../../../../common/inputs";
import { useDispatch } from "react-redux";
import { userSetPreferences } from "../../usersSlice";

function Preferences({ classes }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const promoter = usePromoter();

  const onLanguageChangeHandler = (value) => {
    dispatch(userSetPreferences({ preferences: { language: value } }));
    i18n.changeLanguage(value);
  }

  return (
    <div className={classes.root}>
      <SelectInput
        label={t("users.Preferences.form.language")}
        value={i18n.language}
        onChange={onLanguageChangeHandler}
        options={(promoter?.getConfig("admin", "language") || []).map((item) => ({ value: item, text: t("common.Enums.language." + item) }))}
      />
    </div>
  );
}

export default withStyles(Preferences, styles);
import moment from "moment";
import { copyFile, saveFile } from "../../../api/s3";
import { asyncForEach, base64ToArrayBuffer, guid, isBase64Image } from "../../../common/utils";

export const uploadMedia = async (fileStorageConfigs, promoterCode, productId, media) => {
  const { provider, privateBucket } = fileStorageConfigs;
  let nMedia = [];
  await asyncForEach(media, async (file) => {
    if (file.base64) {
      const extension = file?.name ? file.name.slice(file.name.lastIndexOf(".")) : ".png";
      const filePath =
        `${promoterCode?.toUpperCase()}/${process.env.REACT_APP_ENV}/product/${productId}/media/` + guid() + extension;
      let url = await saveFile(
        provider,
        privateBucket,
        filePath,
        base64ToArrayBuffer(file.base64),
        file.mediaType
      );
      nMedia.push({ type: "PRODUCT_IMAGE", reference: url, thumbnail: undefined }); // thumbnail will be created on backend
    } else {
      if ((file.referenceOg || file.reference || "").indexOf(productId) > 0 || (file.referenceOg || file.reference || "").indexOf(privateBucket) === -1) {
        nMedia.push({
          type: file.type,
          reference: file.referenceOg || file.reference,
          thumbnail: file.thumbnailOg || file.thumbnail,
        });
      }
      else {
        let fileName = file.referenceOg.split('/media/')[1];
        let key = `${promoterCode?.toUpperCase()}/${process.env.REACT_APP_ENV}/product/${productId}/media/` + fileName;
        let [host, ogKey] = file.referenceOg.split(promoterCode?.toUpperCase());
        let url = await copyFile(provider, privateBucket, key, privateBucket + "/" + promoterCode?.toUpperCase() + ogKey, host)
        nMedia.push({ type: "PRODUCT_IMAGE", reference: url, thumbnail: undefined });
      }
    }
  });
  return nMedia;
};

export const uploadDocuments = async (fileStorageConfigs, promoterCode, productId, documents) => {
  const { provider, privateBucket } = fileStorageConfigs;
  let nDocs = [];
  await asyncForEach(documents, async (file) => {
    let { name, base64, type, metadata, urlOg } = file;
    if (base64 && name) {
      const extension = name.slice(name.lastIndexOf("."));
      const filePath =
        `${promoterCode?.toUpperCase()}/${process.env.REACT_APP_ENV}/product/${productId}/docs/` + guid() + extension;
      let url = await saveFile(
        provider,
        privateBucket,
        filePath,
        base64ToArrayBuffer(file.base64),
        file.mediaType
      );
      nDocs.push({ type, url, metadata });
    } else {
      if ((urlOg || "").indexOf(productId) > 0 || (urlOg || "").indexOf(privateBucket) === -1) {
        nDocs.push({ type, url: urlOg, metadata });
      }
      else {
        let fileName = file.urlOg.split('/docs/')[1];
        let key = `${promoterCode?.toUpperCase()}/${process.env.REACT_APP_ENV}/product/${productId}/docs/` + fileName;
        let [host, ogKey] = file.urlOg.split(promoterCode?.toUpperCase());
        let url = await copyFile(provider, privateBucket, key, privateBucket + "/" + promoterCode?.toUpperCase() + ogKey, host)
        nDocs.push({ type, url, metadata });
      }
    }
  });
  return nDocs;
};

export const processHierarchy = async (product, hierarchy) => {
  let nHierarchy = [];
  await asyncForEach(hierarchy, async (node) => {
    nHierarchy.push({
      ...node,
      height: node.height || product?.weightMeasure?.height || { category: "COMP", value: 0, unit: "CMT", valueMax: 0 },
      width: node.width || product?.weightMeasure?.width || { category: "COMP", value: 0, unit: "CMT", valueMax: 0 },
      depth: node.depth || product?.weightMeasure?.depth || { category: "COMP", value: 0, unit: "CMT", valueMax: 0 },
      netWeight: node.netWeight || product?.weightMeasure?.netWeight || { category: "MASS", value: 0, unit: "GRM", valueMax: 0 },
      grossWeight: node.grossWeight || product?.weightMeasure?.grossWeight || { category: "MASS", value: 0, unit: "GRM", valueMax: 0 },
      netContent: node.netContent || product?.weightMeasure?.netContent || { category: "MASS", value: 0, unit: "GRM", valueMax: 0 },
    })
  });
  return nHierarchy;
}

export const processHierarchyToSave = (hierarchy, media0) => {
  if (!hierarchy) {
    return undefined;
  }
  let nHierarchy = [];

  hierarchy.forEach((node, index) => {
    let thumbnail;
    if (index === 0 && isBase64Image(node.thumbnail)) {
      thumbnail = media0.thumbnail;
    }
    else {
      thumbnail = node.thumbnailOg;
    }
    nHierarchy.push({
      ...node,
      child: index === 0 ? null : node.child,
      thumbnail,
      ...processWeightMeasuresToSave(node)
    });
  });

  return nHierarchy;
}

export const processWeightMeasuresToSave = (weightMeasure) => {
  if (!weightMeasure) {
    return undefined;
  }

  const { hasVariableDimensions, height, width, depth, netWeight, grossWeight, netContent, conversionFactor } = weightMeasure;

  let nHeight = parseFloat(height?.value);
  let nWidth = parseFloat(width?.value);
  let nDepth = parseFloat(depth?.value);
  let nNetWeight = parseFloat(netWeight?.value);
  let nGrossWeight = parseFloat(grossWeight?.value);
  let nNetContent = parseFloat(netContent?.value);
  let nConversionFactor = parseFloat(conversionFactor?.value);

  return {
    hasVariableDimensions: Boolean(hasVariableDimensions),
    height: nHeight !== 0 && !isNaN(nHeight) ? {
      ...height,
      value: nHeight,
      valueMax: hasVariableDimensions && !isNaN(parseFloat(height.valueMax)) ? parseFloat(height.valueMax) : undefined
    } : undefined,
    width: nWidth !== 0 && !isNaN(nWidth) ? {
      ...width,
      value: nWidth,
      valueMax: hasVariableDimensions && !isNaN(parseFloat(width.valueMax)) ? parseFloat(width.valueMax) : undefined
    } : undefined,
    depth: nDepth !== 0 && !isNaN(nDepth) ? {
      ...depth,
      value: nDepth,
      valueMax: hasVariableDimensions && !isNaN(parseFloat(depth.valueMax)) ? parseFloat(depth.valueMax) : undefined
    } : undefined,
    netWeight: nNetWeight !== 0 && !isNaN(nNetWeight) ? {
      ...netWeight,
      value: nNetWeight,
      valueMax: hasVariableDimensions && !isNaN(parseFloat(netWeight.valueMax)) ? parseFloat(netWeight.valueMax) : undefined
    } : undefined,
    grossWeight: nGrossWeight !== 0 && !isNaN(nGrossWeight) ? {
      ...grossWeight,
      value: nGrossWeight,
      valueMax: hasVariableDimensions && !isNaN(parseFloat(grossWeight.valueMax)) ? parseFloat(grossWeight.valueMax) : undefined
    } : undefined,
    netContent: nNetContent !== 0 && !isNaN(nNetContent) ? {
      ...netContent,
      value: nNetContent,
      valueMax: hasVariableDimensions && !isNaN(parseFloat(netContent.valueMax)) ? parseFloat(netContent.valueMax) : undefined
    } : undefined,
    conversionFactor: nConversionFactor !== 0 && !isNaN(nConversionFactor) ? {
      ...conversionFactor,
      value: nConversionFactor,
      valueMax: hasVariableDimensions && !isNaN(parseFloat(conversionFactor.valueMax)) ? parseFloat(conversionFactor.valueMax) : undefined
    } : undefined,
  }
}

export const getProductTaxChanges = (productId, ogTaxes, nTaxes) => {
  let result = {};
  let toDelete = [];
  let hasChanges = false
  if (ogTaxes.vat !== nTaxes.vat) {
    result.vat = nTaxes.vat;
    result.startDate = moment().format('YYYY-MM-DD');
    hasChanges = true;
  }
  let codes = getProductOtherTaxCodes(ogTaxes.other, nTaxes.other);
  codes.forEach(code => {
    let codeOgTax = (ogTaxes?.other || []).find(item => item.code === code);
    let codeNTax = (nTaxes?.other || []).find(item => item.code === code);
    if (codeOgTax?.value !== codeNTax?.value) {
      hasChanges = true;
      if (!result.other) {
        result.other = [];
      }
      if (codeNTax) {
        if (!codeNTax.startDate) {
          codeNTax = Object.assign({startDate: moment().format('YYYY-MM-DD')}, codeNTax);
        }
        result.other.push(codeNTax)
      }
    }
    if (!codeNTax) {
      hasChanges = true;
      toDelete.push({
        productId,
        code,
        startDate: moment().format('YYYY-MM-DD')
      })
    }
  })


  return { hasChanges, taxes: result, toDelete }
}

const getProductOtherTaxCodes = (itemOther, ogOther) => {
  let itemOtherCodes = (itemOther || [])
    .filter(item => item.code || item.process?.other?.code)
    .map(item => item.code || item.process?.other?.code);
  let ogExtraOtherCodes = (ogOther || [])
    .filter(item => item.code && !itemOtherCodes.includes(item.code))
    .map(item => item.code);
  return [...itemOtherCodes, ...ogExtraOtherCodes];
}


import MetadataField from "../MetadataField/MetadataField";

export const documentFields = ({ t, user, refData }) => {
  return [
    {
      size: 6,
      field: (
        <MetadataField
          id="functional.document.metadata"
          label={t("promoter.PromoterConfigs.form.docMetadata")}
          documentType={refData?.documentType}
          documentMetadata={refData?.metadata}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
  ];
};

import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import { listColumns } from "./UsersList.columns";
import { styles } from "./UsersList.styles";

import UserListActions from "./UsersList.actions";
import { Drawer, Typography, Avatar } from "@mui/material";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";
import { useParams } from "react-router-dom";

function UsersList({ classes, data, onEdit, onDelete }) {
  const { t } = useTranslation();
  const columns = listColumns(t, classes);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const { contactId } = useParams();

  const onRowSelectedHandler = (row) => {
    setSelectedRow(row);
    setDrawerOpen(true);
  };

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          columns={columns}
          rows={data.map((item) => {
            return {
              ...item,
              className: contactId === item.id ? classes.selectedContact : undefined,
            };
          })}
          actionsComponent={<UserListActions onEdit={onEdit} onDelete={onDelete} />}
          onRowSelected={onRowSelectedHandler}
        />
      )}
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div className={classes.drawer}>
          {selectedRow?.logo && (
            <Avatar
              alt={(selectedRow?.firstName || "") + " " + (selectedRow?.lastName || "")}
              src={selectedRow?.logo}
              className={classes.avatar}
            />
          )}
          {selectedRow?.firstName && (
            <LabelValue
              label={t("entities.UsersList.details.firstName")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.firstName}
                </Typography>
              }
            />
          )}
          {selectedRow?.lastName && (
            <LabelValue
              label={t("entities.UsersList.details.lastName")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.lastName}
                </Typography>
              }
            />
          )}
          {selectedRow?.email && (
            <LabelValue
              label={t("entities.UsersList.details.email")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.email}
                </Typography>
              }
            />
          )}
          {selectedRow?.phoneNumber && (
            <LabelValue
              label={t("entities.UsersList.details.phoneNumber")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.phoneNumber}
                </Typography>
              }
            />
          )}
          {selectedRow?.roleType && (
            <LabelValue
              label={t("entities.UsersList.details.roleType")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {t("common.Enums.roleType." + selectedRow?.roleType)}
                </Typography>
              }
            />
          )}
          {selectedRow?.faxNumber && (
            <LabelValue
              label={t("entities.UsersList.details.faxNumber")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.faxNumber}
                </Typography>
              }
            />
          )}
          {selectedRow?.mobileNumber && (
            <LabelValue
              label={t("entities.UsersList.details.mobileNumber")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.mobileNumber}
                </Typography>
              }
            />
          )}
          {selectedRow?.website && (
            <LabelValue
              label={t("entities.UsersList.details.website")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.website}
                </Typography>
              }
            />
          )}
          {selectedRow?.notify !== undefined && (
            <LabelValue
              label={t("entities.UsersList.details.notify")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.notify ? t('common.Enums.switch.ON') : t('common.Enums.switch.OFF')}
                </Typography>
              }
            />
          )}
          {selectedRow?.sendDoc !== undefined && (
            <LabelValue
              label={t("entities.UsersList.details.sendDoc")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {selectedRow?.sendDoc ? t('common.Enums.switch.ON') : t('common.Enums.switch.OFF')}
                </Typography>
              }
            />
          )}
        </div>
      </Drawer>
    </div>
  );
}

export default withStyles(UsersList, styles)

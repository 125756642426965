import {requestHelper, setSuccess} from "../../../app/coreSlice";
import {call} from "../../../app/websocket/_sliceCalls/websocket";
import {businessGetProcessDetail} from "../businessSlice";

const businessProcessTaskAddUserCall = async ({ setBusinessProcessHistory }, dispatch, { processId, taskId, user }) => {
  let rh = requestHelper(dispatch, "BUSINESS_PROCESS_TASK_ADD_USER");
  try {
    let result = await call({
      type: "PROCESS",
      subType: "TASK_ADD_USER",
      request: {
        taskId,
        user
      },
    });

    if (result.success) {
      dispatch(setSuccess("business", "BUSINESS_PROCESS_TASK_ADD_USER"));
      setTimeout(() => {
        dispatch(businessGetProcessDetail(processId));
      }, 300);
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default businessProcessTaskAddUserCall;

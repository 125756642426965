import {currencyFormat} from "../../../../common/utils";

export const csvFields = (t) => {

  return [
    {
      field: "gtin",
      header: t("orders.OrderProductsDataGrid.columns.gtin"),
    },
    {
      field: "description",
      header: t("orders.OrderProductsDataGrid.columns.description"),
    },
    {
      field: "quantity",
      header: t("orders.OrderProductsDataGrid.columns.quantity"),
    },
    {
      field: "price",
      header: t("orders.OrderProductsDataGrid.columns.price"),
    },
    {
      field: "vat",
      header: t("orders.OrderProductsDataGrid.columns.tax"),
    },
    {
      field: "total",
      header: t("orders.OrderProductsDataGrid.columns.total"),
      customValue: (v) => {
        return v.active ? currencyFormat(parseFloat(v.price) * parseFloat(v.quantity)) : "";
      },
    },
  ];
}
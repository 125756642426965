import * as Yup from "yup";
import { TextField } from "../../../../common/forms";

export function formSchema({ t }) {
  return Yup.object().shape({
    firstName: Yup.string().required(t('users.Profile.form.firstNameRequired')),
    lastName: Yup.string().required(t('users.Profile.form.lastNameRequired'))
  });
}

export const formFields = ({ t }) => {
  return [
    {
      size: 12,
      field: (
        <TextField
          id="firstName"
          type="text"
          label={t("users.Profile.form.firstName")}
          required
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextField
          id="lastName"
          type="text"
          label={t("users.Profile.form.lastName")}
          required
        />
      ),
    },
  ];
};

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper } from "@mui/material";

import { styles } from "./Measures.styles";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";

function Measures({ classes, product }) {
  const { t } = useTranslation();
  const { height, width, depth, netWeight, grossWeight, netContent, hasVariableDimensions } = product?.weightMeasure || {};
  const { unitDescriptor } = product?.units || {}

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h3" align="left" component="h2" className={classes.noMargin}>
            {t("products.ProductDetails.details.measuresTitle")}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {unitDescriptor !== "BULK" &&
              <Grid item xs={2}>
                <LabelValue
                  label={t("products.ProductDetails.details.height")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {unitLabel(t, hasVariableDimensions, height)}
                    </Typography>
                  }
                />
              </Grid>
            }
            {unitDescriptor !== "BULK" &&
              <Grid item xs={2}>
                <LabelValue
                  label={t("products.ProductDetails.details.width")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {unitLabel(t, hasVariableDimensions, width)}
                    </Typography>
                  }
                />
              </Grid>}
            {unitDescriptor !== "BULK" &&
              <Grid item xs={2}>
                <LabelValue
                  label={t("products.ProductDetails.details.depth")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {unitLabel(t, hasVariableDimensions, depth)}
                    </Typography>
                  }
                />
              </Grid>
            }
            <Grid item xs={2}>
              <LabelValue
                label={t("products.ProductDetails.details.netWeight")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {unitLabel(t, hasVariableDimensions, netWeight)}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={2}>
              <LabelValue
                label={t("products.ProductDetails.details.grossWeight")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {unitLabel(t, hasVariableDimensions, grossWeight)}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={2}>
              <LabelValue
                label={t("products.ProductDetails.details.netContent")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {unitLabel(t, hasVariableDimensions, netContent)}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(Measures, styles);

const unitLabel = (t, hasVariableDimensions, measure) => {
  if (!measure) {
    return "N/D"
  }

  const { value, valueMax, unit } = measure;
  let label = value;
  if (hasVariableDimensions) {
    label += ` ${t("products.ProductDetails.details.to")} ${valueMax}`
  }
  if (!unit) {
    return label;
  }
  return `${label} ${t("common.Enums.measureUnits." + unit)}`;
}
import React from "react";
import * as Yup from "yup";
import { FileUploadField } from "../../../common/components/formFields";
import { TextField, SelectField, EntityAutoCompleteField } from "../../../common/forms";

export function formFields(t, user, promoter, onOwnerChange, entity, values) {
  let arrOption = [];
  if (user.hasPermission("products.actions.product.import")) {
    arrOption.push("product");
  }
  if (user.hasPermission("products.actions.price.import")) {
    arrOption.push("price");
  }

  // const importOptions = arrOption.map((item) => {
  //   return {
  //     value: item,
  //     text: t("products.ImportProduct.enum." + item),
  //   };
  // });

  const addressIdOptions = (entity?.addresses || []).map((item) => {
    return {
      value: item.id,
      text: item.description,
    };
  });

  return [
    // {
    //   size: 4,
    //   field: <SelectField id="type" label={t("products.ImportProduct.form.type")} options={importOptions} required />,
    // },
    // {
    //   size: 8,
    // },
    {
      size: 4,
      field: (
        <EntityAutoCompleteField
          typeFilter={promoter?.getConfig("product", "allowedEntityTypes")}
          id="ownerId"
          label={t("products.ImportProduct.form.ownerId")}
          required
          onChange={(v) => onOwnerChange(v, values)}
        />
      ),
      hidden: user?.entity?.type !== "P",
    },
    {
      size: 4,
      field: <TextField id="gln" type="text" label={t("products.ImportProduct.form.gln")} required maxLength={13} />,
    },
    {
      size: 4,
      field: (
        <SelectField
          id="addressId"
          label={t("products.ImportProduct.form.addressId")}
          options={addressIdOptions}
          required={values.type === "product"}
          disabled={values.type !== "product"}
        />
      ),
      hidden: values.type === "price",
    },
    /* {
      size: 2,
      field: <div></div>,
    },*/
    {
      size: 4,
      field: (
        <FileUploadField
          id={"file"}
          label={t("products.ImportProduct.form.upload")}
          labelInput={t("products.ImportProduct.form.uploadInput")}
          required
        />
      ),
    },
  ];
}

export function formSchema(t) {
  return Yup.object().shape({
    type: Yup.string().required(t("products.ImportProduct.form.requiredType")),
    ownerId: Yup.string().required(t("products.ImportProduct.form.requiredOwnerId")),
    gln: Yup.string()
      .required(t("products.ImportProduct.form.requiredGln"))
      .matches(/^[0-9]+$/, t("entities.CreateEntity.form.onlyDigits"))
      .min(13, t("entities.CreateEntity.form.specificDigits", { digits: 13 }))
      .max(13, t("entities.CreateEntity.form.specificDigits", { digits: 13 })),
    file: Yup.array().ensure().min(1, t("products.ImportProduct.form.requiredAtLeastOneFile")),
  });
}

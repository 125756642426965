import { createSlice } from "@reduxjs/toolkit";
import i18n from "i18next";

import { call } from "../../app/websocket/_sliceCalls/websocket";
import { setSuccess } from "../../app/coreSlice";

import { sleep } from "../../common/utils/utils";

export const i18nSlice = createSlice({
  name: "i18n",
  initialState: {
    loader: null,
    error: null,
    bundle: {},
  },
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },

    setBundle: (state, action) => {
      state.bundle = action.payload;
    },
  },
});

export const { setLoader, setError, setBundle } = i18nSlice.actions;

export const updateI18n = (language, i18nValues) => async (dispatch, getState) => {
  let promoterCode = getState().core.selectedPromoter;
  dispatch(setError(null));
  dispatch(setLoader("PROMOTER/UPDATE_I18N"));
  try {
    if (i18nValues[language]) {
      let result = await call({
        type: "PROMOTER",
        subType: "UPDATE_I18N",
        request: {
          language,
          i18n: JSON.stringify(i18nValues[language]),
        },
      });
      if (result?.updated) {
        dispatch(setSuccess("i18n", "PROMOTER_UPDATE_I18N"));
        i18n.init({
          backend: {
            loadPath:
              process.env.REACT_APP_PUBLIC_FILE_STORAGE +
              "/" +
              promoterCode +
              "/" +
              process.env.REACT_APP_ENV +
              "/i18n/messages_{{lng}}.json?t=" +
              new Date().getTime(),
          },
        });
        i18n.reloadResources();
      }
    }
    //initI18n(new Date().getTime());
  } catch (ex) {
    dispatch(setError({ type: "SERVER_ERROR", error: ex }));
  }
  dispatch(setLoader(null));
};

export const createI18nFullBundle = (promoter) => async (dispatch) => {
  dispatch(setLoader("INIT_BUNDLE_I18N"));
  await sleep(300);

  const bundle = {};
  (promoter?.getConfig("admin", "language") || []).forEach((lang) => {
    bundle[lang] = i18n.getResourceBundle(lang, "translation");
  });
  dispatch(setBundle(bundle));
  dispatch(setLoader(null));
};

export const selectLoader = (state) => state.i18n.loader;
export const selectError = (state) => state.i18n.error;
export const selectBundle = (state) => state.i18n.bundle;

export default i18nSlice.reducer;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper } from "@mui/material";

import { styles } from "./AuditImportDetails.styles";

import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { auditGetAuditImport, selectAuditImportDetails } from "../businessSlice";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import LabelValue from "../../../common/displays/LabelValue/LabelValue";
import { dateTimeFormatWithMilliSeconds } from "../../../common/utils/formats";

function AuditImportDetails({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const details = useSelector(selectAuditImportDetails);

  useFirstLoad(() => {
    dispatch(auditGetAuditImport(id))
  });

  if (!details) {
    return null;
  }

  const translateErrorMessage = (items, type) => {
    return items.map((item) => {
      return { lineId: item['lineId'], success: item['success'], message: item['message'] !== undefined ? t("errors.import." + type + "." + item['message']) : "" }
    }
    )
  };

  let lines = details?.lines ? (<pre>{JSON.stringify(details?.lines, null, 2)}</pre>) : [];
  let resultLines = details?.resultLines ? (<pre>{JSON.stringify(translateErrorMessage(details?.resultLines, details?.type.toLowerCase()), null, 2)}</pre>) : [];


  return (
    <Page
      permission="business.actions.audit.get"
      header={
        <PageTitle
          title={t("business.audit.import.title") + " - " + details.type + "/" + details.subType}
          onBack={() => navigate('/activity/audits/import')}
        />
      }>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.audit.import.details_type")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.type}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.audit.import.details_subtype")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.subType}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.audit.import.details_user")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.user}
                    </Typography>
                  }
                />
              </Grid>
              {details?.processData && <Grid item xs={4}>
                {details?.processData.owner && <LabelValue
                  label={t("business.audit.import.details_owner")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details?.processData?.owner}
                    </Typography>
                  }
                />}
                {details?.processData.gln && <LabelValue
                  label={t("business.audit.import.details_gln")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details?.processData?.gln}
                    </Typography>
                  }
                />}
                {details?.processData.address && <LabelValue
                  label={t("business.audit.import.details_address")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details?.processData?.address}
                    </Typography>
                  }
                />}
                {details?.processData.cutOff && <LabelValue
                  label={t("business.audit.import.details_cutOff")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details?.processData?.cutOff}
                    </Typography>
                  }
                />}
                {details?.processData.orderBy && <LabelValue
                  label={t("business.audit.import.details_orderBy")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details?.processData?.orderBy}
                    </Typography>
                  }
                />}
                {details?.processData.orderByAddress && <LabelValue
                  label={t("business.audit.import.details_orderByAddress")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details?.processData?.orderByAddress}
                    </Typography>
                  }
                />}
              </Grid>}
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.audit.import.details_status")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.linesWithError > 0 ? t("business.audit.import.columns_error_true") : t("business.audit.import.columns_error_false")}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.audit.import.details_totalLines")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.totalLines}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.audit.import.details_linesWithError")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.linesWithError}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <LabelValue
                  label={t("business.audit.import.details_time")}
                  value={
                    details?.time && <Typography variant="body1" align="left" component="span">
                      {dateTimeFormatWithMilliSeconds(details.time)}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.audit.import.details_lines")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      <pre className={classes.json}>
                        {lines}
                      </pre>
                    </Typography>
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <LabelValue
                  label={t("business.audit.import.details_resultTime")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {dateTimeFormatWithMilliSeconds(details.resultTime)}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.audit.import.details_resultLines")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      <pre className={classes.json}>
                        {resultLines}
                      </pre>
                    </Typography>
                  }
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

export default withStyles(AuditImportDetails, styles);


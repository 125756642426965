import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Button } from "@mui/material";

import { styles } from "./NotAvailablePage.styles";
import { useNavigate } from "../../hooks";

function NotAvailablePage({ classes }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <Typography>{t("common.NotAvailablePage.page.message")}</Typography>
      <br />
      <br />
      <br />
      <Button variant="contained" onClick={() => navigate("/")}>{t("common.NotAvailablePage.page.backBtn")}</Button>
    </div>
  );
}

export default withStyles(NotAvailablePage, styles);
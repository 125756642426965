import React from "react";
import moment from "moment";
import * as Yup from "yup";
import { EntityGroupSelector } from "../../../common/components/formFields";
import { DatePickerField, TextField } from "../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    name: Yup.string().required(t("products.CampaignCreate.form.requiredName")),
  });
}

export function formFields(t, isEdit, viewMode, startDate, groups) {
  return [
    {
      size: 8,
      field: (
        <TextField id="name" disabled={isEdit} type="text" label={t("products.CampaignCreate.form.name")} required />
      ),
    },
    {
      size: 4,
    },
    {
      size: 4,
      field: (
        <DatePickerField
          id="startDate"
          label={t("products.CampaignCreate.form.startDate")}
          required
          minDate={moment(new Date())}
          disabled={isEdit}
        />
      ),
    },
    {
      size: 4,
      field: (
        <DatePickerField
          id="endDate"
          label={t("products.CampaignCreate.form.endDate")}
          required
          minDate={startDate}
          disabled={isEdit}
        />
      ),
    },
    {
      size: 4,
    },
    {
      size: 8,
      field: (
        <EntityGroupSelector
          id="groupEntity"
          label={t("products.CampaignCreate.form.groupEntity")}
          info={t("products.CampaignCreate.form.groupEntityInfo")}
          groups={groups}
          disabled={viewMode}
        />
      ),
    },
  ];
}

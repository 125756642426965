import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { toGroupCreate } from "../entitiesMapper";

const entityUpdateGroupCall = async (dispatch, { groupId, group }) => {
  let rh = requestHelper(dispatch, "UPDATE_GROUP");
  try {
    await call({
      type: "ENTITY",
      subType: "UPDATE_GROUP",
      request: {
        id: groupId,
        ...toGroupCreate(group),
      },
    });
    setTimeout(() => {
      dispatch(setSuccess("entities", "ENTITY_UPDATE_GROUP"));
      dispatch(setRedirect(`/entities/groups`));
    }, 300);
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityUpdateGroupCall;

import React from "react";
import { withStyles } from "tss-react/mui";
import { Modal, Typography, Grid, CircularProgress } from "@mui/material";

import { styles } from "./SessionLost.styles";
import { useSelector } from "react-redux";
import { selectConnected, selectConnecting } from "../../websocket/websocketSlice";

function SessionLost({ classes }) {
  const connected = useSelector(selectConnected);
  const connecting = useSelector(selectConnecting);

  return (
    <div className={classes.root}>
      <Modal open={connecting && !connected} className={classes.modal}>
        <div className={classes.modalroot}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="space-evenly"
            className={classes.modalBody}
          >
            <Grid item>
              <CircularProgress />
            </Grid>
            <Grid item>
              <Typography>
                <b>Reconnecting...</b>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}

export default withStyles(SessionLost, styles);

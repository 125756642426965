
import { guid } from "../../../common/utils";
import WebSocketService from "./WebSocketService";

let ws;
let subscriptions = {
  user: null,
  notification: null,
  promoter: null
};
let requests = {};

export const init = (
  host,
  credentials,
  { onConnect, onConnectError, onDisconnect, onWebsocketClose, onFailedLogin }
) => {
  if (!ws) {
    ws = new WebSocketService();
    window.ws = ws;
  }

  const onConnectHandler = (sessionId) => {
    subscriptions.user = ws.subscribe("/secured/user/queue/specific-user" + sessionId, (msg) =>
      processMessageHandler(msg)
    );
    onConnect && onConnect(sessionId);
  };
  ws.init(host, credentials, {
    onConnect: onConnectHandler,
    onConnectError,
    onDisconnect,
    onWebsocketClose,
    onFailedLogin,
  });

  // reset subscriptions and requests
  resetSubscriptions();
  requests = {};
};

export const disconnect = () => {
  if (ws) {
    ws.disconnect();
  }
  resetSubscriptions();
};

const resetSubscriptions = () => {
  subscriptions.user && subscriptions.user.unsubscribe();
  subscriptions.user = null;
  subscriptions.notification && subscriptions.notification.unsubscribe();
  subscriptions.notification = null;
  subscriptions.promoter && subscriptions.promoter.unsubscribe();
  subscriptions.promoter = null;
};

export const call = async ({ wsid, type, subType, request, customTimeout }) => {
  if (!wsid && wsid !== null) {
    wsid = guid();
  }
  let body = {
    wsid,
    type,
    subType,
    request,
  };

  if (process.env.REACT_APP_ENV !== "production") {
    console.log("%c >>> REQUEST [%s/%s] %o", "color:#26b9c5; font-weight:bold", type, subType, request);
  }

  ws.publish({
    destination: "/app/flow",
    body: JSON.stringify(body),
  });
  if (wsid) {
    return new Promise((resolve, reject) => {
      requests[wsid] = {
        resolve,
        reject,
        timeout: setTimeout(
          () => reject({ tag: "TIMEOUT", flow: type + "/" + subType }),
          customTimeout || process.env.REACT_APP_WS_TIMEOUT
        ),
      };
    });
  }
};

const processMessageHandler = (message) => {
  let body;
  try {
    body = JSON.parse(message.body);
    let { wsid, reporting, processMessage, error, type, subType } = body;

    let req = requests[wsid];
    if (req && !reporting) {
      clearTimeout(req.timeout);
      if (error) {
        processMessage.tag = processMessage.tag || "INTERNAL_SERVER_ERROR";
        req.reject(processMessage);
      } else {
        if (process.env.REACT_APP_ENV !== "production") {
          console.log("%c <<< RESPONSE [%s/%s] %o", "color:#71e226; font-weight:bold", type, subType, processMessage);
        }
        req.resolve(processMessage);
      }
    }
  } catch (e) {
    console.error(e);
    return;
  }
};

export const subscribe = (key, destination, callback) => {
  try {
    subscriptions[key] = ws.subscribe(destination, callback);
  } catch (ex) {
    console.error(ex);
  }
};

export const unsubscribe = (key) => {
  subscriptions[key] && subscriptions[key].unsubscribe();
};

import {dateTimeFormatWithMilliSeconds} from "../../../../common/utils/formats";

export function listColumns(t, classes) {

  return [

    {
      field: "status",
      headerName: t("business.process.processBusinessHistoryList.columns_status"),
      align: "left",
      headerAlign: "left",

    }, {
      field: "dateIn",
      headerName: t("business.process.processBusinessHistoryList.columns_dateIn"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.dateIn));
      },
    },
    {
      field: "dateOut",
      headerName: t("business.process.processBusinessHistoryList.columns_dateOut"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.dateOut));
      }
    },
    {
      field: "request",
      headerName: t("business.process.processBusinessHistoryList.columns_request"),
      align: "left",
      headerAlign: "left",
    },{
      field: "user",
      headerName: t("business.process.processBusinessHistoryList.columns_user"),
      align: "left",
      headerAlign: "left",
    }
  ];
}

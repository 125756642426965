import * as Yup from "yup";
import {CardSelectField, EntityAutoCompleteField, TextField} from "../../../../common/forms";

export function formSchema(t, promoter) {
  return Yup.object().shape({
    deliveryDay: promoter?.getConfig("cutOff", "automatic")
      ? Yup.string()
      : Yup.string().required(t("orders.DeliveryDayAndAddressForm.form.requiredDeliveryDay")),
    address: Yup.string().required(t("orders.DeliveryDayAndAddressForm.form.requiredAddress")),
  });
}

export function formFields({ t, formOptions, user, values, promoter, selectedAddress, onEntityChange, onAddressChange }) {
  const locationOptions = (selectedAddress?.locations || []).map(item => ({
    value: item.id,
    text: item.name
  }))
  return [
    {
      size: 12,
      field: (
        <CardSelectField
          id="deliveryDay"
          label={t("orders.DeliveryDayAndAddressForm.form.deliveryDay")}
          options={formOptions?.deliveryDay}
          xs={4}
        />
      ),
      hidden: promoter?.getConfig("cutOff", "automatic"),
    },
    {
      size: 8,
      field: (
        <EntityAutoCompleteField
          typeFilter={promoter?.getConfig("order", "allowedEntityTypes") || ["W", "ST"]}
          id="orderBy"
          label={t("orders.DeliveryDayAndAddressForm.form.orderBy")}
          required
          onChange={onEntityChange}
        />
      ),
      hidden: user.entity?.type !== "P",
    },
    {
      size: 4,
    },
    {
      size: 12,
      field: (
        <CardSelectField
          id="address"
          label={t("orders.DeliveryDayAndAddressForm.form.address")}
          options={formOptions?.addresses}
          xs={4}
          onChange={onAddressChange}
        />
      ),
      hidden: !values.orderBy && user.entity?.type === "P",
    },
    {
      size: 12,
      field: (
        <CardSelectField
          id="locationId"
          label={t("orders.DeliveryDayAndAddressForm.form.location")}
          options={locationOptions}
          xs={4}
        />
      ),
      hidden: !locationOptions || locationOptions.length <= 1,
    },
    {
      size: 12,
      field: (
          <TextField id="comments" type="text" label={t("orders.DeliveryDayAndAddressForm.form.comments")}  />
      )
    },

  ];
}

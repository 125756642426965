import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const entityUpdateTaxAddressCall = async (dispatch, { id, address, postalCode, locale, county, state, country }) => {
  let rh = requestHelper(dispatch, "ENTITY/UPDATE_TAX_ADDRESS");
  try {
    await call({
      type: "ENTITY",
      subType: "UPDATE_TAX_ADDRESS",
      request: {
        id,
        address: {
          type: "T",
          address,
          postalCode,
          locale,
          county,
          state,
          country,
        },
      },
    });
    setTimeout(() => {
      dispatch(setSuccess("entities", "ENTITY_UPDATE_TAX_ADDRESS"));
    }, 200);
  } catch (ex) {
    rh.error("entities", ex);
  }
  setTimeout(() => {
    rh.close();
  }, 250);
};

export default entityUpdateTaxAddressCall;

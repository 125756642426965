export const styles = theme => ({
  root: {
    // "& .CdgRow": {
    //   cursor: "pointer",
    //   "&:hover": {
    //     backgroundColor: '#ddd'
    //   }
    // }
  },
  id: {
    margin: 0,
  },
  orderBy: {
    margin: 0,
    color: "#aaa"
  },
  nomargin: {
    margin: 0,
  }
});
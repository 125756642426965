import {call} from "../../../app/websocket/_sliceCalls/websocket";
import {requestHelper, setRedirect, setSuccess} from "../../../app/coreSlice";

const placeUpdateCall = async (
  dispatch,
  {  id, name, type, gln, attributes, addressId, contactId, geoCoordinates, parentId, entityId }
) => {
  let rh = requestHelper(dispatch, "PLACE_UPDATE");
  try {
    let result = await call({
      type: "PLACE",
      subType: "UPDATE",
      request: {
        id, name, type, gln, attributes, addressId, contactId, geoCoordinates, parentId, entityId
      },
    });

    setTimeout(() => {
      if (result) {
        dispatch(setRedirect("/places"));
        dispatch(setSuccess("places", "PLACE_UPDATE"));
      }
    }, 300);
    
  } catch (ex) {
    rh.error("places", ex);
  }
  rh.close();
};

export default placeUpdateCall;


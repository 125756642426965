import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const auditGetAuditJobCall = async ({ setAuditJobDetails }, dispatch, { id }) => {
  let rh = requestHelper(dispatch, "AUDIT_GET_AUDIT_JOB");
  try {
    let result = await call({
      type: "AUDIT",
      subType: "GET_AUDIT_JOB",
      request: {
        id
      },
    });

    if (result && result.audit) {
      dispatch(setAuditJobDetails(result.audit));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default auditGetAuditJobCall;

export const getOrdersOptions = (orders) => {
  // return [
  //   {value: 123, orderNumber: "ORDER 123"},
  //   {value: 124, orderNumber: "ORDER 124"},
  //   {value: 125, orderNumber: "ORDER 125"}
  // ]
  return (orders || []).map(item => ({
    ...item,
    value: item.orderId
  }));
}

export const getInternalBatchs = (batches) => {
  return [
    { value: 123, batchNumber: "BATCH 123" },
    { value: 124, batchNumber: "BATCH 124" },
    { value: 125, batchNumber: "BATCH 125" }
  ]
  return (batches || []).map(item => ({
    ...item,
    value: item.orderId
  }));
}
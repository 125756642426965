import React from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./OrderProductsDataGridField.styles";
import { Field } from "react-final-form";
import OrderProductsDataGrid from "../../OrderProductsDataGrid/OrderProductsDataGrid";
import { replaceObjectInArray } from "../../../../../common/utils/utils";
import MetaError from "../../../../../common/forms/MetaError";

function OrderProductsDataGridField({
  classes,
  id,
  searchQuery,
  showTotals,
  showPrices,
  showCampaigns,
  allowBatching,
  disabledQuantity,
  allowExport = false,
  checkRows = false,
}) {
  const onQuantityChangedHandler = (input, product) => {
    let nProducts = [...input.value];
    nProducts = replaceObjectInArray(nProducts, product, "key");
    input.onChange && input.onChange(nProducts);
  };

  const onBatchsChangeHandler = (input, productKey, batch) => {
    let nProducts = [...input.value];
    let nProduct = { ...nProducts?.find((prod) => prod.key === productKey) };
    // workaround to allow Yup to check this fields in each batch to set required or not
    let nBatch = [...batch].map(b => {
      return {
        ...b,
        hasBatchNumber: nProduct.hasBatchNumber,
        hasExpirationDate: nProduct.hasExpirationDate,
        hasVariableWeight: nProduct.hasVariableWeight,
      }
    })
    if (nProduct) {
      nProduct.productBatch = [...nBatch];
      nProducts = replaceObjectInArray(nProducts, nProduct, "key");
      input.onChange && input.onChange(nProducts);
    }
  };
  const onCheckChangedHandler = (input, product) => {
    let nProducts = [...input.value];
    nProducts = replaceObjectInArray(nProducts, product, "key");
    input.onChange && input.onChange(nProducts);
  };

  const onBulkCheckChangeHandler = (input, checked) => {
    let nProducts = [...input.value];
    input.onChange &&
      input.onChange(
        nProducts.map((p) => {
          return {
            ...p,
            checked,
          };
        })
      );
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => {
          return (
            <div style={{ textAlign: "right" }}>
              <OrderProductsDataGrid
                products={input.value}
                searchQuery={searchQuery}
                showTotals={showTotals}
                showPrices={showPrices}
                showCampaigns={showCampaigns}
                onQuantityChanged={(product) => onQuantityChangedHandler(input, product)}
                allowBatching={allowBatching}
                onBatchsChange={(productKey, batch) => onBatchsChangeHandler(input, productKey, batch)}
                disabledQuantity={disabledQuantity}
                allowExport={allowExport}
                checkRows={checkRows}
                onCheckChanged={(product) => onCheckChangedHandler(input, product)}
                allChecked={input.value && input.value.every((p) => p.checked)}
                onBulkCheckChange={(checked) => onBulkCheckChangeHandler(input, checked)}
              />

              <MetaError className={classes.error} meta={meta} />
            </div>
          );
        }}
      </Field>
    </div>
  );
}

export default withStyles(OrderProductsDataGridField, styles)

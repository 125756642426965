export const styles = (theme) => ({
  root: {
  },
  separator: {
    backgroundColor: theme.palette.primary.back,
    width: "100%",
    padding: theme.spacing(1),
    textAlign: "center",
  },
  separatorTitle: {
    margin: "0px !important"
  },
  separatorObs: {
    margin: "0px !important",
    fontSize: 10
  }
});
import React from "react";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";
import { Typography, Grid, Button } from "@mui/material";
import { Field } from "react-final-form";
import { styles } from "./DispatchProducts.styles";
import { currencyFormat } from "../../../../common/utils";
import BatchFields from "../../_common/inputs/BatchInput/BatchFields/BatchFields";
import AggregatedOrderProductsDataGrid from "../AggregatedOrderProductsDataGrid/AggregatedOrderProductsDataGrid";
import MetaError from "../../../../common/forms/MetaError";

function DispatchProducts({ classes, id, label, disabled, required, buyerId, totals, operationType, order, dispatchScreen, acceptScreen }) {
  const { t } = useTranslation();

  const gridFooter = (
    <Grid container direction="column" alignItems="flex-end">
      <Grid item className={classes.totalbox}>
        <Grid container justifyContent="space-between">
          <Typography className={classes.totalLabel}>{t("orders.OrderDetails.footer.subtotal")}</Typography>
          <Typography className={classes.noMargin} color="textPrimary">
            {currencyFormat(totals?.subtotal)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.totalbox}>
        <Grid container justifyContent="space-between">
          <Typography className={classes.totalLabel}>{t("orders.OrderDetails.footer.ivatotal")}</Typography>
          <Typography className={classes.noMargin} color="textPrimary">
            {currencyFormat(totals?.ivatotal)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.totalbox}>
        <Grid container justifyContent="space-between">
          <Typography className={classes.totalLabel} color="textPrimary">
            {t("orders.OrderDetails.footer.total")}
          </Typography>
          <Typography className={classes.noMargin} color="textPrimary">
            <b>{currencyFormat(totals?.subtotal + totals?.ivatotal)}</b>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const addBatchHandler = (product, index, input) => {
    let nValue = [...input.value];
    if (!nValue[index].productBatch) {
      nValue[index] = { ...nValue[index], productBatch: [] };
    }
    let nQuantity = product.quantity - nValue[index].productBatch.reduce((pv, cv) => pv + cv.quantity, 0);

    nValue[index].productBatch.push({
      batch: "",
      expirationDate: undefined,
      weight: "",
      quantity: nQuantity,
    });
    input.onChange && input.onChange(nValue);
  };

  const removeBatchHandler = (index, bIndex, input) => {
    let nValue = [...input.value];
    nValue[index].productBatch.splice(bIndex, 1);
    input.onChange && input.onChange(nValue);
  };

  const updateBatchHandler = (index, bIndex, batch, input) => {
    let nValue = [...input.value];
    nValue[index].productBatch[bIndex] = { ...batch };
    input.onChange && input.onChange(nValue);
  };

  const onQuantitiesChangeHandler = (quantities, input) => {
    let nValue = [...input.value];
    nValue = nValue.map((item) => {
      let tempQuantProduct = quantities.find((q) => q.key === item.key);
      return {
        ...item,
        quantity: tempQuantProduct ? tempQuantProduct.quantity : item.quantity,
      };
    });
    input.onChange && input.onChange(nValue);
  };

  const innerRow = (product, index, input) => {
    return product.quantity > 0 ? (
      <Grid container direction="column">
        {(product.productBatch || []).map((item, bIndex) => {
          return (
            <Grid item key={"batch_" + bIndex}>
              <BatchFields
                batch={item}
                product={product}
                onChange={(batch) => updateBatchHandler(index, bIndex, batch, input)}
                onRemove={() => removeBatchHandler(index, bIndex, input)}
              />
            </Grid>
          );
        })}
        <Grid item style={{ textAlign: "left" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => addBatchHandler(product, index, input)}
          >
            {t("orders.AggregatedDetails.grid.addBatch")}
          </Button>
        </Grid>
      </Grid>
    ) : null;
  };

  return (
    <div className={classes.root}>
      <Typography
        component="span"
        variant="caption"
        htmlFor={id}
        color="textPrimary"
        className={disabled ? classes.disabledLabel : ""}
      >
        {label}
        {required && <span> *</span>}
      </Typography>
      <Field name={id}>
        {({ input, meta }) => (
          <div>
            <AggregatedOrderProductsDataGrid
              data={input.value}
              order={order}
              buyerId={buyerId}
              footer={gridFooter}
              innerRow={operationType === "CD" ? (row, index) => innerRow(row, index, input) : null}
              onQuantitiesChange={(quantities) => onQuantitiesChangeHandler(quantities, input)}
              dispatchScreen={dispatchScreen}
              acceptScreen={acceptScreen}
            />
            <MetaError className={classes.error} meta={meta} />
          </div>
        )}
      </Field>
    </div>
  );
}

export default withStyles(DispatchProducts, styles)

import * as Yup from "yup";
import { SelectField, TextField, DateTimePickerField } from "../../../common/forms";
import OrderProductsDataGridField from "../../orders/_common/fields/OrderProductsDataGridField/OrderProductsDataGridField";
import { getCodeOptions } from "../ordersIncidents.utils";

export function formFields(t) {
  return [
    {
      size: 4,
      field: (
        <SelectField
          id="code"
          label={t("ordersIncidents.OrdersIncidentsForm.form.code")}
          required
          options={getCodeOptions(t)}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField id="document" type="text" label={t("ordersIncidents.OrdersIncidentsForm.form.document")} required />
      ),
    },
    {
      size: 4,
      field: (
        <DateTimePickerField
          id="deliveryTime"
          label={t("ordersIncidents.OrdersIncidentsForm.form.deliveryTime")}
          required
        />
      ),
    },
    {
      size: 12,
      field: <OrderProductsDataGridField id="products" allowBatching checkRows />,
    },
  ];
}

export function formSchema(t) {
  return Yup.object().shape({
    code: Yup.string().required(),
    document: Yup.string().required(t('ordersIncidents.OrdersIncidentsForm.form.deliveryTimeRequired')),
    deliveryTime: Yup.string().required(),
    products: Yup.array().ensure().requiredArrayWithCheck(t('ordersIncidents.OrdersIncidentsForm.form.productRequired')),
  });
}

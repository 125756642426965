import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { getProductTaxChanges, processHierarchyToSave, processWeightMeasuresToSave, uploadDocuments, uploadMedia } from "./_auxiliars";
import { asyncForEach } from "../../../common/utils";

const productUpdateCall = async ({ productGet }, dispatch, state, product, ogProduct) => {
  const promoterCode = state.core.promoterDetails?.details?.code;
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");

  let rh = requestHelper(dispatch, "PRODUCT_UPDATE");
  try {
    let nMedia = await uploadMedia(fileStorageConfigs, promoterCode, product.id, product.media);
    let nDocs = await uploadDocuments(fileStorageConfigs, promoterCode, product.id, product.documents);

    let result = await call({
      type: "PRODUCT",
      subType: "UPDATE",
      request: {
        product: {
          ...product,
          productId: product.id,
          logo: undefined,
          media: nMedia,
          documents: nDocs,
          weightMeasure: processWeightMeasuresToSave(product?.weightMeasure),
          hierarchy: processHierarchyToSave(product?.hierarchy, nMedia?.[0]),
          taxes: undefined,
          price: undefined,
        },
      },
    });

    const taxesChanges = getProductTaxChanges(product.id, ogProduct?.taxes || [], product?.taxes || []);
    if (taxesChanges.hasChanges) {
      if (taxesChanges.taxes) {
        await call({
          type: "PRODUCT",
          subType: "CREATE_TAX",
          request: {
            taxes: [{
              productId: product.id,
              taxes: taxesChanges.taxes,
            }]
          },
        });
      }
      if (taxesChanges.toDelete) {
        asyncForEach(taxesChanges.toDelete, async (request) => {
          await call({
            type: "PRODUCT",
            subType: "REMOVE_TAX",
            request,
          });
        });
      }
    }

    if (result?.productNotUpdated) {
      if (taxesChanges.hasChanges) {
        dispatch(setSuccess("products", "PRODUCT_UPDATE"));
      }
      dispatch(productGet(product.id));
    }
    else {
      if (result?.productAutoValidated) {
        setTimeout(() => {
          dispatch(setRedirect("/products/" + product.id));
          dispatch(setSuccess("products", "PRODUCT_UPDATE"));
          dispatch(productGet(product.id));
        }, 300);
      } else {
        setTimeout(() => {
          dispatch(setRedirect("/products/validation"));
          dispatch(setSuccess("products", "PRODUCT_UPDATE_PENDING"));
        }, 300);
      }
    }

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productUpdateCall;

import { processImageUrl } from "../../../api/s3";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { asyncForEach } from "../../../common/utils";

const productCatalogCall = async (
  { clearOrderProductList, setOrderProductListFilter, appendToOrderProductList, setOrderProductListTotalRows },
  dispatch,
  state,
  { filter, orderBy, clear }
) => {
  const currentOrder = state.orders.currentOrder;
  let deliveryDay = undefined;

  if (getPromoterConfig(state, "cutOff", "details") && currentOrder?.cutOff) {
    let cutOffObj = getPromoterConfig(state, "cutOff", "details")?.find((item) => item.type === currentOrder.cutOff);
    deliveryDay = cutOffObj?.deliveryDay;
  }
  let rh = requestHelper(dispatch, "LIST_PRODUCTS");
  if (clear) {
    dispatch(clearOrderProductList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setOrderProductListFilter(filter));
  try {
    let list = await call({
      type: "PRODUCT",
      subType: "CATALOG",
      request: {
        ...filter,
        orderBy,
        location: currentOrder?.locationId, 
        deliveryDay,
        sortField: (filter.sortField || "").toUpperCase(),
        available: Boolean((getPromoterConfig(state, "product", "listProductFields") || []).indexOf("available") > -1)
      },
    });
    let products = [];

    await asyncForEach(list.products, async (product) => {
      products.push({
        ...product,
        image: await processImageUrl(getPromoterConfig(state, "admin", "fileStorage"),  product.image),
        thumbnail: await processImageUrl(getPromoterConfig(state, "admin", "fileStorage"),  product.thumbnail),
      });
    });

    dispatch(appendToOrderProductList(products));
    dispatch(setOrderProductListTotalRows(list.rows));
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productCatalogCall;

export const styles = () => ({
  root: {
    minWidth: 240,
  },
  nomargin: {
    margin: 0,
    lineHeight: 1.4,
  },
  datetime: {
    margin: 0,
    lineHeight: 1.4,
    fontSize: 12,
  },
});

import {requestHelper} from "../../../app/coreSlice";
import {call} from "../../../app/websocket/_sliceCalls/websocket";

const auditGetAuditI18nCall = async ({ setAuditI18nDetails }, dispatch, { id }) => {
  let rh = requestHelper(dispatch, "AUDIT_GET_AUDIT_I18N");
  try {
    let result = await call({
      type: "AUDIT",
      subType: "GET_AUDIT_I18N",
      request: {
        id
      },
    });

    if (result && result.audit) {
      dispatch(setAuditI18nDetails(result.audit));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default auditGetAuditI18nCall;

import React from "react";
import moment from "moment";
import { withStyles } from "tss-react/mui";
import { Typography, Stack } from "@mui/material";

import { styles } from "./DateTime.styles";

function DateTime({ classes, date, dateFormat, timeFormat, showSeconds, inline, textColor }) {
  const dateObj = moment.utc(date).local();
  if (!dateFormat) {
    dateFormat = "yyyy-MM-DD";
  }
  if (!timeFormat) {
    timeFormat = showSeconds ? "HH:mm:ss" : "HH:mm";
  }

  return (
    <Stack className={classes.root} direction={inline ? "row" : "column"} spacing={inline ? 1 : 0}>
      <Typography className={classes.date} color={textColor}>
        {dateObj.format(dateFormat)}
      </Typography>
      <Typography className={classes.date} color={textColor}>
        {dateObj.format(timeFormat)}
      </Typography>
    </Stack>
  );
}

export default withStyles(DateTime, styles);
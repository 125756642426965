import React from "react";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Grid, List, ListItem} from "@mui/material";

import {styles} from "./EntityCommentsModal.styles";
import CustomModal from "../../../../../common/displays/CustomModal/CustomModal";
import CommentBox from "../../../../../common/displays/CommentBox/CommentBox";

function EntityCommentsModal({ classes, open, setOpen, comments }) {
  const { t } = useTranslation();

  return (
    <CustomModal open={open} setOpen={setOpen} size="medium" height={500} title={t("entities.EntityDetails.comments.modalTitle")}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <List>
            {(comments || []).map((item) => {
              return (
                <ListItem key={`comment_${item?.id}`} className={classes.comment}>
                  <CommentBox comment={item} />
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </CustomModal>
  );
}

export default withStyles(EntityCommentsModal, styles);

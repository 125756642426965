import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const alertResolveAllCall = async ({ alertList }, dispatch, state) => {
  const filter = state.alerts.alertsListFilter;
  let rh = requestHelper(dispatch, "ALERT_RESOLVE_ALL");
  try {
    await call({
      type: "ALERT",
      subType: "RESOLVE_ALL",
    });
    dispatch(setSuccess("alerts", "ALERT_RESOLVE_ALL"));
    dispatch(alertList(filter, true));
  } catch (ex) {
    rh.error("alerts", ex);
  }
  rh.close();
};

export default alertResolveAllCall;

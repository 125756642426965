import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./EntitiesDataGrid.styles";
import { listColumns } from "./EntitiesDataGrid.columns";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";
import { filterFields, filterSchema } from "./EntitiesDataGrid.filter";
import { entitiesDataGridDefaultFilter } from "../../_common/entitiesFilters";
import { usePromoter, useUser } from "../../../../common/hooks";

function EntitiesDataGrid({ classes, data, totalRows, filter, onEntitySelected, onFilterChange, loading }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const user = useUser();

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0 && !loading) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };
      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={listColumns(t, classes, promoter, user)}
        rows={data || []}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMoreHandler}
        onRowSelected={onEntitySelected}
        total={totalRows}
        //exportCsvFields={csvFields(t)}
        //exportXlsxFields={csvFields(t)}
        topActions={<FilterForm
          style={{ marginBottom: 8 }}
          loading={loading}
          storageKey="EntitiesDataGrid"
          onStorageLoad={onFilterHandler}
          slim
          fields={filterFields(promoter, user)}
          schema={filterSchema()}
          onFilterChange={onFilterHandler}
          filter={filter}
          defaultFilter={entitiesDataGridDefaultFilter}
        />}
      />
    </div>
  );
}

export default withStyles(EntitiesDataGrid, styles)

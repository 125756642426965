export const styles = (theme) => ({
  root: {},
  input: {
    marginBottom: 0,
  },
  error: {
    minHeight: 13,
    marginTop: 2,
    "& .MuiTypography-caption": {
      display: "block",
      lineHeight: "1.1 !important",
      fontSize: 11,
    },
  },
  arraySpace: {
    width: 20,
    height: 2,
  },
  separator: {
    backgroundColor: "#ddd",
    height: 2,
    width: 600,
  },
});

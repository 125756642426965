import React from "react";
import { Typography, Grid, Chip, IconButton, Paper } from "@mui/material";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";
import {useNavigate, usePromoter, useUser} from "../../../../common/hooks";
import { styles } from "./EntityUnits.styles";
import AddIcon from "@mui/icons-material/Add";
import Permission from "../../../../app/components/Permission";
import { useParams } from "react-router-dom";

function EntityUnits({ classes, entity, disabled }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useUser();
  const promoter = usePromoter()

  return (
    <Paper elevation={0} className={classes.root} id="entityUnits">
      <Grid container>
        <Grid item xs={12}>
          <LabelValue
            label={t("entities.Entities.details.units")}
            value={
              <Grid container spacing={1}>
                {(entity?.businessUnits || []).map((unit) => (
                  <Grid item key={"bunit_" + unit.id}>
                    <Chip
                      label={unit.name}
                      variant={id === unit.id ? "default" : "outlined"}
                      onClick={!disabled ? () => navigate(`/entities/${unit.id}`) : undefined}
                    />
                  </Grid>
                ))}
                {!disabled && entity?.status !== "PND" && entity?.type === "S" &&
                  <Permission code="entities.actions.businessUnit.create">
                    <Grid item>
                      <IconButton size="small" onClick={() => navigate(`/entities/${entity.entityId}/business-unit/add`)}>
                        <AddIcon />
                      </IconButton>
                    </Grid>
                  </Permission>
                }
              </Grid>
            }
          />
        </Grid>
        <Grid item xs={7}>
          <Grid container>
            <Grid item xs={4}>
              {entity?.cep && (
                <LabelValue
                  label={t("entities.Entities.details.cep")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {entity?.cep}
                    </Typography>
                  }
                />
              )}
              {entity?.operationType && (
                <LabelValue
                  label={t("entities.Entities.details.operationType")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {t("common.Enums.operationType." + entity.operationType)}
                    </Typography>
                  }
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {entity?.gln && (
                <LabelValue
                  label={t("entities.Entities.details.gln")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {entity?.gln}
                    </Typography>
                  }
                />
              )}

              {entity?.cmpAccumulation && (
                <LabelValue
                  label={t("entities.Entities.details.cmpAccumulation")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {t("common.Enums.entityPermission." + entity.cmpAccumulation)}
                    </Typography>
                  }
                />
              )}
            </Grid>
            <Grid item xs={4}>
              { promoter?.getConfig("entity", "requiresInternalCode") && entity?.promoterInternalCode && user?.isPromoter && (
                <LabelValue
                  label={t("entities.Entities.details.promoterInternalCode")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      { entity.promoterInternalCode }
                    </Typography>
                  }
                />
              )}
              {entity?.trafficType && (
                <LabelValue
                  label={t("entities.Entities.details.trafficType")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {t("common.Enums.trafficType." + entity.trafficType)}
                    </Typography>
                  }
                />
              )}
              {entity?.cpAccumulation && (
                <LabelValue
                  label={t("entities.Entities.details.cpAccumulation")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {t("common.Enums.entityPermission." + entity.cpAccumulation)}
                    </Typography>
                  }
                />
              )}
            </Grid>
          </Grid>

          {(user?.entity?.type !== "P" && user?.entity?.id === entity.entityId) && (
            <Grid container>
              <Grid item xs={4}>
                <LabelValue
                  label={t("entities.Entities.details.feeQuantity")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {entity?.fee?.quantity !== undefined ? entity?.fee?.quantity : "N/D"}
                      {entity?.fee?.valueType !== undefined ? (entity?.fee?.valueType === "P" ? " %" : " €") : ""}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <LabelValue
                  label={t("entities.Entities.details.feeFrequencyType")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {entity?.fee?.frequencyType !== undefined
                        ? t("common.Enums.frequencyType." + entity?.fee?.frequencyType)
                        : "N/D"}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <LabelValue
                  label={t("entities.Entities.details.feeType")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {entity?.fee?.type !== undefined ? t("common.Enums.feeType." + entity?.fee?.type) : "N/D"}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(EntityUnits, styles)

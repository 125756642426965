import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper, setRedirect } from "../../../app/coreSlice";
import { asyncForEach } from "../../../common/utils";
import { getDocumentSignedUrl, processImageUrl } from "../../../api/s3";

const productPendingProductGetCall = async ({ setPendingProductDetails }, dispatch, state, { productId, taskId, processId }) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");

  let rh = requestHelper(dispatch, "PRODUCT_PENDING_PRODUCT_GET");
  try {
    const result = await call({
      type: "PRODUCT",
      subType: "PENDING_PRODUCT_GET",
      request: {
        productId,
        taskId,
        processId
      },
    });

    dispatch(
      setPendingProductDetails({

        ...result,
        product: {
          ...result.product,
          media: await processMediaUrls(fileStorageConfigs, result.product?.media),
          documents: await processDocumentsUrls(fileStorageConfigs, result.product?.documents),
          hierarchy: await processHierarchyUrls(fileStorageConfigs, result.product?.hierarchy),
        },
        newProduct: {
          ...result.newProduct,
          media: await processMediaUrls(fileStorageConfigs, result.newProduct?.media),
          documents: await processDocumentsUrls(fileStorageConfigs, result.newProduct?.documents),
          hierarchy: await processHierarchyUrls(fileStorageConfigs, result.newProduct?.hierarchy),
          baseInfo: {
            ...result.newProduct.baseInfo,
            addressId: result.product?.addressId,
          },
          // documents: result.product?.documents,
          // hierarchy: result.product?.hierarchy,
          price: result.product?.price,
          updatedBy: result.product?.updatedBy,
          updatedTime: result.product?.updatedTime,
        },
      })
    );
  } catch (ex) {
    dispatch(setRedirect("/products/validation"));
    rh.error("products", ex);
  }
  rh.close();
};

export default productPendingProductGetCall;

const processMediaUrls = async (fileStorageConfigs, media) => {
  let nMedia = [];
  await asyncForEach(media, async ({ reference, thumbnail, type }) => {
    nMedia.push({
      reference: await processImageUrl(fileStorageConfigs, reference),
      referenceOg: reference,
      thumbnail: await processImageUrl(fileStorageConfigs, thumbnail),
      thumbnailOg: thumbnail,
      type,
    });
  });
  return nMedia;
};

const processDocumentsUrls = async (fileStorageConfigs, documents) => {
  let nDocuments = [];
  await asyncForEach(documents, async (doc) => {
    nDocuments.push({
      ...doc,
      url: await processDocumentUrl(fileStorageConfigs, doc.url),
      urlOg: doc.url
    });
  });
  return nDocuments;
};

export const processDocumentUrl = async (fileStorageConfigs, url) => {
  const { privateBucket, provider } = fileStorageConfigs;
  if (url && url.indexOf(privateBucket) > 0) {
    return await getDocumentSignedUrl(
      provider,
      privateBucket,
      url.substr(url.indexOf("/", url.indexOf(privateBucket)) + 1, url.length)
    );
  }
  return url;
};

const processHierarchyUrls = async (fileStorageConfigs, hierarchy) => {
  let nHierarchy = [];
  await asyncForEach(hierarchy, async ({ thumbnail, ...node }) => {
    nHierarchy.push({
      ...node,
      thumbnail: await processImageUrl(fileStorageConfigs, thumbnail)
    });
  });
  return nHierarchy;
};
export const styles = (theme) => ({
  root: {
    border: "1px solid #DDD",
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
  },
  batchNumber: {
    margin: 0,
    fontWeight: "bold",
    fontSize: 12
  },
  date: {
    margin: 0,
    fontSize: 12
  }
});
import * as Yup from "yup";
import { NumberField, SelectField, TextField } from "../../../common/forms";
import React from "react";
import { MeasureField } from "../../../common/components/formFields";
import { getAddressIdOptions, getContactIdOptions, getParentIdOptions, getPlaceTypeOptions, getTemperatureOptions } from "./PlaceForm.options";

export function formSchema({ t }) {
  return Yup.object().shape({
    name: Yup.string().required(t("places.PlaceForm.form.nameRequired")),
    type: Yup.string().required(t("places.PlaceForm.form.typeRequired")),
    gln: Yup.string().required(t("places.PlaceForm.form.glnRequired")),
    parentId: Yup.string().when("type", {
      is: "B",
      then: (schema) => schema,
      otherwise: (schema) => schema.required(t("places.PlaceForm.form.parentIdRequired")),
    }),
  });
}

export function formFields({ t, values, loading, promoter, contacts, addresses, parentPlaces, onTypeChange, onAddressChange }) {

  return [
    {
      size: 6,
      field: <TextField
        id="name"
        required
        label={t("places.PlaceForm.form.name")}
      />,
    },
    {
      size: 4,
      field:
        <TextField
          id="gln"
          label={t("places.PlaceForm.form.gln")}
          required
          maxLength={13}
        />,
    },
    {
      size: 4,
      field: <SelectField
        id="type"
        options={getPlaceTypeOptions(t, promoter)}
        required
        onChange={onTypeChange}
        label={t("places.PlaceForm.form.type")}
      />,
    },
    {
      size: 4,
      field: <SelectField
        id="parentId"
        options={getParentIdOptions(t, parentPlaces)}
        required={values?.type !== "B"}
        label={t("places.PlaceForm.form.parentId")}
      />,
      hidden: values?.type === "B"
    },
    {
      size: 12
    },

    {
      size: 4,
      field: <SelectField
        id="addressId"
        label={t("places.PlaceForm.form.addressId")}
        options={getAddressIdOptions(addresses)}
        onChange={onAddressChange}
        disabled={loading}
      />,
      hidden: values?.type !== "B"
    },
    {
      size: 4,
      field: <SelectField
        id="contactId"
        label={t("places.PlaceForm.form.contactId")}
        options={getContactIdOptions(contacts)}
        disabled={loading}
      />,
      hidden: values?.type !== "B"
    },
    {
      size: 12
    },
    {
      size: 4,
      field: <NumberField id="latitude" label={t("places.PlaceForm.form.latitude")} maxLength={11} />,
    },
    {
      size: 4,
      field: <NumberField id="longitude" label={t("places.PlaceForm.form.longitude")} maxLength={11} />,
    },
    {
      size: 12
    },
    {
      size: 4,
      field: <MeasureField
        id="attributes.height"
        label={t("places.PlaceForm.form.height")}
        fixedCategory="COMP"
      />,
    },
    {
      size: 4,
      field: <MeasureField
        id="attributes.width"
        label={t("places.PlaceForm.form.width")}
        fixedCategory="COMP"
      />,
    },
    {
      size: 4,
      field: <MeasureField
        id="attributes.depth"
        label={t("places.PlaceForm.form.depth")}
        fixedCategory="COMP"
      />,
    },
    {
      size: 12
    },
    {
      size: 4,
      field: <SelectField
        id="attributes.temperature"
        label={t("places.PlaceForm.form.temperature")}
        options={getTemperatureOptions(t, promoter)}
      />,
      hidden: values?.type !== "R"
    }
  ];
}


import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import DeleteButton from "../../../../common/components/dialogs/DeleteButton";
import Permission from "../../../../app/components/Permission";

export default function LocationsDataGridActions({ row, onDelete, onEdit }) {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1} justifyContent="flex-end">
      <Permission code="promoter.actions.location.delete">
        <Grid item>
          <DeleteButton
            labels={{
              button: t("promoter.LocationsList.columns.deleteBtn"),
              title: t("promoter.LocationsList.deleteConfirm.title"),
              description: t("promoter.LocationsList.deleteConfirm.description"),
              ok: t("promoter.LocationsList.deleteConfirm.ok"),
              cancel: t("promoter.LocationsList.deleteConfirm.cancel"),
            }}
            onConfirm={() => {
              onDelete && onDelete(row);
            }}
            disabled={!row.canDelete}
          />
        </Grid>
      </Permission>
      <Permission code="promoter.actions.location.update">
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => onEdit && onEdit(row)}>
            {t("promoter.LocationsList.columns.editBtn")}
          </Button>
        </Grid>
      </Permission>
    </Grid>
  );
}

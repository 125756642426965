export const styles = (theme) => ({
  root: {
    height: 400,
    padding: 0,
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth: "thin",
    marginRight: -10,
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "21px",
      boxShadow: "inset 0 0 21px rgba(0,0,0,.2)",
    },
    "& .MuiListItem-root": {
      padding: 0,
      paddingRight: 5
    },
  },
  loader: {
    padding: "10px !important",
    display: "block",
    textAlign: "center",
  },
});

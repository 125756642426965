import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {CustomDataGrid} from "../../../../common/displays/CustomDataGrid";
import {listColumns} from "./ProductDataGrid.columns";
import {styles} from "./ProductDataGrid.styles";
import {csvFields} from "./ProductDataGrid.export";
import {filterFields, filterSchema} from "./ProductDataGrid.filter";
import {usePromoter, useUser} from "../../../../common/hooks";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";
import {queriesReportProductDefaultFilter} from "../../_common/businessFilters";

function ProductDataGrid({
  classes,
  data,
  filter,
  totalRows,
  onRowSelected,
  onFilterChange,
  onLoadMore,
}) {
  const { t } = useTranslation();
  const user = useUser();
  const promoter = usePromoter();

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          columns={listColumns(t, promoter, classes)}
          rows={data}
          onFilter={onFilterHandler}
          onLoadMore={onLoadMore}
          onRowSelected={onRowSelected}
          total={totalRows}
          exportCsvFields={csvFields(t , promoter)}
          exportXlsxFields={csvFields(t, promoter)}
          topActions={
            <FilterForm
              style={{ marginBottom: 8 }}
              storageKey="ProductDataGrid"
              onStorageLoad={onFilterHandler}
              slim
              fields={filterFields(user)}
              schema={filterSchema()}
              onFilterChange={onFilterHandler}
              filter={filter}
              defaultFilter={queriesReportProductDefaultFilter}
            />
          }
        />
      )}
    </div>
  );
}

export default withStyles(ProductDataGrid, styles)




import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const auditGetAuditLoginsCall = async ({ setAuditLoginsDetails }, dispatch, { sessionId, action }) => {
  let rh = requestHelper(dispatch, "GET_AUDIT_LOGINS");
  try {
    let result = await call({
      type: "AUDIT",
      subType: "GET_AUDIT_LOGINS",
      request: {
        sessionId,
        action
      },
    });

    if (result && result.audit) {
      dispatch(setAuditLoginsDetails(result.audit));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default auditGetAuditLoginsCall;

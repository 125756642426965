import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper } from "@mui/material";

import { styles } from "./AuditLoginsDetails.styles";

import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { auditGetAuditLogins, selectAuditLoginsDetails } from "../businessSlice";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import LabelValue from "../../../common/displays/LabelValue/LabelValue";
import { dateTimeFormatWithMilliSeconds } from "../../../common/utils/formats";

function AuditLoginsDetails({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sessionId, action } = useParams();
  const details = useSelector(selectAuditLoginsDetails);

  useFirstLoad(() => {
    dispatch(auditGetAuditLogins({ sessionId, action }))
  });

  if (!details) {
    return null;
  }

  let userDetails = details?.userDetails ? JSON.stringify(details?.userDetails, null, 2) : "{}";
  let sessionAttr = details?.sessionAttr ? JSON.stringify(details?.sessionAttr, null, 2) : "{}";

  return (
    <Page
      permission="business.actions.audit.get"
      header={
        <PageTitle
          title={t("business.audit.login.title") + " - " + t("business.audit.filter.websocket_" + details.action)}
          onBack={() => navigate('/activity/audits/logins')}
        />
      }>
      <Grid container direction="column" spacing={2} style={{ maxWidth: "100%" }}>
        <Grid item>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.audit.login.details_action")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {t("business.audit.filter.websocket_" + details.action)}
                    </Typography>
                  }
                />

                <LabelValue
                  label={t("business.audit.login.details_entity")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.entity}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.audit.login.details_username")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.username}
                    </Typography>
                  }
                />


              </Grid>
              <Grid item xs={4}>
                <LabelValue
                  label={t("business.audit.login.details_datetime")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {dateTimeFormatWithMilliSeconds(details.datetime)}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.audit.login.details_ipAddress")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.ipAddress}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.audit.login.details_sessionId")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {details.sessionId}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <LabelValue
                  label={t("business.audit.login.details_userDetails")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      <pre className={classes.json}>
                        {userDetails}
                      </pre>
                    </Typography>
                  }
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <LabelValue
                  label={t("business.audit.login.details_sessionAttr")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      <pre className={classes.json}>
                        {sessionAttr}
                      </pre>
                    </Typography>
                  }
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

export default withStyles(AuditLoginsDetails, styles);
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { styles } from "./PromoterDashboard.styles";

import PieChart from "./PieChart/PieChart";
import TopProductsDataGrid from "./TopProductsDataGrid/TopProductsDataGrid";
import DashboardBlock from "../../../../common/components/displays/DashboardBlock";

import { homeScreen, selectDataHome } from "../../homeSlice";
import { useFirstLoad } from "../../../../common/hooks";

function PromoterDashboard({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataHome = useSelector(selectDataHome);

  useFirstLoad(() => {
    dispatch(homeScreen());
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} className={classes.space}>
        <DashboardBlock
          icon={"waiting"}
          label={t("home.screen.qtAwaiting.label")}
          value={dataHome?.qtAwaiting !== undefined ? dataHome?.qtAwaiting : "N/D"}
          valueLabel={t("home.screen.qtAwaiting.valueLabel")}
        />
      </Grid>
      <Grid item xs={4} className={classes.space}>
        <DashboardBlock
          icon={"transit"}
          label={t("home.screen.qtTransit.label")}
          value={dataHome?.qtTransit !== undefined ? dataHome?.qtTransit : "N/D"}
          valueLabel={t("home.screen.qtTransit.valueLabel")}
        />
      </Grid>
      <Grid item xs={4} className={classes.space}>
        <DashboardBlock
          icon={"complete"}
          label={t("home.screen.averageWeekOrder.label")}
          value={dataHome?.averageWeekOrder !== undefined ? parseFloat(dataHome?.averageWeekOrder).toFixed(1) : "N/D"}
          valueLabel={t("home.screen.averageWeekOrder.valueLabel")}
        />
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.space}>
              <DashboardBlock
                label={t("home.screen.cxTenseFlow.label")}
                value={dataHome?.cxTenseFlow !== undefined ? dataHome?.cxTenseFlow : "N/D"}
                valueLabel={t("home.screen.cxTenseFlow.valueLabel")}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <DashboardBlock
              label={t("home.screen.cxCrossDock.label")}
              value={dataHome?.cxCrossDock !== undefined ? dataHome?.cxCrossDock : "N/D"}
              valueLabel={t("home.screen.cxCrossDock.valueLabel")}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <PieChart
          label={t("home.screen.topSellers.label")}
          labels={dataHome?.chart?.labels !== undefined ? dataHome?.chart?.labels : []}
          series={dataHome?.chart?.series !== undefined ? dataHome?.chart?.series : []}
        />
      </Grid>

      <Grid item xs={4}>
        <TopProductsDataGrid data={dataHome?.summaryTable} title={t("home.screen.topProducts.label")} />
      </Grid>
    </Grid>
  );
}

export default withStyles(PromoterDashboard, styles);

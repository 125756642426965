export const styles = theme => ({
  root: {
    "& .CdgRow": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: '#fdfdfd'
      }
    }
  },
  id: {
    margin: 0,
  },
  orderBy: {
    margin: 0,
    color: "#aaa"
  }, addressTitle: {
    margin: 0,
    color: "black",
    display: "inline-block",
  }, address: {
    margin: 0,
    color: "#aaa",
    display: "inline-block",
  },
});
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import {Typography} from "@mui/material";

export function listColumns(t, classes, promoter, user) {
  const columnsToShow = promoter?.getConfig("product", "listProductColumns") || [];
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];

  let columns = [
    {
      field: "description",
      headerName: t("products.ProductList.columns.description"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        const showPromoterDescription = Boolean(enabledProductFields.includes("promoterDescription") && user?.entity?.type === "P" && v.promoterDescription);
        return <ProductSnip image={v.thumbnail || v.image} description={showPromoterDescription ? v.promoterDescription : v.description} gtin={v.gtin} promoterInternalCode={v.promoterInternalCode} />;
      },
    },
  ];

  if (columnsToShow.includes("gln")) {
    columns.push({
      field: "gln",
      headerName: t("products.ProductList.columns.gln"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => v.gln.includes(v.ownerTaxNumber) > 0 ? "-" : v.gln,
    });
  }

  if (columnsToShow.includes("owner")) {
    columns.push({
      field: "ownerName",
      headerName: t("products.ProductList.columns.ownerName"),
      align: "left",
      headerAlign: "left",
    });
  }
  if (columnsToShow.includes("brand")) {
    columns.push({
      field: "brand",
      headerName: t("products.ProductList.columns.brand"),
      align: "left",
      headerAlign: "left",
    });
  }
  if (columnsToShow.includes("available")) {
    columns.push({
      field: "available",
      headerName: t("products.ProductList.columns.available"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        if (v.available && v.available === "S") {
          return <Typography className={classes.availableS}>{t("common.Enums.productAvailable." + v?.available)}</Typography>
        }
        if (v.available && v.available === "O") {
          return <Typography className={classes.availableO}>{t("common.Enums.productAvailable." + v?.available)}</Typography>
        }
        if (v.available && v.available === "N") {
          return <Typography className={classes.availableN}>{t("common.Enums.productAvailable." + v?.available)}</Typography>
        } else  return "-"
      }
    });
  }
  return columns;
}

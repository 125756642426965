import ContactCardSlim from "../_common/ContactCardSlim/ContactCardSlim";
import i18next from "i18next";

export const getContactsOptions = (contacts, header) => {
  return (contacts || []).filter(item => item.roleType !== "A").map((contact) => {
    return {
      value: contact.id,
      text: <ContactCardSlim contact={contact} />,
      disabled: Boolean(contact.id === header)
    };
  });
};

export const getFamiliesOptions = (families) => {
  let nFamilies = families.filter(item => !item.parent)
  return (nFamilies || []).map((item) => {
    let altLang = Object.keys(item.description).filter((k) => k !== i18next.language)[0];
    return {
      value: item.id,
      text: item.description[i18next.language] || item.description[altLang],
    };
  });
};
import {call} from "../../../app/websocket/_sliceCalls/websocket";
import {requestHelper, setRedirect, setSuccess} from "../../../app/coreSlice";

const promoterCreateTimerCall = async ({ promoterListTimer }, dispatch, state,{timer}) => {
  const filter = state.promoter.timerFilter;
  const promoterCode = filter.promoterCode ? filter.promoterCode : state.core.promoterDetails?.details?.code;
  let rh = requestHelper(dispatch, "PROMOTER_CREATE_TIMER");
  try {
    await call({
      type: "PROMOTER",
      subType: "CREATE_TIMER",
      request: {
        ...timer,
        promoterCode: promoterCode,
      },
    });
    setTimeout(() => {
      dispatch(setRedirect("/promoter/timer"));
      dispatch(setSuccess("promoter", "PROMOTER_CREATE_TIMER"));
      dispatch(promoterListTimer(filter, true));
    }, 300);
  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default promoterCreateTimerCall;

import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getImageUrlBase64, processDocumentUrl } from "../../../api/s3";
import { asyncForEach, getEntityImageUrl, getImageInfoFromUrl } from "../../../common/utils";

export const entityGetCall = async ({ setEntityDetails }, dispatch, state, id) => {
  let rh = requestHelper(dispatch, "GET_ENTITY");
  try {
    let details = await call({
      type: "ENTITY",
      subType: "GET",
      request: {
        id,
      },
    });
    let d = await fromEntity(state, details);
    dispatch(setEntityDetails(d));
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityGetCall;

const fromEntity = async (
  storeState,
  {
    entityId,
    taxNumber,
    name,
    type,
    website,
    fax,
    phone,
    email,
    taxAddresses: { address, county, locale, postalCode, country, state },
    contacts,
    operationalAddresses,
    config,
    businessUnits,
    confirmed,
    status,
    businessUnitId,
    externalAccess,
    documents,
    ...rest
  }
) => {
  const fileStorageConfigs = getPromoterConfig(storeState, "admin", "fileStorage");
  const promoterCode = storeState.core.promoterDetails?.details?.code;
  let { trafficType, operationType, gln, cep, fee, cmpAccumulation, cpAccumulation, businessUnitDesc, promoterInternalCode } = config || {};
  let contactList = [];
  if (contacts && contacts.length > 0) {
    for (let i = 0; i < contacts.length; i++) {
      let c = await fromUser(fileStorageConfigs, promoterCode, contacts[i]);
      contactList.push(c);
    }
  }
  let logoFilePath = getEntityImageUrl(promoterCode, entityId);
  let logo = getImageInfoFromUrl(logoFilePath);
  const { provider, privateBucket } = fileStorageConfigs;

  let processedDocuments = [];
  await asyncForEach(documents, async (doc) => {
    processedDocuments.push({
      ...doc,
      signedUrl: await processDocumentUrl(fileStorageConfigs, doc.url)
    })
  });
  try {
    logo.url = await getImageUrlBase64(
      provider,
      privateBucket,
      logoFilePath
    );
  } catch (ex) {
    logo.url = "";
  }
  return {
    entityId,
    taxNumber,
    name,
    type,
    website,
    fax,
    phone,
    email,
    logo,
    taxAddress: {
      address,
      county,
      locale,
      postalCode,
      country,
      state,
    },
    contacts: contactList,
    addresses: operationalAddresses,
    trafficType,
    operationType,
    gln,
    cep,
    businessUnits,
    fee,
    cmpAccumulation,
    cpAccumulation,
    confirmed,
    status,
    businessUnitId,
    businessUnitDesc,
    externalAccess,
    documents: processedDocuments,
    promoterInternalCode,
    ...rest,
  };
};

const fromUser = async (filestorageConfigs, promoterCode, contact) => {
  const filePath = "common/" + process.env.REACT_APP_ENV + "/user/avatar/" + contact?.userId;
  let logo = "";
  try {
    logo = await getImageUrlBase64(filestorageConfigs?.provider, filestorageConfigs?.privateBucket, filePath);
  } catch (ex) {
    logo = "";
  }
  return {
    logo,
    ...contact,
  };
};

export const styles = (theme) => ({
  root: {
    paddingBottom: 5,
  },
  datepicker: {
    width: "100%",
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 32px 10px 10px",
    },
  },
  quantity: {
    "& .MuiFormControl-root": {
      width: "100%",
      maxWidth: "100%",
      "& .MuiInputBase-root": {
        height: 42,
      },
    },
  },
  deleteButton: {
    marginTop: 25,
  },
});

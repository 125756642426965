import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { asyncForEach } from "../../../common/utils";
import { processImageUrl } from "../../../api/s3";

const homeScreenCall = async ({ setScreenData }, dispatch, state) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "HOME_SCREEN");

  try {
    let data = await call({
      type: "HOME",
      subType: "SCREEN",
      request: {},
    });

    if (data?.home) {
      let seriesValues = [];
      let labelsValues = [];
      if (data.home.pieChart && data.home.pieChart.length > 0) {
        for (let i = 0; i < data.home.pieChart.length; i++) {
          labelsValues.push(data.home.pieChart[i]?.name || "");
          seriesValues.push(data.home.pieChart[i]?.amount || "");
        }
      }

      let products = [];
      if (data?.home?.summaryTable?.length > 0) {
        await asyncForEach(data.home.summaryTable, async (product) => {
          products.push({
            ...product,
            image: await processImageUrl(fileStorageConfigs, product.image),
            thumbnail: await processImageUrl(fileStorageConfigs, product.thumbnail),
          });
        });
      }

      let screenData = {
        ...data.home,
        chart: {
          series: seriesValues,
          labels: labelsValues,
        },
        summaryTable: products
      };
      dispatch(setScreenData(screenData));
    }
  } catch (ex) {
    rh.error("home", ex);
  }
  rh.close();
};

export default homeScreenCall;

import * as Yup from "yup";
import { TextField } from "../../../../common/forms";

export function formSchema({ t, accept }) {
  return Yup.object().shape({
    comment: accept ?
      Yup.string() :
      Yup.string().required(t("productsPrices.PriceValidationCommentModal.form.commentRequired")),
  });
}

export function formFields({ t, accept }) {
  return [
    {
      size: 12,
      field: <TextField
        id="comment"
        rows={3}
        required={!accept}
        label={t("productsPrices.PriceValidationCommentModal.form.comment")}
      />,
    },
  ];
}


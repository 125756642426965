import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Paper } from "@mui/material";

import { styles } from "./LocationsForm.styles";
import { formFields, formSchema } from "./LocationsForm.schema";

import { Form, FormFields } from "../../../common/forms"
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { useFirstLoad, useNavigate, usePromoter } from "../../../common/hooks";
import { useMemo } from "react";
import { getStateOptions } from "../../../common/forms/options";
import { useParams } from "react-router-dom";
import { promoterCreateLocation, promoterListLocations, promoterUpdateLocation, selectLocationsList } from "../promoterSlice";
import Page from "../../../common/displays/Page/Page";

function LocationsForm({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const promoter = usePromoter();
  const { id } = useParams();
  const locations = useSelector(selectLocationsList);

  useFirstLoad(() => {
    if (!locations?.length) {
      dispatch(promoterListLocations());
    }
  });

  const formOptions = useMemo(() => {
    return {
      states: getStateOptions(promoter, "PT")
    }
  }, [promoter]);

  const onSubmit = async (values) => {
    if (id) {
      dispatch(promoterUpdateLocation({ id, ...values }));
    }
    else {
      dispatch(promoterCreateLocation(values));
    }
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        schema={formSchema(t)}
        initialValues={id ? locations.find(item => item.id === id) || {} : {}}
        render={({ submitting, values, pristine }) => {
          return (
            <Page
              permission="promoter.actions.location.list"
              header={<PageTitle
                title={id ? `${t("promoter.LocationsForm.header.updateTitle")} ${values.name}` : t("promoter.LocationsForm.header.title")}
                onBack={() => navigate("/promoter/configs/locations")}
                actions={
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit" size="large" disabled={submitting || pristine}>
                        {t("promoter.LocationsForm.header.submitBtn")}
                      </Button>
                    </Grid>
                  </Grid>
                }
              />}>
              <Paper className={classes.paper}>
                <FormFields fields={formFields({ t, formOptions })} />
              </Paper>
            </Page>
          );
        }}
      />

    </div>
  );
}

export default withStyles(LocationsForm, styles);
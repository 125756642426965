export const styles = (theme) => ({
  root: {
    "& .CdgRow-actions": {
      width: 180
    },
    "& .CdgRow ": {
      height: 48
    }
  },
  hasChanges: {
    outline: "1px solid " + theme.palette.info.main,
    outlineOffset: "-1px",
    borderRadius: "5px"
  },
  accepted: {
    backgroundColor: theme.palette.success.back,
  },
  rejected: {
    backgroundColor: theme.palette.error.back,
  },
  pending: {
    backgroundColor: theme.palette.warning.back,
  },
  icon: {
    display: "block",
    color: theme.palette.text.secondary,
  }
});
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./AttachmentsDataGrid.styles";
import { listColumns } from "./AttachmentsDataGrid.columns";
import { downloadFile } from "../../../../api/s3";
import { usePromoter } from "../../../../common/hooks";
import AWS from "aws-sdk";

function AttachmentsDataGrid({ classes, data, totalRows }) {
  const { t } = useTranslation();
  const promoter = usePromoter();

  const downloadAttachment = (filePath) => {
    const { privateBucket } = promoter?.getConfig("admin", "fileStorage");
    if (
      filePath.indexOf(
        "https://" +
          privateBucket +
          "." +
          AWS.config.region +
          ".digitaloceanspaces.com/"
      ) !== -1
    ) {
      filePath = filePath.replace(
        "https://" +
          privateBucket +
          "." +
          AWS.config.region +
          ".digitaloceanspaces.com/",
        ""
      );
    }
    downloadFile(promoter, filePath);
  };

  return (
    <div className={classes.root}>
      {data && <CustomDataGrid columns={listColumns(t, downloadAttachment)} rows={data} total={totalRows} />}
    </div>
  );
}
export default withStyles(AttachmentsDataGrid, styles);

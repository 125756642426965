export const styles = (theme) => ({
  root: {
    width: "100%",
    position: "relative",
  },
  quantityInput: {
    "& input.MuiInputBase-input": {
      padding: "4px 0px 4px 8px !important",
      textAlign: "right",
    },
    "& .MuiSelect-select": {
      paddingTop: "4px !important",
      paddingBottom: "4px !important",
    },
  },
  unitsQuantity: {
    margin: 0,
    textAlign: "center",
  },
  qtyError: {
    position: "absolute",
    left: 10,
    bottom: 13,
  },
  qtyErrorSlim: {
    position: "absolute",
    left: 3,
    bottom: 6,
  },
});

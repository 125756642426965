import React from "react";
import { useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

import { styles } from "./PricesDataGrid.styles";
import { listColumns } from "./PricesDataGrid.columns";
import { filterFields, filterSchema } from "./PricesDataGrid.filter";
import PricesVariantsDataGrid from "./PricesVariantsDataGrid/PricesVariantsDataGrid";
import PricesDataGridActions from "./PricesDataGrid.actions";

import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";
import { priceListDefaultFilter } from "../../../products/_common/productsFilters";
import { usePromoter, useUser } from "../../../../common/hooks";
import { selectFormFamilyList, selectGroupContacts } from "../../../products/productsSlice";
import { useParams } from "react-router-dom";
import PriceExport from "./PriceExport/PriceExport";
import PriceImport from "./PriceImport/PriceImport";
import Permission from "../../../../app/components/Permission";
import PriceImportHelper from "./PriceImportHelper/PriceImportHelper";
import { selectEntityGroups } from "../../productsPricesSlice";

function PricesDataGrid({
  classes,
  data,
  totalRows,
  filter,
  onFilterChange,
  onRemove,
  loading,
  validation,
  onValidate,
  onStandardPriceChange,
  onVariantPriceChange,
  onVariantAdd,
  onAddPromotion,
  onEditPromotion,
  onPriceComparerOpen,
  comparerView,
  onStandardRetailPriceChange,
  onVariantRetailPriceChange,
  onPriceHistoryOpen
}) {
  const { t } = useTranslation();
  const user = useUser();
  const promoter = usePromoter();
  const entityGroups = useSelector(selectEntityGroups);
  const columns = listColumns({ t, user, promoter, validation, onStandardPriceChange, onAddPromotion, onEditPromotion, onPriceComparerOpen, onStandardRetailPriceChange, comparerView, entityGroups });
  const groupContacts = useSelector(selectGroupContacts);
  const families = useSelector(selectFormFamilyList);
  const { processId } = useParams();

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };

      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={columns}
        rows={(data || []).map((item) => ({
          ...item,
          className: getRowClass(classes, validation, item.prices, promoter),
          arrow: showRowArrow(item.prices, processId)
        }))}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMoreHandler}
        //onRowSelected={(row) => console.log('row: %o', row)}
        total={totalRows}
        rowKeyField="tempId"
        actionsComponent={comparerView ? null : <PricesDataGridActions classes={classes} validation={validation} onVariantAdd={onVariantAdd} onValidate={onValidate} onHistory={onPriceHistoryOpen} />}
        topActions={comparerView ? null :
          <Stack spacing={1} direction="row" alignItems="center">
            <FilterForm
              style={{ marginBottom: 8 }}
              loading={loading}
              storageKey="PricesDataGrid"
              onStorageLoad={onFilterHandler}
              slim
              fields={filterFields({ families, groupContacts, user, promoter })}
              schema={filterSchema()}
              onFilterChange={onFilterHandler}
              filter={filter}
              defaultFilter={priceListDefaultFilter}
            />
            {
              !validation && <>
                <Permission code="products.actions.price.import">
                  <PriceExport totalRows={totalRows} filter={filter}/>
                </Permission>
                <Permission code="products.actions.price.import">
                  <PriceImport
                    data={data}
                    onVariantPriceChange={onVariantPriceChange}
                    onVariantRetailPriceChange={onVariantRetailPriceChange}
                    onStandardPriceChange={onStandardPriceChange}
                    onStandardRetailPriceChange={onStandardRetailPriceChange}
                  />
                </Permission>
                <Permission code="products.actions.price.import">
                  <PriceImportHelper />
                </Permission>
              </>
            }
          </Stack>
        }
        innerRow={(row) => {
          return hasPriceVariants(row.prices)
            ? <PricesVariantsDataGrid
              data={priceVariantFilter(row.prices, user, validation)}
              product={row}
              onVariantPriceChange={onVariantPriceChange}
              onVariantRetailPriceChange={onVariantRetailPriceChange}
              validation={validation}
              onValidate={onValidate}
              onRemove={(variant) => onRemove({ variant, product: row })}
              onAddPromotion={onAddPromotion}
              onEditPromotion={onEditPromotion}
              comparerView={comparerView}
              onPriceComparerOpen={onPriceComparerOpen}
              onHistory={onPriceHistoryOpen}
            />
            : null
        }}
      />
    </div>
  );
}

const priceVariantFilter = (prices, user, validation) => {
  return prices.filter(item => {
    return item.priceType !== "S";
    // this condition was removed - prices on validation screen should be the same on the price list, the actions and disables should be managed as expected
    // if (validation) {
    //   return item.priceType !== "S"
    // }
    // else {
    //   return item.priceType !== "S" && (!user?.isPromoter || item.status !== "PND")
    // }
  });
}

const hasPriceVariants = (prices) => {
  return prices && prices.some(item => item?.priceType !== "S")
}

const getRowClass = (classes, validation, prices, promoter) => {
  const standardPrice = (prices || []).find(price => price.priceType === "S");
  if (!standardPrice || (!standardPrice.newSalePrice && parseFloat(standardPrice.salePrice) === 0 && !standardPrice.process) || !promoter?.getConfig("price", "allowStandard")) {
    return classes.zero;
  }
  if (!validation && standardPrice.hasChanges) {
    return classes.hasChanges;
  }
  if (standardPrice.process && standardPrice.process.status === "PENDING") {
    return classes.pending;
  }
  if (standardPrice.process && standardPrice.process.status === "ACCEPTED") {
    return classes.accepted;
  }
  if (standardPrice.process && standardPrice.process.status === "REFUSED") {
    return classes.rejected;
  }
  return "";
}

const showRowArrow = (prices, processId) => {
  const standardPrice = (prices || []).find(price => price.priceType === "S");
  return processId && standardPrice && processId === standardPrice.process?.processId;
}

export default withStyles(PricesDataGrid, styles);

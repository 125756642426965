import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Paper, Grid, Button, Divider, Typography } from "@mui/material";
import { usePromoter, useNavigate, useUser, useFirstLoad } from "../../../common/hooks";
import { styles } from "./EntityForm.styles";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {
  formSchema,
  formEditSchema,
  formFields,
  userFormFields,
  addressFormFields,
} from "./EntityForm.schema";
import arrayMutators from "final-form-arrays";

import {
  createEntity,
  selectOperators,
  getOperators,
  entityUpdateConfig,
  entityUpdateTaxAddress,
  entityUpdateLogo,
} from "../entitiesSlice";

import { entityGetByTaxNumber, selectEntityCheckDetails, clearCheckEntity } from "../entitiesSlice";

import { selectIsLoading, entityGet, selectEntityDetails } from "../entitiesSlice";
import { isNifValid } from "../../../common/utils";
import { FormTitle, BlockArrayFields } from "../../../common/components/formFields";
import BusinessUnits from "./fields/BusinessUnits";
import { Form } from "../../../common/forms";
import { useParams } from "react-router-dom";
import Permission from "../../../app/components/Permission";
import { compareObjects } from "../../../common/utils/utils";
import EntityUpdateName from "./EntityUpdateName/EntityUpdateName";
import FormErrors from "../../../common/forms/FormErrors/FormErrors";
import Page from "../../../common/displays/Page/Page";
import { useEffect } from "react";

function CreateEntityForm({ classes, isEdit }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const entityTemplate = useSelector(selectEntityCheckDetails);
  const [selectedType, setSelectedType] = useState(entityTemplate?.type);
  const loading = useSelector(selectIsLoading);
  const dispatch = useDispatch();
  const operators = useSelector(selectOperators);
  const { id } = useParams();
  const details = useSelector(selectEntityDetails);
  const user = useUser();
  const [showEditName, setShowEditName] = useState(false);
  const promoter = usePromoter();

  useFirstLoad(() => {
    dispatch(clearCheckEntity());
    dispatch(getOperators());
    if (id && details?.entityId?.toString() !== id) {
      dispatch(entityGet(id));
    }
  });

  useEffect(() => {
    if (id && details?.entityId?.toString() === id) {
      setSelectedType(details?.type);
    }
    // eslint-disable-next-line
  }, [details])

  const onSubmit = async (values) => {
    if (isEdit) {
      if (user?.entity?.type === "P") {
        dispatch(entityUpdateConfig(values));
      }
      if (!compareObjects(values.taxAddress, details.taxAddress)) {
        dispatch(entityUpdateTaxAddress({ id: values.entityId, ...values.taxAddress }));
      }
      if (values.logo && values.logo !== details.logo) {
        dispatch(entityUpdateLogo({ entityId: values.entityId, logo: values.logo }));
      }
    } else {
      dispatch(createEntity(values));
    }
  };

  const onNifChange = (nif) => {
    let isValid = isNifValid(nif);
    if (isValid) {
      dispatch(entityGetByTaxNumber(nif));
    }
  };

  const onTypeChange = (type) => {
    setSelectedType(type);
  };

  const schema = isEdit ? formEditSchema({ t, user }) : formSchema({ t, selectedType, promoter, disableFields: !Boolean(entityTemplate?.create), user });

  let initialValues = isEdit ?
    {
      ...details,
      fee:
        details?.fee?.quantity && parseInt(details.fee.quantity) !== 0
          ? details?.fee
          : { quantity: "0", valueType: "P", type: "O", frequencyType: "A" },
    }
    : JSON.parse(JSON.stringify(entityTemplate)) //trick to create a complete new instance of the object - needed to avoid read only conflits

  // auto select logistic operator if there's only one
  if (operators && operators.length === 1 && !isEdit && promoter?.getConfig("order", "hasLogisticOperator") && initialValues.addressList) {
    initialValues.addressList[0].operatorAddress = operators[0].id
  }

  initialValues.type = selectedType;

  return (
    <div>
      <Form
        schema={schema}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          submitting,
          form: {
            mutators: { push, update, ...restMutators },
            change,
          },
          values,
          submitFailed,
          errors,
        }) => {
          return (
            <Page
              permission={isEdit ? "entities.actions.entity.update" : "entities.actions.entity.create"}
              header={<PageTitle
                onBack={isEdit ? () => navigate("/entities/" + id) : () => navigate("/entities")}
                title={
                  isEdit ? (
                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      direction="row"
                      onMouseOver={() => setShowEditName(true)}
                      onMouseOut={() => setShowEditName(false)}
                    >
                      <Typography className={classes.title} variant="h3">
                        {details?.name}
                      </Typography>
                      {details?.status === "ACT" && user?.entity?.type === "P" && (
                        <EntityUpdateName hidden={!showEditName} entity={details} />
                      )}
                    </Grid>
                  ) : (
                    t("entities.CreateEntity.form.title")
                  )
                }
                subtitle={isEdit && t("common.Enums.entityType." + details?.type)}
                actions={
                  <Grid container direction="row" spacing={2} alignItems="center">
                    {submitFailed && Object.keys(errors)?.length > 0 && (
                      <Grid item>
                        <FormErrors errors={errors} />
                      </Grid>
                    )}

                    {isEdit ? (
                      <Permission code="entities.actions.entity.update">
                        {((user.permissionFilter("entities.filters.entity.update.onlySelf") && user?.entity?.id === id) || !user.permissionFilter("entities.filters.entity.update.onlySelf")) &&
                          <Grid item>
                            <Button variant="contained" color="primary" type="submit" size="large" disabled={submitting}>
                              {t("entities.CreateEntity.form.submitEditBtn")}
                            </Button>
                          </Grid>
                        }
                      </Permission>
                    ) : (
                      <Permission code="entities.actions.entity.create">
                        <Grid item>
                          <Button variant="contained" color="primary" type="submit" size="large" disabled={submitting}>
                            {t("entities.CreateEntity.form.submitBtn")}
                          </Button>
                        </Grid>
                      </Permission>
                    )}
                  </Grid>
                }
              />}>

              <Paper className={classes.paper} elevation={0}>
                <FormTitle
                  title={t("entities.CreateEntity.form.dataTitle")}
                  subtitle={t("entities.CreateEntity.form.dataSubtitle")}
                />
                <Grid container alignItems="flex-start" spacing={2}>
                  {formFields(
                    t,
                    isEdit,
                    Boolean(loading),
                    promoter,
                    values.type,
                    !Boolean(entityTemplate?.create),
                    onNifChange,
                    onTypeChange,
                    user,
                    values
                  ).map((item, idx) =>
                    item.hidden ? null : (
                      <Grid item xs={item.size} key={idx}>
                        {item.field}
                      </Grid>
                    )
                  )}
                  {!isEdit && (
                    <Grid item xs={12} hidden={values.type !== "S"}>
                      <BusinessUnits
                        mutators={{ push, change }}
                        values={values}
                        selectedType={values.type}
                        defaultShow={Boolean(initialValues?.businessUnits && initialValues?.businessUnits.length > 0)}
                      />
                    </Grid>
                  )}
                </Grid>
                {!isEdit && (
                  <>
                    <Divider className={classes.divider} />
                    <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <BlockArrayFields
                          name="users"
                          formFields={(item) => {
                            return userFormFields(item, t, promoter, values.type, values);
                          }}
                          addMoreLabel={t("entities.CreateEntity.form.usersAddMore")}
                          onAddMore={() => push("users", { notify: true })}
                          title={t("entities.CreateEntity.form.usersTitle")}
                          subtitle={t("entities.CreateEntity.form.usersSubtitle")}
                        />
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <BlockArrayFields
                          name="addressList"
                          innerHeader={
                            <Button
                              variant="contained"
                              color="secondary"
                              type="button"
                              size="small"
                              onClick={() => {
                                update("addressList", 0, {
                                  address: values.taxAddress?.address,
                                  county: values.taxAddress?.county,
                                  locale: values.taxAddress?.locale,
                                  postalCode: values.taxAddress?.postalCode,
                                  state: values.taxAddress?.state,
                                  country: values.taxAddress?.country,
                                });
                              }}
                            >
                              {t("entities.CreateEntity.form.btnCopyAddress")}
                            </Button>
                          }
                          formFields={(item) => addressFormFields(item, t, promoter, values.type, operators, values)}
                          addMoreLabel={t("entities.CreateEntity.form.addressAddMore")}
                          onAddMore={() => push("addressList", undefined)}
                          title={t("entities.CreateEntity.form.addressTitle")}
                          subtitle={t("entities.CreateEntity.form.addressSubtitle")}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Paper>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(CreateEntityForm, styles);

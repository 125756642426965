import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const businessUnitChangeStepsOrderCall = async (
  dispatch,
  { order }
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_CHANGE_STEPS_ORDER");
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "CHANGE_STEPS_ORDER",
      request: {
        order
      },
    });

    if (result) {
      dispatch(setSuccess("businessUnits", "BUSINESS_UNIT_CHANGE_STEPS_ORDER"));
    }

  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitChangeStepsOrderCall;


import {Checkbox, Grid, Typography} from "@mui/material";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import {QuantityInput} from "../../../../common/inputs/custom";
import {currencyFormat} from "../../../../common/utils";
import CampaignTooltip from "../CampaignTooltip/CampaignTooltip";
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CustomTooltip from "../../../../common/displays/CustomTooltip/CustomTooltip";


export function listColumns({
  t,
  classes,
  showPrices,
  showCampaigns,
  onQuantityChanged,
  disabledQuantity,
  checkRows,
  onCheckChanged,
  allChecked,
  onBulkCheckChange,
  showDispatchedQuantity
}) {
  let fields = [
    {
      field: "description",
      headerName: t("orders.OrderProductsDataGrid.columns.description"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <ProductSnip
            image={v.thumbnail || v.image}
            description={v.description}
            ownerName={v.ownerName}
            gtin={v.gtin}
            deliveryIncidents={v.deliveryIncidents}
            promoterInternalCode={v.promoterInternalCode}
          />
        );
      },
    },
    {
      field: "quantity",
      headerName: t("orders.OrderProductsDataGrid.columns.quantity"),
      align: "center",
      headerAlign: "center",
      width: 160,
      customValue: (v) => {
        if (onQuantityChanged) {
          return (
            <QuantityInput
              slim
              style={{ width: 120 }}
              max={v?.maxQuantity}
              value={v?.quantity || 0}
              disabled={disabledQuantity}
              onChange={(value) => onQuantityChanged({ ...v, quantity: value })}
            />
          );
        } else {
          return (v?.quantity || 0) + " UN";
        }
      },
    },
    {
      field: "dispatchedQuantity",
      headerName: t("orders.OrderProductsDataGrid.columns.dispatchedQuantity"),
      align: "center",
      headerAlign: "center",
      width: 160,
      customValue: (v) => {
        let isDelivered = (v?.quantityHist?.dispatched || 0) > 0 && (v?.quantityHist?.dispatched || 0) === (v?.quantityHist?.delivered || 0);
        let partialDelivered = isDelivered && (v?.quantityHist?.dispatched || 0) !== (v?.quantity || 0);
        let tooltip = (
          <Grid container direction="column" spacing={1}>
            <Grid item>{`${t("orders.OrderProductsDataGrid.tooltip.dispatchedQuantity")} ${(v?.quantityHist?.dispatched || 0)} UN`}</Grid>
            <Grid item>{`${t("orders.OrderProductsDataGrid.tooltip.deliveredQuantity")} ${(v?.quantityHist?.delivered || 0)} UN`}</Grid>
          </Grid>
        )
        return (
          <CustomTooltip title={tooltip} placement="right">
            <Grid container spacing={1} alignItems="center" justifyContent="center">
              <Grid item>
                {(v?.quantityHist?.dispatched || 0) + " UN"}
              </Grid>
              <Grid item>
                {
                  isDelivered ? (
                    partialDelivered ?
                      <CheckIcon fontSize="small" style={{ display: "block" }} color="warning" /> :
                      <CheckIcon fontSize="small" style={{ display: "block" }} color="success" />
                  ) :
                    (v?.quantityHist?.dispatched || 0) > 0 && <ErrorOutlineIcon fontSize="small" style={{ display: "block" }} />
                }
              </Grid>
            </Grid>
          </CustomTooltip>
        );
      },
      hidden: !showDispatchedQuantity,
    },
    {
      field: "price",
      headerName: t("orders.OrderProductsDataGrid.columns.price"),
      align: "right",
      headerAlign: "center",
      customValue: (v) => {
        if (v.outDatedPrice) {
          return (
            <Grid container justifyContent="flex-end" spacing={1}>
              {showCampaigns &&
                (v.outDatedCampaigns || []).map((item, index) => (
                  <Grid item key={`campaign_${index}`}>
                    <CampaignTooltip campaign={item} quantity={v?.quantity || 0} outdated />
                  </Grid>
                ))}
              {showCampaigns &&
                (v.campaigns || []).map((item, index) => (
                  <Grid item key={`campaign_${index}`}>
                    <CampaignTooltip campaign={item} quantity={v?.quantity || 0} />
                  </Grid>
                ))}
              <Grid item>
                <Typography style={{ whiteSpace: "nowrap" }} className={v.outDatedPrice > v.price ? classes.outdated : classes.outdatedred}>
                  {currencyFormat(v.outDatedPrice)}
                </Typography>
              </Grid>
              <Grid item><Typography style={{ whiteSpace: "nowrap" }}>{currencyFormat(v.price)}</Typography></Grid>
            </Grid>
          );
        } else {
          return <Typography style={{ whiteSpace: "nowrap" }}>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.nomargin}>{currencyFormat(v.price)}</Typography>
                </Grid>
                {v.priceType === 'C' && <Grid item>
                  <Typography color="textSecondary" className={classes.nomargin}>
                    {t("orders.PriceSelector.type." + v.priceType)}
                  </Typography>
                </Grid>}
              </Grid>
          </Typography>;
        }
      },
      hidden: !showPrices,
    },
    {
      field: "vat",
      headerName: t("orders.OrderProductsDataGrid.columns.tax"),
      align: "center",
      headerAlign: "center",
      hidden: !showPrices,
      customValue: (v) => v.vat !== undefined ? <Typography style={{ whiteSpace: "nowrap", margin: 0 }}>{v.vat} %</Typography> : ""
    },
    {
      field: "total",
      headerName: t("orders.OrderProductsDataGrid.columns.total"),
      align: "right",
      headerAlign: "right",
      hidden: !showPrices,
      customValue: (v) => {
        return v.active ? <Typography style={{ whiteSpace: "nowrap" }}>{currencyFormat(parseFloat(v.price) * parseFloat(v.quantity))}</Typography> : "";
      },
    },
  ];

  if (checkRows) {
    fields = [
      {
        field: "productId",
        align: "left",
        headerAlign: "left",
        disableSort: true,
        headerComponent: (
          <Checkbox
            className={classes.checkbox}
            color="primary"
            checked={allChecked}
            onChange={(e) => onBulkCheckChange && onBulkCheckChange(e.target.checked)}
          />
        ),
        customValue: (v) => {
          return (
            <Checkbox
              className={classes.checkbox}
              color="primary"
              checked={v.checked}
              onChange={(e) => onCheckChanged && onCheckChanged({ ...v, checked: e.target.checked })}
            />
          );
        },
      },
      ...fields,
    ];
  }
  return fields;
}

export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
  },
  root2: {
    padding: theme.spacing(3),
    width: "100%",
    paddingTop: 0,
  },
  paper: {
    padding: theme.spacing(3),
  }
});

import React from "react";
import { withStyles } from "tss-react/mui";
import { styles } from "./HomeScreen.styles";

import { usePromoter, useUser } from "../../../common/hooks";
import PromoterDashboard from "./PromoterDashboard/PromoterDashboard";
import Highlights from "./Highlights/Highlights";

function HomeScreen({ classes }) {
  const promoter = usePromoter();
  const user = useUser();

  let content;
  if (promoter?.getConfig("home", "hasDashboard") && user?.isPromoter) {
    content = <PromoterDashboard />;
  } else if (promoter?.getConfig("home", "hasFlyer") || promoter?.getConfig("home", "hasHighlights")) {
    content = <Highlights />;
  }

  return <div className={classes.root}>{content}</div>;
}

export default withStyles(HomeScreen, styles)

import axios from "axios";

const fileStorage = process.env.REACT_APP_PUBLIC_FILE_STORAGE;
export const refdataAPI = axios.create({
  baseURL: fileStorage,
  headers: {
    "content-type": "application/json",
  },
});

export const getGpcCategories = async (promoter) => {
  let result;
  try {
    result = await refdataAPI.get("/" + promoter + "/" + process.env.REACT_APP_ENV + "/ref_data/brickGpc.json");
  } catch (ex) {
    result = { data: [], failed: true };
  }
  return result.data;
};

export const getProductClasses = async (promoter) => {
  let result;
  try {
    result = await refdataAPI.get("/" + promoter + "/" + process.env.REACT_APP_ENV + "/ref_data/productClass.json");
  } catch (ex) {
    result = { data: [], failed: true };
  }
  return result.data;
};

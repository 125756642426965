export const csvFields = (t) => {
  return [
    {
      field: "sellerName",
      header: t("business.reports.WeeklyUnitsAccepted.columns_sellerName"),
    },
    {
      field: "buyerName",
      header: t("business.reports.WeeklyUnitsAccepted.columns_buyerName"),
    },
    {
      field: "deliveryPlace",
      header: t("business.reports.WeeklyUnitsAccepted.columns_deliveryPlace"),
    },
    {
      field: "totalTransportUnits",
      header: t("business.reports.WeeklyUnitsAccepted.columns_totalTransportUnits"),
    },
  ];
};

import React from "react";
import { withStyles } from "tss-react/mui";
import { Grid, Typography, Paper } from "@mui/material";

import { styles } from "./FormSection.styles";

function FormSection({ classes, title, children }) {

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h4" align="left" component="h4" className={classes.noMargin}>
            {title}
          </Typography>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(FormSection, styles);

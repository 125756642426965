export const styles = (theme) => ({
  root: {},
  transportHeader: {
    backgroundColor: theme.palette.background.default,
  },
  table: {
    "& td": {
      padding: 5,
    },
  },
  minmaxInput: {
    "& input": {
      textAlign: "center",
      padding: 5,
      maxWidth: 80,
    },
  },
  select: {
    minWidth: 160,
    "& .MuiSelect-root": {
      padding: "5px 32px 5px 5px",
    },
  },
  smallButton: {
    width: 32,
    minWidth: 32,
  }
});

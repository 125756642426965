import { createSlice } from "@reduxjs/toolkit";
import orderConfirmDeliveryCall from "./_sliceCalls/orderConfirmDeliveryCall";
import orderDeliveryWithoutOperatorCall from "./_sliceCalls/orderDeliveryWithoutOperatorCall";
import orderEntityAddressesCall from "./_sliceCalls/orderEntityAddressesCall";
import orderDetailsCall from "./_sliceCalls/orderDetailsCall";
import orderUpdateCall from "./_sliceCalls/orderUpdateCall";
import orderCreateCall from "./_sliceCalls/orderCreateCall";
import orderConfirmationCall from "./_sliceCalls/orderConfirmationCall";
import orderCancelCall from "./_sliceCalls/orderCancelCall";
import orderListCall from "./_sliceCalls/orderListCall";
import orderAggregateDetailsCall from "./_sliceCalls/orderAggregateDetailsCall";
import orderAcceptCall from "./_sliceCalls/orderAcceptCall";
import orderDispatchCall from "./_sliceCalls/orderDispatchCall";
import orderModifyQuantityAcceptCall from "./_sliceCalls/orderModifyQuantityAcceptCall";
import orderListAggregateCall from "./_sliceCalls/orderListAggregateCall";
import orderListOrdersAggregateCall from "./_sliceCalls/orderListOrdersAggregateCall";
import { addressAggregatedOrdersDefaultFilter, aggregatedOrdersDefaultFilter, ordersMissingProductsDataGridDefaultFilter } from "./_common/ordersFilters";
import orderListLoCall from "./_sliceCalls/orderListLoCall";
import orderDetailsLoCall from "./_sliceCalls/orderDetailsLoCall";
import orderAddDocumentCall from "./_sliceCalls/orderAddDocumentCall";
import orderDeliveryCall from "./_sliceCalls/orderDeliveryCall";

export const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    orderList: null,
    orderListTotalRows: 0,
    orderListFilter: {
      limit: 30,
      offset: 0,
      sortField: "NAME",
      sortType: "ASC",
    },
    orderDetails: null,
    currentOrder: null,
    aggregatedOrders: null,
    aggregatedOrdersTotalRows: 0,
    aggregatedOrdersFilter: aggregatedOrdersDefaultFilter,
    addressAggregatedOrders: null,
    addressAggregatedOrdersTotalRows: 0,
    addressAggregatedOrdersFilter: addressAggregatedOrdersDefaultFilter,
    addressAggregatedOrdersDetails: null,
    aggregatedOrderDetails: null,
    import: undefined,
    operatorOrders: null,
    operatorOrdersTotalRows: 0,
    operatorOrdersFilter: {
      limit: 30,
      offset: 0,
    },
    operatorOrderDetails: null,
    ordersMissingProducts: [],
    ordersMissingProductsTotalRows: 0,
    ordersMissingProductsFilter: ordersMissingProductsDataGridDefaultFilter,
    orderAddresses: []
  },
  reducers: {
    clearOrderList: (state) => {
      state.orderList = [];
    },

    appendToOrderList: (state, action) => {
      state.orderList = [...state.orderList, ...action.payload];
    },

    setOrderListTotalRows: (state, action) => {
      state.orderListTotalRows = action.payload;
    },

    setOrderListFilter: (state, action) => {
      state.orderListFilter = action.payload;
    },

    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },

    setCurrentOrder: (state, action) => {
      state.currentOrder = action.payload;
    },

    setCurrentOrderProducts: (state, action) => {
      if (state.currentOrder) {
        state.currentOrder.products = action.payload;
      }
    },

    clearAggregatedOrders: (state) => {
      state.aggregatedOrders = [];
    },

    appendToAggregatedOrders: (state, action) => {
      state.aggregatedOrders = [...state.aggregatedOrders, ...action.payload];
    },

    setAggregatedOrdersTotalRows: (state, action) => {
      state.aggregatedOrdersTotalRows = action.payload;
    },

    setAggregatedOrdersFilter: (state, action) => {
      state.aggregatedOrdersFilter = action.payload;
    },

    clearAddressAggregatedOrders: (state) => {
      state.addressAggregatedOrders = [];
    },

    appendToAddressAggregatedOrders: (state, action) => {
      state.addressAggregatedOrders = [...state.addressAggregatedOrders, ...action.payload];
    },

    setAddressAggregatedOrdersTotalRows: (state, action) => {
      state.addressAggregatedOrdersTotalRows = action.payload;
    },

    setAddressAggregatedOrdersFilter: (state, action) => {
      state.addressAggregatedOrdersFilter = action.payload;
    },

    setAddressAggregatedOrdersDetails: (state, action) => {
      state.addressAggregatedOrdersDetails = action.payload;
    },

    setAggregatedOrderDetails: (state, action) => {
      state.aggregatedOrderDetails = action.payload;
    },

    setImport: (state, action) => {
      state.import = action.payload;
    },

    clearOperatorOrders: (state) => {
      state.operatorOrders = [];
    },

    appendToOperatorOrders: (state, action) => {
      state.operatorOrders = [...state.operatorOrders, ...action.payload];
    },

    setOperatorOrdersTotalRows: (state, action) => {
      state.operatorOrdersTotalRows = action.payload;
    },

    setOperatorOrdersFilter: (state, action) => {
      state.operatorOrdersFilter = action.payload;
    },

    setOperatorOrderDetails: (state, action) => {
      state.operatorOrderDetails = action.payload;
    },

    clearOrdersMissingProducts: (state) => {
      state.ordersMissingProducts = [];
    },

    appendToOrdersMissingProducts: (state, action) => {
      state.ordersMissingProducts = [...state.ordersMissingProducts, ...action.payload];
    },

    setOrdersMissingProductsTotalRows: (state, action) => {
      state.ordersMissingProductsTotalRows = action.payload;
    },

    setOrdersMissingProductsFilter: (state, action) => {
      state.ordersMissingProductsFilter = action.payload;
    },

    setOrderAddresses: (state, action) => {
      state.orderAddresses = action.payload;
    },
  },
});

export default ordersSlice.reducer;

export const {
  clearOrderList,
  setOrderListFilter,
  appendToOrderList,
  setOrderListTotalRows,
  setOrderDetails,
  setCurrentOrder,
  setCurrentOrderProducts,
  clearAddressAggregatedOrders,
  appendToAddressAggregatedOrders,
  setAddressAggregatedOrdersTotalRows,
  setAddressAggregatedOrdersFilter,
  clearAggregatedOrders,
  appendToAggregatedOrders,
  setAggregatedOrdersTotalRows,
  setAggregatedOrdersFilter,
  setAddressAggregatedOrdersDetails,
  setAggregatedOrderDetails,
  setImport,
  clearOperatorOrders,
  appendToOperatorOrders,
  setOperatorOrdersTotalRows,
  setOperatorOrdersFilter,
  setOperatorOrderDetails,
  clearOrdersMissingProducts,
  appendToOrdersMissingProducts,
  setOrdersMissingProductsTotalRows,
  setOrdersMissingProductsFilter,
  setOrderAddresses
} = ordersSlice.actions;

// SELECTS
export const selectOrderAddressesList = (state) => state.orders.orderAddresses;
export const selectOrderDetails = (state) => state.orders.orderDetails;
export const selectAggregatedDetails = (state) => state.orders.aggregatedOrderDetails;
export const selectAggregatedOrders = (state) => state.orders.aggregatedOrders;
export const selectAggregatedOrdersTotalRows = (state) => state.orders.aggregatedOrdersTotalRows;
export const selectAggregatedOrdersFilter = (state) => state.orders.aggregatedOrdersFilter;
export const selectAddressAggregatedOrders = (state) => state.orders.addressAggregatedOrders;
export const selectAddressAggregatedOrdersTotalRows = (state) => state.orders.addressAggregatedOrdersTotalRows;
export const selectAddressAggregatedOrdersFilter = (state) => state.orders.addressAggregatedOrdersFilter;
export const selectAddressAggregatedOrdersDetails = (state) => state.orders.addressAggregatedOrdersDetails;
export const selectOperatorOrders = (state) => state.orders.operatorOrders;
export const selectOperatorOrdersTotalRows = (state) => state.orders.operatorOrdersTotalRows;
export const selectOperatorOrdersFilter = (state) => state.orders.operatorOrdersFilter;
export const selectOperatorOrderDetails = (state) => state.orders.operatorOrderDetails;

// CALLS
export const orderConfirmDelivery =
  ({ orderId, totalPallets }) =>
    (dispatch) => {
      orderConfirmDeliveryCall(dispatch, { orderId, totalPallets });
    };

export const orderDeliveryWithoutOperator =
  ({ orderId, orderSellerId, dispatchId, document, documentDate, receivedBy, finished }) =>
    (dispatch) => {
      orderDeliveryWithoutOperatorCall(dispatch, {
        orderId,
        orderSellerId,
        dispatchId,
        document,
        documentDate,
        receivedBy,
        finished,
      });
    };

export const orderDelivery =
  ({ orderId, id, dispatchId, document, documentDate, receivedBy, finished, products, volumes, totalPallets, allProducts }, operationType) =>
    (dispatch) => {
      orderDeliveryCall(dispatch, {
        orderId, orderSellerId: id, dispatchId, document, documentDate, receivedBy, finished, products, volumes, totalPallets, allProducts, operationType
      });
    };


export const orderEntityAddresses = (id) => (dispatch) => {
  orderEntityAddressesCall(ordersSlice.actions, dispatch, { id });
}

export const orderDetails = (id) => (dispatch, getState) => {
  orderDetailsCall(ordersSlice.actions, dispatch, getState(), { id })
}

export const orderUpdate = (orderId, order) => (dispatch) => {
  orderUpdateCall(ordersSlice.actions, dispatch, { orderId, order })
}

export const orderCreate = (order) => (dispatch) => {
  orderCreateCall(ordersSlice.actions, dispatch, order)
}

export const orderConfirmation = (orderId) => (dispatch) => {
  orderConfirmationCall(dispatch, { orderId })
}

export const orderCancel = (orderId) => (dispatch) => {
  orderCancelCall(dispatch, { orderId })
}

export const orderList = (filter, clear = false) => (dispatch) => {
  orderListCall(ordersSlice.actions, dispatch, { filter, clear })
}

export const orderAggregateDetails = (id) => (dispatch, getState) => {
  orderAggregateDetailsCall(ordersSlice.actions, dispatch, getState(), { id })
}

export const orderAccept = ({ aggregateId, orderSellerId, products, finished }) => (dispatch, getState) => {
  orderAcceptCall({ orderAggregateDetails }, dispatch, getState(), { aggregateId, orderSellerId, products, finished })
}

export const orderAcceptAll = (aggregateId, orderSellerId) => (dispatch, getState) => {
  orderAcceptCall({ orderListOrdersAggregate }, dispatch, getState(), { aggregateId, orderSellerId, allProducts: true })
}

export const orderDispatch = ({
  aggregateId,
  orderSellerId,
  document,
  documentDate,
  transportDocument,
  transportDocumentDate,
  products,
  volumes,
  fromMissingProducts,
}) => (dispatch) => {
  orderDispatchCall({ orderAggregateDetails }, dispatch, {
    aggregateId,
    orderSellerId,
    document,
    documentDate,
    transportDocument,
    transportDocumentDate,
    products,
    volumes,
    fromMissingProducts,
  })
}

export const orderModifyQuantityAccept = (products) => (dispatch) => {
  orderModifyQuantityAcceptCall(dispatch, { products })
}

export const orderListAggregate = (filter, clear = false) => (dispatch) => {
  orderListAggregateCall(ordersSlice.actions, dispatch, { filter, clear })
}

export const orderListOrdersAggregate = (id, filter, clear = false) => (dispatch) => {
  orderListOrdersAggregateCall(ordersSlice.actions, dispatch, { id, filter, clear })
}

export const orderListLo = (filter, clear = false) => (dispatch) => {
  orderListLoCall(ordersSlice.actions, dispatch, { filter, clear })
}

export const orderDetailsLo = (id, dispatchId) => (dispatch, getState) => {
  orderDetailsLoCall(ordersSlice.actions, dispatch, getState(), { id, dispatchId })
}

export const orderAddDocument = ({ orderId, orderSellerId, dispatchId, type, metadata, file }) => (dispatch, getState) => {
  orderAddDocumentCall({ orderAggregateDetails, orderDetails, orderDetailsLo }, dispatch, getState(), { orderId, orderSellerId, dispatchId, type, metadata, file })
}
import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const businessReportMissingShipmentsCall = async ({
  clearQueriesReportMissingShipmentsList,
  setQueriesReportMissingShipmentsList,
  setQueriesReportMissingShipmentsListTotalRows,
  setQueriesReportMissingShipmentsFilter
}, dispatch, { filter, clear }) => {
  let rh = requestHelper(dispatch, "BUSINESS_REPORT_MISSING_SHIPMENTS");
  if (clear) {
    dispatch(clearQueriesReportMissingShipmentsList());
  }
  dispatch(setQueriesReportMissingShipmentsFilter(filter));

  try {
    const { limit, offset, sortField = "", sortType, month, year, entityId } = filter;
    let result = await call({
      type: "BUSINESS",
      subType: "REPORT_MISSING_SHIPMENTS",
      request: {
        limit,
        offset,
        sortField: sortField.toUpperCase(),
        sortType,
        month,
        year,
        entityId
      },
    });

    if (result && result.missingShipments && result.missingShipments.length > 0) {
      dispatch(setQueriesReportMissingShipmentsList(result.missingShipments));
      dispatch(setQueriesReportMissingShipmentsListTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default businessReportMissingShipmentsCall;
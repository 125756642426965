import React from "react";
import { withStyles } from "tss-react/mui";
import { Stack, TextField, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { styles } from "./_base.styles";

function TextInput({
  classes,
  id,
  className,
  label,
  value,
  onChange,
  disabled,
  type = "text",
  error,
  required,
  variant = "outlined",
  rows = 0,
  maxLength,
  note
}) {
  return (
    <div className={classes.root + " " + className}>
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="flex-end" >
        {variant === "outlined" && label && (
          <Typography
            style={{ marginBottom: 8, marginTop: 8 }}
            component="span"
            variant="caption"
            htmlFor={id}
            color="textPrimary"
            className={disabled ? classes.disabledLabel : ""}
          >
            {label}
            {required && <span> *</span>}
          </Typography>
        )}
        {note && <Tooltip title={note} placement="left"><InfoOutlinedIcon color="primary" className={classes.noteIcon} /></Tooltip>}
      </Stack>
      <TextField
        id={id}
        fullWidth
        type={type}
        className={classes.input + (disabled ? " " + classes.disabledInput : "")}
        error={error}
        label={variant === "outlined" ? undefined : label + (required ? " *" : "")}
        onChange={(e) => onChange && onChange(maxLength ? e.target.value.slice(0, maxLength) : e.target.value)}
        value={value}
        disabled={Boolean(disabled)}
        variant={variant}
        onFocus={(e) => e.target && e.target.select && e.target.select()}
        size="small"
        multiline={rows > 0}
        rows={rows}
      />
    </div>
  );
}

export default withStyles(TextInput, styles);

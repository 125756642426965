import { NumberWithSelectField, SelectField, TextField } from "../../../../common/forms";
import { getEntityPermissionOptions, getFeeTypeOptions, getFeeValueTypeOptions, getFrequencyTypeOptions, getOperationTypeOptions, getTrafficTypeOptions } from "../EntityForm.options";

export function businessUnitFormFields({ t, item, values, promoter, selectedType }) {
  const [, , index] = item.match(/^(\w+)\[(\d+)\]$/);
  const buValues = values?.businessUnits?.[index];

  return [
    {
      size: 12,
      field: (
        <TextField
          id={`${item}.businessUnitDesc`}
          type="text"
          label={t("entities.CreateEntity.form.businessUnitDesc")}
        />
      ),
    },
    {
      size: 6,
      field: <TextField id={`${item}.promoterInternalCode`} label={t("entities.CreateEntity.form.promoterInternalCode")} disabled />,
      hidden: !buValues.promoterInternalCode
    },
    {
      size: 6,
      hidden: !buValues.promoterInternalCode
    },
    {
      size: 6,
      field: <TextField id={`${item}.cep`} label={t("entities.CreateEntity.form.cep")} maxLength={11} />,
    },
    {
      size: 6,
      field: <TextField id={`${item}.gln`} label={t("entities.CreateEntity.form.gln")} maxLength={13} />,
    },
    {
      size: 6,
      field: (
        <SelectField
          id={`${item}.trafficType`}
          label={t("entities.CreateEntity.form.trafficType")}
          options={getTrafficTypeOptions(t)}
          required
        />
      ),
    },
    {
      size: 6,
      field: (
        <SelectField
          id={`${item}.operationType`}
          label={t("entities.CreateEntity.form.operationType")}
          options={getOperationTypeOptions(t)}
          required
        />
      ),
      hidden: !promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType),
    },
    {
      size: 6,
      field: (
        <NumberWithSelectField
          id={`${item}.fee`}
          label={t("entities.CreateEntity.form.fee")}
          inputType="number"
          textKey="quantity"
          selectKey="valueType"
          options={getFeeValueTypeOptions()}
          decimalScale={0}
        />
      ),
      hidden: !promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType),
    },
    {
      size: 6,
      field: (
        <SelectField
          id={`${item}.fee.type`}
          label={t("entities.CreateEntity.form.feeType")}
          options={getFeeTypeOptions(t)}
        />
      ),
      hidden: !promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType),
    },
    {
      size: 6,
      field: (
        <SelectField
          id={`${item}.fee.frequencyType`}
          label={t("entities.CreateEntity.form.frequencyType")}
          options={getFrequencyTypeOptions(t)}
        />
      ),
      hidden: !promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType),
    },
    {
      size: 6,
      field: (
        <SelectField
          id={`${item}.cmpAccumulation`}
          label={t("entities.CreateEntity.form.cmpAccumulation")}
          options={getEntityPermissionOptions(t)}
          required={
            promoter?.getConfig("price", "cmpAccumulation") === "EC" &&
            promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
          }
        />
      ),
      hidden: !(
        promoter?.getConfig("price", "cmpAccumulation") === "EC" &&
        promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
      ),
    },
    {
      size: 6,
      field: (
        <SelectField
          id={`${item}.cpAccumulation`}
          label={t("entities.CreateEntity.form.cpAccumulation")}
          options={getEntityPermissionOptions(t)}
          required={
            promoter?.getConfig("price", "cpAccumulation") === "EC" &&
            promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
          }
        />
      ),
      hidden: !(
        promoter?.getConfig("price", "cpAccumulation") === "EC" &&
        promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
      ),
    },
  ];
}

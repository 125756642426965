import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper } from "@mui/material";

import { styles } from "./AccountingDetails.styles";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { useParams } from "react-router-dom";
import { businessGetAccountingDoc, selectAccountingDetails } from "../businessSlice";
import LabelValue from "../../../common/displays/LabelValue/LabelValue";
import { currencyFormat, dateFormat } from "../../../common/utils";
import ProductsDataGrid from "./ProductsDataGrid/ProductsDataGrid";
import AttachmentsDataGrid from "./AttachmentsDataGrid/AttachmentsDataGrid";
import { dateTimeFormatWithMilliSeconds } from "../../../common/utils/formats";

function AccountingDetails({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const document = useSelector(selectAccountingDetails);

  useFirstLoad(() => {
    dispatch(businessGetAccountingDoc(id))
  })

  return <Page
    permission="business.actions.accounting.get"
    header={
      <PageTitle
        title={document?.ownerName + " - " + document?.customerDocNumber}
        onBack={() => navigate('/business/accounting')}
      />
    }>
    {document && (<Grid container direction="column" spacing={2}>
      <Grid item>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <LabelValue
                label={t("business.accounting.Accounting.details_documentDate")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {dateFormat(document.documentDate)}
                  </Typography>
                }
              />
              <LabelValue
                label={t("business.accounting.Accounting.details_ownerName")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {document.ownerName}
                  </Typography>
                }
              />
              <LabelValue
                label={t("business.accounting.Accounting.details_customerDocNumber")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {document.customerDocNumber}
                  </Typography>
                }
              />
              <LabelValue
                label={t("business.accounting.Accounting.details_documentId")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {document.documentId}
                  </Typography>
                }
              />
              <LabelValue
                label={t("business.accounting.Accounting.details_documentType")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {t("business.accounting.options.doc_type_" + document.documentType)}
                  </Typography>
                }
              />
              <LabelValue
                label={t("business.accounting.Accounting.details_refDocumentId")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {document.refDocumentId}
                  </Typography>
                }
              />
              <LabelValue
                label={t("business.accounting.Accounting.details_refDocumentType")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {t("business.accounting.options.doc_type_" + document.refDocumentType)}
                  </Typography>
                }
              />
              <LabelValue
                label={t("business.accounting.Accounting.details_supplierDocNumber")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {document.supplierDocNumber}
                  </Typography>
                }
              />
              <LabelValue
                label={t("business.accounting.Accounting.details_total")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {currencyFormat(document.total)}
                  </Typography>
                }
              />
            </Grid>
            {document.supplier &&
              <Grid item xs={4}>
                <Typography
                  variant="h4"
                  align="left"
                  component="h2"
                  className={classes.title}
                >
                  {t("business.accounting.Accounting.details_title_supplier")}
                </Typography>

                <LabelValue
                  label={t("business.accounting.Accounting.details_supplier_description")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {document.supplier.description}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.accounting.Accounting.details_supplier_taxNumber")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {document.supplier.taxNumber}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.accounting.Accounting.details_supplier_name")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {document.supplier.name}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.accounting.Accounting.details_supplier_address")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {document.supplier.streetName}<br />
                      {document.supplier.city}<br />
                      {document.supplier.zipCode}<br />
                      {document.supplier.country}<br />
                    </Typography>
                  }
                />
              </Grid>
            }
            {document.customer &&
              <Grid item xs={4}>
                <Typography
                  variant="h4"
                  align="left"
                  component="h2"
                  className={classes.title}
                >
                  {t("business.accounting.Accounting.details_title_customer")}
                </Typography>
                <LabelValue
                  label={t("business.accounting.Accounting.details_customer_description")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {document.customer.description}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.accounting.Accounting.details_customer_taxNumber")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {document.customer.taxNumber}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.accounting.Accounting.details_customer_name")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {document.customer.name}
                    </Typography>
                  }
                />
                <LabelValue
                  label={t("business.accounting.Accounting.details_customer_address")}
                  value={
                    <Typography variant="body1" align="left" component="span">
                      {document.customer.streetName}<br />
                      {document.customer.city}<br />
                      {document.customer.zipCode}<br />
                      {document.customer.country}<br />
                    </Typography>
                  }
                />
              </Grid>
            }
          </Grid>
        </Paper>
      </Grid>
      {document.products &&
        <Grid item>
          <Typography
            variant="h4"
            align="left"
            component="h2"
            className={classes.title}
          >
            {t("business.accounting.Accounting.details_title_products")}
          </Typography>
          <ProductsDataGrid
            data={document.products}
            totalRows={document.products.length}
          />
        </Grid>
      }
      {document.attachments &&
        <Grid item >
          <Typography
            variant="h4"
            align="left"
            component="h2"
            className={classes.title}
          >
            {t("business.accounting.Accounting.details_title_attachments")}
          </Typography>
          <AttachmentsDataGrid
            data={document.attachments}
            totalRows={document.attachments.length}
          />
        </Grid>
      }
      <Grid item>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <LabelValue
                label={t("business.accounting.Accounting.details_createdBy")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {document.createdBy}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={4}>
              <LabelValue
                label={t("business.accounting.Accounting.details_createdDate")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {dateTimeFormatWithMilliSeconds(document.createdDate)}
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={4}>
              <LabelValue
                label={t("business.accounting.Accounting.details_channel")}
                value={
                  <Typography variant="body1" align="left" component="span">
                    {t("common.Enums.channelType." + document.channel)}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>)}
  </Page>
}

export default withStyles(AccountingDetails, styles);
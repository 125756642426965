export const styles = (theme) => ({
  root: {
    "& .CdgRow ": {
      height: 48,
    },
    "& .CdgRow td": {
      padding: theme.spacing(0.5) + " " + theme.spacing(1)
    },
    "& .CdgRow-actions": {
      width: 20,
    },
    "& .CdgRow td:nth-last-of-type(1)": {
      padding: 0,
    },
    "& .CdgRow td:nth-last-of-type(2)": {
      padding: 0,
    }
  },
  hasChanges: {
    outline: "1px solid " + theme.palette.info.main,
    outlineOffset: "-1px",
    borderRadius: "5px"
  },
  accepted: {
    backgroundColor: theme.palette.success.back,
  },
  rejected: {
    backgroundColor: theme.palette.error.back,
  },
  end: {
    backgroundColor: theme.palette.error.back,
    textDecoration: "line-through"
  },
  pending: {
    backgroundColor: theme.palette.warning.back,
  }
});
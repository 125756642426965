import React from "react";
import { useUser } from "../../common/hooks";
import { useParams, Navigate } from "react-router-dom";

// Permission component checks if the user has the required permissions
// to render its children. If not, it either redirects to a specified path or returns null.
function Permission({ children, code, redirect }) {
  const user = useUser();
  const { promotercode } = useParams();

  if (user.hasPermission(code)) {
    return <>{children}</>;
  }

  if (redirect) {
    return <Navigate to={`/${promotercode}${redirect}`} />;
  }

  return null;
}

export default Permission;

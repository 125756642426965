import { saveFile } from "../../../api/s3";
import { base64ToArrayBuffer, guid } from "../../../common/utils";

export const entityInitialValues = (create, { taxAddresses, contacts, businessUnits, config, ...rest }) => {
  let init = {
    taxAddress: taxAddresses || {},
    taxNumber: "",
    ...config,
    ...rest,
    create,
    type: "S",
    operationType: "TF",
    fee: {
      type: "O",
      valueType: "P",
      frequencyType: "A",
      quantity: "0",
    },
    users: [{ notify: true }],
    addressList: [{}],
  };

  if (contacts && contacts.length > 0) {
    init.users = contacts;
  }

  if (businessUnits && businessUnits.length > 0) {
    init.businessUnits = businessUnits.map(({ name, gln, promoterInternalCode }) => ({ businessUnitDesc: name, gln, promoterInternalCode }))
  }

  return init;
};

export const uploadEntityDocument = async (fileStorageConfigs, promoterCode, entityId, file) => {
  const { provider, privateBucket } = fileStorageConfigs;

  const extension = file?.name ? file.name.slice(file.name.lastIndexOf(".")) : ".png";
  const filePath =
    `${promoterCode?.toUpperCase()}/${process.env.REACT_APP_ENV}/entity/${entityId}/documents/` + guid() + extension;
  let url = await saveFile(
    provider,
    privateBucket,
    filePath,
    base64ToArrayBuffer(file.base64),
    file.mediaType
  );

  return url;
};
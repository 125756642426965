export const styles = theme => ({
  root: {
    "& .MuiPaper-root": {
      border: "none",
      boxShadow: "none",
    },
    "& .MuiAccordionSummary-root": {
      padding: 0,
    },
    "& .MuiAccordionDetails-root": {
      padding: 0,
      flexDirection: "column"
    }
  },
  addMoreButton: {
    margin: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  deleteButton: {
    marginLeft: "auto",
    display: "block",
    marginTop: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
});
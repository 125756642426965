import React from "react";
import {withStyles} from "tss-react/mui";
import {Grid, Typography} from "@mui/material";
import {styles} from "./EntitySnip.styles";

function EntitySnip({ classes, name, taxNumber, internalCode }) {

  let description = taxNumber;
  if(internalCode)
      description = `${description} - ${internalCode}`;
  return (
    <div className={classes.root}>
      <Grid container alignItems="center" >
            <Grid item xs={12}>
              <Typography className={classes.nomargin}>{name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary" className={classes.nomargin}>
                {description}
              </Typography>
            </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(EntitySnip, styles)

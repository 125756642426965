import { Chip, Grid, Typography } from "@mui/material";
import i18next from "i18next";

export const getActiveOptions = (t) => {
  return [true, false].map((item) => {
    return {
      value: item,
      text: t("common.Enums.productActive." + item),
    };
  });
};

export const getAddressIdOptions = (entity) => {
  return (entity?.addresses || []).map((item) => {
    return {
      value: item.id,
      text: item.description,
    };
  });
};

export const getAvailableOptions = (t) => {
  return ["N", "S", "O"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.productAvailable." + item),
    };
  });
};

export const getTemperatureOptions = (t, promoter) => {
  return (promoter?.refData?.temperature || []).map((item) => {
    return {
      value: item,
      text: t("common.Enums.temperature." + item),
    };
  });
};

export const getBaseUnitsOptions = (t) => {
  return [
    {
      value: 'BASE_UNIT_OR_EACH',
      text: t("common.Enums.transport.BASE_UNIT_OR_EACH"),
    },
    {
      value: 'BULK',
      text: t("common.Enums.transport.BULK"),
    }
  ];
};

export const getTransportOptions = (t, promoter) => {
  return (promoter?.refData?.transport || []).map((item) => {
    return {
      value: item,
      text: t("common.Enums.transport." + item),
    };
  });
};

export const getGpcCategoriesOptions = (promoter) => {
  return (promoter?.refData?.gpcCategories || []).map((item) => {
    let altLang = Object.keys(item.description).filter((k) => k !== i18next.language)[0];
    let description = item.description[i18next.language] || item.description[altLang];
    return {
      value: item.code,
      text: item.code + " - " + description,
    };
  });
};

export const getFamiliesOptions = (t, families) => {
  return (families || []).map((item) => {
    let altLang = Object.keys(item.description).filter((k) => k !== i18next.language)[0];
    let description = item.description[i18next.language] || item.description[altLang];
    const isParent = !item.parent
    let parent = !isParent ? families.find(element => element.id === item.parent) : null;

    return {
      value: item.id,
      text: (
        <Grid container spacing={1} wrap="nowrap" alignItems="flex-end">
          <Grid item>
            <Typography style={{ margin: 0 }}>
              {description}
            </Typography>
          </Grid>
          {
            item.type && (isParent || item.type !== parent?.type) &&
            <Grid item>
              <Chip label={t("common.Enums.temperature." + item.type)} size="small" />
            </Grid>
          }
        </Grid>
      ),
      disabled: !item.parent,
    };
  });
};

export const getPackagingTypeOptions = (t, promoter, values) => {
  return (promoter?.refData?.packagingType || []).map((item) => {
    return {
      value: item,
      text: t("common.Enums.packagingType." + item),
      disabled: Boolean((values?.sustainability || []).find((sus) => sus.packagingType === item)),
    };
  });
};

export const getPackagingMaterialComponentType = (t, promoter) => {
  return (promoter?.refData?.packagingMaterialComponentType || []).map((item) => {
    return {
      value: item,
      text: t("common.Enums.packagingMaterialComponentType." + item),
    };
  });
};

export const getPerishableOptions = (t) => {
  return ["Y", "N", "O"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.perishable." + item),
    };
  });
};

export const getContactPointOptions = (groupContacts) => {
  let gc = JSON.parse(JSON.stringify(groupContacts || []));
  return gc.sort((a, b) => a.groupName > b.groupName ? 1 : -1).map((item) => {
    return {
      value: item.contactId,
      text: (
        <Grid container spacing={1} wrap="nowrap" alignItems="flex-end">
          <Grid item>
            <Typography style={{ margin: 0, color: "#aaa", fontSize: 12, lineHeight: "20px" }}>
              {item.groupName}
            </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ margin: 0 }}>{item.contactName}</Typography>
          </Grid>
        </Grid>
      ),
    };
  });
};

export const getVatOptions = (t, promoter) => {
  return (promoter?.refData?.vat || []).map((vatCode) => ({
    text: t('common.Enums.vat.' + vatCode),
    value: vatCode
  }))
};

export const getProductClassOptions = (t, promoter) => {
  return (promoter?.refData?.productClasses || []).map((item) => {
    let altLang = Object.keys(item.name).filter((k) => k !== i18next.language)[0];
    let name = item.name[i18next.language] || item.name[altLang];
    return {
      value: item.code,
      text: name,
    };
  }).sort((a, b) => (a.text || "").localeCompare((b.text || ""), 'en', { sensitivity: 'base' }));
};
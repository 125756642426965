import {currencyFormat} from "../../../../../common/utils";

export const csvFields = (t) => {
  return [
    {
      field: "sellerName",
      header: t("business.reports.SuppliersList.columns_sellerName"),

    },
    {
      field: "fee",
      header: t("business.reports.SuppliersList.columns_fee"),
      customValue: (v) => {
        return v.feeType === 'P' ? v.fee+" %": currencyFormat(v.price);
      },
    },
    {
      field: "cost",
      header: t("business.reports.SuppliersList.columns_cost"),
      customValue: (v) => {
        return currencyFormat(v.cost);
      },
    },
    {
      field: "purchasesVolume",
      header: t("business.reports.SuppliersList.columns_purchasesVolume"),
      customValue: (v) => {
        return currencyFormat(v.purchasesVolume);
      },
    },
    {
      field: "balance",
      header: t("business.reports.SuppliersList.columns_balance"),
      customValue: (v) => {
        return currencyFormat(v.balance);
      },
    },
    {
      field: "delta",
      header: t("business.reports.SuppliersList.columns_delta"),
    },

  ];
}

import {call} from "../../../app/websocket/_sliceCalls/websocket";
import {getPromoterConfig, requestHelper} from "../../../app/coreSlice";
import { processUnitsImages } from "../../businessUnits/_common/businessUnitsUtils";

const businessUnitList_FullCall = async (
  { setBusinessUnitList },
  dispatch,
  state
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_LIST");
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "LIST",
      request: {
        limit: 50000,
        offset: 0,
        sortField: "NAME",
        sortType: "ASC",
        active: true
      },
    });
    dispatch(setBusinessUnitList(await processUnitsImages(fileStorageConfigs, result.items)));
  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitList_FullCall;
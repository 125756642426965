

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import moment from "moment";

const planningCreateCall = async (
  { planningList },
  dispatch,
  {
    name,
    year,
    productId,
    startDate,
    endDate,
    startWeek,
    endWeek,
    target,
    defaultPlan
  }
) => {
  let rh = requestHelper(dispatch, "PLANNING_CREATE");
  try {
    let result = await call({
      type: "PLANNING",
      subType: "CREATE",
      request: {
        name,
        year,
        productId,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        startWeek,
        endWeek,
        target,
        defaultPlan
      },
    });
    if (result) {
      if (!defaultPlan) {
        dispatch(planningList({ year, productId }));
      }
      else {
        dispatch(setRedirect(`/planning/${productId}/${year}`));
      }
      dispatch(setSuccess("planning", "PLANNING_CREATE"));
    }
  } catch (ex) {
    rh.error("planning", ex);
  }
  rh.close();
};

export default planningCreateCall;


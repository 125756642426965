import * as Yup from "yup";
import {DateTimePickerField, EntityAutoCompleteField, SelectField} from "../../../../common/forms";
import React from "react";
import {getAuditErrorTypeOptions, getAuditWSTypeofWSOptions, getAuditWSTypeOptions} from "../../BusinessForm.options";
import TextField from "../../../../common/forms/fields/TextField";
import moment from "moment";

export const filterFields = () => (t) => {
  return [
    {
      size: 12,
      field: (
        <DateTimePickerField
          id="requestDateIni"
          label={t("business.audit.filter.requestStartDate")}
          maxDate={moment().toISOString()}
        />
      )
    },
    {
      size: 12,
      field: (
        <DateTimePickerField
          id="requestDateEnd"
          label={t("business.audit.filter.requestEndDate")}
          maxDate={moment().toISOString()}
        />
      )
    },
    {
      size: 12,
      field: (
        <SelectField
          id="type"
          label={t("business.audit.filter.type")}
          options={getAuditWSTypeofWSOptions(t)}
        />
      )
    },{
      size: 12,
      field: (
        <SelectField
          id="error"
          label={t("business.audit.filter.error")}
          options={getAuditErrorTypeOptions(t)}
        />
      )
    },
    {
      size: 12,
      field: (
        <SelectField
          id="operation"
          label={t("business.audit.filter.operation")}
          options={getAuditWSTypeOptions(t)}
        />
      )
    },
    {
      size: 12,
      field: (
        <TextField
          id="ipAddress"
          label={t("business.audit.filter.ipAddress")}
        />
      )
    },
    {
      size: 12,
      field: (
        <EntityAutoCompleteField id="entity" label={t("business.audit.filter.entity")} typeFilter={["S", "ST", "W", "LO", "P"]} />
      )
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    requestStartDate: Yup.string(),
    requestEndDate: Yup.string(),
  });
};

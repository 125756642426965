import moment from "moment";

export function listColumns(t, classes) {
  return [
    {
      field: "name",
      headerName: t("products.CampaignList.columns.name"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "startDate",
      headerName: t("products.CampaignList.columns.startDate"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return moment(v.startDate).format('D MMMM YYYY')
      }
    },
    {
      field: "endDate",
      headerName: t("products.CampaignList.columns.endDate"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return moment(v.endDate).format('D MMMM YYYY')
      }
    },
    {
      field: "totalCampaigns",
      headerName: t("products.CampaignList.columns.totalCampaigns"),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: t("products.CampaignList.columns.status"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return t("common.Enums.campaignStatus." + v.status)
      }
    },
  ];
}

// {
//   "groupId": "c9df3013-0e8d-4741-afde-7810a7dfd13b",
//   "groupName": "teste criação",
//   "status": true,
//   "entityId": "a6e1a698-f3b3-409b-84c1-069a737d6868",
//   "entityName": "Sumol+Compal Marcas, S.a",
//   "listEntities": [
//     {
//       "entityId": "6f4f23f9-7cc7-42f8-97e5-c3c50b37d84b",
//       "name": "Pastelaria Bomdoce Comercio de Pastelaria Lda",
//       "type": "W",
//       "status": true,
//       "confirmed": true
//     },
//   ]
// },

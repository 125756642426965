export const styles = (theme) => ({
  root: {},
  icon: {
    height: 22,
    width: 22,
  },
  statusBlock: {
    paddingBottom: 4,
    borderBottom: "1px solid #e0e0e0",
    "&:last-child": {
      paddingBottom: 0,
      borderBottom: "none",
    },
  },
  nomargin: {
    margin: 0,
  },
  datetime: {
    margin: 0,
    fontSize: 12,
  },
});



import moment from "moment";
import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const orderListAggregateCall = async ({ clearAggregatedOrders, setAggregatedOrdersFilter, appendToAggregatedOrders, setAggregatedOrdersTotalRows }, dispatch, { filter, clear }) => {
  let rh = requestHelper(dispatch, "LIST_AGGREGATE");
  if (clear) {
    dispatch(clearAggregatedOrders());
    filter = {
      ...filter,
      offset: 0,
      startDate: moment.isMoment(filter.startDate) ? filter.startDate.toISOString() : filter.startDate,
      endDate: moment.isMoment(filter.endDate) ? filter.endDate.toISOString() : filter.endDate,
    };
  }
  dispatch(setAggregatedOrdersFilter(filter));
  try {
    let result = await call({
      type: "ORDER",
      subType: "LIST_AGGREGATE",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    dispatch(appendToAggregatedOrders(result.aggregateOrderDetails));
    dispatch(setAggregatedOrdersTotalRows(result.rows));
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
};


export default orderListAggregateCall;
import React from "react";
import { Routes, Route } from "react-router-dom";

import CatalogList from "./CatalogList/CatalogList";
import CatalogCreate from "./CatalogCreate/CatalogCreate";
import CatalogDetails from "./CatalogDetails/CatalogDetails";
import ProductList from "./ProductList/ProductList";
import ProductForm from "./ProductForm/ProductForm";
import CampaignList from "./CampaignList/CampaignList";
import CampaignCreate from "./CampaignCreate/CampaignCreate";
import ProductDetails from "./ProductDetails/ProductDetails";
import ImportProduct from "./ImportProduct/ImportProduct";
import FamilyList from "../productsFamilies/FamilyList/FamilyList";
import ProductImportImages from "./ProductImportImages/ProductImportImages";
import PricesList from "../productsPrices/PricesList/PricesList";
import ProductValidationList from "./ProductValidationList/ProductValidationList";
import ProductValidationForm from "./ProductValidationForm/ProductValidationForm";
import Security from "../../app/components/Security/Security";
import PendingPurchasesList from "../productsPurchases/PendingPurchasesList/PendingPurchasesList";
import DpromsList from "../productsDproms/DpromsList/DpromsList";
import ExtraPriceList from "../productsPrices/ExtraPriceList/ExtraPriceList";
import ProductTaxList from "./ProductTaxList/ProductTaxList";

function ProductsRouting({ classes }) {
  return (
    <Routes>
      <Route path="/taxes/validation/:processId" element={<ProductTaxList validation />} />
      <Route path="/taxes/validation" element={<ProductTaxList validation />} />
      <Route path="/taxes/:processId" element={<ProductTaxList />} />
      <Route path="/taxes" element={<ProductTaxList />} />
      <Route path="/dproms/validation/:processId" element={<DpromsList validation />} />
      <Route path="/dproms/validation" element={<DpromsList validation />} />
      <Route path="/dproms/:processId" element={<DpromsList />} />
      <Route path="/dproms" element={<DpromsList />} />
      <Route path="/purchases/:processId" element={<PendingPurchasesList />} />
      <Route path="/purchases" element={<PendingPurchasesList />} />
      <Route path="/campaigns/edit/:id" element={
        <Security component={<CampaignCreate />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/campaigns/add" element={<CampaignCreate />} />
      <Route path="/campaigns/:id" element={
        <Security component={<CampaignCreate viewMode />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/campaigns" element={<CampaignList />} />
      <Route path="/catalogs/edit/:id" element={
        <Security component={<CatalogCreate />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/catalogs/add" element={<CatalogCreate />} />
      <Route path="/catalogs/:id" element={
        <Security component={<CatalogDetails />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/catalogs" element={<CatalogList />} />
      <Route path="/prices/validation/:processId" element={<PricesList validation />} />
      <Route path="/prices/validation" element={<PricesList validation />} />
      <Route path="/prices/:processId" element={<PricesList />} />
      <Route path="/prices/extra" element={<ExtraPriceList />} />
      <Route path="/prices" element={<PricesList />} />
      <Route path="/validation/:id/:processId/view" element={
        <Security component={<ProductValidationForm viewMode />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/validation/:id/:processId/:task/view" element={
        <Security component={<ProductValidationForm viewMode />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/validation/:id/:task" element={
        <Security component={<ProductValidationForm />} paramValidation={[{ name: "id", type: "uuid" }, { name: "task", type: "number" }]} />
      } />
      <Route path="/validation/:processId" element={
        <Security component={<ProductValidationList />} paramValidation={[{ name: "processId", type: "number" }]} />
      } />
      <Route path="/validation" element={<ProductValidationList />} />
      <Route path="/confirmation/:id/:task" element={
        <Security component={<ProductDetails confirmation />} paramValidation={[{ name: "id", type: "uuid" }, { name: "task", type: "number" }]} />
      } />
      <Route path="/confirmation-view/:id/:task" element={
        <Security component={<ProductDetails />} paramValidation={[{ name: "id", type: "uuid" }, { name: "task", type: "number" }]} />
      } />
      <Route path="/confirmation-validation/:id/:task" element={
        <Security component={<ProductDetails validation />} paramValidation={[{ name: "id", type: "uuid" }, { name: "task", type: "number" }]} />
      } />
      <Route path="/recover/:id/:processId" element={
        <Security component={<ProductDetails recover />} paramValidation={[{ name: "id", type: "uuid" }, { name: "processId", type: "number" }]} />
      } />
      <Route path="/families" element={<FamilyList />} />
      <Route path="/edit/:id" element={
        <Security component={<ProductForm />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/copy/:id" element={
        <Security component={<ProductForm copy/>} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/add" element={<ProductForm />} />
      <Route path="/import-images" element={<ProductImportImages />} />
      <Route path="/import" element={<ImportProduct />} />
      <Route path="/:id" element={
        <Security component={<ProductDetails />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/" element={<ProductList />} />
    </Routes>
  );
}

export default ProductsRouting;

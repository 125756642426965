

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { processImageUrl } from "../../../api/s3";
import { asyncForEach } from "../../../common/utils";

const planningAggregateListCall = async (
  { setPlanningAggregated },
  dispatch,
  state
) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "PLANNING_AGGREGATE_LIST");
  try {
    let result = await call({
      type: "PLANNING",
      subType: "AGGREGATE_LIST",
      request: {},
    });
    dispatch(setPlanningAggregated(result.items));

    let agregatePlanning = [];
    await asyncForEach(result.items, async (item) => {
      agregatePlanning.push({
        ...item,
        product: {
          ...item.product,
          image: await processImageUrl(fileStorageConfigs, item.product.image),
          thumbnail: await processImageUrl(fileStorageConfigs, item.product.thumbnail),
        }
      });
    });
    dispatch(setPlanningAggregated(agregatePlanning));
  } catch (ex) {
    rh.error("planning", ex);
  }
  rh.close();
};

export default planningAggregateListCall;


export const styles = (theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(3)
  },
  drawer: {
    width: "25vw",
    padding: theme.spacing(2),
  },
  links: {
    width: "100%",
    textAlign: "right",
    "& .MuiToggleButtonGroup-root": {
      backgroundColor: "#FFF"
    }
  },
  circleBtn: {
    backgroundColor: theme.palette.primary.main,
    height: 24,
    width: 24,
    "&:hover": {
      backgroundColor: theme.palette.primary.back,
    }
  }
});
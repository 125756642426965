import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import RefreshIcon from '@mui/icons-material/Refresh';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import HomeIcon from '@mui/icons-material/Home';

import { styles } from "./OrderIcon.styles";
import { Tooltip } from "@mui/material";

function OrderIcon({ classes, type }) {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('common.Enums.batchOrderType.' + type)}>
      <div className={classes.root}>
        <ContentPasteIcon className={classes.iconBase} />
        {type === "B" && <RefreshIcon className={classes.orderType} />}
        {type === "E" && <ShoppingCartIcon className={classes.orderType} />}
        {type === "T" && <CompareArrowsIcon className={classes.orderType} />}
        {type === "I" && <HomeIcon className={classes.orderType} />}
      </div>
    </Tooltip>
  );
}

export default withStyles(OrderIcon, styles);
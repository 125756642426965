import { Box, Divider, Stack } from "@mui/material"
import BlockCard from "../BlockCard/BlockCard"
import SlimCheckBoxField from "../SlimCheckBoxField/SlimCheckBoxField"
import { MultiSelectField } from "../../../../common/forms";

export const entitiesFormFields = ({ t, values, entityType }) => {
  const entityTypeOptions = ["P", "S", "W", "LO", "ST"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.entityType." + item),
    };
  });
  return [
    {
      size: 3,
      field: <BlockCard title="address">
        <Stack>
          <SlimCheckBoxField id="permissions.entities.actions.address.add" label="add" />
          <SlimCheckBoxField id="permissions.entities.actions.address.update" label="update" />
          <SlimCheckBoxField id="permissions.entities.actions.address.confirm" label="confirm" />
          <SlimCheckBoxField id="permissions.entities.actions.address.disable" label="disable" />
          <SlimCheckBoxField id="permissions.entities.actions.address.list" label="list" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="businessUnit">
        <Stack>
          <SlimCheckBoxField id="permissions.entities.actions.businessUnit.create" label="create" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="contact">
        <Stack>
          <SlimCheckBoxField id="permissions.entities.actions.contact.add" label="add" />
          <SlimCheckBoxField id="permissions.entities.actions.contact.disable" label="disable" />
          <SlimCheckBoxField id="permissions.entities.actions.contact.update" label="update" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="entity">
        <Stack>
          <SlimCheckBoxField id="permissions.entities.actions.entity.confirm" label="confirm" />
          <SlimCheckBoxField id="permissions.entities.actions.entity.create" label="create" />
          {values?.permissions?.entities?.actions?.entity?.create &&
            <Box style={{ marginLeft: 42, marginRight: 10 }}>
              <MultiSelectField
                id="permissions.entities.filters.entity.create.type"
                label="type"
                options={entityTypeOptions}
              />
            </Box>
          }
          <SlimCheckBoxField id="permissions.entities.actions.entity.disable" label="disable" />
          <SlimCheckBoxField id="permissions.entities.actions.entity.enable" label="enable" />
          <SlimCheckBoxField id="permissions.entities.actions.entity.externalAccess" label="externalAccess" />
          <SlimCheckBoxField id="permissions.entities.actions.entity.listComments" label="listComments" />
          <SlimCheckBoxField id="permissions.entities.actions.entity.get" label="get" />
          {values?.permissions?.entities?.actions?.entity?.get && <SlimCheckBoxField ident id="permissions.entities.filters.entity.get.includeSelf" label="includeSelf" />}
          {values?.permissions?.entities?.actions?.entity?.get &&
            <Box style={{ marginLeft: 42, marginRight: 10 }}>
              <MultiSelectField
                id="permissions.entities.filters.entity.get.type"
                label="type"
                options={entityTypeOptions}
              />
            </Box>
          }
          <SlimCheckBoxField id="permissions.entities.actions.entity.list" label="list" />
          {values?.permissions?.entities?.actions?.entity?.list && <SlimCheckBoxField ident id="permissions.entities.filters.entity.list.includeSelf" label="includeSelf" />}
          {values?.permissions?.entities?.actions?.entity?.list && entityType !== "P" && <SlimCheckBoxField ident id="permissions.entities.filters.entity.list.onlyCatalog" label="onlyCatalog" />}
          {values?.permissions?.entities?.actions?.entity?.list &&
            <Box style={{ marginLeft: 42, marginRight: 10 }}>
              <MultiSelectField
                id="permissions.entities.filters.entity.list.type"
                label="type"
                options={entityTypeOptions}
              />
            </Box>
          }
          <SlimCheckBoxField id="permissions.entities.actions.entity.update" label="update" />
          {values?.permissions?.entities?.actions?.entity?.update && <SlimCheckBoxField ident id="permissions.entities.filters.entity.update.onlySelf" label="onlySelf" />}
          <Divider />

        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="group">
        <Stack>
          <SlimCheckBoxField id="permissions.entities.actions.group.create" label="create" />
          <SlimCheckBoxField id="permissions.entities.actions.group.disable" label="disable" />
          <SlimCheckBoxField id="permissions.entities.actions.group.get" label="get" />
          <SlimCheckBoxField id="permissions.entities.actions.group.list" label="list" />
          <SlimCheckBoxField id="permissions.entities.actions.group.update" label="update" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="sectorGroup">
        <Stack>
          <SlimCheckBoxField id="permissions.entities.actions.sectorGroup.create" label="create" />
          <SlimCheckBoxField id="permissions.entities.actions.sectorGroup.delete" label="delete" />
          <SlimCheckBoxField id="permissions.entities.actions.sectorGroup.update" label="update" />
          <SlimCheckBoxField id="permissions.entities.actions.sectorGroup.list" label="list" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="pendingEntity">
        <Stack>
          {/* <SlimCheckBoxField id="permissions.products.actions.pendingEntity.get" label="get" /> */}
          <SlimCheckBoxField id="permissions.entities.actions.pendingEntity.list" label="list" />
          <SlimCheckBoxField id="permissions.entities.actions.pendingEntity.action" label="action" />
        </Stack>
      </BlockCard>,
    },
  ]
}

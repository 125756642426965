export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
  },
  paper: {
    display: "flex",
    padding: theme.spacing(2),
  },
  title: {
    margin: 0,
  },
  type: {
    margin: 0,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  noMargin: {
    margin: 0,
  },
  offerItem: {
    backgroundColor: "#eef2ff",
  },
  outdatedItem: {
    backgroundColor: "#faeeed",
    "& *": {
      textDecoration: "line-through",
    },
  },
});

import React, {useRef, useState} from "react";
import { withStyles } from "tss-react/mui";
import {Grid, Typography} from "@mui/material";

import {styles} from "./FileUpload.styles";
import {FileUploadIcon} from "../../icons";
import {toBase64} from "../../utils";

function FileUpload({ classes, filename, onChange , label}) {
  const inputFileRef = useRef();
  const [tempName, setTempName] = useState();

  const handleUpload = async (e) => {
    /*Selected files data can be collected here.*/
    const file = e.target.files[0];
    if (file) {
      setTempName(file.name);
      onChange &&
        onChange({
          name: file.name,
          type: file.type,
          base64: await toBase64(file),
        });
    }
  };

  const handleClick = () => {
    inputFileRef.current.click();
  };

  return (
    <Grid className={classes.root} container onClick={handleClick}>
      <Grid item>
        <FileUploadIcon />
      </Grid>
      <Grid item>
        <Typography component="span" variant="caption" color="textPrimary">
          {filename || tempName || label}
        </Typography>
      </Grid>
      <input
        accept="*/*"
        className={classes.input}
        id="contained-button-file"
        type="file"
        ref={inputFileRef}
        onChange={(e) => handleUpload(e)}
      />
    </Grid>
  );
}

export default withStyles(FileUpload, styles)

import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import ActionsPopover from "../../../../common/components/ActionsPopover/ActionsPopover";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import Permission from "../../../../app/components/Permission";

export default function OrdersDataGridActions({ row, onIncident }) {
  const { t } = useTranslation();

  const onIncidentHandler = (e) => {
    e.preventDefault();
    onIncident && onIncident(row);
  };

  return (row.status === "CD" || row.status === "DE" || row.status === "IN") && row.statusCompleted ? (
    <Grid container spacing={1} justifyContent="flex-end">
      <Permission code="orders.actions.incident.create">
        <Grid item>
          <ActionsPopover
            actions={[
              <Button onClick={onIncidentHandler}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item style={{ display: "flex" }}>
                    <ReportGmailerrorredIcon />
                  </Grid>
                  <Grid item>{t("orders.OrdersList.actions.reportIncident")}</Grid>
                </Grid>
              </Button>,
            ]}
          />
        </Grid>
      </Permission>


    </Grid>
  ) : null;
}

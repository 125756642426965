import {dateTimeFormat} from "../../../../common/utils";

export const csvFields = (t) => {
  return [
    {
      field: "date",
      header: t("business.queries.MissingShipmentsList.columns_date"),
      customValue: (v) => {
        return dateTimeFormat(v.date);
      },
    },{
      field: "operatorName",
      header: t("business.queries.MissingShipmentsList.columns_operatorName"),
    },
    {
      field: "sellerName",
      header: t("business.queries.MissingShipmentsList.columns_sellerName"),
    },
    {
      field: "orderSellerId",
      header: t("business.queries.MissingShipmentsList.columns_orderSellerId"),
    },
    {
      field: "dispatchId",
      header: t("business.queries.MissingShipmentsList.columns_dispatchId"),
    },{
      field: "receptionDone",
      header: t("business.queries.MissingShipmentsList.columns_receptionDone"),
    },{
      field: "dispatchDone",
      header: t("business.queries.MissingShipmentsList.columns_dispatchDone"),
    },{
      field: "deliveryDone",
      header: t("business.queries.MissingShipmentsList.columns_deliveryDone"),
    },
  ];
}

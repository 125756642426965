import {Box, Stack} from "@mui/material"
import BlockCard from "../BlockCard/BlockCard"
import SlimCheckBoxField from "../SlimCheckBoxField/SlimCheckBoxField"
import {MultiSelectField} from "../../../../common/forms";

export const businessFormFields = (t, values) => {
  const auditTypeOptions = ["audit_data", "audit_import", "audit_login", "audit_ws", "audit_external", "audit_job", "audit_i18n"].map((item) => {
    return {
      value: item,
      text: t("business.audit.options." + item),
    };
  });
  const reportTypeOptions = ["report_suppliers", "report_weekly_units", "report_weekly_units_accepted", "report_weekly_units_delivered"].map((item) => {
    return {
      value: item,
      text: t("business.reports.options." + item),
    };
  });
  const queriesTypeOptions = ["report_missing_shipments", "report_orders_overview", "report_product_prices", "report_product"].map((item) => {
    return {
      value: item,
      text: t("business.queries.options." + item),
    };
  });
  return [
    {
      size: 3,
      field: <BlockCard title="history">
        <Stack>
          <SlimCheckBoxField id="permissions.business.actions.history.list" label="list" />
          <SlimCheckBoxField id="permissions.business.actions.history.get" label="get" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="queries">
        <Stack>
          <SlimCheckBoxField id="permissions.business.actions.queries.list.available" label="list" />
          {values?.permissions?.business?.actions?.queries?.list?.available &&
              <Box style={{ marginLeft: 42, marginRight: 10 }}>
                <MultiSelectField
                    id="permissions.business.actions.queries.list.options"
                    label="type"
                    options={queriesTypeOptions}
                />
              </Box>
          }
          <SlimCheckBoxField id="permissions.business.actions.queries.get" label="get" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="reports">
        <Stack>
          <SlimCheckBoxField id="permissions.business.actions.report.list.available" label="list" />
          {values?.permissions?.business?.actions?.report?.list?.available &&
              <Box style={{ marginLeft: 42, marginRight: 10 }}>
                <MultiSelectField
                    id="permissions.business.actions.report.list.options"
                    label="type"
                    options={reportTypeOptions}
                />
              </Box>
          }
          <SlimCheckBoxField id="permissions.business.actions.report.get" label="get" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="audits">
        <Stack>
          <SlimCheckBoxField id="permissions.business.actions.audit.list.available" label="list" />

          {values?.permissions?.business?.actions?.audit?.list?.available &&
              <Box style={{ marginLeft: 42, marginRight: 10 }}>
                <MultiSelectField
                    id="permissions.business.actions.audit.list.options"
                    label="type"
                    options={auditTypeOptions}
                />
              </Box>
          }

          <SlimCheckBoxField id="permissions.business.actions.audit.get" label="get" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="accounting">
        <Stack>
          <SlimCheckBoxField id="permissions.business.actions.accounting.list" label="list" />
          <SlimCheckBoxField id="permissions.business.actions.accounting.get" label="get" />
          <SlimCheckBoxField id="permissions.business.actions.accounting.setAvailable" label="setAvailable" />
          <SlimCheckBoxField id="permissions.business.actions.accounting.upload" label="upload" />
          <SlimCheckBoxField id="permissions.business.actions.accounting.download" label="download" />
        </Stack>
      </BlockCard>,
    },{
      size: 3,
      field: <BlockCard title="process">
        <Stack>
          <SlimCheckBoxField id="permissions.business.actions.process.list" label="list" />
          <SlimCheckBoxField id="permissions.business.actions.process.get" label="get" />
          <SlimCheckBoxField id="permissions.business.actions.process.update" label="update" />
        </Stack>
      </BlockCard>,
    },
  ]
}
import { createSlice } from "@reduxjs/toolkit";
import planningListCall from "./_sliceCalls/planningListCall";
import planningAggregateListCall from "./_sliceCalls/planningAggregateListCall";
import planningCreateCall from "./_sliceCalls/planningCreateCall";
import planningUpdateCall from "./_sliceCalls/planningUpdateCall";
import planningDeleteCall from "./_sliceCalls/planningDeleteCall";
import businessUnitList_FullCall from "./_sliceCalls/businessUnitList_FullCall";
import planningForecastGetCall from "./_sliceCalls/planningForecastGetCall";
import planningForecastCall from "./_sliceCalls/planningForecastCall";

export const planningSlice = createSlice({
  name: "planning",
  initialState: {
    planningAggregated: [],
    planningList: [],
    tempPlanningList: [],
    businessUnitList: [],
    planningForecast: [],
  },
  reducers: {
    setPlanningAggregated: (state, action) => {
      state.planningAggregated = action.payload;
    },

    setPlanningList: (state, action) => {
      state.planningList = action.payload;
    },

    updateTempPlanningList: (state, action) => {
      state.tempPlanningList = action.payload;
    },

    setBusinessUnitList: (state, action) => {
      state.businessUnitList = action.payload;
    },

    setPlanningForecast: (state, action) => {
      state.planningForecast = action.payload;
    },
  },
});

export default planningSlice.reducer;

export const {
  updateTempPlanningList
} = planningSlice.actions;

// SELECTS
export const selectPlanningAggregated = (state) => state.planning.planningAggregated;
export const selectPlanningList = (state) => state.planning.planningList;
export const selectTempPlanningList = (state) => state.planning.tempPlanningList;
export const selectBusinessUnitList = (state) => state.planning.businessUnitList;
export const selectPlanningForecast = (state) => state.planning.planningForecast;

// CALLS
export const planningAggregateList = () => (dispatch, getState) => {
  planningAggregateListCall(planningSlice.actions, dispatch, getState());
};

export const planningList = ({ year, productId }) => (dispatch) => {
  planningListCall(planningSlice.actions, dispatch, { year, productId });
};

export const planningCreate = ({
  name,
  year,
  productId,
  startDate,
  endDate,
  startWeek,
  endWeek,
  target,
  isDefault
}) => (dispatch) => {
  planningCreateCall({ planningList }, dispatch, {
    name,
    year,
    productId,
    startDate,
    endDate,
    startWeek,
    endWeek,
    target,
    defaultPlan: isDefault
  });
};

export const planningSingleUpdate = ({
  id,
  name,
  year,
  productId,
  startDate,
  endDate,
  startWeek,
  endWeek,
  target
}) => (dispatch) => {
  planningUpdateCall({ planningList }, dispatch,
    [{
      id,
      name,
      year,
      productId,
      startDate,
      endDate,
      startWeek,
      endWeek,
      target
    }],
    year,
    productId
  );
};

export const planningUpdate = (year, productId) => (dispatch, getState) => {
  let nPlanningValues = getState().planning.tempPlanningList;
  // merge temp with original and send it 
  planningUpdateCall({ planningList }, dispatch, nPlanningValues, year, productId);
};

export const planningDelete = ({ id, year, productId }) => (dispatch) => {
  planningDeleteCall({ planningList }, dispatch, { id, year, productId });
};

export const businessUnitList_Full = () => (dispatch, getState) => {
  businessUnitList_FullCall(planningSlice.actions, dispatch, getState());
};

export const planningForecastGet = ({ year, productId }) => (dispatch) => {
  planningForecastGetCall(planningSlice.actions, dispatch, { year, productId });
}

export const planningForecast = ({ year, productId, forecasts }) => (dispatch) => {
  planningForecastCall({ planningForecastGet }, dispatch, {
    year, productId, forecasts
  });
}
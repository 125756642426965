export const csvFields = (t) => {
  return [
    {
      field: "operation",
      header: t("business.audit.ws.columns_operation"),
    },{
      field: "entity",
      header: t("business.audit.ws.columns_entity"),
    },{
      field: "userRequest",
      header: t("business.audit.ws.columns_userRequest"),
    },{
      field: "requestTime",
      header: t("business.audit.ws.columns_requestTime"),
    },{
      field: "responseTime",
      header: t("business.audit.ws.columns_responseTime"),
    },{
      field: "error",
      header: t("business.audit.ws.columns_error"),
    },{
      field: "ipAddress",
      header: t("business.audit.ws.columns_ipAddress"),
    }
  ];
}
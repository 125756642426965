import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { useUser } from "../../../../common/hooks";
import ActionsPopover from "../../../../common/components/ActionsPopover/ActionsPopover";
import CheckIcon from "@mui/icons-material/Check";

export default function AddressAggregatedDataGridActions({ row, onAcceptAll }) {
  const { t } = useTranslation();
  const user = useUser();

  const onAcceptAllHandler = (e) => {
    e.preventDefault();
    onAcceptAll && onAcceptAll(row);
  };

  let actions = [];
  if (user.hasPermission("orders.actions.received.accept") && row.status === "CO") {
    actions.push(
      <Button onClick={onAcceptAllHandler}>
        <Grid container spacing={2} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <CheckIcon />
          </Grid>
          <Grid item>{t("orders.AggregatedOrders.actions.acceptAll")}</Grid>
        </Grid>
      </Button>
    )
  }
  return actions?.length > 0 ? (
    <Grid container spacing={1} justifyContent="flex-end">
      <Grid item>
        <ActionsPopover
          actions={actions}
        />
      </Grid>
    </Grid>
  ) : null;
}

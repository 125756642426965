import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const orderEntityAddressesCall = async ({ setOrderAddresses }, dispatch, { id }) => {
  let rh = requestHelper(dispatch, "ORDER_ENTITY_ADDRESSES");
  try {
    let result = await call({
      type: "ORDER",
      subType: "ENTITY_ADDRESSES",
      request: { id },
    });
    dispatch(setOrderAddresses(result.addresses || []));
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
};

export default orderEntityAddressesCall;

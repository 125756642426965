import moment from "moment";
import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const auditListAuditWsCall = async ({ clearAuditWsList, setAuditWsList, setAuditWsListTotalRows, setAuditWsFilter }, dispatch, { filter, clear }) => {
  let rh = requestHelper(dispatch, "LIST_AUDIT_WS");
  if (clear) {
    dispatch(clearAuditWsList());
  }

  const requestDateIni = (filter.isDefaultFilter || !filter.requestDateIni) ? moment().add(-1, "day").toISOString() : filter.requestDateIni;
  const requestDateEnd = (filter.isDefaultFilter || !filter.requestDateEnd) ? moment().toISOString() : filter.requestDateEnd;

  dispatch(setAuditWsFilter({
    ...filter,
    requestDateIni,
    requestDateEnd
  }));

  try {
    let result = await call({
      type: "AUDIT",
      subType: "LIST_AUDIT_WS",
      request: {
        ...filter,
        requestDateIni,
        requestDateEnd
      },
    });

    if (result && result.items && result.items.length > 0) {
      dispatch(setAuditWsList(result.items));
      dispatch(setAuditWsListTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default auditListAuditWsCall;

import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { Grid, Button } from "@mui/material";

import { styles } from "./ButtonWithConfirm.styles";
import CustomModal from "../CustomModal/CustomModal";
import { TextInput } from "../../inputs";

function ButtonWithConfirm({ classes, label, buttonProps, modalProps = {}, onConfirm, children, commentProps }) {
  const [modal, setModal] = useState(false);
  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);

  const btnProps = {
    variant: "contained",
    color: "primary",
    size: "medium",
    type: "button",
    ...buttonProps
  }

  const { title, confirmLabel, cancelLabel, height } = modalProps;


  const onConfirmHandler = (e) => {
    e.stopPropagation();
    setError(false);
    if (commentProps && commentProps.required && !comment) {
      setError(true);
      return;
    }
    setModal(false);
    onConfirm && onConfirm(comment);
  }

  return (
    <div className={classes.root}>
      <Button
        {...btnProps}
        onClick={() => setModal(true)}
      >
        {label}
      </Button>
      <CustomModal open={modal} setOpen={setModal} size="medium" height={height} title={title}>
        <Grid container spacing={2} direction="column" justifyContent="space-between" height={height - 65}>
          {children && (<Grid item>
            {children}
          </Grid>
          )}
          {commentProps && (
            <Grid item>
              <TextInput rows={2} required={commentProps.required} label={commentProps.label} onChange={(value) => setComment(value)} error={error} />
            </Grid>
          )}
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  size="medium"
                  onClick={(e) => {
                    e.stopPropagation();
                    setModal(false);
                  }}
                >
                  {cancelLabel}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  {...btnProps}
                  size="medium"
                  onClick={(e) => {
                    onConfirmHandler && onConfirmHandler(e);
                  }}
                >
                  {confirmLabel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>
    </div>
  );
}

export default withStyles(ButtonWithConfirm, styles);
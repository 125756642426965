import {createSlice} from "@reduxjs/toolkit";
import {
  accountingDefaultFilter,
  auditDataDefaultFilter,
  auditExternalDefaultFilter,
  auditImportDefaultFilter,
  auditJobDefaultFilter,
  auditLoginsDefaultFilter,
  auditWsDefaultFilter,
  historyOrderMovementsDefaultFilter,
  processListDefaultFilter,
  queriesReportMissingShipmentsDefaultFilter,
  queriesReportOrdersOverviewDefaultFilter, queriesReportProductDefaultFilter,
  queriesReportProductPricesDefaultFilter,
  reportSuppliersDefaultFilter,
  reportWeeklyUnitsAcceptedDefaultFilter,
  reportWeeklyUnitsDefaultFilter,
  reportWeeklyUnitsDeliveredDefaultFilter
} from "./_common/businessFilters";
import businessListAccountingDocsCall from "./_sliceCalls/businessListAccountingDocsCall";
import businessGetAccountingDocCall from "./_sliceCalls/businessGetAccountingDocCall";
import accountingSetAvailableCall from "./_sliceCalls/accountingSetAvailableCall";
import auditListAuditDataCall from "./_sliceCalls/auditListAuditDataCall";
import auditListAuditImportCall from "./_sliceCalls/auditListAuditImportCall";
import auditListAuditLoginsCall from "./_sliceCalls/auditListAuditLoginsCall";
import auditListAuditWsCall from "./_sliceCalls/auditListAuditWsCall";
import auditGetAuditDataCall from "./_sliceCalls/auditGetAuditDataCall";
import auditGetAuditImportCall from "./_sliceCalls/auditGetAuditImportCall";
import auditGetAuditLoginsCall from "./_sliceCalls/auditGetAuditLoginsCall";
import auditGetAuditWsCall from "./_sliceCalls/auditGetAuditWsCall";
import businessReportOrdersMovementsCall from "./_sliceCalls/businessReportOrdersMovementsCall";
import businessReportMissingShipmentsCall from "./_sliceCalls/businessReportMissingShipmentsCall";
import businessReportOrdersOverviewCall from "./_sliceCalls/businessReportOrdersOverviewCall";
import businessReportProductPricesCall from "./_sliceCalls/businessReportProductPricesCall";
import businessReportWeeklyUnitsCall from "./_sliceCalls/businessReportWeeklyUnitsCall";
import businessReportWeeklyUnitsAcceptedCall from "./_sliceCalls/businessReportWeeklyUnitsAcceptedCall";
import businessReportWeeklyUnitsDeliveredCall from "./_sliceCalls/businessReportWeeklyUnitsDeliveredCall";
import businessReportSuppliersCall from "./_sliceCalls/businessReportSuppliersCall";
import auditListAuditExternalCall from "./_sliceCalls/auditListAuditExternalCall";
import auditGetAuditExternalCall from "./_sliceCalls/auditGetAuditExternalCall";
import auditListAuditJobCall from "./_sliceCalls/auditListAuditJobCall";
import auditGetAuditJobCall from "./_sliceCalls/auditGetAuditJobCall";
import auditRetryJobCall from "./_sliceCalls/auditRetryJobCall";
import auditGetAuditI18nCall from "./_sliceCalls/auditGetAuditI18nCall";
import auditListAuditI18nCall from "./_sliceCalls/auditListAuditI18nCall";
import businessGetProcessDetailCall from "./_sliceCalls/businessGetProcessDetailCall";
import businessListProcessCall from "./_sliceCalls/businessListProcessCall";
import businessGetProcessHistoryCall from "./_sliceCalls/businessGetProcessHistoryCall";
import businessProcessTaskAddUserCall from "./_sliceCalls/businessProcessTaskAddUserCall";
import auditRetryExternalCall from "./_sliceCalls/auditRetryExternalCall";
import businessReportProductCall from "./_sliceCalls/businessReportProductCall";

export const businessSlice = createSlice({
  name: "business",
  initialState: {
    accountingFilter: accountingDefaultFilter,
    accountingList: null,
    accountingListTotalRows: 0,
    accountingDetails: null,

    auditDataFilter: auditDataDefaultFilter,
    auditDataList: null,
    auditDataListTotalRows: 0,
    auditDataDetails: null,
    auditImportFilter: auditImportDefaultFilter,
    auditImportList: null,
    auditImportListTotalRows: 0,
    auditImportDetails: null,
    auditLoginsFilter: auditLoginsDefaultFilter,
    auditLoginsList: null,
    auditLoginsListTotalRows: 0,
    auditLoginsDetails: null,
    auditWsFilter: auditWsDefaultFilter,
    auditWsList: null,
    auditWsListTotalRows: 0,
    auditWsDetails: null,
    auditExternalFilter: auditExternalDefaultFilter,
    auditExternalList: null,
    auditExternalListTotalRows: 0,
    auditExternalDetails: null,
    auditJobFilter: auditJobDefaultFilter,
    auditJobList: null,
    auditJobListTotalRows: 0,
    auditJobDetails: null,

    historyOrderMovementsFilter: historyOrderMovementsDefaultFilter,
    historyOrderMovementsList: null,
    historyOrderMovementsListTotalRows: 0,

    queriesReportOrdersOverviewFilter: queriesReportOrdersOverviewDefaultFilter,
    queriesReportOrdersOverviewList: null,
    queriesReportOrdersOverviewListTotalRows: 0,
    queriesReportMissingShipmentsFilter: queriesReportMissingShipmentsDefaultFilter,
    queriesReportMissingShipmentsList: null,
    queriesReportMissingShipmentsListTotalRows: 0,
    queriesReportProductPricesFilter: queriesReportProductPricesDefaultFilter,
    queriesReportProductPricesList: null,
    queriesReportProductPricesListTotalRows: 0,
    queriesReportProductFilter: queriesReportProductDefaultFilter,
    queriesReportProductList: null,
    queriesReportProductListTotalRows: 0,

    reportWeeklyUnitsFilter: reportWeeklyUnitsDefaultFilter,
    reportWeeklyUnitsList: null,
    reportWeeklyUnitsListTotalRows: 0,
    reportWeeklyUnitsAcceptedFilter: reportWeeklyUnitsAcceptedDefaultFilter,
    reportWeeklyUnitsAcceptedList: null,
    reportWeeklyUnitsAcceptedListTotalRows: 0,
    reportWeeklyUnitsDeliveredFilter: reportWeeklyUnitsDeliveredDefaultFilter,
    reportWeeklyUnitsDeliveredList: null,
    reportWeeklyUnitsDeliveredListTotalRows: 0,
    reportSuppliersList: null,
    reportSuppliersCostByDeliveryList: null,
    reportSuppliersPlatformCostList: null,
    reportSuppliersTransportCostList: null,
    reportSuppliersFilter: reportSuppliersDefaultFilter,

    processFilter: processListDefaultFilter,
    processList: null,
    processListTotalRows: 0,
    processDetails: null,
    processHistory: [],
  },
  reducers: {
    // ACCOUNTING
    clearAccountingList: (state) => {
      state.accountingList = [];
      state.accountingListTotalRows = 0;
    },
    setAccountingList: (state, action) => {
      state.accountingList = [...state.accountingList, ...action.payload];
    },
    setAccountingListTotalRows: (state, action) => {
      state.accountingListTotalRows = action.payload;
    },
    setAccountingDetails: (state, action) => {
      state.accountingDetails = action.payload;
    },
    // AUDIT DATA
    clearAuditDataList: (state) => {
      state.auditDataList = [];
      state.auditDataListTotalRows = 0;
    },
    setAuditDataList: (state, action) => {
      state.auditDataList = [...state.auditDataList, ...action.payload];
    },
    setAuditDataListTotalRows: (state, action) => {
      state.auditDataListTotalRows = action.payload;
    },
    setAuditDataDetails: (state, action) => {
      state.auditDataDetails = action.payload;
    },
    setAuditDataFilter: (state, action) => {
      state.auditDataFilter = action.payload;
    },
    // AUDIT IMPORT
    clearAuditImportList: (state) => {
      state.auditImportList = [];
      state.auditImportListTotalRows = 0;
    },
    setAuditImportList: (state, action) => {
      state.auditImportList = [...state.auditImportList, ...action.payload];
    },
    setAuditImportListTotalRows: (state, action) => {
      state.auditImportListTotalRows = action.payload;
    },
    setAuditImportDetails: (state, action) => {
      state.auditImportDetails = action.payload;
    },
    setAuditImportFilter: (state, action) => {
      state.auditImportFilter = action.payload;
    },
    // AUDIT LOGINS
    clearAuditLoginsList: (state) => {
      state.auditLoginsList = [];
      state.auditLoginsListTotalRows = 0;
    },
    setAuditLoginsList: (state, action) => {
      state.auditLoginsList = [...state.auditLoginsList, ...action.payload];
    },
    setAuditLoginsListTotalRows: (state, action) => {
      state.auditLoginsListTotalRows = action.payload;
    },
    setAuditLoginsDetails: (state, action) => {
      state.auditLoginsDetails = action.payload;
    },
    setAuditLoginsFilter: (state, action) => {
      state.auditLoginsFilter = action.payload;
    },
    // AUDIT WS
    clearAuditWsList: (state) => {
      state.auditWsList = [];
      state.auditWsListTotalRows = 0;
    },
    setAuditWsList: (state, action) => {
      state.auditWsList = [...state.auditWsList, ...action.payload];
    },
    setAuditWsListTotalRows: (state, action) => {
      state.auditWsListTotalRows = action.payload;
    },
    setAuditWsDetails: (state, action) => {
      state.auditWsDetails = action.payload;
    },
    setAuditWsFilter: (state, action) => {
      state.auditWsFilter = action.payload;
    },
    // AUDIT EXTERNAL
    clearAuditExternalList: (state) => {
      state.auditExternalList = [];
      state.auditExternalListTotalRows = 0;
    },
    setAuditExternalList: (state, action) => {
      state.auditExternalList = [...state.auditExternalList, ...action.payload];
    },
    setAuditExternalListTotalRows: (state, action) => {
      state.auditExternalListTotalRows = action.payload;
    },
    setAuditExternalDetails: (state, action) => {
      state.auditExternalDetails = action.payload;
    },
    setAuditExternalFilter: (state, action) => {
      state.auditExternalFilter = action.payload;
    },
    // AUDIT JOB
    clearAuditJobList: (state) => {
      state.auditJobList = [];
      state.auditJobListTotalRows = 0;
    },
    setAuditJobList: (state, action) => {
      state.auditJobList = [...state.auditJobList, ...action.payload];
    },
    setAuditJobListTotalRows: (state, action) => {
      state.auditJobListTotalRows = action.payload;
    },
    setAuditJobDetails: (state, action) => {
      state.auditJobDetails = action.payload;
    },
    setAuditJobFilter: (state, action) => {
      state.auditJobFilter = action.payload;
    },
    // AUDIT I18n
    clearAuditI18nList: (state) => {
      state.auditI18nList = [];
      state.auditI18nListTotalRows = 0;
    },
    setAuditI18nList: (state, action) => {
      state.auditI18nList = [...state.auditI18nList, ...action.payload];
    },
    setAuditI18nListTotalRows: (state, action) => {
      state.auditI18nListTotalRows = action.payload;
    },
    setAuditI18nDetails: (state, action) => {
      state.auditI18nDetails = action.payload;
    },
    setAuditI18nFilter: (state, action) => {
      state.auditI18nFilter = action.payload;
    },
    // HISTORY - ORDER MOVEMENTS
    clearHistoryOrderMovementsList: (state) => {
      state.historyOrderMovementsList = [];
      state.historyOrderMovementsListTotalRows = 0;
    },
    setHistoryOrderMovementsList: (state, action) => {
      state.historyOrderMovementsList = [...state.historyOrderMovementsList, ...action.payload];
    },
    setHistoryOrderMovementsListTotalRows: (state, action) => {
      state.historyOrderMovementsListTotalRows = action.payload;
    },
    setHistoryOrderMovementsFilter: (state, action) => {
      state.historyOrderMovementsFilter = action.payload;
    },
    // QUERIES - REPORT ORDERS OVERVIEW
    clearQueriesReportOrdersOverviewList: (state) => {
      state.queriesReportOrdersOverviewList = [];
      state.queriesReportOrdersOverviewListTotalRows = 0;
    },
    setQueriesReportOrdersOverviewList: (state, action) => {
      state.queriesReportOrdersOverviewList = [...state.queriesReportOrdersOverviewList, ...action.payload];
    },
    setQueriesReportOrdersOverviewListTotalRows: (state, action) => {
      state.queriesReportOrdersOverviewListTotalRows = action.payload;
    },
    setQueriesReportOrdersOverviewFilter: (state, action) => {
      state.queriesReportOrdersOverviewFilter = action.payload;
    },
    // QUERIES - MISSING SHIPMENTS
    clearQueriesReportMissingShipmentsList: (state) => {
      state.queriesReportMissingShipmentsList = [];
      state.queriesReportMissingShipmentsListTotalRows = 0;
    },
    setQueriesReportMissingShipmentsList: (state, action) => {
      state.queriesReportMissingShipmentsList = [...state.queriesReportMissingShipmentsList, ...action.payload];
    },
    setQueriesReportMissingShipmentsListTotalRows: (state, action) => {
      state.queriesReportMissingShipmentsListTotalRows = action.payload;
    },
    setQueriesReportMissingShipmentsFilter: (state, action) => {
      state.queriesReportMissingShipmentsFilter = action.payload;
    },
    // QUERIES - REPORT PRODUCT PRICES
    clearQueriesReportProductPricesList: (state) => {
      state.queriesReportProductPricesList = [];
      state.queriesReportProductPricesListTotalRows = 0;
    },
    setQueriesReportProductPricesList: (state, action) => {
      state.queriesReportProductPricesList = [...state.queriesReportProductPricesList, ...action.payload];
    },
    setQueriesReportProductPricesListTotalRows: (state, action) => {
      state.queriesReportProductPricesListTotalRows = action.payload;
    },
    setQueriesReportProductPricesFilter: (state, action) => {
      state.queriesReportProductPricesFilter = action.payload;
    },
    // QUERIES - REPORT PRODUCT
    clearQueriesReportProductList: (state) => {
      state.queriesReportProductList = [];
      state.queriesReportProductListTotalRows = 0;
    },
    setQueriesReportProductList: (state, action) => {
      state.queriesReportProductList = [...state.queriesReportProductList, ...action.payload];
    },
    setQueriesReportProductListTotalRows: (state, action) => {
      state.queriesReportProductListTotalRows = action.payload;
    },
    setQueriesReportProductFilter: (state, action) => {
      state.queriesReportProductFilter = action.payload;
    },
    // REPORTS - WEEKLY UNITS
    clearReportWeeklyUnitsList: (state) => {
      state.reportWeeklyUnitsList = [];
      state.reportWeeklyUnitsListTotalRows = 0;
    },
    setReportWeeklyUnitsList: (state, action) => {
      state.reportWeeklyUnitsList = [...state.reportWeeklyUnitsList, ...action.payload];
    },
    setReportWeeklyUnitsListTotalRows: (state, action) => {
      state.reportWeeklyUnitsListTotalRows = action.payload;
    },
    setReportWeeklyUnitsFilter: (state, action) => {
      state.reportWeeklyUnitsFilter = action.payload;
    },
    // REPORTS - WEEKLY UNITS ACCEPTED
    clearReportWeeklyUnitsAcceptedList: (state) => {
      state.reportWeeklyUnitsAcceptedList = [];
      state.reportWeeklyUnitsAcceptedListTotalRows = 0;
    },
    setReportWeeklyUnitsAcceptedList: (state, action) => {
      state.reportWeeklyUnitsAcceptedList = [...state.reportWeeklyUnitsAcceptedList, ...action.payload];
    },
    setReportWeeklyUnitsAcceptedListTotalRows: (state, action) => {
      state.reportWeeklyUnitsAcceptedListTotalRows = action.payload;
    },
    setReportWeeklyUnitsAcceptedFilter: (state, action) => {
      state.reportWeeklyUnitsAcceptedFilter = action.payload;
    },
    // REPORTS - WEEKLY UNITS DELIVERED
    clearReportWeeklyUnitsDeliveredList: (state) => {
      state.reportWeeklyUnitsDeliveredList = [];
      state.reportWeeklyUnitsDeliveredListTotalRows = 0;
    },
    setReportWeeklyUnitsDeliveredList: (state, action) => {
      state.reportWeeklyUnitsDeliveredList = [...state.reportWeeklyUnitsDeliveredList, ...action.payload];
    },
    setReportWeeklyUnitsDeliveredListTotalRows: (state, action) => {
      state.reportWeeklyUnitsDeliveredListTotalRows = action.payload;
    },
    setReportWeeklyUnitsDeliveredFilter: (state, action) => {
      state.reportWeeklyUnitsDeliveredFilter = action.payload;
    },
    // REPORTS - SUPPLIERS
    setReportSuppliersList: (state, action) => {
      state.reportSuppliersList = action.payload;
    },
    setReportSuppliersCostByDeliveryList: (state, action) => {
      state.reportSuppliersCostByDeliveryList = action.payload;
    },
    setReportSuppliersPlatformCostList: (state, action) => {
      state.reportSuppliersPlatformCostList = action.payload;
    },
    setReportSuppliersTransportCostList: (state, action) => {
      state.reportSuppliersTransportCostList = action.payload;
    },
    setReportSuppliersFilter: (state, action) => {
      state.reportSuppliersFilter = action.payload;
    },
    // PROCESS
    clearBusinessProcessList: (state) => {
      state.processList = [];
      state.processListTotalRows = 0;
    },
    setBusinessProcessList: (state, action) => {
      state.processList = [...state.processList, ...action.payload];
    },
    setBusinessProcessListTotalRows: (state, action) => {
      state.processListTotalRows = action.payload;
    },
    setBusinessProcessFilter: (state, action) => {
      state.processFilter = action.payload;
    },
    setBusinessProcessDetails: (state, action) => {
      state.processDetails = action.payload;
    },
    setBusinessProcessHistory: (state, action) => {
      state.processHistory = action.payload;
    },
  },
});

export default businessSlice.reducer;


// SELECTS
export const selectAccountingFilter = (state) => state.business.accountingFilter;
export const selectAccountingList = (state) => state.business.accountingList;
export const selectAccountingListTotalRows = (state) => state.business.accountingListTotalRows;
export const selectAccountingDetails = (state) => state.business.accountingDetails;

export const selectAuditDataFilter = (state) => state.business.auditDataFilter;
export const selectAuditDataList = (state) => state.business.auditDataList;
export const selectAuditDataListTotalRows = (state) => state.business.auditDataListTotalRows;
export const selectAuditDataDetails = (state) => state.business.auditDataDetails;
export const selectAuditImportFilter = (state) => state.business.auditImportFilter;
export const selectAuditImportList = (state) => state.business.auditImportList;
export const selectAuditImportListTotalRows = (state) => state.business.auditImportListTotalRows;
export const selectAuditImportDetails = (state) => state.business.auditImportDetails;
export const selectAuditLoginsFilter = (state) => state.business.auditLoginsFilter;
export const selectAuditLoginsList = (state) => state.business.auditLoginsList;
export const selectAuditLoginsListTotalRows = (state) => state.business.auditLoginsListTotalRows;
export const selectAuditLoginsDetails = (state) => state.business.auditLoginsDetails;
export const selectAuditWsFilter = (state) => state.business.auditWsFilter;
export const selectAuditWsList = (state) => state.business.auditWsList;
export const selectAuditWsListTotalRows = (state) => state.business.auditWsListTotalRows;
export const selectAuditWsDetails = (state) => state.business.auditWsDetails;
export const selectAuditExternalFilter = (state) => state.business.auditExternalFilter;
export const selectAuditExternalList = (state) => state.business.auditExternalList;
export const selectAuditExternalListTotalRows = (state) => state.business.auditExternalListTotalRows;
export const selectAuditExternalDetails = (state) => state.business.auditExternalDetails;
export const selectAuditJobFilter = (state) => state.business.auditJobFilter;
export const selectAuditJobList = (state) => state.business.auditJobList;
export const selectAuditJobListTotalRows = (state) => state.business.auditJobListTotalRows;
export const selectAuditJobDetails = (state) => state.business.auditJobDetails;
export const selectAuditI18nFilter = (state) => state.business.auditI18nFilter;
export const selectAuditI18nList = (state) => state.business.auditI18nList;
export const selectAuditI18nListTotalRows = (state) => state.business.auditI18nListTotalRows;
export const selectAuditI18nDetails = (state) => state.business.auditI18nDetails;

export const selectHistoryOrderMovementsFilter = (state) => state.business.historyOrderMovementsFilter;
export const selectHistoryOrderMovementsList = (state) => state.business.historyOrderMovementsList;
export const selectHistoryOrderMovementsListTotalRows = (state) => state.business.historyOrderMovementsListTotalRows;

export const selectQueriesReportOrdersOverviewFilter = (state) => state.business.queriesReportOrdersOverviewFilter;
export const selectQueriesReportOrdersOverviewList = (state) => state.business.queriesReportOrdersOverviewList;
export const selectQueriesReportOrdersOverviewListTotalRows = (state) => state.business.queriesReportOrdersOverviewListTotalRows;
export const selectQueriesReportMissingShipmentsFilter = (state) => state.business.queriesReportMissingShipmentsFilter;
export const selectQueriesReportMissingShipmentsList = (state) => state.business.queriesReportMissingShipmentsList;
export const selectQueriesReportMissingShipmentsListTotalRows = (state) => state.business.queriesReportMissingShipmentsListTotalRows;
export const selectQueriesReportProductPricesFilter = (state) => state.business.queriesReportProductPricesFilter;
export const selectQueriesReportProductPricesList = (state) => state.business.queriesReportProductPricesList;
export const selectQueriesReportProductPricesListTotalRows = (state) => state.business.queriesReportProductPricesListTotalRows;
export const selectQueriesReportProductFilter = (state) => state.business.queriesReportProductFilter;
export const selectQueriesReportProductList = (state) => state.business.queriesReportProductList;
export const selectQueriesReportProductListTotalRows = (state) => state.business.queriesReportProductListTotalRows;

export const selectReportReportWeeklyUnitsFilter = (state) => state.business.reportWeeklyUnitsFilter;
export const selectReportReportWeeklyUnitsList = (state) => state.business.reportWeeklyUnitsList;
export const selectReportReportWeeklyUnitsListTotalRows = (state) => state.business.reportWeeklyUnitsListTotalRows;
export const selectReportReportWeeklyUnitsAcceptedFilter = (state) => state.business.reportWeeklyUnitsAcceptedFilter;
export const selectReportReportWeeklyUnitsAcceptedList = (state) => state.business.reportWeeklyUnitsAcceptedList;
export const selectReportReportWeeklyUnitsAcceptedListTotalRows = (state) => state.business.reportWeeklyUnitsAcceptedListTotalRows;
export const selectReportReportWeeklyUnitsDeliveredFilter = (state) => state.business.reportWeeklyUnitsDeliveredFilter;
export const selectReportReportWeeklyUnitsDeliveredList = (state) => state.business.reportWeeklyUnitsDeliveredList;
export const selectReportReportWeeklyUnitsDeliveredListTotalRows = (state) => state.business.reportWeeklyUnitsDeliveredListTotalRows;
export const selectReportSuppliersList = (state) => state.business.reportSuppliersList;
export const selectReportSuppliersCostByDeliveryList = (state) => state.business.reportSuppliersCostByDeliveryList;
export const selectReportSuppliersPlatformCostList = (state) => state.business.reportSuppliersPlatformCostList;
export const selectReportSuppliersTransportCostList = (state) => state.business.reportSuppliersTransportCostList;
export const selectReportSuppliersFilter = (state) => state.business.reportSuppliersFilter;
export const selectProcessFilter = (state) => state.business.processFilter;
export const selectProcessList = (state) => state.business.processList;
export const selectProcessListTotalRows = (state) => state.business.processListTotalRows;
export const selectProcessDetails = (state) => state.business.processDetails;
export const selectProcessHistory = (state) => state.business.processHistory;

// CALLS
export const businessListAccountingDocs = (filter, clear = false) => (dispatch) => {
  businessListAccountingDocsCall(businessSlice.actions, dispatch, { filter, clear });
};

export const businessGetAccountingDoc = (id) => (dispatch) => {
  businessGetAccountingDocCall(businessSlice.actions, dispatch, { id })
}

export const accountingSetAvailable = (documents) => (dispatch, getState) => {
  const filter = getState().business.accountingFilter;
  accountingSetAvailableCall({ businessListAccountingDocs }, dispatch, { documents, filter })
}

export const auditListAuditData = (filter, clear = false) => (dispatch) => {
  auditListAuditDataCall(businessSlice.actions, dispatch, { filter, clear });
};

export const auditListAuditImport = (filter, clear = false) => (dispatch) => {
  auditListAuditImportCall(businessSlice.actions, dispatch, { filter, clear });
};

export const auditListAuditLogins = (filter, clear = false) => (dispatch) => {
  auditListAuditLoginsCall(businessSlice.actions, dispatch, { filter, clear });
};

export const auditListAuditWs = (filter, clear = false) => (dispatch) => {
  auditListAuditWsCall(businessSlice.actions, dispatch, { filter, clear });
};

export const auditGetAuditData = (id) => (dispatch) => {
  auditGetAuditDataCall(businessSlice.actions, dispatch, { id })
}

export const auditGetAuditImport = (id) => (dispatch) => {
  auditGetAuditImportCall(businessSlice.actions, dispatch, { id })
}

export const auditGetAuditLogins = ({ sessionId, action }) => (dispatch) => {
  auditGetAuditLoginsCall(businessSlice.actions, dispatch, { sessionId, action })
}

export const auditGetAuditWs = (id) => (dispatch) => {
  auditGetAuditWsCall(businessSlice.actions, dispatch, { id })
}

export const businessReportOrdersMovements = (filter, clear = false) => (dispatch, getState) => {
 businessReportOrdersMovementsCall(businessSlice.actions, dispatch,getState(), { filter, clear });
};

export const businessReportMissingShipments = (filter, clear = false) => (dispatch) => {
  businessReportMissingShipmentsCall(businessSlice.actions, dispatch, { filter, clear });
};

export const businessReportOrdersOverview = (filter, clear = false) => (dispatch, getState) => {
  businessReportOrdersOverviewCall(businessSlice.actions, dispatch, getState(), { filter, clear });
};

export const businessReportProductPrices = (filter, clear = false) => (dispatch) => {
  businessReportProductPricesCall(businessSlice.actions, dispatch, { filter, clear });
};

export const businessReportProduct = (filter, clear = false) => (dispatch, getState) => {
  businessReportProductCall(businessSlice.actions, dispatch, getState(), { filter, clear });
};

export const businessReportWeeklyUnits = (filter, clear = false) => (dispatch) => {
  businessReportWeeklyUnitsCall(businessSlice.actions, dispatch, { filter, clear });
};

export const businessReportWeeklyUnitsAccepted = (filter, clear = false) => (dispatch) => {
  businessReportWeeklyUnitsAcceptedCall(businessSlice.actions, dispatch, { filter, clear });
};

export const businessReportWeeklyUnitsDelivered = (filter, clear = false) => (dispatch) => {
  businessReportWeeklyUnitsDeliveredCall(businessSlice.actions, dispatch, { filter, clear });
};

export const businessReportSuppliers = (filter) => (dispatch) => {
  businessReportSuppliersCall(businessSlice.actions, dispatch, { filter });
}

export const auditListAuditExternal = (filter, clear = false) => (dispatch) => {
  auditListAuditExternalCall(businessSlice.actions, dispatch, { filter, clear });
};

export const auditGetAuditExternal = (id) => (dispatch) => {
  auditGetAuditExternalCall(businessSlice.actions, dispatch, { id })
}
export const auditRetryExternal = (wsid, eventId) => (dispatch, getState) => {
  auditRetryExternalCall({ auditGetAuditExternal }, dispatch, { wsid, eventId })
}
export const auditListAuditJob = (filter, clear = false) => (dispatch) => {
  auditListAuditJobCall(businessSlice.actions, dispatch, { filter, clear });
};

export const auditGetAuditJob = (id) => (dispatch) => {
  auditGetAuditJobCall(businessSlice.actions, dispatch, { id })
}

export const auditRetryJob = (id) => (dispatch, getState) => {
  const filter = getState().business.auditJobFilter;
  auditRetryJobCall({ auditListAuditJob }, dispatch, { id, filter })
}

export const auditListAuditI18n = (filter, clear = false) => (dispatch) => {
  auditListAuditI18nCall(businessSlice.actions, dispatch, { filter, clear });
};

export const auditGetAuditI18n = (id) => (dispatch) => {
  auditGetAuditI18nCall(businessSlice.actions, dispatch, { id })
}

export const businessListProcess = (filter, clear = false) => (dispatch) => {
  businessListProcessCall(businessSlice.actions, dispatch, { filter, clear });
};

export const businessGetProcessDetail = (processId) => (dispatch) => {
  businessGetProcessDetailCall(businessSlice.actions, dispatch, { processId })
}
export const businessGetProcessHistory = (processId) => (dispatch) => {
  businessGetProcessHistoryCall(businessSlice.actions, dispatch, { processId })
}

export const businessProcessTaskAddUser = (processId, taskId, user) => (dispatch) => {
  businessProcessTaskAddUserCall(businessSlice.actions, dispatch, { processId, taskId, user})
}

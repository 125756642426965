import React from "react";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";
import { Grid, Button } from "@mui/material";

import { styles } from "./BatchInput.styles";
import BatchFields from "./BatchFields/BatchFields";
import moment from "moment";
import { getNewBatchQuantity } from "../../../orders.utils";

function BatchInput({ classes, product, batchs, onChange }) {
  const { t } = useTranslation();

  const addBatchHandler = () => {
    let nBatchs = batchs;
    nBatchs.push({
      batch: "",
      expirationDate: product.hasExpirationDate ? moment(new Date()) : undefined,
      weight: "",
      quantity: getNewBatchQuantity(product),
    });
    onChange && onChange(nBatchs);
  };

  const removeBatchHandler = (bIndex) => {
    let nBatchs = batchs;
    nBatchs.splice(bIndex, 1);
    onChange && onChange(nBatchs);
  };

  const updateBatchHandler = (bIndex, batch) => {
    let nBatchs = batchs;
    nBatchs[bIndex] = { ...batch };
    onChange && onChange(nBatchs);
  };

  return (
    <Grid container direction="column">
      {(product.productBatch || []).map((item, bIndex) => {
        return (
          <Grid item key={"batch_" + bIndex}>
            <BatchFields
              batch={item}
              product={product}
              onChange={(batch) => updateBatchHandler(bIndex, batch)}
              onRemove={() => removeBatchHandler(bIndex)}
            />
          </Grid>
        );
      })}
      <Grid item style={{ textAlign: "left" }}>
        <Button variant="contained" color="primary" size="small" onClick={() => addBatchHandler()}>
          {t("orders.AggregatedDetails.grid.addBatch")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default withStyles(BatchInput, styles)

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, List, ListItem, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { styles } from "./CommentsGrid.styles";
import { selectTaskListComments } from "../../../tasksSlice";
import { dateTimeFormatWithSeconds } from "../../../../../common/utils/formats";
import ContactCardSlim from "../../../../entities/_common/ContactCardSlim/ContactCardSlim";

function CommentsGrid({ classes }) {
  const { t } = useTranslation();
  const data = useSelector(selectTaskListComments);

  return (
    <div className={classes.root}>
      {data?.length > 0 ?
        <List>
          {data.map((item) => {
            return (
              <ListItem key={`comment_${item?.id}`}>
                <Grid container className={classes.root} >
                  <Grid item xs={12}>
                    <ContactCardSlim contact={item} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="left" >
                      [{dateTimeFormatWithSeconds(item.createdTime)}]: {item.comment}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            );
          })}
        </List> :
        <Typography color="textSecondary" style={{ width: "100%", textAlign: "center" }}>{t("tasks.CommentsGrid.details.nocomments")}</Typography>
      }
    </div>
  );
}

export default withStyles(CommentsGrid, styles);
import { Button, Grid } from "@mui/material";
import React from "react";
import DeleteButton from "../../../../common/components/dialogs/DeleteButton";
import { useUser } from "../../../../common/hooks";
import Permission from "../../../../app/components/Permission";
import { useSelector } from "react-redux";
import { selectEntityDetails } from "../../entitiesSlice";

export default function AddressesListActions({ row, onDelete, onConfirm, t, allowDelete, onEdit }) {
  const user = useUser();
  const details = useSelector(selectEntityDetails);

  return (
    <Grid container justifyContent="flex-end" spacing={1}>
      <Permission code="entities.actions.address.confirm">
        {user?.entity?.type === "P" && !row.confirmed && (
          <Grid item>
            <Button
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                onConfirm && onConfirm(row);
              }}
            >
              {t("entities.AddressesList.columns.confirm")}
            </Button>
          </Grid>
        )}
      </Permission>
      <Permission code="entities.actions.address.update">
        {row.confirmed && details?.status !== "PND" && (
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                onEdit && onEdit(row);
              }}
            >
              {t("entities.AddressesList.columns.editBtn")}
            </Button>
          </Grid>
        )}
      </Permission>
      <Permission code="entities.actions.address.disable">
        {(user?.entity?.type === "P" || user?.entity?.id === row.entityId) && details?.status !== "PND" &&
          allowDelete && (
            <Grid item>
              <DeleteButton
                labels={{
                  button: t("entities.Entities.confirmDeleteAddress.button"),
                  title: t("entities.Entities.confirmDeleteAddress.title"),
                  description: t("entities.Entities.confirmDeleteAddress.description"),
                  ok: t("entities.Entities.confirmDeleteAddress.ok"),
                  cancel: t("entities.Entities.confirmDeleteAddress.cancel"),
                }}
                onConfirm={() => {
                  onDelete(row);
                }}
              />
            </Grid>
          )}
      </Permission>
    </Grid>
  );
}

import * as Yup from "yup";
import { SelectField, MultiSelectField, DateTimePickerField, TextField } from "../../../../common/forms";
import { getCodeOptions, getSearchType } from "../../ordersIncidents.utils";

export const filterFields = () => (t, values) => {
  return [
    {
      size: 12,
      field: (
        <MultiSelectField
          id="codes"
          label={t("ordersIncidents.OrdersIncidentsList.filter.code")}
          options={getCodeOptions(t)}
        />
      ),
    },
    {
      size: 12,
      field: (
        <SelectField
          id="searchField"
          label={t("ordersIncidents.OrdersIncidentsList.filter.searchType")}
          options={getSearchType(t)}
        />
      ),
    },
    {
      size: 12,
      field: <DateTimePickerField id="startDate" label={t("ordersIncidents.OrdersIncidentsList.filter.startDate")} />,
      hidden: !values.searchField || ["ORDERID", "PRODUCT"].includes(values.searchField),
    },
    {
      size: 12,
      field: <DateTimePickerField id="endDate" label={t("ordersIncidents.OrdersIncidentsList.filter.endDate")} />,
      hidden: !values.searchField || ["ORDERID", "PRODUCT"].includes(values.searchField),
    },
    {
      size: 12,
      field: <TextField id="search" label={t("ordersIncidents.OrdersIncidentsList.filter.search")} />,
      hidden: !values.searchField || ["CREATEDTIME", "DELIVERYTIME"].includes(values.searchField),
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    code: Yup.array(),
    searchField: Yup.string().nullable(),
    startDate: Yup.string().when("searchField", {
      is: (val) => ["CREATEDTIME", "DELIVERYTIME"].includes(val),
      then: (schema) => schema.required(t("ordersIncidents.OrdersIncidentsList.filter.requiredDate")),
      otherwise: (schema) => schema.nullable(),
    }),
    endDate: Yup.string().when("searchField", {
      is: (val) => ["CREATEDTIME", "DELIVERYTIME"].includes(val),
      then: (schema) => schema.required(t("ordersIncidents.OrdersIncidentsList.filter.requiredDate")),
      otherwise: (schema) => schema.nullable(),
    }),
    search: Yup.string().when("searchField", {
      is: (val) => ["ORDERID", "PRODUCT"].includes(val),
      then: (schema) => schema.required(t("ordersIncidents.OrdersIncidentsList.filter.requiredSearch")),
      otherwise: (schema) => schema.nullable(),
    }),
  });
};

import { processImageUrl, saveFile } from "../../../api/s3";
import { asyncForEach, base64ToArrayBuffer, guid } from "../../../common/utils";

export const uploadOrderAttachment = async (fileStorageConfigs, promoterCode, orderId, file) => {
  const { provider, privateBucket } = fileStorageConfigs;

  const extension = file?.name ? file.name.slice(file.name.lastIndexOf(".")) : ".png";
  const filePath =
    `${promoterCode?.toUpperCase()}/${process.env.REACT_APP_ENV}/order/${orderId}/documents/` + guid() + extension;
  let url = await saveFile(
    provider,
    privateBucket,
    filePath,
    base64ToArrayBuffer(file.base64),
    file.mediaType
  );

  return url;
};

export const getOrderDocumentsSignedUrls = async (fileStorageConfigs, documents) => {
  let docsProcessed = [];
  await asyncForEach(documents, async (doc) => {
    docsProcessed.push({
      ...doc,
      signedUrl: await processImageUrl(fileStorageConfigs,  doc.url)
    });
  });
  return docsProcessed;
}
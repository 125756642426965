export const getVatOptions = (t, promoter) => {
  return (promoter?.refData?.vat || []).map((item) => {
    return {
      value: item,
      text: t("common.Enums.vat." + item),
    };
  });
};

export const getLocationsOptions = (locations) => {
  return (locations || []).map(({ id, name }) => {
    return {
      value: id,
      text: name,
    };
  });
};
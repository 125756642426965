import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Chip, Stack } from "@mui/material";

import { styles } from "./TaskInfoItem.styles";
import { multiLanguagePropToString } from "../../../../common/utils";

function TaskInfoItem({ classes, info, onTaskInfoSelected }) {
  const { i18n } = useTranslation();
  let chipColor = "default";
  if (info.type === "warning") {
    chipColor = "warning";
  }
  else if (info.type !== "text") {
    chipColor = "primary"
  }

  return (
    <div className={classes.root} onClick={() => onTaskInfoSelected(info)}>
      <Stack>
        <div>
          <Chip label={info.type} size="small" color={chipColor} className={classes.chip} />
        </div>
        {(info.type === "text" || info.type === "warning") &&
          <Typography style={{ margin: 0, fontSize: 12 }}>{multiLanguagePropToString(info.content, i18n.language)}</Typography>
        }
      </Stack>
    </div>
  );
}

export default withStyles(TaskInfoItem, styles);
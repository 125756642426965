import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const promoterEnableCutOffCall = async ({ listCutOffsAppend }, dispatch, state, { cutOffId }) => {
  const filter = state.entities.cutOffListFilter;
  let rh = requestHelper(dispatch, "ENABLE_CUT_OFF");
  try {
    await call({
      type: "PROMOTER",
      subType: "ENABLE_CUT_OFF",
      request: {
        id: cutOffId,
      },
    });
    setTimeout(() => {
      dispatch(setSuccess("entities", "PROMOTER_ENABLE_CUT_OFF"));
      dispatch(listCutOffsAppend(filter, true));
    }, 300);
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default promoterEnableCutOffCall;


const productsPurchases = {
  PendingPurchasesList: {
    header: {
      title: "Products Special Purchases",
      rowsInfo: "products"
    },
    columns: {
      description: "Product",
      quantity: "Quantity",
      newPrice: "Price",
      status: "Status",
    },
    actions: {
      acceptPurchase: "Accept",
      rejectPurchase: "Reject",
      proposePurchase: "Propose",
      executePurchase: "Execute"
    },
    filter: {
      status: "Status",
      owner: "Owner",
    }
  },
  PendingPurchaseForm: {
    form: {
      quantity: "Quantity",
      unitPrice: "Unit Price",
      price: "Total",
      btnCreate: "Create",
      comment: "Comment",
      btnAccept: "Accept",
      btnReject: "Reject",
      btnProposal: "Propose",
      btnExecute: "Execute",
      requiredQuantity: "Quantity is required",
      requiredPrice: "Price is required",
    }
  }
}

export default productsPurchases;
import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Alert, Button, Grid, Stack, ToggleButton, ToggleButtonGroup} from "@mui/material";

import {styles} from "./PricesList.styles";
import PricesDataGrid from "./PricesDataGrid/PricesDataGrid";
import PriceVariantForm from "./PriceVariantForm/PriceVariantForm";
import PromotionForm from "./PromotionForm/PromotionForm";

import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import CustomModal from "../../../common/displays/CustomModal/CustomModal";
import Permission from "../../../app/components/Permission";
import {useFirstLoad, useNavigate, usePromoter, useUser} from "../../../common/hooks";
import {formProductListFamily, productGroupContacts} from "../../products/productsSlice";
import SearchBox from "../../../common/components/inputs/SearchBox";
import {
  addTempPrice,
  clearTempPrices,
  clearTempValidation,
  entityListGroup_full,
  productCreatePrice,
  productPendingPriceAction,
  productPriceComparison,
  productPriceHistoryList,
  productPriceList,
  productRemovePrice,
  promoterListLocations_full,
  removeTempPrice,
  removeTempRetailPrice,
  removeTempSalePrice,
  selectLoader,
  selectPriceHistoryListFilter,
  selectPricesList,
  selectPricesListFilter,
  selectPricesListTotalRows,
  selectTempPrices,
  selectTempValidations,
  updateTempRetailPrice,
  updateTempSalePrice,
  updateTempValidation,
} from "../productsPricesSlice";
import PriceComparerModal from "./PriceComparerModal/PriceComparerModal";
import {mixPricesWithTemp} from "../_sliceCalls/_auxiliars";
import {guid} from "../../../common/utils";
import PriceRemoveModal from "./PriceRemoveModal/PriceRemoveModal";
import PriceValidationCommentModal from "./PriceValidationCommentModal/PriceValidationCommentModal";
import PriceHistoryModal from "../PriceHistory/PriceHistoryModal";

function PricesList({ classes, validation }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const promoter = usePromoter();
  const [modal, setModal] = useState(false);
  const [comparerModal, setComparerModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [priceToRemove, setPriceToRemove] = useState();
  const [modalPromotion, setModalPromotion] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [productHistory, setProductHistory] = useState();
  const [comparerProduct, setComparerProduct] = useState();
  const [comparerPrice, setComparerPrice] = useState();
  const pricesList = useSelector(selectPricesList);
  const totalRows = useSelector(selectPricesListTotalRows);
  const filter = useSelector(selectPricesListFilter);
  const filterPriceHistory = useSelector(selectPriceHistoryListFilter);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedPromotion, setSelectedPromotion] = useState();
  const [commentModal, setCommentModal] = useState(false);
  const [validatePrice, setValidatePrice] = useState();
  const tempValidations = useSelector(selectTempValidations);
  const tempPrices = useSelector(selectTempPrices);
  const loading = useSelector(selectLoader);
  const navigate = useNavigate();
  const user = useUser();

  const data = useMemo(() => {
    return mixPricesWithTemp(pricesList, tempPrices, tempValidations);
  }, [pricesList, tempPrices, tempValidations])

  useFirstLoad(() => {
    if ((promoter?.getConfig("price", "listCreationTypes") || []).includes("G")) {
      dispatch(entityListGroup_full());
    }
    dispatch(promoterListLocations_full());
    dispatch(formProductListFamily());
    dispatch(productGroupContacts());
  });

  const onSubmitHandler = () => {
    if (validation) {
      dispatch(productPendingPriceAction());
    }
    else {
      dispatch(productCreatePrice());
    }
  }

  const onFilterChangeHandler = (v) => {
    dispatch(productPriceList({ ...filter, ...v.filter }, v.reset));
  }

  const onValidateHandler = (row, valid) => {
    if (row.valid === valid) {
      dispatch(updateTempValidation({ ...row, valid: undefined }));
    }
    else {
      setValidatePrice({ ...row, valid });
      setCommentModal(true);
    }
  }

  const onConfirmValidationHandler = (comment) => {
    dispatch(updateTempValidation({ ...validatePrice, comment }));
    setCommentModal(false);
  }

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search,
      offset: 0,
    };

    dispatch(productPriceList(nFilter, true));
  };

  const onAddPromotionHandler = (product, price) => {
    const { priceId, newSalePrice } = price;
    const { productId } = product;
    setSelectedPromotion();
    setSelectedRow({ productId, priceId, newSalePrice });
    setModalPromotion(true);
  }

  const onEditPromotionHandler = (product, price, promotion) => {
    const { priceId, newSalePrice } = price;
    const { productId } = product;
    setSelectedRow({ productId, priceId, newSalePrice });
    setSelectedPromotion(promotion);
    setModalPromotion(true);
  }

  const onVariantAddHandler = (product) => {
    const { productId, contactPoint, owner: ownerId } = product;
    setSelectedRow({ productId, contactPoint, ownerId });
    setModal(true);
  }

  const onStandardPriceChangeHandler = ({ productId, priceId, newSalePrice }) => {
    const product = data?.find(item => item.productId === productId);
    const priceObj = (product?.prices || []).find(item => item.priceType === "S") || {};
    if (parseFloat(priceObj?.salePrice) !== parseFloat(newSalePrice)) {
      dispatch(updateTempSalePrice({ productId, priceId, newSalePrice }));
    }
    else {
      dispatch(removeTempSalePrice({ productId, priceId }));
    }
  }

  const onStandardRetailPriceChangeHandler = ({ productId, priceId, newRetailPrice }) => {
    const product = data?.find(item => item.productId === productId);
    const priceObj = (product?.prices || []).find(item => item.priceType === "S") || {};
    if (parseFloat(priceObj?.retailPrice) !== parseFloat(newRetailPrice)) {
      dispatch(updateTempRetailPrice({ productId, priceId, newRetailPrice }));
    }
    else {
      dispatch(removeTempRetailPrice({ productId, priceId }));
    }
  }

  const onVariantPriceChangeHandler = ({ productId, priceId, newSalePrice, isNew, priceType }) => {
    // console.log('onVariantPriceChangeHandler: %o', { productId, priceId, newSalePrice, isNew, priceType });
    if (isNew) {
      dispatch(addTempPrice({ productId, isNew, priceId: priceId || guid(), newSalePrice, priceType }));
    }
    else {
      const product = data?.find(item => item.productId === productId);
      const priceObj = (product?.prices || []).find(item => item.priceId === priceId) || {};
      if (parseFloat(priceObj?.salePrice) !== parseFloat(newSalePrice)) {
        dispatch(updateTempSalePrice({ productId, priceId, newSalePrice }));
      }
      else {
        dispatch(removeTempSalePrice({ productId, priceId }));
      }
    }
  }

  const onVariantRetailPriceChangeHandler = ({ productId, priceId, newRetailPrice, isNew, priceType }) => {
    if (isNew) {
      dispatch(addTempPrice({ productId, isNew, priceId: priceId || guid(), newRetailPrice, priceType }));
    }
    else {
      const product = data?.find(item => item.productId === productId);
      const priceObj = (product?.prices || []).find(item => item.priceId === priceId) || {};
      if (parseFloat(priceObj?.retailPrice) !== parseFloat(newRetailPrice)) {
        dispatch(updateTempRetailPrice({ productId, priceId, newRetailPrice }));
      }
      else {
        dispatch(removeTempRetailPrice({ productId, priceId }));
      }
    }
  }

  const onAddPromotionSubmitHandler = () => {
    setSelectedRow();
    setModalPromotion(false);
  }

  const onDeleteHandler = () => {
    setSelectedRow();
    setSelectedPromotion();
    setModalPromotion(false);
  }

  const onRemoveHandler = ({ variant, product }) => {
    const { priceId, process } = variant;
    const { productId, owner: ownerId, contactPoint, ownerName } = product;
    if (variant.isNew) {
      dispatch(removeTempPrice({ productId, priceId }));
    }
    else {
      if (process?.status === "PENDING") {
        dispatch(productRemovePrice({ priceId, productId, ownerId, contactPoint, ownerName }));
      }
      else {
        setPriceToRemove({ priceId, productId, ownerId, contactPoint, ownerName })
        setRemoveModal(true);
      }
    }
  }

  const onRemoveConfirmHandler = (removeDate) => {
    setRemoveModal(false);
    dispatch(productRemovePrice({ ...priceToRemove, removeDate }));
  }

  const onPriceComparerOpenHandler = (product, price) => {
    const { productId } = product;
    dispatch(productPriceComparison({ productId }));
    setComparerProduct(product);
    setComparerPrice(price);
    setComparerModal(true);
  }

  const hasChanges = useMemo(() => {
    if (validation) {
      return Object.keys(tempValidations)?.filter(item => tempValidations[item].valid !== undefined).length > 0;
    }
    return Object.keys(tempPrices).some(item => tempPrices[item].length > 0)

  }, [validation, tempValidations, tempPrices]);

  const onPriceHistoryOpenHandler = (product, priceId) => {
    const { productId } = product;
    dispatch(productPriceHistoryList({ ...filterPriceHistory, productId: productId, priceId: priceId }, true));
    setProductHistory(product);
    setHistoryModal(true);
  }

  const onFilterPriceHistoryChangeHandler = (v) => {
    dispatch(productPriceHistoryList({ ...filterPriceHistory, ...v.filter }, v.reset));
  }

  return (
    <Page
      permission={validation ? "products.actions.pendingPrice.list" : "products.actions.price.list"}
      header={<PageTitle
        title={validation ? t("productsPrices.PricesList.header.titleValidation") : t("productsPrices.PricesList.header.title")}
        info={`${totalRows} ${t("productsPrices.PricesList.header.rowsInfo")}`}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <SearchBox onEnter={(v) => onSearchHandler(v)} value={filter?.search} disabled={hasChanges} />
            </Grid>
            <Permission code={validation ? "products.actions.pendingPrice.action" : "products.actions.price.create"}>
              <Grid item>
                <Button
                  variant="contained"
                  size="large"
                  onClick={onSubmitHandler}
                  disabled={loading || !hasChanges}
                >
                  {t("productsPrices.PricesList.header.btnSubmit")}
                </Button>
              </Grid>
            </Permission>
          </Grid>
        }
      />}>
      <Stack spacing={1}>
        {user?.rolePermission?.products?.actions?.pendingPrice?.list && !promoter?.getConfig("price")?.autoAcceptPrice &&
          <div className={classes.links}>
            <ToggleButtonGroup color="primary" size="small" value={validation ? "validation" : "prices"} >
              <ToggleButton color="primary" value="prices" onClick={() => validation && navigate('/products/prices')} >
                {t("productsPrices.PricesList.header.pricesLink")}
              </ToggleButton>
              <ToggleButton color="primary" value="validation" onClick={() => !validation && navigate('/products/prices/validation')} >
                {t("productsPrices.PricesList.header.validationLink")}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        }
        {hasChanges &&
          <Alert variant="outlined" severity="info" className={classes.alert}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {validation ? t("productsPrices.PricesList.header.changesToSubmitValidation") : t("productsPrices.PricesList.header.changesToSubmit")}
              <Button size="small" variant="text" color="info" disableRipple style={{ height: "auto", padding: 0 }} onClick={() => validation ? dispatch(clearTempValidation()) : dispatch(clearTempPrices())}>
                {t("productsPrices.PricesList.header.clearChanges")}
              </Button>
            </Stack>
          </Alert>
        }
        <PricesDataGrid
          data={data}
          totalRows={totalRows}
          filter={filter}
          onFilterChange={onFilterChangeHandler}
          onStandardPriceChange={onStandardPriceChangeHandler}
          onStandardRetailPriceChange={onStandardRetailPriceChangeHandler}
          onVariantPriceChange={onVariantPriceChangeHandler}
          onVariantRetailPriceChange={onVariantRetailPriceChangeHandler}
          onVariantAdd={onVariantAddHandler}
          validation={validation}
          onRemove={onRemoveHandler}
          onValidate={onValidateHandler}
          onAddPromotion={onAddPromotionHandler}
          onEditPromotion={onEditPromotionHandler}
          onPriceComparerOpen={onPriceComparerOpenHandler}
          onPriceHistoryOpen={onPriceHistoryOpenHandler}
        />
        <CustomModal open={modal} setOpen={setModal} size="large" height={680} title={t("productsPrices.PricesList.modal.title")}>
          <PriceVariantForm price={selectedRow} onSubmit={() => setModal(false)} />
        </CustomModal>

        <CustomModal open={modalPromotion} setOpen={setModalPromotion} size="large" height={680} title={t("productsPrices.PricesList.modalPromotion.title")}>
          <PromotionForm price={selectedRow} promotion={selectedPromotion} onAddPromotionSubmit={onAddPromotionSubmitHandler} onDelete={onDeleteHandler} />
        </CustomModal>
        <PriceValidationCommentModal open={commentModal} setOpen={setCommentModal} onSubmit={onConfirmValidationHandler} accept={validatePrice?.valid} />
        <PriceComparerModal open={comparerModal} setOpen={setComparerModal} product={comparerProduct} price={comparerPrice} />
        <PriceRemoveModal open={removeModal} setOpen={setRemoveModal} onRemoveConfirm={onRemoveConfirmHandler} />
        <PriceHistoryModal open={historyModal} setOpen={setHistoryModal} product={productHistory} onFilterChange={onFilterPriceHistoryChangeHandler} />

      </Stack>
    </Page>
  );
}

export default withStyles(PricesList, styles);
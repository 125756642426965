import moment from "moment";
import {requestHelper} from "../../../app/coreSlice";
import {call} from "../../../app/websocket/_sliceCalls/websocket";

const businessListProcessCall = async ({ clearBusinessProcessList, setBusinessProcessList, setBusinessProcessListTotalRows, setBusinessProcessFilter }, dispatch, { filter, clear }) => {
  let rh = requestHelper(dispatch, "BUSINESS_LIST_PROCESS");
  if (clear) {
    dispatch(clearBusinessProcessList());
  }

  const dateIni = (filter.isDefaultFilter || !filter.dateIni) ? moment().add(-1, "day").toISOString() : filter.dateIni;
  const dateEnd = (filter.isDefaultFilter || !filter.dateEnd) ? moment().toISOString() : filter.dateEnd;

  dispatch(setBusinessProcessFilter({
    ...filter,
    dateIni,
    dateEnd
  }));

  try {
    let result = await call({
      type: "PROCESS",
      subType: "LIST",
      request: {
        ...filter,
        dateIni,
        dateEnd
      }
    });

    if (result && result.items && result.items.length > 0) {
      dispatch(setBusinessProcessList(result.items));
      dispatch(setBusinessProcessListTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default businessListProcessCall;

import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const productDisableFamilyCall = async ({ productListFamily }, dispatch, state, { id }) => {
  let rh = requestHelper(dispatch, "DISABLE_FAMILY");
  let filter = state.productsFamilies.familyListFilter;
  try {
    await call({
      type: "PRODUCT",
      subType: "DISABLE_FAMILY",
      request: {
        id,
      },
    });
    dispatch(productListFamily(filter, true));
  } catch (ex) {
    rh.error("productsFamilies", ex);
  }
  rh.close();
};

export default productDisableFamilyCall;
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./AggregatedDataGrid.styles";
import { listColumns } from "./AggregatedDataGrid.columns";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";
import { filterFields, filterSchema } from "./AggregatedDataGrid.filter";
import { aggregatedOrdersDefaultFilter } from "../../_common/ordersFilters";

function AggregatedDataGrid({ classes, data, totalRows, onRowSelected, filter, onFilterChange, loading }) {
  const { t } = useTranslation();
  const columns = listColumns(t, classes);

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0 && !loading) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };
      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  return (
    <div className={classes.root}>

      <CustomDataGrid
        columns={columns}
        rows={data || []}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMoreHandler}
        onRowSelected={onRowSelected}
        total={totalRows}
        topActions={<FilterForm
          style={{ marginBottom: 8 }}
          loading={loading}
          storageKey="AggregatedDataGrid"
          onStorageLoad={onFilterHandler}
          slim
          fields={filterFields()}
          schema={filterSchema()}
          onFilterChange={onFilterHandler}
          filter={filter}
          defaultFilter={aggregatedOrdersDefaultFilter}
        />}
      />

    </div>
  );
}

export default withStyles(AggregatedDataGrid, styles)

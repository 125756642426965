import * as Yup from "yup";
import {DateTimePickerField, MultiSelectField, SelectField, TextField} from "../../../../common/forms";
import React from "react";
import moment from "moment";

export const filterFields = () => (t) => {
    return [
        {
            size: 12,
            field: (
                <DateTimePickerField
                    id="dateIni"
                    label={t("business.process.filter.dateIni")}
                    maxDate={moment().toISOString()}
                />
            )
        },
        {
            size: 12,
            field: (
                <DateTimePickerField
                    id="dateEnd"
                    label={t("business.process.filter.dateEnd")}
                    maxDate={moment().toISOString()}
                />
            )
        },
        {
            size: 12,
            field: (
                <MultiSelectField
                    id="processTypes"
                    label={t("business.process.filter.processTypes")}
                    options={getProcessTypesOptions(t)}
                />
            ),
        },
        {
            size: 12,
            field: (
                <MultiSelectField
                    id="status"
                    label={t("business.process.filter.status")}
                    options={getStatusOptions(t)}
                />
            ),
        },
        {
            size: 12,
            field: (
                <SelectField
                    id="active"
                    label={t("business.process.filter.activeLabel")}
                    options={getActiveOptions(t)}/>
            ),
        },
        {
            size: 12,
            field: <TextField
                id="search"
                label={t("business.queries.filter.search")}/>,
        },
    ];
};

export const filterSchema = () => (t) => {
    return Yup.object().shape({
        dateIni: Yup.string(),
        dateEnd: Yup.string(),
    });
};


const getStatusOptions = (t) => {
    return ["PENDING", "VALIDATING", "REFUSED", "ACCEPTED", "NOT_OK", "NOT_VALIDATED", "RECOVERED"].map((value) => {
        return {
            value,
            text: t("common.Enums.productWorkflow." + value),
        };
    });

    //priceActionStatus
};

const getProcessTypesOptions = (t) => {
    return ["FlowCreateProduct", "FlowUpdateProduct", "FlowPrice", "FlowPurchase", "FlowRecoverProduct", "FlowCreateEntity", "FlowUpdateEntity", "FlowCreatePromoDiscount", "FlowRemovePromoDiscount", "FlowRemovePrice", "FlowTax", "FlowCreateTask", "FlowUpdateTask"].map((value) => {
        return {
            value,
            text: t("common.Enums.processType." + value),
        };
    });
};

const getActiveOptions = (t) => {
    return [
        {
            value: null,
            text: t("business.process.filter.optionAll"),
        },
        {
            value: true,
            text: t("business.process.filter.optionActive"),
        },
        {
            value: false,
            text: t("business.process.filter.optionInactive"),
        },
    ];
};
import React from "react";
import { withStyles } from "tss-react/mui";
import { FormControlLabel, Stack, Switch, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { styles } from "./_base.styles";

function SwitchInput({ id, classes, className, label, value, onChange, disabled, size = "small", note }) {
  return (
    <div className={classes.root + (className ? " " + className : "")}>
      <FormControlLabel
        className={classes.switch}
        control={
          <Switch
            color="primary"
            id={id}
            size={size}
            checked={value}
            onChange={(e) => onChange && onChange(e.target.checked)}
            disabled={Boolean(disabled)}
          />
        }
        label={
          <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" >
            <Typography
              component="span"
              variant="caption"
              htmlFor={id}
              color="textPrimary"
              className={disabled ? classes.disabledLabel : ""}
              style={{ marginRight: 5 }}
            >
              {label}
            </Typography>
            {note && <Tooltip title={note} placement="left"><InfoOutlinedIcon color="primary" className={classes.noteIconCheck} /></Tooltip>}
          </Stack>
        }
        labelPlacement="start"
        disabled={Boolean(disabled)}
      />
    </div>
  );
}

export default withStyles(SwitchInput, styles);

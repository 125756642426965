import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { styles } from "./PieChart.styles";
import Chart from "react-apexcharts";
import { withStyles } from "tss-react/mui";

function PieChart({ classes, label, labels, series }) {
  const options = {
    chart: {
      type: "donut",
    },
    labels: labels,
    legend: {
      position: "top",
    },
  };

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container direction="column">
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Typography variant="h6" color="textSecondary">
              {label}
            </Typography>
          </Grid>
        </Grid>
        <Chart options={options} series={series.map(item => item || 0)} type="donut" height={500} />
      </Grid>
    </Paper>
  );
}

export default withStyles(PieChart, styles)

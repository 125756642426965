import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { styles } from "./PendingEntityList.styles";
import PendingEntityDataGrid from "./PendingEntityDataGrid/PendingEntityDataGrid";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {
  entityPendingEntityList,
  selectPendingEntityList,
  selectPendingEntityListFilter,
  selectPendingEntityListTotalRows,
} from "../entitiesSlice";
import SearchBox from "../../../common/components/inputs/SearchBox";
import Page from "../../../common/displays/Page/Page";

function PendingEntityList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filter = useSelector(selectPendingEntityListFilter);
  const data = useSelector(selectPendingEntityList);
  const totalRows = useSelector(selectPendingEntityListTotalRows);

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search,
      offset: 0,
    };
    dispatch(entityPendingEntityList(nFilter, true));
  };

  const onFilterChangeHandler = (v) => {
    dispatch(entityPendingEntityList(v.filter, v.reset));
  };

  return (
    <Page
      permission="entities.actions.pendingEntity.list"
      header={<PageTitle
        title={t("entities.PendingEntityList.header.title")}
        info={`${totalRows} ${t("entities.PendingEntityList.header.rowsInfo")}`}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <SearchBox onEnter={(v) => onSearchHandler(v)} />
            </Grid>
          </Grid>
        }
      />}>

      <PendingEntityDataGrid
        data={data || []}
        filter={filter}
        totalRows={totalRows}
        onFilterChange={onFilterChangeHandler}
      />
    </Page>
  );
}

export default withStyles(PendingEntityList, styles);

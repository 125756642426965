import React from "react";
import { Routes, Route } from "react-router-dom";
import OrdersIncidentsForm from "./OrdersIncidentsForm/OrdersIncidentsForm";
import OrdersIncidentsList from "./OrdersIncidentsList/OrdersIncidentsList";
import Security from "../../app/components/Security/Security";

function OrdersIncidentsRouting() {
  return (
    <Routes>
      <Route path="/add/:orderId" element={
        <Security component={<OrdersIncidentsForm />} paramValidation={[{ name: "orderId", type: "number" }]} />
      } />
      <Route path="/" element={<OrdersIncidentsList />} />
    </Routes>
  );
}

export default OrdersIncidentsRouting;

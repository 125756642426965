import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const entityList_pickerCall = async ({ setPickerEntitiesList }, dispatch, typeFilter) => {
  let rh = requestHelper(dispatch, "ENTITY_LIST");
  try {
    let list = await call({
      type: "ENTITY",
      subType: "LIST",
      request: {
        limit: 10000,
        sortField: "NAME",
        sortType: "ASC",
        status: "ACT",
        types: typeFilter
      },
    });
    dispatch(setPickerEntitiesList(list.entities));
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityList_pickerCall;

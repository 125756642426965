export const styles = (theme) => ({
  root: {},
  modal: {
    paddingTop: "10vh",
  },
  modalroot: {
    width: "70vw",
    margin: "0 auto",
    backgroundColor: theme.palette.secondary.main,
    height: "80vh",
  },
  modalbody: {
    padding: theme.spacing(2),
  },
  gridBox: {
    height: "calc( 80vh - 90px )",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
  },
  grid: {
    padding: 2,
    overflow: "auto",
  },
  deleteButton: {
    marginTop: 25,
  },
});

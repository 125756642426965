import { saveFile } from "../../../api/s3";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper, setSuccess } from "../../../app/coreSlice";
import { asyncForEach, base64ToArrayBuffer, isBase64Image } from "../../../common/utils";

const promoterUpdateCall = async ({ promoterConfigs }, dispatch, state, { configs }) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  const promoterCode = state.core.promoterDetails.details.code;
  let rh = requestHelper(dispatch, "PROMOTER_UPDATE");
  try {
    let admin = {
      ...configs.admin,
      logo: configs.admin?.logoFile ? configs.admin?.logoFile?.name : configs.admin?.logo,
    };

    let functionalHome = {
      ...configs.functional.home,
      flyer: configs.functional.home.hasFlyer
        ? configs.functional.home.flyer?.base64
          ? {
            index: 0,
            location: await uploadHomeFile(
              fileStorageConfigs,
              promoterCode,
              "flyer.pdf",
              configs.functional.home.flyer.base64,
              configs.functional.home.flyer.type,
              true,
              configs.functional.home.flyer?.url
            ),
          }
          : configs.functional.home.flyer?.url
            ? {
              index: 0,
              location: configs.functional.home.flyer?.url,
            }
            : undefined
        : undefined,
    };

    if (configs.functional.home.hasHighlights) {
      let highlights = [];
      await asyncForEach(configs.functional.home.highlights, async (highlight, index) => {
        if (isBase64Image(highlight.base64)) {
          highlights.push({
            index,
            location: await uploadHomeFile(fileStorageConfigs, promoterCode, `highlight_${index}.jpg`, highlight.base64, "image/jpg", false, highlight.location),
          });
        } else if (highlight.location) {
          highlights.push({
            index,
            location: highlight.location,
          });
        }
      });
      functionalHome.highlights = highlights;
    } else {
      functionalHome.highlights = undefined;
    }

    let functionalCutoff = {
      ...configs.functional.cutOff,
      automatic: configs.functional.cutOff.automatic === undefined ? false : configs.functional.cutOff.automatic,
    };

    let importTemplateFileOrder = configs.technical?.order?.importTemplate?.name ? configs.technical?.order?.importTemplate : undefined;
    let importTemplateFileProduct = configs.technical?.product?.importTemplate?.name ? configs.technical?.product?.importTemplate : undefined;
    let importTemplateFilePrice = configs.technical?.price?.importTemplate?.name ? configs.technical?.price?.importTemplate : undefined;

    await call({
      type: "PROMOTER",
      subType: "UPDATE",
      request: {
        configs: {
          ...configs,
          functional: {
            ...configs.functional,
            home: functionalHome,
            cutOff: functionalCutoff
          },
          admin,
          technical: {
            ...configs.technical,
            order: {
              ...configs.technical?.order,
              importTemplate: configs.technical?.order?.importTemplate?.name ? configs.technical?.order?.importTemplate?.name : configs.technical?.order?.importTemplate,
            },
            price: {
              ...configs.technical.price,
              importTemplate: configs.technical?.price?.importTemplate?.name ? configs.technical?.price?.importTemplate?.name : configs.technical?.price?.importTemplate,
            },
            product: {
              ...configs.technical.product,
              importTemplate: configs.technical?.product?.importTemplate?.name ? configs.technical?.product?.importTemplate?.name : configs.technical?.product?.importTemplate,
            },
          }
        }
      },
    });

    if (configs.admin?.logoFile) {
      await uploadLogo(fileStorageConfigs, promoterCode, configs?.admin?.logoFile);
    }
    if (importTemplateFileOrder) {
      await uploadTemplate(fileStorageConfigs, promoterCode, importTemplateFileOrder);
    }
    if (importTemplateFilePrice) {
      await uploadTemplate(fileStorageConfigs, promoterCode, importTemplateFilePrice);
    }
    if (importTemplateFileProduct) {
      await uploadTemplate(fileStorageConfigs, promoterCode, importTemplateFileProduct);
    }

    dispatch(setSuccess("promoter", "PROMOTER_UPDATE"));
    dispatch(promoterConfigs());
  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default promoterUpdateCall;

const uploadHomeFile = async (fileStorageConfigs, promoterCode, name, base64, type, isPublic, urlOg) => {
  const { provider, privateBucket, publicBucket } = fileStorageConfigs;
  const filePath = promoterCode.toUpperCase() + "/" + process.env.REACT_APP_ENV + "/home/" + name;
  if (!base64 || base64.startsWith("http://") || base64.startsWith("https://")) {
    return urlOg;
  }

  let url = await saveFile(
    provider,
    isPublic ? publicBucket : privateBucket,
    filePath,
    base64ToArrayBuffer(base64),
    type,
    isPublic
  );
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "https://" + url;
  }
  return url;
};

const uploadTemplate = async (fileStorageConfigs, promoterCode, template) => {
  const { provider, privateBucket } = fileStorageConfigs;
  const filePath = promoterCode.toUpperCase() + "/" + process.env.REACT_APP_ENV + "/template/" + template.name;

  await saveFile(
    provider,
    privateBucket,
    filePath,
    base64ToArrayBuffer(template.base64),
    template.type,
    false
  );
};

const uploadLogo = async (fileStorageConfigs, promoterCode, logo) => {
  const { provider, publicBucket } = fileStorageConfigs;
  const filePath = promoterCode.toUpperCase() + "/" + process.env.REACT_APP_ENV + "/logo/" + logo.name;

  await saveFile(
    provider,
    publicBucket,
    filePath,
    base64ToArrayBuffer(logo.base64),
    logo.mediaType,
    true
  );
};

import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const promoterDisableCutOffCall = async ({ listCutOffsAppend }, dispatch, state, { cutOffId }) => {
  const filter = state.entities.cutOffListFilter;
  let rh = requestHelper(dispatch, "DISABLE_CUT_OFF");
  try {
    await call({
      type: "PROMOTER",
      subType: "DISABLE_CUT_OFF",
      request: {
        id: cutOffId,
      },
    });
    setTimeout(() => {
      dispatch(setSuccess("entities", "PROMOTER_DISABLE_CUT_OFF"));
      dispatch(listCutOffsAppend(filter, true));
    }, 300);
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default promoterDisableCutOffCall;

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./AuditImportDataGrid.styles";
import { csvFields } from "./AuditImportDataGrid.export";
import { listColumns } from "./AuditImportDataGrid.columns";
import { filterFields, filterSchema } from "./AuditImportDataGrid.filter";

import { auditImportDefaultFilter } from "../../_common/businessFilters";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";

function AuditImportDataGrid({ classes, data, filter, totalRows, onRowSelected, onFilterChange }) {
  const { t } = useTranslation();

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };

      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={listColumns(t, classes)}
        rows={data || []}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMoreHandler}
        onRowSelected={onRowSelected}
        total={totalRows}
        exportCsvFields={csvFields(t)}
        exportXlsxFields={csvFields(t)}
        topActions={
          <FilterForm
            style={{ marginBottom: 8 }}
            storageKey="AuditImportDataGrid"
            onStorageLoad={onFilterHandler}
            slim
            fields={filterFields()}
            schema={filterSchema()}
            onFilterChange={onFilterHandler}
            filter={filter}
            defaultFilter={auditImportDefaultFilter}
          />
        }
      />
    </div>
  );
}

export default withStyles(AuditImportDataGrid, styles);
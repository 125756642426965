export const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100vh"
  },
  header: {
    width: "100%"
  },
  content: {
    margin: "0 auto",
    padding: theme.spacing(3),
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    height: "calc(100vh - var(--header-height))",
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "21px",
      boxShadow: "inset 0 0 21px rgba(0,0,0,.3)",
    },
    "& .MuiListItem-root": {
      padding: 0,
      paddingLeft: 5,
      paddingRight: 10,
    },
  }
});
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper, Stack, Drawer, Divider, ToggleButtonGroup, ToggleButton, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { styles } from "./BatchesOrdersList.styles";

import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { selectLoader } from "../../../app/coreSlice";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import InfiniteList from "../../../common/components/displays/InfiniteList/InfiniteList";
import { batchCreateInternalOrder, batchListInternal, batchListOrders, batchOutputProducts, selectBatchOrdersList, selectBatchOrdersListFilter, selectBatchOrdersListTotalRows, selectOutputProductsList, selectPlacesList, setBatchOrderDetails } from "../batchesSlice";
import BatchOrderItem from "./BatchOrderItem/BatchOrderItem";
import CreateInternalOrderModal from "../BatchForm/CreateInternalOrderModal/CreateInternalOrderModal";
import BatchOrderDrawerDetails from "./BatchOrderDrawerDetails/BatchOrderDrawerDetails";

function BatchesOrdersList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { businessUnitId } = useParams();
  const batchesOrdersData = useSelector(selectBatchOrdersList);
  const placesData = useSelector(selectPlacesList);
  const totalRows = useSelector(selectBatchOrdersListTotalRows);
  const filter = useSelector(selectBatchOrdersListFilter);
  const loading = useSelector(selectLoader);
  const products = useSelector(selectOutputProductsList);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [createOrderModal, setCreateOrderModal] = useState(false);

  useFirstLoad(() => {
    dispatch(batchOutputProducts({ businessUnitId }));
    dispatch(batchListOrders(businessUnitId, filter, true));
    dispatch(batchListInternal({ businessUnitId }));
  });

  const ordersLoadMoreHandler = () => {
    if (batchesOrdersData.length < totalRows) {
      dispatch(batchListOrders(filter, false));
    }
  }

  const internalBatchesLoadMoreHandler = () => {
    // if (batchesOrdersData.length < totalRows) {
    //   dispatch(batchListInternal(filter, false));
    // }
  }

  console.log('batchesOrdersData: %o', batchesOrdersData);

  // const onAddHandler = () => {
  //   navigate("/batches/" + businessUnitId + "/create");
  // }

  const batchOrderSelectHandler = (item) => {
    console.log('order: %o', item);
    setDrawerOpen(true);
    dispatch(setBatchOrderDetails(item));
    // dispatch(batchGet({}))
  }

  const onCreateInternalOrderSubmit = (values) => {
    console.log('values: %o', values);
    dispatch(batchCreateInternalOrder({ ...values, businessUnitId }));
  }

  return (
    <Page
      // permission={"promoter.actions.batches.list"}
      header={<PageTitle
        title={t("batches.BatchesOrdersList.header.title")}
        actions={
          <Grid container direction="row" spacing={2}>
            {/* <Permission code={"promoter.actions.batches.create"}> */}
            <Grid item>
              {/* <Button variant="outlined" size="large" onClick={onAddHandler} disabled={loading} id="add">
                <AddIcon fontSize="inherit" />
                <Typography>{t("batches.BatchesOrdersList.header.btnAdd")}</Typography>
              </Button> */}
            </Grid>
            {/* </Permission> */}
          </Grid>
        }
      />}>
      <Stack spacing={2}>
        <div className={classes.links}>
          <ToggleButtonGroup color="primary" size="small" value="orders">
            <ToggleButton color="primary" value="batches" onClick={() => navigate('/batches/' + businessUnitId)}>
              {t("batches.BatchesList.header.batchesLink")}
            </ToggleButton>
            <ToggleButton color="primary" value="orders">
              {t("batches.BatchesList.header.batchesOrdersLink")}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
              <Paper className={classes.paper}>
                <Stack>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ paddingRight: 5 }}>
                    <Typography variant="h4" style={{ marginBottom: 8 }}>{t("batches.BatchesOrdersList.header.titleOrders")}</Typography>
                    <IconButton size="small" className={classes.circleBtn} onClick={() => setCreateOrderModal(true)}>
                      <AddIcon fontSize="medium" />
                    </IconButton>
                  </Stack>
                  <InfiniteList style={{ height: "calc(100vh - 257px)" }} isLoading={loading} data={batchesOrdersData} loadMore={ordersLoadMoreHandler} renderElement={(row) => (
                    <BatchOrderItem order={row} onClick={batchOrderSelectHandler} />
                  )} />
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper className={classes.paper}>
                <Stack>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4" style={{ marginBottom: 0 }}>{t("batches.BatchesOrdersList.header.titleInternalBatches")}</Typography>
                    <IconButton size="small" className={classes.circleBtn}>
                      <AddIcon fontSize="medium" />
                    </IconButton>
                  </Stack>
                  <Divider />
                  <InfiniteList style={{ height: "calc(100vh - 250px)" }} isLoading={loading} data={placesData} loadMore={internalBatchesLoadMoreHandler} renderElement={(row) => (
                    <div>todo</div>
                  )} />
                </Stack>
              </Paper>
            </Grid>
          </Grid>
          <CreateInternalOrderModal open={createOrderModal} setOpen={setCreateOrderModal} products={products} onSubmit={onCreateInternalOrderSubmit} />
        </div>
      </Stack>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div className={classes.drawer}>
          <BatchOrderDrawerDetails />
        </div>
      </Drawer>
    </Page>
  );
}

export default withStyles(BatchesOrdersList, styles);
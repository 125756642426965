import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Checkbox, Paper } from "@mui/material";

import { styles } from "./ProductDiff.styles.js";
import ProductSnip from "../../../../../../common/displays/ProductSnip/ProductSnip.js";

function ProductDiff({ classes, field, onCheck, checked, parsedField, viewMode }) {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container wrap="nowrap" alignItems="center" className={parsedField?.hasDiff ? classes.hasDiff : ""}>
        <Grid item flexGrow={1}>
          <Typography variant="caption" component="span" style={{ marginLeft: 8 }}>
            {t("products.ProductValidationForm.field." + field)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {
            parsedField?.valueObj && (
              <Paper elevation={0} className={classes.paper}>
                <ProductSnip
                  image={parsedField?.valueObj?.image}
                  description={parsedField?.valueObj?.description}
                  gtin={parsedField?.valueObj?.gtin}
                  promoterInternalCode={parsedField?.valueObj?.promoterInternalCode}
                />
              </Paper>
            )
          }
        </Grid>
        <Grid item xs={5}>
          {
            parsedField?.newValueObj && (
              <Paper elevation={0} className={classes.paper}>
                <ProductSnip
                  image={parsedField?.newValueObj?.image}
                  description={parsedField?.newValueObj?.description}
                  gtin={parsedField?.newValueObj?.gtin}
                  promoterInternalCode={parsedField?.newValueObj?.promoterInternalCode}
                />
              </Paper>
            )
          }
        </Grid>
        <Grid item style={{ width: 40 }}>
          {parsedField?.hasDiff && !viewMode && (
            <Checkbox color="success" checked={checked} onChange={(e) => onCheck(e.target.checked, field)} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(ProductDiff, styles);

export const styles = (theme) => ({
  root: {
    width: 12,
    height: 12,
    border: "2px solid #AAA",
    borderRadius: "50%"
  },
  fill: {
    width: 10,
    height: 10,
    margin: -1,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main
  },
  fillError: {
    width: 10,
    height: 10,
    margin: -1,
    borderRadius: "50%",
    backgroundColor: theme.palette.error.main
  }
});
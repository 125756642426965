import React from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Button, Stack } from "@mui/material";

import { styles } from "./Profile.styles";
import { formFields, formSchema } from "./Profile.schema";

import { Form, FormFields } from "../../../../common/forms"
import AvatarUpload from "../../../../common/inputs/custom/AvatarUpload/AvatarUpload";
import { useUser } from "../../../../common/hooks";
import { refreshUserAvatar, setError } from "../../../../app/coreSlice";
import { userUpdate } from "../../usersSlice";

function Profile({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();

  const onAvatarUploadCompleteHandler = () => {
    dispatch(refreshUserAvatar());
  }

  const onSubmit = async (values) => {
    dispatch(userUpdate(values));
  };

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmit}
        schema={formSchema({ t })}
        initialValues={{ firstName: user?.firstName, lastName: user?.lastName }}
        render={() => {
          return (
            <Stack spacing={2}>
              <div>
                <Typography variant="caption">{t("users.Profile.form.avatar")}</Typography>
                <AvatarUpload
                  keyId={user.id}
                  folderToStore={`common/${process.env.REACT_APP_ENV}/user/avatar`}
                  imageSrc={user?.avatarSignedUrl}
                  displayName={user?.firstName || user?.lastName ? (user?.firstName || "") + " " + (user?.lastName || "") : user?.username}
                  onUploadComplete={onAvatarUploadCompleteHandler}
                  onError={(error) => dispatch(setError("users", { tag: error }))}
                />
              </div>
              <Stack spacing={1} style={{ maxWidth: 400 }}>
                <div>
                  <FormFields fields={formFields({ t })} />
                </div>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary">
                  {t("users.Profile.form.btnSubmit")}
                </Button>

              </Stack>
            </Stack>
          );
        }}
      />

    </div>
  );
}

export default withStyles(Profile, styles);
import { documentSlice } from "./documentSlice";
import { requestHelper, setSuccess } from "../../app/coreSlice";
import { call } from "../../app/websocket/_sliceCalls/websocket";

export const selectPdfList = (state) => state.document.pdfList;
export const selectPdfDocument = (state) => state.document.pdfDocument;
export const selectLoader = (state) => state.core.loader.length > 0;

export const { setPdf, setPdfList } = documentSlice.actions;

export const documentListPdf = () => async (dispatch) => {
  let rh = requestHelper(dispatch, "DOCUMENT/LIST_PDF");

  try {
    let result = await call({
      type: "DOCUMENT",
      subType: "LIST_PDF",
      request: {},
    });

    if (result && result.documents) dispatch(setPdfList(result.documents));
  } catch (ex) {
    rh.error("document", ex);
  }
  rh.close();
};

export const documentGetPdf = (id) => async (dispatch) => {
  let rh = requestHelper(dispatch, "DOCUMENT/GET_PDF");

  try {
    let result = await call({
      type: "DOCUMENT",
      subType: "GET_PDF",
      request: {
        id,
      },
    });
    if (result && result.document)
      dispatch(
        setPdf({
          ...result.document,
          active: result.document?.versions?.length === result.document?.version + 1,
        })
      );
  } catch (ex) {
    rh.error("document", ex);
  }
  rh.close();
};

export const documentNewVersionPdf = (id, body, navigate) => async (dispatch) => {
  let rh = requestHelper(dispatch, "DOCUMENT/NEW_VERSION_PDF");

  try {
    let result = await call({
      type: "DOCUMENT",
      subType: "NEW_VERSION_PDF",
      request: {
        id,
        body,
      },
    });
    dispatch(setSuccess("documents", "DOCUMENT_NEW_VERSION_PDF"));
    navigate("/documents/" + result.id);
  } catch (ex) {
    rh.error("document", ex);
  }
  rh.close();
};

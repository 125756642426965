import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";

import { styles } from "./RefDataTaxsDataGrid.styles";
import { listColumns } from "./RefDataTaxsDataGrid.columns";
import RefDataTaxsDataGridActions from "./RefDataTaxsDataGrid.actions";
import { filterFields, filterSchema } from "./RefDataTaxsDataGrid.filter";
import { vatListDefaultFilter } from "../../_common/promoterFilters";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";

function RefDataTaxsDataGrid({ classes, data, filter, onFilterChange, onDelete, onEnable, onEdit, locations }) {
  const { t } = useTranslation();
  const columns = listColumns(t, classes);

  const onFilterHandler = (nFilter) => {
    onFilterChange &&
      onFilterChange({
        ...filter,
        ...nFilter,
      });
  };

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          columns={columns}
          rows={data}
          onFilter={onFilterHandler}
          total={data.length}
          actionsComponent={<RefDataTaxsDataGridActions onDelete={onDelete} onEnable={onEnable} onEdit={onEdit}/>}
          topActions={
            <FilterForm
              style={{ marginBottom: 8 }}
              storageKey="RefDataTaxsDataGrid"
              onStorageLoad={onFilterHandler}
              slim
              fields={filterFields(locations)}
              schema={filterSchema()}
              onFilterChange={onFilterHandler}
              filter={filter}
              defaultFilter={vatListDefaultFilter}
            />
          }
        />
      )}
    </div>
  );
}

export default withStyles(RefDataTaxsDataGrid, styles)

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";
import { Button, Grid, Tabs, Tab, Paper } from "@mui/material";
import { useParams } from "react-router-dom";

import { styles } from "./OperatorOrderDetails.styles";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
// import SearchBox from "../../../common/components/inputs/SearchBox";
import { useFirstLoad, useNavigate, usePromoter, useUser } from "../../../common/hooks";
import {
  orderIntransit,
  orderReception,
} from "../ordersSlice.calls";

import { Form, FormFields } from "../../../common/forms";
import { formSchema, formFields } from "./OperatorOrderDetails.schema";
import moment from "moment";
import Page from "../../../common/displays/Page/Page";
import { orderDelivery, orderDetailsLo, selectOperatorOrderDetails } from "../ordersSlice";
import OrderAttachments from "../_common/OrderAttachments/OrderAttachments";
import OrderStatusDetails from "../_common/OrderStatus/OrderStatusDetails/OrderStatusDetails";
import Permission from "../../../app/components/Permission";

function OperatorOrderDetails({ classes }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, dispatchId } = useParams();
  const dispatch = useDispatch();
  const details = useSelector(selectOperatorOrderDetails);
  const [selectedStatus, setSelectedStatus] = useState();
  const [availableStatus, setAvailableStatus] = useState();
  const promoter = usePromoter();
  const user = useUser();

  useFirstLoad(() => {
    if (details?.id?.toString() !== id || details?.dispatchId?.toString() !== dispatchId) {
      dispatch(orderDetailsLo(id, dispatchId));
    }
  });

  useEffect(() => {
    if (details?.status) {
      setSelectedStatus(details?.status);
      const status = ["DI", "RE", "IT", "DE"];
      if (details?.statusToComplete) {
        setAvailableStatus(
          status.slice(status.indexOf(details?.statusToComplete) - 1, status.indexOf(details?.status) + 1)
        );
      } else {
        setAvailableStatus(["DE"]);
      }
    }
  }, [details]);

  const onSubmitHandler = (formValues) => {
    if (selectedStatus === "DI") {
      // RECEPTION
      let values = {
        ...details,
        ...formValues,
      };
      dispatch(orderReception(values));
    } else if (selectedStatus === "RE") {
      // IN TRANSIT
      dispatch(orderIntransit({ ...details, ...formValues }));
    } else if (selectedStatus === "IT") {
      // DELIVERY
      let values = {
        ...details,
        ...formValues,
      };
      dispatch(orderDelivery({ ...values, operationType: details.operationType, receivedBy: user.username }));
    }
  };

  return details ? (
    <div>
      <Form
        schema={formSchema(t, details, selectedStatus)}
        onSubmit={onSubmitHandler}
        initialValues={{
          documentDate: moment(),
          volumes: filterVolumesByStatus(details?.volumes || [], selectedStatus),
          products: filterProductsByStatus(details?.products || [], selectedStatus),
          finished: false,
        }}
        render={({ values }) => {
          return (
            <Page
              permission="orders.actions.operator.get"
              header={<PageTitle
                title={
                  t("orders.OperatorOrderDetails.header.title") + " " + details.id + " (" + details.dispatchId + ")"
                }
                onBack={() => navigate("/orders/operator")}
                subtitle={
                  promoter?.getConfig("cutOff") && !promoter?.getConfig("cutOff", "automatic")
                    ? t("entities.CutOffs.dateType." + details.cutOffType)
                    : null
                }
                actions={
                  <Grid container spacing={2}>
                    <Grid item>{/* <SearchBox onEnter={(v) => onSearchHandler(v)} fast /> */}</Grid>
                    {selectedStatus === "DI" && (
                      <Permission code="orders.actions.operator.reception">
                        <Grid item>
                          <Button variant="contained" type="submit" color="primary" size="large">
                            {t("orders.OperatorOrderDetails.header.receptionBtn")}
                          </Button>
                        </Grid>
                      </Permission>
                    )}
                    {selectedStatus === "RE" && (
                      <Permission code="orders.actions.operator.inTransit">
                        <Grid item>
                          <Button variant="contained" type="submit" color="primary" size="large">
                            {t("orders.OperatorOrderDetails.header.intransitBtn")}
                          </Button>
                        </Grid>
                      </Permission>
                    )}
                    {selectedStatus === "IT" && (
                      <Permission code="orders.actions.operator.delivery">
                        <Grid item>
                          <Button variant="contained" type="submit" color="primary" size="large">
                            {t("orders.OperatorOrderDetails.header.deliveryBtn")}
                          </Button>
                        </Grid>
                      </Permission>
                    )}
                  </Grid>
                }
              />}>

              <Grid container spacing={3}>
                <Grid item xs={10}>
                  {availableStatus && availableStatus.length > 1 && (
                    <Tabs
                      orientation="horizontal"
                      value={availableStatus.indexOf(selectedStatus)}
                      onChange={(e, i) => setSelectedStatus(availableStatus[i])}
                      className={classes.tabs}
                    >
                      {availableStatus.map((item) => (
                        <Tab key={item} label={t("common.Enums.orderStatus." + item)} />
                      ))}
                    </Tabs>
                  )}
                  <FormFields
                    fields={formFields(
                      t,
                      classes,
                      details,
                      selectedStatus,
                      filterProductsByStatus(details?.products || [], selectedStatus)
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <OrderAttachments orderSellerId={id} dispatchId={dispatchId} attachments={details?.documents} />
                    </Grid>
                    <Grid item>
                      <Paper className={classes.paper} elevation={2}>
                        <OrderStatusDetails order={details} />
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Page>
          );
        }}
      />
    </div>
  ) : null;
}

const filterVolumesByStatus = (volumes, status) => {
  return volumes.filter((v) => v.status === status);
};

const filterProductsByStatus = (products, status) => {
  switch (status) {
    case "DI":
      return products
        .filter((p) => p.quantityHistory?.reception < p.quantityHistory?.dispatched)
        .map((p) => {
          return {
            ...p,
            quantity: p.quantityHistory?.dispatched - p.quantityHistory?.reception,
            maxQuantity: p.quantityHistory?.dispatched - p.quantityHistory?.reception,
          };
        });
    case "RE":
      return products
        .filter((p) => p.quantityHistory?.reception > 0 && p.quantityHistory?.inTransit < p.quantityHistory?.reception)
        .map((p) => {
          return {
            ...p,
            quantity: p.quantityHistory?.reception - p.quantityHistory?.inTransit,
            maxQuantity: p.quantityHistory?.reception - p.quantityHistory?.inTransit,
          };
        });
    case "IT":
      return products
        .filter((p) => p.quantityHistory?.inTransit > 0 && p.quantityHistory?.delivered < p.quantityHistory?.inTransit)
        .map((p) => {
          return {
            ...p,
            quantity: p.quantityHistory?.inTransit - p.quantityHistory?.delivered,
            maxQuantity: p.quantityHistory?.inTransit - p.quantityHistory?.delivered,
          };
        });
    case "DE":
      return products
        .filter((p) => p.quantityHistory?.delivered > 0)
        .map((p) => {
          return {
            ...p,
            quantity: p.quantityHistory?.delivered,
            maxQuantity: p.quantityHistory?.delivered,
          };
        });
    default:
      return products;
  }
};

export default withStyles(OperatorOrderDetails, styles);

import * as Yup from "yup";
import { MultiSelectField } from "../../../../common/forms";

export const filterFields = () => (t) => {
  return [
    {
      size: 12,
      field: (
        <MultiSelectField
          id="status"
          label={t("entities.PendingEntityList.filter.status")}
          options={getStatusOptions(t)}
        />
      ),
    },
    {
      size: 12,
      field: (
        <MultiSelectField
          id="processTypes"
          label={t("entities.PendingEntityList.filter.processTypes")}
          options={getProcessTypesOptions(t)}
        />
      ),
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    status: Yup.array(),
  });
};

const getStatusOptions = (t) => {
  return ["PENDING", "VALIDATING", "REFUSED", "ACCEPTED", "NOT_OK", "NOT_VALIDATED", "RECOVERED"].map((value) => {
    return {
      value,
      text: t("common.Enums.productWorkflow." + value),
    };
  });
};

const getProcessTypesOptions = (t) => {
  return ["FlowCreateEntity", "FlowUpdateEntity"].map((value) => {
    return {
      value,
      text: t("common.Enums.entityProcessType." + value),
    };
  });
};


import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess, setRedirect } from "../../../app/coreSlice";

const taskCreateCall = async (
  dispatch,
  { name, info, dueDate, userId, groupId, sectorCode, entityId }
) => {
  let rh = requestHelper(dispatch, "TASK_CREATE");
  try {
    let result = await call({
      type: "TASK",
      subType: "CREATE",
      request: {
        name, info, dueDate, userId, groupId, sectorCode, entityId
      },
    });

    if (result && result.success) {

      setTimeout(() => {
        dispatch(setRedirect("/tasks"));
        dispatch(setSuccess("tasks", "TASK_CREATE"));
      }, 300);
    }

  } catch (ex) {
    rh.error("tasks", ex);
  }
  rh.close();
};

export default taskCreateCall;


import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const promoterUpdateCutOffCall = async (dispatch, { id, cutoff }) => {
  let rh = requestHelper(dispatch, "UPDATE_CUTOFF");
  try {
    await call({
      type: "PROMOTER",
      subType: "UPDATE_CUT_OFF",
      request: {
        ...cutoff,
        id: id,
      },
    });

    dispatch(setSuccess("entities", "PROMOTER_UPDATE_CUT_OFF"));
    dispatch(setRedirect(`/entities/cutoffs`));
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default promoterUpdateCutOffCall;

export const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
    borderRadius: theme.spacing(1)
  },
  avatar: {
    // borderRadius: "0 0 " + theme.spacing(1) + " 0",
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5) + " " + theme.spacing(3) + " " + theme.spacing(0.5) + " " + theme.spacing(1),
    display: "inline-block"
  },
  comment: {
    padding: "0 " + theme.spacing(1),
    margin: 0,
  },
  date: {
    color: theme.palette.text.secondary,
    margin: 0,
    width: "100%",
    textAlign: "right",
    paddingRight: 5
  }
});


import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess, setRedirect } from "../../../app/coreSlice";

const taskAssignCall = async (
  { _ },
  dispatch,
  state,
  { taskId, entityId, userId, groupId, sectorCode }
) => {
  let rh = requestHelper(dispatch, "TASK_ASSIGN");
  try {
    let result = await call({
      type: "TASK",
      subType: "ASSIGN",
      request: {
        taskId,
        entityId,
        userId,
        groupId,
        sectorCode
      },
    });

   if (result && result.success) {

     setTimeout(() => {
       dispatch(setRedirect("/tasks"));
       dispatch(setSuccess("tasks", "TASK_ASSIGN"));
     }, 300);
   }


  } catch (ex) {
    rh.error("tasks", ex);
  }
  rh.close();
};

export default taskAssignCall;


export const styles = () => ({
  root: {
    marginBottom: 5
  },
  type: {
    fontWeight: "bold",
    fontSize: 12,
    margin: 0,
  },
  button: {
    marginRight: -5
  },
  label: {
    margin: 0,
    fontSize: 10,
    color: "#97A1AF",
    textTransform: "uppercase",
    marginBottom: 2
  },
  value: {
    margin: 0,
    fontSize: 10,
    lineHeight: 1,
    marginBottom: 2
  }
});
export const styles = () => ({
  root: {
  },
  tabs: {
    "& .MuiTab-root": {
      alignItems: "center",
      maxWidth: "none",
    },
    "& .MuiTab-wrapper": {
      alignItems: "flex-start",
    },
    "& .Mui-selected": {
      backgroundColor: "#FFF",
      borderRadius: "2px 0 0 2px",
    },
  },
  section: {
    position: "relative",
  },
  anchor: {
    position: "absolute",
    top: -90,
  },
});
import * as Yup from "yup";
import { AutoCompleteField, EntityAutoCompleteField, NumberField, TextField } from "../../../../common/forms";

export const formSchema = ({ t, user }) => {
  return Yup.object().shape({
    baseInfo: Yup.object().shape({
      ownerId: user?.entity?.type === "P" ? Yup.string().required(t("products.ProductCreate.form.requiredProductEntityId")) : Yup.string(),
      gln: Yup.string(),
      gtin: Yup.string().gtin(t("products.ProductCreate.form.invalidGtin")),
      supplierInternalCode: Yup.string().nullable(),
    })
  });
}

export function formFields({
  t, user, promoter, loading, onOwnerChange, onGlnChange, onGtinChange, form, entity
}) {
  const entityOptions = (entity?.businessUnits || [])
    .map((item) => {
      return {
        value: item.id,
        text: item.name
      };
    });

  return [
    {
      size: 12,
      field: (
        <EntityAutoCompleteField
          typeFilter={promoter?.getConfig("product", "allowedEntityTypes")}
          id="baseInfo.ownerId"
          label={t("products.ProductCreate.form.ownerId")}
          required
          disabled={loading}
          onChange={(owner) => onOwnerChange(owner, form)}
        />
      ),
      hidden: user?.entity?.type !== "P",
    },
    {
      size: 12,
      field: (
        <AutoCompleteField
          id="baseInfo.ownerId"
          label={t("products.ProductCreate.form.ownerId")}
          required
          disabled={loading}
          options={entityOptions}
          onChange={(ownerId) => onOwnerChange(entity?.businessUnits.find(item => item.id === ownerId), form)}
        />
      ),
      hidden: user?.entity?.type === "P" || entity?.businessUnitId !== 0 || entity?.businessUnits?.length <= 1,
    },
    {
      size: 6,
      field: (
        <NumberField
          id="baseInfo.gln"
          label={t("products.ProductCreate.form.gln")}
          maxLength={13}
          decimalScale={0}
          disabled={loading}
          allowLeadingZeros
          onChange={(gln) => onGlnChange(gln, form)}
        />
      ),
    },
    {
      size: 6,
      field: (
        <NumberField
          id="baseInfo.gtin"
          label={t("products.ProductCreate.form.gtin")}
          maxLength={14}
          decimalScale={0}
          disabled={loading}
          allowLeadingZeros
          onChange={(gtin) => onGtinChange(gtin, form)}
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextField
          id="baseInfo.supplierInternalCode"
          type="text"
          label={t("products.ProductCreate.form.supplierInternalCode")}
          disabled={loading}
        />
      ),
    },
  ]
}
export const styles = (theme) => ({
  root: {},
  modal: {
    paddingTop: "10vh",
    '&:focus': {
      outline: 'none',
    },
  },
  modalroot: {
    width: "70vw",
    margin: "0 auto",
    backgroundColor: theme.palette.secondary.main,
    height: "80vh",
  },
  modalbody: {
    padding: theme.spacing(2),
  },
  grid: {
    padding: 2,
    height: "calc( 80vh - 90px )",
    overflow: "auto",
  },
  emptytable: {
    textAlign: "center",
    border: "1px solid " + theme.palette.text.secondary,
    padding: 10,
    borderRadius: 4,
    cursor: "pointer",
  }
});

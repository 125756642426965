import React from "react";
import {Route, Routes} from "react-router-dom";
import TasksList from "./TasksList/TasksList";
import TasksForm from "./TasksForm/TasksForm";

function TasksRouting() {
  return (
    <Routes>
      <Route path="/" element={<TasksList />} />
      <Route path="/:taskId" element={<TasksList />} />
      <Route path="/create" element={<TasksForm />} />
    </Routes>
  );
}

export default TasksRouting;

import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const entityDisableGroupCall = async ({ listGroupsAppend }, dispatch, state, { groupId, entityId }) => {
  const filter = state.entities.groupListFilter;
  let rh = requestHelper(dispatch, "DISABLE_GROUP");
  try {
    await call({
      type: "ENTITY",
      subType: "DISABLE_GROUP",
      request: {
        id: groupId,
        entityId,
      },
    });
    setTimeout(() => {
      dispatch(setSuccess("entities", "ENTITY_DISABLE_GROUP"));
      dispatch(listGroupsAppend(filter, true));
    }, 300);
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityDisableGroupCall;

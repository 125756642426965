import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useFirstLoad, useNavigate, useUser } from "../../../common/hooks";
import { Button, Grid } from "@mui/material";

import { styles } from "./DocumentTemplate.styles";
import { useParams } from "react-router-dom";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import VersionsDataGrid from "./VersionsDataGrid/VersionsDataGrid";
import { formFields, formSchema } from "./DocumentTemplateForm.schema";
import Form from "../../../common/forms/Form";
import Permission from "../../../app/components/Permission";
import {
  documentGetPdf,
  documentListPdf,
  documentNewVersionPdf,
  selectPdfDocument,
  setPdf,
} from "../documentSlice.calls";
import HTMLEditor from "../../../common/components/displays/HTMLEditor";
import Page from "../../../common/displays/Page/Page";
import { FormFields } from "../../../common/forms";

function DocumentTemplate({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const documentTemplate = useSelector(selectPdfDocument);
  const user = useUser();

  useFirstLoad(() => {
    if (documentTemplate?.id !== id) {
      dispatch(documentGetPdf(id));
    }
  });

  const editorRef = useRef();

  const onSubmit = async (values) => {
    dispatch(documentNewVersionPdf(documentTemplate.id, editorRef.current.getContent(), navigate));
  };

  const onRowSelectedHandler = (row) => {
    if (row.id !== documentTemplate?.version) {
      dispatch(documentGetPdf(row.id));
      navigate("/documents/" + row.id);
    }
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        schema={formSchema(t)}
        initialValues={documentTemplate}
        render={({ submitting, values }) => {
          return (
            <Page
              permission="promoter.actions.document.get"
              header={<PageTitle
                title={t("documents.DocumentTemplate.header.title")}
                subtitle={
                  t("documents.DocumentTemplate.header.subtitle") +
                  " " +
                  documentTemplate?.type +
                  "/" +
                  documentTemplate?.subType
                }
                onBack={() => {
                  dispatch(documentListPdf());
                  dispatch(setPdf({}));
                  navigate("/documents");
                }}
                actions={
                  <Permission code="promoter.actions.document.update">
                    <Button variant="contained" color="primary" type="submit" size="large" disabled={submitting}>
                      {t("documents.DocumentTemplate.header.saveBtn")}
                    </Button>
                  </Permission>
                }
              />}>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormFields fields={formFields(t)} />
                </Grid>
                <Grid item xs={12}>
                  <HTMLEditor
                    label={"documents.DocumentTemplate.editor.body"}
                    initialValue={documentTemplate?.body}
                    disabled={!user.hasPermission("promoter.actions.document.update")}
                    variables={[
                      { label: "documents.DocumentTemplate.editor.templateVars", vars: documentTemplate?.variables },
                    ]}
                    editorRef={editorRef}
                  />
                </Grid>
                <Grid item xs={6}>
                  <VersionsDataGrid
                    currentVersion={documentTemplate?.version}
                    data={documentTemplate?.versions}
                    totalRows={documentTemplate?.versions?.length}
                    onRowSelected={onRowSelectedHandler}
                  />
                </Grid>
              </Grid>
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(DocumentTemplate, styles);



import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess, setRedirect } from "../../../app/coreSlice";
import { taskListComments } from "../tasksSlice";

const taskActionCall = async (
  dispatch,
  state,
  { taskId, status, comment, payload, dueDate }
) => {
  let rh = requestHelper(dispatch, "TASK_ACTION");
  try {
    let result = await call({
      type: "TASK",
      subType: "ACTION",
      request: {
        taskId,
        status, // (COMPLETED/UPDATED)
        comment,
        payload, // (key / value)
        dueDate
      },
    });

    if (result) {
      setTimeout(() => {
        dispatch(setSuccess("tasks", "TASK_ACTION"));
        if (comment !== undefined) {
          dispatch(taskListComments({ ...state.tasks?.taskListCommentsFilter, taskId: taskId }, true));
        } else {
          dispatch(setRedirect("/tasks"));
        }
      }, 300);
    }

  } catch (ex) {
    rh.error("tasks", ex);
  }
  rh.close();
};

export default taskActionCall;


import React from "react";
import { Typography } from "@mui/material";

export function listColumns(t, classes) {
  return [
    {
      field: "code",
      headerName: t("promoter.RefDataTaxsList.columns.code"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => <Typography>{t("common.Enums.vat." + v.code)}</Typography>,
    },
    {
      field: "value",
      headerName: t("promoter.RefDataTaxsList.columns.value"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => <Typography>{v.value + " %"}</Typography>,
    },
    {
      field: "locationsName",
      headerName: t("promoter.RefDataTaxsList.columns.locations"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => <Typography>{(v.locationsName || []).join(", ")}</Typography>,
    },
    {
      field: "active",
      headerName: t("promoter.RefDataTaxsList.columns.active"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v.active ? (
          <Typography className={classes.statusActive}>{t("promoter.RefDataTaxsList.active.true")}</Typography>
        ) : (
          <Typography className={classes.statusDisabled}>{t("promoter.RefDataTaxsList.active.false")}</Typography>
        );
      },
    },
  ];
}

// "active": true,
// "createdBy": "system",
// "createdTime": "2021-11-25T10:52:21.29049",
// "updatedBy": "system",
// "updatedTime": "2021-11-25T10:52:21.29049",
// "id": "f02057e6-91ed-4f60-87a6-7669cfb6e31c",
// "code": "VAT0",
// "group": "VAT",
// "value": 0

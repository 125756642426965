import * as Yup from "yup";
import React from "react";
import LineField from "./LineField/LineField";

export function formSchema({ t }) {
  return Yup.object().shape({
    lines: Yup.array().of(Yup.object().shape({
      successRate: Yup.string().test(
        "maxSuccessRate",
        t("businessUnits.BusinessUnitProductionForm.form.maxSuccessRate"),
        (value) => value === undefined || (parseFloat(value) >= 0 && parseFloat(value) <= 100)
      ),
      loadBalance: Yup.string().test(
        "maxLoadBalance",
        t('businessUnits.BusinessUnitProductionForm.form.maxLoadBalance'),
        (value) => value === undefined || (parseFloat(value) >= 0 && parseFloat(value) <= 100)
      ),
    })),
  });
}

export function formFields({ t, businessUnits  }) {

  return [
    {
      size: 12,
      field: <LineField id="lines" businessUnits={businessUnits} />,
    },
  ];
}


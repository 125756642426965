import React, { useEffect, useRef, useState } from "react";
import { withStyles } from "tss-react/mui";
import { Grid, SvgIcon, Typography } from "@mui/material";

import { styles } from "./_base.styles";
import { toBase64 } from "../../utils";

const FileUploadIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20ZM8 15.01L9.41 16.42L11 14.84V19H13V14.84L14.59 16.43L16 15.01L12.01 11L8 15.01Z"
        fill="#97A1AF"
      />
    </SvgIcon>
  );
};

const DefaultImageIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#FBFCFD" stroke="inherit" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="inherit" />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29 17C31.2091 17 33 15.2091 33 13C33 10.7909 31.2091 9 29 9C26.7909 9 25 10.7909 25 13C25 15.2091 26.7909 17 29 17ZM4 29.5V37C4 38.6569 5.34315 40 7 40H34.5L15.8453 21.3453C15.0917 20.5917 13.8795 20.5614 13.0892 21.2764L4 29.5ZM37.5 32.5V38.7929C37.5 39.2383 36.9614 39.4614 36.6464 39.1464L23 25.5L26.1358 23.4095C26.929 22.8807 27.9852 22.9852 28.6594 23.6594L37.5 32.5Z"
          stroke="none"
          fill="inherit"
        />
      </g>
    </SvgIcon>
  );
};

// const isImage = (file) => {
//   return file?.type === "image/png" || file?.type === "image/jpeg";
// };

function FileUploadInput({
  classes,
  id,
  onChange,
  multiple,
  placeholder,
  file,
  label,
  required,
  variant = "outlined",
  disabled,
  image,
  accept = "*/*",
  onError,
  maxFileSize,
  clearOnChange,
}) {
  const inputFileRef = useRef();
  const [tempName, setTempName] = useState();
  const [localFile, setLocaFile] = useState();

  useEffect(() => {
    setLocaFile(file);
  }, [file]);

  const handleUpload = async (e) => {
    /*Selected files data can be collected here.*/
    if (multiple) {
      let files = [];
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        files.push({
          name: file.name,
          type: file.type,
          base64: await toBase64(file),
        });
      }
      if (files) {
        setTempName(files.map((item) => item.name).join(", "));
        onChange && onChange(files);
      }
    } else {
      const file = e.target.files[0];
      if (file) {
        if (maxFileSize && file.size > parseFloat(maxFileSize) * 1024 * 1024) {
          onError && onError("SIZE_TOO_BIG");
        } else {
          let fileObj = {
            name: file.name,
            mediaType: file.type,
            base64: await toBase64(file),
          };
          setTempName(file.name);
          setLocaFile(fileObj);
          onChange && onChange(fileObj);
          if (clearOnChange) {
            setLocaFile();
            inputFileRef.current.value = "";
          }
        }
      }
    }
  };

  const handleClick = () => {
    if (!disabled) {
      inputFileRef.current.click();
    }
  };

  const description = multiple ? tempName : localFile?.name || tempName;

  return (
    <div>
      {variant === "outlined" && label && (
        <Typography
          component="span"
          variant="caption"
          htmlFor={id}
          color="textPrimary"
          className={disabled ? classes.disabledLabel : ""}
        >
          {label}
          {required && <span> *</span>}
        </Typography>
      )}
      <div className={classes.fileupload + (disabled ? " " + classes.fileuploadDisabled : "")} onClick={handleClick}>
        <Grid container direction="row" wrap="nowrap" spacing={1} justifyContent="space-between" alignItems="center">
          {image && (
            <Grid item>
              <div className={classes.fileuploadImage}>
                {localFile?.url || localFile?.base64 ? (
                  <img src={localFile?.url || localFile?.base64} alt={localFile?.name}></img>
                ) : (
                  <DefaultImageIcon className={classes.fileuploadImagePlaceholder} />
                )}
              </div>
            </Grid>
          )}
          <Grid item style={{ overflow: "hidden" }}>
            {description ? (
              <Typography className={classes.filename} component="span" variant="subtitle1" color="textPrimary">
                {description}
              </Typography>
            ) : (
              <Typography
                className={classes.fileuploadTextPlaceholder}
                variant="body1"
                color="textSecondary"
                component="label"
              >
                {placeholder}
              </Typography>
            )}
          </Grid>
          <Grid item style={{ height: 32 }}>
            <FileUploadIcon />
          </Grid>
          <input
            accept={accept}
            className={classes.fileuploadInput}
            id={id}
            type="file"
            ref={inputFileRef}
            onChange={(e) => handleUpload(e)}
            multiple={multiple}
          />
        </Grid>
      </div>
    </div>
  );
}

export default withStyles(FileUploadInput, styles);

import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const promoterAddBusCall = async (
  { entityGet },
  dispatch,
  {
    entityId,
    taxNumber,
    type,
    businessUnitDesc,
    cep,
    gln,
    trafficType,
    operationType,
    fee,
    cmpAccumulation,
    cpAccumulation,
  }
) => {
  let rh = requestHelper(dispatch, "ADD_BUSINESS_UNIT");
  try {
    await call({
      type: "PROMOTER",
      subType: "ADD_BUS",
      request: {
        entity: {
          taxNumber,
          type,
          config: [
            {
              businessUnitDesc,
              cep,
              gln,
              trafficType,
              operationType,
              fee: fee?.quantity ? fee : undefined,
              cmpAccumulation,
              cpAccumulation,
            },
          ],
        }
      },
    });
    setTimeout(() => {
      dispatch(entityGet(entityId));
      dispatch(setRedirect("/entities/" + entityId));
      dispatch(setSuccess("entities", "BUSINESS_UNIT_ADDED"));
    }, 200);
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default promoterAddBusCall;

import {createSlice} from "@reduxjs/toolkit";
import {default as entityListCall} from "./_sliceCalls/entityList";
import {default as entityGetCall} from "./_sliceCalls/entityGet";
import {default as entityGetByTaxNumberCall} from "./_sliceCalls/entityGetByTaxNumber";
import {entityInitialValues} from "./_sliceCalls/_auxiliars";
import entityCreateCall from "./_sliceCalls/entityCreate";
import entityUpdateLogoCall from "./_sliceCalls/entityUpdateLogo";
import promoterAddBusCall from "./_sliceCalls/promoterAddBus";
import entityUpdateConfigCall from "./_sliceCalls/entityUpdateConfig";
import entityUpdateTaxAddressCall from "./_sliceCalls/entityUpdateTaxAddress";
import entityUpdateNameCall from "./_sliceCalls/entityUpdateName";
import operatorListAddressCall from "./_sliceCalls/operatorListAddress";
import entityAddContactCall from "./_sliceCalls/entityAddContact";
import entityUpdateContactCall from "./_sliceCalls/entityUpdateContact";
import entityDisableContactCall from "./_sliceCalls/entityDisableContact";
import entityAddAddressCall from "./_sliceCalls/entityAddAddress";
import entityDisableAddressCall from "./_sliceCalls/entityDisableAddress";
import entityConfirmAddressCall from "./_sliceCalls/entityConfirmAddress";
import entityDisableCall from "./_sliceCalls/entityDisable";
import entityEnableCall from "./_sliceCalls/entityEnable";
import entityConfirmCall from "./_sliceCalls/entityConfirm";
import entityList_ownerCall from "./_sliceCalls/entityList_owner";
import entityList_autocompleteCall from "./_sliceCalls/entityList_autocomplete";
import entityListGroupCall from "./_sliceCalls/entityListGroup";
import entityGetGroupCall from "./_sliceCalls/entityGetGroup";
import entityCreateGroupCall from "./_sliceCalls/entityCreateGroup";
import entityUpdateGroupCall from "./_sliceCalls/entityUpdateGroup";
import entityDisableGroupCall from "./_sliceCalls/entityDisableGroup";
import entityListGroup_fullCall from "./_sliceCalls/entityListGroup_full";
import promoterListCutOffCall from "./_sliceCalls/promoterListCutOff";
import promoterCreateCutOffCall from "./_sliceCalls/promoterCreateCutOff";
import promoterUpdateCutOffCall from "./_sliceCalls/promoterUpdateCutOff";
import orderCutOffCall from "./_sliceCalls/orderCutOff";
import promoterEnableCutOffCall from "./_sliceCalls/promoterEnableCutOff";
import promoterDisableCutOffCall from "./_sliceCalls/promoterDisableCutOff";
import promoterAddExternalAccessCall from "./_sliceCalls/promoterAddExternalAccess";
import promoterDisableExternalAccessCall from "./_sliceCalls/promoterDisableExternalAccess";
import userDefineExternalAccessCall from "./_sliceCalls/userDefineExternalAccess";
import entityCreateSectorGroupCall from "./_sliceCalls/entityCreateSectorGroupCall";
import entityUpdateSectorGroupCall from "./_sliceCalls/entityUpdateSectorGroupCall";
import entityDeleteSectorGroupCall from "./_sliceCalls/entityDeleteSectorGroupCall";
import {
  cutOffsDataGridDefaultFilter,
  entitiesDataGridDefaultFilter,
  entityDetailCommentsDefaultFilter,
  groupDataGridDefaultFilter,
  pendingEntityListDefaultFilter
} from "./_common/entitiesFilters";
import entityList_pickerCall from "./_sliceCalls/entityList_pickerCall";
import entityUpdateAddressCall from "./_sliceCalls/entityUpdateAddressCall";
import entityPendingEntityActionCall from "./_sliceCalls/entityPendingEntityActionCall";
import entityPendingEntityListCall from "./_sliceCalls/entityPendingEntityListCall";
import entityAddDocumentCall from "./_sliceCalls/entityAddDocumentCall";
import entityGetContactCall from "./_sliceCalls/entityGetContactCall";
import promoterScheduleCutOffCall from "./_sliceCalls/promoterScheduleCutOff";
import entityListCommentsCall from "./_sliceCalls/entityListComments";
import {appendItemsToList} from "../../common/utils";

// SLICE
export const entitiesSlice = createSlice({
  name: "entities",
  initialState: {
    createResult: null,
    details: null,
    entityCheckDetails: entityInitialValues(false, {}),
    list: null,
    listTotalRows: 0,
    operators: null,
    listFilter: entitiesDataGridDefaultFilter,
    entitiesForOwner: null,
    groupList: null,
    groupListTotalRows: 0,
    groupListFilter: groupDataGridDefaultFilter,
    groupDetails: null,
    groupFullList: null,
    cutOffList: null,
    cutOffListTotalRows: 0,
    cutOffListFilter: cutOffsDataGridDefaultFilter,
    cutOffDetails: null,
    autoCompleteEntitiesList: [],
    externalAccessKey: null,
    pickerEntitiesList: [],
    pendingEntityList: null,
    pendingEntityListTotalRows: 0,
    pendingEntityListFilter: pendingEntityListDefaultFilter,
    contactDetails: null,
    entityDetailComments: null,
    entityDetailCommentsFilter: entityDetailCommentsDefaultFilter,
    entityDetailCommentsTotalRows: 0,
  },
  reducers: {
    setCreateResult: (state, action) => {
      state.createResult = action.payload;
    },
    setEntityDetails: (state, action) => {
      state.details = action.payload;
    },
    setEntityCheckDetails: (state, action) => {
      state.entityCheckDetails = action.payload;
    },
    clearList: (state) => {
      state.list = [];
    },
    appendToList: (state, action) => {
      state.list = [...state.list, ...action.payload];
    },
    setListTotalRows: (state, action) => {
      state.listTotalRows = action.payload;
    },
    setOperators: (state, action) => {
      state.operators = action.payload;
    },
    setListFilter: (state, action) => {
      state.listFilter = action.payload;
    },
    setEntitiesForOwner: (state, action) => {
      state.entitiesForOwner = action.payload;
    },
    clearGroupList: (state) => {
      state.groupList = [];
    },
    appendToGroupList: (state, action) => {
      state.groupList = [...state.groupList, ...action.payload];
    },
    setGroupListTotalRows: (state, action) => {
      state.groupListTotalRows = action.payload;
    },
    setGroupListFilter: (state, action) => {
      state.groupListFilter = action.payload;
    },
    setGroupDetails: (state, action) => {
      state.groupDetails = action.payload;
    },
    setGroupsFull: (state, action) => {
      state.groupFullList = action.payload;
    },
    clearCutOffList: (state) => {
      state.cutOffList = [];
    },
    appendToCutOffList: (state, action) => {
      state.cutOffList = [...state.cutOffList, ...action.payload];
    },
    setCutOffListTotalRows: (state, action) => {
      state.cutOffListTotalRows = action.payload;
    },
    setCutOffListFilter: (state, action) => {
      state.cutOffListFilter = action.payload;
    },
    setCutOffDetails: (state, action) => {
      state.cutOffDetails = action.payload;
    },
    setAutoCompleteEntitiesList: (state, action) => {
      state.autoCompleteEntitiesList = action.payload;
    },
    setExternalAccessKey: (state, action) => {
      state.externalAccessKey = action.payload;
    },
    updateSelectedEntity: (state, action) => {
      let nDetails = state.details;
      if (nDetails.entityId === action.payload.entityId) {
        state.details = { ...nDetails, ...action.payload };
      }
    },
    setPickerEntitiesList: (state, action) => {
      state.pickerEntitiesList = action.payload;
    },

    clearPendingEntityList: (state) => {
      state.pendingEntityList = [];
    },

    appendToPendingEntityList: (state, action) => {
      state.pendingEntityList = [...(state.pendingEntityList || []), ...action.payload];
    },

    setPendingEntityListTotalRows: (state, action) => {
      state.pendingEntityListTotalRows = action.payload;
    },

    setPendingEntityListFilter: (state, action) => {
      state.pendingEntityListFilter = action.payload;
    },

    setContactDetails: (state, action) => {
      state.contactDetails = action.payload;
    },
    setEntityDetailComments: (state, action) => {
      state.entityDetailComments = action.payload;
    },

    clearEntityDetailComments: (state) => {
      state.entityDetailComments = [];
    },

    appendToEntityDetailComments: (state, action) => {
      appendItemsToList(state.entityDetailComments, action.payload, true, "id");
    },

    setEntityDetailCommentsTotalRows: (state, action) => {
      state.entityDetailCommentsTotalRows = action.payload;
    },
    setEntityDetailCommentsFilter: (state, action) => {
      state.entityDetailCommentsFilter = action.payload;
    },
  },
});

export default entitiesSlice.reducer;

export const {
  setEntityDetails,
  setEntityCheckDetails,
  setCreateResult,
  appendToList,
  clearList,
  setListTotalRows,
  setOperators,
  setListFilter,
  setEntitiesForOwner,
  clearGroupList,
  appendToGroupList,
  setGroupListTotalRows,
  setGroupListFilter,
  setGroupDetails,
  setGroupsFull,
  clearCutOffList,
  appendToCutOffList,
  setCutOffListTotalRows,
  setCutOffListFilter,
  setCutOffDetails,
  setCutOffCreate,
  setAutoCompleteEntitiesList,
  setExternalAccessKey,
  clearEntityDetailComments,
} = entitiesSlice.actions;

// SELECTS
export const selectIsLoading = (state) => state.core.loader.length > 0;

export const selectList = (state) => state.entities.list;
export const selectListTotalRows = (state) => state.entities.listTotalRows;
export const selectListFilter = (state) => state.entities.listFilter;
export const selectEntityDetails = (state) => state.entities.details;

export const selectEntityCheckDetails = (state) => state.entities.entityCheckDetails;

export const selectLoader = (state) => state.core.loader.length > 0;
export const selectCreateResult = (state) => state.entities.createResult;

export const selectOperators = (state) => state.entities.operators;
export const selectEntitiesForOwner = (state) => state.entities.entitiesForOwner;
export const selectGroupList = (state) => state.entities.groupList;
export const selectGroupListTotalRows = (state) => state.entities.groupListTotalRows;
export const selectGroupListFilter = (state) => state.entities.groupListFilter;
export const selectGroupDetails = (state) => state.entities.groupDetails;
export const selectGroupFull = (state) => state.entities.groupFullList;

export const selectCutOffList = (state) => state.entities.cutOffList;
export const selectCutOffListTotalRows = (state) => state.entities.cutOffListTotalRows;
export const selectCutOffListFilter = (state) => state.entities.cutOffListFilter;
export const selectCutOffDetails = (state) => state.entities.cutOffDetails;
export const selectAutoCompleteEntities = (state) => state.entities.autoCompleteEntitiesList;
export const selectExternalAccessKey = (state) => state.entities.externalAccessKey;
export const selectPickerEntities = (state) => state.entities.pickerEntitiesList;

export const selectPendingEntityList = (state) => state.entities.pendingEntityList;
export const selectPendingEntityListTotalRows = (state) => state.entities.pendingEntityListTotalRows;
export const selectPendingEntityListFilter = (state) => state.entities.pendingEntityListFilter;

export const selectContactDetails = (state) => state.entities.contactDetails;

export const selectEntityDetailComment = (state) => state.entities.entityDetailComments;
export const selectEntityDetailCommentsFilter = (state) => state.entities.entityDetailCommentsFilter;
export const selectEntityDetailCommentsTotalRows = (state) => state.entities.entityDetailCommentsTotalRows;
// CALLS
export const entityList =
  (filter, clear = false) =>
    (dispatch) => {
      entityListCall(entitiesSlice.actions, dispatch, filter, clear);
    };

export const entityGet = (id) => (dispatch, getState) => {
  entityGetCall(entitiesSlice.actions, dispatch, getState(), id);
};

export const entityGetByTaxNumber = (taxNumber) => (dispatch) => {
  entityGetByTaxNumberCall(entitiesSlice.actions, dispatch, taxNumber);
};

export const clearCheckEntity = () => (dispatch) => {
  dispatch(setEntityCheckDetails(entityInitialValues(false, {})));
};

/// --- TO TEST:

export const entityUpdateLogo =
  ({ entityId, logo }) =>
    async (dispatch, getState) => {
      entityUpdateLogoCall(dispatch, getState(), { entityId, logo });
    };

export const createEntity = (values) => async (dispatch, getState) => {
  entityCreateCall({ ...entitiesSlice.actions, entityUpdateLogo }, dispatch, getState(), values);
};

export const addBusinessUnit =
  (
    entityId,
    { taxNumber, type, businessUnitDesc, cep, gln, trafficType, operationType, fee, cmpAccumulation, cpAccumulation }
  ) =>
    async (dispatch) => {
      promoterAddBusCall({ entityGet }, dispatch, {
        entityId,
        taxNumber,
        type,
        businessUnitDesc,
        cep,
        gln,
        trafficType,
        operationType,
        fee,
        cmpAccumulation,
        cpAccumulation,
      });
    };

export const entityUpdateConfig =
  ({ entityId, cep, fee, gln, trafficType, operationType, cmpAccumulation, cpAccumulation, businessUnitDesc, promoterInternalCode }) =>
    async (dispatch) => {
      entityUpdateConfigCall({ entityGet }, dispatch, {
        entityId,
        cep,
        fee,
        gln,
        trafficType,
        operationType,
        cmpAccumulation,
        cpAccumulation,
        businessUnitDesc,
        promoterInternalCode
      });
    };

export const entityUpdateTaxAddress =
  ({ id, address, postalCode, locale, county, state, country }) =>
    async (dispatch) => {
      entityUpdateTaxAddressCall(dispatch, { id, address, postalCode, locale, county, state, country });
    };

export const entityUpdateName =
  ({ entityId, name }) =>
    async (dispatch, getState) => {
      entityUpdateNameCall({ entityGet, entityList }, dispatch, getState(), { entityId, name });
    };

export const getOperators = () => async (dispatch) => {
  operatorListAddressCall(entitiesSlice.actions, dispatch);
};

export const addContact = (_promoter, entityId, contact) => async (dispatch, getState) => {
  entityAddContactCall({ entityGet }, dispatch, getState(), { entityId, contact });
};

export const updateContact = (_promoter, entityId, contactId, contact) => async (dispatch, getState) => {
  entityUpdateContactCall({ entityGet }, dispatch, getState(), { entityId, contactId, contact });
};

export const deleteContact = (_promoter, entityId, id) => async (dispatch) => {
  entityDisableContactCall({ entityGet }, dispatch, { entityId, id });
};

export const addAddress = (_promoter, entityId, address) => async (dispatch) => {
  entityAddAddressCall({ entityGet }, dispatch, { entityId, address });
};

export const deleteAddress = (_promoter, entityId, id) => async (dispatch) => {
  entityDisableAddressCall({ entityGet }, dispatch, { entityId, id });
};

export const confirmAddress = (entityId, id) => async (dispatch) => {
  entityConfirmAddressCall({ entityGet }, dispatch, { entityId, id });
};

export const disableEntity = (filter, id) => async (dispatch) => {
  entityDisableCall({ entityList, ...entitiesSlice.actions }, dispatch, { filter, id });
};

export const enableEntity = (filter, id) => async (dispatch) => {
  entityEnableCall({ entityList, ...entitiesSlice.actions }, dispatch, { filter, id });
};

export const confirmEntity = (id) => async (dispatch) => {
  entityConfirmCall(entitiesSlice.actions, dispatch, { id });
};

export const getEntitiesForOwner =
  (typeFilter = ["S", "W"]) =>
    async (dispatch) => {
      entityList_ownerCall(entitiesSlice.actions, dispatch, { typeFilter });
    };

export const loadEntitiesForAutoComplete = () => async (dispatch) => {
  entityList_autocompleteCall(entitiesSlice.actions, dispatch);
};

export const listGroupsAppend =
  (filter, clear = false) =>
    async (dispatch) => {
      entityListGroupCall(entitiesSlice.actions, dispatch, { filter, clear });
    };

export const getGroupDetails = (groupId) => async (dispatch) => {
  entityGetGroupCall(entitiesSlice.actions, dispatch, { groupId });
};

export const createGroup = (group) => async (dispatch) => {
  entityCreateGroupCall(dispatch, group);
};

export const updateGroup = (groupId, group) => async (dispatch) => {
  entityUpdateGroupCall(dispatch, { groupId, group });
};

export const deleteGroup = (groupId, entityId) => async (dispatch, getState) => {
  entityDisableGroupCall({ listGroupsAppend }, dispatch, getState(), { groupId, entityId });
};

export const listGroupsFull = () => async (dispatch) => {
  entityListGroup_fullCall(entitiesSlice.actions, dispatch);
};

export const listCutOffsAppend =
  (filter, clear = false) =>
    async (dispatch) => {
      promoterListCutOffCall(entitiesSlice.actions, dispatch, { filter, clear });
    };

export const createCutOff = (cutoff) => async (dispatch) => {
  promoterCreateCutOffCall(dispatch, cutoff);
};

export const updateCutOff = (id, cutoff) => async (dispatch) => {
  promoterUpdateCutOffCall(dispatch, { id, cutoff });
};

export const forceCutOff = (entityId, cutOff) => async (dispatch, getState) => {
  orderCutOffCall({ listCutOffsAppend }, dispatch, getState(), { entityId, cutOff });
};

export const scheduleCutOff = (type, description, dayOfMonth, dayOfWeek, hour, minute ,deliveryDay) => async (dispatch, getState) => {
  promoterScheduleCutOffCall({ listCutOffsAppend }, dispatch, getState(), { type, description, dayOfMonth, dayOfWeek, hour, minute ,deliveryDay});
};

export const enableCutOff = (cutOffId) => async (dispatch, getState) => {
  promoterEnableCutOffCall({ listCutOffsAppend }, dispatch, getState(), { cutOffId });
};

export const disableCutOff = (cutOffId) => async (dispatch, getState) => {
  promoterDisableCutOffCall({ listCutOffsAppend }, dispatch, getState(), { cutOffId });
};

export const promoterAddExternalAccess =
  ({ entityId, username }) =>
    async (dispatch) => {
      promoterAddExternalAccessCall({ entityGet }, dispatch, { entityId, username });
    };

export const promoterDisableExternalAccess =
  ({ entityId, username }) =>
    async (dispatch) => {
      promoterDisableExternalAccessCall({ entityGet }, dispatch, { entityId, username });
    };

export const userDefineExternalAccess =
  ({ entityId, username }) =>
    async (dispatch) => {
      userDefineExternalAccessCall(entitiesSlice.actions, dispatch, { entityId, username });
    };

export const entityCreateSectorGroup =
  ({ groupName, sector, entityId, header, users, productFamily }) =>
    async (dispatch) => {
      entityCreateSectorGroupCall({ entityGet }, dispatch, { groupName, sector, entityId, header, users, productFamily });
    };

export const entityUpdateSectorGroup =
  ({ groupId, groupName, sector, entityId, header, users, productFamily }) =>
    async (dispatch) => {
      entityUpdateSectorGroupCall({ entityGet }, dispatch, {
        groupId,
        groupName,
        sector,
        entityId,
        header,
        users,
        productFamily,
      });
    };

export const entityDeleteSectorGroup =
  ({ groupId, entityId }) =>
    async (dispatch) => {
      entityDeleteSectorGroupCall({ entityGet }, dispatch, {
        groupId,
        entityId,
      });
    };


export const loadEntitiesForPicker = (typeFilter) => async (dispatch) => {
  entityList_pickerCall(entitiesSlice.actions, dispatch, typeFilter);
};

export const entityUpdateAddress = ({ id, entityId, description, contactId }) => async (dispatch) => {
  entityUpdateAddressCall({ entityGet }, dispatch, { id, entityId, description, contactId });
};

export const entityPendingEntityAction = ({ status, taskId, comment, confirm , entityId}) => async (dispatch, getState) => {
  entityPendingEntityActionCall(dispatch, getState(), { status, taskId, comment, entityId });
}

export const entityPendingEntityList = (filter, clear) => async (dispatch) => {
  entityPendingEntityListCall(entitiesSlice.actions, dispatch, { filter, clear });
}

export const entityAddDocument = ({ entityId, file, metadata, type }) => async (dispatch, getState) => {
  entityAddDocumentCall({ entityGet }, dispatch, getState(), { entityId, file, metadata, type });
}

export const entityGetContact = ({ entityId, contactId }) => async (dispatch) => {
  entityGetContactCall(entitiesSlice.actions, dispatch, { entityId, contactId })
}

export const entityListComments = ({ entityId, filter, clear}) => async (dispatch, getState) => {
  entityListCommentsCall(entitiesSlice.actions, dispatch, getState(), { entityId, filter, clear });
}
import { CheckListField, CheckboxField } from "../../../../common/forms";

export const taxesFields = ({ t, user, formOptions }) => {
  return [
    {
      size: 4,
      field: (
        <CheckboxField
          id="functional.tax.autoAcceptCreateTax"
          label={t("promoter.PromoterConfigs.form.autoAcceptCreateTax")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <CheckboxField
          id="functional.tax.autoAcceptRemoveTax"
          label={t("promoter.PromoterConfigs.form.autoAcceptRemoveTax")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: (
        <CheckListField
          id="functional.tax.allowTaxes"
          options={formOptions.taxGroup}
          label={t("promoter.PromoterConfigs.form.allowTaxes")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
  ];
};
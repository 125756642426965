
import React, { useMemo } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./PlanningListDataGrid.styles";
import { listColumns } from "./PlanningListDataGrid.columns";
import PlanningListDataGridActions from "./PlanningListDataGrid.actions";

import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import { Grid, Typography } from "@mui/material";
import MeasureInput from "../../../../common/components/inputs/MeasureInput";
import { useDispatch } from "react-redux";
import { updateTempPlanningList } from "../../planningSlice";
import { getValue } from "../../_common/planningUtils";

function PlanningOverviewDataGrid({
  classes,
  data,
  tempData,
  onRemove,
  onCopy,
  onEdit,
  defaultPlanning,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const total = useMemo(() => {
    if (!data) {
      return 0;
    }
    let tot = 0;
    data.forEach(element => {
      tot += parseFloat(getValue((tempData || []).find(item => item.id === element.id)?.target?.value, element?.target?.value));
    });
    return tot;
  }, [data, tempData])

  const onMeasureChange = (plan, row) => {
    // console.log('onMeasureChange: %o', { plan, row })
    let nPlanning = [];
    let processed = false;
    let diff = 0;
    // change item 
    (tempData || []).forEach(item => {
      if (item.id === row.id) {
        processed = true;
        nPlanning.push({
          ...item,
          target: {
            ...item.target,
            value: parseFloat(plan.value),
          }
        });
        let ogRow = (data || []).find(r => r.id === item.id);
        diff = (parseFloat(ogRow?.target?.value) || 0) - (parseFloat(plan.value) || 0);
      }
      else {
        nPlanning.push(item);
      }
    });
    if (!processed) {
      nPlanning.push({
        ...row,
        target: {
          ...row.target,
          value: parseFloat(plan.value),
        }
      });
      diff = parseFloat(-plan.value);
    }

    if (!row?.defaultPlan) {
      let defaultProcessed = false;
      let nPlanning2 = [];
      nPlanning.forEach(item => {
        if (item.defaultPlan) {
          defaultProcessed = true;
          nPlanning2.push({
            ...defaultPlanning,
            target: {
              ...defaultPlanning.target,
              value: Math.max(0, (parseFloat(defaultPlanning?.target?.value) || 0) + parseFloat(diff))
            }
          });
        }
        else {
          nPlanning2.push(item);
        }
      });
      if (!defaultProcessed) {
        nPlanning2.push({
          ...defaultPlanning,
          target: {
            ...defaultPlanning.target,
            value: Math.max(0, (parseFloat(defaultPlanning?.target?.value) || 0) + parseFloat(diff)),
          }
        });
      }
      dispatch(updateTempPlanningList(nPlanning2));
    }
    else {
      dispatch(updateTempPlanningList(nPlanning));
    }
  }

  const columns = listColumns(t, tempData, defaultPlanning, onMeasureChange, total);

  const onTotalChangeHandler = (plan) => {
    let tot = 0;
    data.forEach(element => {
      tot += parseFloat(element?.target?.value || 0);
    });
    let nPlanning = [];
    (data || []).forEach(item => {
      let value = (tot > 0 ? parseFloat(item?.target?.value) / tot * parseFloat(plan.value) : parseFloat(plan.value));
      // console.log('--- %s --- %s --- %s ---> %o', item?.target?.value, tot, plan?.value, value);
      nPlanning.push({
        ...item,
        target: {
          ...item.target,
          value: value > 1000 ? value.toFixed(0) : value.toFixed(2),
        }
      });
    });
    dispatch(updateTempPlanningList(nPlanning));
  }

  const gridFooter = (
    <Grid container alignItems="flex-end">
      <Grid item flexGrow={1}>
        <Typography variant="caption">TOTAL</Typography>
      </Grid>
      <Grid item style={{ width: 260, paddingTop: 10 }}>
        <MeasureInput value={{ ...defaultPlanning?.target, value: (total || 0).toString() }} fixedCategory={defaultPlanning?.target?.category} onChange={onTotalChangeHandler} />
      </Grid>
    </Grid>
  )

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={columns}
        rowKeyField="id"
        rows={data}
        actionsComponent={<PlanningListDataGridActions onRemove={onRemove} onCopy={onCopy} onEdit={onEdit} />}
        footer={gridFooter}
      />
    </div>
  );
}

export default withStyles(PlanningOverviewDataGrid, styles);

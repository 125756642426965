import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, getPromoterConfig } from "../../../app/coreSlice";
import { processImageUrl } from "../../../api/s3";

const batchGetCall = async (
  { setBatchDetails },
  dispatch,
  state,
  { batchId }
) => {
  let rh = requestHelper(dispatch, "BATCH_GET");
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  try {
    let result = await call({
      type: "BATCH",
      subType: "GET",
      request: {
        batchId
      },
    });
    let batch = result?.batch ? {
      ...result.batch,
      product: {
        ...result.batch.product,
        thumbnail: await processImageUrl(fileStorageConfigs, result.batch.product?.thumbnail),
      }
    } : null

    dispatch(setBatchDetails(batch))
  } catch (ex) {
    rh.error("batches", ex);
  }
  rh.close();
};

export default batchGetCall;


import React, {useState} from "react";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Grid} from "@mui/material";

import {styles} from "./QueriesList.styles";
import OrdersOverviewDataGrid from "./OrdersOverviewDataGrid/OrdersOverviewDataGrid";
import ProductPricesDatagrid from "./ProductPricesDatagrid/ProductPricesDatagrid";
import MissingShipmentsDatagrid from "./MissingShipmentsDatagrid/MissingShipmentsDatagrid";
import ProductDataGrid from "./ProductDatagrid/ProductDataGrid";

import {
  businessReportMissingShipments,
  businessReportOrdersOverview,
  businessReportProduct,
  businessReportProductPrices,
  selectQueriesReportMissingShipmentsFilter,
  selectQueriesReportMissingShipmentsList,
  selectQueriesReportMissingShipmentsListTotalRows,
  selectQueriesReportOrdersOverviewFilter,
  selectQueriesReportOrdersOverviewList,
  selectQueriesReportOrdersOverviewListTotalRows,
  selectQueriesReportProductFilter,
  selectQueriesReportProductList,
  selectQueriesReportProductListTotalRows,
  selectQueriesReportProductPricesFilter,
  selectQueriesReportProductPricesList,
  selectQueriesReportProductPricesListTotalRows
} from "../businessSlice";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {SelectInput} from "../../../common/inputs";
import {getQueriesOptions} from "../BusinessForm.options";
import {useUser} from "../../../common/hooks";
import Page from "../../../common/displays/Page/Page";
import {selectLoader} from "../../../app/coreSlice";
import {dateTimeFormat} from "../../../common/utils";


function QueriesList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();
  const [selectedReport, setSelectedReport] = useState("empty");
  const loading = useSelector(selectLoader);

  const filterMissingShipments = useSelector(selectQueriesReportMissingShipmentsFilter);
  const totalRowsMissingShipments = useSelector(selectQueriesReportMissingShipmentsListTotalRows);
  const dataMissingShipments = useSelector(selectQueriesReportMissingShipmentsList);

  const filterOrdersOverview = useSelector(selectQueriesReportOrdersOverviewFilter);
  const totalRowsOrdersOverview = useSelector(selectQueriesReportOrdersOverviewListTotalRows);
  const dataOrdersOverview = useSelector(selectQueriesReportOrdersOverviewList);

  const filterProductPrices = useSelector(selectQueriesReportProductPricesFilter);
  const totalRowsProductPrices = useSelector(selectQueriesReportProductPricesListTotalRows);
  const dataProductPrices = useSelector(selectQueriesReportProductPricesList);

  const filterProduct = useSelector(selectQueriesReportProductFilter);
  const totalRowsProduct = useSelector(selectQueriesReportProductListTotalRows);
  const dataProduct = useSelector(selectQueriesReportProductList);

  const onFilterChangeHandler = ({ filter, reset }) => {
    populateReport(selectedReport, filter, reset);
  };

  const populateReport = (reportName, filter, reset) => {
    if (loading) {
      return;
    }

    if (reset === undefined) reset = true;

    switch (reportName) {
      case "report_missing_shipments":
        dispatch(businessReportMissingShipments(filter, reset));
        break;
      case "report_orders_overview":
        dispatch(businessReportOrdersOverview(filter, reset));
        break;
      case "report_product_prices":
        dispatch(businessReportProductPrices(filter, reset));
        break;
      case "report_product":
        dispatch(businessReportProduct(filter, reset));
        break;

      default:
        return;
    }
  };

  const onQueryOptionChange = (value) => {
    setSelectedReport(value);
    switch (value) {
      case "report_missing_shipments":
        populateReport(value, filterMissingShipments);
        break;
      case "report_orders_overview":
        populateReport(value, filterOrdersOverview);
        break;
      case "report_product_prices":
        populateReport(value, filterProductPrices);
        break;
      case "report_product":
        populateReport(value, filterProduct);
        break;
      default: break;
    }
  };

  const onLoadMoreOrdersOverviewListHandler = () => {
    if (dataOrdersOverview.length < totalRowsOrdersOverview && dataOrdersOverview.length > 0 && !loading) {
      let nFilter = {
        ...filterOrdersOverview,
        offset: dataOrdersOverview.length,
      };

      onFilterChangeHandler({
        filter: nFilter,
        reset: false,
      });
    }
  };
  const onLoadMoreMissingShipmentsHandler = () => {
    if (dataMissingShipments.length < totalRowsMissingShipments && dataMissingShipments.length > 0 && !loading) {
      let nFilter = {
        ...filterMissingShipments,
        offset: dataMissingShipments.length,
      };

      onFilterChangeHandler({
        filter: nFilter,
        reset: false,
      });
    }
  };

  const onLoadMoreProductPricesHandler = () => {
    if (dataProductPrices.length < totalRowsProductPrices && dataProductPrices.length > 0 && !loading) {
      let nFilter = {
        ...filterProductPrices,
        offset: dataProductPrices.length,
      };

      onFilterChangeHandler({
        filter: nFilter,
        reset: false,
      });
    }
  };

  const onLoadMoreProductHandler = () => {
    if (dataProduct.length < totalRowsProduct && dataProduct.length > 0 && !loading) {
      let nFilter = {
        ...filterProduct,
        offset: dataProduct.length,
      };

      onFilterChangeHandler({
        filter: nFilter,
        reset: false,
      });
    }
  };

  return (
    <Page
      permission="business.actions.queries.list"
      header={<PageTitle
        title={t("business.queries.header.title")}
        subtitle={
          selectedReport === "report_missing_shipments"
            ? `${t("business.queries.filter.year")}: ${filterMissingShipments?.year} | ${t("business.queries.filter.month")}: ${filterMissingShipments?.month}`
            : selectedReport === "report_orders_overview"
              ? `${t("business.queries.filter.year")}: ${filterOrdersOverview?.year} | ${t("business.queries.filter.month")}: ${filterOrdersOverview?.month}`
              : selectedReport === "report_product"
                      ? `${t("business.queries.filter.createdTimeIni")}: ${dateTimeFormat(filterProduct?.createdTimeIni)} | ${t("business.queries.filter.createdTimeEnd")}: ${dateTimeFormat(filterProduct?.createdTimeEnd)}`
                      : " "
        }
      />}>

      <Grid container direction="row" spacing={3}>
        <Grid item xs={4}>
          <SelectInput
            value={selectedReport}
            label={t("business.queries.filter.report")}
            onChange={onQueryOptionChange}
            options={getQueriesOptions(t, user)}
          />
        </Grid>
        <Grid item xs={12}>

          {/* report_missing_shipments */}
          {selectedReport === "report_missing_shipments" && (
            <MissingShipmentsDatagrid
              data={dataMissingShipments}
              totalRows={totalRowsMissingShipments}
              onLoadMore={onLoadMoreMissingShipmentsHandler}
              filter={filterMissingShipments}
              onFilterChange={onFilterChangeHandler}
            />
          )}

          {/* report_orders_overview */}
          {selectedReport === "report_orders_overview" && (
            <OrdersOverviewDataGrid
              onLoadMore={onLoadMoreOrdersOverviewListHandler}
              data={dataOrdersOverview}
              totalRows={totalRowsOrdersOverview}
              filter={filterOrdersOverview}
              onFilterChange={onFilterChangeHandler}
            />
          )}

          {/* report_product_prices */}
          {selectedReport === "report_product_prices" && (
            <ProductPricesDatagrid
              data={dataProductPrices}
              totalRows={totalRowsProductPrices}
              onLoadMore={onLoadMoreProductPricesHandler}
              filter={filterProductPrices}
              onFilterChange={onFilterChangeHandler}
            />
          )} {/* report_product */}
          {selectedReport === "report_product" && (
            <ProductDataGrid
              data={dataProduct}
              totalRows={totalRowsProduct}
              onLoadMore={onLoadMoreProductHandler}
              filter={filterProduct}
              onFilterChange={onFilterChangeHandler}
          />
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default withStyles(QueriesList, styles);

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const businessUnitDeleteStepCall = async (
  { businessUnitSteps },
  dispatch,
  { id, businessUnitId, lineId, internal }
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_DELETE_STEP");
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "DELETE_STEP",
      request: {
        id
      },
    });

    if (result) {
      dispatch(setSuccess("businessUnits", "BUSINESS_UNIT_DELETE_STEP"));
      setTimeout(() => {
        dispatch(businessUnitSteps({ businessUnitId, lineId, internal }));
      }, 300);
    }

  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitDeleteStepCall;


import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Stack, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { styles } from "./ResourceGroupField.styles";
import { useForm, useFormState } from "react-final-form";
import { getTaskInputField } from "../../../_common/auxiliars";
import ResourceSelectorModal from "./ResourceSelectorModal/ResourceSelectorModal";

function ResourceGroupField({ classes, input, view, fields, disabled, resources, places, task }) {
  const { t, i18n } = useTranslation();
  const form = useForm();
  const state = useFormState();
  const [modal, setModal] = useState(false);

  // console.log('form: %o', form);
  // console.log('values: %o', state?.values);

  const onAddResourceHandler = (values) => {
    setModal(false);
    if (!Object.keys(state?.values?.payload?.resources || {}).some(resourceId => resourceId === values.resource)) {
      form.change("payload.resources", { ...(state?.values?.payload?.resources || {}), [values?.resource]: {} });
    }
  }

  const selectedResources = resources.filter(item => Object.keys(state?.values?.payload?.resources || {}).some(resourceId => resourceId === item.id));

  const availableResources = (resources || []).filter(item => item.type === input.resourceType && !(selectedResources || []).map(obj => obj.id).includes(item.id));

  return (
    <div className={classes.root}>
      <Stack spacing={1}>
        {view ?
          <div>
            <div>
              <Typography className={classes.resourceTitle}>{t("common.Enums.resourceTypes." + input.resourceType)}</Typography>
            </div>
            <Stack spacing={1} className={classes.resource} >
              {
                (fields || []).map((field, index) => (<div key={"resource_" + index}>{getTaskInputField({ i18n, input: field, disabled, id: `payload.resources.example`, resources, places, task })}</div>))
              }
            </Stack>
          </div>
          :
          (selectedResources).map(item => (
            <div key={"resource_" + item.id}>
              <div>
                <Typography className={classes.resourceTitle}>{item.title}</Typography>
              </div>
              <Stack spacing={1} className={classes.resource} >
                {
                  (fields || []).map((field, index) => (<div key={"resource_" + item.id + "_" + index}>{getTaskInputField({ i18n, input: field, disabled, id: `payload.resources.${item.id}`, resources, places, task, resourceId: item.id })}</div>))
                }
              </Stack>
            </div>
          ))
        }
        {
          !view && availableResources.length > 0 && <IconButton size="small" className={classes.circleBtn} onClick={() => setModal(true)}>
            <AddIcon fontSize="small" />
          </IconButton>
        }
      </Stack>
      <ResourceSelectorModal open={modal} setOpen={setModal} onSubmit={onAddResourceHandler} resources={availableResources} />
    </div>
  );
}

export default withStyles(ResourceGroupField, styles);
import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Checkbox } from "@mui/material";

import { styles } from "./MeasureAttribute.styles";

function MeasureAttribute({ classes, field, onCheck, inputValue, parsedField, attribute, viewMode }) {
  const { t } = useTranslation();

  const onCheckHandler = (e) => {
    onCheck && onCheck(e.target.checked, [parsedField?.[attribute]?.section, field, attribute].join("."));
  };

  const checked = inputValue.indexOf([parsedField?.[attribute]?.section, field, attribute].join(".")) > -1;

  return (
    <div className={classes.root}>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        className={parsedField?.[attribute]?.hasDiff ? classes.hasDiff : ""}
      >
        <Grid item flexGrow={1} style={{ marginLeft: 8 }}>
          <Typography variant="caption" component="span">
            {t("products.ProductValidationForm.field." + field) +
              " " +
              t("products.ProductValidationForm.field.measure_" + attribute)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" component="span">
            {parsedField?.[attribute]?.value}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          {(parsedField?.[attribute]?.diff || []).map((part, index) =>
            part.added ? (
              <Typography key={field + "_" + index} variant="body1" component="span" className={classes.added}>
                {part.value}
              </Typography>
            ) : part.removed ? (
              <Typography key={field + "_" + index} variant="body1" component="span" className={classes.removed}>
                {part.value}
              </Typography>
            ) : (
              <Typography key={field + "_" + index} variant="body1" component="span">
                {part.value}
              </Typography>
            )
          )}
        </Grid>
        <Grid item style={{ width: 40 }}>
          {parsedField?.[attribute]?.hasDiff && !viewMode && (
            <Checkbox color="success" checked={checked} onChange={onCheckHandler} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(MeasureAttribute, styles);

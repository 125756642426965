const business = {
  flowsMessages: {
    success: {
      ACCOUNTING_SET_AVAILABLE: "Documents set available successfully",
      AUDIT_RETRY_JOB: "Retry job done successfully"
    },
    error: {
      GENERIC: "Something went wrong",
      RETRY_JOB_ERROR: "Unable to retry this job"
    },
  },
  queries: {
    header: {
      title: "Queries",
      filterBtn: "Filter",
    },
    options: {
      empty: "Choose an option",
      report_missing_shipments: "Report missing shipments",
      report_orders_overview: "Report orders overview",
      report_product_prices: "Report product prices",
      report_product: "Report product",
    },
    filter: {
      report: "Query",
      discrepancies: "Discrepancies",
      week: "Week",
      month: "Month",
      year: "Year",
      entity: "Entity",
      searchField: "Search Field",
      search: "Search",
      active: "Status",
      createdTimeIni: "Start Time",
      createdTimeEnd: "End Time",
      createdBy: "Created By",
      searchField_NONE: "",
      searchField_SELLERNAME: "SELLERNAME",
      searchField_SELLERTAX: "SELLERTAX",
      searchField_BUYERNAME: "BUYERNAME",
      searchField_BUYERTAX: "BUYERTAX",
      searchField_ADDRESS: "ADDRESS",
      searchField_ORDERSELLERID: "ORDERSELLERID",
      searchField_GTIN: "GTIN",
      searchField_GLN: "GLN",
      searchField_DESCRIPTION: "Description",
      searchField_ORDERID: "OrderId",
      searchField_CUTOFFID: "CutOffId",
    },
    discrepancies: {
      true: "Yes",
      false: "No",
    },
    OrdersOverviewList: {
      title: "Orders Overview",
      columns_sellerName: "Supplier",
      columns_buyerName: "Buyer",
      columns_description: "Description",
      columns_orderId: "Order Id",
      columns_lineId: "Line Id",
      columns_ordered: "Units Ordered",
      columns_orderDone: "Order Status",
      columns_accepted: "Units Accepted",
      columns_acceptDone: "Accept Status",
      columns_dispatched: "Units Dispatched",
      columns_dispatchDone: "Dispatched Status",
      columns_inTransit: "Units In Transit",
      columns_inTransitDone: "In Transit Status",
      columns_delivered: "Units Delivered",
      columns_deliveryDone: "Delivered Status",
      columns_reception: "Units Reception",
      columns_receptionDone: "Reception Status",
    },
    MissingShipmentsList: {
      title: "Missing Shipments",
      columns_sellerName: "Supplier",
      columns_operatorName: "Operator",
      columns_date: "Date",
      columns_orderSellerId: "Order Seller Id",
      columns_dispatchId: "Dispatch Id",
      columns_receptionDone: "Reception Status",
      columns_dispatchDone: "Dispatched Status",
      columns_deliveryDone: "Delivered Status",
    },
    ProductPricesList: {
      title: "Product Prices",
      columns_entityName: "Entity Name",
      columns_gtin: "GTIN",
      columns_gln: "GLN",
      columns_productName: "Product Name",
      columns_priceType: "Price Type",
      columns_groupName: "Group Name",
      columns_retailPrice: "Retail Price",
      columns_salePrice: "Sale Price",
      columns_active: "Active",
      columns_createdBy: "Created By",
      columns_createdDate: "Created Date",
      columns_updatedBy: "Updated By",
      columns_updatedDate: "Updated Date",
      status_true: "Active",
      status_false: "Inactive",
      status_NONE: " ",
    },
    ProductList: {
      title: "Product",
      columns_productName: "Product Name",
      columns_gtin: "GTIN",
      columns_gln: "GLN",
      columns_productInternalCode: "Product Internal Code",
      columns_ownerTaxNumber: "Owner",
      columns_ownerName: "Owner Name",
      columns_ownerInternalCode: "Owner Internal Code",
      columns_createdByEntityTaxNumber: "Tax Number",
      columns_createdByEntityName: "Created By Entity",
      columns_createdBy: "Created By",
      columns_createdTime: "Created Date",
      columns_status: "Status",
      columns_available: "Available",
    },
  },
  history: {
    header: {
      title: "History - Report orders movements",
      filterBtn: "Filter",
    },
    filter: {
      report: "Query",
      week: "Week",
      month: "Month",
      year: "Year",
      searchField: "Search Field",
      search: "Search",
      searchField_NONE: "",
      searchField_SELLERNAME: "SELLERNAME",
      searchField_SELLERTAX: "SELLERTAX",
      searchField_BUYERNAME: "BUYERNAME",
      searchField_BUYERTAX: "BUYERTAX",
      searchField_ADDRESS: "ADDRESS",
      searchField_ORDERSELLERID: "ORDERSELLERID",
    },
    OrdersMovementList: {
      title: "Orders Movement",
      columns_orderSellerId: "Order Seller Id",
      columns_sellerName: "Seller",
      columns_sellerTax: "Seller Tax",
      columns_buyerName: "Buyer",
      columns_buyerTax: "Buyer Tax",
      columns_description: "Description",
      columns_address: "Address",
      columns_updatedBy: "Updated",
    },
    OrdersCDList: {
      columns_volume: "Volume",
      columns_status: "Status",
      columns_updatedBy: "Updated By",
      columns_updatedTime: "Updated Time",
    },
    OrdersTFList: {
      columns_description: "Description",
      columns_quantity: "Quantity",
      columns_totalTransportUnits: "Total Transport Units",
      columns_status: "Status",
      columns_updatedBy: "Updated By",
      columns_updatedTime: "Updated Time",
    },
  },
  reports: {
    header: {
      title: "Reports",
      filterBtn: "Filter",
    },
    options: {
      empty: "Choose an option",
      report_suppliers: "Report suppliers",
      report_weekly_units: "Report weekly units",
      report_weekly_units_accepted: "Report weekly units accepted",
      report_weekly_units_delivered: "Report weekly units delivered",
    },
    filter: {
      report: "Report",
      week: "Week",
      month: "Month",
      year: "Year",
      entity: "Entity",
      cutoff: "CutOff",
    },
    WeeklyUnits: {
      title: "Report Weekly Units",
      columns_sellerName: "Supplier",
      columns_buyerName: "Buyer",
      columns_deliveryPlace: "Delivery Place",
      columns_totalTransportUnits: "Total Transport Units",
    },
    WeeklyUnitsAccepted: {
      title: "Report Weekly Units Accepted",
      columns_sellerName: "Supplier",
      columns_buyerName: "Buyer",
      columns_deliveryPlace: "Delivery Place",
      columns_totalTransportUnits: "Total Transport Units",
    },
    WeeklyUnitsDelivered: {
      title: "Report Weekly Units Delivered",
      columns_sellerName: "Supplier",
      columns_buyerName: "Buyer",
      columns_deliveryPlace: "Delivery Place",
      columns_totalTransportUnits: "Total Transport Units",
      columns_price: "Price",
    },
    SuppliersList: {
      title: "Report Cost by Supplier",
      columns_sellerName: "Supplier",
      columns_fee: "Fee",
      columns_feeType: "Fee Type",
      columns_cost: "Cost",
      columns_purchasesVolume: "Purchases Volume",
      columns_balance: "Balance",
      columns_delta: "Delta",
    },
    CostDeliveryList: {
      title: "Cost By Delivery Point",
      columns_sellerName: "Supplier",
      columns_deliveryPlace: "Delivery Place",
      columns_platformCost: "Platform Cost",
      columns_transportCost: "Transport Cost",
      columns_totalCost: "Total Cost",
    },
    TransportCostItemsList: {
      title: "Transport Cost",
      columns_buyerName: "Supplier",
      columns_deliveryPlace: "Delivery Place",
      columns_transportCost: "Transport Cost",
      columns_totalTransportUnits: "Total Transport Units",
      columns_averageCost: "Average Cost",
    },
    PlatformCostItemsList: {
      title: "Plataform Cost",
      columns_sellerName: "Supplier",
      columns_deliveryPlace: "Delivery Place",
      columns_platformCost: "Platform Cost",
      columns_administrativeCost: "Administrative Cost",
      columns_totalTransportUnits: "Total Transport Units",
      columns_averageCost: "Average Cost",
    },
  },
  audit: {
    header: {
      title: "Audit",
      filterBtn: "Filter",
    },
    options: {
      empty: "Choose an option",
      audit_ws: "Web Services",
      audit_data: "Database",
      audit_import: "Import File",
      audit_login: "Access Logins",
      audit_external: "External Access",
      audit_job: "Jobs",
      audit_i18n: "I18n"
    },
    filter: {
      report: "Audit",
      requestDate: "Date",
      ipAddress: "IP Address",
      operation: "Operation",
      error: "Error",
      error_NONE: " ",
      error_true: "Yes",
      error_false: "No",
      ws_NONE: " ",
      ws_ListNewEntities: "List New Entities",
      ws_ListNewOrderCreation: "List New Order Creation",
      ws_ListNewOrderCreation2: "List New Order Creation 2",
      ws_ListNewOrderMovements: "List New Order Movements",
      ws_OrderAccept: "Order Accept",
      ws_OrderDelivery: "Order Delivery",
      ws_OrderReceivement: "Order Receivement",
      ws_OrderShipment: "Order Shipment",
      ws_OrdersShipment: "Orders Shipment",
      ws_ListNewItems: "List New Items",
      schema: "Schema",
      table: "Table",
      username: "Username",
      type: "Type",
      subtype: "Import Type",
      schema_NONE: " ",
      schema_batch: "Batch",
      schema_document: "Document",
      schema_entity: "Entity",
      schema_notification: "Notification",
      schema_order: "Order",
      schema_product: "Product",
      schema_planning: "Planning",
      schema_promoter: "Promoter",
      schema_ref_data: "Ref data",
      schema_task: "Task",
      schema_user: "User",
      schema_workflow: "Workflow",
      method_CATALOG_PRODUCT: "CATALOG_PRODUCT",
      method_CATALOG_PRODUCT_CATEGORIES: "CATALOG_PRODUCT_CATEGORIES",
      method_CATALOG_PRODUCT_CATEGORY: "CATALOG_PRODUCT_CATEGORY",
      method_CATALOG_PRODUCT_CONNECTORS: "CATALOG_PRODUCT_CONNECTORS",
      method_CATALOG_NOTIFY_NEW_GLN: "CATALOG_NOTIFY_NEW_GLN",
      method_CATALOG_GET_ENTITY: "CATALOG_GET_ENTITY",
      method_CATALOG_CREATE_ENTITY: "CATALOG_CREATE_ENTITY",
      method_CATALOG_CONFIRM_ENTITY: "CATALOG_CONFIRM_ENTITY",
      method_CATALOG_EDIT_ENTITY: "CATALOG_EDIT_ENTITY",
      method_CATALOG_EDIT_ENTITY_CONTACTS: "CATALOG_EDIT_ENTITY_CONTACTS",
      method_CATALOG_EDIT_ENTITY_ADDRESS: "CATALOG_EDIT_ENTITY_ADDRESS",
      method_CATALOG_UPDATE_ENTITY_CHANGE: "CATALOG_UPDATE_ENTITY_CHANGE",
      method_CATALOG_LIST_ENTITY_CHANGES: "CATALOG_LIST_ENTITY_CHANGES",
      method_SGN_GET_ENTITIES: "SGN_GET_ENTITIES",
      method_SGN_GET_MATERIAL: "SGN_GET_MATERIAL",
      method_SGN_GET_PRODUCT_STATUS: "SGN_GET_PRODUCT_STATUS",
      method_SGN_CHANGE_PRODUCT: "SGN_CHANGE_PRODUCT",
      method_SGN_SEND_PRODUCT_TECHNICAL_SHEETS: "SGN_SEND_PRODUCT_TECHNICAL_SHEETS",
      method_SGN_CHANGE_PRICE: "SGN_CHANGE_PRICE",
      method_SGN_CHANGE_PRICING: "SGN_CHANGE_PRICING",
      method_FTP_UPLOAD_DATA_FROM_S3: "FTP_UPLOAD_DATA_FROM_S3",
      type_SOAP: "SOAP",
      type_REST: "REST",
      subtype_NONE: " ",
      subtype_IMPORT_CREATE: "ORDER - IMPORT_CREATE",
      subtype_IMPORT_PRODUCT: "PRODUCT - IMPORT_PRODUCT",
      subtype_IMPORT_PRICE: "PRODUCT - IMPORT_PRICE",
      websocket_C: "CONNECT",
      websocket_D: "DISCONNECT",
      websocket_U: "UNAUTHORIZED",
      requestStartDate: "Start Date",
      requestEndDate: "End Date",
      externalMethod: "Method",
      externalMethodClear: "Clear",
      externalError: "Error",
      externalEntity: "Entity",
      externalType: "Type",
      externalSubType: "SubType",
      jobType: "Type",
      jobSubType: "SubType",
      jobId: "Job Id",
      jobStatus: "Status",
      action: "Action",
      entity: "Entity",
      dateIni: "Start Date",
      dateEnd: "End Date",
      language: "Language",
    },
    ws: {
      title: "Web Services",
      columns_type: "Type",
      columns_operation: "Operation",
      columns_userRequest: "User",
      columns_requestTime: "Request Time",
      columns_request: "Request",
      columns_responseTime: "Response Time",
      columns_response: "Response",
      columns_error: "Status",
      columns_error_true: "Error",
      columns_error_false: "Success",
      columns_ipAddress: "IP Address",
      columns_promoterCode: "Promoter",
      columns_entity: "Entity",
      details_title: "Detail",
      details_operation: "Operation",
      details_status: "Status",
      details_entity: "Entity",
      details_user: "User",
      details_ipAddress: "IP Address",
      details_type: "Type",
      details_requestTime: "Request Time",
      details_request: "Request",
      details_responseTime: "Response Time",
      details_response: "Response",
    },
    data: {
      title: "Database",
      columns_action: "Action",
      columns_changeDate: "Change Date",
      columns_schema: "Schema",
      columns_table: "Table",
      columns_user: "User",
      columns_rawData: "Raw Data",
      columns_changedFields: "Changed Fields",
      columns_action_insert: "Insert",
      columns_action_update: "Update",
      columns_action_delete: "Delete",
      columns_action_truncate: "Truncate",
      details_title: "Detail",
      details_action: "Action",
      details_schema: "Schema",
      details_table: "Table",
      details_user: "User",
      details_changedate: "Change Date",
      details_rawData: "Raw Data",
      details_changedFields: "Changed Fields",
    },
    import: {
      title: "Import File",
      columns_type: "Type",
      columns_subtype: "SubType",
      columns_user: "User",
      columns_time: "Time",
      columns_resultTime: "Result Time",
      columns_status: "Status",
      columns_error_true: "Error",
      columns_error_false: "Success",
      columns_totalLines: "Total Lines",
      columns_linesWithError: "Number of Lines With Error",
      details_title: "Detail",
      details_type: "Type",
      details_subtype: "SubType",
      details_user: "User",
      details_owner: "Product Owner",
      details_gln: "GLN",
      details_address: "Address",
      details_cutOff: "Cut Off",
      details_orderBy: "Order By",
      details_orderByAddress: "Order By Address",
      details_time: "Time",
      details_resultTime: "Result Time",
      details_lines: "Lines",
      details_resultLines: "Result",
      details_status: "Status",
      details_totalLines: "Total Lines",
      details_linesWithError: "Number of Lines With Error",
    },
    login: {
      title: "Access Logins",
      columns_action: "Action",
      columns_entity: "Entity",
      columns_username: "User",
      columns_datetime: "Time",
      columns_ipAddress: "IP Address",
      columns_sessionId: "Session ID",
      details_title: "Detail",
      details_action: "Action",
      details_entity: "Entity",
      details_username: "User",
      details_datetime: "Time",
      details_ipAddress: "IP Address",
      details_sessionId: "Session ID",
      details_userDetails: "User Details",
      details_sessionAttr: "Session Attributes",
    },
  },
  accounting: {
    header: {
      title: "Accounting",
      filterBtn: "Filter",
    },
    options: {
      doc_type_0: "Order",
      doc_type_1: "Delivery",
      doc_type_2: "Receivement",
      doc_type_3: "Invoice",
      doc_type_4: "Credit Note",
      attachment_type_0: "Document",
      attachment_type_1: "Signature",
      attachment_type_2: "Photo",
      attachment_type_99: "Other",
    },
    Accounting: {
      columns_createdDate: "Created Date",
      columns_createdBy: "Created By",
      columns_customerDocNumber: "Document Number",
      columns_documentDate: "Document Date",
      columns_documentId: "Document Id",
      columns_documentType: "Document Type",
      columns_ownerName: "Owner Name",
      columns_refDocumentId: "Ref Document Id",
      columns_refDocumentType: "Ref Document Type",
      columns_supplierDocNumber: "Supplier Document Number",
      columns_total: "Total",

      details_title: "Detail",
      details_createdDate: "Created Date",
      details_channel: "Channel",
      details_createdBy: "Created By",
      details_customerDocNumber: "Document Number",
      details_documentDate: "Document Date",
      details_documentId: "Document Id",
      details_documentType: "Document Type",
      details_ownerName: "Owner Name",
      details_refDocumentId: "Ref Document Id",
      details_refDocumentType: "Ref Document Type",
      details_supplierDocNumber: "Supplier Document Number",
      details_title_supplier: "Supplier",
      details_supplier_description: "Supplier",
      details_supplier_taxNumber: "Tax Number",
      details_supplier_name: "Address Name",
      details_supplier_address: "Address",
      details_title_customer: "Customer",
      details_customer_description: "Customer",
      details_customer_taxNumber: "Tax Number",
      details_customer_name: "Address Name",
      details_customer_address: "Address",
      details_title_products: "Products",
      details_products_description: "Description",
      details_products_gtin: "GTIN",
      details_products_quantity: "Quantity",
      details_products_lineID: "Line ID",
      details_products_price: "Price",
      details_products_referenceLineID: "Reference Line Id",
      details_products_referenceID: "Reference Id",
      details_products_referenceType: "Reference Type",
      details_products_tax: "Tax",
      details_products_unitOfMeasureID: "Unit Of Measure",
      details_title_attachments: "Attachments",
      details_attachments_description: "Description",
      details_attachments_type: "Type",
      details_attachments_url: "File",
      details_attachments_download: "Download",
      details_total: "Total",
    },
  },
  AccountingList: {
    header: {
      title: "Accounting",
      rowsInfo: "records",
      btnSave: "Set available"
    }
  },
  AuditsList: {
    columns: {
      type: "Type",
      subType: "Subtype",
      method: "Method",
      entity: "Entity",
      userRequest: "User",
      requestTime: "Request Time",
      responseTime: "Response Time",
      error: "Status",
      errorTrue: "Error",
      errorFalse: "Success",
      jobDescription: "Job Description",
      startTime: "Start Time",
      endTime: "End Time",
      status: "Status",
      datetime: "Date",
      language: "Language",
    }
  },
  AuditExternalDetails: {
    header: {
      retryBtn: "Retry Request"
    },
    details: {
      entity: "Entity",
      userRequest: "User",
      requestTime: "Request Time",
      responseTime: "Response Time",
      method: "Method",
      request: "Request",
      response: "Response",
      error: "Status",
      errorTrue: "Error",
      errorFalse: "Success",
      true: "Yes",
      false: "No",
      url: "Url",
      httpCode: "Http Code",
      hadError: "Had Error",
      retried: "Retried",
    }
  },
  AuditJobDetails: {
    header: {
      retryJobBtn: "Retry Job"
    },
    details: {
      jobDescription: "Job description",
      status: "Status",
      true: "Success",
      false: "Error",
      startTime: "Start Time",
      endTime: "End Time",
      request: "Request",
      response: "Response",
    }
  },
  AuditI18nDetails: {
    header: {
      title: "I18n Change Detail",
    },
    details: {
      promoter: "Promoter",
      entity: "Entity",
      username: "Username",
      dateTime: "Change Time",
      i18n: "New I18n"
    }
  },
  process: {
    header: {
      title: "Processes List",
    },
    filter: {
      dateIni: "Start Date",
      dateEnd: "End Date",
      processTypes: "Process Types",
      status: "Status",
      activeLabel: "Active",
      optionAll: "All",
      optionActive: "Active",
      optionInactive: "Inactive",
    },
    processList:{
      columns_type: "Type",
      columns_processType: "Process Type",
      columns_processId: "Process Id",
      columns_state: "State",
      columns_status: "Status",
      columns_active: "Active",
      columns_createdTime: "Created Time",
      columns_updateTime: "Update Time",
      columns_createdBy: "Created By",
      columns_updatedBy: "Updated By",
      status_true: "Active",
      status_false: "Inactive",
    },
    processBusinessHistoryList:{
      columns_status: "Status",
      columns_dateIn: "Date In",
      columns_dateOut: "Date Out",
      columns_request: "Request",
      columns_user: "User",
    },
    processHistoryList:{
      columns_actionDate: "Date",
      columns_actionUser: "Action User",
      columns_assignedUsers: "Assigned Users",
      columns_businessId: "Business Id",
      columns_comment: "Comment",
      columns_state: "State",
      columns_status: "Status",
    },
    details: {
      btnAddUser: "Add User to Task",
      detail: "Detail",
      processHistory: "Process History",
      businessHistory: "Business History",
      processData: "Process Data",
      businessData: "Business Data",
      processData_type: "Type",
      processData_processType: "Process Type",
      processData_processId: "Process Id",
      processData_taskId: "Task Id",
      processData_state: "State",
      processData_status: "Status",
      processData_date: "Date",
      processData_active: "Active",
      processData_createdBy: "Created By",
      processData_userAssignees: "User Assignees",
      businessData_dueDate: "Due Date",
      businessData_entityId: "Entity Id",
      businessData_taskActionDate: "Task Action Date",
      businessData_assignedUsers: "Assigned Users",
    }, TaskAddUserModal: {
      title: "Add User to task",
      btnCancel: "Cancel",
      btnSubmit: "Submit",
      userRequired: "Email is required",
      invalidEmail: "Email is invalid",
      user: "Email",
    }
  }
};

export default business;

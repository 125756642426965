import React from "react";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useNavigate, useUser } from "../../../../common/hooks";
import Permission from "../../../../app/components/Permission";

export default function ProductValidationDataGridActions({ row }) {
  const { t } = useTranslation();
  // add permissions
  const navigate = useNavigate();
  const user = useUser();

  return (
    <Grid container spacing={1} justifyContent="flex-end">
      {row?.status === "PENDING" && row?.waitingAction && (
        <Permission code="products.actions.pendingProduct.action">
          <Grid item>
            <Button
              onClick={() => navigate(`/products/confirmation/${row?.productId}/${row?.taskId}`)}
              color="primary"
              variant="outlined"
            >
              <Grid container spacing={1} alignItems="center" wrap="nowrap">
                <Grid item style={{ display: "flex" }}>
                  <CheckIcon />
                </Grid>
                <Grid item>{t("products.ProductValidationList.actions.confirm")}</Grid>
              </Grid>
            </Button>
          </Grid>
        </Permission>
      )}
      {row?.status === "PENDING" && !row?.waitingAction && row?.taskId && (
        <Grid item>
          <Button
            onClick={
              () => navigate(`/products/confirmation-view/${row?.productId}/${row?.taskId}`)
            }
            color="primary"
            variant="outlined"
          >
            <Grid container spacing={1} alignItems="center" wrap="nowrap">
              <Grid item style={{ display: "flex" }}>
                <VisibilityIcon />
              </Grid>
              <Grid item>{t("products.ProductValidationList.actions.view")}</Grid>
            </Grid>
          </Button>
        </Grid>
      )}
      {row?.waitingAction && row?.status === "VALIDATING" && row?.taskId && (
        <Permission code="products.actions.pendingProduct.action">
          <Grid item>
            <Button
              onClick={() =>
                row?.processType === "FlowUpdateProduct"
                  ? navigate(`/products/validation/${row?.productId}/${row?.taskId}`)
                  : navigate(`/products/confirmation-validation/${row?.productId}/${row?.taskId}`)
              }
              color="primary"
              variant="outlined"
            >
              <Grid container spacing={1} alignItems="center" wrap="nowrap">
                <Grid item style={{ display: "flex" }}>
                  <CheckIcon />
                </Grid>
                <Grid item>{t("products.ProductValidationList.actions.validate")}</Grid>
              </Grid>
            </Button>
          </Grid>
        </Permission>
      )}
      {!row?.waitingAction && row?.processType === "FlowUpdateProduct" && row?.processId && (
        <Grid item>
          <Button
            onClick={
              () => row?.taskId ?
                navigate(`/products/validation/${row?.productId}/${row?.processId}/${row?.taskId}/view`) :
                navigate(`/products/validation/${row?.productId}/${row?.processId}/view`)
            }
            color="primary"
            variant="outlined"
          >
            <Grid container spacing={1} alignItems="center" wrap="nowrap">
              <Grid item style={{ display: "flex" }}>
                <VisibilityIcon />
              </Grid>
              <Grid item>{t("products.ProductValidationList.actions.view")}</Grid>
            </Grid>
          </Button>
        </Grid>
      )}
      {
        row?.status === "REFUSED" && row?.processType === "FlowCreateProduct" && row?.processId &&
        !row?.active && user?.entity?.type === "P" && (
          <Permission code="products.actions.product.create">
            <Grid item>
              <Button
                onClick={() => navigate(`/products/recover/${row?.productId}/${row?.processId}`)}
                color="primary"
                variant="outlined"
              >
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                  <Grid item style={{ display: "flex" }}>
                    <CheckIcon />
                  </Grid>
                  <Grid item>{t("products.ProductValidationList.actions.recover")}</Grid>
                </Grid>
              </Button>
            </Grid>
          </Permission>
        )
      }
      {user?.entity?.id &&
        (user?.entity?.id === row?.ownerId || user?.username === row?.createdBy) &&
        (row?.status === "NOT_VALIDATED" || row?.status === "NOT_OK") && (
          <Permission code="products.actions.product.update">
            <Grid item>
              <Button onClick={() => navigate(`/products/edit/${row?.productId}`)} color="primary" variant="outlined">
                {t("products.ProductValidationList.actions.update")}
              </Button>
            </Grid>
          </Permission>
        )}
    </Grid>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import documentProcessImageRecognitionCall from "./_sliceCalls/documentProcessImageRecognitionCall";

export const documentSlice = createSlice({
  name: "document",
  initialState: {
    pdfList: null,
    pdfDocument: null,
    imageProcessingResult: null,
  },
  reducers: {
    setPdfList: (state, action) => {
      state.pdfList = action.payload;
    },
    setPdf: (state, action) => {
      state.pdfDocument = action.payload;
    },
    setImageProcessingResult: (state, action) => {
      state.imageProcessingResult = action.payload;
    },
  },
});

export default documentSlice.reducer;

export const selectImageProcessingResult = (state) => state.document.imageProcessingResult;

export const documentProcessImageRecognition = (files) => (dispatch, getState) => {
  documentProcessImageRecognitionCall(documentSlice.actions, dispatch, getState(), files);
};
import React, { useMemo } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Stack, Divider } from "@mui/material";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useTheme } from "@emotion/react";
import moment from "moment";

import { styles } from "./PricePromotions.styles";

import CustomTooltip from "../../../../../common/displays/CustomTooltip/CustomTooltip";
import { CampaignTypeIcon } from "../../../../../common/icons";
import { classJoin, currencyFormat } from "../../../../../common/utils";
import { usePromoter } from "../../../../../common/hooks";
import { dateFormat } from "../../../../../common/utils";
import { calculatePriceWithDproms, calculatePriceWithPromotions, getDpromDiscount } from "../../../_common/auxiliars";

function PricePromotions({ classes, price, onEdit, pending }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const promoter = usePromoter();
  const { promotions, newSalePrice, salePrice, dproms, priceId } = price;
  // const dproms = useSelector(selectDproms);
  const filteredPromotions = (promotions || []).filter(item => !item.toDelete);
  const deletedPromotions = (promotions || []).filter(item => item.toDelete);
  const currentPrice = newSalePrice || salePrice || 0;

  const priceWithPromotions = calculatePriceWithPromotions(currentPrice, filteredPromotions, promoter);
  const priceWithDiscounts = calculatePriceWithDproms(priceWithPromotions, dproms);

  const processedDproms = useMemo(() => {
    if (dproms && dproms.length > 0) {
      let processedDproms = [];
      dproms.forEach(dprom => {
        let discount = getDpromDiscount(dprom.value, priceWithPromotions);
        processedDproms.push({ ...dprom, discount })
      })

      return processedDproms;
    }
  }, [dproms, priceWithPromotions]);

  const pricePromotionClickHandler = (promotion) => {
    console.log('promotion: %o', promotion);
    !pending && onEdit && onEdit(promotion)
  }


  return (
    <CustomTooltip title={
      <Stack spacing={2} className={classes.root}>
        <Stack spacing={1}>
          <Typography className={classes.nomargin} variant="caption">{t("productsPrices.PricesList.promotionsInfo.currentPrice")}</Typography>
          <Typography className={classes.nomargin}>{currencyFormat(currentPrice)}</Typography>
        </Stack>
        {filteredPromotions?.length > 0 && <Divider />}
        {filteredPromotions.map((promotion, index) => {
          const { type, name, percentage, buyQuantity, offerQuantity, startDate, endDate } = promotion;
          const promotionClasses = [classes.promotion];
          if (!moment().isBetween(moment(startDate), moment(endDate), "day", "[]")) {
            promotionClasses.push(classes.inactive);
          }
          return (
            <Stack spacing={1} key={"promotion_label_" + index} className={classJoin(promotionClasses)} onClick={() => pricePromotionClickHandler(promotion)} >
              <Typography className={classes.nomargin} variant="caption">{t("productsPrices.PromotionForm.promotionType." + type) + (endDate ? " (" + dateFormat(endDate) + ")" : "")}</Typography>
              {type === "PD" && <Typography className={classes.nomargin}>{`${name}: ${percentage}%`}</Typography>}
              {type === "BU" && <Typography className={classes.nomargin}>{`${name}: ${t("productsPrices.PricesList.promotionsInfo.bonus", { buyQuantity, offerQuantity })}`}</Typography>}
            </Stack>
          )
        })}
        {deletedPromotions?.length > 0 && <Divider />}
        {deletedPromotions.map((promotion, index) => {
          const { type, name, percentage, buyQuantity, offerQuantity } = promotion;
          return (
            <Stack spacing={1} key={"promotion_delete_label_" + index} className={classes.promotionToDelete} >
              <Typography className={classes.nomargin} variant="caption">{t("productsPrices.PromotionForm.promotionType." + type)}</Typography>
              {type === "PD" && <Typography className={classes.nomargin}>{`${name}: ${percentage}%`}</Typography>}
              {type === "BU" && <Typography className={classes.nomargin}>{`${name}: ${t("productsPrices.PricesList.promotionsInfo.bonus", { buyQuantity, offerQuantity })}`}</Typography>}
            </Stack>
          )
        })}
        <Divider />
        <Stack spacing={1} className={classes.price}>
          <Typography className={classes.nomargin} variant="caption">{t("productsPrices.PricesList.promotionsInfo.priceWithPromotions")}</Typography>
          <Typography className={classes.nomargin}>{currencyFormat(priceWithPromotions)}</Typography>
        </Stack>
        {processedDproms?.length > 0 &&
          <Stack spacing={2}>
            <Divider />
            <Stack spacing={1}>
              <Typography className={classes.nomargin} variant="caption">{t("productsPrices.PricesList.promotionsInfo.dproms")}</Typography>
              <Stack spacing={1}>
                {
                  processedDproms.map(({ name: dpromName, value, id, discount }) => (
                    <Typography className={classes.nomargin} key={`dprom_${priceId}_${id}`}>
                      {`${dpromName}: ${value?.type === "P" ? `${value?.amount}% (${currencyFormat(discount)})` : currencyFormat(value?.amount)} `}
                    </Typography>
                  ))
                }
              </Stack>
            </Stack>
            <Divider />
            <Stack spacing={1} className={classes.price}>
              <Typography className={classes.nomargin} variant="caption">{t("productsPrices.PricesList.promotionsInfo.priceWithDiscounts")}</Typography>
              <Typography className={classes.nomargin}>{currencyFormat(priceWithDiscounts)}</Typography>
            </Stack>
          </Stack>
        }
      </Stack>
    }>
      <Stack direction="row" spacing={1}>
        {processedDproms?.length > 0 && <AttachMoneyIcon color="primary" />}
        {filteredPromotions.map((item, index) => (
          <div key={"promotion_" + index} className={classJoin([classes.icon, item?.hasChanges || item?.isNew ? classes.hasChanges : undefined])}>
            <CampaignTypeIcon type={item.type} />
          </div>
        ))}
        {deletedPromotions.map((item, index) => (
          <div key={"promotion_" + index} className={classJoin([classes.icon, classes.iconDelete, item?.hasChanges || item?.isNew ? classes.hasChanges : undefined])}>
            <CampaignTypeIcon type={item.type} fill={theme.palette.error.main} />
          </div>
        ))}


      </Stack>
    </CustomTooltip>
  );
}

export default withStyles(PricePromotions, styles);

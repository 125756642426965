import * as Yup from "yup";
import { DatePickerField, SelectField } from "../../../../common/forms";

export function formSchema({ t }) {
  return Yup.object().shape({
    vat: Yup.string().required(t("products.ProductVatFormModal.form.vatRequired")),
    startDate: Yup.string().required(t("products.ProductVatFormModal.form.startDateRequired")),
  });
}

export function formFields({ t, values, ogProduct, promoter }) {
  const { tax } = ogProduct;
  return [
    {
      size: 12,
      field: <SelectField
        id="vat"
        options={getVatOptions(t, promoter)}
        label={t("products.ProductVatFormModal.form.vat")}
      />,
    },
    {
      size: 12,
      field: <DatePickerField
        id="startDate"
        disabled={tax?.vat === values.vat}
        label={t("products.ProductVatFormModal.form.startDate")}
      />,
    },
  ];
}

const getVatOptions = (t, promoter) => {
  return (promoter?.refData?.vat || []).map((vatCode) => ({
    text: t('common.Enums.vat.' + vatCode),
    value: vatCode
  }))
};
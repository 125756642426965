import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFirstLoad, useNavigate, usePromoter, useUser } from "../../../common/hooks";
import { Button, Drawer, Grid, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";

import { styles } from "./ProductList.styles";
import ProductDataGrid from "./ProductsDataGrid/ProductsDataGrid";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import SearchBox from "../../../common/components/inputs/SearchBox";

import ProductDrawerDetails from "./ProductDrawerDetails/ProductDrawerDetails";
import Permission from "../../../app/components/Permission";
import {
  formProductListFamily,
  productGet,
  productGroupContacts,
  productList,
  selectFormFamilyList,
  selectLoader,
  selectProductDetails,
  selectProductList,
  selectProductListFilter,
  selectProductListTotalRows,
} from "../productsSlice";
import Page from "../../../common/displays/Page/Page";

function ProductList({ classes }) {
  const { t } = useTranslation();
  const loading = useSelector(selectLoader);
  const data = useSelector(selectProductList);
  const filter = useSelector(selectProductListFilter);
  const totalRows = useSelector(selectProductListTotalRows);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useUser();
  const productDetails = useSelector(selectProductDetails);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const families = useSelector(selectFormFamilyList);
  const promoter = usePromoter();

  useFirstLoad(() => {
    dispatch(formProductListFamily());
    dispatch(productGroupContacts());
  });

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search,
      offset: 0,
    };

    dispatch(productList(nFilter, true));
  };

  const onFilterChangeHandler = (v) => {
    dispatch(productList(v.filter, v.reset));
  };

  const onProductSelectedHandler = (product) => {
    /* Permission */
    if (
      user.hasPermission("products.actions.product.get")
    ) {
      if (product.status === "DFT") {
        navigate("/products/edit/" + product.productId);
      } else {
        dispatch(productGet(product.productId));
        setDrawerOpen(true);
      }
    }
  };

  return (
    <Page
      permission="products.actions.product.list"
      header={<PageTitle
        title={t("products.ProductList.header.title")}
        info={`${totalRows} ${t("products.ProductList.header.rowsInfo")}`}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <SearchBox onEnter={(v) => onSearchHandler(v)} value={filter?.search} />
            </Grid>
            <Grid item>
              <Permission code="products.actions.product.import">
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => navigate("/products/import-images")}
                >
                  {t("products.ProductList.header.importImagesBtn")}
                </Button>
              </Permission>
            </Grid>
            <Grid item>
              <Permission code="products.actions.product.import">
                <Button variant="outlined" color="primary" size="large" onClick={() => navigate("/products/import")}>
                  {t("products.ProductList.header.importBtn")}
                </Button>
              </Permission>
            </Grid>
            <Grid item>
              <Permission code="products.actions.product.create">
                <Button variant="outlined" color="primary" size="large" onClick={() => navigate("/products/add")}>
                  {t("products.ProductList.header.addBtn")}
                </Button>
              </Permission>
            </Grid>
          </Grid>
        }
      />}>
      <Stack spacing={1}>
        {user?.rolePermission?.products?.actions?.pendingProduct?.list && (!promoter?.getConfig("product", "autoAcceptCreate") || !promoter?.getConfig("product", "autoAcceptUpdate") || !promoter?.getConfig("product", "autoAcceptUpdateExternal")) &&
          <div className={classes.links}>
            <ToggleButtonGroup color="primary" size="small" value="products" >
              <ToggleButton color="primary" value="products">
                {t("products.ProductList.header.productsLink")}
              </ToggleButton>
              <ToggleButton color="primary" value="validation" onClick={() => navigate('/products/validation')} >
                {t("products.ProductList.header.validationLink")}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        }
        <ProductDataGrid
          data={data}
          filter={filter}
          totalRows={totalRows}
          onFilterChange={onFilterChangeHandler}
          onProductSelected={onProductSelectedHandler}
          loading={loading}
          families={families}
        />
      </Stack>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div className={classes.drawer}>
          {productDetails && <ProductDrawerDetails product={productDetails} loading={loading} />}
        </div>
      </Drawer>
    </Page>
  );
}

export default withStyles(ProductList, styles);

import { Typography } from "@mui/material";

export function listColumns(t, classes, promoter, user) {
  const columnsToShow = promoter?.getConfig("entity", "listEntityColumns") || [];
  let columns = [{
    field: "name",
    headerName: t("entities.Entities.columns.name"),
    align: "left",
    headerAlign: "left",
  }];

  if (columnsToShow.includes("type")) {
    columns.push( {
      field: "type",
      headerName: t("entities.Entities.columns.type"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return t("common.Enums.entityType." + v.type);
      },
    });
  }
  if (columnsToShow.includes("taxNumber")) {
    columns.push( {
      field: "taxNumber",
      headerName: t("entities.Entities.columns.taxNumber"),
      align: "left",
      headerAlign: "left",
    });
  }
  if (columnsToShow.includes("gln")) {
    columns.push( {
      field: "gln",
      headerName: t("entities.Entities.columns.gln"),
      align: "left",
      headerAlign: "left",
    });
  }
 if (user?.isPromoter && promoter?.getConfig("entity", "requiresInternalCode") && columnsToShow.includes("promoterInternalCode")) {
    columns.push( {
      field: "promoterInternalCode",
      headerName: t("entities.Entities.columns.promoterInternalCode"),
      align: "left",
      headerAlign: "left",
    });
  }

  if (columnsToShow.includes("status")) {
    columns.push(  {
      field: "status",
      headerName: t("entities.Entities.columns.status"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        if (v.status === "ACT") {
          return <Typography className={classes.statusActive}>{t("entities.Entities.status.active")}</Typography>
        }
        if (v.status === "PND") {
          return <Typography className={classes.statusPending}>{t("entities.Entities.status.pending")}</Typography>
        }
        return <Typography className={classes.statusDisabled}>{t("entities.Entities.status.disabled")}</Typography>
      },
    });
  }

  return columns;

}

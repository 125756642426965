import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../../common/displays/CustomDataGrid";

import { styles } from "./OperatorOrderVolumesDataGrid.styles";
import { listColumns } from "./OperatorOrderVolumesDataGrid.columns";
import OrderProductsDataGrid from "../../../_common/OrderProductsDataGrid/OrderProductsDataGrid";
// import { Typography } from "@mui/material";

function OperatorOrderVolumesDataGrid({
  classes,
  data,
  totalRows,
  onRowSelected,
  footer,
  onVolumeCheckChange,
  hideCheckbox,
}) {
  const { t } = useTranslation();
  const columns = listColumns(t, classes, onVolumeCheckChange, hideCheckbox);

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          localFilters
          columns={columns}
          rows={data}
          onRowSelected={onRowSelected}
          total={totalRows}
          footer={footer}
          rowKeyField="id"
          innerRow={(row) => <OrderProductsDataGrid products={(row?.products || []).filter((p) => p.quantity > 0)} />}
        />
      )}
    </div>
  );
}

export default withStyles(OperatorOrderVolumesDataGrid, styles)

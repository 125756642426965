import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";
import { useParams } from "react-router-dom";
import { useFirstLoad, useNavigate, usePromoter, useUser } from "../../../common/hooks";
import {Button, Grid, Paper, Typography} from "@mui/material";

import { styles } from "./OrderDetails.styles";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import SearchBox from "../../../common/components/inputs/SearchBox";
import OrderProductsDataGrid from "../_common/OrderProductsDataGrid/OrderProductsDataGrid";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

import { detailProductsToCurrentOrderProducts } from "../ordersMapper";
import { dateTimeFormat } from "../../../common/utils";
import DeleteButton from "../../../common/components/dialogs/DeleteButton";
import ConfirmDelivery from "./ConfirmDelivery/ConfirmDelivery";
import moment from "moment";
import { Form, FormFields } from "../../../common/forms";
import { formFields, formSchema } from "./OrderDetails.schema";
import { orderCancel, orderConfirmation, orderDeliveryWithoutOperator, orderDetails, selectOrderDetails, setCurrentOrder, setCurrentOrderProducts } from "../ordersSlice";
import Page from "../../../common/displays/Page/Page";
import OrderAttachments from "../_common/OrderAttachments/OrderAttachments";
import OrderStatusDetails from "../_common/OrderStatus/OrderStatusDetails/OrderStatusDetails";
import Permission from "../../../app/components/Permission";
import LabelValue from "../../../common/displays/LabelValue/LabelValue";

function areAllProductsDispatched(products) {
  return products.every(product => {
    return product.quantity === (product.quantityHist.dispatched || 0);
  });
}

function OrderDetails({ classes }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const details = useSelector(selectOrderDetails);
  const [searchQuery, setSearchQuery] = useState();
  const user = useUser();
  const promoter = usePromoter();

  useFirstLoad(() => {
    dispatch(orderDetails(id));
  });

  const onSearchHandler = (v) => {
    setSearchQuery(v);
  };

  const editHandler = () => {
    const { deliverToId, orderBy, deliverToAddress, location, cutOffType } = details;
    dispatch(setCurrentOrder({
      deliverToId,
      orderBy,
      deliverToAddress,
      cutOff: cutOffType,
      locationId: location,
      products: [],
    }));
    dispatch(setCurrentOrderProducts(detailProductsToCurrentOrderProducts(details.products)));
    navigate("/orders/edit/" + details.id);
  };

  const copyHandler = () => {
    const { deliverToId, orderBy, deliverToAddress, location, cutOffType } = details;
    dispatch(setCurrentOrder({
      deliverToId,
      orderBy,
      deliverToAddress,
      cutOff: cutOffType,
      locationId: location,
      products: [],
    }));
    dispatch(setCurrentOrderProducts(detailProductsToCurrentOrderProducts(details.products)));
    navigate("/orders/add");
  };

  const onSubmitHandler = (values) => {
    const { id, products, statusHistory } = details;

    dispatch(
      orderDeliveryWithoutOperator({
        orderId: id,
        orderSellerId: products?.[0]?.orderSellerId,
        dispatchId: statusHistory?.find((item) => item.status === "DI")?.dispatchId,
        receivedBy: user?.username,
        document: values?.document,
        documentDate: values?.documentDate,
        finished: areAllProductsDispatched(products)
      })
    );
  };

  const reportIncidentHandler = () => {
    navigate("/orders-incidents/add/" + details.id);
  };

  if (details) {
    let subtitle = `${t("orders.OrderDetails.header.deliverTo")}: ${details.deliverToAddressDescription}, `;
    if (promoter?.getConfig("cutOff && !promoter", "configs.functional.cutOff.automatic")) {
      subtitle += `${t("orders.OrderDetails.header.cutoffType")}: ${t(
        "entities.CutOffs.dateType." + details.cutOffType
      )}, `;
    }
    subtitle += `${t("orders.OrderDetails.header.createTime")}: ${dateTimeFormat(details.createdTime)}`;
    return (
      <div>
        <Form
          schema={formSchema(t)}
          onSubmit={onSubmitHandler}
          initialValues={{
            documentDate: moment(),
          }}
          render={({ values }) => {
            return (
              <Page
                permission="orders.actions.placed.get"
                header={<PageTitle
                  title={t("orders.OrderDetails.header.title") + " " + details.id}
                  onBack={() => navigate("/orders/placed")}
                  subtitle={subtitle}
                  actions={
                    <Grid container spacing={2}>
                      <Grid item>
                        <SearchBox onEnter={(v) => onSearchHandler(v)} fast />
                      </Grid>
                      {details.lastOrderStatus === "CR" &&
                        <Permission code="orders.actions.placed.update">
                          <Grid item>
                            <Button variant="contained" color="primary" size="large" onClick={() => editHandler()}>
                              {t("orders.OrderDetails.header.editBtn")}
                            </Button>
                          </Grid>
                        </Permission>
                      }


                      {details.lastOrderStatus === "CD" && details.statusCompleted &&
                        <Permission code="orders.actions.incident.create">
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="large"
                              onClick={() => reportIncidentHandler()}
                            >
                              <Grid container spacing={2} alignItems="center">
                                <Grid item style={{ display: "flex" }}>
                                  <ReportGmailerrorredIcon />
                                </Grid>
                                <Grid item>{t("orders.OrdersList.actions.reportIncident")}</Grid>
                              </Grid>
                            </Button>
                          </Grid>
                        </Permission>
                      }
                      <Permission code="orders.actions.placed.create">
                        <Grid item>
                          <Button variant="contained" color="primary" size="large" onClick={() => copyHandler()}>
                            {t("orders.OrderDetails.header.copyBtn")}
                          </Button>
                        </Grid>
                      </Permission>
                      {details.lastOrderStatus === "CR" && (
                        <Permission code="orders.actions.placed.cancel">
                          <Grid item>
                            <DeleteButton
                              size="large"
                              labels={{
                                button: t("orders.OrderDetails.confirmCancelOrder.button"),
                                title: t("orders.OrderDetails.confirmCancelOrder.title"),
                                description: t("orders.OrderDetails.confirmCancelOrder.description"),
                                ok: t("orders.OrderDetails.confirmCancelOrder.ok"),
                                cancel: t("orders.OrderDetails.confirmCancelOrder.cancel"),
                              }}
                              onConfirm={() => {
                                dispatch(orderCancel(id));
                              }}
                            />
                          </Grid>
                        </Permission>
                      )}
                      {details.lastOrderStatus === "CR" && (
                        <Permission code="orders.actions.placed.confirm">
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              onClick={() => dispatch(orderConfirmation(details.id))}
                            >
                              {t("orders.OrderDetails.header.confirmBtn")}
                            </Button>
                          </Grid>
                        </Permission>
                      )}
                      {details.lastOrderStatus === "DI" && !promoter?.getConfig("order", "hasLogisticOperator") && (
                        <Permission code="orders.actions.placed.delivery">
                          <Grid item>
                            <Button variant="contained" color="primary" size="large" type="submit">
                              {t("orders.OrderDetails.header.deliveryBtn")}
                            </Button>
                          </Grid>
                        </Permission>
                      )}
                      <ConfirmDelivery order={details} />
                    </Grid>
                  }
                />}>
                <Grid container spacing={3}>
                  <Grid item xs={10}>
                    {["DI", "RE", "IT"].indexOf(details?.lastOrderStatus) > -1 && <Paper elevation={1} className={classes.paper}>
                      <Grid container spacing={2}>
                        <Grid item xs={10}>
                          <FormFields fields={formFields(t, details?.lastOrderStatus)} />
                        </Grid>
                      </Grid>
                    </Paper>}
                    {details.id.toString() === id && (
                          <Grid container>
                            <Grid item xs={12}>
                              <LabelValue
                                  label={t("orders.OrderDetails.header.comments")}
                                  value={
                                    <Typography variant="body1" align="left" component="span">
                                      {details?.comments ? details?.comments : "-"}
                                    </Typography>
                                  }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <OrderProductsDataGrid
                                  products={[
                                    ...(details.productsOffers || [])?.map((item) => {
                                      return {
                                        ...item,
                                        isOffer: true,
                                        className: classes.offerItem,
                                      };
                                    }),
                                    ...(details.products || []).map((item) => {
                                      return {
                                        ...item,
                                        className: !item?.active ? classes.outdatedItem : undefined,
                                      };
                                    }),
                                  ]}
                                  searchQuery={searchQuery}
                                  showTotals
                                  showPrices
                                  showCampaigns
                                  allowExport
                                  showDispatchedQuantity={details.lastOrderStatus === "DI" || details.lastOrderStatus === "DE"}
                              />
                          </Grid>
                          </Grid>
                            )}
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <OrderAttachments orderId={details?.id} attachments={details?.documents} />
                      </Grid>
                      <Grid item>
                        <Paper className={classes.paper} elevation={2}>
                          <OrderStatusDetails order={details} />
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Page>
            );
          }}
        />
      </div>
    );
  }

  return null;
}

export default withStyles(OrderDetails, styles);

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

export const promoterListVatCall = async ({ setVatListFilter, setVatList }, dispatch, filter) => {
  let rh = requestHelper(dispatch, "PROMOTER_LIST_VAT");
  dispatch(setVatListFilter(filter));
  try {
    let result = await call({
      type: "PROMOTER",
      subType: "LIST_VAT",
      request: { ...filter, sortField: filter.sortField.toUpperCase() },
    });

    dispatch(setVatList(result.list));
  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default promoterListVatCall;

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const auditListRetentionCall = async (
  { clearAuditRetentionList, setAuditRetentionList, setAuditRetentionListTotalRows },
  dispatch,
  { clear = false }
) => {
  let rh = requestHelper(dispatch, "LIST_RETENTION");
  if (clear) {
    dispatch(clearAuditRetentionList());
  }

  try {
    let list = await call({
      type: "AUDIT",
      subType: "LIST_RETENTION",
      request: {},
    });

    if (list && list.items && list.items.length > 0) {
      dispatch(setAuditRetentionList(list.items));
      dispatch(setAuditRetentionListTotalRows(list.rows));
    }
  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default auditListRetentionCall;

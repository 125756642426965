import React from "react";
import { Route, Routes } from "react-router-dom";

import EntitiesList from "./EntitiesList/EntitiesList";
import EntityDetails from "./EntityDetails/EntityDetails";
import EntityUsers from "./EntityUsers/EntityUsers";
import EntityAddresses from "./EntityAddresses/EntityAddresses";
import GroupList from "./GroupList/GroupList";
import GroupCreate from "./GroupCreate/GroupCreate";
import CostsForm from "../../features/operator/CostsForm/CostsForm";
import CutOffsList from "./CutOffsList/CutOffsList";
import CutOffCreate from "./CutOffCreate/CutOffCreate";
import EntityForm from "./EntityForm/EntityForm";
import BusinessUnitForm from "./BusinessUnitForm/BusinessUnitForm";
import UserForm from "./UserForm/UserForm";
import EntitySectorGroupsList from "./EntitySectorGroupsList/EntitySectorGroupsList";
import EntitySectorGroupForm from "./EntitySectorGroupForm/EntitySectorGroupForm";
import EntityAddressForm from "./EntityAddressForm/EntityAddressForm";
import Security from "../../app/components/Security/Security";
import PendingEntityList from "./PendingEntityList/PendingEntityList";

function EntitiesRouting() {
  return (
    <Routes>
      <Route path="cutoffs/edit/:id" element={
        <Security component={<CutOffCreate />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="cutoffs/add" element={<CutOffCreate />
      } />
      <Route path="cutoffs" element={<CutOffsList />} />
      <Route path="groups/edit/:id" element={
        <Security component={<GroupCreate />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="groups/add" element={<GroupCreate />} />
      <Route path="groups" element={<GroupList />} />
      <Route path="add" element={<EntityForm />} />
      <Route path="edit/:id" element={
        <Security component={<EntityForm isEdit />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/validation/:processId" element={
        <Security component={<PendingEntityList />} paramValidation={[{ name: "processId", type: "number" }]} />
      } />
      <Route path="/validation" element={<PendingEntityList />} />
      <Route path=":id/confirm" element={<EntityDetails confirm />} />
      <Route path=":id/addresses/add" element={
        <Security component={<EntityAddressForm />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path=":id/addresses/edit/:addressId" element={
        <Security component={<EntityAddressForm />} paramValidation={[{ name: "id", type: "uuid" }, { name: "addressId", type: "uuid" }]} />
      } />
      <Route path=":id/addresses/:addressId" element={
        <Security component={<EntityAddresses />} paramValidation={[{ name: "id", type: "uuid" }, { name: "addressId", type: "uuid" }]} />
      } />
      <Route path=":id/addresses" element={
        <Security component={<EntityAddresses />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path=":id/contacts/add" element={
        <Security component={<UserForm />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path=":id/contacts/edit/:contactId" element={
        <Security component={<UserForm />} paramValidation={[{ name: "id", type: "uuid" }, { name: "contactId", type: "uuid" }]} />
      } />
      <Route path=":id/contacts/:contactId" element={
        <Security component={<EntityUsers />} paramValidation={[{ name: "id", type: "uuid" }, { name: "contactId", type: "uuid" }]} />
      } />
      <Route path=":id/contacts" element={
        <Security component={<EntityUsers />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path=":id/sectorgroups/add" element={
        <Security component={<EntitySectorGroupForm />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path=":id/sectorgroups/edit/:groupId" element={
        <Security component={<EntitySectorGroupForm />} paramValidation={[{ name: "id", type: "uuid" }, { name: "groupId", type: "uuid" }]} />
      } />
      <Route path=":id/sectorgroups" element={
        <Security component={<EntitySectorGroupsList />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path=":id/operator-costs" element={
        <Security component={<CostsForm />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path=":id/business-unit/add" element={
        <Security component={<BusinessUnitForm />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path=":id/business-unit/edit" element={
        <Security component={<BusinessUnitForm isEdit={true} />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path=":id/validation/:task" element={
        <Security component={<EntityDetails validation />} paramValidation={[{ name: "id", type: "uuid" }, { name: "task", type: "number" }]} />
      } />
      <Route path=":id" element={
        <Security component={<EntityDetails />} paramValidation={[{ name: "id", type: "uuid" }]} />
      } />
      <Route path="/" element={<EntitiesList />} />
      {/* <Route path="*" element={<NotAvailablePage />
    } /> */}
    </Routes>
  );
}

export default EntitiesRouting;

import { requestHelper } from "../../../app/coreSlice";
import { jsonTryParse } from "../../../common/utils";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const productListFamilyCall = async ({ clearFamilyList, setFamilyListFilter, appendToFamilyList, setFamilyListTotalRows }, dispatch, { filter, clear }) => {
  let rh = requestHelper(dispatch, "PRODUCT_LIST_FAMILY");
  if (clear) {
    dispatch(clearFamilyList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setFamilyListFilter(filter));
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "LIST_FAMILY",
      request: {
        ...filter,
        parentWithChild: false,
      },
    });
    dispatch(
      appendToFamilyList(
        (result.productFamily || []).map((item) => {
          return {
            ...item,
            description: jsonTryParse(item.description),
          };
        })
      )
    );
    dispatch(setFamilyListTotalRows(result.rows));
  } catch (ex) {
    rh.error("productsFamilies", ex);
  }
  rh.close();
};

export default productListFamilyCall;
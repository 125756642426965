import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useFirstLoad, useNavigate, useUser } from "../../../common/hooks";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

import { withStyles } from "tss-react/mui";
import { styles } from "./EntityAddresses.styles";

import PageTitle from "../../../common/displays/PageTitle/PageTitle";

import { deleteAddress, confirmAddress } from "../entitiesSlice";
import { entityGet, selectEntityDetails } from "../entitiesSlice";
import AddressesDataGrid from "./AddressesDataGrid/AddressesDataGrid";
import { usePromoter } from "../../../common/hooks";
import Page from "../../../common/displays/Page/Page";
import Permission from "../../../app/components/Permission";

function EntityAddresses({ classes }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const details = useSelector(selectEntityDetails);
  const promoter = usePromoter();
  const user = useUser();

  useFirstLoad(() => {
    if (details?.entityId?.toString() !== id) {
      dispatch(entityGet(id));
    }
  });

  return details ? (
    <Page
      permission="entities.actions.address.list"
      header={
        <PageTitle
          title={t("entities.Entities.addresses.title")}
          info={`${details.addresses?.length || 0}`}
          onBack={() => navigate(`/entities/${id}`)}
          actions={
            <Permission code="entities.actions.address.add">
              {(user?.entity?.type === "P" || user?.entity?.id === id) && details?.status !== "PND" &&
                <Button variant="contained" color="primary" onClick={() => navigate(`/entities/${id}/addresses/add`)}>
                  {t("entities.Entities.addresses.btnAdd")}
                </Button>
              }
            </Permission>
          }
        />}>
      <AddressesDataGrid
        data={details.addresses}
        entity={details}
        onDelete={(row) => dispatch(deleteAddress(promoter, row.entityId, row.id))}
        onConfirm={(row) => dispatch(confirmAddress(row.entityId, row.id))}
        onEdit={(row) => navigate(`/entities/${id}/addresses/edit/${row.id}`)}
      />
    </Page>
  ) : null;
}

export default withStyles(EntityAddresses, styles);

export const styles = theme => ({
  root: {},
  input: {
    marginBottom: 0,
  },
  adornment: {
    backgroundColor: "#fbfcfd"
  },
  disabledLabel: {
    color: "#97A1AF",
  },
  disabledInput: {
    backgroundColor: "#F5F7F9",
    color: "#97A1AF"
  },
  checkIcon: {
    cursor: "pointer"
  },
  statusIcon: {
    cursor: "auto"
  },
  error: {
    minHeight: 13,
    marginTop: 2,
    "& .MuiTypography-caption": {
      display: "block",
      lineHeight: "1.1 !important",
      fontSize: 11,
    },
  },
});
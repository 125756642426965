export const getInputTypeOptions = (t, inputs, selectedResourceInput, edit) => {
  // console.log('getInputTypeOptions: %o', { inputs, selectedResourceInput, edit })
  let options = ["text", "number"];

  if ((!inputs.some(item => item.type === "resource") && !selectedResourceInput) || edit) {
    options.push("resource");
  }

  if (selectedResourceInput || edit) {
    options.push("transfer");
  }

  return (options)
    .map((item) => {
      return {
        value: item,
        text: t("common.Enums.taskInputType." + item),
      };
    });
};

export const getResourceTypeOptions = (t, promoter) => {
  let resourceTypesAllowed = promoter?.getConfig("general", "resourceTypes") || [];
  return resourceTypesAllowed.map((item) => {
    return {
      value: item,
      text: t("common.Enums.resourceTypes." + item),
    };
  });
};

export const getPlaceTypeOptions = (t, promoter) => {
  let placeTypesAllowed = promoter?.refData?.placeTypes || [];
  return placeTypesAllowed.map((item) => {
    return {
      value: item.type,
      text: t("common.Enums.placeTypes." + item.type),
    };
  });
};
import React from "react";
import { withStyles } from "tss-react/mui";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "../../../common/hooks";
import { Button, Grid } from "@mui/material";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import SearchBox from "../../../common/components/inputs/SearchBox";
import RefreshIcon from "@mui/icons-material/Refresh";

import { styles } from "./AggregatedOrders.styles";
import AggregatedDataGrid from "./AggregatedDataGrid/AggregatedDataGrid";

import {
  orderListAggregate,
  selectAggregatedOrders,
  selectAggregatedOrdersTotalRows,
  selectAggregatedOrdersFilter,
} from "../ordersSlice";
import { selectLoader } from "../../../app/coreSlice";
import Page from "../../../common/displays/Page/Page";
import { canAcceptOrder, canDispatchOrder } from "../_common/ordersUtils";

function AggregatedOrders({ classes }) {
  const { t } = useTranslation();
  const loading = useSelector(selectLoader);
  const data = useSelector(selectAggregatedOrders);
  const filter = useSelector(selectAggregatedOrdersFilter);
  const totalRows = useSelector(selectAggregatedOrdersTotalRows);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search,
      offset: 0,
    };
    dispatch(orderListAggregate(nFilter, true));
  };

  const onRowSelectedHandler = (order) => {
    if (order?.orderSellerIdList?.length === 1) {
      let orderSellerId = order.orderSellerIdList[0];
      if (canAcceptOrder(order)) {
        navigate(`/orders/received/${orderSellerId}/accept`);
      }
      else if (canDispatchOrder(order)) {
        navigate(`/orders/received/${orderSellerId}/dispatch`);
      }
      else {
        navigate("/orders/received/products/" + orderSellerId);
      }
    }
    else {
      navigate("/orders/received/" + order.id);
    }
  };

  const onFilterChangeHandler = (v) => {
    dispatch(orderListAggregate(v.filter, v.reset));
  };

  const refreshHandler = () => {
    dispatch(orderListAggregate(filter, true));
  };

  return (
    <Page
      permission="orders.actions.received.list"
      header={<PageTitle
        title={t("orders.AggregatedOrders.header.title")}
        info={`${totalRows} ${t("orders.AggregatedOrders.header.rowsInfo")}`}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <SearchBox onEnter={(v) => onSearchHandler(v)} />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={refreshHandler}
                disabled={Boolean(loading)}
              >
                <RefreshIcon />
              </Button>
            </Grid>
          </Grid>
        }
      />}>
      <AggregatedDataGrid
        data={data}
        totalRows={totalRows}
        onRowSelected={onRowSelectedHandler}
        filter={filter}
        onFilterChange={onFilterChangeHandler}
        loading={loading}
      />
    </Page>
  );
}

export default withStyles(AggregatedOrders, styles);

export const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  title: {
    margin: 0,
  },
  type: {
    margin: 0,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  noMargin: {
    margin: 0,
  },
  addressDescription: {
    margin: 0,
    fontWeight: "bold"
  }
});
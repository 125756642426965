import React from "react";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {CustomDataGrid} from "../../../../common/displays/CustomDataGrid";

import {styles} from "./AggregatedOrderCommentsDataGrid.styles";
import {listColumns} from "./AggregatedOrderCommentsDataGrid.columns";

function AggregatedOrderCommentsDataGrid({
  classes,
  data
}) {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          localFilters
          columns={listColumns(t, classes)}
          rows={data}
          rowKeyField="key"
        />
      )}
    </div>
  );
}

export default withStyles(AggregatedOrderCommentsDataGrid, styles)

import {call} from "../../../app/websocket/_sliceCalls/websocket";
import {requestHelper} from "../../../app/coreSlice";

const placeListCall = async (
  { clearPlaceList, setPlaceListFilter, appendToPlaceList, setPlaceListTotalRows, setPlaceListDetails },
  dispatch,
  state,
  { filter, clear, reloadList }
) => {
  let rh = requestHelper(dispatch, "PLACE_LIST");
  if(reloadList){
    if (clear) {
      dispatch(clearPlaceList());
      filter = { ...filter, offset: 0 };
    }
    dispatch(setPlaceListFilter(filter));
  } else {
    if (clear)
      dispatch(setPlaceListDetails([]));
  }
  try {
    let result = await call({
      type: "PLACE",
      subType: "LIST",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    if(reloadList){
      // used to list places in /list
      dispatch(appendToPlaceList(result.items));
      dispatch(setPlaceListTotalRows(result.rows));
    } else {
      // used to obtain parentId options
      dispatch(setPlaceListDetails(result.items));
    }
  } catch (ex) {
    rh.error("places", ex);
  }
  rh.close();
};

export default placeListCall;
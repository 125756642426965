

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";

const productRequestPurchaseCall = async (
  dispatch,
  state,
  { productId, comment, quantity, price }
) => {
  let rh = requestHelper(dispatch, "PRODUCT_REQUEST_PURCHASE");
  try {
    await call({
      type: "PRODUCT",
      subType: "REQUEST_PURCHASE",
      request: {
        productId,
        comment,
        quantity,
        price,
      },
    });
    dispatch(setSuccess("products", "PRODUCT_REQUEST_PURCHASE"));
    setTimeout(() => {
      dispatch(setRedirect("/products/purchases"));
    }, 300);
  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productRequestPurchaseCall;




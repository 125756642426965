import { processImageUrl } from "../../../api/s3";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { asyncForEach } from "../../../common/utils";

const productPriceComparisonCall = async (
  { setPricesComparerLists, updatePricesComparerListsThumbnails },
  dispatch,
  state,
  { productId }
) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "PRODUCT_PRICE_COMPARISON");
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "PRICE_COMPARISON",
      request: {
        productId
      },
    });
    dispatch(setPricesComparerLists(result));

    let similarProducts = [];
    await asyncForEach(result.similarProducts, async (product) => {
      similarProducts.push({
        ...product,
        thumbnail: await processImageUrl(fileStorageConfigs, product.thumbnail),
      });
    });

    let subFamilyProducts = [];
    await asyncForEach(result.subFamilyProducts, async (product) => {
      subFamilyProducts.push({
        ...product,
        thumbnail: await processImageUrl(fileStorageConfigs, product.thumbnail),
      });
    });

    dispatch(updatePricesComparerListsThumbnails({ similarProducts, subFamilyProducts }));

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productPriceComparisonCall;

import React from "react";
import { useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Paper, Grid, Button, CircularProgress } from "@mui/material";

import { styles } from "./Highlights.styles";
import { usePromoter } from "../../../../common/hooks";
import Carousel from "react-material-ui-carousel";
import { selectPromoterHighlights, selectPromoterHighlightsLoaded } from "../../../../app/coreSlice";

function Highlights({ classes }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const highlightsLoaded = useSelector(selectPromoterHighlightsLoaded);
  const highlights = useSelector(selectPromoterHighlights);

  return (
    <Grid container direction="column" className={classes.root} spacing={2}>
      {promoter?.getConfig("home", "hasFlyer") && promoter?.getConfig("home", "flyer") && (
        <Grid item container justifyContent="flex-end">
          <Button variant="outlined" href={promoter?.getConfig("home", "flyer")?.url} target="_blank">
            {t("home.Highlights.flyer.flyerBtn")}
          </Button>
        </Grid>
      )}
      {promoter?.getConfig("home", "hasHighlights") &&
        highlights && (
          <Grid item>
            <Paper
              className={classes.paper}
              style={{ height: promoter?.getConfig("home", "hasFlyer") ? "calc(100vh - 100px)" : "calc(100vh - 50px)" }}
            >

              {
                highlightsLoaded ?
                  <Carousel indicators={false}>
                    {(highlights || []).map((highligh) => (
                      <div
                        className={classes.carouselItem}
                        key={highligh.id}
                        style={{
                          backgroundImage: `url('${highligh.base64}')`,
                          height: promoter?.getConfig("home", "hasFlyer") ? "calc(100vh - 132px)" : "calc(100vh - 82px)",
                        }}
                      >
                      </div>
                    ))}
                  </Carousel> :
                  <Grid container className={classes.loaderBox} justifyContent="center" alignItems="center">
                    <CircularProgress />
                  </Grid>
              }
            </Paper>
          </Grid>
        )}
    </Grid>
  );
}

export default withStyles(Highlights, styles)

import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const entityEnableCall = async ({ entityList, updateSelectedEntity }, dispatch, { filter, id }) => {
  let rh = requestHelper(dispatch, "ENTITY_ENABLE");
  try {
    await call({
      type: "ENTITY",
      subType: "ENABLE",
      request: { id },
    });
    dispatch(setSuccess("entities", "ENTITY_ENABLE"));
    dispatch(updateSelectedEntity({ entityId: id, status: "ACT" }));
    setTimeout(() => {
      dispatch(entityList(filter, true));
    }, 300);
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default entityEnableCall;

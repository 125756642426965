export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
  },
  paper: {
    padding: theme.spacing(3),
    position: "relative",
    width: "100%",
  },
  loader: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  drawer: {
    width: "25vw",
  },
  drawerFilter: {
    width: "25vw",
    padding: theme.spacing(2),
  },
});

import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { listToTree } from "../../../../common/utils";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";


import { styles } from "./FamilyListDataTree.styles";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Permission from "../../../../app/components/Permission";

function FamilyListDataTree({ classes, data, onSelect, onAdd }) {
  const { i18n, t } = useTranslation();
  const [tree, setTree] = useState([]);

  useEffect(() => {
    if (data) {
      setTree(listToTree(data));
    }
  }, [data]);

  let treeUI = (arr, parentId, lvl = 0) => {
    let items =
      arr && arr.length > 0
        ? arr.map(({ id, description, children }) => {
          let label = getItemLabel(t, i18n, description, lvl, children);
          return (
            <TreeItem
              className={label.isEmpty ? classes.empty : ""}
              key={"key_" + id.toString()}
              itemId={id.toString()}
              label={label.description}
              onClick={() => onSelect && onSelect({ ...data.find((item) => item.id === id), children })}
            >
              {treeUI(children, id, lvl + 1)}
            </TreeItem>
          );
        })
        : [];

    if (lvl <= 1) {
      items.push(
        <div key={"key_add_" + parentId}>
          <Permission code="promoter.actions.family.create">
            <TreeItem
              itemId={"add_" + parentId}
              label={
                <IconButton size="small" className={classes.add}>
                  <AddIcon />
                </IconButton>
              }
              onClick={() => onAdd && onAdd({ parent: parentId })}
            ></TreeItem>
          </Permission>
        </div>
      );
    }

    return items;
  };

  return (
    <div className={classes.root}>
      <SimpleTreeView>
        {treeUI(tree)}
      </SimpleTreeView>
    </div>
  );
}

export default withStyles(FamilyListDataTree, styles)

const getItemLabel = (t, i18n, description, lvl, children) => {
  let desc = description[i18n.language];
  let isEmpty = false;
  if (!desc.trim()) {
    desc = t("productsFamilies.FamilyList.item.empty")
    isEmpty = true;
  }
  return {
    description: lvl === 0 ? `${desc} (${(children || []).length})` : desc,
    isEmpty,
  };
};

import * as Yup from "yup";
import {MultiSelectField, SelectField} from "../../../../common/forms";

export const filterFields = (promoter) => (t) => {
  return [
    {
      size: 12,
      field: (
        <MultiSelectField
          id="status"
          label={t("products.ProductValidationList.filter.processStatus")}
          options={getStatusOptions(t)}
        />
      ),
      hidden: (promoter?.getConfig("tax", "autoAcceptCreateTax"))
    },
    {
      size: 12,
      field: (
        <MultiSelectField
          id="processTypes"
          label={t("products.ProductValidationList.filter.processTypes")}
          options={getProcessTypesOptions(t)}
        />
      ),
    },
    {
      size: 12,
      field: (
          <SelectField
              id="active"
              label={t("products.ProductValidationList.filter.status")}
              options={[
                { value: null, text: t("products.ProductValidationList.filter.allStatusOption") },
                ...getEnableOptions(t)
              ]}
          />
      ),
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    status: Yup.array(),
  });
};

const getStatusOptions = (t) => {
  return ["PENDING", "VALIDATING", "REFUSED", "ACCEPTED", "NOT_OK", "NOT_VALIDATED", "RECOVERED"].map((value) => {
    return {
      value,
      text: t("common.Enums.productWorkflow." + value),
    };
  });
};
const getEnableOptions = (t) => {
  return [true, false].map((value) => {
    return {
      value,
      text: t("products.ProductValidationList.filter.status_" + value),
    };
  });
};
const getProcessTypesOptions = (t) => {
  return ["FlowCreateProduct", "FlowUpdateProduct", "FlowRecoverProduct"].map((value) => {
    return {
      value,
      text: t("common.Enums.productProcessType." + value),
    };
  });
};
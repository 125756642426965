import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const userDefineExternalAccessCall = async ({ setExternalAccessKey }, dispatch, { entityId, username }) => {
  let rh = requestHelper(dispatch, "DEFINE_EXTERNAL_ACCESS");
  try {
    let result = await call({
      type: "USER",
      subType: "DEFINE_EXTERNAL_ACCESS",
      request: {
        entityId,
        username,
        type: "WS",
      },
    });
    setTimeout(() => {
      dispatch(setSuccess("entities", "USER_DEFINE_EXTERNAL_ACCESS"));
      dispatch(setExternalAccessKey(result.apiKey));
    }, 300);
  } catch (ex) {
    rh.error("entities", ex);
  }
  rh.close();
};

export default userDefineExternalAccessCall;

export const getCodeOptions = (t) => {
  return ["MISSING", "DAMAGED", "EXCESS", "NON_CONFORMING_GOODS", "GOODS_WITHOUT_DOCUMENTATION", "INVOICING"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.incidentCode." + item),
    };
  });
};

export const getSearchType = (t) => {
  return ["ORDERID", "DELIVERYTIME", "CREATEDTIME", "PRODUCT"].map((item) => {
    return {
      value: item,
      text: t("common.Enums.incidentSearchType." + item),
    };
  });
};
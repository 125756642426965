import React from "react";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";
import { TextField, Typography, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { styles } from "./BatchFields.styles";
import { DatePickerInput } from "../../../../../../common/inputs";
import { QuantityInput } from "../../../../../../common/inputs/custom";

function BatchFields({ classes, product, batch, onChange, onRemove }) {
  const { t } = useTranslation();

  const fieldChangeHandler = (field, value) => {
    let nBatch = { ...batch };
    nBatch[field] = value;
    onChange && onChange(nBatch);
  };

  return (
    <Grid className={classes.root} container direction="row" spacing={2} wrap="nowrap">
      <Grid item style={{ width: "100%" }}>
        <Grid className={classes.root} container direction="row" spacing={2}>
          {product?.hasBatchNumber && (
            <Grid item xs={3}>
              <Typography component="span" variant="caption" color="textPrimary">
                {t("orders.AggregatedDetails.grid.batch")}
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={batch?.batch}
                onChange={(e) => fieldChangeHandler("batch", e.target.value)}
              />
            </Grid>
          )}
          {product?.hasExpirationDate && (
            <Grid item xs={3}>
              <DatePickerInput
                label={t("orders.AggregatedDetails.grid.expirationDate")}
                value={batch?.expirationDate}
                onChange={(v) => fieldChangeHandler("expirationDate", v)}
              />
            </Grid>
          )}
          {product?.hasVariableWeight && (
            <Grid item xs={3}>
              <Typography component="span" variant="caption" color="textPrimary">
                {t("orders.AggregatedDetails.grid.weight")}
              </Typography>
              <TextField
                fullWidth
                type="number"
                variant="outlined"
                size="small"
                value={batch?.weight}
                onChange={(e) => fieldChangeHandler("weight", e.target.value)}
              />
            </Grid>
          )}
          <Grid item xs={3}>
            <QuantityInput
              value={batch?.quantity}
              onChange={(v) => fieldChangeHandler("quantity", v)}
              label={t("orders.AggregatedDetails.grid.quantity")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <IconButton className={classes.deleteButton} onClick={() => onRemove && onRemove()} size="large">
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default withStyles(BatchFields, styles)

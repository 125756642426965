export const styles = (theme) => ({
  root: {},
  emptytable: {
    textAlign: "center",
    border: "1px solid " + theme.palette.text.secondary,
    padding: 10,
    borderRadius: 4,
    cursor: "pointer",
  },
  disabled: {
    backgroundColor: "#F5F7F9",
    borderColor: "#D2D9E4!important",
    cursor: "default"
  }
});


import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Stack } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { styles } from "./TasksList.styles";
import TasksDataGrid from "./TasksDataGrid/TasksDataGrid";

import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import Permission from "../../../app/components/Permission";
import {
  selectTaskDetails,
  selectTaskList, selectTaskListCommentsFilter,
  selectTaskListFilter,
  selectTaskListTotalRows,
  taskGet,
  taskList, taskListComments,
} from "../tasksSlice";
import { selectLoader } from "../../../app/coreSlice";
import TaskDetailsModal from "./TaskDetailsModal/TaskDetailsModal";
import { useNavigate } from "../../../common/hooks";

function TasksList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(selectTaskList);
  const totalRows = useSelector(selectTaskListTotalRows);
  const filter = useSelector(selectTaskListFilter);
  const filterListComments = useSelector(selectTaskListCommentsFilter);
  const loading = useSelector(selectLoader);
  const [modalDetails, setModalDetails] = useState(false);
  const [selectedTask, setSelectedTask] = useState();
  const taskDetails = useSelector(selectTaskDetails);

  useEffect(() => {
    // console.log('------- %o ---> %o', selectedTask, taskDetails)
    if (taskDetails && taskDetails.taskId === selectedTask?.taskId) {
      setSelectedTask(taskDetails);
    }
    // eslint-disable-next-line
  }, [taskDetails])

  const onAddHandler = () => {
    navigate("/tasks/create")
  }

  const onFilterChangeHandler = (v) => {
    dispatch(taskList({ ...filter, ...v.filter }, v.reset));
  }

  const onTaskSelectHandler = (row) => {
    console.log('onTaskSelectHandler - task: %o', row);
    setSelectedTask(row);
    dispatch(taskListComments({ ...filterListComments, taskId: row.taskId }, true));
    dispatch(taskGet({ taskId: row.taskId }))
    setModalDetails(true);
  }

  return (
    <Page
      permission={"tasks.actions.tasks.list"}
      header={<PageTitle
        title={t("tasks.TasksList.header.title")}
        actions={
          <Grid container direction="row" spacing={2}>
            <Permission code={"tasks.actions.tasks.create"}>
              <Grid item>
                <Button variant="outlined" size="large" disabled={loading} onClick={onAddHandler} id="add"><AddIcon fontSize="inherit" /></Button>
              </Grid>
            </Permission>
          </Grid>
        }
      />}>
      <Stack spacing={1} className={classes.root}>
        <TasksDataGrid
          data={data}
          totalRows={totalRows}
          onFilterChange={onFilterChangeHandler}
          filter={filter}
          onSelect={onTaskSelectHandler}
        />
        <TaskDetailsModal open={modalDetails} setOpen={setModalDetails} task={selectedTask} />
      </Stack>
    </Page>
  );
}

export default withStyles(TasksList, styles);
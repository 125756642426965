import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper, Accordion, AccordionSummary, AccordionDetails, Stack } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import i18next from "i18next";
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';

import { styles } from "./CustomFormsDetails.styles";
import { useFirstLoad } from "../../../../common/hooks";
import { promoterGetCustomForms, promoterListCustomForms, selectCustomFormDetails, selectCustomFormsList } from "../../../promoter/promoterSlice";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";
import { useEffect } from "react";
import { diffWordsWithSpace } from "diff";
import DiffSpan from "../../_common/DiffSpan/DiffSpan";
import CheckboxIcon from "./CheckboxIcon/CheckboxIcon";

function CustomFormsDetails({ classes, forms, diff, oldForms }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customForms = useSelector(selectCustomFormsList);
  const filteredForms = (customForms || []).filter(item => Object.keys(forms).includes(item.key));
  const [selectedFormKey, setSelectedFormKey] = useState(null);
  const customFormsDetails = useSelector(selectCustomFormDetails);

  useFirstLoad(() => {
    if (!customForms || customForms.length === 0) {
      dispatch(promoterListCustomForms());
    }
  });

  useEffect(() => {
    if (filteredForms) {
      (filteredForms || []).forEach(form => {
        if (!customFormsDetails?.[form.key]) {
          dispatch(promoterGetCustomForms(form.id));
        }
      })
    }
    // eslint-disable-next-line
  }, [customForms])

  const onExpandHandler = (expanded, form) => {
    if (expanded) {
      setSelectedFormKey(form.key);
    }
    else {
      setSelectedFormKey(null);
    }
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container direction="column" spacing={2}>
        {!diff && (
          <Grid item>
            <Typography variant="h3" align="left" component="h2" className={classes.noMargin}>
              {t("products.ProductDetails.details.customFormsTitle")}
            </Typography>
          </Grid>
        )}
        <Grid item>
          {filteredForms.map(item => (
            <Accordion key={item.key} onChange={(e, expanded) => onExpandHandler(expanded, item)} expanded={Boolean(selectedFormKey === item.key || diff)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Stack direction="row" alignItems="center" spacing={2} >
                  <DnsOutlinedIcon />
                  <Typography>{getLocalName(item.name)}</Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={1}>
                  {
                    customFormsDetails?.[item.key] && (customFormsDetails?.[item.key]?.fields || []).map((field, index) => (
                      <div key={field.key + "_" + index}>
                        {
                          field.type === "separator" &&
                          <div className={classes.separator}>
                            {getLocalName(field.label)}
                          </div>
                        }
                        {
                          field.type === "checkbox" &&
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <CheckboxIcon value={forms?.[item.key]?.[field.key]} oldValue={oldForms?.[item.key]?.[field.key]} />
                            <Typography variant="h6" align="left" component="label" color="textSecondary">{getLocalName(field.label)}</Typography>
                          </Stack>
                        }
                        {
                          field.type !== "separator" && field.type !== "checkbox" &&
                          <LabelValue
                            label={getLocalName(field.label)}
                            value={diff && oldForms
                              ? <DiffSpan diff={diffWordsWithSpace(oldForms?.[item.key]?.[field.key] || "N/A", forms?.[item.key]?.[field.key] || "N/A")} field={field.key} />
                              : (
                                <Typography variant="body1" align="left" component="span">
                                  {forms?.[item.key]?.[field.key] || "N/A"}
                                </Typography>
                              )
                            }
                          />
                        }
                      </div>
                    ))
                  }
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(CustomFormsDetails, styles);

const getLocalName = (name) => {
  if (!name) {
    return "";
  }
  let altLang = Object.keys(name).filter((k) => k !== i18next.language)?.[0];
  return name[i18next.language] || (altLang && name[altLang])
}
import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { Grid, Button } from "@mui/material";

import { styles } from "./RejectButtonWithComment.styles";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";
import { TextInput } from "../../../../common/inputs";

function RejectButtonWithComment({ classes, btnLabel, cancelLabel, inputLabel, title, onConfirm, disabled }) {
  const [modal, setModal] = useState(false);
  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);

  const onConfirmHandler = () => {
    setError(false);
    if (comment) {
      onConfirm && onConfirm(comment);
    }
    else {
      setError(true);
    }
  }

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="error"
        size="large"
        type="button"
        disabled={disabled}
        onClick={() => setModal(true)}
      >
        {btnLabel}
      </Button>
      <CustomModal open={modal} setOpen={setModal} size="medium" height={285} title={title}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <TextInput rows={3} label={inputLabel} onChange={(value) => setComment(value)} error={error} />
          </Grid>
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  disabled={disabled}
                  onClick={() => setModal(false)}
                >
                  {cancelLabel}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="error"
                  type="button"
                  disabled={disabled}
                  onClick={onConfirmHandler}
                >
                  {btnLabel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>
    </div>
  );
}

export default withStyles(RejectButtonWithComment, styles);
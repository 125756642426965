import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import { listColumns } from "./EntitySectorGroupDataGrid.columns";
import { styles } from "./EntitySectorGroupDataGrid.styles";

import EntitySectorGroupDataGridActions from "./EntitySectorGroupDataGrid.actions";

function EntitySectorGroupDataGrid({ classes, data, onEdit, onDelete, contacts }) {
  const { t } = useTranslation();
  const columns = listColumns(t, contacts, classes);

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          columns={columns}
          rows={data}
          actionsComponent={<EntitySectorGroupDataGridActions onEdit={onEdit} onDelete={onDelete} />}
        />
      )}
    </div>
  );
}

export default withStyles(EntitySectorGroupDataGrid, styles);

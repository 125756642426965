import * as Yup from "yup";
import {DateTimePickerField, EntityAutoCompleteField, TextField} from "../../../../common/forms";
import moment from "moment/moment";
import React from "react";

export const filterFields = (user) => (t) => {
  return [
    {
      size: 12,
      field: (
          <DateTimePickerField
              id="createdTimeIni"
              label={t("business.queries.filter.createdTimeIni")}
              maxDate={moment().toISOString()}
              required
          />
      )
    },
    {
      size: 12,
      field: (
          <DateTimePickerField
              id="createdTimeEnd"
              label={t("business.queries.filter.createdTimeEnd")}
              maxDate={moment().toISOString()}
              required
          />
      )
    },
    {
      size: 12,
      field: (
          <TextField
              id="createdBy"
              label={t("business.queries.filter.createdBy")}
          />
      ),
    },
    {
      size: 12,
      field: (
          <EntityAutoCompleteField
              id="createdByEntityId"
              label={t("business.queries.filter.entity")}
              typeFilter={["S", "ST", "W", "LO", "P"]}
          />
      ),
      hidden: !["P", "A"].includes(user?.entity?.type),
    },
    {
      size: 12,
      field: <TextField id="search" label={t("business.queries.filter.search")} />,
    },
  ];
};

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    createdTimeIni: Yup.string(),
    createdTimeEnd: Yup.string()
  });
};


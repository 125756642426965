import alerts from "./alerts";
import authentication from "./authentication";
import business from "./business";
import common from "./common";
import documents from "./documents";
import emails from "./emails";
import entities from "./entities";
import errors from "./errors";
import home from "./home";
import i18n from "./i18n";
import operator from "./operator";
import orders from "./orders";
import ordersIncidents from "./ordersIncidents";
import products from "./products";
import productsDproms from "./productsDproms";
import productsFamilies from "./productsFamilies";
import productsPrices from "./productsPrices";
import productsPurchases from "./productsPurchases";
import promoter from "./promoter";
import roles from "./roles";
import users from "./users";
import tasks from "./tasks";
import places from "./places";
import resources from "./resources";
import planning from "./planning";
import businessUnits from "./businessUnits";
import batches from "./batches";

const defaultI18n = {
  alerts,
  authentication,
  business,
  common,
  documents,
  emails,
  entities,
  errors,
  home,
  i18n,
  operator,
  orders,
  ordersIncidents,
  products,
  productsDproms,
  productsFamilies,
  productsPrices,
  productsPurchases,
  promoter,
  roles,
  users,
  tasks,
  places,
  resources,
  planning,
  businessUnits,
  batches
};

export default defaultI18n;

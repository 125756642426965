const alerts = {
  flowsMessages: {
    success: {
      ALERT_RESOLVE: "Alert marked as read",
      ALERT_RESOLVE_ALL: "All alerts marked as read"
    },
    error: {
      GENERIC: "Something went wrong",
    },
  },
  AlertsList: {
    actions: {
      markAllRead: "Mark all read"
    },
    tabs: {
      title: "Alerts",
      unread: "Unread",
      read: "Read",
    },
    tooltip: {
      resolve: "Mark as read",
      resolved: "Resolved by {{resolvedBy}} \n@ {{resolvedDate}}",
    },
    title: {
      ENTITY: "Entity",
      ORDER: "Order",
      PRODUCT: "Product",
      PRODUCT_PRICE: "Product Price",
      PRODUCT_PRICE_ACTION: "Product Price Validation",
      PRODUCT_ACTION: "Product Validation",
      PRODUCT_PROMOTER: "Product",
      PRODUCT_DOC: "Product Document",
      ENTITY_DOC: "Entity Document",
      PRODUCT_PURCHASE: "Product Purchase",
      ENTITY_ACTION: "Entity Validation",
      ENTITY_PROMOTER: "Entity",
      PROMO_DISCOUNT: "Promotional Discount",
      PROMO_DISCOUNT_ACTION: "Promotional Discount Validation",
      TAX: "Product Tax",
      TAX_ACTION: "Product Tax Validation",
      TASK: "New Task",
      TASK_ACTION: "Task Changed",
      TASK_CHECK_TASK_DUE_DATE: "Task Due Date",
    },
    description: {
      ENTITY_UPDATE_NAME_BY_ENTITY: "Entity name was updated by entity",
      ENTITY_UPDATE_NAME_BY_PROMOTER: "Entity name was updated by promoter",
      ENTITY_UPDATE_TAX_ADDRESS_BY_ENTITY: "Entity tax address was updated by entity",
      ENTITY_UPDATE_TAX_ADDRESS_BY_PROMOTER: "Entity tax address was updated by promoter",
      ENTITY_ADD_ADDRESS_BY_ENTITY: "Address added by entity",
      ENTITY_ADD_ADDRESS_BY_PROMOTER: "Address added by promoter",
      ENTITY_DISABLE_ADDRESS_BY_ENTITY: "Address disabled by entity",
      ENTITY_DISABLE_ADDRESS_BY_PROMOTER: "Address disabled by promoter",
      ENTITY_DISABLE_CONTACT_BY_ENTITY: "Contact disabled by entity",
      ENTITY_DISABLE_CONTACT_BY_PROMOTER: "Contact disabled by promoter",
      ENTITY_ACTION_ACCEPT_ENTITY: "Entity to accept: {{entityName}}",
      ENTITY_ACTION_VALIDATE_ENTITY: "Entity to validate: {{entityName}}",
      ENTITY_ACTION_ENTITY_ACCEPTED: "Entity accepted: {{entityName}}",
      ENTITY_ACTION_ENTITY_REFUSED: "Entity refused: {{entityName}}",
      ENTITY_ACTION_ENTITY_NOT_OK: "Entity not ok: {{entityName}}",
      ENTITY_ACTION_ENTITY_NOT_VALIDATED: "Entity not valid: {{entityName}}",
      ENTITY_PROMOTER_ENTITY_CREATED: "Entity created: {{entityName}}",
      ENTITY_PROMOTER_ENTITY_UPDATED: "Entity updated: {{entityName}}",
      ORDER_NEW_ORDER: "New order created",
      ORDER_NEW_ORDER_S: "New order created",
      ORDER_ACCEPT_ORDER: "Order Accepted",
      ORDER_DISPATCH_ORDER: "Order Dispatched",
      ORDER_RECEIVE_ORDER: "Order Received",
      ORDER_RECEIVE_ORDER_S: "Order Received",
      ORDER_IN_TRANSIT: "Order in transit",
      ORDER_IN_TRANSIT_S: "Order in transit",
      ORDER_DELIVER_ORDER: "Order delivered",
      ORDER_DELIVER_ORDER_S: "Order delivered",
      ORDER_REMINDER_OPEN_ORDER: "Open order reminder",
      ORDER_AUTO_CLOSE_ORDER: "Order automatically closed",
      ORDER_CONFIRM_DELIVERY: "Order delivery confirmed",
      ORDER_ORDER_CLIENT_PRICE: "New Order was Created with Client Price",
      PRODUCT_IMPORT_PRODUCT_IMAGES: "Product Images imported - success: {{totalImport}} - failed: {{failedImport}}",
      PRODUCT_CALLBACK_UPDATE_PRODUCT: "Product updated on GS1 - gtin: {{gtin}} - gln: {{gln}}",
      PRODUCT_PRICE_CHECK_PRICE: "Check new price for product: {{productName}}",
      PRODUCT_PRICE_CHECK_PROPOSAL: "Check new price proposal for product: {{productName}}",
      PRODUCT_ACTION_ACCEPT_PRODUCT: "New product: {{productName}}",
      PRODUCT_ACTION_VALIDATE_PRODUCT: "Validate new product: {{productName}}",
      PRODUCT_ACTION_VALIDATE_PRODUCT_UPDATE: "Validate product update: {{productName}}",
      PRODUCT_ACTION_PRODUCT_ACCEPTED: "Product Accepted: {{productName}}",
      PRODUCT_ACTION_PRODUCT_REFUSED: "Product Refused: {{productName}}",
      PRODUCT_ACTION_PRODUCT_NOT_OK: "Product not ok: {{productName}}",
      PRODUCT_PRICE_ACCEPTED: "Product price accepted: {{productName}}",
      PRODUCT_PRICE_AUTO_ACCEPTED: "Product price updated: {{productName}}",
      PRODUCT_PRICE_REFUSED: "Product price refused: {{productName}}",
      PRODUCT_PROMOTER_PRODUCT_CREATED: "Product created: {{productName}}",
      PRODUCT_PROMOTER_PRODUCT_UPDATED: "Product updated: {{productName}}",
      PRODUCT_ACTION_PRODUCT_NOT_VALIDATED: "Product validation rejected: {{productName}}",
      PRODUCT_DOC_CHECK_DOC_EXP: "Product document near to expire.",
      ENTITY_DOC_CHECK_DOC_EXP: "Entity document near to expire.",
      PRODUCT_PURCHASE_CHECK_PURCHASE: "New special purchase created: {{productName}}",
      PRODUCT_PURCHASE_PROCESS_PURCHASE: "Special purchase accepted: {{productName}}",
      PRODUCT_PURCHASE_CHECK_PURCHASE_PROPOSAL: "Special purchase new proposal: {{productName}}",
      PRODUCT_PURCHASE_PURCHASE_REFUSED: "Special purchase refused: {{productName}}",
      PRODUCT_PURCHASE_PURCHASE_EXECUTED: "Special purchase executed: {{productName}}",
      PROMO_DISCOUNT_STATUS: "Promotional Discount Status",
      PROMO_DISCOUNT_CHECK_DPROM_EXP: "Promotional Discount near the end date",
      PROMO_DISCOUNT_ACTION_ACTION: "Promotional Discount Action",
      PROMO_DISCOUNT_ACTION_ACTION_REMOVE: "Promotional Discount Action Remove",
      PRODUCT_PRICE_ACTION_ACTION: "Product prices submited: {{newPricesCount}} to validate.",
      PRODUCT_PRICE_STATUS: "Product prices processed: {{acceptedCount}} accepted, {{refusedCount}} refused.",
      PRODUCT_PRICE_ACTION_ACTION_REMOVE: "Product price removed",
      TAX_ACTION_ACTION: "Product tax to validate",
      TAX_STATUS: "Product tax status changed",
      IMPORT_IMPORT_PRODUCT_IMAGES: "Product Images imported - success: {{totalImport}} - failed: {{failedImport}}",
      TASK_CHECK_TASK: "Task assigned: {{title}}",
      TASK_ACTION_PROCESS_TASK: "Task completed: {{title}}",
      TASK_ACTION_TASK_COMMENT: "Task with new comment: {{title}}",
      TASK_CHECK_TASK_DUE: "The task :{{title}} is approaching due date. Please check it out",
      TASK_CHECK_TASK_DUE_DATE: "The task :{{title}} is approaching due date. Please check it out",
    },
  },
};

export default alerts;

import { CheckListField, CheckboxField, FileUploadField, NumberField, TextFieldCommaArray } from "../../../../common/forms";

export const priceFields = ({ t, user }) => {
  const priceTypes = (["G", "D", "W"]).map(item => ({
    value: item,
    text: t("common.Enums.priceType." + item),
  }));
  return [
    {
      size: 4,
      field: (
        <FileUploadField
          id="technical.price.importTemplate"
          label={t("promoter.PromoterConfigs.form.importTemplatePrice")}
          placeholder={t("promoter.PromoterConfigs.form.importTemplatePricePlaceholder")}
          image
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: <CheckboxField id="functional.price.allowRetailPrice" label={t("promoter.PromoterConfigs.form.allowRetailPrice")} />,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: <CheckboxField id="functional.price.allowStandard" label={t("promoter.PromoterConfigs.form.allowStandard")} />,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: <CheckboxField id="functional.price.allowClientInput" label={t("promoter.PromoterConfigs.form.allowClientInput")} />,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <CheckboxField id="functional.price.allowPriceDiscount" label={t("promoter.PromoterConfigs.form.allowPriceDiscount")} />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: <CheckboxField id="functional.price.autoAcceptPrice" label={t("promoter.PromoterConfigs.form.autoAcceptPrice")} />,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: <CheckboxField id="technical.price.allowPricePromotions" label={t("promoter.PromoterConfigs.form.allowPricePromotions")} />,
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
    {
      size: 4,
      field: <CheckboxField id="technical.price.allowPriceCampaigns" label={t("promoter.PromoterConfigs.form.allowPriceCampaigns")} />,
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
    {
      size: 4,
      field: (
        <CheckListField
          id="technical.price.listCreationTypes"
          options={priceTypes}
          label={t("promoter.PromoterConfigs.form.priceListCreationTypes")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },

    {
      size: 12,
    },
    {
      size: 4,
      field: <CheckboxField id="functional.price.autoAcceptPurchase" label={t("promoter.PromoterConfigs.form.autoAcceptPurchase")} />,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: <CheckboxField id="functional.price.autoAcceptPromoDiscount" label={t("promoter.PromoterConfigs.form.autoAcceptPromoDiscount")} />,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: <CheckboxField id="functional.price.autoAcceptRemovePromoDiscount" label={t("promoter.PromoterConfigs.form.autoAcceptRemovePromoDiscount")} />,
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: (
        <NumberField
          id="functional.price.daysBeforeExpirePromoDiscount"
          label={t("promoter.PromoterConfigs.form.daysBeforeExpirePromoDiscount")}
          decimalScale={0}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: (
        <TextFieldCommaArray
          id="functional.price.allowedExtraPriceTypes"
          label={t("promoter.PromoterConfigs.form.allowedExtraPriceTypes")}
          decimalScale={0}
          note={t("promoter.PromoterConfigs.form.allowedExtraPriceTypesNote")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: <CheckboxField id="technical.price.allowExtraPriceStartDate" label={t("promoter.PromoterConfigs.form.allowExtraPriceStartDate")} />,
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
    {
      size: 4,
      field: <CheckboxField id="technical.price.allowExtraPriceVariants" label={t("promoter.PromoterConfigs.form.allowExtraPriceVariants")} />,
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
  ];
};

import { List, ListItem, Typography } from "@mui/material";
import CustomTooltip from "../../../../common/displays/CustomTooltip/CustomTooltip";
import ContactCardSlim from "../../../entities/_common/ContactCardSlim/ContactCardSlim";

export function listColumns({ t, usersList }) {
  let columns = [];

  columns.push({
    field: "designation",
    headerName: t("roles.RolesList.columns.designation"),
    align: "left",
    disableSort: true,
    headerAlign: "left",
    customValue: (v) => {
      return <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{v.designation}</Typography>
    }
  });

  columns.push({
    field: "association",
    headerName: t("roles.RolesList.columns.association"),
    align: "left",
    disableSort: true,
    headerAlign: "left",
    customValue: (v) => {
      if (v.user?.length > 0) {
        return <CustomTooltip title={
          <List>
            {(v?.user || []).map((user) => {
              let contact = usersList[user];
              return (
                <ListItem key={`user_${user}`}>
                  <ContactCardSlim contact={contact} />
                </ListItem>
              );
            })}
          </List>
        }>
          <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{`${t("roles.enum.associationType.USER")} (${v.user?.length})`}</Typography>
        </CustomTooltip>
      }
      if (v.group?.length > 0) {
        return <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{`${t("roles.enum.associationType.GROUP")} (${v.group?.length})`}</Typography>
      }
      if (v.sector?.length > 0) {
        return <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{`${t("roles.enum.associationType.SECTOR")} (${v.sector?.length})`}</Typography>
      }
      return <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{t("roles.enum.associationType.NONE")}</Typography>
    }
  });


  return columns;
}

import { Button } from "@mui/material";
import { MeasureField } from "../../../../common/components/formFields";
import { NumberField, SelectField, TextField } from "../../../../common/forms";

export function formFields({ item, t, values, promoter, classes, itemValue, onGtinChange, user, showGlnAndGtin, onGS1Check }) {
  // const glnGtinAvailable = (user?.entity?.type !== "P" && user?.entity?.gln) || (user?.entity?.type === "P" && values?.baseInfo?.gln !== values?.baseInfo?.ownerTaxNumber);
  const gtinOptions = (values?.hierarchy || [])
    .filter((item) => item.gtin !== itemValue.gtin)
    .map((item) => {
      return {
        value: item.gtin,
        text: item.gtin + " - " + item.description,
      };
    });
  const transportOptions = (promoter?.refData?.transport || []).map((item) => {
    return {
      value: item,
      text: t("common.Enums.transport." + item),
    };
  });
  // const parent = values?.hierarchy?.find((item) => item.gtin === values?.child?.childGtin);

  return [
    {
      size: 12,
      field: (
        <TextField
          id={`${item}.description`}
          type="text"
          label={t("products.ProductHierarchy.form.description")}
          disabled={itemValue.level === 0}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField
          id={`${item}.gtin`}
          type="text"
          label={showGlnAndGtin ? t("products.ProductHierarchy.form.gtin") : t("products.ProductHierarchy.form.internalCode")}
          disabled={itemValue.level === 0}
          valuesThatPreventChange={gtinOptions.map((item) => item.value)}
          autoValidate
          forceAutoValidate
          onChange={onGtinChange}
        />
      ),
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`${item}.unitDescriptor`}
          label={t("products.ProductHierarchy.form.unitDescriptor")}
          options={transportOptions}
          disabled={itemValue.level === 0}
        />
      ),
    },
    {
      size: 4,
      field: (
        <Button variant="contained" fullWidth style={{ marginTop: 34 }} onClick={onGS1Check}>{t("products.ProductHierarchy.form.gs1Btn")}</Button>
      ),
      hidden: itemValue.level === 0,
    },
    {
      size: 12,
      field: (
        <SelectField
          id={`${item}.child.childGtin`}
          type="text"
          label={t("products.ProductHierarchy.form.childGtin")}
          options={gtinOptions}
        />
      ),
      hidden: itemValue.level === 0,
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`${item}.child.childUnitDescriptor`}
          defaultValue={itemValue?.unitDescriptor}
          label={t("products.ProductHierarchy.form.childUnitDescriptor")}
          options={transportOptions}
        />
      ),
      hidden: itemValue.level === 0,
    },
    {
      size: 4,
      field: (
        <NumberField
          id={`${item}.child.childQuantity`}
          decimalScale={0}
          label={t("products.ProductHierarchy.form.childQuantity")}
        />
      ),
      hidden: itemValue.level === 0,
    },
    {
      size: 4,
    },
    {
      size: 4,
      field: (
        <NumberField
          id={`${item}.quantityOfLayers`}
          decimalScale={0}
          label={t("products.ProductHierarchy.form.quantityOfLayers")}
        />
      ),
      hidden: itemValue.level === 0,
    },
    {
      size: 4,
      field: (
        <NumberField
          id={`${item}.quantityOfItemsByLayer`}
          decimalScale={0}
          label={t("products.ProductHierarchy.form.quantityOfItemsByLayer")}
        />
      ),
      hidden: itemValue.level === 0,
    },
    {
      size: 4,
      hidden: itemValue.level === 0,
    },
    {
      size: 12,
      field: <div className={classes.divider}></div>,
    },
    {
      size: 4,
      field: (
        <MeasureField
          id={`${item}.height`}
          label={t("products.ProductHierarchy.form.height")}
          toLabel={t("products.ProductHierarchy.form.to")}
        />
      ),
      hidden: itemValue.level === 0,
    },
    {
      size: 4,
      field: (
        <MeasureField
          id={`${item}.width`}
          label={t("products.ProductHierarchy.form.width")}
          toLabel={t("products.ProductHierarchy.form.to")}
        />
      ),
      hidden: itemValue.level === 0,
    },
    {
      size: 4,
      field: (
        <MeasureField
          id={`${item}.depth`}
          label={t("products.ProductHierarchy.form.depth")}
          toLabel={t("products.ProductHierarchy.form.to")}
        />
      ),
      hidden: itemValue.level === 0,
    },
    {
      size: 4,
      field: (
        <MeasureField
          id={`${item}.netWeight`}
          label={t("products.ProductHierarchy.form.netWeight")}
          toLabel={t("products.ProductHierarchy.form.to")}
        />
      ),
      hidden: itemValue.level === 0,
    },
    {
      size: 4,
      field: (
        <MeasureField
          id={`${item}.grossWeight`}
          label={t("products.ProductHierarchy.form.grossWeight")}
          toLabel={t("products.ProductHierarchy.form.to")}
        />
      ),
      hidden: itemValue.level === 0,
    },
    {
      size: 4,
      field: (
        <MeasureField
          id={`${item}.netContent`}
          label={t("products.ProductHierarchy.form.netContent")}
          toLabel={t("products.ProductHierarchy.form.to")}
        />
      ),
      hidden: itemValue.level === 0,
    },
  ];
}

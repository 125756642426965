import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Stack } from "@mui/material";

import { styles } from "./TaskInputModal.styles";
import { formFields, formSchema } from "./TaskInputModal.schema";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";

import { Form, FormFields } from "../../../../common/forms";
import { usePromoter } from "../../../../common/hooks";

function TaskInputModal({ classes, open, setOpen, onSubmit, input, onInputRemove, selectedResourceInput, inputs }) {
  const { t, i18n } = useTranslation();
  const promoter = usePromoter();

  const onSubmitHandler = async (values) => {
    // console.log('values: %o', values);
    onSubmit && onSubmit({ ...values, parentId: selectedResourceInput?.id || values?.parentId });
  };

  return (
    <CustomModal open={open} setOpen={setOpen} size="large" height={600} title={t("businessUnits.TaskInputModal.modal.title")}>
      <div className={classes.root}>
        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t })}
          initialValues={input || {}}
          render={({ values, form }) => {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormFields fields={formFields({ t, promoter, i18n, values, inputs, selectedResourceInput, form, edit: Boolean(input) })} />
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={1}>
                    {input && <Button variant="contained" color="error" fullWidth onClick={() => onInputRemove(input)}>
                      {t("businessUnits.TaskInputModal.form.btnRemove")}
                    </Button>}
                    <Button variant="contained" color="primary" type="submit" fullWidth>
                      {t("businessUnits.TaskInputModal.form.btnSubmit")}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            );
          }}
        />
      </div>
    </CustomModal>
  );
}

export default withStyles(TaskInputModal, styles);

export const styles = (theme) => ({
  root: {},
  description: {
    display: "block",
    whiteSpace: "nowrap",
    margin: 0,
  },
  campaign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 24,
    width: 24,
    borderRadius: 12,
    backgroundColor: "#bfc5d2",
    padding: 4,
    "& svg": {
      height: 14,
    },
  },
  campaignMet: {
    backgroundColor: "#2e5bff",
    "& svg": {
      fill: "#FFF !important",
    },
  },
  outdated: {
    backgroundColor: theme.palette.background.error,
    "& svg": {
      fill: theme.palette.error.main + " !important",
    },
  },
  product: {
    fontSize: 10,
    margin: 0,
    lineHeight: 1,
  },
});

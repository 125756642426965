import * as Yup from "yup";
import React from "react";
import FileUploadField from "../../../../common/components/formFields/FileUploadField";

export function formFields(t) {

  return [
    {
      size: 4,
      field: <FileUploadField id={"file"} label={t("orders.ImportOrder.form.upload")} labelInput={t("orders.ImportOrder.form.uploadInput")} required />
    },
  ];
}

export function formSchema(t) {
  return Yup.object().shape({
   "file": Yup.array().ensure().min(1, t("orders.ImportOrder.form.requiredAtLeastOneFile")),
  });
}

export const styles = (theme) => ({
  root: {
    padding: 20,
  },
  paper: {
    padding: 20,
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  title: {
    margin: 0,
  },
});

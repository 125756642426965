import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { processUnitsImages } from "../_common/businessUnitsUtils";

const businessUnitListCall = async (
  { clearBusinessUnitList, appendToBusinessUnitList, setBusinessUnitListDetails },
  dispatch,
  state,
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_LIST");
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");

  dispatch(clearBusinessUnitList());
  dispatch(setBusinessUnitListDetails([]));
  try {
    let result = await call({
      type: "BUSINESS_UNIT",
      subType: "LIST",
      request: {
        limit: 500,
        offset: 0,
        sortField: "NAME",
        sortType: "ASC",
        search: '',
        type: [],
        entityId: null,
      },
    });
    // used to list businessUnits in /list
    dispatch(appendToBusinessUnitList(await processUnitsImages(fileStorageConfigs, result.items)));
    // used to obtain parentId options
    dispatch(setBusinessUnitListDetails(result.items));
  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitListCall;
import { dateTimeFormatWithMilliSeconds } from "../../../../common/utils/formats";

export function listColumns(t, classes) {
  return [
    {
      field: "type",
      headerName: t("business.audit.import.columns_type"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "subType",
      headerName: t("business.audit.import.columns_subtype"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "user",
      headerName: t("business.audit.import.columns_user"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "time",
      headerName: t("business.audit.import.columns_time"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.time));
      },
    },
    {
      field: "resultTime",
      headerName: t("business.audit.import.columns_resultTime"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (dateTimeFormatWithMilliSeconds(v.resultTime));
      },
    },
    {
      field: "status",
      headerName: t("business.audit.import.columns_status"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (v.linesWithError > 0 ? t("business.audit.import.columns_error_true") : t("business.audit.import.columns_error_false"));
      },
      disableSort: true,
    },
    {
      field: "totalLines",
      headerName: t("business.audit.import.columns_totalLines"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "linesWithError",
      headerName: t("business.audit.import.columns_linesWithError"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    }
  ];
}

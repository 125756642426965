import React from "react";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Permission from "../../../../app/components/Permission";
import { useUser } from "../../../../common/hooks";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectEntityDetails } from "../../entitiesSlice";

export default function EntitySectorGroupDataGridActions({ row, onEdit }) {
  const { t } = useTranslation();
  const user = useUser();
  const { id } = useParams();
  const details = useSelector(selectEntityDetails);

  return (
    <Grid container justifyContent="flex-end" spacing={1}>
      <Permission code="entities.actions.sectorGroup.update">
        {(user?.entity?.type === "P" || user?.entity?.id === id) && details?.status !== "PND" && 
          <Grid item>
            <Button color="primary" onClick={() => onEdit && onEdit(row)}>
              {t("entities.EntitySectorGroupsList.columns.edit")}
            </Button>
          </Grid>
        }
      </Permission>
    </Grid>
  );
}

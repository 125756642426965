import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import { listColumns } from "./DefaultRolesDataGrid.columns";
import { styles } from "./DefaultRolesDataGrid.styles";
import {useUser} from "../../../../common/hooks";


function DefaultRolesDataGrid({
  classes,
  data,
  totalRows,
  onRowSelected,
  onLoadMore,
  filter,
  onFilterChange,
  onEdit,
  defaultRoles
}) {
  const { t } = useTranslation();
  const user = useUser();
  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={listColumns(t, classes, defaultRoles, user)}
        rows={data || []}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMore}
        onRowSelected={onRowSelected}
        total={totalRows}
        onEdit={onEdit}
      />
    </div>
  );
}

export default withStyles(DefaultRolesDataGrid, styles)

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";

import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { styles } from "./RefDataTaxsList.styles";
import RefDataTaxsDataGrid from "./RefDataTaxsDataGrid/RefDataTaxsDataGrid";

import {
  selectVatList,
  selectVatListFilter,
  promoterListVat,
  promoterDisableVat,
  promoterEnableVat,
  selectLocationsList,
  promoterListLocations,
} from "../promoterSlice";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import Page from "../../../common/displays/Page/Page";

function RefDataTaxsList({ classes }) {
  const { t } = useTranslation();
  const data = useSelector(selectVatList);
  const filter = useSelector(selectVatListFilter);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locations = useSelector(selectLocationsList);

  useFirstLoad(() => {
    dispatch(promoterListVat(filter));
    if (!locations || locations.length === 0) {
      dispatch(promoterListLocations());
    }
  });

  const onFilterChangeHandler = (f) => {
    dispatch(promoterListVat(f));
  };

  const onDeleteHandler = ({ id }) => {
    dispatch(promoterDisableVat({ id }));
  };

  const onEnableHandler = ({ id }) => {
    dispatch(promoterEnableVat({ id }));
  };

  const onEditHandler = ({ id }) => {
    navigate("/promoter/configs/vats/edit/" + id)
  }

  return (
    <Page
      permission="promoter.actions.vat.list"
      header={<PageTitle
        title={t("promoter.RefDataTaxsList.header.title")}
        onBack={() => navigate("/promoter/configs")}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => navigate("/promoter/configs/vats/add")}
              >
                {t("promoter.RefDataTaxsList.header.addBtn")}
              </Button>
            </Grid>
          </Grid>
        }
      />}>
      <RefDataTaxsDataGrid
        data={data}
        filter={filter}
        onFilterChange={onFilterChangeHandler}
        onDelete={onDeleteHandler}
        onEnable={onEnableHandler}
        onEdit={onEditHandler}
        locations={locations}
      />
    </Page>
  );
}

export default withStyles(RefDataTaxsList, styles);

import * as Yup from "yup";
import { TextField } from "../../../../common/forms";

export function formSchema({ t }) {
  return Yup.object().shape({
    password: Yup.string().required(t('users.PasswordChangeForm.form.passwordRequired')),
    newPassword: Yup.string().required(t('users.PasswordChangeForm.form.newPasswordRequired'))
      .matches(process.env.REACT_APP_PASSWORD_PATTERN, t('users.PasswordChangeForm.form.passwordPattern')),
    newPasswordConfirm: Yup.string().required(t('users.PasswordChangeForm.form.newPasswordConfirmRequired')).oneOf(
      [Yup.ref("newPassword")],
      t("users.PasswordChangeForm.form.matchPassword")
    ),
  });
}

export const formFields = ({ t }) => {
  return [
    {
      size: 12,
      field: (
        <TextField
          id="password"
          type="password"
          label={t("users.PasswordChangeForm.form.password")}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextField
          id="newPassword"
          type="text"
          label={t("users.PasswordChangeForm.form.newPassword")}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextField
          id="newPasswordConfirm"
          type="text"
          label={t("users.PasswordChangeForm.form.newPasswordConfirm")}
        />
      ),
    },
  ];
};

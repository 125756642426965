
import * as Yup from "yup";
import moment from "moment";
import { promotionTypeOptions } from "./PromotionForm.options";
import { CardSelectField, DatePickerField, NumberField, TextField } from "../../../../common/forms";

export const formSchema = ({ t }) => {
  return Yup.object().shape({
    name: Yup.string().required(t('productsPrices.PromotionForm.form.nameRequired')),
    type: Yup.string().required(t('productsPrices.PromotionForm.form.typeRequired')),
    startDate: Yup.date()
      .min(moment().format("YYYY-MM-DDT00:00:00"), t('productsPrices.PromotionForm.form.startDateMin'))
      .required(t('productsPrices.PromotionForm.form.startDateRequired')),
    endDate: Yup.date()
      .test(
        'minEndTest',
        t('productsPrices.PromotionForm.form.endDateMin'),
        (value, context) => !value || moment(value).isSameOrAfter(moment(context?.parent?.startDate))
      ),
    percentage: Yup.string().not(["0"], t("productsPrices.PromotionForm.form.percentageRequired"))
      .when("type", {
        is: "PD",
        then: (schema) => schema.required(t('productsPrices.PromotionForm.form.percentageRequired')).test(
          "maxPercentage",
          t('productsPrices.PromotionForm.form.maxPercentage'),
          (value) => parseFloat(value) <= 100
        ),
        otherwise: (schema) => schema
      }),
    buyQuantity: Yup.string().when("type", {
      is: "BU",
      then: (schema) => schema.required(t('productsPrices.PromotionForm.form.buyQuantityRequired')),
      otherwise: (schema) => schema
    }),
    offerQuantity: Yup.string().when("type", {
      is: "BU",
      then: (schema) => schema.required(t('productsPrices.PromotionForm.form.offerQuantityRequired')),
      otherwise: (schema) => schema
    })
  });
}

export const formFields = ({ t, values }) => {
  return [
    {
      size: 12,
      field: <TextField
        id="name"
        label={t("productsPrices.PromotionForm.form.name")}
        required
      />,
    },
    {
      size: 12,
      field: (
        <CardSelectField
          id="type"
          label={t("productsPrices.PromotionForm.form.type")}
          options={promotionTypeOptions(t)}
          textAlign="center"
          required
          xs={6}
        />
      ),
    },
    {
      size: 6,
      field: <DatePickerField
        id="startDate"
        label={t("productsPrices.PromotionForm.form.startDate")}
        required
        minDate={moment()}
      />,
    },
    {
      size: 6,
      field: <DatePickerField
        id="endDate"
        label={t("productsPrices.PromotionForm.form.endDate")}
        minDate={values.startDate}
      />,
    },
    {
      size: 6,
      field: <NumberField
        id="percentage"
        label={t("productsPrices.PromotionForm.form.percentage")}
        required
        endAdornment="%"
      />,
      hidden: values.type !== "PD"
    },
    {
      size: 6,
      field: <NumberField
        id="buyQuantity"
        label={t("productsPrices.PromotionForm.form.buyQuantity")}
        endAdornment="UN"
        required
      />,
      hidden: values.type !== "BU"
    },
    {
      size: 6,
      field: <NumberField
        id="offerQuantity"
        label={t("productsPrices.PromotionForm.form.offerQuantity")}
        endAdornment="UN"
        required
      />,
      hidden: values.type !== "BU"
    },
  ];
}


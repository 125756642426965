import React from "react";
import { withStyles } from "tss-react/mui";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { styles } from "./DPromForm.styles";
import { formFields, formSchema } from "./DPromForm.schema";
import { Form, FormFields } from "../../../../common/forms";
import { useDispatch, useSelector } from "react-redux";
import { addTempDprom, updateTempDprom } from "../../productsDpromsSlice";
import { usePromoter, useUser } from "../../../../common/hooks";
import { selectFormFamilyList } from "../../../products/productsSlice";
import { selectAutoCompleteEntities } from "../../../entities/entitiesSlice";
import { selectLocations } from "../../../productsPrices/productsPricesSlice";


function DPromForm({ classes, dprom, onSubmit }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const promoter = usePromoter();
  const user = useUser();
  const families = useSelector(selectFormFamilyList);
  const entities = useSelector(selectAutoCompleteEntities);
  const locations = useSelector(selectLocations);

  const onSubmitHandler = async (values) => {
    let tempFamily = JSON.stringify(families?.find(item => item.id === values.family)?.description);
    if (dprom?.tempId) {
      dispatch(updateTempDprom({ ...values, status: "NEW", tempFamily }));
    }
    else {
      let ownerName = user?.entity?.type === "P" ? entities.find(item => item.entityId === values.ownerId)?.name : user?.entity?.name;
      dispatch(addTempDprom({ ...values, ownerName, status: "NEW", tempFamily }));
    }
    onSubmit && onSubmit();
  };

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmitHandler}
        schema={formSchema({ t })}
        keepDirtyOnReinitialize
        initialValues={dprom || { startDate: moment().toISOString(), value: { amount: "0", type: "P" }, ownerId: user?.entity?.type !== "P" ? user?.entity?.id : undefined }}
        render={({ values, form }) => {
          return (
            <div>
              <FormFields fields={formFields({ t, values, promoter, families, user, locations, form })} />
              <br />
              <Button variant="contained" type="submit" style={{ float: "right" }}>{dprom?.tempId ? t("productsDproms.DPromForm.form.updateBtn") : t("productsDproms.DPromForm.form.addBtn")}</Button>
            </div>
          );
        }}
      />

    </div>
  );
}

export default withStyles(DPromForm, styles);
export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  container: {
    maxWidth: 900,
  },
  name: {
    margin: 0,
  },
  title: {
    textTransform: "uppercase",
  },
  btn: {
    fontSize: 11,
    padding: 0,
    minWidth: 20,
  },
  details: {
    color: theme.palette.text.secondary,
    margin: 0,
  },
});

import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Alert, Button, Grid, Stack, Typography} from "@mui/material";

import {styles} from "./EntityDetails.styles";
import EntityUnits from "./EntityUnits/EntityUnits";
import EntityContacts from "./EntityContacts/EntityContacts";
import EntityAddresses from "./EntityAddresses/EntityAddresses";
import EntityExternalAccess from "./EntityExternalAccess/EntityExternalAccess";
import EntitySectorGroups from "./EntitySectorGroups/EntitySectorGroups";
import EntityInfo from "./EntityInfo/EntityInfo";

import {useFirstLoad, useNavigate, useUser} from "../../../common/hooks";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {
    disableEntity,
    enableEntity,
    entityGet,
    entityPendingEntityAction,
    selectEntityDetails,
    selectListFilter
} from "../entitiesSlice";
import Permission from "../../../app/components/Permission";
import Page from "../../../common/displays/Page/Page";
import ButtonWithConfirm from "../../../common/displays/ButtonWithConfirm/ButtonWithConfirm";
import ScrollNav from "../../../common/displays/ScrollNav/ScrollNav";
import EntityDocuments from "./EntityDocuments/EntityDocuments";
import EntityComments from "./EntityComments/EntityComments";

function EntityDetails({ classes, confirm, validation }) {
  const navigate = useNavigate();
  const { id, task } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const details = useSelector(selectEntityDetails);
  const user = useUser();
  const filter = useSelector(selectListFilter);

  useFirstLoad(() => {
    if (details?.entityId?.toString() !== id) {
      dispatch(entityGet(id));
    }
  });

  return details ? (
    <Page
      permission="entities.actions.entity.get"
      header={<PageTitle
        onBack={!confirm ? () => navigate("/entities") : undefined}
        title={details.name}
        subtitle={t("common.Enums.entityType." + details.type)}
        actions={
          <Grid container spacing={2} wrap="nowrap" direction="row">
              <Permission code="entities.actions.entity.listComments">
                  <Grid item>
                      <EntityComments entityId={id} />
                  </Grid>
              </Permission>
            {details.type === "LO" && user?.entity?.type === "P" && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => navigate(`/entities/${id}/operator-costs`)}
                >
                  {t("entities.Entities.details.operatorCosts")}
                </Button>
              </Grid>
            )}
            {user?.entity?.type === "P" && user?.entity?.id !== details?.entityId && (
              <Grid item>
                {details?.status === "ACT" && (
                  <Permission code="entities.actions.entity.disable">
                    <ButtonWithConfirm
                      buttonProps={{
                        size: "large"
                      }}
                      label={t("entities.Entities.confirmDisable.button")}
                      modalProps={{
                        title: t("entities.Entities.confirmDisable.title"),
                        confirmLabel: t("entities.Entities.confirmDisable.ok"),
                        cancelLabel: t("entities.Entities.confirmDisable.cancel"),
                        height: 200
                      }}
                      onConfirm={() => {
                        dispatch(disableEntity(filter, id));
                      }}
                    >
                      <Typography>{t("entities.Entities.confirmDisable.description")}</Typography>
                    </ButtonWithConfirm>
                  </Permission>
                )}
                {details?.status === "DIS" && (
                  <Permission code="entities.actions.entity.enable">
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => {
                        dispatch(enableEntity(filter, id));
                      }}
                    >
                      {t("entities.Entities.drawer.enableBtn")}
                    </Button>
                  </Permission>
                )}
              </Grid>
            )}

            {!confirm && (
              <Permission code={details?.businessUnitId === 0 ? "entities.actions.entity.update" : "entities.actions.businessUnit.create"}>
                {details?.status !== "PND" && ((user.permissionFilter("entities.filters.entity.update.onlySelf") && user?.entity?.id === id) || !user.permissionFilter("entities.filters.entity.update.onlySelf")) &&
                  <Grid item>
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={() => {
                        navigate(
                          details?.businessUnitId === 0 ? `/entities/edit/${id}` : `/entities/${id}/business-unit/edit`
                        );
                      }}
                    >
                      {t("entities.Entities.drawer.editBtn")}
                    </Button>
                  </Grid>
                }
              </Permission>
            )}

            <Permission code="entities.actions.entity.update">
              {details?.status === "PND" && confirm && user?.entity?.id === id && (
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => {
                      dispatch(entityPendingEntityAction({ status: "ACCEPTED", confirm, entityId: id }));
                    }}
                  >
                    {t("entities.Entities.details.confirmBtn")}
                  </Button>
                </Grid>
              )}
            </Permission>

            <Permission code="entities.actions.pendingEntity.action">
              {validation && (
                <Grid item>
                  <ButtonWithConfirm
                    buttonProps={{
                      size: "large",
                      color: "primary",
                    }}
                    label={t("entities.EntityDetails.header.acceptBtn")}
                    modalProps={{
                      title: t("entities.EntityDetails.confirmAccept.title"),
                      confirmLabel: t("entities.EntityDetails.confirmAccept.ok"),
                      cancelLabel: t("entities.EntityDetails.confirmAccept.cancel"),
                      height: 300
                    }}
                    commentProps={{
                      label: t("entities.EntityDetails.confirmAccept.comment"),
                    }}
                    onConfirm={(comment) => {
                      dispatch(entityPendingEntityAction({ status: "ACCEPTED", taskId: task, comment, entityId: id }));
                    }}
                  >
                    <Typography>{t("entities.EntityDetails.confirmAccept.description")}</Typography>
                  </ButtonWithConfirm>
                </Grid>
              )}
            </Permission>
            <Permission code="entities.actions.pendingEntity.action">
              {validation && (
                <Grid item>
                  <ButtonWithConfirm
                    buttonProps={{
                      size: "large",
                      color: "error",
                    }}
                    label={t("entities.EntityDetails.header.rejectBtn")}
                    modalProps={{
                      title: t("entities.EntityDetails.confirmReject.title"),
                      confirmLabel: t("entities.EntityDetails.confirmReject.ok"),
                      cancelLabel: t("entities.EntityDetails.confirmReject.cancel"),
                      height: 300
                    }}
                    commentProps={{
                      label: t("entities.EntityDetails.confirmReject.comment"),
                      required: true
                    }}
                    onConfirm={(comment) => {
                      dispatch(entityPendingEntityAction({ status: "REFUSED", taskId: task, comment, entityId: id}));
                    }}
                  >
                    <Typography>{t("entities.EntityDetails.confirmReject.description")}</Typography>
                  </ButtonWithConfirm>
                </Grid>
              )}
            </Permission>
          </ Grid>
        }
      />}>

      <Stack spacing={2}>
        {details?.status === "PND" &&
          <Alert variant="outlined" severity="info" className={classes.alert}>
            {t("entities.Entities.details.pendingValidation")}
          </Alert>
        }
        <ScrollNav
          tabs={[
            {
              id: "generalSection",
              title: t("entities.EntityDetails.tabs.generalTitle"),
              content: <EntityInfo entity={details} />,
            },
            {
              id: "businessUnitsSection",
              title: t("entities.EntityDetails.tabs.businessUnitsTitle"),
              content: <EntityUnits entity={details} disabled={confirm} />,
              hidden: details.type === "LO"
            },
            {
              id: "contactsSection",
              title: t("entities.EntityDetails.tabs.contactsTitle"),
              content: <EntityContacts contacts={details?.contacts} onMore={() => navigate(`/entities/${id}/contacts`)} disabled={confirm} />,
            },
            {
              id: "adressesSection",
              title: t("entities.EntityDetails.tabs.adressesTitle"),
              content: <EntityAddresses addresses={details?.addresses} onMore={() => navigate(`/entities/${id}/addresses`)} disabled={confirm} />
            },
            {
              id: "groupsSection",
              title: t("entities.EntityDetails.tabs.groupsTitle"),
              content: <EntitySectorGroups groups={details?.sectorsGroups} contacts={details?.contacts} entityId={id} disabled={confirm} />
            },
            {
              id: "documentsSection",
              title: t("entities.EntityDetails.tabs.documentsTitle"),
              content: <EntityDocuments documents={details?.documents} entityId={id} />
            },
            {
              id: "externalAccessSection",
              title: t("entities.EntityDetails.tabs.externalAccessTitle"),
              content: <EntityExternalAccess externalAccess={details?.externalAccess} entityId={details?.entityId} />,
              hidden: !user.hasPermission("entities.actions.entity.externalAccess") || !(((user?.entity?.type === "P" && user?.entity?.id !== details?.entityId) ||
                (user?.entity?.type !== "P" && user?.entity?.id === details?.entityId)) &&
                details?.status !== "PND" &&
                details?.businessUnitId === 0)
            },
          ]}
        />
        {/* <EntityInfo entity={details} />
        <Grid container>
          <Grid item xs={5}></Grid>
          <Grid item xs={7}>
            <div className={classes.divider}></div>
          </Grid>
        </Grid>
        {details.type !== "LO" && <EntityUnits entity={details} disabled={confirm} />}
        <div>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <EntityContacts contacts={details.contacts} onMore={() => navigate(`/entities/${id}/contacts`)} disabled={confirm} />
              <br />
              <Permission code="entities.actions.entity.externalAccess">
                {((user?.entity?.type === "P" && user?.entity?.id !== details?.entityId) ||
                  (user?.entity?.type !== "P" && user?.entity?.id === details?.entityId)) &&
                  details?.status !== "PND" &&
                  details?.businessUnitId === 0 && (
                    <EntityExternalAccess externalAccess={details?.externalAccess} entityId={details?.entityId} />
                  )}
              </Permission>
            </Grid>
            <Grid item xs={6}>
              <EntityAddresses addresses={details.addresses} onMore={() => navigate(`/entities/${id}/addresses`)} disabled={confirm} />
              <br />
              <EntitySectorGroups groups={details?.sectorsGroups} contacts={details?.contacts} entityId={id} disabled={confirm} />
            </Grid>
          </Grid>
        </div> */}
      </Stack>
    </Page>
  ) : null;
}

export default withStyles(EntityDetails, styles);

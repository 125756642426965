export function listColumns(t, classes) {
  return [
    {
      field: "lineId",
      headerName: t("orders.ImportOrder.column.lineId"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "success",
      headerName: t("orders.ImportOrder.column.success"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        if(typeof v.message === "string" && v.message === "importing") {
          return t("errors.import.order.importing")
        } else if (v.success) {
          return t("orders.ImportOrder.column.statusTrue");
        } else {
          return t("orders.ImportOrder.column.statusFalse");
        }
      },
    },
    {
      field: "message",
      headerName: t("orders.ImportOrder.column.message"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        if (v.message) {
          let messageList = "";
          if (Array.isArray(v.message)) {
            v.message.forEach(function (item) {
              messageList += t("errors.import.order." + item) + ", ";
            });
          } else if (typeof v.message === "string" && v.message !== "importing") {
            messageList += t("errors.import.order." + v.message);
          }
          return messageList;
        }
      },
    },
  ];
}

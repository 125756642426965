export const getResourceTypeOptions = (t, promoter) => {
  let resourceTypesAllowed = promoter?.getConfig("general", "resourceTypes") || [];
  return resourceTypesAllowed.map((item) => {
    return {
      value: item,
      text: t("common.Enums.resourceTypes." + item),
    };
  });
};

export const getPlaceOptions = (places) => {
  return (places || [])
    .filter(item => {
      return item.type === "A" || item.type === "R"
    })
    .map(({ id, title }) => {
      return {
        value: id, text: title
      }
    });
};

export const getParentOptions = (resources, type) => {
  // to change to cylinder
  return (resources || [])
    .filter(item => {
      return type === "C" && item.type === "T"
    })
    .map(({ id, title }) => {
      return {
        value: id, text: title
      }
    });
};

export const getTemperatureOptions = (t, promoter) => {
  return (promoter?.refData?.temperature || []).map((item) => {
    return {
      value: item,
      text: t("common.Enums.temperature." + item),
    };
  });
};

export const getStateOptions = (t) => {
  return ['A','I','U','M'].map((item) => {
    return {
      value: item,
      text: t("common.Enums.resourceStates." + item),
    };
  });
};
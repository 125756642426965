const operator = {
  flowsMessages: {
    success: {
      OPERATOR_DEFINE_COST: "Cost defined successfully!",
    },
    error: {
      GENERIC: "Something went wrong",
      WORKFLOW_VALIDATION_ERROR: "Invalid Request!",
    },
  },
  CostsForm: {
    form: {
      btnSave: "Save",
      tenseFlow: "Tense Flow",
      room: "Room",
      chilled: "Chilled",
      frozen: "Frozen",
      crossDocking: "Cross Docking",
      administrative: "Administrative",
      pallet: "Pallet",
      halfPallet: "Half Pallet",
      transport: "Transport",
      weight: "Weight",
    },
    transportTable: {
      from: "from",
      to: "to",
    },
  },
};

export default operator;

import {call} from "../../../app/websocket/_sliceCalls/websocket";
import {requestHelper} from "../../../app/coreSlice";

const placeList_fullCall = async (
  { setPlaceList },
  dispatch,
) => {
  let rh = requestHelper(dispatch, "PLACE_LIST");

  try {
    let result = await call({
      type: "PLACE",
      subType: "LIST",
      request: {
        limit: 10000,
        offset: 0,
        sortField: "TYPE",
        sortType: "ASC",
        active: true,
      },
    });
    dispatch(setPlaceList(result.items));
  } catch (ex) {
    rh.error("places", ex);
  }
  rh.close();
};

export default placeList_fullCall;
import React, { useMemo } from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Typography, Stack, Divider } from "@mui/material";

import { styles } from "./BatchDrawerDetails.styles";
import { useSelector } from "react-redux";
import { selectBatchDetails } from "../../batchesSlice";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import HatcheryProgress from "../../_common/HatcheryProgress/HatcheryProgress";
import StepDetails from "./StepDetails/StepDetails";

function BatchDrawerDetails({ classes, resources }) {
  // const { t } = useTranslation();
  const batchDetails = useSelector(selectBatchDetails);
  const { batchNumber, product } = (batchDetails || {})

  console.log('batchDetails: %o', batchDetails);
  console.log('product: %o', product);

  const progressValues = useMemo(() => {
    let values = [0, 0, 0, 0, 0, 0, 0];
    if (!resources || !batchDetails) {
      return values;
    }
    const res = batchDetails?.processedData?.resources || [];
    const validResourceIds = Object.keys(res).filter(id => parseFloat(res[id]?.quantity) > 0);
    const filteredResources = validResourceIds.map(id => ({
      ...resources.find(item => item.id === id) || {},
      quantity: parseFloat(res[id]?.quantity)
    }))
    filteredResources.forEach(item => {
      if (parseFloat(item.attributes?.mesh) < 150) {
        values[0] += item.quantity;
      }
      else if (parseFloat(item.attributes?.mesh) < 300) {
        values[1] += item.quantity;
      }
      else if (parseFloat(item.attributes?.mesh) < 500) {
        values[2] += item.quantity;
      }
      else if (parseFloat(item.attributes?.mesh) < 1000) {
        values[3] += item.quantity;
      }
      else if (parseFloat(item.attributes?.mesh) < 1500) {
        values[4] += item.quantity;
      }
      else if (parseFloat(item.attributes?.mesh) < 2400) {
        values[5] += item.quantity;
      }
      else {
        values[6] += item.quantity;
      }
    })
    return values;
  }, [batchDetails, resources]);

  const distribution = batchDetails?.processedData?.distribution || {};

  return (
    <div className={classes.root}>
      <Stack spacing={2}>
        <ProductSnip image={product?.thumbnail} description={product?.description} gtin={product?.gtin} />
        <Divider />
        <Typography variant="h4">{batchNumber}</Typography>
        <HatcheryProgress values={progressValues} />
        <Stack>
          {Object.keys(distribution).map((id, index) => {
            let step = distribution[id];
            return (
              <div key={`step_${id}`}>
                <StepDetails
                  title={id}
                  date={step?.startTime}
                  tasks={{ completed: step?.tasks?.closedTasks, toComplete: step?.tasks?.openTasks }}
                  completed={!step?.tasks?.openTasks}
                  inprogress={step?.tasks?.closedTasks > 0}
                  isLast={index + 1 === Object.keys(distribution).length}
                />
              </div>
            )
          })}
        </Stack>

      </Stack>
    </div>
  );
}

export default withStyles(BatchDrawerDetails, styles);


import React from "react";
import { withStyles } from "tss-react/mui";
import { Field } from "react-final-form";
import MetaError from "../MetaError";
import { styles } from "../fields/_base.styles";
import { MultiLangTextInput } from "../../inputs/custom";

function MultiLangTextField({
  classes,
  id,
  type,
  label,
  disabled,
  required,
  onChange,
  autoValidate = false,
  forceAutoValidate = false,
  rows,
  maxLength,
  languages,
  defaultLanguage
}) {
  const onChangeHandler = (v, input) => {
    input.onChange && input.onChange(v);
    onChange && onChange(v);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => {
          return (
            <>
              <MultiLangTextInput
                className={classes.input}
                id={id}
                label={label}
                type={type || "text"}
                value={input.value}
                onChange={(value) => onChangeHandler(value, input)}
                disabled={disabled}
                required={required}
                error={Boolean(meta.error && (meta.touched || (autoValidate && (meta.modified || forceAutoValidate))))}
                variant={"outlined"}
                rows={rows}
                maxLength={maxLength}
                languages={languages}
                defaultLanguage={defaultLanguage}
              />
              <div className={classes.error}>
                <MetaError meta={meta} autoValidate={autoValidate} forceAutoValidate={forceAutoValidate} />
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
}

export default withStyles(MultiLangTextField, styles);




import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";


const businessGetAccountingDocCall = async ({ setAccountingDetails }, dispatch, { id }) => {
  let rh = requestHelper(dispatch, "GET_ACCOUNTING_DOC");
  try {
    let list = await call({
      type: "BUSINESS",
      subType: "GET_ACCOUNTING_DOC",
      request: {
        id
      },
    });

    if (list && list.document) {
      dispatch(setAccountingDetails(list.document));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default businessGetAccountingDocCall;

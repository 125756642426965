import React from "react";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Button, Grid, Stack} from "@mui/material";

import {styles} from "./TaskAddUserModal.styles";
import {formFields, formSchema} from "./TaskAddUserModal.schema";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";

import {Form, FormFields} from "../../../../common/forms";

function TaskAddUserModal({ classes, open, setOpen, onSubmit }) {
  const { t } = useTranslation();

  const onSubmitHandler = async (values) => {
    onSubmit && onSubmit(values.user);
  };

  return (
    <CustomModal open={open} setOpen={setOpen} size="medium" height={265} title={ t("business.process.TaskAddUserModal.title")}>
      <div className={classes.root}>
        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t })}
          initialValues={{}}
          render={({ values }) => {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormFields fields={formFields({ t })} />
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <Button variant="contained" color={"primary"} type="submit" fullWidth>
                      {t("business.process.TaskAddUserModal.btnSubmit")}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            );
          }}
        />
      </div>
    </CustomModal>
  );
}

export default withStyles(TaskAddUserModal, styles);

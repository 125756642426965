import {currencyFormat} from "../../../../common/utils";

export function listColumns(t, classes) {
  return [
    {
      field: "sellerName",
      headerName: t("business.reports.WeeklyUnitsDelivered.columns_sellerName"),
      align: "left",
      headerAlign: "left",
    },{
      field: "buyerName",
      headerName: t("business.reports.WeeklyUnitsDelivered.columns_buyerName"),
      align: "left",
      headerAlign: "left",
    },{
      field: "deliveryPlace",
      headerName: t("business.reports.WeeklyUnitsDelivered.columns_deliveryPlace"),
      align: "left",
      headerAlign: "left",
    },{
      field: "totalTransportUnits",
      headerName: t("business.reports.WeeklyUnitsDelivered.columns_totalTransportUnits"),
      align: "left",
      headerAlign: "left",
    },{
      field: "price",
      headerName: t("business.reports.WeeklyUnitsDelivered.columns_price"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return currencyFormat(v.price);
      },
    },


  ];
}

export const styles = (theme) => ({
  root: {
    position: "relative",
  },
  hightLightRow: {
    backgroundColor: "#fffbb8",
  },
  ACC: {
    backgroundColor: theme.palette.success.back,
  },
  REF: {
    backgroundColor: theme.palette.error.back,
  },
  CAN: {
    backgroundColor: theme.palette.error.back,
  },
  END: {
    backgroundColor: theme.palette.error.back,
  },
  REM: {
    backgroundColor: theme.palette.error.back,
  },
  PND: {
    backgroundColor: theme.palette.warning.back,
  },
  NEW: {
    outline: "1px solid " + theme.palette.info.main,
    outlineOffset: "-1px",
    borderRadius: "5px"
  }
});

export const styles = (theme) => ({
  root: {},
  imagesWrapper: {
    border: "1px solid #D2D9E4",
    borderRadius: 4,
    minHeight: 160,
  },
  image: {
    padding: theme.spacing(2),
    position: "relative",
    "&:hover .MuiIconButton-root": {
      display: "block",
    },
  },
  img: {
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: 160,
  },
  delete: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "none",
  },
  default: {
    position: "absolute",
    top: 0,
    right: 0,
    display: "none",
  },
});

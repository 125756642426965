import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./EmailsList.styles";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import { useDispatch, useSelector } from "react-redux";
import { notificationListEmail, selectEmailList } from "../notificationSlice.calls";
import EmailsDataGrid from "./EmailsDataGrid/EmailsDataGrid";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { Typography } from "@mui/material";
import Page from "../../../common/displays/Page/Page";

function EmailsList({ classes }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(selectEmailList);

  useFirstLoad(() => {
    dispatch(notificationListEmail());
  });

  const onRowSelectedHandler = (row) => {
    navigate("/emails/" + row.id);
  };

  return (
    <Page
      permission="promoter.actions.email.list"
      header={<PageTitle title={t("emails.list.header.title")} />}>
      <Typography component="span" variant="caption" htmlFor="entityAutocomplete" color="textPrimary">
        {t("emails.list.header.table")}
      </Typography>
      <EmailsDataGrid data={data} totalRows={data?.length} onRowSelected={onRowSelectedHandler} />
    </Page>
  );
}

export default withStyles(EmailsList, styles);

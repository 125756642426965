import { Chip, Grid, Tooltip, Typography } from "@mui/material";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import MessageIcon from '@mui/icons-material/Message';
import DateTime from "../../../../common/displays/DateTime/DateTime";

export function listColumns({ t, user }) {
  return [
    {
      field: "description",
      headerName: t("products.ProductValidationList.columns.description"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return <ProductSnip image={v.thumbnail} description={v.description} gtin={v.gtin} promoterInternalCode={v.promoterInternalCode} />;
      },
    },
    {
      field: "ownerName",
      headerName: t("products.ProductValidationList.columns.owner"),
      align: "center",
      headerAlign: "center",
      customValue: (v) => {
        return (
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography>{v.ownerName}</Typography>
            </Grid>
            {v.externalSource && (
              <Grid item>
                <Chip size="small" label={t("products.ProductValidationList.columns.external")} />
              </Grid>
            )}
          </Grid>
        );
      },
    },
    {
      field: "date",
      headerName: t("products.ProductValidationList.columns.date"),
      align: "center",
      headerAlign: "center",
      customValue: (v) => {
        return <DateTime date={v.date} />
      },
    },
    {
      field: "processType",
      headerName: t("products.ProductValidationList.columns.processType"),
      align: "center",
      headerAlign: "center",
      disableSort: true,
      customValue: (v) => {
        return v.processType === "FlowUpdateProduct"
          ? <Chip size="small" label={t("products.ProductValidationList.columns.valueUpdate")} />
          : <Chip size="small" color="primary" label={t("products.ProductValidationList.columns.valueCreate")} />
      },
    },
    {
      field: "status",
      headerName: t("products.ProductValidationList.columns.status"),
      align: "center",
      headerAlign: "center",
      customValue: (v) => {
        return (v.comment && (user?.entity?.type === "P" || v.status === "REFUSED" || v.status === "NOT_VALIDATED" || v.status === "NOT_OK")) ? (
          <Tooltip title={v.comment}>
            <Grid container spacing={1} justifyContent="center" alignItems="center" wrap="nowrap">
              <Grid item>
                <Typography>{t("common.Enums.productWorkflow." + v.status)}</Typography>
              </Grid>
              <Grid item>
                <MessageIcon fontSize="small" style={{ display: "inline" }} />
              </Grid>
            </Grid>
          </Tooltip>
        ) : (
          <Typography>{t("common.Enums.productWorkflow." + v.status)}</Typography>
        );
      },
    },
  ];
}

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setRedirect, setSuccess } from "../../../app/coreSlice";

const orderDeliveryWithoutOperatorCall = async (
  dispatch,
  { orderId, orderSellerId, dispatchId, document, documentDate, receivedBy, finished }
) => {
  let rh = requestHelper(dispatch, "DELIVERY_ORDER");
  try {
    await call({
      type: "ORDER",
      subType: "DELIVERY",
      request: {
        orders: [
          {
            orderId,
            orderSellerId,
            dispatchId,
            finished,
            receivedBy,
            document,
            documentDate: documentDate.format("YYYY-MM-DDTHH:mm:ss"),
            allProducts: true,
          },
        ],
      },
    });
    setTimeout(() => {
      dispatch(setRedirect(`/orders/placed`));
      dispatch(setSuccess("orders", "ORDER_DELIVERY"));
    }, 500);
  } catch (ex) {
    rh.error("orders", ex);
  }
  rh.close();
};

export default orderDeliveryWithoutOperatorCall;

export const styles = (theme) => ({
  root: {},
  qty: {
    width: 90,
  },
  input: {
    maxWidth: 90,
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: 4,
      paddingBottom: 4,
      backgroundColor: "transparent",
    },

    "& input": {
      textAlign: "right",
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
  btnAdd: {
    minWidth: 30,
  },
  lbl: {
    fontWeight: "normal",
  },
});

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Checkbox, Divider, Grid, IconButton, Typography } from "@mui/material";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";

import { styles } from "./DocumentsDiff.styles";
import LabelValue from "../../../../../common/displays/LabelValue/LabelValue";
import { dateFormat } from "../../../../../common/utils";

function DocumentsDiff({ classes, value, newValue, hasDiff, onCheck, checked, viewMode }) {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container direction="column">
        <Grid item
          className={classes.headerContainer}>
          <Typography variant="h4" className={classes.header}>
            {t("products.ProductValidationForm.section.documents")}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2} wrap="nowrap">
            <Grid item flex={1}>
              <Grid container spacing={2} justifyContent="space-between" direction="column">
                {(value || []).map((item, index) => (
                  <Grid item key={`documents_v_${index}`}>
                    <Grid container spacing={2} alignItems="center">
                      {index > 0 && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                      <Grid item xs={3} style={{ textAlign: "center" }}>
                        <IconButton disableRipple size="large" href={item.url} rel="noreferrer" target="_blank">
                          <Grid container direction="column">
                            <Grid item>
                              <FileOpenOutlinedIcon fontSize="large" color="action" />
                            </Grid>
                            <Grid item>
                              <Typography variant="caption">{t(`common.Enums.documentType.${item.type}`)}</Typography>
                            </Grid>
                          </Grid>
                        </IconButton>
                      </Grid>
                      {item?.metadata && (
                        <Grid item xs={9}>
                          <Grid container spacing={1}>
                            {Object.keys(item?.metadata).map((meta) => {
                              let value = item?.metadata?.[meta];
                              if (meta === "EXD" || meta === "RCD") {
                                value = dateFormat(value);
                              }
                              return (
                                <Grid item xs={6} key={`documents_meta_${index}_${meta}`}>
                                  <LabelValue label={t(`common.Enums.documentMetadata.${meta}`)} value={value} />
                                </Grid>
                              )
                            })}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item flex={1}>
            <Grid container spacing={2} justifyContent="space-between" direction="column">
                {(newValue || []).map((item, index) => (
                  <Grid item key={`documents_nv_${index}`}>
                    <Grid container spacing={2} alignItems="center">
                      {index > 0 && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                      <Grid item xs={3} style={{ textAlign: "center" }}>
                        <IconButton disableRipple size="large" href={item.url} rel="noreferrer" target="_blank">
                          <Grid container direction="column">
                            <Grid item>
                              <FileOpenOutlinedIcon fontSize="large" color="action" />
                            </Grid>
                            <Grid item>
                              <Typography variant="caption">{t(`common.Enums.documentType.${item.type}`)}</Typography>
                            </Grid>
                          </Grid>
                        </IconButton>
                      </Grid>
                      {item?.metadata && (
                        <Grid item xs={9}>
                          <Grid container spacing={1}>
                            {Object.keys(item?.metadata).map((meta) => {
                              let value = item?.metadata?.[meta];
                              if (meta === "EXD" || meta === "RCD") {
                                value = dateFormat(value);
                              }
                              return (
                                <Grid item xs={6} key={`documents_meta_${index}_${meta}`}>
                                  <LabelValue label={t(`common.Enums.documentMetadata.${meta}`)} value={value} />
                                </Grid>
                              )
                            })}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item style={{ width: 56 }}>
              {hasDiff && !viewMode && (
                <Checkbox color="success" checked={checked} onChange={(e) => onCheck(e.target.checked)} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(DocumentsDiff, styles);



import { requestHelper } from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";

const auditGetAuditImportCall = async ({ setAuditImportDetails }, dispatch, { id }) => {
  let rh = requestHelper(dispatch, "GET_AUDIT_IMPORT");
  try {
    let result = await call({
      type: "AUDIT",
      subType: "GET_AUDIT_IMPORT",
      request: {
        id
      },
    });

    if (result && result.audit) {
      dispatch(setAuditImportDetails(result.audit));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default auditGetAuditImportCall;

import React from "react";
import { usePromoter } from "../../hooks";
import NumberField from "../fields/NumberField";

function CurrencyField(props) {
  const promoter = usePromoter();
  let nProps = {};
  switch (promoter?.getConfig("general", "defaultCurrency")) {
    case "USD":
      nProps = {
        ...props,
        startAdornment: "$",
        decimalScale: 2,
        textAlign: "left",
      };
      break;
    default:
      nProps = {
        ...props,
        endAdornment: "€",
        decimalScale: 2,
        textAlign: "right",
      };
      break;
  }
  return <NumberField {...nProps} />;
}

export default CurrencyField;

export const csvFields = (t) => {
  return [
    {
      field: "id",
      header: t("orders.AggregatedOrders.columns.id"),
    },{
      field: "description",
      header: t("orders.AggregatedOrders.columns.description"),
    },{
      field: "address",
      header: t("orders.AggregatedOrders.columns.address"),
    },
    {
      field: "numberOfProducts",
      header: t("orders.AggregatedOrders.columns.numberOfProducts"),
    },
    {
      field: "price",
      header: t("orders.AggregatedOrders.columns.price"),
    },
    {
      field: "status",
      header: t("orders.AggregatedOrders.columns.status"),
    },
  ];
}
import * as Yup from "yup";
import { TextField } from "../../../../common/forms";
import React from "react";
import ResourceGroupField from "./ResourceGroupField/ResourceGroupField";
import { getTaskInputField } from "../../_common/auxiliars";

export function formSchema({ t, inputs }) {
  return Yup.object().shape({
  });
}

export function formFields({ i18n, inputs, disabled, view, task, resources, places }) {

  // console.log('inputs: %o', inputs);
  let fields = [];

  (inputs || []).filter(item => !item.parentId).forEach(input => {
    if (input.type === "resource") {
      fields.push({
        size: 12,
        field: <ResourceGroupField input={input} view={view} fields={(inputs || []).filter(item => item.parentId === input.id)} disabled={disabled} task={task} resources={resources} places={places} />
      });
    }
    else {
      fields.push({
        size: 12,
        field: getTaskInputField({ i18n, input, disabled, id: "payload.fields" })
      });
    }
  });


  return fields;
}

export function formFieldsComments({ t, disabled }) {

  return [
    {
      size: 12,
      field: <TextField
        id="comment"
        rows={2}
        required
        label={t("tasks.TasksForm.form.comment")}
        disabled={disabled}
      />,
    },
  ];
}



import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import ActionsPopover from "../../../../common/components/ActionsPopover/ActionsPopover";
import Permission from "../../../../app/components/Permission";

export default function PlanningListDataGridActions({ row, onRemove, onEdit, onCopy }) {
  const { t } = useTranslation();

  const onEditHandler = (e) => {
    e.preventDefault();
    onEdit && onEdit(row);
  };

  const onCopyHandler = (e) => {
    e.preventDefault();
    onCopy && onCopy(row);
  };

  const onRemoveHandler = (e) => {
    e.preventDefault();
    onRemove && onRemove(row);
  };

  let actionsButtons = [];

  // EDIT
  actionsButtons.push(<Permission code="planning.actions.production.update">
    <Button onClick={onEditHandler} fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item style={{ display: "flex" }}>
          <EditIcon />
        </Grid>
        <Grid item>{t("planning.PlanningList.actions.edit")}</Grid>
      </Grid>
    </Button>
  </Permission>);

  // COPY
  actionsButtons.push(<Permission code="planning.actions.production.create">
    <Button onClick={onCopyHandler} fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item style={{ display: "flex" }}>
          <ContentCopyIcon />
        </Grid>
        <Grid item>{t("planning.PlanningList.actions.copy")}</Grid>
      </Grid>
    </Button>
  </Permission>);

  // DELETE
  if (!row.defaultPlan) {
    actionsButtons.push(<Permission code="planning.actions.production.delete">
      <Button onClick={onRemoveHandler} color="error" fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <DeleteIcon />
          </Grid>
          <Grid item>{t("planning.PlanningList.actions.remove")}</Grid>
        </Grid>
      </Button>
    </Permission>);
  }

  return (
    <Grid container spacing={1} justifyContent="flex-end" alignItems="center" wrap="nowrap">
      {actionsButtons.length > 0 &&
        <Grid item>
          <ActionsPopover
            actions={actionsButtons}
          />
        </Grid>
      }
    </Grid>
  );
}

import React, { useMemo } from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./PlaceListItem.styles";
import RoomCard from "./RoomCard/RoomCard";

function PlaceListItem({ classes, item }) {
  const processResults = useMemo(() => {
    if (item.placeType === "B") {
      let items = [...(item.places || [])];
      return items.sort((a, b) => (a?.placeName || "").localeCompare(b?.placeName || "")).map(room => ({ ...room, buildingName: item.placeName }))
    }
    return [];
  }, [item]);

  return (
    <div className={classes.root}>
      {processResults.filter(item => item.places?.length > 0).map(item =>
        <RoomCard key={item.placeId} placeName={item.placeName} buildingName={item.buildingName} places={item.places} />
      )}
    </div>
  );
}

export default withStyles(PlaceListItem, styles);
const errors = {
  import: {
    product: {
      MISSING_OWNER_GLN: "Missing owner gln",
      PRODUCT_NOT_IN_SYSTEM: "Product not in system",
      MISSING_PRICE_AT_LEAST_ONE: "Missing price at least one",
      MISSING_OWNER_ADDRESS: "Missing owner address",
      INVALID_OWNER_ADDRESS: "Invalid owner address",
      INVALID_OWNER: "Invalid owner",
      MISSING_PRODUCT_GTIN: "Missing product gtin",
      MISSING_PRODUCT_DESCRIPTION: "Missing product description",
      MISSING_PRODUCT_UNIT_DESCRIPTOR: "Missing product unit descriptor",
      MISSING_PRODUCT_UNIT_CONSUMER: "Missing product unit consumer",
      MISSING_PRODUCT_TRANSPORT_UNIT: "Missing product transport unit",
      MISSING_PRODUCT_VAT: "Missing product vat",
      MISSING_PRODUCT_ACTIVE: "Missing product active",
      MISSING_PRODUCT_AVAILABLE: "Missing product available",
      MISSING_INFO_BATCH_NUMBER: "Missing info batch number",
      MISSING_INFO_EXPIRATION_DATE: "Missing info expiration date",
      MISSING_INFO_EXPIRATION_WEIGHT: "Missing info expiration weight",
      MISSING_PACK_QUANTITY: "Missing pack quantity",
      MISSING_PACK_GTIN: "Missing pack gtin",
      MISSING_CASE_QUANTITY: "Missing case quantity",
      MISSING_CASE_GTIN: "Missing case gtin",
      MISSING_PALLET_QUANTITY: "Missing pallet quantity",
      MISSING_PALLET_GTIN: "Missing pallet gtin",
      importing: "Importing...",
    },
    order: {
      MISSING_PRODUCT_OWNER: "Missing product owner",
      MISSING_PRODUCT: "Missing product",
      MISSING_QUANTITY: "Missing quantity",
      INVALID_UNIT: "Invalid unit",
      PRODUCT_NOT_DEFINED: "Product not defined",
      MORE_THAN_ONE_PRODUCT: "More than one product",
      PRODUCT_NOT_FROM_OWNER: "Product not from owner",
      PRODUCT_NOT_ACTIVE: "Product not active",
      PRODUCT_NOT_AVAILABLE: "Product not available",
      INVALID_MINIMUM_QUANTITY: "Invalid minimum quantity",
      INVALID_MAXIMUM_QUANTITY: "Invalid maximum quantity",
      INVALID_MULTIPLE_QUANTITY: "Invalid multiple quantity",
      PROMOTER_DONT_ALLOW_CLIENT_PRICE: "Client price not allowed",
      importing: "Importing...",
    },
  },
};

export default errors;

export const csvFields = (t) => {
  return [
    {
      field: "orderSellerId",
      header: t("business.history.OrdersMovementList.columns_orderSellerId"),
    }, {
      field: "sellerTax",
      header: t("business.history.OrdersMovementList.columns_sellerTax"),
    },
    {
      field: "sellerName",
      header: t("business.history.OrdersMovementList.columns_sellerName"),
    }, {
      field: "buyerTax",
      header: t("business.history.OrdersMovementList.columns_buyerTax"),
    }, {
      field: "buyerName",
      header: t("business.history.OrdersMovementList.columns_buyerName"),
    },
    {
      field: "address",
      header: t("business.history.OrdersMovementList.columns_address"),
    },
    {
      field: "updatedTime",
      header: t("business.history.OrdersMovementList.columns_updatedTime"),
    },{
      field: "updatedBy",
      header: t("business.history.OrdersMovementList.columns_updatedBy"),
    }
  ];
}
import DateTime from "../../../../common/displays/DateTime/DateTime";
import { Grid, Typography } from "@mui/material";

export function listColumns(t, classes) {
  return [
    {
      field: "orderSellerId",
      headerName: t("business.history.OrdersMovementList.columns_orderSellerId"),
      align: "left",
      headerAlign: "left",
    },
   /*{
      field: "description",
      headerName: t("business.history.OrdersMovementList.columns_description"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
              <Grid
                  item
                  style={{
                    width: 60,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
              >
                {v.thumbnail ? (
                    <img
                        style={{ maxWidth: 44, maxHeight: 40 }}
                        alt={v.description}
                        src={v.thumbnail}
                    />
                ) : (
                    <PicturePlaceholder />
                )}
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography className={classes.nomargin}>
                      {v.description}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                        color="textSecondary"
                        className={classes.nomargin}
                    >
                      {v.gtin}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        );
      },
    },*/ {
      field: "sellerName",
      headerName: t("business.history.OrdersMovementList.columns_sellerName"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.nomargin}>
                    {v.sellerName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    className={classes.nomargin}
                  >
                    {v.sellerTax}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      },
    }, {
      field: "buyerName",
      headerName: t("business.history.OrdersMovementList.columns_buyerName"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.nomargin}>
                    {v.buyerName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    className={classes.nomargin}
                  >
                    {v.buyerTax}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      },
    }/*,{
          field: "sellerTax",
          headerName: t("business.history.OrdersMovementList.columns_sellerTax"),
          align: "left",
          headerAlign: "left"
      }, {
          field: "sellerName",
          headerName: t("business.history.OrdersMovementList.columns_sellerName"),
          align: "left",
          headerAlign: "left"
      },{
          field: "buyerTax",
          headerName: t("business.history.OrdersMovementList.columns_buyerTax"),
          align: "left",
          headerAlign: "left"
      }, {
          field: "buyerName",
          headerName: t("business.history.OrdersMovementList.columns_buyerName"),
          align: "left",
          headerAlign: "left"
      }*/,
    {
      field: "address",
      headerName: t("business.history.OrdersMovementList.columns_address"),
      align: "left",
      headerAlign: "left",
    }/*,{
      field: "documentNumber",
      headerName: t("business.history.OrdersMovementList.columns_documentNumber"),
      align: "left",
      headerAlign: "left",
    },{
      field: "orderId",
      headerName: t("business.history.OrdersMovementList.columns_orderId"),
      align: "left",
      headerAlign: "left",
    },{
      field: "lineId",
      headerName: t("business.history.OrdersMovementList.columns_lineId"),
      align: "left",
      headerAlign: "left",
    },{
      field: "dispatchId",
      headerName: t("business.history.OrdersMovementList.columns_dispatchId"),
      align: "left",
      headerAlign: "left",
    },{
      field: "quantity",
      headerName: t("business.history.OrdersMovementList.columns_quantity"),
      align: "left",
      headerAlign: "left",
    },{
      field: "totalTransportUnits",
      headerName: t("business.history.OrdersMovementList.columns_totalTransportUnits"),
      align: "left",
      headerAlign: "left",
    },{
      field: "status",
      headerName: t("business.history.OrdersMovementList.columns_status"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return t("common.Enums.orderStatus." + v.status);
      },
    }*/, {
      field: "updatedBy",
      headerName: t("business.history.OrdersMovementList.columns_updatedBy"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return (
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.nomargin}>
                    {v.updatedBy}
                  </Typography>
                </Grid>
                <Grid item>
                  <DateTime date={v.updatedTime} inline textColor="textSecondary" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      },
    }


  ];
}

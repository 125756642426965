import { createSlice } from "@reduxjs/toolkit";
import productListFamilyCall from "./_sliceCalls/productListFamilyCall";
import productCreateFamilyCall from "./_sliceCalls/productCreateFamilyCall";
import productDisableFamilyCall from "./_sliceCalls/productDisableFamilyCall";
import productUpdateFamilyCall from "./_sliceCalls/productUpdateFamilyCall";


export const productsFamiliesSlice = createSlice({
  name: "productsFamilies",
  initialState: {
    familyList: null,
    familyListTotalRows: 0,
    familyListFilter: {
      limit: 10000,
      offset: 0,
      search: null,
      active: true,
    },
    selectedFamilyId: null
  },
  reducers: {
    clearFamilyList: (state) => {
      state.familyList = [];
    },

    appendToFamilyList: (state, action) => {
      state.familyList = [...(state.familyList || []), ...action.payload];
    },

    setFamilyListTotalRows: (state, action) => {
      state.familyListTotalRows = action.payload;
    },

    setFamilyListFilter: (state, action) => {
      state.familyListFilter = action.payload;
    },

    setSelectedFamilyId: (state, action) => {
      state.selectedFamilyId = action.payload;
    },
  },
});

export default productsFamiliesSlice.reducer;

// SELECTS
export const selectFamilyListTotalRows = (state) => state.productsFamilies.familyListTotalRows;
export const selectFamilyListFilter = (state) => state.productsFamilies.familyListFilter;
export const selectFamilyList = (state) => state.productsFamilies.familyList;
export const selectSelectedFamilyId = (state) => state.productsFamilies.selectedFamilyId;

// CALLS
export const productListFamily = (filter, clear = false) => (dispatch) => {
  productListFamilyCall(productsFamiliesSlice.actions, dispatch, { filter, clear });
};

export const productCreateFamily = ({ id, description, type, parent }) => (dispatch, getState) => {
  productCreateFamilyCall({ ...productsFamiliesSlice.actions, productListFamily }, dispatch, getState(), { id, description, type, parent });
};

export const productDisableFamily = ({ id }) => (dispatch, getState) => {
  productDisableFamilyCall({ productListFamily }, dispatch, getState(), { id });
};

export const productUpdateFamily = ({ id, description, type, parent }) => (dispatch, getState) => {
  productUpdateFamilyCall({ productListFamily }, dispatch, getState(), { id, description, type, parent });
};

import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { styles } from "./ResourceForm.styles";
import { formFields, formSchema } from "./ResourceForm.schema";

import { Form, FormFields } from "../../../common/forms";
import Permission from "../../../app/components/Permission";
import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import {
  resourceUpdate,
  resourceCreate,
  placesList,
  selectPlacesList,
  resourceGet,
  selectResourceDetails,
  resourceListFull,
  selectResourceListFull,
} from "../resourcesSlice";
import { useFirstLoad, useNavigate, usePromoter } from "../../../common/hooks";

function ResourceForm({ classes, copy }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const promoter = usePromoter();
  const navigate = useNavigate();
  const places = useSelector(selectPlacesList);
  const resources = useSelector(selectResourceListFull);
  const resource = useSelector(selectResourceDetails);

  useFirstLoad(() => {
    dispatch(placesList());
    dispatch(resourceListFull());
    if (id) {
      dispatch(resourceGet(id));
    }
  });

  const onSubmitHandler = async (values) => {
    if (id && !copy) {
      dispatch(resourceUpdate({ ...values, id }));
    }
    else {
      dispatch(resourceCreate(values));
    }
  };

  const defaultValues = {
    height: { category: "COMP", value: 0, unit: "CMT" },
    width: { category: "COMP", value: 0, unit: "CMT" },
    depth: { category: "COMP", value: 0, unit: "CMT" },
    weight: { category: "MASS", value: 0, unit: "GRM" },
    attributes: {
      capacityHeight: { category: "COMP", value: 0, unit: "CMT" },
      capacityWidth: { category: "COMP", value: 0, unit: "CMT" },
      capacityDepth: { category: "COMP", value: 0, unit: "CMT" },
      capacityWeight: { category: "MASS", value: 0, unit: "GRM" },
    }
  }

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmitHandler}
        schema={formSchema({ t })}
        initialValues={id || copy ? resource : defaultValues}
        render={({ values, form }) => {
          return (
            <Page
              permission={id ? "promoter.actions.resources.update" : "promoter.actions.resources.create"}
              header={<PageTitle
                onBack={() => navigate("/resources")}
                title={id ? `${t("resources.ResourceForm.header.titleEdit")} ${resource?.title}` : t("resources.ResourceForm.header.title")}
                actions={
                  <Grid container direction="row" spacing={2}>
                    <Permission code={id ? "promoter.actions.resources.update" : "promoter.actions.resources.create"}>
                      <Grid item>
                        <Button variant="contained" color="primary" type="submit">
                          {t("resources.ResourceForm.form.btnSubmit")}
                        </Button>
                      </Grid>
                    </Permission>
                  </Grid>
                }
              />}>
              <Paper className={classes.paper}>
                <FormFields fields={formFields({ t, values, places, resources, promoter, form })} />
              </Paper>
            </Page>
          );
        }}
      />
    </div>
  );
}

export default withStyles(ResourceForm, styles);

import React from "react";
import { Grid, CircularProgress } from "@mui/material";

export default function MainLoader() {
  return (
    <Grid container style={{ height: "100vh" }} justifyContent="center" alignItems="center">
      <CircularProgress />
    </Grid>
  );
}

import { processImageUrl } from "../../../api/s3";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { asyncForEach, guid } from "../../../common/utils";

const promoterConfigsCall = async ({ setConfigs }, dispatch, state) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
  let rh = requestHelper(dispatch, "PROMOTER_CONFIGS");
  try {
    let result = await call({
      type: "PROMOTER",
      subType: "CONFIGS",
      request: {},
    });
    let parsedConfigs = {
      ...result.configs,
      functional: {
        ...(result.configs?.functional || {}),
        home: {
          ...(result.configs?.functional?.home || {}),
          flyer: {
            url: result.configs.functional?.home?.flyer?.location,
            // eslint-disable-next-line
            name: (result.configs.functional?.home?.flyer?.location || "").replace(/^.*[\\\/]/, ""),
          },
        },
      }
    };

    let highlights = [];
    await asyncForEach(result.configs?.functional?.home?.highlights, async (highlight) => {
      highlights.push({
        location: highlight?.location,
        base64: await processImageUrl(fileStorageConfigs, highlight?.location),
        // eslint-disable-next-line
        name: (highlight?.location || "").replace(/^.*[\\\/]/, ""),
        id: guid(),
      });
    });

    parsedConfigs.functional.home.highlights = highlights;

    dispatch(setConfigs(parsedConfigs));
  } catch (ex) {
    rh.error("promoter", ex);
  }
  rh.close();
};

export default promoterConfigsCall;

import React from "react";
import { withStyles } from "tss-react/mui";
import { Button, IconButton, Grid, Divider, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { FieldArray } from "react-final-form-arrays";
import { styles } from "./BlockArrayFields.styles";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import FormTitle from "./FormTitle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { yupErrorStringExtractor } from "../../../common/utils";

function BlockArrayFields({ classes, name, formFields, addMoreLabel, onAddMore, title, subtitle, innerHeader }) {
  return (
    <div className={classes.root}>
      <FieldArray name={name}>
        {({ fields, meta }) => {
          let errorMsg = typeof meta.error === "string" ? meta.error : meta.error?.[0]?._base
          return (
            <div>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <FormTitle
                    title={title}
                    subtitle={subtitle}
                    error={meta.error && meta.touched && errorMsg}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  {innerHeader && (
                    <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item>{innerHeader}</Grid>
                    </Grid>
                  )}
                  {fields.map((item, index) => (
                    <div key={item}>
                      <Grid container alignItems="flex-start" spacing={2}>
                        {formFields(item).map((item, idx) =>
                          item.hidden ? null : (
                            <Grid item xs={item.size} key={idx}>
                              {item.field}
                            </Grid>
                          )
                        )}
                        <Grid item xs={1}>
                          <IconButton
                            className={classes.deleteButton}
                            aria-label="delete"
                            onClick={() => fields.remove(index)}
                            size="large">
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Divider className={classes.divider} />
                    </div>
                  ))}
                  <Grid container justifyContent="center">
                    <Button onClick={onAddMore} variant="text" className={classes.addMoreButton} startIcon={<AddIcon />}>
                      {addMoreLabel}
                    </Button>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          )
        }}
      </FieldArray>
    </div>
  );
}

export default withStyles(BlockArrayFields, styles)

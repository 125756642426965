import {getPromoterConfig, requestHelper} from "../../../app/coreSlice";
import { call } from "../../../app/websocket/_sliceCalls/websocket";
import {asyncForEach} from "../../../common/utils";
import {processImageUrl} from "../../../api/s3";

const businessReportOrdersOverviewCall = async ({
  clearQueriesReportOrdersOverviewList,
  setQueriesReportOrdersOverviewList,
  setQueriesReportOrdersOverviewListTotalRows,
  setQueriesReportOrdersOverviewFilter
}, dispatch,state, { filter, clear }) => {
  const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");

  let rh = requestHelper(dispatch, "BUSINESS_REPORT_ORDERS_OVERVIEW");
  if (clear) {
    dispatch(clearQueriesReportOrdersOverviewList());
  }
  dispatch(setQueriesReportOrdersOverviewFilter(filter));

  try {
    const { limit, offset, sortField = "", sortType, month, year, searchField, search, discrepancies, entityId } = filter;
    let result = await call({
      type: "BUSINESS",
      subType: "REPORT_ORDERS_OVERVIEW",
      request: {
        limit,
        offset,
        sortField: sortField.toUpperCase(),
        sortType,
        month,
        year,
        searchField,
        search: searchField ? search : undefined,
        discrepancies,
        entityId,
      },
    });

    if (result && result.ordersOverview && result.ordersOverview.length > 0) {

      let ordersOverview = [];
      await asyncForEach(result.ordersOverview, async (order) => {
        ordersOverview.push({
          ...order,
          image: await processImageUrl(fileStorageConfigs,  order.image),
          thumbnail: await processImageUrl(fileStorageConfigs,  order.thumbnail),
        });
      });

      dispatch(setQueriesReportOrdersOverviewList(ordersOverview));
      dispatch(setQueriesReportOrdersOverviewListTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("business", ex);
  }
  rh.close();
};

export default businessReportOrdersOverviewCall;
import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid, Button } from "@mui/material";

import { styles } from "./PromotionInput.styles";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { CurrencyInput, QuantityInput } from "../../../../../common/inputs/custom";
import { usePromoter } from "../../../../../common/hooks";

// "campaignType": "PR",
// "promotionCampaign": {
//   "productPrice": [
//     {
//       "quantity": 10,
//       "price": 104.99
//     }
//   ]
// }

function PromotionInput({ classes, id, campaignId, disabled, products }) {
  const { t } = useTranslation();
  const promoter = usePromoter();

  const addHandler = (input) => {
    let nValue = [...input.value] || [{ quantity: 1, price: 0 }];
    input.onChange && input.onChange([...nValue, { quantity: 1, price: 0 }]);
  };
  const removeHandler = (input) => {
    input.onChange && input.onChange(input.value.slice(0, input.value.length - 1));
  };

  const quantityHandler = (input, val, pos) => {
    let nValue = [...input.value] || [{ quantity: 1, price: 0 }];
    nValue[pos] = { ...nValue[pos], quantity: val };
    input.onChange && input.onChange([...nValue]);
  };

  const priceHandler = (input, val, pos) => {
    let nValue = [...input.value] || [{ quantity: 1, price: 0 }];
    nValue[pos] = { ...nValue[pos], price: val };
    input.onChange && input.onChange([...nValue]);
  };
  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <div>
            <Grid container spacing={1}>
              <Grid item>
                <Grid
                  container
                  justifyContent="space-between"
                  direction="column"
                  style={{ height: "100%" }}
                  spacing={1}
                >
                  <Grid item>
                    <Typography
                      component="span"
                      variant="caption"
                      htmlFor={id}
                      color="textPrimary"
                      className={classes.lbl}
                    >
                      {t("products.CampaignConfiguration.form.promotionQuantity")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      component="span"
                      variant="caption"
                      htmlFor={id}
                      color="textPrimary"
                      className={classes.lbl}
                    >
                      {t("products.CampaignConfiguration.form.promotionPrice")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {(input.value || [{ quantity: 1, price: 0 }]).map((item, i) => (
                <Grid item key={id + "_" + campaignId + "_" + i}>
                  <Grid container justifyContent="space-between" direction="column" spacing={1}>
                    <Grid item>
                      <QuantityInput
                        slim
                        style={{ width: 105 }}
                        multi={1}
                        value={item.quantity}
                        onChange={(val) => quantityHandler(input, val, i)}
                        disabled={disabled}
                        hierarchy={products?.length > 0 ? products[0].hierarchy : undefined}
                      />
                    </Grid>
                    <Grid item>
                      <CurrencyInput
                        className={classes.input}
                        disabled={disabled}
                        value={item.price}
                        onChange={(v) => priceHandler(input, v, i)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              {!disabled && (
                <Grid item>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    spacing={1}
                    style={{ height: "100%" }}
                  >
                    {input.value?.length < promoter?.getConfig("price", "numberQuantityRange") &&
                      <Grid item>
                        <Button
                          className={classes.btnAdd}
                          variant="contained"
                          color="secondary"
                          size="small"
                          type="button"
                          onClick={() => addHandler(input)}
                        >
                          +
                        </Button>
                      </Grid>
                    }
                    {input.value?.length > 1 && (
                      <Grid item>
                        <Button
                          className={classes.btnAdd}
                          variant="contained"
                          color="secondary"
                          size="small"
                          type="button"
                          onClick={() => removeHandler(input)}
                        >
                          -
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
        )}
      </Field>
    </div>
  );
}

export default withStyles(PromotionInput, styles)

export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
    minHeight: 80,
  },
  separator: {
    backgroundColor: theme.palette.primary.back,
    width: "100%",
    padding: theme.spacing(1),
    textAlign: "center",
    "& p": {
      margin: "0px !important"
    }
  }
});
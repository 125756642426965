import React from "react";
import { withStyles } from "tss-react/mui";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useNavigate } from "../../../common/hooks";
import { Button, Grid } from "@mui/material";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import SearchBox from "../../../common/components/inputs/SearchBox";

import { styles } from "./AddressAggregatedOrders.styles";
import AddressAggregatedDataGrid from "./AddressAggregatedDataGrid/AddressAggregatedDataGrid";
import RefreshIcon from "@mui/icons-material/Refresh";

import { dateTimeFormat } from "../../../common/utils";
import { orderAcceptAll, orderListOrdersAggregate, selectAddressAggregatedOrders, selectAddressAggregatedOrdersDetails, selectAddressAggregatedOrdersFilter, selectAddressAggregatedOrdersTotalRows } from "../ordersSlice";
import Page from "../../../common/displays/Page/Page";
import { canAcceptOrder, canDispatchOrder } from "../_common/ordersUtils";
import { selectLoader } from "../../../app/coreSlice";

function AddressAggregatedOrders({ classes }) {
  const { t } = useTranslation();
  const loading = useSelector(selectLoader);
  const data = useSelector(selectAddressAggregatedOrders);
  const filter = useSelector(selectAddressAggregatedOrdersFilter);
  const totalRows = useSelector(selectAddressAggregatedOrdersTotalRows);
  const details = useSelector(selectAddressAggregatedOrdersDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  let idcheck = parseInt(details?.aggregateId) === parseInt(id);

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search,
      offset: 0,
    };
    dispatch(orderListOrdersAggregate(id, nFilter, true));
  };

  const onRowSelectedHandler = (order) => {
    if (canAcceptOrder(order)) {
      navigate(`/orders/received/${order.id}/accept`);
    }
    else if (canDispatchOrder(order)) {
      navigate(`/orders/received/${order.id}/dispatch`);
    }
    else {
      navigate("/orders/received/products/" + order.id);
    }
  };

  const onFilterChangeHandler = (v) => {
    dispatch(orderListOrdersAggregate(id, v.filter, v.reset));
  };

  const refreshHandler = () => {
    dispatch(orderListOrdersAggregate(id, filter, true));
  };

  const onAcceptAllHandler = (row) => {
    dispatch(orderAcceptAll(id, row.id));
  };

  return (
    <Page
      permission="orders.actions.received.list"
      header={<PageTitle
        title={idcheck && t("orders.AggregatedOrders.header.subtitle") + " " + details?.aggregateId}
        subtitle={idcheck && dateTimeFormat(details?.cutOffTime)}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <SearchBox onEnter={(v) => onSearchHandler(v)} />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={refreshHandler}
                disabled={Boolean(loading)}
              >
                <RefreshIcon />
              </Button>
            </Grid>
          </Grid>
        }
        onBack={() => navigate("/orders/received")}
      />}>
      <AddressAggregatedDataGrid
        data={idcheck ? data : []}
        totalRows={totalRows}
        onRowSelected={onRowSelectedHandler}
        filter={filter}
        onFilterChange={onFilterChangeHandler}
        onAcceptAll={onAcceptAllHandler}
      />
    </Page>
  );
}

export default withStyles(AddressAggregatedOrders, styles);

import ProductSnip from "../../../../../common/displays/ProductSnip/ProductSnip";

export function listColumns(t, classes) {
  return [
    {
      field: "description",
      headerName: t("ordersIncidents.OrdersIncidentsList.columns.description"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return <ProductSnip
          image={v.thumbnail || v.image}
          description={v.description}
          gtin={v.gtin}
          promoterInternalCode={v.promoterInternalCode}
        />;
      },
    },
    {
      field: "incidentQuantity",
      headerName: t("ordersIncidents.OrdersIncidentsList.columns.incidentQuantity"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.incidentQuantity || 0 + " UN";
      },
    },
    {
      field: "orderQuantity",
      headerName: t("ordersIncidents.OrdersIncidentsList.columns.orderQuantity"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.orderQuantity || 0 + " UN";
      },
    },
  ];
}

// products: [
//   description: "FILETE DE PESCADA ÁFRICA DO SUL 400G\t",
//   gtin: "05601718201717",
//   image: "https://gelpeixe.pt/assets/site/uploads/products/pt/20190123175860.png",
//   incidentQuantity: 5,
//   orderQuantity: 50,
//   productBatches: "[{\"batch\": \"test\", \"expirationDate\": \"2022-08-08\"}]",
//   productId: "e22b9a3a-ffec-4204-9d4f-834064f4d304",
//   thumbnail: "https://gelpeixe.pt/assets/site/uploads/products/pt/20190123175860.png"
// ]

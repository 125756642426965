import { useNavigate as useNavigateRR } from "react-router-dom";
import { selectSelectedPromoter } from "../../app/coreSlice";
import { useSelector } from "react-redux";

export const useNavigate = () => {
  const selectedPromoter = useSelector(selectSelectedPromoter);
  const navigateRouter = useNavigateRR();
  const navigate = (path) => {
    if (selectedPromoter) {
      navigateRouter("/" + selectedPromoter + path);
    }
    else {
      navigateRouter(path);
    }
  }
  return navigate;
};
import React, { useState, useEffect } from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid, Switch } from "@mui/material";
import { Field } from "react-final-form";

import { styles } from "./BusinessUnits.styles";
import ListArrayFields from "../../../../common/components/formFields/ListArrayFields";
import { businessUnitFormFields } from "./BusinessUnits.schema";
import { usePromoter } from "../../../../common/hooks";
import { useTranslation } from "react-i18next";

function BusinessUnits({
  classes,
  id = "businessUnits",
  textField = "businessUnitDesc",
  // arrayFields,
  mutators,
  selectedType,
  defaultShow = false,
  values,
}) {
  const { t } = useTranslation();
  const [showUnits, setShowUnits] = useState(false);
  const promoter = usePromoter();
  const { push, change } = mutators;

  useEffect(() => {
    if (defaultShow) {
      setShowUnits(true);
    }
    // eslint-disable-next-line
  }, [defaultShow])

  const onAddMoreHandler = () => {
    push("businessUnits", {
      businessUnitDesc: t("entities.CreateEntity.form.businessUnitsDefaultName"),
      fee: {
        type: "O",
        valueType: "P",
        frequencyType: "A",
        quantity: "0",
      },
    })
  }

  const onClearHandler = () => {
    change("businessUnits", []);
  }

  const switchHandler = (field) => {
    setShowUnits(!showUnits);
    if (!showUnits) {
      onAddMoreHandler && onAddMoreHandler();
    } else {
      onClearHandler && onClearHandler();
    }
  };


  return (
    <div className={classes.root}>
      <Grid container direction="row">
        <Grid item>
          <Grid container direction="column">
            <Typography component="h6" variant="h6" className={classes.title}>
              {t("entities.CreateEntity.form.businessUnitsTitle")}
            </Typography>
            <Typography
              component="h6"
              variant="body1"
              color="textSecondary"
              className={classes.subtitle}
            >
              {t("entities.CreateEntity.form.businessUnitsSubtitle")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Field name={id}>
            {({ input }) => (
              <Switch
                checked={showUnits}
                color="primary"
                onChange={() => switchHandler(input.value)}
                name={id}
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <div hidden={!showUnits}>
        <ListArrayFields
          id={id}
          formFields={(item) => businessUnitFormFields({t, item, values, promoter, selectedType})}
          textField={textField}
          onAddMore={onAddMoreHandler}
          addMoreLabel={t("entities.CreateEntity.form.businessUnitsAddMore")}
          deleteLabel={t("entities.CreateEntity.form.businessUnitsRemove")}
        />
      </div>
    </div>
  );
}

export default withStyles(BusinessUnits, styles)

export const styles = () => ({
  root: {
    position: "fixed",
    color: "#c8c8c8",
    fontSize: 11,
    zIndex: 2,
    bottom: 0,
    right: 0,
    borderTop: "1px solid #c8c8c8",
    borderLeft: "1px solid #c8c8c8",
    borderRadius: "10px 0 0 0",
    padding: "0 5px",
    backgroundColor: "#fff",
    lineHeight: 1.9,
    "& a": {
      color: "#a1b5ff",
      fontWeight: "bold"
    }
  },
  dev: {
    backgroundColor: "#c096ff",
    color: "#9c44c1",
    borderTop: "1px solid #9c44c1",
    borderLeft: "1px solid #9c44c1",
    "& a": {
      color: "#9c44c1"
    }
  },
  stage: {
    backgroundColor: "#fffbcb"
  }
});
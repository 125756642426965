import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./ImportOrder.styles";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { importData, importOrder, selectCurrentOrder } from "../../ordersSlice.calls";
import { usePromoter } from "../../../../common/hooks";
import ImportOrderResultsDataGrid from "./ImportOrderResultsDataGrid/ImportOrderResultsDataGrid";
import { formFields, formSchema } from "./ImportOrder.schema";
import Form from "../../../../common/forms/Form";
import Permission from "../../../../app/components/Permission";
import { downloadFile } from "../../../../api/s3";

function ImportOrder({ classes }) {
  const { t } = useTranslation();
  const importFile = useSelector(importData);
  const dispatch = useDispatch();
  const promoter = usePromoter();
  const currentOrder = useSelector(selectCurrentOrder);

  const onSubmit = async (values) => {
    dispatch(importOrder(promoter, values, currentOrder));
  };

  const downloadTemplate = (filePath) => {
    downloadFile(promoter, filePath);
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        schema={formSchema(t)}
        initialValues={importFile}
        render={() => {
          return (
            <>
              <div className={classes.root}>
                <Paper className={classes.paper} elevation={0}>
                  <Grid container alignItems="flex-start" spacing={2}>
                    {formFields(t).map((item, idx) => (
                      <Grid item xs={item.size} key={idx}>
                        {item.field}
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container direction="row-reverse" spacing={2}>
                    <Permission code="orders.actions.placed.import">
                      <Grid item>
                        <Button variant="contained" color="primary" size="large" type="submit">
                          {t("orders.ImportOrder.form.importBtn")}
                        </Button>
                      </Grid>
                    </Permission>
                  </Grid>
                </Paper>

                <div>&nbsp;</div>

                {importFile?.results !== undefined && (
                  <Paper className={classes.paper} elevation={0}>
                    <ImportOrderResultsDataGrid
                      data={importFile?.results}
                      title={t("orders.ImportOrder.header.results")}
                    />
                  </Paper>
                )}

                <div>&nbsp;</div>

                <Paper className={classes.paper} elevation={0}>
                  <Typography component="span" variant="caption" color="textPrimary">
                    {t("orders.ImportOrder.header.template")}
                  </Typography>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => downloadTemplate(promoter?.template?.order)}
                      >
                        {t("orders.ImportOrder.template.order")}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(ImportOrder, styles)

import * as Yup from "yup";
import { EntityPicker, ProductPickerField } from "../../../common/components/formFields";
import { EntityAutoCompleteField, TextField } from "../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    name: Yup.string().required(t("products.CatalogCreate.form.requiredName")),
    ownerId: Yup.string().required(t("products.CatalogCreate.form.requiredOwner")),
    buyers: Yup.array().ensure().min(1, t("products.CatalogCreate.form.requiredAtLeastOneBuyer")),
    products: Yup.array().ensure().min(1, t("products.CatalogCreate.form.requiredAtLeastOneProduct")),
  });
}

export function formFields(t, promoter, edit, user, values) {
  let ownerIdDisabled = edit;
  return [
    {
      size: 12,
      field: <TextField id="name" type="text" label={t("products.CatalogCreate.form.name")} required />,
    },
    {
      size: 12,
      field: (
        <EntityAutoCompleteField
          typeFilter={["P", ...promoter?.getConfig("product", "allowedEntityTypes")]}
          id="ownerId"
          label={t("products.CatalogCreate.form.ownerId")}
          required
          disabled={ownerIdDisabled}
        />
      ),
    },
    {
      size: 12,
      field: (
        <ProductPickerField
          id="products"
          label={t("products.CatalogCreate.form.products")}
          height={300}
          required
          ownerId={values?.ownerId === user.entity?.id && user.entity?.type === "P" ? undefined : values?.ownerId}
        />
      ),
    },
    {
      size: 12,
      field: (
        <EntityPicker
          typeFilter={promoter?.getConfig("order", "allowedEntityTypes") || ["W", "ST"]}
          id="buyers"
          required
          label={t("products.CatalogCreate.form.buyers")}
          height={300}
        />
      ),
    },
  ];
}

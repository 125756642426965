import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

import ActionsPopover from "../../../../common/components/ActionsPopover/ActionsPopover";
import Permission from "../../../../app/components/Permission";

export default function DpromsDataGridActions({ row, onRemove, validation, onValidate, onEdit, onCopy, onCancel }) {
  const { t } = useTranslation();

  const onEditHandler = (e) => {
    e.preventDefault();
    onEdit && onEdit(row);
  };

  const onCopyHandler = (e) => {
    e.preventDefault();
    onCopy && onCopy(row);
  };

  const onRemoveHandler = (e) => {
    e.preventDefault();
    onRemove && onRemove(row);
  };

  const onCancelHandler = (e) => {
    e.preventDefault();
    onCancel && onCancel(row);
  };

  const setValidHandler = (value) => {
    onValidate && onValidate(row, value);
  }

  let actionsButtons = [];

  // EDIT
  if (row.status === "NEW" || !row.status) {
    actionsButtons.push(<Button onClick={onEditHandler} fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item style={{ display: "flex" }}>
          <EditIcon />
        </Grid>
        <Grid item>{t("productsDproms.DpromsList.actions.edit")}</Grid>
      </Grid>
    </Button>);
  }

  // COPY
  actionsButtons.push(<Button onClick={onCopyHandler} fullWidth>
    <Grid container spacing={1} alignItems="center">
      <Grid item style={{ display: "flex" }}>
        <ContentCopyIcon />
      </Grid>
      <Grid item>{t("productsDproms.DpromsList.actions.copy")}</Grid>
    </Grid>
  </Button>);

  // DELETE
  if (row.status === "NEW" || !row.status) {
    actionsButtons.push(<Button onClick={onRemoveHandler} color="error" fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item style={{ display: "flex" }}>
          <DeleteIcon />
        </Grid>
        <Grid item>{t("productsDproms.DpromsList.actions.remove")}</Grid>
      </Grid>
    </Button>);
  }

  // CANCEL
  if ((row.status === "ACC" || row.status === "PND") && !(row.processes || []).some(({ waitingAction, processType }) => waitingAction && processType === "FlowRemovePromoDiscount") && !row.removePending) {
    actionsButtons.push(<Button onClick={onCancelHandler} color="error" fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item style={{ display: "flex" }}>
          <DeleteIcon />
        </Grid>
        <Grid item>{row.status === "PND" ? t("productsDproms.DpromsList.actions.cancel") : t("productsDproms.DpromsList.actions.remove")}</Grid>
      </Grid>
    </Button>);
  }


  return (
    <Grid container spacing={1} justifyContent="flex-end" alignItems="center" wrap="nowrap">
      {(row?.processes || []).some(item => item.status === "PENDING" && item.processType === "FlowRemovePromoDiscount") &&
        <Grid item>
          <div style={{ margin: 6, display: "flex", justifyContent: "flex-end" }}>
            <Tooltip title={t("productsDproms.DpromsList.columns.pendingRemove")}>
              <ReportProblemOutlinedIcon style={{ display: "block" }} color="warning" fontSize="small" />
            </Tooltip>
          </div>
        </Grid>
      }
      {validation && (row.processes || []).some(({ waitingAction }) => waitingAction) &&
        <Permission code="products.actions.pendingDprom.action">
          <Grid item>
            <Stack direction="row" spacing={0} >
              <IconButton onClick={() => setValidHandler(false)}>
                <CancelOutlinedIcon color={row.valid === false ? "error" : "default"} />
              </IconButton>
              <IconButton onClick={() => setValidHandler(true)}>
                <CheckCircleOutlineIcon color={row.valid === true ? "success" : "default"} />
              </IconButton>
            </Stack>
          </Grid>
        </Permission>
      }
      {!validation && actionsButtons.length > 0 &&
        <Grid item>
          <ActionsPopover
            actions={actionsButtons}
          />
        </Grid>
      }
    </Grid>
  );
}

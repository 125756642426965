import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Alert, Button, Grid, Stack, ToggleButton, ToggleButtonGroup} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import moment from "moment";

import {styles} from "./DpromsList.styles";
import DPromForm from "./DPromForm/DPromForm";
import DpromsDataGrid from "./DpromsDataGrid/DpromsDataGrid";

import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import CustomModal from "../../../common/displays/CustomModal/CustomModal";
import {formProductListFamily} from "../../products/productsSlice";
import {useFirstLoad, useNavigate, useUser} from "../../../common/hooks";
import Permission from "../../../app/components/Permission";
import {
  addTempDprom,
  clearTempDproms,
  clearTempValidation,
  initTempDproms,
  productDpromAction,
  productDpromCreate,
  productDpromList,
  productDpromRemove,
  removeTempDprom,
  selectDpromsList,
  selectDpromsListFilter,
  selectDpromsListTotalRows,
  selectTempDpromsList,
  selectTempDpromsValidation,
  updateTempValidation
} from "../productsDpromsSlice";
import {promoterListLocations_full} from "../../productsPrices/productsPricesSlice";
import {selectLoader} from "../../../app/coreSlice";
import DpromRemoveModal from "./DpromRemoveModal/DpromRemoveModal";
import DpromValidationCommentModal from "./DpromValidationCommentModal/DpromValidationCommentModal";
import SearchBox from "../../../common/components/inputs/SearchBox";

function DpromsList({ classes, validation }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [dpromToRemove, setDpromToRemove] = useState();
  const dpromsList = useSelector(selectDpromsList);
  const tempDproms = useSelector(selectTempDpromsList);
  const tempValidation = useSelector(selectTempDpromsValidation);
  const navigate = useNavigate();
  const user = useUser();

  const totalRows = useSelector(selectDpromsListTotalRows);
  const filter = useSelector(selectDpromsListFilter);
  const [selectedRow, setSelectedRow] = useState();
  const [commentModal, setCommentModal] = useState(false);
  const loading = useSelector(selectLoader);

  useFirstLoad(() => {
    dispatch(formProductListFamily());
    dispatch(initTempDproms());
    dispatch(promoterListLocations_full());
  });

  const data = useMemo(() => {
    let joinedData = validation ? dpromsList : [...tempDproms, ...dpromsList];
    if (validation && Object.keys(tempValidation).length > 0) {
      joinedData = joinedData.map(item => ({ ...item, ...tempValidation[item.id] }));
    }
    return joinedData;
    // eslint-disable-next-line
  }, [dpromsList, tempDproms, tempValidation])

  const onAddHandler = () => {
    setSelectedRow();
    setModal(true);
  }

  const onRemoveHandler = (row) => {
    dispatch(removeTempDprom(row.tempId))
  }

  const onSubmitHandler = () => {
    if (validation) {
      dispatch(productDpromAction());
    }
    else {
      dispatch(productDpromCreate());
    }
  }

  const onFilterChangeHandler = (v) => {
    dispatch(productDpromList({ ...filter, ...v.filter }, v.reset));
  }

  const onValidateHandler = (row, valid) => {
    if (row.valid === valid) {
      dispatch(updateTempValidation({ ...row, valid: undefined }));
    }
    else {
      setSelectedRow({ ...row, valid });
      setCommentModal(true);
    }
  }

  const onConfirmValidationHandler = (comment) => {
    dispatch(updateTempValidation({ ...selectedRow, comment }));
    setCommentModal(false);
  }

  const onCopyHandler = (row) => {
    let { status, family, tempFamily, familyCode } = row;
    if (status !== "NEW") {
      tempFamily = family;
      family = familyCode;
    }
    let endDate = row.endDate && moment(row.endDate).isBefore(moment()) ? null : row.endDate;
    dispatch(addTempDprom({ ...row, status: "NEW", id: undefined, startDate: moment().toISOString(), endDate, tempFamily, family }));
  }

  const onEditHandler = (row) => {
    setSelectedRow(row);
    setModal(true);
  }

  const onCancelHandler = (row) => {
    setRemoveModal(true);
    setDpromToRemove(row);
  }

  const onRemoveConfirmHandler = (removeDate) => {
    const { id, type, ownerId, ownerName, locations, products, family } = dpromToRemove;
    dispatch(productDpromRemove({ id, type, ownerId, ownerName, locations, products, family, removeDate }));
    setRemoveModal(false);
  }

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search,
      offset: 0,
    };

    dispatch(productDpromList(nFilter, true));
  };

  return (
    <Page
      permission={validation ? "products.actions.pendingDprom.list" : "products.actions.dprom.list"}
      header={<PageTitle
        title={t("productsDproms.DpromsList.header.title")}
        info={`${totalRows} ${t("productsDproms.DpromsList.header.rowsInfo")}`}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <SearchBox onEnter={(v) => onSearchHandler(v)} value={filter?.search} />
            </Grid>
            <Permission code={validation ? "products.actions.pendingDprom.action" : "products.actions.dprom.create"}>
              <Grid item>
                <Button
                  variant="contained"
                  size="large"
                  onClick={onSubmitHandler}
                  disabled={loading || (validation ? !(data || []).some(item => item.valid !== undefined) : !(data || []).some(item => item.status === "NEW"))}
                >
                  {t("productsDproms.DpromsList.header.btnSubmit")}
                </Button>
              </Grid>
            </Permission>
            {!validation &&
              <Grid item>
                <Button variant="outlined" size="large" onClick={onAddHandler} id="add"><AddIcon fontSize="inherit" /></Button>
              </Grid>
            }
          </Grid>
        }
      />}>
      <Stack spacing={1}>
        {user?.rolePermission?.products?.actions?.pendingDprom?.list &&
          <div className={classes.links}>
            <ToggleButtonGroup color="primary" size="small" value={validation ? "validation" : "dproms"} >
              <ToggleButton color="primary" value="dproms" onClick={() => validation && navigate('/products/dproms')} >
                {t("productsDproms.DpromsList.header.dpromsLink")}
              </ToggleButton>
              <ToggleButton color="primary" value="validation" onClick={() => !validation && navigate('/products/dproms/validation')} >
                {t("productsDproms.DpromsList.header.validationLink")}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        }
        {(validation ? (data || []).some(item => item.valid !== undefined) : (data || []).some(item => item.status === "NEW")) &&
          <Alert variant="outlined" severity="info" className={classes.alert}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {validation ? t("productsDproms.DpromsList.header.changesToSubmitValidation") : t("productsDproms.DpromsList.header.changesToSubmit")}
              <Button size="small" variant="text" color="info" disableRipple style={{ height: "auto", padding: 0 }} onClick={() => validation ? dispatch(clearTempValidation()) : dispatch(clearTempDproms())}>
                {t("productsDproms.DpromsList.header.clearChanges")}
              </Button>
            </Stack>
          </Alert>
        }
        <DpromsDataGrid
          data={validation ? data.filter(item => item.status !== "NEW") : data}
          totalRows={totalRows}
          onRemove={onRemoveHandler}
          onFilterChange={onFilterChangeHandler}
          validation={validation}
          onValidate={onValidateHandler}
          filter={filter}
          onCopy={onCopyHandler}
          onEdit={onEditHandler}
          onCancel={onCancelHandler}
        />
        <CustomModal open={modal} setOpen={setModal} size="large" height={870} title={t("productsDproms.DpromsList.modal.title")}>
          <DPromForm dprom={selectedRow} onSubmit={() => setModal(false)} />
        </CustomModal>
        <DpromValidationCommentModal open={commentModal} setOpen={setCommentModal} onSubmit={onConfirmValidationHandler} accept={selectedRow?.valid} />
        <DpromRemoveModal open={removeModal} setOpen={setRemoveModal} onRemoveConfirm={onRemoveConfirmHandler} dpromToRemove={dpromToRemove} />
      </Stack>
    </Page>
  );
}

export default withStyles(DpromsList, styles);
export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
  },
  icon: {
    fontSize: 36,
    marginTop: 5,
  },
  info: {
    color: theme.palette.text.secondary,
  },
  percentageUp: {
    color: theme.palette.success.main,
    margin: 0,
  },
  percentageDown: {
    color: theme.palette.warning.main,
    margin: 0,
  },
  value: {
    marginRight: 10,
    marginBottom: 0,
    marginTop: 0,
    display:"inline"
  },
  valueLabel: {
    marginBottom: 0,
    marginTop: 0,
    display:"inline",
    fontSize:14,
  },
  category: {
   // display:"block"
  },
  nomargin: {
    margin: 0,
  },
});

export const styles = (theme) => ({
  root: {},
  priceSelector: {
    maxWidth: 120,
    "& .MuiInputBase-root": {
      padding: 0,
      "& input.MuiInputBase-input": {
        textAlign: "right",
        padding: "4px 0 4px 8px !important",
      },
    },
    "& .Mui-disabled": {
      backgroundColor: "#FFF !important",
    },
  },
  input: {
    maxWidth: 120,
    width: "auto",
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 4,
      backgroundColor: "transparent",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    "& input": {
      color: theme.palette.text.primary,
      textAlign: "right",
    },
  },
  adornment: {
    cursor: "pointer",
    color: theme.palette.text.primary,
  },
  select: {
    "&.MuiInput-underline": {
      "&::before": {
        border: "none !important",
      },
      "&::after": {
        border: "none !important",
      },
    },
    "& .MuiSelect-select.MuiSelect-select": {
      backgroundColor: "transparent",
    },
  },
  rotateIcon: {
    transform: "rotate(180deg)",
  },
  selected: {
    backgroundColor: "red",
  },
  dropicon: {
    display: "block",
  },
  currency: {
    marginRight: 4,
  },
});
